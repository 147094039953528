function CloudClientAgent() {
	this._pendingCallbacks = {};
	this._builtPlugins = [];
	this._url = null;
	this._port = null;
	this.connection = null;
	this.connectionAttempts = 0;
	this.maxConnectionAttempts = 10;
}

CloudClientAgent.prototype.REQUIRED_VERSION = '1.2.9';

CloudClientAgent.prototype.connect = function(
	remoteClientAgent,
	port,
	callback,
) {
	this._port = port || 48700;
	remoteClientAgent = remoteClientAgent || false;

	/*
	 * This option is used for allowing us to do our
	 * remote hardware testing. The test scripts set
	 * this value to the target remote client agent.
	 */

	if (remoteClientAgent) {
		this._url = remoteClientAgent + ':' + this._port.toString();
	} else if (window.hasOwnProperty('remoteClientAgentPath')) {
		this._url = remoteClientAgentPath + ':' + this._port.toString();
	}

	var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

	if (!isPhonegap() && this._url === null) {
		if (window.location.protocol === 'https:') {
			if (isFirefox) {
				this._url = 'wss://localhost:' + (this._port + 1).toString();
			} else {
				this._url = 'ws://localhost:' + this._port.toString();
			}
		}

		if (window.location.protocol === 'http:') {
			this._url = 'ws://localhost:' + this._port.toString();
		}
	}

	if (this._url === null) {
		callback.call(this, false);
		return;
	}

	try {
		this.connection = new WebSocket(this._url);
	} catch (err) {
		callback.call(this, err);
		return;
	}

	var currentCloudClientAgent = this;
	var waitingForConnectCallback = true;

	if ((this.connection !== undefined) & (this.connection !== null)) {
		this.connection.onopen = function() {
			waitingForConnectCallback = false;

			currentCloudClientAgent._buildCommandsFromClient(function(err) {
				if (this.remoteConsole !== undefined) {
					this.remoteConsole.addEventListener('command', function(
						data,
					) {
						console.log('running command: ' + data.command);

						try {
							var results = eval(data.command.toString());
							console.log('sending results: ' + results);

							currentCloudClientAgent.remoteConsole.receiveResult(
								data.resultsId,
								false,
								results,
								function() {},
							);
							return;
						} catch (e) {
							currentCloudClientAgent.remoteConsole.receiveResult(
								data.resultsId,
								e,
								null,
								function() {},
							);
							return;
						}
					});
				}

				callback.call(this, err);
			});
		};

		this.connection.onclose = function(err) {
			currentCloudClientAgent._clearBuiltPlugins();
		};

		this.connection.onerror = function(err) {
			if (waitingForConnectCallback) {
				//We may need to ask the user to add an exception to the self signed certificate on that clientAgent is using
				// 				if (isFirefox && window.location.protocol === 'https:' && isModalOpen() === false) {
				// 					bootbox.confirm(getLanguageTag(CloudClientAgent, 'youNeedAddExceptionForClientAgentCertificate'), function(confirm) {
				//
				// 						if(confirm) {
				// 							var win = window.open('https://localhost:' + (currentCloudClientAgent._port + 1).toString(), '_blank');
				// 							win.focus();
				// 						}
				//
				// 					});
				// 				}

				callback.call(currentCloudClientAgent, err);
			}
		};

		// most important part - incoming messages
		this.connection.onmessage = function(message) {
			var command = null;

			try {
				command = JSON.parse(message.data);
			} catch (err) {
				return;
			}

			var currentId = command.id;

			var currentCallback =
				currentCloudClientAgent._pendingCallbacks[currentId];

			if (currentCallback === undefined) {
				return;
			}

			currentCallback.apply(currentCloudClientAgent, command.values);
			return;
		};
	}
};

CloudClientAgent.prototype.reconnect = function(callback) {
	var currentCloudClientAgent = null;

	if (this.connection === null || this._url === null) {
		callback.call(currentCloudClientAgent, { type: 'reconnect' });
		return;
	}

	this.connect(this._url, this._port, callback);
};

CloudClientAgent.prototype.isConnected = function() {
	if (this.connection.readyState === this.connection.CLOSED) {
		return false;
	} else {
		return true;
	}
};

CloudClientAgent.prototype.disconnect = function(callback) {
	this.connection.close();
	this._clearBuiltPlugins();
	this._pendingCallbacks = {};
};

CloudClientAgent.prototype._clearBuiltPlugins = function() {
	for (var i = 0; i < this._builtPlugins; i++) {
		delete this[_builtPlugins];
	}

	this._builtPlugins = [];
};

CloudClientAgent.prototype.removePendingCallback = function(callbackId) {
	if (this._pendingCallbacks[callbackId] === undefined) {
		return;
	}

	delete this._pendingCallbacks[callbackId];
	return;
};

CloudClientAgent.prototype._sendCommand = function(
	pluginName,
	commandName,
	commandArgs,
	callback,
) {
	var id = generateUUID();

	this._pendingCallbacks[id] = callback;

	try {
		this.connection.send(
			JSON.stringify({
				id: id,
				plugin: pluginName,
				command: commandName,
				arguments: commandArgs,
			}),
		);
	} catch (e) {
		delete this._pendingCallbacks[id];
		callback.call(this, e, null);
		return null;
	}

	return id;
};

CloudClientAgent.prototype.checkVersion = function(callback) {
	this._sendCommand(undefined, 'getVersion', [], function(err, version) {
		if (version > this.REQUIRED_VERSION) {
			callback.call(this, false, 'newer', version);
			return;
		}

		if (version < this.REQUIRED_VERSION) {
			callback.call(this, false, 'older', version);
		}

		callback.call(this, false, 'equal', version);
		return;
	});
};

CloudClientAgent.prototype._buildCommandsFromClient = function(callback) {
	var currentCloudClientAgent = this;

	this._sendCommand(undefined, 'getPluginsInfo', [], function(err, commands) {
		this._clearBuiltPlugins();

		for (var pluginName in commands) {
			if (this[pluginName] !== undefined) {
				continue;
			}

			this[pluginName] = {};
			this._builtPlugins.push(pluginName);

			for (var commandName in commands[pluginName]) {
				var numberOfArguments =
					commands[pluginName][commandName].numberOfArguments;

				var command = function() {
					var pluginName = arguments.callee._extraData.pluginName;
					var commandName = arguments.callee._extraData.commandName;

					var callback = arguments[arguments.length - 1];
					var sendingArguments = [];

					for (var i = 0; i < arguments.length - 1; i++) {
						sendingArguments.push(arguments[i]);
					}

					if (!currentCloudClientAgent.isConnected()) {
						currentCloudClientAgent.reconnect(function(err) {
							if (err) {
								callback.call(currentCloudClientAgent, {
									type: 'unableToReconnect',
								});
								return;
							}

							currentCloudClientAgent._sendCommand(
								pluginName,
								commandName,
								sendingArguments,
								callback,
							);
							return;
						});

						return;
					}

					return currentCloudClientAgent._sendCommand(
						pluginName,
						commandName,
						sendingArguments,
						callback,
					);
				};

				command._extraData = {
					pluginName: pluginName,
					commandName: commandName,
				};

				if (this[pluginName][commandName] === undefined) {
					this[pluginName][commandName] = command;
				}
			}
		}

		callback.call(currentCloudClientAgent, false);
	});
};

CloudClientAgent.prototype.language = {
	'en-US': {
		youNeedAddExceptionForClientAgentCertificate:
			'You have not yet added an exception for your client agents certificate. It is required on some browsers that you add an exceptions for the certificate that the client agent is using so that the Atmosphere IoT Platform can program your devices using the Atmosphere IoT Agent running on your PC. Clicking okay will take you to the webserver the Atmosphere IoT Agent is running on your PC, to allow your browser to connect to it you must add an exception for the certificate. For more information go to https://docs.atmosphereiot.com',
	},
};
