function WidgetDemoProjectsTable(id, api, parent, options) {
	WidgetTable.call(this, id, api, parent, options);

	this._container.append(
		`<div id="${this.generateChildId(
			'subContainer',
		)}" class="WidgetDemoProjectsTable"></div>`,
	);
	this._subContainer = $(`#${this.generateChildId('subContainer')}`);
}

WidgetDemoProjectsTable.prototype = Object.create(WidgetTable.prototype);
WidgetDemoProjectsTable.prototype.constructor = WidgetDemoProjectsTable;

WidgetDemoProjectsTable.prototype.getContainer = function() {
	return this._subContainer;
};

WidgetDemoProjectsTable.prototype.formatterProjectName = function(a, rowData) {
	return `<a href="${generateHashCommand({
		location: 'ProjectEditor',
		projectExample: rowData.fileName,
	})}" class="WidgetProjectTable-ProjectName NoSelect">${a}</a>`;
};

WidgetDemoProjectsTable.prototype.removeSelectedProjects = function(
	callback,
) {};

WidgetDemoProjectsTable.prototype.initialize = function(callback) {
	var currentWidget = this;

	WidgetTable.prototype.initialize.call(this, function(err) {
		this.getAPI()
			.getAPIRoute('/user/create/examples')
			.get(function(err, data) {
				if (err) {
					console.log(err);
					return;
				}

				var tableData = [];

				var currentConfiguration = currentWidget.getConfiguration();

				for (var i = 0; i < data.length; i++) {
					var currentProjectData = data[i];

					if (currentConfiguration.projectTypeWhiteList) {
						if (
							currentConfiguration.projectTypeWhiteList.indexOf(
								currentProjectData.meta.projectTypeId,
							) < 0
						) {
							continue;
						}
					}

					if (currentConfiguration.projectTypeBlackList) {
						if (
							currentConfiguration.projectTypeWhiteList.indexOf(
								currentProjectData.meta.projectTypeId,
							) >= 0
						) {
							continue;
						}
					}

					if (currentConfiguration.projectNameWhiteList) {
						if (
							currentConfiguration.projectNameBlackList.indexOf(
								currentProjectData.projectName,
							) < 0
						) {
							continue;
						}
					}

					if (currentConfiguration.projectNameBlackList) {
						if (
							currentConfiguration.projectNameBlackList.indexOf(
								currentProjectData.projectName,
							) >= 0
						) {
							continue;
						}
					}

					tableData.push({
						name: currentProjectData.projectName,
						type: currentProjectData.projectTypeName,
						description: currentProjectData.description,
						created: new Date(currentProjectData.lastModified),
						fileName: currentProjectData.fileName,
					});
				}

				currentWidget.setTable(
					['name', 'description'],

					{
						name: {
							label: getLanguageTag(
								currentWidget.constructor,
								'projectName',
							),
							sortable: true,
							formatter: currentWidget.formatterProjectName,
						},
						description: {
							label: getLanguageTag(
								currentWidget.constructor,
								'description',
							),
							sortable: true,
						},
					},

					tableData,
					'descending',
					'created',
				);

				callback.call(currentWidget, false);
				return;
			});
	});
};

WidgetDemoProjectsTable.prototype.ICON = './Resources/icons/DemoProjects.svg';

WidgetDemoProjectsTable.prototype.language = deepAssign(
	{},
	WidgetTable.prototype.language,
	{
		'en-US': {
			name: 'Example Applications',
			projectName: 'Name',
			type: 'Type',
			description: 'Description',
			created: 'Created',
			updated: 'Updated',
			removeSelectedProjects: 'Remove selected applications',
			filterInputPlaceHolder: 'Search for an application...',
			noData: 'No applications found',
		},
	},
);
