function ElementControllerEmbeddedNxpRpkRgbLed(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
    RGB_Led_Set_State(RGB_LED_BRIGHT_HIGH, RGB_LED_COLOR_BLACK);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setColorPreset', [], []);
	this.setAbilityCode(
		'setColorPreset',
		`
    unsigned int desiredColor = 0;
    ATMO_GetUnsignedInt(in, &desiredColor);

    uint8_t brightness = 0;
    uint8_t color = 0;
    RGB_Led_Get_State(&brightness, &color);
    RGB_Led_Set_State(brightness, desiredColor);
    return ATMO_Status_Success;
    `,
	);

	this.addAbility('setBrightnessLow', [], ['brightnessSet']);
	this.setAbilityCode(
		'setBrightnessLow',
		`
    uint8_t brightness = 0;
    uint8_t color = 0;
    RGB_Led_Get_State(&brightness, &color);
    RGB_Led_Set_State(RGB_LED_BRIGHT_LOW, color);
    return ATMO_Status_Success;
    `,
	);

	this.addAbility('setBrightnessOff', [], ['brightnessSet']);
	this.setAbilityCode(
		'setBrightnessOff',
		`
    uint8_t brightness = 0;
    uint8_t color = 0;
    RGB_Led_Get_State(&brightness, &color);
    RGB_Led_Set_State(RGB_LED_BRIGHT_OFF, color);
    return ATMO_Status_Success;
    `,
	);

	this.addAbility('setBrightnessMedium', [], ['brightnessSet']);
	this.setAbilityCode(
		'setBrightnessMedium',
		`
    uint8_t brightness = 0;
    uint8_t color = 0;
    RGB_Led_Get_State(&brightness, &color);
    RGB_Led_Set_State(RGB_LED_BRIGHT_MEDIUM, color);
    return ATMO_Status_Success;
    `,
	);

	this.addAbility('setBrightnessHigh', [], ['brightnessSet']);
	this.setAbilityCode(
		'setBrigtnessMedium',
		`
    uint8_t brightness = 0;
    uint8_t color = 0;
    RGB_Led_Get_State(&brightness, &color);
    RGB_Led_Set_State(RGB_LED_BRIGHT_HIGH, color);
    return ATMO_Status_Success;
    `,
	);

	this.addAbility('setRedOn', [], []);
	this.setAbilityCode(
		'setRedOn',
		`
    RGB_Led_Set_State(RGB_LED_BRIGHT_HIGH, RGB_LED_COLOR_RED);
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('setGreenOn', [], []);
	this.setAbilityCode(
		'setGreenOn',
		`
    RGB_Led_Set_State(RGB_LED_BRIGHT_HIGH, RGB_LED_COLOR_GREEN);
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('setBlueOn', [], []);
	this.setAbilityCode(
		'setBlueOn',
		`
    RGB_Led_Set_State(RGB_LED_BRIGHT_HIGH, RGB_LED_COLOR_BLUE);
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('setWhiteOn', [], []);
	this.setAbilityCode(
		'onWhite',
		`
    RGB_Led_Set_State(RGB_LED_BRIGHT_HIGH, RGB_LED_COLOR_WHITE);
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('toggleRed', [], []);
	this.setAbilityCode(
		'toggleRed',
		`
	uint8_t color, brightness;
    RGB_Led_Get_State(&brightness, &color);
    if(brightness == RGB_LED_BRIGHT_OFF || color == RGB_LED_COLOR_BLACK)
    {
        brightness = RGB_LED_BRIGHT_HIGH;
    }
    else
    {
        brightness = RGB_LED_BRIGHT_OFF;
    }

    RGB_Led_Set_State(brightness, RGB_LED_COLOR_RED);
    return ATMO_Status_Success;
	`,
	);

	this.addAbility('toggleGreen', [], []);
	this.setAbilityCode(
		'toggleGreen',
		`
	uint8_t color, brightness;
    RGB_Led_Get_State(&brightness, &color);
    if(brightness == RGB_LED_BRIGHT_OFF || color == RGB_LED_COLOR_BLACK)
    {
        brightness = RGB_LED_BRIGHT_HIGH;
    }
    else
    {
        brightness = RGB_LED_BRIGHT_OFF;
    }

    RGB_Led_Set_State(brightness, RGB_LED_COLOR_GREEN);
    return ATMO_Status_Success;
	`,
	);

	this.addAbility('toggleBlue', [], []);
	this.setAbilityCode(
		'toggleBlue',
		`
	uint8_t color, brightness;
    RGB_Led_Get_State(&brightness, &color);
    if(brightness == RGB_LED_BRIGHT_OFF || color == RGB_LED_COLOR_BLACK)
    {
        brightness = RGB_LED_BRIGHT_HIGH;
    }
    else
    {
        brightness = RGB_LED_BRIGHT_OFF;
    }

    RGB_Led_Set_State(brightness, RGB_LED_COLOR_BLUE);
    return ATMO_Status_Success;
	`,
	);

	this.addAbility('setOff', [], []);
	this.setAbilityCode(
		'setOff',
		`
    uint8_t color, brightness;
    RGB_Led_Get_State(&brightness, &color);
    RGB_Led_Set_State(brightness, RGB_LED_COLOR_BLACK);
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('setColorHex', [], ['colorSet']);
	this.setAbilityCode(
		'setColorHex',
		`
    unsigned int color = 0;
    ATMO_GetUnsignedInt(in, &color);
    uint8_t red = (color >> 16) & 0xFF;
    uint8_t green = (color >> 8) & 0xFF;
    uint8_t blue = color & 0xFF;
    Rgb_Led_Set_Colors(red, green, blue);  
    ATMO_CreateValueUnsignedInt(out, color);
	return ATMO_Status_Success;
    `,
	);
}

ElementControllerEmbeddedNxpRpkRgbLed.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedNxpRpkRgbLed.prototype.constructor = ElementControllerEmbeddedNxpRpkRgbLed;

ElementControllerEmbeddedNxpRpkRgbLed.prototype.DEFAULT_TRIGGER = 'colorSet';
ElementControllerEmbeddedNxpRpkRgbLed.prototype.DEFAULT_ABILITY = 'toggleRed';
ElementControllerEmbeddedNxpRpkRgbLed.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerEmbeddedNxpRpkRgbLed.prototype.TYPE = 'EmbeddedNxpRpkRgbLed';
ElementControllerEmbeddedNxpRpkRgbLed.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'rpk',
];
ElementControllerEmbeddedNxpRpkRgbLed.prototype.HIDDEN = false;
ElementControllerEmbeddedNxpRpkRgbLed.prototype.CATEGORY = 'Rapid IOT';
ElementControllerEmbeddedNxpRpkRgbLed.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedNxpRpkRgbLed.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedNxpRpkRgbLed: 'RGB LED',
			setRed: 'Set Red LED',
			setBlue: 'Set Blue LED',
			setGreen: 'Set Green LED',
			adjustRed: 'Adjust Red LED',
			adjustBlue: 'Adjust Blue LED',
			adjustGreen: 'Adjust Green LED',
			setRedOn: 'Set Red LED On',
			setBlueOn: 'Set Blue LED On',
			setGreenOn: 'Set Green LED On',
			setWhiteOn: 'Set White LED On',
			setOff: 'LED Off',
			toggleRed: 'Toggle Red LED',
			toggleGreen: 'Toggle Green LED',
			toggleBlue: 'Toggle Blue LED',
			setRedOff: 'Set Red LED Off',
			setBlueOff: 'Set Blue LED Off',
			setGreenOff: 'Set Green LED Off',
			setColorHex: 'Set Color (RGB Hex)',
			setBrightnessMedium: 'Set Brightness (Medium)',
			setBrightnessHigh: 'Set Brightness (High)',
			setBrightnessOff: 'Set Brightness (Off)',
			setBrightnessLow: 'Set Brightness (Low)',
			setColorPreset: 'Set Color to Preset',
			colorSet: 'Color Set',
			brightnessSet: 'Brightness Set',
		},
	},
);
