function WidgetMenu(id, api, parentWidget, options) {
	WidgetBase.call(this, id, api, parentWidget, options);

	var currentWidget = this;

	this._outsideClickListener = function(event) {
		currentWidget._onOutsideClick(event);
	};

	this.setSelectableEntries(this.getOptions().selectableMenuEntries);
	this.setMultiSelectableEntries(this.getOptions().multiSelectableEntries);
}

WidgetMenu.prototype = Object.create(WidgetBase.prototype);
WidgetMenu.prototype.constructor = WidgetMenu;

WidgetMenu.prototype._onOutsideClick = function(event) {
	if (this.getOptions().disableAutoHide) {
		return;
	}

	if (!this.getContainer().has($(event.target)).length) {
		this.hide();
	}
};

WidgetMenu.prototype.show = function() {
	var currentWidget = this;

	// Hide all other dashboard option menus
	// before showing this one. Refer to
	// WidgetPanelOptionsBase.js for more
	this.getMainContainer()
		.getContainer()
		.find('.WidgetPanelOptionsBase-OptionsMenu')
		.hide();

	WidgetBase.prototype.show.call(this);

	document.body.removeEventListener('click', this._outsideClickListener);
	document.body.addEventListener(
		'click',
		currentWidget._outsideClickListener,
	);
};

WidgetMenu.prototype.hide = function() {
	WidgetBase.prototype.hide.call(this);

	document.body.removeEventListener('click', this._outsideClickListener);
};

WidgetMenu.prototype.remove = function(callback) {
	document.body.removeEventListener('click', this._outsideClickListener);

	WidgetBase.prototype.remove.call(this, callback);
};

WidgetMenu.prototype.getSelectedValues = function() {
	//Find all the selected menu entries.
	var entries = this.getContainer().find('*[data-menuValue][selected]');

	var values = [];

	entries.each(function() {
		values.push(
			JSON.parse(decodeURIComponent($(this).attr('data-menuValue'))),
		);
	});

	return values;
};

WidgetMenu.prototype.clearSelections = function() {
	var selectedItems = this.getContainer().find('[selected]');

	for (var j = 0; j < selectedItems.length; j++) {
		$(selectedItems[j]).removeAttr('selected');
	}
};

WidgetMenu.prototype._onMenuEntryClick = function(menuEntry) {
	var currentWidget = this;

	var value = JSON.parse(
		decodeURIComponent(menuEntry.attr('data-menuValue')),
	);

	if (this._selectableMenuEntries) {
		var selectedItems = menuEntry.siblings('[selected]');

		if (!this._multiSelectableEntries) {
			for (var j = 0; j < selectedItems.length; j++) {
				$(selectedItems[j]).removeAttr('selected');
			}
		}

		if (menuEntry.attr('selected') === 'selected') {
			menuEntry.removeAttr('selected');
		} else {
			menuEntry.attr('selected', 'selected');
		}
	}

	if (!this._multiSelectableEntries) {
		this.event('menuEntryClicked', value);
	} else {
		this.event('menuEntryClicked', this.getSelectedValues());
	}
};

WidgetMenu.prototype.setMultiSelectableEntries = function(multiSelectable) {
	this._multiSelectableEntries = Boolean(multiSelectable);
};

WidgetMenu.prototype.setSelectableEntries = function(selectable) {
	this._selectableMenuEntries = Boolean(selectable);
	return;
};

WidgetMenu.prototype.setMenu = function(entries, title, footer) {
	/*
	 * This function renders and set's the menu
	 * entries for the menu, the following format
	 * is used
	 *
	 * this.setMenu([
	 * 	{label:'Stuff', value:'stuff'},
	 * 	{label:'Object', value:{obj:23}}
	 * ]);
	 */

	var currentWidget = this;

	title = title || null;
	footer = footer || null;

	var dataEntries = [];

	for (var i = 0; i < entries.length; i++) {
		dataEntries.push({
			label: entries[i].label,
			jsonValue: encodeURIComponent(JSON.stringify(entries[i].value)),
		});
	}

	this.renderTemplate(
		{
			labelTitle: title,
			labelFooter: footer,
			entries: dataEntries,
			showTitle: title !== null,
			showFooter: footer !== null,
		},
		WidgetMenu.name,
	);

	this.getContainer()
		.find('.WidgetMenu-Entry')
		.each(function() {
			$(this).click(function(e) {
				// If the user clicks directly on an href, navigate directly to that instead of the normal menu entry
				// This is used by the Notifications Menu, if the notification itself contains a link
				if (e.target.href) {
					window.location.href = e.target.href;
					currentWidget.event('menuHrefHandled');
				} else {
					currentWidget._onMenuEntryClick($(this));
				}
			});
		});

	document.body.removeEventListener('click', this._outsideClickListener);
	document.body.addEventListener('click', this._outsideClickListener);
};

WidgetMenu.prototype.language = deepAssign({}, WidgetBase.prototype.language, {
	'en-US': {
		name: 'Menu',
	},
});
