function WidgetUserAccountOverview(id, api, parent, options) {
	WidgetBase.call(this, id, api, parent, options);

	// ID Assignment
	this.userAccountGeneralUsageWidgetContainerId = this.generateChildId(
		'userAccountGeneralUsageWidgetContainerId',
	);
}

WidgetUserAccountOverview.prototype = Object.create(WidgetBase.prototype);
WidgetUserAccountOverview.prototype.constructor = WidgetUserAccountOverview;

WidgetUserAccountOverview.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.render(function(err, data) {
		this.addChildWidget(
			WidgetUserAccountOverviewGeneralUsage,
			this.userAccountGeneralUsageWidgetContainerId,
			{},
			function(err, userAccountGeneralUsageWidget) {
				WidgetBase.prototype.initialize.call(this, callback);
			},
		);
	});
};

WidgetUserAccountOverview.prototype.getTemplateContext = function(callback) {
	var currentWidget = this;

	callback = callback || function() {};

	var context = {};

	context.rootIds = {
		userAccountGeneralUsageWidgetContainerId: this
			.userAccountGeneralUsageWidgetContainerId,
	};

	callback.call(this, false, context);
	return context;
};

WidgetUserAccountOverview.prototype.render = function(callback) {
	var currentWidget = this;

	callback = callback || function() {};

	var context = this.getTemplateContext(function() {});
	var templateName = this.constructor.name;
	var container = this.getContainer();

	this.renderTemplate(context, templateName, container);

	callback.call(this, false, null);
	return;
};

WidgetUserAccountOverview.prototype.ICON = './Resources/icons/Overview.svg';

WidgetUserAccountOverview.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'WidgetUserAccountOverview',
		},
	},
);

WidgetUserAccountOverview.prototype.$_$ = function(done) {
	//Testing function for WidgetUserAccountOverview

	this.$_SetupWidgetTest(function(err, currentWidget) {
		WidgetBase.prototype.$_$.call(this, done);
	});
};
