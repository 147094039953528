function BLECentralBackendPhonegap() {
	BLECentralBackendBase.call(this);

	console.log('BLECentralBackendPhonegap()');

	this._isEnabled = false;
	this._localNameServiceUuidMapping = {};
	this._address = null;
	this._discovered = {};
	this._removed = false;

	bluetoothle.initialize(
		function(retObject) {
			console.log(
				'bluetoothle.initialize(): ' + JSON.stringify(retObject),
			);

			if (retObject.status === 'enabled') {
				this._isEnabled = true;
			} else {
				this._isEnabled = false;
			}
		},
		{
			request: true,
			statusReceiver: true,
			restoreKey:
				'com.atmosphereiot.atmosphere.BLECentralBackendPhonegap',
		},
	);
}

BLECentralBackendPhonegap.prototype = Object.create(
	BLECentralBackendBase.prototype,
);
BLECentralBackendPhonegap.prototype.constructor = BLECentralBackendPhonegap;

BLECentralBackendPhonegap.prototype.IS_AVAILABLE = function() {
	return isPhonegap() && bluetoothle !== undefined;
};

BLECentralBackendPhonegap.prototype.connectToDevice = function(
	thingUuid,
	callback,
) {
	this.connectToDeviceByServiceUuid(thingUuid, callback);
};

BLECentralBackendPhonegap.prototype.connectToDeviceByServiceUuid = function(
	primaryServiceUuid,
	callback,
) {
	var currentBackend = this;

	var completedCallback = false;

	if (this._localNameServiceUuidMapping[primaryServiceUuid] === undefined) {
		this._startScanning(
			false,
			true,
			function(err, data) {
				if (currentBackend._removed || completedCallback) {
					return;
				}

				console.log(
					'this._startScanning event callback: ' +
						JSON.stringify(data),
				);

				if (data.primaryServiceUuid === primaryServiceUuid) {
					completedCallback = true;

					primaryServiceUuid = null;
					currentBackend.stopScanning(function(err) {
						currentBackend.connectToDeviceByAddress(
							data.address,
							callback,
						);
					});
					return;
				}

				return;
			},

			function(err) {
				if (completedCallback) {
					return;
				}

				console.log(
					'this._startScanning callback: ' + JSON.stringify(err),
				);

				if (err) {
					completedCallback = true;

					callback.call(currentBackend, err, null);
					return;
				}
			},
		);

		return;
	}

	var currentData = this._localNameServiceUuidMapping[primaryServiceUuid];

	this.connectToDeviceByAddress(currentData.address, callback);
	return;
};

BLECentralBackendPhonegap.prototype._close = function(address, callback) {
	var currentBackend = this;

	var completedCallback = false;

	setTimeout(function() {
		bluetoothle.close(
			function(retObject) {
				if (completedCallback) {
					return;
				}

				completedCallback = true;

				console.log(
					'bluetoothle.close success callback: ' +
						JSON.stringify(retObject),
				);

				currentBackend.event('disconnected', { address: address });

				callback.call(currentBackend, false);
				return;
			},

			function(err) {
				if (completedCallback) {
					return;
				}

				completedCallback = true;

				console.log(
					'bluetoothle.close error callback: ' + JSON.stringify(err),
				);

				currentBackend.event('disconnected', { address: address });

				callback.call(currentBackend, err);
				return;
			},

			{
				address: address,
			},
		);
	}, 100);
};

BLECentralBackendPhonegap.prototype._disconnectAndClose = function(
	address,
	callback,
) {
	var currentBackend = this;

	this.stopScanning(function(err) {
		setTimeout(function() {
			bluetoothle.isConnected(
				function(retObject) {
					console.log(
						'bluetoothle.isConnected success callback: ' +
							JSON.stringify(retObject),
					);

					if (retObject.isConnected === false) {
						callback.call(currentBackend, {
							type: 'isNotConnected',
						});
						return;
					}

					bluetoothle.disconnect(
						function(retObject) {
							console.log(
								'bluetoothle.disconnect success callback: ' +
									JSON.stringify(retObject),
							);

							currentBackend._close(address, callback);
							return;
						},

						function(err) {
							console.log(
								'bluetoothle.disconnect error callback: ' +
									JSON.stringify(err),
							);

							if (err.error === 'isDisconnected') {
								currentBackend._close(address, callback);
								return;
							}

							callback.call(currentBackend, err);
							return;
						},

						{
							address: address,
						},
					);
				},

				function(err) {
					console.log(
						'bluetoothle.isConnected error callback: ' +
							JSON.stringify(err),
					);

					callback.call(currentBackend, err);
					return;
				},

				{
					address: address,
				},
			);
		}, 100);
	});
};

BLECentralBackendPhonegap.prototype._iOSDiscover = function(address, callback) {
	console.log('_iOSDiscover(' + address + ')');

	var currentBackend = this;

	setTimeout(function() {
		bluetoothle.services(
			function(retObject) {
				console.log(
					'bluetoothle.services success callback: ' +
						JSON.stringify(retObject),
				);

				if (retObject.status !== 'services') {
					return;
				}

				var foundServices = retObject.services.slice();

				function findServiceCharacteristicHelper() {
					if (foundServices.length <= 0) {
						currentBackend._discovered[address] = true;
						callback.call(currentBackend, false);
						return;
					}

					var currentServiceUuid = foundServices.shift();

					bluetoothle.characteristics(
						function(retObject) {
							console.log(
								'bluetoothle.characteristics success callback: ' +
									JSON.stringify(retObject),
							);

							if (retObject.status !== 'characteristics') {
								return;
							}

							findServiceCharacteristicHelper();
						},

						function(err) {
							console.log(
								'bluetoothle.characteristics error callback: ' +
									JSON.stringify(err),
							);
							callback.call(currentBackend, err);
							return;
						},

						{
							address: address,
							service: currentServiceUuid,
						},
					);
				}

				findServiceCharacteristicHelper();
			},

			function(err) {
				console.log(
					'bluetoothle.services error callback: ' +
						JSON.stringify(err),
				);
				callback.call(currentBackend, err);
				return;
			},

			{
				address: address,
				services: [],
			},
		);
	}, 100);
};

BLECentralBackendPhonegap.prototype._androidSetMTU = function(
	address,
	mtu,
	callback,
) {
	console.log('_androidSetMTU(' + address + ')');

	var currentBackend = this;

	var completedCallback = false;

	setTimeout(function() {
		bluetoothle.mtu(
			function(retObject) {
				if (completedCallback) {
					return;
				}

				console.log(
					'bluetoothle.mtu success callback: ' +
						JSON.stringify(retObject),
				);

				completedCallback = true;
				callback.call(currentBackend, false);
				return;
			},

			function(err) {
				if (completedCallback) {
					return;
				}

				console.log(
					'bluetoothle.mtu error callback: ' + JSON.stringify(err),
				);

				completedCallback = true;
				callback.call(currentBackend, err);
				return;
			},

			{
				address: address,
				mtu: mtu,
			},
		);
	}, 100);
};

BLECentralBackendPhonegap.prototype._androidDiscover = function(
	address,
	callback,
) {
	console.log('_androidDiscover(' + address + ')');

	var currentBackend = this;

	setTimeout(function() {
		bluetoothle.discover(
			function(retObject) {
				console.log(
					'bluetoothle.discover success callback: ' +
						JSON.stringify(retObject),
				);

				currentBackend._discovered[address] = true;
				callback.call(currentBackend, false);
				return;
			},

			function(err) {
				console.log(
					'bluetoothle.discover error callback: ' +
						JSON.stringify(err),
				);

				currentBackend._discovered[address] = false;
				callback.call(currentBackend, err);
				return;
			},

			{
				address: address,
				clearCache: false,
			},
		);
	}, 600);
};

BLECentralBackendPhonegap.prototype._androidStartScanningForConnect = function(
	callback,
) {
	var currentBackend = this;

	this.isScanning(function(err, scanning) {
		if (scanning) {
			callback.call(currentBackend, false);
			return;
		}

		var completedCallback = false;

		bluetoothle.startScan(
			function(retObject) {
				if (completedCallback) {
					return;
				}

				console.log(
					'_androidStartScanningForConnect: bluetoothle.startScan success callback: ' +
						JSON.stringify(retObject),
				);

				if (retObject.status === 'scanStarted') {
					completedCallback = true;
					callback.call(currentBackend, false);
					return;
				}
			},

			function(err) {
				if (completedCallback) {
					return;
				}

				console.log(
					'_androidStartScanningForConnect: bluetoothle.startScan error callback: ' +
						JSON.stringify(err),
				);

				completedCallback = true;
				callback.call(currentBackend, err);
				return;
			},

			{
				services: [],
			},
		);
	});
};

BLECentralBackendPhonegap.prototype._androidConnectToDeviceByAddress = function(
	address,
	callback,
) {
	var currentBackend = this;

	console.log('_androidConnectToDeviceByAddress(' + address + ')');
	this._discovered[address] = false;

	this._androidStartScanningForConnect(function(err) {
		setTimeout(function() {
			currentBackend.event('connecting', {});

			var completedCallback = false;

			bluetoothle.connect(
				function(retObject) {
					if (completedCallback) {
						return;
					}

					console.log(
						'bluetoothle.connect success callback: ' +
							JSON.stringify(retObject),
					);

					if (retObject.status === 'disconnected') {
						completedCallback = true;
						callback.call(currentBackend, {
							type: 'disconnected',
							retObject: retObject,
						});
						return;
					} else if (retObject.status === 'connected') {
						if (device.platform == 'Android') {
							currentBackend._androidDiscover(address, function(
								err,
							) {
								if (err) {
									completedCallback = true;
									callback.call(currentBackend, err);
									return;
								}

								currentBackend._androidSetMTU(
									address,
									128,
									function(err) {
										bluetoothle.stopScan(
											function(retObject) {
												if (completedCallback) {
													return;
												}

												console.log(
													'_androidConnectToDeviceByAddress: bluetoothle.stopScan success callback: ' +
														JSON.stringify(
															retObject,
														),
												);

												currentBackend.event(
													'connected',
													{},
												);

												completedCallback = true;
												callback.call(
													currentBackend,
													false,
												);
												return;
											},

											function(err) {
												if (completedCallback) {
													return;
												}

												console.log(
													'_androidConnectToDeviceByAddress: bluetoothle.stopScan error callback: ' +
														JSON.stringify(
															retObject,
														),
												);

												currentBackend.event(
													'connected',
													{},
												);

												completedCallback = true;
												callback.call(
													currentBackend,
													false,
												);
												return;
											},
										);
									},
								);
							});
						} else {
							completedCallback = true;
							callback.call(currentBackend, {
								type: 'bleFunctionNotSupported',
							});
							return;
						}
					}
				},

				function(err) {
					if (completedCallback) {
						return;
					}

					console.log(
						'bluetoothle.connect error callback: ' +
							JSON.stringify(err),
					);

					completedCallback = true;

					currentBackend._close(address, function(closeErr) {
						callback.call(currentBackend, err);
						return;
					});
				},

				{
					address: address,
					autoConnect: false,
				},
			);
		}, 100);
	});
};

BLECentralBackendPhonegap.prototype._connectToDeviceByAddress = function(
	address,
	callback,
) {
	var currentBackend = this;

	console.log('_connectToDeviceByAddress(' + address + ')');

	if (device.platform == 'Android') {
		this._androidConnectToDeviceByAddress(address, callback);
		return;
	}

	this._discovered[address] = false;

	setTimeout(function() {
		currentBackend.event('connecting', {});

		var completedCallback = false;

		bluetoothle.connect(
			function(retObject) {
				if (completedCallback) {
					return;
				}

				console.log(
					'bluetoothle.connect success callback: ' +
						JSON.stringify(retObject),
				);

				if (retObject.status === 'disconnected') {
					completedCallback = true;
					callback.call(
						currentBackend,
						{ type: 'disconnected', retObject: retObject },
						address,
					);
					return;
				} else if (retObject.status === 'connected') {
					if (device.platform == 'Android') {
						currentBackend._androidDiscover(address, function(err) {
							if (err) {
								completedCallback = true;
								callback.call(currentBackend, err, address);
								return;
							}

							currentBackend.event('connected', {});

							completedCallback = true;
							callback.call(currentBackend, false, address);
							return;
						});
					} else if (device.platform === 'iOS') {
						currentBackend._iOSDiscover(address, function(err) {
							if (err) {
								completedCallback = true;
								callback.call(currentBackend, err, address);
								return;
							}

							currentBackend.event('connected', {});

							completedCallback = true;
							callback.call(currentBackend, false, address);
							return;
						});
					} else {
						completedCallback = true;
						callback.call(
							currentBackend,
							{ type: 'bleFunctionNotSupported' },
							address,
						);
						return;
					}
				}
			},

			function(err) {
				if (completedCallback) {
					return;
				}

				console.log(
					'bluetoothle.connect error callback: ' +
						JSON.stringify(err),
				);

				completedCallback = true;

				currentBackend._close(address, function(closeErr) {
					callback.call(currentBackend, err, address);
					return;
				});
			},

			{
				address: address,
				autoConnect: false,
			},
		);
	}, 100);
};

BLECentralBackendPhonegap.prototype.connectToDeviceByAddress = function(
	address,
	callback,
) {
	console.log('connectToDeviceByAddress(' + address + ')');

	var currentBackend = this;

	this._address = address;

	var numberOfRetries = 0;
	var error = false;

	this.stopScanning(function(err) {
		function _connectToDeviceByAddressHelper() {
			var completedCallback = false;

			if (numberOfRetries >= 5 || currentBackend._removed) {
				currentBackend.event('disconnected', { address: address });

				if (!completedCallback) {
					completedCallback = true;
					callback.call(currentBackend, error, address);
				}

				return;
			}

			currentBackend._connectToDeviceByAddress(address, function(err) {
				console.log(
					'this._connectToDeviceByAddress callback: ' +
						JSON.stringify(err),
				);

				error = err;

				if (err) {
					numberOfRetries++;

					if (!completedCallback) {
						completedCallback = true;
						setTimeout(function() {
							_connectToDeviceByAddressHelper();
							return;
						}, 100);
					}
				} else {
					if (!completedCallback) {
						completedCallback = true;
						setTimeout(function() {
							callback.call(currentBackend, false, address);

							return;
						}, 100);
					}
				}
			});
		}

		_connectToDeviceByAddressHelper();
	});
};

BLECentralBackendPhonegap.prototype.disconnectFromDeviceByPrimaryServiceUuid = function(
	primaryServiceUuid,
	callback,
) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendPhonegap.prototype.disconnectFromDeviceByAddress = function(
	address,
	callback,
) {
	var currentBackend = this;

	address = address || this._address;

	this._disconnectAndClose(address, callback);
};

BLECentralBackendPhonegap.prototype.read = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	callback,
) {
	// 	console.log('BLECentralBackendPhonegap.prototype.read(' + address + ', ' + bleServiceUuid + ', ' + bleCharacteristicUuid + ')');

	var currentBackend = this;
	var completedCallback = false;

	address = address || this._address;

	if (address === null) {
		callback.call(currentBackend, { type: 'notConnected' });
		return;
	}

	bluetoothle.isConnected(
		function(retObject) {
			// 		console.log('bluetoothle.isConnected: ' + JSON.stringify(retObject));

			if (
				retObject.isConnected === false ||
				!currentBackend._discovered[address]
			) {
				if (!completedCallback) {
					callback.call(currentBackend, { type: 'notConnected' });

					//If the device isn't in the list of discovered devices,
					//then it wasn't really connected.
					if (currentBackend._discovered[address]) {
						currentBackend.event('disconnected', {
							address: address,
						});
					}
				}

				completedCallback = true;

				return;
			}

			bluetoothle.read(
				function(retObject) {
					// 				console.log("bluetoothle.read success callback: " + JSON.stringify(retObject));

					if (
						retObject.service.toLowerCase() !== bleServiceUuid ||
						retObject.characteristic.toLowerCase() !==
							bleCharacteristicUuid
					) {
						return;
					}

					var data = null;

					if (
						retObject.value !== undefined &&
						retObject.value !== null
					) {
						data = Array.from(atob(retObject.value), (c) =>
							c.charCodeAt(0),
						);
					}

					if (!completedCallback) {
						callback.call(currentBackend, false, data);
						completedCallback = true;
					}

					return;
				},

				function(err) {
					console.log(
						'bluetoothle.read error callback: ' +
							JSON.stringify(err),
					);

					if (!completedCallback) {
						callback.call(currentBackend, err, null);
						completedCallback = true;
					}

					return;
				},

				{
					address: address,
					service: bleServiceUuid,
					characteristic: bleCharacteristicUuid,
				},
			);
		},

		function(err) {},

		{ address: address },
	);
};

BLECentralBackendPhonegap.prototype.write = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	rawValue,
	callback,
) {
	// 	console.log('BLECentralBackendPhonegap.prototype.write(' + address + ', ' + bleServiceUuid + ', ' + bleCharacteristicUuid + ', ' + rawValue + ')');

	var currentBackend = this;

	address = address || this._address;

	if (address === null) {
		callback.call(currentBackend, { type: 'notConnected' });
		return;
	}

	var data = new Uint8Array(rawValue);
	var b64Value = bluetoothle.bytesToEncodedString(data);

	var completedCallback = false;

	bluetoothle.isConnected(
		function(retObject) {
			if (
				retObject.isConnected === false ||
				!currentBackend._discovered[address]
			) {
				callback.call(currentBackend, { type: 'notConnected' });
				return;
			}

			bluetoothle.write(
				function(retObject) {
					// 				console.log("bluetoothle.write success callback: " + JSON.stringify(retObject));

					if (
						retObject.service.toLowerCase() !== bleServiceUuid ||
						retObject.characteristic.toLowerCase() !==
							bleCharacteristicUuid
					) {
						return;
					}

					if (!completedCallback) {
						completedCallback = true;
						callback.call(currentBackend, false);
					}
				},

				function(err) {
					console.log(
						'bluetoothle.write error callback: ' +
							JSON.stringify(err),
					);

					if (!completedCallback) {
						completedCallback = true;
						callback.call(currentBackend, err);
					}
				},

				{
					value: b64Value,
					address: address,
					service: bleServiceUuid,
					characteristic: bleCharacteristicUuid,
				},
			);
		},

		function(err) {},

		{ address: address },
	);
};

BLECentralBackendPhonegap.prototype.writeWithoutResponse = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	rawValue,
	callback,
) {
	var currentBackend = this;

	address = address || this._address;

	if (address === null) {
		callback.call(currentBackend, { type: 'notConnected' });
		return;
	}

	var data = new Uint8Array(rawValue);
	var b64Value = bluetoothle.bytesToEncodedString(data);

	var completedCallback = false;

	bluetoothle.isConnected(
		function(retObject) {
			if (
				retObject.isConnected === false ||
				!currentBackend._discovered[address]
			) {
				callback.call(currentBackend, { type: 'notConnected' });
				return;
			}

			bluetoothle.write(
				function(retObject) {
					// 				console.log("bluetoothle.write (without response) success callback: " + JSON.stringify(retObject));

					if (
						retObject.service.toLowerCase() !== bleServiceUuid ||
						retObject.characteristic.toLowerCase() !==
							bleCharacteristicUuid
					) {
						return;
					}

					if (!completedCallback) {
						completedCallback = true;
						callback.call(currentBackend, false);
					}
				},

				function(err) {
					console.log(
						'bluetoothle.write (without response) error callback: ' +
							JSON.stringify(err),
					);

					if (!completedCallback) {
						completedCallback = true;
						callback.call(currentBackend, err);
					}
				},

				{
					value: b64Value,
					address: address,
					service: bleServiceUuid,
					characteristic: bleCharacteristicUuid,
					type: 'noResponse',
				},
			);
		},

		function(err) {},

		{ address: address },
	);
};

BLECentralBackendPhonegap.prototype.subscribe = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	onDataCallback,
	callback,
) {
	var currentBackend = this;

	address = address || this._address;

	if (!currentBackend._discovered[address]) {
		callback.call(currentBackend, { type: 'notConnected' });
		return;
	}

	var completedCallback = false;

	bluetoothle.subscribe(
		function(retObject) {
			// 			console.log("bluetoothle.subscribe dataCallback: " + JSON.stringify(retObject));

			if (retObject.status === 'subscribedResult') {
				data = Array.from(atob(retObject.value), (c) =>
					c.charCodeAt(0),
				);
				onDataCallback(false, data);
			} else if (!completedCallback) {
				completedCallback = true;
				callback.call(currentBackend, false);
				return;
			}
		},

		function(err) {
			if (completedCallback) {
				return;
			}

			completedCallback = true;

			callback.call(currentBackend, err);
			return;
		},

		{
			address: address,
			service: bleServiceUuid,
			characteristic: bleCharacteristicUuid,
		},
	);
};

BLECentralBackendPhonegap.prototype.unsubscribe = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	callback,
) {
	var currentBackend = this;

	address = address || this._address;

	if (!currentBackend._discovered[address]) {
		callback.call(currentBackend, { type: 'notConnected' });
		return;
	}

	var completedCallback = false;

	bluetoothle.unsubscribe(
		function(retObject) {
			if (!completedCallback) {
				completedCallback = true;
				callback.call(currentBackend, false);
			}
			return;
		},

		function(err) {
			if (!completedCallback) {
				completedCallback = true;
				callback.call(currentBackend, err);
			}

			return;
		},

		{
			address: address,
			service: bleServiceUuid,
			characteristic: bleCharacteristicUuid,
		},
	);
};

BLECentralBackendBase.prototype.isScanning = function(callback) {
	var currentBackend = this;
	var completedCallback = false;

	bluetoothle.isScanning(function(retObject) {
		if (!completedCallback) {
			completedCallback = true;
			callback.call(currentBackend, false, retObject.isScanning);
		}
	});
};

BLECentralBackendPhonegap.prototype._advertismentDataParser = function(data) {
	var retData = {};

	var raw = window.atob(data);
	var rawLength = raw.length;
	var rawBufferData = new Uint8Array(new ArrayBuffer(rawLength));

	for (i = 0; i < rawLength; i++) {
		rawBufferData[i] = raw.charCodeAt(i);
	}

	var i = 0;

	while (true) {
		if (i + 1 >= rawBufferData.length) {
			break;
		}

		var currentLen = rawBufferData[i];
		var currentType = rawBufferData[i + 1];

		if (i + currentLen >= rawBufferData.length) {
			break;
		}

		switch (currentType) {
			case 8: //Local Name
				var firstLocalNamePosition = i + 2;
				var lastLocalNamePosition = i + currentLen;
				var localName = '';

				for (
					j = firstLocalNamePosition;
					j < lastLocalNamePosition;
					j++
				) {
					localName += String.fromCharCode(rawBufferData[j]);
				}

				retData.localName = localName;

				break;

			// FIXME: This currently just reads the first UUID on the list.
			case 7: //Complete Service Uuid List
				var currentUuidPosition = i + 2;
				var lastUuidPosition = i + currentLen + 1;
				var primaryServiceUuid = '';

				for (
					j = currentUuidPosition;
					j < currentUuidPosition + 16;
					j++
				) {
					primaryServiceUuid =
						('00' + rawBufferData[j].toString(16)).slice(-2) +
						primaryServiceUuid;

					if (
						j === currentUuidPosition + 5 ||
						j === currentUuidPosition + 7 ||
						j === currentUuidPosition + 9 ||
						j === currentUuidPosition + 11
					) {
						primaryServiceUuid = '-' + primaryServiceUuid;
					}
				}

				retData.primaryServiceUuid = primaryServiceUuid;

				break;

			case 6: //Incomplete Service Uuid List
				var firstUuidPosition = i + 2;
				var lastUuidPosition = i + currentLen + 1;
				var primaryServiceUuid = '';

				for (j = firstUuidPosition; j < lastUuidPosition; j++) {
					primaryServiceUuid =
						('00' + rawBufferData[j].toString(16)).slice(-2) +
						primaryServiceUuid;

					if (
						j === firstUuidPosition + 5 ||
						j === firstUuidPosition + 7 ||
						j === firstUuidPosition + 9 ||
						j === firstUuidPosition + 11
					) {
						primaryServiceUuid = '-' + primaryServiceUuid;
					}
				}

				retData.primaryServiceUuid = primaryServiceUuid;

				break;
		}

		i = i + currentLen + 1;
	}

	return retData;
};

BLECentralBackendPhonegap.prototype._startScanning = function(
	sendEvents,
	requestPermission,
	eventCallback,
	callback,
) {
	var currentBackend = this;

	sendEvents = sendEvents || false;

	this._localNameServiceUuidMapping = {};

	//Request permission not supported on iOS so no reason to bother
	if (requestPermission && device.platform !== 'iOS') {
		bluetoothle.requestPermission(
			function(retObject) {
				currentBackend._startScanning(
					true,
					false,
					eventCallback,
					callback,
				);
				return;
			},

			function(err) {
				currentBackend._startScanning(
					true,
					false,
					eventCallback,
					callback,
				);
			},
		);

		return;
	}

	currentBackend.stopScanning(function(err) {
		if (err && err.message !== 'Not scanning') {
			console.log("startScanning(): Couldn't stop scanning...");
			console.log(JSON.stringify(err));

			if (err.message === 'Bluetooth not initialized') {
				currentBackend.event('bluetoothNotEnabled', {});
			}

			callback.call(currentBackend, err);
			return;
		}

		console.log('startScanning()');

		bluetoothle.startScan(
			function(retObject) {
				console.log(
					'bluetoothle.startScan success callback: ' +
						JSON.stringify(retObject),
				);

				if (retObject.status === 'scanStarted') {
					currentBackend.event('scanningStarted', {});

					callback.call(currentBackend, false);
					return;
				}

				if (retObject.status !== 'scanResult') {
					return;
				}

				var primaryServiceUuid = null;
				var serviceUuids = [];
				var txPowerLevel = 0;
				var manufacturerData = null;

				if (
					retObject.advertisement !== undefined &&
					retObject.advertisement.serviceUuids !== undefined
				) {
					if (retObject.advertisement.serviceUuids.length > 0) {
						primaryServiceUuid = retObject.advertisement.serviceUuids[0].toLowerCase();
					}

					serviceUuids = retObject.advertisement.serviceUuids;
					txPowerLevel = retObject.advertisement.txPowerLevel;

					if (
						retObject.advertisement.manufacturerData !== undefined
					) {
						manufacturerData = Array.from(
							atob(retObject.advertisement.manufacturerData),
							(c) => c.charCodeAt(0),
						);
					}
				}

				if (device.platform == 'Android') {
					var parseAdvData = currentBackend._advertismentDataParser(
						retObject.advertisement,
					);

					primaryServiceUuid = parseAdvData.primaryServiceUuid;
				}

				var localName = retObject.name;

				if (
					retObject.advertisement !== undefined &&
					retObject.advertisement.localName !== undefined
				) {
					localName = retObject.advertisement.localName;
				}

				var currentEventData = {
					address: retObject.address,
					mac: retObject.address,
					connectable: true,
					rssi: retObject.rssi,
					localName: localName,
					primaryServiceUuid: primaryServiceUuid,
					serviceUuids: serviceUuids,
					manufacturerData: manufacturerData,
					txPowerLevel: txPowerLevel,
				};

				currentBackend._localNameServiceUuidMapping[
					primaryServiceUuid
				] = currentEventData;

				if (sendEvents) {
					currentBackend.event('advertisement', currentEventData);
				}

				if (eventCallback !== null && eventCallback !== undefined) {
					eventCallback.call(currentBackend, false, currentEventData);
				}
			},

			function(err) {
				console.log(
					'bluetoothle.startScan error callback: ' +
						JSON.stringify(err),
				);

				callback.call(currentBackend, err);
				return;
			},

			{
				services: [],
			},
		);
	});
};

BLECentralBackendPhonegap.prototype.startScanning = function(callback) {
	var currentBackend = this;

	this.isScanning(function(err, scanning) {
		if (scanning) {
			callback.call(this, false);
			return;
		}

		this._startScanning(true, true, null, callback);
		return;
	});
};

BLECentralBackendPhonegap.prototype.stopScanning = function(callback) {
	console.log('stopScanning()');

	var currentBackend = this;

	this.isScanning(function(err, scanning) {
		if (!scanning) {
			callback.call(this, false);
			return;
		}

		bluetoothle.stopScan(
			function(retObject) {
				console.log(
					'bluetoothle.stopScan callback: ' +
						JSON.stringify(retObject),
				);

				if (device.platform == 'Android') {
					setTimeout(function() {
						currentBackend.event('scanningStopped', {});

						callback.call(currentBackend, false);
						return;
					}, 500);

					return;
				}

				currentBackend.event('scanningStopped', {});

				callback.call(currentBackend, false);
				return;
			},

			function(err) {
				currentBackend.event('scanningStopped', {});

				callback.call(currentBackend, err);
				return;
			},
		);
	});
};

BLECentralBackendPhonegap.prototype.getDiscovered = function(callback) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendPhonegap.prototype.getServices = function(address, callback) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendPhonegap.prototype.getServiceCharacteristics = function(
	address,
	serviceUuid,
	callback,
) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendPhonegap.prototype.remove = function(callback) {
	this._removed = true;

	this.stopScanning(function(err) {
		if (this._address !== null) {
			this.disconnectFromDeviceByAddress(this._address, function(err) {
				this._address = null;

				callback.call(this, false);
				return;
			});
		} else {
			callback.call(this, false);
			return;
		}
	});
};
