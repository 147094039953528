function WidgetProjectManager(id, api, parent, options) {
	const currentWidget = this;

	WidgetBase.call(this, id, api, parent, options);
	EventListener.call(this);

	this.newProjectButtonId = this.generateChildId('_newProjectButton');
	this.importProjectButtonId = this.generateChildId('_importProjectButton');
	this.importProjectCardId = this.generateChildId('_importProjectCardId');
	this.projectsTableContainerId = this.generateChildId(
		'projectsTableContainer',
	);

	this.templateWhiteList = {};

	this.openProjectButtonToProjectUuid = {};
	this.infoProjectButtonToProjectUuid = {};
	this.exportProjectButtonToProjectUuid = {};
	this.removeProjectButtonToProjectUuid = {};
	this.addNewButtonToProjectTemplate = {};

	this.getContainer().on('dragover', function(event) {
		event.preventDefault();
		event.stopPropagation();
		$(this).addClass('WidgetProjectManager-Dragging');
	});

	this.getContainer().on('dragleave', function(event) {
		event.preventDefault();
		event.stopPropagation();
		$(this).removeClass('WidgetProjectManager-Dragging');
	});

	this.getContainer().on('drop', function(e) {
		e.preventDefault();
		e.stopPropagation();
		currentWidget.onDrop(e);
		$(this).removeClass('WidgetProjectManager-Dragging');
	});
}

WidgetProjectManager.prototype = Object.create(WidgetBase.prototype);
WidgetProjectManager.prototype = Object.assign(
	WidgetProjectManager.prototype,
	EventListener.prototype,
);
WidgetProjectManager.prototype.constructor = WidgetProjectManager;

WidgetProjectManager.prototype.initialize = function(callback) {
	this.update(function(err) {
		WidgetBase.prototype.initialize.call(this, callback);
	});
};

WidgetProjectManager.prototype.remove = function(callback) {
	//AI-1159: Make sure the modal is hiden in case we are removed for some reason.
	this.getMainContainer().closeModal();

	WidgetBase.prototype.remove.call(this, callback);
};

WidgetProjectManager.prototype.onNewProjectClicked = function() {
	this.addProject([ProjectControllerCloud], function() {});
};

WidgetProjectManager.prototype.onOpenProjectClicked = function(button) {
	const project = this.openProjectButtonToProjectUuid[button.id];
	this.event('openProject', {
		projectId: project.id,
		projectUuid: project.uuid,
	});

	this.getMainContainer().setLocation(LocationProjectEditor, {
		projectId: project.id,
		projectUuid: project.uuid,
		org: getHashCommand().org,
	});
};

WidgetProjectManager.prototype.onNewFromTemplateClicked = function(button) {
	const currentTemplate = this.addNewButtonToProjectTemplate[button.id];
	const planes = [];
	for (let i = 0; i < currentTemplate.planes.length; i++) {
		planes.push(currentTemplate.planes[i].controller);
	}
	this.addProject(planes, function() {});
};

WidgetProjectManager.prototype.onImportProjectClicked = function(button) {
	const currentWidget = this;
	this.importProject(function(err) {
		if (err && err.type) {
			currentWidget
				.getMainContainer()
				.showErrorMessage(
					getLanguageTag(WidgetProjectManager, err.type),
				);
		} else if (err) {
			currentWidget
				.getMainContainer()
				.showErrorMessage(JSON.stringify(err));
		}
	});
};

WidgetProjectManager.prototype.onRemoveProjectClicked = function(button) {
	const currentWidget = this;
	const projectId = this.removeProjectButtonToProjectUuid[button.id].id;
	const api = currentWidget.getApiV2();

	this.getMainContainer().showConfirm(
		{
			title: getLanguageTag(this.constructor, 'confirmRemoveTitle'),
			message: getLanguageTag(
				WidgetProjectManager,
				'confirmRemoveProject',
			),
			confirmLabel: getLanguageTag(
				this.constructor,
				'confirmRemoveButton',
			),
			confirmClasses: ['btn-danger'],
		},
		async function() {
			await api.apis.projects.deleteProject({ id: projectId });
			currentWidget.update();
		},
		function() {},
		function() {},
	);
};

WidgetProjectManager.prototype.onInfoProjectClicked = function(button) {
	const project = this.infoProjectButtonToProjectUuid[button.id];
	this.event('infoProject', {
		projectUuid: project.uuid,
	});
};

WidgetProjectManager.prototype.onExportProjectClicked = function(button) {
	const currentWidget = this;
	const projectController = new ProjectController(
		this.getAPI(),
		this.getApiV2(),
	);
	const project = this.exportProjectButtonToProjectUuid[button.id];
	projectController.load(project.id, function(err) {
		const projectData = projectController.export();
		projectData.name = project.name;
		saveObjectAsJSON(project.name + '.atmo', projectData);
	});
};

WidgetProjectManager.prototype.addProject = function(callback) {
	const currentWidget = this;

	this.getMainContainer().setModalWidget(
		WidgetNewProjectSelector,
		{},
		function(err, newProjectSelectorWidget) {
			this.getMainContainer().showModal();

			newProjectSelectorWidget.addEventListener('dismissed', function() {
				currentWidget.getMainContainer().closeModal();
				callback.call(this, false);
			});

			newProjectSelectorWidget.addEventListener('confirmed', function() {
				currentWidget.getMainContainer().closeModal();
				callback.call(this, false);
			});
		},
	);
};

WidgetProjectManager.prototype.importProject = function(callback) {
	const currentWidget = this;
	const $fileInput = $('#' + this.importProjectButtonId + '_fileInput');

	$fileInput.off();
	$fileInput.change(function(e) {
		e.preventDefault();
		e.stopPropagation();
		const file = e.target.files[0];
		if (file) {
			currentWidget.importFileDataAndNavigateToStudio(file, callback);
		}
	});
};

WidgetProjectManager.prototype.importFileDataAndNavigateToStudio = function(
	file,
	callback,
) {
	this.readProjectDataAndImport(file, function(err, projectUuid, projectId) {
		if (err && err.type) {
			this.getMainContainer().showPopupErrorMessage(
				getLanguageTag(WidgetProjectManager, err.type),
			);
		} else if (err) {
			this.getMainContainer().showPopupErrorMessage(JSON.stringify(err));
		}

		if (projectUuid !== undefined && projectUuid !== null) {
			this.getMainContainer().setLocation(LocationProjectEditor, {
				projectUuid,
				projectId,
				org: getHashCommand().org,
			});
		}
	});
};

WidgetProjectManager.prototype.readProjectDataAndImport = function(
	data,
	callback,
) {
	const currentWidget = this;
	if (data === undefined || data === null) {
		callback.call(currentWidget, { type: 'invalidFileImportGenericError' });
		return;
	}

	if (data.constructor !== File) {
		// Just process the data directly
		processProjectDataAndLoadIntoStudio(data);
		return;
	} else {
		// Load the file data via FileReader API
		const file = data;
		const fileName = data.name;
		if (checkForFileType(fileName, ['.atmo']) === false) {
			callback.call(currentWidget, {
				type: 'invalidFileImportExtensionError',
			});
		}

		const reader = new FileReader();
		reader.readAsText(file);
		reader.onload = function(readerEvent) {
			const binaryString = readerEvent.target.result;
			const fileProjectData = binaryString.toString();
			processProjectDataAndLoadIntoStudio(fileProjectData);
		};
	}

	function processProjectDataAndLoadIntoStudio(incomingData) {
		let projectData;

		try {
			if (incomingData.constructor === String) {
				projectData = JSON.parse(incomingData);
			} else {
				projectData = incomingData;
			}

			if (projectData.createVersion === undefined) {
				callback.call(currentWidget, {
					type: 'invalidFileImportGenericError',
				});
				return;
			}

			if (projectData.meta && projectData.meta.projectTypeId) {
				const projectTypeId = projectData.meta.projectTypeId;
				if (
					currentWidget.templateWhiteList &&
					Object.keys(currentWidget.templateWhiteList).length > 0
				) {
					const templateWhiteList = currentWidget.templateWhiteList;
					if (!templateWhiteList[projectTypeId]) {
						callback.call(currentWidget, {
							type: 'invalidFileImportUnsupportedProjectType',
						});
						return;
					}
				}
			}

			currentWidget.projectController = new ProjectController(
				currentWidget.getAPI(),
				currentWidget.getApiV2(),
			);

			currentWidget.projectController.import(projectData);
			currentWidget.projectController.add(projectData.name, function(
				err,
			) {
				if (err) {
					callback.call(currentWidget, err);
					return;
				}
				currentWidget.update();
				callback.call(
					currentWidget,
					false,
					currentWidget.projectController.getProjectUuid(),
					currentWidget.projectController.getProjectId(),
				);
				return;
			});
		} catch (err) {
			console.error(err);
			callback.call(currentWidget, {
				type: 'invalidFileImportJSONError',
			});
			return;
		}
	}
};

WidgetProjectManager.prototype.update = function(callback) {
	const currentWidget = this;
	const api = currentWidget.getApiV2();
	callback = callback || function() {};

	const getProjects = async function() {
		const response = await api.apis.projects.getProjects({
			organizationId: getHashCommand().org,
		});
		return response.data.map((project) => {
			return {
				id: project.id,
				name: project.name,
				uuid: project.uuid,
				type: project.type,
				created: project.createdAt,
				modified: project.updatedAt,
			};
		});
	};

	getProjects()
		.then((data) => {
			const numberOfProjectsForUser = data.length;

			currentWidget.openProjectButtonToProjectUuid = {};
			currentWidget.infoProjectButtonToProjectUuid = {};
			currentWidget.exportProjectButtonToProjectUuid = {};
			currentWidget.removeProjectButtonToProjectUuid = {};

			const context = {
				id: currentWidget.generateChildId('_projectContainer'),
				projectsTableContainerId:
					currentWidget.projectsTableContainerId,
				projectsTableLabel: getLanguageTag(
					currentWidget.constructor,
					'myProjects',
				),
				categories: [],
				created: getLanguageTag(WidgetProjectManager, 'created'),
				updated: getLanguageTag(WidgetProjectManager, 'updated'),
				open: getLanguageTag(WidgetProjectManager, 'open'),
				addButtonPath: currentWidget.PLUS_ICON_PATH,
			};

			const newProjectsContext = [
				// 			{
				// 				id:newProjectButtonId,
				// 				name:getLanguageTag(WidgetProjectManager, "newProject"),
				// 				newProject:true
				// 			},

				{
					id: currentWidget.newProjectButtonId,
					name: getLanguageTag(WidgetProjectManager, 'newProject'),
					newProject: true,
					addButtonPath: currentWidget.PLUS_ICON_PATH,
				},

				{
					id: currentWidget.importProjectButtonId,
					importProjectCardId: currentWidget.importProjectCardId,
					name: getLanguageTag(WidgetProjectManager, 'importProject'),
					newProject: false,
					importProject: true,
					fileInputId:
						currentWidget.importProjectButtonId + '_fileInput',
					addButtonPath: currentWidget.IMPORT_ICON_PATH,
				},
			];

			const currentGlobalConfig = currentWidget.globalConfig || {};

			if (
				currentGlobalConfig &&
				currentGlobalConfig.WidgetProjectManager &&
				currentGlobalConfig.WidgetProjectManager.templateWhiteList
			) {
				currentWidget.templateWhiteList =
					currentGlobalConfig.WidgetProjectManager.templateWhiteList;
			}

			newProjectsContext[0] = {
				id: currentWidget.newProjectButtonId,
				name: getLanguageTag(WidgetProjectManager, 'newProject'),
				newProject: true,
				addButtonPath: currentWidget.PLUS_ICON_PATH,
			};

			context.categories.push({
				name: getLanguageTag(WidgetProjectManager, 'addProject'),
				projectsTableContainerId: this.projectsTableContainerId,
				projects: newProjectsContext,
			});

			const savedProjectContext = {
				name: getLanguageTag(WidgetProjectManager, 'recentProjects'),
				projects: [],
			};

			data = data.sort(function(a, b) {
				//Sort Projects By Their Modified Timestamp
				return a.modified > b.modified
					? -1
					: a.modified < b.modified
					? 1
					: 0;
			});

			for (let i = 0; i < 5; i++) {
				//Only display the 5 most recent projects
				const currentProjectData = data[i];
				if (
					currentProjectData === undefined ||
					currentProjectData === null
				) {
					//So what if we have < 5 recent projects?
					continue;
				}

				const currentProjectId = currentWidget.generateChildId(
					'_savedProject_openButton_' + currentProjectData.uuid,
				);
				const currentInfoButtonId = currentWidget.generateChildId(
					'_savedProject_infoButton_' + currentProjectData.uuid,
				);
				const currentExportButtonId = currentWidget.generateChildId(
					'_savedProject_exportButton_' + currentProjectData.uuid,
				);
				const currentRemoveButtonId = currentWidget.generateChildId(
					'_savedProject_removeButton_' + currentProjectData.uuid,
				);

				currentWidget.openProjectButtonToProjectUuid[
					currentProjectId
				] = currentProjectData;
				currentWidget.infoProjectButtonToProjectUuid[
					currentInfoButtonId
				] = currentProjectData;
				currentWidget.exportProjectButtonToProjectUuid[
					currentExportButtonId
				] = currentProjectData;
				currentWidget.removeProjectButtonToProjectUuid[
					currentRemoveButtonId
				] = currentProjectData;

				const currentProjectContext = {
					id: currentProjectId,
					projectUuid: currentProjectData.uuid,
					removeButtonId: currentRemoveButtonId,
					infoButtonId: currentInfoButtonId,
					exportButtonId: currentExportButtonId,
					name: currentProjectData.name,
					type: currentProjectData.type || '',
					created: moment(
						new Date(currentProjectData.created),
					).fromNow(),
					modified: moment(
						new Date(currentProjectData.modified),
					).fromNow(),
					infoButtonPath:
						'./Widgets/WidgetProjectManager/Resources/Information.svg',
					exportButtonPath: './Resources/icons/Export.svg',
					exportButtonTooltip: getLanguageTag(
						WidgetProjectManager,
						'exportProject',
					),
					removeButtonPath:
						'./Widgets/WidgetProjectManager/Resources/Trash.svg',
					removeButtonTooltip: getLanguageTag(
						WidgetProjectManager,
						'removeProject',
					),
				};

				savedProjectContext.projects.push(currentProjectContext);
			}

			context.categories.push(savedProjectContext);

			context.hasProjects =
				numberOfProjectsForUser && numberOfProjectsForUser > 0
					? true
					: false;
			currentWidget.renderTemplate(context, WidgetProjectManager.name);

			currentWidget.removeChildWidget(
				currentWidget.projectsTableContainerId,
				function(err) {
					currentWidget.addChildWidget(
						WidgetProjectsTable,
						currentWidget.projectsTableContainerId,
						{},
						function(err, tableWidget) {
							currentWidget._tableWidget = tableWidget;

							currentWidget._tableWidget.addEventListener(
								'changed',
								function() {
									currentWidget.update();
								},
							);

							currentWidget.newProjectButton = $(
								'#' + currentWidget.newProjectButtonId,
							);
							currentWidget.importProjectButton = $(
								'#' + currentWidget.importProjectButtonId,
							);

							currentWidget.importProjectCard = $(
								'#' + currentWidget.importProjectCardId,
							);

							currentWidget.newProjectButton.click(function() {
								currentWidget.addProject(function(err, data) {
									if (err) {
										console.error(err);
										return;
									}
								});
							});

							currentWidget.importProjectButton.click(function() {
								currentWidget.onImportProjectClicked(this);
							});

							let k;

							for (k in currentWidget.addNewButtonToProjectTemplate) {
								$('#' + k).click(function() {
									currentWidget.onNewFromTemplateClicked(
										this,
									);
								});
							}

							for (k in currentWidget.openProjectButtonToProjectUuid) {
								$('#' + k).click(function() {
									currentWidget.onOpenProjectClicked(this);
								});
							}

							for (k in currentWidget.infoProjectButtonToProjectUuid) {
								$('#' + k).click(function() {
									currentWidget.onInfoProjectClicked(this);
								});
							}

							for (k in currentWidget.exportProjectButtonToProjectUuid) {
								$('#' + k).click(function() {
									currentWidget.onExportProjectClicked(this);
								});
							}

							for (k in currentWidget.removeProjectButtonToProjectUuid) {
								$('#' + k).click(function() {
									currentWidget.onRemoveProjectClicked(this);
								});
							}

							callback.call(currentWidget, false);
							return;
						},
					);
				},
			);
		})
		.catch((err) => {
			callback.call(currentWidget, err);
			return;
		});
};

WidgetProjectManager.prototype.onDrop = function(e) {
	const currentWidget = this;

	if (
		e &&
		e.originalEvent &&
		e.originalEvent.dataTransfer &&
		e.originalEvent.dataTransfer.files &&
		e.originalEvent.dataTransfer.files[0]
	) {
		const file = e.originalEvent.dataTransfer.files[0];
		currentWidget.importFileDataAndNavigateToStudio(file, function() {});
	}
};

WidgetProjectManager.prototype.ICON = './Resources/icons/ProjectList.svg';

WidgetProjectManager.prototype.IMPORT_ICON_PATH =
	'./Widgets/WidgetProjectManager/Resources/ImportProject.svg';

WidgetProjectManager.prototype.PLUS_ICON_PATH =
	'./Widgets/WidgetProjectManager/Resources/Add.svg';

WidgetProjectManager.prototype.language = {
	'en-US': {
		name: 'My Applications',
		created: 'Created',
		updated: 'Updated',
		open: 'Open',
		addProject: 'Create Application',
		newProject: 'New Application',
		projectName: 'Name',
		myProjects: 'My Applications',
		newProjectName: 'Create New Application',
		createProject: 'Create Application',
		recentProjects: 'Recent Applications',
		importProject: 'Import Application',
		confirmRemoveTitle: 'Delete Application',
		confirmRemoveProject: 'Are you sure you want to delete this application?',
		confirmRemoveButton: 'Delete',
		pleaseEnterAProjectName: 'Enter an application name',
		pleaseSelectAProject: 'Select an application type',
		browse: 'Browse',
		projectTypeLabel: 'Type',
		invalidFileImportGenericError:
			'Invalid file imported. Select a valid .atmo file then try again.',
		invalidFileImportExtensionError:
			'Invalid file imported. Select a file with the .atmo extension then try again.',
		invalidFileImportJSONError:
			'Invalid file imported. Select a valid .atmo file then try again.',
		invalidFileImportUnsupportedProjectType: 'Application type not supported',
		removeProject: 'Delete Application',
		exportProject: 'Export Application',
	},
};
