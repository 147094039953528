// Copyright 2018 Atmosphere IoT Corp.
// All rights reserved
// jshint esversion: 6

function WidgetPanelOptionsBase(id, api, parent, options) {
	var currentWidget = this;

	WidgetPanelBase.call(this, id, api, parent, options);

	this._optionsMenu = null;

	var buttons = [
		{
			childId: 'options',
			label: 'options',
			image: 'options',
			value: 'options',
		},
	];

	this.setPanel(buttons);
}

WidgetPanelOptionsBase.prototype = Object.create(WidgetPanelBase.prototype);
WidgetPanelOptionsBase.prototype.constructor = WidgetPanelOptionsBase;

WidgetPanelOptionsBase.prototype.remove = function(callback) {
	WidgetPanelBase.prototype.remove.call(this, callback);
};

WidgetPanelOptionsBase.prototype.showSettings = function(callback) {
	this.getMainContainer().showPopupErrorMessage(
		getLanguageTag(this.constructor, 'noSettingsToConfigure'),
	);

	callback = callback || function() {};

	callback.call(this, false);
	return;
};

WidgetPanelOptionsBase.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.addMenuWidget(WidgetMenu, {}, 'options', function(
		err,
		optionsMenuWidget,
	) {
		this._optionsMenu = optionsMenuWidget;

		// This logic has been moved to WidgetDashboardBase as of AI-3480
		var menuOptions = this.getOptions().menuOptions || [];

		this._optionsMenu.setMenu(menuOptions);

		this._optionsMenu.hide();

		this._optionsMenu.addEventListener('menuEntryClicked', function(value) {
			currentWidget._optionsMenu.hide();
			currentWidget.event('optionSelected', value);
		});

		this.addEventListener('buttonClicked', function(value) {
			if (value === 'options') {
				currentWidget._optionsMenu.toggleVisible();
			}
		});

		this.addEventListener('optionSelected', function(option) {
			if (option === 'remove') {
				this.event('closed', this);
			} else if (option === 'settings') {
				this.showSettings();
			} else if (option === 'help') {
				openLinkInNewWindowOrTab(
					'https://docs.atmosphereiot.com/configuring-the-dashboard/',
				);
			}
		});

		// This allows the client to collapse other menus
		// when an "Outside click" is detected.
		// Refer to WidgetMenu.js for more.
		this._optionsMenu
			.getContainer()
			.addClass('WidgetPanelOptionsBase-OptionsMenu');

		WidgetPanelBase.prototype.initialize.call(this, callback);
	});
};

WidgetPanelOptionsBase.prototype.PACKERY_SIZE = 'WidgetDashboard_Container_SxS';

WidgetPanelOptionsBase.prototype.language = deepAssign(
	{},
	WidgetPanelBase.prototype.language,
	{
		'en-US': {
			settings: 'Settings',
			download: 'Download',
			remove: 'Remove',
			help: 'Help',
			gotoDevice: 'Go To Device',
			noSettingsToConfigure: 'This widget does not have any settings',
		},
	},
);
