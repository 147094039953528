function ProvisionerBLE(api) {
	ProvisionerBase.call(this, api);

	const currentProvisioner = this;

	this._deviceTypes = {};
	this._removedDevices = {};

	this._currentSearchingTimeout = null;

	this._autoProvisionCallback = null;
	this._autoProvisionLocalName = null;
	this._autoProvisionServiceUUID = null;

	this._provisioning = false;
	this._cancelProvisioning = false;

	PluginBLECentral.prototype.SELECT_BACKEND();
	this.bleCentral = new PluginBLECentral();

	if (this.bleCentral !== undefined) {
		this._currentAdvertisementListener = this.bleCentral.addEventListener(
			'advertisement',
			function(data) {
				currentProvisioner._onBLEAdvertisment(data);
			},
		);
	}
}

ProvisionerBLE.prototype = Object.create(ProvisionerBase.prototype);
ProvisionerBLE.prototype.constructor = ProvisionerBLE;

ProvisionerBLE.prototype.TYPE = 'ble';
ProvisionerBLE.prototype.BLE_PROVISION_SERVICE_UUID =
	'bfe433cf-6b5e-4368-abab-b0a59666a402';
ProvisionerBLE.prototype.BLE_PROVISION_CHAR_UUID =
	'bfe433cf-6b5f-4368-abab-b0a59666a402';

ProvisionerBLE.prototype.hasPermission = function(callback) {
	const currentProvisioner = this;

	if (PluginBLECentral.prototype.SELECT_BACKEND() === BLECentralBackendNull) {
		callback.call(this, false, false);
	} else {
		callback.call(currentProvisioner, false, true);
	}
};

ProvisionerBLE.prototype.isAvailable = function(callback) {
	if (this.bleCentral !== undefined) {
		callback.call(this, false, true);
		return;
	}

	callback.call(this, false, false);
	return;
};

ProvisionerBLE.prototype._onBLEAdvertisment = function(data) {
	// console.log("_onBLEAdvertisment: " + JSON.stringify(data));

	if (
		this._deviceTypes[data.primaryServiceUuid] !== undefined &&
		/^P[0-9a-fA-F]{4}$/.test(data.localName)
	) {
		var deviceFoundData = {
			id: data.address,
			type: this._deviceTypes[data.primaryServiceUuid],
			projectUuid: data.primaryServiceUuid,
			signalStrength: data.rssi,
			mac: data.mac,
			label:
				'*:' +
				data.localName.slice(1, 3).toUpperCase() +
				':' +
				data.localName.slice(3, 5).toUpperCase(),
		};

		this.setAvailableDeviceEntry(deviceFoundData);

		if (
			this._autoProvisionLocalName === data.localName ||
			this._autoProvisionServiceUUID === data.primaryServiceUuid
		) {
			var currentAutoProvisionCallback = this._autoProvisionCallback;

			this.provisionDevice(
				data.address,
				this._deviceTypes[data.primaryServiceUuid],
				data.primaryServiceUuid,
				function(err, newDevice, extraSettings) {
					currentAutoProvisionCallback.call(
						this,
						err,
						deviceFoundData,
						newDevice,
						extraSettings,
					);
				},
			);

			this._autoProvisionCallback = null;
			this._autoProvisionLocalName = null;
			this._autoProvisionServiceUUID = null;
			return;
		}

		this.event('deviceFound', deviceFoundData);
	} else if (this._removedDevices[data.primaryServiceUuid] !== undefined) {
		const currentRemovedDevice = this._removedDevices[
			data.primaryServiceUuid
		];

		console.log(
			'Device was removed but is available now: ' +
				JSON.stringify(currentRemovedDevice),
		);
		console.log(
			'Deivce is a project type we can use: ' +
				this._deviceTypes[currentRemovedDevice.projectUuid] !==
				undefined,
		);

		if (this._deviceTypes[currentRemovedDevice.projectUuid] !== undefined) {
			let label = '';

			if (/^D[0-9a-fA-F]{4}$/.test(data.localName)) {
				label =
					'*:' +
					data.localName.slice(1, 3).toUpperCase() +
					':' +
					data.localName.slice(3, 5).toUpperCase();
			}

			var deviceFoundData = {
				id: data.address,
				type: this._deviceTypes[currentRemovedDevice.projectUuid],
				projectUuid: currentRemovedDevice.projectUuid,
				signalStrength: data.rssi,
				mac: data.mac,
				label: label,
				removed: true,
				removedData: currentRemovedDevice,
			};

			this.setAvailableDeviceEntry(deviceFoundData);

			if (
				this._autoProvisionLocalName === data.localName ||
				this._autoProvisionServiceUUID === data.primaryServiceUuid
			) {
				var currentAutoProvisionCallback = this._autoProvisionCallback;

				this.provisionDevice(
					data.address,
					this._deviceTypes[currentRemovedDevice.projectUuid],
					currentRemovedDevice.projectUuid,
					function(err, newDevice, extraSettings) {
						currentAutoProvisionCallback.call(
							this,
							err,
							deviceFoundData,
							newDevice,
							extraSettings,
						);
					},
				);

				this._autoProvisionCallback = null;
				this._autoProvisionLocalName = null;
				this._autoProvisionServiceUUID = null;
				return;
			}

			this.event('deviceFound', deviceFoundData);
		}
	}
};

ProvisionerBLE.prototype.startAutoProvisioning = function(
	localName,
	primaryServiceUuid,
	timeout,
	callback,
) {
	const currentProvisioner = this;

	timeout = timeout || 5000;

	this._autoProvisionCallback = callback;
	this._autoProvisionLocalName = localName.toString();
	this._autoProvisionServiceUUID = primaryServiceUuid.toString();

	this.startSearching(timeout, function(err) {
		if (err) {
			this._autoProvisionCallback = null;
			this._autoProvisionLocalName = null;
			this._autoProvisionServiceUUID = null;
			callback.call(this, err);
			return;
		}

		setTimeout(function() {
			if (currentProvisioner._autoProvisionCallback !== null) {
				this._autoProvisionCallback = null;
				this._autoProvisionLocalName = null;
				this._autoProvisionServiceUUID = null;
				callback.call(this, { type: 'couldNotFindDevice' });
				return;
			}
		}, timeout + 1000);
	});
};

ProvisionerBLE.prototype.startSearching = function(timeout, callback) {
	const currentProvisioner = this;

	this._availableDeviceEntries = {};

	timeout = timeout || 5000;

	if (this._currentSearchingTimeout !== null) {
		clearTimeout(this._currentSearchingTimeout);
		this._currentSearchingTimeout = null;
	}

	this.getRemovedDevices(function(err, removedDevices) {
		this._removedDevices = removedDevices || {};

		this.getAvailableDeviceTypes(function(err, deviceTypes) {
			this._deviceTypes = deviceTypes || {};

			this.bleCentral.startScanning(function(err) {
				currentProvisioner.event('searchingStarted', null);

				if (currentProvisioner._currentSearchingTimeout !== null) {
					clearTimeout(currentProvisioner._currentSearchingTimeout);
					currentProvisioner._currentSearchingTimeout = null;
				}

				currentProvisioner._currentSearchingTimeout = setTimeout(
					function() {
						currentProvisioner.stopSearching(function() {});
					},
					timeout,
				);

				callback.call(currentProvisioner, false);
			});
		});
	});
};

ProvisionerBLE.prototype.stopSearching = function(callback) {
	const currentProvisioner = this;

	if (this._currentSearchingTimeout !== null) {
		clearTimeout(this._currentSearchingTimeout);
		this._currentSearchingTimeout = null;
	}

	if (this.bleCentral !== undefined) {
		this.bleCentral.stopScanning(function(err) {
			currentProvisioner.event('searchingStopped', null);
			callback.call(currentProvisioner, err);
		});
	}
};

ProvisionerBLE.prototype._unlockRegistrationInfo = function(id, callback) {
	const currentProvisioner = this;

	const availableDeviceEntry = this.getAvailableDeviceEntry(id) || {};

	if (availableDeviceEntry.removed) {
		const data = [currentProvisioner.UNLOCKREGISTRATIONINFO];
		const token = availableDeviceEntry.removedData.token || [];

		for (let i = 0; i < token.length; i++) {
			data.push(token.charCodeAt(i));
		}

		data.push(0);

		console.log('_unlockRegistrationInfo: ' + JSON.stringify(data));

		setTimeout(function() {
			currentProvisioner.bleCentral.write(
				id,
				currentProvisioner.BLE_PROVISION_SERVICE_UUID,
				currentProvisioner.BLE_PROVISION_CHAR_UUID,
				data,
				function(err) {
					setTimeout(function() {
						currentProvisioner.bleCentral.read(
							id,
							currentProvisioner.BLE_PROVISION_SERVICE_UUID,
							currentProvisioner.BLE_PROVISION_CHAR_UUID,
							function(err, data) {
								console.log(
									'_unlockRegistrationInfo:' +
										JSON.stringify(data),
								);

								if (
									!data ||
									data[0] !==
										currentProvisioner.UNLOCKREGISTRATIONINFO
								) {
									callback.call(
										currentProvisioner,
										{ type: 'invalidResponse' },
										null,
									);
									return;
								}

								callback.call(
									currentProvisioner,
									false,
									data[1],
								);
								return;
							},
						);
					}, 500);
				},
			);
		}, 100);
	} else {
		callback.call(this, false);
		return;
	}
};

ProvisionerBLE.prototype._parseExtraSettingFlags = function(extraSettingsData) {
	const extraSettingsOptions = [];

	if (
		(extraSettingsData & ProvisionerBLE.prototype.EXTRA_SETTINGS_BLE) ===
		this.EXTRA_SETTINGS_BLE
	) {
		extraSettingsOptions.push('BLE');
	}

	if (
		(extraSettingsData & ProvisionerBLE.prototype.EXTRA_SETTINGS_WIFI) ===
		this.EXTRA_SETTINGS_WIFI
	) {
		extraSettingsOptions.push('WiFi');
	}

	if (
		(extraSettingsData & ProvisionerBLE.prototype.EXTRA_SETTINGS_LORA) ===
		this.EXTRA_SETTINGS_LORA
	) {
		extraSettingsOptions.push('LoRa');
	}

	if (
		(extraSettingsData &
			ProvisionerBLE.prototype.EXTRA_SETTINGS_CELLULAR) ===
		this.EXTRA_SETTINGS_CELLULAR
	) {
		extraSettingsOptions.push('Cellular');
	}

	if (
		(extraSettingsData & ProvisionerBLE.prototype.EXTRA_SETTINGS_THREAD) ===
		this.EXTRA_SETTINGS_THREAD
	) {
		extraSettingsOptions.push('Thread');
	}

	if (
		(extraSettingsData &
			ProvisionerBLE.prototype.EXTRA_SETTINGS_6LOWPAN) ===
		this.EXTRA_SETTINGS_6LOWPAN
	) {
		extraSettingsOptions.push('6LowPAN');
	}

	if (
		(extraSettingsData &
			ProvisionerBLE.prototype.EXTRA_SETTINGS_ETHERNET) ===
		this.EXTRA_SETTINGS_ETHERNET
	) {
		extraSettingsOptions.push('Ethernet');
	}

	if (
		(extraSettingsData & ProvisionerBLE.prototype.EXTRA_SETTINGS_ZIGBEE) ===
		this.EXTRA_SETTINGS_ZIGBEE
	) {
		extraSettingsOptions.push('Zigbee');
	}

	if (
		(extraSettingsData & ProvisionerBLE.prototype.EXTRA_SETTINGS_ZWAVE) ===
		this.EXTRA_SETTINGS_ZWAVE
	) {
		extraSettingsOptions.push('ZWave');
	}

	if (
		(extraSettingsData & ProvisionerBLE.prototype.EXTRA_SETTINGS_SIGFOX) ===
		this.EXTRA_SETTINGS_SIGFOX
	) {
		extraSettingsOptions.push('Sigfox');
	}

	if (
		(extraSettingsData & ProvisionerBLE.prototype.EXTRA_SETTINGS_NEUL) ===
		this.EXTRA_SETTINGS_NEUL
	) {
		extraSettingsOptions.push('Neul');
	}

	if (
		(extraSettingsData &
			ProvisionerBLE.prototype.EXTRA_SETTINGS_RESERVED) ===
		this.EXTRA_SETTINGS_RESERVED
	) {
		extraSettingsOptions.push('Reserved');
	}

	return extraSettingsOptions;
};

ProvisionerBLE.prototype._setWiFiSSID = function(id, ssid, callback) {
	const currentProvisioner = this;

	const data = [
		currentProvisioner.EXTRASETTINGSWIFI,
		currentProvisioner.EXTRASETTINGSWIFI_SETSSID,
	];

	for (let i = 0; i < ssid.length; i++) {
		data.push(ssid.charCodeAt(i));
	}

	data.push(0);

	console.log('_setWiFiSSID: ' + JSON.stringify(data));

	setTimeout(function() {
		currentProvisioner.bleCentral.write(
			id,
			currentProvisioner.BLE_PROVISION_SERVICE_UUID,
			currentProvisioner.BLE_PROVISION_CHAR_UUID,
			data,
			function(err) {
				setTimeout(function() {
					currentProvisioner.bleCentral.read(
						id,
						currentProvisioner.BLE_PROVISION_SERVICE_UUID,
						currentProvisioner.BLE_PROVISION_CHAR_UUID,
						function(err, data) {
							console.log('_setWiFiSSID:' + JSON.stringify(data));

							if (
								!data ||
								data[0] !== currentProvisioner.EXTRASETTINGSWIFI
							) {
								callback.call(
									currentProvisioner,
									{ type: 'invalidResponse' },
									null,
								);
								return;
							}

							callback.call(currentProvisioner, false, data[1]);
							return;
						},
					);
				}, 500);
			},
		);
	}, 100);
};

ProvisionerBLE.prototype._setWiFiPassword = function(id, password, callback) {
	const currentProvisioner = this;

	const data = [
		currentProvisioner.EXTRASETTINGSWIFI,
		currentProvisioner.EXTRASETTINGSWIFI_SETPASSWORD,
	];

	for (let i = 0; i < password.length; i++) {
		data.push(password.charCodeAt(i));
	}

	data.push(0);

	console.log('_setWiFiPassword: ' + JSON.stringify(data));

	setTimeout(function() {
		currentProvisioner.bleCentral.write(
			id,
			currentProvisioner.BLE_PROVISION_SERVICE_UUID,
			currentProvisioner.BLE_PROVISION_CHAR_UUID,
			data,
			function(err) {
				setTimeout(function() {
					currentProvisioner.bleCentral.read(
						id,
						currentProvisioner.BLE_PROVISION_SERVICE_UUID,
						currentProvisioner.BLE_PROVISION_CHAR_UUID,
						function(err, data) {
							console.log(
								'_setWiFiPassword:' + JSON.stringify(data),
							);

							if (
								!data ||
								data[0] !== currentProvisioner.EXTRASETTINGSWIFI
							) {
								callback.call(
									currentProvisioner,
									{ type: 'invalidResponse' },
									null,
								);
								return;
							}

							callback.call(currentProvisioner, false, data[1]);
							return;
						},
					);
				}, 500);
			},
		);
	}, 100);
};

ProvisionerBLE.prototype._getWiFiStatus = function(id, callback) {};

ProvisionerBLE.prototype._getIdentityType = function(id, callback) {
	const currentProvisioner = this;

	const data = [currentProvisioner.GETIDENTITYTYPE];

	console.log('_getIdentityType: ' + JSON.stringify(data));

	setTimeout(function() {
		currentProvisioner.bleCentral.write(
			id,
			currentProvisioner.BLE_PROVISION_SERVICE_UUID,
			currentProvisioner.BLE_PROVISION_CHAR_UUID,
			data,
			function(err) {
				setTimeout(function() {
					currentProvisioner.bleCentral.read(
						id,
						currentProvisioner.BLE_PROVISION_SERVICE_UUID,
						currentProvisioner.BLE_PROVISION_CHAR_UUID,
						function(err, data) {
							console.log(
								'_getIdentityType:' + JSON.stringify(data),
							);

							if (
								!data ||
								data[0] !== currentProvisioner.GETIDENTITYTYPE
							) {
								callback.call(
									currentProvisioner,
									{ type: 'invalidResponse' },
									null,
									null,
								);
								return;
							}

							let extraSettingsOptions = [];

							//Did we get an extended ID data with the extra settings options?
							if (data.length >= 4) {
								extraSettingsOptions = currentProvisioner._parseExtraSettingFlags(
									UleInt16ToNumber(data.splice(2, 2)),
								);
							}

							callback.call(
								currentProvisioner,
								false,
								data[1],
								extraSettingsOptions,
							);
							return;
						},
					);
				}, 500);
			},
		);
	}, 500);
};

ProvisionerBLE.prototype._basicCentralConfirmIdentity = function(id, callback) {
	const currentProvisioner = this;

	const data = [currentProvisioner.BASICCENTRALCONFIRMIDENTITY];

	console.log('_setRegistrationInfoUuid: ' + JSON.stringify(data));

	setTimeout(function() {
		currentProvisioner.bleCentral.write(
			id,
			currentProvisioner.BLE_PROVISION_SERVICE_UUID,
			currentProvisioner.BLE_PROVISION_CHAR_UUID,
			data,
			function(err) {
				setTimeout(function() {
					currentProvisioner.bleCentral.read(
						id,
						currentProvisioner.BLE_PROVISION_SERVICE_UUID,
						currentProvisioner.BLE_PROVISION_CHAR_UUID,
						function(err, data) {
							console.log(
								'_basicCentralConfirmIdentity:' +
									JSON.stringify(data),
							);

							if (
								!data ||
								data[0] !==
									currentProvisioner.BASICCENTRALCONFIRMIDENTITY
							) {
								callback.call(
									currentProvisioner,
									{ type: 'invalidResponse' },
									null,
								);
								return;
							}

							callback.call(currentProvisioner, false, data[1]);
							return;
						},
					);
				}, 500);
			},
		);
	}, 100);
};

ProvisionerBLE.prototype._setRegistrationInfoUuid = function(
	id,
	uuid,
	callback,
) {
	const currentProvisioner = this;

	const data = [this.SETREGISTRATIONINFOUUID];

	const cleanUuid = uuid.replace(/-/g, '');

	if (cleanUuid.length !== 32) {
		callback.call(this, { type: 'notAValidUuid' });
		return;
	}

	for (let i = 0; i < cleanUuid.length; i = i + 2) {
		data.push(parseInt(cleanUuid.substring(i, i + 2), 16));
	}

	console.log('_setRegistrationInfoUuid: ' + JSON.stringify(data));

	setTimeout(function() {
		currentProvisioner.bleCentral.write(
			id,
			currentProvisioner.BLE_PROVISION_SERVICE_UUID,
			currentProvisioner.BLE_PROVISION_CHAR_UUID,
			data,
			function(err) {
				setTimeout(function() {
					currentProvisioner.bleCentral.read(
						id,
						currentProvisioner.BLE_PROVISION_SERVICE_UUID,
						currentProvisioner.BLE_PROVISION_CHAR_UUID,
						function(err, data) {
							console.log(
								'_setRegistrationInfoUuid:' +
									JSON.stringify(data),
							);

							if (
								!data ||
								data[0] !==
									currentProvisioner.SETREGISTRATIONINFOUUID
							) {
								callback.call(
									currentProvisioner,
									{ type: 'invalidResponse' },
									null,
								);
								return;
							}

							callback.call(currentProvisioner, false, data[1]);
							return;
						},
					);
				}, 500);
			},
		);
	}, 100);
};

ProvisionerBLE.prototype._setRegistrationInfoToken = function(
	id,
	token,
	callback,
) {
	const currentProvisioner = this;

	const data = [this.SETREGISTRATIONINFOTOKEN];

	for (let i = 0; i < token.length; i++) {
		data.push(token.charCodeAt(i));
	}

	data.push(0);

	console.log('_setRegistrationInfoToken: ' + JSON.stringify(data));

	setTimeout(function() {
		currentProvisioner.bleCentral.write(
			id,
			currentProvisioner.BLE_PROVISION_SERVICE_UUID,
			currentProvisioner.BLE_PROVISION_CHAR_UUID,
			data,
			function(err) {
				setTimeout(function() {
					currentProvisioner.bleCentral.read(
						id,
						currentProvisioner.BLE_PROVISION_SERVICE_UUID,
						currentProvisioner.BLE_PROVISION_CHAR_UUID,
						function(err, data) {
							console.log(
								'_setRegistrationInfoToken:' +
									JSON.stringify(data),
							);

							if (
								!data ||
								data[0] !==
									currentProvisioner.SETREGISTRATIONINFOTOKEN
							) {
								callback.call(
									currentProvisioner,
									{ type: 'invalidResponse' },
									null,
								);
								return;
							}

							callback.call(currentProvisioner, false, data[1]);
							return;
						},
					);
				}, 500);
			},
		);
	}, 100);
};

ProvisionerBLE.prototype._setRegistrationInfoUrl = function(id, url, callback) {
	const currentProvisioner = this;

	const data = [this.SETREGISTRATIONINFOURL];

	for (let i = 0; i < url.length; i++) {
		data.push(url.charCodeAt(i));
	}

	data.push(0);

	console.log('_setRegistrationInfoUrl: ' + JSON.stringify(data));

	setTimeout(function() {
		currentProvisioner.bleCentral.write(
			id,
			currentProvisioner.BLE_PROVISION_SERVICE_UUID,
			currentProvisioner.BLE_PROVISION_CHAR_UUID,
			data,
			function(err) {
				setTimeout(function() {
					currentProvisioner.bleCentral.read(
						id,
						currentProvisioner.BLE_PROVISION_SERVICE_UUID,
						currentProvisioner.BLE_PROVISION_CHAR_UUID,
						function(err, data) {
							console.log(
								'_setRegistrationInfoUrl:' +
									JSON.stringify(data),
							);

							if (
								!data ||
								data[0] !==
									currentProvisioner.SETREGISTRATIONINFOURL
							) {
								callback.call(
									currentProvisioner,
									{ type: 'invalidResponse' },
									null,
								);
								return;
							}

							callback.call(currentProvisioner, false, data[1]);
							return;
						},
					);
				}, 500);
			},
		);
	}, 100);
};

ProvisionerBLE.prototype._lockRegistrationInfo = function(id, callback) {
	const currentProvisioner = this;

	const data = [this.LOCKREGISTRATIONINFO];

	console.log('_lockRegistrationInfo: ' + JSON.stringify(data));

	setTimeout(function() {
		currentProvisioner.bleCentral.write(
			id,
			currentProvisioner.BLE_PROVISION_SERVICE_UUID,
			currentProvisioner.BLE_PROVISION_CHAR_UUID,
			data,
			function(err) {
				setTimeout(function() {
					currentProvisioner.bleCentral.read(
						id,
						currentProvisioner.BLE_PROVISION_SERVICE_UUID,
						currentProvisioner.BLE_PROVISION_CHAR_UUID,
						function(err, data) {
							console.log(
								'_lockRegistrationInfo:' + JSON.stringify(data),
							);

							if (
								!data ||
								data[0] !==
									currentProvisioner.LOCKREGISTRATIONINFO
							) {
								callback.call(
									currentProvisioner,
									{ type: 'invalidResponse' },
									null,
								);
								return;
							}

							callback.call(currentProvisioner, false, true);
							return;
						},
					);
				}, 500);
			},
		);
	}, 100);
};

ProvisionerBLE.prototype._setRegistrationInfo = function(
	id,
	uuid,
	token,
	url,
	callback,
) {
	const currentProvisioner = this;

	this._setRegistrationInfoUuid(id, uuid, function(err, status) {
		this._setRegistrationInfoToken(id, token, function(err, status) {
			this._setRegistrationInfoUrl(id, url, function(err, status) {
				this._lockRegistrationInfo(id, function(err) {
					callback.call(currentProvisioner, err);
				});
			});
		});
	});
};

ProvisionerBLE.prototype._getRegistrationInfo = function(id, callback) {
	const currentProvisioner = this;

	this.bleCentral.write(
		id,
		currentProvisioner.BLE_PROVISION_SERVICE_UUID,
		currentProvisioner.BLE_PROVISION_CHAR_UUID,
		[this.GETREGISTRATIONINFO],
		function(err) {
			setTimeout(function() {
				currentProvisioner.bleCentral.read(
					id,
					currentProvisioner.BLE_PROVISION_SERVICE_UUID,
					currentProvisioner.BLE_PROVISION_CHAR_UUID,
					function(err, data) {
						console.log(
							'_getRegistrationInfo:' + JSON.stringify(data),
						);

						if (
							!data ||
							data[0] !== currentProvisioner.GETREGISTRATIONINFO
						) {
							callback.call(
								currentProvisioner,
								{ type: 'invalidResponse' },
								null,
							);
							return;
						}

						callback.call(currentProvisioner, false, data);
						return;
					},
				);
			}, 500);
		},
	);
};

ProvisionerBLE.prototype._setDateTime = function(id, date, callback) {
	date = date || new Date();

	const currentProvisioner = this;

	let data = [this.SETDATETIMEEPOCH];

	data = data.concat(
		numberToUleInt32(
			parseInt(date.getTime() / 1000 - date.getTimezoneOffset() * 60),
		),
	);

	console.log('_setDateTime: ' + JSON.stringify(data));

	setTimeout(function() {
		currentProvisioner.bleCentral.write(
			id,
			currentProvisioner.BLE_PROVISION_SERVICE_UUID,
			currentProvisioner.BLE_PROVISION_CHAR_UUID,
			data,
			function(err) {
				setTimeout(function() {
					currentProvisioner.bleCentral.read(
						id,
						currentProvisioner.BLE_PROVISION_SERVICE_UUID,
						currentProvisioner.BLE_PROVISION_CHAR_UUID,
						function(err, data) {
							console.log('_setDateTime:' + JSON.stringify(data));

							if (
								!data ||
								data[0] !== currentProvisioner.SETDATETIMEEPOCH
							) {
								callback.call(
									currentProvisioner,
									{ type: 'invalidResponse' },
									null,
								);
								return;
							}

							callback.call(currentProvisioner, false, data[1]);
							return;
						},
					);
				}, 500);
			},
		);
	}, 100);
};

ProvisionerBLE.prototype._authenticateDevice = function(id, callback) {
	callback.call(this, false, true);
};

ProvisionerBLE.prototype.cancelProvisioningDevice = function(id, callback) {
	const currentProvisioner = this;

	this.bleCentral.disconnectFromDeviceByAddress(id, function(err) {
		callback.call(currentProvisioner, err);
		return;
	});
};

ProvisionerBLE.prototype.cancelProvisioning = function() {
	this._cancelProvisioning = true;

	return this._provisioning;
};

ProvisionerBLE.prototype._setExtraSettingWiFi = function(
	id,
	extraSettingsOptions,
	callback,
) {
	const currentProvisioner = this;

	const ssid = extraSettingsOptions.ssid || null;
	const password = extraSettingsOptions.password || null;

	if (ssid === null) {
		callback.call(this, { type: 'invalidWiFiSSID' });
		return;
	}

	if (password === null) {
		callback.call(this, { type: 'invalidWiFiPassword' });
	}

	this._setWiFiSSID(id, ssid, function(err) {
		if (err) {
			this.bleCentral.disconnectFromDeviceByAddress(id, function() {
				callback.call(currentProvisioner, err);
			});
			return;
		}

		this._setWiFiPassword(id, password, function(err) {
			if (err) {
				this.bleCentral.disconnectFromDeviceByAddress(id, function() {
					callback.call(currentProvisioner, err);
				});
				return;
			}

			this.bleCentral.disconnectFromDeviceByAddress(id, function() {
				callback.call(currentProvisioner, false);
			});

			return;
		});
	});
};

ProvisionerBLE.prototype._getSigfoxDeviceId = function(id, callback) {
	const currentProvisioner = this;

	this.bleCentral.write(
		id,
		currentProvisioner.BLE_PROVISION_SERVICE_UUID,
		currentProvisioner.BLE_PROVISION_CHAR_UUID,
		[this.EXTRASETTINGSSIGFOX, this.EXTRASETTINGSSIGFOX_GETDEVICEID],
		function(err) {
			setTimeout(function() {
				currentProvisioner.bleCentral.read(
					id,
					currentProvisioner.BLE_PROVISION_SERVICE_UUID,
					currentProvisioner.BLE_PROVISION_CHAR_UUID,
					function(err, data) {
						if (err) {
							currentProvisioner.bleCentral.disconnectFromDeviceByAddress(
								id,
								function() {
									callback.call(
										currentProvisioner,
										err,
										null,
									);
								},
							);

							return;
						}

						console.log(
							'_getSigfoxDeviceId read data: ' +
								JSON.stringify(data),
						);

						let sigfoxDeviceId = '';

						sigfoxDeviceId += (
							'0' + parseInt(data[6]).toString(16)
						).slice(-2);
						sigfoxDeviceId += (
							'0' + parseInt(data[5]).toString(16)
						).slice(-2);
						sigfoxDeviceId += (
							'0' + parseInt(data[4]).toString(16)
						).slice(-2);
						sigfoxDeviceId += (
							'0' + parseInt(data[3]).toString(16)
						).slice(-2);

						sigfoxDeviceId = parseInt(sigfoxDeviceId, 16)
							.toString(16)
							.toUpperCase();

						console.log(
							'_getSigfoxDeviceId:' +
								JSON.stringify(sigfoxDeviceId),
						);

						currentProvisioner.bleCentral.disconnectFromDeviceByAddress(
							id,
							function() {
								callback.call(
									currentProvisioner,
									false,
									sigfoxDeviceId,
								);
							},
						);

						return;
					},
				);
			}, 500);
		},
	);
};

ProvisionerBLE.prototype._getLoRaDeviceEui = function(id, callback) {
	const currentProvisioner = this;

	this.bleCentral.write(
		id,
		currentProvisioner.BLE_PROVISION_SERVICE_UUID,
		currentProvisioner.BLE_PROVISION_CHAR_UUID,
		[this.EXTRASETTINGSLORA, this.EXTRASETTINGSLORA_GETDEVICEID],
		function(err) {
			setTimeout(function() {
				currentProvisioner.bleCentral.read(
					id,
					currentProvisioner.BLE_PROVISION_SERVICE_UUID,
					currentProvisioner.BLE_PROVISION_CHAR_UUID,
					function(err, data) {
						if (err) {
							currentProvisioner.bleCentral.disconnectFromDeviceByAddress(
								id,
								function() {
									callback.call(
										currentProvisioner,
										err,
										null,
									);
								},
							);

							return;
						}

						console.log(
							'_getSigfoxDeviceId read data: ' +
								JSON.stringify(data),
						);

						const devEui = data.slice(3).toString();

						console.log(
							'_getLoRaDeviceEui:' + JSON.stringify(devEui),
						);

						currentProvisioner.bleCentral.disconnectFromDeviceByAddress(
							id,
							function() {
								callback.call(
									currentProvisioner,
									false,
									devEui,
								);
							},
						);

						return;
					},
				);
			}, 500);
		},
	);
};

ProvisionerBLE.prototype._setExtraSettingSigfox = function(
	id,
	extraSettingsOptions,
	callback,
) {
	const currentProvisioner = this;

	this._getSigfoxDeviceId(id, function(err, sigfoxDeviceId) {
		if (err) {
			callback.call(this, err);
			return;
		}

		if (extraSettingsOptions.device === undefined) {
			callback.call(this, { type: 'noDeviceSpecified' });
			return;
		}

		const meta = extraSettingsOptions.device.data.meta;
		meta.sigfoxDeviceId = sigfoxDeviceId;
		extraSettingsOptions.device
			.save()
			.then(() => {
				callback.call(currentProvisioner, false);
			})
			.catch((err) => {
				callback.call(currentProvisioner, err);
			});
	});
};

ProvisionerBLE.prototype._setExtraSettingLoRa = function(
	id,
	extraSettingsOptions,
	callback,
) {
	const currentProvisioner = this;

	this._getLoRaDeviceEui(id, function(err, loRaDeviceId) {
		if (err) {
			callback.call(this, err);
			return;
		}

		if (extraSettingsOptions.device === undefined) {
			callback.call(this, { type: 'noDeviceSpecified' });
			return;
		}

		const meta = extraSettingsOptions.device.data.meta;
		meta.loraDevEUI = loRaDeviceId;
		extraSettingsOptions.device
			.save()
			.then(() => {
				callback.call(currentProvisioner, false);
			})
			.catch((err) => {
				callback.call(currentProvisioner, err);
			});
	});
};

ProvisionerBLE.prototype.setExtraSettings = function(
	id,
	extraSettingsType,
	extraSettingsOptions,
	callback,
) {
	const currentProvisioner = this;

	extraSettingsOptions = extraSettingsOptions || {};

	this.stopSearching(function(err) {
		console.log('Connecting to device: ' + id);

		this.bleCentral.connectToDeviceByAddress(id, function(err) {
			if (err) {
				callback.call(
					currentProvisioner,
					{ type: 'failedToConnectToDevice', err: err },
					null,
					null,
				);
				return;
			}

			console.log('Connected to device: ' + id);

			switch (extraSettingsType) {
				case 'WiFi':
					currentProvisioner._setExtraSettingWiFi(
						id,
						extraSettingsOptions,
						callback,
					);
					return;

				case 'Sigfox':
					currentProvisioner._setExtraSettingSigfox(
						id,
						extraSettingsOptions,
						callback,
					);
					return;

				case 'LoRa':
					currentProvisioner._setExtraSettingLoRa(
						id,
						extraSettingsOptions,
						callback,
					);
					return;

				default:
					callback.call(currentProvisioner, {
						type: 'invalidExtraSettingsType',
					});
					return;
			}
		});
	});
};

ProvisionerBLE.prototype.setExtraSettingsByUuid = function(
	deviceUuid,
	extraSettingsType,
	extraSettingsOptions,
	callback,
) {
	const currentProvisioner = this;

	extraSettingsOptions = extraSettingsOptions || {};

	let timedout = false;

	const timeout = setTimeout(function() {
		timedout = true;

		currentProvisioner.bleCentral.stopScanning(function() {
			callback.call(
				currentProvisioner,
				{ type: 'timeoutConnectingToDevice' },
				null,
				null,
			);
		});
	}, 60000);

	this.stopSearching(function(err) {
		console.log('Connecting to device: ' + deviceUuid);

		this.bleCentral.connectToDeviceByServiceUuid(deviceUuid, function(
			err,
			id,
		) {
			if (timedout) {
				return;
			}

			clearTimeout(timeout);

			if (err) {
				callback.call(
					currentProvisioner,
					{ type: 'failedToConnectToDevice', err: err },
					null,
					null,
				);
				return;
			}

			console.log('Connected to device: ' + id);

			switch (extraSettingsType) {
				case 'WiFi':
					currentProvisioner._setExtraSettingWiFi(
						id,
						extraSettingsOptions,
						callback,
					);
					return;

				case 'Sigfox':
					currentProvisioner._setExtraSettingSigfox(
						id,
						extraSettingsOptions,
						callback,
					);
					return;

				case 'LoRa':
					currentProvisioner._setExtraSettingLoRa(
						id,
						extraSettingsOptions,
						callback,
					);
					return;

				default:
					callback.call(currentProvisioner, {
						type: 'invalidExtraSettingsType',
					});
					return;
			}
		});
	});
};

ProvisionerBLE.prototype.provisionDevice = function(
	id,
	newName,
	projectUuid,
	callback,
) {
	const currentProvisioner = this;

	if (this._cancelProvisioning) {
		callback.call(
			currentProvisioner,
			{ type: 'canceledProvisioning' },
			null,
			null,
		);
		return;
	}

	this._provisioning = true;

	this.stopSearching(function(err) {
		console.log('Connecting to device: ' + id);

		let timeoutFired = false;

		const connectToDeviceTimeout = setTimeout(function() {
			timeoutFired = true;

			currentProvisioner._provisioning = false;
			callback.call(
				currentProvisioner,
				{ type: 'timedOutConnectingToDevice' },
				null,
				null,
			);
			return;
		}, 1000 * 60 * 2); //Wait to minutes before giving up

		this.bleCentral.connectToDeviceByAddress(id, function(err) {
			if (timeoutFired) {
				return;
			}

			clearTimeout(connectToDeviceTimeout);

			if (err) {
				currentProvisioner._provisioning = false;
				callback.call(
					currentProvisioner,
					{ type: 'failedToConnectToDevice', err: err },
					null,
					null,
				);
				return;
			}

			console.log('Connected to device: ' + id);

			currentProvisioner._authenticateDevice(id, function(
				err,
				authenticated,
			) {
				if (!authenticated) {
					currentProvisioner.bleCentral.disconnectFromDeviceByAddress(
						id,
						function(otherErr) {
							currentProvisioner._provisioning = false;
							callback.call(
								currentProvisioner,
								{ type: 'unableToAuthenticateDevice' },
								null,
								null,
							);
						},
					);

					return;
				}

				if (currentProvisioner._cancelProvisioning) {
					currentProvisioner.bleCentral.disconnectFromDeviceByAddress(
						id,
						function(otherErr) {
							currentProvisioner._provisioning = false;
							callback.call(
								currentProvisioner,
								{ type: 'canceledProvisioning' },
								null,
								null,
							);
						},
					);

					return;
				}

				currentProvisioner._getIdentityType(id, function(
					err,
					type,
					extraSettings,
				) {
					if (type !== 0x01) {
						currentProvisioner.bleCentral.disconnectFromDeviceByAddress(
							id,
							function(otherErr) {
								currentProvisioner._provisioning = false;
								callback.call(
									currentProvisioner,
									{ type: 'unsupportedIdenitificationType' },
									null,
									null,
								);
							},
						);

						return;
					}

					if (currentProvisioner._cancelProvisioning) {
						currentProvisioner.bleCentral.disconnectFromDeviceByAddress(
							id,
							function(otherErr) {
								currentProvisioner._provisioning = false;
								callback.call(
									currentProvisioner,
									{ type: 'canceledProvisioning' },
									null,
									null,
								);
							},
						);

						return;
					}

					this._unlockRegistrationInfo(id, function(err) {
						if (err) {
							currentProvisioner.bleCentral.disconnectFromDeviceByAddress(
								id,
								function(otherErr) {
									currentProvisioner._provisioning = false;
									callback.call(
										currentProvisioner,
										err,
										null,
										null,
									);
								},
							);

							return;
						}

						if (currentProvisioner._cancelProvisioning) {
							currentProvisioner.bleCentral.disconnectFromDeviceByAddress(
								id,
								function(otherErr) {
									currentProvisioner._provisioning = false;
									callback.call(
										currentProvisioner,
										{ type: 'canceledProvisioning' },
										null,
										null,
									);
								},
							);

							return;
						}

						//After this point we should just let it go through for provisioning
						//so we are ignoring the cancel provisioning after this point
						this._setDateTime(id, null, function(err) {
							if (err) {
								currentProvisioner.bleCentral.disconnectFromDeviceByAddress(
									id,
									function(otherErr) {
										currentProvisioner._provisioning = false;
										callback.call(
											currentProvisioner,
											err,
											null,
											null,
										);
									},
								);

								return;
							}

							this._basicCentralConfirmIdentity(id, function(
								err,
								status,
							) {
								if (err) {
									currentProvisioner.bleCentral.disconnectFromDeviceByAddress(
										id,
										function(otherErr) {
											currentProvisioner._provisioning = false;
											callback.call(
												currentProvisioner,
												err,
												null,
												null,
											);
										},
									);

									return;
								}

								if (status !== 0x00) {
									currentProvisioner.bleCentral.disconnectFromDeviceByAddress(
										id,
										function(otherErr) {
											currentProvisioner._provisioning = false;
											callback.call(
												currentProvisioner,
												{
													type:
														'centralUnableToConfirmIdentity',
												},
												null,
												null,
											);
											return;
										},
									);

									return;
								}

								_mainContainer.getOrganizationId((err, orgId) => {
								
									const registerData = {
										projectUuid: projectUuid,
										versionUuid: null,
										protocol: currentProvisioner.TYPE,
										protocols: extraSettings,
										name: newName,
										organizationId: orgId,
									};

									const api = _mainContainer._apiv2.apis;
									const device = new Device(api, registerData);
									device
										.create()
										.then((data) => {
											const deviceAPIBase =
												_mainContainer._globalConfig
													.deviceAPIBase || getAPIBase();

											console.log('Device registered ', data);

											currentProvisioner._setRegistrationInfo(
												id,
												data.uuid,
												data.token,
												deviceAPIBase,
												function(err, status) {
													currentProvisioner.bleCentral.disconnectFromDeviceByAddress(
														id,
														function(err) {
															currentProvisioner._provisioning = false;
															callback.call(
																currentProvisioner,
																err,
																data,
																extraSettings,
															);
														},
													);

													return;
												},
											);
										})
										.catch((err) => {
											currentProvisioner.bleCentral.disconnectFromDeviceByAddress(
												id,
												function(otherErr) {
													currentProvisioner._provisioning = false;
													callback.call(
														currentProvisioner,
														{
															type:
																'failedToProvisionDevice',
															err: err,
														},
														null,
														null,
													);
												},
											);
										});
								});
							});
						});
					});
				});
			});
		});
	});
};

ProvisionerBLE.prototype.remove = function(callback) {
	this.stopSearching(callback);
};

ProvisionerBLE.prototype.$_$ = function(done) {
	function BLECentralBackendTesting() {
		BLECentralBackendBase.call(this);
	}

	const results = {};

	BLECentralBackendTesting.prototype = Object.create(
		BLECentralBackendBase.prototype,
	);
	BLECentralBackendTesting.prototype.constructor = BLECentralBackendTesting;

	BLECentralBackendTesting.prototype.IS_AVAILABLE = function() {
		return true;
	};

	BLECentralBackendTesting.prototype.connectToDeviceByServiceUuid = function(
		primaryServiceUuid,
		callback,
	) {
		callback.call(this, { type: 'bleFunctionNotSupported' });
	};

	BLECentralBackendTesting.prototype.connectToDeviceByAddress = function(
		address,
		callback,
	) {
		if (address !== 'testing') {
			callback.call(this, { type: 'expectedAddressToBeTesting' });
			return;
		}

		results.connectToDeviceByAddress = true;

		callback.call(this, false);
	};

	BLECentralBackendTesting.prototype.disconnectFromDeviceByPrimaryServiceUuid = function(
		primaryServiceUuid,
		callback,
	) {
		callback.call(this, { type: 'bleFunctionNotSupported' });
	};

	BLECentralBackendTesting.prototype.read = function(
		address,
		bleServiceUuid,
		bleCharacteristicUuid,
		callback,
	) {
		callback.call(this, { type: 'bleFunctionNotSupported' });
	};

	BLECentralBackendTesting.prototype.write = function(
		address,
		bleServiceUuid,
		bleCharacteristicUuid,
		rawValue,
		callback,
	) {
		callback.call(this, { type: 'bleFunctionNotSupported' });
	};

	BLECentralBackendTesting.prototype.writeWithoutResponse = function(
		address,
		bleServiceUuid,
		bleCharacteristicUuid,
		rawValue,
		callback,
	) {
		callback.call(this, { type: 'bleFunctionNotSupported' });
	};

	BLECentralBackendTesting.prototype.subscribe = function(
		address,
		bleServiceUuid,
		bleCharacteristicUuid,
		callback,
	) {
		callback.call(this, { type: 'bleFunctionNotSupported' });
	};

	BLECentralBackendTesting.prototype.unsubscribe = function(
		address,
		bleServiceUuid,
		bleCharacteristicUuid,
		callback,
	) {
		callback.call(this, { type: 'bleFunctionNotSupported' });
	};

	BLECentralBackendTesting.prototype.isScanning = function(callback) {
		callback.call(this, { type: 'bleFunctionNotSupported' });
	};

	BLECentralBackendTesting.prototype.startScanning = function(callback) {
		results.startScanning = true;

		const currentBackend = this;

		setTimeout(function() {
			currentBackend.event('advertisement', {
				primaryServiceUuid: 'f7873f80-86eb-8841-d192-b5d76097e89c',
				localName: 'P0000',
				address: 'testing',
				rssi: '-20',
				mac: '11:22:33:44:55',
			});
		}, 100);

		callback.call(this, false);
	};

	BLECentralBackendTesting.prototype.stopScanning = function(callback) {
		callback.call(this, { type: 'bleFunctionNotSupported' });
	};

	BLECentralBackendTesting.prototype.getDiscovered = function(callback) {
		callback.call(this, { type: 'bleFunctionNotSupported' });
	};

	BLECentralBackendTesting.prototype.getServices = function(
		address,
		callback,
	) {
		callback.call(this, { type: 'bleFunctionNotSupported' });
	};

	BLECentralBackendTesting.prototype.getServiceCharacteristics = function(
		address,
		serviceUuid,
		callback,
	) {
		callback.call(this, { type: 'bleFunctionNotSupported' });
	};

	BLECentralBackendTesting.prototype.remove = function(callback) {
		callback.call(this, { type: 'bleFunctionNotSupported' });
	};

	const SELECT_BACKEND = PluginBLECentral.prototype.SELECT_BACKEND;

	PluginBLECentral.prototype.SELECT_BACKEND = function() {
		const SELECTED_BACKEND = BLECentralBackendTesting;
		const BACKENDS = PluginBLECentral.prototype.BACKENDS;

		console.log('PluginBLECentral: Using ' + SELECTED_BACKEND.name);

		PluginBLECentral.prototype = Object.create(SELECTED_BACKEND.prototype);
		PluginBLECentral.prototype.constructor = PluginBLECentral;
		PluginBLECentral.prototype.SELECT_BACKEND = SELECT_BACKEND;
		PluginBLECentral.prototype.SELECTED_BACKEND = SELECTED_BACKEND;
		PluginBLECentral.prototype.BACKENDS = BACKENDS;

		return SELECTED_BACKEND;
	};

	const api = new CloudApi();

	const provisionerBLE = new ProvisionerBLE(api);

	provisionerBLE.startAutoProvisioning(
		'P0000',
		'f7873f80-86eb-8841-d192-b5d76097e89c',
		10000,
		function(err) {
			console.log(results);

			done(err);
		},
	);
};
