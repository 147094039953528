function ElementControllerCloudLogicBase(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);
}

ElementControllerCloudLogicBase.prototype = Object.create(
	ElementControllerBase.prototype,
);
ElementControllerCloudLogicBase.prototype.constructor = ElementControllerCloudLogicBase;

ElementControllerCloudLogicBase.prototype.TYPE = 'CloudLogicBase';
ElementControllerCloudLogicBase.prototype.HIDDEN = true;
ElementControllerCloudLogicBase.prototype.CATEGORY = 'cloudLogic';
ElementControllerCloudLogicBase.prototype.VARIANTS = ['cloud'];

ElementControllerCloudLogicBase.prototype.language = deepAssign(
	{},
	ElementControllerBase.prototype.language,
	{
		'en-US': {
			CloudLogicBase: 'Cloud Logic Base',
		},
	},
);
