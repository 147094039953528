function ElementControllerCloudAWSLambda(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudAWSBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('value', null, null, 'none', null, null);

	this.addAbility('invoke', [
		{ name: 'functionName', validator: null },
		{ name: 'payload', validator: null },
	]);

	this.addTrigger('invoked');
}

ElementControllerCloudAWSLambda.prototype = Object.create(
	ElementControllerCloudAWSBase.prototype,
);
ElementControllerCloudAWSLambda.prototype.constructor = ElementControllerCloudAWSLambda;

ElementControllerCloudAWSLambda.prototype.NAME = 'AWSLambda';
ElementControllerCloudAWSLambda.prototype.TYPE = 'CloudAWSLambda';
ElementControllerCloudAWSLambda.prototype.HIDDEN = false;
ElementControllerCloudAWSLambda.prototype.DEFAULT_TRIGGER = 'invoked';
ElementControllerCloudAWSLambda.prototype.DEFAULT_ABILITY = 'invoke';
ElementControllerCloudAWSLambda.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerCloudAWSLambda.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudAWSLambda.prototype.language = deepAssign(
	{},
	ElementControllerCloudAWSBase.prototype.language,
	{
		'en-US': {
			AWSLambda: 'AWS Lambda',
			CloudAWSLambda: 'AWS Lambda',
			invoke: 'Invoke Function (invoke)',
			invoked: 'Function Invoked',
			functionName: 'Function Name',
			payload: 'Payload',
		},
	},
);
