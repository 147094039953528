function ElementControllerCloudEffect(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);
}

ElementControllerCloudEffect.prototype = Object.create(
	ElementControllerCloudBase.prototype,
);
ElementControllerCloudEffect.prototype.constructor = ElementControllerCloudEffect;

ElementControllerCloudEffect.prototype.TYPE = 'CloudEffect';
ElementControllerCloudEffect.prototype.HIDDEN = true;
ElementControllerCloudEffect.prototype.CATEGORY = 'Cloud';

ElementControllerCloudEffect.prototype.language = deepAssign(
	{},
	ElementControllerCloudBase.prototype.language,
	{
		'en-US': {
			CloudEffect: 'Cloud Effect',
		},
	},
);
