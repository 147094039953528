function WidgetDeviceGeolocation(id, api, parent, options) {
	const currentWidget = this;

	WidgetBase.call(this, id, api, parent, options);

	this.deviceMapContainerId = this.generateChildId('deviceMapContainer');
	this.latitudeInputId = this.generateChildId('latitudeInput');
	this.longitudeInputId = this.generateChildId('longitudeInput');
	this.saveChangesButtonId = this.generateChildId('saveChangesButton');
	this.useCurrentLocationId = this.generateChildId('useCurrentLocation');

	this.deviceMapWidget = null;

	this.renderTemplate(
		{
			deviceGeolocationLabel: getLanguageTag(this.constructor, 'name'),
			useCurrentLocationLabel:
				this.getOptions() && this.getOptions().language
					? getFromLanguageObject(
							this.getOptions().language,
							'useCurrentLocation',
					  )
					: this.getLanguageTag('useCurrentLocation'),
			useCurrentLocationId: this.useCurrentLocationId,
			latitudeLabel: getLanguageTag(this.constructor, 'latitude'),
			latitude: getLanguageTag(this.constructor, 'loading'),
			longitudeLabel: getLanguageTag(this.constructor, 'longitude'),
			longitude: getLanguageTag(this.constructor, 'loading'),
			saveChangesLabel: getLanguageTag(this.constructor, 'saveChanges'),
			deviceMapContainerId: this.deviceMapContainerId,
			latitudeInputId: this.latitudeInputId,
			longitudeInputId: this.longitudeInputId,
			saveChangesButtonId: this.saveChangesButtonId,
		},
		WidgetDeviceGeolocation.name,
	);

	this.latitudeInput = $(`#${this.latitudeInputId}`);
	this.longitudeInput = $(`#${this.longitudeInputId}`);
	this.saveChangesButton = $(`#${this.saveChangesButtonId}`);
	this.useCurrentLocation = $(`#${this.useCurrentLocationId}`);

	this._canSetGeolocation = false;

	this.saveChangesButton.hide();
}

WidgetDeviceGeolocation.prototype = Object.create(WidgetBase.prototype);
WidgetDeviceGeolocation.prototype.constructor = WidgetDeviceGeolocation;

WidgetDeviceGeolocation.prototype.initialize = function(callback) {
	const currentWidget = this;

	this.addChildWidget(
		WidgetDeviceMap,
		this.deviceMapContainerId,
		// Don't fetch all the devices available to the user on the map
		{ dontFetchAll: this.getOptions().dontFetchAll ? true : false },
		function(err, deviceMapWidget) {
			this.deviceMapWidget = deviceMapWidget;

			this.latitudeInput.change(function() {
				currentWidget.saveChangesButton.prop('disabled', false);
			});

			this.longitudeInput.change(function() {
				currentWidget.saveChangesButton.prop('disabled', false);
			});

			this.deviceMapWidget.addEventListener('locationClicked', function(
				latLon,
			) {
				if (currentWidget._canSetGeolocation) {
					currentWidget.latitudeInput.val(latLon.lat);
					currentWidget.longitudeInput.val(latLon.lon);
					currentWidget.saveChangesButton.prop('disabled', false);
				}
			});

			this.update(function() {
				this.saveChangesButton.click(function() {
					currentWidget.getMainContainer().showConfirm(
						getLanguageTag(
							currentWidget.constructor,
							'confirmLocationSettings',
						),
						function() {
							currentWidget.saveChanges();
						},

						function() {
							currentWidget.update();
						},
					);
				});

				this.useCurrentLocation.click(function() {
					currentWidget.setToCurrentLocation();
				});

				WidgetBase.prototype.initialize.call(this, callback);
			});
		},
	);
};

WidgetDeviceGeolocation.prototype.update = function(callback) {
	callback = callback || function() {};

	const currentWidget = this;

	const deviceId = (getHashCommand() || {}).deviceId || null;
	const currentUser = this.getCurrentUser();

	this.saveChangesButton.prop('disabled', true);

	if (!navigator.geolocation) {
		this.useCurrentLocation.hide();
	} else {
		this.useCurrentLocation.show();
	}

	if (!deviceId) {
		callback.call(this, { type: 'noThingUuid' });
		return;
	}

	const api = currentWidget.getApiV2().apis;
	api.devices
		.getDevice({ id: deviceId })
		.then((deviceData) => {
			const device = new Device(api, deviceData);
			currentWidget._currentDevice = device;
			if (currentUser.ability.can('write', device)) {
				currentWidget.saveChangesButton.show();
				currentWidget.latitudeInput.prop('disabled', false);
				currentWidget.longitudeInput.prop('disabled', false);
				currentWidget
					.getContainer()
					.find('.WidgetDeviceGeolocation-DegreesUnit')
					.addClass('.WidgetDeviceGeolocation-DegreesUnit-Disabled');
				currentWidget.useCurrentLocation.show();

				currentWidget._canSetGeolocation = true;
			} else {
				currentWidget.saveChangesButton.hide();
				currentWidget.latitudeInput.prop('disabled', true);
				currentWidget.longitudeInput.prop('disabled', true);
				currentWidget
					.getContainer()
					.find('.WidgetDeviceGeolocation-DegreesUnit')
					.addClass('.WidgetDeviceGeolocation-DegreesUnit-Disabled');
				currentWidget.useCurrentLocation.hide();

				currentWidget._canSetGeolocation = false;
			}

			const { lat, lon } = device.getGeolocation();

			currentWidget.latitudeInput.val(lat);
			currentWidget.longitudeInput.val(lon);

			currentWidget.saveChangesButton.prop('disabled', true);

			currentWidget.deviceMapWidget.update(true);
			callback.call(currentWidget, false);
		})
		.catch((err) => {
			console.error('Error reading device data ', err);
			callback.call(currentWidget, err);
		});
};

WidgetDeviceGeolocation.prototype.setToCurrentLocation = function(callback) {
	callback = callback || function() {};

	const currentWidget = this;

	if (navigator.geolocation) {
		try {
			navigator.geolocation.getCurrentPosition(
				function(position) {
					currentWidget.latitudeInput.val(position.coords.latitude);
					currentWidget.longitudeInput.val(position.coords.longitude);
					currentWidget.saveChangesButton.prop('disabled', false);

					callback.call(currentWidget, false);
					return;
				},

				function(err) {
					currentWidget
						.getMainContainer()
						.showPopupErrorMessage(
							getLanguageTag(
								currentWidget.constructor,
								'geoLocationNotAccessible',
							),
						);
					callback.call(currentWidget, err);
				},
			);
		} catch (err) {
			currentWidget
				.getMainContainer()
				.showPopupErrorMessage(
					getLanguageTag(
						currentWidget.constructor,
						'geoLocationNotAccessible',
					),
				);
			callback.call(currentWidget, err);
			return;
		}
	}
};

WidgetDeviceGeolocation.prototype.setValues = function(latLon, pending) {
	const { lat, lon } = latLon;
	this.latitudeInput.val(lat);
	this.longitudeInput.val(lon);
	this.deviceMapWidget.setCoordinate(latLon, pending);
};

WidgetDeviceGeolocation.prototype.saveChanges = function(callback) {
	callback = callback || function() {};

	const currentWidget = this;

	const lon = parseFloat(this.longitudeInput.val());
	const lat = parseFloat(this.latitudeInput.val());

	const api = currentWidget.getApiV2().apis;

	api.devices
		.getDevice({ id: currentWidget._currentDevice.data.id })
		.then((device) => {
			currentWidget._currentDevice = new Device(api, device);
			currentWidget._currentDevice.setGeolocation(lat, lon);
			return currentWidget._currentDevice.save();
		})
		.then(() => {
			currentWidget.update(callback);
		})
		.catch((err) => {
			console.error('Error saving settings ', err);
			callback.call(currentWidget, err);
		});
};

WidgetDeviceGeolocation.prototype.ICON = './Resources/icons/Geolocation.svg';

WidgetDeviceGeolocation.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'Device Coordinates',
			useCurrentLocation: 'Use My Location',
			latitude: 'Latitude',
			longitude: 'Longitude',
			saveChanges: 'Save Changes',
			confirmLocationSettings:
				"Use these coordinates for the device's geolocation?",
			geoLocationNotAccessible: 'Geolocation data not accessible',
		},
	},
);
