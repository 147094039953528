function ElementControllerCloudAWSDynamoDB(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudAWSBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('tableName', null, '', 'text', null, null);

	this.addAbility('get', [{ name: 'key', validator: null }]);
	this.addAbility('put', [{ name: 'item', validator: null }]);
	this.addAbility('update', [
		{ name: 'key', validator: null },
		{ name: 'updateExpression', validator: null },
		{ name: 'expressionAttributeNames', validator: null },
		{ name: 'expressionAttributeValues', validator: null },
	]);
	this.addAbility('delete', [{ name: 'key', validator: null }]);

	this.addTrigger('gotten');
	this.addTrigger('placed');
	this.addTrigger('updated');
	this.addTrigger('deleted');
}

ElementControllerCloudAWSDynamoDB.prototype = Object.create(
	ElementControllerCloudAWSBase.prototype,
);
ElementControllerCloudAWSDynamoDB.prototype.constructor = ElementControllerCloudAWSDynamoDB;

ElementControllerCloudAWSDynamoDB.prototype.NAME = 'AWSDynamoDB';
ElementControllerCloudAWSDynamoDB.prototype.TYPE = 'CloudAWSDynamoDB';
ElementControllerCloudAWSDynamoDB.prototype.HIDDEN = false;
ElementControllerCloudAWSDynamoDB.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudAWSDynamoDB.prototype.language = deepAssign(
	{},
	ElementControllerCloudAWSBase.prototype.language,
	{
		'en-US': {
			CloudAWSDynamoDB: 'AWS DynamoDB',
			tableName: 'Table Name',
			key: 'Key',
			item: 'Item',
			get: 'Get Item (getItem)',
			put: 'Put Item (putItem)',
			update: 'Update Item (updateItem)',
			delete: 'Delete Item (deleteItem)',
			updateExpression: 'Update Expression',
			expressionAttributeNames: 'Expression Attribute Names',
			expressionAttributeValues: 'Expression Attribute Values',
			gotten: 'Item Received',
			placed: 'Item Placed',
			updated: 'Item Updated',
			deleted: 'Item Deleted',
		},
	},
);
