function WidgetUserAccountSecurityChangePassword(id, api, parent, options) {
	WidgetBase.call(this, id, api, parent, options);

	// ID Assignment
	this.changePasswordFormContainerId = this.generateChildId(
		'changePasswordFormContainerId',
	);
	this.changePasswordFooterButtonsContainerId = this.generateChildId(
		'changePasswordFooterButtonsContainerId',
	);

	this._formWidget = null;
	this._footerWidget = null;

	this.$updatePasswordButton = null;
	this.$invalidMessageContainer = null;
}

WidgetUserAccountSecurityChangePassword.prototype = Object.create(
	WidgetBase.prototype,
);
WidgetUserAccountSecurityChangePassword.prototype.constructor = WidgetUserAccountSecurityChangePassword;

WidgetUserAccountSecurityChangePassword.prototype.initialize = function(
	callback,
) {
	var currentWidget = this;

	this.update(function(err, data) {
		var formOptions = this.getFormOptions();

		var footerButtonsOptions = this.getFooterButtonsOptions();

		this.addChildWidget(
			WidgetSettingsForm,
			this.changePasswordFormContainerId,
			formOptions,
			function(err, formWidget) {
				currentWidget._formWidget = formWidget;
				currentWidget.$invalidMessageContainer = formWidget
					.getContainer()
					.find('.WidgetSettingsForm-InvalidMessage');
				currentWidget.$invalidMessageContainer.hide();

				formWidget.addEventListener('valueChanged', function(
					eventData,
				) {
					currentWidget.$invalidMessageContainer.show();
					currentWidget.$updatePasswordButton.prop(
						'disabled',
						!formWidget.validate(),
					);
				});

				this.addChildWidget(
					WidgetButtonsFooter,
					this.changePasswordFooterButtonsContainerId,
					footerButtonsOptions,
					function(err, footerWidget) {
						currentWidget._footerWidget = footerWidget;

						currentWidget.$updatePasswordButton = this.getContainer().find(
							'.WidgetUserAccountSecurityChangePassword-ChangePasswordFooterButtonsContainer-Top button',
						);
						currentWidget.$updatePasswordButton.prop(
							'disabled',
							true,
						);

						footerWidget.addEventListener(
							'footerButtonPressed',
							function(eventData) {
								// console.log(eventData);
								// eventData is NULL for now! Not sure if we would need it for something else in the future...
								currentWidget.onFooterButtonPressed(eventData);
							},
						);

						WidgetBase.prototype.initialize.call(this, callback);
						return;
					},
				);
			},
		);
	});
};

WidgetUserAccountSecurityChangePassword.prototype.update = function(callback) {
	var currentWidget = this;

	this.render(function(err, data) {
		callback.call(currentWidget, false);
		return;
	});
};

WidgetUserAccountSecurityChangePassword.prototype.getTemplateContext = function(
	callback,
) {
	var currentWidget = this;

	callback = callback || function() {};

	var context = {};

	context.language = {
		changePassword: getLanguageTag(this.constructor, 'changePassword'),
	};

	context.rootIds = {
		changePasswordFormContainerId: this.changePasswordFormContainerId,
		changePasswordFooterButtonsContainerId: this
			.changePasswordFooterButtonsContainerId,
	};

	callback.call(this, false, context);
	return context;
};

WidgetUserAccountSecurityChangePassword.prototype.render = function(callback) {
	var currentWidget = this;

	callback = callback || function() {};

	var context = this.getTemplateContext(function() {});
	var templateName = this.constructor.name;
	var container = this.getContainer();

	this.renderTemplate(context, templateName, container);

	if (currentWidget.$invalidMessageContainer) {
		currentWidget.$invalidMessageContainer.hide();
	}

	if (currentWidget.$updatePasswordButton) {
		currentWidget.$updatePasswordButton.prop('disabled', true);
	}

	// console.log(this._footerWidget);

	callback.call(this, false, null);
	return;
};

WidgetUserAccountSecurityChangePassword.prototype.onFooterButtonPressed = function(
	eventData,
) {
	const currentWidget = this;
	const { currentPassword, newPassword } = this._formWidget.getValues();

	this.sendChangePasswordData(currentPassword, newPassword, function(
		err,
		data,
	) {
		if (err) {
			if (err && err.type === 'accessDenied') {
				this.getMainContainer().showPopupErrorMessage(
					this.getLanguageTag('incorrectCurrentPassword'),
				);
				return;
			}
		}

		if (data && data.status === 400) {
			currentWidget
				.getMainContainer()
				.showPopupErrorMessage(
					getLanguageTag(currentWidget.constructor, data.statusText),
				);
			return;
		}

		if (data && data.status === 403) {
			currentWidget
				.getMainContainer()
				.showPopupErrorMessage(
					getLanguageTag(
						currentWidget.constructor,
						'incorrectCurrentPassword',
					),
				);
			return;
		}

		if (data && data.status === 500) {
			currentWidget
				.getMainContainer()
				.showPopupErrorMessage(
					getLanguageTag(
						currentWidget.constructor,
						'internalServerError',
					),
				);
			return;
		}

		currentWidget
			.getMainContainer()
			.showPopupMessage(
				currentWidget.getLanguageTag('passwordHasBeenSet'),
				'green',
				3000,
				function() {},
			);
		// AI-3639: Do not keep a session in place once the password has been changed.
		currentWidget.getMainContainer().logoutUser();
		return;
	});
};

WidgetUserAccountSecurityChangePassword.prototype.validatePasswords = function() {
	const currentWidget = this;

	if (!currentWidget._formWidget) {
		// Need to wait for form widget to actually initialize.
		return;
	}

	const {
		newPassword,
		confirmNewPassword,
	} = currentWidget._formWidget.getValues();

	if (
		!newPassword.replace(/\s/g, '').length ||
		!confirmNewPassword.replace(/\s/g, '').length
	) {
		return false;
	}

	return newPassword === confirmNewPassword;
};

WidgetUserAccountSecurityChangePassword.prototype.getFormOptions = function() {
	const currentWidget = this;

	return {
		fields: [
			{
				name: 'currentPassword',
				type: 'password',
				label: getLanguageTag(this.constructor, 'currentPassword'),
			},
			{
				name: 'newPassword',
				type: 'password',
				label: getLanguageTag(this.constructor, 'newPassword'),
				validator: function(value) {
					return currentWidget.validatePasswords();
				},
			},
			{
				name: 'confirmNewPassword',
				type: 'password',
				label: getLanguageTag(this.constructor, 'confirmNewPassword'),
				validator: function(value) {
					return currentWidget.validatePasswords();
				},
			},
		],
	};
};

WidgetUserAccountSecurityChangePassword.prototype.getFooterButtonsOptions = function() {
	return {
		buttons: [
			{
				label: getLanguageTag(this.constructor, 'updatePassword'),
				value: 'updatePassword',
				classes: ['btn-primary'],
			},
		],
	};
};

WidgetUserAccountSecurityChangePassword.prototype.sendChangePasswordData = function(
	currentPassword,
	newPassword,
	callback,
) {
	const currentWidget = this;

	const data = JSON.stringify({
		currentPassword,
		newPassword,
	});

	//TODO: Why is this calling postDirect? Why didn't we just follow the pattern...
	currentWidget
		.getAPI()
		.getAPIRoute('/auth/change_password/direct/')
		.postDirect(
			data,
			{ dataType: 'JSON', contentType: 'application/json' },
			(err, results) => {
				if (err) {
					if (err.status === 403) {
						callback.call(
							currentWidget,
							{ type: 'accessDenied' },
							results,
						);
					} else if (err.status === 400) {
						callback.call(
							currentWidget,
							{ type: 'badRequest' },
							results,
						);
					} else {
						callback.call(
							currentWidget,
							{ type: 'serverError' },
							results,
						);
					}
				} else {
					callback.call(currentWidget, false, results);
				}
			},
		);
};

WidgetUserAccountSecurityChangePassword.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'WidgetUserAccountSecurityChangePassword',
			changePassword: 'Change Password',
			currentPassword: 'Current Password',
			newPassword: 'New Password',
			confirmNewPassword: 'Confirm New Password',
			updatePassword: 'Update Password',
			incorrectCurrentPassword:
				'The password provided was incorrect. Please try again.',
			passwordHasBeenSet: 'Password updated',
			badNewPassword: 'Invalid new password',
			invalidPassword:
				'Invalid password(s) provided. Please check your inputs',
		},
	},
);

WidgetUserAccountSecurityChangePassword.prototype.$_$ = function(done) {
	//Testing function for WidgetUserAccountSecurityChangePassword

	this.$_SetupWidgetTest(function(err, currentWidget) {
		WidgetBase.prototype.$_$.call(this, done);
	});
};
