function LocationBase(id, api, parentWidget, options) {
	WidgetBase.call(this, id, api, parentWidget, options);

	this.titleLabelId = this.generateChildId('titleLabel');
	this.headerSideContainerId = this.generateChildId('headerSideContainer');

	this._tabsHeaderIdToContentId = {};
	this._contentIdToSection = {};
}

LocationBase.prototype = Object.create(WidgetBase.prototype);
LocationBase.prototype.constructor = LocationBase;

LocationBase.prototype.initialize = function(callback) {
	WidgetBase.prototype.initialize.call(this, callback);
};

LocationBase.prototype.getParentLocation = function() {
	return null;
};

LocationBase.prototype.setLocationToParent = function() {
	this.getMainContainer().setLocation(this.getParentLocation());
};

LocationBase.prototype.getSelectedTab = function() {
	for (var k in this._tabsHeaderIdToContentId) {
		if ($(`#${k}`).hasClass('LocationBase-TabHeaderEntry-Selected')) {
			return k;
		}
	}

	return null;
};

LocationBase.prototype.setSelectedTab = function(tabHeaderIdOrChildId) {
	for (var k in this._tabsHeaderIdToContentId) {
		$(`#${this._tabsHeaderIdToContentId[k]}`).hide();
		$(`#${k}`).removeClass('LocationBase-TabHeaderEntry-Selected');
	}

	if (!this._tabsHeaderIdToContentId[tabHeaderIdOrChildId]) {
		var currentHeaderId = this.generateChildId(
			tabHeaderIdOrChildId + '_header',
		);
		$(`#${this._tabsHeaderIdToContentId[currentHeaderId]}`).show();
		$(`#${currentHeaderId}`).addClass(
			'LocationBase-TabHeaderEntry-Selected',
		);

		var hashCommand = getHashCommand() || {};
		hashCommand.section = tabHeaderIdOrChildId;
		this.getMainContainer().forceHashCommand(hashCommand);
	} else {
		$(`#${this._tabsHeaderIdToContentId[tabHeaderIdOrChildId]}`).show();
		$(`#${tabHeaderIdOrChildId}`).addClass(
			'LocationBase-TabHeaderEntry-Selected',
		);

		var hashCommand = getHashCommand() || {};
		hashCommand.section = this._contentIdToSection[tabHeaderIdOrChildId];
		this.getMainContainer().forceHashCommand(hashCommand);
	}
};

LocationBase.prototype.setTitle = function(value, titleLink) {
	if (!this._titleLabel) {
		return;
	}

	//AI-225 Fix script injection attack on the title labels
	this._titleLabel.html(value.replace(/</g, '&lt;').replace(/>/g, '&gt;'));

	if (titleLink) {
		// Add a link to the title header if needed.
		const titleLinkHtml = `<a class="LocationBase-Title-Link" href="${titleLink}" target="_system">${value}</a>`;
		this._titleLabel.html(titleLinkHtml);
	}
};

LocationBase.prototype.getTitle = function() {
	if (!this._titleLabel) {
		return;
	}

	this._titleLabel.html();
};

LocationBase.prototype.showTitle = function() {
	this.setTitleVisible(true);
};

LocationBase.prototype.hideTitle = function() {
	this.setTitleVisible(false);
};

LocationBase.prototype.setTitleVisible = function(visible) {
	if (visible) {
		this._titleLabel.show();
	} else {
		this._titleLabel.hide();
	}
};

LocationBase.prototype._checkToHideTabsEntries = function() {
	if (this.getOptions().alwaysShowTabEntries) {
		return;
	}

	if (Object.keys(this._tabsHeaderIdToContentId).length <= 1) {
		this.getContainer()
			.find('.LocationBase-TabHeaderEntry')
			.hide();
	} else {
		this.getContainer()
			.find('.LocationBase-TabHeaderEntry')
			.show();
	}
};

LocationBase.prototype.addHeaderWidget = function(
	widgetConstructor,
	options,
	callback,
) {
	this.removeChildWidget(this.headerSideContainerId, function() {
		return this.addChildWidget(
			widgetConstructor,
			this.headerSideContainerId,
			options,
			callback,
		);
	});
};

LocationBase.prototype.removeHeaderWidget = function(callback) {
	return this.removeChildWidget(this.headerSideContainerId, callback);
};

LocationBase.prototype.addTabbedWidget = function(
	widgetConstructor,
	label,
	childId,
	options,
	callback,
) {
	var currentWidget = this;

	options = options || {};

	widgetConstructor.prototype.hasPermission(function(err, allowed) {
		if (!allowed) {
			callback.call(currentWidget, { type: 'permissionDenied' }, null);
			return;
		}

		if (typeof widgetConstructor !== 'function') {
			console.error(
				'LocationBase: setTabWidgets got invalid widget constructor for argument',
			);
			return;
		}

		var currentChildId = childId || widgetConstructor.name;
		var currentContentId = currentWidget.generateChildId(currentChildId);
		var currentHeaderId = currentWidget.generateChildId(
			currentChildId + '_header',
		);
		var currentLabel = label || getLanguageTag(widgetConstructor, 'name');

		currentWidget._contentIdToSection[currentHeaderId] = currentChildId;

		var tabEntryContext = {
			idHeader: currentHeaderId,
			idContent: currentContentId,
			label: currentLabel,
			imageSrc: options.icon || widgetConstructor.prototype.ICON,
		};

		currentWidget
			.getContainer()
			.find('.LocationBase-TabsHeader')
			.append(
				$.handlebarTemplates[LocationBase.name + '_TabHeader'](
					tabEntryContext,
				),
			);
		currentWidget
			.getContainer()
			.find('.LocationBase-TabsContent')
			.append(
				$.handlebarTemplates[LocationBase.name + '_TabContent'](
					tabEntryContext,
				),
			);

		currentWidget._tabsHeaderIdToContentId[tabEntryContext.idHeader] =
			tabEntryContext.idContent;

		$(`#${currentHeaderId}`).click(function() {
			currentWidget.setSelectedTab(this.id);
		});

		currentWidget._checkToHideTabsEntries();

		if (!currentWidget.getSelectedTab()) {
			currentWidget.setSelectedTab(childId);
		}

		return currentWidget.addChildWidget(
			widgetConstructor,
			tabEntryContext.idContent,
			options,
			callback,
		);
	});
};

LocationBase.prototype.removeTabbedWidget = function(
	idChildIdOrWidget,
	callback,
) {
	var currentChildId = idChildIdOrWidget.name || idChildIdOrWidget.toString();
	var currentContentId = this.generateChildId(currentChildId);
	var currentHeaderId = this.generateChildId(currentChildId + '_header');

	if (this._tabsHeaderIdToContentId[currentHeaderId] === undefined) {
		currentContentId = this.generateChildId(idChildIdOrWidget);
		currentHeaderId = this.generateChildId(idChildIdOrWidget + '_header');

		if (this._tabsHeaderIdToContentId[currentHeaderId] === undefined) {
			console.error(
				`No such tabbed widget with id of "${idChildIdOrWidget.name ||
					idChildIdOrWidget}"!`,
			);
			return;
		}
	}

	this.removeChildWidget(currentContentId, function(err) {
		$(`#${currentHeaderId}`).remove();
		$(`#${currentContentId}`).remove();

		delete this._contentIdToSection[currentChildId];

		this._checkToHideTabsEntries();

		callback.call(this, err);
	});
};

LocationBase.prototype.setTabWidgets = function(
	labelTitle,
	widgetEntries,
	callback,
) {
	/*
	 * this.setTabWidgets(null,
	 *	[
	 *		{
	 *			childId:'deviceTable', constructor:WidgetDeviceTable, options:{}
	 *		}
	 *	], function(err) {
	 *
	 *	LocationBase.prototype.initialize.call(this, callback);
	 * });
	 */

	var defaultSection = getHashCommand().section || null;

	widgetEntries = widgetEntries || [];
	labelTitle = labelTitle || null;

	this.getContainer().hide();

	this.removeAllChildrenWidgets(function(err) {
		var currentWidget = this;

		currentWidget.renderTemplate(
			{
				labelTitle:
					labelTitle || getLanguageTag(this.constructor, 'name'),
				titleLabelId: this.titleLabelId,
				headerSideContainerId: this.headerSideContainerId,
			},
			LocationBase.name,
		);

		this._titleLabel = $(`#${this.titleLabelId}`);

		var originalTabEntries = widgetEntries.slice();
		var tabEntries = widgetEntries.slice();

		function _setTabWidgetsHelper() {
			if (tabEntries.length <= 0) {
				var foundDefaultSection = false;

				if (defaultSection && originalTabEntries.length > 0) {
					for (var i = 0; i < originalTabEntries.length; i++) {
						if (defaultSection === originalTabEntries[i].childId) {
							currentWidget.setSelectedTab(
								originalTabEntries[i].childId,
							);
							foundDefaultSection = true;
							break;
						}
					}
				}

				if (!foundDefaultSection && originalTabEntries.length > 0) {
					currentWidget.setSelectedTab(
						originalTabEntries[0].childId ||
							originalTabEntries[0].constructor.name,
					);
				}

				currentWidget.getContainer().show();
				callback.call(currentWidget, false);
				return;
			}

			var currentEntry = tabEntries.shift();

			currentWidget.addTabbedWidget(
				currentEntry.constructor,
				currentEntry.label,
				currentEntry.childId,
				currentEntry.options,
				function(err) {
					_setTabWidgetsHelper();
				},
			);
		}

		_setTabWidgetsHelper();
	});
};

LocationBase.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'BaseLocation',
		},
	},
);
