function ElementControllerEmbeddedComparison(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.conditionList = [
		'ATMO_LESS_THAN',
		'ATMO_LESS_THAN_EQUAL',
		'ATMO_GREATER_THAN',
		'ATMO_GREATER_THAN_EQUAL',
		'ATMO_EQUAL',
	];
	this.addProperty(
		'condition',
		null,
		'ATMO_EQUAL',
		'select',
		this.conditionList,
		null,
	);
	this.addProperty('target', null, 0, 'text', null, null);
	this.setPropertyEmbeddedConversion('target', 'string');

	this.addTrigger('conditionTrue');
	this.addTrigger('conditionFalse');
	this.addTrigger('invalidType');

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('evaluate', [], []);
	this.setAbilityCode(
		'evaluate',
		`
	ATMO_BOOL_t conditionTrue = false;

	// Convert property to same type as input
	ATMO_Value_t targetStr;
	ATMO_Value_t targetNum;
	
	ATMO_InitValue(&targetStr);
	ATMO_InitValue(&targetNum);
	ATMO_CreateValueString(&targetStr, ATMO_PROPERTY(undefined, target));
	ATMO_CreateValueConverted(&targetNum, in->type, &targetStr);
	
	if(ATMO_CompareValues(in, &targetNum, ATMO_PROPERTY(undefined, condition), &conditionTrue) != ATMO_Status_Success)
	{
		ATMO_Value_t value;
		ATMO_InitValue(&value);
		ATMO_TriggerHandler(ATMO_TRIGGER(undefined, invalidType), NULL);
		ATMO_FreeValue(&value);
		ATMO_FreeValue(&targetStr);
		ATMO_FreeValue(&targetNum);
		return ATMO_Status_Fail;
	}

	ATMO_FreeValue(&targetStr);
	ATMO_FreeValue(&targetNum);


	ATMO_Value_t value;
	ATMO_InitValue(&value);
	ATMO_CreateValueCopy(&value, in);
	ATMO_TriggerHandler(conditionTrue ? ATMO_TRIGGER(undefined, conditionTrue) : ATMO_TRIGGER(undefined, conditionFalse), &value);
	ATMO_FreeValue(&value);		

	return ATMO_Status_Success;
	`,
	);
}

ElementControllerEmbeddedComparison.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedComparison.prototype.constructor = ElementControllerEmbeddedComparison;

ElementControllerEmbeddedComparison.prototype.TYPE = 'EmbeddedComparison';
ElementControllerEmbeddedComparison.prototype.HIDDEN = false;
ElementControllerEmbeddedComparison.prototype.DEFAULT_ABILITY = 'evaluate';
ElementControllerEmbeddedComparison.prototype.DEFAULT_TRIGGER = 'conditionTrue';
ElementControllerEmbeddedComparison.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
];
ElementControllerEmbeddedComparison.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedComparison.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedComparison: 'Comparison',
			condition: 'Condition',
			target: 'Target Value',
			ATMO_LESS_THAN: 'Less Than',
			ATMO_LESS_THAN_EQUAL: 'Less Than Or Equal',
			ATMO_GREATER_THAN: 'Greater Than',
			ATMO_GREATER_THAN_EQUAL: 'Greater Than Or Equal',
			ATMO_EQUAL: 'Equal',
			conditionTrue: 'Condition True',
			conditionFalse: 'Condition False',
			invalidType: 'Invalid Input',
			evaluate: 'Evaluate',
		},
	},
);
