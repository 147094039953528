function ElementAppForEach(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);
}

ElementAppForEach.prototype = Object.create(ElementAppBase.prototype);
ElementAppForEach.prototype.constructor = ElementAppForEach;

ElementAppForEach.prototype.iterate = function(value, callback) {
	var currentElement = this;

	function forEachIterate() {
		if (value.length <= 0) {
			callback.call(currentElement, false);
			return;
		}

		var currentValue = value.shift();

		currentElement._setProperty('value', currentValue, function(err) {
			currentElement.trigger('iteration', function(err) {
				if (err) {
				}

				forEachIterate();
			});
		});
	}

	forEachIterate();
};
