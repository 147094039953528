function AppView(api, plugins, crocRoot, controller) {
	var currentAppView = this;

	EventListener.call(this);

	this._api = api;
	this._elements = {};
	this._plugins = plugins;
	this.root = crocRoot;

	this._crocPanel = new AppViewPanel(crocRoot);
	this.root.addChild(this._crocPanel);
	this.attachController(controller, function() {});

	this.width = 1024;
	this.height = 768;

	this.editable = false;
	this.editorAlignment = false;
	this.editorGridAlignment = false;
	this.drawGuides = null;
	this.autoConnect = true;
	this.lastConnectedDeviceUuid = null;

	if (this.autoConnect) {
		this.connectToDevice(function() {});
	}
}

AppView.prototype = Object.create(EventListener.prototype);
AppView.prototype.constructor = AppView;

AppView.prototype.setAutoConnect = function(autoConnect) {
	this.autoConnect = Boolean(autoConnect);
	return;
};

AppView.prototype.connectToDevice = function(callback) {
	var currentAppView = this;

	var pluginKeys = Object.keys(this._plugins);

	if (this.lastConnectedDeviceUuid === null) {
		var currentHashCommand = getHashCommand();

		if (currentHashCommand.thingUuid === undefined) {
			callback.call(this, { type: 'invalidThingUuid' });
			return;
		}

		this.lastConnectedDeviceUuid = currentHashCommand.thingUuid;
	}

	var thingUuid = this.lastConnectedDeviceUuid;

	function connectToDeviceHelper() {
		if (pluginKeys.length <= 0) {
			callback.call(currentAppView, {});
			return;
		}

		var currentPlugin = currentAppView._plugins[pluginKeys.shift()];

		if (currentPlugin.connectToDevice !== undefined) {
			currentPlugin.connectToDevice(thingUuid, function(err) {
				if (err) {
					connectToDeviceHelper();
					return;
				}

				callback.call(currentAppView, false);
				return;
			});
		} else {
			connectToDeviceHelper();
		}
	}

	connectToDeviceHelper();
};

AppView.prototype.remove = function(callback) {
	for (var k in this._elements) {
		this._elements[k]._remove();
	}

	var currentHashCommand = getHashCommand();

	for (var k in this._plugins) {
		this._plugins[k].remove(function() {});
	}

	if (callback !== undefined) {
		callback.call(this, false);
	}
};

AppView.prototype.onElementAdded = function(controller) {
	var elementData = controller.export();

	var elementConstructorName = 'Element' + elementData.type;
	var elementConstructor = window[elementConstructorName];

	if (elementConstructor !== undefined) {
		this.addElement(
			elementData.name,
			elementConstructor,
			elementData.properties,
			elementData.triggers,
			controller,
			function() {},
		);
	}
};

AppView.prototype.onElementRemoved = function(controller) {
	var elementData = controller.export();

	this.removeElement(elementData.name, function() {});
};

AppView.prototype.onElementNameSet = function(oldName, newName) {
	this._elements[newName] = this._elements[oldName];
	delete this._elements[oldName];
	return;
};

AppView.prototype.attachController = function(controller, callback) {
	var currentAppView = this;

	this.removeAllElements(function(err) {
		this._controller = controller;

		if (this._controller !== undefined && this._controller !== null) {
			this._controller.addEventListener('elementAdded', function(data) {
				currentAppView.onElementAdded(data.controller);
			});

			this._controller.addEventListener('elementRemoved', function(data) {
				currentAppView.onElementRemoved(data.controller);
			});

			this._controller.addEventListener('elementNameSet', function(data) {
				currentAppView.onElementNameSet(data.oldName, data.newName);
			});

			this._controller.addEventListener('elementMovedToBack', function(
				data,
			) {
				currentAppView.onElementMovedToBack(data.controller.getName());
			});

			this._controller.addEventListener('elementMovedToFront', function(
				data,
			) {
				currentAppView.onElementMovedToFront(data.controller.getName());
			});
		}

		callback.call(this, false);
	});
};

AppView.prototype.onElementMovedToBack = function(elementName) {
	this._elements[elementName]._moveToBack();
};

AppView.prototype.onElementMovedToFront = function(elementName) {
	this._elements[elementName]._moveToFront();
};

AppView.prototype.setDrawGuides = function(horizontal, vertical) {
	this._crocPanel.setDrawGuides(horizontal, vertical);
};

AppView.prototype.increaseGridSize = function() {
	this._crocPanel.changeGridSizeBy(2);
};

AppView.prototype.decreaseGridSize = function() {
	this._crocPanel.changeGridSizeBy(-2);
};

AppView.prototype.getGridSize = function() {
	return this._crocPanel.getGridSize();
};

AppView.prototype.setEditorAlignment = function(alignment) {
	this.editorAlignment = Boolean(alignment);
	this._crocPanel.setShowDrawGuides(this.editorAlignment);
	this.editorGridAlignment = false;
	this._crocPanel.setShowGrid(false);
};

AppView.prototype.setEditorGridAlignment = function(gridAlignment) {
	this.editorGridAlignment = Boolean(gridAlignment);
	this._crocPanel.setShowGrid(this.editorGridAlignment);
	this.editorAlignment = false;
	this._crocPanel.setShowDrawGuides(false);
};

AppView.prototype.setEditable = function(editable) {
	var currentAppView = this;
	var currentHashCommand = getHashCommand();

	this.editable = Boolean(editable);

	for (var k in this._elements) {
		this._elements[k]._setEditable(this.editable);
	}

	if (
		this._plugins.PluginBLECentral !== undefined &&
		currentHashCommand.location === 'studio' &&
		currentHashCommand.projectUuid !== undefined
	) {
		// 		this._plugins.PluginBLECentral.connectToDeviceByServiceUuid(currentHashCommand.projectUuid, function(err){});
	} else if (
		currentHashCommand.thingUuid !== undefined &&
		this._type !== 'appStandalone'
	) {
		this._plugins.PluginBLECentral.connectToDeviceByServiceUuid(
			currentHashCommand.thingUuid,
			function(err) {},
		);
	}

	if (!this.editable) {
		this._crocPanel.setShowDrawGuides(false);
		this._crocPanel.setShowGrid(false);
	}
};

AppView.prototype.getSmallestLayout = function(callback) {
	var currentAppView = this;
	var currentElement = null;

	for (var elementName in this._elements) {
		if (this._elements[elementName]._setLayout !== undefined) {
			currentElement = this._elements[elementName];
			break;
		}
	}

	if (currentElement === null) {
		callback.call(this, { type: 'noLayoutsAreDefined' }, null, null);
		return;
	}

	currentElement._getProperty('layouts', function(err, layouts) {
		var smallestWidth = parseInt(Object.keys(layouts)[0]);
		var smallestHeight = parseInt(
			Object.keys(layouts[Object.keys(layouts)[0]])[0],
		);

		for (var width in layouts) {
			width = parseInt(width);

			if (width < smallestWidth) {
				smallestWidth = width;
			}

			for (var height in layouts[width]) {
				height = parseInt(height);

				if (height < smallestHeight) {
					smallestHeight = height;
				}
			}
		}

		callback.call(currentAppView, false, smallestWidth, smallestHeight);
		return;
	});
};

AppView.prototype.getLargestLayout = function(callback) {
	var currentAppView = this;
	var currentElement = null;

	for (var elementName in this._elements) {
		if (this._elements[elementName]._setLayout !== undefined) {
			currentElement = this._elements[elementName];
			break;
		}
	}

	if (currentElement === null) {
		callback.call(this, { type: 'noLayoutsAreDefined' }, null, null);
		return;
	}

	currentElement._getProperty('layouts', function(err, layouts) {
		var largestWidth = 0;
		var largestHeight = 0;

		for (var width in layouts) {
			width = parseInt(width);

			if (width > largestWidth) {
				largestWidth = width;
			}

			for (var height in layouts[width]) {
				height = parseInt(height);

				if (height > largestHeight) {
					largestHeight = height;
				}
			}
		}

		callback.call(currentAppView, false, largestWidth, largestHeight);
		return;
	});
};

AppView.prototype.getBestLayoutFit = function(fitWidth, fitHeight, callback) {
	/*
	 * This function will attempt to find the best layout fit for the
	 * current values presented. It does this by going through the
	 * layouts of each element and find the one that best fits to the
	 * total area of the layout without going over the width, and
	 * height provided by this function
	 *
	 */

	var currentAppView = this;
	var currentElement = null;

	var targetAspectRatio = fitWidth / fitHeight;

	for (var elementName in this._elements) {
		if (this._elements[elementName]._setLayout !== undefined) {
			currentElement = this._elements[elementName];
			break;
		}
	}

	if (currentElement === null) {
		callback.call(this, { type: 'noLayoutsAreDefined' }, null, null);
		return;
	}

	currentElement._getProperty('layouts', function(err, layouts) {
		var bestWidth = 0;
		var bestHeight = 0;
		var bestRatioOffset = Infinity;
		var bestCorrectionScale = 0;

		for (var width in layouts) {
			for (var height in layouts[width]) {
				var currentRatio = width / height;
				var currentCorrectionScale = 0;

				currentCorrectionScale = Math.min(
					fitHeight / height,
					fitWidth / width,
				);

				var currentRatioOffset = Math.abs(
					currentRatio - targetAspectRatio,
				);

				if (currentRatioOffset < bestRatioOffset) {
					bestWidth = width;
					bestHeight = height;
					bestRatioOffset = currentRatioOffset;
					bestCorrectionScale = currentCorrectionScale;
				}
			}
		}

		if (bestWidth === 0 || bestHeight === 0) {
			callback.call(currentAppView, { type: 'noLayoutFits' }, null, null);
			return;
		}

		callback.call(
			currentAppView,
			false,
			bestWidth,
			bestHeight,
			bestCorrectionScale,
		);
		return;
	});
};

AppView.prototype.setLayout = function(width, height, callback) {
	var currentAppView = this;
	var elementNames = Object.keys(this._elements);

	if (width === null || height === null) {
		callback.call(this, {});
		return;
	}

	this.width = width;
	this.height = height;

	function setLayoutHelper() {
		if (elementNames.length <= 0) {
			callback.call(currentAppView, false, width, height);
			return;
		}

		var currentElementName = elementNames.shift();

		if (
			currentAppView._elements[currentElementName]._setLayout !==
			undefined
		) {
			currentAppView._elements[currentElementName]._setLayout(
				width,
				height,
				function(err) {
					if (err) {
						currentAppView.error(err);
					}

					setLayoutHelper();
				},
			);
		} else {
			setLayoutHelper();
		}
	}

	setLayoutHelper();
};

AppView.prototype.addElement = function(
	elementName,
	elementConstructor,
	properties,
	triggers,
	controller,
	callback,
) {
	if (this._elements[elementName] !== undefined) {
		callback.call(this, { type: 'elementWithNameAlreadyExists' }, null);
		return;
	}

	var requires = {};

	if (elementConstructor.prototype.REQUIRES !== undefined) {
		for (var i = 0; i < elementConstructor.prototype.REQUIRES.length; i++) {
			var currentRequirement = this._plugins[
				elementConstructor.prototype.REQUIRES[i]
			];

			if (currentRequirement === undefined) {
				callback.call(
					this,
					{
						type: 'elementRequirementCouldNotBeMet',
						requirement: elementConstructor.REQUIRES[i],
					},
					null,
				);
				return;
			}

			requires[
				elementConstructor.prototype.REQUIRES[i]
			] = currentRequirement;
		}
	}

	var newElement = new elementConstructor(
		elementName,
		this,
		requires,
		properties,
		triggers,
		controller,
	);
	this._elements[elementName] = newElement;
	callback.call(this, false, newElement);

	return;
};

AppView.prototype.getElementByCrocObject = function(crocObject, callback) {
	if (crocObject === null) {
		callback.call(this, false, null);
		return;
	}

	for (var k in this._elements) {
		var currentElement = this._elements[k];

		if (
			currentElement._crocObject !== null &&
			currentElement._crocObject !== undefined
		) {
			if (currentElement._crocObject.uuid === crocObject.uuid) {
				callback.call(this, false, currentElement);
				return;
			} else if (crocObject.hasAncestor(currentElement._crocObject)) {
				callback.call(this, false, currentElement);
				return;
			} else if ((currentElement._editHandles.uuid = crocObject.uuid)) {
				callback.call(this, false, currentElement);
				return;
			}
		}
	}

	callback.call(this, false, null);
	return;
};

AppView.prototype.removeElement = function(elementName, callback) {
	if (this._elements[elementName] === undefined) {
		callback.call(this, { type: 'noSuchElement' });
		return;
	}

	this._elements[elementName]._remove();
	delete this._elements[elementName];

	callback.call(this, false);
	return;
};

AppView.prototype.removeAllElements = function(callback) {
	var currentAppView = this;

	var elementNames = Object.keys(this._elements);

	function removeAllElementsHelper() {
		if (elementNames.length <= 0) {
			callback.call(currentAppView, false);
			return;
		}

		var currrentElementName = elementNames.shift();

		this._elements[currentElementName].remove(function(err) {
			if (err) {
			}

			removeAllElementsHelper();
			return;
		});
	}

	removeAllElementsHelper();
};

AppView.prototype.getElement = function(elementName, callback) {
	if (this._elements[elementName] === undefined) {
		callback.call(this, { type: 'noSuchElement' }, null);
		return;
	}

	callback.call(this, false, this._elements[elementName]);
	return;
};

AppView.prototype.initializeElements = function(callback) {
	var currentAppView = this;

	var elementNames = Object.keys(this._elements);

	function initializeElementsHelper() {
		if (elementNames.length <= 0) {
			callback.call(currentAppView, false);
			return;
		}

		var currentElementName = elementNames.shift();

		currentAppView.getElement(currentElementName, function(err, element) {
			if (err) {
				initializeElementsHelper();
				return;
			}

			element._initialize(function(err) {
				initializeElementsHelper();
				return;
			});
		});
	}

	initializeElementsHelper();

	return;
};

// This is a hacky way to figure out if the project is old or not
AppView.prototype.getElementConstructor = function(elementType, triggers) {
	// v1 "cloud events" have a "sent" trigger. This is actually a "Device Event"
	if (elementType === 'AppCloudEvent') {
		if (triggers.sent !== undefined) {
			return ElementAppDeviceEvent;
		} else {
			// No sent trigger, this is actually a v2 "Cloud Event"
			return ElementAppCloudEvent;
		}
	} else if (elementType === 'AppCloudCommand') {
		return ElementAppCloudEvent;
	} else {
		return window['Element' + elementType];
	}
};

AppView.prototype.import = function(planeData, callback) {
	/*
	 * Given plane data from a project this will initialize the app view with that data.
	 *
	 */

	var currentAppView = this;

	this._type = planeData.type;

	var elements = planeData.elements.slice();

	function elementImportHelper() {
		if (elements.length <= 0) {
			currentAppView.initializeElements(callback);
			return;
		}

		var currentElementData = elements.shift();
		const currentElementConstructor = currentAppView.getElementConstructor(
			currentElementData.type,
			currentElementData.triggers,
		);

		if (
			currentElementConstructor === undefined ||
			currentElementConstructor.call === undefined
		) {
			currentAppView.error({
				type: 'elementTypeDoesNotExist',
				elementType: currentElementData.type,
			});
			elementImportHelper();
		}

		currentAppView.addElement(
			currentElementData.name,
			currentElementConstructor,
			currentElementData.properties,
			currentElementData.triggers,
			null,
			function(err) {
				if (err) {
					currentAppView.error(err);
				}

				elementImportHelper();
			},
		);
	}

	elementImportHelper();
};

// FIXME: This function is suppose to get the appview data from the cloud.
AppView.prototype.load = function(projectUuid, planeName, callback) {};
