function WifiBackendNull() {
	WifiBackendBase.call(this);
}

WifiBackendNull.prototype = Object.create(WifiBackendBase.prototype);
WifiBackendNull.prototype.constructor = WifiBackendNull;

WifiBackendNull.prototype.IS_AVAILABLE = function() {
	return true;
};

WifiBackendNull.prototype.connectNetwork = function(options, callback) {
	callback.call(this, { type: 'wifiFunctionNotSupported' });
};

WifiBackendNull.prototype.disconnectNetwork = function(ssid, callback) {
	callback.call(this, { type: 'wifiFunctionNotSupported' });
};

WifiBackendNull.prototype.getScanResults = function(callback) {
	callback.call(this, { type: 'wifiFunctionNotSupported' });
};

WifiBackendNull.prototype.getCurrentSSID = function(callback) {
	callback.call(this, { type: 'wifiFunctionNotSupported' });
};

WifiBackendBase.prototype.tcpConnect = function(
	addr,
	port,
	rxCallback,
	rxTimeoutCallback,
	rxTimeoutSeconds,
	callback,
) {
	callback.call(this, { type: 'wifiFunctionNotSupported' });
};

WifiBackendBase.prototype.tcpSend = function(data, callback) {
	callback.call(this, { type: 'wifiFunctionNotSupported' });
};

WifiBackendBase.prototype.tcpDisconnect = function(callback) {
	callback.call(this, { type: 'wifiFunctionNotSupported' });
};
