function PlaneControllerEmbeddedOnSemiRSL10(parentController, elementName) {
	PlaneControllerEmbeddedOnSemi.call(this, parentController, elementName);
}

PlaneControllerEmbeddedOnSemiRSL10.prototype = Object.create(
	PlaneControllerEmbeddedOnSemi.prototype,
);
PlaneControllerEmbeddedOnSemiRSL10.prototype.constructor = PlaneControllerEmbeddedOnSemiRSL10;

PlaneControllerEmbeddedOnSemiRSL10.prototype.VARIANTS = [
	'RSL10',
	'RSL10-SENSE-GEVK',
];
PlaneControllerEmbeddedOnSemiRSL10.prototype.SUPPORTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'interval',
	'variables',
	'gpio',
	'i2c',
	'fpmath',
	'ble',
	'debugPrint',
];
PlaneControllerEmbeddedOnSemiRSL10.prototype.CLIENT_AGENT_REQUIRED = true;

PlaneControllerEmbeddedOnSemiRSL10.prototype.DRIVERS = {
	adc: [],
	ble: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLE_BLE1',
		},
	],
	block: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLOCK_BLOCK1',
		},
	],
	datetime: [],
	filesytem: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_FILESYSTEM_FILESYSTEM1',
		},
	],
	gpio: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_GPIO_GPIO1',
		},
	],
	http: [],
	i2c: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C1',
			primary: true,
		},
	],
	interval: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_INTERVAL_INTERVAL1',
		},
	],
	nfc: [],
	pwm: [],
	spi: [],
	uart: [],
	wifi: [],
};

PlaneControllerEmbeddedOnSemiRSL10.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'RSL10_LED_RED',
		mode: 'ATMO_GPIO_PinMode_Output_PushPull',
	},

	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'RSL10_GIO',
	},

	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'RSL10_GIO',
	},

	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'RSL10_GIO',
	},

	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'RSL10_GIO',
	},
};

PlaneControllerEmbeddedOnSemiRSL10.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			defaultName: 'RSL10-SENSE-GEVK',
			ATMO_DRIVERINSTANCE_SPI_SPI1: 'SPI1',
		},
	},
);
