function ElementAppBLEConnection(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);

	var currentElement = this;

	this._pluginBLECentral = requires.PluginBLECentral;

	this._connectedEventListener = this._pluginBLECentral.addEventListener(
		'connected',
		function(e) {
			currentElement._onConnected(e);
		},
	);

	this._disconnectedEventListener = this._pluginBLECentral.addEventListener(
		'disconnected',
		function(e) {
			currentElement._onDisconnected(e);
		},
	);

	// 	if(this._properties.autoConnect === false) {
	// 		this.parentApp.setAutoConnect(false);
	// 	}
}

ElementAppBLEConnection.prototype = Object.create(ElementBase.prototype);
ElementAppBLEConnection.prototype.constructor = ElementAppBLEConnection;

ElementAppBLEConnection.prototype.REQUIRES = ['PluginBLECentral'];

ElementAppBLEConnection.prototype.connect = function(callback) {};

ElementAppBLEConnection.prototype.disconnect = function(callback) {};

ElementAppBLEConnection.prototype._onConnected = function(data) {
	this.trigger('connected', function() {});
};

ElementAppBLEConnection.prototype._onDisconnected = function(data) {
	this.trigger('disconnected', function() {});
};
