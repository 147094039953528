function WifiBackendBase() {
	EventListener.call(this);

	this._plugins = {};

	this.loadPlugins(function(err) {});
}

WifiBackendBase.prototype = Object.create(EventListener.prototype);
WifiBackendBase.prototype.constructor = WifiBackendBase;

WifiBackendBase.prototype.IS_AVAILABLE = function() {
	return false;
};

WifiBackendBase.prototype.addPlugin = function(pluginConstructor, callback) {
	if (typeof pluginConstructor !== 'function') {
		callback.call(this, { type: 'invalidPluginConstructor' }, null);
		return;
	}

	if (this._plugins[pluginConstructor.name] !== undefined) {
		callback.call(this, { type: 'pluginAlreadyAdded' }, null);
		return;
	}

	var newPlugin = new pluginConstructor(this);
	this._plugins[pluginConstructor.name] = newPlugin;

	callback.call(this, false, newPlugin);
	return newPlugin;
};

WifiBackendBase.prototype.loadPlugins = function(callback) {
	var currentBackend = this;
	var wifiPlugins = [];
	var windowKeys = Object.keys(window);

	for (i = 0; i < windowKeys.length; i++) {
		if (windowKeys[i].indexOf('WifiPlugin') === 0) {
			if (typeof window[windowKeys[i]] === 'function') {
				wifiPlugins.push(window[windowKeys[i]]);
			}
		}
	}

	var errors = false;

	function _loadPluginsHelper() {
		if (wifiPlugins.length <= 0) {
			callback.call(currentBackend, errors);
			return;
		}

		var currentPlugin = wifiPlugins.shift();

		currentBackend.addPlugin(currentPlugin, function(err) {
			if (err) {
				errors = errors || [];
				errors.push(err);
			}

			_loadPluginsHelper();
			return;
		});
	}

	_loadPluginsHelper();
	return;
};

WifiBackendBase.prototype.connectNetwork = function(options, callback) {
	callback.call(this, { type: 'wifiFunctionNotSupported' });
};

WifiBackendBase.prototype.disconnectNetwork = function(ssid, callback) {
	callback.call(this, { type: 'wifiFunctionNotSupported' });
};

WifiBackendBase.prototype.getScanResults = function(callback) {
	callback.call(this, { type: 'wifiFunctionNotSupported' });
};

WifiBackendBase.prototype.getCurrentSSID = function(callback) {
	callback.call(this, { type: 'wifiFunctionNotSupported' });
};

WifiBackendBase.prototype.tcpConnect = function(
	addr,
	port,
	rxCallback,
	rxTimeoutCallback,
	rxTimeoutSeconds,
	callback,
) {
	callback.call(this, { type: 'wifiFunctionNotSupported' });
};

WifiBackendBase.prototype.tcpSend = function(data, callback) {
	callback.call(this, { type: 'wifiFunctionNotSupported' });
};

WifiBackendBase.prototype.tcpDisconnect = function(callback) {
	callback.call(this, { type: 'wifiFunctionNotSupported' });
};
