function WidgetDeviceDataSelect(id, api, parent, options) {
	/*
	 * The WidgetDeviceDataSelect is used to pick devices,
	 * datastores in devices or variables in datastores.
	 *
	 * The options for this widget are as follows,
	 *
	 *
	 * @param options.variables variables
	 * @param options.variables[i].name The name of the variable as it'll be returned in the object from getValues()
	 * @param options.variables[i].label The label to show the user in the UI
	 * @param options.variables[i].selectMultiple Should multiple variables be selected
	 *
	 * @param options.deviceFilter This object is used to filter devices that match the key values pairs as described {},
	 * @param options.onlyDeviceSelect Used to make it only select a device true/false
	 * @param options.selectLastValues To be used for a variable select on all possible meta, lastvalues, and first class data of a device (warning only use with one variable declared in variables)
	 * @param options.confirmLabel
	 * @param options.dismissLabel
	 *
	 */

	var currentWidget = this;

	WidgetBase.call(this, id, api, parent, options);

	this.footerContainerId = this.generateChildId('footerContainer');
	this.deviceSelectId = this.generateChildId('deviceSelect');
	this.storageSelectId = this.generateChildId('storageSelect');
	this.variableSelectId = this.generateChildId('variableSelectId');
	this.labelId = this.generateChildId('labelId');

	this._footerWidget = null;
	this._deviceSelectTable = null;
	this._storageSelectMenu = null;
	this._variableSelectMenu = null;
	this._label = null;

	this._settingVariableName = null;
	this._settingVariableNumber = 0;

	this._currentValues = {
		deviceData: null,
		storageName: null,
		variables: {},
	};
}

WidgetDeviceDataSelect.prototype = Object.create(WidgetBase.prototype);
WidgetDeviceDataSelect.prototype.constructor = WidgetDeviceDataSelect;

WidgetDeviceDataSelect.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.renderTemplate(
		{
			deviceSelectId: this.deviceSelectId,
			storageSelectId: this.storageSelectId,
			variableSelectId: this.variableSelectId,
			labelId: this.labelId,
			footerContainerId: this.footerContainerId,
		},
		WidgetDeviceDataSelect.name,
	);

	this._label = $(`#${this.labelId}`);

	this.addChildWidget(
		WidgetButtonsFooter,
		this.footerContainerId,
		{},
		function(err, footerWidget) {
			const { confirmLabel, dismissLabel } = this.getOptions();
			var confirmButtonLabel =
				confirmLabel || getLanguageTag(this.constructor, 'confirm');
			var dismissButtonLabel =
				dismissLabel || getLanguageTag(this.constructor, 'dismiss');

			this._footerWidget = footerWidget;

			this._footerWidget.setButtons([
				{
					label: dismissButtonLabel,
					value: 'dismissed',
					classes: [`btn-cancel`],
				},

				{
					label: confirmButtonLabel,
					value: 'confirmed',
					classes: ['btn-primary'],
				},
			]);

			this._footerWidget.addEventListener('footerButtonPressed', function(
				value,
			) {
				currentWidget.event(value);
			});

			this.disableConfirmButton();

			this.addChildWidget(
				WidgetTable,
				this.deviceSelectId,
				{
					filterInputPlaceHolder: this.getLanguageTag(
						'filterInputPlaceHolder',
					),
					noData: this.getLanguageTag('noData'),
					showRowSelected: true,
				},

				function(err, deviceSelectMenuWidget) {
					this.addChildWidget(
						WidgetMenu,
						this.storageSelectId,
						{ disableAutoHide: true, selectableMenuEntries: true },
						function(err, storageSelectMenuWidget) {
							this.addChildWidget(
								WidgetMenu,
								this.variableSelectId,
								{
									disableAutoHide: true,
									selectableMenuEntries: true,
								},
								function(err, variableSelectMenuWidget) {
									this._deviceSelectTable = deviceSelectMenuWidget;
									this._storageSelectMenu = storageSelectMenuWidget;
									this._variableSelectMenu = variableSelectMenuWidget;

									this._deviceSelectTable.addEventListener(
										'rowClicked',
										function(data) {
											currentWidget._onDeviceSelected(
												data.rowData.value,
											);
										},
									);

									this._storageSelectMenu.addEventListener(
										'menuEntryClicked',
										function(data) {
											currentWidget._onStorageSelected(
												data,
											);
										},
									);

									this._variableSelectMenu.addEventListener(
										'menuEntryClicked',
										function(data) {
											currentWidget._onVariableSelected(
												data,
											);
										},
									);

									WidgetBase.prototype.initialize.call(
										currentWidget,
										callback,
									);
								},
							);
						},
					);
				},
			);
		},
	);
};

WidgetDeviceDataSelect.prototype.getAllDevices = async function() {
	const api = this.getApiV2().apis;
	const depth = 'all';
	const initialDevices = await api.devices.getDevices({ depth });
	const devices = [...initialDevices.data];

	const { totalPages } = initialDevices.meta || {};

	if (totalPages > 1) {
		const additionalDevicePromises = [];
		for (let i = 2; i <= totalPages; i += 1) {
			additionalDevicePromises.push(
				api.devices.getDevices({ depth, page: i }),
			);
		}

		const pages = await Promise.all(additionalDevicePromises);

		pages.forEach((page) => {
			devices.push(...page.data);
		});
	}

	return devices;
};

WidgetDeviceDataSelect.prototype.showDeviceSelect = function(callback) {
	var currentWidget = this;

	callback = callback || function() {};

	const deviceId = (getHashCommand() || {}).deviceId || null;

	if (deviceId) {
		const api = currentWidget.getApiV2().apis;
		//We are in the context of a thing so we should just jump to a storage select
		api.devices
			.getDevice({ id: deviceId })
			.then((deviceData) => {
				currentWidget._currentValues.deviceData = deviceData;
				currentWidget.showStorageSelect(callback);
			})
			.catch((err) => {
				console.error('Error getting device data ', err);
				callback.call(currentWidget, err);
			});
		return;
	}

	this._deviceSelectTable
		.getContainer()
		.parent()
		.show();
	this._storageSelectMenu
		.getContainer()
		.parent()
		.hide();
	this._variableSelectMenu
		.getContainer()
		.parent()
		.hide();

	this._label.html(getLanguageTag(this.constructor, 'selectDevice'));

	function _showDeviceSelectFinish(devices) {
		const { deviceFilter } = currentWidget.getOptions();

		if (devices !== null) {
			var tableData = [];

			//This are the extra meta keys we want to include for filtering
			var includedMetaKeys = [
				'globalstaresn',
				'serial',
				'description',
				'order',
				'sigfoxDeviceId',
			];

			var tableColumnSettings = {
				label: {
					label: currentWidget.getLanguageTag('deviceName'),
					sortable: true,
				},
				description: {
					label: currentWidget.getLanguageTag('deviceDescription'),
					sortable: true,
				},
			};

			for (var i = 0; i < devices.length; i++) {
				/*
				 * We strip out the meta data for the menu system
				 * because the value attributes we set in DOM can
				 * not be too big.
				 */

				for (var k in devices[i].meta) {
					if (includedMetaKeys.includes(k.toLowerCase())) {
						tableColumnSettings[k] = {
							label: currentWidget.getLanguageTag(k),
							sortable: true,
						};
					} else {
						delete devices[i].meta[k];
					}
				}

				var filterOutDevice = false;

				if (deviceFilter) {
					var filterKeys = Object.keys(deviceFilter);

					for (var j = 0; j < filterKeys.length; j++) {
						if (
							deviceFilter[filterKeys[j]] !==
							devices[i][filterKeys[j]]
						) {
							filterOutDevice = true;
							break;
						}
					}
				}

				if (!filterOutDevice) {
					var rowData = {
						label: devices[i].name,
						description: (devices[i].meta || {}).description || '',
						value: devices[i],
					};

					for (var k in devices[i].meta) {
						if (includedMetaKeys.includes(k.toLowerCase())) {
							rowData[k] = devices[i].meta[k];
						}
					}

					tableData.push(rowData);
				}
			}

			currentWidget._deviceSelectTable.setTable(
				['label'],
				tableColumnSettings,
				tableData,
				'ascending',
				'label',
			);
		}

		callback.call(currentWidget, false);
	}

	currentWidget
		.getAllDevices()
		.then((devices) => {
			_showDeviceSelectFinish(devices || null);
		})
		.catch((err) => {
			console.trace();
			console.error(JSON.stringify(err));
			callback.call(currentWidget, err);
		});
};

WidgetDeviceDataSelect.prototype.showStorageSelect = function(callback) {
	callback = callback || function() {};

	const currentWidget = this;
	const api = currentWidget.getApiV2();

	const { onlyDeviceSelect } = this.getOptions();

	if (onlyDeviceSelect) {
		callback.call(this, false);
		this.enableConfirmButton();
		return;
	}

	this._deviceSelectTable
		.getContainer()
		.parent()
		.hide();
	this._storageSelectMenu
		.getContainer()
		.parent()
		.show();
	this._variableSelectMenu
		.getContainer()
		.parent()
		.hide();

	this._label.html(getLanguageTag(this.constructor, 'selectStorage'));

	if (
		!this._currentValues.deviceData ||
		!this._currentValues.deviceData.uuid
	) {
		callback.call(this, { type: 'noDeviceSelected' });
		return;
	}

	api.apis.storage
		.getDeviceStorageElements({ id: this._currentValues.deviceData.id })
		.then((response) => {
			const { data } = response;

			const storages = data.map((storage) => {
				return storage.name;
			});

			const menuData = [];
			storages.sort();

			for (let i = 0; i < storages.length; i += 1) {
				menuData.push({
					label: getLanguageTag(
						currentWidget.constructor,
						storages[i],
					),
					value: storages[i],
				});
			}

			currentWidget._storageSelectMenu.setMenu(menuData);
			callback.call(currentWidget, false);
		})
		.catch((error) => {
			callback.call(currentWidget, err);
			return;
		});
};

WidgetDeviceDataSelect.prototype.showVariableSelect = function(
	variableNumber,
	callback,
) {
	variableNumber = variableNumber || 0;
	callback = callback || function() {};

	const currentWidget = this;
	const api = this.getApiV2();

	var variableOptions = this.getOptions().variables || [];

	if (variableNumber >= variableOptions.length) {
		callback.call(this, false);
		this.enableConfirmButton();
		return;
	}

	var currentVariable = variableOptions[variableNumber];

	this._settingVariableName = currentVariable.name;
	this._settingVariableNumber = variableNumber;
	this._settingVariableMultiSelect = currentVariable.selectMultiple || false;

	this._deviceSelectTable
		.getContainer()
		.parent()
		.hide();
	this._storageSelectMenu
		.getContainer()
		.parent()
		.hide();
	this._variableSelectMenu
		.getContainer()
		.parent()
		.show();

	this._label.html(currentVariable.label);

	if (
		!this._currentValues.deviceData ||
		!this._currentValues.deviceData.uuid
	) {
		callback.call(this, { type: 'noDeviceSelected' });
		return;
	}

	this._variableSelectMenu.setMultiSelectableEntries(
		this._settingVariableMultiSelect,
	);

	if (this.getOptions().selectLastValues) {
		api.apis.devices
			.getDevice({
				id: this._currentValues.deviceData.id,
				lastValues: 'all',
			})
			.then((deviceData) => {
				const menuData = [];

				const variableData = Object.assign({}, deviceData);

				delete variableData.lastValues;
				delete variableData.meta;
				delete variableData.imageUrl;
				delete variableData.protocol;
				delete variableData.protocols;
				delete variableData.statusInt;
				delete variableData.id;
				delete variableData.uuid;
				delete variableData.projectUuid;
				delete variableData.versionUuid;
				delete variableData.createdBy;

				let variableNames = Object.keys(variableData);

				let variableDataTypes = DataTypes.dataTypesFrom(
					JSON.stringify(variableData),
				);

				variableNames.sort();

				for (let i = 0; i < variableNames.length; i += 1) {
					const variableName = variableNames[i];

					let variableTag = currentWidget.getLanguageTag(
						variableName,
					);

					// This will collide with WidgetBase's language
					// object, hence the if-check
					if (variableName === 'name') {
						variableTag = currentWidget.getLanguageTag(
							'deviceNameEntry',
						);
					}

					let variableType = variableDataTypes[variableName];
					if (variableType.constructor === Object) {
						variableType = 'Object';
					}
					const variableSource = currentWidget.getLanguageTag(
						'deviceInfo',
					);

					const label = `${variableTag} (${variableType}) (${variableSource})`;
					const menuValue = `DeviceModel.${variableName}`;

					menuData.push({
						label,
						value: menuValue,
					});
				}

				const { meta } = deviceData;

				const metaTags = Object.keys(meta);
				const metaTypes = DataTypes.dataTypesFrom(JSON.stringify(meta));

				metaTags.forEach((metaTag) => {
					// const metaValue = meta[metaTag];
					const metaLangTag = currentWidget.getLanguageTag(metaTag);
					let metaType = metaTypes[metaTag];
					if (metaType.constructor === Object) {
						metaType = 'Object';
					}
					const metaSource = currentWidget.getLanguageTag(
						'deviceMeta',
					);

					const label = `${metaLangTag} (${metaType}) (${metaSource})`;

					menuData.push({
						label,
						value: `DeviceMeta.${metaTag}`,
					});
				});

				const { lastValues } = deviceData;

				lastValues.forEach((storageElement) => {
					if (
						storageElement &&
						Object.keys(storageElement) &&
						Object.keys(storageElement)[0]
					) {
						const storageName = Object.keys(storageElement)[0];
						const storageValues = storageElement[storageName];
						const dataTypes = DataTypes.dataTypesFrom(
							JSON.stringify(storageValues),
						);

						Object.keys(storageValues).forEach((value) => {
							const valueTag = currentWidget.getLanguageTag(
								value,
							);
							let dataType = dataTypes[value];
							const label = `${valueTag} (${dataType}) (${storageName})`;
							const menuValue = `LastValues.${storageName}.${value}`;

							menuData.push({ label, value: menuValue });
						});
					}
				});

				// Known bug here, it doesn't show which variables
				// were selected.
				currentWidget._variableSelectMenu.setMenu(menuData);
				callback.call(currentWidget, false);
			})
			.catch((error) => {
				callback.call(currentWidget, error);
				return;
			});
	} else {
		if (!this._currentValues.storageName) {
			callback.call(this, { type: 'noStorageNameSelected' });
			return;
		}

		api.apis.data
			.getLastDeviceData({
				id: this._currentValues.deviceData.id,
				elementName: this._currentValues.storageName,
			})
			.then((response) => {
				const { values } = response;
				// UI expects the _timestamp field
				values._timestamp = response.updatedAt;
				const menuData = [];
				const variableNames = Object.keys(values);
				const dataTypes = DataTypes.dataTypesFrom(
					JSON.stringify(values),
				);
				variableNames.sort();

				for (let i = 0; i < variableNames.length; i += 1) {
					menuData.push({
						label: `${getLanguageTag(
							currentWidget.constructor,
							variableNames[i],
						)} (${getLanguageTag(
							currentWidget.constructor,
							dataTypes[variableNames[i]],
						)})`,
						value: variableNames[i],
					});
				}

				currentWidget._variableSelectMenu.setMenu(menuData);
				callback.call(currentWidget, false);
			})
			.catch((error) => {
				callback.call(currentWidget, error);
				return;
			});
	}
};

WidgetDeviceDataSelect.prototype._onDeviceSelected = function(deviceData) {
	this._currentValues.deviceData = deviceData;

	if (this.getOptions().selectLastValues) {
		if (this.getOptions().variables.length > 1) {
			console.warn(
				'WidgetDeviceDataSelect.prototype._onDeviceSelected: Using selectLastValues option but more than one variable option was defined!',
			);
		}

		this.showVariableSelect();
		return;
	}

	this.showStorageSelect();
	return;
};

WidgetDeviceDataSelect.prototype._onStorageSelected = function(storageName) {
	this._currentValues.storageName = storageName;

	this.showVariableSelect();
};

WidgetDeviceDataSelect.prototype._onVariableSelected = function(variableName) {
	this._currentValues.variables[this._settingVariableName] = variableName;

	if (!this._settingVariableMultiSelect) {
		this.showVariableSelect(this._settingVariableNumber + 1);
	} else if (
		this._currentValues.variables[this._settingVariableName].length > 0
	) {
		this.enableConfirmButton();
	} else {
		this.disableConfirmButton();
	}
};

WidgetDeviceDataSelect.prototype.getValues = function() {
	return this._currentValues;
};

WidgetDeviceDataSelect.prototype.disableConfirmButton = function() {
	this._footerWidget.setButtonDisabled('confirmed', true);
};

WidgetDeviceDataSelect.prototype.enableConfirmButton = function() {
	this._footerWidget.setButtonDisabled('confirmed', false);
};

WidgetDeviceDataSelect.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			selectDevice: 'Select Device',
			selectStorage: 'Select Cloud Storage',
			_timestamp: 'Server Timestamp',
			Number: 'Number',
			Date: 'Date',
			_id: 'ID',
			ObjectID: 'Object ID',
			deviceName: 'Device',
			deviceDescription: 'Description',
			confirm: 'Confirm',
			dismiss: 'Back',
			filterInputPlaceHolder: 'Search for a device...',
			noData: 'No devices found',
			deviceNameEntry: 'Device Name', // so it doesnt use the language object value from WidgetBase
			deviceMeta: 'Device Meta',
			deviceInfo: 'Device Info',
			organization: 'Organization',
			organizationName: 'Organization Name',
			organizationId: 'Organization ID',
			description: 'Description',
			lastActive: 'Last Active',
			createdAt: 'Registered',
			createdBy: 'Created By',
			platform: 'Platform',
			owner: 'Owner',
			uuid: 'Device UUID',
			id: 'Device ID',
			projectUuid: 'Application UUID',
			versionUuid: 'Version UUID',
			streetAddress: 'Street Address',
			image: 'Device Image',
			protocol: 'Protocol',
			protocols: 'Protocols',
			imageUrl: 'Device Image URL',
			status: 'Status',
			statusInt: 'Status Integer',
			statusText: 'Status Text',
			geoloc: 'Geolocation',
		},
	},
);

WidgetDeviceDataSelect.prototype.$_$ = function(done) {
	this.$_SetupWidgetTest(function(err, currentWidget) {
		var variables = [
			{
				name: 'xData',
				label: 'X Axis Data',
				selectMultiple: false,
			},

			{
				name: 'yData',
				label: 'Y Axis Data',
				selectMultiple: false,
			},
		];

		WidgetBase.prototype.$_$.call(this, done);
	});
};
