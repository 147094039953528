//Copyright 2018 Atmosphere IoT Corp.
//All rights reserved
// jshint esversion: 6

function WidgetUsage(id, api, parent, options) {
	WidgetBase.call(this, id, api, parent, options);

	this.setUsage('', 'gray', '', '', 0);
}

WidgetUsage.prototype = Object.create(WidgetBase.prototype);
WidgetUsage.prototype.constructor = WidgetUsage;

WidgetUsage.prototype.setUsage = function(
	value,
	units,
	color,
	icon,
	leftLabel,
	rightLabel,
	percent,
) {
	/*
	 */

	percent = Math.min(percent, 1.0);

	this.renderTemplate(
		{
			value: value,
			units: units,
			color: color,
			imageSrc: icon,
			leftLabel: leftLabel,
			rightLabel: rightLabel,
			percent: percent * 100,
		},
		WidgetUsage.name,
	);
};

WidgetUsage.prototype.language = deepAssign({}, WidgetBase.prototype.language, {
	usage: 'Usage',
});

WidgetUsage.prototype.$_$ = function(done) {
	this.$_SetupWidgetTest(function(err, currentWidget) {
		this.setUsage(
			72.6,
			'units',
			'yellow',
			'./Resources/icons/Trash.svg',
			'MB Data Storage Used',
			'of 100MB storage',
			0.23,
		);

		setTimeout(function() {
			currentWidget.setUsage(
				120,
				'units',
				'green',
				'./Resources/icons/Studio.svg',
				'Stuff goes here yay!',
				'of a lot of things...',
				0,
			);

			setTimeout(function() {
				currentWidget.setUsage(
					120,
					'units',
					'red',
					'./Resources/icons/Studio.svg',
					'Stuff goes here yay!',
					'of a lot of things...',
					1242141.5,
				);

				setTimeout(function() {
					currentWidget.setUsage(
						120,
						'units',
						'blue',
						'./Resources/icons/Studio.svg',
						'Stuff goes here yay!',
						'of a lot of things...',
						-241241.5,
					);

					setTimeout(function() {
						currentWidget.setUsage(
							120,
							'units',
							'orange',
							'./Resources/icons/Studio.svg',
							'Stuff goes here yay!',
							'of a lot of things...',
							null,
						);

						setTimeout(function() {
							currentWidget.setUsage(
								120,
								'units',
								'purple',
								'./Resources/icons/Studio.svg',
								'Stuff goes here yay!',
								'of a lot of things...',
								'1.50',
							);

							setTimeout(function() {
								currentWidget.setUsage(
									120,
									'units',
									'gray',
									'./Resources/icons/Studio.svg',
									'Stuff goes here yay!',
									'of a lot of things...',
								);

								setTimeout(function() {
									WidgetBase.prototype.$_$.call(
										currentWidget,
										done,
									);
								}, 1000);
							}, 1000);
						}, 1000);
					}, 1000);
				}, 1000);
			}, 1000);
		}, 1000);
	});
};
