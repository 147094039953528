function ElementControllerEmbeddedDataTypeConversion(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty(
		'type',
		null,
		'ATMO_DATATYPE_STRING',
		'select',
		this.DATA_TYPES,
		null,
	);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('convert', [], ['converted'], false, false);
	this.setAbilityCode(
		'convert',
		`
    return ATMO_CreateValueConverted(out, ATMO_PROPERTY(undefined, type), in);
    `,
	);
}

ElementControllerEmbeddedDataTypeConversion.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedDataTypeConversion.prototype.constructor = ElementControllerEmbeddedDataTypeConversion;

ElementControllerEmbeddedDataTypeConversion.prototype.NAME = 'DataTypeConvert';
ElementControllerEmbeddedDataTypeConversion.prototype.TYPE =
	'EmbeddedDataTypeConversion';
ElementControllerEmbeddedDataTypeConversion.prototype.HIDDEN = false;
ElementControllerEmbeddedDataTypeConversion.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
];
ElementControllerEmbeddedDataTypeConversion.prototype.DEFAULT_ABILITY =
	'convert';
ElementControllerEmbeddedDataTypeConversion.prototype.DEFAULT_TRIGGER =
	'converted';
ElementControllerEmbeddedDataTypeConversion.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedDataTypeConversion.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedDataTypeConversion: 'Type Conversion',
			type: 'Output Type',
			convert: 'Convert',
			converted: 'Converted',
		},
	},
);

ElementControllerEmbeddedDataTypeConversion.prototype.DOCS = {
	'en-US': {
		meta: {
			summary:
				'The ADC pin element is used to read the voltage of an ADC pin.',
		},
		body: [
			{
				header: 'Data Type Conversion Element',
				headerLevel: 1,
				value: `
                The Data Type Conversion Element allows a user to manually convert an Embedded Atmosphere Value from one Data Type to another. 
                
                Most Atmosphere Elements will convert types implicitly, but there are some instance in which a user might need to manually specify the type. One example of this is converting a float temperature reading to an integer before displaying on a screen.
                
                |Element Icon|   
                |:-----:|
                |![Data Type Conversion Element](/images/ElementIcons/ADCPinElement.svg)|
                `,
			},
			{
				header: 'Properties',
				headerLevel: 2,
				value:
					'The following properties can be changed for the Data Type Conversion Element:',
			},
			{
				header: 'Type',
				headerLevel: 4,
				value: 'The desired output type of the input value. ',
			},
			{
				header: 'Abilities',
				headerLevel: 2,
				value:
					'The following abilities are available for the Data Type Conversion Element',
			},
			{
				header: 'Convert',
				headerLevel: 4,
				value: 'Perform a data type conversion on the input value.',
			},
		],
	},
};
