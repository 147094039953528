function ElementControllerTest(parentController, parentPlane, elementName) {
	ElementControllerBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('text', null, 'Test', 'text');
	this.addProperty('radio', null, '', 'radio');
	this.addProperty('checkbox', null, true, 'checkbox');
	this.addProperty('code', null, '', 'code');
	this.addProperty('select', null, 'test3', 'select', [
		'test1',
		'test2',
		'test3',
	]);

	this.addAbility('evaluate', [{ name: 'values', validator: null }]);
	this.addAbility('clearValues', []);
	this.addAbility('addValues', [{ name: 'values', validator: null }]);
	this.addAbility('addValue', [
		{ name: 'key', validator: null },
		{ name: 'value', validator: null },
	]);

	this.addTrigger('expressionTrue');
	this.addTrigger('expressionFalse');
	this.addTrigger('evaluated');
}

ElementControllerTest.prototype = Object.create(
	ElementControllerBase.prototype,
);
ElementControllerTest.prototype.constructor = ElementControllerTest;

ElementControllerTest.prototype.TYPE = 'Test';
ElementControllerTest.prototype.HIDDEN = true;

ElementControllerTest.prototype.language = {
	'en-US': {
		ElementControllerTest: 'Test',
		text: 'Text',
		radio: 'Radio',
		checkbox: 'Checkbox',
		code: 'Code',
		select: 'Select',
		evaluate: 'Evaluate',
		clearValues: 'Clear Values',
		addValues: 'Add Values',
		addValue: 'Add Value',
		expressionTrue: 'Expression True',
		expressionFalse: 'Expression False',
		evaluated: 'Evaluated',
	},
};
