function PlaneControllerEmbeddedArduino(parentController, elementName) {
	PlaneControllerEmbeddedBase.call(this, parentController, elementName);
}

PlaneControllerEmbeddedArduino.prototype = Object.create(
	PlaneControllerEmbeddedBase.prototype,
);
PlaneControllerEmbeddedArduino.prototype.constructor = PlaneControllerEmbeddedArduino;

PlaneControllerEmbeddedArduino.prototype.TYPE = 'arduino';
PlaneControllerEmbeddedArduino.prototype.VARIANTS = [];
PlaneControllerEmbeddedArduino.prototype.SUPPORTS = [
	'embedded',
	'gpio',
	'adc',
	'i2c',
	'spi',
	'uart',
	'pwm',
	'debugPrint',
];
PlaneControllerEmbeddedArduino.prototype.SELECTABLE_VARIANTS = [];

PlaneControllerEmbeddedArduino.prototype.language = {
	'en-US': {
		defaultName: 'Arduino',
	},
};
