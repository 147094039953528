function ElementControllerAppBLECharacteristicCustom(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppBLECharacteristicBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('readData', null, null, 'none', null, null);

	this.addAbility('read', []);
	this.addAbility('write', [{ name: 'value', validator: null }]);
	this.addAbility('writeWithoutResponse', [
		{ name: 'value', validator: null },
	]);
	this.addAbility('subscribe', []);
	this.addAbility('unsubscribe', []);

	this.addProperty('readData', null, null, 'none', null, null);

	this.addTrigger('read');
	this.addTrigger('written');
	this.addTrigger('notification');
	this.addTrigger('indication');
	this.addTrigger('subscribed');
	this.addTrigger('unsubscribed');
}

ElementControllerAppBLECharacteristicCustom.prototype = Object.create(
	ElementControllerAppBLECharacteristicBase.prototype,
);
ElementControllerAppBLECharacteristicCustom.prototype.constructor = ElementControllerAppBLECharacteristicCustom;

ElementControllerAppBLECharacteristicCustom.prototype.DEFAULT_TRIGGER = 'read';
ElementControllerAppBLECharacteristicCustom.prototype.DEFAULT_ABILITY = 'read';
ElementControllerAppBLECharacteristicCustom.prototype.DEFAULT_ARGUMENTS = [
	'readData',
];
ElementControllerAppBLECharacteristicCustom.prototype.NAME =
	'BLECharacteristicCustom';
ElementControllerAppBLECharacteristicCustom.prototype.TYPE =
	'AppBLECharacteristicCustom';
ElementControllerAppBLECharacteristicCustom.prototype.HIDDEN = false;
ElementControllerAppBLECharacteristicCustom.prototype.CATEGORY = 'appBLE';
ElementControllerAppBLECharacteristicCustom.prototype.COUPLED_TO =
	'bleCharacteristicCustom';

ElementControllerAppBLECharacteristicCustom.prototype.language = deepAssign(
	{},
	ElementControllerAppBLECharacteristicBase.prototype.language,
	{
		'en-US': {
			AppBLECharacteristicCustom: 'BLE Characteristic',
			read: 'Read',
			write: 'Write',
			value: 'Value',
			writeWithoutResponse: 'Write Without Response',
			subscribe: 'Subscibe',
			unsubscribe: 'Unsubscribe',
			written: 'Written',
			notification: 'Notification',
			indication: 'Indication',
			subscribed: 'Subscribed',
			unsubscribed: 'Unsubscribed',
		},
	},
);
