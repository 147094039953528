function ElementControllerAppUIImage(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppUIBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty(
		'image',
		null,
		'./Resources/images/AppUIImage_Default.png',
		'image',
		null,
	);
	this.addProperty('scaling', null, 'targetWidth', 'select', [
		'target',
		'none',
		'targetWidth',
	]);
	this.addProperty('pressedData', null, { x: 0, y: 0 }, 'none', null, null);
	this.addProperty('releasedData', null, { x: 0, y: 0 }, 'none', null, null);
	this.addProperty('overData', null, { x: 0, y: 0 }, 'none', null, null);
	this.addProperty('leftData', null, { x: 0, y: 0 }, 'none', null, null);

	this.addAbility('setImage', [{ name: 'value', validator: null }]);

	this.addTrigger('pressed');
	this.addTrigger('released');
	this.addTrigger('over');
	this.addTrigger('imageSet');
}

ElementControllerAppUIImage.prototype = Object.create(
	ElementControllerAppUIBase.prototype,
);
ElementControllerAppUIImage.prototype.constructor = ElementControllerAppUIImage;

ElementControllerAppUIImage.prototype.NAME = 'Image';
ElementControllerAppUIImage.prototype.TYPE = 'AppUIImage';
ElementControllerAppUIImage.prototype.HIDDEN = false;
ElementControllerAppUIImage.prototype.DEFAULT_TRIGGER = 'triggered';
ElementControllerAppUIImage.prototype.DEFAULT_ABILITY = 'setImage';
ElementControllerAppUIImage.prototype.DEFAULT_ARGUMENTS = ['image'];
ElementControllerAppUIImage.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerAppUIImage.prototype.language = deepAssign(
	{},
	ElementControllerAppUIBase.prototype.language,
	{
		'en-US': {
			AppUIImage: 'Image',
			image: 'Image',
			scaling: 'Scaling',
			pressedData: 'Pressed Data',
			releasedData: 'Released Data',
			overData: 'Over Data',
			leftData: 'Left Data',
			setImage: 'Set Image',
			value: 'Value',
			pressed: 'Pressed',
			released: 'Released',
			over: 'Over',
			imageSet: 'Image Set',
			targetWidth: 'Scale Keeping Aspect',
			none: 'No Scaling',
			target: 'Stretch',
		},
	},
);
