function WidgetOrganizations(id, api, parent, options) {
	WidgetBase.call(this, id, api, parent, options);
	this._tableWidget = null;
	this._searchContainerId = this.generateChildId('searchContainer');
	this._tableContainerId = this.generateChildId('tableContainer');

	this.settingsNameId = this.generateChildId('settingsName');
	this.settingsDescriptionId = this.generateChildId('settingsDescription');
	this.saveButtonId = this.generateChildId('saveButton');
	this.removeImageId = this.generateChildId('removeImage');
	this.imageIconId = this.generateChildId('imageIcon');
	this.imageInputId = this.generateChildId('imageInput');
	this.addButtonId = this.generateChildId('addButton');
	this.deleteButtonId = this.generateChildId('deleteButton');
	this.moveButtonId = this.generateChildId('moveButton');
	this.showChildrenCheckboxId = this.generateChildId('showChildrenCheckbox');

	this.children = null;
}

WidgetOrganizations.prototype = Object.create(WidgetBase.prototype);
WidgetOrganizations.prototype.constructor = WidgetOrganizations;

WidgetOrganizations.prototype.NEW_ORG_FORM = [
	{
		name: 'Name',
		type: 'text',
		label: 'orgName',
		value: '',
		validator: (a) => {
			return a.length > 0 && a.length < 50;
		},
	},
	{
		name: 'Description',
		type: 'text',
		label: 'orgDesc',
		value: '',
		validator: (a) => {
			return a.length < 500;
		},
	},
];

WidgetOrganizations.prototype.verifyImageData = function(imageData, callback) {
	const currentWidget = this;

	if (imageData.constructor === File) {
		const file = imageData;
		const fileName = imageData.name;

		if (checkForFileType(fileName, this.VALID_FILE_TYPES) === false) {
			callback.call(this, {
				type: 'invalidImageUploadExtensionError',
			});

			return;
		}

		callback.call(this, false, imageData);
		return;
	} else {
		callback.call(this, false, imageData);
		return;
	}
};

WidgetOrganizations.prototype.readImageFromFile = function(
	imageFile,
	callback,
) {
	const currentWidget = this;

	const fileReader = new FileReader();
	let base64ImageData = null;

	if (isPhonegap()) {
		fileReader.onloadend = function(e) {
			base64ImageData = e.target.result;

			callback.call(currentWidget, false, base64ImageData);

			return;
		};
	} else {
		fileReader.addEventListener('load', function(e) {
			base64ImageData = e.target.result;

			callback.call(currentWidget, false, base64ImageData);

			return;
		});
	}

	fileReader.readAsDataURL(imageFile);
	return;
};

WidgetOrganizations.prototype.setImage = function(
	base64ImageDataOrUrl,
	callback,
) {
	const currentWidget = this;

	if (!base64ImageDataOrUrl) {
		this.image = null;

		callback.call(this, false, null);

		return;
	}

	const img = new Image();
	const canvas = document.createElement('canvas');

	img.src = base64ImageDataOrUrl;

	img.onload = function() {
		if (
			img.height > currentWidget.UPLOADED_IMAGE_HEIGHT_MAX ||
			img.width > currentWidget.UPLOADED_IMAGE_WIDTH_MAX
		) {
			canvas.height = currentWidget.UPLOADED_IMAGE_HEIGHT_MAX;
			canvas.width =
				(currentWidget.UPLOADED_IMAGE_HEIGHT_MAX * img.width) /
				img.height;
		} else {
			canvas.height = img.height;
			canvas.width = img.width;
		}

		canvas
			.getContext('2d')
			.drawImage(img, 0, 0, canvas.width, canvas.height);

		const imageData = canvas.toDataURL('image/png') || null;

		if (!imageData) {
			callback.call(currentWidget, { type: 'invalidImageData' });
			return;
		}

		const api = currentWidget.getApiV2().apis;

		currentWidget.getOrganizationId((err, orgId) => {
			if (err) {
				callback.call(currentWidget, err);
				return;
			}

			api.organizations
				.updateOrganizationImage(
					{
						id: orgId,
					},
					{
						requestBody: imageData,
					},
				)
				.then(() => {
					callback.call(currentWidget, false);
				})
				.catch((err) => {
					callback.call(currentWidget, err);
					currentWidget
						.getMainContainer()
						.showPopupErrorMessage(
							getLanguageTag(
								currentWidget.constructor,
								'errorSavingSettings',
							),
						);
				});
		});
	};
};

WidgetOrganizations.prototype.unsetImage = function(callback) {
	callback = callback || function() {};

	const currentWidget = this;

	const api = currentWidget.getApiV2().apis;

	currentWidget.getOrganizationId((err, orgId) => {
		if (err) {
			callback.call(currentWidget, err);
			return;
		}

		api.organizations
			.deleteOrganizationImage({
				id: orgId,
			})
			.then(() => {
				callback.call(currentWidget, false);
			})
			.catch((err) => {
				callback.call(currentWidget, err);
				currentWidget
					.getMainContainer()
					.showPopupErrorMessage(
						getLanguageTag(
							currentWidget.constructor,
							'errorSavingSettings',
						),
					);
			});
	});
};

WidgetOrganizations.prototype._onImageFileInputChanged = function(
	event,
	callback,
) {
	const currentWidget = this;

	event.preventDefault();
	event.stopPropagation();

	const file = event.target.files[0];

	function reportError(err) {
		currentWidget
			.getMainContainer()
			.showPopupErrorMessage(currentWidget.getLanguageTag(err.type));

		console.error(err);
		callback.call(currentWidget, err);
		return;
	}

	if (file) {
		this.verifyImageData(file, function(err, verifiedImageData) {
			if (err) {
				reportError.call(this, err);
				return;
			}

			this.readImageFromFile(file, function(err, base64ImageData) {
				if (err) {
					reportError.call(this, err);
					return;
				}

				this.setImage(base64ImageData, function(err, data) {
					if (err) {
						reportError.call(this, err);
						return;
					}
					callback.call(currentWidget, false);
				});

				return;
			});
		});
	}
};

WidgetOrganizations.prototype.UPLOADED_IMAGE_HEIGHT_MAX = 256;
WidgetOrganizations.prototype.UPLOADED_IMAGE_WIDTH_MAX = 256;

WidgetOrganizations.prototype.VALID_FILE_TYPES = [
	'.png',
	'.jpg',
	'.gif',
	'.jpeg',
	'.svg',
	'.webp',
];

WidgetOrganizations.prototype.getAndShowImage = function(callback) {
	callback = callback || function() {};

	const currentWidget = this;
	const api = currentWidget.getApiV2().apis;

	this.getOrganizationId((err, orgId) => {
		api.organizations
			.getOrganizationImage({ id: orgId })
			.then((image) => {
				$(`#${currentWidget.imageIconId}`).attr('src', image);
				callback.call(currentWidget, false);
			})
			.catch(() => {
				$(`#${currentWidget.imageIconId}`).attr(
					'src',
					'./Resources/icons/SetImage.svg',
				);
				callback.call(currentWidget, false);
			});
	});
};

WidgetOrganizations.prototype.initialize = function(callback) {
	const currentWidget = this;

	currentWidget.render(function(err) {
		if (err) {
			callback.call(currentWidget, err);
			return;
		}

		currentWidget.addTableWidget(function(err) {
			if (err) {
				callback.call(currentWidget, err);
				return;
			}

			currentWidget.update(function(err) {
				if (err) {
					callback.call(currentWidget, err);
					return;
				}

				const imageIcon = $(`#${currentWidget.imageIconId}`);
				const imageFileInput = $(`#${currentWidget.imageInputId}`);
				const removeImage = $(`#${currentWidget.removeImageId}`);
				const currentUser = currentWidget.getCurrentUser();

				if (currentUser.ability.can('manage', 'Organization')) {
					removeImage.click(function() {
						currentWidget.getMainContainer().showConfirm(
							{
								message: currentWidget.getLanguageTag(
									'confirmRemovingImage',
								),
								title: currentWidget.getLanguageTag(
									'removingImageTitle',
								),
								confirmLabel: currentWidget.getLanguageTag(
									'confirmRemovingImageButton',
								),
							},

							function() {
								currentWidget.unsetImage(() => {
									currentWidget.getAndShowImage();
								});
							},
						);
					});

					//AI-3556: We must be attaching the click event twice somewhere?
					imageIcon.off("click");
					
					imageIcon.on("click", function(e) {
						imageFileInput.show();
						imageFileInput.trigger('click');
						imageFileInput.hide();
					});

					imageFileInput.on('change', function(e) {
						currentWidget._onImageFileInputChanged(e, () => {
							currentWidget.getAndShowImage();
						});
					});

					$(`#${currentWidget.settingsNameId}`).prop(
						'disabled',
						false,
					);
					$(`#${currentWidget.settingsDescriptionId}`).prop(
						'disabled',
						false,
					);
				} else {
					//If the user can't manage the orgs then
					//disable the input on name and description
					$(`#${currentWidget.settingsNameId}`).prop(
						'disabled',
						true,
					);
					$(`#${currentWidget.settingsDescriptionId}`).prop(
						'disabled',
						true,
					);
				}

				currentWidget.getAndShowImage((err) => {
					WidgetBase.prototype.initialize.call(
						currentWidget,
						callback,
					);
				});
			});
		});
	});
};

/**
 * Perform HTTP request to move organizations to new parent
 *
 * @param {Array<Organization>} orgs
 * @param {Organization} newParent
 * @returns
 */
WidgetOrganizations.prototype.moveOrgs = async function(orgs, newParent) {
	const currentWidget = this;
	const movePromises = [];
	const api = currentWidget.getMainContainer()._apiv2.apis;

	orgs.forEach((org) => {
		const orgModel = new Organization(api, org);
		orgModel.data.parentId = newParent.id;
		movePromises.push(orgModel.save());
	});

	return Promise.all(movePromises);
};

/**
 * Handle event when picker modal is closed and a new parent has been selected
 *
 * @param {Array<Organization>} orgsToMove
 * @param {Organization} newParent
 */
WidgetOrganizations.prototype.onParentPicked = async function(
	orgsToMove,
	newParent,
) {
	const currentWidget = this;
	try {
		await currentWidget.moveOrgs(orgsToMove, newParent);
	} catch (e) {
		console.error('Error moving organizations', e);
		currentWidget
			.getMainContainer()
			.showPopupErrorMessage(
				currentWidget.getLanguageTag('unableToMoveOrg'),
			);
		return;
	}

	currentWidget.getMainContainer().hideModal();
	currentWidget
		.getMainContainer()
		.showPopupInfoMessage(currentWidget.getLanguageTag('orgMoved'));

	currentWidget.update();
};

/**
 * Handle event when "Move Organizations" action clicked. Creates picker modal for user
 * to pick a new parent.
 *
 * @param {Array<Organization>} orgsToMove
 * @returns
 */
WidgetOrganizations.prototype.onMoveOrganizationAction = function(orgsToMove) {
	const currentWidget = this;

	if (orgsToMove.length <= 0) {
		currentWidget
			.getMainContainer()
			.showPopupErrorMessage(
				currentWidget.getLanguageTag('mustSelectOrg'),
			);
		return;
	}

	currentWidget
		.getMainContainer()
		.setModalWidget(
			WidgetOrganizationPicker,
			{ disabledOrgs: orgsToMove.map((org) => org.id) },
			(err, widget) => {
				currentWidget.getMainContainer().showModal();

				widget.setTitle(
					getLanguageTag(currentWidget.constructor, 'moveOrg'),
				);

				widget.addEventListener('dismissed', function() {
					currentWidget.getMainContainer().hideModal();
					currentWidget.update(() => {});
				});

				widget.addEventListener('confirmed', async function() {
					await currentWidget.onParentPicked(
						orgsToMove,
						widget.pickerContext,
					);
				});
			},
		);
};

WidgetOrganizations.prototype.orgImageFormatter = function(img) {
	return $.handlebarTemplates['WidgetOrganizations_OrganizationEntry']({
		imgSrc: img || './Resources/icons/DefaultOrgIcon.svg',
	});
};

WidgetOrganizations.prototype.statusFormatter = function(value, rowData) {
	if (!value || !value.toLowerCase) {
		return this.getLanguageTag('none');
	}

	return this.getLanguageTag(value.toLowerCase());
};

WidgetOrganizations.prototype.onDeleteOrganizationAction = async function(
	orgsToDelete,
) {
	const currentWidget = this;
	try {
		await Promise.all(
			orgsToDelete.map((org) => {
				const organization = new Organization(
					currentWidget.getApiV2().apis,
					{
						id: org.id,
					},
				);
				return organization.delete();
			}),
		);

		currentWidget.update();
		currentWidget
			.getMainContainer()
			.showPopupInfoMessage(currentWidget.getLanguageTag('orgsDeleted'));
	} catch (e) {
		console.error('Error deleting orgs', e);
		currentWidget
			.getMainContainer()
			.showPopupErrorMessage(
				currentWidget.getLanguageTag('errorDeletingOrg'),
			);
	}
};

WidgetOrganizations.prototype.addTableWidget = function(callback) {
	const currentWidget = this;
	const currentUser = this.getCurrentUser();

	const api = currentWidget.getApiV2().apis;

	this.getOrganizationId((err, orgId) => {
		this.addChildWidget(
			WidgetSearchBox,
			this._searchContainerId,
			{
				onSearch: () => {
					currentWidget.update(() => {});
				},
			},
			function(err, searchBoxWidget) {
				currentWidget.searchBoxWidget = searchBoxWidget;

				this.addChildWidget(
					WidgetTableDynamic,
					currentWidget._tableContainerId,
					{
						hideFilterInput: true,
						selectable: true,
						apiRoute: api.organizations.getOrganizations.bind(
							currentWidget,
						),
						addParams: () => {
							const showAll = $(
								`#${this.showChildrenCheckboxId}`,
							).prop('checked');

							const params = {
								parentId: orgId,
								depth: showAll ? 'all' : '1',
							};

							const searchText = currentWidget.searchBoxWidget.getSearchText();

							if (searchText && searchText.length > 0) {
								params.searchText = searchText;
							}

							return params;
						},
						entryMapper: async (entry) => {
							const org = new Organization(
								currentWidget.getApiV2().apis,
								entry,
							);
							entry.parent = org.getParentName();
							entry.img = await org.getImage();
							entry.imgHtml = currentWidget.orgImageFormatter(
								entry.img,
							);
							entry.status = currentWidget.statusFormatter(
								entry.statusText,
							);
							entry.orgLink = currentWidget
								.getMainContainer()
								.getLocationLink('Organizations', {
									org: entry.id,
								});
							return entry;
						},
						rowFormatter: (row) => {
							const rowData = row.getData();
							const element = row.getElement();
							const rowClass = deviceStatus.rowClass(
								rowData.raw.statusText,
							);
							row.getElement().classList.add(rowClass);
						},
						onRowClicked: (entry) => {
							currentWidget.handleOrgClicked(entry.id);
						},
						onRowDblTap: (entry) => {
							currentWidget.handleOrgClicked(entry.id);
						},
						onSelectionChanged: (rows) => {
							if (rows.length > 0) {
								currentWidget.moveButton.show();
								currentWidget.deleteButton.show();
							} else {
								currentWidget.moveButton.hide();
								currentWidget.deleteButton.hide();
							}
						},
						ajaxSorting: true,
						headerSort: true,
						paginationSize: 20,
						columns: [
							{
								formatter: 'rowSelection',
								titleFormatter: 'rowSelection',
								hozAlign: 'center',
								width: 10,
								responsive: 0,
								headerSort: false,
							},
							{
								formatter: 'html',
								field: 'imgHtml',
								responsive: 3,
								align: 'center',
								width: 60,
								headerSort: false,
							},
							{
								title: currentWidget.getLanguageTag('orgName'),
								formatter: 'link',
								field: 'orgLink',
								formatterParams: {
									labelField: 'name',
								},
								responsive: 0,
								minWidth: 150,
							},
							{
								title: currentWidget.getLanguageTag('status'),
								field: 'status',
								responsive: 1,
								minWidth: 100,
								headerSort: true,
							},
							{
								title: currentWidget.getLanguageTag('parent'),
								field: 'parent',
								responsive: 1,
								minWidth: 200,
								headerSort: false,
							},
							{
								title: currentWidget.getLanguageTag(
									'description',
								),
								field: 'description',
								responsive: 2,
								minWidth: 300,
							},
						],
					},

					function(err, tableWidget) {
						if (err) {
							callback.call(currentWidget, err);
							return;
						}

						currentWidget._tableWidget = tableWidget;

						callback.call(currentWidget, false);
					},
				);
			},
		);
	});
};

WidgetOrganizations.prototype.update = function(callback) {
	const currentWidget = this;
	currentWidget._tableWidget.update(callback);
};

// A new child has just been added under the current parent
WidgetOrganizations.prototype.onNewChild = function(newOrg) {
	this.update(() => {});
};

/**
 * An organization item has actually been clicked. Navigate to that organization.
 *
 * @param {*} org
 */
WidgetOrganizations.prototype.handleOrgClicked = function(orgId) {
	const currentWidget = this;
	const api = currentWidget.getApiV2().apis;

	//Clear table to give some feedback that we are doing something
	currentWidget._tableWidget.clearTable();

	this.getMainContainer().setLocation(LocationOrganizations, {
		org: orgId,
		reload: true,
	});
};

WidgetOrganizations.prototype.enableSaveBtn = function() {
	$(`#${this.saveButtonId}`).prop('disabled', false);
};

WidgetOrganizations.prototype.onSaveClicked = function() {
	const currentWidget = this;
	const api = currentWidget.getApiV2().apis;

	if (
		$(`#${this.settingsNameId}`).hasClass(
			'WidgetOrganizations-InvalidSetting',
		)
	) {
		$(`#${this.settingsNameId}`).removeClass(
			'WidgetOrganizations-InvalidSetting',
		);
	}

	if (
		$(`#${this.settingsDescriptionId}`).hasClass(
			'WidgetOrganizations-InvalidSetting',
		)
	) {
		$(`#${this.settingsDescriptionId}`).removeClass(
			'WidgetOrganizations-InvalidSetting',
		);
	}

	const name = $(`#${this.settingsNameId}`).prop('value');
	const description = $(`#${this.settingsDescriptionId}`).prop('value');

	this.getOrganizationContext((err, orgData) => {
		if (err) {
			throw err;
		}

		const org = new Organization(api, orgData);

		org.setName(name);
		org.setDescription(description);

		org.save()
			.then(() => {
				currentWidget
					.getMainContainer()
					.showPopupInfoMessage(
						currentWidget.getLanguageTag('orgSaved'),
					);

				currentWidget.getMainContainer().event('orgNameChanged', name);
			})
			.catch((e) => {
				console.error('Error saving settings', e);
				if (e instanceof OrganizationSettingsInvalidError) {
					console.error('Invalid Fields: ', e.invalidFields);
					e.invalidFields.forEach((field) => {
						if (field === 'Name') {
							$(`#${currentWidget.settingsNameId}`).addClass(
								'WidgetOrganizations-InvalidSetting',
							);
						} else if (field === 'Description') {
							$(
								`#${currentWidget.settingsDescriptionId}`,
							).addClass('WidgetOrganizations-InvalidSetting');
						}
					});
					currentWidget
						.getMainContainer()
						.showPopupErrorMessage(
							currentWidget.getLanguageTag('invalidSettings'),
						);
				} else {
					currentWidget
						.getMainContainer()
						.showPopupErrorMessage(
							currentWidget.getLanguageTag('errorSavingSettings'),
						);
				}
			});
	});
};

WidgetOrganizations.prototype.populateFormLanguage = function(form) {
	const currentWidget = this;
	return form.map((entry) => {
		entry.label = getLanguageTag(currentWidget.constructor, entry.label);
		return entry;
	});
};

WidgetOrganizations.prototype.getNewChildData = function(newChildWidget) {
	const values = newChildWidget.getValues();

	return {
		name: values.Name,
		description: values.Description,
	};
};

WidgetOrganizations.prototype.onNewChildConfirmed = async function(
	newChildWidget,
) {
	const currentWidget = this;
	currentWidget.getMainContainer().hideModal();

	const newOrgData = currentWidget.getNewChildData(newChildWidget);

	const org = new Organization(currentWidget.getApiV2().apis, newOrgData);
	org.setParentId(await this.asyncGetOrganizationId());

	try {
		await org.create();
		// Re-fetch context in case current parent didn't have a child
		currentWidget.update();
		currentWidget
			.getMainContainer()
			.showPopupInfoMessage(currentWidget.getLanguageTag('orgCreated'));
	} catch (e) {
		console.error('Unable to create organization', e);
		currentWidget
			.getMainContainer()
			.showPopupErrorMessage(
				currentWidget.getLanguageTag('unableToCreateOrg'),
			);
	}
};

WidgetOrganizations.prototype.configureNewChildModal = function(
	newChildWidget,
) {
	const currentWidget = this;
	newChildWidget.setTitle(
		getLanguageTag(currentWidget.constructor, 'addNewOrg'),
	);

	newChildWidget.addEventListener('dismissed', function() {
		currentWidget.getMainContainer().hideModal();
	});

	newChildWidget.addEventListener('confirmed', async function() {
		await currentWidget.onNewChildConfirmed(newChildWidget);
	});

	currentWidget.getMainContainer().showModal();
};

WidgetOrganizations.prototype.onAddChildClicked = function() {
	const currentWidget = this;
	const fields = currentWidget.populateFormLanguage(
		currentWidget.NEW_ORG_FORM,
	);
	currentWidget
		.getMainContainer()
		.setModalWidget(
			WidgetSettingsForm,
			{ fields },
			(err, newChildWidget) => {
				currentWidget.configureNewChildModal(newChildWidget);
			},
		);
};

WidgetOrganizations.prototype.render = function(callback) {
	callback = callback || function() {};
	const currentWidget = this;

	const currentUser = this.getMainContainer().getCurrentUser();

	this.getOrganizationContext((err, currentOrg) => {
		if (err) {
			callback.call(currentWidget, err);
			return;
		}

		this.renderTemplate(
			{
				tableContainerId: this._tableContainerId,
				searchContainerId: this._searchContainerId,
				addOrganization: this.getLanguageTag('addOrganization'),
				moveOrganization: this.getLanguageTag('moveOrganization'),
				deleteOrganization: this.getLanguageTag('deleteOrganization'),
				settingsNameId: this.settingsNameId,
				settingsDescriptionId: this.settingsDescriptionId,
				saveButtonId: this.saveButtonId,
				removeImageId: this.removeImageId,
				imageIconId: this.imageIconId,
				imageInputId: this.imageInputId,
				addButtonId: this.addButtonId,
				deleteButtonId: this.deleteButtonId,
				moveButtonId: this.moveButtonId,
				showChildrenCheckboxId: this.showChildrenCheckboxId,
				currentOrg: currentOrg,
				canManageOrgs: currentUser.ability.can(
					'manage',
					'Organization',
				),
				showSubOrganizations: this.getLanguageTag(
					'showSubOrganizations',
				),
				canSeeOrgs:
					currentUser.ability.can('see', 'Organizations') &&
					currentOrg.hasChildren,
			},
			WidgetOrganizations.name,
			this.getContainer(),
		);

		this.saveButton = $(`#${this.saveButtonId}`);
		this.addButton = $(`#${this.addButtonId}`);
		this.moveButton = $(`#${this.moveButtonId}`);
		this.deleteButton = $(`#${this.deleteButtonId}`);
		this.showChildrenCheckbox = $(`#${this.showChildrenCheckboxId}`);
		this.settingsDescription = $(`#${this.settingsDescriptionId}`);
		this.settingsName = $(`#${this.settingsNameId}`);

		this.moveButton.hide();
		this.deleteButton.hide();

		this.saveButton.on('click', (e) => {
			e.preventDefault();
			currentWidget.onSaveClicked();
		});

		this.settingsName.on('input', () => {
			currentWidget.enableSaveBtn();
		});

		this.settingsDescription.on('input', () => {
			currentWidget.enableSaveBtn();
		});

		this.addButton.on('click', () => {
			currentWidget.onAddChildClicked();
		});

		this.showChildrenCheckbox.on('click', (e) => {
			currentWidget._tableWidget.update();
		});

		this.moveButton.on('click', (e) => {
			currentWidget.onMoveOrganizationAction(
				currentWidget._tableWidget.getSelectedRowsData(),
			);
		});

		this.deleteButton.on('click', (e) => {
			currentWidget.getMainContainer().showConfirm(
				{
					message: currentWidget.getLanguageTag(
						'confirmOrganizationRemove',
					),
					title: currentWidget.getLanguageTag('removeOrganizations'),
					confirmLabel: this.getLanguageTag(
						'removeOrganizationsButton',
					),
					confirmClasses: ['btn-danger'],
				},

				() => {
					currentWidget.onDeleteOrganizationAction(
						currentWidget._tableWidget.getSelectedRowsData(),
					);
				},
			);
		});

		callback.call(this, false);
	});
};

WidgetOrganizations.prototype.ICON = './Resources/icons/OrganizationsNav.svg';

WidgetOrganizations.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'Organizations',
			icon: 'Icon',
			orgName: 'Name',
			description: 'Description',
			deleteOrg: 'Delete Selected',
			moveOrg: 'Move Selected',
			status: 'Status',
			noData: 'No organizations',
			filterInputPlaceHolder: 'Filter',
			orgMoved: 'Organizations moved',
			orgsDeleted: 'Organizations deleted',
			unableToMoveOrg: 'Unable to move selected organization(s)',
			errorDeletingOrg: 'Unable to delete selected organizations',
			invalidSettings:
				'Invalid setting detected. Check inputs and try again',
			errorSavingSettings: 'Error saving organization settings',
			orgSaved: 'Organization settings saved',
			orgDesc: 'Description',
			unableToCreateOrg: 'Unable to create new Organization',
			orgCreated: 'Organization created successfully',
			mustSelectOrg: 'You must select at least one organization',
			addNewOrg: 'New Organization',
			removingImageTitle: 'Remove Image',
			confirmRemovingImage:
				"Are you sure you want to remove this organization's image?",
			confirmRemovingImageButton: 'Remove Image',
			none: 'None',
			info: 'Info',
			good: 'Good',
			minor: 'Minor',
			warning: 'Warning',
			critical: 'Critical',
			missing: 'Missing',
			parent: 'Parent',
			addOrganization: 'Add',
			moveOrganization: 'Move',
			deleteOrganization: 'Delete',
			confirmOrganizationRemove:
				'Are you sure you want to delete the selected organizations?',
			removeOrganizations: 'Delete Organizations',
			removeOrganizationsButton: 'Delete',
			showSubOrganizations: 'Show Sub-Organizations',
		},
	},
);
