function ElementControllerAppBLECharacteristicBase(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppBLEBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this._uuidValidator = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
}

ElementControllerAppBLECharacteristicBase.prototype = Object.create(
	ElementControllerAppBLEBase.prototype,
);
ElementControllerAppBLECharacteristicBase.prototype.constructor = ElementControllerAppBLECharacteristicBase;

ElementControllerAppBLECharacteristicBase.prototype.TYPE =
	'AppBLECharacteristicBase';
ElementControllerAppBLECharacteristicBase.prototype.HIDDEN = true;
ElementControllerAppBLECharacteristicBase.prototype.VARIANTS = ['app', 'ble'];

ElementControllerAppBLECharacteristicBase.prototype.language = deepAssign(
	{},
	ElementControllerAppBLEBase.prototype.language,
	{
		'en-US': {
			AppBLECharacteristicBase: 'BLE Characteristic Base',
		},
	},
);
