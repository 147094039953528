function WidgetCompileReport(id, api, parent, options) {
	var currentWidget = this;

	WidgetPanelBase.call(this, id, api, parent, options);
}

WidgetCompileReport.prototype = Object.create(WidgetPanelBase.prototype);
WidgetCompileReport.prototype.constructor = WidgetCompileReport;

WidgetCompileReport.prototype.updateCompileReport = function(reportData) {
	var currentWidget = this;

	console.log(reportData);

	var closeButtonId = this.generateChildId('closeButton');

	var errors = [];

	for (var i = 0; i < reportData.error.length; i++) {
		if (reportData.error[i].type === 'fatal error') {
			reportData.error[i].type = 'error';
		}

		if (reportData.error[i].type === 'error') {
			// For undefined reference errors, this is the code
			if (reportData.error[i].affectedSymbol) {
				if (
					reportData.error[i].code === '' ||
					reportData.error[i].code === undefined
				) {
					reportData.error[i].code =
						reportData.error[i].affectedSymbol;
				}
			}

			// Don't show code box if there's no code to show
			if (
				reportData.error[i].code === '' ||
				reportData.error[i].code === undefined
			) {
				reportData.error[i].hasCode = false;
			} else {
				reportData.error[i].hasCode = true;
			}

			// Remove path from filename
			reportData.error[i].filename = reportData.error[i].filename
				.split('/')
				.pop();

			errors.push(reportData.error[i]);
		} else if (reportData.error[i].type === 'notification') {
			errors.push(reportData.error[i]);
		}
	}

	this.renderTemplate({
		errors: errors,
		closeButtonId: closeButtonId,
		closeButtonLabel: getLanguageTag(this.constructor, 'close'),
		lineLabel: getLanguageTag(this.constructor, 'line'),
		noticeLabel: getLanguageTag(this.constructor, 'notice'),
		filenameLabel: getLanguageTag(this.constructor, 'filename'),
		fwTooLargeLabel: getLanguageTag(this.constructor, 'fwTooLarge'),
	});

	$(`#${closeButtonId}`).click(function() {
		currentWidget.event('closed');
	});
};

WidgetCompileReport.prototype.language = deepAssign(
	{},
	WidgetPanelBase.prototype.language,
	{
		'en-US': {
			name: 'Compile Report',
			line: 'Line',
			notice: 'The following errors occurred while compiling',
			filename: 'Filename',
			fwTooLarge: 'Firmware Too Large. Unexpected behavior may occur.',
		},
	},
);
