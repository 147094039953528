function PlaneControllerBase(parentController, data) {
	ControllerBase.call(this);

	this._parentController = parentController;
	this._meta = {};
	this._elements = []; //Storage for all the elements in the plane currently
	this._compilerVersion = 'latest';
}

PlaneControllerBase.prototype = Object.create(ControllerBase.prototype);
PlaneControllerBase.prototype.constructor = PlaneControllerBase;

PlaneControllerBase.prototype.TYPE = 'base';
PlaneControllerBase.prototype.VARIANTS = [];
PlaneControllerBase.prototype.SUPPORTS = ['base'];
PlaneControllerBase.prototype.COUPLING_BADGE = 'unknownBadge.svg';

PlaneControllerBase.prototype.getDefaultDriverInstance = function(
	elementType,
	propertyName,
	driverType,
) {
	var currentPlaneController = this;

	// Is there a default entry for this element
	if (
		currentPlaneController.DEFAULT_PROPERTIES !== undefined &&
		currentPlaneController.DEFAULT_PROPERTIES[elementType] !== undefined &&
		currentPlaneController.DEFAULT_PROPERTIES[elementType][propertyName] !==
			undefined
	) {
		// Search by name
		var foundDriver = currentPlaneController._runtimeDrivers[
			driverType
		].find(function(element) {
			return (
				element.name ===
				currentPlaneController.DEFAULT_PROPERTIES[elementType][
					propertyName
				]
			);
		});

		if (foundDriver === undefined) {
			// It's an integer, so find the correct entry
			var driverInt = parseInt(
				currentPlaneController.DEFAULT_PROPERTIES[elementType][
					propertyName
				],
			);
			foundDriver = currentPlaneController._runtimeDrivers[
				driverType
			].find(function(element) {
				return element.id === driverInt;
			});
		}

		if (foundDriver) {
			return foundDriver.name;
		}
	}

	// There was no default for this specific element
	// so get the plane default
	var primaryInstance = currentPlaneController._runtimeDrivers[
		driverType
	].find(function(element) {
		return element.primary === true;
	});

	if (primaryInstance === undefined) {
		return currentPlaneController._runtimeDrivers[driverType][0].name;
	}

	return primaryInstance.name;
};

PlaneControllerBase.prototype.getElementDefaultProperty = function(
	elementType,
	propertyName,
	propertyInputType,
	propertyDataType,
	defaultValue,
) {
	if (
		propertyInputType === 'driverInstance' &&
		this._runtimeDrivers[propertyDataType] !== undefined
	) {
		return this.getDefaultDriverInstance(
			elementType,
			propertyName,
			propertyDataType,
		);
	} else if (
		this.DEFAULT_PROPERTIES !== undefined &&
		this.DEFAULT_PROPERTIES[elementType] !== undefined &&
		this.DEFAULT_PROPERTIES[elementType][propertyName] !== undefined
	) {
		return this.DEFAULT_PROPERTIES[elementType][propertyName];
	}

	return defaultValue;
};

PlaneControllerBase.prototype.updateDriverInstanceName = function(
	oldName,
	elementController,
) {
	var currentPlaneController = this;

	elementController._implements.forEach(function(driver) {
		var oldDriverInstanceName = `ATMO_DRIVERINSTANCE_${driver.toUpperCase()}_${oldName}`;
		var newDriverInstanceName = `ATMO_DRIVERINSTANCE_${driver.toUpperCase()}_${
			elementController._name
		}`;

		var currentEntry = currentPlaneController._runtimeDrivers[driver].find(
			function(data) {
				return data.name === oldDriverInstanceName;
			},
		);

		if (currentEntry) {
			currentEntry.name = newDriverInstanceName;

			// Update language tags
			Object.keys(currentPlaneController.language).forEach(function(
				language,
			) {
				delete currentPlaneController.language[language][
					oldDriverInstanceName
				];
				currentPlaneController.language[language][
					newDriverInstanceName
				] = elementController._name;
			});
		}
	});
};

PlaneControllerBase.prototype.processBuildError = function(err) {
	this.event('buildError', { controller: this, err: err });
};

PlaneControllerBase.prototype.getParent = function() {
	return this._parentController;
};

PlaneControllerBase.prototype.getDefaultName = function() {
	return getLanguageTag(this.constructor, 'defaultName');
};

PlaneControllerBase.prototype.getType = function() {
	return this.constructor.prototype.TYPE;
};

PlaneControllerBase.prototype.getVariants = function() {
	return this.constructor.prototype.VARIANTS;
};

PlaneControllerBase.prototype.setMeta = function(meta) {
	this._meta = meta;
};

PlaneControllerBase.prototype.setMetaValue = function(key, value) {
	this._meta[key] = value;
	return;
};

PlaneControllerBase.prototype.getMetaValue = function(key) {
	return this._meta[key];
};

PlaneControllerBase.prototype.getMeta = function() {
	return this._meta;
};

PlaneControllerBase.prototype.getPrimaryUuid = function() {
	return this._parentController._projectUuid;
};

PlaneControllerBase.prototype.getImportedLibraries = function() {
	return this._libraries;
};

PlaneControllerBase.prototype.getNextAvailableUuidAfterPrimaryUuid = function() {
	var currentUuid = this.getPrimaryUuid();

	while (true) {
		if (currentUuid === null) {
			return null;
		}

		currentUuid = addToUuid(currentUuid, 1);
		var isAvailable = true;

		for (var i = 0; i < this._elements.length; i++) {
			var currentElementProperties = this._elements[i].getProperties();

			for (var propertyName in currentElementProperties) {
				if (
					currentElementProperties[propertyName].value === currentUuid
				) {
					isAvailable = false;
					break;
				}
			}

			if (!isAvailable) {
				break;
			}
		}

		if (isAvailable) {
			break;
		}
	}

	return currentUuid;
};

PlaneControllerBase.prototype.checkElementName = function(elementName) {
	return /^[a-zA-Z_][a-zA-Z0-9_]*$/.test(elementName);
};

PlaneControllerBase.prototype.isElementControllerAvailable = function(
	elementController,
) {
	if (elementController.prototype.HIDDEN) {
		return false;
	}

	for (var i = 0; i < elementController.prototype.VARIANTS.length; i++) {
		var currentRequirement = elementController.prototype.VARIANTS[i];

		if (this.SUPPORTS.indexOf(currentRequirement) < 0) {
			if (this._runtimeDrivers == undefined) {
				return false;
			}

			if (
				Object.keys(this._runtimeDrivers).indexOf(currentRequirement) <
				0
			) {
				return false;
			} else if (this._runtimeDrivers[currentRequirement].length === 0) {
				return false;
			}
		}
	}

	return true;
};

PlaneControllerBase.prototype.getAvailableElements = function() {
	var elements = {};

	var windowKeys = Object.keys(window);

	const filter = [];

	for (i = 0; i < windowKeys.length; i++) {
		if (
			windowKeys[i].indexOf('ElementController') === 0 &&
			!filter.includes(windowKeys[i])
		) {
			if (this.isElementControllerAvailable(window[windowKeys[i]])) {
				elements[windowKeys[i].replace('ElementController', '')] = {
					controllerConstructor: window[windowKeys[i]],
				};
			}
		}
	}

	return elements;
};

PlaneControllerBase.prototype.getElementController = function(type) {
	const elementFactory = AtmoVersionAdapter.elementFactoryFrom(
		this._parentController._createVersion,
	);
	return elementFactory.controllerFromType(type);
};

PlaneControllerBase.prototype.getAvailableElementControllerList = function() {
	var elementControllers = [];

	var windowKeys = Object.keys(window);

	const filter = [];

	for (i = 0; i < windowKeys.length; i++) {
		if (
			windowKeys[i].indexOf('ElementController') === 0 &&
			!filter.includes(windowKeys[i])
		) {
			if (this.isElementControllerAvailable(window[windowKeys[i]])) {
				elementControllers.push(window[windowKeys[i]]);
			}
		}
	}

	return elementControllers;
};

PlaneControllerBase.prototype.getAvailableCoupledElementControllerList = function(
	couplerType,
) {
	var elementControllers = this.getAvailableElementControllerList();
	var coupledElementControllers = [];

	for (var i = 0; i < elementControllers.length; i++) {
		if (elementControllers[i].prototype.COUPLED_TO === couplerType) {
			coupledElementControllers.push(elementControllers[i]);
		}
	}

	return coupledElementControllers;
};

PlaneControllerBase.prototype.addElementByCouplerElement = function(
	couplerElement,
) {
	var elementControllers = this.getAvailableCoupledElementControllerList(
		couplerElement.COUPLER_TYPE,
	);

	for (var i = 0; i < elementControllers.length; i++) {
		var currentElement = this.addElement(
			couplerElement.getName(),
			elementControllers[i],
			null,
		);

		if (currentElement !== undefined) {
			currentElement.setCoupledElement(couplerElement);
		}
	}

	return;
};

PlaneControllerBase.prototype.addElementByType = function(
	elementName,
	type,
	data,
) {
	const elementConstructor = this.getElementController(type);

	if (elementConstructor === undefined) {
		return;
	}

	return this.addElement(elementName, elementConstructor, data);
};

PlaneControllerBase.prototype.addElement = function(
	elementName,
	elementConstructor,
	data,
) {
	if (
		elementConstructor.prototype.COUPLED_TO === null ||
		elementConstructor.prototype.COUPLED_TO === undefined
	) {
		elementName = this._parentController.getUniqueElementName(elementName);
	}

	if (!this.checkElementName(elementName)) {
		this.error({ type: 'elementNameIsNotValid' });
		return;
	}

	//If the element type is a singleton, that means
	//only one instance of this element is allowed in
	//a plane at a time.
	if (elementConstructor.prototype.SINGLETON) {
		if (this.getElementsByConstructor(elementConstructor).length > 0) {
			return;
		}
	}

	var currentElement = new elementConstructor(
		this._parentController,
		this,
		elementName,
		data,
	);

	this._elements.push(currentElement);

	currentElement.import(data);

	if (data === undefined || data === null) {
		this._parentController.addCoupledElementToPlanes(currentElement);
	}

	this.event('elementAdded', { controller: currentElement });

	return currentElement;
};

PlaneControllerBase.prototype.updateElement = function(
	elementName,
	elementConstructor,
) {
	var currentElement = this.getElement(elementName);

	var elementData = currentElement.export();

	Object.setPrototypeOf(currentElement, elementConstructor.prototype);

	elementConstructor.call(
		currentElement,
		this._parentController,
		this,
		elementName,
		elementData,
	);

	currentElement.import(elementData);

	this.event('elementUpdated', { controller: currentElement });

	return currentElement;
};

PlaneControllerBase.prototype.moveElementToFront = function(elementName) {
	var elementIndex = -1;

	for (var i = 0; i < this._elements.length; i++) {
		if (this._elements[i].getName() === elementName) {
			elementIndex = i;
			break;
		}
	}

	if (elementIndex < 0) {
		return;
	}

	var currentElement = this._elements[elementIndex];
	var elementArray = this._elements.splice(elementIndex, 1);

	this._elements = elementArray.concat(this._elements);

	this.event('elementMovedToFront', { controller: currentElement });
};

PlaneControllerBase.prototype.moveElementToBack = function(elementName) {
	var elementIndex = -1;

	for (var i = 0; i < this._elements.length; i++) {
		if (this._elements[i].getName() === elementName) {
			elementIndex = i;
			break;
		}
	}

	if (elementIndex < 0) {
		return;
	}

	var currentElement = this._elements[elementIndex];
	var elementArray = this._elements.splice(elementIndex, 1);

	this._elements = this._elements.concat(elementArray);

	this.event('elementMovedToBack', { controller: currentElement });
};

PlaneControllerBase.prototype.getElements = function() {
	return this._elements;
};

PlaneControllerBase.prototype.getElementsByConstructor = function(
	elementConstructor,
) {
	var retList = [];

	for (var i = 0; i < this._elements.length; i++) {
		if (this._elements[i].constructor === elementConstructor) {
			retList.push(this._elements[i]);
		}
	}

	return retList;
};

PlaneControllerBase.prototype.getElement = function(elementName) {
	for (var i = 0; i < this._elements.length; i++) {
		if (this._elements[i].getName() === elementName) {
			return this._elements[i];
		}
	}

	return null;
};

PlaneControllerBase.prototype.setElementName = function(
	oldElementName,
	newElementName,
) {
	var currentElement = this.getElement(oldElementName);

	if (currentElement === null) {
		this.error('elementDoesNotExist');
		return;
	}

	if (this._parentController.isElementNameUnique(newElementName) !== true) {
		this.error('elementDuplicateName');
		return;
	}

	if (!this.checkElementName(newElementName)) {
		this.error({ type: 'elementNameIsNotValid' });
		return;
	}

	currentElement.setName(newElementName);

	this.updateTriggerEvents(oldElementName, newElementName);

	this.event('elementNameSet', {
		oldName: oldElementName,
		newName: newElementName,
	});

	return;
};

PlaneControllerBase.prototype.updateTriggerEvents = function(
	oldElementName,
	newElementName,
) {
	for (var i = 0; i < this._elements.length; i++) {
		this._elements[i].updateTriggerEventTargetElements(
			oldElementName,
			newElementName,
		);
	}

	return;
};

PlaneControllerBase.prototype.removeElement = function(elementName) {
	var removedElement = null;
	var currentPlaneController = this;

	for (var i = 0; i < this._elements.length; i++) {
		if (this._elements[i].getName() === elementName) {
			removedElement = this._elements[i];
			this._elements.splice(i, 1);
			break;
		}
	}

	//We can not remove an element that is not there
	if (removedElement === null) {
		throw new Error(`No such element with name "${elementName}"`);
	}

	for (var i = 0; i < this._elements.length; i++) {
		this._elements[i].removeTriggerEventThatTargetElement(
			removedElement.getName(),
		);
	}

	// Remove from driver list of plane
	if (removedElement._implements) {
		removedElement._implements.forEach(function(driver) {
			currentPlaneController._runtimeDrivers[
				driver
			] = currentPlaneController._runtimeDrivers[driver].filter(function(
				data,
			) {
				return (
					data.name !==
					`ATMO_DRIVERINSTANCE_${driver.toUpperCase()}_${elementName}`
				);
			});
		});
	}

	this._parentController.removeCoupledElementsFromPlanes(removedElement);

	removedElement.event('elementRemoved', { planeController: this });
	this.event('elementRemoved', { controller: removedElement });

	return removedElement;
};

PlaneControllerBase.prototype.removeElementsByCouplerElementController = function(
	elementController,
) {
	for (var i = 0; i < this._elements.length; i++) {
		if (this._elements[i].getCoupledElement() === elementController) {
			this.removeElement(this._elements[i].getName());
		}
	}
};

PlaneControllerBase.prototype.removeAllElementsTriggerEventErrors = function() {
	for (var i = 0; i < this._elements.length; i++) {
		this._elements[i].removeAllTriggerEventErrors();
	}
};

PlaneControllerBase.prototype.importDriverInstances = function(elements) {
	var currentPlaneController = this;

	// Check for elements that implement drivers
	// This needs to be done before any element constructors are actually run
	for (var i = 0; i < elements.length; i++) {
		if (elements[i].implements) {
			elements[i].implements.forEach(function(driver) {
				// Add to runtime list
				var driverInstanceName = `ATMO_DRIVERINSTANCE_${driver.toUpperCase()}_${
					elements[i].name
				}`;

				if (
					currentPlaneController._runtimeDrivers[driver] == undefined
				) {
					currentPlaneController._runtimeDrivers[driver] = [];
				}

				currentPlaneController._runtimeDrivers[driver].push({
					name: driverInstanceName,
				});

				// Add language tag for it
				Object.keys(currentPlaneController.language).forEach(function(
					language,
				) {
					currentPlaneController.language[language][
						driverInstanceName
					] = elements[i].name;
				});
			});
		}
	}
};

PlaneControllerBase.prototype.import = function(planeData) {
	if (planeData === null || planeData === undefined) {
		return;
	}

	this.event('importing', planeData);

	this._compilerVersion = planeData.compilerVersion;
	this._meta = planeData.meta;
	var currentPlaneController = this;

	currentPlaneController.importDriverInstances(planeData.elements);

	for (var i = 0; i < planeData.elements.length; i++) {
		this.addElementByType(
			planeData.elements[i].name,
			planeData.elements[i].type,
			planeData.elements[i],
		);
	}

	this.event('imported', planeData);

	return;
};

PlaneControllerBase.prototype.postImport = function() {
	for (var i = 0; i < this._elements.length; i++) {
		this._elements[i].postImport();
	}

	return;
};

PlaneControllerBase.prototype.export = function() {
	var planeExportData = {};

	planeExportData.type = this.getType();
	planeExportData.compilerVersion = this._compilerVersion;
	planeExportData.variants = this.getVariants();
	planeExportData.meta = this._meta;
	planeExportData.elements = [];

	for (var i = 0; i < this._elements.length; i++) {
		planeExportData.elements.push(this._elements[i].export());
	}

	return planeExportData;
};

PlaneControllerBase.prototype.language = {
	'en-US': {
		defaultName: 'Base Plane Controller',
	},
};
