function ElementControllerAppUIAnalogMeter(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppUIBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('value', null, 0, 'number', null, null);
	this.addProperty('minValue', null, 0, 'number', null, null);
	this.addProperty('maxValue', null, 100, 'number', null, null);

	this.addAbility('setValue', [{ name: 'value', validator: null }]);
	this.addAbility('setMaxValue', [{ name: 'maxValue', validator: null }]);
	this.addAbility('setMinValue', [{ name: 'minValue', validator: null }]);
}

ElementControllerAppUIAnalogMeter.prototype = Object.create(
	ElementControllerAppUIBase.prototype,
);
ElementControllerAppUIAnalogMeter.prototype.constructor = ElementControllerAppUIAnalogMeter;

ElementControllerAppUIAnalogMeter.prototype.NAME = 'AnalogMeter';
ElementControllerAppUIAnalogMeter.prototype.TYPE = 'AppUIAnalogMeter';
ElementControllerAppUIAnalogMeter.prototype.HIDDEN = false;
ElementControllerAppUIAnalogMeter.prototype.DEFAULT_TRIGGER = 'triggered';
ElementControllerAppUIAnalogMeter.prototype.DEFAULT_ABILITY = 'setValue';
ElementControllerAppUIAnalogMeter.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerAppUIAnalogMeter.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerAppUIAnalogMeter.prototype.language = {
	'en-US': {
		AppUIAnalogMeter: 'Analog Meter',
		value: 'Value',
		minValue: 'Minimum Value',
		maxValue: 'Maximum Value',
		setValue: 'Set Value',
		setMaxValue: 'Set Maximum Value',
		setMinValue: 'Set Minimum Value',
	},
};
