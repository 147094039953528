function ElementControllerCloudDeviceTrigger(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('value', null, null, 'none');

	this.addAbility('triggerDevice', [{ name: 'value', validator: null }]);

	this.addTrigger('deviceTriggered');
}

ElementControllerCloudDeviceTrigger.prototype = Object.create(
	ElementControllerCloudBase.prototype,
);
ElementControllerCloudDeviceTrigger.prototype.constructor = ElementControllerCloudDeviceTrigger;

ElementControllerCloudDeviceTrigger.prototype.TYPE = 'CloudDeviceTrigger';
ElementControllerCloudDeviceTrigger.prototype.HIDDEN = true;
ElementControllerCloudDeviceTrigger.prototype.DEFAULT_TRIGGER =
	'deviceTriggered';
ElementControllerCloudDeviceTrigger.prototype.DEFAULT_ABILITY = 'triggerDevice';
ElementControllerCloudDeviceTrigger.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerCloudDeviceTrigger.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudDeviceTrigger.prototype.language = deepAssign(
	{},
	ElementControllerCloudBase.prototype.language,
	{
		'en-US': {
			CloudStorage: 'Device Trigger',
			value: 'Value',
			deviceTriggered: 'Device Triggered',
			triggerDevice: 'Trigger Device',
		},
	},
);
