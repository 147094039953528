function ElementControllerAppUICheckbox(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppUIBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('value', null, false, 'checkbox', null, null);

	this.addAbility('setValue', [{ name: 'value', validator: null }]);
	this.addAbility('toggle', []);

	this.addTrigger('changed');
	this.addTrigger('checked');
	this.addTrigger('unchecked');
}

ElementControllerAppUICheckbox.prototype = Object.create(
	ElementControllerAppUIBase.prototype,
);
ElementControllerAppUICheckbox.prototype.constructor = ElementControllerAppUICheckbox;

ElementControllerAppUICheckbox.prototype.NAME = 'Checkbox';
ElementControllerAppUICheckbox.prototype.TYPE = 'AppUICheckbox';
ElementControllerAppUICheckbox.prototype.HIDDEN = false;
ElementControllerAppUICheckbox.prototype.DEFAULT_TRIGGER = 'changed';
ElementControllerAppUICheckbox.prototype.DEFAULT_ABILITY = 'setValue';
ElementControllerAppUICheckbox.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerAppUICheckbox.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerAppUICheckbox.prototype.language = deepAssign(
	{},
	ElementControllerAppUIBase.prototype.language,
	{
		'en-US': {
			AppUICheckbox: 'Checkbox',
			value: 'Value',
			toggle: 'Toggle',
			setValue: 'Set Value',
			changed: 'Changed',
			checked: 'Checked',
			unchecked: 'Unchecked',
		},
	},
);
