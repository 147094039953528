/* eslint-disable complexity */
function WidgetNavbar(id, api, parentWidget, options) {
	var currentWidget = this;

	WidgetBase.call(this, id, api, parentWidget, options);

	this._navbarId = this.generateChildId('navbar');
	this._navbarExpanderButtonId = this.generateChildId('navbarExpanderButton');

	this._navbar = null;
	this._navbarExpanderButton = null;
	this._navbarEntryIdToValue = {};
	this._navbarEntryIdToMenuId = {};
	this._navbarMenuIdToEntry = {};

	this._firstTouch = { x: null, y: null };
}

WidgetNavbar.prototype = Object.create(WidgetBase.prototype);
WidgetNavbar.prototype.constructor = WidgetNavbar;

WidgetNavbar.prototype.enableTouch = function() {
	var currentWidget = this;
	/*
		https://api.jquery.com/jQuery.proxy/
		Keeps the scope of WidgetNavbar when an event is emitted from document
	*/
	$(document).on(
		'touchstart.WidgetNavbar',
		$.proxy(function(e) {
			currentWidget._onTouchStart(e);
		}, currentWidget),
	);
	$(document).on(
		'touchmove.WidgetNavbar',
		$.proxy(function(e) {
			currentWidget._onTouchMove(e);
		}, currentWidget),
	);
	$(document).on(
		'touchcancel.WidgetNavbar',
		$.proxy(function(e) {
			currentWidget._onTouchCancel(e);
		}, currentWidget),
	);
	$(document).on(
		'touchend.WidgetNavbar',
		$.proxy(function(e) {
			currentWidget._onTouchEnd(e);
		}, currentWidget),
	);
};

WidgetNavbar.prototype.disableTouch = function() {
	/*
		https://api.jquery.com/event.namespace/
		There are other touch events bound to document in the UI, this helps bound the events specifically to this widget
	*/
	$(document).off('touchstart.WidgetNavbar');
	$(document).off('touchmove.WidgetNavbar');
	$(document).off('touchcancel.WidgetNavbar');
	$(document).off('touchend.WidgetNavbar');
};

WidgetNavbar.prototype._onTouchStart = function(event) {
	//Only capture for the left side of the screen
	if (event.touches[0].clientX > $(document).width() / 2) {
		return;
	}

	this._firstTouch = {
		x: event.touches[0].clientX,
		y: event.touches[0].clientY,
	};
};

WidgetNavbar.prototype._onTouchEnd = function(event) {
	this._firstTouch = { x: null, y: null };
};

WidgetNavbar.prototype._onTouchCancel = function(event) {
	this._firstTouch = { x: null, y: null };
};

WidgetNavbar.prototype._onTouchMove = function(event) {
	if (this._firstTouch.x === null || this._firstTouch.y === null) {
		return;
	}

	var xUp = event.touches[0].clientX;
	var yUp = event.touches[0].clientY;

	var xDiff = this._firstTouch.x - xUp;
	var yDiff = this._firstTouch.y - yUp;

	//Deadzone check
	if (Math.abs(xDiff) < 64 && Math.abs(yDiff) < 64) {
		return;
	}

	if (Math.abs(xDiff) > Math.abs(yDiff)) {
		if (xDiff > 0) {
			this.shrink();
		} else {
			this.expand();
		}
	}

	this._firstTouch = { x: null, y: null };
};

WidgetNavbar.prototype.setUserAccountIcon = function(callback) {
	var currentWidget = this;

	// This widget seems to get instantiated early, before the user is actually logged in
	// If that happens, then just return
	// It will get update later after the user is logged in
	if (!currentWidget.getCurrentUser()) {
		callback.call(currentWidget, false);
		return;
	}

	const userInfo = currentWidget.getCurrentUser();
	var fullId = currentWidget.generateChildId('entry_UserAccount');

	// Get profile image
	currentWidget
		.getApiV2()
		.apis.users.getUserImage({ id: userInfo.id })
		.then((image) => {
			$(`#${fullId}`).find('.WidgetNavbar-Entry').html(`
		<img class="WidgetNavbar-EntryIcon WidgetNavbar-ProfileImage" src="${image}" title="${getLanguageTag(
				LocationUserAccount,
				'name',
			)}"></img>
		<span class="WidgetNavbar-EntryLabel standard-local-header-h3">${
			(userInfo || {}).username
		}</span>
	`);
			callback.call(currentWidget, false);
		})
		.catch(() => {
			$(`#${fullId}`).find('.WidgetNavbar-Entry').html(`
				<img class="_svg-inject WidgetNavbar-EntryIcon" src="./Resources/icons/ProfileNav.svg" title="${getLanguageTag(
					LocationUserAccount,
					'name',
				)}"></img>
				<span class="WidgetNavbar-EntryLabel standard-local-header-h3">${
					(userInfo || {}).username
				}</span>
			`);

			currentWidget.injectSVGElements();
			callback.call(currentWidget, false);
		});
};

WidgetNavbar.prototype._checkLocationPermissionHelper = function(
	entries,
	callback,
) {
	var currentWidget = this;

	var currentEntries = entries.slice();
	var outputEntries = [];

	function _checkEntriesPermissionHelper() {
		if (currentEntries.length <= 0) {
			callback.call(currentWidget, false, outputEntries);
			return;
		}

		var currentEntry = currentEntries.shift();

		if (currentEntry.value && currentEntry.value.location) {
			var currentLocationConstructor =
				window[`Location${currentEntry.value.location}`];

			if (
				!currentLocationConstructor ||
				!currentLocationConstructor.prototype.hasPermission
			) {
				outputEntries.push(currentEntry);
				_checkEntriesPermissionHelper();
				return;
			}

			currentLocationConstructor.prototype.hasPermission.call(
				currentWidget,
				function(err, hasAccess) {
					if (err) {
						_checkEntriesPermissionHelper();
						return;
					}

					if (hasAccess) {
						outputEntries.push(currentEntry);
					}

					_checkEntriesPermissionHelper();
					return;
				},
			);
		} else {
			outputEntries.push(currentEntry);
			_checkEntriesPermissionHelper();
			return;
		}
	}

	_checkEntriesPermissionHelper();
};

WidgetNavbar.prototype.setEntryNumberCount = function(entryId, number) {
	if (!this._navbarEntryIdToValue[entryId]) {
		return;
	}

	if (number !== 0) {
		$(`#${entryId}`)
			.find('.WidgetNavbar-EntryNumberCount')
			.html(number.toString());
		$(`#${entryId}`)
			.find('.WidgetNavbar-EntryNumberCount')
			.show();
	} else {
		$(`#${entryId}`)
			.find('.WidgetNavbar-EntryNumberCount')
			.html('');
		$(`#${entryId}`)
			.find('.WidgetNavbar-EntryNumberCount')
			.hide();
	}
};

WidgetNavbar.prototype.initialize = function(callback) {
	var currentWidget = this;

	this._navbarEntryIdToValue = {};
	this._navbarEntryIdToMenuId = {};
	this._navbarMenuIdToEntry = {};

	this.getMainContainer().addEventListener('locationChanged', function(
		event,
	) {
		currentWidget.onLocationChanged(event.location);
	});

	this.getMainContainer().addEventListener('userLoggedIn', function(event) {
		currentWidget.update();
	});

	this.getMainContainer().addEventListener('profileImageChanged', function(
		event,
	) {
		currentWidget.setUserAccountIcon(function(err) {});
	});

	this.getMainContainer().addEventListener('userLoggingOut', function(event) {
		var fullId = currentWidget.generateChildId('UserAccount');
		$(`#${fullId}`)
			.find('.WidgetNavbar-EntryIcon')
			.attr('src', './Resources/icons/ProfileNav.svg');
	});

	this.getMainContainer().addEventListener('modalHidden', function() {
		currentWidget.enableTouch();
	});

	this.getMainContainer().addEventListener('modalShown', function() {
		currentWidget.disableTouch();
	});

	this.enableTouch();

	this.update(function(err) {
		WidgetBase.prototype.initialize.call(this, callback);
	});
};

WidgetNavbar.prototype.update = function(callback) {
	const currentWidget = this;

	callback = callback || function() {};

	var sidebarSettings = {
		entries: [
			{
				value: { location: 'Dashboards' },
				childId: 'Dashboards',
				label: getLanguageTag(LocationDashboards, 'name'),
				imageSrc: './Resources/icons/DashboardsNav.svg',
			},
			{
				value: { location: 'Studio' },
				childId: 'Studio',
				label: getLanguageTag(LocationStudio, 'name'),
				imageSrc: './Resources/icons/ProjectsNav.svg',
			},

			{
				value: { location: 'DeviceManager' },
				childId: 'DeviceManager',
				label: getLanguageTag(LocationDeviceManager, 'name'),
				imageSrc: './Resources/icons/DevicesNav.svg',
			},
			{
				value: { location: 'Organizations' },
				childId: 'Organizations',
				label: getLanguageTag(LocationOrganizations, 'name'),
				imageSrc: './Resources/icons/OrganizationsNav.svg',
			},

			{
				value: { location: 'UsersAdministration' },
				childId: 'UsersAdministration',
				label: getLanguageTag(LocationUsersAdministration, 'name'),
				imageSrc: './Resources/icons/UsersNav.svg',
			},
		],

		bottomEntries: [
			{
				value: { location: 'SystemAdministration' },
				childId: 'SystemAdministration',
				label: getLanguageTag(LocationSystemAdministration, 'name'),
				imageSrc: './Resources/icons/SettingsNav.svg',
			},
			{
				value: { location: 'Help' },
				childId: 'Help',
				label: getLanguageTag(this.constructor, 'help'),
				imageSrc: './Resources/icons/HelpNav.svg',
				menuConstructor: WidgetHelpMenu,
			},

			{
				value: { location: 'Notifications' },
				childId: 'Notifications',
				label: getLanguageTag(LocationNotifications, 'name'),
				imageSrc: './Resources/icons/NotificationsNav.svg',
				// Got rid of the side menu by commenting this out
				// it's confusing for our customers, now the bell
				// will take the user straight to the notification
				// manager aka table where both read and unread
				// notifications are available
				// menuConstructor: WidgetNotificationsMenu,
			},

			{
				value: { location: 'UserAccount' },
				childId: 'UserAccount',
				label: getLanguageTag(LocationUserAccount, 'name'),
				imageSrc: './Resources/icons/ProfileNav.svg',
				menuConstructor: WidgetUserAccountMenu,
			},
		],
	};

	currentWidget._checkLocationPermissionHelper(
		sidebarSettings.entries,
		(err, filteredEntries) => {
			currentWidget._checkLocationPermissionHelper(
				sidebarSettings.bottomEntries,
				(err, filteredBottomEntries) => {
					const currentUser = currentWidget.getCurrentUser();

					sidebarSettings.bottomEntries = filteredBottomEntries;
					sidebarSettings.entries = filteredEntries;

					for (var i = 0; i < sidebarSettings.entries.length; i++) {
						sidebarSettings.entries[i].id = this.generateChildId(
							`entry_${sidebarSettings.entries[i].childId}`,
						);
						this._navbarEntryIdToValue[
							sidebarSettings.entries[i].id
						] = sidebarSettings.entries[i].value;
						sidebarSettings.entries[i].hasMenu = Boolean(
							sidebarSettings.entries[i].menuConstructor || false,
						);

						if (sidebarSettings.entries[i].menuConstructor) {
							sidebarSettings.entries[
								i
							].menuContainerId = this.generateChildId(
								`menu_${sidebarSettings.entries[i].childId}`,
							);
						}
					}

					for (
						var i = 0;
						i < sidebarSettings.bottomEntries.length;
						i++
					) {
						sidebarSettings.bottomEntries[
							i
						].id = this.generateChildId(
							`entry_${sidebarSettings.bottomEntries[i].childId}`,
						);
						this._navbarEntryIdToValue[
							sidebarSettings.bottomEntries[i].id
						] = sidebarSettings.bottomEntries[i].value;
						sidebarSettings.bottomEntries[i].hasMenu = Boolean(
							sidebarSettings.bottomEntries[i].menuConstructor ||
								false,
						);

						if (sidebarSettings.bottomEntries[i].menuConstructor) {
							sidebarSettings.bottomEntries[
								i
							].menuContainerId = this.generateChildId(
								`menu_${sidebarSettings.bottomEntries[i].childId}`,
							);
						}
					}

					this.renderTemplate({
						id: this._navbarId,
						navbarExpanderButtonId: this._navbarExpanderButtonId,
						entries: sidebarSettings.entries,
						bottomEntries: sidebarSettings.bottomEntries,
					});

					this.getContainer()
						.find('.WidgetNavbar-EntryNumberCount')
						.hide();

					this._navbar = $(`#${this._navbarId}`);
					this._navbarExpanderButton = $(
						`#${this._navbarExpanderButtonId}`,
					);

					for (var i = 0; i < sidebarSettings.entries.length; i++) {
						var currentEntry = sidebarSettings.entries[i];

						if (currentEntry.menuConstructor) {
							$(`#${currentEntry.menuContainerId}`).hide();

							this.removeChildWidget(
								currentEntry.menuContainerId,
								function(err) {
									this.addChildWidget(
										currentEntry.menuConstructor,
										currentEntry.menuContainerId,
										currentEntry.menuOptions || {},
										function(err, menuWidget) {
											menuWidget.hide();
											menuWidget.addEventListener(
												'numberOfItemsChanged',
												function(data) {
													currentWidget.setEntryNumberCount(
														this._id,
														data,
													);
												},
											);
										},
									);
								},
							);

							this._navbarEntryIdToMenuId[currentEntry.id] =
								currentEntry.menuContainerId;
							this._navbarMenuIdToEntry[
								currentEntry.menuContainerId
							] = currentEntry.id;
						}
					}

					for (
						var i = 0;
						i < sidebarSettings.bottomEntries.length;
						i++
					) {
						var currentEntry = sidebarSettings.bottomEntries[i];

						if (currentEntry.menuConstructor) {
							$(`#${currentEntry.menuContainerId}`).hide();

							this.removeChildWidget(
								currentEntry.menuContainerId,
								function(err) {
									this.addChildWidget(
										currentEntry.menuConstructor,
										currentEntry.menuContainerId,
										currentEntry.menuOptions || {},
										function(err, menuWidget) {
											menuWidget.hide();

											menuWidget.addEventListener(
												'numberOfItemsChanged',
												function(data) {
													currentWidget.setEntryNumberCount(
														currentWidget
															._navbarMenuIdToEntry[
															this._id
														],
														data,
													);
												},
											);
										},
									);
								},
							);

							this._navbarEntryIdToMenuId[currentEntry.id] =
								currentEntry.menuContainerId;
							this._navbarMenuIdToEntry[
								currentEntry.menuContainerId
							] = currentEntry.id;
						}
					}

					for (var k in this._navbarEntryIdToValue) {
						$(`#${k}`).click(function(event) {
							if (currentWidget._navbarEntryIdToMenuId[this.id]) {
								for (var k in currentWidget._navbarEntryIdToMenuId) {
									if (k !== this.id) {
										currentWidget
											.getChildWidget(
												currentWidget
													._navbarEntryIdToMenuId[k],
											)
											.hide();
									}
								}

								currentWidget
									.getChildWidget(
										currentWidget._navbarEntryIdToMenuId[
											this.id
										],
									)
									.toggleVisible();
								event.stopPropagation();
							} else {
								currentWidget.setSelectedEntry(this.id);
								currentWidget.event('navbarEntrySelected', {
									id: this.id,
									value:
										currentWidget._navbarEntryIdToValue[
											this.id
										],
								});
							}
						});
					}

					this.shrink();

					this._navbarExpanderButton.click(function() {
						currentWidget.onExpanderButtonClick();
					});

					this.setUserAccountIcon(function(err) {
						callback.call(currentWidget, err);
					});
				},
			);
		},
	);
};

WidgetNavbar.prototype.onLocationChanged = function(location) {
	if (!location) {
		return;
	}

	var locationConstructor = location.NAVBAR_LOCATION || location.constructor;

	var locationName = locationConstructor.name.replace('Location', '');

	for (var k in this._navbarEntryIdToValue) {
		if (this._navbarEntryIdToValue[k].location === locationName) {
			this.setSelectedEntry(k);
		}
	}
};

WidgetNavbar.prototype.setSelectedEntry = function(entryId) {
	for (var k in this._navbarEntryIdToValue) {
		$(`#${k}`).removeClass('WidgetNavbar-EntrySelected');
	}

	$(`#${entryId}`).addClass('WidgetNavbar-EntrySelected');
};

WidgetNavbar.prototype.onExpanderButtonClick = function() {
	if (this._navbar.hasClass('WidgetNavbar-Shrunk')) {
		this.expand();
	} else {
		this.shrink();
	}
};

WidgetNavbar.prototype.expand = function() {
	if (this._navbar === null) {
		return;
	}

	this._navbar.removeClass('WidgetNavbar-Shrunk');
	this._navbar.addClass('WidgetNavbar-Expanded');
};

WidgetNavbar.prototype.shrink = function() {
	if (this._navbar === null) {
		return;
	}

	this._navbar.addClass('WidgetNavbar-Shrunk');
	this._navbar.removeClass('WidgetNavbar-Expanded');
};

WidgetNavbar.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			help: 'Help',
		},
	},
);
