// Copyright 2018 Atmosphere IoT Corp.
// All rights reserved
// jshint esversion: 6

function WidgetUserAgreement(id, api, parent, options) {
	var currentWidget = this;

	WidgetPanelBase.call(this, id, api, parent, options);

	this.agreeCheckboxId = this.generateChildId('agreeCheckbox');
	this.footerContainerId = this.generateChildId('footerContainer');
}

WidgetUserAgreement.prototype = Object.create(WidgetPanelBase.prototype);
WidgetUserAgreement.prototype.constructor = WidgetUserAgreement;

WidgetUserAgreement.prototype.hasAgreed = function(
	currentMainContainer,
	callback,
) {
	// return callback(false, false); for testing purposes ONLY!
	if (currentMainContainer.getGlobalConfig().userAgreement !== undefined) {
		const userHash = currentMainContainer.getCurrentUser().tosHash;

		// https://www.freeformatter.com/json-escape.html 
		// This helps a lot to be able to clearly read the agreement 
		// when it needs to be modified, any JSON to text service should suffice.
		const userAgreementText = currentMainContainer.getGlobalConfig()
			.userAgreement;

		if (!userAgreementText || userAgreementText.length <= 0) {
			// The user agreement field has not been populated
			// in the global config, or it's empty or set to null.
			// Consider the agreement agreed.
			return callback(false, true);
		}

		if (
			userAgreementText.startsWith !== undefined &&
			userAgreementText.startsWith('http')
		) {
			$.ajax({
				type: 'GET',
				url: userAgreementText,
				crossDomain: true,
				data: JSON.stringify(postData),
				dataType: 'json',
				contentType: 'application/json',
				success: function(responseData, textStatus, jqXHR) {
					var checksum = hex_md5(JSON.stringify(responseData));

					if (userHash !== checksum) {
						callback(false, false);
					} else {
						callback(false, true);
					}
				},
				error: function(responseData, textStatus, errorThrown) {
					callback(false, false);
				},
			});
		} else {
			var checksum = hex_md5(JSON.stringify(userAgreementText));

			if (userHash !== checksum) {
				callback(false, false);
			} else {
				callback(false, true);
			}
		}
	} else {
		callback(false, true);
		return;
	}
};

WidgetUserAgreement.prototype.excludeFromWizard = true;

WidgetUserAgreement.prototype.getAgreementData = function(callback) {
	var currentWidget = this;

	var userAgreementText =
		this.getMainContainer().getGlobalConfig().userAgreement || null;

	if (userAgreementText) {
		if (
			userAgreementText.startsWith !== undefined &&
			userAgreementText.startsWith('http')
		) {
			$.ajax({
				type: 'GET',
				url: userAgreementText,
				crossDomain: true,
				data: JSON.stringify(postData),
				dataType: 'json',
				contentType: 'application/json',
				success: function(responseData, textStatus, jqXHR) {
					callback.call(currentWidget, false, responseData);
				},
				error: function(responseData, textStatus, errorThrown) {
					callback.call(currentWidget, responseData, null);
				},
			});
		} else {
			callback.call(currentWidget, false, userAgreementText);
		}
	} else {
		callback.call(currentWidget, false, null);
	}
};

WidgetUserAgreement.prototype.onAgreeButtonClicked = function() {
	var currentWidget = this;

	if (this.agreeCheckbox.is(':checked')) {
		this.acceptAgreement();
	} else {
		this.getMainContainer().showPopupErrorMessage(
			this.getLanguageTag('pleaseClickTheCheckbox'),
		);
	}
};

WidgetUserAgreement.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.getAgreementData(function(err, agreementData) {
		if (err) {
			callback.call(this, err);
			return;
		}

		this.renderTemplate(
			{
				agreeCheckboxId: this.agreeCheckboxId,
				agreementText: getMessageLanguage(agreementData),
				agreeCheckboxLabel: getLanguageTag(
					WidgetUserAgreement,
					'agreeToAbove',
				),
				footerContainerId: this.footerContainerId,
			},
			WidgetUserAgreement.name,
		);

		this.agreeCheckbox = $(`#${this.agreeCheckboxId}`);

		this.addChildWidget(
			WidgetButtonsFooter,
			this.footerContainerId,
			{},
			function(err, footerWidget) {
				this.footerWidget = footerWidget;

				this.footerWidget.setButtons([
					{
						label: getLanguageTag(this.constructor, 'dismiss'),
						value: 'dismissed',
						classes: [`btn-cancel`],
					},

					{
						label: getLanguageTag(this.constructor, 'agree'),
						value: 'agreed',
						classes: ['btn-primary'],
					},
				]);

				this.footerWidget.addEventListener(
					'footerButtonPressed',
					function(value) {
						if (value === 'agreed') {
							currentWidget.onAgreeButtonClicked();
						} else {
							currentWidget.rejectAgreement();
						}
					},
				);

				WidgetPanelBase.prototype.initialize.call(this, callback);
			},
		);
	});
};

WidgetUserAgreement.prototype.acceptAgreement = function(callback) {
	callback = callback || function() {};

	var currentWidget = this;

	this.getAgreementData(function(err, agreementData) {
		if (err) {
			callback.call(currentWidget, err);
			return;
		}

		const agreementHash = hex_md5(JSON.stringify(agreementData));

		const user = new User(currentWidget.getApiV2().apis, {
			...currentWidget.getMainContainer().getCurrentUser(),
		});
		delete user.data.ability;
		user.data.tosHash = agreementHash;
		user.save()
			.then(() => {
				callback.call(currentWidget, false);
			})
			.catch((err) => {
				callback.call(currentWidget, err);
			});

		currentWidget.event('agreementAccepted');
	});
};

WidgetUserAgreement.prototype.rejectAgreement = function(callback) {
	callback = callback || function() {};

	var currentWidget = this;

	const user = new User(currentWidget.getApiV2().apis, {
		...currentWidget.getMainContainer().getCurrentUser(),
	});
	delete user.data.ability;
	user.data.tosHash = null;
	user.save()
		.then(() => {
			callback.call(currentWidget, false);
		})
		.catch((err) => {
			callback.call(currentWidget, err);
		});

	currentWidget.event('agreementRejected');
};

WidgetUserAgreement.prototype.language = deepAssign(
	{},
	WidgetPanelBase.prototype.language,
	{
		'en-US': {
			name: 'User Agreement',
			iAccept: 'I Accept',
			agree: 'Agree',
			agreeToAbove: 'By checking this box you agree to the above terms.',
			pleaseClickTheCheckbox: 'Please read and accept the listed terms',
		},
	},
);

WidgetUserAgreement.prototype.$_$ = function(done) {
	this.$_SetupWidgetTest(function(err) {
		WidgetPanelBase.prototype.$_$.call(this, done);
	});
};
