/*
 * This is the table widget that allows you to display items in
 * a row column format.
 *
 * @param {string} id					Unique container div id
 * @param {CloudAPI} api				CloudAPI instance
 * @param {WidgetBase} parent			Parent Widget
 * @param {Object} options 				Widget Options
 * @param {Boolean} options.ajaxSorting - Enable AJAX sorting on column header clicks
 * @param {Boolean} options.hideControls - Hide controls area entirely
 * @param {Array} options.initialSort - Initial Sort, Takes an array of objects representing the name and direction of each column to apply the initial sort to.
 * @param {Boolean} options.selectable - Are rows selectable
 * @param {Function} options.apiRoute - API Route to be called for server side pagination
 * @param {Function} options.addProps - Function called  before API route execution in order to add query props to HTTP call
 * @param {Function} options.sortByFrom - Convert parameters for API Sorting
 * @param {Boolean} options.headerSort - Are columns sortable?
 * @param {String} options.layout - Layout mode. See tabulator 'layout' option
 * @param {Object} options.columns - Column configuration
 * @param {Object} options.filter - Tabulator filter. Can be a function or a list of Tabulator filters
 * @param {Function} options.onRowClicked - Callback executed when a row is clicked
 * @param {Function} options.tableBuilt - Callback executed when table is built
 * @param {Function} options.ajaxResponse - Callback executed with AJAX response data
 * @param {Function} options.entryMapper - ASYNC function to be executed for every returned data row entry. Should return a mutated form of the original data.
 * @param {Object} options.actionMenuEntries
 * @param {Number} options.paginationSize - How many entries should be on each page
 */
function WidgetTableDynamic(id, api, parent, options) {
	WidgetBase.call(this, id, api, parent, options);

	this.disableRowSelectCellClick();
	this.headerId = this.generateChildId('header');
	this.selectAllId = this.generateChildId('selectAll');
	this.selectClearId = this.generateChildId('selectClear');
	this.selectionActionId = this.generateChildId('selectionAction');
	this.controlsSectionId = this.generateChildId('controlsSection');
	this.footerId = this.generateChildId('footer');
	this.selectionActionMenuContainerId = this.generateChildId(
		'selectionActionMenuContainer',
	);
	this.tableId = this.generateChildId('table');

	this._filterTimeout = null;
	this._page = null;
}

WidgetTableDynamic.prototype = Object.create(WidgetBase.prototype);
WidgetTableDynamic.prototype.constructor = WidgetTableDynamic;

WidgetTableDynamic.prototype.initialize = function(callback) {
	this.renderTable();

	WidgetBase.prototype.initialize.call(this, callback);
};

WidgetTableDynamic.prototype.remove = function(callback) {
	WidgetBase.prototype.remove.call(this, callback);
};

/**
 * If the leftmost column is checkboxes, ignore any clicks in those cells that aren't actual
 * checkbox clicks. Without this, it's easy to misclick and accidentally click a row when you mean
 * to just click the checkbox.
 */
WidgetTableDynamic.prototype.disableRowSelectCellClick = function() {
	const opts = this.getOptions();

	if (
		opts.columns &&
		opts.columns.length > 0 &&
		opts.columns[0].formatter === 'rowSelection'
	) {
		opts.columns[0].cellClick = (e, cell) => {
			// prevent click event from reaching the row.
			e.stopPropagation();
		};
	}
};

WidgetTableDynamic.prototype.sortByFrom = function(sorters) {
	// Converts the tabulator parameters into API V2 params for sorting
	// http://tabulator.info/docs/4.0/data#ajax-sort
	// TODO this should probably be something passed in by each table instance as configuration, not stored globally in the widget itself
	let sortBy = [];
	if (sorters && sorters.length) {
		sortBy = sorters.map((entry) => {
			let str = '';

			switch (entry.field) {
				// Sort user table by username
				case 'userLink':
					entry.field = 'username';
					break;
				// Sort device table by device name
				case 'deviceLink':
					entry.field = 'name';
					break;
				// To get sorting on the organization table by name
				case 'orgLink':
					entry.field = 'name';
					break;
				case 'nameLink':
					entry.field = 'name';
				default:
					entry.field = entry.field;
					break;
			}

			if (entry.dir === 'desc') {
				str += '-';
			} else {
				str += '+';
			}

			str += entry.field;
			return str;
		});
	}
	return sortBy;
};

WidgetTableDynamic.prototype.renderTable = function(callback) {
	var currentWidget = this;

	callback = callback || function() {};

	const executeApiCall = async function(apiFn, config, params) {
		let parameters = { ...params };

		if (currentWidget.getOptions().addParams) {
			parameters = {
				...params,
				...currentWidget.getOptions().addParams(),
			};
		}

		let sortByFrom =
			currentWidget.getOptions().sortByFrom || currentWidget.sortByFrom;

		parameters.sortBy = sortByFrom(parameters.sorters);
		if (!parameters.sortBy.length) {
			delete parameters.sortBy;
		}
		delete parameters.sorters;

		const resp = await apiFn(parameters);

		if (resp === null) {
			return null;
		}

		const { meta } = resp;
		let { data } = resp;

		if (currentWidget.getOptions().entryMapper) {
			const mapPromises = data.map((entry) => {
				entry.raw = { ...entry };
				return currentWidget.getOptions().entryMapper(entry);
			});
			data = await Promise.all(mapPromises);
		}
		return {
			last_page: meta.totalPages,
			row_count: meta.totalDocs,
			limit: meta.limit,
			page: meta.page,
			data,
		};
	};

	async function ajaxResponse(url, params, response) {
		let stats = ``;
		const { page, limit, row_count, data } = response;
		// tabulator's "row_count" is really the total records
		const total = row_count;
		const start = (page - 1) * limit + 1;
		const end = (page - 1) * limit + data.length;
		if (total > 0) {
			//TODO: Language this
			stats += `Showing ${start} - ${end} of ${total} entries`;
		} else {
			//TODO: Language this
			stats = '0 entries';
		}
		$(`#${currentWidget.tableId}`)
			.find(`.WidgetTableDynamic-footer`)
			.text(stats);
		return response;
	}

	const opts = {
		headerId: this.headerId,
		tableId: this.tableId,
		selectAllId: this.selectAllId,
		labelSelectAll: getLanguageTag(this.constructor, 'selectAll'),
		selectClearId: this.selectClearId,
		labelSelectClear: getLanguageTag(this.constructor, 'selectClear'),
		selectionActionId: this.selectionActionId,
		controlsSectionId: this.controlsSectionId,
		labelAction: getLanguageTag(this.constructor, 'action'),
		labelNoData:
			this.getOptions().noData ||
			getLanguageTag(this.constructor, 'noData'),
		selectionActionMenuContainerId: this.selectionActionMenuContainerId,
	};

	this.renderTemplate(opts, WidgetTableDynamic.name);

	this._controlsSection = $(`#${this.controlsSectionId}`);

	this._selectionAction = $(`#${this.selectionActionId}`);
	this._selectionActionMenuContainer = $(
		`#${this.selectionActionMenuContainerId}`,
	);

	this._selectionAction.hide();

	if (currentWidget._table === undefined) {
		// The try / catch here is used to
		// print the error to the console.
		// Helps greatly to debug issues during
		// Tabulator's initialization process.
		try {
			currentWidget._table = new Tabulator(`#${currentWidget.tableId}`, {
				autoColumns: currentWidget.getOptions().autoColumns || false,
				data: [], //assign data to table
				responsiveLayout: 'hide', // hide rows that no longer fit
				pagination: 'remote',
				movableColumns:
					currentWidget.getOptions().movableColumns || false,
				columnMoved:
					currentWidget.getOptions().columnMoved || undefined,
				rowFormatter: currentWidget.getOptions().rowFormatter || null,
				selectable: currentWidget.getOptions().selectable || false,
				ajaxURL: currentWidget.getOptions().apiRoute,
				ajaxRequestFunc: executeApiCall,
				ajaxSorting: currentWidget.getOptions().ajaxSorting,
				ajaxResponse:
					currentWidget.getOptions().ajaxResponse || ajaxResponse,
				paginationSize: currentWidget.getOptions().paginationSize || 50,
				paginationDataSent: {
					size: 'limit',
				},
				headerSort: currentWidget.getOptions().headerSort || false,
				layout: currentWidget.getOptions().layout || 'fitColumns', //fit columns to width of table (optional)
				columns: currentWidget.getOptions().columns || null,
				footerElement: `<div id="${currentWidget.footerId}" class='WidgetTableDynamic-footer tabulator-footer'> </div>`,
				tableBuilt:
					currentWidget.getOptions().tableBuilt || function() {},
				initialSort: currentWidget.getOptions().initialSort || [],
				rowClick: !isPhonegap()
					? (event, row) => {
							if (currentWidget.getOptions().onRowClicked) {
								currentWidget
									.getOptions()
									.onRowClicked(row._row.data);
							}
					  }
					: undefined,
				rowDblTap:(event, row) => {
							if (currentWidget.getOptions().onRowDblTap) {
								currentWidget
									.getOptions()
									.onRowDblTap(row._row.data);
							}
					},
				rowSelectionChanged: function(data, rows) {
					if (
						rows.length > 0 &&
						currentWidget.getOptions().actionMenuEntries
					) {
						currentWidget._selectionAction.show();
					} else {
						currentWidget._selectionAction.hide();
					}

					if (currentWidget.getOptions().onSelectionChanged) {
						currentWidget
							.getOptions()
							.onSelectionChanged.call(currentWidget, rows);
					}
				},
			});
		} catch (tabulatorError) {
			// Logging and re-throwing because the
			// default behavior is not very helpful.
			console.warn(
				`[WidgetTableDynamic] Error initializing Tabulator due to ${tabulatorError}`,
			);
			throw tabulatorError;
		}
	}

	this.removeChildWidget(this.selectionActionMenuContainerId, function(err) {
		this.addChildWidget(
			WidgetMenu,
			this.selectionActionMenuContainerId,
			{},
			function(err, actionMenuWidget) {
				this._actionMenuWidget = actionMenuWidget;

				this._actionMenuWidget.setMenu(
					currentWidget.getOptions().actionMenuEntries || [],
				);

				this._actionMenuWidget.hide();

				actionMenuWidget.addEventListener('menuEntryClicked', function(
					value,
				) {
					currentWidget._actionMenuWidget.hide();
					currentWidget.event('actionSelected', value);
				});

				this._selectionAction.click(function(e) {
					currentWidget._actionMenuWidget.toggleVisible();

					e.stopPropagation();
				});

				if (this.getOptions().hideControls) {
					this._controlsSection.hide();
				}

				callback.call(this, false);
			},
		);
	});
};

/**
 * Get selected rows as WidgetTableDynamic entries. This will include anything added to the entry in the entryMapper
 */
WidgetTableDynamic.prototype.getSelectedRowsEntries = function() {
	return this._table.getSelectedData();
};

/**
 * Get selected rows as raw data. This is the raw data from the API that was used to populate each row
 */
WidgetTableDynamic.prototype.getSelectedRowsData = function() {
	const selectedRows = this._table.getSelectedData();
	return selectedRows.map((entry) => entry.raw);
};

/**
 * Clear the a row selection
 * If you do not specify a specific row you will un-select all of them
 */
WidgetTableDynamic.prototype.deselectRow = function(rowNumber) {
	if (!this._table) {
		return false;
	}

	return this._table.deselectRow(rowNumber);
};

/**
 * Allows us to clear all the data out of the table and set it to empty
 */
WidgetTableDynamic.prototype.clearTable = function() {
	return this._table.clearData();
};

WidgetTableDynamic.prototype.update = function(callback = () => {}) {
	const currentWidget = this;
	if (this._table) {
		this._table
			.replaceData()
			.then(() => {
				callback.call(currentWidget, false);
			})
			.catch((err) => {
				callback.call(currentWidget, err);
			});
	}
	callback.call(this, false);
};

WidgetTableDynamic.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'Table',
			selectAll: 'Select All',
			selectClear: 'Clear Selection',
			action: 'Action',
			search: 'Search Table',
			noData: 'This table has no information.',
			sortAscending: 'Sort Ascending',
			sortDescending: 'Sort Descending',
			never: 'Never',
			total: 'Total',
		},
	},
);
