function LocationUserAdministration(id, api, parentWidget, options) {
	LocationBase.call(this, id, api, parentWidget, options);
}

LocationUserAdministration.prototype = Object.create(LocationBase.prototype);
LocationUserAdministration.prototype.constructor = LocationUserAdministration;

LocationUserAdministration.prototype.initialize = function(callback) {
	const currentLocation = this;
	const currentHashCommand = getHashCommand();

	// Null user case
	if (!currentHashCommand.username) {
		this.getMainContainer().showPopupErrorMessage(
			this.getLanguageTag('cannotEditTheNullUser'),
		);
		callback.call(this, { redirect: LocationUsersAdministration });
		return;
	}

	currentLocation.setTabWidgets(
		null,
		[
			{
				childId: 'profile',
				label: currentLocation.getLanguageTag('profile'),
				constructor: WidgetUserAccountProfile,
				options: {},
			},

			{
				childId: 'notifications',
				label: currentLocation.getLanguageTag('notifications'),
				constructor: WidgetNotificationSettings,
				options: {},
			},
		],
		function(err) {
			this.addHeaderWidget(
				WidgetLocationControls,
				{
					entries: [
						{
							name: 'exit',
							imageSrc: './Resources/icons/Exit.svg',
							label: this.getLanguageTag('exit'),
						},
					],
				},
				function(err, controlsWidget) {
					controlsWidget.addEventListener('exit', function() {
						currentLocation._onExit();
					});

					LocationBase.prototype.initialize.call(this, callback);
				},
			);
		},
	);
};

LocationUserAdministration.prototype._onExit = function() {
	if (!this.getMainContainer().setLocationBack()) {
		this.getMainContainer().setLocation(LocationUsersAdministration, {org:getHashCommand().org});
	}
};

LocationUserAdministration.prototype.NAVBAR_LOCATION = LocationUsersAdministration;

LocationUserAdministration.prototype.language = deepAssign(
	{},
	LocationBase.prototype.language,
	{
		'en-US': {
			name: 'User Admin',
			profile: 'Profile',
			groups: 'Groups',
			security: 'Security',
			notifications: 'Notifications',
			cannotEditTheNullUser: 'You cannot modify the null user',
			doNotHavePermissionToViewThisUser:
				'You do not have permission to view this user',
		},
	},
);
