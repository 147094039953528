function ElementControllerCloudBase(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);
}

ElementControllerCloudBase.prototype = Object.create(
	ElementControllerBase.prototype,
);
ElementControllerCloudBase.prototype.constructor = ElementControllerCloudBase;

ElementControllerCloudBase.prototype.TYPE = 'CloudBase';
ElementControllerCloudBase.prototype.HIDDEN = true;
ElementControllerCloudBase.prototype.CATEGORY = 'cloud';
ElementControllerCloudBase.prototype.VARIANTS = ['cloud'];

ElementControllerCloudBase.prototype.language = deepAssign(
	{},
	ElementControllerBase.prototype.language,
	{
		'en-US': {
			CloudBase: 'Cloud Base',
		},
	},
);
