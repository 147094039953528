function ElementControllerCloudMeta(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('value', null, null, 'none', null, null);

	this.addAbility('setValue', [
		{ name: 'tag', validator: null },
		{ name: 'value', validator: null },
	]);
	this.addAbility('setAlarming', [{ name: 'values', validator: null }]);
	this.addAbility('setStatusNone', []);
	this.addAbility('setStatusGood', []);
	this.addAbility('setStatusWarning', []);
	this.addAbility('setStatusCritical', []);
	this.addAbility('setStatusInfo', []);
	this.addAbility('setStatusMissing', []);

	this.addAbility('escalateStatusToGood', []);
	this.addAbility('escalateStatusToWarning', []);
	this.addAbility('escalateStatusToCritical', []);
	this.addAbility('escalateStatusToInfo', []);
	this.addAbility('escalateStatusToMissing', []);

	this.addAbility('setGeoLocation', [
		{ name: 'lon', validator: null },
		{ name: 'lat', validator: null },
	]);
}

ElementControllerCloudMeta.prototype = Object.create(
	ElementControllerCloudBase.prototype,
);
ElementControllerCloudMeta.prototype.constructor = ElementControllerCloudMeta;

ElementControllerCloudMeta.prototype.NAME = 'CloudMeta';
ElementControllerCloudMeta.prototype.TYPE = 'CloudMeta';
ElementControllerCloudMeta.prototype.HIDDEN = false;
ElementControllerCloudMeta.prototype.DEFAULT_TRIGGER = 'triggered';
ElementControllerCloudMeta.prototype.DEFAULT_ABILITY = 'setValue';
ElementControllerCloudMeta.prototype.DEFAULT_ARGUMENTS = [];
ElementControllerCloudMeta.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudMeta.prototype.language = deepAssign(
	{},
	ElementControllerCloudBase.prototype.language,
	{
		'en-US': {
			CloudMeta: 'Cloud Meta',
			setValue: 'Set Value',
			setAlarming: 'Set Alarming',
			setStatusNone: 'Set Status to None',
			setStatusGood: 'Set Status to Good',
			setStatusWarning: 'Set Status to Warning',
			setStatusCritical: 'Set Status to Critical',
			setStatusInfo: 'Set Status to Info',
			setStatusMissing: 'Set Status to Missing',
			setGeoLocation: 'Set Geolocation Position',
			escalateStatusToGood: 'Escalate Status to Good',
			escalateStatusToWarning: 'Escalate Status to Warning',
			escalateStatusToCritical: 'Escalate Status to Critical',
			escalateStatusToInfo: 'Escalate Status to Missing',
			escalateStatusToMissing: 'Escalate Status to Info',
			setGeoLocation: 'Set Geolocation Position',
			lon: 'Longitude',
			lat: 'Latitude',
		},
	},
);
