function PlaneControllerEmbeddedBase(parentController) {
	PlaneControllerBase.call(this, parentController);

	this._types = ['base', 'embedded'];
	this._staticFunctions = {};
	this._staticHeaderCode = '';
	this._staticFooterCode = '';
	this._libraries = {};
	this._libraryElementControllers = {}; //We need to add on the fly controlers for the elemnets that are introduced via embedded element libraries.
	this._runtimeDrivers = deepAssign({}, this.DRIVERS);
	this.addStaticFunction('ATMO_Setup', 'void', '', []);
}

PlaneControllerEmbeddedBase.prototype = Object.create(
	PlaneControllerBase.prototype,
);
PlaneControllerEmbeddedBase.prototype.constructor = PlaneControllerEmbeddedBase;

PlaneControllerEmbeddedBase.prototype.COUPLING_BADGE = 'embeddedBadge';

PlaneControllerEmbeddedBase.prototype.EXTRA_TOOLS = ['WidgetCodeEditor'];

PlaneControllerEmbeddedBase.prototype.PLANE_ICON =
	'./Resources/icons/EmbeddedView.svg';

PlaneControllerEmbeddedBase.prototype.SOURCE_DOWNLOAD = true;
PlaneControllerEmbeddedBase.prototype.PROGRAMMING = true;

PlaneControllerEmbeddedBase.prototype.DRIVERS = {};

PlaneControllerEmbeddedBase.prototype.FIRMWARE_DOWNLOAD_FILES = {
	artifactName: 'firmware',
	fileName: 'firmware.zip',
};

PlaneControllerEmbeddedBase.prototype.TYPE = 'embedded';
PlaneControllerEmbeddedBase.prototype.SUPPORTS = [
	'embedded',
	'abilities',
	'variables',
	'properties',
	'interval',
	'triggers',
	'ble',
	'bleCharacteristic',
	'i2c',
	'uart',
	'gpio',
	'adc',
	'pwm',
];

PlaneControllerEmbeddedBase.prototype.getAvailableElements = function() {
	var elements = PlaneControllerBase.prototype.getAvailableElements.call(
		this,
	);

	for (var elementControllerName in this._libraryElementControllers) {
		elements[elementControllerName] = {
			controllerConstructor: this._libraryElementControllers[
				elementControllerName
			],
		};
	}

	return elements;
};

PlaneControllerEmbeddedBase.prototype.getElementController = function(type) {
	var controller = PlaneControllerBase.prototype.getElementController.call(
		this,
		type,
	);

	if (controller === null) {
		if (this._libraryElementControllers[type] !== undefined) {
			return this._libraryElementControllers[type];
		}
	}

	return controller;
};

PlaneControllerEmbeddedBase.prototype.compareLibraryMD5s = function(
	libraryEELA,
	libraryEELB,
) {
	return libraryEELA.md5 === libraryEELB.md5;
};

PlaneControllerEmbeddedBase.prototype.getLibrary = function(libraryName) {
	if (this._libraries[libraryName] === undefined) {
		return null;
	}

	return this._libraries[libraryName];
};

PlaneControllerEmbeddedBase.prototype.addLibrary = function(
	libraryEEL,
	update,
) {
	update = update || false;

	libraryName = libraryEEL.libName;

	if (!update && this._libraries[libraryName] !== undefined) {
		this.error('libraryAlreadyExists');
		return;
	}

	this._libraries[libraryName] = libraryEEL;

	this.updateElementControllersFromLibraries();

	this.event('libraryAdded', {
		plane: this,
		libraryName: libraryName,
		libraryEEL: libraryEEL,
	});

	if (update) {
		this.updateElementsUsingLibrary(libraryName);
		this.event('libraryUpdated', {
			plane: this,
			libraryName: libraryName,
			libraryEEL: libraryEEL,
		});
	}
};

PlaneControllerEmbeddedBase.prototype.removeLibrary = function(libraryName) {
	if (this._libraries[libraryName] === undefined) {
		this.error('libraryDoesNotExist');
		return;
	}

	delete this._libraries[libraryName];

	this.updateElementControllersFromLibraries();

	this.event('libraryRemoved', { plane: this, libraryName: libraryName });
};

PlaneControllerEmbeddedBase.prototype.updateElementsUsingLibrary = function(
	libraryName,
) {
	var currentLibrary = this.getLibrary(libraryName);

	if (currentLibrary === null) {
		console.log(
			'PlaneControllerEmbeddedBase.prototype.updateElementsUsingLibrary: No such library',
		);
		return;
	}

	var libraryElementTypes = {};

	for (var i = 0; i < currentLibrary.elements.length; i++) {
		libraryElementTypes[currentLibrary.elements[i].type] =
			currentLibrary.elements[i];
	}

	for (var i = 0; i < this._elements.length; i++) {
		var currentElement = this._elements[i];

		//We check to see if this element is affected by the update to the library
		if (libraryElementTypes[currentElement.TYPE] !== undefined) {
			this.updateElement(
				currentElement.getName(),
				this.getElementController(currentElement.TYPE),
			);
		}
	}
};

PlaneControllerEmbeddedBase.prototype.removeUnavailableElements = function() {
	var availableElements = this.getAvailableElements();

	var availableElementTypes = Object.keys(availableElements);

	for (var i = 0; i < this._elements.length; i++) {
		var currentElement = this._elements[i];

		if (availableElementTypes.indexOf(currentElement.getType()) < 0) {
			this.removeElement(currentElement.getName());
		}
	}
};

PlaneControllerEmbeddedBase.prototype.updateElementControllersFromLibraries = function() {
	this._libraryElementControllers = {};
	var currentPlane = this;

	for (var libraryName in this._libraries) {
		var currentLibrary = this._libraries[libraryName];

		for (var i = 0; i < currentLibrary.elements.length; i++) {
			var currentElement = currentLibrary.elements[i];

			var newElementController = function(
				parentController,
				parentPlane,
				elementName,
			) {
				ElementControllerEmbeddedLibraryBase.call(
					this,
					parentController,
					parentPlane,
					elementName,
				);
			};

			newElementController.prototype = Object.create(
				ElementControllerEmbeddedLibraryBase.prototype,
			);
			newElementController.prototype.constructor = newElementController;

			newElementController.prototype.NAME = currentElement.name;
			newElementController.prototype.IMPLEMENTS =
				currentElement.implements || [];
			newElementController.prototype.TYPE = currentElement.type;
			newElementController.prototype.HIDDEN = Boolean(
				currentElement.hidden,
			);
			newElementController.prototype.ICON = currentElement.icon;
			newElementController.prototype.TOOLBOX_ICON =
				currentElement.toolboxIcon;
			newElementController.prototype.CATEGORY = 'imported';
			newElementController.prototype.EEL = currentElement;
			newElementController.prototype.HELP_PAGE_HREF =
				currentElement.helpPageHref;

			if (currentElement.couplerType !== undefined) {
				newElementController.prototype.COUPLER_TYPE =
					currentElement.couplerType;
			}

			newElementController.prototype.DEFAULT_ABILITY =
				currentElement.defaultAbility;
			newElementController.prototype.DEFAULT_TRIGGER =
				currentElement.defaultTrigger;
			newElementController.prototype.language = deepAssign(
				{},
				newElementController.prototype.language,
				currentElement.language,
			);

			this._libraryElementControllers[
				currentElement.type
			] = newElementController;
		}
	}

	this.removeUnavailableElements();

	this.event('availableElementControllersUpdated', { plane: this });
};

PlaneControllerEmbeddedBase.prototype.updateLibrary = function(
	libraryName,
	libraryEEL,
) {
	this.error('functionNotImplemented');
	return;
};

PlaneControllerEmbeddedBase.prototype.addStaticFunction = function(
	name,
	returnType,
	code,
	args,
) {
	if (this._staticFunctions[name] !== undefined) {
		this.error('staticFunctionAlreadyExist');
		return;
	}

	var staticFunction = {
		returnType: returnType,
		code: code,
		arguments: [],
	};

	this._staticFunctions[name] = staticFunction;

	for (var i = 0; i < args.length; i++) {
		this.addStaticFunctionArgument(name, args[i].name, args[i].type);
	}
};

PlaneControllerEmbeddedBase.prototype.addStaticFunctionArgument = function(
	name,
	argumentName,
	argumentType,
) {
	if (this._staticFunctions[name] === undefined) {
		this.error('staticFunctionDoesNotExist');
		return;
	}

	this._staticFunctions[name].arguments.push({
		name: argumentName,
		type: argumentType,
	});

	return;
};

PlaneControllerEmbeddedBase.prototype.setStaticFunctionReturnType = function(
	name,
	returnType,
) {
	if (this._staticFunctions[name] === undefined) {
		this.error('staticFunctionDoesNotExist');
		return;
	}

	this._staticFunctions[name].returnType = returnType;

	return;
};

PlaneControllerEmbeddedBase.prototype.setStaticFunctionCode = function(
	name,
	code,
) {
	if (this._staticFunctions[name] === undefined) {
		this.error('staticFunctionDoesNotExist');
		return;
	}

	this._staticFunctions[name].code = code;

	return;
};

PlaneControllerEmbeddedBase.prototype.getStaticFunction = function(name) {
	if (this._staticFunctions[name] === undefined) {
		this.error('staticFunctionDoesNotExist');
		return null;
	}

	return this._staticFunctions[name];
};

PlaneControllerEmbeddedBase.prototype.getStaticFunctionCode = function(name) {
	if (this._staticFunctions[name] === undefined) {
		this.error('staticFunctionDoesNotExist');
		return null;
	}

	return this._staticFunctions[name].code;
};

PlaneControllerEmbeddedBase.prototype.getStaticFunctionReturnType = function(
	name,
) {
	if (this._staticFunctions[name] === undefined) {
		this.error('staticFunctionDoesNotExist');
		return null;
	}

	return this._staticFunctions[name].returnType;
};

PlaneControllerEmbeddedBase.prototype.getStaticFunctionArguments = function(
	name,
) {
	if (this._staticFunctions[name] === undefined) {
		this.error('staticFunctionDoesNotExist');
		return null;
	}

	return this._staticFunctions[name].arguments;
};

PlaneControllerEmbeddedBase.prototype.setStaticHeaderCode = function(code) {
	this._staticHeaderCode = code;

	return;
};

PlaneControllerEmbeddedBase.prototype.getStaticHeaderCode = function() {
	return this._staticHeaderCode;
};

PlaneControllerEmbeddedBase.prototype.setStaticFooterCode = function(code) {
	this._staticFooterCode = code;

	return;
};

PlaneControllerEmbeddedBase.prototype.getStaticFooterCode = function() {
	return this._staticFooterCode;
};

PlaneControllerEmbeddedBase.prototype.import = function(planeData) {
	if (planeData === null || planeData === undefined) {
		return;
	}

	this._libraries = planeData.libraries;
	this._staticHeaderCode = planeData.static.header;
	this._staticFooterCode = planeData.static.footer;
	this._staticFunctions = Object.assign({}, planeData.static.functions);

	this.updateElementControllersFromLibraries();

	PlaneControllerBase.prototype.import.call(this, planeData);
};

PlaneControllerEmbeddedBase.prototype.export = function() {
	var planeExportData = PlaneControllerBase.prototype.export.call(this);

	planeExportData.libraries = this._libraries;
	planeExportData.drivers = this._runtimeDrivers;
	planeExportData.firmwareFiles = this.FIRMWARE_DOWNLOAD_FILES;
	planeExportData.static = {};
	planeExportData.static.header = this._staticHeaderCode;
	planeExportData.static.footer = this._staticFooterCode;
	planeExportData.static.functions = {};

	for (var staticFunctionName in this._staticFunctions) {
		planeExportData.static.functions[
			staticFunctionName
		] = this._staticFunctions[staticFunctionName];
	}

	return planeExportData;
};

PlaneControllerEmbeddedBase.prototype.language = {
	'en-US': {
		defaultName: 'Embedded',
		ATMO_DRIVERINSTANCE_ADC_ADC1: 'ADC1',
		ATMO_DRIVERINSTANCE_BLE_BLE1: 'BLE1',
		ATMO_DRIVERINSTANCE_BLOCK_BLOCK1: 'BLOCK1',
		ATMO_DRIVERINSTANCE_DATETIME_DATETIME1: 'DATETIME1',
		ATMO_DRIVERINSTANCE_FILESYSTEM_FILESYSTEM1: 'FILESYSTEM1',
		ATMO_DRIVERINSTANCE_GPIO_GPIO1: 'GPIO1',
		ATMO_DRIVERINSTANCE_I2C_I2C1: 'I2C1',
		ATMO_DRIVERINSTANCE_I2C_I2C2: 'I2C2',
		ATMO_DRIVERINSTANCE_SPI_SPI1: 'SPI1',
		ATMO_DRIVERINSTANCE_INTERVAL_INTERVAL1: 'INTERVAL1',
		ATMO_DRIVERINSTANCE_NFC_NFC1: 'NFC1',
		ATMO_DRIVERINSTANCE_PWM_PWM1: 'PWM1',
		ATMO_DRIVERINSTANCE_WIFI_WIFI1: 'WIFI1',
		ATMO_DRIVERINSTANCE_HTTP_HTTP1: 'HTTP1',
		ATMO_DRIVERINSTANCE_UART_UART1: 'UART1',
		ATMO_DRIVERINSTANCE_UART_UART2: 'UART2',
		ATMO_DRIVERINSTANCE_UART_UART3: 'UART3',
		ATMO_DRIVERINSTANCE_UART_UART4: 'UART4',
	},
};
