function ElementAppUIButton(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppUIBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);

	var currentElement = this;

	this._setCrocObject(new CrocButtonLabeled(this._crocPanel.getRoot(), ''));

	this._crocObject.addEventListener('pointerdown', function(e) {
		currentElement._setProperty('pressedData', e, function() {
			currentElement.trigger('pressed');
		});

		return false;
	});

	this._crocObject.addEventListener('pointerup', function(e) {
		currentElement._setProperty('releasedData', e, function() {
			currentElement.trigger('released');
		});

		return false;
	});

	this._crocObject.addEventListener('pointermove', function(e) {
		currentElement._setProperty('overData', e, function() {
			currentElement.trigger('over');
		});
	});

	this._crocObject.addEventListener('pointerleave', function(e) {
		currentElement._setProperty('leftData', e, function() {
			currentElement.trigger('left');
		});
	});

	this._onPropertyChange();
}

ElementAppUIButton.prototype = Object.create(ElementAppUIBase.prototype);
ElementAppUIButton.prototype.constructor = ElementAppUIButton;

ElementAppUIButton.prototype._onPropertyChange = function() {
	ElementAppUIBase.prototype._onPropertyChange.call(this);

	this._crocObject.label.setText(this._properties.labelText);
	this._crocObject.label.setFont(this._properties.labelFont);
	this._crocObject.label.setColor(this._properties.labelColor);
};

ElementAppUIButton.prototype.setLabelText = function(labelText, callback) {
	labelText = this._convertToString(labelText);

	this._setProperty('labelText', labelText, function(err) {
		this._crocObject.label.setText(labelText);
		callback.call(this, err);
		return;
	});
};

ElementAppUIButton.prototype.setLabelFont = function(labelFont, callback) {
	labelFont = this._convertToFont(labelFont);

	this._setProperty('labelFont', labelFont, function(err) {
		this._crocObject.label.setFont(labelFont);
		callback.call(this, err);
		return;
	});
};

ElementAppUIButton.prototype.setLabelColor = function(labelColor, callback) {
	labelColor = this._convertToColor(labelColor);

	this._setProperty('labelColor', labelColor, function(err) {
		this._crocObject.label.setColor(labelColor);
		callback.call(this, err);
		return;
	});
};
