function ElementControllerCloudStorage(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('value', null, null, 'none');

	this.addAbility('addData', [{ name: 'value', validator: null }]);
	this.addAbility('updateData', [
		{ name: 'query', validator: null },
		{ name: 'update', validator: null },
		{ name: 'options', validator: null },
	]);
	this.addAbility('removeData', [{ name: 'query', validator: null }]);
	this.addAbility('queryData', [
		{ name: 'query', validator: null },
		{ name: 'limit', validator: null },
	]);

	this.addTrigger('dataAdded');
	this.addTrigger('dataUpdated');
	this.addTrigger('dataRemoved');
	this.addTrigger('dataQueried');
}

ElementControllerCloudStorage.prototype = Object.create(
	ElementControllerCloudBase.prototype,
);
ElementControllerCloudStorage.prototype.constructor = ElementControllerCloudStorage;

ElementControllerCloudStorage.prototype.TYPE = 'CloudStorage';
ElementControllerCloudStorage.prototype.HIDDEN = false;
ElementControllerCloudStorage.prototype.DEFAULT_TRIGGER = 'dataAdded';
ElementControllerCloudStorage.prototype.DEFAULT_ABILITY = 'addData';
ElementControllerCloudStorage.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerCloudStorage.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudStorage.prototype.language = deepAssign(
	{},
	ElementControllerCloudBase.prototype.language,
	{
		'en-US': {
			CloudStorage: 'Cloud Storage',
			value: 'Value',
			addData: 'Add Data',
			updateData: 'Update Data',
			removeData: 'Remove Data',
			queryData: 'Query Data',
			dataAdded: 'Data Added',
			dataUpdated: 'Data Updated',
			dataRemoved: 'Data Removed',
			dataQueried: 'Data Queried',
		},
	},
);
