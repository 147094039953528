function LayoutEditorHandles(
	root,
	parentApp,
	elementController,
	crocPanel,
	framingObject,
) {
	/*
	 * This class will draw the UI element editor handles in the layout tool
	 * for the WidgetAppEditor. It updates the values in the elementController
	 * and also tracks it's self over the framingObject's shape.
	 *
	 */

	var currentObject = this;

	CrocPanel.call(this, root);

	this.borderWidth = '2px';
	this.borderColor = '#5d6261';
	this.borderPadding = '8px';

	this.crocPanel = crocPanel;
	this.framingObject = framingObject;
	this.elementController = elementController;
	this.parentApp = parentApp;

	this.currentDragging = false;
	this.currentDraggingOffset = { x: 0, y: 0 };
	this.currentDraggingDirection = null;

	this.dragTarget = new CrocBase(root);
	this.firstDraw = true;

	this.topLeftHandle = new CrocImageSimple(
		root,
		root.themer.getValue(arguments.callee, 'handleIcon'),
	);
	this.topHandle = new CrocImageSimple(
		root,
		root.themer.getValue(arguments.callee, 'handleIcon'),
	);
	this.topRightHandle = new CrocImageSimple(
		root,
		root.themer.getValue(arguments.callee, 'handleIcon'),
	);
	this.leftHandle = new CrocImageSimple(
		root,
		root.themer.getValue(arguments.callee, 'handleIcon'),
	);
	this.rightHandle = new CrocImageSimple(
		root,
		root.themer.getValue(arguments.callee, 'handleIcon'),
	);
	this.bottomLeftHandle = new CrocImageSimple(
		root,
		root.themer.getValue(arguments.callee, 'handleIcon'),
	);
	this.bottomHandle = new CrocImageSimple(
		root,
		root.themer.getValue(arguments.callee, 'handleIcon'),
	);
	this.bottomRightHandle = new CrocImageSimple(
		root,
		root.themer.getValue(arguments.callee, 'handleIcon'),
	);

	this.topLeftHandle.addEventListener('pointermove', function(data) {
		currentObject.onPointerMoveHandleIcon(this);
	});
	this.topHandle.addEventListener('pointermove', function(data) {
		currentObject.onPointerMoveHandleIcon(this);
	});
	this.topRightHandle.addEventListener('pointermove', function(data) {
		currentObject.onPointerMoveHandleIcon(this);
	});
	this.leftHandle.addEventListener('pointermove', function(data) {
		currentObject.onPointerMoveHandleIcon(this);
	});
	this.rightHandle.addEventListener('pointermove', function(data) {
		currentObject.onPointerMoveHandleIcon(this);
	});
	this.bottomLeftHandle.addEventListener('pointermove', function(data) {
		currentObject.onPointerMoveHandleIcon(this);
	});
	this.bottomHandle.addEventListener('pointermove', function(data) {
		currentObject.onPointerMoveHandleIcon(this);
	});
	this.bottomRightHandle.addEventListener('pointermove', function(data) {
		currentObject.onPointerMoveHandleIcon(this);
	});

	this.topLeftHandle.addEventListener('pointerleave', function(data) {
		currentObject.onPointerLeaveHandleIcon(this);
	});
	this.topHandle.addEventListener('pointerleave', function(data) {
		currentObject.onPointerLeaveHandleIcon(this);
	});
	this.topRightHandle.addEventListener('pointerleave', function(data) {
		currentObject.onPointerLeaveHandleIcon(this);
	});
	this.leftHandle.addEventListener('pointerleave', function(data) {
		currentObject.onPointerLeaveHandleIcon(this);
	});
	this.rightHandle.addEventListener('pointerleave', function(data) {
		currentObject.onPointerLeaveHandleIcon(this);
	});
	this.bottomLeftHandle.addEventListener('pointerleave', function(data) {
		currentObject.onPointerLeaveHandleIcon(this);
	});
	this.bottomHandle.addEventListener('pointerleave', function(data) {
		currentObject.onPointerLeaveHandleIcon(this);
	});
	this.bottomRightHandle.addEventListener('pointerleave', function(data) {
		currentObject.onPointerLeaveHandleIcon(this);
	});

	this.topLeftHandle.addEventListener('pointerdown', function(data) {
		currentObject.onPointerDownHandleIcon(this);
	});
	this.topHandle.addEventListener('pointerdown', function(data) {
		currentObject.onPointerDownHandleIcon(this);
	});
	this.topRightHandle.addEventListener('pointerdown', function(data) {
		currentObject.onPointerDownHandleIcon(this);
	});
	this.leftHandle.addEventListener('pointerdown', function(data) {
		currentObject.onPointerDownHandleIcon(this);
	});
	this.rightHandle.addEventListener('pointerdown', function(data) {
		currentObject.onPointerDownHandleIcon(this);
	});
	this.bottomLeftHandle.addEventListener('pointerdown', function(data) {
		currentObject.onPointerDownHandleIcon(this);
	});
	this.bottomHandle.addEventListener('pointerdown', function(data) {
		currentObject.onPointerDownHandleIcon(this);
	});
	this.bottomRightHandle.addEventListener('pointerdown', function(data) {
		currentObject.onPointerDownHandleIcon(this);
	});

	this.topLeftHandle.addEventListener('pointerup', function(data) {
		currentObject.onPointerUp(this);
	});
	this.topHandle.addEventListener('pointerup', function(data) {
		currentObject.onPointerUp(this);
	});
	this.topRightHandle.addEventListener('pointerup', function(data) {
		currentObject.onPointerUp(this);
	});
	this.leftHandle.addEventListener('pointerup', function(data) {
		currentObject.onPointerUp(this);
	});
	this.rightHandle.addEventListener('pointerup', function(data) {
		currentObject.onPointerUp(this);
	});
	this.bottomLeftHandle.addEventListener('pointerup', function(data) {
		currentObject.onPointerUp(this);
	});
	this.bottomHandle.addEventListener('pointerup', function(data) {
		currentObject.onPointerUp(this);
	});
	this.bottomRightHandle.addEventListener('pointerup', function(data) {
		currentObject.onPointerUp(this);
	});

	this.dragTarget.addEventListener('pointerdown', function(data) {
		currentObject.onPointerDown(this, data);
	});
	this.dragTarget.addEventListener('pointermove', function(data) {
		this.getRoot().setCursor('all-scroll');
		return false;
	});

	this.dragTarget.addEventListener('pointerleave', function(data) {
		this.getRoot().setCursor();
		return false;
	});

	this.addEventListener('pointerout', function(data) {
		currentObject.onPointerOut(this, data);
	});

	this.addEventListener('pointerup', function(data) {
		currentObject.onPointerUp(this);
	});
	this.addEventListener('pointermove', function(data) {
		currentObject.onPointerMove(this, data);
	});

	this.addChild(this.dragTarget);
	this.addChild(this.topLeftHandle);
	this.addChild(this.topHandle);
	this.addChild(this.topRightHandle);
	this.addChild(this.leftHandle);
	this.addChild(this.rightHandle);
	this.addChild(this.bottomLeftHandle);
	this.addChild(this.bottomHandle);
	this.addChild(this.bottomRightHandle);
}

LayoutEditorHandles.prototype = Object.create(CrocPanel.prototype);
LayoutEditorHandles.prototype.constructor = LayoutEditorHandles;

LayoutEditorHandles.prototype.handleToDirection = function(handleIcon) {
	if (handleIcon === this.topLeftHandle) {
		return 'nw';
	}

	if (handleIcon === this.topHandle) {
		return 'n';
	}

	if (handleIcon === this.topRightHandle) {
		return 'ne';
	}

	if (handleIcon === this.leftHandle) {
		return 'w';
	}

	if (handleIcon === this.rightHandle) {
		return 'e';
	}

	if (handleIcon === this.bottomLeftHandle) {
		return 'sw';
	}

	if (handleIcon === this.bottomHandle) {
		return 's';
	}

	if (handleIcon === this.bottomRightHandle) {
		return 'se';
	}
};

LayoutEditorHandles.prototype.onPointerMoveHandleIcon = function(handleIcon) {
	if (!this.framingObject.getVisible()) {
		this.getRoot().setCursor('not-allowed');

		return false;
	}

	var currentDirection = this.handleToDirection(handleIcon);

	this.getRoot().setCursor(currentDirection + '-resize');

	return false;
};

LayoutEditorHandles.prototype.onPointerLeaveHandleIcon = function(handleIcon) {
	this.getRoot().setCursor();

	return false;
};

LayoutEditorHandles.prototype.onPointerDownHandleIcon = function(handleIcon) {
	if (!this.framingObject.getVisible()) {
		return false;
	}

	this.currentDraggingDirection = this.handleToDirection(handleIcon);
	this.getRoot().setCursor(this.handleToDirection(handleIcon) + '-resize');
	this.focus();

	return false;
};

LayoutEditorHandles.prototype.onPointerUp = function(handleIcon) {
	if (!this.framingObject.getVisible()) {
		return false;
	}

	this.currentDraggingDirection = null;
	this.currentDragging = false;
	this.parentApp.drawGuides = null;
	this.blur();

	return false;
};

LayoutEditorHandles.prototype.onPointerDown = function(handleIcon, data) {
	if (!this.framingObject.getVisible()) {
		return false;
	}

	this.currentDragging = true;

	var currentPosition = this.crocPanel.getChildOrientation(
		this.framingObject,
	);

	this.currentDraggingOffset.x = currentPosition.x - data.x;
	this.currentDraggingOffset.y = currentPosition.y - data.y;

	this.focus();

	return false;
};

LayoutEditorHandles.prototype.onPointerOut = function(handle, data) {
	if (!this.framingObject.getVisible()) {
		return false;
	}

	this.currentDraggingDirection = null;
	this.currentDragging = false;
	this.parentApp.drawGuides = null;
	this.blur();

	return false;
};

LayoutEditorHandles.prototype.getGuide = function(
	position,
	size,
	target,
	deadzone,
) {
	if (position >= target - deadzone && position <= target + deadzone) {
		return { position: target, offset: 0 };
	} else if (
		position + size / 2 >= target - deadzone &&
		position + size / 2 <= target + deadzone
	) {
		return { position: target, offset: -(size / 2) };
	} else if (
		position + size >= target - deadzone &&
		position + size <= target + deadzone
	) {
		return { position: target, offset: -size };
	}

	return false;
};

LayoutEditorHandles.prototype.findElementGuides = function(
	x,
	y,
	width,
	height,
	deadzone,
) {
	/*
	 * This function will find all the nearest element edges and
	 * centers to the point provided with in a certain amount
	 * offset
	 */

	deadzone = deadzone || 12;

	var currentLayoutWidth = this.parentApp.width;
	var currentLayoutHeight = this.parentApp.height;

	var currentLayouts = this.elementController.getProperty('layouts').value;
	var currentLayoutWidth = this.parentApp.width;
	var currentLayoutHeight = this.parentApp.height;
	var currentLayout = currentLayouts[currentLayoutWidth][currentLayoutHeight];

	var guides = {
		horizontals: [],
		verticles: [],
	};

	var appWidthGuide = this.getGuide(
		x,
		width,
		currentLayoutWidth / 2,
		deadzone,
	);
	var appHeightGuide = this.getGuide(
		y,
		height,
		currentLayoutHeight / 2,
		deadzone,
	);

	if (appWidthGuide !== false) {
		guides.verticles.push(appWidthGuide);
	}

	if (appHeightGuide !== false) {
		guides.horizontals.push(appHeightGuide);
	}

	for (var elementName in this.parentApp._elements) {
		if (
			this.parentApp._elements[elementName]._crocObject ===
				this.framingObject ||
			this.parentApp._elements[elementName]._properties.layouts ===
				undefined
		) {
			continue;
		}

		var currentElementLayout = this.parentApp._elements[elementName]
			._properties.layouts[currentLayoutWidth][currentLayoutHeight];

		var currentXLeft = currentElementLayout.x;
		var currentXMiddle =
			currentElementLayout.x +
			this.parentApp._elements[elementName]._crocObject.getWidth() / 2;
		var currentXRight =
			currentElementLayout.x +
			this.parentApp._elements[elementName]._crocObject.getWidth();

		var xLeftGuide = this.getGuide(x, width, currentXLeft, deadzone);
		var xMiddleGuide = this.getGuide(x, width, currentXMiddle, deadzone);
		var xRightGuide = this.getGuide(x, width, currentXRight, deadzone);

		if (xLeftGuide !== false) {
			guides.verticles.push(xLeftGuide);
		}

		if (xMiddleGuide !== false) {
			guides.verticles.push(xMiddleGuide);
		}

		if (xRightGuide !== false) {
			guides.verticles.push(xRightGuide);
		}

		var currentYTop = currentElementLayout.y;
		var currentYMiddle =
			currentElementLayout.y +
			this.parentApp._elements[elementName]._crocObject.getHeight() / 2;
		var currentYBottom =
			currentElementLayout.y +
			this.parentApp._elements[elementName]._crocObject.getHeight();

		var yTopGuide = this.getGuide(y, height, currentYTop, deadzone);
		var yMiddleGuide = this.getGuide(y, height, currentYMiddle, deadzone);
		var yBottomGuide = this.getGuide(y, height, currentYBottom, deadzone);

		if (yTopGuide !== false) {
			guides.horizontals.push(yTopGuide);
		}

		if (yMiddleGuide !== false) {
			guides.horizontals.push(yMiddleGuide);
		}

		if (yBottomGuide !== false) {
			guides.horizontals.push(yBottomGuide);
		}
	}

	var bestHorizontal = null;
	var bestVertical = null;

	for (var i = 0; i < guides.horizontals.length; i++) {
		var currentDistance = Math.abs(
			guides.horizontals[i].position - (x + guides.horizontals[i].offset),
		);

		if (
			bestHorizontal === null ||
			currentDistance <
				bestHorizontal.position - (x + bestHorizontal.offset)
		) {
			bestHorizontal = guides.horizontals[i];
		}
	}

	for (var i = 0; i < guides.verticles.length; i++) {
		var currentDistance = Math.abs(
			guides.verticles[i].position - (y + guides.verticles[i].offset),
		);

		if (
			bestVertical === null ||
			currentDistance < bestVertical.position - (y + bestVertical.offset)
		) {
			bestVertical = guides.verticles[i];
		}
	}

	return { verticle: bestVertical, horizontal: bestHorizontal };
};

LayoutEditorHandles.prototype.onPointerMove = function(panel, data) {
	if (this.hasFocus() && this.framingObject.getVisible()) {
		if (this.currentDragging === true) {
			this.parentApp.drawGuides = null;

			this.getRoot().setCursor('all-scroll');
			currentX = data.x + this.currentDraggingOffset.x;
			currentY = data.y + this.currentDraggingOffset.y;
			var currentLayouts = this.elementController.getProperty('layouts')
				.value;
			var currentLayoutWidth = this.parentApp.width;
			var currentLayoutHeight = this.parentApp.height;

			if (this.parentApp.editorGridAlignment === true) {
				currentLayouts[currentLayoutWidth][currentLayoutHeight].x =
					this.parentApp.getGridSize() *
					parseInt(currentX / this.parentApp.getGridSize());
				currentLayouts[currentLayoutWidth][currentLayoutHeight].y =
					this.parentApp.getGridSize() *
					parseInt(currentY / this.parentApp.getGridSize());
			} else if (this.parentApp.editorAlignment === true) {
				var guides = this.findElementGuides(
					currentX,
					currentY,
					this.framingObject.getWidth(),
					this.framingObject.getHeight(),
				);
				this.parentApp.setDrawGuides(
					guides.horizontal,
					guides.verticle,
				);

				var currentHorizontalGuide = guides.horizontal;
				var currentVerticleGuide = guides.verticle;

				if (currentVerticleGuide === null) {
					currentLayouts[currentLayoutWidth][
						currentLayoutHeight
					].x = currentX;
				} else {
					currentLayouts[currentLayoutWidth][currentLayoutHeight].x =
						currentVerticleGuide.position +
						currentVerticleGuide.offset;
				}

				if (currentHorizontalGuide === null) {
					currentLayouts[currentLayoutWidth][
						currentLayoutHeight
					].y = currentY;
				} else {
					currentLayouts[currentLayoutWidth][currentLayoutHeight].y =
						currentHorizontalGuide.position +
						currentHorizontalGuide.offset;
				}
			} else {
				currentLayouts[currentLayoutWidth][
					currentLayoutHeight
				].x = currentX;
				currentLayouts[currentLayoutWidth][
					currentLayoutHeight
				].y = currentY;
			}

			this.elementController.setProperty('layouts', currentLayouts);
			return false;
		}

		if (this.currentDraggingDirection !== null) {
			var currentOrientation = this.crocPanel.getChildOrientation(
				this.framingObject,
			);

			var currentLayouts = this.elementController.getProperty('layouts')
				.value;
			var currentLayoutWidth = this.parentApp.width;
			var currentLayoutHeight = this.parentApp.height;

			var currentTargetWidth = this.framingObject.getWidth();
			var currentTargetHeight = this.framingObject.getHeight();

			var currentX = currentOrientation.x;
			var currentY = currentOrientation.y;
			var offset = this.getOffset();

			var currentLayouts = this.elementController.getProperty('layouts')
				.value;
			var currentLayoutWidth = this.parentApp.width;
			var currentLayoutHeight = this.parentApp.height;

			var guides = { verticle: null, horizontal: null };
			var currentGuides = this.findElementGuides(data.x, data.y, 0, 0);

			if (this.currentDraggingDirection.indexOf('e') > -1) {
				guides.verticle = currentGuides.verticle;

				currentTargetWidth =
					data.x - currentOrientation.x + offset.x / 2;
			}

			if (this.currentDraggingDirection.indexOf('s') > -1) {
				guides.horizontal = currentGuides.horizontal;

				currentTargetHeight =
					data.y - currentOrientation.y + offset.y / 2;
			}

			if (this.currentDraggingDirection.indexOf('w') > -1) {
				guides.verticle = currentGuides.verticle;

				if (
					currentLayouts[currentLayoutWidth][currentLayoutHeight]
						.width +
						(currentOrientation.x - (data.x - offset.x / 2)) >
					0
				) {
					currentX = data.x - offset.x / 2;
					currentTargetWidth =
						currentLayouts[currentLayoutWidth][currentLayoutHeight]
							.width +
						(currentOrientation.x - currentX);
				}
			}

			if (this.currentDraggingDirection.indexOf('n') > -1) {
				guides.horizontal = currentGuides.horizontal;

				if (
					currentLayouts[currentLayoutWidth][currentLayoutHeight]
						.height +
						(currentOrientation.y - (data.y - offset.y / 2)) >
					0
				) {
					currentY = data.y - offset.y / 2;
					currentTargetHeight =
						currentLayouts[currentLayoutWidth][currentLayoutHeight]
							.height +
						(currentOrientation.y - currentY);
				}
			}

			currentTargetHeight = Math.max(currentTargetHeight, 1);
			currentTargetWidth = Math.max(currentTargetWidth, 1);

			if (this.parentApp.editorGridAlignment === true) {
				currentX =
					this.parentApp.getGridSize() *
					Math.ceil(currentX / this.parentApp.getGridSize());
				currentTargetWidth =
					this.parentApp.getGridSize() *
					Math.floor(
						currentTargetWidth / this.parentApp.getGridSize(),
					);

				currentY =
					this.parentApp.getGridSize() *
					Math.ceil(currentY / this.parentApp.getGridSize());
				currentTargetHeight =
					this.parentApp.getGridSize() *
					Math.floor(
						currentTargetHeight / this.parentApp.getGridSize(),
					);
			} else if (this.parentApp.editorAlignment === true) {
				var currentHorizontalGuide = guides.horizontal;
				var currentVerticleGuide = guides.verticle;

				if (currentVerticleGuide !== null) {
					if (this.currentDraggingDirection.indexOf('w') > -1) {
						if (
							currentVerticleGuide.position <
							currentOrientation.x +
								currentLayouts[currentLayoutWidth][
									currentLayoutHeight
								].width
						) {
							currentX = currentVerticleGuide.position;
							currentTargetWidth =
								currentOrientation.x -
								currentX +
								currentLayouts[currentLayoutWidth][
									currentLayoutHeight
								].width;
						} else {
							guides.verticle = null;
						}
					} else {
						currentTargetWidth = Math.abs(
							currentVerticleGuide.position - currentX,
						);
					}
				}

				if (currentHorizontalGuide !== null) {
					if (this.currentDraggingDirection.indexOf('n') > -1) {
						if (
							currentHorizontalGuide.position <
							currentOrientation.y +
								currentLayouts[currentLayoutWidth][
									currentLayoutHeight
								].height
						) {
							currentY = currentHorizontalGuide.position;
							currentTargetHeight =
								currentOrientation.y -
								currentY +
								currentLayouts[currentLayoutWidth][
									currentLayoutHeight
								].height;
						} else {
							guides.horizontal = null;
						}
					} else {
						currentTargetHeight = Math.abs(
							currentHorizontalGuide.position - currentY,
						);
					}
				}

				this.parentApp.setDrawGuides(
					guides.horizontal,
					guides.verticle,
				);
			}

			currentLayouts[currentLayoutWidth][
				currentLayoutHeight
			].x = currentX;
			currentLayouts[currentLayoutWidth][
				currentLayoutHeight
			].y = currentY;
			currentLayouts[currentLayoutWidth][
				currentLayoutHeight
			].width = currentTargetWidth;
			currentLayouts[currentLayoutWidth][
				currentLayoutHeight
			].height = currentTargetHeight;

			this.elementController.setProperty('layouts', currentLayouts);

			return false;
		}
	}

	return true;
};

LayoutEditorHandles.prototype.getOffset = function() {
	var currentBorderPadding = this.convertToPixels(this.borderPadding);
	var handleIconWidth = this.convertToPixels(this.topHandle.getWidth());
	var handleIconHeight = this.convertToPixels(this.topHandle.getHeight());

	var x = -(currentBorderPadding * 2);
	var y = -(currentBorderPadding * 2);

	return { x: x, y: y };
};

LayoutEditorHandles.prototype.paint = function(context, width, height) {
	var currentLayouts = this.elementController.getProperty('layouts').value;
	var currentLayoutWidth = this.parentApp.width;
	var currentLayoutHeight = this.parentApp.height;

	var framingWidth =
		currentLayouts[currentLayoutWidth][currentLayoutHeight].width;
	var framingHeight =
		currentLayouts[currentLayoutWidth][currentLayoutHeight].height;

	if (this.framingObject.getVisible() && !this.firstDraw) {
		framingWidth = this.framingObject.getWidth();
		framingHeight = this.framingObject.getHeight();
	}

	if (!this.framingObject.getVisible()) {
		currentLayouts[currentLayoutWidth][
			currentLayoutHeight
		].width = this.lastFramingWidth;
		currentLayouts[currentLayoutWidth][
			currentLayoutHeight
		].height = this.lastFramingHeight;

		this.elementController.setProperty('layouts', currentLayouts);
	} else {
		this.lastFramingWidth = framingWidth;
		this.lastFramingHeight = framingHeight;
	}

	var currentBorderPadding = this.convertToPixels(this.borderPadding, width);
	var borderWidth = this.convertToPixels(this.borderWidth, 0);
	var borderColor = this.borderColor;
	var handleIconWidth = this.convertToPixels(this.topHandle.getWidth());
	var handleIconHeight = this.convertToPixels(this.topHandle.getHeight());

	this.dragTarget.setTargetWidth(framingWidth);
	this.dragTarget.setTargetHeight(framingHeight);

	this.setTargetWidth(
		framingWidth + currentBorderPadding * 2 + handleIconWidth * 2,
	);
	this.setTargetHeight(
		framingHeight + currentBorderPadding * 2 + handleIconHeight * 2,
	);

	this.setChildOrientation(
		this.dragTarget,
		currentBorderPadding * 2,
		currentBorderPadding * 2,
	);
	this.setChildOrientation(this.topLeftHandle, 0, 0);
	this.setChildOrientation(
		this.topHandle,
		(framingWidth + currentBorderPadding * 2) / 2 + handleIconWidth / 2,
		0,
	);
	this.setChildOrientation(
		this.topRightHandle,
		framingWidth + currentBorderPadding * 2 + handleIconWidth,
		0,
	);
	this.setChildOrientation(
		this.leftHandle,
		0,
		(framingHeight + currentBorderPadding * 2) / 2 + handleIconHeight / 2,
	);
	this.setChildOrientation(
		this.rightHandle,
		framingWidth + currentBorderPadding * 2 + handleIconWidth,
		(framingHeight + currentBorderPadding * 2) / 2 + handleIconHeight / 2,
	);
	this.setChildOrientation(
		this.bottomLeftHandle,
		0,
		framingHeight + currentBorderPadding * 2 + handleIconHeight,
	);
	this.setChildOrientation(
		this.bottomHandle,
		(framingWidth + currentBorderPadding * 2) / 2 + handleIconWidth / 2,
		framingHeight + currentBorderPadding * 2 + handleIconHeight,
	);
	this.setChildOrientation(
		this.bottomRightHandle,
		framingWidth + currentBorderPadding * 2 + handleIconWidth,
		framingHeight + currentBorderPadding * 2 + handleIconHeight,
	);

	CrocPanel.prototype.paint.call(this, context, width, height);

	this.firstDraw = false;
};
