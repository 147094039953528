function WidgetNewProjectSelector(id, api, parent, options) {
	const currentWidget = this;

	WidgetPanelBase.call(this, id, api, parent, options);

	this._tableWidget = null;
	this._buttonsFooter = null;
	this._selectedProjectType = null;
	this._projectController = null;

	this._addingNewProject = false;

	this.tableContainerId = this.generateChildId('tableContainer');
	this.projectDetailsContainerId = this.generateChildId(
		'projectDetailsContainer',
	);
	this.buttonsFooterContainerId = this.generateChildId(
		'footerButtonsContainer',
	);
	this.nameInputId = this.generateChildId('nameInputId');

	this.renderTemplate(
		{
			selectProjectTypeLabel: getLanguageTag(
				this.constructor,
				'selectProjectType',
			),
			tableContainerId: this.tableContainerId,
			projectDetailsContainerId: this.projectDetailsContainerId,
			buttonsFooterContainerId: this.buttonsFooterContainerId,
		},
		WidgetNewProjectSelector.name,
	);

	this.projectDetailsContainer = $(`#${this.projectDetailsContainerId}`);
}

WidgetNewProjectSelector.prototype = Object.create(WidgetPanelBase.prototype);
WidgetNewProjectSelector.prototype.constructor = WidgetNewProjectSelector;

WidgetNewProjectSelector.prototype.initialize = function(callback) {
	const currentWidget = this;

	this.addChildWidget(
		WidgetButtonsFooter,
		this.buttonsFooterContainerId,
		{},
		function(err, buttonsFooterWidget) {
			this._buttonsFooter = buttonsFooterWidget;

			this._buttonsFooter.setButtons([
				{
					label:
						this.getOptions().dismissLabel ||
						getLanguageTag(this.constructor, 'dismiss'),
					value: 'dismissed',
					classes: [`btn-cancel`],
				},

				{
					label:
						this.getOptions().confirmLabel ||
						getLanguageTag(this.constructor, 'create'),
					value: 'confirmed',
					classes: ['btn-primary'],
				},
			]);

			this._buttonsFooter.addEventListener(
				'footerButtonPressed',
				function(value) {
					if (value === 'confirmed') {
						if (currentWidget._addingNewProject) {
							return;
						}

						if (!currentWidget.hasProjectTypeSelected()) {
							currentWidget
								.getMainContainer()
								.showPopupInfoMessage(
									getLanguageTag(
										currentWidget.constructor,
										'selectAProjectTypeFirst',
									),
								);
							return;
						}

						let newProjectName = $(`#${currentWidget.nameInputId}`)
							.val()
							.trim();

						if (!newProjectName || newProjectName.length <= 0) {
							newProjectName = getLanguageTag(
								currentWidget.constructor,
								'newProjectName',
							);
						}

						currentWidget._addingNewProject = true;

						currentWidget.addNewProjectFromTemplate(
							newProjectName,
							currentWidget._selectedProjectType,
							function(err, projectSpec) {
								currentWidget.event(value);
								currentWidget
									.getMainContainer()
									.setLocation(LocationProjectEditor, {
										projectId: currentWidget._projectController.getProjectId(),
										projectUuid: currentWidget._projectController.getProjectUuid(),
										org: getHashCommand().org,
									});

								currentWidget._addingNewProject = false;
							},
						);
					} else {
						currentWidget.event(value);
					}
				},
			);

			this.addChildWidget(
				WidgetTable,
				this.tableContainerId,
				{
					filterInputPlaceHolder: this.getLanguageTag(
						'filterInputPlaceHolder',
					),
					noData: this.getLanguageTag('noData'),
					showRowSelected: true,
				},

				function(err, tableWidget) {
					this._tableWidget = tableWidget;

					this.updateTable(function(err) {
						WidgetPanelBase.prototype.initialize.call(
							this,
							callback,
						);
					});
				},
			);
		},
	);
};

WidgetNewProjectSelector.prototype.hasProjectTypeSelected = function() {
	let isSelected = false;
	const projectType = this._selectedProjectType;

	// This function checks to see that a valid project type entry
	// has actually been selected from within the table.
	if (
		projectType &&
		Object.keys(projectType) &&
		Object.keys(projectType).length > 0 &&
		projectType.id
	) {
		isSelected = true;
	}
	return isSelected;
};

WidgetNewProjectSelector.prototype.updateTable = function(callback) {
	const currentWidget = this;

	callback = callback || function() {};

	let columns = ['type', 'chipset', 'manufacturer'];
	let projectTypes = [];

	let whiteList =
		(
			this.getMainContainer().getGlobalConfig()
				.WidgetNewProjectSelector || {}
		).projectTypeWhiteList || null;
	let defaultProjectType =
		(
			this.getMainContainer().getGlobalConfig()
				.WidgetNewProjectSelector || {}
		).defaultProjectType || null;

	let defaultProjectEntry = null;

	if (whiteList !== null) {
		for (let i = 0; i < this.PROJECT_TYPES.length; i++) {
			if (whiteList.indexOf(this.PROJECT_TYPES[i].id) >= 0) {
				projectTypes.push(this.PROJECT_TYPES[i]);
			}
		}
	} else {
		projectTypes = this.PROJECT_TYPES;
	}

	if (defaultProjectType) {
		for (let i = 0; i < projectTypes.length; i++) {
			if (projectTypes[i].id === defaultProjectType) {
				defaultProjectEntry = projectTypes[i];
			}
		}
	}

	// FIXME: Encode the buy link logic
	if (this.getMainContainer().getGlobalConfig().studioShoppingCart) {
		columns.push('buy');
	}

	this._tableWidget.setTable(
		columns,

		{
			type: {
				label: getLanguageTag(this.constructor, 'type'),
				sortable: true,
				columnWidth: 1.3,
			},
			chipset: {
				label: getLanguageTag(this.constructor, 'chipset'),
				sortable: true,
				columnWidth: 1.0,
			},
			manufacturer: {
				label: getLanguageTag(this.constructor, 'manufacturer'),
				sortable: true,
				columnWidth: 1.3,
			},
			buy: {
				label: getLanguageTag(this.constructor, 'buy'),
				sortable: true,
				columnWidth: 0.4,
				formatter: this.buyColumnFormatter,
			},
		},

		projectTypes,
		'ascending',
		'type',
	);

	this._tableWidget.addEventListener('rowClicked', function(e) {
		currentWidget.setProjectDetails(e.rowData);
	});

	this._tableWidget.addEventListener('columnClicked', function(e) {
		//The buy column was pressed
		if (e.column === 3) {
			const currentCart =
				currentWidget.getMainContainer().getGlobalConfig()
					.studioShoppingCart || null;

			const cartData =
				((e.rowData.shoppingCartLinks || {})[currentCart] || {})
					.cartData || null;

			if (cartData !== null && currentCart === 'digikey') {
				openLinkInNewWindowOrTab(
					`https://www.digikey.com/classic/ordering/fastadd.aspx?part1=${encodeURIComponent(
						cartData.part,
					)}&qty1=1&cref1=dkstudio`,
				);
			}
		}
	});

	if (projectTypes.length === 1) {
		this.setProjectDetails(projectTypes[0]);
	} else {
		if (defaultProjectEntry) {
			this.setProjectDetails(defaultProjectEntry);
		} else {
			this.setProjectDetails(null);
		}
	}

	callback.call(this, false);
};

WidgetNewProjectSelector.prototype.buyColumnFormatter = function(
	data,
	rowData,
) {
	const currentCart =
		this.getMainContainer().getGlobalConfig().studioShoppingCart || null;
	const cartData =
		((rowData.shoppingCartLinks || {})[currentCart] || {}).cartData || null;

	if (cartData !== null && currentCart === 'digikey') {
		return `<div class="WidgetNewProjectSelector-BuyColumn">
			<img src="./Resources/icons/ShoppingCartAdd.svg" class="WidgetNewProjectSelector-BuyImage _svg-inject" title="${getLanguageTag(
				WidgetNewProjectSelector,
				'addToCart',
			)}"></img>
		</div>`;
	}

	return ``;
};

WidgetNewProjectSelector.prototype.setProjectDetails = function(
	entry,
	callback,
) {
	if (entry) {
		this._selectedProjectType = entry;
	} else {
		entry = {};
	}

	callback = callback || function() {};

	if (entry) {
		this.renderTemplate(
			{
				title: getLanguageTag(this.constructor, 'projectDetails'),
				nameLabel: getLanguageTag(this.constructor, 'projectName'),
				nameInputId: this.nameInputId,
				newProjectName: getLanguageTag(this.constructor, 'newProject'),
				projectImageSrc: entry.image || './Resources/icons/Unknown.svg',
				projectTypeLabel: getLanguageTag(
					this.constructor,
					'projectType',
				),
				projectType: entry.type,
				descriptionLabel: getLanguageTag(
					this.constructor,
					'description',
				),
				description: entry.description,
				manufacturerLabel: getLanguageTag(
					this.constructor,
					'manufacturer',
				),
				manufacturer: entry.manufacturer,
				chipsetLabel: getLanguageTag(this.constructor, 'chipset'),
				chipset: entry.chipset,
				protocolLabel: getLanguageTag(this.constructor, 'protocol'),
				protocol: entry.protocol,
			},
			WidgetNewProjectSelector.name + '_ProjectDetails',
			this.projectDetailsContainer,
		);
	}

	callback.call(this, false);
};

WidgetNewProjectSelector.prototype.addNewProjectFromTemplate = function(
	newProjectName,
	templateData,
	callback,
) {
	const currentWidget = this;

	const projectController = new ProjectController(
		this.getAPI(),
		this.getApiV2(),
	);

	this._projectController = projectController;

	projectController.setName(newProjectName);
	projectController.setMetaValue('projectTypeName', templateData.type);
	projectController.setMetaValue('projectTypeId', templateData.id);

	let planesToLoad = templateData.planes.slice();

	function _loadPlanesHelper() {
		if (planesToLoad.length <= 0) {
			projectController.add(newProjectName, function(err, data) {
				callback.call(this, err, data);
			});

			return;
		}

		const currentPlane = planesToLoad.shift();

		const currentPlaneController = projectController.addPlane(
			currentPlane.controller,
		);
		const planeData = currentPlane.data;

		if (planeData !== undefined && planeData.loadEels !== undefined) {
			let eelsToLoad = planeData.loadEels.slice();

			function _loadEelHelper() {
				if (eelsToLoad.length <= 0) {
					_loadPlanesHelper();
					return;
				}

				const currentEelName = eelsToLoad.shift();

				currentWidget
					.getAPI()
					.getAPIRoute('/user/create/library/:name')
					.get(currentEelName, function(err, data) {
						if (err) {
							console.log(err);
							_loadEelHelper();
							return;
						}

						currentPlaneController.addLibrary(data);
						_loadEelHelper();
						return;
					});
			}

			_loadEelHelper();
			return;
		} else {
			_loadPlanesHelper();
			return;
		}
	}

	_loadPlanesHelper();
	return;
};

WidgetNewProjectSelector.prototype.language = deepAssign(
	{},
	WidgetPanelBase.prototype.language,
	{
		'en-US': {
			name: 'Create New Application',
			selectProjectType: 'Select Application Type',
			projectDetails: 'Application Details',
			description: 'Description',
			manufacturer: 'Manufacturer',
			chipset: 'Chipset',
			protocol: 'Protocol',
			type: 'Type',
			buy: 'Buy',
			addToCart: 'Add To Cart',
			projectType: 'Application Type',
			projectName: 'Name',
			newProject: 'New Application',
			selectAProjectTypeFirst: 'Select an application type',
			filterInputPlaceHolder: 'Search for a type...',
			noData: 'No application types found',
		},
	},
);

WidgetNewProjectSelector.prototype.PROJECT_TYPES = [
	{
		id: 'appOnly',
		type: 'Application',
		image: './Resources/images/APIProject.png',
		description:
			'Use the Atmosphere HTTP API in your own device code to communicate directly with the cloud!',
		planes: [
			{
				controller: PlaneControllerAppViewStandalone,
				data: {},
			},

			{
				controller: PlaneControllerCloudStandalone,
				data: {},
			},
		],
	},
];
