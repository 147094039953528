function WidgetDeviceStorageTable(id, api, parent, options) {
	WidgetTable.call(this, id, api, parent, options);

	this.allowedToDeleteStorage = false;
	this._interval = null;

	this.canEditStorage = false;
}

WidgetDeviceStorageTable.prototype = Object.create(WidgetTable.prototype);
WidgetDeviceStorageTable.prototype.constructor = WidgetDeviceStorageTable;

WidgetDeviceStorageTable.prototype._dataFormatter = function(a, rowData) {};

WidgetDeviceStorageTable.prototype._percentageFormatter = function(
	a,
	rowData,
) {};

WidgetDeviceStorageTable.prototype._optionsFormatter = function(a, rowData) {
	if (this.allowedToDeleteStorage && !isPhonegap() && this.canEditStorage) {
		// If the user can delete storage, the client ISN'T a phonegap
		// environment, and the storage can be edited, show both the
		// export and delete storage buttons.
		return `
		<div class="WidgetDeviceStorageTable-ExportButton" data-export="${rowData.name}">
			<img src="./Resources/icons/Export.svg" class="_svg-inject WidgetDeviceStorageTable-ExportImage" title="downloadData"></img>
		</div>
		
		<div class="WidgetDeviceStorageTable-ClearButton" data-clear="${rowData.name}">
			<img src="./Resources/icons/Trash.svg" class="_svg-inject WidgetDeviceStorageTable-TrashImage" title="deleteData"></img>
		</div>
		`;
	} else if (
		this.allowedToDeleteStorage &&
		isPhonegap() &&
		this.canEditStorage
	) {
		// If the user can delete storage, the client IS a phonegap,
		// environment and the storage can be edited, show both the
		// export and delete storage buttons.
		return `
		<div class="WidgetDeviceStorageTable-ClearButton" data-clear="${rowData.name}">
			<img src="./Resources/icons/Trash.svg" class="_svg-inject WidgetDeviceStorageTable-TrashImage" title="deleteData"></img>
		</div>`;
	} else if (!isPhonegap()) {
		// If the environment is not phonegap, the user will be able
		// to see and download storage data.
		return `
		<div class="WidgetDeviceStorageTable-ExportButton" data-export="${rowData.name}">
			<img src="./Resources/icons/Export.svg" class="_svg-inject WidgetDeviceStorageTable-ExportImage" title="downloadData"></img>
		</div>
		`;
	} else {
		return ``;
	}
};

WidgetDeviceStorageTable.prototype._attachButtonEvents = function() {
	var currentWidget = this;

	var exportButtons = this.getContainer().find(`*[data-export]`);

	for (var i = 0; i < exportButtons.length; i++) {
		$(exportButtons[i]).click(function() {
			currentWidget.exportData($(this).attr('data-export'));
		});
	}

	var clearButtons = this.getContainer().find(`*[data-clear]`);

	for (var i = 0; i < clearButtons.length; i++) {
		$(clearButtons[i]).click(function() {
			currentWidget.clearData($(this).attr('data-clear'));
		});
	}
};

// Gets all the data for a specific elementName
WidgetDeviceStorageTable.prototype.dataBy = async function(query) {
	const api = this.getApiV2();

	const firstPage = await api.apis.data.getDeviceData(query);
	const { totalPages } = firstPage.meta;
	const data = [...firstPage.data];
	const newQuery = { ...query };

	if (totalPages > 1) {
		const promises = [];
		for (let i = 2; i <= totalPages; i += 1) {
			newQuery.page = i;
			promises.push(api.apis.data.getDeviceData(newQuery));
		}

		const pages = await Promise.all(promises);
		pages.forEach((page) => {
			data.push(...page.data);
		});
	}

	return data;
};

WidgetDeviceStorageTable.prototype.exportData = function(dataSetName) {
	const currentWidget = this;
	const api = currentWidget.getApiV2();

	const deviceId = getHashCommand().deviceId;
	const query = {
		id: deviceId,
		elementName: dataSetName,
		limit: 10000,
	};

	let deviceName;

	api.apis.devices
		.getDevice({ id: deviceId })
		.then((device) => {
			deviceName = device.name;
			return currentWidget.dataBy(query);
		})
		.then((storage) => {
			const exportData = [];

			storage.forEach((row) => {
				let fRow = row.payload || {};

				fRow.id = row.id;
				fRow.createdAt = row.createdAt;
				fRow.deviceId = row.deviceId;
				fRow.elementName = row.elementName;

				exportData.push(fRow);
			});

			var saveDate = moment(Date.now()).format('YYYY-MM-DD');
			var fileName = `${deviceName}_${dataSetName}_${saveDate}`;
			saveObjectAsCSV(fileName, exportData);
		})
		.catch((err) => {
			console.error('Error getting device data ', err);
		});
};

WidgetDeviceStorageTable.prototype.clearData = function(dataSetName) {
	const currentWidget = this;
	const api = currentWidget.getApiV2();

	const deviceId = getHashCommand().deviceId;

	this.getMainContainer().showConfirm(
		{ message: getLanguageTag(this.constructor, 'confirmClearingData') },
		function() {
			api.apis.storage
				.deleteDeviceStorageElement({
					id: deviceId,
					elementName: dataSetName,
				})
				.then(() => {
					currentWidget.update();
				})
				.catch((error) => {
					console.error(error);
					currentWidget
						.getMainContainer()
						.showPopupErrorMessage(
							getLanguageTag(
								currentWidget.constructor,
								'errorClearingData',
							),
						);
				});
		},
	);
};

WidgetDeviceStorageTable.prototype.update = function(callback) {
	callback = callback || function() {};
	const currentWidget = this;
	const api = this.getApiV2();

	const deviceId = getHashCommand().deviceId;
	currentWidget.allowedToDeleteStorage = true;

	const currentUser = this.getCurrentUser();

	api.apis.devices
		.getDevice({ id: deviceId })
		.then((deviceData) => {
			const device = new Device(api, deviceData);

			currentWidget.canEditStorage = currentUser.ability.can(
				'write',
				device,
			);

			api.apis.storage
				.getDeviceStorageElements({ id: deviceId })
				.then((response) => {
					const dataSets = response.data;
					var tableData = [];

					for (var i = 0; i < dataSets.length; i++) {
						tableData.push({
							name: dataSets[i].name,
						});
					}

					currentWidget.setTable(
						['name', 'options'],
						{
							name: {
								label: getLanguageTag(
									currentWidget.constructor,
									'name',
								),
								sortable: true,
							},
							options: {
								label: getLanguageTag(
									currentWidget.constructor,
									'options',
								),
								formatter: currentWidget._optionsFormatter,
							},
						},
						tableData,
						'descending',
						'name',
					);

					currentWidget._attachButtonEvents();
					callback.call(currentWidget, false);
					return;
				})
				.catch((error) => {
					console.warn(
						`[${currentWidget.constructor.name}] error occurred while calling update!`,
					);
					console.error(error);
					callback.call(currentWidget, error);
					return;
				});
		})
		.catch((err) => {
			console.error('Error reading device data ', err);
			callback.call(currentWidget, err);
		});
};

WidgetDeviceStorageTable.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.update(function(err) {
		this._interval = setInterval(function() {
			// Only update if visible
			if (currentWidget.isVisible() && isActive()) {
				currentWidget.update();
			}
		}, 60000);

		WidgetTable.prototype.initialize.call(this, callback);
	});
};

WidgetDeviceStorageTable.prototype.remove = function(callback) {
	if (this._interval) {
		clearInterval(this._interval);
		this._interval = null;
	}

	WidgetTable.prototype.remove.call(this, callback);
};

WidgetDeviceStorageTable.prototype.language = deepAssign(
	{},
	WidgetTable.prototype.language,
	{
		'en-US': {
			name: 'Name',
			data: 'Data',
			percentage: 'Percentage',
			options: 'Options',
			confirmClearingData:
				'Are you sure you want to clear the stored data for this device?',
			errorClearingData:
				"There was an error clearing the device's data. Please try again.",
			downloadData: 'Download Data',
			deleteData: 'Delete Data',
		},
	},
);

WidgetDeviceStorageTable.prototype.$_$ = function(done) {
	//Testing function for WidgetDeviceStorageTable

	this.$_SetupWidgetTest(function(err, currentWidget) {
		WidgetTable.prototype.$_$.call(this, done);
	});
};
