function ElementControllerCloudCondition(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudLogicBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('value', null, null, 'none', null, null);

	this.addAbility('check', [{ name: 'value', validator: null }]);

	this.addTrigger('conditionTrue');
	this.addTrigger('conditionFalse');
}

ElementControllerCloudCondition.prototype = Object.create(
	ElementControllerCloudLogicBase.prototype,
);
ElementControllerCloudCondition.prototype.constructor = ElementControllerCloudCondition;

ElementControllerCloudCondition.prototype.NAME = 'Condition';
ElementControllerCloudCondition.prototype.TYPE = 'CloudCondition';
ElementControllerCloudCondition.prototype.HIDDEN = false;
ElementControllerCloudCondition.prototype.DEFAULT_TRIGGER = 'conditionTrue';
ElementControllerCloudCondition.prototype.DEFAULT_ABILITY = 'check';
ElementControllerCloudCondition.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerCloudCondition.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudCondition.prototype.language = deepAssign(
	{},
	ElementControllerCloudLogicBase.prototype.language,
	{
		'en-US': {
			CloudCondition: 'Condition',
			value: 'Value',
			check: 'Check',
			conditionTrue: 'Condition True',
			conditionFalse: 'Condition False',
		},
	},
);
