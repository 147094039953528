function ElementControllerAppUIList(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppUIBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);
}

ElementControllerAppUIList.prototype = Object.create(
	ElementControllerAppUIBase.prototype,
);
ElementControllerAppUIList.prototype.constructor = ElementControllerAppUIList;

ElementControllerAppUIList.prototype.NAME = 'List';
ElementControllerAppUIList.prototype.TYPE = 'AppUIList';
ElementControllerAppUIList.prototype.HIDDEN = true;

ElementControllerAppUIList.prototype.language = deepAssign(
	{},
	ElementControllerAppUIBase.prototype.language,
	{
		'en-US': {
			AppUIList: 'List',
		},
	},
);
