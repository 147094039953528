function WidgetAppViewControls(id, api, parentWidget, options) {
	WidgetBase.call(this, id, api, parentWidget, options);

	this.exitButtonId = this.generateChildId('exitButton');
}

WidgetAppViewControls.prototype = Object.create(WidgetBase.prototype);
WidgetAppViewControls.prototype.constructor = WidgetAppViewControls;

WidgetAppViewControls.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.renderTemplate(
		{
			exitButtonId: this.exitButtonId,
			exitButtonLabel: getLanguageTag(this.constructor, 'exitLabel'),
		},
		WidgetAppViewControls.name,
	);

	$(`#${this.exitButtonId}`).click(function() {
		currentWidget.event('exit');
	});

	WidgetBase.prototype.initialize.call(this, callback);
};

WidgetAppViewControls.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			exitLabel: 'Exit',
		},
	},
);
