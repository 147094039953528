function ElementControllerEmbeddedADCPin(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('instance', null, 0, 'driverInstance', null, 'adc');
	this.addProperty('pin', null, 'ATMO_DEFAULT_GPIO', 'text', null, null);
	this.addProperty('numberOfSamples', null, 1, 'number', null, null);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('readRaw', [], ['rawRead']);
	this.setAbilityCode(
		'readRaw',
		`
	
	int32_t adcReading = 0;
	
	ATMO_ADC_ReadRaw(
		ATMO_PROPERTY(undefined, instance), 
		ATMO_PROPERTY(undefined, pin), 
		&adcReading, 
		ATMO_PROPERTY(undefined, numberOfSamples));
	
	ATMO_CreateValueInt(out, adcReading);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('readVoltage', [], ['voltageRead']);
	this.setAbilityCode(
		'readVoltage',
		`
	
	int32_t adcReading = 0;
	
	ATMO_ADC_Read(
		ATMO_PROPERTY(undefined, instance), 
		ATMO_PROPERTY(undefined, pin),
		ATMO_ADC_VoltageUnits_MilliVolts,
		&adcReading, 
		ATMO_PROPERTY(undefined, numberOfSamples));

	ATMO_CreateValueInt(out, adcReading);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('readReferenceVoltage', [], ['referenceVoltageRead']);
	this.setAbilityCode(
		'readReferenceVoltage',
		`
	
	uint32_t adcReading = 0;
	
	ATMO_ADC_GetReferenceVoltageMv(
		ATMO_PROPERTY(undefined, instance), 
		&adcReading);

	ATMO_CreateValueInt(out, adcReading);
	return ATMO_Status_Success;
	`,
	);
}

ElementControllerEmbeddedADCPin.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedADCPin.prototype.constructor = ElementControllerEmbeddedADCPin;

ElementControllerEmbeddedADCPin.prototype.NAME = 'ADCPin';
ElementControllerEmbeddedADCPin.prototype.TYPE = 'EmbeddedADCPin';
ElementControllerEmbeddedADCPin.prototype.HIDDEN = false;
ElementControllerEmbeddedADCPin.prototype.DEFAULT_ABILITY = 'readRaw';
ElementControllerEmbeddedADCPin.prototype.DEFAULT_TRIGGER = 'rawRead';
ElementControllerEmbeddedADCPin.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'adc',
];
ElementControllerEmbeddedADCPin.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedADCPin.prototype.language = {
	'en-US': {
		EmbeddedADCPin: 'ADC Pin',
		instance: 'Instance',
		pin: 'Pin',
		numberOfSamples: 'Number of Samples',
		timeout: 'Timeout',
		setup: 'Setup',
		readRaw: 'Read Raw',
		rawRead: 'Raw Read',
		voltageRead: 'Voltage Read',
		readVoltage: 'Read Voltage',
		readReferenceVoltage: 'Read Reference Voltage',
		referenceVoltageRead: 'Reference Voltage Read',
	},
};
