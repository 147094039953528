function WidgetNotificationSettings(id, api, parent, options) {
	WidgetBase.call(this, id, api, parent, options);

	this._settingsFormToPlugin = {};
	this.footerWidget = null;
	this.footerContainerId = this.generateChildId('footerContainer');
}

WidgetNotificationSettings.prototype = Object.create(WidgetBase.prototype);
WidgetNotificationSettings.prototype.constructor = WidgetNotificationSettings;

WidgetNotificationSettings.prototype.ICON =
	'./Resources/icons/Notifications.svg';

WidgetNotificationSettings.prototype.initialize = function(callback) {
	this.update(callback);
};

WidgetNotificationSettings.prototype.hasPermission = function(callback) {
	const currentWidget = this;
	callback = callback || function() {};

	const api = _mainContainer.getApiV2();
	const loggedInUser = _mainContainer.getCurrentUser();
	const command = getHashCommand();

	let profileId = command.id;

	// If no ID is found in the hash, the UI should assume
	// that the currently logged in user is editing their
	// own notifications.
	if (!profileId) {
		profileId = loggedInUser.id;
	}

	api.apis.users
		.getUser({ id: profileId })
		.then((userData) => {
			// hydrate the user JSON into a model
			const profileUser = new User(api, userData);
			const canManageUser = loggedInUser.ability.can(
				'manage',
				profileUser,
			);

			// logged in user is not authorized to manage the profile
			// if (!canManageUser) {
			// 	callback.call(currentWidget, false, false);
			// 	return;
			// }

			// succesfully authorized
			callback.call(currentWidget, false, true);
		})
		.catch((error) => {
			callback.call(currentWidget, error);
		});
};

WidgetNotificationSettings.prototype.saveSettings = function(
	context,
	callback,
) {
	const currentWidget = this;

	// No more ability to configure a global switch for notifications,
	// will always be enabled for system and account notifications
	// const allEmails = $(`#${context.preferences.general.inputs.allEmails.id}`);

	const deviceEmail = $(
		`#${context.preferences.device.inputs.deviceEmail.id}`,
	);
	const deviceInApp = $(
		`#${context.preferences.device.inputs.deviceInApp.id}`,
	);

	if (!(deviceEmail && deviceInApp)) {
		callback.call(this, true, 'Unable to retrieve all settings');
		return;
	}

	const notificationPrefs = this.user.data.preferences.notifications;

	console.debug(notificationPrefs);

	// notificationPrefs.systemNotification.email = allEmails[0].checked;
	// notificationPrefs.accountNotification.email = allEmails[0].checked;

	// No more ability to configure a global switch for notifications,
	// will always be enabled for system and account notifications
	notificationPrefs.systemNotification.email = true;
	notificationPrefs.accountNotification.email = true;
	notificationPrefs.deviceNotification.email = deviceEmail[0].checked;
	notificationPrefs.deviceNotification.inapp = deviceInApp[0].checked;

	this.user
		.save()
		.then(() => {
			callback.call(currentWidget, false);
		})
		.catch((err) => {
			callback.call(currentWidget, err);
		});
};

WidgetNotificationSettings.prototype.buildContext = function(
	notificationPrefs,
) {
	const currentWidget = this;
	const context = {
		preferences: {
			// No more ability to configure a global switch for notifications,
			// will always be enabled for system and account notifications
			// hence the commented section below

			// general: {
			// 	header: currentWidget.getLanguageTag('generalNotifications'),
			// 	subHeader: currentWidget.getLanguageTag(
			// 		'generalNotificationsSubHeader',
			// 	),
			// 	subText: currentWidget.getLanguageTag(
			// 		'generalNotificationSubText',
			// 	),
			// 	inputs: {
			// 		allEmails: {
			// 			label: currentWidget.getLanguageTag('allEmails'),
			// 			enabled: notificationPrefs.systemNotification.email,
			// 			id: currentWidget.generateChildId('allEmails'),
			// 		},
			// 	},
			// },
			device: {
				header: currentWidget.getLanguageTag('deviceNotifications'),
				subHeader: currentWidget.getLanguageTag(
					'deviceNotificationsSubHeader',
				),
				subText: currentWidget.getLanguageTag(
					'deviceNotificationSubText',
				),
				inputs: {
					deviceEmail: {
						label: currentWidget.getLanguageTag('deviceEmail'),
						enabled: notificationPrefs.deviceNotification.email,
						id: currentWidget.generateChildId('deviceEmail'),
					},
					deviceInApp: {
						label: currentWidget.getLanguageTag('deviceInApp'),
						enabled: notificationPrefs.deviceNotification.inapp,
						id: currentWidget.generateChildId('deviceInApp'),
					},
				},
			},
		},
		footerContainerId: currentWidget.footerContainerId,
	};

	return context;
};

// No more ability to configure a global switch for notifications,
// will always be enabled for system and account notifications
// hence why this handler is disabled

// If the "send email for _every_ notification" checkbox changes
// WidgetNotificationSettings.prototype.registerSystemWideEmailSettingsListener = function(
// 	allEmailsId,
// 	deviceEmailsId,
// ) {
// 	const allEmailsCheckbox = $(`#${allEmailsId}`)[0];
// 	const deviceEmailCheckbox = $(`#${deviceEmailsId}`)[0];

// 	// Auto-check the device email setting checkbox
// 	allEmailsCheckbox.onchange = () => {
// 		if (allEmailsCheckbox.checked === true) {
// 			deviceEmailCheckbox.checked = true;
// 		}
// 	};
// };

WidgetNotificationSettings.prototype.saveButtonHandler = function(context) {
	const currentWidget = this;
	currentWidget.saveSettings(context, (saveErr) => {
		if (saveErr) {
			currentWidget
				.getMainContainer()
				.showPopupErrorMessage(
					currentWidget.getLanguageTag('errorSavingSettings'),
				);
		} else {
			currentWidget
				.getMainContainer()
				.showPopupInfoMessage(
					currentWidget.getLanguageTag('settingsSaved'),
				);
		}
	});
};

WidgetNotificationSettings.prototype.generateSaveFooter = function(
	context,
	callback,
) {
	const currentWidget = this;
	currentWidget.removeChildWidget(currentWidget.footerWidget, function(err) {
		currentWidget.addChildWidget(
			WidgetButtonsFooter,
			currentWidget.footerContainerId,
			{},
			function(err, footerWidget) {
				currentWidget.footerWidget = footerWidget;

				currentWidget.footerWidget.setButtons([
					{
						label: currentWidget.getLanguageTag('save'),
						value: 'confirmed',
						classes: ['btn-primary'],
					},
				]);

				currentWidget.footerWidget.addEventListener(
					'footerButtonPressed',
					() => {
						currentWidget.saveButtonHandler(context);
					},
				);

				callback.call(currentWidget, false);
			},
		);
	});
};

WidgetNotificationSettings.prototype.update = function(callback) {
	const currentWidget = this;
	const updateCallback = callback || function() {};

	const currentUser = this.getCurrentUser();
	const id = (getHashCommand() || {}).id || currentUser.id;
	const api = this.getApiV2().apis;

	api.users
		.getUser({ id })
		.then((user) => {
			this.user = new User(api, user);
			const notificationPrefs = user.preferences.notifications;
			const context = currentWidget.buildContext(notificationPrefs);
			context.disabled = currentUser.ability.cannot('manage', this.user);
			currentWidget.renderTemplate(
				context,
				WidgetNotificationSettings.name,
			);
			// currentWidget.registerSystemWideEmailSettingsListener(
			// 	context.preferences.general.inputs.allEmails.id,
			// 	context.preferences.device.inputs.deviceEmail.id,
			// );
			currentWidget.generateSaveFooter(context, updateCallback);

			if (currentUser.ability.can('manage', this.user)) {
				currentWidget.footerWidget.setButtonHidden('confirmed', false);
			} else {
				currentWidget.footerWidget.setButtonHidden('confirmed', true);
			}
		})
		.catch((err) => {
			console.error('Error getting user data ', err);
			updateCallback.call(currentWidget, err);
		});
};

WidgetNotificationSettings.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'Settings',
			settingsSaved: 'Notification settings saved',
			errorSavingSettings: 'There was an error saving the settings!',
			generalNotifications: 'General Notifications',
			deviceNotifications: 'Device Notifications',
			generalNotificationSubText:
				'Choose which types of email updates you receive.',
			deviceNotificationSubText:
				'Manage how you receive notifications from devices you have access to (those in your organization and all your sub-organizations)<br>Note: All In-app notifications are found in the lower left corner of the browser application, directly above your user account tab -- the bell icon above your user profile. Depending on browser settings, you may also enable/disable separate, browser-based alerts.',
			generalNotificationsSubHeader: 'System Preferences',
			deviceNotificationsSubHeader: 'Preferences',
			allEmails:
				'Send me a corresponding email for all notifications I receive in this platform.',
			deviceInApp:
				'Send me an in-app notification whenever a device sends a notification.',
			deviceEmail: 'Email me whenever a device sends a notification.',
			save: 'Save Changes',
		},
	},
);
