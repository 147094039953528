function ElementControllerCloudGlobalstarStandalone(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('rawData', null, null, 'none');

	this.addTrigger('rawDataReceived');
}

ElementControllerCloudGlobalstarStandalone.prototype = Object.create(
	ElementControllerCloudBase.prototype,
);
ElementControllerCloudGlobalstarStandalone.prototype.constructor = ElementControllerCloudGlobalstarStandalone;

ElementControllerCloudGlobalstarStandalone.prototype.NAME = 'CloudGlobalstar';
ElementControllerCloudGlobalstarStandalone.prototype.TYPE =
	'CloudGlobalstarStandalone';
ElementControllerCloudGlobalstarStandalone.prototype.HIDDEN = false;
ElementControllerCloudGlobalstarStandalone.prototype.DEFAULT_TRIGGER =
	'rawDataReceived';
ElementControllerCloudGlobalstarStandalone.prototype.DEFAULT_ABILITY =
	'trigger';
ElementControllerCloudGlobalstarStandalone.prototype.DEFAULT_ARGUMENTS = [
	'rawData',
];
ElementControllerCloudGlobalstarStandalone.prototype.VARIANTS = [
	'cloud',
	'standalone',
];
ElementControllerCloudGlobalstarStandalone.prototype.SINGLETON = true;
ElementControllerCloudGlobalstarStandalone.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudGlobalstarStandalone.prototype.language = deepAssign(
	{},
	ElementControllerCloudBase.prototype.language,
	{
		'en-US': {
			CloudGlobalstar: 'Globalstar',
			CloudGlobalstarStandalone: 'Globalstar',
			rawData: 'Raw Data',
			rawDataReceived: 'Raw Data Received',
		},
	},
);
