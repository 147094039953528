function WidgetDeviceLogs(id, api, parentWidget, options) {
	const currentWidget = this;

	WidgetBase.call(this, id, api, parentWidget, options);

	this.settingsHistoryTable = null;
	this.logsTable = null;
	this.errorsTable = null;

	this.settingsHistoryTableContainerId = this.generateChildId(
		'settingsHistoryTableContainerId',
	);
	this.logsTableContainerId = this.generateChildId('logsTableContainerId');
	this.errorsTableContainerId = this.generateChildId(
		'errorsTableContainerId',
	);
}

WidgetDeviceLogs.prototype = Object.create(WidgetBase.prototype);
WidgetDeviceLogs.prototype.constructor = WidgetDeviceLogs;

WidgetDeviceLogs.prototype.hasPermission = function(callback) {
	const currentWidget = this;
	const deviceId = (getHashCommand() || {}).deviceId || null;
	const api = _mainContainer.getApiV2().apis;
	const currentUser = _mainContainer.getCurrentUser();
	callback = callback || function() {};

	api.devices
		.getDevice({ id: deviceId })
		.then(async (deviceData) => {
			const device = new Device(api, deviceData);
			return device.populate();
		})
		.then((device) => {
			if (currentUser.ability.can('read', device)) {
				return callback.call(currentWidget, false, true);
			}
			return callback.call(currentWidget, false, false);
		})
		.catch((error) => {
			console.error(
				`[WidgetDeviceLogs] Error checking location permissions due to ${(error,
				error.stack)}`,
			);
			return callback.call(currentWidget, error, false);
		});
};

WidgetDeviceLogs.prototype.initialize = function(callback) {
	const currentWidget = this;
	const api = this.getApiV2().apis;
	const deviceId = getHashCommand().deviceId;
	const currentUser = _mainContainer.getCurrentUser();
	const canSeeHistoryTable =
		// Hiding the history table for now until a full followup
		// solution to AI-3504 is implemented that will allow the user
		// to view the sorted history with the current settings always
		// starting on the first page.
		currentUser && currentUser.ability.can('see', 'SystemAdministration');

	this.renderTemplate(
		{
			settingsHistoryTitleLabel: this.getLanguageTag('settingsHistory'),
			settingsHistoryTableContainerId: this
				.settingsHistoryTableContainerId,
			canSeeHistoryTable: canSeeHistoryTable,
			logsTableContainerId: this.logsTableContainerId,
			logsTitleLabel: this.getLanguageTag('events'),
			logsTableContainerId: this.logsTableContainerId,
			errorsTitleLabel: this.getLanguageTag('executionErrors'),
			errorsTableContainerId: this.errorsTableContainerId,
		},
		WidgetDeviceLogs.name,
	);

	currentWidget.addChildWidget(
		WidgetTableDynamic,
		currentWidget.settingsHistoryTableContainerId,
		{
			apiRoute: api.devices.getDeviceSettingsHistory.bind(currentWidget),
			ajaxSorting: true,
			headerSort: true,
			addParams: () => {
				const params = {};
				params.id = deviceId || null;
				return params;
			},
			paginationSize: 10,
			entryMapper: async (entry) => {
				// Determine if a user changed the setting, and
				// link to their profile if possible.
				let user;
				let username;

				if (!entry.changedById) {
					// If a user did not change a setting, then
					// the system did.
					entry.username = 'System';
					delete entry.userLink;
					return entry;
				}

				if (entry.changedById) {
					// The entry was changed by a user.
					// Figure out if the user is accessible
					// If so, get their username
					try {
						user = await api.users.getUser({
							id: entry.changedById,
						});
						entry.username = user.username;
						entry.userLink = currentWidget
							.getMainContainer()
							.getLocationLink('UserAdministration', {
								username: user.username,
								id: user.id,
								org: user.organizationId,
							});
					} catch (error) {
						// The entry was changed by a user,
						// but is currently inaccessible
						entry.username = entry.changedById;
						entry.userLink = entry.changedById;
					}
				}

				return entry;
			},
			columns: [
				{
					field: 'title',
					title: currentWidget.getLanguageTag('settingName'),
					mutator: (value, data, type, params, component) => {
						// If no title is found, fallback to name.
						// value is the DeviceSetting title field
						// data.name is the DeviceSetting name field
						if (!value) {
							value = data.name;
						}
						return value;
					},
				},
				{
					field: 'value',
					title: currentWidget.getLanguageTag('value'),
					mutator: (value, data, type, params, component) => {
						const complexInputs = ['geolocation'];
						const { inputType } = data.raw;

						// Prevent from being displayed as
						// [object Object] in the table
						if (complexInputs.includes(inputType) && value) {
							return JSON.stringify(value);
						}
						return value;
					},
				},
				{
					field: 'userLink',
					title: currentWidget.getLanguageTag('changedById'),
					formatterParams: {
						labelField: 'username',
					},
					// See the entryMapper above for how the userLink
					// attribute is acquired by the formatter...
					// http://tabulator.info/docs/4.0/format#format-custom
					formatter: function(cell, formatterParams, onRendered) {
						const value = cell.getValue();
						const data = cell.getData();
						const label = data[formatterParams.labelField];

						if (!value) {
							// If no user can be identified then label it as system
							const el = document.createElement('div');
							el.className =
								'WidgetDeviceLogs-SettingsHistory-SystemUser';
							el.innerHTML = currentWidget.getLanguageTag(
								'system',
							);
							return el;
						}

						if (value.startsWith('#')) {
							// It's an internal link, not an objectId
							// display the whole username
							const el = document.createElement('a');
							el.setAttribute('href', value);
							el.innerHTML = label;
							return el;
						}

						if (!value.startsWith('#')) {
							// It's an ObjectId, do not provide a link
							const el = document.createElement('div');
							el.innerHTML = label;
							return el;
						}
					},
				},
				{
					field: 'changedAt',
					title: currentWidget.getLanguageTag('changedAt'),
					mutator: (value, data, type, params, component) => {
						return new Date(data.changedAt);
					},
					formatter: 'datetimediff',
					headerSortStartingDir: 'desc',
					formatterParams: {
						humanize: true,
						suffix: true,
						invalidPlaceholder: currentWidget.getLanguageTag(
							'never',
						),
					},
				},
			],
			sortByFrom: function(sorters) {
				// Converts the tabulator parameters into
				// API V2 params for sorting
				// http://tabulator.info/docs/4.0/data#ajax-sort
				let sortBy = [];
				if (sorters && sorters.length) {
					let str = '';
					let sorterField;

					sortBy = sorters.map((entry) => {
						if (entry.field === 'userLink') {
							sorterField = 'changedById';
						} else {
							sorterField = entry.field;
						}

						if (entry.dir === 'desc') {
							str += '-';
						} else {
							str += '+';
						}

						str += sorterField;
						return str;
					});
				}
				return sortBy;
			},
			rowFormatter: (row) => {
				return row;
			},
		},
		(err, settingsHistoryTable) => {
			currentWidget.settingsHistoryTable = settingsHistoryTable;

			this.addChildWidget(
				WidgetTableDynamic,
				currentWidget.logsTableContainerId,
				{
					data: [], // getLogs will provide the data
					apiRoute: this.getLogs.bind(currentWidget),
					// Went ahead and allowed "headerSort" for
					// in-memory sorting
					headerSort: true,
					// AJAX not supported on this endpoint
					// https://koliada.atlassian.net/browse/AI-3369
					// ajaxSorting: true,
					addParams: () => {
						// These params get fed in as the
						// first argument to WidgetDeviceLogs.prototype.getLogs
						const params = {};
						params.deviceId = getHashCommand().deviceId || null;

						return params;
					},
					paginationSize: 10,
					columns: [
						{
							field: 'type',
							title: currentWidget.getLanguageTag('eventType'),
						},
						{
							field: 'elementName',
							title: currentWidget.getLanguageTag('elementName'),
						},
						{
							field: 'payload',
							title: currentWidget.getLanguageTag('payload'),
							mutator: (value, data, type, params, component) => {
								if (
									value.constructor === Object ||
									value.constructor === Array
								) {
									try {
										return JSON.stringify(value);
									} catch (error) {
										return value;
									}
								}
								return value;
							},
						},
						{
							field: 'receivedAt',
							title: currentWidget.getLanguageTag('receivedAt'),
							mutator: (value, data, type, params, component) => {
								return new Date(data.receivedAt);
							},
							formatter: 'datetimediff',
							headerSortStartingDir: 'desc',
							formatterParams: {
								humanize: true,
								suffix: true,
								invalidPlaceholder: currentWidget.getLanguageTag(
									'never',
								),
							},
						},
					], // This gets set in the update function
					rowFormatter: (row) => {
						return row;
					},
				},
				function(err, logsTable) {
					currentWidget.logsTable = logsTable;

					this.addChildWidget(
						WidgetTableDynamic,
						currentWidget.errorsTableContainerId,
						{
							data: [], // getErrors will provide the data
							apiRoute: currentWidget.getErrors.bind(
								currentWidget,
							),
							// In-memory sorting support
							headerSort: true,
							// AJAX not supported on this endpoint
							// https://koliada.atlassian.net/browse/AI-3369
							// ajaxSorting: true,
							addParams: () => {
								const params = {};
								params.thingUuid =
									getHashCommand().thingUuid || null;

								return params;
							},
							paginationSize: 5,
							columns: [
								{
									field: 'type',
									title: currentWidget.getLanguageTag(
										'errorType',
									),
								},
								{
									field: 'elementName',
									title: currentWidget.getLanguageTag(
										'elementName',
									),
								},
								{
									field: 'triggerName',
									title: currentWidget.getLanguageTag(
										'triggerName',
									),
								},

								{
									field: 'targetElement',
									title: currentWidget.getLanguageTag(
										'targetElement',
									),
								},
								{
									field: 'targetAbility',
									title: currentWidget.getLanguageTag(
										'targetAbility',
									),
								},
								{
									field: 'error',
									title: currentWidget.getLanguageTag(
										'error',
									),
								},
								{
									field: '_timestamp',
									title: currentWidget.getLanguageTag(
										'_timestamp',
									),
									mutator: (
										value,
										data,
										type,
										params,
										component,
									) => {
										return new Date(data._timestamp);
									},
									formatter: 'datetimediff',
									headerSortStartingDir: 'desc',
									formatterParams: {
										humanize: true,
										suffix: true,
										invalidPlaceholder: currentWidget.getLanguageTag(
											'never',
										),
									},
								},
							], // This gets set in the update function
							rowFormatter: (row) => {
								return row;
							},
						},
						function(err, errorsTable) {
							currentWidget.errorsTable = errorsTable;

							WidgetBase.prototype.initialize.call(
								currentWidget,
								callback,
							);
						},
					);
				},
			);
		},
	);
};

WidgetDeviceLogs.prototype.update = function(callback) {
	callback = callback || function() {};
	// Property _table refers to the Tabulator instance.
	// http://tabulator.info/docs/4.0/update
	this.settingsHistoryTable._table.replaceData();
	this.logsTable._table.replaceData();
	this.errorsTable._table.replaceData();

	return callback.call(this, false);
};

// Gets 1 page of 10000 points
// returned via the provided query to the v2 storage API.
WidgetDeviceLogs.prototype.getLogs = async function(query) {
	const api = this.getApiV2();

	//We need to be in the context of a device
	if (!query.deviceId) {
		return null;
	}

	const response = await api.apis.devices.getDeviceEvents(query);
	const { data, meta } = response;

	// Map data into something flat that Tabulator can use
	const tableData = data.map((storedValue) => {
		const obj = { ...storedValue };
		obj.receivedAt = new Date(obj.receivedAt);
		return obj;
	});

	return { meta, data: tableData };
};

// TODO: Implement and leverage V2 API
// for cloud execution errors
// update pagination as well
WidgetDeviceLogs.prototype.getErrors = async function() {
	const currentWidget = this;
	const api = currentWidget.getAPI();

	const { thingUuid } = getHashCommand();

	return new Promise((resolve, reject) => {
		api.getAPIRoute('/user/thing/:thingUuid/admin/executionErrors').get(
			thingUuid,
			function(err, data) {
				if (err) {
					reject(err);
					return;
				}

				// Map the data into something flat that Tabulator can use
				const tableData = data.map((errorReport) => {
					const error = { ...errorReport.executionErrors[0] };
					error._timestamp = errorReport._timestamp;
					return error;
				});

				resolve({
					meta: {
						hasNextPage: false,
						hasPrevPage: false,
						limit: 10000,
						page: 1,
						totalDocs: tableData.length,
						totalPages: 1,
					},
					data: tableData,
				});
			},
		);
	});
};

WidgetDeviceLogs.prototype.ICON = './Resources/icons/DeviceLogs.svg';

WidgetDeviceLogs.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'Device Logs',
			settingsHistory: 'Settings History',
			deviceId: 'Device ID',
			changedToId: 'Changed To',
			changedAt: 'Changed',
			changedById: 'User',
			listOrder: 'List Order',
			settingName: 'Name',
			settingTitle: 'Title',
			description: 'Description',
			inputType: 'Input Type',
			text: 'Text',
			toggle: 'Toggle',
			number: 'Number',
			geolocation: 'Geolocation',
			inputTypeProperties: 'Input Properties',
			value: 'Value',
			defaultValue: 'Default Value',
			events: 'Events',
			eventType: 'Type',
			elementName: 'Element',
			payload: 'Payload',
			receivedAt: 'Received',
			executionErrors: 'Execution Errors',
			errorType: 'Type',
			elementName: 'Element',
			triggerName: 'Trigger',
			targetElement: 'Target Element',
			targetAbility: 'Target Ability',
			error: 'Error',
			_timestamp: 'Date',
			never: 'Never',
			system: 'System',
		},
	},
);
