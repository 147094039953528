function ElementControllerEmbeddedRegex(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('pattern', null, 0, 'text', null, null);
	this.setPropertyEmbeddedConversion('pattern', 'rawstring');

	this.addTrigger('conditionTrue');
	this.addTrigger('conditionFalse');

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('evaluate', [], []);
	this.setAbilityCode(
		'evaluate',
		`
	// Convert property to same type as input
	ATMO_Value_t inputString;
	
	ATMO_InitValue(&inputString);
	ATMO_CreateValueConverted(&inputString, ATMO_DATATYPE_STRING, in);

	if(re_match(ATMO_PROPERTY(undefined, pattern), (const char *)inputString.data) >= 0)
	{
		ATMO_TriggerHandler(ATMO_TRIGGER(undefined, conditionTrue), in);
	}
	else
	{
		ATMO_TriggerHandler(ATMO_TRIGGER(undefined, conditionFalse), in);
	}
	
	ATMO_FreeValue(&inputString);
	return ATMO_Status_Success;
	`,
	);
}

ElementControllerEmbeddedRegex.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedRegex.prototype.constructor = ElementControllerEmbeddedRegex;

ElementControllerEmbeddedRegex.prototype.TYPE = 'EmbeddedRegex';
ElementControllerEmbeddedRegex.prototype.HIDDEN = false;
ElementControllerEmbeddedRegex.prototype.DEFAULT_ABILITY = 'evaluate';
ElementControllerEmbeddedRegex.prototype.DEFAULT_TRIGGER = 'conditionTrue';
ElementControllerEmbeddedRegex.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
];
ElementControllerEmbeddedRegex.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedRegex.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedRegex: 'Regular Expression',
			pattern: 'Pattern',
			conditionTrue: 'Pattern Matches',
			conditionFalse: 'Pattern Does Not Match',
			invalidType: 'Invalid Input',
			evaluate: 'Evaluate',
		},
	},
);
