function ElementAppNFC(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);

	this._pluginNFC = requires.PluginNFC;

	this._pluginNFC.addNFCElement(this); //This will have the NFC plugin fire of our triggers when needed
}

ElementAppNFC.prototype = Object.create(ElementAppBase.prototype);
ElementAppNFC.prototype.constructor = ElementAppNFC;
ElementAppNFC.prototype.REQUIRES = ['PluginNFC'];

ElementAppNFC.prototype._initialize = function(callback) {
	ElementAppBase.prototype._initialize.call(this, callback);
};

ElementAppNFC.prototype._remove = function() {
	ElementAppBase.prototype._remove.call(this);
};

ElementAppNFC.prototype._setEditable = function(enabled) {
	ElementAppBase.prototype._setEditable.call(this, enabled);
};

ElementAppNFC.prototype._onPropertyChange = function() {
	ElementAppBase.prototype._onPropertyChange.call(this);
};
