function PlaneControllerEmbeddedCYBT213043Mesh(parentController, elementName) {
	PlaneControllerEmbeddedCypress.call(this, parentController, elementName);
}

PlaneControllerEmbeddedCYBT213043Mesh.prototype = Object.create(
	PlaneControllerEmbeddedCypress.prototype,
);
PlaneControllerEmbeddedCYBT213043Mesh.prototype.constructor = PlaneControllerEmbeddedCYBT213043Mesh;

PlaneControllerEmbeddedCYBT213043Mesh.prototype.VARIANTS = [
	'BT20819A1',
	'CYBT213043Mesh',
];
PlaneControllerEmbeddedCYBT213043Mesh.prototype.SUPPORTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'interval',
	'variables',
	'ble',
	'gpio',
	'i2c',
	'spi',
	'fpmath',
	'debugPrint',
	'adc',
];

PlaneControllerEmbeddedCYBT213043Mesh.prototype.DRIVERS = {
	adc: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_ADC_ADC1',
		},
	],
	ble: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLE_BLE1',
		},
	],
	block: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLOCK_BLOCK1',
		},
	],
	datetime: [],
	filesytem: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_FILESYSTEM_FILESYSTEM1',
		},
	],
	gpio: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_GPIO_GPIO1',
		},
	],
	http: [],
	i2c: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C1',
			primary: true,
		},
	],
	interval: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_INTERVAL_INTERVAL1',
		},
	],
	nfc: [],
	pwm: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_PWM_PWM1',
		},
	],
	spi: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_SPI_SPI1',
		},
	],
	uart: [],
	wifi: [],
};

PlaneControllerEmbeddedCYBT213043Mesh.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'LED_GREEN',
		mode: 'ATMO_GPIO_PinMode_Output_PushPull',
	},
	EmbeddedADCPin: {
		instance: 0,
		pin: 'ADC_THERMISTOR',
	},
	EmbeddedNCP15XV103: {
		adcInstance: 0,
		vddPin: 'ADC_VDDIO',
		tempPin: 'ADC_THERMISTOR',
		powerPin: 'WICED_P09',
	},

	EmbeddedGroveLight: {
		adcInstance: 0,
		adcPin: 'ADC_THERMISTOR',
	},

	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'D0',
	},

	EmbeddedGroveRotaryAngle: {
		adcInstance: 0,
		adcPin: 'ADC_THERMISTOR',
	},

	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'D0',
	},

	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'D0',
	},

	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'D0',
	},
};

PlaneControllerEmbeddedCYBT213043Mesh.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			defaultName: 'EZ-BT Mesh',
		},
	},
);
