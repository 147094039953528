//Copyright 2018 Atmosphere IoT Corp.
//All rights reserved
//jshint esversion: 6

/*
 *
 * Configuration options:
 *
 * eelBlackList: A list of eels never to show
 * eelWhiteList: A list of eels that can be displayed
 *
 *
 */
function WidgetElementLibraryBrowser(id, api, parent, options) {
	var currentWidget = this;

	WidgetPanelBase.call(this, id, api, parent, options);

	this.footerContainerId = this.generateChildId('footerContainer');
	this.fileInputId = this.generateChildId('fileInput');

	this.tableContainerId = this.generateChildId('tableContainer');

	this.table = null;
	this.planeController = null;
	this.currentEelDataByLibName = null;

	this.renderTemplate(
		{
			tableContainerId: this.tableContainerId,
			footerContainerId: this.footerContainerId,
			fileInputId: this.fileInputId,
		},
		WidgetElementLibraryBrowser.name,
	);

	this.fileInput = $(`#${currentWidget.fileInputId}`);

	this.fileInput.change(function(e) {
		e.preventDefault();
		e.stopPropagation();

		var currentInput = this;

		var file = e.target.files[0];

		if (file) {
			var fileName = file.name;

			if (!fileName.toLowerCase().endsWith('.eel')) {
				console.log('Please select an EEL file');
				currentWidget
					.getMainContainer()
					.showPopupErrorMessage(
						getLanguageTag(
							currentWidget.constructor,
							'pleaseSelectAnEelFile',
						),
					);
				return;
			}

			var reader = new FileReader();

			reader.readAsText(file);

			reader.onload = function(readerEvent) {
				var binaryString = readerEvent.target.result;
				var eelData = null;

				try {
					eelData = JSON.parse(binaryString.toString());
				} catch (err) {
					console.log(err);
					return;
				}

				// FIXME: Ask the user to select a variant if the eel file declares them

				currentWidget.planeController.addLibrary(eelData);

				currentWidget.event('dismissed');

				currentWidget
					.getMainContainer()
					.showPopupInfoMessage(
						getLanguageTag(
							currentWidget.constructor,
							'eelImported',
						),
					);
			};
		}
	});
}

WidgetElementLibraryBrowser.prototype = Object.create(
	WidgetPanelBase.prototype,
);
WidgetElementLibraryBrowser.prototype.constructor = WidgetElementLibraryBrowser;

WidgetElementLibraryBrowser.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.addChildWidget(
		WidgetButtonsFooter,
		this.footerContainerId,
		{},
		function(err, footerWidget) {
			footerWidget.setButtons([
				{
					label:
						this.getOptions().dismissLabel ||
						getLanguageTag(this.constructor, 'import'),
					value: 'imported',
					classes: ['btn-primary'],
				},

				{
					label:
						this.getOptions().dismissLabel ||
						getLanguageTag(this.constructor, 'dismiss'),
					value: 'dismissed',
					classes: [`btn-cancel`],
				},

				{
					label:
						this.getOptions().dismissLabel ||
						getLanguageTag(this.constructor, 'save'),
					value: 'confirmed',
					classes: ['btn-primary'],
				},
			]);

			footerWidget.addEventListener('footerButtonPressed', function(
				value,
			) {
				switch (value) {
					case 'imported':
						currentWidget.fileInput.click();

						break;

					case 'dismissed':
						currentWidget.event(value);
						break;

					case 'confirmed':
						currentWidget.confirmChanges(function(err) {
							currentWidget.event(value);
						});
						break;
				}
			});

			this.addChildWidget(
				WidgetTable,
				this.tableContainerId,
				{
					filterInputPlaceHolder: this.getLanguageTag(
						'filterInputPlaceHolder',
					),
					noData: this.getLanguageTag('noData'),
				},

				function(err, newWidgetTable) {
					this.table = newWidgetTable;

					this.updateTable(function(err) {
						WidgetPanelBase.prototype.initialize.call(
							this,
							callback,
						);
					});
				},
			);
		},
	);
};

WidgetElementLibraryBrowser.prototype.confirmChanges = function(callback) {
	var enabledEels = this.getEnabledEels();
	var disabledEels = this.getDisabledEels();

	this.addEels(enabledEels, function(err) {
		this.removeEels(disabledEels);

		callback.call(this, false);
	});
};

WidgetElementLibraryBrowser.prototype.getEnabledEels = function() {
	var eelEnablerInputs = this.getContainer().find(`*[data-eelenabler]`);

	var outputData = [];

	for (var i = 0; i < eelEnablerInputs.length; i++) {
		if ($($(eelEnablerInputs[i]).find('input')).prop('checked')) {
			var libName = $(eelEnablerInputs[i]).attr('data-eelenabler');
			outputData.push(libName);
		}
	}

	return outputData;
};

WidgetElementLibraryBrowser.prototype.getDisabledEels = function() {
	var eelEnablerInputs = this.getContainer().find(`*[data-eelenabler]`);

	var enabledEels = this.getEnabledEels();

	var outputData = [];

	for (var i = 0; i < eelEnablerInputs.length; i++) {
		var checked = $($(eelEnablerInputs[i]).find('input')).prop('checked');

		if (!checked) {
			var libName = $(eelEnablerInputs[i]).attr('data-eelenabler');

			outputData.push(libName);
		}
	}

	return outputData;
};

WidgetElementLibraryBrowser.prototype.setPlaneController = function(
	planeController,
	callback,
) {
	this.planeController = planeController;

	this.updateTable(callback);
};

WidgetElementLibraryBrowser.prototype.isEelEnabled = function(libName) {
	return Boolean(this.planeController.getLibrary(libName));
};

WidgetElementLibraryBrowser.prototype.isEelUpgradable = function(libName) {
	var libData = this.planeController.getLibrary(libName);

	if (!libData) {
		return false;
	}

	if (this.isEelEnabled(libName)) {
		return !this.planeController.compareLibraryMD5s(
			this.currentEelDataByLibName[libName],
			libData,
		);
	}

	return false;
};

WidgetElementLibraryBrowser.prototype.attachUpdateButtons = function() {
	var currentWidget = this;

	var eelUpdateButtons = this.getContainer().find(`*[data-eelupdater]`);

	for (var i = 0; i < eelUpdateButtons.length; i++) {
		$(eelUpdateButtons[i]).click(function() {
			currentWidget.updateEel(
				$(this).attr('data-eelupdater'),
				function() {},
			);
		});
	}
};

WidgetElementLibraryBrowser.prototype.attachBuyButtons = function() {
	var currentWidget = this;

	var eelBuyButtons = this.getContainer().find(`*[data-eelbuy]`);

	for (var i = 0; i < eelBuyButtons.length; i++) {
		$(eelBuyButtons[i]).click(function() {
			var cartData = JSON.parse($(this).attr('data-eelbuy'));

			var currentCart =
				currentWidget.getMainContainer().getGlobalConfig()
					.studioShoppingCart || null;

			if (currentCart === 'digikey') {
				openLinkInNewWindowOrTab(
					`https://www.digikey.com/classic/ordering/fastadd.aspx?part1=${cartData.part}&qty1=1&cref1=dkstudio`,
				);
			}
		});
	}
};

WidgetElementLibraryBrowser.prototype.updateTable = function(callback) {
	var currentWidget = this;

	if (this.table === null) {
		callback.call(this, { type: 'tableNotConstructed' });
		return;
	}

	if (this.planeController === null) {
		//We can show a loading message or something here.
		callback.call(this, false);
		return;
	}

	this.getAPI()
		.getAPIRoute('/user/create/libraries/:requires')
		.get(this.planeController.SUPPORTS, function(err, data) {
			currentWidget.currentEelDataByLibName = {};

			var eelBlackList =
				currentWidget.getConfiguration().eelBlackList || [];
			var eelWhiteList =
				currentWidget.getConfiguration().eelWhiteList || null;
			var tableData = [];

			for (var i = 0; i < data.length; i++) {
				if (eelBlackList.indexOf(data[i].libName) >= 0) {
					continue;
				}

				if (eelWhiteList && eelWhiteList.indexOf(data[i].libName) < 0) {
					continue;
				}

				tableData.push(data[i]);
				currentWidget.currentEelDataByLibName[data[i].libName] =
					data[i];

				if (data[i].variants) {
					for (var j = 0; j < data[i].variants.length; j++) {
						var variantEel = JSON.parse(JSON.stringify(data[i]));
						variantEel = deepOverlay(
							variantEel,
							data[i].variants[j],
						);
						//We set baseLibName value so we know what library the variant is constructed from
						variantEel.baseLibName = data[i].libName;
						tableData.push(variantEel);

						currentWidget.currentEelDataByLibName[
							variantEel.libName
						] = variantEel;
					}
				}
			}

			if (err) {
				callback.call(currentWidget, err);
				return;
			}

			var columns = [
				'enable',
				'update',
				'libName',
				'manufacturer',
				'type',
				'description',
			];

			var currentCart = null;

			if (
				currentWidget.getMainContainer().getGlobalConfig()
					.studioShoppingCart
			) {
				columns.push('buy');
			}

			currentWidget.table.setTable(
				columns,

				{
					enable: {
						columnWidth: 0.5,
						label: getLanguageTag(
							WidgetElementLibraryBrowser,
							'enable',
						),
						sortable: false,
						formatter: function(a, row) {
							return $.handlebarTemplates[
								WidgetElementLibraryBrowser.name +
									'_EnableColumn'
							]({
								libName: row.libName,
								checked: currentWidget.isEelEnabled(
									row.libName,
								),
							});
						},
					},
					update: {
						columnWidth: 0.5,
						label: getLanguageTag(
							WidgetElementLibraryBrowser,
							'update',
						),
						sortable: true,
						formatter: function(a, row) {
							return $.handlebarTemplates[
								WidgetElementLibraryBrowser.name +
									'_UpdateColumn'
							]({
								updateAvailableLabel: getLanguageTag(
									WidgetElementLibraryBrowser,
									'updateAvailable',
								),
								libName: row.libName,
								upgradable: currentWidget.isEelUpgradable(
									row.libName,
								),
							});
						},
					},
					libName: {
						columnWidth: 1.5,
						label: getLanguageTag(
							WidgetElementLibraryBrowser,
							'libName',
						),
						sortable: true,
						formatter: function(a) {
							return a.toUpperCase();
						},
					},
					manufacturer: {
						columnWidth: 1.5,
						label: getLanguageTag(
							WidgetElementLibraryBrowser,
							'manufacturer',
						),
						sortable: true,
					},
					type: {
						columnWidth: 1.5,
						label: getLanguageTag(
							WidgetElementLibraryBrowser,
							'type',
						),
						sortable: true,
					},
					description: {
						label: getLanguageTag(
							WidgetElementLibraryBrowser,
							'description',
						),
						sortable: false,
					},
					buy: {
						columnWidth: 0.5,
						label: getLanguageTag(
							WidgetElementLibraryBrowser,
							'buy',
						),
						sortable: false,

						formatter: function(a, row) {
							var currentCart =
								currentWidget
									.getMainContainer()
									.getGlobalConfig().studioShoppingCart ||
								null;

							if (currentCart === null) {
								return null;
							}

							if (!row.shoppingCartLinks || !row.shoppingCartLinks[currentCart]) {
								return null;
							}

							var cartData =
								row.shoppingCartLinks[currentCart].cartData ||
								null;

							if (cartData === null) {
								return null;
							}
							cartData.addToCart = getLanguageTag(
								WidgetElementLibraryBrowser,
								'addToCart',
							);

							return $.handlebarTemplates[
								WidgetElementLibraryBrowser.name + '_BuyColumn'
							]({
								eelData: JSON.stringify(cartData),
								cartData: cartData,
							});
						},
					},
				},

				tableData,
				'descending',
				'libName',
			);

			currentWidget.table.addEventListener(
				'tableSortingChanged',
				function() {
					currentWidget.attachUpdateButtons();
					currentWidget.attachBuyButtons();
				},
			);

			currentWidget.table.addEventListener(
				'tableFilterChanged',
				function() {
					currentWidget.attachUpdateButtons();
					currentWidget.attachBuyButtons();
				},
			);

			window.requestAnimationFrame(function() {
				currentWidget.attachUpdateButtons();
				currentWidget.attachBuyButtons();
				callback.call(currentWidget, false);
			});
		});
};

WidgetElementLibraryBrowser.prototype.addEels = function(eels, callback) {
	var currentWidget = this;

	if (eels.length <= 0) {
		callback.call(this, false);
		return;
	}

	addEelsHelper();

	function addEelsHelper() {
		if (eels.length <= 0) {
			callback.call(currentWidget, false);
			return;
		}

		var currentEelName = eels.pop();

		if (
			currentWidget.planeController !== null &&
			currentWidget.planeController.getLibrary(currentEelName) === null
		) {
			var eelEntry =
				currentWidget.currentEelDataByLibName[currentEelName];
			var baseLibName = currentEelName;

			if (eelEntry.baseLibName) {
				baseLibName = eelEntry.baseLibName;
			}

			currentWidget
				.getAPI()
				.getAPIRoute('/user/create/library/:name')
				.get(baseLibName, function(err, data) {
					if (err) {
						console.log(err);
						callback.call(currentWidget, err);
						return;
					}

					var eelData = data;

					//It's a variant and we need to reconstruct the eel data
					if (eelEntry.baseLibName) {
						var eelData = Object.assign({}, data);
						var variantData = null;

						for (var i = 0; i < data.variants.length; i++) {
							if (data.variants[i].libName === currentEelName) {
								variantData = data.variants[i];
							}
						}

						//We couldn't find the variant data for this eel variant?
						if (!variantData) {
							callback.call(currentWidget, {
								type: 'noVariantDataFound',
							});
							console.error(
								`No variant data found for EEL "${currentEelName}"?`,
							);
							return;
						}

						eelData = deepOverlay(eelData, variantData);
					}

					delete eelData.variants;

					currentWidget.planeController.addLibrary(eelData);
					addEelsHelper();
				});
		} else {
			addEelsHelper();
		}
	}
};

WidgetElementLibraryBrowser.prototype.updateEel = function(eelName, callback) {
	var currentWidget = this;

	if (currentWidget.planeController !== null) {
		// 		currentWidget.showLoadingIndicator(getLanguageTag(WidgetElementLibraryBrowser, 'updatingEel'));

		var eelEntry = currentWidget.currentEelDataByLibName[eelName];
		var baseLibName = eelName;

		if (eelEntry.baseLibName) {
			baseLibName = eelEntry.baseLibName;
		}

		currentWidget
			.getAPI()
			.getAPIRoute('/user/create/library/:name')
			.get(baseLibName, function(err, data) {
				if (err) {
					// 				currentWidget.hideLoadingIndicator();
					callback.call(currentWidget, err);
					console.log(err);
					return;
				}

				var eelData = data;

				//It's a variant and we need to reconstruct the eel data
				if (eelEntry.baseLibName) {
					var eelData = Object.assign({}, data);
					var variantData = null;

					for (var i = 0; i < data.variants.length; i++) {
						if (data.variants[i].libName === eelName) {
							variantData = data.variants[i];
						}
					}

					//We couldn't find the variant data for this eel variant?
					if (!variantData) {
						callback.call(currentWidget, {
							type: 'noVariantDataFound',
						});
						console.log(
							`No variant data found for EEL "${eelName}"?`,
						);
						return;
					}

					eelData = deepOverlay(eelData, variantData);
				}

				currentWidget.planeController.addLibrary(eelData, true);
				currentWidget.updateTable(callback);
				// 			currentWidget.hideLoadingIndicator();
			});
	}
};

WidgetElementLibraryBrowser.prototype.removeEels = function(eels) {
	var currentWidget = this;

	if (eels.length <= 0) {
		return;
	}

	for (var i = 0; i < eels.length; i++) {
		var currentEelName = eels[i];

		currentWidget.planeController.removeLibrary(currentEelName);
	}
};

WidgetElementLibraryBrowser.prototype.language = deepAssign(
	{},
	WidgetPanelBase.prototype.language,
	{
		'en-US': {
			name: 'Element Library',
			manufacturer: 'Manufacturer',
			driver: 'Driver',
			type: 'Type',
			info: 'Info',
			sensorDriverCatalog: 'Element Library',
			removeElementLibrary:
				'Are you sure you want to remove this element library and its corresponding elements?',
			description: 'Description',
			elementAbilities: 'Element Abilities',
			resources: 'Resources',
			added: 'added',
			noDescriptionAvailable: 'No description available.',
			noAbilitiesAvailable: 'No abilities available.',
			noResourcesAvailable: 'No resources available.',
			updatingEel: 'Updating Element Library',
			enable: 'Enable',
			update: 'Update',
			import: 'Import',
			libName: 'Library Name',
			manufacturer: 'Manufacturer',
			type: 'Type',
			description: 'Description',
			buy: 'Purchase',
			pleaseSelectAnEelFile:
				'Select a valid embedded element library file (.eel)',
			eelImported: 'Element library imported',
			addToCart: 'Add To Cart',
			updateAvailable: 'Update Available',
			filterInputPlaceHolder: 'Search for an element...',
			noData: 'No elements or sensors found',
		},
	},
);
