function ProvisionerUart(api) {
	ProvisionerBase.call(this, api);

	var currentProvisioner = this;

	this.uart = new PluginUart();
}

ProvisionerUart.prototype = Object.create(ProvisionerBase.prototype);
ProvisionerUart.prototype.constructor = ProvisionerUart;

ProvisionerUart.prototype.TYPE = 'uart';
ProvisionerUart.prototype.UNLOCKREGISTRATION = 'AT#NIMUNLOCK';
ProvisionerUart.prototype.LOCKREGISTRATION = 'AT#NIMLOCK';
ProvisionerUart.prototype.GETEXTRASETTINGS = 'AT#NIMGETEXTRASETTINGS';
ProvisionerUart.prototype.SETEXTRASETTING = 'AT#NIMSETEXTRASETTING';
ProvisionerUart.prototype.GETEXTRASETTING = 'AT#NIMGETEXTRASETTING';
ProvisionerUart.prototype.SETURL = 'AT#NIMSETURL';
ProvisionerUart.prototype.SETTOKEN = 'AT#NIMSETTOKEN';
ProvisionerUart.prototype.SETUUID = 'AT#NIMSETUUID';

ProvisionerUart.prototype.isAvailable = function(callback) {
	if (this.uart !== undefined && this.uart.IS_AVAILABLE() === true) {
		callback.call(this, false, true);
		return;
	}

	callback.call(this, false, false);
	return;
};

ProvisionerUart.prototype._parseExtraSettingFlags = function(
	extraSettingsData,
) {
	var extraSettingsOptions = [];

	if (
		(extraSettingsData & ProvisionerUart.prototype.EXTRA_SETTINGS_BLE) ===
		this.EXTRA_SETTINGS_BLE
	) {
		extraSettingsOptions.push('BLE');
	}

	if (
		(extraSettingsData & ProvisionerUart.prototype.EXTRA_SETTINGS_WIFI) ===
		this.EXTRA_SETTINGS_WIFI
	) {
		extraSettingsOptions.push('WiFi');
	}

	if (
		(extraSettingsData & ProvisionerUart.prototype.EXTRA_SETTINGS_LORA) ===
		this.EXTRA_SETTINGS_LORA
	) {
		extraSettingsOptions.push('LoRa');
	}

	if (
		(extraSettingsData &
			ProvisionerUart.prototype.EXTRA_SETTINGS_CELLULAR) ===
		this.EXTRA_SETTINGS_CELLULAR
	) {
		extraSettingsOptions.push('Cellular');
	}

	if (
		(extraSettingsData &
			ProvisionerUart.prototype.EXTRA_SETTINGS_THREAD) ===
		this.EXTRA_SETTINGS_THREAD
	) {
		extraSettingsOptions.push('Thread');
	}

	if (
		(extraSettingsData &
			ProvisionerUart.prototype.EXTRA_SETTINGS_6LOWPAN) ===
		this.EXTRA_SETTINGS_6LOWPAN
	) {
		extraSettingsOptions.push('6LowPAN');
	}

	if (
		(extraSettingsData &
			ProvisionerUart.prototype.EXTRA_SETTINGS_ETHERNET) ===
		this.EXTRA_SETTINGS_ETHERNET
	) {
		extraSettingsOptions.push('Ethernet');
	}

	if (
		(extraSettingsData &
			ProvisionerUart.prototype.EXTRA_SETTINGS_ZIGBEE) ===
		this.EXTRA_SETTINGS_ZIGBEE
	) {
		extraSettingsOptions.push('Zigbee');
	}

	if (
		(extraSettingsData & ProvisionerUart.prototype.EXTRA_SETTINGS_ZWAVE) ===
		this.EXTRA_SETTINGS_ZWAVE
	) {
		extraSettingsOptions.push('ZWave');
	}

	if (
		(extraSettingsData &
			ProvisionerUart.prototype.EXTRA_SETTINGS_SIGFOX) ===
		this.EXTRA_SETTINGS_SIGFOX
	) {
		extraSettingsOptions.push('Sigfox');
	}

	if (
		(extraSettingsData & ProvisionerUart.prototype.EXTRA_SETTINGS_NEUL) ===
		this.EXTRA_SETTINGS_NEUL
	) {
		extraSettingsOptions.push('Neul');
	}

	if (
		(extraSettingsData &
			ProvisionerUart.prototype.EXTRA_SETTINGS_RESERVED) ===
		this.EXTRA_SETTINGS_RESERVED
	) {
		extraSettingsOptions.push('Reserved');
	}

	return extraSettingsOptions;
};

ProvisionerUart.prototype._singleScan = function(startTime, callback) {
	var currentProvisioner = this;

	currentProvisioner.uart.getScanResults(function(err, scanResults) {
		if (scanResults) {
			// Check all of the SSIDs to see if any match existing projects
			scanResults.forEach(function(result) {
				var deviceFoundData = {
					id: result.comPort,
					type: currentProvisioner._deviceTypes[result.uuid],
					projectUuid: result.uuid,
					signalStrength: 0,
					mac: '',
					label: result.comPort,
				};

				if (currentProvisioner._deviceTypes[result.uuid]) {
					currentProvisioner.setAvailableDeviceEntry(deviceFoundData);
					currentProvisioner.event('deviceFound', deviceFoundData);
				} else if (
					currentProvisioner._removedDevices[result.uuid] !==
					undefined
				) {
					var currentRemovedDevice =
						currentProvisioner._removedDevices[result.uuid];
					deviceFoundData.type =
						currentProvisioner._deviceTypes[
							currentRemovedDevice.projectUuid
						];
					deviceFoundData.projectUuid =
						currentRemovedDevice.projectUuid;
					deviceFoundData.removed = true;
					deviceFoundData.removedData = currentRemovedDevice;
					currentProvisioner.setAvailableDeviceEntry(deviceFoundData);
					currentProvisioner.event('deviceFound', deviceFoundData);
				}
			});
		}
		currentProvisioner.event('searchingStopped', null);
	});
};

ProvisionerUart.prototype.startSearching = function(timeout, callback) {
	var currentProvisioner = this;
	currentProvisioner.event('searchingStarted', null);
	currentProvisioner.getRemovedDevices(function(err, removedDevices) {
		currentProvisioner._removedDevices = removedDevices || {};

		currentProvisioner.getAvailableDeviceTypes(function(err, deviceTypes) {
			currentProvisioner._timeout = timeout;
			currentProvisioner._deviceTypes = deviceTypes || {};
			callback.call(currentProvisioner, false);
			var startDate = new Date();
			currentProvisioner._singleScan(startDate.getTime(), callback);
		});
	});
};

ProvisionerUart.prototype.stopSearching = function(callback) {
	var currentProvisioner = this;
	this._timeout = 0;
	callback.call(currentProvisioner, false);
};

ProvisionerUart.prototype._setExtraSettingsWiFi = function(
	currentSSID,
	extraSettingsOptions,
	callback,
) {
	var currentProvisioner = this;
	var ssid = extraSettingsOptions.ssid || null;
	var password = extraSettingsOptions.password || null;

	if (ssid === null) {
		callback.call(this, { type: 'invalidWiFiSSID' });
		return;
	}

	if (password === null) {
		callback.call(this, { type: 'invalidWiFiPassword' });
	}

	var ssidCmd =
		currentProvisioner.SETEXTRASETTING +
		`=0x${currentProvisioner.EXTRASETTINGSWIFI.toString(
			16,
		)},0x${currentProvisioner.EXTRASETTINGSWIFI_SETSSID.toString(
			16,
		)},${ssid}`;

	currentProvisioner.uart.sendAtCommand(ssidCmd, function(err, data) {
		if (err) {
			currentProvisioner.uart.disconnect(function(discErr) {
				callback.call(currentProvisioner, err);
				return;
			});
		}

		var passCmd =
			currentProvisioner.SETEXTRASETTING +
			`=0x${currentProvisioner.EXTRASETTINGSWIFI.toString(
				16,
			)},0x${currentProvisioner.EXTRASETTINGSWIFI_SETPASSWORD.toString(
				16,
			)},${password}`;

		currentProvisioner.uart.sendAtCommand(passCmd, function(err) {
			callback.call(currentProvisioner, err);
			return;
		});
	});
};

ProvisionerUart.prototype._getSigfoxDeviceId = function(id, callback) {
	var currentProvisioner = this;

	var getIdCmd =
		currentProvisioner.GETEXTRASETTING +
		`=0x${currentProvisioner.EXTRASETTINGSSIGFOX.toString(
			16,
		)},0x${currentProvisioner.EXTRASETTINGSSIGFOX_GETDEVICEID.toString(
			16,
		)}`;
	currentProvisioner.uart.sendAtCommand(getIdCmd, function(err, data) {
		currentProvisioner.uart.disconnect(function(discErr) {
			if (err) {
				callback.call(currentProvisioner, true);
				return;
			}

			var deviceId = parseInt(data, 16)
				.toString(16)
				.toUpperCase();
			callback.call(currentProvisioner, false, deviceId);
		});
	});
};

ProvisionerUart.prototype._getLoraDeviceEui = function(id, callback) {
	var currentProvisioner = this;

	var getIdCmd =
		currentProvisioner.GETEXTRASETTING +
		`=0x${currentProvisioner.EXTRASETTINGSLORA.toString(
			16,
		)},0x${currentProvisioner.EXTRASETTINGSLORA_GETDEVICEID.toString(16)}`;
	currentProvisioner.uart.sendAtCommand(getIdCmd, function(err, data) {
		currentProvisioner.uart.disconnect(function(discErr) {
			if (err) {
				callback.call(currentProvisioner, true);
				return;
			}

			// Filter out any non-ascii characters just in case
			callback.call(
				currentProvisioner,
				false,
				data
					.trim()
					.replace(/[^\x00-\x7F]/g, '')
					.toUpperCase(),
			);
		});
	});
};

ProvisionerUart.prototype._setExtraSettingSigfox = function(
	id,
	extraSettingsOptions,
	callback,
) {
	var currentProvisioner = this;

	this._getSigfoxDeviceId(id, function(err, sigfoxDeviceId) {
		if (err) {
			callback.call(this, err);
			return;
		}

		if (extraSettingsOptions.device === undefined) {
			callback.call(this, { type: 'noDeviceSpecified' });
			return;
		}

		const meta = extraSettingsOptions.device.data.meta;
		meta.sigfoxDeviceId = sigfoxDeviceId;
		extraSettingsOptions.device
			.save()
			.then(() => {
				callback.call(currentProvisioner, false);
			})
			.catch((err) => {
				callback.call(currentProvisioner, err);
			});
	});
};

ProvisionerUart.prototype._setExtraSettingLoRa = function(
	id,
	extraSettingsOptions,
	callback,
) {
	var currentProvisioner = this;

	this._getLoraDeviceEui(id, function(err, loraDeviceEui) {
		if (err) {
			callback.call(this, err);
			return;
		}

		if (extraSettingsOptions.device === undefined) {
			callback.call(this, { type: 'noDeviceSpecified' });
			return;
		}

		const meta = extraSettingsOptions.device.data.meta;
		meta.loraDevEUI = loraDeviceEui;
		extraSettingsOptions.device
			.save()
			.then(() => {
				callback.call(currentProvisioner, false);
			})
			.catch((err) => {
				callback.call(currentProvisioner, err);
			});
	});
};

ProvisionerUart.prototype.setExtraSettings = function(
	id,
	extraSettingsType,
	extraSettingsOptions,
	callback,
) {
	var currentProvisioner = this;

	extraSettingsOptions = extraSettingsOptions || {};

	this.uart.connect(id, { baudRate: 115200 }, function(err) {
		if (err) {
			callback.call(
				currentProvisioner,
				{ type: 'failedToConnectToDevice', err: err },
				null,
				null,
			);
			return;
		}

		switch (extraSettingsType) {
			case 'WiFi':
				currentProvisioner._setExtraSettingsWiFi(
					id,
					extraSettingsOptions,
					function(wifiErr) {
						currentProvisioner.uart.disconnect(function(discErr) {
							callback.call(currentProvisioner, wifiErr);
						});
					},
				);
				return;

			case 'Sigfox':
				currentProvisioner._setExtraSettingSigfox(
					id,
					extraSettingsOptions,
					function(sigfoxErr) {
						currentProvisioner.uart.disconnect(function(discErr) {
							callback.call(currentProvisioner, sigfoxErr);
						});
					},
				);
				return;

			case 'LoRa':
				currentProvisioner._setExtraSettingLoRa(
					id,
					extraSettingsOptions,
					function(loRaErr) {
						currentProvisioner.uart.disconnect(function(discErr) {
							callback.call(currentProvisioner, loRaErr);
						});
					},
				);
				return;

			default:
				callback.call(currentProvisioner, {
					type: 'invalidExtraSettingsType',
				});
				return;
		}
	});
};

// TODO nick put in base
ProvisionerUart.prototype._parseExtraSettingFlags = function(
	extraSettingsData,
) {
	var extraSettingsOptions = [];

	if (
		(extraSettingsData & ProvisionerUart.prototype.EXTRA_SETTINGS_BLE) ===
		this.EXTRA_SETTINGS_BLE
	) {
		extraSettingsOptions.push('BLE');
	}

	if (
		(extraSettingsData & ProvisionerUart.prototype.EXTRA_SETTINGS_WIFI) ===
		this.EXTRA_SETTINGS_WIFI
	) {
		extraSettingsOptions.push('WiFi');
	}

	if (
		(extraSettingsData & ProvisionerUart.prototype.EXTRA_SETTINGS_LORA) ===
		this.EXTRA_SETTINGS_LORA
	) {
		extraSettingsOptions.push('LoRa');
	}

	if (
		(extraSettingsData &
			ProvisionerUart.prototype.EXTRA_SETTINGS_CELLULAR) ===
		this.EXTRA_SETTINGS_CELLULAR
	) {
		extraSettingsOptions.push('Cellular');
	}

	if (
		(extraSettingsData &
			ProvisionerUart.prototype.EXTRA_SETTINGS_THREAD) ===
		this.EXTRA_SETTINGS_THREAD
	) {
		extraSettingsOptions.push('Thread');
	}

	if (
		(extraSettingsData &
			ProvisionerUart.prototype.EXTRA_SETTINGS_6LOWPAN) ===
		this.EXTRA_SETTINGS_6LOWPAN
	) {
		extraSettingsOptions.push('6LowPAN');
	}

	if (
		(extraSettingsData &
			ProvisionerUart.prototype.EXTRA_SETTINGS_ETHERNET) ===
		this.EXTRA_SETTINGS_ETHERNET
	) {
		extraSettingsOptions.push('Ethernet');
	}

	if (
		(extraSettingsData &
			ProvisionerUart.prototype.EXTRA_SETTINGS_ZIGBEE) ===
		this.EXTRA_SETTINGS_ZIGBEE
	) {
		extraSettingsOptions.push('Zigbee');
	}

	if (
		(extraSettingsData & ProvisionerUart.prototype.EXTRA_SETTINGS_ZWAVE) ===
		this.EXTRA_SETTINGS_ZWAVE
	) {
		extraSettingsOptions.push('ZWave');
	}

	if (
		(extraSettingsData &
			ProvisionerUart.prototype.EXTRA_SETTINGS_SIGFOX) ===
		this.EXTRA_SETTINGS_SIGFOX
	) {
		extraSettingsOptions.push('Sigfox');
	}

	if (
		(extraSettingsData & ProvisionerUart.prototype.EXTRA_SETTINGS_NEUL) ===
		this.EXTRA_SETTINGS_NEUL
	) {
		extraSettingsOptions.push('Neul');
	}

	if (
		(extraSettingsData &
			ProvisionerUart.prototype.EXTRA_SETTINGS_RESERVED) ===
		this.EXTRA_SETTINGS_RESERVED
	) {
		extraSettingsOptions.push('Reserved');
	}

	return extraSettingsOptions;
};

ProvisionerUart.prototype._unlockRegistration = function(id, callback) {
	var currentProvisioner = this;

	var availableDeviceEntry = this.getAvailableDeviceEntry(id) || {};

	if (availableDeviceEntry.removed) {
		var token = availableDeviceEntry.removedData.token || [];
		var unlockCmd = `${currentProvisioner.UNLOCKREGISTRATION}=${token}`;
		currentProvisioner.uart.sendAtCommand(unlockCmd, function(err, result) {
			if (err) {
				currentProvisioner.uart.disconnect(function(err) {
					callback.call(currentProvisioner, true);
				});
				return;
			}

			callback.call(currentProvisioner, false);
		});
	} else {
		callback.call(currentProvisioner, false);
	}
};

ProvisionerUart.prototype._getExtraSettings = function(callback) {
	var currentProvisioner = this;
	currentProvisioner.uart.sendAtCommand(
		currentProvisioner.GETEXTRASETTINGS,
		function(err, data) {
			if (err) {
				callback.call(currentProvisioner, true);
			}
			var extraSettingsNum = parseInt(data, 16);

			var extraSettings = currentProvisioner._parseExtraSettingFlags(
				extraSettingsNum,
			);
			callback.call(currentProvisioner, false, extraSettings);
		},
	);
};

ProvisionerUart.prototype._setRegistrationInfo = function(
	id,
	uuid,
	token,
	url,
	callback,
) {
	var currentProvisioner = this;
	var setTokenCommand = currentProvisioner.SETTOKEN + `=${token}`;

	currentProvisioner.uart.sendAtCommand(setTokenCommand, function(err, data) {
		if (err) {
			callback.call(currentProvisioner, false);
			return;
		}

		var setUrlCommand = currentProvisioner.SETURL + `=${url}`;
		currentProvisioner.uart.sendAtCommand(setUrlCommand, function(
			err,
			data,
		) {
			if (err) {
				callback.call(currentProvisioner, false);
				return;
			}

			var setUuidCommand = currentProvisioner.SETUUID + `=${uuid}`;
			currentProvisioner.uart.sendAtCommand(setUuidCommand, function(
				err,
				data,
			) {
				if (err) {
					callback.call(currentProvisioner, false);
					return;
				}

				currentProvisioner.uart.sendAtCommand(
					currentProvisioner.LOCKREGISTRATION,
					function(err, data) {
						callback.call(currentProvisioner, err);
					},
				);
				return;
			});
		});
	});
};

ProvisionerUart.prototype.provisionDevice = function(
	id,
	newName,
	projectUuid,
	callback,
) {
	// Connect to the device
	var currentProvisioner = this;
	currentProvisioner.stopSearching(function(err) {
		currentProvisioner.uart.connect(id, { baudRate: 115200 }, function(
			err,
		) {
			if (err) {
				callback.call(
					currentProvisioner,
					{ type: 'failedToConnectToDevice', err: err },
					null,
					null,
				);
				return;
			}

			currentProvisioner._unlockRegistration(id, function(err) {
				if (err) {
					currentProvisioner.uart.disconnect(function(otherErr) {
						callback.call(
							currentProvisioner,
							{ type: 'failedToProvisionDevice', err: err },
							null,
							null,
						);
					});
					return;
				}

				currentProvisioner._getExtraSettings(function(
					err,
					extraSettings,
				) {
					if (err) {
						currentProvisioner.uart.disconnect(function(otherErr) {
							callback.call(
								currentProvisioner,
								{ type: 'failedToProvisionDevice', err: err },
								null,
								null,
							);
						});
						return;
					}

					_mainContainer.getOrganizationId((err, orgId) => {
					
						const registerData = {
							projectUuid,
							versionUuid: null,
							protocol: currentProvisioner.TYPE,
							protocols: extraSettings,
							name: newName,
							organizationId: orgId,
						};

						const api = _mainContainer._apiv2.apis;
						const device = new Device(api, registerData);
						device
							.create()
							.then((data) => {
								var deviceAPIBase =
									_mainContainer._globalConfig.deviceAPIBase ||
									getAPIBase();

								currentProvisioner._setRegistrationInfo(
									id,
									data.uuid,
									data.token,
									deviceAPIBase,
									function(err) {
										currentProvisioner.uart.disconnect(function(
											otherErr,
										) {
											callback.call(
												currentProvisioner,
												err,
												data,
												extraSettings,
											);
										});
										return;
									},
								);
							})
							.catch((err) => {
								console.error(
									'Failed to provision over UART ',
									registerData,
								);
								currentProvisioner.uart.disconnect(function(
									otherErr,
								) {
									callback.call(
										currentProvisioner,
										{
											type: 'failedToProvisionDevice',
											err: err,
										},
										null,
										null,
									);
								});
								return;
							});
					});
				});
			});
		});
	});
};

ProvisionerUart.prototype.remove = function(callback) {
	this.stopSearching(callback);
};
