function PlaneControllerEmbeddedLinux(parentController, elementName) {
	PlaneControllerEmbeddedBase.call(this, parentController, elementName);
}

PlaneControllerEmbeddedLinux.prototype = Object.create(
	PlaneControllerEmbeddedBase.prototype,
);
PlaneControllerEmbeddedLinux.prototype.constructor = PlaneControllerEmbeddedLinux;

PlaneControllerEmbeddedLinux.prototype.TYPE = 'EmbeddedLinux';
PlaneControllerEmbeddedLinux.prototype.VARIANTS = [];
PlaneControllerEmbeddedLinux.prototype.SUPPORTS = [
	'embedded',
	'gpio',
	'adc',
	'i2c',
	'spi',
	'uart',
	'fpmath',
];
PlaneControllerEmbeddedLinux.prototype.SELECTABLE_VARIANTS = [];

PlaneControllerEmbeddedLinux.prototype.language = {
	'en-US': {
		defaultName: 'EmbeddedLinux',
	},
};
