function PlaneControllerEmbeddedPSoC6WiFiBtProto(
	parentController,
	elementName,
) {
	PlaneControllerEmbeddedCypress.call(this, parentController, elementName);
}

PlaneControllerEmbeddedPSoC6WiFiBtProto.prototype = Object.create(
	PlaneControllerEmbeddedCypress.prototype,
);
PlaneControllerEmbeddedPSoC6WiFiBtProto.prototype.constructor = PlaneControllerEmbeddedPSoC6WiFiBtProto;

PlaneControllerEmbeddedPSoC6WiFiBtProto.prototype.VARIANTS = [
	'PSoC6WiFiBtProto',
];
PlaneControllerEmbeddedPSoC6WiFiBtProto.prototype.SUPPORTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'interval',
	'variables',
	'adc',
	'ble',
	'gpio',
	'uart',
	'i2c',
	'fpmath',
	'debugPrint',
	'log',
	'uptime',
];
PlaneControllerEmbeddedPSoC6WiFiBtProto.prototype.CLIENT_AGENT_REQUIRED = true;

PlaneControllerEmbeddedPSoC6WiFiBtProto.prototype.FIRMWARE_DOWNLOAD_FILES = {
	artifactName: 'firmware',
	fileName: 'atmosphere-project.hex',
};

PlaneControllerEmbeddedPSoC6WiFiBtProto.prototype.DRIVERS = {
	adc: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_ADC_ADC1',
		},
	],
	ble: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLE_BLE1',
		},
	],
	block: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLOCK_BLOCK1',
		},
	],
	embeddedCloud: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_EMBEDDEDCLOUD_EMBEDDEDCLOUD1',
		},
	],
	datetime: [],
	filesytem: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_FILESYSTEM_FILESYSTEM1',
		},
	],
	gpio: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_GPIO_GPIO1',
		},
	],
	http: [],
	i2c: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C1',
			primary: true,
		},
	],
	interval: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_INTERVAL_INTERVAL1',
		},
	],
	nfc: [],
	pwm: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_PWM_PWM1',
		},
	],
	spi: [],
	uart: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_UART_UART1',
		},
	],
	wifi: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_WIFI_WIFI1',
		},
	],
};

PlaneControllerEmbeddedPSoC6WiFiBtProto.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'LED1',
		mode: 'ATMO_GPIO_PinMode_Output_PushPull',
	},

	EmbeddedADCPin: {
		instance: 0,
		pin: 'A0',
	},

	EmbeddedNTCThermistor: {
		bValue: 3428,
		t0Value: 25,
		vddADCPin: 'P10_0',
		temperatureADCPin: 'P10_1',
	},
	EmbeddedGroveLight: {
		adcInstance: 0,
		adcPin: 'A0',
	},
	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'P12_0',
	},
	EmbeddedGroveTemperature: {
		adcInstance: 0,
		adcPin: 'P10_1',
	},
	EmbeddedGroveRotaryAngle: {
		adcInstance: 0,
		adcPin: 'P10_1',
	},
	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'P12_0',
	},
	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'P12_0',
	},
	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'P12_0',
	},
};

PlaneControllerEmbeddedPSoC6WiFiBtProto.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			defaultName: 'PSoC 6 Wi-Fi BT Proto',
			ATMO_DRIVERINSTANCE_EMBEDDEDCLOUD_EMBEDDEDCLOUD1: 'WiFi',
		},
	},
);
