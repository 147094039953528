function WidgetCodeEditor(id, api, parent, options) {
	var currentWidget = this;

	WidgetBase.call(this, id, api, parent, options);

	this.aceEditorDivId = this.generateChildId('aceEditorDiv');

	this.renderTemplate({ id: this.aceEditorDivId }, WidgetCodeEditor.name);

	/*
	 * With in the code editor we have places in the callbacks file
	 * we don't want the user to be able to editor, normally it goes
	 * like the following
	 *
	 * void foo() { << they can't edit this line
	 * 		int stuff = 2;
	 * 		stuff++;
	 * 		return stuff; << they can edit all these lines
	 *	} << they can't edit this line.
	 *
	 * So each callback function is defined by three anchors
	 * One for the first non editable line
	 * second is all the editable content inbetween
	 * and another for the last non editable line
	 *
	 * All other lines in the editor are available for editing.
	 *
	 * Now when we detect a change between two markers we check
	 * if the line that is trying to be edited is a marker line
	 * if so then we prevent it, other than that we allow the
	 * modification. After the modification we update the correct
	 * plane property it maybe the ability code of an element or
	 * some of the global headers/footers on the callback code.
	 */

	this._planeEditor = null;
	this.editableRanges = {};
	this.editableRangeIdToStaticCode = {};
	this.editableRangeIdToElementController = {};
	this.buildErrors = [];
	this.headerRangeId = null;
	this.footerRangeId = null;
	this.updateOnChangeInfo = null;
	this.updateCodeEditorUpdateTimeout = null;
	this.disableOnEditorExec = false;

	this.currentlyEditing = null; //indicating what file we are currently editing, if null we are working on the element callbacks.

	this.editor = ace.edit(this.aceEditorDivId);
	this.editor.setTheme('ace/theme/kuroir');
	this.editor.getSession().setMode('ace/mode/c_cpp');
	this.editor.$blockScrolling = Infinity;
	this.editor.setOptions({ enableLiveAutocompletion: false });

	this.loading = false;

	this.editor.commands.on('exec', function(event) {
		if (!currentWidget.loading) {
			currentWidget.onEditorExec(event);
		}
	});

	this.editor.getSession().on('change', function(event) {
		if (!currentWidget.loading) {
			currentWidget.onChange(null);
		}
	});

	this.editor
		.getSession()
		.selection.on('changeSelection', function(event) {});

	this.editor.getSession().selection.on('changeCursor', function(event) {});

	this.editor.setOption('dragEnabled', false);

	this.getParent().addEventListener('elementEditorOpened', function(event) {
		currentWidget.scrollToElement(event.elementName);
	});

	this.controller = null;
}

WidgetCodeEditor.prototype = Object.create(WidgetBase.prototype);
WidgetCodeEditor.prototype.constructor = WidgetCodeEditor;

WidgetCodeEditor.prototype.EXTRA_TOOL_ICON =
	'./Widgets/WidgetCodeEditor/Resources/CodeEditor.svg';
WidgetCodeEditor.prototype.HIDE_ON_INITIALIZE = true;

WidgetCodeEditor.prototype.show = function() {
	this.setEditingFile(null);

	WidgetBase.prototype.show.call(this);
};

WidgetCodeEditor.prototype.addEventListener = function(event, callback) {};

WidgetCodeEditor.prototype.attachPlaneEditor = function(planeEditor) {
	this._planeEditor = planeEditor;
};

WidgetCodeEditor.prototype.onChange = function(event) {
	if (this.disableOnEditorExec) {
		return;
	}

	if (this.updateOnChangeInfo !== null) {
		var Range = ace.require('ace/range').Range;
		var updateTextRange = new Range();

		updateTextRange.start.row =
			this.updateOnChangeInfo.editableRange.start.row + 1;
		updateTextRange.end.row =
			this.updateOnChangeInfo.editableRange.end.row - 2;

		var currentCode = this.editor
			.getSession()
			.doc.getTextRange(updateTextRange);

		if (this.updateOnChangeInfo.header) {
			//NH-409 Fix
			if (updateTextRange.start.row > updateTextRange.end.row) {
				this.updateOnChangeInfo = null;
				this.editor.insert(`\n`);
				this.controller.setStaticHeaderCode(`\n`);
			} else {
				this.controller.setStaticHeaderCode(currentCode);
			}
		} else if (this.updateOnChangeInfo.static) {
			//NH-409 Fix
			if (updateTextRange.start.row > updateTextRange.end.row) {
				var currentStaticCodeName = this.updateOnChangeInfo.static;
				this.updateOnChangeInfo = null;
				this.editor.insert(`\n\treturn;`);
				this.controller.setStaticFunctionCode(
					currentStaticCodeName,
					`\n\treturn;`,
				);
			} else {
				this.controller.setStaticFunctionCode(
					this.updateOnChangeInfo.static,
					currentCode,
				);
			}
		} else if (this.updateOnChangeInfo.element) {
			var currentElementController = this.updateOnChangeInfo.element
				.controller;
			var currentAbilityName = this.updateOnChangeInfo.element
				.abilityName;

			//NH-409 Fix
			if (updateTextRange.start.row > updateTextRange.end.row) {
				this.updateOnChangeInfo = null;
				this.editor.insert(`\n\treturn ATMO_Status_Success;`);
				currentElementController.setAbilityCode(
					currentAbilityName,
					'\treturn ATMO_Status_Success;',
					true,
				);
			} else {
				currentElementController.setAbilityCode(
					currentAbilityName,
					currentCode,
					true,
				);
			}
		} else if (this.updateOnChangeInfo.footer) {
			//NH-409 Fix
			if (updateTextRange.start.row > updateTextRange.end.row) {
				this.updateOnChangeInfo = null;
				this.editor.insert(`\n`);
				this.controller.setStaticFooterCode(`\n`);
			} else {
				this.controller.setStaticFooterCode(currentCode);
			}
		}
	}
};

WidgetCodeEditor.prototype.onEditorExec = function(event) {
	/* This was a trick we learned far to late in the development of Atmosphere 1.0
	 * that we could hook ALL command events from the ace editor and stop propagation
	 * on any of them that we wanted.
	 *
	 * if we want to stop the command we can call,
	 *
	 * event.preventDefault();
	 * event.stopPropagation();
	 */

	if (this.disableOnEditorExec) {
		return;
	}

	switch (event.command.name) {
		// FIXME: We will have to catch this and update the elements accordingly
		case 'undo':
			break;

		// FIXME: We will have to catch this and update the elements accordingly
		case 'redo':
			break;

		case 'golineup':
			break;

		case 'golinedown':
			break;

		case 'golineleft':
			break;

		case 'golineright':
			break;

		case 'selectup':
			break;

		case 'selectdown':
			break;

		case 'selectleft':
			break;

		case 'selectright':
			break;

		case 'del':
			// FIXME: We need to check to see if we are deleting the last newline in the end of
			//  an editable range and then stop it if that is the case.
			//  Same for backspace.
			this.updateOnChangeInfo = null;
			event.preventDefault();
			event.stopPropagation();
			break;

		case 'paste':
		case 'cut':
		case 'backspace':
		case 'movelinesdown':
		case 'movelinesup':
		case 'insertstring':
			this.updateOnChangeInfo = null;

			if (!this.isEditableRange(this.editor.selection.getRange())) {
				event.preventDefault();
				event.stopPropagation();
				return;
			} else {
				this.onInsertString(this.editor.selection.getRange());
			}
			break;

		default:
			if (!this.isEditableRange(this.editor.selection.getRange())) {
				event.preventDefault();
				event.stopPropagation();
				return;
			}

			break;
	}
};

WidgetCodeEditor.prototype.attachController = function(controller) {
	var currentWidget = this;

	this.controller = controller;

	this.controller.addEventListener('elementAdded', function(data) {
		currentWidget.setEditingFile(null);
	});

	this.controller.addEventListener('elementRemoved', function(data) {
		currentWidget.setEditingFile(null);
	});

	this.controller.addEventListener('elementNameSet', function(data) {
		currentWidget.setEditingFile(null);
	});

	this.controller.addEventListener('buildError', function(data) {
		currentWidget.onBuildError(data);
	});

	this.controller.addEventListener('libraryUpdated', function(data) {
		currentWidget.setEditingFile(null);
	});

	this.controller.getParent().addEventListener('compiling', function(data) {
		currentWidget.editor.getSession().clearAnnotations();
	});

	this.setEditingFile(null);
	return;
};

WidgetCodeEditor.prototype.onBuildError = function(data) {
	this.buildErrors = [];

	this.editor.getSession().clearAnnotations();

	var annotations = [];

	for (var i = 0; i < data.err.error.length; i++) {
		if (
			data.err.error[i].type === 'error' &&
			data.err.error[i].filename.endsWith(
				'app_src/atmosphere_callbacks.c',
			)
		) {
			this.buildErrors.push(data.err.error[i]);

			annotations.push({
				row: data.err.error[i].line - 1,
				column: data.err.error[i].column,
				text: data.err.error[i].text,
				type: 'error', // also warning and information
			});
		}
	}

	this.editor.getSession().setAnnotations(annotations);
};

WidgetCodeEditor.prototype.isPositionEditable = function(row, column) {
	//We only care about blocking editing for the dynamically generated callback file
	//We don't care about other files we maybe editing.
	if (this.currentlyEditing !== null) {
		return true;
	}

	for (var rangeId in this.editableRanges) {
		var currentRange = this.editableRanges[rangeId];

		if (row > currentRange.start.row && row < currentRange.end.row - 1) {
			return true;
		}
	}

	return false;
};

WidgetCodeEditor.prototype.isEditableRange = function(range, offset) {
	offset = offset || 0;

	var start = Math.min(range.start.row, range.end.row);
	var end = Math.max(range.start.row, range.end.row);

	for (var i = start + offset; i <= end + offset; i++) {
		if (this.isPositionEditable(i, 0)) {
			return true;
		}
	}

	return false;
};

WidgetCodeEditor.prototype.getEditableRangeIdFromRange = function(
	range,
	offset,
) {
	offset = offset || 0;

	var start = Math.min(range.start.row, range.end.row);
	var end = Math.max(range.start.row, range.end.row);

	for (var i = start + offset; i <= end + offset; i++) {
		for (var rangeId in this.editableRanges) {
			var currentRange = this.editableRanges[rangeId];

			if (i > currentRange.start.row && i < currentRange.end.row) {
				return rangeId;
			}
		}
	}

	return null;
};

WidgetCodeEditor.prototype.isRangeStaticHeader = function(range) {
	var currentRangeId = this.getEditableRangeIdFromRange(range);

	if (currentRangeId === null) {
		return null;
	}

	return currentRangeId === this.headerRangeId;
};

WidgetCodeEditor.prototype.isRangeStaticFooter = function(range) {
	var currentRangeId = this.getEditableRangeIdFromRange(range);

	if (currentRangeId === null) {
		return null;
	}

	return currentRangeId === this.footerRangeId;
};

WidgetCodeEditor.prototype.getElementEntryFromRange = function(range) {
	var currentRangeId = this.getEditableRangeIdFromRange(range);

	if (currentRangeId === null) {
		return null;
	}

	var currentElementEntry = this.editableRangeIdToElementController[
		currentRangeId
	];

	if (currentElementEntry === undefined) {
		return null;
	}

	return currentElementEntry;
};

WidgetCodeEditor.prototype.getStaticCodeFunctionFromRange = function(range) {
	var currentRangeId = this.getEditableRangeIdFromRange(range);

	if (currentRangeId === null) {
		return null;
	}

	var currentStaticCodeName = this.editableRangeIdToStaticCode[
		currentRangeId
	];

	if (currentStaticCodeName === undefined) {
		return null;
	}

	return currentStaticCodeName;
};

WidgetCodeEditor.prototype.onInsertString = function(range) {
	var currentRangeId = this.getEditableRangeIdFromRange(range);

	if (currentRangeId === null) {
		return;
	}

	var currentRange = this.editableRanges[currentRangeId];

	if (this.isRangeStaticHeader(range)) {
		this.updateOnChangeInfo = {
			header: true,
			range: range,
			editableRange: currentRange,
			editableRangeId: currentRangeId,
		};
		return;
	}

	if (this.isRangeStaticFooter(range)) {
		this.updateOnChangeInfo = {
			footer: true,
			range: range,
			editableRange: currentRange,
			editableRangeId: currentRangeId,
		};
		return;
	}

	var currentStaticCodeName = this.getStaticCodeFunctionFromRange(range);
	var currentElementEntry = this.getElementEntryFromRange(range);

	if (currentStaticCodeName !== null) {
		this.updateOnChangeInfo = {
			static: currentStaticCodeName,
			range: range,
			editableRange: currentRange,
			editableRangeId: currentRangeId,
		};
		return;
	}

	if (currentElementEntry !== null) {
		this.updateOnChangeInfo = {
			element: currentElementEntry,
			range: range,
			editableRange: currentRange,
			editableRangeId: currentRangeId,
		};
		return;
	}
};

WidgetCodeEditor.prototype.addEditableRange = function(
	id,
	startingLine,
	endingLine,
) {
	var currentWidget = this;

	if (this.editableRanges[id] !== undefined) {
		return;
	}

	var Range = ace.require('ace/range').Range;
	var editableRange = new Range();
	editableRange.start = this.editor
		.getSession()
		.doc.createAnchor(startingLine, 0);
	editableRange.end = this.editor
		.getSession()
		.doc.createAnchor(endingLine, 0);

	editableRange.start.on('change', function(event) {
		currentWidget.onChange(null);
	});

	editableRange.end.on('change', function(event) {
		currentWidget.onChange(null);
	});

	this.editableRanges[id] = editableRange;

	this.editor.session.addMarker(editableRange, 'ace_editableArea');
};

WidgetCodeEditor.prototype.removeEditableRange = function(id) {
	if (this.editableRanges[id] === undefined) {
		return;
	}

	this.editor.session.removeMarker(this.editableRanges[id]);

	delete this.editableRanges[id];
};

WidgetCodeEditor.prototype.removeAllEditableRanges = function() {
	var ids = Object.keys(this.editableRanges);

	for (var i = 0; i < ids.length; i++) {
		this.removeEditableRange(ids[i]);
	}
};

//THIS SHOULD ALWAYS MATCH THE BACKEND VERSION IN CREATEMAN
WidgetCodeEditor.prototype.createCallbacksCode = function(
	projectData,
	planeData,
) {
	var currentCompiler = this;

	if (this.updateCodeEditorUpdateTimeout !== null) {
		clearTimeout(this.updateCodeEditorUpdateTimeout);
	}

	this.updateCodeEditorUpdateTimeout = setTimeout(function() {
		currentCompiler.updateCodeEditorUpdateTimeout = null;
		currentCompiler.disableOnEditorExec = true;

		//We want to include callbacks header file which will include all our defines and other information.
		currentCompiler.editor.insert(`#include "callbacks.h"\n\n`);

		if (planeData.static !== undefined) {
			var startRow = currentCompiler.editor.getCursorPosition().row;

			currentCompiler.editor.insert('//HEADER START\n');

			currentCompiler.editor.insert(planeData.static.header);

			currentCompiler.editor.insert('\n//HEADER END\n\n');

			var endRow = currentCompiler.editor.getCursorPosition().row - 1;

			currentCompiler.addEditableRange('staticHeader', startRow, endRow);
			currentCompiler.headerRangeId = 'staticHeader';

			for (var codeFunctionName in planeData.static.functions) {
				var currentCodeFunction =
					planeData.static.functions[codeFunctionName];

				var currentReturnType = currentCodeFunction.returnType;

				currentCompiler.editor.insert(
					`${currentCodeFunction.returnType} ${codeFunctionName}(`,
				);

				for (var i = 0; i < currentCodeFunction.arguments.length; i++) {
					var currentArgument = currentCodeFunction.arguments[i];

					currentCompiler.editor.insert(
						`${currentArgument.type} ${currentArgument.name}`,
					);

					//We only want spaces between the arguments
					if (i + 1 < currentCodeFunction.arguments.length) {
						currentCompiler.editor.insert(', ');
					}
				}

				var startRow = currentCompiler.editor.getCursorPosition().row;

				currentCompiler.editor.insert(
					`) {\n${currentCodeFunction.code}\n}\n\n`,
				);

				var endRow = currentCompiler.editor.getCursorPosition().row - 1;

				currentCompiler.addEditableRange(
					codeFunctionName,
					startRow,
					endRow,
				);
				currentCompiler.editableRangeIdToStaticCode[
					codeFunctionName
				] = codeFunctionName;
			}
		}

		for (var i = 0; i < planeData.elements.length; i++) {
			var elementName = planeData.elements[i].name;
			var currentElement = planeData.elements[i];

			if (
				currentElement.variants.indexOf('embedded') < 0 ||
				currentElement.variants.indexOf('abilities') < 0
			) {
				continue;
			}

			for (var j = 0; j < currentElement.abilities.length; j++) {
				var currentAbility = currentElement.abilities[j];
				var currentAbilityName = currentAbility.name;

				currentCompiler.editor.insert(
					`\nATMO_Status_t ${elementName}_${currentAbilityName}(ATMO_Value_t *in, ATMO_Value_t *out)`,
				);

				var startRow = currentCompiler.editor.getCursorPosition().row;

				//TODO: We need to replace a keying value with the declared defines in the function element.
				currentCompiler.editor.insert(
					` {\n${(currentElement.properties.code || {})[
						currentAbilityName
					] || '\treturn;'}\n}\n\n`,
				);

				var endRow = currentCompiler.editor.getCursorPosition().row - 1;

				currentCompiler.addEditableRange(
					elementName + '_' + currentAbilityName,
					startRow,
					endRow,
				);
				currentCompiler.editableRangeIdToElementController[
					elementName + '_' + currentAbilityName
				] = {
					controller: currentCompiler.controller.getElement(
						elementName,
					),
					abilityName: currentAbilityName,
				};
			}
		}

		if (planeData.static !== undefined) {
			var startRow = currentCompiler.editor.getCursorPosition().row;

			currentCompiler.editor.insert('//FOOTER START\n');

			currentCompiler.editor.insert(planeData.static.footer);

			currentCompiler.editor.insert('\n//FOOTER END\n\n');

			var endRow = currentCompiler.editor.getCursorPosition().row - 1;

			currentCompiler.addEditableRange('staticFooter', startRow, endRow);
			currentCompiler.footerRangeId = 'staticFooter';
		}

		currentCompiler.disableOnEditorExec = false;
	}, 30);
};

WidgetCodeEditor.prototype.clearEditor = function() {
	this.disableOnEditorExec = true;
	this.editor.setValue('');
	this.removeAllEditableRanges();
	this.editableRangeIdToStaticCode = {};
	this.editableRangeIdToElementController = {};
	this.headerRangeId = null;
	this.footerRangeId = null;
	this.disableOnEditorExec = false;
};

WidgetCodeEditor.prototype.loadCallbacksCode = function() {
	if (this.controller === null || this.controller === undefined) {
		return;
	}

	this.clearEditor();

	this.createCallbacksCode(null, this.controller.export());
};

WidgetCodeEditor.prototype.loadProjectFile = function(filePath) {};

WidgetCodeEditor.prototype.loadLibraryFile = function(libraryName, filePath) {};

WidgetCodeEditor.prototype.setEditingFile = function(filePath) {
	if (!this.isVisible()) {
		return;
	}

	filePath = filePath || null;

	this.loading = true;

	this.currentlyEditing = null;

	if (filePath === null) {
		this.loadCallbacksCode();
	}

	//We need to clear the undo manager so the user can't blow out their entire file on acceident.
	this.editor.getSession().setUndoManager(new ace.UndoManager());

	this.loading = false;
};

WidgetCodeEditor.prototype.scrollToEditableRange = function(editableRangeId) {
	if (this.editableRanges[editableRangeId] === undefined) {
		return;
	}

	var editableRange = this.editableRanges[editableRangeId];

	this.editor.scrollToLine(
		editableRange.start.row + 1,
		true,
		true,
		function() {},
	);
	this.editor.gotoLine(editableRange.start.row + 1, 0, true);
};

WidgetCodeEditor.prototype.scrollToElementAbility = function(
	elementName,
	abilityName,
) {
	if (elementName === undefined || elementName === null) {
		return;
	}

	if (abilityName === undefined || abilityName === null) {
		var editableRangeIds = Object.keys(this.editableRanges);

		for (var i = 0; i < editableRangeIds.length; i++) {
			if (editableRangeIds[i].startsWith(elementName + '_')) {
				abilityName = editableRangeIds[i].replace(
					elementName + '_',
					'',
				);
				break;
			}
		}
	}

	this.scrollToEditableRange(elementName + '_' + abilityName);
	return;
};

WidgetCodeEditor.prototype.scrollToElement = function(elementName) {
	this.scrollToElementAbility(elementName, null);
};

WidgetCodeEditor.prototype.language = {
	'en-US': {
		name: 'WidgetCodeEditor',
		tooltip: 'Show/Hide Code Editor',
	},
};

WidgetCodeEditor.prototype.createmanLangauge = {
	default: {
		callbacksHeader: 'atmosphere_callbacks.h',
		callbacksSource: 'atmosphere_callbacks.c',
		abilityHandlerHeader: 'atmosphere_abilityHandler.h',
		abilityHandlerSource: 'atmosphere_abilityHandler.c',
		triggerHandlerHeader: 'atmosphere_triggerHandler.h',
		triggerHandlerSource: 'atmosphere_triggerHandler.c',
		platformHeader: 'atmosphere_platform.h',
		propertiesHeader: 'atmosphere_properties.h',
		variablesHeader: 'atmosphere_variables.h',
		elementSetupHeader: 'atmosphere_elementSetup.h',
		elementSetupSource: 'atmosphere_elementSetup.c',
		intervalHandlerHeader: 'atmosphere_intervalHandler.h',
		intervalHandlerSource: 'atmosphere_intervalHandler.c',
	},
};
