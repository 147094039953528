function WidgetDonutChart(id, api, parent, options) {
	var currentWidget = this;

	WidgetDashboardBase.call(this, id, api, parent, options);

	this.config = {
		storageName: null,
		variable: null,
		title: getLanguageTag(this.constructor, 'name'),
		updateTime: 60000,
	};

	this._updateInterval = null;
	this._showingSettings = false;

	this.donutChartContainerId = this.generateChildId('donutChartContainer');
}

WidgetDonutChart.prototype = Object.create(WidgetDashboardBase.prototype);
WidgetDonutChart.prototype.constructor = WidgetDonutChart;

WidgetDonutChart.prototype.showSettings = function(callback) {
	callback = callback || function() {};

	var currentWidget = this;

	this.getMainContainer().setModalWidget(
		WidgetSettingsForm,
		{
			fields: [
				{
					name: 'title',
					type: 'text',
					label: getLanguageTag(this.constructor, 'title'),
					value: this.config.title,
				},

				{
					name: 'updateTime',
					type: 'select',
					label: this.getLanguageTag('updateTime'),
					options: [
						{ value: 60000, label: this.getLanguageTag('1Minute') },
						{
							value: 300000,
							label: this.getLanguageTag('5Minutes'),
						},
						{
							value: 600000,
							label: this.getLanguageTag('10Minutes'),
						},
						{
							value: 900000,
							label: this.getLanguageTag('15Minutes'),
						},
						{
							value: 1800000,
							label: this.getLanguageTag('30Minutes'),
						},
						{ value: 3600000, label: this.getLanguageTag('1Hour') },
						{
							value: 7200000,
							label: this.getLanguageTag('2Hours'),
						},
						{
							value: 14400000,
							label: this.getLanguageTag('4Hours'),
						},
						{
							value: 21600000,
							label: this.getLanguageTag('6Hours'),
						},
						{
							value: 28800000,
							label: this.getLanguageTag('8Hours'),
						},
						{
							value: 43200000,
							label: this.getLanguageTag('12Hours'),
						},
					],
					value: this.config.updateTime,
				},

				{
					name: 'variable',
					type: 'deviceVariableSelect',
					label: getLanguageTag(this.constructor, 'variable'),
					value: {
						deviceId: this.config.deviceId,
						storageName: this.config.storageName,
						variables: { value: this.config.variable },
					},
					options: {
						variables: [
							{
								name: 'value',
								label: getLanguageTag(
									this.constructor,
									'value',
								),
							},
						],
					},
				},
			],
		},
		function(err, settingsWidget) {
			settingsWidget.setTitle(
				getLanguageTag(
					currentWidget.constructor,
					'configureDonutChart',
				),
			);

			settingsWidget.addEventListener('dismissed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;
			});

			settingsWidget.addEventListener('confirmed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;

				values = this.getValues();
				values.variable = values.variable || {};

				var config = {
					deviceId: (values.variable.deviceData || {}).id || null,
					title: values.title,
					storageName: values.variable.storageName || null,
					variable: (values.variable.variables || {}).value || null,
					updateTime: values.updateTime || 60000,
				};

				currentWidget.setConfiguration(config, function() {
					this.event('configurationSet', {
						widget: this,
						configuration: this.config,
					});
				});
			});

			currentWidget._showingSettings = true;
			this.showModal();

			callback.call(this, false);
			return;
		},
	);
};

WidgetDonutChart.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.renderTemplate(
		{
			donutChartContainerId: this.donutChartContainerId,
		},
		WidgetDonutChart.name,
	);

	this.eChart = echarts.init(
		document.getElementById(this.donutChartContainerId),
	);

	this.update(function(err) {
		this._updateInterval = setInterval(function() {
			
			if(isActive()) {
				currentWidget.update();
			}
		}, this.config.updateTime || 60000);

		WidgetDashboardBase.prototype.initialize.call(this, callback);
	});
};

WidgetDonutChart.prototype.remove = function(callback) {
	if (this._updateInterval) {
		clearInterval(this._updateInterval);
	}

	if (this._showingSettings) {
		this.getMainContainer().hideModal();
	}

	WidgetDashboardBase.prototype.remove.call(this, callback);
};

WidgetDonutChart.prototype.DONUT_COLOR_WHEEL = [
	getComputedStyle(window.document.body).getPropertyValue('--red'),
	getComputedStyle(window.document.body).getPropertyValue('--orange'),
	getComputedStyle(window.document.body).getPropertyValue('--yellow'),
	getComputedStyle(window.document.body).getPropertyValue('--green'),
	getComputedStyle(window.document.body).getPropertyValue('--blue'),
	getComputedStyle(window.document.body).getPropertyValue('--purple'),
	getComputedStyle(window.document.body).getPropertyValue('--pink'),
	getComputedStyle(window.document.body).getPropertyValue('--gray'),
	getComputedStyle(window.document.body).getPropertyValue('--teal'),
];

WidgetDonutChart.prototype.setChart = function(chartData) {
	this.eChart.setOption({
		series: [
			{
				name: '',
				type: 'pie',
				radius: ['50%', '70%'],
				avoidLabelOverlap: false,
				label: {
					normal: {
						show: false,
						position: 'center',
					},
					emphasis: {
						show: true,
						formatter: '{b}\n{d}%',
						textStyle: {
							fontSize: '14',
							fontWeight: 'bold',
						},
					},
				},
				labelLine: {
					normal: {
						show: false,
					},
				},
				data: chartData,
				color: this.DONUT_COLOR_WHEEL,
			},
		],
	});
};

// Gets 1 page of 10000 points
// returned via the provided query to the v2 storage API.
WidgetDonutChart.prototype.dataBy = async function(query) {
	const api = this.getApiV2();
	const response = await api.apis.data.getDeviceData(query);
	return response.data;
};

WidgetDonutChart.prototype.update = function(callback) {
	callback = callback || function() {};
	const currentWidget = this;

	// Check for mandatory API params
	if (!this.config.deviceId || !this.config.storageName) {
		callback.call(this, false);
		return;
	}

	// set the panel title
	this.setTitle(this.config.title);

	// build the query
	const query = {
		id: this.config.deviceId,
		elementName: this.config.storageName,
		limit: 10000,
	};

	this.dataBy(query)
		.then((storedValues) => {
			const data = storedValues.map((storedValue) => {
				const obj = storedValue.payload;
				// UI expects _timestamp field
				obj._timestamp = storedValue.createdAt;
				return obj;
			});

			var chartData = [];
			var dataSets = {};

			for (var i = 0; i < data.length; i++) {
				var value = data[i][currentWidget.config.variable];
				if (value === undefined) {
					continue;
				}

				if (dataSets[value] === undefined) {
					dataSets[value] = 0;
				}

				dataSets[value] += 1;
			}

			for (var k in dataSets) {
				chartData.push({ value: dataSets[k], name: k });
			}

			currentWidget.setChart(chartData);

			// signal end of update
			callback.call(currentWidget, false);
		})
		.catch((error) => {
			console.warn(
				`[${currentWidget.constructor.name}] Error while calling update!`,
			);
			console.error(error);
			callback.call(currentWidget, error);
			return;
		});
};

WidgetDonutChart.prototype.setConfiguration = function(config, callback) {
	var currentWidget = this;

	callback = callback || function() {};

	this.config = {
		deviceId: config.deviceId || null,
		storageName: config.storageName || null,
		variable: config.variable || null,
		title: config.title || getLanguageTag(this.constructor, 'name'),
		updateTime: config.updateTime || 60000,
	};

	if (this._updateInterval) {
		clearInterval(this._updateInterval);
	}

	this.update(function(err) {
		if (currentWidget.config.updateTime < 60000) {
			currentWidget.config.updateTime = 60000;
		}

		this._updateInterval = setInterval(function() {
			
			if(isActive()) {
				currentWidget.update(function() {});
			}
			
		}, this.config.updateTime || 60000);
		callback.call(this, err);
	});
};

WidgetDonutChart.prototype.getConfiguration = function() {
	return this.config;
};

WidgetDonutChart.prototype.PACKERY_SIZE = 'WidgetDashboard_Container_SxS';

WidgetDonutChart.prototype.language = deepAssign(
	{},
	WidgetDashboardBase.prototype.language,
	{
		'en-US': {
			name: 'Donut Chart',
			title: 'Title',
			selectADevice: 'Select a Device',
			variable: 'Data Source',
			value: 'Select Value',
			configureDonutChart: 'Configure Donut Chart',
			updateTime: 'Update Interval',
			'1Minute': '1 Minute',
			'5Minutes': '5 Minutes',
			'10Minutes': '10 Minutes',
			'15Minutes': '15 Minutes',
			'30Minutes': '30 Minutes',
			'1Hour': '1 Hour',
			'2Hours': '2 Hours',
			'4Hours': '4 Hours',
			'6Hours': '6 Hours',
			'8Hours': '8 Hours',
			'12Hours': '12 Hours',
		},

		'en-UK': {
			name: 'Doughnut Chart',
			configureDonutChart: 'Configure Doughnut Chart',
			updateTime: 'Update Interval',
			'1Minute': '1 Minute',
			'5Minutes': '5 Minutes',
			'10Minutes': '10 Minutes',
			'15Minutes': '15 Minutes',
			'30Minutes': '30 Minutes',
			'1Hour': '1 Hour',
			'2Hours': '2 Hours',
			'4Hours': '4 Hours',
			'6Hours': '6 Hours',
			'8Hours': '8 Hours',
			'12Hours': '12 Hours',
		},
	},
);

WidgetDonutChart.prototype.$_$ = function(done) {
	this.$_SetupWidgetTest(function(err, currentWidget) {
		this.setChart([
			{ value: 10, name: '10' },
			{ value: 20, name: '20' },
			{ value: 30, name: '30' },
			{ value: 40, name: '40' },
		]);

		WidgetDashboardBase.prototype.$_$.call(this, done);
	});
};
