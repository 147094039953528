function WidgetAppViewEditor(id, api, parent, options) {
	var currentWidget = this;

	WidgetBase.call(this, id, api, parent, options);

	this._planeEditor = null;
	this._planeController = null;
	this._elementBlurCallback = null;
	this._elementIconPointerDownCallback = null;
	this._elementAddedCallback = null;
	this._planeImportedCallback = null;
	this._elementRemovedCallback = null;
	this._focusedElementName = null;
	this._focusedElementController = null;
	this._focusedElementPropertySetCallback = null;

	this.appViewContainerId = this.generateChildId('appViewContainer');
	this.toBackButtonId = this.generateChildId('toBackButton');
	this.toFrontButtonId = this.generateChildId('toFrontButton');
	this.snapButtonId = this.generateChildId('snapButton');
	this.gridSnapButtonId = this.generateChildId('gridSnapButton');
	this.smallerGridSizeButtonId = this.generateChildId(
		'smallerGridSizeButton',
	);
	this.largerGridSizeButtonId = this.generateChildId('largerGridSizeButton');
	this.showGridButtonId = this.generateChildId('showGridButton');
	this.toggleExecuteAppButtonId = this.generateChildId(
		'toggleExecuteAppButton',
	);
	this.currentLayoutNameId = this.generateChildId('layoutName');
	this.layoutSelectorId = this.generateChildId('layoutSelector');
	this.addLayoutButtonId = this.generateChildId('addLayoutButton');
	this.xInputId = this.generateChildId('xInput');
	this.yInputId = this.generateChildId('yInput');
	this.widthInputId = this.generateChildId('widthInput');
	this.heightInputId = this.generateChildId('heightInput');

	this.iconPaths = {
		toBack: './Resources/icons/LayoutSendToBack.svg',
		toFront: './Resources/icons/LayoutBringToFront.svg',
		align: './Resources/icons/LayoutAlign.svg',
		snap: './Resources/icons/LayoutSnapToGrid.svg',
		decrease: './Resources/icons/LayoutDecreaseGrid.svg',
		increase: './Resources/icons/LayoutIncreaseGrid.svg',
		execute: './Resources/icons/LayoutExecuteApp.svg',
		addLayout: './Resources/icons/LayoutAddLayout.svg',
	};

	this.context = {
		iconPaths: this.iconPaths,
		appViewContainerId: this.appViewContainerId,
		toBackButtonId: this.toBackButtonId,
		toFrontButtonId: this.toFrontButtonId,
		snapButtonId: this.snapButtonId,
		gridSnapButtonId: this.gridSnapButtonId,
		smallerGridSizeButtonId: this.smallerGridSizeButtonId,
		largerGridSizeButtonId: this.largerGridSizeButtonId,
		toggleExecuteAppButtonId: this.toggleExecuteAppButtonId,
		currentLayoutNameId: this.currentLayoutNameId,
		layoutSelectorId: this.layoutSelectorId,
		addLayoutButtonId: this.addLayoutButtonId,
		xInputId: this.xInputId,
		yInputId: this.yInputId,
		widthInputId: this.widthInputId,
		heightInputId: this.heightInputId,
		xLabel: getLanguageTag(WidgetAppViewEditor, 'xLabel'),
		yLabel: getLanguageTag(WidgetAppViewEditor, 'yLabel'),
		widthLabel: getLanguageTag(WidgetAppViewEditor, 'widthLabel'),
		heightLabel: getLanguageTag(WidgetAppViewEditor, 'heightLabel'),
	};

	this.context.language = passLanguageTagsToHandlebarsContext(
		this.language,
		this.context,
	);

	this.renderTemplate(this.context, WidgetAppViewEditor.name);

	this.appViewContainer = $('#' + this.appViewContainerId);
	this.toBackButton = $('#' + this.toBackButtonId);
	this.toFrontButton = $('#' + this.toFrontButtonId);
	this.snapButton = $('#' + this.snapButtonId);
	this.gridSnapButton = $('#' + this.gridSnapButtonId);
	this.smallerGridSizeButton = $('#' + this.smallerGridSizeButtonId);
	this.largerGridSizeButton = $('#' + this.largerGridSizeButtonId);
	this.toggleExecuteAppButton = $('#' + this.toggleExecuteAppButtonId);
	this.currentLayoutName = $('#' + this.currentLayoutNameId);
	this.layoutSelector = $('#' + this.layoutSelectorId);
	this.addLayoutButton = $('#' + this.addLayoutButtonId);
	this.xInput = $('#' + this.xInputId);
	this.yInput = $('#' + this.yInputId);
	this.widthInput = $('#' + this.widthInputId);
	this.heightInput = $('#' + this.heightInputId);
}

WidgetAppViewEditor.prototype = Object.create(WidgetBase.prototype);
WidgetAppViewEditor.prototype.constructor = WidgetAppViewEditor;

WidgetAppViewEditor.prototype.EXTRA_TOOL_ICON =
	'./Resources/icons/InterfaceBuilder.svg';
WidgetAppViewEditor.prototype.DEFAULT_HEIGHT = 560;
WidgetAppViewEditor.prototype.DEFAULT_WIDTH = 320;

WidgetAppViewEditor.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.addChildWidget(WidgetAppView, this.appViewContainerId, {}, function(
		err,
		widgetAppView,
	) {
		this.widgetAppView = widgetAppView;
		this.appView = this.widgetAppView.appView;

		this.widgetAppView.canvasContainer.addClass(
			'WidgetAppViewEditor-canvasContainer',
		);

		this.addLayoutButton.click(function() {
			currentWidget.showAddLayoutDialog();
		});

		this.layoutSelector.change(function() {
			var widthHeightSplit = $('#' + this.id)
				.val()
				.split(':');
			var width = parseInt(widthHeightSplit[0]);
			var height = parseInt(widthHeightSplit[1]);

			currentWidget.setLayout(width, height);
			currentWidget.updateLayoutInputControls();
		});

		this.widthInput.change(function() {
			currentWidget.onLayoutInputControlsChanged();
		});

		this.heightInput.change(function() {
			currentWidget.onLayoutInputControlsChanged();
		});

		this.xInput.change(function() {
			currentWidget.onLayoutInputControlsChanged();
		});

		this.yInput.change(function() {
			currentWidget.onLayoutInputControlsChanged();
		});

		this.toBackButton.click(function() {
			currentWidget.onToBackButton();
		});

		this.toFrontButton.click(function() {
			currentWidget.onToFrontButton();
		});

		this.snapButton.click(function() {
			currentWidget.onSnapButtonClick();
		});

		this.gridSnapButton.click(function() {
			currentWidget.onGridSnapButtonClick();
		});

		this.smallerGridSizeButton.click(function() {
			currentWidget.onSmallerGridSizeButtonClick();
		});

		this.largerGridSizeButton.click(function() {
			currentWidget.onLargerGridSizeButtonClick();
		});

		this.toggleExecuteAppButton.click(function() {
			currentWidget.onToggleExecuteAppButtonClick();
		});

		this.appView.root.addEventListener('keyup', function(e) {
			return currentWidget.onKeyUp(e);
		});

		this.appView.addEventListener('triggerEventArgumentEvalError', function(
			data,
		) {
			currentWidget.onTriggerEventArgumentEvalError(data);
		});

		this.appViewContainer.on('dragover', function(event) {
			event.preventDefault();
			event.stopPropagation();
			$(this).addClass('WidgetAppViewEditor-Dragging');
		});

		this.appViewContainer.on('dragleave', function(event) {
			event.preventDefault();
			event.stopPropagation();
			$(this).removeClass('WidgetAppViewEditor-Dragging');
		});

		this.appViewContainer.on('drop', function(e) {
			e.preventDefault();
			e.stopPropagation();
			currentWidget.onDrop(e);
			$(this).removeClass('WidgetAppViewEditor-Dragging');
		});

		this.fillContainer = false;
		this.fillLocationContainer = false;

		this.setEditable(true);

		this.appView.addEventListener('elementFocus', function(data) {
			currentWidget.setFocusedElement(data.name);
			currentWidget._planeEditor.onElementFocus(data);
		});

		WidgetBase.prototype.initialize.call(this, callback);
		return;
	});
};

WidgetAppViewEditor.prototype.addEventListener = function(event, callback) {
	var currentWidget = this;

	this.appView.addEventListener(event, function(data) {
		callback.call(currentWidget, data);
		return;
	});
};

WidgetAppViewEditor.prototype.onDrop = function(e) {
	var currentWidget = this;

	e.preventDefault();
	// If dropped items aren't files, reject them
	var dt = e.originalEvent.dataTransfer;
	if (dt.items) {
		// Use DataTransferItemList interface to access the file(s)
		for (var i = 0; i < dt.items.length; i++) {
			if (dt.items[i].kind == 'file') {
				var f = dt.items[i].getAsFile();

				if (f.type.startsWith('image')) {
					var reader = new FileReader();

					reader.onload = function(image) {
						var imageData = image.target.result;

						currentWidget.appView.event('imagedrop', {
							image: imageData,
							layoutX: e.originalEvent.layerX,
							layoutY: e.originalEvent.layerY,
						});
						return;
					};

					reader.readAsDataURL(f);
				}
			}
		}
	} else {
		// Use DataTransfer interface to access the file(s)
		for (var i = 0; i < dt.files.length; i++) {
			var reader = new FileReader();

			reader.onload = function(e) {};

			reader.readAsText(f);
		}
	}
};

WidgetAppViewEditor.prototype.setEditable = function(editable) {
	this.appView.setEditable(editable);

	if (!editable) {
		this._planeController.removeAllElementsTriggerEventErrors();
	}
};

WidgetAppViewEditor.prototype.onTriggerEventArgumentEvalError = function(data) {
	var currentElementController = this._planeController.getElement(
		data.element._name,
	);

	if (currentElementController !== null) {
		currentElementController.addTriggerEventError(
			data.triggerName,
			data.eventNumber,
			data.mappingValue,
			data.error,
			data,
		);
	}
};

WidgetAppViewEditor.prototype.onKeyUp = function(e) {
	if (this.appView.editable === false) {
		return;
	}

	if (this._focusedElementName !== null) {
		if (e.key === 'Delete') {
			this._planeController.removeElement(this._focusedElementName);
			this._focusedElementName = null;
			this._focusedElementController = null;
		}
	}
};

WidgetAppViewEditor.prototype.showAddLayoutDialog = function(callback) {
	callback = callback || function() {};

	var currentWidget = this;

	this.getMainContainer().setModalWidget(
		WidgetAppViewLayoutSelection,
		{},
		function(err, appViewLayoutSelectionWidget) {
			if (err) {
				callback.call(currentWidget, err);
				return;
			}

			appViewLayoutSelectionWidget.addEventListener('confirmed', function(
				data,
			) {
				currentWidget.setLayouts(data.layouts, function() {
					currentWidget.getMainContainer().hideModal();
				});
			});

			appViewLayoutSelectionWidget.addEventListener(
				'dismissed',
				function() {
					currentWidget.getMainContainer().hideModal();
				},
			);

			appViewLayoutSelectionWidget.setPlaneController(
				currentWidget._planeController,
				function(err) {
					currentWidget.getMainContainer().showModal();

					callback.call(currentWidget, false);
				},
			);
		},
	);
};

WidgetAppViewEditor.prototype.attachPlaneEditor = function(planeEditor) {
	var currentWidget = this;

	if (this._planeEditor !== null) {
		this._planeEditor.designerView.removeEventListener(
			'iconpointerdown',
			this._elementIconPointerDownCallback,
		);
		this._planeEditor.designerView.removeEventListener(
			'iconblur',
			this._elementBlurCallback,
		);
	}

	this._planeEditor = planeEditor;

	this._elementIconPointerDownCallback = this._planeEditor.designerView.addEventListener(
		'iconpointerdown',
		function(data) {
			currentWidget.setFocusedElement(data.name);
		},
	);
};

WidgetAppViewEditor.prototype.onLayoutInputControlsChanged = function() {
	if (this._focusedElementController === null) {
		return;
	}

	var width = parseInt(this.widthInput.val());
	var height = parseInt(this.heightInput.val());
	var x = parseInt(this.xInput.val());
	var y = parseInt(this.yInput.val());

	var currentWidth = this.appView.width;
	var currentHeight = this.appView.height;

	var layouts = this._focusedElementController.getPropertyValue('layouts');

	if (layouts === null) {
		this.xInput.val('');
		this.yInput.val('');
		this.widthInput.val('');
		this.heightInput.val('');

		return;
	}

	var currentLayoutData = layouts[currentWidth][currentHeight];

	if (isNaN(width)) {
		width = currentLayoutData.width;
	}

	if (isNaN(height)) {
		height = currentLayoutData.height;
	}

	if (isNaN(x)) {
		x = currentLayout.x;
	}

	if (isNaN(y)) {
		y = currentLayout.y;
	}

	currentLayoutData.width = width;
	currentLayoutData.height = height;
	currentLayoutData.x = x;
	currentLayoutData.y = y;

	this.appView.setDrawGuides(null, null);

	this._focusedElementController.setProperty('layouts', layouts);
};

WidgetAppViewEditor.prototype.updateLayoutInputControls = function() {
	var currentWidth = this.appView.width;
	var currentHeight = this.appView.height;

	if (this._focusedElementController === null) {
		this.xInput.val('');
		this.yInput.val('');
		this.widthInput.val('');
		this.heightInput.val('');

		return;
	}

	var layouts = this._focusedElementController.getPropertyValue('layouts');

	if (layouts === null) {
		this.xInput.val('');
		this.yInput.val('');
		this.widthInput.val('');
		this.heightInput.val('');

		return;
	}

	var currentLayoutData = layouts[currentWidth][currentHeight];

	this.xInput.val(currentLayoutData.x);
	this.yInput.val(currentLayoutData.y);
	this.widthInput.val(currentLayoutData.width);
	this.heightInput.val(currentLayoutData.height);
};

WidgetAppViewEditor.prototype.setFocusedElement = function(elementName) {
	var currentWidget = this;

	elementName = elementName || null;

	if (this._focusedElementPropertySetCallback !== null) {
		this._focusedElementController.removeEventListener(
			'propertySet',
			this._focusedElementPropertySetCallback,
		);

		this._focusedElementPropertySetCallback = null;
		this._focusedElementController = null;
	}

	this._focusedElementName = elementName;
	this._focusedElementController = this._planeController.getElement(
		this._focusedElementName,
	);

	this.appView.setDrawGuides(null, null);

	if (this._focusedElementController !== null) {
		this._focusedElementPropertySetCallback = this._focusedElementController.addEventListener(
			'propertySet',
			function() {
				currentWidget.updateLayoutInputControls();
			},
		);
	}

	this.updateLayoutInputControls();

	return;
};

WidgetAppViewEditor.prototype.addEventListener = function(event, callback) {
	var currentWidget = this;

	return this.appView.addEventListener(event, function(data) {
		callback.call(currentWidget, data);
		return;
	});
};

WidgetAppViewEditor.prototype.showLayoutAddingDialog = function(callback) {};

WidgetAppViewEditor.prototype.setLayout = function(width, height) {
	this.widgetAppView.setLayout(width, height);

	this.layoutSelector.val(width + ':' + height);
};

WidgetAppViewEditor.prototype.generateDefaultLayout = function() {
	var data = {};
	data[WidgetAppViewEditor.prototype.DEFAULT_WIDTH] = {};
	data[WidgetAppViewEditor.prototype.DEFAULT_WIDTH][
		WidgetAppViewEditor.prototype.DEFAULT_HEIGHT
	] = true;

	return data;
};

WidgetAppViewEditor.prototype.getAvailableLayouts = function(callback) {
	var currentLayouts = {};

	var appViewLayouts = this._planeController.getMetaValue('appViewLayouts');

	for (var width in appViewLayouts) {
		for (var height in appViewLayouts[width]) {
			if (currentLayouts[width] === undefined) {
				currentLayouts[width] = {};
			}

			currentLayouts[width][height] = true;
		}
	}

	for (var k in this.appView._elements) {
		var currentElementController = this.appView._elements[k]._controller;
		var layouts = currentElementController.getPropertyValue('layouts');

		for (var width in layouts) {
			for (var height in layouts[width]) {
				if (currentLayouts[width] === undefined) {
					currentLayouts[width] = {};
				}

				if (currentLayouts[width][height] === undefined) {
					currentLayouts[width][height] = true;
				}
			}
		}
	}

	this._planeController.setMetaValue('appViewLayouts', currentLayouts);

	callback.call(this, false, currentLayouts);
	return;
};

WidgetAppViewEditor.prototype.getAvailableLayoutList = function(callback) {
	var currentLayouts = {};
	var availableLayouts = [];

	var appViewLayouts = this._planeController.getMetaValue('appViewLayouts');

	for (var width in appViewLayouts) {
		for (var height in appViewLayouts[width]) {
			availableLayouts.push({ width: width, height: height });

			if (currentLayouts[width] === undefined) {
				currentLayouts[width] = {};
			}

			currentLayouts[width][height] = true;
		}
	}

	for (var k in this.appView._elements) {
		var currentElementController = this.appView._elements[k]._controller;
		var layouts = currentElementController.getPropertyValue('layouts');

		for (var width in layouts) {
			for (var height in layouts[width]) {
				if (currentLayouts[width] === undefined) {
					currentLayouts[width] = {};
				}

				if (currentLayouts[width][height] === undefined) {
					availableLayouts.push({ width: width, height: height });
					currentLayouts[width][height] = true;
				}
			}
		}
	}

	this._planeController.setMetaValue('appViewLayouts', currentLayouts);

	callback.call(this, false, availableLayouts);
	return;
};

WidgetAppViewEditor.prototype.updateLayoutSelector = function(callback) {
	this.layoutSelector.html('');

	this.getAvailableLayoutList(function(err, availableLayouts) {
		for (var i = 0; i < availableLayouts.length; i++) {
			this.layoutSelector.append(
				$('<option></option>')
					.attr(
						'value',
						availableLayouts[i].width +
							':' +
							availableLayouts[i].height,
					)
					.text(
						availableLayouts[i].width +
							'x' +
							availableLayouts[i].height,
					),
			);
		}

		var currentWidth = this.appView.width;
		var currentHeight = this.appView.height;

		this.layoutSelector.val(currentWidth + ':' + currentHeight);

		callback.call(this, false);
		return;
	});
};

WidgetAppViewEditor.prototype.getClosestLayout = function(
	width,
	height,
	callback,
) {
	this.getAvailableLayoutList(function(err, layoutList) {
		var bestLayout = null;

		for (var i = 0; i < layoutList.length; i++) {
			if (bestLayout === null) {
				bestLayout = layoutList[i];
				continue;
			}

			var bestLayoutWidthDelta = bestLayout.width - width;
			var bestLayoutHeightDelta = bestLayout.height - height;

			var currentLayoutWidthDelta = layoutList[i].width - width;
			var currentLayoutHeightDelta = layoutList[i].height - height;

			var bestLayoutDelta = bestLayoutWidthDelta + bestLayoutHeightDelta;
			var currentLayoutDelta =
				currentLayoutWidthDelta + currentLayoutHeightDelta;

			if (Math.abs(currentLayoutDelta) < Math.abs(bestLayout)) {
				bestLayout = layoutList[i];
			}
		}

		callback.call(this, false, bestLayout);
		return;
	});
};

WidgetAppViewEditor.prototype.setAddedElementLayoutsProperty = function(
	elementName,
) {
	this._planeController.getElement(elementName);
};

WidgetAppViewEditor.prototype.setLayouts = function(layouts, callback) {
	var currentWidget = this;

	var copyLayouts = layouts.slice();

	var appViewLayouts = this._planeController.getMetaValue('appViewLayouts');

	function _setLayoutsHelper() {
		if (copyLayouts.length <= 0) {
			var layoutMappings = {};

			for (var i = 0; i < layouts.length; i++) {
				if (!layoutMappings[layouts[i].width]) {
					layoutMappings[layouts[i].width] = {};
				}

				layoutMappings[layouts[i].width][layouts[i].height] = true;
			}

			for (var width in appViewLayouts) {
				for (var height in appViewLayouts[width]) {
					if (
						!(
							layoutMappings[width] &&
							layoutMappings[width][height]
						)
					) {
						currentWidget.removeLayout(width, height, function(
							err,
						) {
							if (err) {
								currentWidget
									.getMainContainer()
									.showPopupErrorMessage(
										getLanguageTag(
											currentWidget.constructor,
											err.type,
										),
									);
							}
						});
					}
				}
			}

			callback.call(currentWidget, false);
			return;
		}

		var currentLayout = copyLayouts.pop();

		if (
			!(
				appViewLayouts[currentLayout.width] &&
				appViewLayouts[currentLayout.width][currentLayout.height]
			)
		) {
			currentWidget.addLayout(
				currentLayout.width,
				currentLayout.height,
				function(err) {
					_setLayoutsHelper();
				},
			);
		} else {
			_setLayoutsHelper();
		}
	}

	_setLayoutsHelper();
};

WidgetAppViewEditor.prototype.addLayout = function(width, height, callback) {
	this.getClosestLayout(width, height, function(err, bestLayout) {
		var appViewLayouts = this._planeController.getMetaValue(
			'appViewLayouts',
		);

		if (appViewLayouts === null || appViewLayouts === undefined) {
			appViewLayouts = {};
		}

		if (appViewLayouts[width] === undefined) {
			appViewLayouts[width] = {};
		}

		appViewLayouts[width][height] = true;

		this._planeController.setMetaValue('appViewLayouts', appViewLayouts);

		for (var k in this.appView._elements) {
			var currentElementController = this.appView._elements[k]
				._controller;
			var layouts = currentElementController.getPropertyValue('layouts');

			if (layouts === undefined || layouts === null) {
				continue;
			}

			var nearestLayout = {
				width: 100,
				height: 100,
				x: 0,
				y: 0,
				rotation: 0,
			};

			if (
				layouts[bestLayout.width] !== undefined &&
				layouts[bestLayout.width][bestLayout.height] !== undefined
			) {
				nearestLayout = Object.assign(
					nearestLayout,
					layouts[bestLayout.width][bestLayout.height],
				);
			}

			if (layouts[width] === undefined) {
				layouts[width] = {};
			}

			if (nearestLayout.x + nearestLayout.width > width) {
				nearestLayout.x = width - nearestLayout.width;
			}

			if (nearestLayout.y + nearestLayout.height > height) {
				nearestLayout.y = height - nearestLayout.height;
			}

			if (layouts[width][height] === undefined) {
				layouts[width][height] = {
					width: nearestLayout.width,
					height: nearestLayout.height,
					x: nearestLayout.x,
					y: nearestLayout.y,
					rotation: nearestLayout.rotation,
					propertyOverlay: {},
				};
			}

			currentElementController.setProperty('layouts', layouts);
		}

		this.updateLayoutSelector(function() {
			this.setLayout(width, height);

			callback.call(this, false);
		});
	});
};

WidgetAppViewEditor.prototype.removeLayout = function(width, height, callback) {
	this.getAvailableLayoutList(function(err, layoutList) {
		if (layoutList.length === 1) {
			callback.call(this, { type: 'mustHaveOneLayout' });
			return;
		}

		var appViewLayouts = this._planeController.getMetaValue(
			'appViewLayouts',
		);

		if (appViewLayouts[width] !== undefined) {
			delete appViewLayouts[width][height];

			if (Object.keys(appViewLayouts[width]) <= 0) {
				delete appViewLayouts[width];
			}
		}

		this._planeController.setMetaValue('appViewLayouts', appViewLayouts);

		if (appViewLayouts === null || appViewLayouts === undefined) {
			appViewLayouts = {};
		}

		if (appViewLayouts[width] === undefined) {
			appViewLayouts[width] = {};
		}

		for (var k in this.appView._elements) {
			var currentElementController = this.appView._elements[k]
				._controller;
			var layouts = currentElementController.getPropertyValue('layouts');

			if (
				layouts !== null &&
				layouts[width] !== undefined &&
				layouts[width][height] !== undefined
			) {
				delete layouts[width][height];

				if (Object.keys(layouts[width]).length <= 0) {
					delete layouts[width];
				}
			}

			currentElementController.setProperty('layouts', layouts);
		}

		this.getAvailableLayouts(function(err, layouts) {
			this.getAvailableLayoutList(function(err, layoutList) {
				var currentWidth = this.appView.width;
				var currentHeight = this.appView.height;

				if (
					layouts[currentWidth] === undefined ||
					layouts[currentWidth][currentHeight] === undefined
				) {
					this.setLayout(layoutList[0].width, layoutList[0].height);
				}

				this.updateLayoutSelector(callback);
			});
		});
	});
};

WidgetAppViewEditor.prototype.attachController = function(
	controller,
	callback,
) {
	var currentWidget = this;

	if (this._planeController !== null) {
		this._planeController.removeEventListener(
			'elementAdded',
			this._elementAddedCallback,
		);
		this._planeController.removeEventListener(
			'elementRemoved',
			this._elementRemovedCallback,
		);
		this._planeController.removeEventListener(
			'imported',
			this._planeImportedCallback,
		);

		this._planeController = null;
		this._elementAddedCallback = null;
		this._elementRemovedCallback = null;
	}

	this._planeController = controller;
	this._elementAddedCallback = this._planeController.addEventListener(
		'elementAdded',
		function(data) {
			currentWidget.onElementAdded(data);
		},
	);

	this._elementRemovedCallback = this._planeController.addEventListener(
		'elementRemoved',
		function(data) {
			currentWidget.onElementRemoved(data);
		},
	);

	this._planeImportedCallback = this._planeController.addEventListener(
		'imported',
		function(data) {
			currentWidget.onPlaneImported();
		},
	);

	this.appView.attachController(controller, function(err) {
		currentWidget.updateLayoutSelector(callback);
	});
};

WidgetAppViewEditor.prototype.excludeFromWizard = true;
WidgetAppViewEditor.prototype.ICON = 'fa-mobile-phone';

WidgetAppViewEditor.prototype.onPlaneImported = function(data) {
	var appViewLayouts = this._planeController.getMetaValue('appViewLayouts');

	if (appViewLayouts === null || appViewLayouts === undefined) {
		this._planeController.setMetaValue(
			'appViewLayouts',
			this.generateDefaultLayout(),
		);
	}

	this.getAvailableLayoutList(function(err, layoutList) {
		if (layoutList.length === 0) {
			this.addLayout(
				WidgetAppViewEditor.prototype.DEFAULT_WIDTH,
				WidgetAppViewEditor.prototype.DEFAULT_HEIGHT,
				function() {},
			);
		} else {
			this.setLayout(layoutList[0].width, layoutList[0].height);
		}
	});

	this.updateLayoutSelector(function() {});
};

WidgetAppViewEditor.prototype.onElementAdded = function(data) {
	this.updateLayoutSelector(function() {});

	var currentElementController = data.controller;

	this.getAvailableLayoutList(function(err, layoutList) {
		var layouts = currentElementController.getPropertyValue('layouts');

		if (layouts === undefined || layouts === null) {
			return;
		}

		for (var i = 0; i < layoutList.length; i++) {
			var currentLayout = layoutList[i];

			if (layouts[currentLayout.width] === undefined) {
				layouts[currentLayout.width] = {};
			}

			if (
				layouts[currentLayout.width][currentLayout.height] === undefined
			) {
				layouts[currentLayout.width][currentLayout.height] = {
					width: 100,
					height: 100,
					x: 0,
					y: 0,
					rotation: 0,
					propertyOverlay: {},
				};
			}
		}

		currentElementController.setProperty('layouts', layouts);
	});
};

WidgetAppViewEditor.prototype.onElementRemoved = function(data) {
	this.updateLayoutSelector(function() {});

	this._focusedElementController = null;
	this._focusedElementName = null;
	this._focusedElementPropertySetCallback = null;

	this.updateLayoutInputControls();
};

WidgetAppViewEditor.prototype.onToBackButton = function() {
	this.appView._controller.moveElementToBack(this._focusedElementName);
};

WidgetAppViewEditor.prototype.onToFrontButton = function() {
	this.appView._controller.moveElementToFront(this._focusedElementName);
};

WidgetAppViewEditor.prototype.onSnapButtonClick = function() {
	if (this.appView.editorAlignment) {
		this.appView.setEditorAlignment(false);
		this.snapButton.removeClass('WidgetAppViewEditor-toolbar-icon-toggled');
	} else {
		this.appView.setEditorAlignment(true);
		this.snapButton.addClass('WidgetAppViewEditor-toolbar-icon-toggled');
		this.gridSnapButton.removeClass(
			'WidgetAppViewEditor-toolbar-icon-toggled',
		);
	}
};

WidgetAppViewEditor.prototype.onGridSnapButtonClick = function() {
	if (this.appView.editorGridAlignment) {
		this.appView.setEditorGridAlignment(false);
		this.gridSnapButton.removeClass(
			'WidgetAppViewEditor-toolbar-icon-toggled',
		);
	} else {
		this.appView.setEditorGridAlignment(true);
		this.gridSnapButton.addClass(
			'WidgetAppViewEditor-toolbar-icon-toggled',
		);
		this.snapButton.removeClass('WidgetAppViewEditor-toolbar-icon-toggled');
	}
};

WidgetAppViewEditor.prototype.onSmallerGridSizeButtonClick = function() {
	if (this.appView.editorGridAlignment) {
		this.appView.decreaseGridSize();
	}
};

WidgetAppViewEditor.prototype.onLargerGridSizeButtonClick = function() {
	if (this.appView.editorGridAlignment) {
		this.appView.increaseGridSize();
	}
};

WidgetAppViewEditor.prototype.onToggleExecuteAppButtonClick = function() {
	if (this.appView.editable) {
		this.setEditable(false);
		this.toggleExecuteAppButton.addClass(
			'WidgetAppViewEditor-toolbar-icon-toggled',
		);
	} else {
		this.setEditable(true);
		this.toggleExecuteAppButton.removeClass(
			'WidgetAppViewEditor-toolbar-icon-toggled',
		);
	}
};

WidgetAppViewEditor.prototype.language = {
	'en-US': {
		name: 'App View',
		layoutSendtoBack: 'Send To Back',
		layoutBringToFront: 'Bring To Front',
		align: 'Align',
		layoutSnapToGrid: 'Snap To Grid',
		layoutDecreaseGrid: 'Decrease Grid Size',
		layoutIncreaseGrid: 'Increase Grid Size',
		executeApp: 'Execute App',
		addLayout: 'Add Layout',
		xLabel: 'x',
		yLabel: 'y',
		widthLabel: 'w',
		heightLabel: 'h',
		tooltip: 'Show/Hide App Builder',
		mustHaveOneLayout: 'You must have at least one display size enabled',
	},
};
