// Copyright 2018 Atmosphere IoT Corp.
// All rights reserved
// jshint esversion: 6

function WidgetPlaneEditor(id, api, parentWidget, options) {
	var currentWidget = this;

	WidgetBase.call(this, id, api, parentWidget, options);

	this.planeController = null;
	this.extraToolWidgets = {};

	this.IS_CURRENTLY_DOWNLOADING = false;

	this.headerId = this.generateChildId('header');
	this.resizePanelId = this.generateChildId('resizePanel');
	this.splitterId = this.generateChildId('splitter');
	this.headerLabelId = this.generateChildId('labelHeader');
	this.leftSideContainerId = this.generateChildId('leftSideContainer');
	this.extraToolsContainerId = this.generateChildId('extraToolsContainerId');
	this.designerViewContainerId = this.generateChildId('designerView');
	this.toolboxContainerId = this.generateChildId('toolbox');
	this.propertiesContainerId = this.generateChildId('properties');
	this.bottomIconsContainerId = this.generateChildId('bottomIcons');
	this.downloadSourceButtonId = this.generateChildId('downloadSourceButton');
	this.programEmbeddedButtonId = this.generateChildId(
		'programEmbeddedButton',
	);
	this.extraToolIconId = this.generateChildId('extraToolIcon');
	this.programmingFeedbackProgressBarId = this.generateChildId(
		'programmingFeedbackProgressBar',
	);
	this.programmingFeedbackProgressBarTotalId = this.generateChildId(
		'programmingFeedbackProgressBarTotal',
	);
	this.programmingFeedbackProgressBarContainerId = this.generateChildId(
		'programmingFeedbackProgressBarContainerId',
	);
	this.programmingFeedbackStdOutContainerId = this.generateChildId(
		'programmingFeedbackStdOutContainerId',
	);
	this.programmingFeedbackShowStdOutButtonId = this.generateChildId(
		'programmingFeedbackShowStdOutButtonId',
	);
	this.programmingFeedbackInfoContainerId = this.generateChildId(
		'programmingFeedbackInfoContainerId',
	);

	this.programmerListener = null;
	this.programmingFeedbackDialog = null;

	this.getContainer().html(
		$.handlebarTemplates.WidgetPlaneEditor({
			id: this.leftSideContainerId,
			toolboxContainerId: this.toolboxContainerId,
			propertiesContainerId: this.propertiesContainerId,
			headerId: this.headerId,
			headerLabel: '',
			headerLabelId: this.headerLabelId,
			resizePanelId: this.resizePanelId,
			icons: [
				{
					src: './Resources/icons/Nothing.svg',
					guidepost: 'extraTools',
					id: this.extraToolIconId,
					label: getLanguageTag(WidgetPlaneEditor, 'extraTools'),
				},
				{
					src: './Resources/icons/Download.svg',
					guidepost: 'downloadSource',
					id: this.downloadSourceButtonId,
					label: getLanguageTag(WidgetPlaneEditor, 'download'),
				},
				{
					src: './Resources/icons/Program.svg',
					guidepost: 'programFirmware',
					id: this.programEmbeddedButtonId,
					label: getLanguageTag(WidgetPlaneEditor, 'program'),
				},
			],

			leftPanelId: this.extraToolsContainerId,
			rightPanelId: this.designerViewContainerId,
			splitterId: this.splitterId,
		}),
	);

	this.downloadSourceButton = $('#' + this.downloadSourceButtonId);
	this.programEmbeddedButton = $('#' + this.programEmbeddedButtonId);
	this.headerLabel = $('#' + this.headerLabelId);
	this.leftSideContainer = $('#' + this.leftSideContainerId);
	this.extraToolsContainer = $('#' + this.extraToolsContainerId);
	this.designerViewContainer = $('#' + this.designerViewContainerId);
	this.bottomIconsContainer = $('#' + this.bottomIconsContainerId);
	this.splitter = $('#' + this.splitterId);
	this.extraToolIcon = $('#' + this.extraToolIconId);

	this.extraToolIcon.hide();

	this.extraToolIcon.click(function() {
		currentWidget.toggleExtraTools();
	});

	this.extraToolsContainer.resizable({
		handleSelector: '#' + this.splitterId,
		resizeHeight: false,
	});

	this.clipBoard = [];
}

WidgetPlaneEditor.prototype = Object.create(WidgetBase.prototype);
WidgetPlaneEditor.prototype.constructor = WidgetPlaneEditor;

WidgetPlaneEditor.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.addChildWidget(
		WidgetDesignerView,
		this.designerViewContainerId,
		{},
		function(err, designerView) {
			this.designerView = designerView;

			this.elementIconLocation =
				'./Widgets/WidgetDesignerView/Resources/elementIcons/';
			this.elementBadgeLocation =
				'./Widgets/WidgetDesignerView/Resources/elementBadges/';

			this.addChildWidget(
				WidgetElementToolbox,
				this.toolboxContainerId,
				{},
				function(err, elementToolbox) {
					this.toolbox = elementToolbox;

					this.toolbox.addEventListener(
						'addButtonClicked',
						function() {
							currentWidget
								.getMainContainer()
								.setModalWidget(
									WidgetElementLibraryBrowser,
									{},
									function(err, libraryBrowserWidget) {
										libraryBrowserWidget.addEventListener(
											'dismissed',
											function() {
												currentWidget
													.getMainContainer()
													.hideModal();
											},
										);

										libraryBrowserWidget.addEventListener(
											'confirmed',
											function() {
												currentWidget
													.getMainContainer()
													.hideModal();
											},
										);

										libraryBrowserWidget.setPlaneController(
											currentWidget.planeController,
											function(err) {
												currentWidget
													.getMainContainer()
													.showModal();
											},
										);
									},
								);
						},
					);

					this.addChildWidget(
						WidgetElementEditor,
						this.propertiesContainerId,
						{},
						function(err, elementEditor) {
							this.properties = elementEditor;
							this.properties.setDesignerView(this.designerView);

							this.properties.hide();

							this.downloadSourceButton.click(function() {
								currentWidget.downloadSourceZip(function(
									err,
									data,
								) {
									if (err) {
										currentWidget.IS_CURRENTLY_DOWNLOADING = false;
										console.log(err);
										return;
									}

									currentWidget.IS_CURRENTLY_DOWNLOADING = false;
									return;
								});
							});

							this.programEmbeddedButton.click(function() {
								// To program, WidgetPlaneEditor should check if the artifacts are available first.
								currentWidget.planeController
									.getParent()
									.getPlaneArtifacts(
										currentWidget.planeController,
										function(err, artifacts) {
											// Is there an error when getting the artifacts?
											if (err) {
												console.error(err);
												currentWidget
													.getMainContainer()
													.showPopupErrorMessage(
														getLanguageTag(
															WidgetPlaneEditor,
															'noAvailableFirmware',
														),
													);
												return;
											}

											// Do we have the artifacts?
											// In the artifacts, is the firmware available?
											if (
												!isValueObject(artifacts) ||
												!isValueObject(
													artifacts.data,
												) ||
												artifacts.data.firmwareLink ===
													undefined ||
												artifacts.data.firmwareLink ===
													null
											) {
												currentWidget
													.getMainContainer()
													.showPopupErrorMessage(
														getLanguageTag(
															WidgetPlaneEditor,
															'noAvailableFirmware',
														),
													);
												return;
											}

											currentWidget.showProgrammingDialog();
										},
									);
							});

							this.toolbox.addEventListener(
								'iconClicked',
								function(handleName) {
									var elementController = currentWidget.planeController.getElementController(
										handleName,
									);

									if (elementController === null) {
										return;
									}

									var newName = null;

									if (
										elementController.prototype.NAME !==
										undefined
									) {
										newName =
											elementController.prototype.NAME;
									}

									if (newName === null) {
										newName = elementController.prototype.TYPE.replace(
											'Element',
											'',
										);
									}

									currentWidget.planeController.addElement(
										newName,
										elementController,
									);
								},
							);

							this.designerView.panel.addEventListener(
								'connectorfocus',
								function(data) {
									currentWidget.openElementEditor(
										data.from,
										data.to,
									);
								},
							);

							this.designerView.panel.addEventListener(
								'connectorblur',
								function(data) {
									currentWidget.showToolbox();
								},
							);

							this.designerView.panel.addEventListener(
								'iconblur',
								function(data) {
									currentWidget.showToolbox();

									return true;
								},
							);

							this.designerView.addEventListener(
								'iconclick',
								function(data) {
									currentWidget.openElementEditor(data.name);

									return;
								},
							);

							this.designerView.addEventListener(
								'imagedrop',
								function(data) {
									currentWidget.onImageDrop(data);

									return;
								},
							);

							this.designerView.addEventListener(
								'iconpointerdown',
								function(data) {
									currentWidget.openElementEditor(data.name);
									return;
								},
							);

							this.designerView.addEventListener(
								'iconmove',
								function(data) {
									var currentElement = currentWidget.planeController.getElement(
										data.name,
									);
									currentElement.setMetaValue(
										'editorX',
										data.x,
									);
									currentElement.setMetaValue(
										'editorY',
										data.y,
									);
								},
							);

							this.designerView.addEventListener(
								'connectoradded',
								function(data) {
									currentWidget.addConnector(
										data.from,
										data.to,
									);
								},
							);

							this.designerView.addEventListener(
								'deleteconnector',
								function(data) {
									currentWidget.showToolbox();
									currentWidget.deleteConnector(
										data.from,
										data.to,
									);
								},
							);

							this.designerView.addEventListener(
								'deleteicon',
								function(data) {
									currentWidget.showToolbox();
									currentWidget.deleteElement(data.name);
								},
							);

							this.designerView.addEventListener(
								'iconcopy',
								function(data) {},
							);

							this.designerView.addEventListener(
								'iconcut',
								function(data) {},
							);

							WidgetBase.prototype.initialize.call(
								this,
								callback,
							);
						},
					);
				},
			);
		},
	);
};

WidgetPlaneEditor.prototype.showProperties = function(
	elementController,
	toElementController,
) {
	this.properties.setElementController(
		elementController,
		toElementController,
	);
	this.toolbox.hide();
	this.properties.show();
};

WidgetPlaneEditor.prototype.showToolbox = function() {
	this.properties.setElementController(null);
	this.toolbox.show();
	this.properties.hide();
};

WidgetPlaneEditor.prototype.toggleExtraTools = function() {
	if (this.extraToolsContainer.is(':visible')) {
		this.hideExtraTools();
	} else {
		this.showExtraTools();
	}
};

WidgetPlaneEditor.prototype.showExtraTools = function() {
	this.splitter.show();
	this.extraToolsContainer.show();

	for (var k in this.extraToolWidgets) {
		if (
			this.extraToolWidgets[k].show !== undefined &&
			typeof this.extraToolWidgets[k].show === 'function'
		) {
			this.extraToolWidgets[k].show();
		}
	}
};

WidgetPlaneEditor.prototype.hideExtraTools = function() {
	this.splitter.hide();
	this.extraToolsContainer.hide();

	for (var k in this.extraToolWidgets) {
		if (
			this.extraToolWidgets[k].hide !== undefined &&
			typeof this.extraToolWidgets[k].hide === 'function'
		) {
			this.extraToolWidgets[k].hide();
		}
	}
};

WidgetPlaneEditor.prototype.onImageDrop = function(data) {
	var elementController = this.planeController.getElementController(
		'AppUIImage',
	);

	if (
		elementController === null ||
		!this.planeController.isElementControllerAvailable(elementController)
	) {
		return;
	}

	var newName = null;

	if (elementController.prototype.NAME !== undefined) {
		newName = elementController.prototype.NAME;
	}

	if (newName === null) {
		newName = elementController.prototype.TYPE.replace('Element', '');
	}

	var currentElement = this.planeController.addElement(
		newName,
		elementController,
	);

	this.designerView.setIconPosition(currentElement.getName(), data.x, data.y);
	currentElement.setProperty('image', data.image);
};

WidgetPlaneEditor.prototype.remove = function(callback) {
	window.document.body.style.cursor = '';

	WidgetBase.prototype.remove.call(this, callback);
};

WidgetPlaneEditor.prototype.addConnector = function(fromElement, toElement) {
	var fromElementController = this.planeController.getElement(fromElement);

	fromElementController.addTriggerEvent(null, toElement, null, null, null);
};

WidgetPlaneEditor.prototype.deleteConnector = function(fromElement, toElement) {
	this.planeController
		.getElement(fromElement)
		.removeTriggerEventThatTargetElement(toElement);
};

WidgetPlaneEditor.prototype.deleteElement = function(elementName) {
	this.planeController.removeElement(elementName);
};

WidgetPlaneEditor.prototype.getElementControllerIcon = function(c) {
	//Did the element controller specifically call out an element.
	if (c.ICON !== undefined) {
		return this.elementIconLocation + c.ICON;
	}

	return this.elementIconLocation + c.TYPE + '.svg';
};

WidgetPlaneEditor.prototype.getElementControllerBadge = function(c) {
	var coupledElement = c.getCoupledElement();

	if (
		coupledElement !== null &&
		coupledElement._parentController !== undefined
	) {
		return coupledElement._parentPlane.COUPLING_BADGE;
	}

	return null;
};

WidgetPlaneEditor.prototype.onImporting = function(e) {
	this._importing = true;
};

WidgetPlaneEditor.prototype.onImported = function(e) {
	var elements = this.planeController.getElements();

	for (var j = 0; j < elements.length; j++) {
		var currentElement = elements[j];

		var currentTriggers = currentElement.getTriggers();

		for (var triggerName in currentTriggers) {
			for (var i = 0; i < currentTriggers[triggerName].length; i++) {
				var targetElement =
					currentTriggers[triggerName][i].targetElement;

				this.designerView.addConnector(
					currentElement._name,
					targetElement,
				);
			}
		}
	}

	this._importing = false;
};

WidgetPlaneEditor.prototype.onLibraryUpdating = function(e) {};

WidgetPlaneEditor.prototype.onElementUpdated = function(e) {
	this.designerView.setIconType(
		e.controller.getName(),
		this.getElementControllerIcon(e.controller),
	);
};

WidgetPlaneEditor.prototype.onElementAdded = function(e) {
	this.designerView.addIcon(
		e.controller.getName(),
		this.getElementControllerIcon(e.controller),
		'solid',
		e.controller.getMetaValue('editorX'),
		e.controller.getMetaValue('editorY'),
		this.getElementControllerBadge(e.controller),
	);

	this.scrollToElement(e.controller.getName());

	// If the controller implements a driver
	// Rebuild the element toolbox so that any new available elements will be added
	if (e.controller._implements && e.controller._implements.length > 0) {
		this.buildElementToolbox();
	}
};

WidgetPlaneEditor.prototype.scrollToElement = function(elementName) {
	if (this._importing) {
		return;
	}

	var iconPosition = this.designerView.getIconPosition(elementName);

	this.designerView.setFocusedIcon(this.designerView.getIcon(elementName));

	if (
		iconPosition.y >=
			this.designerViewContainer.scrollTop() +
				this.designerViewContainer.height() ||
		iconPosition.y < this.designerViewContainer.scrollTop()
	) {
		this.designerViewContainer.scrollTop(iconPosition.y - 24);
	}

	if (
		iconPosition.x >=
			this.designerViewContainer.scrollLeft() +
				this.designerViewContainer.width() ||
		iconPosition.x < this.designerViewContainer.scrollLeft()
	) {
		this.designerViewContainer.scrollLeft(iconPosition.x - 24);
	}
};

WidgetPlaneEditor.prototype.onElementNameSet = function(e) {
	this.designerView.setIconHandle(e.oldName, e.newName);
	return;
};

WidgetPlaneEditor.prototype.onElementCoupled = function(e) {
	var badge = this.getElementControllerBadge(e.controller);
	this.designerView.setIconBadge(e.name, badge);
	return;
};

WidgetPlaneEditor.prototype.onElementRemoved = function(e) {
	this.designerView.removeIcon(e.controller.getName());

	if (e.controller._implements && e.controller._implements.length > 0) {
		this.buildElementToolbox();
	}

	return;
};

WidgetPlaneEditor.prototype.onConnectorAdded = function(e) {
	this.designerView.addConnector(
		e.fromElement.getName(),
		e.toElement.getName(),
	);
	$('[target=selectAnElementToAddATriggerEvent]')
		.parent()
		.find('.close')
		.click();
};

WidgetPlaneEditor.prototype.onConnectorRemoved = function(e) {
	if (e.fromElement !== null && e.toElement !== null) {
		this.designerView.removeConnector(
			e.fromElement.getName(),
			e.toElement.getName(),
		);
	}
	return;
};

WidgetPlaneEditor.prototype.onElementTriggerEventErrorAdded = function(e) {
	this.designerView
		.getConnectorConnecting(
			e.data.element._name,
			e.data.trigger.targetElement,
		)
		.setError();
};

WidgetPlaneEditor.prototype.onElementTriggerEventErrorRemoved = function(e) {
	this.designerView
		.getConnectorConnecting(
			e.data.element._name,
			e.data.trigger.targetElement,
		)
		.setNormal();
};

WidgetPlaneEditor.prototype.availableElementControllersUpdated = function(e) {
	this.buildElementToolbox();
};

WidgetPlaneEditor.prototype.buildElementToolbox = function() {
	if (this.planeController === undefined || this.planeController === null) {
		return;
	}

	var availableElements = this.planeController.getAvailableElements();

	this.toolbox.removeAllCategories();

	this.toolbox.setShowAddButton(
		this.planeController.SUPPORTS.indexOf('embedded') >= 0,
	);

	for (var k in availableElements) {
		var currentElementController =
			availableElements[k].controllerConstructor;

		if (currentElementController.prototype.COUPLED_TO === null) {
			this.toolbox.addCategory(
				currentElementController.prototype.CATEGORY,
				{},
			);
			this.toolbox.addIconToCategory(
				currentElementController.prototype.CATEGORY,
				k,
				this.getElementControllerIcon(
					currentElementController.prototype,
				),
				currentElementController.prototype.language,
			);
		}
	}
};

WidgetPlaneEditor.prototype.openElementEditor = function(
	elementName,
	toElementName,
) {
	this.showProperties(
		this.planeController.getElement(elementName),
		this.planeController.getElement(toElementName),
	);

	this.event('elementEditorOpened', {
		elementName: elementName,
		toElementName: toElementName,
		elementController: this.planeController.getElement(elementName),
	});

	return;
};

WidgetPlaneEditor.prototype.onElementFocus = function(data) {
	var designerViewIcon = this.designerView.getIcon(data.name);

	if (designerViewIcon === null) {
		return;
	}

	this.designerView.setFocusedIcon(designerViewIcon);
	this.showProperties(this.planeController.getElement(data.name), null);
	return;
};

WidgetPlaneEditor.prototype.addExtraToolWidget = function(widgetConstructor) {
	var currentWidget = this;

	if (this.extraToolWidgets[widgetConstructor.name] !== undefined) {
		return;
	}
	this.addChildWidget(
		widgetConstructor,
		this.extraToolsContainerId,
		{},
		function(err, newExtraToolWidgets) {
			this.extraToolWidgets[widgetConstructor.name] = newExtraToolWidgets;
			this.extraToolWidgets[widgetConstructor.name].attachController(
				this.planeController,
				function(err) {},
			);

			this.extraToolWidgets[widgetConstructor.name].attachPlaneEditor(
				this,
			);

			if (
				this.extraToolWidgets[widgetConstructor.name]
					.addEventListener !== undefined
			) {
				this.extraToolWidgets[widgetConstructor.name].addEventListener(
					'imagedrop',
					function(data) {
						currentWidget.onImageDrop(data);
						return;
					},
				);
			}

			if (widgetConstructor.prototype.HIDE_ON_INITIALIZE) {
				this.extraToolWidgets[widgetConstructor.name].hide();
				this.splitter.hide();
			} else {
				this.extraToolWidgets[widgetConstructor.name].show();
				this.splitter.show();
			}

			this.extraToolIcon.show();

			this.extraToolIcon.html(
				`<img src="${
					widgetConstructor.prototype.EXTRA_TOOL_ICON
				}" class="standard-svg-button-icon _svg-inject" title="${getFromLanguageObject(
					widgetConstructor.prototype.language,
					'tooltip',
				)}" />`,
			);
		},
	);
};

WidgetPlaneEditor.prototype.setPlaneController = function(planeController) {
	var currentWidget = this;

	this.planeController = planeController;

	if (this.planeController === null) {
		return;
	}

	this.headerLabel.html(
		getLanguageTag(this.planeController.constructor, 'defaultName'),
	);

	if (this.planeController.SOURCE_DOWNLOAD === true) {
		this.downloadSourceButton.show();
	} else {
		this.downloadSourceButton.hide();
	}

	if (this.planeController.PROGRAMMING === true) {
		this.programEmbeddedButton.show();
	} else {
		this.programEmbeddedButton.hide();
	}

	if (
		this.planeController.EXTRA_TOOLS !== undefined &&
		this.planeController.EXTRA_TOOLS.length > 0
	) {
		for (var i = 0; i < this.planeController.EXTRA_TOOLS.length; i++) {
			var currentWidgetName = this.planeController.EXTRA_TOOLS[i];
			var currentWidgetConstructor = window[currentWidgetName];

			if (currentWidgetConstructor !== undefined) {
				this.addExtraToolWidget(currentWidgetConstructor);
			}
		}
	} else {
		this.splitter.hide();
		this.extraToolsContainer.hide();
	}

	this.planeController.addEventListener('importing', function(e) {
		currentWidget.onImporting(e);
	});

	this.planeController.addEventListener('imported', function(e) {
		currentWidget.onImported(e);
	});

	this.planeController.addEventListener('libraryUpdating', function(e) {
		currentWidget.onLibraryUpdating(e);
	});

	this.planeController.addEventListener('elementUpdated', function(e) {
		currentWidget.onElementUpdated(e);
	});

	this.planeController.addEventListener('elementAdded', function(e) {
		currentWidget.onElementAdded(e);
	});

	this.planeController.addEventListener('elementRemoved', function(e) {
		currentWidget.onElementRemoved(e);
	});

	this.planeController.addEventListener('elementNameSet', function(e) {
		currentWidget.onElementNameSet(e);
	});

	this.planeController.addEventListener('elementCoupled', function(e) {
		currentWidget.onElementCoupled(e);
	});

	this.planeController.addEventListener('triggerEventAdded', function(e) {
		currentWidget.onConnectorAdded(e);
	});

	this.planeController.addEventListener(
		'allTriggerEventsToElementRemoved',
		function(e) {
			currentWidget.onConnectorRemoved(e);
		},
	);

	this.planeController.addEventListener(
		'availableElementControllersUpdated',
		function(e) {
			currentWidget.availableElementControllersUpdated(e);
		},
	);

	this.planeController.addEventListener(
		'elementTriggerEventErrorAdded',
		function(e) {
			currentWidget.onElementTriggerEventErrorAdded(e);
		},
	);

	this.planeController.addEventListener(
		'elementTriggerEventErrorRemoved',
		function(e) {
			currentWidget.onElementTriggerEventErrorRemoved(e);
		},
	);

	this.buildElementToolbox();
};

WidgetPlaneEditor.prototype.downloadSourceZip = function(callback) {
	var currentWidget = this;

	if (this.IS_CURRENTLY_DOWNLOADING === true) {
		return;
	}

	this.IS_CURRENTLY_DOWNLOADING = true;

	this.planeController
		.getParent()
		.getPlaneArtifacts(this.planeController, function(err, artifacts) {
			//TODO: Display some sort of error here.
			if (err) {
				if (err.code === 'ENOENT') {
					currentWidget
						.getMainContainer()
						.showPopupErrorMessage(
							getLanguageTag(
								WidgetPlaneEditor,
								'noSourceAvailable',
							),
						);

					callback.call(currentWidget, err, false);

					return;
				} else {
					if (!err.type) {
						currentWidget
							.getMainContainer()
							.showPopupErrorMessage(
								getLanguageTag(
									WidgetPlaneEditor,
									'noSourceAvailable',
								),
							);

						callback.call(currentWidget, err, false);

						return;
					}

					currentWidget
						.getMainContainer()
						.showPopupErrorMessage(
							getLanguageTag(WidgetPlaneEditor, err.type),
						);

					callback.call(currentWidget, err, false);

					return;
				}
			}

			if (
				!isValueObject(artifacts) ||
				!isValueObject(artifacts.data) ||
				artifacts.data.sourceLink === undefined ||
				artifacts.data.sourceLink === null
			) {
				currentWidget
					.getMainContainer()
					.showPopupErrorMessage(
						getLanguageTag(WidgetPlaneEditor, 'noSourceAvailable'),
					);
				return;
			}

			openLinkInNewWindowOrTab(artifacts.data.sourceLink);
			callback.call(currentWidget, false, null);

			return;
		});
};

WidgetPlaneEditor.prototype.showProgrammingDialog = function() {
	var currentWidget = this;

	var clientAgent = this.getMainContainer().getClientAgent();

	if (currentWidget.planeController === null) {
		return;
	}

	if (
		clientAgent.programmer === undefined ||
		currentWidget.planeController.CLIENT_AGENT_REQUIRED === false
	) {
		// If the client agent wasn't found but was required, show an error message
		if (
			clientAgent.programmer === undefined &&
			currentWidget.planeController.CLIENT_AGENT_REQUIRED !== false
		) {
			currentWidget
				.getMainContainer()
				.showPopupErrorMessage(
					currentWidget.getLanguageTag('noClientAgent'),
					null,
				);
			return;
		}

		this.downloadFirmwareZip(function(err, data) {
			if (err) {
				currentWidget.IS_CURRENTLY_DOWNLOADING = false;
				console.log(err);
				return;
			}

			currentWidget.IS_CURRENTLY_DOWNLOADING = false;
			return;
		});

		return;
	}

	currentWidget
		.getMainContainer()
		.setModalWidget(WidgetProgrammingDialog, {}, function(
			err,
			programmingDialogWidget,
		) {
			programmingDialogWidget.addEventListener('dismissed', function() {
				currentWidget.getMainContainer().hideModal();
			});

			programmingDialogWidget.showProgrammingOptions(
				currentWidget.planeController,
				function(err) {
					if (err && err.type === 'noProgrammerAvailableForPlane') {
						if (
							currentWidget.planeController
								.CLIENT_AGENT_REQUIRED !== false
						) {
							currentWidget
								.getMainContainer()
								.showPopupErrorMessage(
									currentWidget.getLanguageTag(
										'noProgrammerAvailableForPlane',
									),
								);
							return;
						}

						currentWidget.downloadFirmwareZip(function(err, data) {
							if (err) {
								currentWidget.IS_CURRENTLY_DOWNLOADING = false;
								console.log(err);
								return;
							}

							currentWidget.IS_CURRENTLY_DOWNLOADING = false;
							return;
						});

						return;
					} else if (err) {
						//TODO:Insert error message here!
						console.error(err);
						return;
					}

					currentWidget.getMainContainer().showModal();
					return;
				},
			);
		});
};

WidgetPlaneEditor.prototype.downloadFirmwareZip = function(callback) {
	var currentWidget = this;

	if (this.IS_CURRENTLY_DOWNLOADING === true) {
		return;
	}

	this.IS_CURRENTLY_DOWNLOADING = true;

	if (this.planeController === undefined && this.planeController === null) {
		this.getMainContainer().showAlert(
			getLanguageTag(WidgetPlaneEditor, 'unableToDownloadFirmware'),
			null,
		);

		callback.call(currentWidget, false, null);

		return;
	}

	this.planeController
		.getParent()
		.getPlaneArtifacts(this.planeController, function(err, artifacts) {
			if (err) {
				if (err.code === 'ENOENT') {
					currentWidget
						.getMainContainer()
						.showPopupErrorMessage(
							getLanguageTag(
								WidgetPlaneEditor,
								'noFirmwareAvailable',
							),
						);

					callback.call(currentWidget, err, false);

					return;
				} else {
					if (err.type === undefined || err.type === null) {
						currentWidget
							.getMainContainer()
							.showPopupErrorMessage(
								getLanguageTag(
									WidgetPlaneEditor,
									'noFirmwareAvailable',
								),
							);

						callback.call(currentWidget, err, false);

						return;
					}

					currentWidget
						.getMainContainer()
						.showPopupErrorMessage(
							getLanguageTag(WidgetPlaneEditor, err.type),
						);

					callback.call(currentWidget, err, false);

					return;
				}
			}

			if (
				!isValueObject(artifacts) ||
				!isValueObject(artifacts.data) ||
				artifacts.data.firmwareLink === undefined ||
				artifacts.data.firmwareLink === null
			) {
				currentWidget
					.getMainContainer()
					.showPopupErrorMessage(
						getLanguageTag(
							WidgetPlaneEditor,
							'noFirmwareAvailable',
						),
					);
				return;
			}

			openLinkInNewWindowOrTab(artifacts.data.firmwareLink);
			callback.call(currentWidget, false, null);

			return;
		});
};

WidgetPlaneEditor.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			download: 'Download',
			done: 'Programming is complete',
			deviceTimedOut: "Error: The device timed out it's connection",
			programmingRequiresAgent:
				'To use the programmer feature you must have the client agent running on your PC',
			program: 'Program Firmware',
			programmingFeedback: 'Programming Firmware',
			serialPort: 'Serial Port',
			serialPortSpeed: 'Serial Speed',
			esp32FatalError:
				'Fatal error occcured while programming the ESP32, try reducing your serial speed and program again',
			serialPortPermissionDenied:
				'Permission was denied for the programmer to use the serial port',
			serialPortBusy:
				'The serial port selected is busy, check to make sure there are no other application using it and try again',
			flashBootLoader: 'Flash Boot Loader',
			noFirmwareAvailable:
				'There is no firmware available for this embedded device. Have you compiled your application?',
			noSourceAvailable:
				'There is no source code available for this embedded device. Have you compiled your application?',
			noArtifactDataForPlane:
				'There is no source code available for this embedded device, have you compiled your application?',
			tryPressingReset:
				'The embedded device is not responding, try pressing the RESET button.',
			tryPressingResetInitial: 'Reset the board to begin.',
			compilingCompleted: 'Compiling Complete',
			buildError:
				'There was an error when trying to build the embedded source for the application.',
			unableToReconnect:
				'There was an issue communicating with the Client Agent application on your PC, please check to make sure that it is running.',
			unableToDownloadFirmware: 'Unable to download firmware.',
			showStdOut: 'Show Advanced Output',
			hideStdOut: 'Hide Advanced Output',
			noAvailableInputOptions: 'No available options',
			extraTools: 'Extra Tools',
			noAvailableFirmware:
				'No available firmware for download. Have you compiled your application?',
			noClientAgent:
				'The Atmosphere Client Agent was not detected. Please start the Atmosphere Client Agent and try again.',
			noProgrammerAvailableForPlane:
				'The Atmosphere Client Agent was detected, but does not support your application type. Are you running the latest version of the Atmosphere Client Agent?',
		},
	},
);
