function ElementControllerCloudAWSSQS(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudAWSBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);
}

ElementControllerCloudAWSSQS.prototype = Object.create(
	ElementControllerCloudAWSBase.prototype,
);
ElementControllerCloudAWSSQS.prototype.constructor = ElementControllerCloudAWSSQS;

ElementControllerCloudAWSSQS.prototype.NAME = 'AWSSQS';
ElementControllerCloudAWSSQS.prototype.TYPE = 'CloudAWSSQS';
ElementControllerCloudAWSSQS.prototype.HIDDEN = true;

ElementControllerCloudAWSSQS.prototype.language = deepAssign(
	{},
	ElementControllerCloudAWSBase.prototype.language,
	{
		'en-US': {
			CloudAWSSQS: 'AWS SQS',
		},
	},
);
