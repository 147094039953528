function WidgetButtonsFooter(id, api, parentWidget, options) {
	WidgetBase.call(this, id, api, parentWidget, options);

	if (this.getOptions().buttons) {
		var buttons = this.getOptions().buttons;

		this.setButtons(buttons);
	}
}

WidgetButtonsFooter.prototype = Object.create(WidgetBase.prototype);
WidgetButtonsFooter.prototype.constructor = WidgetButtonsFooter;

WidgetButtonsFooter.prototype.getButton = function(buttonValue) {
	return $(
		this.getContainer().find(
			`*[data-buttonvalue='${JSON.stringify(buttonValue)}']`,
		)[0],
	);
};

WidgetButtonsFooter.prototype._attachFooterButtonEvents = function() {
	var currentWidget = this;

	var buttons = this.getContainer().find('*[data-buttonvalue]');

	for (var i = 0; i < buttons.length; i++) {
		$(buttons[i]).on('click', function() {
			var value = JSON.parse($(this).attr('data-buttonvalue'));

			currentWidget.event('footerButtonPressed', value);
		});
	}
};

WidgetButtonsFooter.prototype.setButtonHidden = function(buttonValue, hidden) {
	var currentWidget = this;

	var buttons = this.getContainer().find(
		`*[data-buttonvalue='${JSON.stringify(buttonValue)}']`,
	);

	for (var i = 0; i < buttons.length; i++) {
		if (hidden) {
			$(buttons[i]).hide();
		} else {
			$(buttons[i]).show();
		}
	}
};

WidgetButtonsFooter.prototype.setButtonDisabled = function(
	buttonValue,
	disabled,
) {
	var currentWidget = this;

	var buttons = this.getContainer().find(
		`*[data-buttonvalue='${JSON.stringify(buttonValue)}']`,
	);

	for (var i = 0; i < buttons.length; i++) {
		$(buttons[i]).prop('disabled', Boolean(disabled));
	}
};

WidgetButtonsFooter.prototype.setButtons = function(buttons) {
	/*
	 * this function will set the buttons for the footer widget.
	 *
	 * Example:
	 *
	 * [
	 * 	{label:'Testing', value:{a:2}, disabled:true},
	 * 	{label:'Hello', value:24, classes:['btn-prm']}
	 * ]
	 *
	 * When a button is pressed the value is thrown in an event called 'footerButtonPressed'.
	 * The value must be stringifiable into JSON.
	 */

	var contextButtons = [];

	for (var i = 0; i < buttons.length; i++) {
		contextButtons.push({
			label: buttons[i].label || '',
			value: buttons[i].value || null,
			JSONValue: JSON.stringify(buttons[i].value || null),
			classes: buttons[i].classes || [],
		});
	}

	this.renderTemplate(
		{
			buttons: contextButtons,
		},
		WidgetButtonsFooter.name,
	);

	this._attachFooterButtonEvents();

	return;
};

WidgetButtonsFooter.prototype.$_$ = function(done) {
	this.$_SetupWidgetTest(function(err) {
		this.setButtons([
			{
				label: 'Test',
				value: { a: 2 },
			},

			{
				label: 'Cancel',
				value: 'cancelTest',
				classes: ['btn-primary'],
			},
		]);

		this.setButtonDisabled('cancelTest', true);

		if (!this.getButton('cancelTest').prop('disabled')) {
			done({ type: 'Failed to disable the button' });
			return;
		}

		this.addEventListener('footerButtonPressed', function(data) {
			console.log(data);
		});

		WidgetBase.prototype.$_$.call(this, done);
	});
};
