function EventListener() {
	this._listeners = {};
}

EventListener.prototype.error = function(type) {
	var sourceString = this.constructor.name;
	var currentCaller = arguments.callee.caller;
	var currentProto = this.constructor.prototype;

	while (true) {
		var currentProtoKeys = Object.keys(currentProto);

		for (var i = 0; i < currentProtoKeys.length; i++) {
			var currentKey = currentProtoKeys[i];
			var currentPrototypeProperty = this[currentKey];

			if (typeof currentPrototypeProperty === 'function') {
				if (currentPrototypeProperty === currentCaller) {
					sourceString =
						currentProto.constructor.name +
						'.prototype.' +
						currentKey;
					break;
				}
			}
		}

		currentProto = currentProto.__proto__;

		if (currentProto === null || currentProto.length === 0) {
			break;
		}
	}

	var langauge = type;
	var constructor = null;

	if (currentProto !== null) {
		constructor = currentProto.constructor;
		langauge = getLanguageTag(constructor, type);
	}

	var err = new Error();

	this.event('error', {
		type: type,
		constructor: constructor,
		attribute: currentProto,
		source: sourceString,
		stack: err.stack,
		langauge: langauge,
	});
};

EventListener.prototype.event = function(eventType, eventData) {
	var retValue = true;

	if (eventType in this._listeners) {
		for (var i = this._listeners[eventType].length - 1; i >= 0; i--) {
			if (!this._listeners[eventType][i].call(this, eventData)) {
				retValue = false;
			}
		}
	}

	if (eventType !== 'event') {
		this.event('event', { type: eventType, data: eventData });
	}

	return retValue;
};

EventListener.prototype.addEventListener = function(event, callback) {
	if (!(event in this._listeners)) {
		this._listeners[event] = [];
	}

	if (callback in this._listeners[event]) {
		return;
	}

	this._listeners[event].push(callback);
	return callback;
};

EventListener.prototype.removeEventListener = function(event, callback) {
	if (!(event in this._listeners)) {
		return false;
	}

	var index = this._listeners[event].indexOf(callback);

	if (index > -1) {
		this._listeners[event].splice(index, 1);
	}
};

EventListener.prototype.removeAllEventListeners = function(event) {
	if (event === undefined) {
		this._listeners = {};
		return true;
	}

	if (!(event in this._listeners)) {
		return false;
	}

	this._listeners[event] = [];
	return true;
};
