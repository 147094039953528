function ElementControllerEmbeddedNxpRpkUserButtons(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('enableResetCombo', null, true, 'checkbox', null, null);
	this.addProperty(
		'enableDisplayToggleCombo',
		null,
		true,
		'checkbox',
		null,
		null,
	);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
    ATMO_MK64F_GPIO_EnableResetCombo(ATMO_PROPERTY(undefined, enableResetCombo));
    ATMO_MK64F_GPIO_EnableDisplayToggleCombo(ATMO_PROPERTY(undefined, enableDisplayToggleCombo));
    ATMO_MK64F_UserButton_RegisterAbilityHandle(ATMO_MK64F_SW1_Pushed, ATMO_ABILITY(undefined, topRightPushed));
    ATMO_MK64F_UserButton_RegisterAbilityHandle(ATMO_MK64F_SW2_Pushed, ATMO_ABILITY(undefined, bottomRightPushed));
    ATMO_MK64F_UserButton_RegisterAbilityHandle(ATMO_MK64F_SW3_Pushed, ATMO_ABILITY(undefined, topLeftPushed));
    ATMO_MK64F_UserButton_RegisterAbilityHandle(ATMO_MK64F_SW4_Pushed, ATMO_ABILITY(undefined, bottomLeftPushed));
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('topRightPushed', [], ['topRightPushed']);
	this.setAbilityCode(
		'topRightPushed',
		`
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('bottomRightPushed', [], ['bottomRightPushed']);
	this.setAbilityCode(
		'bottomRightPushed',
		`
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('topLeftPushed', [], ['topLeftPushed']);
	this.setAbilityCode(
		'topLeftPushed',
		`
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('bottomLeftPushed', [], ['bottomLeftPushed']);
	this.setAbilityCode(
		'bottomLeftPushed',
		`
	return ATMO_Status_Success;
    `,
	);
}

ElementControllerEmbeddedNxpRpkUserButtons.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedNxpRpkUserButtons.prototype.constructor = ElementControllerEmbeddedNxpRpkUserButtons;

ElementControllerEmbeddedNxpRpkUserButtons.prototype.DEFAULT_TRIGGER =
	'topRightPushed';
ElementControllerEmbeddedNxpRpkUserButtons.prototype.DEFAULT_ABILITY =
	'topRightPushed';
ElementControllerEmbeddedNxpRpkUserButtons.prototype.DEFAULT_ARGUMENTS = [
	'value',
];
ElementControllerEmbeddedNxpRpkUserButtons.prototype.TYPE =
	'EmbeddedNxpRpkUserButtons';
ElementControllerEmbeddedNxpRpkUserButtons.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'rpk',
];
ElementControllerEmbeddedNxpRpkUserButtons.prototype.HIDDEN = false;
ElementControllerEmbeddedNxpRpkUserButtons.prototype.CATEGORY = 'Rapid IOT';

ElementControllerEmbeddedNxpRpkUserButtons.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedNxpRpkUserButtons.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedNxpRpkUserButtons: 'User Buttons',
			topRightPushed: 'Top Right Pushed',
			bottomRightPushed: 'Bottom Right Pushed',
			topLeftPushed: 'Top Left Pushed',
			bottomLeftPushed: 'Bottom Left Pushed',
			enableResetCombo: 'Kit Reset Button Combo',
			enableDisplayToggleCombo: 'Display Toggle Button Combo',
		},
	},
);
