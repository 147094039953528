function WidgetDeviceTable(id, api, parentWidget, options) {
	WidgetBase.call(this, id, api, parentWidget, options);

	this.titleLabelId = this.generateChildId('titleLabelId');
	this.devicesTableContainerId = this.generateChildId('deviceTableContainer');
	this.searchContainerId = this.generateChildId('deviceTableSearchContainer');
	this.noDevicesOverlayId = this.generateChildId('noDevicesOverlayId');
	this.menuButtonId = this.generateChildId('menuButton');
	this.menuContainerId = this.generateChildId('menuContainer');

	this.selectAllButtonId = this.generateChildId('selectAllButton');
	this.addButtonId = this.generateChildId('addButton');
	this.scanButtonId = this.generateChildId('scanButton');
	this.moveButtonId = this.generateChildId('moveButton');
	this.deleteButtonId = this.generateChildId('deleteButton');
	this.configureDevicesButtonId = this.generateChildId(
		'configureDevicesButton',
	);
	this.showChildrenCheckboxId = this.generateChildId('showChildrenCheckbox');
	this.selectAllLabelId = this.generateChildId('selectAllLabel');

	return;
}

WidgetDeviceTable.prototype = Object.create(WidgetBase.prototype);
WidgetDeviceTable.prototype.constructor = WidgetDeviceTable;

WidgetDeviceTable.prototype.initialize = function(callback) {
	const currentWidget = this;

	const currentUser = this.getMainContainer().getCurrentUser();

	if (!currentUser) {
		callback.call(this, { type: 'noCurrentUser' });
		return;
	}

	this.getOrganizationContext((err, currentOrg) => {
		if (err) {
			callback.call(currentWidget, err);
			return;
		}

		this.renderTemplate(
			{
				titleLabelId: this.titleLabelId,
				titleLabel: this.getLanguageTag('name'),
				selectAllButtonId: this.selectAllButtonId,
				selectAllLabelId: this.selectAllLabelId,
				addButtonId: this.addButtonId,
				scanButtonId: this.scanButtonId,
				moveButtonId: this.moveButtonId,
				deleteButtonId: this.deleteButtonId,
				configureDevicesButtonId: this.configureDevicesButtonId,
				showChildrenCheckboxId: this.showChildrenCheckboxId,
				devicesTableContainerId: this.devicesTableContainerId,
				searchContainerId: this.searchContainerId,
				noDevicesOverlayId: this.noDevicesOverlayId,
				selectAll: this.getLanguageTag('selectAll'),
				selectAllHover: this.getLanguageTag('selectAll'),
				youHaveNoDevicesLabel: this.getLanguageTag('youHaveNoDevices'),
				showSubOrganizations: this.getLanguageTag(
					'showSubOrganizations',
				),
				addDevice: this.getLanguageTag('addDevice'),
				moveDevice: this.getLanguageTag('moveDevice'),
				columns: this.getLanguageTag('columns'),
				configureDevices: this.getLanguageTag('configureDevices'),
				deleteDevice: this.getLanguageTag('deleteDevice'),
				scanForDevice: this.getLanguageTag('scanForDevice'),
				needHelpAddingADeviceLabel: this.getLanguageTag(
					'needHelpAddingADevice',
				),
				canProvisionDevices: currentUser.ability.can(
					'create',
					'Device',
				),
				canMoveDevices: currentUser.ability.can('manage', 'Device'),
				canDeleteDevices: currentUser.ability.can('remove', 'Device'),
				// The reasoning goes that if a user can manage the dashboard,
				// they should manage the device table columns.
				canCustomizeColumns:
					!isPhonegap() &&
					currentUser.ability.can('manage', 'Dashboard'),
				canSeeOrgs:
					currentUser.ability.can('see', 'Organizations') &&
					currentOrg.hasChildren,
				canConfigureDevices:
					!isPhonegap() &&
					currentUser.ability.can('manage', 'Device'),
				menuButtonId: this.menuButtonId,
				menuContainerId: this.menuContainerId,
			},
			WidgetDeviceTable.name,
		);

		this.titleLabel = $(`#${this.titleLabelId}`);
		this.noDevicesOverlay = $(`#${this.noDevicesOverlayId}`);
		this.selectAllButton = $(`#${this.selectAllButtonId}`);
		this.addButton = $(`#${this.addButtonId}`);
		this.scanButton = $(`#${this.scanButtonId}`);
		this.moveButton = $(`#${this.moveButtonId}`);
		this.deleteButton = $(`#${this.deleteButtonId}`);
		this.configureDevicesButton = $(`#${this.configureDevicesButtonId}`);
		this.showChildrenCheckbox = $(`#${this.showChildrenCheckboxId}`);
		this.menuButton = $(`#${this.menuButtonId}`);

		this.selectAllLabel = $(`#${this.selectAllLabelId}`);

		this.config = {
			organizationId: null,
			deviceId: null,
			columns: null, // essentially the device table columns
		};

		this.selectAllButton.hide();
		this.noDevicesOverlay.hide();
		this.moveButton.hide();
		this.deleteButton.hide();
		this.configureDevicesButton.hide();

		const api = currentWidget.getApiV2().apis;

		this.addChildWidget(WidgetMenu, this.menuContainerId, {}, function(
			err,
			menuWidget,
		) {
			menuWidget.hide();

			currentWidget._optionsMenu = menuWidget;

			currentWidget._optionsMenu.setMenu([
				{
					label: this.getLanguageTag('columnSettings'),
					value: 'columnSettings',
				},
				{
					label: getLanguageTag(this.constructor, 'help'),
					value: 'help',
				},
			]);

			currentWidget._optionsMenu.addEventListener(
				'menuEntryClicked',
				(value) => {
					if (value === 'columnSettings') {
						currentWidget.showColumnsSettings();
					} else if (value === 'help') {
						openLinkInNewWindowOrTab(
							'https://docs.atmosphereiot.com/iot-central-organizations/',
						);
					}
				},
			);

			currentWidget.menuButton.on('click', (e) => {
				currentWidget._optionsMenu.toggleVisible();
				e.stopPropagation();
			});

			this.addChildWidget(
				WidgetSearchBox,
				this.searchContainerId,
				{
					onSearch: () => {
						currentWidget.update(() => {});
					},
				},
				function(err, searchBoxWidget) {
					currentWidget.searchBoxWidget = searchBoxWidget;

					currentWidget.addChildWidget(
						WidgetTableDynamic,
						this.devicesTableContainerId,
						{
							hideFilterInput: true,
							selectable: true,
							movableColumns:
								!isPhonegap() &&
								currentUser.ability.can('manage', 'Dashboard'),
							columnMoved: (column) => {
								currentWidget.onColumnMoved(column);
							},
							apiRoute: api.devices.getDevices.bind(
								currentWidget,
							),
							ajaxResponse: async (url, params, response) => {
								// To get the current count of devices selected
								// when "Select All" button is exposed.
								let stats = ``;
								const {
									page,
									limit,
									row_count,
									data,
								} = response;
								// tabulator's "row_count" is really the total records
								const total = row_count;
								const start = (page - 1) * limit + 1;
								const end = (page - 1) * limit + data.length;
								if (total > 0) {
									stats += `Showing ${start} - ${end} of ${total} entries`;
								} else {
									stats = '0 entries';
								}

								currentWidget._currentTotalDevices = total;

								$(`#${currentWidget.devicesTableContainerId}`)
									.find(`.WidgetTableDynamic-footer`)
									.text(stats);
								currentWidget.selectAllLabel.text(
									`${currentWidget.getLanguageTag(
										'selectAll',
									)} (${currentWidget._currentTotalDevices})`,
								);

								return response;
							},
							ajaxSorting: true,
							headerSort: true,
							addParams: () => {
								const showAll = currentWidget.showChildrenCheckbox.prop(
									'checked',
								);

								const params = {
									depth: showAll ? 'all' : '1',
									lastValues: 'all',
									organizationId: getHashCommand().org,
								};

								const searchText = currentWidget.searchBoxWidget.getSearchText();

								if (searchText && searchText.length > 0) {
									params.searchText = searchText;
								}
								return params;
							},
							entryMapper: async (entry) => {
								// Not fetching device image for now
								// In the future it should be fetched
								// asynchronously

								// const device = new Device(
								// 	currentWidget.getApiV2().apis,
								// 	entry,
								// );

								// let img = await device.getImage();
								// entry._deviceImage = currentWidget.deviceImageFormatter(
								// 	img,
								// );

								return entry;
							},
							sortByFrom: function(sorters) {
								// Converts the tabulator parameters into
								// API V2 params for sorting
								// http://tabulator.info/docs/4.0/data#ajax-sort
								let sortBy = [];
								if (sorters && sorters.length) {
									sortBy = sorters.map((entry) => {
										let str = '';

										const segments = entry.field.split('.');
										const type = segments[0];
										let sorterField;

										switch (type) {
											case 'DeviceModel':
												// Currently only the name,
												// description, and createdAt
												// fields are sortable
												sorterField = segments[1];

												// sorterField should be statusInt
												// when sorting by status
												if (
													sorterField === 'statusText'
												) {
													sorterField = 'status';
												}
												break;
											case 'DeviceMeta':
												// Sorting by meta is not supported over the
												// API for now. So this will be disabled
												// anyway. Tweak as needed if we ever decide
												// to support this.
												sorterField = `meta.${segments[1]}`;
												break;
											case 'LastValues':
												// In the future update this
												// if we have support for
												// sorting by lastValues
												sorterField = segments[1];
												break;

											default:
												// By default assume the sorter
												// is the second segment in the path
												console.warn(
													`[WidgetDeviceTable] Path type ${type} not recognized, running default case for sorting.`,
												);
												sorterField = segments[1];
												break;
										}

										if (entry.dir === 'desc') {
											str += '-';
										} else {
											str += '+';
										}

										str += sorterField;
										return str;
									});
								}
								return sortBy;
							},
							rowFormatter: (row) => {
								const rowData = row.getData();
								const element = row.getElement();
								const rowClass = deviceStatus.rowClass(
									rowData.raw.statusText,
								);
								row.getElement().classList.add(rowClass);
							},
							onRowClicked: (entry) => {
								currentWidget.onDeviceRowClicked(entry);
							},
							onRowDblTap: (entry) => {
								currentWidget.onDeviceRowClicked(entry);
							},
							onSelectionChanged: (rows) => {
								if (rows.length > 0) {
									currentWidget.selectAllButton.show();
									currentWidget.moveButton.show();
									currentWidget.deleteButton.show();
									currentWidget.configureDevicesButton.show();
								} else {
									currentWidget.selectAllButton.hide();
									currentWidget.moveButton.hide();
									currentWidget.deleteButton.hide();
									currentWidget.configureDevicesButton.hide();
								}

								let numberOfCheckboxesDisplayed = $(
									`#${currentWidget.devicesTableContainerId}`,
								)
									.find('.tabulator-table')
									.find(':checkbox').length;
								let numberOfCheckboxesChecked = $(
									`#${currentWidget.devicesTableContainerId}`,
								)
									.find('.tabulator-table')
									.find(':checkbox:checked').length;

								if (
									numberOfCheckboxesChecked ===
										numberOfCheckboxesDisplayed &&
									numberOfCheckboxesChecked !== 0
								) {
									currentWidget.selectAllButton.show();
								} else {
									currentWidget.allDevicesSelected = false; //If for any reason we stop selecting all entries then set this flag to false.
									currentWidget.selectAllButton.hide();
								}
							},
							paginationSize: 20,
							columns: [], // This gets set by WidgetDeviceTable.prototype.columns
						},
						function(err, devicesTableWidget) {
							this.devicesTableWidget = devicesTableWidget;

							this.showChildrenCheckbox.on('click', (e) => {
								currentWidget.update(() => {});
							});

							this.selectAllButton.on('click', async function() {
								await currentWidget._onSelectAll();
							});

							this.addButton.on('click', (event) => {
								currentWidget.onAddDevicesClicked();
							});

							this.scanButton.on('click', (event) => {
								currentWidget.onScanDevicesClicked();
							});

							this.deleteButton.on('click', (event) => {
								currentWidget._onRemoveSelectedDevices();
							});

							this.moveButton.on('click', (event) => {
								currentWidget._onMoveSelectedDevices();
							});

							this.configureDevicesButton.on('click', (event) => {
								currentWidget._onConfigureSelectedDevices();
							});

							this.update(function(err) {
								WidgetBase.prototype.initialize.call(
									this,
									callback,
								);
							});
						},
					);
				},
			);
		});
	});
};

WidgetDeviceTable.prototype.columns = async function() {
	const currentWidget = this;
	const columns = [];
	const api = this.getApiV2().apis;
	const orgId = await this.asyncGetOrganizationId();
	let tableConfig = null;

	try {
		tableConfig = await api.organizations.getDeviceTableConfigs({
			id: orgId,
		});
	} catch (error) {
		if (error.status && error.status === 404) {
			tableConfig = {
				organizationId: orgId,
				columns: [
					{
						path: 'DeviceModel.name',
						type: 'DeviceModel',
					},
					{
						path: 'DeviceModel.organization',
						type: 'DeviceModel',
					},
					{
						path: 'DeviceModel.statusText',
						type: 'DeviceModel',
					},
					{
						path: 'DeviceModel.description',
						type: 'DeviceModel',
					},
					{
						path: 'DeviceModel.lastActive',
						type: 'DeviceModel',
					},
					{
						path: 'DeviceModel.createdAt',
						type: 'DeviceModel',
					},
				],
			};
		} else {
			throw error;
		}
	}

	currentWidget.config = tableConfig;

	// Our device table config is from a parent, update the menu
	if (currentWidget.config.organizationId !== orgId) {
		currentWidget._optionsMenu.setMenu(
			[
				{
					label: this.getLanguageTag('columnSettings'),
					value: 'columnSettings',
				},
				{
					label: getLanguageTag(this.constructor, 'help'),
					value: 'help',
				},
			],
			null,
			currentWidget.getLanguageTag('settingsInherited'),
		);
	}

	// We always want the checkbox and icon to be displayed
	columns.push({
		formatter: 'rowSelection',
		field: '_rowSelector',
		titleFormatter: 'rowSelection',
		hozAlign: 'center',
		width: 10,
		responsive: 0,
		headerSort: false,
	});

	// Not fetching device image for now
	// In the future it should be fetched
	// asynchronously
	// columns.push({
	// 	formatter: 'html',
	// 	field: '_deviceImage',
	// 	responsive: 3,
	// 	align: 'center',
	// 	width: 60,
	// 	headerSort: false,
	// });

	tableConfig.columns.forEach((column) => {
		const { path, type } = column;
		const segments = path.split('.');

		switch (segments[0]) {
			case 'DeviceModel':
				const deviceAttr = segments[1];

				switch (deviceAttr) {
					case 'name':
						columns.push({
							title: this.getLanguageTag('deviceName'),
							formatter: 'link',
							field: path,
							formatterParams: {
								labelField: 'name',
							},
							mutator: (value, data, type, params, component) => {
								return currentWidget
									.getMainContainer()
									.getLocationLink('DeviceDashboard', {
										deviceId: data.id,
										thingUuid: data.uuid,
										org: data.organizationId,
									});
							},
							responsive: 0,
							minWidth: 150,
						});
						break;

					case 'organization':
						columns.push({
							title: this.getLanguageTag('organizationName'),
							field: path,
							mutator: (value, data, type, params, component) => {
								return data.organizationName;
							},
							responsive: 1,
							minWidth: 150,
							visible: currentWidget
								.getCurrentUser()
								.ability.can('see', 'Organizations'),
							headerSort: false,
						});
						break;

					case 'statusText':
						columns.push({
							title: this.getLanguageTag('status'),
							formatter: 'html',
							field: path,
							mutator: (value, data, type, params, component) => {
								const { statusText } = data;
								return currentWidget.deviceStatusFormatter(
									statusText,
								);
							},
							responsive: 1,
							minWidth: 100,
							headerSort: true,
						});
						break;

					case 'description':
						columns.push({
							title: this.getLanguageTag('description'),
							field: path,
							mutator: (value, data, type, params, component) => {
								return data.description;
							},
							responsive: 1,
							minWidth: 200,
						});
						break;

					case 'lastActive':
						columns.push({
							title: this.getLanguageTag('lastActive'),
							field: path,
							mutator: (value, data, type, params, component) => {
								return new Date(data.lastActive);
							},
							formatter: 'datetime',
							headerSortStartingDir: 'desc',
							responsive: 1,
							minWidth: 200,
							formatterParams: {
								humanize: true,
								suffix: true,
								invalidPlaceholder: 'Never',
								outputFormat: 'MMMM Do YYYY, h:mm a',
							},
						});
						break;

					case 'createdAt':
						columns.push({
							title: this.getLanguageTag('createdAt'),
							field: path,
							mutator: (value, data, type, params, component) => {
								return new Date(data.createdAt);
							},
							formatter: 'datetime',
							headerSortStartingDir: 'desc',
							responsive: 1,
							minWidth: 200,
							formatterParams: {
								humanize: true,
								suffix: true,
								invalidPlaceholder: 'Never',
								outputFormat: 'MMMM Do YYYY, h:mm a',
							},
						});
						break;

					default:
						console.warn(
							`[WidgetDeviceTable] DeviceModel attribute ${deviceAttr} not recognized, running default case.`,
						);
						columns.push({
							field: path,
							title: this.getLanguageTag(deviceAttr),
							mutator: (value, data, type, params, component) => {
								return data[deviceAttr];
							},
							// If we don't recognize the
							// attribute the user should
							// not sort by it either
							headerSort: false,
						});
						break;
				}

				break;

			case 'DeviceMeta':
				const metaTag = segments[1];
				return columns.push({
					field: path,
					title: this.getLanguageTag(metaTag),
					mutator: (value, data, type, params, component) => {
						if (data && data.meta && data.meta[metaTag]) {
							return data.meta[metaTag];
						}
						return value;
					},
					// APIv2 does not support sorting by meta
					headerSort: false,
				});

				break;

			case 'LastValues':
				const storageName = segments[1];
				const lastValueName = segments[2];

				return columns.push({
					field: path,
					title: lastValueName,
					// APIv2 does not support sorting by lastValues
					headerSort: false,
					mutator: (value, data, type, params, component) => {
						if (data && data.lastValues) {
							data.lastValues.forEach((storage) => {
								const foundStorage = storage[storageName];
								if (foundStorage) {
									value = foundStorage[lastValueName];
									return value;
								}
							});
						}
						return value;
					},
				});

				break;
		}
	});

	return columns;
};

WidgetDeviceTable.prototype.onAddDevicesClicked = function() {
	const currentWidget = this;

	this.getMainContainer().setModalWidget(WidgetAddDevice, {}, function(
		err,
		widget,
	) {
		currentWidget.getMainContainer().showModal();
		widget.addEventListener('dismissed', function() {
			currentWidget.getMainContainer().closeModal();
		});
	});
};

WidgetDeviceTable.prototype.onScanDevicesClicked = function() {
	const currentWidget = this;

	this.getMainContainer().setModalWidget(WidgetProvisioner, {}, function(
		err,
		widget,
	) {
		widget.addEventListener('dismissed', function() {
			currentWidget.getMainContainer().closeModal();
		});

		widget.startSearching(30000, function(err) {
			currentWidget.getMainContainer().showModal();
		});
	});
};

WidgetDeviceTable.prototype.showColumnsSettings = function(callback) {
	callback = callback || function() {};
	const currentWidget = this;
	const api = currentWidget.getApiV2().apis;
	let orgId = getHashCommand().org;

	this.getMainContainer().setModalWidget(
		WidgetSettingsForm,
		{
			buttons: [
				{
					label: currentWidget.getLanguageTag('dismiss'),
					value: 'dismissed',
					classes: ['btn-cancel'],
				},
				{
					label: currentWidget.getLanguageTag('clear'),
					value: 'cleared',
					classes: ['btn-danger'],
				},
				{
					label: currentWidget.getLanguageTag('save'),
					value: 'confirmed',
					classes: ['btn-primary'],
				},
			],
			fields: [
				{
					name: 'variables',
					type: 'deviceLastValuesSelect',
					label: currentWidget.getLanguageTag('selectedDevice'),
					value: {
						deviceId: this.config.deviceId,
						variables: { value: this.config.variables },
					},
					options: {
						variables: [
							{
								name: 'value',
								selectMultiple: true,
								label: getLanguageTag(
									this.constructor,
									'value',
								),
							},
						],
					},
				},
			],
		},
		async function(err, settingsWidget) {
			if (!orgId) {
				orgId = await this.asyncGetOrganizationId();
			}

			settingsWidget.setTitle(
				getLanguageTag(currentWidget.constructor, 'customizeColumns'),
			);

			settingsWidget.addEventListener('dismissed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;
			});

			settingsWidget.addEventListener('cleared', async function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;
				try {
					// Attempt to clear device table config
					await api.organizations.deleteDeviceTableConfigs({
						id: orgId,
					});
					// Successfully cleared column settings
					// Notify the user
					// Update the table
					currentWidget
						.getMainContainer()
						.showPopupInfoMessage(
							currentWidget.getLanguageTag(
								'columnSettingsCleared',
							),
						);
					return currentWidget.update(() => {});
				} catch (error) {
					// If there is no column settings
					// to be cleared due to a 404:
					// Consider this a no-op and let the
					// user know they were cleared anyway.
					if (error && error.status === 404) {
						console.warn(
							`[WidgetDeviceTable] Could not delete device table configurations due to 404.`,
						);
						return currentWidget
							.getMainContainer()
							.showPopupInfoMessage(
								currentWidget.getLanguageTag(
									'columnSettingsCleared',
								),
							);
					}
					// Otherwise something else unexpected happened.
					console.error(
						`Error clearing configuration due to ${(error,
						error.stack)}`,
					);
					return currentWidget
						.getMainContainer()
						.showPopupErrorMessage(
							currentWidget.getLanguageTag(
								'errorClearingColumns',
							),
						);
				}
			});

			settingsWidget.addEventListener('confirmed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;
				const values = this.getValues();

				const config = {
					organizationId: orgId,
					deviceId:
						(values.variables.deviceData || {}).id ||
						currentWidget.config.deviceId ||
						null,
					columns:
						(values.variables.variables || {}).value ||
						currentWidget.config.variables ||
						null,
				};

				const requestBody = { ...config };
				requestBody.columns = [];

				config.columns.forEach((column) => {
					const segments = column.split('.');
					const path = column;
					const type = segments[0];
					requestBody.columns.push({
						path,
						type,
					});
				});

				api.organizations
					.updateDeviceTableConfigs({ id: orgId }, { requestBody })
					.then(() => {
						// Column settings successfully applied
						// Update the table
						// Notify the user
						currentWidget.update(() => {});
						return currentWidget
							.getMainContainer()
							.showPopupInfoMessage(
								currentWidget.getLanguageTag(
									'columnSettingsApplied',
								),
							);
					})
					.catch((error) => {
						console.warn(
							`[WidgetDeviceTable] Error updating device table configs due to ${error}`,
						);
						return currentWidget
							.getMainContainer()
							.showPopupErrorMessage(
								currentWidget.getLanguageTag(
									'errorSettingColumns',
								),
							);
					});
			});

			currentWidget._showingSettings = true;
			this.showModal();

			callback.call(this, false);
			return;
		},
	);
};

WidgetDeviceTable.prototype.onColumnMoved = function(column) {
	const tabulatorInstance = this.devicesTableWidget._table;
	const currentWidget = this;
	const api = currentWidget.getApiV2().apis;

	this.getOrganizationId((err, orgId) => {
		if (err) {
			throw err;
		}

		/*
		 * [
		 * 	{
		 * 		type:'ColumnComponent',
		 * 		_column: { //<Acutal column instance from tabulator>
		 * 			field: 'imgHtml'
		 * 		}
		 * 	{
		 * ]
		 */

		let newColumns = [];

		tabulatorInstance.getColumns().forEach((tColumn) => {
			// Not fetching device image for now
			// In the future it should be fetched
			// asynchronously
			if (
				tColumn._column.field === '_rowSelector'
				// tColumn._column.field === '_deviceImage'
			) {
				return;
			}

			const segments = tColumn._column.field.split('.');

			newColumns.push({
				path: tColumn._column.field,
				type: segments[0],
			});
		});

		const config = {
			organizationId: orgId,
			deviceId: currentWidget.config.deviceId,
			columns: newColumns,
		};

		const requestBody = { ...config };

		api.organizations
			.updateDeviceTableConfigs({ id: orgId }, { requestBody })
			.then(() => {
				currentWidget.update(() => {});
				return currentWidget
					.getMainContainer()
					.showPopupInfoMessage(
						currentWidget.getLanguageTag('columnSettingsApplied'),
					);
			});
	});
};

WidgetDeviceTable.prototype.showOrganizationPicker = function(
	opts,
	onConfirmed,
) {
	const currentWidget = this;

	currentWidget
		.getMainContainer()
		.setModalWidget(
			WidgetOrganizationPicker,
			{ confirmLabel: opts.confirmBtnLabel },
			(err, widget) => {
				currentWidget.getMainContainer().showModal();

				widget.setTitle(opts.title);

				widget.addEventListener('dismissed', function() {
					currentWidget.getMainContainer().hideModal();
				});

				widget.addEventListener('confirmed', async function() {
					currentWidget.getMainContainer().hideModal();
					onConfirmed(widget.pickerContext);
				});
			},
		);
};

WidgetDeviceTable.prototype._onSelectAll = async function() {
	const currentWidget = this;

	this.allDevicesSelected = !this.allDevicesSelected;

	if (this.allDevicesSelected) {
		// Change the button text to clear selected
		// Get api data for all the devices, store it, and use it when doing
		// any other operations
		this.selectAllLabel.text(this.getLanguageTag('clearSelectAll'));

		const subOrgsChecked = this.showChildrenCheckbox.prop('checked');
		const searchText = currentWidget.searchBoxWidget.getSearchText();
		const api = this.getApiV2().apis;

		const params = {
			depth: subOrgsChecked ? 'all' : '1',
			lastValues: 'all',
			organizationId: getHashCommand().org,
		};

		if (searchText && searchText.length > 0) {
			params.searchText = searchText;
		}

		this.allSelectedDevices = await this.getAllDevices();
	} else {
		// Clear button was selected
		this.allSelectedDevices = null;
		// Clear the selected rows on the table
		this.devicesTableWidget._table.deselectRow();

		this.selectAllLabel.text(
			`${this.getLanguageTag('selectAll')} (${
				this._currentTotalDevices
			})`,
		);
	}
};

WidgetDeviceTable.prototype.getAllDevices = async function() {
	const api = this.getApiV2().apis;
	const currentOrg = await this.asyncGetOrganizationContext();
	const depth =
		this.showChildrenCheckbox.prop('checked') === true ? 'all' : 1;
	const initialDevices = await api.devices.getDevices({
		depth,
		organizationId: currentOrg.id,
		limit: 10000,
	});
	const devices = [...initialDevices.data];

	const { totalPages } = initialDevices.meta || {};

	if (totalPages > 1) {
		const additionalDevicePromises = [];
		for (let i = 2; i <= totalPages; i += 1) {
			additionalDevicePromises.push(
				api.devices.getDevices({
					depth,
					organizationId: currentOrg.id,
					limit: 10000,
					page: i,
				}),
			);
		}

		const pages = await Promise.all(additionalDevicePromises);

		pages.forEach((page) => {
			devices.push(...page.data);
		});
	}

	return devices;
};

WidgetDeviceTable.prototype._onMoveSelectedDevices = function() {
	const currentWidget = this;
	const devicesToMove =
		this.allSelectedDevices ||
		this.devicesTableWidget.getSelectedRowsData();
	currentWidget.showOrganizationPicker(
		{
			title: currentWidget.getLanguageTag('moveDevices'),
		},
		async function(targetOrg) {
			const movePromises = devicesToMove.map((deviceData) => {
				const device = new Device(
					currentWidget.getApiV2().apis,
					deviceData,
				);
				device.data.organizationId = targetOrg.id;
				return device.save();
			});

			try {
				await Promise.all(movePromises);
				currentWidget
					.getMainContainer()
					.showPopupInfoMessage(
						currentWidget.getLanguageTag('devicesMoved'),
					);
				currentWidget.update();
			} catch (err) {
				console.error('Error moving devices', err);
				currentWidget
					.getMainContainer()
					.showPopupErrorMessage(
						currentWidget.getLanguageTag('errorMovingDevices'),
					);
			}
		},
	);
};

WidgetDeviceTable.prototype._onConfigureSelectedDevices = function() {
	const devicesToConfigure =
		this.allSelectedDevices ||
		this.devicesTableWidget.getSelectedRowsData();

	const deviceIdList = devicesToConfigure.map((deviceData) => deviceData.id);

	this.getMainContainer().setLocation(LocationMultipleDeviceSettings, {
		deviceIdList,
		org: getHashCommand().org,
	});
};

WidgetDeviceTable.prototype._onRemoveSelectedDevices = function() {
	const currentWidget = this;

	const selectedRowsData =
		this.allSelectedDevices ||
		this.devicesTableWidget.getSelectedRowsData();

	this.getMainContainer().showConfirm(
		{
			message: this.getLanguageTag('confirmDeviceRemove'),
			title: this.getLanguageTag('removeDevices'),
			confirmLabel: this.getLanguageTag('removeDevicesButton'),
			confirmClasses: ['btn-danger'],
		},

		() => {
			const api = currentWidget.getApiV2().apis;
			const deletePromises = selectedRowsData.map((entry) => {
				const device = new Device(api, { id: entry.id });
				return device.delete();
			});
			Promise.all(deletePromises)
				.then(() => {
					currentWidget.event('changed');
					currentWidget.update();
				})
				.catch((err) => {
					console.error(`Error deleting devices due to ${err}`);
					currentWidget
						.getMainContainer()
						.showPopupErrorMessage(
							currentWidget.getLanguageTag(
								'errorRemovingDevices',
							),
						);
				});
		},
	);
};

WidgetDeviceTable.prototype.onDeviceRowClicked = function(data) {
	this.getMainContainer().setLocation(LocationDeviceDashboard, {
		deviceId: data.raw.id,
		thingUuid: data.raw.uuid,
		org: data.raw.organizationId || undefined,
	});
};

// WidgetDeviceTable.prototype.deviceImageFormatter = function(img) {
// Not fetching device image for now
// In the future it should be fetched
// asynchronously
// 	return $.handlebarTemplates[`${WidgetDeviceTable.name}_DeviceEntry`]({
// 		imgSrc: img || './Resources/icons/DefaultDeviceIcon.svg',
// 	});
// };

WidgetDeviceTable.prototype.deviceStatusFormatter = function(value, rowData) {
	if (!value || !value.toLowerCase) {
		return this.getLanguageTag('none');
	}

	return this.getLanguageTag(value.toLowerCase());
};

WidgetDeviceTable.prototype.update = function(callback) {
	const currentWidget = this;
	const tabulatorInstance = this.devicesTableWidget._table;

	callback = callback || function() {};

	this.devicesTableWidget.update(function(err) {
		currentWidget.asyncGetOrganizationId().then((orgId) => {
			currentWidget.columns().then((columns) => {
				tabulatorInstance.setColumns(columns);
				if (currentWidget.config.organizationId !== orgId) {
					// Device table config is inherited, display the inherited entry.
					currentWidget._optionsMenu.setMenu(
						[
							{
								label: currentWidget.getLanguageTag(
									'columnSettings',
								),
								value: 'columnSettings',
							},
							{
								label: currentWidget.getLanguageTag('help'),
								value: 'help',
							},
						],
						null,
						currentWidget.getLanguageTag('settingsInherited'),
					);
				} else {
					// Device table config is unique to the organization
					currentWidget._optionsMenu.setMenu(
						[
							{
								label: currentWidget.getLanguageTag(
									'columnSettings',
								),
								value: 'columnSettings',
							},
							{
								label: currentWidget.getLanguageTag('help'),
								value: 'help',
							},
						],
						null,
					);
				}

				callback.call(currentWidget, err);
			});
		});
	});
};

WidgetDeviceTable.prototype.ICON = './Resources/icons/TableList.svg';

WidgetDeviceTable.prototype.language = deepAssign(
	{},
	WidgetTable.prototype.language,
	{
		'en-US': {
			name: 'Table',
			deviceName: 'Name',
			organization: 'Organization',
			status: 'Status',
			description: 'Description',
			registered: 'Registered',
			options: 'Options',
			root: 'Devices',
			none: 'None',
			info: 'Info',
			good: 'Good',
			minor: 'Minor',
			warning: 'Warning',
			critical: 'Critical',
			missing: 'Missing',
			removeSelectedDevices: 'Delete',
			moveSelectedDevices: 'Move',
			moveDevices: 'Move Devices',
			devicesMoved: 'Devices moved successfully',
			errorMovingDevices:
				'There was an error while moving the selected devices. Please try again.',
			confirmDeviceRemove:
				'Are you sure you want to delete the selected devices?',
			removeDevices: 'Delete Devices',
			removeDevicesButton: 'Delete',
			errorRemovingDevices:
				'There was an error removing the devices from the system. Please try again.',
			removeDeviceRoles: 'Remove Devices',
			confirmDeviceRoleRemoveButton: 'Remove',
			youHaveNoDevices: "You haven't added any devices!",
			noDescription: 'No description set',
			needHelpAddingADevice: `<p>Need help adding a device? Head over to the docs to <a target="_system" id="location_container_noDevicesNoticesubGraphicBottomTextLink" href="https://docs.atmosphereiot.com/docs-device-console/">learn how</a>.</p>`,
			showSubOrganizations: 'Show Sub-Organizations',
			selectAll: 'Select All',
			addDevice: 'Add',
			scanForDevice: 'Scan',
			deleteDevice: 'Delete',
			moveDevice: 'Move',
			columns: 'Columns',
			customizeColumns: 'Customize Columns',
			columnSettingsCleared: 'Column settings cleared.',
			errorSettingColumns: 'Error setting columns.',
			errorClearingColumns: 'Error clearing columns.',
			columnSettingsApplied: 'Column settings applied.',
			organization: 'Organization',
			organizationName: 'Organization Name',
			organizationId: 'Organization ID',
			description: 'Description',
			lastActive: 'Last Active',
			createdAt: 'Registered',
			createdBy: 'Created By',
			platform: 'Platform',
			owner: 'Owner',
			uuid: 'Device UUID',
			id: 'Device ID',
			projectUuid: 'Application UUID',
			versionUuid: 'Version UUID',
			streetAddress: 'Street Address',
			image: 'Device Image',
			protocol: 'Protocol',
			protocols: 'Protocols',
			imageUrl: 'Device Image URL',
			status: 'Status',
			statusInt: 'Status Integer',
			statusText: 'Status Text',
			geoloc: 'Geolocation',
			columnSettings: 'Column Settings',
			help: 'Help',
			settingsInherited: '(Inherited)',
			selectedDevice: 'Selected Device',
			configureDevices: 'Configure',
			clearSelectAll: 'Clear',
		},
	},
);

WidgetDeviceTable.prototype.$_$ = function(done) {
	this.$_SetupWidgetTest(function(err) {});
};
