function ElementControllerEmbeddedPageController(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty(
		'upDownNavigationLabelsEnabled',
		null,
		true,
		'checkbox',
		null,
		null,
	);
	this.addProperty(
		'leftRightNavigationLabelsEnabled',
		null,
		true,
		'checkbox',
		null,
		null,
	);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
	ATMO_UI_PAGE_CONTROLLER_Config_t config;
	config.enableUpDownNavLabels = ATMO_PROPERTY(undefined, upDownNavigationLabelsEnabled);
	config.enableLeftRightNavLabels = ATMO_PROPERTY(undefined, leftRightNavigationLabelsEnabled);
	ATMO_UI_Page_SetConfiguration(&config);
    return ATMO_Status_Success;
	`,
	);

	this.addAbility('displayRootPage', [], []);
	this.setAbilityCode(
		'displayRootPage',
		`
	
	ATMO_UI_Page_DisplayRootPage();
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('navigateUp', [], ['navigateUp']);
	this.setAbilityCode(
		'navigateUp',
		`
	ATMO_UI_Page_ProcessNavButton(ATMO_UI_PAGE_NAV_UP);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('navigateDown', [], ['navigateDown']);
	this.setAbilityCode(
		'navigateDown',
		`
	ATMO_UI_Page_ProcessNavButton(ATMO_UI_PAGE_NAV_DOWN);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('navigateLeft', [], ['navigateLeft']);
	this.setAbilityCode(
		'navigateLeft',
		`
	ATMO_UI_Page_ProcessNavButton(ATMO_UI_PAGE_NAV_LEFT);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('navigateRight', [], ['navigateRight']);
	this.setAbilityCode(
		'navigateRight',
		`
	ATMO_UI_Page_ProcessNavButton(ATMO_UI_PAGE_NAV_RIGHT);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('processTopRightButton', [], ['processTopRightButton']);
	this.setAbilityCode(
		'processTopRightButton',
		`
	ATMO_UI_Page_ProcessUserButton(1);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility(
		'processBottomRightButton',
		[],
		['processBottomRightButton'],
	);
	this.setAbilityCode(
		'processBottomRightButton',
		`
	ATMO_UI_Page_ProcessUserButton(2);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('processTopLeftButton', [], ['processTopLeftButton']);
	this.setAbilityCode(
		'processTopLeftButton',
		`
	ATMO_UI_Page_ProcessUserButton(3);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('processBottomLeftButton', [], ['processBottomLeftButton']);
	this.setAbilityCode(
		'processBottomLeftButton',
		`
	ATMO_UI_Page_ProcessUserButton(4);
	return ATMO_Status_Success;
	`,
	);
}

ElementControllerEmbeddedPageController.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedPageController.prototype.constructor = ElementControllerEmbeddedPageController;

ElementControllerEmbeddedPageController.prototype.DEFAULT_ABILITY =
	'displayRootPage';
ElementControllerEmbeddedPageController.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerEmbeddedPageController.prototype.TYPE =
	'EmbeddedPageController';
ElementControllerEmbeddedPageController.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'rpk',
];
ElementControllerEmbeddedPageController.prototype.HIDDEN = false;
ElementControllerEmbeddedPageController.prototype.CATEGORY = 'Rapid IOT';

ElementControllerEmbeddedPageController.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedPageController.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedPageController: 'Display Page Controller',
			displayFirstPage: 'Display First Page',
			displayNextPage: 'Display Next Page',
			displayRootPage: 'Display Root Page',
			upTitle: 'Sub-Menu Title(Up)',
			downTitle: 'Sub-Menu Title(Down)',
			leftTitle: 'Sub-Menu Title(Left)',
			rightTitle: 'Sub-Menu Title(Right)',
			processTopLeftButton: 'Process Top Left Button',
			processBottomLeftButton: 'Process Bottom Left Button',
			processTopRightButton: 'Process Top Right Button',
			processBottomRightButton: 'Process Bottom Right Button',
			navigateUp: 'Navigate Up',
			navigateDown: 'Navigate Down',
			navigateLeft: 'Navigate Left',
			navigateRight: 'Navigate Right',
			upDownNavigationLabelsEnabled: 'Up-Down Navigation Labels',
			leftRightNavigationLabelsEnabled: 'Left-Right Navigation Labels',
		},
	},
);
