function LocationProjectEditor(id, api, parentWidget, options) {
	LocationBase.call(this, id, api, parentWidget, options);

	this._isCompiling = false;
	this.replaceButtonInputId = this.generateChildId('replaceButtonInput');

	// If there is an error during intialization
	// Don't ask the user to confirm or save before redirecting
	this._initError = false;
}

LocationProjectEditor.prototype = Object.create(LocationBase.prototype);
LocationProjectEditor.prototype.constructor = LocationProjectEditor;

LocationProjectEditor.prototype.initialize = function(callback) {
	const currentLocation = this;
	const currentHashCommand = getHashCommand() || {};

	this._projectExample = currentHashCommand.projectExample || null;
	this._projectId = currentHashCommand.projectId || null;
	this._projectUuid = currentHashCommand.projectUuid || null;
	this._versionUuid = currentHashCommand.versionUuid || null;
	this._organizationId = currentHashCommand.organizationId || null;

	this._hashCommand = currentHashCommand;

	// No projectUuid specified so we should leave to the project manager
	if (this._projectUuid === null && this._projectExample === null) {
		LocationBase.prototype.initialize.call(this, callback);
		this.getMainContainer().setLocation(LocationStudio, {
			org: getHashCommand().org,
		});
		this.getMainContainer().showPopupErrorMessage(
			getLanguageTag(this.constructor, 'noProjectSelected'),
		);
		return;
	}

	if (this._projectExample !== null && this._projectUuid === null) {
		this.importExampleProject(this._projectExample, function(err, data) {
			LocationBase.prototype.initialize.call(this, callback);
			if (err) {
				this.getMainContainer().setLocation(LocationStudio, {
					org: getHashCommand().org,
				});
				this.getMainContainer().showPopupErrorMessage(
					getLanguageTag(
						this.constructor,
						'errorLoadingExampleProject',
					),
				);
				return;
			}

			this.getMainContainer().setLocation(LocationProjectEditor, {
				projectId: data.id,
				projectUuid: data.uuid,
				org: getHashCommand().org,
			});
		});

		return;
	}

	this.setTabWidgets(null, null, function(err) {
		this.hideTitle();

		this.initializeProjectController(function(err) {
			if (err) {
				// FUTURE: Initialize _initError to true and set to false if it gets through this
				// whole callback chain. We really should get out of here if any one of the following init
				// methods fail!
				this._initError = true;
				const redirectErr = { ...err, redirect: LocationStudio };
				this.getMainContainer().showPopupErrorMessage(
					this.getLanguageTag('projectDataIsNull'),
				);
				callback.call(this, redirectErr);
				return;
			}

			this.addHeaderWidget(WidgetProjectEditorControls, {}, function(
				err,
				editorControlsWidget,
			) {
				editorControlsWidget.addEventListener('info', function() {
					currentLocation
						.getMainContainer()
						.setModalWidget(WidgetProjectInfoEditor, {}, function(
							err,
							projectInfoEditorWidget,
						) {
							projectInfoEditorWidget.setProjectController(
								currentLocation._projectController,
								function(err) {
									currentLocation
										.getMainContainer()
										.showModal();

									projectInfoEditorWidget.addEventListener(
										'closed',
										function() {
											currentLocation
												.getMainContainer()
												.hideModal();
										},
									);
								},
							);
						});
				});

				// JS Dom manipulation to override the behavior of the button
				// and allow for file uploads
				this.replaceButton = editorControlsWidget.getEntry('replace');
				this.replaceButton.wrap(
					`<label for="${this.replaceButtonInputId}"></label>`,
				);
				this.replaceButton.append(
					`<input id="${this.replaceButtonInputId}" accept=".atmo" type="file" style="display:none;">`,
				);

				// Binding file upload APIs to the button
				this.$replaceButton = $(this.replaceButton);
				this.$replaceButtonInput = $(`#${this.replaceButtonInputId}`);
				this.$replaceButton.off();
				this.$replaceButton.on('change', (event) => {
					// preventDefault and stopPropagation are
					// executed so that the file upload
					// may be re-attempted by the user
					event.preventDefault();
					event.stopPropagation();
					const file = event.target.files[0];
					if (file) {
						this.getMainContainer().showConfirm(
							{
								title: this.getLanguageTag(
									'replaceProjectConfirmTitle',
								),
								message: formatString(
									this.getLanguageTag(
										'replaceProjectMessage',
									),
									this._projectController.getName(),
									file.name,
								),
								confirmLabel: this.getLanguageTag('confirm'),
								dismissLabel: this.getLanguageTag('cancel'),
							},
							function() {
								// confirmed
								currentLocation.replaceProject(
									file,
									(err, data) => {
										if (err) {
											console.debug(err);
											// Report error visually
											if (err.type) {
												// Known error type
												return currentLocation
													.getMainContainer()
													.showPopupErrorMessage(
														currentLocation.getLanguageTag(
															err.type,
														),
													);
											}
											// Unexpected error
											return currentLocation
												.getMainContainer()
												.showPopupErrorMessage(
													currentLocation.getLanguageTag(
														'errorReplacingProject',
													),
												);
										}

										currentLocation
											.getMainContainer()
											.setLocation(
												LocationProjectEditor,
												{
													projectId: data.projectId,
													projectUuid:
														data.projectUuid,
													org: getHashCommand().org,
												},
											);

										location.reload();
									},
								);
							},
							function() {
								// dismissed
								// Reset the state of the <input> element.
								// So that one may re-attempt importing the same file again.
								currentLocation.$replaceButtonInput.val('');
							},
							function() {
								// callback
							},
						);
					}
				});

				editorControlsWidget.addEventListener('export', function() {
					const projectData = currentLocation._projectController.export();
					saveObjectAsJSON(
						`${currentLocation._projectController._name}.atmo`,
						projectData,
					);
				});

				editorControlsWidget.addEventListener('save', function() {
					currentLocation._projectController.save(function(err) {
						if (err) {
							console.error(JSON.stringify(err));
							currentLocation
								.getMainContainer()
								.showPopupErrorMessage(
									getLanguageTag(
										currentLocation.constructor,
										'errorSavingProject',
									),
								);
							return;
						}

						currentLocation
							.getMainContainer()
							.showPopupInfoMessage(
								getLanguageTag(
									currentLocation.constructor,
									'projectSaved',
								),
							);
					});
				});

				editorControlsWidget.addEventListener('compile', function() {
					if (currentLocation._isCompiling) {
						currentLocation
							.getMainContainer()
							.showPopupInfoMessage(
								getLanguageTag(
									currentLocation.constructor,
									'isCompiling',
								),
							);
						return;
					}

					currentLocation._isCompiling = true;

					currentLocation
						.getMainContainer()
						.showPopupMessage(
							getLanguageTag(
								currentLocation.constructor,
								'compilingStarted',
							),
							'blue',
							0,
						);

					currentLocation._projectController.save(function(err) {
						if (err) {
							currentLocation._isCompiling = false;
							console.error(JSON.stringify(err));
							currentLocation
								.getMainContainer()
								.showPopupErrorMessage(
									getLanguageTag(
										currentLocation.constructor,
										'errorSavingProject',
									),
								);
							return;
						}

						currentLocation._projectController.compile(function(
							err,
						) {
							currentLocation._isCompiling = false;

							if (err) {
								if (err.error) {
									if (err.planeName !== undefined) {
										const planeController = currentLocation._projectController.getPlane(
											err.planeName,
										);

										if (planeController !== null) {
											planeController.processBuildError(
												err,
											);
										}
									}
								}

								if (err.error && err.error.length > 0) {
									currentLocation
										.getMainContainer()
										.setModalWidget(
											WidgetCompileReport,
											{},
											function(
												setModalWidgetErr,
												compileReportWidget,
											) {
												compileReportWidget.addEventListener(
													'closed',
													function() {
														currentLocation
															.getMainContainer()
															.hideModal();
													},
												);
												compileReportWidget.updateCompileReport(
													err,
												);
												currentLocation
													.getMainContainer()
													.showModal();
											},
										);
								} else if (err && err.type) {
									currentLocation
										.getMainContainer()
										.showPopupErrorMessage(
											getLanguageTag(
												this.constructor,
												err.type,
											),
										);
								} else if (err) {
									currentLocation
										.getMainContainer()
										.showPopupErrorMessage(
											JSON.stringify(err),
										);
								}

								currentLocation
									.getMainContainer()
									.showPopupErrorMessage(
										getLanguageTag(
											currentLocation.constructor,
											'errorCompilingProject',
										),
									);
								return;
							}

							currentLocation
								.getMainContainer()
								.showPopupMessage(
									getLanguageTag(
										currentLocation.constructor,
										'compilingComplete',
									),
									'green',
									0,
								);
						});
					});
				});

				editorControlsWidget.addEventListener('exit', function() {
					if (!currentLocation.getMainContainer().setLocationBack()) {
						currentLocation
							.getMainContainer()
							.setLocation(LocationStudio, {
								org: getHashCommand().org,
							});
					}
				});

				if (err) {
				}

				LocationBase.prototype.initialize.call(this, callback);
			});
		});
	});
};

LocationProjectEditor.prototype.remove = function(callback) {
	const currentLocation = this;

	if (
		this._projectController &&
		!this._projectController.isSaved() &&
		!this._initError
	) {
		this.getMainContainer().showConfirm(
			{
				title: getLanguageTag(this.constructor, 'confirmTitle'),
				message: getLanguageTag(
					this.constructor,
					'confirmUnsavedProject',
				),
				confirmLabel: getLanguageTag(this.constructor, 'save'),
				dismissLabel: getLanguageTag(this.constructor, 'discard'),
			},

			function() {
				currentLocation._projectController.save(function(err) {
					LocationBase.prototype.remove.call(
						currentLocation,
						callback,
					);

					if (err) {
						console.error(JSON.stringify(err));
						currentLocation
							.getMainContainer()
							.showPopupErrorMessage(
								getLanguageTag(
									currentLocation.constructor,
									'errorSavingProject',
								),
							);
						return;
					}

					currentLocation
						.getMainContainer()
						.showPopupInfoMessage(
							getLanguageTag(
								currentLocation.constructor,
								'projectSaved',
							),
						);
				});
			},

			function() {
				//We do not continue the callback, and we force the hash command back
				//to where it original was, this allows us to ignore the request to
				//change the location.
				// currentLocation.getMainContainer().forceHashCommand(currentLocation._hashCommand);

				LocationBase.prototype.remove.call(currentLocation, callback);
			},

			function() {},
		);

		return;
	}

	LocationBase.prototype.remove.call(this, callback);
};

LocationProjectEditor.prototype.importExampleProject = function(
	projectFileName,
	callback,
) {
	const currentLocation = this;
	this.getAPI()
		.getAPIRoute('/user/create/examples/:projectFileName')
		.get(projectFileName, function(err, projectData) {
			if (err) {
				console.log(err);
				callback.call(currentLocation, err);
				return;
			}

			const newProjectController = new ProjectController(
				currentLocation.getAPI(),
				currentLocation.getApiV2(),
			);
			newProjectController.import(projectData);
			newProjectController.add(projectData.name, function(err, data) {
				if (err) {
					console.log(err);
					return;
				}
				data.id = newProjectController.getProjectId();
				data.uuid = newProjectController.getProjectUuid();
				callback.call(currentLocation, err, data);
			});
		});
};

LocationProjectEditor.prototype.replaceProject = function(file, callback) {
	const currentLocation = this;

	if (!file) {
		return callback.call(currentLocation, {
			type: 'invalidFileImportGenericError',
		});
	}

	const fileName = file.name;
	if (checkForFileType(fileName, ['.atmo']) === false) {
		return callback.call(currentLocation, {
			type: 'invalidFileImportExtensionError',
		});
	}

	const reader = new FileReader();
	reader.readAsText(file);
	reader.onload = (readerEvent) => {
		let projectData;
		try {
			// Parse the binary data from the readerEvent into a JavaScript object
			projectData = JSON.parse(readerEvent.target.result.toString());
		} catch (error) {
			return callback.call(currentLocation, {
				type: 'invalidFileImportJSONError',
			});
		}

		// Compare project types
		const projectType = projectData.meta.projectTypeId;
		const currentProjectType = this._projectController.getMetaValue(
			'projectTypeId',
		);

		if (projectType !== currentProjectType) {
			// Are the project types matching? Don't want to have
			// to deal with the complexity of adding and removing
			// planes in Designer View and other areas.
			return callback.call(currentLocation, {
				type: 'newProjectTypeCannotBeDifferent',
			});
		}

		const newProjectController = new ProjectController(
			currentLocation.getAPI(),
			currentLocation.getApiV2(),
		);
		newProjectController.import(projectData);
		newProjectController._id = currentLocation._projectController.getProjectId();
		newProjectController._projectUuid = currentLocation._projectController.getProjectUuid();
		newProjectController.save((err) => {
			const data = {
				projectId: newProjectController.getProjectId(),
				projectUuid: newProjectController.getProjectUuid(),
			};
			return callback.call(currentLocation, err, data);
		});
	};
};

LocationProjectEditor.prototype.getParentLocation = function() {
	return LocationStudio;
};

LocationProjectEditor.prototype.onPlaneAdded = function(
	planeName,
	planeController,
) {
	this.addTabbedWidget(
		WidgetPlaneEditor,
		getLanguageTag(planeController.constructor, 'defaultName'),
		planeName,
		{ icon: planeController.PLANE_ICON },
		function(err, planeEditorWidget) {
			planeEditorWidget.setPlaneController(planeController);
		},
	);
};

LocationProjectEditor.prototype.onPlaneRemoved = function(
	planeName,
	planeController,
) {};

LocationProjectEditor.prototype.onImported = function(eventData) {};

LocationProjectEditor.prototype.onImportError = function(eventData) {};

LocationProjectEditor.prototype.initializeProjectController = function(
	callback,
) {
	const currentLocation = this;

	this._projectController = new ProjectController(
		this.getAPI(),
		this.getApiV2(),
	);

	this.currentNameSetListener = this._projectController.addEventListener(
		'nameSet',
		function(nameSetEventData) {
			currentLocation.setTitle(nameSetEventData.name);
			currentLocation.showTitle();
		},
	);

	this.currentPlaneAddedListener = this._projectController.addEventListener(
		'planeAdded',
		function(data) {
			currentLocation.onPlaneAdded(data.name, data.controller);
		},
	);

	this.currentPlaneRemovedListener = this._projectController.addEventListener(
		'planeRemoved',
		function(data) {
			currentLocation.onPlaneRemoved(data.name, data.controller);
		},
	);

	this.currentImportedListener = this._projectController.addEventListener(
		'imported',
		function(e) {
			currentLocation.onImported(e);
		},
	);

	this.currentImportErrorListener = this._projectController.addEventListener(
		'importError',
		function(e) {
			currentLocation.onImportError(e);
		},
	);

	this._projectController.load(this._projectId, function(err) {
		if (err) {
			callback.call(currentLocation, err);
			return;
		}
		currentLocation.setTitle(this.getName());
		currentLocation.showTitle();
		callback.call(currentLocation, err);
	});
};

LocationProjectEditor.prototype.NAVBAR_LOCATION = LocationStudio;

LocationProjectEditor.prototype.language = deepAssign(
	{},
	LocationBase.prototype.language,
	{
		'en-US': {
			name: 'Application Editor',
			projectSaved: 'Application saved',
			errorSavingProject: 'There was an error saving the application.',
			compilingStarted:
				'Application saved and compiling started, please wait...',
			isCompiling:
				'Application has not finished compiling, please wait...',
			compilingComplete: 'Application compiled',
			confirmTitle: 'Save Changes',
			confirmUnsavedProject:
				'Save changes to this application before leaving?',
			errorCompilingProject:
				'There was an error compiling your application.',
			discard: 'Discard',
			compilationFailure: 'Compilation failure',
			buildError:
				'Application failed to build. Check code editor for more information.',
			buildServersBusy:
				'The build servers are busy, please try again shortly.',
			code: 'Code',
			line: 'Line',
			invalidPropertyError: 'Invalid property',
			badJSONResponseFromBuildServer:
				'Application failed to compile, bad response from build server.',
			bleCharacteristicConfigError:
				'BLE characteristic configuration error. See platform documentation for details.',
			projectDataIsNull: 'Unable to load application data.',
			noProjectSelected: 'Invalid application specified',
			errorLoadingExampleProject:
				'There was an error loading the example application.',
			newProjectTypeCannotBeDifferent:
				'Application type cannot be different from the currently open one. Please select a application of the same type.',
			invalidFileImportGenericError:
				'Invalid file imported. Please select a valid .atmo file then try again.',
			invalidFileImportExtensionError:
				'Invalid file imported. Please select a file with the .atmo extension then try again.',
			invalidFileImportJSONError:
				'Invalid file imported. Please select a valid .atmo file then try again.',
			errorReplacingProject:
				'Unspecified error when replacing application. Please make sure the file is valid and then try again.',
			replaceProjectConfirmTitle: 'Replace application',
			replaceProjectMessage:
				'Are you sure you want to replace the current application ({0}) with the following file: ({1})?',
			confirm: 'Confirm',
			cancel: 'Cancel',
		},
	},
);
