function ElementAppBuffer(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);
}

ElementAppBuffer.prototype = Object.create(ElementAppBase.prototype);
ElementAppBuffer.prototype.constructor = ElementAppBuffer;

ElementAppBuffer.prototype.push = function(value, callback) {
	this._getProperty('buffer', function(err, data) {
		data.push(value);

		this._setProperty('pushedValue', value, function(err) {
			this._setProperty('value', value, function(err) {
				this._setProperty('buffer', data, function(err) {
					this.trigger('pushed', callback);
				});
			});
		});
	});
};

ElementAppBuffer.prototype.pop = function(callback) {
	this._getProperty('buffer', function(err, data) {
		var poppedValue = data.pop();

		this._setProperty('poppedValue', poppedValue, function(err) {
			this._setProperty('value', poppedValue, function(err) {
				this._setProperty('buffer', data, function(err) {
					this.trigger('popped', callback);
				});
			});
		});
	});
};

ElementAppBuffer.prototype.shift = function(callback) {
	this._getProperty('buffer', function(err, data) {
		var shiftValue = data.shift();

		this._setProperty('shiftValue', shiftValue, function(err) {
			this._setProperty('value', shiftValue, function(err) {
				this._setProperty('buffer', data, function(err) {
					this.trigger('shifted', callback);
				});
			});
		});
	});
};

ElementAppBuffer.prototype.splice = function(value, length, callback) {
	this._getProperty('buffer', function(err, data) {
		var splicedValue = data.splice(value, length);

		this._setProperty('splicedValue', splicedValue, function(err) {
			this._setProperty('value', splicedValue, function(err) {
				this._setProperty('buffer', data, function(err) {
					this.trigger('spliced', callback);
				});
			});
		});
	});
};

ElementAppBuffer.prototype.setIndex = function(index, value, callback) {
	this._getProperty('buffer', function(err, data) {
		if (!(index < data.length)) {
			this._triggerError({ type: 'indexLargerThanBuffer' }, callback);
			return;
		}

		var oldIndexValue = data[index];
		data[index] = value;

		this._setProperty('oldIndexValue', oldIndexValue, function(err) {
			this._setProperty('value', oldIndexValue, function(err) {
				this._setProperty('buffer', data, function(err) {
					this.trigger('indexSet', callback);
				});
			});
		});
	});
};

ElementAppBuffer.prototype.getIndex = function(index, callback) {
	this._getProperty('buffer', function(err, data) {
		if (!(index < data.length)) {
			this._triggerError({ type: 'indexLargerThanBuffer' }, callback);
			return;
		}

		var indexValue = data[index];

		this._setProperty('indexValue', indexValue, function(err) {
			this._setProperty('value', indexValue, function(err) {
				this._setProperty('buffer', data, function(err) {
					this.trigger('indexRetrieved', callback);
				});
			});
		});
	});
};

ElementAppBuffer.prototype.clear = function(callback) {
	this._setProperty('buffer', [], function(err) {
		this.trigger('cleared', callback);
	});
};
