function ElementAppInterval(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);

	var currentElement = this;

	this._currentInterval = null;
}

ElementAppInterval.prototype = Object.create(ElementAppBase.prototype);
ElementAppInterval.prototype.constructor = ElementAppInterval;

ElementAppInterval.prototype._initialize = function(callback) {
	var currentElement = this;

	this._getProperty('startOnInitialize', function(err, startOnInitialize) {
		this.start(function(err) {
			ElementAppBase.prototype._initialize.call(this, callback);
		});
	});
};

ElementAppInterval.prototype._remove = function() {
	ElementAppBase.prototype._remove.call(this);

	if (this._currentInterval !== null) {
		clearInterval(this._currentInterval);
		this._currentInterval = null;
	}
};

ElementAppInterval.prototype._setEditable = function(enabled) {
	ElementAppBase.prototype._setEditable.call(this, enabled);

	var currentElement = this;

	if (this._currentInterval !== null) {
		clearInterval(this._currentInterval);
		this._currentInterval = null;
	}

	if (!enabled) {
		this._initialize(function() {});
	}
};

ElementAppInterval.prototype._onPropertyChange = function() {
	ElementAppBase.prototype._onPropertyChange.call(this);

	this.setTime(this._properties.time, function(err) {});
};

ElementAppInterval.prototype.start = function(callback) {
	var currentElement = this;

	this._getProperty('time', function(err, time) {
		if (err) {
			callback.call(this, err);
			return;
		}

		if (this._currentInterval !== null) {
			clearInterval(this._currentInterval);
			this._currentInterval = null;
		}

		this._currentInterval = setInterval(function() {
			currentElement.trigger('interval', function(err) {});
		}, time);

		this.trigger('started', callback);
	});
};

ElementAppInterval.prototype.pause = function(callback) {
	if (this._currentInterval !== null) {
		clearInterval(this._currentInterval);
		this._currentInterval = null;
	}

	this.trigger('paused', callback);
};

ElementAppInterval.prototype.set = function(value, callback) {
	if (Boolean(value) && this._currentInterval === null) {
		this.start(callback);
	} else if (Boolean(value) === false) {
		this.pause(callback);
	}
};

ElementAppInterval.prototype.setTime = function(milliseconds, callback) {
	this._setProperty('time', milliseconds, function(err) {
		if (err) {
			callback.call(this, err);
			return;
		}

		this.start(callback);
		return;
	});
};
