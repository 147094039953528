function ElementControllerAppUICombobox(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppUIBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);
}

ElementControllerAppUICombobox.prototype = Object.create(
	ElementControllerAppUIBase.prototype,
);
ElementControllerAppUICombobox.prototype.constructor = ElementControllerAppUICombobox;

ElementControllerAppUICombobox.prototype.NAME = 'Combobox';
ElementControllerAppUICombobox.prototype.TYPE = 'AppUICombobox';
ElementControllerAppUICombobox.prototype.HIDDEN = true;

ElementControllerAppUICombobox.prototype.language = deepAssign(
	{},
	ElementControllerAppUIBase.prototype.language,
	{
		'en-US': {
			AppUICombobox: 'Combobox',
		},
	},
);
