function WidgetAddDevice(id, api, parent, options) {
	WidgetPanelBase.call(this, id, api, parent, options);

	this.selectedProject = null;
	this.deviceNameInput = null;
	this._tableWidget = null;
	this.invalidationContainer = null;
	this._buttonsFooter = null;
	this.tableContainerId = this.generateChildId('tableContainer');
	this.projectDetailsContainerId = this.generateChildId(
		'projectDetailsContainer',
	);
	this.invalidationContainerId = this.generateChildId(
		'invalidationContainer',
	);
	this.buttonsFooterContainerId = this.generateChildId(
		'footerButtonsContainer',
	);
	this.deviceNameInputId = this.generateChildId('deviceNameInputId');
	this.renderTemplate(
		{
			selectProjectTypeLabel: getLanguageTag(
				this.constructor,
				'selectProjectType',
			),
			deviceNameInputId: this.deviceNameInputId,
			tableContainerId: this.tableContainerId,
			projectDetailsContainerId: this.projectDetailsContainerId,
			invalidationContainerId: this.invalidationContainerId,
			buttonsFooterContainerId: this.buttonsFooterContainerId,
		},
		WidgetAddDevice.name,
	);
	this.projectDetailsContainer = $(`#${this.projectDetailsContainerId}`);
	this.invalidationContainer = $(`#${this.invalidationContainerId}`);
}

WidgetAddDevice.prototype = Object.create(WidgetPanelBase.prototype);
WidgetAddDevice.prototype.constructor = WidgetAddDevice;

WidgetAddDevice.prototype.initialize = function(callback) {
	const currentWidget = this;
	this.addChildWidget(
		WidgetButtonsFooter,
		this.buttonsFooterContainerId,
		{},
		function(err, buttonsFooterWidget) {
			this._buttonsFooter = buttonsFooterWidget;

			this._buttonsFooter.setButtons([
				{
					label:
						this.getOptions().dismissLabel ||
						getLanguageTag(this.constructor, 'dismiss'),
					value: 'dismissed',
					classes: [`btn-cancel`],
				},

				{
					label:
						this.getOptions().confirmLabel ||
						getLanguageTag(this.constructor, 'create'),
					value: 'confirmed',
					classes: ['btn-primary'],
				},
			]);

			currentWidget.disableSubmitButton(true);

			this._buttonsFooter.addEventListener(
				'footerButtonPressed',
				async function(value) {
					if (value === 'confirmed') {
						await currentWidget.onSubmitButtonClicked();
					} else {
						currentWidget.event('dismissed');
					}
				},
			);

			this.addChildWidget(
				WidgetTable,
				this.tableContainerId,
				{
					filterInputPlaceHolder: this.getLanguageTag(
						'filterInputPlaceHolder',
					),
					noData: this.getLanguageTag('noData'),
					showRowSelected: true,
				},

				function(err, tableWidget) {
					this._tableWidget = tableWidget;

					this.deviceNameInput = $(`#${this.deviceNameInputId}`);
					this.deviceNameInput.on('input', () => {
						currentWidget.onInputChanged();
					});

					this.updateTable(function(err) {
						WidgetPanelBase.prototype.initialize.call(
							this,
							callback,
						);
					});
				},
			);
		},
	);
};

WidgetAddDevice.prototype.updateTable = function(callback) {
	const currentWidget = this;
	callback = callback || function() {};
	const api = currentWidget.getApiV2();
	let columns = ['name', 'updatedAt'];

	// Only filter App Only projects AKA API Projects.
	const query = {
		depth: 'all',
		organizationId: null,
		filter: {
			type: 'API Project',
		},
	};

	api.apis.projects
		.getProjects(query)
		.then((response) => {
			const projects = response.data;

			this._tableWidget.setTable(
				columns,
				{
					name: {
						label: getLanguageTag(this.constructor, 'projectName'),
						sortable: true,
						columnWidth: 1.3,
					},
					updatedAt: {
						label: getLanguageTag(this.constructor, 'updatedAt'),
						sortable: true,
						columnWidth: 1.0,
						formatter: (value, rowData) => {
							value, rowData;
							return moment(rowData.updatedAt).fromNow();
						},
					},
				},
				projects,
				'descending',
				'updatedAt',
			);
			this._tableWidget.addEventListener('rowClicked', function(e) {
				currentWidget.selectedProject = e.rowData;
				currentWidget.onInputChanged();
				currentWidget.setProjectDetails(e.rowData);
			});
			this.setProjectDetails(null);
			callback.call(this, false);
			return;
		})
		.catch((err) => {
			callback.call(this, err);
			return;
		});
};

WidgetAddDevice.prototype.setProjectDetails = function(entry, callback) {
	const api = this.getApiV2();
	if (entry) {
		this._selectedProjectType = entry;
	} else {
		entry = {};
	}
	callback = callback || function() {};

	const context = {
		title: this.getLanguageTag('details'),
		projectImageSrc: './Resources/images/APIProject.png',
		createdAtLabel: this.getLanguageTag('projectCreated'),
		updatedAtLabel: this.getLanguageTag('updatedAt'),
	};

	if (entry && entry.id) {
		api.apis.projects
			.getProjectImage({ id: entry.id })
			.then((projectImage) => {
				context.type = entry.type;
				context.projectImageSrc = projectImage;
				context.createdAt = entry.createdAt
					? moment(entry.createdAt).fromNow()
					: null;
				context.updatedAt = entry.updatedAt
					? moment(entry.updatedAt).fromNow()
					: null;
				this.renderTemplate(
					context,
					WidgetAddDevice.name + '_ProjectDetails',
					this.projectDetailsContainer,
				);
				callback.call(this, false);
				return;
			})
			.catch((err) => {
				context.type = entry.type;
				context.createdAt = entry.createdAt
					? moment(entry.createdAt).fromNow()
					: null;
				context.updatedAt = entry.updatedAt
					? moment(entry.updatedAt).fromNow()
					: null;
				this.renderTemplate(
					context,
					WidgetAddDevice.name + '_ProjectDetails',
					this.projectDetailsContainer,
				);
				callback.call(this, false);
				return;
			});
	} else {
		context.type = this.getLanguageTag('selectAProjectTypeFirst');
		context.createdAt = null;
		context.updatedAt = null;
		this.renderTemplate(
			context,
			WidgetAddDevice.name + '_ProjectDetails',
			this.projectDetailsContainer,
		);
		callback.call(this, false);
	}
};

WidgetAddDevice.prototype.getInputVals = function() {
	const deviceName = this.deviceNameInput.val();
	const project = this.selectedProject;
	return { deviceName, project };
};

WidgetAddDevice.prototype.onInputChanged = function() {
	const isValid = this.validate();
	if (!isValid) {
		this.disableSubmitButton(true);
	} else {
		this.disableSubmitButton(false);
	}
};

WidgetAddDevice.prototype.onSubmitButtonClicked = async function() {
	try {
		const api = this.getApiV2();
		const { deviceName, project } = this.getInputVals();
		const registrationData = {
			name: deviceName,
			projectUuid: project.uuid,
			organizationId: await this.asyncGetOrganizationId(),
		};
		const response = await api.apis.devices.postDevices(
			{},
			{ requestBody: registrationData },
		);
		if (response.token) {
			this.onFinished(response);
		}
	} catch (error) {
		console.error(error);
		let langTag;
		if (
			// U...G...H... *sigh* JavaScript...
			error.response &&
			error.response.obj &&
			error.response.obj.message &&
			error.response.obj.message.includes('device limit')
		) {
			langTag = 'deviceLimitErrorMsg';
		} else {
			langTag = 'registrationErrorMsg';
		}
		this.getMainContainer().showPopupErrorMessage(
			this.getLanguageTag(langTag),
		);
	}
};

WidgetAddDevice.prototype.validate = function() {
	let isValid = true;
	const { deviceName, project } = this.getInputVals();
	if (!deviceName || deviceName.length < 1) {
		this.showInvalidation(this.getLanguageTag('provideDeviceName'));
		isValid = false;
		return isValid;
	}
	if (!project || project.length < 0) {
		this.showInvalidation(this.getLanguageTag('pleaseSelectProject'));
		isValid = false;
		return isValid;
	}
	this.showInvalidation(``);
	return isValid;
};

WidgetAddDevice.prototype.showInvalidation = function(invalidation) {
	this.invalidationContainer.text(invalidation);
};

WidgetAddDevice.prototype.disableSubmitButton = function(bool) {
	this._buttonsFooter.setButtonDisabled('confirmed', bool);
};

WidgetAddDevice.prototype.onFinished = function(response) {
	this.getMainContainer().closeModal();
	this.getMainContainer().setModalWidget(
		WidgetAddDeviceSuccess,
		{
			deviceId: response.id,
			deviceUuid: response.uuid,
			deviceToken: response.token,
		},
		() => {},
	);
	this.getMainContainer().showModal();
};

WidgetAddDevice.prototype.language = deepAssign(
	{},
	WidgetPanelBase.prototype.language,
	{
		'en-US': {
			name: 'Register Device',
			selectProjectType: 'Select Application Type',
			projectCreated: 'Application Created',
			projectUpdated: 'Application Updated',
			projectDetails: 'Application Details',
			updatedAt: 'Updated',
			type: 'Type',
			projectType: 'Application Type',
			projectName: 'Application Name',
			selectAProjectTypeFirst: 'Select an Application!',
			filterInputPlaceHolder: 'Search for applications...',
			noData: 'No Applications found',
			details: 'Details',
			provideDeviceName: 'Please provide a device name!',
			pleaseSelectProject: 'Please select an application!',
			registrationSuccess: 'Registration Successful!',
			deviceId: `Device ID: {0}`,
			successMessage:
				'Please copy the token above and use it to <a href="{0}" target="_system">authenticate your device</a>.',
			onlyTimeHaveAccessToToken:
				'This is the only time you will have visible access to the device token!',
			registrationErrorMsg: 'There was an error registering the device.',
			deviceLimitErrorMsg: 'You have reached your device limit.',
			finish: 'Finish',
			copiedToClipboard: 'Copied to clipboard',
		},
	},
);
