function LocationChangePassword(id, api, parentWidget, options) {
	LocationBase.call(this, id, api, parentWidget, options);

	this._loginWidget = null;
}

LocationChangePassword.prototype = Object.create(LocationBase.prototype);
LocationChangePassword.prototype.constructor = LocationChangePassword;

LocationChangePassword.prototype.initialize = function(callback) {
	this.getContainer().html(
		`<div id="${this.generateChildId('changePassword')}"></div>`,
	);

	this.addChildWidget(
		WidgetChangePassword,
		this.generateChildId('changePassword'),
		{},
		function(err, loginWidget) {
			this._loginWidget = loginWidget;

			LocationBase.prototype.initialize.call(this, callback);
		},
	);
};

LocationChangePassword.prototype.FULLSCREEN = true;
