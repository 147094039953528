//Copyright 2018 Atmosphere IoT Corp.
//All rights reserved
// jshint esversion: 6

function WidgetUserAccountOverviewGeneralUsageDevicesStorageUsage(
	id,
	api,
	parent,
	options,
) {
	WidgetUsage.call(this, id, api, parent, options);

	this._interval = null;
}

WidgetUserAccountOverviewGeneralUsageDevicesStorageUsage.prototype = Object.create(
	WidgetUsage.prototype,
);
WidgetUserAccountOverviewGeneralUsageDevicesStorageUsage.prototype.constructor = WidgetUserAccountOverviewGeneralUsageDevicesStorageUsage;

WidgetUserAccountOverviewGeneralUsageDevicesStorageUsage.prototype.initialize = function(
	callback,
) {
	const currentWidget = this;

	this.update(function(err, data) {
		this._interval = setInterval(function() {
			if (currentWidget.isVisible() && isActive()) {
				currentWidget.update(function() {});
			}
		}, 60000);

		WidgetUsage.prototype.initialize.call(currentWidget, callback);
	});
};

WidgetUserAccountOverviewGeneralUsageDevicesStorageUsage.prototype.remove = function(
	callback,
) {
	const currentWidget = this;

	if (this._interval) {
		clearInterval(this._interval);
		this._interval = null;
	}

	WidgetUsage.prototype.remove.call(currentWidget, callback);
};

WidgetUserAccountOverviewGeneralUsageDevicesStorageUsage.prototype.update = function(
	callback,
) {
	const currentWidget = this;
	const api = currentWidget.getApiV2().apis;

	callback = callback || function() {};

	let cardLimit,
		cardCount,
		percent = null;
	let readableLimitString,
		readableCountString = null;


	this.getOrganizationContext((err, org) => {
		if(err) {
			callback.call(currentWidget, err);
			return;
		}

		api.organizations.getUsage({id: org.id}).then((orgWithUsage) => {
			const perDeviceLimit = 0;
		
			cardLimit = orgWithUsage.dataUsageMbLimit;
			cardCount = orgWithUsage.dataUsageMb;
			percent = 1;
	
			percent = (cardCount / cardLimit) * 100;
	
			let storageUsageRatio = 1;
	
			if (cardLimit !== 0) {
				storageUsageRatio = cardCount / cardLimit || null;
			}
	
			let currentColor = 'green';
	
			if (storageUsageRatio >= 0.75) {
				currentColor = 'yellow';
			}
	
			if (storageUsageRatio >= 0.9) {
				currentColor = 'orange';
			}
	
			if (storageUsageRatio >= 0.98) {
				currentColor = 'red';
			}
	
			const cardLabel =
				cardLimit >= 0
					? cardLimit
					: currentWidget.getLanguageTag('unlimitedStorage');
	
			currentWidget.setUsage(
				numeral(cardCount).format('0.00MB'),
				null,
				currentColor,
				'./Resources/icons/StorageOverview.svg',
				getLanguageTag(currentWidget.constructor, 'storageUsed'),
				cardLabel,
				numeral(storageUsageRatio).format('0.00MB'),
			);
	
			const stats = {
				storageUsageRatio,
				limit: cardLimit,
				usage: cardCount,
			};
	
			callback.call(currentWidget, false, stats);
	
		});
	});
};

WidgetUserAccountOverviewGeneralUsageDevicesStorageUsage.prototype.language = deepAssign(
	{},
	WidgetUsage.prototype.language,
	{
		'en-US': {
			storageUsed: 'Cloud Storage',
			storageUsedSubLabel: '{0} Data Storage Used',
			storagedUsedPrimaryLabel: 'of {0} storage',
			unlimitedStorage: 'Unlimited',
		},
	},
);

WidgetUserAccountOverviewGeneralUsageDevicesStorageUsage.prototype.$_$ = function(
	done,
) {
	this.$_SetupWidgetTest(function(err, currentWidget) {
		WidgetUsage.prototype.$_$.call(this, done);
	});
};
