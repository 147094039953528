function WidgetAppViewLayoutSelection(id, api, parent, options) {
	var currentWidget = this;

	WidgetPanelBase.call(this, id, api, parent, options);

	var confirmButtonLabel =
		this.getOptions().confirmLabel ||
		getLanguageTag(this.constructor, 'save');
	var dismissButtonLabel =
		this.getOptions().dismissLabel ||
		getLanguageTag(this.constructor, 'dismiss');

	this.tableContainerId = this.generateChildId('tableContainer');
	this.dismissButtonId = this.generateChildId('dismissButtonId');
	this.confirmButtonId = this.generateChildId('confirmButtonId');

	this.table = null;
	this.planeController = null;
	this.currentEelDataByLibName = null;

	this.renderTemplate(
		{
			tableContainerId: this.tableContainerId,
			labelConfirmButton: confirmButtonLabel,
			confirmButtonId: this.confirmButtonId,
			labelDismissButton: dismissButtonLabel,
			dismissButtonId: this.dismissButtonId,
		},
		WidgetAppViewLayoutSelection.name,
	);

	this.dismissButton = $(`#${this.dismissButtonId}`);
	this.confirmButton = $(`#${this.confirmButtonId}`);

	this.dismissButton.click(function() {
		currentWidget.event('dismissed');
	});

	this.confirmButton.click(function() {
		currentWidget.event('confirmed', {
			layouts: currentWidget.getEnabledLayouts(),
		});
	});
}

WidgetAppViewLayoutSelection.prototype = Object.create(
	WidgetPanelBase.prototype,
);
WidgetAppViewLayoutSelection.prototype.constructor = WidgetAppViewLayoutSelection;

WidgetAppViewLayoutSelection.prototype.setPlaneController = function(
	planeController,
	callback,
) {
	planeController = planeController || null;

	this.planeController = planeController;

	this.updateTable();

	callback.call(this, false);
};

WidgetAppViewLayoutSelection.prototype.getEnabledLayouts = function() {
	var layoutEnablerInputs = this.getContainer().find(
		`*[data-appviewlayoutwidth]`,
	);

	var outputData = [];
	var layoutMapping = {};

	for (var i = 0; i < layoutEnablerInputs.length; i++) {
		if ($($(layoutEnablerInputs[i]).find('input')).prop('checked')) {
			var width = parseInt(
				$(layoutEnablerInputs[i]).attr('data-appviewlayoutwidth'),
			);
			var height = parseInt(
				$(layoutEnablerInputs[i]).attr('data-appviewlayoutheight'),
			);

			if (!(layoutMapping[width] && layoutMapping[width][height])) {
				layoutMapping[width] = {};
				layoutMapping[width][height] = true;
				outputData.push({ width: width, height: height });
			}
		}
	}

	return outputData;
};

WidgetAppViewLayoutSelection.prototype.updateEnablers = function(
	width,
	height,
	enabled,
) {
	var layoutEnablerInputs = this.getContainer().find(
		`[data-appviewlayoutwidth=${width}][data-appviewlayoutheight=${height}]`,
	);

	for (var i = 0; i < layoutEnablerInputs.length; i++) {
		$(layoutEnablerInputs[i])
			.find('input')
			.prop('checked', enabled);
	}
};

WidgetAppViewLayoutSelection.prototype.attachEnablerEvents = function() {
	var currentWidget = this;

	var layoutEnablerInputs = this.getContainer().find(
		`*[data-appviewlayoutwidth]`,
	);

	for (var i = 0; i < layoutEnablerInputs.length; i++) {
		$(layoutEnablerInputs[i])
			.find('input')
			.change(function() {
				var parent = $(this).parent();

				var checked = $(this).prop('checked');

				var width = parseInt($(parent).attr('data-appviewlayoutwidth'));
				var height = parseInt(
					$(parent).attr('data-appviewlayoutheight'),
				);

				currentWidget.updateEnablers(width, height, checked);
			});
	}
};

WidgetAppViewLayoutSelection.prototype.isLayoutEnabled = function(
	width,
	height,
) {
	if (this.planeController === null) {
		return false;
	}

	var appViewLayouts = this.planeController.getMetaValue('appViewLayouts');

	return (
		(appViewLayouts &&
			appViewLayouts[width] &&
			appViewLayouts[width][height]) ||
		false
	);
};

WidgetAppViewLayoutSelection.prototype.updateTable = function() {
	var currentWidget = this;

	this.table.setTable(
		['enable', 'company', 'deviceName', 'width', 'height'],

		{
			enable: {
				columnWidth: 0.5,
				label: getLanguageTag(this.constructor, 'enable'),
				sortable: false,
				formatter: function(a, row) {
					row.checked = currentWidget.isLayoutEnabled(
						row.width,
						row.height,
					);
					return $.handlebarTemplates[
						WidgetAppViewLayoutSelection.name + '_EnableColumn'
					](row);
				},
			},
			company: {
				columnWidth: 1.0,
				label: getLanguageTag(this.constructor, 'company'),
				sortable: true,
			},
			deviceName: {
				columnWidth: 1.0,
				label: getLanguageTag(this.constructor, 'deviceName'),
				sortable: true,
			},
			width: {
				columnWidth: 1.0,
				label: getLanguageTag(this.constructor, 'width'),
				sortable: true,
			},
			height: {
				columnWidth: 1.0,
				label: getLanguageTag(this.constructor, 'height'),
				sortable: true,
			},
		},

		this.LAYOUTS,
		'descending',
		'width',
	);

	this.attachEnablerEvents();
};

WidgetAppViewLayoutSelection.prototype.initialize = function(callback) {
	this.addChildWidget(
		WidgetTable,
		this.tableContainerId,
		{
			filterInputPlaceHolder: this.getLanguageTag(
				'filterInputPlaceHolder',
			),
			noData: this.getLanguageTag('noData'),
		},
		function(err, newWidgetTable) {
			this.table = newWidgetTable;

			this.updateTable();

			WidgetPanelBase.prototype.initialize.call(this, callback);
		},
	);
};

WidgetAppViewLayoutSelection.prototype.LAYOUTS = [
	{
		company: 'Standard',
		deviceName: 'Large',
		description: 'Large',
		width: 768,
		height: 1024,
	},

	{
		company: 'Standard',
		deviceName: 'Medium',
		description: 'Medium',
		width: 400,
		height: 700,
	},

	{
		company: 'Standard',
		deviceName: 'Small',
		description: 'Small',
		width: 370,
		height: 600,
	},

	{
		company: 'Standard',
		deviceName: 'Tiny',
		description: 'Tiny',
		width: 320,
		height: 560,
	},

	{
		company: 'Apple',
		deviceName: 'iPhone X',
		description: 'iPhone X',
		width: 375,
		height: 812,
	},

	{
		company: 'Apple',
		deviceName: 'iPhone 8 Plus',
		description: 'iPhone 8 Plus',
		width: 414,
		height: 736,
	},

	{
		company: 'Apple',
		deviceName: 'iPhone 8',
		description: 'iPhone 8',
		width: 375,
		height: 667,
	},

	{
		company: 'Apple',
		deviceName: 'iPhone 7 Plus',
		description: 'iPhone 7 Plus',
		width: 414,
		height: 736,
	},

	{
		company: 'Apple',
		deviceName: 'iPhone 7',
		description: 'iPhone 7',
		width: 375,
		height: 667,
	},

	{
		company: 'Apple',
		deviceName: 'iPhone 6 Plus/6S Plus',
		description: 'iPhone 6 Plus/6S Plus',
		width: 414,
		height: 736,
	},

	{
		company: 'Apple',
		deviceName: 'iPhone 6/6S',
		description: 'iPhone 6/6S',
		width: 375,
		height: 667,
	},

	{
		company: 'Apple',
		deviceName: 'iPod Touch',
		description: 'iPod Touch',
		width: 320,
		height: 568,
	},

	{
		company: 'Apple',
		deviceName: 'iPad Pro',
		description: 'iPad Pro',
		width: 1024,
		height: 1366,
	},

	{
		company: 'Apple',
		deviceName: 'iPad',
		description: 'iPad (3rd, 4th, Air, Mini ect.)',
		width: 768,
		height: 1024,
	},

	{
		company: 'Android',
		deviceName: 'Nexus 6P, 5X',
		description: 'Nexus 6P, 5X',
		width: 411,
		height: 731,
	},

	{
		company: 'Android',
		deviceName: 'Google Pixel (XL, 2, 2 XL)',
		description: 'Google Pixel (XL, 2, 2 XL)',
		width: 411,
		height: 731,
	},

	{
		company: 'Android',
		deviceName: 'Samsung Galaxy Note 5',
		description: 'Samsung Galaxy Note 5',
		width: 480,
		height: 853,
	},

	{
		company: 'Android',
		deviceName: 'LG G5',
		description: 'LG G5',
		width: 480,
		height: 853,
	},

	{
		company: 'Android',
		deviceName: 'Samsung Galaxy S8, S8+',
		description: 'Samsung Galaxy S8, S8+',
		width: 360,
		height: 740,
	},

	{
		company: 'Android',
		deviceName: 'Samsung Galaxy S7, S7 Edge',
		description: 'Samsung Galaxy S7, S7 Edge',
		width: 360,
		height: 640,
	},

	{
		company: 'Android',
		deviceName: 'Nexus 7 (2013)',
		description: 'Nexus 7 (2013)',
		width: 600,
		height: 960,
	},

	{
		company: 'Android',
		deviceName: 'Nexus 9',
		description: 'Nexus 9',
		width: 768,
		height: 1024,
	},

	{
		company: 'Android',
		deviceName: 'Samsung Galaxy Tab 10',
		description: 'Samsung Galaxy Tab 10',
		width: 800,
		height: 1280,
	},

	{
		company: 'Android',
		deviceName: 'Chromebook Pixel',
		description: 'Chromebook Pixel',
		width: 1280,
		height: 850,
	},
];

WidgetAppViewLayoutSelection.prototype.language = deepAssign(
	{},
	WidgetPanelBase.prototype.language,
	{
		'en-US': {
			name: 'Add Display Sizes',
			width: 'Width',
			height: 'Height',
			deviceName: 'Device',
			company: 'Company',
			enable: 'Enable',
			filterInputPlaceHolder: 'Search for a display...',
			noData: 'No devices or display sizes found',
		},
	},
);
