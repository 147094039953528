function ElementControllerAppAverage(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppLogicBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('samples', null, [], 'none', null, null);
	this.addProperty('sampleSize', null, 5, 'number', null, null);
	this.addProperty('average', null, 5, 'none', null, null);
	this.addProperty('cma', null, 5, 'none', null, null);
	this.addProperty('cmaSize', null, 5, 'none', null, null);

	this.addAbility('push', [{ name: 'value', validator: null }]);
	this.addAbility('clear', []);
	this.addAbility('averageValues', [{ name: 'values', validator: null }]);
	this.addAbility('setSampleSize', [{ name: 'value', validator: null }]);

	this.addTrigger('averagedSamples');
	this.addTrigger('averaged');
	this.addTrigger('pushed');
	this.addTrigger('cleared');
	this.addTrigger('sampleSizeSet');
}

ElementControllerAppAverage.prototype = Object.create(
	ElementControllerAppLogicBase.prototype,
);
ElementControllerAppAverage.prototype.constructor = ElementControllerAppAverage;

ElementControllerAppAverage.prototype.NAME = 'Average';
ElementControllerAppAverage.prototype.TYPE = 'AppAverage';
ElementControllerAppAverage.prototype.HIDDEN = false;
ElementControllerAppAverage.prototype.CATEGORY = 'appLogic';
ElementControllerAppAverage.prototype.DEFAULT_TRIGGER = 'averagedSamples';
ElementControllerAppAverage.prototype.DEFAULT_ABILITY = 'push';
ElementControllerAppAverage.prototype.DEFAULT_ARGUMENTS = ['average'];
ElementControllerAppAverage.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerAppAverage.prototype.language = deepAssign(
	{},
	ElementControllerBase.prototype.language,
	{
		'en-US': {
			AppAverage: 'Average',
			samples: 'Samples',
			sampleSize: 'Sample Size',
			average: 'Average',
			cma: 'Cumulative Moving Average',
			cmaSize: 'Cumulative Moving Average Size',
			push: 'Push',
			clear: 'Clear',
			setSampleSize: 'Set Sample Size',
			averaged: 'Averaged',
			averagedSamples: 'Averaged Samples',
			averageValues: 'Average Values',
			pushed: 'Pushed',
			cleared: 'Cleared',
			sampleSizeSet: 'Sample Size Set',
		},
	},
);
