function WidgetOrganizationPicker(id, api, parent, options) {
	WidgetPanelBase.call(this, id, api, parent, options);
	this.pickerContext = null;
	this.currentChildren = null;

	if (this._options.disabledOrgs === undefined) {
		this._options.disabledOrgs = [];
	}
}

WidgetOrganizationPicker.prototype = Object.create(WidgetPanelBase.prototype);
WidgetOrganizationPicker.prototype.constructor = WidgetOrganizationPicker;

WidgetOrganizationPicker.prototype.initialize = function(callback) {
	const currentWidget = this;

	currentWidget.getOrganizationContext((err, context) => {
	
		if(err) {
			callback.call(this, err);
			return;
		}
		
		this.pickerContext = context;
		
		currentWidget.render(function(err) {
			if (err) {
				callback.call(currentWidget, err);
				return;
			}

			const api = currentWidget.getApiV2();

			const disabledOrgs = currentWidget.getOptions().disabledOrgs || [];

			const filter = (data) => {
				return !disabledOrgs.includes(data.id);
			};

			const onPickerRowClicked = (entry) => {
				currentWidget.pickerContext = entry;
				currentWidget.update(() => {});
			};

			currentWidget.addChildWidget(
				WidgetTableDynamic,
				'WidgetOrganizationPicker-OrgList',
				{
					hideFilterInput: true,
					hideControls: true,
					selectable: false,
					apiRoute: api.apis.organizations.getOrganizations.bind(
						currentWidget,
					),
					filter,
					addParams: () => {
						return {
							parentId: currentWidget.pickerContext.id,
							depth: '1',
						};
					},
					onRowClicked: onPickerRowClicked,
					headerSort: false,
					paginationSize: 10,
					columns: [
						{
							title: currentWidget.getLanguageTag('orgName'),
							// Custom formatter for the link in each cell
							// When the cell is rendered, attach a handler to the link to
							// navigate to the row.
							formatter: function(cell, formatterParams, onRendered) {
								onRendered(() => {
									const linkElement = cell.getElement()
										.firstChild;
									const row = cell.getRow();
									if (row) {
										const data = row.getData();
										if (data) {
											linkElement.onclick = (e) => {
												e.preventDefault();
												onPickerRowClicked(
													cell._cell.row.data,
												);
											};
										}
									}
								});
								return `<a href="#">${cell.getValue()}</a>`;
							},
							field: 'name',
						},
					],
				},
				(err, tableWidget) => {
					currentWidget._table = tableWidget;
					WidgetBase.prototype.initialize.call(currentWidget, callback);
				},
			);
			return;
		});
	
	});
};

WidgetOrganizationPicker.prototype.update = function(callback) {
	const currentWidget = this;
	const doneCb = callback || function() {};
	currentWidget._table.update();
	currentWidget._breadcrumbWidget.setOrganizationContext(this.pickerContext);
	doneCb.call(currentWidget, false);
};

WidgetOrganizationPicker.prototype.getMoveButtonText = function() {
	const currentWidget = this;
	const moveButtonText = currentWidget.getLanguageTag('moveTextSingle');
	return moveButtonText;
};

WidgetOrganizationPicker.prototype.addFooterButtons = function(callback) {
	const currentWidget = this;
	currentWidget.removeChildWidget(this.footerWidget, function(err) {
		currentWidget.addChildWidget(
			WidgetButtonsFooter,
			'WidgetOrganizationPicker-Footer',
			{},
			function(err, footerWidget) {
				this.footerWidget = footerWidget;

				const moveButtonText = currentWidget.getLanguageTag(
					'moveTextSingle',
				);

				this.footerWidget.setButtons([
					{
						label:
							this.getOptions().dismissLabel ||
							getLanguageTag(this.constructor, 'dismiss'),
						value: 'dismissed',
						classes: [`btn-cancel`],
					},

					{
						label: this.getOptions().confirmLabel || moveButtonText,
						value: 'confirmed',
						classes: ['btn-primary'],
					},
				]);

				this.footerWidget.addEventListener(
					'footerButtonPressed',
					function(value) {
						if (value === 'confirmed') {
							currentWidget.event(
								'confirmed',
								currentWidget.pickerContext,
							);
						} else {
							currentWidget.event(value);
						}
					},
				);
				callback.call(currentWidget, false);
			},
		);
	});
};

WidgetOrganizationPicker.prototype.render = function(callback) {
	const doneCb = callback || function() {};
	const currentWidget = this;

	currentWidget.renderTemplate(
		{},
		WidgetOrganizationPicker.name,
		currentWidget.getContainer(),
	);

	currentWidget.addChildWidget(
		WidgetOrganizationPickerBreadcrumb,
		'WidgetOrganizationPicker-Breadcrumb',
		{
			initialContext: currentWidget.pickerContext,
			onRootClick: async () => {
				
				const api = currentWidget.getApiV2().apis;
				
				let user = await api.auth.getCurrentUser();
				currentOrgId = user.organizationId || null;
				currentWidget.pickerContext = await api.organizations.getOrganization({id:currentOrgId});
				currentWidget.update();
			},
			onNavUp: async () => {
				
				const api = currentWidget.getApiV2().apis;

				currentWidget.pickerContext = await api.organizations.getOrganization({id:currentWidget.pickerContext.parentId});
				currentWidget.update();
			},
		},
		function(err, widget) {
			currentWidget._breadcrumbWidget = widget;
			currentWidget.addFooterButtons((err) => {
				if (err) {
					doneCb.call(currentWidget, err);
					return;
				}
				doneCb.call(currentWidget, false);
			});
		},
	);
};

WidgetOrganizationPicker.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'Organizations',
			moveTextSingle: 'Move',
			orgName: 'Select Organization',
		},
	},
);
