function ElementControllerEmbeddedWebThingEvent(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);
	this.addProperty('title', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('title', 'string');
	this.addProperty(
		'capability',
		null,
		'WEBTHING_TYPE_None',
		'select',
		[
			'WEBTHING_TYPE_None',
			'WEBTHING_EVENT_TYPE_AlarmEvent',
			'WEBTHING_EVENT_TYPE_DoublePressedEvent',
			'WEBTHING_EVENT_TYPE_LongPressedEvent',
			'WEBTHING_EVENT_TYPE_OverheatedEvent',
			'WEBTHING_EVENT_TYPE_PressedEvent',
		],
		null,
	);
	this.addProperty('description', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('description', 'string');
	this.addProperty(
		'type',
		null,
		'ATMO_DATATYPE_STRING',
		'select',
		this.DATA_TYPES,
		null,
	);
	this.addProperty('unit', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('unit', 'string');

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
	WebThing_Event_t event;
	event.id = ATMO_ELEMENT_NAME_SLUG(undefined);
	event.title = strlen(ATMO_PROPERTY(undefined, title)) == 0 ? ATMO_ELEMENT_NAME(undefined): ATMO_PROPERTY(undefined, title);
	event.description = ATMO_PROPERTY(undefined, description);
	event.capabilityType = ATMO_PROPERTY(undefined, capability);
	event.unit = ATMO_PROPERTY(undefined, unit);
	event.type = ATMO_PROPERTY(undefined, type);
	WebThing_AddEvent(&event);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('send', [], ['sent'], false, false);
	this.setAbilityCode(
		'send',
		`
	ATMO_Value_t eventVal;
	ATMO_InitValue(&eventVal);
	ATMO_CreateValueConverted(&eventVal, ATMO_PROPERTY(undefined, type), in);
	WebThing_SendEvent(ATMO_ELEMENT_NAME_SLUG(undefined), &eventVal);
	ATMO_FreeValue(&eventVal);
    return ATMO_Status_Success;
	`,
	);
}

ElementControllerEmbeddedWebThingEvent.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedWebThingEvent.prototype.constructor = ElementControllerEmbeddedWebThingEvent;

ElementControllerEmbeddedWebThingEvent.prototype.NAME = 'WebThingEvent';
ElementControllerEmbeddedWebThingEvent.prototype.TYPE = 'EmbeddedWebThingEvent';
ElementControllerEmbeddedWebThingEvent.prototype.CATEGORY = 'webthing';
ElementControllerEmbeddedWebThingEvent.prototype.HIDDEN = false;
ElementControllerEmbeddedWebThingEvent.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'wifi',
	'esp32',
];
ElementControllerEmbeddedWebThingEvent.prototype.DEFAULT_ABILITY = 'send';
ElementControllerEmbeddedWebThingEvent.prototype.DEFAULT_TRIGGER = 'sent';
ElementControllerEmbeddedWebThingEvent.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedWebThingEvent.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedWebThingEvent: 'WebThing Event',
			description: 'Description',
			name: 'Name',
			id: 'ID',
			initialValue: 'Initial Value',
			type: 'Data Type',
			minimum: 'Minimum Value (optional)',
			maximum: 'Maximum Value (optional)',
			unit: 'SI Unit (optional)',
			readOnly: 'Read Only',
			write: 'Write',
			written: 'Written',
			title: 'Title',
			capability: 'Capability',
			WEBTHING_TYPE_None: 'Custom',
			WEBTHING_EVENT_TYPE_AlarmEvent: 'AlarmEvent',
			WEBTHING_EVENT_TYPE_DoublePressedEvent: 'DoublePressedEvent',
			WEBTHING_EVENT_TYPE_LongPressedEvent: 'LongPressedEvent',
			WEBTHING_EVENT_TYPE_OverheatedEvent: 'OverheatedEvent',
			WEBTHING_EVENT_TYPE_PressedEvent: 'PressedEvent',
		},
	},
);
