function PluginUart(nfcHandler) {
	var currentPlugin = this;

	//FIXME: You shouldn't be taking from the global _mainContainer it's not robust
	this._clientAgent = _mainContainer.getClientAgent() || null;
}

PluginUart.prototype.IS_AVAILABLE = function() {
	var clientAgent = _mainContainer.getClientAgent();

	if (
		clientAgent !== undefined &&
		clientAgent !== null &&
		clientAgent.uart !== undefined &&
		clientAgent.uart !== null &&
		clientAgent.isConnected()
	) {
		return true;
	}

	return false;
};

PluginUart.prototype.connect = function(port, options, callback) {
	var currentPlugin = this;

	if (!this._clientAgent || !this._clientAgent.uart) {
		callback.call(this, { type: 'noClientAgent' }, null);
		return;
	}

	this._clientAgent.uart.connect(port, options, function(err) {
		callback.call(currentPlugin, err);
	});
	return;
};

PluginUart.prototype.disconnect = function(callback) {
	var currentPlugin = this;

	if (!this._clientAgent || !this._clientAgent.uart) {
		callback.call(this, { type: 'noClientAgent' }, null);
		return;
	}

	this._clientAgent.uart.disconnect(function(err) {
		callback.call(currentPlugin, err);
	});
	return;
};

PluginUart.prototype.sendAtCommand = function(command, callback) {
	var currentPlugin = this;

	if (!this._clientAgent || !this._clientAgent.uart) {
		callback.call(this, { type: 'noClientAgent' }, null);
		return;
	}

	this._clientAgent.uart.sendAtCommand(command, function(err, data) {
		callback.call(currentPlugin, err, data);
	});
	return;
};

PluginUart.prototype.getScanResults = function(callback) {
	var currentPlugin = this;

	if (!this._clientAgent || !this._clientAgent.uart) {
		callback.call(this, { type: 'noClientAgent' }, null);
		return;
	}

	this._clientAgent.uart.getScanResults(function(err, results) {
		callback.call(currentPlugin, err, results);
	});
	return;
};
