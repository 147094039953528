/**
 *
 * @param {*} id
 * @param {*} api
 * @param {*} parent
 * @param {*} options.initialContext
 * @param {*} options.onRootClick
 * @param {*} options.onNavUp
 */
function WidgetOrganizationPickerBreadcrumb(id, api, parent, options) {
	
	// If this is set to null we use the current context from teh global hash
	// if it is set then we assume it's the context we want to display for
	this.orgContext = null;
	
	WidgetBase.call(this, id, api, parent, options);
}

WidgetOrganizationPickerBreadcrumb.prototype = Object.create(
	WidgetBase.prototype,
);
WidgetOrganizationPickerBreadcrumb.prototype.constructor = WidgetOrganizationPickerBreadcrumb;

WidgetOrganizationPickerBreadcrumb.prototype.initialize = function(callback) {
	const currentWidget = this;

	currentWidget.render(function(err) {
		if (err) {
			callback.call(currentWidget, err);
			return;
		}

		WidgetBase.prototype.initialize.call(currentWidget, callback);

		return;
	});
};

WidgetOrganizationPickerBreadcrumb.prototype.update = function(callback) {
	this.render(callback);
};

WidgetOrganizationPickerBreadcrumb.prototype.setOrganizationContext = function(context, callback) {
	this.orgContext = context;
	this.update(callback);
};

WidgetOrganizationPickerBreadcrumb.prototype._getCurrentContext = async function() {
	
	let context = null;
	
	if(this.orgContext) {
		const api = this.getApiV2().apis;

		return api.organizations.getOrganization({id:this.orgContext.id});
	}
	
	else {
		return this.asyncGetOrganizationContext();
	}
	
};

WidgetOrganizationPickerBreadcrumb.prototype.getBreadcrumbs = function(callback) {
	
	callback = callback || function() {};
	
	const currentWidget = this;
	let breadcrumbs;

	this._getCurrentContext().then((currentContext) => {
		// Limit to 3 elements
		if (currentContext.path.length > 1) {
			breadcrumbs = [
				currentContext.path[0],
				{
					ellipsis: true,
				},
				currentContext,
			];
		} else if (currentContext.path.length > 0) {
			breadcrumbs = [currentContext.path[0], currentContext];
		} else {
			breadcrumbs = [currentContext];
		}

		callback.call(this, false, breadcrumbs);
		return;
	});
};

WidgetOrganizationPickerBreadcrumb.prototype.render = function(callback) {
	callback = callback || function() {};
	const currentWidget = this;

	currentWidget.getBreadcrumbs((err, breadcrumbs) => {
		currentWidget.renderTemplate(
			{ breadcrumbs: breadcrumbs },
			WidgetOrganizationPickerBreadcrumb.name,
			currentWidget.getContainer(),
		);
		$('.WidgetOrganizationPickerBreadcrumb-Clickable').on('click', function(e) {
			e.preventDefault();
			// For now this widget only has one clickable breadcrumb, and it's to the user's parent org
			currentWidget.getOptions().onRootClick();
		});

		$('.WidgetOrganizationPickerBreadcrumb-Ellipsis').on('click', function(e) {
			e.preventDefault();
			currentWidget.getOptions().onNavUp();
		});
		
		callback.call(currentWidget, false);
	});
};

WidgetOrganizationPickerBreadcrumb.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'OrganizationPickerBreadcrumb',
		},
	},
);
