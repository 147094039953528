function NFCHandler(mainContainer) {
	/* The NFC Handler is controlled by mainContainer and handles
	 * all the NFC communication and traffic. WidgetAppView does
	 * not use this class directly instead uses the PluginNFC which
	 * handles registering it's self to the event listenr on it.
	 */

	EventListener.call(this);

	var currentPlugin = this;

	this.mainContainer = mainContainer;
	this.currentProvisioner = null;

	if (window.nfc === undefined) {
		// 		console.log('NFC not available...');
		return;
	}

	if (device.platform === 'iOS') {
		shake.startWatch(function() {
			nfc.beginSession(
				function() {
					'nfc.beginSession: Success!';
				},
				function(err) {
					console.log('nfc.beginSession: Failure!');
				},
			);
		}, 40);
	}

	nfc.addTagDiscoveredListener(
		function() {},
		function() {},
		function() {},
	);
	nfc.addMimeTypeListener(
		'',
		function() {},
		function() {},
		function() {},
	);

	nfc.addNdefListener(
		function(nfcEvent) {
			// 			console.log('nfc.addNdefListener event callback: ' + JSON.stringify(nfcEvent, 0, 2));
			currentPlugin.handleNdefEvent(nfcEvent);
		},

		function() {
			console.log('nfc.addNdefListener success callback');
		},

		function(err) {
			console.log(
				'nfc.addNdefListener error callback: ' + JSON.stringify(err),
			);
		},
	);

	this.addEventListener('ndefRecordRead', function(data) {
		console.log('ndefRecordRead: ' + JSON.stringify(data));
	});
}

NFCHandler.prototype = Object.create(EventListener.prototype);
NFCHandler.prototype.constructor = NFCHandler;

NFCHandler.prototype.TNF_TYPES = {
	TNF_EMPTY: 0x0,
	TNF_WELL_KNOWN: 0x01,
	TNF_MIME_MEDIA: 0x02,
	TNF_ABSOLUTE_URI: 0x03,
	TNF_EXTERNAL_TYPE: 0x04,
	TNF_UNKNOWN: 0x05,
	TNF_UNCHANGED: 0x06,
	TNF_RESERVED: 0x07,
};

NFCHandler.prototype.tnfToString = function(tnf) {
	switch (tnf) {
		case this.TNF_TYPES.TNF_EMPTY:
			return 'TNF_EMPTY';
			break;

		case this.TNF_TYPES.TNF_WELL_KNOWN:
			return 'TNF_WELL_KNOWN';
			break;

		case this.TNF_TYPES.TNF_MIME_MEDIA:
			return 'TNF_MIME_MEDIA';
			break;

		case this.TNF_TYPES.TNF_ABSOLUTE_URI:
			return 'TNF_ABSOLUTE_URI';
			break;
		case this.TNF_TYPES.TNF_EXTERNAL_TYPE:
			return 'TNF_EXTERNAL_TYPE';
			break;

		case this.TNF_TYPES.TNF_UNKNOWN:
			return 'TNF_UNKNOWN';
			break;

		case this.TNF_TYPES.TNF_UNCHANGED:
			return 'TNF_UNCHANGED';
			break;

		case this.TNF_TYPES.TNF_RESERVED:
			return 'TNF_RESERVED';
			break;
	}

	return null;
};

NFCHandler.prototype.URI_ID_CODE_TO_PREFIX = {
	0x00: '',
	0x01: 'http://www.',
	0x02: 'https://www.',
	0x03: 'http://',
	0x04: 'https://',
	0x05: 'tel:',
	0x06: 'mailto:',
	0x07: 'ftp://anonymous:anonymous@',
	0x08: 'ftp://ftp.',
	0x09: 'ftps://',
	0x0a: 'sftp://',
	0x0b: 'smb://',
	0x0c: 'nfs://',
	0x0d: 'ftp://',
	0x0e: 'dav://',
	0x0f: 'news:',
	0x10: 'telnet://',
	0x11: 'imap:',
	0x12: 'rtsp://',
	0x13: 'urn:',
	0x14: 'pop:',
	0x15: 'sip:',
	0x16: 'sips:',
	0x17: 'tftp:',
	0x18: 'btspp://',
	0x19: 'btl2cap://',
	0x1a: 'btgoep://',
	0x1b: 'tcpobex://',
	0x1c: 'irdaobex://',
	0x1d: 'file://',
	0x1e: 'urn:epc:id:',
	0x1f: 'urn:epc:tag:',
	0x20: 'urn:epc:pat:',
	0x21: 'urn:epc:raw:',
	0x22: 'urn:epc:',
	0x23: 'urn:nfc:',
};

NFCHandler.prototype.convertWellKnownToString = function(type, payloadRaw) {
	if (payloadRaw === undefined || payloadRaw === null) {
		return null;
	}

	var payloadUtf8 = '';
	payloadRaw.forEach((x) => {
		payloadUtf8 += String.fromCharCode(x);
	});
	payloadPostfix = payloadUtf8.slice(1);

	switch (type) {
		case 'U':
			if (
				NFCHandler.prototype.URI_ID_CODE_TO_PREFIX[payloadRaw[0]] ===
				undefined
			) {
				return payloadPostfix;
			}

			return (
				NFCHandler.prototype.URI_ID_CODE_TO_PREFIX[payloadRaw[0]] +
				payloadPostfix
			);

			break;

		case 'T':
			//FIXME: We need better parsing of the status byte to convert to UTF-16 and not just 8
			var statusByte = payloadRaw[0];
			var textEncoding = statusByte & 0x80; //Status byte 0 = UTF-8 and 1 = UTF-16
			var rfu = statusByte & 0x40; //RFU must be 0

			if (rfu !== 0) {
				console.log(
					'NFCHandler.prototype.convertWellKnownToString: RFU was not 0!',
				);
				return null;
			}

			var languageCodeLength = statusByte & 0x3f; //We only want bytes 5..0
			var languageCode = payloadUtf8.slice(1, languageCodeLength + 1);
			var data = payloadUtf8.slice(languageCodeLength + 1);

			return data;
			break;

		default:
			return null;
			break;
	}
};

NFCHandler.prototype.handleNdefEvent = function(nfcEvent) {
	var currentNFCHandler = this;

	if (nfcEvent.tag.ndefMessage === undefined) {
		return;
	}

	var ndefMessages = nfcEvent.tag.ndefMessage.slice();

	function handleNdefEventHelper() {
		//We stop recursively looping if we are running a tap to provision or we are out of records
		if (
			ndefMessages.length <= 0 ||
			currentNFCHandler.currentProvisioner !== null
		) {
			return;
		}

		var currentNdefRecord = ndefMessages.shift();
		var tnfRaw = currentNdefRecord.tnf;
		var typeRaw = currentNdefRecord.type;
		var idRaw = currentNdefRecord.id;
		var payloadRaw = currentNdefRecord.payload.slice();

		var payloadUtf8 = '';
		payloadRaw.forEach((x) => {
			payloadUtf8 += String.fromCharCode(x);
		});

		var typeUtf8 = '';
		typeRaw.forEach((x) => {
			typeUtf8 += String.fromCharCode(x);
		});

		var tnfUtf8 = currentNFCHandler.tnfToString(tnfRaw);

		console.log(
			'NFCHandler.prototype.handleNdefEvent: processing NDEF record: "' +
				typeUtf8 +
				'" with data "' +
				payloadUtf8 +
				'"',
		);

		if (
			tnfRaw === currentNFCHandler.TNF_TYPES.TNF_MIME_MEDIA &&
			typeUtf8 === 'application/atmoiotprov'
		) {
			var payloadData = null;

			try {
				payloadData = JSON.parse(payloadUtf8);
			} catch (err) {
				console.log(
					'NFCHandler.prototype.handleNdefEvent: Failed to parse JSON payload: ' +
						payloadUtf8 +
						' (' +
						JSON.stringify(err) +
						')',
				);
				handleNdefEventHelper();
				return;
			}

			if (
				payloadData.uuid !== undefined &&
				payloadData.name !== undefined &&
				payloadData.type !== undefined
			) {
				//This is to prevent the user from experiencing any lag with the NFC element in their apps
				if (
					currentNFCHandler.mainContainer._currentLocation &&
					currentNFCHandler.mainContainer._currentLocation
						.constructor === LocationAppView
				) {
					console.log(
						'NFCHandler.prototype.handleNdefEvent: ignoring Atmo IoT provisioning record as we are in appview',
					);
					handleNdefEventHelper();
					return;
				}

				var registeredRecord = null;

				//We need to see if this is already registered and if it is
				//We should check to make sure it's provisioned and if it's
				//not then we try and provision it again
				for (var j = 0; j < nfcEvent.tag.ndefMessage.length; j++) {
					var otherCurrentNdefRecord = nfcEvent.tag.ndefMessage[j];
					var otherTnfRaw = otherCurrentNdefRecord.tnf;
					var otherTypeRaw = otherCurrentNdefRecord.type;
					var otherIdRaw = otherCurrentNdefRecord.id;
					var otherPayloadRaw = otherCurrentNdefRecord.payload;

					var otherPayloadUtf8 = '';
					otherPayloadRaw.forEach((x) => {
						otherPayloadUtf8 += String.fromCharCode(x);
					});

					var otherTypeUtf8 = '';
					otherTypeRaw.forEach((x) => {
						otherTypeUtf8 += String.fromCharCode(x);
					});

					if (
						otherTnfRaw ===
							currentNFCHandler.TNF_TYPES.TNF_MIME_MEDIA &&
						otherTypeUtf8 === 'application/atmoiotid'
					) {
						registeredRecord = otherCurrentNdefRecord;
						break;
					}
				}

				if (registeredRecord !== null) {
					//We let the check on the atmoiotid record figure out if this device is provisoined
					handleNdefEventHelper();
					return;
				} else {
					console.log(
						'NFCHandler: application/atmoiotprov found trying to auto provision',
					);

					currentNFCHandler.mainContainer.setModalWidget(
						WidgetProvisioner,
						{},
						function(err, currentProvisioner) {
							currentNFCHandler.currentProvisioner = currentProvisioner;

							currentProvisioner.autoProvision(
								payloadData.name,
								payloadData.uuid,
								payloadData.type,
								function(err) {
									currentNFCHandler.currentProvisioner = null;
								},
							);

							currentProvisioner.addEventListener(
								'dismissed',
								function() {
									currentNFCHandler.mainContainer.closeModal();
								},
							);

							currentNFCHandler.mainContainer.showModal();
						},
					);

					//We need to prevent the other events from potentially messing this up.
					return;
				}
			}

			handleNdefEventHelper();
			return;
		}

		if (
			tnfRaw === currentNFCHandler.TNF_TYPES.TNF_MIME_MEDIA &&
			typeUtf8 === 'application/atmoiotid'
		) {
			var payloadData = null;

			try {
				payloadData = JSON.parse(payloadUtf8);
			} catch (err) {
				console.log(
					'NFCHandler.prototype.handleNdefEvent: Failed to parse JSON payload for Atmo IoT ID record: ' +
						payloadUtf8 +
						' (' +
						JSON.stringify(err) +
						')',
				);
				handleNdefEventHelper();
				return;
			}

			if (payloadData.uuid !== undefined) {
				//This is to prevent the user from experiencing any lag with the NFC element in their apps
				if (
					currentNFCHandler.mainContainer._currentLocation &&
					currentNFCHandler.mainContainer._currentLocation
						.constructor === LocationAppView
				) {
					console.log(
						'NFCHandler.prototype.handleNdefEvent: ignoring Atmo IoT ID record as we are in appview',
					);
					handleNdefEventHelper();
					return;
				}

				currentNFCHandler.mainContainer
					.getAPI()
					.getAPIRoute('/user/general/things/removed')
					.get(function(err, data) {
						if (err) {
							callback.call(currentNFCHandler, err, null);
							return;
						}

						var removedDevice = false;

						for (var i = 0; i < data.length; i++) {
							if (payloadData.uuid === data[i].uuid) {
								removedDevice = true;
								break;
							}
						}

						if (removedDevice) {
							console.log(
								'NFCHandler.prototype.handleNdefEvent: Device was removed try provisioning?',
							);

							var provisionRecordPayloadData = null;

							//We need to grab the provision record to complete
							//re-provisioning on this device.
							for (
								var j = 0;
								j < nfcEvent.tag.ndefMessage.length;
								j++
							) {
								var otherCurrentNdefRecord =
									nfcEvent.tag.ndefMessage[j];
								var otherTnfRaw = otherCurrentNdefRecord.tnf;
								var otherTypeRaw = otherCurrentNdefRecord.type;
								var otherIdRaw = otherCurrentNdefRecord.id;
								var otherPayloadRaw =
									otherCurrentNdefRecord.payload;

								var otherPayloadUtf8 = '';
								otherPayloadRaw.forEach((x) => {
									otherPayloadUtf8 += String.fromCharCode(x);
								});

								var otherTypeUtf8 = '';
								otherTypeRaw.forEach((x) => {
									otherTypeUtf8 += String.fromCharCode(x);
								});

								if (
									otherTnfRaw ===
										currentNFCHandler.TNF_TYPES
											.TNF_MIME_MEDIA &&
									otherTypeUtf8 === 'application/atmoiotprov'
								) {
									try {
										provisionRecordPayloadData = JSON.parse(
											otherPayloadUtf8,
										);
									} catch (err) {
										console.log(
											'NFCHandler.prototype.handleNdefEvent: Failed to parse JSON payload: ' +
												otherPayloadUtf8 +
												' (' +
												JSON.stringify(err) +
												')',
										);
										handleNdefEventHelper();
										return;
									}

									break;
								}
							}

							if (provisionRecordPayloadData === null) {
								console.log(
									'No valid NFC record for provisioning',
								);
								handleNdefEventHelper();
								return;
							}

							currentNFCHandler.mainContainer.setModalWidget(
								WidgetProvisioner,
								{},
								function(err, currentProvisioner) {
									currentNFCHandler.currentProvisioner = currentProvisioner;

									currentProvisioner.autoProvision(
										provisionRecordPayloadData.name,
										payloadData.uuid,
										provisionRecordPayloadData.type,
										function(err) {
											currentNFCHandler.currentProvisioner = null;
										},
									);

									currentProvisioner.addEventListener(
										'dismissed',
										function() {
											currentNFCHandler.mainContainer.closeModal();
										},
									);

									currentNFCHandler.mainContainer.showModal();
								},
							);

							return;
						} else {
							//Don't redirect the user if they are already at the App View
							if (
								currentNFCHandler.mainContainer
									._currentLocation &&
								currentNFCHandler.mainContainer._currentLocation
									.constructor !== LocationAppView
							) {
								console.log(
									'NFCHandler.prototype.handleNdefEvent: Redirecting to appview for device ' +
										payloadData.uuid,
								);

								currentNFCHandler.mainContainer.setLocation(
									LocationAppView,
									{
										thingUuid: payloadData.uuid,
										org: getHashCommand().org,
										reload: true,
									},
								);
								return;
							}
						}
					});
			} else {
				console.log(
					'NFCHandler.prototype.handleNdefEvent: Atmo IoT ID payload missing uuid?',
				);
				handleNdefEventHelper();
				return;
			}
		}

		switch (tnfRaw) {
			case currentNFCHandler.TNF_TYPES.TNF_EMPTY:
				currentNFCHandler.event('ndefRecordRead', {
					tnf: tnfUtf8,
					type: typeUtf8,
					value: null,
					payload: payloadRaw,
				});
				break;

			case currentNFCHandler.TNF_TYPES.TNF_WELL_KNOWN:
				currentNFCHandler.event('ndefRecordRead', {
					tnf: tnfUtf8,
					type: typeUtf8,
					value: currentNFCHandler.convertWellKnownToString(
						typeUtf8,
						payloadRaw,
					),
					payload: payloadRaw,
				});
				break;

			case currentNFCHandler.TNF_TYPES.TNF_MIME_MEDIA:
				{
					if (typeUtf8 === 'text/json') {
						var payloadData = null;

						try {
							payloadData = JSON.parse(payloadUtf8);
						} catch (err) {
							console.log(
								'NFCHandler.prototype.handleNdefEvent: Failed to parse JSON payload: ' +
									payloadUtf8 +
									' (' +
									JSON.stringify(err) +
									')',
							);
							handleNdefEventHelper();
							return;
						}

						currentNFCHandler.event('ndefRecordRead', {
							tnf: tnfUtf8,
							type: typeUtf8,
							value: payloadData,
							payload: payloadRaw,
						});
					} else if (typeUtf8.startsWith('text/')) {
						currentNFCHandler.event('ndefRecordRead', {
							tnf: tnfUtf8,
							type: typeUtf8,
							value: payloadUtf8,
							payload: payloadRaw,
						});
					} else {
						currentNFCHandler.event('ndefRecordRead', {
							tnf: tnfUtf8,
							type: typeUtf8,
							value: payloadUtf8,
							payload: payloadRaw,
						});
					}
				}

				break;

			case currentNFCHandler.TNF_TYPES.TNF_ABSOLUTE_URI:
				currentNFCHandler.event('ndefRecordRead', {
					tnf: tnfUtf8,
					type: typeUtf8,
					value: payloadUtf8,
					payload: payloadRaw,
				});
				break;

			case currentNFCHandler.TNF_TYPES.TNF_EXTERNAL_TYPE:
				currentNFCHandler.event('ndefRecordRead', {
					tnf: tnfUtf8,
					type: typeUtf8,
					value: payloadRaw,
					payload: payloadRaw,
				});
				break;

			case currentNFCHandler.TNF_TYPES.TNF_UNKNOWN:
				currentNFCHandler.event('ndefRecordRead', {
					tnf: tnfUtf8,
					type: typeUtf8,
					value: payloadRaw,
					payload: payloadRaw,
				});
				break;

			case currentNFCHandler.TNF_TYPES.TNF_UNCHANGED:
				break;

			case currentNFCHandler.TNF_TYPES.TNF_RESERVED:
				break;
		}

		handleNdefEventHelper();
	}

	handleNdefEventHelper();
};

NFCHandler.prototype.$_$ = function(done) {
	var currentNFCHandler = new NFCHandler(_mainContainer);

	var typeData = 'application/atmoiotid';
	var typeDataBuffer = [];

	for (var i = 0; i < typeData.length; i++) {
		typeDataBuffer.push(typeData.charCodeAt(i));
	}

	var payloadData = JSON.stringify({
		uuid: 'testing',
	});
	var payloadDataBuffer = [];

	for (var i = 0; i < payloadData.length; i++) {
		payloadDataBuffer.push(payloadData.charCodeAt(i));
	}

	var nfcEvent = {
		tag: {
			ndefMessage: [
				{
					tnf: NFCHandler.prototype.TNF_TYPES.TNF_MIME_MEDIA,
					type: typeDataBuffer,
					id: [0],
					payload: payloadDataBuffer,
				},
			],
		},
	};

	currentNFCHandler.handleNdefEvent(nfcEvent);

	if (_mainContainer._currentLocation.constructor !== LocationAppView) {
		done({
			expected: LocationAppView,
			got: _mainContainer._currentLocation.constructor,
			message: 'Shoud have switched to the LocationAppView area',
		});
		return;
	}

	if (getHashCommand().thingUuid !== 'testing') {
		done({
			expected: 'testing',
			got: getHashCommand().thingUuid,
			message:
				'Shoud set the thingUuid arguments for the LocationAppView correctly',
		});
		return;
	}

	done(false);
};

function PluginNFC(nfcHandler) {
	var currentPlugin = this;

	this._nfcHandler = nfcHandler;
	this._ndefRecordReadListener = this._nfcHandler.addEventListener(
		'ndefRecordRead',
		function(data) {
			currentPlugin.onNDEFRecordRead(data);
		},
	);

	this._nfcElements = {}; //These are all the elements who want their triggers executed based on the tnf type we get
}

PluginNFC.prototype.remove = function() {
	this._nfcHandler.removeEventListener(
		'ndefRecordRead',
		this._ndefRecordReadListener,
	);
	return;
};

PluginNFC.prototype.setNFCElementsPropertiesAndTrigger = function(
	tnf,
	type,
	value,
	triggerName,
) {
	var currentPlugin = this;
	var nfcElementNames = Object.keys(this._nfcElements);

	console.log(
		'PluginNFC.prototype.setNFCElementsPropertiesAndTrigger: (' +
			tnf +
			', ' +
			type +
			', ' +
			value +
			')',
	);

	function _nfcHelper() {
		if (nfcElementNames.length <= 0) {
			return;
		}

		var currentElementName = nfcElementNames.shift();

		console.log(
			'PluginNFC.prototype.setNFCElementsPropertiesAndTrigger: triggering "' +
				currentElementName +
				'"',
		);

		currentPlugin._nfcElements[currentElementName]._setProperty(
			'tnf',
			tnf,
			function(err) {
				currentPlugin._nfcElements[currentElementName]._setProperty(
					'type',
					type,
					function(err) {
						currentPlugin._nfcElements[
							currentElementName
						]._setProperty('value', value, function(err) {
							currentPlugin._nfcElements[
								currentElementName
							].trigger(triggerName, function(err) {
								_nfcHelper();
							});
						});
					},
				);
			},
		);
	}

	_nfcHelper();
	return;
};

PluginNFC.prototype.TNF_TO_TRIGGER_MAPPING = {
	TNF_EMPTY: 'emptyRecordRead',
	TNF_WELL_KNOWN: 'wellKnownRecordRead',
	TNF_MIME_MEDIA: 'mimeMediaRecordRead',
	TNF_ABSOLUTE_URI: 'absoluteURIRecordRead',
	TNF_EXTERNAL_TYPE: 'externalRecordRead',
	TNF_UNKNOWN: 'unknownRecordRead',
	TNF_UNCHANGED: 'unchangedRecordRead',
	TNF_RESERVED: 'reservedRecordRead',
};

PluginNFC.prototype.onNDEFRecordRead = function(data) {
	/*
	 * In this function we will intercept the ndef records
	 * and based on the TNF type we will fire of each of the
	 * nfc elements triggers for the individual records.
	 *
	 * emptyRecordRead
	 * wellKnownRecordRead
	 * mimeMediaRecordRead
	 * absoluteURIRecordRead
	 * externalRecordRead
	 * unknownRecordRead
	 * unchangedRecordRead
	 * reservedRecordRead
	 */

	var triggerName = PluginNFC.prototype.TNF_TO_TRIGGER_MAPPING[data.tnf];

	if (triggerName === undefined || typeof triggerName !== 'string') {
		return;
	}

	this.setNFCElementsPropertiesAndTrigger(
		data.tnf,
		data.type,
		data.value,
		triggerName,
	);
};

PluginNFC.prototype.addNFCElement = function(nfcElement) {
	if (this._nfcElements[nfcElement._name] !== undefined) {
		return;
	}

	this._nfcElements[nfcElement._name] = nfcElement;
	return;
};

PluginNFC.prototype.removeNFCElement = function(nfcElement) {
	if (this._nfcElements[nfcElement._name] === undefined) {
		return;
	}

	delete this._nfcElements[nfcElement._name];
	return;
};
