function ElementControllerAppWebLink(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppLogicBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('url', null, 'about:blank', 'text', null, null);

	this.addAbility('open', [{ name: 'url', validator: null }]);
	this.addAbility('setURL', [{ name: 'url', validator: null }]);

	this.addTrigger('URLSet');
}

ElementControllerAppWebLink.prototype = Object.create(
	ElementControllerAppLogicBase.prototype,
);
ElementControllerAppWebLink.prototype.constructor = ElementControllerAppWebLink;

ElementControllerAppWebLink.prototype.NAME = 'WebLink';
ElementControllerAppWebLink.prototype.TYPE = 'AppWebLink';
ElementControllerAppWebLink.prototype.HIDDEN = false;
ElementControllerAppWebLink.prototype.CATEGORY = 'appLogic';
ElementControllerAppWebLink.prototype.DEFAULT_TRIGGER = 'triggered';
ElementControllerAppWebLink.prototype.DEFAULT_ABILITY = 'open';
ElementControllerAppWebLink.prototype.DEFAULT_ARGUMENTS = ['url'];
ElementControllerAppWebLink.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerAppWebLink.prototype.language = {
	'en-US': {
		AppWebLink: 'Web Link',
		url: 'URL',
		open: 'Open',
		setURL: 'Set URL',
		URLSet: 'URL Set',
	},
};
