function LocationUserAccount(id, api, parentWidget, options) {
	LocationBase.call(this, id, api, parentWidget, options);
	this._tabWidgetsData = null;
}

LocationUserAccount.prototype = Object.create(LocationBase.prototype);
LocationUserAccount.prototype.constructor = LocationUserAccount;

LocationUserAccount.prototype.initialize = function(callback) {
	var currentLocation = this;

	this._tabWidgetsData = [
		// Overview tab is disabled until we figure out how our 
		// device and data limits are going to work.
		// {
		// 	childId: 'overview',
		// 	label: getLanguageTag(this.constructor, 'overview'),
		// 	constructor: WidgetUserAccountOverview,
		// 	options: {},
		// },

		{
			childId: 'profile',
			label: getLanguageTag(this.constructor, 'profile'),
			constructor: WidgetUserAccountProfile,
			options: {},
		},

		{
			childId: 'notifications',
			label: getLanguageTag(this.constructor, 'notifications'),
			constructor: WidgetNotificationSettings,
			options: {},
		},

		{
			childId: 'security',
			label: getLanguageTag(this.constructor, 'security'),
			constructor: WidgetUserAccountSecurity,
			options: {},
		},
	];

	if (!this.getConfiguration().hideCancelAccount) {
		this._tabWidgetsData.push({
			childId: 'cancel',
			label: getLanguageTag(this.constructor, 'cancel'),
			constructor: WidgetUserAccountCancel,
			options: {},
		});
	}

	this.setTabWidgets(null, this._tabWidgetsData, function(err) {
		if (err) {
			callback.call(currentLocation, err);
			return;
		}

		callback.call(currentLocation, false);
		return;
	});
};

LocationUserAccount.prototype.language = deepAssign(
	{},
	LocationBase.prototype.language,
	{
		'en-US': {
			name: 'Account',
			overview: 'Overview',
			profile: 'Profile',
			security: 'Security',
			notifications: 'Notifications',
			cancel: 'Cancel',
		},
	},
);
