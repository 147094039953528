function ElementControllerAppNFC(parentController, parentPlane, elementName) {
	ElementControllerBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('tnf', null, null, 'none', null, null);
	this.addProperty('type', null, null, 'none', null, null);
	this.addProperty('value', null, null, 'none', null, null);

	this.addTrigger('emptyRecordRead');
	this.addTrigger('wellKnownRecordRead');
	this.addTrigger('mimeMediaRecordRead');
	this.addTrigger('absoluteURIRecordRead');
	this.addTrigger('externalRecordRead');
	this.addTrigger('unknownRecordRead');
	this.addTrigger('unchangedRecordRead');
	this.addTrigger('reservedRecordRead');
}

ElementControllerAppNFC.prototype = Object.create(
	ElementControllerBase.prototype,
);
ElementControllerAppNFC.prototype.constructor = ElementControllerAppNFC;

ElementControllerAppNFC.prototype.NAME = 'NFC';
ElementControllerAppNFC.prototype.TYPE = 'AppNFC';
ElementControllerAppNFC.prototype.HIDDEN = true;
ElementControllerAppNFC.prototype.CATEGORY = 'appLogic';
ElementControllerAppNFC.prototype.VARIANTS = ['app'];
ElementControllerAppNFC.prototype.DEFAULT_TRIGGER = 'wellKnownRecordRead';
ElementControllerAppNFC.prototype.DEFAULT_ABILITY = 'trigger';
ElementControllerAppNFC.prototype.DEFAULT_ARGUMENTS = ['value'];

ElementControllerAppNFC.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerAppNFC.prototype.language = deepAssign(
	{},
	ElementControllerBase.prototype.language,
	{
		'en-US': {
			AppNFC: 'NFC',
			emptyRecordRead: 'Empty Record Read',
			wellKnownRecordRead: 'Well Known Record Read',
			mimeMediaRecordRead: 'MIME Media Record Read',
			absoluteURIRecordRead: 'Absolute URI Record Read',
			externalRecordRead: 'External Record Read',
			unknownRecordRead: 'Unknown Record Read',
			unchangedRecordRead: 'Unchanged Record Read',
			reservedRecordRead: 'Reserved Record Read',
		},
	},
);
