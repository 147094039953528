function ElementControllerEmbeddedSystemStatusDisplay(
	parentController,
	parentPlane,
	elementName,
	numMeasurements,
) {
	ElementControllerEmbeddedPageTemplateBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addAbility('onDisplayed', [], ['onDisplayed']);
	this.setAbilityCode(
		'onDisplayed',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
    ATMO_UI_PAGE_Config_t config;
    config.hidden = ATMO_PROPERTY(undefined, pageHidden);
    config.activeButtons = ATMO_UI_Page_GetButtonMask(ATMO_PROPERTY(undefined, topRightButtonEnabled),
    ATMO_PROPERTY(undefined,bottomRightButtonEnabled), ATMO_PROPERTY(undefined, topLeftButtonEnabled), ATMO_PROPERTY(undefined, bottomLeftButtonEnabled));
    config.x = ATMO_PROPERTY(undefined, x);
    config.y = ATMO_PROPERTY(undefined, y);
	strncpy(config.topLeftButtonLabel, ATMO_PROPERTY(undefined, topLeftButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
	strncpy(config.topRightButtonLabel, ATMO_PROPERTY(undefined, topRightButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
	strncpy(config.bottomLeftButtonLabel, ATMO_PROPERTY(undefined, bottomLeftButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
	strncpy(config.bottomRightButtonLabel, ATMO_PROPERTY(undefined, bottomRightButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
    config.spanX = ATMO_PROPERTY(undefined, spanX);
	config.spanY = ATMO_PROPERTY(undefined, spanY);
    config.title = ATMO_PROPERTY(undefined, pageTitle);
    config.titleHidden = ATMO_PROPERTY(undefined, titleHidden);
    ATMO_UI_SYSTEMSTATUS_Init(&config);
    `,
	);
}

ElementControllerEmbeddedSystemStatusDisplay.prototype = Object.create(
	ElementControllerEmbeddedPageTemplateBase.prototype,
);
ElementControllerEmbeddedSystemStatusDisplay.prototype.constructor = ElementControllerEmbeddedSystemStatusDisplay;

ElementControllerEmbeddedSystemStatusDisplay.prototype.DEFAULT_TRIGGER =
	'onDisplayed';
ElementControllerEmbeddedSystemStatusDisplay.prototype.DEFAULT_ABILITY =
	'onDisplayed';
ElementControllerEmbeddedSystemStatusDisplay.prototype.DEFAULT_ARGUMENTS = [
	'value',
];
ElementControllerEmbeddedSystemStatusDisplay.prototype.TYPE =
	'EmbeddedSystemStatusDisplay';
ElementControllerEmbeddedSystemStatusDisplay.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'rpk',
];
ElementControllerEmbeddedSystemStatusDisplay.prototype.HIDDEN = false;
ElementControllerEmbeddedSystemStatusDisplay.prototype.CATEGORY = 'Rapid IOT';

ElementControllerEmbeddedSystemStatusDisplay.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedSystemStatusDisplay.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedPageTemplateBase.prototype.language,
	{
		'en-US': {
			NULL: 'No Icon',
			EmbeddedSystemStatusDisplay: 'System Status Page',
			valueChanged: 'Value Changed',
			initialValue: 'Initial Value',
			label: 'Label',
		},
	},
);
