function WidgetDeviceList(id, api, parentWidget, options) {
	WidgetPanelBase.call(this, id, api, parentWidget, options);

	this.devicesTableContainerId = this.generateChildId('deviceTableContainer');
	this.footerWidgetContainerId = this.generateChildId(
		'footerWidgetContainer',
	);

	this.devicesTableWidget = null;
	this.footerWidget = null;

	this.deviceIdList = this.getOptions().deviceIdList;
}

WidgetDeviceList.prototype = Object.create(WidgetPanelBase.prototype);
WidgetDeviceList.prototype.constructor = WidgetDeviceList;

WidgetDeviceList.prototype.initialize = function(callback) {
	const currentWidget = this;
	const api = this.getApiV2().apis;

	this.renderTemplate(
		{
			devicesTableContainerId: this.devicesTableContainerId,
			footerWidgetContainerId: this.footerWidgetContainerId,
		},
		WidgetDeviceList.name,
	);

	this.titleLabel = $(`#${this.titleLabelId}`);

	this.addChildWidget(
		WidgetTableDynamic,
		this.devicesTableContainerId,
		{
			apiRoute: api.devices.getDevices.bind(this),
			ajaxResponse: async (url, params, response) => {
				// To get the current count of devices selected
				// when "Select All" button is exposed.
				let stats = ``;
				const { page, limit, row_count, data } = response;
				// tabulator's "row_count" is really the total records
				const total = row_count;
				const start = (page - 1) * limit + 1;
				const end = (page - 1) * limit + data.length;
				if (total > 0) {
					stats += `Showing ${start} - ${end} of ${total} entries`;
				} else {
					stats = '0 entries';
				}

				$(`#${this.devicesTableContainerId}`)
					.find(`.WidgetTableDynamic-footer`)
					.text(stats);
				return response;
			},
			addParams: () => {
				return {
					depth: 'all',
					entityList: this.deviceIdList.toString(),
				};
			},
			entryMapper: async (entry) => {
				entry.deviceName = entry.name;
				return entry;
			},
			rowFormatter: (row) => {
				const rowData = row.getData();
				const rowClass = deviceStatus.rowClass(rowData.raw.statusText);
				row.getElement().classList.add(rowClass);
			},
			pagination: 'remote',
			paginationSize: 5,
			columns: [
				{
					title: currentWidget.getLanguageTag('deviceName'),
					field: 'deviceName',
				},
				{
					title: currentWidget.getLanguageTag('organizationName'),
					field: 'organizationName',
				},
			], // This gets set by WidgetDeviceList.prototype.columns
		},
		function(err, devicesTableWidget) {
			this.devicesTableWidget = devicesTableWidget;

			this.addChildWidget(
				WidgetButtonsFooter,
				this.footerWidgetContainerId,
				{},
				(err, footerWidget) => {
					this.footerWidget = footerWidget;
					this.footerWidget.setButtons([
						{
							label:
								this.getOptions().confirmLabel ||
								this.getLanguageTag('close'),
							value: 'dismissed',
							classes: ['btn-primary'],
						},
					]);

					this.footerWidget.addEventListener(
						'footerButtonPressed',
						function(value) {
							if (value === 'dismissed') {
								currentWidget.event('dismissed');
							}
						},
					);

					this.update(function(err) {
						WidgetPanelBase.prototype.initialize.call(
							this,
							callback,
						);
					});
				},
			);
		},
	);
};

WidgetDeviceList.prototype.update = function(callback) {
	const currentWidget = this;
	callback = callback || function() {};
	this.devicesTableWidget.update((err) => {
		callback.call(currentWidget, err);
	});
};

WidgetDeviceList.prototype.ICON = './Resources/icons/TableList.svg';

WidgetDeviceList.prototype.defaultColumns = WidgetDeviceList.prototype.language = deepAssign(
	{},
	WidgetTable.prototype.language,
	{
		'en-US': {
			name: 'Device List',
			deviceName: 'Device',
			organizationName: 'Organization',
			createdAt: 'Registered',
			lastActive: 'Last Active',
			close: 'Close',
		},
	},
);

WidgetDeviceList.prototype.$_$ = function(done) {
	this.$_SetupWidgetTest(function(err) {});
};
