function PlaneControllerEmbeddedStm32(parentController, elementName) {
	PlaneControllerEmbeddedBase.call(this, parentController, elementName);
}

PlaneControllerEmbeddedStm32.prototype = Object.create(
	PlaneControllerEmbeddedBase.prototype,
);
PlaneControllerEmbeddedStm32.prototype.constructor = PlaneControllerEmbeddedStm32;

PlaneControllerEmbeddedStm32.prototype.TYPE = 'stm32';
PlaneControllerEmbeddedStm32.prototype.VARIANTS = [];
PlaneControllerEmbeddedStm32.prototype.SUPPORTS = [
	'embedded',
	'gpio',
	'adc',
	'i2c',
	'spi',
	'uart',
	'fpmath',
];
PlaneControllerEmbeddedStm32.prototype.SELECTABLE_VARIANTS = [];

PlaneControllerEmbeddedStm32.prototype.FIRMWARE_DOWNLOAD_FILES = {
	artifactName: 'firmware',
	fileName: 'Atmosphere_Project.bin',
};

PlaneControllerEmbeddedStm32.prototype.language = {
	'en-US': {
		defaultName: 'STM32',
	},
};
