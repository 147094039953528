function ElementControllerEmbeddedCounter(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('initialValue', null, '0', 'number', null, null);
	this.addProperty('rolloverValue', null, '10', 'number', null, null);
	this.addProperty('resetToInitial', null, false, 'checkbox', null, null);
	this.addProperty('rollover', null, true, 'checkbox', null, null);
	this.addVariable('value', 'ATMO_Value_t');

	this.addTrigger('reset');

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
    ATMO_CounterInit(&ATMO_VARIABLE(undefined, value), ATMO_PROPERTY(undefined, initialValue));
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('increment', [], ['incremented'], false, false);
	this.setAbilityCode(
		'increment',
		`
    if(ATMO_CounterIncrement(&ATMO_VARIABLE(undefined, value), 
        ATMO_PROPERTY(undefined, initialValue),
        ATMO_PROPERTY(undefined, rolloverValue),
        ATMO_PROPERTY(undefined, rollover),
        ATMO_PROPERTY(undefined, resetToInitial)))
    {
        ATMO_TriggerHandler(ATMO_TRIGGER(undefined, reset), NULL);
    }
    ATMO_CreateValueCopy(out, &ATMO_VARIABLE(undefined, value));
    return ATMO_Status_Success;
    `,
	);

	this.addAbility('decrement', [], ['decremented'], false, false);
	this.setAbilityCode(
		'decrement',
		`
    if(ATMO_CounterDecrement(&ATMO_VARIABLE(undefined, value), 
        ATMO_PROPERTY(undefined, initialValue),
        ATMO_PROPERTY(undefined, rolloverValue),
        ATMO_PROPERTY(undefined, rollover),
        ATMO_PROPERTY(undefined, resetToInitial)))
    {
        ATMO_TriggerHandler(ATMO_TRIGGER(undefined, reset), NULL);
    }
    ATMO_CreateValueCopy(out, &ATMO_VARIABLE(undefined, value));
    return ATMO_Status_Success;
    `,
	);

	this.addAbility('getValue', [], ['valueRetrieved'], false, false);
	this.setAbilityCode(
		'getValue',
		`
    ATMO_CreateValueCopy(out, &ATMO_VARIABLE(undefined, value));
    return ATMO_Status_Success;
    `,
	);
}

ElementControllerEmbeddedCounter.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedCounter.prototype.constructor = ElementControllerEmbeddedCounter;

ElementControllerEmbeddedCounter.prototype.NAME = 'Counter';
ElementControllerEmbeddedCounter.prototype.TYPE = 'EmbeddedCounter';
ElementControllerEmbeddedCounter.prototype.HIDDEN = false;
ElementControllerEmbeddedCounter.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
];
ElementControllerEmbeddedCounter.prototype.DEFAULT_ABILITY = 'increment';
ElementControllerEmbeddedCounter.prototype.DEFAULT_TRIGGER = 'incremented';
ElementControllerEmbeddedCounter.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedCounter.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedCounter: 'Counter',
			setValue: 'Set Value',
			valueSet: 'Value Set',
			getValue: 'Get Value',
			valueRetrieved: 'Value Retrieved',
			initialValue: 'Initial Value',
			rolloverValue: 'Rollover Value',
			reset: 'Counter Reset',
			increment: 'Increment Value',
			incremented: 'Value Incremented',
			decrement: 'Decrement Value',
			decremented: 'Value Decremented',
			resetToInitial: 'Reset To Initial Value',
			rollover: 'Counter Rollover',
		},
	},
);
