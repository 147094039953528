function PlaneControllerEmbeddedEspduino32(parentController, elementName) {
	PlaneControllerEmbeddedEsp32.call(this, parentController, elementName);
}

PlaneControllerEmbeddedEspduino32.prototype = Object.create(
	PlaneControllerEmbeddedEsp32.prototype,
);
PlaneControllerEmbeddedEspduino32.prototype.constructor = PlaneControllerEmbeddedEspduino32;

PlaneControllerEmbeddedEspduino32.prototype.VARIANTS = ['espduino32'];

PlaneControllerEmbeddedEspduino32.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'D13',
	},

	EmbeddedPWMPin: {
		instance: 0,
		pin: 'D13',
	},

	EmbeddedADCPin: {
		instance: 0,
		pin: 'A0',
	},

	EmbeddedCloudCommand: {
		instance: 1,
	},

	EmbeddedCloudEvent: {
		instance: 1,
	},

	EmbeddedRelayClick: {
		relay1Pin: 'IO18',
		relay2Pin: 'IO19',
		gpioDriverInstance: 0,
	},

	EmbeddedIRThermoClick: {
		i2cInstance: 0,
	},

	EmbeddedHBridge2Click: {
		enablePin: 'IO18',
		dirPin: 'IO19',
		ginPin: 'IO20',
		speedCtrlPin: 'IO22',
		gpioDriverInstance: 0,
		pwmDriverInstance: 0,
		initialSpeedPct: 100,
	},

	EmbeddedGpsClick: {
		uartDriverInstance: 1,
		gpioDriverInstance: 0,
		powerPin: 'IO18',
		wupPin: 'IO19',
		rstPin: 'IO20',
		reportFrequency: 1,
	},

	EmbeddedMotionClick: {
		gpioDriverInstance: 0,
		enablePin: 'IO18',
		motionPin: 'IO19',
	},

	Embedded8x8LedClick: {
		csPin: 'IO18',
	},

	EmbeddedGroveLight: {
		adcInstance: 0,
		adcPin: 'A0',
	},

	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'D13',
	},

	EmbeddedGroveRotaryAngle: {
		adcInstance: 0,
		adcPin: 'A0',
	},

	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'D13',
	},

	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'D13',
	},

	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'D13',
	},

	EmbeddedADXL343: {
		i2cInstance: 0,
		gpioInstance: 0,
		int1Pin: 'IO18',
		int2Pin: 'IO19',
	},

	EmbeddedADXL345: {
		i2cInstance: 0,
		gpioInstance: 0,
		int1Pin: 'IO18',
		int2Pin: 'IO19',
	},
};

PlaneControllerEmbeddedEspduino32.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedEsp32.prototype.language,
	{
		'en-US': {
			defaultName: 'ESPDuino-32',
		},
	},
);
