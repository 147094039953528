function WidgetProgrammingDialog(id, api, parent, options) {
	var currentWidget = this;

	WidgetPanelBase.call(this, id, api, parent, options);
}

WidgetProgrammingDialog.prototype = Object.create(WidgetPanelBase.prototype);
WidgetProgrammingDialog.prototype.constructor = WidgetProgrammingDialog;

WidgetProgrammingDialog.prototype.initialize = function(callback) {
	WidgetPanelBase.prototype.initialize.call(this, callback);
};

WidgetProgrammingDialog.prototype.showCloseButton = function() {
	$(`#${this.dismissButtonId}`).html(this.getLanguageTag('close'));
};

WidgetProgrammingDialog.prototype.startProgramming = function(
	planeController,
	programmingOptions,
	callback,
) {
	var currentWidget = this;

	callback = callback || function() {};

	planeController
		.getParent()
		.getPlaneArtifacts(planeController, function(err, artifacts) {
			// FIXME: Display some sort of error here.
			if (err) {
				currentWidget
					.getMainContainer()
					.showPopupErrorMessage(
						getLanguageTag(
							WidgetProgrammingDialog,
							'unexpectedError',
						),
					);
				currentWidget.event('dismissed');
				return;
			}

			if (
				!isValueObject(artifacts) ||
				!isValueObject(artifacts.data) ||
				artifacts.data.firmwareLink === undefined ||
				artifacts.data.firmwareLink === null
			) {
				currentWidget
					.getMainContainer()
					.showPopupErrorMessage(
						getLanguageTag(
							WidgetProgrammingDialog,
							'noFirmwareAvailable',
						),
					);
				currentWidget.event('dismissed');

				callback.call(currentWidget, { type: 'invalidArtifactData' });
				return;
			}

			var errorOccurred = false;

			currentWidget.showProgrammingFeedbackDialog(function() {
				var stdMessageBuffer = '';

				var pendingCallbackId = currentWidget
					.getMainContainer()
					.getClientAgent()
					.programmer.addEventListener(
						planeController.getType(),
						function(data) {
							if (data.type === 'info') {
								currentWidget.programmingFeedbackInfoContainer.show();

								currentWidget.showProgrammingFeedbackInfoMessage(
									getLanguageTag(
										WidgetProgrammingDialog,
										data.data.type,
									),
								);
							}

							if (data.type === 'stdout') {
								stdMessageBuffer += data.data + '\n';

								currentWidget.appendProgrammingFeedbackStdOutMessage(
									stdMessageBuffer.trim(),
								);
							}

							if (data.type === 'stderr') {
								stdMessageBuffer +=
									'<strong class="standard-error-color">' +
									data.data +
									'</strong>\n\n';

								currentWidget.appendProgrammingFeedbackStdOutMessage(
									stdMessageBuffer.trim(),
								);

								currentWidget.toggleStandardOutContainerVisibility(
									true,
								);

								errorOccurred = true;
							}

							if (data.type === 'progress') {
								currentWidget.setProgrammingFeedbackDialogPercent(
									data.data,
								);
							}

							if (data.type === 'error') {
								currentWidget.programmingFeedbackInfoContainer.show();

								currentWidget.showProgrammingFeedbackErrorMessage(
									getLanguageTag(
										WidgetProgrammingDialog,
										data.data.type,
									),
								);

								errorOccurred = true;

								currentWidget.showCloseButton();

								currentWidget.toggleStandardOutContainerVisibility(
									true,
								);
							}

							if (
								data.type === 'done' &&
								errorOccurred === false
							) {
								currentWidget.programmingFeedbackInfoContainer.show();

								currentWidget.showProgrammingFeedbackDoneMessage(
									getLanguageTag(
										WidgetProgrammingDialog,
										data.type,
									),
								);

								errorOccurred = false;

								currentWidget.showCloseButton();

								currentWidget.hideProgrammingFeedbackDialog();

								currentWidget
									.getMainContainer()
									.showPopupInfoMessage(
										getLanguageTag(
											currentWidget.constructor,
											'done',
										),
									);
							}
						},
					);

				currentWidget
					.getMainContainer()
					.getClientAgent()
					.programmer.program(
						planeController.getType(),
						planeController.getVariants(),
						programmingOptions,
						artifacts.data.firmwareLink,
						function(err, data) {
							currentWidget
								.getMainContainer()
								.getClientAgent()
								.removePendingCallback(pendingCallbackId);
						},
					);
			});
		});
};

WidgetProgrammingDialog.prototype.hideProgrammingFeedbackDialog = function() {
	if (!Boolean(this.programmingFeedbackDialog)) {
		return;
	}

	this.programmingFeedbackDialog.modal('hide');
	this.programmingFeedbackDialog = null;

	return true;
};

WidgetProgrammingDialog.prototype.setProgrammingFeedbackDialogPercent = function(
	percent,
) {
	var progressBarMarkupBuffer = '';

	if (percent !== undefined && percent !== null) {
		this.programmingFeedbackProgressBarContainer.fadeIn('slow');
	}

	progressBarMarkupBuffer += `
				<div role="progressbar" aria-valuenow="width: ${percent}"
										aria-valuemin="0" 
										aria-valuemax="100" 													
										class="WidgetProgrammingDialogProgrammingFeedbackDialog-ProgressBar progress-bar-success" style="width: ${percent}%;">
				</div>
			`;

	$('#' + this.programmingFeedbackProgressBarId).html(
		progressBarMarkupBuffer,
	);

	$('#' + this.programmingFeedbackProgressBarTotalId).html(percent + '%');
};

WidgetProgrammingDialog.prototype.showProgrammingFeedbackInfoMessage = function(
	message,
) {
	var currentWidget = this;

	this.programmingFeedbackInfoContainer
		.removeClass()
		.addClass('alert alert-info');

	this.programmingFeedbackInfoContainer.html(message);
};

WidgetProgrammingDialog.prototype.showProgrammingFeedbackDoneMessage = function(
	message,
) {
	var currentWidget = this;

	this.programmingFeedbackInfoContainer
		.removeClass()
		.addClass('alert alert-success');

	this.programmingFeedbackInfoContainer.html(message);
};

WidgetProgrammingDialog.prototype.showProgrammingFeedbackErrorMessage = function(
	message,
) {
	var currentWidget = this;

	this.programmingFeedbackInfoContainer
		.removeClass()
		.addClass('alert alert-danger');

	this.programmingFeedbackInfoContainer.html(message);
};

WidgetProgrammingDialog.prototype.appendProgrammingFeedbackStdOutMessage = function(
	messageBuffer,
) {
	var currentWidget = this;

	var stdOutContainer = this.programmingFeedbackStdOutPre;

	stdOutContainer.html(messageBuffer);

	if (document.getElementById(this.programmingFeedbackStdOutContainerId)) {
		document.getElementById(
			this.programmingFeedbackStdOutContainerId,
		).scrollTop = document.getElementById(
			this.programmingFeedbackStdOutContainerId,
		).scrollHeight;
	}
};

WidgetProgrammingDialog.prototype.toggleStandardOutContainerVisibility = function(
	visibilityBoolean,
) {
	if (visibilityBoolean === true) {
		this.programmingFeedbackStdOutButton.html(
			getLanguageTag(WidgetProgrammingDialog, 'hideStdOut'),
		);

		this.programmingFeedbackStdOutContainer.fadeIn('slow');

		this.programmingFeedbackStdOutContainerOpen = true;
	} else {
		this.programmingFeedbackStdOutButton.html(
			getLanguageTag(WidgetProgrammingDialog, 'showStdOut'),
		);

		this.programmingFeedbackStdOutContainer.fadeOut('slow');

		this.programmingFeedbackStdOutContainerOpen = false;
	}
};

WidgetProgrammingDialog.prototype.showProgrammingFeedbackDialog = function(
	callback,
) {
	var currentWidget = this;

	this.programmingFeedbackProgressBarId = this.generateChildId('progressBar');
	this.programmingFeedbackProgressBarTotalId = this.generateChildId(
		'barTotal',
	);
	this.programmingFeedbackProgressBarContainerId = this.generateChildId(
		'barContainer',
	);
	this.programmingFeedbackStdOutContainerId = this.generateChildId(
		'stdoutContainer',
	);
	this.programmingFeedbackInfoContainerId = this.generateChildId(
		'infoContainer',
	);
	this.programmingFeedbackStdOutPreId = this.generateChildId('preStdOutput');
	this.programmingFeedbackShowStdOutButtonId = this.generateChildId(
		'stdOutButtonId',
	);
	this.dismissButtonId = this.generateChildId('dismissButton');
	this.confirmButtonId = this.generateChildId('confirmButton');
	this.labelDismissButton = getLanguageTag(this.constructor, 'dismiss');

	this.renderTemplate(
		{
			progressBarId: this.programmingFeedbackProgressBarId,
			progressBarTotalId: this.programmingFeedbackProgressBarTotalId,
			progressBarContainerId: this
				.programmingFeedbackProgressBarContainerId,
			stdOutContainerId: this.programmingFeedbackStdOutContainerId,
			stdOutPreId: this.programmingFeedbackStdOutPreId,
			infoContainerId: this.programmingFeedbackInfoContainerId,
			showStdOutButtonId: this.programmingFeedbackShowStdOutButtonId,
			dismissButtonId: this.dismissButtonId,
			confirmButtonId: this.confirmButtonId,
			labelDismissButton: this.labelDismissButton,
		},
		'WidgetProgrammingDialog_Feedback',
	);

	this.programmingFeedbackProgressBarContainer = $(
		'#' + this.programmingFeedbackProgressBarContainerId,
	);

	this.programmingFeedbackStdOutContainer = $(
		'#' + this.programmingFeedbackStdOutContainerId,
	);

	this.programmingFeedbackStdOutPre = $(
		'#' + this.programmingFeedbackStdOutPreId,
	);

	this.programmingFeedbackStdOutButton = $(
		'#' + this.programmingFeedbackShowStdOutButtonId,
	);

	this.programmingFeedbackInfoContainer = $(
		'#' + this.programmingFeedbackInfoContainerId,
	);

	this.programmingFeedbackInfoContainer.hide();

	this.programmingFeedbackProgressBarContainer.hide();

	this.programmingFeedbackStdOutContainer.hide();

	this.toggleStandardOutContainerVisibility(false);

	this.programmingFeedbackStdOutButton.on('click', function(e) {
		if (currentWidget.programmingFeedbackStdOutContainerOpen === true) {
			currentWidget.toggleStandardOutContainerVisibility(false);
		} else {
			currentWidget.toggleStandardOutContainerVisibility(true);
		}
	});

	$(`#${this.dismissButtonId}`).click(function() {
		currentWidget.event('dismissed');
	});

	callback.call(this, false);
};

WidgetProgrammingDialog.prototype.showProgrammingOptions = function(
	planeController,
	callback,
) {
	var currentWidget = this;

	var clientAgent = this.getMainContainer().getClientAgent();

	planeController = planeController || null;

	if (planeController === null) {
		callback.call(this, { type: 'noPlaneControllerSpecified' });
		return;
	}

	clientAgent.programmer.getProgrammers(function(err, programmerList) {
		if (
			programmerList === undefined ||
			programmerList.indexOf(planeController.getType()) < 0
		) {
			callback.call(currentWidget, {
				type: 'noProgrammerAvailableForPlane',
			});
			return;
		}

		clientAgent.programmer.getProgrammerOptions(
			planeController.getType(),
			planeController.getVariants(),
			function(err, data) {
				var inputIdToVariables = {};

				if (err) {
					callback.call(currentWidget, err);
					return;
				}

				for (var i = 0; i < data.inputs.length; i++) {
					var currentId = currentWidget.generateChildId(
						data.inputs[i].name,
					);

					inputIdToVariables[currentId] = data.inputs[i].name;

					data.inputs[i].guidepost =
						'programmer_input_' + data.inputs[i].name;
					data.inputs[i].name = getLanguageTag(
						WidgetProgrammingDialog,
						data.inputs[i].name,
					);
					data.inputs[i].id = currentId;

					if (
						data.inputs[i].inputOptions &&
						data.inputs[i].inputOptions.length <= 0
					) {
						data.inputs[i].inputOptions = [
							{
								name: getLanguageTag(
									WidgetProgrammingDialog,
									'noAvailableInputOptions',
								),
							},
						];
					}
				}

				data.dismissButtonId = currentWidget.generateChildId(
					'dismissButton',
				);
				data.confirmButtonId = currentWidget.generateChildId(
					'confirmButton',
				);
				data.labelDismissButton = getLanguageTag(
					currentWidget.constructor,
					'dismiss',
				);
				data.labelConfirmButton = getLanguageTag(
					currentWidget.constructor,
					'confirm',
				);

				currentWidget.renderTemplate(
					data,
					WidgetProgrammingDialog.name,
				);

				$(`#${data.dismissButtonId}`).click(function() {
					currentWidget.event('dismissed');
				});

				$(`#${data.confirmButtonId}`).click(function() {
					$(`#${data.confirmButtonId}`).prop('disabled', true);

					var programmingOptions = {};

					for (var id in inputIdToVariables) {
						var currentInput = $('#' + id);

						if (currentInput.attr('type') === 'checkbox') {
							programmingOptions[
								inputIdToVariables[id]
							] = currentInput.is(':checked');
						} else {
							programmingOptions[
								inputIdToVariables[id]
							] = currentInput.val();
						}
					}

					currentWidget.startProgramming(
						planeController,
						programmingOptions,
						function() {},
					);
				});

				callback.call(currentWidget, false);
			},
		);
	});
};

WidgetProgrammingDialog.prototype.language = deepAssign(
	{},
	WidgetPanelBase.prototype.language,
	{
		'en-US': {
			name: 'Program Firmware',
			confirm: 'Program',
			done: 'Programming complete',
			deviceTimedOut: "Error: The device timed out it's connection",
			programmingRequiresAgent:
				'To use the programmer feature you must have the Atmosphere IoT Agent running on your PC.',
			program: 'Program Firmware',
			programmingFeedback: 'Program Firmware',
			serialPort: 'Serial Port',
			serialPortSpeed: 'Serial Speed',
			esp32FatalError:
				'Fatal error occcured while programming the ESP32. Try reducing your serial speed and program again.',
			serialPortPermissionDenied:
				'Permission was denied for the programmer to use the serial port',
			serialPortBusy:
				'The selected serial port is busy. Make sure there are no other applications using this port then try again.',
			flashBootLoader: 'Flash Boot Loader',
			extractionError:
				'The IoT Agent encountered an error retrieving your firmware.',
			noFirmwareAvailable:
				'No firmware is available for this device. Have you compiled your application?',
			noSourceAvailable:
				'No source code is available for this device. Have you compiled your application?',
			noArtifactDataForPlane:
				'No source code is available for this device, have you compiled your application?',
			tryPressingReset:
				'The device is not responding, try pressing the reset button.',
			tryPressingResetInitial: 'Reset the board to begin.',
			compilingCompleted: 'Compiling complete',
			buildError:
				'There was an error when compiling the embedded source for the application. Please try again.',
			unableToReconnect:
				"There was an issue communicating with the Atmosphere IoT Agent. Make sure it's running then try again.",
			unableToDownloadFirmware: 'Unable to download firmware',
			showStdOut: 'Show Advanced Output',
			hideStdOut: 'Hide Advanced Output',
			noAvailableInputOptions: 'No available options',
			extraTools: 'Extra Tools',
			unknown:
				'An unknown error has occurred. Check the advanced output for more information.',
			jLinkCannotConnect:
				'JLink cannot connect to the target device. Please check connections.',
			jLinkErrorCode: 'JLink returned a non-zero error code.',
			close: 'Close',
			ipeCmdPath: 'IPECMD Path',
			ipecmdCannotConnect:
				'IPECMD cannot connect. Try powering down the device for 10 seconds and then retry programming.',
			ipecmdFailed:
				'IPECMD failed to program. Try powering down the device for 10 seconds and then retry programming.',
			ipecmdErrorCode: 'IPECMD returned a non-zero error code.',
			ipecmdNotFound:
				'ipecmd.jar not found in the specified location. Make sure MPLABX is installed.',
			redlinkErrorCode: 'An error has occured with the LPC55xx Redlink Programmer. Try powering down the device for 10 seconds and then retry programming. See the debug output for more information.'
		},
	},
);
