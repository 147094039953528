function ElementControllerCloudAWSS3(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudAWSBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('value', null, null, 'none', null, null);
	this.addProperty('objectList', null, null, 'none', null, null);
	this.addProperty('bucket', null, null, 'text', null, null);
	this.addProperty('key', null, null, 'text', null, null);
	this.addProperty('acl', null, null, 'text', null, null);

	this.addAbility('getObject', []);
	this.addAbility('putObject', [{ name: 'data', validator: null }]);
	this.addAbility('deleteObject', []);
	this.addAbility('listObjects', [
		{ name: 'prefix', validator: null },
		{ name: 'delimiter', validator: null },
	]);

	this.addTrigger('objectPlaced');
	this.addTrigger('objectGotten');
	this.addTrigger('objectDeleted');
	this.addTrigger('objectsListed');
}

ElementControllerCloudAWSS3.prototype = Object.create(
	ElementControllerCloudAWSBase.prototype,
);
ElementControllerCloudAWSS3.prototype.constructor = ElementControllerCloudAWSS3;

ElementControllerCloudAWSS3.prototype.NAME = 'AWSS3';
ElementControllerCloudAWSS3.prototype.TYPE = 'CloudAWSS3';
ElementControllerCloudAWSS3.prototype.HIDDEN = false;
ElementControllerCloudAWSS3.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudAWSS3.prototype.language = deepAssign(
	{},
	ElementControllerCloudAWSBase.prototype.language,
	{
		'en-US': {
			CloudAWSS3: 'AWS S3',
			value: 'Value',
			objectList: 'Object List',
			bucket: 'Bucket',
			key: 'Key',
			acl: 'ACL',
			prefix: 'Prefix',
			delimiter: 'Delimiter',
			getObject: 'Get Object (getObject)',
			putObject: 'Place Object (putObject)',
			deleteObject: 'Delete Object (deleteObject)',
			listObjects: 'List Objects (listObjects)',
			objectPlaced: 'Object Placed',
			objectGotten: 'Object Received',
			objectDeleted: 'Object Deleted',
			objectsListed: 'Objects Listed',
		},
	},
);
