function WidgetDeviceDashboardControls(id, api, parentWidget, options) {
	options = options || {};

	options.entries = options.entries || [
		{
			name: 'removeDevice',
			label: this.getLanguageTag('deleteDevice'),
			imageSrc: './Resources/icons/Trash.svg',
		},

		{
			name: 'exit',
			label: this.getLanguageTag('exit'),
			imageSrc: './Resources/icons/Exit.svg',
		},
	];

	// Ideally this wouldn't be happening here in the constructor
	// Because it is, I have to use the global mainContainer
	const currentUser = _mainContainer.getCurrentUser();
	if (currentUser && currentUser.ability.cannot('delete', 'Device')) {
		options.entries = options.entries.filter((entry) => {
			return entry.name !== 'removeDevice';
		});
	}

	WidgetLocationControls.call(this, id, api, parentWidget, options);
}

WidgetDeviceDashboardControls.prototype = Object.create(
	WidgetLocationControls.prototype,
);

WidgetDeviceDashboardControls.prototype.constructor = WidgetDeviceDashboardControls;

WidgetDeviceDashboardControls.prototype.initialize = function(callback) {
	const currentWidget = this;
	const api = this.getAPI();

	api.getAPIRoute('/user/thing/:thingUuid/view/plane/:planeName').get(
		getHashCommand().thingUuid,
		'App View',
		(err, data) => {
			if (err || !data || !data.elements || !data.elements.length > 0) {
				console.debug(
					'[WidgetDeviceDashboardControls] Error or empty App plane found, Not displaying App View button.',
				);
				return WidgetLocationControls.prototype.initialize.call(
					currentWidget,
					callback,
				);
			}

			currentWidget.getOptions().entries.unshift({
				name: 'appView',
				label: this.getLanguageTag('appView'),
				imageSrc: './Resources/icons/InterfaceBuilder.svg',
			});

			return WidgetLocationControls.prototype.initialize.call(
				currentWidget,
				callback,
			);
		},
	);
};

WidgetDeviceDashboardControls.prototype.language = deepAssign(
	{},
	WidgetLocationControls.prototype.language,
	{
		'en-US': {
			name: 'BaseLocation',
			appView: 'App View',
			deleteDevice: 'Delete Device',
			exit: 'Exit',
		},
	},
);
