function ElementControllerAppInterval(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppLogicBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('time', null, 1000, 'number', null, null);
	this.addProperty('startOnInitialize', null, true, 'checkbox', null, null);

	this.addAbility('start', []);
	this.addAbility('pause', []);
	this.addAbility('set', [{ name: 'value', validator: null }]);
	this.addAbility('setTime', [{ name: 'milliseconds', validator: null }]);

	this.addTrigger('interval');
	this.addTrigger('started');
	this.addTrigger('paused');
}

ElementControllerAppInterval.prototype = Object.create(
	ElementControllerAppLogicBase.prototype,
);
ElementControllerAppInterval.prototype.constructor = ElementControllerAppInterval;

ElementControllerAppInterval.prototype.NAME = 'Interval';
ElementControllerAppInterval.prototype.TYPE = 'AppInterval';
ElementControllerAppInterval.prototype.HIDDEN = false;
ElementControllerAppInterval.prototype.DEFAULT_TRIGGER = 'interval';
ElementControllerAppInterval.prototype.DEFAULT_ABILITY = 'start';
ElementControllerAppInterval.prototype.DEFAULT_ARGUMENTS = [];

ElementControllerAppInterval.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerAppInterval.prototype.language = deepAssign(
	{},
	ElementControllerBase.prototype.language,
	{
		'en-US': {
			AppInterval: 'Interval',
			startOnInitialize: 'Start on Initialize',
			time: 'Time (milliseconds)',
			milliseconds: 'Time (milliseconds)',
			start: 'Start',
			started: 'Started',
			pause: 'Pause',
			paused: 'Paused',
			set: 'Set',
			setTime: 'Set Time',
			interval: 'Interval',
			value: 'Value',
		},
	},
);
