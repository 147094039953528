function ProvisionerBase(api) {
	EventListener.call(this);

	this._api = api;
	this._availableDeviceEntries = {};
}

ProvisionerBase.prototype = Object.create(EventListener.prototype);
ProvisionerBase.prototype.constructor = ProvisionerBase;

ProvisionerBase.prototype.GETIDENTITYTYPE = 0x01;
ProvisionerBase.prototype.BASICCENTRALCONFIRMIDENTITY = 0x02;
ProvisionerBase.prototype.BASICCLIENTCONFIRMIDENTITY = 0x03;
ProvisionerBase.prototype.LOCKREGISTRATIONINFO = 0x04;
ProvisionerBase.prototype.GETREGISTRATIONINFO = 0x05;
ProvisionerBase.prototype.SETREGISTRATIONINFOURL = 0x06;
ProvisionerBase.prototype.SETREGISTRATIONINFOUUID = 0x07;
ProvisionerBase.prototype.SETREGISTRATIONINFOTOKEN = 0x08;
ProvisionerBase.prototype.CLEARREGISTRATIONINFO = 0x09;
ProvisionerBase.prototype.SETDATETIME = 0x0a;
ProvisionerBase.prototype.UNLOCKREGISTRATIONINFO = 0x0b;
ProvisionerBase.prototype.SETDATETIMEEPOCH = 0x0c;
ProvisionerBase.prototype.EXTRASETTINGSWIFI = 0x0d;
ProvisionerBase.prototype.EXTRASETTINGSWIFI_GETSTATUS = 0x00;
ProvisionerBase.prototype.EXTRASETTINGSWIFI_SETSSID = 0x01;
ProvisionerBase.prototype.EXTRASETTINGSWIFI_SETPASSWORD = 0x02;
ProvisionerBase.prototype.EXTRASETTINGSSIGFOX = 0x0e;
ProvisionerBase.prototype.EXTRASETTINGSSIGFOX_GETDEVICEID = 0x00;
ProvisionerBase.prototype.EXTRASETTINGSLORA = 0x0f;
ProvisionerBase.prototype.EXTRASETTINGSLORA_GETDEVICEID = 0x00;

ProvisionerBase.prototype.EXTRA_SETTINGS_BLE = 0x0001;
ProvisionerBase.prototype.EXTRA_SETTINGS_WIFI = 0x0002;
ProvisionerBase.prototype.EXTRA_SETTINGS_LORA = 0x0004;
ProvisionerBase.prototype.EXTRA_SETTINGS_CELLULAR = 0x0008;
ProvisionerBase.prototype.EXTRA_SETTINGS_THREAD = 0x0010;
ProvisionerBase.prototype.EXTRA_SETTINGS_6LOWPAN = 0x0020;
ProvisionerBase.prototype.EXTRA_SETTINGS_ETHERNET = 0x0040;
ProvisionerBase.prototype.EXTRA_SETTINGS_ZIGBEE = 0x0080;
ProvisionerBase.prototype.EXTRA_SETTINGS_ZWAVE = 0x0100;
ProvisionerBase.prototype.EXTRA_SETTINGS_SIGFOX = 0x0200;
ProvisionerBase.prototype.EXTRA_SETTINGS_NEUL = 0x0400;
ProvisionerBase.prototype.EXTRA_SETTINGS_RESERVED = 0x8000;

ProvisionerBase.prototype.startSearching = function(timeout, callback) {
	callback.call(this, false);
};

ProvisionerBase.prototype.stopSearching = function(callback) {
	callback.call(this, false);
};

ProvisionerBase.prototype.provisionDevice = function(id, callback) {
	callback.call(this, { type: 'notSupported' }, null);
};

ProvisionerBase.prototype.getAvailableDeviceTypes = function(callback) {
	const currentProvisioner = this;
	const api = _mainContainer.getApiV2();

	api.apis.projects
		.getProjects()
		.then((projects) => {
			let deviceTypes = {};
			for (let i = 0; i < projects.data.length; i++) {
				deviceTypes[projects.data[i].uuid] = projects.data[i].name;
			}
			callback.call(currentProvisioner, false, deviceTypes);
		})
		.catch((err) => {
			callback.call(currentProvisioner, err, null);
		});
};

ProvisionerBase.prototype.getRemovedDevices = function(callback) {
	var currentProvisioner = this;

	this._api
		.getAPIRoute('/user/general/things/removed')
		.get(function(err, data) {
			if (err) {
				callback.call(currentProvisioner, err, null);
				return;
			}

			var devicesRemoved = {};

			for (var i = 0; i < data.length; i++) {
				devicesRemoved[data[i].uuid] = data[i];
			}

			callback.call(currentProvisioner, false, devicesRemoved);
			return;
		});
};

ProvisionerBase.prototype.setAvailableDeviceEntry = function(deviceEntry) {
	this._availableDeviceEntries[deviceEntry.id] = deviceEntry;
	return;
};

ProvisionerBase.prototype.getAvailableDeviceEntries = function() {
	return this._availableDeviceEntries;
};

ProvisionerBase.prototype.getAvailableDeviceEntry = function(id) {
	return this._availableDeviceEntries[id];
};

ProvisionerBase.prototype.remove = function(callback) {
	callback.call(this, false);
};
