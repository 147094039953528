function ElementControllerBase(
	parentController,
	parentPlane,
	elementName,
	data,
) {
	ControllerBase.call(this);

	this._parentController = parentController;
	this._parentPlane = parentPlane;
	this._name = null;
	this._meta = {};
	this._properties = {};
	this._abilities = {};
	this._triggers = {};

	//Are we coupled to another element?
	this._coupledElement = null;
	this._coupledElementName = null;
	this._coupledElementPlaneName = null;

	this._currentOnCoupledElementNameSet = null;

	this.setName(elementName);

	this.addProperty('errorData', null, null, 'none', null, null);

	this.addAbility('trigger', [], ['triggered']);

	this.triggerEventErrors = {};
}

ElementControllerBase.prototype = Object.create(ControllerBase.prototype);
ElementControllerBase.prototype.constructor = ElementControllerBase;

ElementControllerBase.prototype.DEFAULT_TRIGGER = 'triggered';
ElementControllerBase.prototype.DEFAULT_ABILITY = 'trigger';
ElementControllerBase.prototype.DEFAULT_ARGUMENTS = [];
ElementControllerBase.prototype.TYPE = 'Base';
ElementControllerBase.prototype.COUPLED_TO = null;
ElementControllerBase.prototype.COUPLER_TYPE = null;
ElementControllerBase.prototype.VARIANTS = ['base'];
ElementControllerBase.prototype.CATEGORY = 'base';
ElementControllerBase.prototype.HIDDEN = true;
ElementControllerBase.prototype.VALID_PROPERTY_INPUT_TYPES = [
	'none',
	'number',
	'text',
	'checkbox',
];

ElementControllerBase.prototype.VALID_PROPERTY_DATA_TYPES = [
	'Object',
	'Number',
	'String',
	'Array',
	'null',
	'undefined',
];

ElementControllerBase.prototype.DATA_TYPES = [
	'ATMO_DATATYPE_VOID',
	'ATMO_DATATYPE_CHAR',
	'ATMO_DATATYPE_BOOL',
	'ATMO_DATATYPE_INT',
	'ATMO_DATATYPE_UNSIGNED_INT',
	'ATMO_DATATYPE_FLOAT',
	'ATMO_DATATYPE_DOUBLE',
	'ATMO_DATATYPE_BINARY',
	'ATMO_DATATYPE_STRING',
	'ATMO_DATATYPE_3D_VECTOR_FLOAT',
	'ATMO_DATATYPE_3D_VECTOR_DOUBLE',
];

ElementControllerBase.prototype.setName = function(name) {
	var oldName = this._name;

	this._name = name.toString();

	this.event('nameSet', {
		element: this,
		oldName: oldName,
		name: this._name,
	});
	return;
};

ElementControllerBase.prototype.changeName = function(name) {
	if (this.COUPLED_TO !== null) {
		this.error({ type: 'coupledElementNameCanNotBeDirectlyChanged' });
		return;
	}

	if (this._parentPlane !== null) {
		this._parentPlane.setElementName(this._name, name);
	} else {
		this.setName(name);
	}
};

ElementControllerBase.prototype.getName = function() {
	if (this._coupledElement !== null) {
		return this._coupledElement.getName();
	}

	return this._name;
};

ElementControllerBase.prototype.setMeta = function(meta) {
	this._meta = meta;
};

ElementControllerBase.prototype.setMetaValue = function(key, value) {
	this._meta[key] = value;
	return;
};

ElementControllerBase.prototype.getMetaValue = function(key) {
	return this._meta[key];
};

ElementControllerBase.prototype.getMeta = function() {
	return this._meta;
};

ElementControllerBase.prototype.getType = function() {
	return this.constructor.prototype.TYPE;
};

ElementControllerBase.prototype.getVariants = function() {
	return this.constructor.prototype.VARIANTS;
};

ElementControllerBase.prototype.isHidden = function() {
	return this.constructor.prototype.HIDDEN;
};

ElementControllerBase.prototype.addProperty = function(
	propertyName,
	propertyValidator,
	value,
	inputType,
	inputOptions,
	dataType,
	hidden,
) {
	dataType = dataType || 'Object';
	hidden = hidden || false;

	if (this._properties[propertyName] !== undefined) {
		this.error('elementPropertyAlreadyExist');
		return;
	}

	this._properties[propertyName] = {
		value: null,
		validator: propertyValidator,
		inputType: inputType,
		dataType: dataType,
		hidden: hidden,
		inputOptions: inputOptions,
	};

	value = this._parentPlane.getElementDefaultProperty(
		this.TYPE,
		propertyName,
		inputType,
		dataType,
		value,
	);

	this.setProperty(propertyName, value);
	return;
};

ElementControllerBase.prototype.mergeDriverInstance = function(
	propertyName,
	value,
) {
	var currentElement = this;

	var currentDrivers =
		currentElement._parentPlane._runtimeDrivers[
			currentElement._properties[propertyName].dataType
		];

	// If special default macro is used
	if (
		value ===
			`ATMO_DEFAULT_${currentElement._properties[
				propertyName
			].dataType.toUpperCase()}` ||
		value == undefined
	) {
		// Find the primary driver
		var primaryDriver = currentDrivers.find(function(driver) {
			return driver.primary === true;
		});

		if (primaryDriver) {
			this._properties[propertyName].value = primaryDriver.name;
			return primaryDriver.name;
		} else {
			this._properties[propertyName].value = currentDrivers[0].name;
			return currentDrivers[0].name;
		}
	} else {
		var valueFound = currentDrivers.find(function(driver) {
			return driver.name === value;
		});

		// The value is a valid driverInstance
		// Set as normal
		if (valueFound) {
			this._properties[propertyName].value = value;
			return value;
		}

		// Try to parse as an integer
		var driverInstanceInt = parseInt(value) || 0;

		// Find driver with matching ID
		var selectedDriver =
			currentDrivers.find(function(driver) {
				return driver.id === driverInstanceInt;
			}) || currentDrivers[0];

		this._properties[propertyName].value = selectedDriver.name;
		return selectedDriver.name;
	}
};

ElementControllerBase.prototype.mergeProperty = function(propertyName, value) {
	var currentElement = this;
	if (this._properties[propertyName] === undefined) {
		this.error('elementPropertyDoesNotExist');
		return;
	}

	if (
		this._properties[propertyName].validator === undefined ||
		this._properties[propertyName].validator === null
	) {
		if (value && typeof value === 'object') {
			Object.keys(value).forEach(function(subProp) {
				if (
					!(subProp in currentElement._properties[propertyName].value)
				) {
					delete value[subProp];
				}
			});
			this._properties[propertyName].value = Object.assign(
				{},
				this._properties[propertyName].value || {},
				value || {},
			);
		} else {
			// Handle backwards compatibility for old driver instance
			if (this._properties[propertyName].inputType === 'driverInstance') {
				value = currentElement.mergeDriverInstance(propertyName, value);
			} else {
				this._properties[propertyName].value = value;
			}
		}
		this.event('propertyValid', {
			element: this,
			property: propertyName,
			value: value,
		});
		this.event('propertySet', {
			element: this,
			property: propertyName,
			value: value,
		});
		return;
	}

	if (
		this._properties[propertyName].validator(
			value,
			this._properties[propertyName].inputOptions,
		)
	) {
		if (typeof value === 'object') {
			this._properties[propertyName].value = Object.assign(
				this._properties[propertyName].value || {},
				value || {},
			);
		} else {
			this._properties[propertyName].value = value;
		}
		this.event('propertyValid', {
			element: this,
			property: propertyName,
			value: value,
		});
		this.event('propertySet', {
			element: this,
			property: propertyName,
			value: value,
		});
		return;
	}

	this.event('propertyInvalid', {
		element: this,
		property: propertyName,
		value: value,
	});
	return;
};

ElementControllerBase.prototype.setPropertyHidden = function(propertyName) {
	if (this._properties[propertyName] === undefined) {
		this.error('elementPropertyDoesNotExist');
		return;
	}

	this._properties[propertyName].hidden = true;
	return;
};

ElementControllerBase.prototype.setProperty = function(propertyName, value) {
	if (this._properties[propertyName] === undefined) {
		this.error('elementPropertyDoesNotExist');
		return;
	}

	if (
		this._properties[propertyName].validator === undefined ||
		this._properties[propertyName].validator === null
	) {
		this._properties[propertyName].value = value;
		this.event('propertySet', {
			element: this,
			property: propertyName,
			value: value,
		});
		return;
	}

	if (
		this._properties[propertyName].validator(
			value,
			this._properties[propertyName].inputOptions,
		)
	) {
		this._properties[propertyName].value = value;
		this.event('propertySet', {
			element: this,
			property: propertyName,
			value: value,
		});
		return;
	}

	this.event('propertyInvalid', {
		element: this,
		property: propertyName,
		value: value,
	});
	return;
};

ElementControllerBase.prototype.getProperties = function() {
	return this._properties;
};

ElementControllerBase.prototype.getProperty = function(propertyName) {
	if (this._properties[propertyName] === undefined) {
		this.error('elementPropertyDoesNotExist');
		return null;
	}

	return this._properties[propertyName];
};

ElementControllerBase.prototype.getPropertyValue = function(propertyName) {
	if (this._properties[propertyName] === undefined) {
		return null;
	}

	return this._properties[propertyName].value;
};

ElementControllerBase.prototype.getPropertyValidator = function(propertyName) {
	if (this._properties[propertyName] === undefined) {
		this.error('elementPropertyDoesNotExist');
		return;
	}

	return this._properties[propertyName].validator;
};

ElementControllerBase.prototype.removeProperty = function(propertyName) {
	if (this._properties[propertyName] === undefined) {
		this.error('elementPropertyDoesNotExist');
		return;
	}

	delete this._properties[propertyName].validator;
	return;
};

ElementControllerBase.prototype.addAbility = function(
	abilityName,
	args,
	triggers,
	hidden,
) {
	/*
	 * This will add an ability to the controller.
	 *
	 * the triggers argument is what triggers may fire if this event is used.
	 * That data is also used by the embedded compiler plans to create the
	 * linking events in the event handler.
	 *
	 */

	if (this._abilities[abilityName] !== undefined) {
		this.error('elementAbilityAlreadyExist');
		return;
	}

	triggers = triggers || [];
	hidden = hidden || false;

	for (var i = 0; i < triggers.length; i++) {
		this.addTrigger(triggers[i]);
	}

	this._abilities[abilityName] = {
		arguments: [],
		triggers: triggers,
		hidden: hidden,
	};

	for (var i = 0; i < args.length; i++) {
		this.addAbilityArgument(abilityName, args[i].name, args[i].validator);
	}
};

ElementControllerBase.prototype.getAbility = function(abilityName) {
	if (this._abilities[abilityName] === undefined) {
		this.error('elementAbilityDoesNotExist');
		return null;
	}

	return this._abilities[abilityName];
};

ElementControllerBase.prototype.getAbilities = function() {
	return this._abilities;
};

ElementControllerBase.prototype.removeAbility = function(abilityName) {
	if (this._abilities[abilityName] === undefined) {
		this.error('elementAbilityDoesNotExist');
		return;
	}

	delete this._abilities[abilityName];
	return;
};

ElementControllerBase.prototype.addAbilityArgument = function(
	abilityName,
	argumentName,
	validator,
) {
	if (this._abilities[abilityName] === undefined) {
		this.error('elementAbilityDoesNotExist');
		return;
	}

	if (this.getAbilityArgument(abilityName, argumentName) !== null) {
		this.error('elementAbilityArgumentAlreadyExists');
		return;
	}

	this._abilities[abilityName].arguments.push({
		name: argumentName,
		validator: validator,
	});
	return;
};

ElementControllerBase.prototype.getAbilityArguments = function(abilityName) {
	if (this._abilities[abilityName] === undefined) {
		this.error('elementAbilityDoesNotExist');
		return;
	}

	return this._abilities[abilityName].arguments;
};

ElementControllerBase.prototype.getAbilityArgument = function(
	abilityName,
	argumentName,
) {
	if (this._abilities[abilityName] === undefined) {
		this.error('elementAbilityDoesNotExist');
		return;
	}

	for (var i = 0; i < this._abilities[abilityName].arguments.length; i++) {
		if (this._abilities[abilityName].arguments[i].name === argumentName) {
			return this._abilities[abilityName].arguments[i];
		}
	}

	return null;
};

ElementControllerBase.prototype.removeAbilityArgument = function(
	abilityName,
	argumentName,
) {
	if (this._abilities[abilityName] === undefined) {
		this.error('elementAbilityDoesNotExist');
		return;
	}

	var removedArgument = null;

	for (var i = 0; i < this._abilities[abilityName].arguments.length; i++) {
		if (this._abilities[abilityName].arguments[i].name === argumentName) {
			removedArgument = this._abilities[abilityName].arguments[i];
			this._abilities[abilityName].arguments.splice(i, 1);
			break;
		}
	}

	return removedArgument;
};

ElementControllerBase.prototype.addTrigger = function(triggerName) {
	if (this._triggers[triggerName] !== undefined) {
		this.error('elementTriggerAlreadyExist');
		return;
	}

	this._triggers[triggerName] = [];
	return;
};

ElementControllerBase.prototype.getTriggers = function() {
	return this._triggers;
};

ElementControllerBase.prototype.getTrigger = function(triggerName) {
	if (this._triggers[triggerName] === undefined) {
		this.error('elementTriggerDoesNotExist');
		return;
	}

	return this._triggers[triggerName];
};

ElementControllerBase.prototype.removeTrigger = function(triggerName) {
	if (this._triggers[triggerName] === undefined) {
		this.error('elementTriggerDoesNotExist');
		return;
	}

	delete this._triggers[triggerName];
	return;
};

ElementControllerBase.prototype.addTriggerEvent = function(
	triggerName,
	targetElement,
	mapping,
	targetAbility,
	targetOrder,
) {
	if (
		targetElement === undefined ||
		targetElement === null ||
		this._parentPlane.getElement(targetElement.toString()) === null
	) {
		this.error('targetElementDoesNotExist');
		return;
	}

	if (
		triggerName === null ||
		triggerName === undefined ||
		targetAbility === undefined ||
		targetAbility === null
	) {
		var defaultTrigger = triggerName || this.DEFAULT_TRIGGER;
		var defaultFillArguments = this.DEFAULT_ARGUMENTS;
		var defaultAbility =
			targetAbility ||
			this._parentPlane.getElement(targetElement.toString())
				.DEFAULT_ABILITY;
		var abilityArguments = this._parentPlane
			.getElement(targetElement.toString())
			.getAbilityArguments(defaultAbility);

		var initialMapping = {};
		var argumentMappingOrder = [];

		for (var i = 0; i < abilityArguments.length; i++) {
			var currentArgument = abilityArguments[i];

			argumentMappingOrder.push(currentArgument.name);

			if (defaultFillArguments.length > i) {
				initialMapping[currentArgument.name] = {
					code: defaultFillArguments[i],
				};
			} else {
				initialMapping[currentArgument.name] = { code: '' };
			}
		}

		mapping = initialMapping;
		targetAbility = defaultAbility;
		targetOrder = argumentMappingOrder;
		triggerName = defaultTrigger;
	}

	if (this._triggers[triggerName] === undefined) {
		this.error('elementTriggerDoesNotExist');
		return;
	}

	if (
		this._parentPlane
			.getElement(targetElement.toString())
			.getAbility(targetAbility) === null
	) {
		this.error('targetAbilityDoesNotExist');
		return;
	}

	var currentIndex =
		this._triggers[triggerName].push({
			mapping: {},
			targetOrder: [],
		}) - 1;

	this.setTriggerEventTarget(
		triggerName,
		currentIndex,
		targetElement,
		targetAbility,
		targetOrder,
	);

	for (var variableName in mapping) {
		this.setTriggerEventMapping(
			triggerName,
			currentIndex,
			variableName,
			mapping[variableName].code,
		);
	}

	var eventData = {
		fromElement: this,
		toElement: this._parentPlane.getElement(targetElement.toString()),
		triggerName: triggerName,
		targetAbility: targetAbility,
		index: currentIndex,
	};

	this.event('triggerEventAdded', eventData);

	this._parentPlane.event('triggerEventAdded', eventData);
};

//If an element is renamed we will have to rename all the element references for the target elements.
ElementControllerBase.prototype.updateTriggerEventTargetElements = function(
	oldElementName,
	newElementName,
) {
	for (var triggerName in this._triggers) {
		var currentTrigger = this._triggers[triggerName];

		for (var i = 0; i < currentTrigger.length; i++) {
			if (currentTrigger[i].targetElement === oldElementName) {
				this.setTriggerEventTargetElement(
					triggerName,
					i,
					newElementName,
				);
			}
		}
	}
};

ElementControllerBase.prototype.removeTriggerEventThatTargetElement = function(
	elementName,
) {
	for (var triggerName in this._triggers) {
		var currentTrigger = this._triggers[triggerName];

		for (var i = currentTrigger.length - 1; i >= 0; i--) {
			if (currentTrigger[i].targetElement === elementName) {
				this.removeTriggerEvent(triggerName, i);
			}
		}
	}
};

ElementControllerBase.prototype.setTriggerEventMapping = function(
	triggerName,
	index,
	variableName,
	code,
) {
	if (this._triggers[triggerName] === undefined) {
		this.error('elementTriggerDoesNotExist');
		return;
	}

	if (this._triggers[triggerName][index] === undefined) {
		this.error('elementTriggerEventDoesNotExist');
		return;
	}

	this._triggers[triggerName][index].mapping[variableName] = {
		code: code.toString(),
	};

	var eventData = {
		triggerName: triggerName,
		index: index,
		variableName: variableName,
		code: code,
	};

	this.removeTriggerEventError(triggerName, index);

	this.event('triggerEventMappingSet', eventData);

	this._parentPlane.event('triggerEventMappingSet', eventData);

	return;
};

ElementControllerBase.prototype.runCodeInSandbox = function(code, callback) {
	var currentElement = this;

	var testSandboxState = {};

	var properties = this.getProperties();

	for (propertyName in properties) {
		var currentProperty = properties[propertyName];

		switch (currentProperty.dataType) {
			case 'Object':
				testSandboxState[propertyName] = new Object();
				break;

			case 'Number':
				testSandboxState[propertyName] = 23.42;
				break;

			case 'String':
				testSandboxState[propertyName] = 'Test24';
				break;

			case 'Array':
				testSandboxState[propertyName] = [1, 4, 5, 'test'];
				break;

			case 'null':
				testSandboxState[propertyName] = null;
				break;

			case 'undefined':
				break;

			default:
				testSandboxState[propertyName] = null;
				break;
		}
	}

	codeEvalTest(code, testSandboxState).then(function(result, err) {
		if (result.err) {
			callback.call(currentElement, result.err, null);
			return;
		}

		callback.call(currentElement, false, result.data);
		return;
	});
};

ElementControllerBase.prototype.testTriggerEventMapping = function(
	triggerName,
	index,
	variableName,
) {};

ElementControllerBase.prototype.removeTriggerEventMapping = function(
	triggerName,
	index,
	variableName,
) {
	if (this._triggers[triggerName] === undefined) {
		this.error('elementTriggerDoesNotExist');
		return;
	}

	if (this._triggers[triggerName][index] === undefined) {
		this.error('elementTriggerEventDoesNotExist');
		return;
	}

	if (
		this._triggers[triggerName][index].mapping[variableName] === undefined
	) {
		this.error('elementTriggerEventMappingVariableDoesNotExist');
		return;
	}

	delete this._triggers[triggerName][index].mapping[variableName];
	return;
};

ElementControllerBase.prototype.setTriggerEventTargetElement = function(
	triggerName,
	index,
	targetElement,
) {
	if (this._triggers[triggerName] === undefined) {
		this.error('elementTriggerDoesNotExist');
		return;
	}

	if (this._triggers[triggerName][index] === undefined) {
		this.error('elementTriggerEventDoesNotExist');
		return;
	}

	if (this._parentPlane.getElement(targetElement.toString()) === null) {
		this.error('targetElementDoesNotExist');
		return;
	}

	this._triggers[triggerName][index].targetElement = targetElement.toString();
};

ElementControllerBase.prototype.setTriggerEventTarget = function(
	triggerName,
	index,
	targetElement,
	targetAbility,
	targetOrder,
) {
	if (this._triggers[triggerName] === undefined) {
		this.error('elementTriggerDoesNotExist');
		return;
	}

	if (this._triggers[triggerName][index] === undefined) {
		this.error('elementTriggerEventDoesNotExist');
		return;
	}

	if (this._parentPlane.getElement(targetElement.toString()) === null) {
		this.error('targetElementDoesNotExist');
		return;
	}

	if (
		this._parentPlane
			.getElement(targetElement.toString())
			.getAbility(targetAbility) === null
	) {
		this.error('targetAbilityDoesNotExist');
		return;
	}

	this._triggers[triggerName][index].targetElement = targetElement.toString();
	this._triggers[triggerName][index].targetAbility = targetAbility.toString();
	this._triggers[triggerName][index].targetOrder = targetOrder;

	for (var i = 0; i < targetOrder.length; i++) {
		if (
			this._triggers[triggerName][index].mapping[targetOrder[i]] ===
			undefined
		) {
			this.error('elementTargetOrderVariableDoesNotExistInMapping');
			return;
		}
	}
};

ElementControllerBase.prototype.setTriggerEventTargetAbility = function(
	triggerName,
	index,
	targetAbility,
) {
	if (this._triggers[triggerName] === undefined) {
		this.error('elementTriggerDoesNotExist');
		return;
	}

	if (this._triggers[triggerName][index] === undefined) {
		this.error('elementTriggerEventDoesNotExist');
		return;
	}

	var targetElement = this._triggers[triggerName][index].targetElement;
	var targetElementController = this._parentPlane.getElement(
		targetElement.toString(),
	);

	if (targetElementController === null) {
		this.error('targetElementDoesNotExist');
		return;
	}

	if (targetElementController.getAbility(targetAbility) === null) {
		this.error('targetAbilityDoesNotExist');
		return;
	}

	var abilityArguments = targetElementController.getAbilityArguments(
		targetAbility,
	);
	var currentTriggerEvent = this._triggers[triggerName][index];

	var newTargetOrder = [];
	var newMapping = {};

	for (var i = 0; i < abilityArguments.length; i++) {
		newTargetOrder.push(abilityArguments[i].name);
	}

	for (var i = 0; i < newTargetOrder.length; i++) {
		var mapping =
			currentTriggerEvent.mapping[currentTriggerEvent.targetOrder[i]];

		if (mapping === undefined || mapping.code === undefined) {
			mapping = { code: '' };
		}

		newMapping[newTargetOrder[i]] = { code: mapping.code };
	}

	currentTriggerEvent.targetAbility = targetAbility.toString();
	currentTriggerEvent.targetOrder = newTargetOrder;
	currentTriggerEvent.mapping = newMapping;

	for (var i = 0; i < currentTriggerEvent.targetOrder.length; i++) {
		if (
			this._triggers[triggerName][index].mapping[
				currentTriggerEvent.targetOrder[i]
			] === undefined
		) {
			this.error('elementTargetOrderVariableDoesNotExistInMapping');
			return;
		}
	}

	this.event('triggerEventMappingSet', {});
};

ElementControllerBase.prototype.getTriggerEvents = function(triggerName) {
	if (this._triggers[triggerName] === undefined) {
		this.error('elementTriggerDoesNotExist');
		return null;
	}

	return this._triggers[triggerName];
};

ElementControllerBase.prototype.getTriggerEvent = function(triggerName, index) {
	if (this._triggers[triggerName] === undefined) {
		this.error('elementTriggerDoesNotExist');
		return null;
	}

	if (this._triggers[triggerName][index] === undefined) {
		this.error('elementTriggerEventDoesNotExist');
		return null;
	}

	return this._triggers[triggerName][index];
};

ElementControllerBase.prototype.moveTriggerEvent = function(
	triggerName,
	index,
	newIndex,
) {};

ElementControllerBase.prototype.getTriggerEventsThatInvolveElement = function(
	elementName,
) {
	var triggers = [];

	for (var triggerName in this._triggers) {
		var currentTrigger = this._triggers[triggerName];

		for (var i = 0; i < currentTrigger.length; i++) {
			if (currentTrigger[i].targetElement === elementName) {
				triggers.push(currentTrigger[i]);
			}
		}
	}

	return triggers;
};

ElementControllerBase.prototype.removeTriggerEvent = function(
	triggerName,
	index,
) {
	if (this._triggers[triggerName] === undefined) {
		this.error('elementTriggerDoesNotExist');
		return null;
	}

	if (this._triggers[triggerName][index] === undefined) {
		this.error('elementTriggerEventDoesNotExist');
		return null;
	}

	var removedTriggerEvent = this._triggers[triggerName][index];
	this._triggers[triggerName].splice(index, 1);

	if (
		this.getTriggerEventsThatInvolveElement(
			removedTriggerEvent.targetElement,
		).length <= 0
	) {
		this.event('allTriggerEventsToElementRemoved', {
			fromElement: this,
			toElement: this._parentPlane.getElement(
				removedTriggerEvent.targetElement.toString(),
			),
		});

		this._parentPlane.event('allTriggerEventsToElementRemoved', {
			fromElement: this,
			toElement: this._parentPlane.getElement(
				removedTriggerEvent.targetElement.toString(),
			),
		});
	}

	this.event('triggerEventRemoved', {
		triggerName: triggerName,
		index: index,
	});

	return removedTriggerEvent;
};

ElementControllerBase.prototype.onCoupledElementNameSet = function(data) {
	this._parentPlane.updateTriggerEvents(data.oldName, data.name);

	this._parentPlane.event('elementNameSet', {
		oldName: data.oldName,
		newName: data.name,
	});
};

ElementControllerBase.prototype.setCoupledElement = function(element) {
	var currentElement = this;

	if (this._coupledElement !== null) {
		this._coupledElement.removeEventListener(
			this._currentOnCoupledElementNameSet,
		);
	}

	this._coupledElement = element;
	this._coupledElementName = element.getName();
	this._coupledElementPlaneName = element._parentPlane.getDefaultName();

	this._currentOnCoupledElementNameSet = this._coupledElement.addEventListener(
		'nameSet',
		function(data) {
			currentElement.onCoupledElementNameSet(data);
		},
	);

	this._parentPlane.event('elementCoupled', {
		name: this.getName(),
		controller: this,
	});

	return;
};

ElementControllerBase.prototype.getCoupledElement = function() {
	return this._coupledElement;
};

ElementControllerBase.prototype.removeAllTriggerEventErrors = function() {
	this.triggerEventErrors = {};

	this.event('allTriggerEventErrorsRemoved');

	return;
};

ElementControllerBase.prototype.addTriggerEventError = function(
	triggerName,
	eventNumber,
	argumentName,
	errorMessage,
	data,
) {
	if (this.triggerEventErrors[triggerName] === undefined) {
		this.triggerEventErrors[triggerName] = {};
	}

	this.triggerEventErrors[triggerName][eventNumber] = {
		controller: this,
		eventNumber: eventNumber,
		argumentName: argumentName,
		errorMessage: errorMessage,
		data: data,
	};

	this.event(
		'triggerEventErrorAdded',
		this.triggerEventErrors[triggerName][eventNumber],
	);
	this._parentPlane.event(
		'elementTriggerEventErrorAdded',
		this.triggerEventErrors[triggerName][eventNumber],
	);

	return;
};

ElementControllerBase.prototype.removeTriggerEventError = function(
	triggerName,
	eventNumber,
) {
	if (this.triggerEventErrors[triggerName] === undefined) {
		return;
	}

	if (this.triggerEventErrors[triggerName][eventNumber] === undefined) {
		return;
	}

	this.event(
		'triggerEventErrorRemoved',
		this.triggerEventErrors[triggerName][eventNumber],
	);
	this._parentPlane.event(
		'elementTriggerEventErrorRemoved',
		this.triggerEventErrors[triggerName][eventNumber],
	);

	delete this.triggerEventErrors[triggerName][eventNumber];

	return;
};

ElementControllerBase.prototype.getTriggerEventErrors = function() {
	return this.triggerEventErrors;
};

ElementControllerBase.prototype.import = function(elementData) {
	if (elementData === undefined || elementData === null) {
		return;
	}

	this._meta = elementData.meta;
	this._triggers = Object.assign(this._triggers, elementData.triggers);
	this._coupledElementName = elementData.coupledElementName;
	this._coupledElementPlaneName = elementData.coupledElementPlaneName;

	for (propertyName in elementData.properties) {
		this.setProperty(propertyName, elementData.properties[propertyName]);
	}

	this.event('imported', elementData);
};

//We need to do somethings after everything in the project has finally been imported.
ElementControllerBase.prototype.postImport = function() {
	if (
		this._coupledElementName !== null &&
		this._coupledElementPlaneName !== null
	) {
		var coupledElementPlane = this._parentController.getPlane(
			this._coupledElementPlaneName,
		);

		if (coupledElementPlane === null || coupledElementPlane === undefined) {
			return;
		}

		var coupledElement = coupledElementPlane.getElement(
			this._coupledElementName,
		);

		if (coupledElement === null || coupledElement === undefined) {
			return;
		}

		this.setCoupledElement(coupledElement);
	}
};

ElementControllerBase.prototype.export = function() {
	var elementExportData = {};

	elementExportData.name = this.getName();
	elementExportData.type = this.getType();
	elementExportData.variants = this.getVariants();
	elementExportData.properties = {};
	elementExportData.meta = this._meta;
	elementExportData.triggers = this._triggers;

	for (var propertyName in this._properties) {
		elementExportData.properties[propertyName] = this._properties[
			propertyName
		].value;
	}

	//We are coupled to another element meaning
	//we need to have the same propeties as our
	//coupled to element.
	if (this._coupledElement !== null) {
		var coupledElementData = this._coupledElement.export();

		elementExportData.coupledElementName = this._coupledElement.getName();
		elementExportData.coupledElementPlaneName = this._coupledElement._parentPlane.getDefaultName();
		elementExportData.properties = Object.assign(
			elementExportData.properties,
			coupledElementData.properties,
		);
	}

	return elementExportData;
};

ElementControllerBase.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com/';

ElementControllerBase.prototype.language = {
	'en-US': {
		ElementControllerBase: 'Base',
		errorData: 'Error Data',
		trigger: 'Trigger',
		triggered: 'Triggered',
		ATMO_DATATYPE_VOID: 'Void',
		ATMO_DATATYPE_CHAR: 'Character',
		ATMO_DATATYPE_BOOL: 'Boolean',
		ATMO_DATATYPE_INT: 'Integer',
		ATMO_DATATYPE_UNSIGNED_INT: 'Unsigned Integer',
		ATMO_DATATYPE_FLOAT: 'Floating Point (32-bit)',
		ATMO_DATATYPE_DOUBLE: 'Double Floating Point (64-bit)',
		ATMO_DATATYPE_BINARY: 'Raw Binary Data',
		ATMO_DATATYPE_STRING: 'String',
		ATMO_DATATYPE_3D_VECTOR_FLOAT: '3D Float (32-bit) Vector',
		ATMO_DATATYPE_3D_VECTOR_DOUBLE: '3D Double (64-bit) Vector',
	},
};
