if (window.Sentry && isRunningInProduction()) {
	Sentry.init({
		dsn: 'https://f64d71dc41ae4557850e383686965132@sentry.io/1366841',
		release: 'atmosphere-ui@' + window.ATMO_VERSION,
		ignoreErrors: ['ResizeObserver loop limit exceeded']
	});

	Sentry.configureScope(function(scope) {
		if (window.ATMO_VERSION || window.ATMO_REV) {
			scope.setTag('Version', window.ATMO_VERSION || 'unknown');
			scope.setTag('Revision', window.ATMO_REV || 'unknown');
		} else {
			var cloudAPI = new CloudApi();

			cloudAPI.getAPIRoute('/version').get(function(err, version) {
				cloudAPI.getAPIRoute('/revision').get(function(err, revision) {
					scope.setTag('Version', version || 'unknown');
					scope.setTag('Revision', revision || 'unknown');
				});
			});
		}
	});
}

function initializeApp() {
	jQuery(document).ready(function() {
		addHandleBarHelpers();

		$(document).autoBars({
			callback: function() {
				window._mainContainer = new MainContainer();

				window._mainContainer.initialize(function(err) {
					
					if(err) {
						console.error(err);
					}
					
				});
			},
		});
	});
}

//Phonegap or locally run UI code needs to init a little differently
if (window.location.protocol === 'file:' || window.location.protocol === 'app:') {
	if (isPhonegap()) {
		
		document.addEventListener('deviceready', function() {
			console.log('deviceready');
			
			// We want to capture all anchor links and make sure
			// if they are target _system we open them as expected
			document.addEventListener('click', function (e) {
				
				if (e.target.tagName === 'A' &&
					e.target.href.match(/^https?:\/\//) &&
					e.target.getAttribute('target') === '_system') {
					
					// Handle click events for all external URLs
					if (device.platform.toUpperCase() === 'ANDROID') {
						navigator.app.loadUrl(e.target.getAttribute('href'), { openExternal: true });
						e.preventDefault();
					}
					else if (device.platform.toUpperCase() === 'IOS') {
						cordova.InAppBrowser.open(e.target.getAttribute('href'), '_system', '');
						e.preventDefault();
					}
				}
			});
			
			if (StatusBar !== undefined) {
				StatusBar.hide(); //Hide the status bar for phonegap
			}
		
			initializeApp();
		});
	} else {
		initializeApp();
	}
} else {
	initializeApp();
}
