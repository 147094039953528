function WidgetElementEditor(id, api, parentWidget, options) {
	var currentWidget = this;

	WidgetBase.call(this, id, api, parentWidget, options);

	this.mainDivId = this.generateChildId('mainDiv');
	this.elementNameEditorId = this.generateChildId('elementNameEditor');
	this.propertiesDivId = this.generateChildId('propertiesDiv');
	this.triggersDivId = this.generateChildId('triggersDiv');
	this.nameEditorId = this.generateChildId('nameEditor');
	this.helpLinkId = this.generateChildId('helpLink');
	this.triggerSelectId = this.generateChildId('triggerSelect');
	this.triggerEventAddButtonId = this.generateChildId(
		'triggerEventAddButton',
	);
	this.designerView = null;
	this.addingTriggerEvent = false;
	this.removeElementId = this.generateChildId('removeElement');

	this.triggerSelect = null;

	this.toElementController = null;
	this.elementController = null;
	this.elementPropertiesController = null; //Normally this is the same as the elementController unless we are coupled.

	this.propertyInputs = {};
	this.propertyInputIdToName = {};
	this.propertyToCodeEditor = {};

	this.triggerEventInputs = {};
	this.triggerCodeEditIdMapping = {};
	this.triggerTargetAbilityIdMapping = {};
	this.triggerEventTrashMapping = {};
}

WidgetElementEditor.prototype = Object.create(WidgetBase.prototype);
WidgetElementEditor.prototype.constructor = WidgetElementEditor;

WidgetElementEditor.prototype.showCodeEditor = function(
	propertyInput,
	mode,
	callback,
) {
	var currentWidget = this;

	mode = mode || 'ace/mode/javascript';

	if (propertyInput === undefined || propertyInput === null) {
		callback.call(this, { type: 'invalidPropetyInput' });
		return;
	}

	var currentFormWidget = this.parent.showModalWidget(WidgetForm, {
		title: propertyName,
		form: {
			data: propertyInput.val(),
			schema: {
				type: 'string',
			},
			options: {
				type: 'editor',
				aceMode: mode,
				buttons: {
					close: {
						title: getLanguageTag(WidgetElementEditor, 'close'),

						click: function() {
							propertyInput.val(
								currentFormWidget.getFormValues(),
							);
							propertyInput.change();

							currentFormWidget.parent.removeWidget(
								currentFormWidget.id,
								function() {
									callback.call(currentWidget, err);
									return;
								},
							);
						},

						attributes: {
							'data-cy':
								'WidgetElementEditorCodeEditorCloseButton',
						},

						styles: 'btn button btn btn-primary',
					},
				},
			},
		},
	});
};

WidgetElementEditor.prototype.setDesignerView = function(designerView) {
	var currentWidget = this;

	if (
		this.designerView !== null &&
		this._currentElementTargetedCallback !== undefined &&
		this._currentElementTargetedCallback !== null
	) {
		this.designerView.removeEventListener(
			'elementTargeted',
			this._currentElementTargetedCallback,
		);
	}

	this.designerView = designerView;

	this._currentElementTargetedCallback = this.designerView.addEventListener(
		'elementTargeted',
		function(data) {
			currentWidget.onElementTargeted(data);
		},
	);

	return;
};

WidgetElementEditor.prototype.onPropertyValid = function(property, value) {
	var currentWidget = this;

	var currentProperty = this.elementPropertiesController.getProperty(
		property,
	);

	this.propertyInputs[property].removeClass(
		'WidgetElementEditor-InvalidProperty',
	);
};

WidgetElementEditor.prototype.onPropertyInvalid = function(property, value) {
	var currentWidget = this;

	var currentProperty = this.elementPropertiesController.getProperty(
		property,
	);

	this.propertyInputs[property].addClass(
		'WidgetElementEditor-InvalidProperty',
	);
};

WidgetElementEditor.prototype.onPropertySet = function(property, value) {
	var currentProperty = this.elementPropertiesController.getProperty(
		property,
	);

	switch (currentProperty.inputType) {
		case 'text':
			this.propertyInputs[property].val(value);
			break;

		case 'number':
			this.propertyInputs[property].val(value);
			break;

		case 'color':
			this.propertyInputs[property].val(value);
			break;

		case 'font':
			this.propertyInputs[property].val(value);
			break;

		case 'image':
			if (value.startsWith('data:image/')) {
				this.propertyInputs[property].val(
					getLanguageTag(WidgetElementEditor, 'encodedImage'),
				);
			} else {
				this.propertyInputs[property].val(value);
			}

			break;

		case 'checkbox':
			if (Boolean(value)) {
				this.propertyInputs[property].prop('checked', true);
			} else {
				this.propertyInputs[property].prop('checked', false);
			}

			break;

		case 'array':
			break;

		case 'object':
			break;

		case 'code':
			if (this.propertyToCodeEditor[property] !== undefined) {
				// 				this.propertyToCodeEditor[property].setValue(value, 1);
			}
			break;

		case 'select':
			this.propertyInputs[property].val(value);
			break;

		case 'driverInstance':
			this.propertyInputs[property].val(value);
			break;
	}
};

WidgetElementEditor.prototype.onNameSet = function(oldName, name) {
	var currentElementEditor = this;

	if (currentElementEditor.elementController._implements) {
		currentElementEditor.elementController._parentPlane.updateDriverInstanceName(
			oldName,
			currentElementEditor.elementController,
		);
	}
};

WidgetElementEditor.prototype.onElementTargeted = function(data) {
	this.designerView.setTargetingElement(false);

	this._currentElementTargetedCallback = null;
	this.addingTriggerEvent = false;

	if (data.element !== null) {
		this.elementController.addTriggerEvent(
			this.triggerSelect.val(),
			data.element,
			null,
			null,
			null,
		);
		return;
	}
};

WidgetElementEditor.prototype.onTriggerEventAddButtonClick = function(button) {
	var currentWidget = this;

	if (this.toElementController !== null) {
		this.elementController.addTriggerEvent(
			this.triggerSelect.val(),
			this.toElementController.getName(),
			null,
			null,
			null,
		);
		return;
	}

	if (this.addingTriggerEvent === true) {
		return;
	}

	this.getMainContainer().showPopupInfoMessage(
		getLanguageTag(
			WidgetElementEditor,
			'selectAnElementToAddATriggerEvent',
		),
	);

	this.addingTriggerEvent = true;

	this.designerView.setTargetingElement(true);
};

WidgetElementEditor.prototype.onTriggerEventArgumentChanged = function(
	argumentInput,
) {
	if (this.elementController === null) {
		return;
	}

	var currentInput = $('#' + argumentInput.id);
	var currentTriggerMapping = this.triggerCodeEditIdMapping[argumentInput.id];

	this.elementController.setTriggerEventMapping(
		currentTriggerMapping.trigger,
		currentTriggerMapping.eventIndex,
		currentTriggerMapping.argument,
		currentInput.val(),
	);
};

WidgetElementEditor.prototype.onTriggerEventTrashClick = function(icon) {
	var currentTriggerEvent = this.triggerEventTrashMapping[icon.id];

	this.elementController.removeTriggerEvent(
		currentTriggerEvent.trigger,
		parseInt(currentTriggerEvent.name),
	);
};

WidgetElementEditor.prototype.onTargetAbilityChanged = function(abilityInput) {
	var currentInput = $('#' + abilityInput.id);
	var currentTargetAbilityMapping = this.triggerTargetAbilityIdMapping[
		abilityInput.id
	];

	if (
		this.elementController !== undefined &&
		this.elementController !== null
	) {
		this.elementController.setTriggerEventTargetAbility(
			currentTargetAbilityMapping.trigger,
			currentTargetAbilityMapping.eventIndex,
			currentInput.val(),
		);
	}

	this.update();
};

WidgetElementEditor.prototype.onTriggerSelectChange = function(triggerSelect) {
	this.setEditingTrigger(this.triggerSelect.val());
};

WidgetElementEditor.prototype.setEditingTrigger = function(triggerName) {
	this.elementController.setMetaValue('lastTrigger', triggerName);

	for (var k in this.triggerToDiv) {
		if (k === triggerName) {
			this.triggerToDiv[k].show();
		} else {
			this.triggerToDiv[k].hide();
		}
	}
};

WidgetElementEditor.prototype.setElementController = function(
	elementController,
	toElementController,
) {
	var currentWidget = this;

	this.toElementController = toElementController || null; //If we select a connector we want to filter the information

	if (this.elementController !== null) {
		//FIXME: We shouldn't have to blow up every instance of this class just to make sure all the colorpickers are gone...
		// 		$(".colorpicker").remove();

		this.elementController.removeEventListener(
			'allTriggerEventErrorsRemoved',
			this._currentAllTriggerEventErrorsRemovedCallback,
		);
		this.elementController.removeEventListener(
			'triggerEventErrorAdded',
			this._currentTriggerEventErrorAddedCallback,
		);
		this.elementController.removeEventListener(
			'triggerEventErrorRemoved',
			this._currentTriggerEventErrorRemovedCallback,
		);
		this.elementController.removeEventListener(
			'triggerEventRemoved',
			this._currentTriggerEventRemovedCallback,
		);
		this.elementPropertiesController.removeEventListener(
			'propertySet',
			this._currentPropretySetCallback,
		);
		this.elementPropertiesController.removeEventListener(
			'propertyValid',
			this._currentPropertyValidCallback,
		);
		this.elementPropertiesController.removeEventListener(
			'propertyInvalid',
			this._currentPropertyInvalidCallback,
		);
		this.elementPropertiesController.removeEventListener(
			'nameSet',
			this._currentNameSetCallback,
		);
		this.elementPropertiesController.removeEventListener(
			'triggerEventAdded',
			this._currentTriggerEventAddedCallback,
		);
	}

	this.elementController = elementController;
	this.elementPropertiesController = null;

	if (this.elementController === null) {
		this.update();
		return;
	}

	if (elementController.getCoupledElement() !== null) {
		this.elementPropertiesController = elementController.getCoupledElement();
	} else {
		this.elementPropertiesController = elementController;
	}

	this._currentAllTriggerEventErrorsRemovedCallback = this.elementController.addEventListener(
		'allTriggerEventErrorsRemoved',
		function() {
			currentWidget.update();
		},
	);

	this._currentTriggerEventErrorAddedCallback = this.elementController.addEventListener(
		'triggerEventErrorAdded',
		function(data) {
			currentWidget.update();
		},
	);

	this._currentTriggerEventErrorRemovedCallback = this.elementController.addEventListener(
		'triggerEventErrorRemoved',
		function(data) {
			// 		currentWidget.update();
		},
	);

	this._currentTriggerEventAddedCallback = this.elementController.addEventListener(
		'triggerEventAdded',
		function(data) {
			currentWidget.update();
		},
	);

	this._currentTriggerEventRemovedCallback = this.elementController.addEventListener(
		'triggerEventRemoved',
		function(data) {
			currentWidget.update();
		},
	);

	this._currentTriggerEventMappingSet = this.elementController.addEventListener(
		'triggerEventMappingSet',
		function(data) {},
	);

	this._currentPropertyValidCallback = this.elementPropertiesController.addEventListener(
		'propertyValid',
		function(data) {
			currentWidget.onPropertyValid(data.property, data.value);
		},
	);

	this._currentPropertyInvalidCallback = this.elementPropertiesController.addEventListener(
		'propertyInvalid',
		function(data) {
			currentWidget.onPropertyInvalid(data.property, data.value);
		},
	);

	this._currentPropretySetCallback = this.elementPropertiesController.addEventListener(
		'propertySet',
		function(data) {
			currentWidget.onPropertySet(data.property, data.value);
		},
	);

	this._currentNameSetCallback = this.elementPropertiesController.addEventListener(
		'nameSet',
		function(data) {
			currentWidget.onNameSet(data.oldName, data.name);
		},
	);

	this.update();
};

WidgetElementEditor.prototype.update = function() {
	var currentWidget = this;

	if (
		this.elementController === null ||
		this.elementPropertiesController === null
	) {
		return;
	}

	var currentProperties = this.elementPropertiesController.getProperties();
	var currentPropertiesKeys = Object.keys(currentProperties);

	var filtering = this.toElementController !== null;

	var isConnector = currentWidget.toElementController !== null ? true : false;

	var elementName =
		isConnector === true
			? getLanguageTag(WidgetElementEditor, 'connector')
			: getFromLanguageObject(
					this.elementPropertiesController.language,
					this.elementPropertiesController.TYPE,
			  ) +
			  ' ' +
			  getLanguageTag(WidgetElementEditor, 'element');

	var helpPageLink =
		isConnector === true
			? this.CONNECTOR_HELP_PAGE_HREF
			: this.elementPropertiesController.HELP_PAGE_HREF;

	var templateContext = {
		nameId: this.elementNameEditorId,
		isConnector: isConnector,
		elementNameLabel: getLanguageTag(
			WidgetElementEditor,
			'elementNameLabel',
		),
		editorId: this.nameEditorId,
		removeElementId: this.removeElementId,
		elementName: elementName,
		helpLinkId: this.helpLinkId,
		helpPageLink: helpPageLink,
		name: this.elementPropertiesController.getName(),
		propertiesName: getLanguageTag(WidgetElementEditor, 'properties'),
		triggersName: getLanguageTag(WidgetElementEditor, 'triggers'),
		addEventLabel: getLanguageTag(WidgetElementEditor, 'addEvent'),
		browseLabel: getLanguageTag(WidgetElementEditor, 'browse'),
		propertiesDivId: this.propertiesDivId,
		triggersDivId: this.triggersDivId,
		triggerSelectId: this.triggerSelectId,
		triggerEventAddButtonId: this.triggerEventAddButtonId,
		addButtonPath: this.ADD_BUTTON_PATH,
		elements: [],

		properties: [],

		triggers: [],
	};

	for (var i = 0; i < currentPropertiesKeys.length; i++) {
		var currentKey = currentPropertiesKeys[i];
		var currentProperty = currentProperties[currentKey];
		var propertyName = getFromLanguageObject(
			this.elementPropertiesController.language,
			currentKey,
		);
		var currentInputId = this.generateChildId(
			'_propertyInput_' + currentKey,
		);

		if (
			currentProperty.inputType !== 'layouts' &&
			currentProperty.inputType !== 'none' &&
			!currentProperty.hidden
		) {
			var currentPropertyEntry = {
				id: currentInputId,
				name: propertyName,
				value: currentProperty.value,
				input: currentProperty.inputType,
				inputOptions: [],
			};

			if (currentProperty.inputType === 'driverInstance') {
				currentProperty.inputOptions = [];

				var currentParentPlane =
					currentWidget.elementController._parentPlane;

				//We are coupled to something, so get your instance data from that things parent plane.
				if (currentWidget.elementController.getCoupledElement()) {
					currentParentPlane = currentWidget.elementController.getCoupledElement()
						._parentPlane;
				}

				currentParentPlane._runtimeDrivers[
					currentProperty.dataType
				].forEach(function(driver) {
					var currentDriverInstanceName = getFromLanguageObject(
						currentWidget.elementPropertiesController.language,
						driver.name,
					);
					currentProperty.inputOptions.push(driver.name);
				});

				if (
					currentProperty.inputOptions !== undefined &&
					currentProperty.inputOptions !== null
				) {
					for (
						var j = 0;
						j < currentProperty.inputOptions.length;
						j++
					) {
						var currentOption = currentProperty.inputOptions[j];
						var optionName = getFromLanguageObject(
							currentParentPlane.language,
							currentOption,
						);
						currentPropertyEntry.inputOptions.push({
							value: currentOption,
							name: optionName,
						});
					}
				}
			} else {
				if (
					currentProperty.inputOptions !== undefined &&
					currentProperty.inputOptions !== null
				) {
					for (
						var j = 0;
						j < currentProperty.inputOptions.length;
						j++
					) {
						var currentOption = currentProperty.inputOptions[j];
						var optionName = getFromLanguageObject(
							this.elementPropertiesController.language,
							currentOption,
						);

						currentPropertyEntry.inputOptions.push({
							value: currentOption,
							name: optionName,
						});
					}
				}
			}

			templateContext.properties.push(currentPropertyEntry);
		}
	}

	var currentTriggers = this.elementController.getTriggers();
	var currentTriggersEventErrors = this.elementController.getTriggerEventErrors();

	for (var currentKey in currentTriggers) {
		var currentTriggerContext = { events: [] };

		var triggerName = getFromLanguageObject(
			this.elementController.language,
			currentKey,
		);

		currentTriggerContext.name = triggerName;
		currentTriggerContext.key = currentKey;
		currentTriggerContext.id = this.generateChildId(
			'trigger_' + currentKey,
		);

		var currentTriggerEvents = currentTriggers[currentKey];
		var currentTriggerEventErrors =
			currentTriggersEventErrors[currentKey] || {};

		for (var i = 0; i < currentTriggerEvents.length; i++) {
			var currentEventContext = {
				id: '',
				key: '',
				name: '',
				targetElementName: '',
				abilityTitle: '',
				abilitySelectId: '',
				targetAbility: '',
				targetAbilities: [],

				argumentMappings: [],
			};

			var currentEvent = currentTriggerEvents[i];
			var currentIdPrefix = currentKey + '_' + i;

			var currentTargetElement = currentEvent.targetElement;
			var currentTargetAbility = currentEvent.targetAbility;
			var currentTargetArgumentOrder = currentEvent.targetOrder;
			var currentArgumentMapping = currentEvent.mapping;
			var currentTargetAbilities = this.elementController._parentPlane
				.getElement(currentTargetElement)
				.getAbilities();
			var currentTriggerEventError = currentTriggerEventErrors[i] || null;

			var abilityOptions = Object.keys(currentTargetAbilities).filter(
				function(ability) {
					return !currentTargetAbilities[ability].hidden;
				},
			);

			if (
				filtering &&
				currentTargetElement !== this.toElementController.getName()
			) {
				continue;
			}

			currentEventContext.id = currentIdPrefix;
			currentEventContext.trashId = currentIdPrefix + '_trash';
			currentEventContext.key = currentKey;
			currentEventContext.name = i.toString();
			currentEventContext.targetElementName = currentTargetElement;
			currentEventContext.argumentsName = getLanguageTag(
				WidgetElementEditor,
				'arguments',
			);
			currentEventContext.abilityTitle = getLanguageTag(
				WidgetElementEditor,
				'ability',
			);
			currentEventContext.abilitySelectId =
				currentIdPrefix + '_abilityOptions';
			currentEventContext.ability = currentTargetAbility;
			currentEventContext.eventIndex = i;
			currentEventContext.eventError = currentTriggerEventError !== null;

			if (currentTriggerEventError !== null) {
				currentEventContext.eventErrorMessage =
					currentTriggerEventError.errorMessage.err;
			}

			for (var j = 0; j < abilityOptions.length; j++) {
				currentEventContext.targetAbilities.push({
					label: getFromLanguageObject(
						this.elementController._parentPlane.getElement(
							currentTargetElement,
						).language,
						abilityOptions[j],
					),
					name: abilityOptions[j],
					value: abilityOptions[j],
				});
			}

			var currentTargetAbilitySelect = $(
				'#' + currentIdPrefix + '_abilityOptions',
			);

			for (var argumentName in currentArgumentMapping) {
				var currentArgumentName = argumentName;
				var currentArgumentInputId =
					currentIdPrefix + '_' + currentArgumentName + '_codeEdit';
				var currentArgumentCode =
					currentArgumentMapping[argumentName].code;

				currentEventContext.argumentMappings.push({
					id: currentArgumentInputId,
					key: argumentName,
					editIconId: '',
					label: getFromLanguageObject(
						this.elementController._parentPlane.getElement(
							currentTargetElement,
						).language,
						currentArgumentName,
					),
					name: currentArgumentName,
					value: currentArgumentCode,
				});
			}

			currentTriggerContext.events.push(currentEventContext);
		}

		templateContext.triggers.push(currentTriggerContext);
	}

	this.renderTemplate(templateContext, WidgetElementEditor.name);

	this.elementNameEditor = $('#' + this.elementNameEditorId);
	this.propertiesDiv = $('#' + this.propertiesDivId);
	this.triggersDiv = $('#' + this.triggersDivId);
	this.nameEditor = $('#' + this.nameEditorId);
	this.helpLink = $('#' + this.helpLinkId);
	this.removeElement = $('#' + this.removeElementId);
	this.triggerSelect = $('#' + this.triggerSelectId);
	this.triggerEventAddButton = $('#' + this.triggerEventAddButtonId);

	if (!helpPageLink) {
		this.helpLink.hide();
	}

	if (filtering) {
		this.propertiesDiv.hide();
	} else {
		this.propertiesDiv.show();
	}

	this.triggerEventAddButton.on('click', function() {
		currentWidget.onTriggerEventAddButtonClick(this);
	});

	this.nameEditor.on('change', function(e) {
		var currentInput = $(this).val();

		if (
			currentWidget &&
			currentWidget.elementPropertiesController !== null
		) {
			currentWidget.elementPropertiesController.changeName(currentInput);
		}
	});

	this.nameEditor.on('keyup', function(e) {
		var currentInput = $(this).val();

		if (
			currentWidget &&
			currentWidget.elementPropertiesController !== null
		) {
			currentWidget.elementPropertiesController.changeName(currentInput);
		}
	});

	this.helpLink.click(function(e) {
		e.preventDefault();
		e.stopPropagation();

		var url = $(this).attr('href');

		openLinkInNewWindowOrTab(url);
	});

	this.removeElement.click(function(e) {
		var planeEditor = currentWidget.getParent();
		var confirmationTitle, confirmationMessage;

		if (currentWidget.toElementController === null) {
			//Element language
			(confirmationTitle = currentWidget.getLanguageTag(
				'removeElementTitle',
			)),
				(confirmationMessage = currentWidget.getLanguageTag(
					'areYouSureYouWantToRemoveTheElement',
				));
		} else {
			//Connector language
			(confirmationTitle = currentWidget.getLanguageTag(
				'removeConnectorTitle',
			)),
				(confirmationMessage = currentWidget.getLanguageTag(
					'areYouSureYouWantToRemoveTheConnector',
				));
		}

		currentWidget.getMainContainer().showConfirm(
			{
				message: confirmationMessage,
				title: confirmationTitle,
				confirmLabel: currentWidget.getLanguageTag('removeButton'),
				confirmClasses: ['btn-danger'],
			},

			function() {
				if (
					currentWidget.elementController !== null &&
					planeEditor !== null
				) {
					if (currentWidget.toElementController !== null) {
						planeEditor.deleteConnector(
							currentWidget.elementController.getName(),
							currentWidget.toElementController.getName(),
						);
					} else {
						planeEditor.deleteElement(
							currentWidget.elementController.getName(),
						);
					}

					planeEditor.showToolbox();
				}
			},

			function() {
				//dismissed
			},

			function() {
				//shown
			},
		);

		// 		currentWidget.getMainContainer().showConfirm({
		// 			message: confirmationMessage,
		// 			closeButton: false,
		// 			buttons: {
		// 				cancel: {
		// 					label: getFromLanguageObject(globalLanguageObject, "cancel"),
		// 					className: 'button btn btn-cancel',
		// 				},
		// 				confirm: {
		// 					label: getFromLanguageObject(globalLanguageObject, "confirm"),
		// 					className: 'button btn btn-primary',
		// 				}
		// 			},
		// 			callback: function (result) {
		//
		// 				if(result) {
		//
		// 					if(currentWidget.elementController !== null && planeEditor !== null) {
		//
		// 						if(currentWidget.toElementController !== null) {
		//
		// 							planeEditor.deleteConnector(currentWidget.elementController.getName(), currentWidget.toElementController.getName());
		//
		// 						}
		//
		// 						else {
		//
		// 							planeEditor.deleteElement(currentWidget.elementController.getName());
		//
		// 						}
		//
		// 						planeEditor.showToolbox();
		//
		// 					}
		//
		// 				}
		// 			}
		// 		}, null);
	});

	this.triggerSelect.on('change', function() {
		currentWidget.onTriggerSelectChange(this);
		return;
	});

	this.nameEditor.val(this.elementPropertiesController.getName());
	this.propertyInputs = {};
	this.propertyInputIdToName = {};

	if (
		this.elementController === null ||
		this.elementPropertiesController === null
	) {
		return;
	}

	currentPropertiesKeys.sort();

	for (var i = 0; i < currentPropertiesKeys.length; i++) {
		var currentKey = currentPropertiesKeys[i];
		var currentProperty = currentProperties[currentKey];
		var propertyName = getFromLanguageObject(
			this.elementPropertiesController.language,
			currentKey,
		);
		var currentInputId = this.generateChildId(
			'_propertyInput_' + currentKey,
		);

		if (
			currentProperty.inputType !== 'layouts' &&
			currentProperty.inputType !== 'none' &&
			!currentProperty.hidden
		) {
			switch (currentProperty.inputType) {
				case 'text':
					this.propertyInputs[currentKey] = $('#' + currentInputId);
					this.onPropertySet(currentKey, currentProperty.value);
					this.propertyInputIdToName[currentInputId] = currentKey;

					this.propertyInputs[currentKey].change(function() {
						var currentProperty =
							currentWidget.propertyInputIdToName[this.id];
						var currentInput = $('#' + this.id);

						if (
							currentWidget.elementPropertiesController !== null
						) {
							currentWidget.elementPropertiesController.setProperty(
								currentProperty,
								currentInput.val(),
							);
						}
					});

					this.propertyInputs[currentKey].keyup(function() {
						var currentProperty =
							currentWidget.propertyInputIdToName[this.id];
						var currentInput = $('#' + this.id);

						if (
							currentWidget.elementPropertiesController !== null
						) {
							currentWidget.elementPropertiesController.setProperty(
								currentProperty,
								currentInput.val(),
							);
						}
					});

					break;

				case 'number':
					this.propertyInputs[currentKey] = $('#' + currentInputId);
					this.onPropertySet(currentKey, currentProperty.value);
					this.propertyInputIdToName[currentInputId] = currentKey;

					this.propertyInputs[currentKey].change(function() {
						var currentProperty =
							currentWidget.propertyInputIdToName[this.id];
						var currentInput = $('#' + this.id);

						if (
							currentWidget.elementPropertiesController !== null
						) {
							currentWidget.elementPropertiesController.setProperty(
								currentProperty,
								currentInput.val(),
							);
						}
					});

					this.propertyInputs[currentKey].keyup(function() {
						var currentProperty =
							currentWidget.propertyInputIdToName[this.id];
						var currentInput = $('#' + this.id);

						if (
							currentWidget.elementPropertiesController !== null
						) {
							currentWidget.elementPropertiesController.setProperty(
								currentProperty,
								currentInput.val(),
							);
						}
					});

					break;

				case 'color':
					this.propertyInputs[currentKey] = $('#' + currentInputId);
					this.onPropertySet(currentKey, currentProperty.value);
					this.propertyInputIdToName[currentInputId] = currentKey;

					this.propertyInputs[currentKey]
						.colorpicker()
						.on('changeColor', function(ev) {
							var currentProperty =
								currentWidget.propertyInputIdToName[this.id];
							var currentInput = $('#' + this.id);

							if (
								currentWidget.elementPropertiesController !==
								null
							) {
								currentWidget.elementPropertiesController.setProperty(
									currentProperty,
									currentInput.val(),
								);
							}
						});

					// 					this.propertyInputs[currentKey].keyup(function() {
					//
					// 						var currentProperty = currentWidget.propertyInputIdToName[this.id];
					// 						var currentInput = $("#" + this.id);
					//
					// 						currentWidget.elementPropertiesController.setProperty(currentProperty, currentInput.val());
					// 					});

					break;

				case 'font':
					this.propertyInputs[currentKey] = $('#' + currentInputId);
					this.onPropertySet(currentKey, currentProperty.value);
					this.propertyInputIdToName[currentInputId] = currentKey;

					this.propertyInputs[currentKey].change(function() {
						var currentProperty =
							currentWidget.propertyInputIdToName[this.id];
						var currentInput = $('#' + this.id);

						currentWidget.elementPropertiesController.setProperty(
							currentProperty,
							currentInput.val(),
						);
					});

					this.propertyInputs[currentKey].keyup(function() {
						var currentProperty =
							currentWidget.propertyInputIdToName[this.id];
						var currentInput = $('#' + this.id);

						if (
							currentWidget.elementPropertiesController !== null
						) {
							currentWidget.elementPropertiesController.setProperty(
								currentProperty,
								currentInput.val(),
							);
						}
					});

					break;

				case 'image':
					var fileImporter = $(
						'#' + currentInputId + '_fileImporter',
					);

					this.propertyInputs[currentKey] = $('#' + currentInputId);
					this.onPropertySet(currentKey, currentProperty.value);
					this.propertyInputIdToName[currentInputId] = currentKey;

					this.propertyInputs[currentKey].change(function() {
						var currentProperty =
							currentWidget.propertyInputIdToName[this.id];
						var currentInput = $('#' + this.id);

						if (
							currentWidget.elementPropertiesController !== null
						) {
							currentWidget.elementPropertiesController.setProperty(
								currentProperty,
								currentInput.val(),
							);
						}
					});

					fileImporter.change(function(e) {
						var currentInput = this;

						var file = e.target.files[0];

						if (file) {
							var reader = new FileReader();

							reader.onload = function(readerEvt) {
								var binaryString = readerEvt.target.result;

								var fileFormat = 'png';

								if (file.name.endsWith('.png')) {
									fileFormat = 'png';
								} else if (file.name.endsWith('.jpg')) {
									fileFormat = 'jpg';
								} else if (file.name.endsWith('.jpeg')) {
									fileFormat = 'jpg';
								} else if (file.name.endsWith('.gif')) {
									fileFormat = 'gif';
								} else if (file.name.endsWith('.svg')) {
									fileFormat = 'svg+xml';
								}

								var base64ImageOutput =
									'data:image/' +
									fileFormat +
									';base64,' +
									btoa(binaryString);

								$(
									'#' +
										currentInput.id.replace(
											'_fileImporter',
											'',
										),
								).val(base64ImageOutput);
								$(
									'#' +
										currentInput.id.replace(
											'_fileImporter',
											'',
										),
								).trigger('change');
							};

							reader.readAsBinaryString(file);
						}
					});

					this.propertyInputs[currentKey].keyup(function() {
						var currentProperty =
							currentWidget.propertyInputIdToName[this.id];
						var currentInput = $('#' + this.id);

						if (
							currentWidget.elementPropertiesController !== null
						) {
							currentWidget.elementPropertiesController.setProperty(
								currentProperty,
								currentInput.val(),
							);
						}
					});

					break;

				case 'checkbox':
					this.propertyInputs[currentKey] = $('#' + currentInputId);
					this.propertyInputIdToName[currentInputId] = currentKey;
					this.onPropertySet(currentKey, currentProperty.value);

					this.propertyInputs[currentKey].change(function() {
						var currentProperty =
							currentWidget.propertyInputIdToName[this.id];
						var currentInput = $('#' + this.id);
						var value = currentInput.is(':checked');

						if (
							currentWidget.elementPropertiesController !== null
						) {
							currentWidget.elementPropertiesController.setProperty(
								currentProperty,
								value,
							);
						}
					});

					break;

				case 'array':
					break;

				case 'object':
					break;

				case 'code':
					this.propertyInputs[currentKey] = $('#' + currentInputId);
					this.propertyInputIdToName[currentInputId] = currentKey;

					var aceEditor = window.ace.edit(
						`${currentInputId}_aceEditor`,
					);

					aceEditor.setOptions({
						enableBasicAutocompletion: true, // the editor completes the statement when you hit Ctrl + Space
						enableLiveAutocompletion: true, // the editor completes the statement while you are typing
						showPrintMargin: false, // hides the vertical limiting strip
						maxLines: Infinity,
						fontSize: '100%', // ensures that the editor fits in the environment
					});

					aceEditor.getSession().setMode('ace/mode/javascript');
					aceEditor.setValue(currentProperty.value);
					aceEditor.clearSelection();

					this.propertyInputs[currentKey].on('keyup', function(e) {
						var editor = ace.edit(`${this.id}_aceEditor`);

						if (
							currentWidget.elementPropertiesController !== null
						) {
							var currentProperty =
								currentWidget.propertyInputIdToName[this.id];

							currentWidget.elementPropertiesController.setProperty(
								currentProperty,
								editor.getSession().getValue(),
							);
						}
					});

					console.log($(`#${currentInputId}_popoutEditor`));

					$(`#${currentInputId}_popoutEditor`).click(function(e) {
						var currentProperty =
							currentWidget.propertyInputIdToName[
								this.id.replace('_popoutEditor', '')
							];
						var aceEditor = window.ace.edit(
							`${this.id.replace('_popoutEditor', '')}_aceEditor`,
						);

						currentWidget.getMainContainer().setModalWidget(
							WidgetSettingsForm,
							{
								fields: [
									{
										name: 'code',
										type: 'code',
										label: '',
										value: aceEditor
											.getSession()
											.getValue(),
										mode: 'ace/mode/javascript',
									},
								],
							},
							function(err, settingsWidget) {
								settingsWidget.setTitle(
									getLanguageTag(
										currentWidget.constructor,
										'codeEditorTitle',
									),
								);

								settingsWidget.addEventListener(
									'dismissed',
									function() {
										currentWidget
											.getMainContainer()
											.hideModal();
									},
								);

								settingsWidget.addEventListener(
									'confirmed',
									function() {
										currentWidget
											.getMainContainer()
											.hideModal();

										currentWidget.elementPropertiesController.setProperty(
											currentProperty,
											settingsWidget.getValues().code,
										);
										aceEditor.setValue(
											settingsWidget.getValues().code,
										);
										aceEditor.clearSelection();
									},
								);

								this.showModal();
								return;
							},
						);
					});

					break;

				case 'select':
					this.propertyInputs[currentKey] = $('#' + currentInputId);
					this.propertyInputIdToName[currentInputId] = currentKey;
					this.onPropertySet(currentKey, currentProperty.value);

					this.propertyInputs[currentKey].change(function() {
						var currentProperty =
							currentWidget.propertyInputIdToName[this.id];
						var currentInput = $('#' + this.id);

						if (
							currentWidget.elementPropertiesController !== null
						) {
							currentWidget.elementPropertiesController.setProperty(
								currentProperty,
								currentInput.val(),
							);
						}
					});

					break;

				case 'driverInstance':
					this.propertyInputs[currentKey] = $('#' + currentInputId);
					this.propertyInputIdToName[currentInputId] = currentKey;
					this.onPropertySet(currentKey, currentProperty.value);

					this.propertyInputs[currentKey].change(function() {
						var currentProperty =
							currentWidget.propertyInputIdToName[this.id];
						var currentInput = $('#' + this.id);

						if (
							currentWidget.elementPropertiesController !== null
						) {
							currentWidget.elementPropertiesController.setProperty(
								currentProperty,
								currentInput.val(),
							);
						}
					});

					break;
			}
		}
	}

	this.triggerEventInputs = {};
	this.triggerCodeEditIdMapping = {};
	this.triggerTargetAbilityIdMapping = {};
	this.triggerToDiv = {};

	for (var i = 0; i < templateContext.triggers.length; i++) {
		var currentTriggerContext = templateContext.triggers[i];

		this.triggerEventInputs[currentTriggerContext.key] = {};
		this.triggerToDiv[currentTriggerContext.key] = $(
			'#' + currentTriggerContext.id,
		);
		this.triggerToDiv[currentTriggerContext.key].hide();

		for (var j = 0; j < currentTriggerContext.events.length; j++) {
			var currentEventContext = currentTriggerContext.events[j];
			var currentAbilitySelectId = currentEventContext.abilitySelectId;
			this.triggerEventTrashMapping[currentEventContext.trashId] = {
				trigger: currentTriggerContext.key,
				name: currentEventContext.name,
			};

			var currentTriggerEventTrash = $('#' + currentEventContext.trashId);

			currentTriggerEventTrash.on('click', function() {
				currentWidget.onTriggerEventTrashClick(this);
			});

			var currentArgumentMapping = currentEventContext.argumentMappings;
			var currentTargetAbilitySelect = $('#' + currentAbilitySelectId);

			currentTargetAbilitySelect.val(currentEventContext.ability);

			this.triggerTargetAbilityIdMapping[currentAbilitySelectId] = {
				trigger: currentTriggerContext.key,
				event: currentEventContext.key,
				eventIndex: currentEventContext.eventIndex,
			};

			currentTargetAbilitySelect.on('change', function() {
				currentWidget.onTargetAbilityChanged(this);
			});

			this.triggerEventInputs[currentTriggerContext.key][
				currentEventContext.key
			] = {};
			this.triggerEventInputs[currentTriggerContext.key][
				currentEventContext.key
			].targetAbilitySelect = currentTargetAbilitySelect;
			this.triggerEventInputs[currentTriggerContext.key][
				currentEventContext.key
			].targetArgumentInputs = {};

			for (var l = 0; l < currentArgumentMapping.length; l++) {
				var currentArgumentMappingId = currentArgumentMapping[l].id;

				var currentArgumentInput = $('#' + currentArgumentMappingId);

				var onKeyup = function() {
					currentWidget.onTriggerEventArgumentChanged(this);
				};

				var onChange = function() {
					currentWidget.onTriggerEventArgumentChanged(this);
				};

				var optionsData = [];
				var currentElementProperties = currentWidget.elementController.getProperties();

				for (var k in currentElementProperties) {
					if (!currentElementProperties[k].hidden) {
						optionsData.push(k);
					}
				}

				var options = {
					data: optionsData,
					adjustWidth: false,
					setFieldValue: false,

					list: {
						hideOnEmptyPhrase: false,
						onChooseEvent: function(field, value) {
							this.lastSetValue = this.lastSetValue || '';

							if (field.val() === this.lastSetValue) {
								field
									.val(field.val() + value)
									.trigger('change');
							} else {
								field
									.val(
										field.val().replace(/\w+[.!?]?$/, '') +
											value,
									)
									.trigger('change');
							}

							this.lastSetValue = field.val();
						},
					},
				};

				currentArgumentInput.easyAutocomplete(options);

				currentArgumentInput.on('change', onChange);

				currentArgumentInput.on('keyup', onKeyup);

				this.triggerCodeEditIdMapping[currentArgumentMappingId] = {
					trigger: currentTriggerContext.key,
					event: currentEventContext.key,
					eventIndex: currentEventContext.eventIndex,
					argument: currentArgumentMapping[l].key,
				};
				this.triggerEventInputs[currentTriggerContext.key][
					currentEventContext.key
				].targetArgumentInputs[
					currentArgumentMapping[l].key
				] = currentArgumentInput;
			}
		}
	}

	this.triggerSelect.val(
		this.elementController.getMetaValue('lastTrigger') ||
			this.elementController.DEFAULT_TRIGGER,
	);
	this.setEditingTrigger(
		this.elementController.getMetaValue('lastTrigger') ||
			this.elementController.DEFAULT_TRIGGER,
	);
};

WidgetElementEditor.prototype.CONNECTOR_HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

WidgetElementEditor.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			properties: 'Properties',
			loading: 'Loading',
			elementNameLabel: 'Name',
			triggers: 'Triggers',
			ability: 'Ability',
			arguments: 'Arguments',
			addEvent: 'Add Event',
			encodedImage: 'Encoded Image',
			selectAnElementToAddATriggerEvent:
				'Select an element to add a trigger event.',
			removeConnectorTitle: 'Delete Connector',
			removeElementTitle: 'Delete Element',
			areYouSureYouWantToRemoveTheElement:
				'Are you sure you want to delete this element?',
			areYouSureYouWantToRemoveTheConnector:
				'Are you sure you want to delete this connector?',
			removeButton: 'Delete',
			connector: 'Connector',
			element: 'Element',
			browse: 'Browse',
			codeEditorTitle: 'Code Editor',
			help: 'Help',
		},
	},
);
