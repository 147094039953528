function ElementAppBLECharacteristicBase(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);

	this._pluginBLECentral = requires.PluginBLECentral;
}

ElementAppBLECharacteristicBase.prototype = Object.create(
	ElementBase.prototype,
);
ElementAppBLECharacteristicBase.prototype.constructor = ElementAppBLECharacteristicBase;

ElementAppBLECharacteristicBase.prototype.REQUIRES = ['PluginBLECentral'];

ElementAppBLECharacteristicBase.prototype._convertDataFromRaw = function(
	data,
	dataType,
	callback,
) {
	if (
		data === null ||
		data === undefined ||
		data.slice === undefined ||
		data.length === 0
	) {
		callback.call(this, false, null);
		return;
	}

	switch (dataType) {
		case 'ATMO_DATATYPE_VOID':
			callback.call(this, false, null);
			return;
			break;

		case 'ATMO_DATATYPE_BINARY':
			callback.call(this, false, data.slice());
			return;
			break;

		case 'ATMO_DATATYPE_CHAR':
			callback.call(this, false, String.fromCharCode(data[0]));
			return;
			break;

		case 'ATMO_DATATYPE_BOOL':
			if (data[0] === 0) {
				callback.call(this, false, false);
				return;
			}

			callback.call(this, false, true);
			return;

			break;

		case 'ATMO_DATATYPE_INT':
			if (data.length < 4) {
				callback.call(this, { type: 'invalidDataSize' }, 0);
				return;
			}

			var buf = new Uint8Array(4);

			for (var i = 0; i < 4; i++) {
				buf[i] = data[i];
			}

			var value = new Int32Array(buf.buffer)[0];

			callback.call(this, false, value);
			return;

			break;

		case 'ATMO_DATATYPE_UNSIGNED_INT':
			if (data.length < 4) {
				callback.call(this, { type: 'invalidDataSize' }, 0);
				return;
			}

			var buf = new Uint8Array(4);

			for (var i = 0; i < 4; i++) {
				buf[i] = data[i];
			}

			var value = new Uint32Array(buf.buffer)[0];

			callback.call(this, false, value);
			return;

			break;

		case 'ATMO_DATATYPE_FLOAT':
			if (data.length < 4) {
				callback.call(this, { type: 'invalidDataSize' }, 0);
				return;
			}

			var buf = new Uint8Array(4);

			for (var i = 0; i < 4; i++) {
				buf[i] = data[i];
			}

			var value = new Float32Array(buf.buffer)[0];

			callback.call(this, false, value);
			return;

			break;

		case 'ATMO_DATATYPE_DOUBLE':
			if (data.length < 8) {
				callback.call(this, { type: 'invalidDataSize' }, 0);
				return;
			}

			var buf = new Uint8Array(8);

			for (var i = 0; i < 8; i++) {
				buf[i] = data[i];
			}

			var value = new Float64Array(buf.buffer)[0];

			callback.call(this, false, value);
			return;

			break;

		case 'ATMO_DATATYPE_STRING':
			var value = '';

			for (var i = 0; i < data.length; i++) {
				if (data[i] === 0) {
					break;
				}

				value += String.fromCharCode(data[i]);
			}

			callback.call(this, false, value);

			break;

		case 'ATMO_DATATYPE_3D_VECTOR_FLOAT':
			if (data.length < 12) {
				callback.call(this, { type: 'invalidDataSize' }, 0);
				return;
			}

			var buf = new Uint8Array(12);

			for (var i = 0; i < 12; i++) {
				buf[i] = data[i];
			}

			var value = {};
			value.x = new Float32Array(buf.buffer.slice(0, 4))[0];
			value.y = new Float32Array(buf.buffer.slice(4, 8))[0];
			value.z = new Float32Array(buf.buffer.slice(8, 12))[0];

			callback.call(this, false, value);
			return;

			break;
		case 'ATMO_DATATYPE_3D_VECTOR_DOUBLE':
			if (data.length < 24) {
				callback.call(this, { type: 'invalidDataSize' }, 0);
				return;
			}

			var buf = new Uint8Array(24);

			for (var i = 0; i < 24; i++) {
				buf[i] = data[i];
			}

			var value = {};
			value.x = new Float64Array(buf.buffer.slice(0, 8))[0];
			value.y = new Float64Array(buf.buffer.slice(8, 16))[0];
			value.z = new Float64Array(buf.buffer.slice(16, 24))[0];

			callback.call(this, false, value);
			return;

			break;
		default:
			callback.call(
				this,
				{ type: 'missingSupport', arguments: { dataType: dataType } },
				null,
			);
			return;
			break;
	}
};

ElementAppBLECharacteristicBase.prototype._convertDataToRaw = function(
	data,
	dataType,
	callback,
) {
	switch (dataType) {
		case 'ATMO_DATATYPE_VOID':
			callback.call(this, false, [0]);
			return;
			break;

		case 'ATMO_DATATYPE_BINARY':
			if (data.slice === undefined) {
				callback.call(this, false, [0]);
				return;
			}

			callback.call(this, false, data.slice());
			return;
			break;

		case 'ATMO_DATATYPE_CHAR':
			if (data === null || data === undefined) {
				callback.call(this, false, [0]);
				return;
			}

			callback.call(this, false, [data.charCodeAt(0)]);
			return;
			break;

		case 'ATMO_DATATYPE_BOOL':
			if (data === true) {
				callback.call(this, false, [1]);
				return;
			}

			callback.call(this, false, [0]);
			return;

			break;

		case 'ATMO_DATATYPE_INT':
			data = parseInt(data);

			if (isNaN(data)) {
				data = 0;
			}

			var value = [0, 0, 0, 0];

			for (i = 0; i < value.length; i++) {
				var byte = data & 0xff;
				value[i] = byte;
				data = (data - byte) / 256;
			}

			callback.call(this, false, value);
			return;

			break;

		case 'ATMO_DATATYPE_UNSIGNED_INT':
			data = parseInt(data);

			if (isNaN(data)) {
				data = 0;
			}

			if (data < 0) {
				callback.call(this, { type: 'invalidInput' }, []);
				return;
			}

			var value = [0, 0, 0, 0];

			value[3] = (data >> 24) & 0xff;
			value[2] = (data >> 16) & 0xff;
			value[1] = (data >> 8) & 0xff;
			value[0] = (data >> 0) & 0xff;

			callback.call(this, false, value);
			return;

			break;

		case 'ATMO_DATATYPE_FLOAT':
			data = parseFloat(data);

			if (isNaN(data)) {
				data = 0.0;
			}

			var buffer = new ArrayBuffer(4);
			var intView = new Uint8Array(buffer);
			var floatView = new Float32Array(buffer);

			floatView[0] = data;

			callback.call(this, false, [
				intView[0],
				intView[1],
				intView[2],
				intView[3],
			]);
			return;

			break;

		case 'ATMO_DATATYPE_DOUBLE':
			data = parseFloat(data);

			if (isNaN(data)) {
				data = 0.0;
			}

			var buffer = new ArrayBuffer(8);
			var intView = new Uint8Array(buffer);
			var floatView = new Float64Array(buffer);

			floatView[0] = data;

			callback.call(this, false, [
				intView[0],
				intView[1],
				intView[2],
				intView[3],
				intView[4],
				intView[5],
				intView[6],
				intView[7],
			]);
			return;

			break;

		case 'ATMO_DATATYPE_STRING':
			if (data === undefined || data === null) {
				callback.call(this, false, [0]);
				return;
			}

			data = data.toString();
			var value = [];

			for (var i = 0; i < data.length; i++) {
				value.push(data.charCodeAt(i));
			}

			value.push(0); //Null terminator for C strings.

			callback.call(this, false, value);

			break;

		case 'ATMO_DATATYPE_3D_VECTOR_FLOAT':
			if (data === undefined || data === null) {
				callback.call(this, false, [0]);
				return;
			}

			var bufferX = new ArrayBuffer(4);
			var intViewX = new Uint8Array(bufferX);
			var floatViewX = new Float32Array(bufferX);

			var bufferY = new ArrayBuffer(4);
			var intViewY = new Uint8Array(bufferY);
			var floatViewY = new Float32Array(bufferY);

			var bufferZ = new ArrayBuffer(4);
			var intViewZ = new Uint8Array(bufferZ);
			var floatViewZ = new Float32Array(bufferZ);

			floatViewX[0] = data.x;
			floatViewY[0] = data.y;
			floatViewZ[0] = data.z;

			console.log('Data.x: ' + data.x);
			console.log('Data.y: ' + data.y);
			console.log('Data.z: ' + data.z);
			console.log(JSON.stringify(intViewX));
			console.log(JSON.stringify(intViewY));
			console.log(JSON.stringify(intViewZ));

			callback.call(this, false, [
				intViewX[0],
				intViewX[1],
				intViewX[2],
				intViewX[3],
				intViewY[0],
				intViewY[1],
				intViewY[2],
				intViewY[3],
				intViewZ[0],
				intViewZ[1],
				intViewZ[2],
				intViewZ[3],
			]);

			break;

		case 'ATMO_DATATYPE_3D_VECTOR_DOUBLE':
			if (data === undefined || data === null) {
				callback.call(this, false, [0]);
				return;
			}

			var bufferX = new ArrayBuffer(8);
			var intViewX = new Uint8Array(bufferX);
			var floatViewX = new Float64Array(bufferX);

			var bufferY = new ArrayBuffer(8);
			var intViewY = new Uint8Array(bufferY);
			var floatViewY = new Float64Array(bufferY);

			var bufferZ = new ArrayBuffer(8);
			var intViewZ = new Uint8Array(bufferZ);
			var floatViewZ = new Float64Array(bufferZ);

			floatViewX[0] = data.x;
			floatViewY[0] = data.y;
			floatViewZ[0] = data.z;

			callback.call(this, false, [
				intViewX[0],
				intViewX[1],
				intViewX[2],
				intViewX[3],
				intViewX[4],
				intViewX[5],
				intViewX[6],
				intViewX[7],
				intViewY[0],
				intViewY[1],
				intViewY[2],
				intViewY[3],
				intViewY[4],
				intViewY[5],
				intViewY[6],
				intViewY[7],
				intViewZ[0],
				intViewZ[1],
				intViewZ[2],
				intViewZ[3],
				intViewZ[4],
				intViewZ[5],
				intViewZ[6],
				intViewZ[7],
			]);

			break;

		default:
			callback.call(this, { type: 'missingSupport' }, null);
			return;
			break;
	}
};

ElementAppBLECharacteristicBase.prototype._read = function(
	triggerName,
	callback,
) {
	var currentElement = this;

	this._getProperty('bleServiceUuid', function(err, bleServiceUuid) {
		if (err) {
			callback.call(this, err);
			return this._triggerError(err, function() {});
		}

		this._getProperty('bleCharacteristicUuid', function(
			err,
			bleCharacteristicUuid,
		) {
			if (err) {
				callback.call(this, err);
				return this._triggerError(err, function() {});
			}

			this._pluginBLECentral.read(
				null,
				bleServiceUuid,
				bleCharacteristicUuid,
				function(err, data) {
					if (err) {
						callback.call(currentElement, err);
						return currentElement._triggerError(err, function() {});
					}

					currentElement._getProperty('readDataType', function(
						err,
						readDataType,
					) {
						if (err) {
							callback.call(currentElement, err);
							return currentElement._triggerError(
								err,
								function() {},
							);
						}

						currentElement._convertDataFromRaw(
							data,
							readDataType,
							function(err, newReadData) {
								if (err) {
									callback.call(currentElement, err);
									return currentElement._triggerError(
										err,
										function() {},
									);
								}

								currentElement._setProperty(
									'readData',
									newReadData,
									function(err, data) {
										if (err) {
											callback.call(currentElement, err);
											return currentElement._triggerError(
												err,
												function() {},
											);
										}

										currentElement.trigger(
											triggerName,
											callback,
										);
										return;
									},
								);
							},
						);
					});
				},
			);
		});
	});
};

ElementAppBLECharacteristicBase.prototype._write = function(
	value,
	triggerName,
	callback,
) {
	var currentElement = this;

	this._getProperty('bleServiceUuid', function(err, bleServiceUuid) {
		if (err) {
			callback.call(this, err);
			this._triggerError(err, function() {});
			return;
		}

		this._getProperty('bleCharacteristicUuid', function(
			err,
			bleCharacteristicUuid,
		) {
			if (err) {
				callback.call(this, err);
				this._triggerError(err, function() {});
				return;
			}

			this._setProperty('writtenData', value, function(err) {
				if (err) {
					callback.call(this, err);
					this._triggerError(err, function() {});
					return;
				}

				this._getProperty('writeDataType', function(
					err,
					writeDataType,
				) {
					if (err) {
						callback.call(this, err);
						this._triggerError(err, function() {});
						return;
					}

					this._convertDataToRaw(value, writeDataType, function(
						err,
						rawValue,
					) {
						if (err) {
							callback.call(this, err);
							this._triggerError(err, function() {});
							return;
						}

						try {
							bleServiceUuid = JSON.parse(bleServiceUuid);
						} catch (err) {}

						try {
							bleCharacteristicUuid = JSON.parse(
								bleCharacteristicUuid,
							);
						} catch (err) {}

						currentElement._pluginBLECentral.write(
							null,
							bleServiceUuid,
							bleCharacteristicUuid,
							rawValue,
							function(err) {
								if (err) {
									callback.call(currentElement, err);
									currentElement._triggerError(
										err,
										function() {},
									);
									return;
								}

								currentElement.trigger(triggerName, callback);
								return;
							},
						);
					});
				});
			});
		});
	});
};

ElementAppBLECharacteristicBase.prototype._writeWithoutResponse = function(
	value,
	callback,
) {};

ElementAppBLECharacteristicBase.prototype._subscribe = function(
	value,
	callback,
) {
	var currentElement = this;
	currentElement._pluginBLECentral.subscribe(
		null,
		bleServiceUuid,
		bleCharacteristicUuid,
		function(data) {
			console.log(data);
		},
		callback,
	);
};

ElementAppBLECharacteristicBase.prototype._unsubscribe = function(
	value,
	callback,
) {};
