function ElementAppWebLink(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);
}

ElementAppWebLink.prototype = Object.create(ElementBase.prototype);
ElementAppWebLink.prototype.constructor = ElementAppWebLink;

ElementAppWebLink.prototype.open = function(url, callback) {
	this._getProperty('url', function(err, data) {
		url = url || data;

		openLinkInNewWindowOrTab(url);

		this.trigger('opened', callback);
		return;
	});
};

ElementAppWebLink.prototype.setURL = function(url, callback) {
	this._setProperty('url', url, function(err, url) {
		this.trigger('URLSet', callback);
		return;
	});
};
