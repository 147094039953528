class Jwt {
	constructor(jwtData) {
		this.token = jwtData.jwtToken;
		this.tokenExpire = new Date(jwtData.jwtTokenExpiry);
	}

	isExpired() {
		const now = new Date();
		return this.tokenExpire <= now;
	}
}
