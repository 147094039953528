function PlaneControllerEmbeddedEsp32(parentController, elementName) {
	PlaneControllerEmbeddedBase.call(this, parentController, elementName);
}

PlaneControllerEmbeddedEsp32.prototype = Object.create(
	PlaneControllerEmbeddedBase.prototype,
);
PlaneControllerEmbeddedEsp32.prototype.constructor = PlaneControllerEmbeddedEsp32;

PlaneControllerEmbeddedEsp32.prototype.TYPE = 'esp32';
PlaneControllerEmbeddedEsp32.prototype.VARIANTS = [];
PlaneControllerEmbeddedEsp32.prototype.SUPPORTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'interval',
	'variables',
	'fpmath',
	'ble',
	'gpio',
	'adc',
	'i2c',
	'spi',
	'esp32',
	'wifi',
	'uart',
	'debugPrint',
	'log',
	'uptime',
];
PlaneControllerEmbeddedEsp32.prototype.SELECTABLE_VARIANTS = [];

PlaneControllerEmbeddedEsp32.prototype.DRIVERS = {
	adc: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_ADC_ADC1',
		},
	],
	ble: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLE_BLE1',
		},
	],
	block: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLOCK_BLOCK1',
		},
	],
	embeddedCloud: [
		{
			id: 1,
			name: 'ATMO_DRIVERINSTANCE_CLOUD_WIFI',
		},
	],
	datetime: [],
	filesytem: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_FILESYSTEM_FILESYSTEM1',
		},
	],
	gpio: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_GPIO_GPIO1',
		},
	],
	http: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_HTTP_HTTP1',
			primary: true,
		},
	],
	i2c: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C1',
			primary: true,
		},
	],
	interval: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_INTERVAL_INTERVAL1',
		},
	],
	nfc: [],
	pwm: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_PWM_PWM1',
		},
	],
	spi: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_SPI_SPI1',
		},
	],
	uart: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_UART_UART1',
		},
		{
			id: 1,
			name: 'ATMO_DRIVERINSTANCE_UART_UART2',
			primary: true,
		},
		{
			id: 2,
			name: 'ATMO_DRIVERINSTANCE_UART_UART3',
		},
	],
	wifi: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_WIFI_WIFI1',
		},
	],
};

PlaneControllerEmbeddedEsp32.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'IO13',
	},

	EmbeddedPWMPin: {
		instance: 0,
		pin: 'IO13',
	},

	EmbeddedADCPin: {
		instance: 0,
		pin: 'IO2',
	},

	EmbeddedCloudCommand: {
		instance: 'ATMO_DRIVERINSTANCE_CLOUD_WIFI',
	},

	EmbeddedCloudEvent: {
		instance: 'ATMO_DRIVERINSTANCE_CLOUD_WIFI',
	},

	EmbeddedRelayClick: {
		relay1Pin: 'IO18',
		relay2Pin: 'IO19',
		gpioDriverInstance: 0,
	},

	EmbeddedIRThermoClick: {
		i2cInstance: 0,
	},

	EmbeddedHBridge2Click: {
		enablePin: 'IO18',
		dirPin: 'IO19',
		ginPin: 'IO20',
		speedCtrlPin: 'IO22',
		gpioDriverInstance: 0,
		pwmDriverInstance: 0,
		initialSpeedPct: 100,
	},

	EmbeddedGpsClick: {
		uartDriverInstance: 1,
		gpioDriverInstance: 0,
		powerPin: 'IO18',
		wupPin: 'IO19',
		rstPin: 'IO20',
		reportFrequency: 1,
	},

	EmbeddedMotionClick: {
		gpioDriverInstance: 0,
		enablePin: 'IO18',
		motionPin: 'IO19',
	},

	Embedded8x8LedClick: {
		csPin: 'IO18',
	},

	EmbeddedSigfoxClick: {
		csPin: 'IO4',
		rstPin: 'IO18',
		ackPin: 'IO19',
	},

	EmbeddedGroveLight: {
		adcInstance: 0,
		adcPin: 'IO2',
	},

	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'IO13',
	},

	EmbeddedGroveTemperature: {
		adcInstance: 0,
		adcPin: 'IO2',
	},

	EmbeddedGroveRotaryAngle: {
		adcInstance: 0,
		adcPin: 'IO2',
	},

	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'IO13',
	},

	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'IO13',
	},

	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'IO13',
	},

	EmbeddedADXL343: {
		i2cInstance: 0,
		gpioInstance: 0,
		int1Pin: 'IO18',
		int2Pin: 'IO19',
	},

	EmbeddedADXL345: {
		i2cInstance: 0,
		gpioInstance: 0,
		int1Pin: 'IO18',
		int2Pin: 'IO19',
	},
};

PlaneControllerEmbeddedEsp32.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			defaultName: 'ESP32',
			ATMO_DRIVERINSTANCE_CLOUD_WIFI: 'WiFi',
		},
	},
);
