function WidgetDashboard(id, api, parent, options) {
	var currentWidget = this;

	WidgetBase.call(this, id, api, parent, options);

	this.noWidgetsNoticeContainerId = this.generateChildId('noWidgetsNotice');
	this.packeryContainerId = this.generateChildId('packeryContainer');
	this.sideMenuId = this.generateChildId('sideMenu');
	this.sideMenuGripperId = this.generateChildId('sideMenuGripper');
	this.sideMenuContainerId = this.generateChildId('sideMenuContainer');
	this.noWidgetsOverlayId = this.generateChildId('noWidgetsOverlay');

	this.widgetDraggies = {};

	this._firstTouch = { x: null, y: null };
}

//WidgetBase Inheritance
WidgetDashboard.prototype = Object.create(WidgetBase.prototype);
WidgetDashboard.prototype.constructor = WidgetDashboard;

WidgetDashboard.prototype.enableTouch = function() {
	var currentWidget = this;
	/* 
		https://api.jquery.com/jQuery.proxy/
		Keeps the scope of WidgetDashboard when an event is emitted from document
	*/
	$(document).on(
		'touchstart.WidgetDashboard',
		$.proxy(function(e) {
			currentWidget._onTouchStart(e);
		}, currentWidget),
	);
	$(document).on(
		'touchmove.WidgetDashboard',
		$.proxy(function(e) {
			currentWidget._onTouchMove(e);
		}, currentWidget),
	);
	$(document).on(
		'touchcancel.WidgetDashboard',
		$.proxy(function(e) {
			currentWidget._onTouchCancel(e);
		}, currentWidget),
	);
	$(document).on(
		'touchend.WidgetDashboard',
		$.proxy(function(e) {
			currentWidget._onTouchEnd(e);
		}, currentWidget),
	);
};

WidgetDashboard.prototype.disableTouch = function() {
	/*
		https://api.jquery.com/event.namespace/ 
		There are other touch events bound to document in the UI, this helps bound the events specifically to this widget
	*/
	$(document).off('touchstart.WidgetDashboard');
	$(document).off('touchmove.WidgetDashboard');
	$(document).off('touchcancel.WidgetDashboard');
	$(document).off('touchend.WidgetDashboard');
};

WidgetDashboard.prototype._onTouchStart = function(event) {
	//Only capture for the left side of the screen
	if (event.touches[0].clientX < $(document).width() / 2) {
		return;
	}

	this._firstTouch = {
		x: event.touches[0].clientX,
		y: event.touches[0].clientY,
	};
};

WidgetDashboard.prototype._onTouchEnd = function(event) {
	this._firstTouch = { x: null, y: null };
};

WidgetDashboard.prototype._onTouchCancel = function(event) {
	this._firstTouch = { x: null, y: null };
};

WidgetDashboard.prototype._onTouchMove = function(event) {
	if (this._firstTouch.x === null || this._firstTouch.y === null) {
		return;
	}

	var xUp = event.touches[0].clientX;
	var yUp = event.touches[0].clientY;

	var xDiff = this._firstTouch.x - xUp;
	var yDiff = this._firstTouch.y - yUp;

	//Deadzone check
	if (Math.abs(xDiff) < 64 && Math.abs(yDiff) < 64) {
		return;
	}

	if (Math.abs(xDiff) > Math.abs(yDiff)) {
		if (xDiff > 0) {
			this.setSideMenuCollapsed(false);
		} else {
			this.setSideMenuCollapsed(true);
		}
	}

	this._firstTouch = { x: null, y: null };
};

WidgetDashboard.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.renderTemplate(
		{
			noWidgetsNoticeContainerId: this.noWidgetsNoticeContainerId,
			packeryContainerId: this.packeryContainerId,
			sideMenuId: this.sideMenuId,
			sideMenuGripperId: this.sideMenuGripperId,
			sideMenuContainerId: this.sideMenuContainerId,
			noWidgetsOverlayId: this.noWidgetsOverlayId,
			noWidgetsLabel: getLanguageTag(
				this.constructor,
				'youHaveNoWidgets',
			),
			needHelpLabel: getLanguageTag(
				this.constructor,
				'needHelpAddingWidgets',
			),
		},
		WidgetDashboard.name,
	);

	this.packeryContainer = $(`#${this.packeryContainerId}`);
	this.noWidgetsNoticeContainer = $(`#${this.noWidgetsNoticeContainerId}`);
	this.sideMenu = $(`#${this.sideMenuId}`);
	this.sideMenuGripper = $(`#${this.sideMenuGripperId}`);
	this.sideMenuContainer = $(`#${this.sideMenuContainerId}`);
	this.noWidgetsOverlay = $(`#${this.noWidgetsOverlayId}`);

	this.sideMenuGripper.click(function() {
		currentWidget.onSideMenuGripperClick();
	});

	this.enableTouch();

	this.addChildWidget(
		WidgetDashboardMenu,
		this.sideMenuContainerId,
		{ showChildrenInherit: currentWidget.getOptions().showChildrenInherit },
		function(err, sideMenuWidget) {
			sideMenuWidget.addEventListener('menuEntrySelected', function(
				value,
			) {
				currentWidget.onMenuEntrySelected(value);
			});

			sideMenuWidget.addEventListener('optionEntrySelected', function(
				value,
			) {
				currentWidget.onOptionEntrySelected(value);
			});

			this.sideMenu.hide();

			if (!isPhonegap()) {
				currentWidget.sideMenu.show();
			}

			if (this._options.readOnly) {
				this.sideMenu.hide();
			}

			this.packeryContainer.packery({
				itemSelector: '.WidgetDashboard-Selector',
				columnWidth: 200,
				rowHeight: 200,
				gutter: 0,
				transitionDuration: 0,
				stagger: 0,
				resize: true,
			});

			this.packeryContainer.on('dragItemPositioned', function() {
				requestAnimationFrame(function() {
					currentWidget.packeryContainer.packery();

					currentWidget.event('configurationChanged');
				});
			});

			this.getMainContainer().addEventListener('hashChanged', function() {
				currentWidget.packeryContainer.packery();
			});

			this.getMainContainer().addEventListener('modalHidden', function() {
				currentWidget.enableTouch();
			});

			this.getMainContainer().addEventListener('modalShown', function() {
				currentWidget.disableTouch();
			});

			WidgetBase.prototype.initialize.call(this, function(err) {
				callback.call(this, err);
			});
		},
	);
};

WidgetDashboard.prototype.getShiftPositions = function() {
	return this.packeryContainer.packery('getShiftPositions', 'id');
};

WidgetDashboard.prototype._onWidgetConfigurationSet = function(data) {
	//We throw this event up higher to the LocationDashbord to work with
	this.event('configurationChanged', {
		dashboard: this,
		configuration: data.configuration,
		widget: data.widget,
	});
};

WidgetDashboard.prototype.onMenuEntrySelected = function(value) {
	var currentWidget = this;

	this.addWidget(value, {}, function(err, newWidget) {
		this.setSideMenuCollapsed(true);
	});
};

WidgetDashboard.prototype.setChildrenInheritable = async function(
	isInheritable,
) {
	const currentWidget = this;
	const api = currentWidget.getApiV2().apis;

	const orgModel = new Organization(
		api,
		await this.asyncGetOrganizationContext(),
	);
	orgModel.setChildrenInheritable(isInheritable);
	try {
		await orgModel.save();
	} catch (err) {
		currentWidget
			.getMainContainer()
			.showPopupErrorMessage(
				currentWidget.getLanguageTag('errorUpdatingDashboardSettings'),
			);
	}
};

WidgetDashboard.prototype.onOptionEntrySelected = function(data) {
	const { type, value } = data;
	switch (String(type)) {
		case 'reset':
			this.resetDashboard();
			break;

		case 'refresh':
			this.refreshDashboard();
			break;

		case 'erase':
			this.eraseDashboard();
			break;

		case 'save':
			this.event('configurationChanged');
			break;

		case 'inheritable':
			this.setChildrenInheritable(value);
			break;

		default:
			console.error(
				`WidgetDashboard.prototype.onOptionEntrySelected: unknown option "${type}"`,
			);
			break;
	}
};

WidgetDashboard.prototype.onSideMenuGripperClick = function() {
	this.setSideMenuCollapsed(!this.isSideMenuCollapsed());
};

WidgetDashboard.prototype.isSideMenuCollapsed = function() {
	return this.sideMenu.hasClass('WidgetDashboard-SideMenu-Collapsed');
};

WidgetDashboard.prototype.setSideMenuCollapsed = function(value) {
	if (value) {
		this.sideMenu.addClass('WidgetDashboard-SideMenu-Collapsed');
	} else {
		this.sideMenu.removeClass('WidgetDashboard-SideMenu-Collapsed');
	}
};

WidgetDashboard.prototype.addWidget = function(
	widgetConstructor,
	options,
	callback,
) {
	var currentWidget = this;

	if(!widgetConstructor) {
		callback.call(this, {type:'No widget constructor provided'}, null);
		return;
	}
	
	options = options || {};

	options = { ...options, readOnly: this._options.readOnly };

	var newId = options.id || this.generateChildId(generateUUID());

	this.packeryContainer
		.packery()
		.append(
			`<div id="${newId}" class="WidgetDashboard-Selector ${widgetConstructor
				.prototype.PACKERY_SIZE ||
				'WidgetDashboardBase_container_MxM'}"></div>`,
		);

	this.addChildWidget(widgetConstructor, newId, options, function(
		err,
		newWidget,
	) {
		if(err) {
			callback.call(this, err, newWidget);
			return;
		}

		var draggie = null;

		if (!isPhonegap()) {
			draggie = new Draggabilly(document.getElementById(newId), {
				handle:
					newWidget.DRAGGABILLY_HANDLE || '.WidgetPanelBase-TopBar',
				containment: `#${this.packeryContainerId}`,
			});
			this.widgetDraggies[newId] = draggie;
		}

		//We still use the widgetDraggies to determine if there are still widgets on the dashboard
		else {
			this.widgetDraggies[newId] = true;
		}

		this.packeryContainer.packery('appended', $(`#${newId}`));

		if (!isPhonegap()) {
			this.packeryContainer.packery('bindDraggabillyEvents', draggie);
		}

		newWidget.addEventListener('closed', function(value) {
			currentWidget.removeWidget(this.getId(), function() {});
		});

		newWidget.addEventListener('configurationSet', function(data) {
			currentWidget._onWidgetConfigurationSet(data);
		});

		this.event('configurationChanged', {
			dashboard: this,
			widget: newWidget,
		});

		this.noWidgetsOverlay.hide();

		callback.call(this, err, newWidget);
	});
};

WidgetDashboard.prototype.removeWidget = function(id, callback) {
	this.packeryContainer.packery('remove', $(`#${id}`)).packery();

	this.removeChildWidget(id, function(err) {
		delete this.widgetDraggies[id];

		$(`#${id}`).remove();

		this.event('configurationChanged', { dashboard: this, widgetId: id });

		if (Object.keys(this.widgetDraggies).length <= 0) {
			this.noWidgetsOverlay.show();
		}

		callback.call(this, err);
	});
};

WidgetDashboard.prototype.refreshDashboard = function(callback) {
	callback = callback || function() {};

	console.error(
		'WidgetDashboard.prototype.refreshDashboard: Not implemented!',
	);
};

WidgetDashboard.prototype.resetDashboard = function(callback) {
	callback = callback || function() {};

	var currentWidget = this;

	this.getMainContainer().showConfirm(
		{
			message: getLanguageTag(this.constructor, 'confirmReset'),
			title: getLanguageTag(this.constructor, 'resetTitle'),
			confirmLabel: getLanguageTag(
				this.constructor,
				'confirmResetButton',
			),
		},

		function(err) {
			//We throw this event so someone else like LocationDashboards can handle it
			currentWidget.event('configurationReset');
		},
	);
};

WidgetDashboard.prototype.eraseDashboard = function(callback) {
	var currentWidget = this;

	callback = callback || function() {};

	this.getMainContainer().showConfirm(
		{
			message: getLanguageTag(
				this.constructor,
				'eraseDashboardConfirmMessage',
			),
			title: getLanguageTag(
				this.constructor,
				'eraseDashboardConfirmTitle',
			),
			confirmLabel: getLanguageTag(
				this.constructor,
				'eraseDashboardConfirmButton',
			),
		},

		function() {
			currentWidget.event('eraseDashboard', { dashboard: currentWidget });
		},

		function() {
			callback.call(currentWidget, false);
		},

		function() {},
	);
};

WidgetDashboard.prototype.setConfiguration = function(config, callback) {
	/* Example: config = {
	 * 	widgets:[
	 * 		type:'WidgetDataGlance',
	 * 		options:'',
	 * 		configuration:{}
	 * 	]
	 *
	 *
	 * }
	 */

	callback = callback || function() {};
	config = config || {};

	var currentWidget = this;

	var widgets = (config.widgets || []).slice();

	function _setConfigurationHelper() {
		if (widgets.length <= 0) {
			if (config.positions) {
				currentWidget.packeryContainer
					.packery('initShiftLayout', config.positions, 'id')
					.packery();
			}

			callback.call(currentWidget, false);
			return;
		}

		var currentWidgetEntry = widgets.shift();

		currentWidget.addWidget(
			window[currentWidgetEntry.type],
			currentWidgetEntry.options,
			function(err, widget) {
				
				if(err) {
					console.debug(err);
					callback.call(currentWidget, err);
					return;
				}
				
				widget.setConfiguration(
					currentWidgetEntry.configuration || {},
					function() {
						_setConfigurationHelper();
					},
				);
			},
		);
	}

	_setConfigurationHelper();
};

WidgetDashboard.prototype.getConfiguration = function() {
	var childrenIds = this.getChildrenIds();

	var currentShiftPositions = this.getShiftPositions();

	var config = {
		widgets: [],
		positions: currentShiftPositions,
	};

	for (var i = 0; i < childrenIds.length; i++) {
		var currentWidget = this.getChildWidget(childrenIds[i]);

		if (currentWidget.constructor !== WidgetDashboardMenu) {
			config.widgets.push({
				options: {
					id: currentWidget.getId(),
				},

				type: currentWidget.constructor.name,
				configuration: currentWidget.getConfiguration(),
			});
		}
	}

	return config;
};

WidgetDashboard.prototype.removeAllWidgets = function(callback) {
	var currentWidget = this;

	var dashboardWidgetIds = Object.keys(this.widgetDraggies);

	function _removeAllWidgetsHelper() {
		if (dashboardWidgetIds.length <= 0) {
			callback.call(currentWidget, false);
			return;
		}

		var currentWidgetId = dashboardWidgetIds.shift();

		currentWidget.removeWidget(currentWidgetId, function(err) {
			_removeAllWidgetsHelper();
			return;
		});
	}

	_removeAllWidgetsHelper();
};

WidgetDashboard.prototype.ICON = './Resources/icons/Dashboard.svg';

WidgetDashboard.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'Dashboard',
			dashboardMenu: 'Dashboard Menu',
			resetTitle: 'Reset Dashboard',
			confirmReset:
				'Are you sure you want to reset the dashboard to its original state?',
			confirmResetButton: 'Reset',
			eraseDashboardConfirmMessage:
				'Are you sure you want to remove all widgets from the dashboard?',
			eraseDashboardConfirmTitle: 'Erase Dashboard',
			eraseDashboardConfirmButton: 'Erase',
			youHaveNoWidgets: "You haven't added any widgets!",
			errorUpdatingDashboardSettings:
				'Unable to update dashboard settings',
			needHelpAddingWidgets: `<p id="location_container_noWidgetsNoticesubGraphicBottomText" class="WidgetBaseNotice-Bottom-Text">Need help configuring your dashboard? Head over to the docs to <a id="location_container_noWidgetsNoticesubGraphicBottomTextLink" href="https://docs.atmosphereiot.com/configuring-the-dashboard/" class="WidgetBaseNotice-Link" target="_system">learn how</a>.</p>`,
		},
	},
);
