function ElementControllerEmbeddedInterval(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('instance', null, 0, 'driverInstance', null, 'interval');
	this.addProperty('time', null, 1000, 'number', null, null);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
	ATMO_INTERVAL_Handle_t intervalHandle;
    ATMO_INTERVAL_AddAbilityInterval(
		ATMO_PROPERTY(undefined, instance), 
		ATMO_ABILITY(undefined, interval), 
		ATMO_PROPERTY(undefined, time), 
		&intervalHandle
	);
	
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('interval', [], ['interval'], true);
}

ElementControllerEmbeddedInterval.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedInterval.prototype.constructor = ElementControllerEmbeddedInterval;

ElementControllerEmbeddedInterval.prototype.NAME = 'Interval';
ElementControllerEmbeddedInterval.prototype.TYPE = 'EmbeddedInterval';
ElementControllerEmbeddedInterval.prototype.HIDDEN = false;
ElementControllerEmbeddedInterval.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
];
ElementControllerEmbeddedInterval.prototype.DEFAULT_ABILITY = 'trigger';
ElementControllerEmbeddedInterval.prototype.DEFAULT_TRIGGER = 'interval';
ElementControllerEmbeddedInterval.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedInterval.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedInterval: 'Interval',
			time: 'Time (milliseconds)',
			setup: 'Setup',
			interval: 'Interval',
		},
	},
);
