function WidgetOrganizationsControls(id, api, parentWidget, options) {
	options = options || {};

	options.entries = options.entries || [
		{
			name: 'addOrganization',
			label: this.getLanguageTag('addOrganization'),
			imageSrc: './Resources/icons/AddDevice.svg', // TODO get an icon for this...
		},
	];

	WidgetLocationControls.call(this, id, api, parentWidget, options);

	this.hashChangeHandler = null;
}

WidgetOrganizationsControls.prototype = Object.create(
	WidgetLocationControls.prototype,
);
WidgetOrganizationsControls.prototype.constructor = WidgetOrganizationsControls;

WidgetOrganizationsControls.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.hashChangeHandler = this.getMainContainer().addEventListener(
		'hashChanged',
		function() {
			currentWidget.update();
		},
	);

	WidgetLocationControls.prototype.initialize.call(this, function(err) {
		this.update(callback);
	});
};

WidgetOrganizationsControls.prototype.update = function(callback) {
	var currentWidget = this;

	callback = callback || function() {};
	callback.call(currentWidget, false);
};

WidgetOrganizationsControls.prototype.remove = function(callback) {
	this.getMainContainer().removeEventListener(
		'hashChanged',
		this.hashChangeHandler,
	);

	WidgetLocationControls.prototype.remove.call(this, callback);
};

WidgetOrganizationsControls.prototype.language = deepAssign(
	{},
	WidgetLocationControls.prototype.language,
	{
		'en-US': {
			addOrganization: 'Add Organization',
			moveOrganization: 'Move Selected',
			deleteOrganization: 'Delete Selected',
		},
	},
);
