function LocationDeviceManager(id, api, parentWidget, options) {
	LocationBase.call(this, id, api, parentWidget, options);
}

LocationDeviceManager.prototype = Object.create(LocationBase.prototype);
LocationDeviceManager.prototype.constructor = LocationDeviceManager;

LocationDeviceManager.prototype.initialize = function(callback) {
	var currentLocation = this;

	var config = this.getConfiguration() || {};

	var tabs = [];

	if (!config.hideDeviceTable) {
		tabs.push({
			childId: 'deviceTable',
			constructor: WidgetDeviceTable,
			options: {},
		});
	}

	this.setTabWidgets(null, tabs, function(err) {
		currentLocation
			.getMainContainer()
			.addEventListener('hashChanged', function() {
				const deviceTable = currentLocation.getChildWidget(
					'deviceTable',
				);
				if (deviceTable) {
					deviceTable.update();
				}
			});

		LocationBase.prototype.initialize.call(this, callback);
	});
};

LocationDeviceManager.prototype.language = deepAssign(
	{},
	LocationBase.prototype.language,
	{
		'en-US': {
			name: 'Devices',
		},
	},
);
