//Copyright 2018 Atmosphere IoT Corp.
//All rights reserved
// jshint esversion: 6

function WidgetDeviceStorageUsage(id, api, parent, options) {
	WidgetUsage.call(this, id, api, parent, options);

	this._interval = null;
}

WidgetDeviceStorageUsage.prototype = Object.create(WidgetUsage.prototype);
WidgetDeviceStorageUsage.prototype.constructor = WidgetDeviceStorageUsage;

WidgetDeviceStorageUsage.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.update(function() {
		this._interval = setInterval(function() {
			// Only update if visible
			if (currentWidget.isVisible()) {
				
				if(isActive()) {
					currentWidget.update();
				}
			}
		}, 60000);

		WidgetUsage.prototype.initialize.call(currentWidget, callback);
	});
};

WidgetDeviceStorageUsage.prototype.remove = function(callback) {
	var currentWidget = this;

	if (this._interval) {
		clearInterval(this._interval);
		this._interval = null;
	}

	WidgetUsage.prototype.remove.call(currentWidget, callback);
};

WidgetDeviceStorageUsage.prototype.update = function(callback) {
	var currentWidget = this;

	callback = callback || function() {};

	const api = this.getMainContainer()._apiv2.apis;

	api.devices
		.getDevice({ id: getHashCommand().deviceId })
		.then((deviceData) => {
			this.getAPI()
				.getAPIRoute('/user/thing/:thingUuid/view/storageUsage')
				.get(deviceData.uuid, function(err, storageUsage) {
					if (err) {
						callback.call(currentWidget, err);
						return;
					}

					// AI-3203: We may not get anything back from the API
					if(!storageUsage) {
						callback.call(currentWidget, {type:'noStorageUsageData'});
						return;
					}
					
					var storageUsageRatio = storageUsage.usage / storageUsage.limit;

					var currentColor = 'green';

					if (storageUsageRatio >= 0.75) {
						currentColor = 'yellow';
					}

					if (storageUsageRatio >= 0.9) {
						currentColor = 'orange';
					}

					if (storageUsageRatio >= 0.98) {
						currentColor = 'red';
					}

					currentWidget.setUsage(
						numeral(storageUsage.usage).format('0.00b'),
						null,
						currentColor,
						'./Resources/icons/StorageOverview.svg',
						getLanguageTag('dataStorageUsed'),
						numeral(storageUsage.limit).format('0.00b'),
						storageUsageRatio,
					);

					callback.call(currentWidget, false);
				});
		})
		.catch((err) => {
			console.error('Error getting device information ', err);
			currentLocation
				.getMainContainer()
				.showPopupErrorMessage(
					getLanguageTag(this.constructor, 'invalidDevice'),
				);
			callback.call(currentLocation, {
				type: 'invalidDevice',
				redirect: LocationDeviceManager,
			});
		});
};

WidgetDeviceStorageUsage.prototype.language = deepAssign(
	{},
	WidgetUsage.prototype.language,
	{
		'en-US': {
			dataStorageUsed: 'Data Storage Used',
		},
	},
);

WidgetDeviceStorageUsage.prototype.$_$ = function(done) {
	this.$_SetupWidgetTest(function(err, currentWidget) {
		WidgetUsage.prototype.$_$.call(this, done);
	});
};
