function PlaneControllerEmbeddedNxpRpk(parentController, elementName) {
	PlaneControllerEmbeddedMcuxpresso.call(this, parentController, elementName);
}

PlaneControllerEmbeddedNxpRpk.prototype = Object.create(
	PlaneControllerEmbeddedMcuxpresso.prototype,
);
PlaneControllerEmbeddedNxpRpk.prototype.constructor = PlaneControllerEmbeddedNxpRpk;
PlaneControllerEmbeddedNxpRpk.prototype.CLIENT_AGENT_REQUIRED = false;

PlaneControllerEmbeddedNxpRpk.prototype.DRIVERS = {
	adc: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_ADC_ADC1',
		},
	],
	ble: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLE_BLE1',
		},
	],
	block: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLOCK_BLOCK1',
		},
	],
	datetime: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_DATETIME_DATETIME1',
		},
	],
	filesytem: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_FILESYSTEM_FILESYSTEM1',
		},
	],
	gpio: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_GPIO_GPIO1',
		},
	],
	http: [],
	i2c: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C1',
			primary: true,
		},
		{
			id: 1,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C2',
		},
	],
	interval: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_INTERVAL_INTERVAL1',
		},
	],
	nfc: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_NFC_NFC1',
		},
	],
	pwm: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_PWM_PWM1',
		},
	],
	spi: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_SPI_SPI1',
		},
	],
	uart: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_UART_UART1',
		},
		{
			id: 3,
			name: 'ATMO_DRIVERINSTANCE_UART_UART4',
			primary: true,
		},
	],
	wifi: [],
};

PlaneControllerEmbeddedNxpRpk.prototype.VARIANTS = ['NxpRpk'];
PlaneControllerEmbeddedNxpRpk.prototype.SUPPORTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'interval',
	'variables',
	'ble',
	'gpio',
	'adc',
	'i2c',
	'spi',
	'uart',
	'fpmath',
	'mk64f',
	'rpk',
	'pwm',
	'nfc',
	'log',
	'debugPrint',
	"uptime"
];

PlaneControllerEmbeddedNxpRpk.prototype.FIRMWARE_DOWNLOAD_FILES = {
	artifactName: 'firmware',
	fileName: 'Atmosphere_Project.bin',
};

PlaneControllerEmbeddedNxpRpk.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'MB1_PWM',
	},

	EmbeddedPWMPin: {
		instance: 0,
		pin: 'MB1_PWM',
	},

	EmbeddedADCPin: {
		instance: 0,
		pin: 'MB1_AN',
	},

	EmbeddedFXOS8700: {
		gpioInstance: 0,
		i2cInstance: 1,
		interrupt2Gpio: 'PTD13',
	},

	EmbeddedCCS811: {
		i2cInstance: 1,
	},

	EmbeddedENS210: {
		i2cInstance: 1,
		tempCalibrationOffset: -7,
	},

	EmbeddedFXAS21002: {
		i2cInstance: 1,
	},

	EmbeddedMPL3115: {
		i2cInstance: 1,
	},

	EmbeddedTSL2572: {
		i2cInstance: 1,
	},

	EmbeddedSX9500: {
		gpioInstance: 0,
		i2cInstance: 1,
		interruptGpio: 'PTA9',
	},

	EmbeddedWS2812: {
		numRows: 4,
		numCols: 4,
		gpioPin: 'MB1_RST',
	},

	EmbeddedRelayClick: {
		relay1Pin: 'MB1_PWM',
		relay2Pin: 'MB1_CS',
		gpioDriverInstance: 0,
	},

	EmbeddedIRThermoClick: {
		i2cInstance: 0,
	},

	EmbeddedHBridge2Click: {
		enablePin: 'MB3_CS',
		dirPin: 'MB3_RST',
		ginPin: 'MB3_AN',
		speedCtrlPin: 'MB3_PWM',
		gpioDriverInstance: 0,
		pwmDriverInstance: 0,
		initialSpeedPct: 100,
	},

	EmbeddedGpsClick: {
		uartDriverInstance: 3,
		gpioDriverInstance: 0,
		powerPin: 'MB3_PWM',
		wupPin: 'MB3_AN',
		rstPin: 'MB3_RST',
		reportFrequency: 1,
	},

	EmbeddedMotionClick: {
		gpioDriverInstance: 0,
		enablePin: 'MB3_RST',
		motionPin: 'MB3_INT',
	},

	Embedded8x8LedClick: {
		csPin: 'MB3_CS',
	},

	EmbeddedHBridge1Click: {
		in1APin: 'MB3_PWM',
		in1BPin: 'MB3_CS',
		in2APin: 'MB3_AN',
		in2BPin: 'MB3_RST',
	},

	EmbeddedSigfoxClick: {
		rstPin: 'MB3_RST',
		ackPin: 'MB3_AN',
		csPin: 'MB3_CS',
	},

	EmbeddedNfcClick: {
		intPin: 'MB1_INT',
		rstPin: 'MB1_RST',
		gpioDriverInstance: 0,
		i2cDriverInstance: 0,
		pollRateMs: 1000,
	},

	EmbeddedLPS25HB: {
		i2cInstance: 0,
	},

	EmbeddedLIS3DH: {
		csPin: 'MB3_CS',
	},

	EmbeddedLSM6DSL: {
		i2cInstance: 0,
		i2cAddress: '0x6B',
	},

	EmbeddedBuzzerClick: {
		pwmDriverInstance: 0,
		pwmPin: 'MB1_PWM',
	},

	EmbeddedGroveLight: {
		adcInstance: 0,
		adcPin: 'MB1_AN',
	},

	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'MB1_PWM',
	},

	EmbeddedGroveTemperature: {
		adcInstance: 0,
		adcPin: 'MB1_AN',
	},

	EmbeddedGroveRotaryAngle: {
		adcInstance: 0,
		adcPin: 'MB1_AN',
	},

	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'MB1_PWM',
	},

	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'MB1_PWM',
	},

	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'MB1_PWM',
	},

	EmbeddedADXL343: {
		i2cInstance: 0,
		gpioInstance: 0,
		int1Pin: 'MB1_RST',
		int2Pin: 'MB1_INT',
	},

	EmbeddedADXL345: {
		i2cInstance: 0,
		gpioInstance: 0,
		int1Pin: 'MB1_RST',
		int2Pin: 'MB1_INT',
	},
};

PlaneControllerEmbeddedNxpRpk.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			defaultName: 'NXP Rapid IoT',
			ATMO_DRIVERINSTANCE_I2C_I2C1: 'I2C1 (Docking Station)',
			ATMO_DRIVERINSTANCE_I2C_I2C2: 'I2C2 (Internal Sensors)',
			ATMO_DRIVERINSTANCE_SPI_SPI1: 'SPI1 (Docking Station)',
			ATMO_DRIVERINSTANCE_UART_UART1: 'UART1 (USB Debug Console)',
			ATMO_DRIVERINSTANCE_UART_UART4: 'UART4 (Docking Station Slot 3)',
		},
	},
);
