function PlaneControllerEmbeddedM5Stack(parentController, elementName) {
	PlaneControllerEmbeddedEsp32.call(this, parentController, elementName);
}

PlaneControllerEmbeddedM5Stack.prototype = Object.create(
	PlaneControllerEmbeddedEsp32.prototype,
);
PlaneControllerEmbeddedM5Stack.prototype.constructor = PlaneControllerEmbeddedM5Stack;

PlaneControllerEmbeddedM5Stack.prototype.VARIANTS = ['m5stack'];

PlaneControllerEmbeddedM5Stack.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'M5STACK_BUTTON_A',
	},

	EmbeddedPWMPin: {
		instance: 0,
		pin: 'IO18',
	},

	EmbeddedADCPin: {
		instance: 0,
		pin: 'IO35',
	},

	EmbeddedCloudCommand: {
		instance: 1,
	},

	EmbeddedCloudEvent: {
		instance: 1,
	},

	EmbeddedRelayClick: {
		relay1Pin: 'IO18',
		relay2Pin: 'IO19',
		gpioDriverInstance: 0,
	},

	EmbeddedIRThermoClick: {
		i2cInstance: 0,
	},

	EmbeddedHBridge2Click: {
		enablePin: 'IO18',
		dirPin: 'IO19',
		ginPin: 'IO21',
		speedCtrlPin: 'IO22',
		gpioDriverInstance: 0,
		pwmDriverInstance: 0,
		initialSpeedPct: 100,
	},

	EmbeddedGpsClick: {
		uartDriverInstance: 1,
		gpioDriverInstance: 0,
		powerPin: 'IO18',
		wupPin: 'IO19',
		rstPin: 'IO21',
		reportFrequency: 1,
	},

	EmbeddedMotionClick: {
		gpioDriverInstance: 0,
		enablePin: 'IO18',
		motionPin: 'IO19',
	},

	Embedded8x8LedClick: {
		csPin: 'IO18',
	},

	EmbeddedGroveLight: {
		adcInstance: 0,
		adcPin: 'IO35',
	},

	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'M5STACK_BUTTON_A',
	},

	EmbeddedGroveTemperature: {
		adcInstance: 0,
		adcPin: 'IO35',
	},

	EmbeddedGroveRotaryAngle: {
		adcInstance: 0,
		adcPin: 'IO35',
	},

	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'M5STACK_BUTTON_A',
	},

	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'M5STACK_BUTTON_A',
	},

	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'M5STACK_BUTTON_A',
	},

	EmbeddedADXL343: {
		i2cInstance: 0,
		gpioInstance: 0,
		int1Pin: 'IO18',
		int2Pin: 'IO19',
	},

	EmbeddedADXL345: {
		i2cInstance: 0,
		gpioInstance: 0,
		int1Pin: 'IO18',
		int2Pin: 'IO19',
	},
};

PlaneControllerEmbeddedM5Stack.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedEsp32.prototype.language,
	{
		'en-US': {
			defaultName: 'M5Stack (ESP32)',
		},
	},
);
