function PlaneControllerEmbeddedMangohYellow(parentController, elementName) {
	PlaneControllerEmbeddedLinux.call(this, parentController, elementName);
}

PlaneControllerEmbeddedMangohYellow.prototype = Object.create(
	PlaneControllerEmbeddedLinux.prototype,
);
PlaneControllerEmbeddedMangohYellow.prototype.constructor = PlaneControllerEmbeddedMangohYellow;

PlaneControllerEmbeddedMangohYellow.prototype.VARIANTS = ['MangOH_Yellow'];
PlaneControllerEmbeddedMangohYellow.prototype.SUPPORTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'interval',
	'variables',
	'gpio',
	'i2c',
	'uart',
	'fpmath',
	'debugPrint',
	'uptime',
	'linux',
	'adc',
	'log',
	'mangohyellow',
];
PlaneControllerEmbeddedMangohYellow.prototype.CLIENT_AGENT_REQUIRED = false;

PlaneControllerEmbeddedMangohYellow.prototype.DRIVERS = {
	adc: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_ADC_ADC1',
		},
	],
	ble: [],
	block: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLOCK_BLOCK1',
		},
	],
	datetime: [],
	embeddedCloud: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_CLOUD_PI',
		},
	],
	filesytem: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_FILESYSTEM_FILESYSTEM1',
		},
	],
	gpio: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_GPIO_GPIO1',
		},
	],
	http: [],
	i2c: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C1',
		},
		{
			id: 1,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C2',
			primary: true,
		},
		{
			id: 2,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C3',
		},
		{
			id: 3,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C4',
		},
		{
			id: 4,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C5',
		},
	],
	interval: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_INTERVAL_INTERVAL1',
		},
	],
	nfc: [],
	pwm: [],
	spi: [],
	uart: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_UART_UART1',
		},
	],
	wifi: [],
};

PlaneControllerEmbeddedMangohYellow.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'P_LED_B',
		mode: 'ATMO_GPIO_PinMode_Output_PushPull',
	},
	EmbeddedBME680: {
		i2cAddress: '0x76',
		i2cInstance: 2,
	},
	EmbeddedOPT3002: {
		i2cInstance: 3,
	},
	EmbeddedBMI160: {
		i2cInstance: 2,
		bmm150Enable: true,
	},
	EmbeddedADCPin: {
		pin: 'P_IOT_20_ADC',
	},
	EmbeddedGroveLight: {
		adcInstance: 0,
		adcPin: 'P_IOT_20_ADC',
	},
	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'P_IOT_24',
	},
	EmbeddedGroveTemperature: {
		adcInstance: 0,
		adcPin: 'P_IOT_20_ADC',
	},
	EmbeddedGroveRotaryAngle: {
		adcInstance: 0,
		adcPin: 'P_IOT_20_ADC',
	},
	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'P_IOT_24',
	},
	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'P_IOT_24',
	},
	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'P_IOT_24',
	},
};

PlaneControllerEmbeddedMangohYellow.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			defaultName: 'MangOH Yellow',
			ATMO_DRIVERINSTANCE_CLOUD_PI: 'Internal',
			ATMO_DRIVERINSTANCE_I2C_I2C1: 'I2C4',
			ATMO_DRIVERINSTANCE_I2C_I2C2: 'I2C5 (IoT Exp)',
			ATMO_DRIVERINSTANCE_I2C_I2C3: 'I2C6',
			ATMO_DRIVERINSTANCE_I2C_I2C4: 'I2C7',
			ATMO_DRIVERINSTANCE_I2C_I2C5: 'I2C8',
			ATMO_DRIVERINSTANCE_UART_UART1: 'UART1 (IOT 12/13)',
		},
	},
);
