function LocationEmbedded(id, api, parentWidget, options) {
	LocationBase.call(this, id, api, parentWidget, options);
}

LocationEmbedded.prototype = Object.create(LocationBase.prototype);
LocationEmbedded.prototype.constructor = LocationEmbedded;

LocationEmbedded.prototype.initialize = function(callback) {
	this.renderTemplate({
		src: this.getOptions().url || 'https://docs.atmosphereiot.com',
	});

	LocationBase.prototype.initialize.call(this, callback);
};

LocationEmbedded.prototype.language = deepAssign(
	{},
	LocationBase.prototype.language,
	{
		'en-US': {
			name: 'Dashboard',
		},
	},
);
