function PlaneControllerEmbeddedOnSemi(parentController, elementName) {
	PlaneControllerEmbeddedBase.call(this, parentController, elementName);
}

PlaneControllerEmbeddedOnSemi.prototype = Object.create(
	PlaneControllerEmbeddedBase.prototype,
);
PlaneControllerEmbeddedOnSemi.prototype.constructor = PlaneControllerEmbeddedOnSemi;

PlaneControllerEmbeddedOnSemi.prototype.TYPE = 'OnSemi';
PlaneControllerEmbeddedOnSemi.prototype.VARIANTS = [];
PlaneControllerEmbeddedOnSemi.prototype.SUPPORTS = [
	'embedded',
	'gpio',
	'i2c',
	'debugPrint',
];
PlaneControllerEmbeddedOnSemi.prototype.SELECTABLE_VARIANTS = [];

PlaneControllerEmbeddedOnSemi.prototype.language = {
	'en-US': {
		defaultName: 'OnSemi',
	},
};
