function WidgetPanelBase(id, api, parent, options) {
	var currentWidget = this;

	WidgetBase.call(this, id, api, parent, options);

	this._panelContainerId = this.generateChildId('panelContainer');
	this._titleId = this.generateChildId('title');
	this._titleValue = null;

	this.setPanel();
}

//WidgetBase Inheritance
WidgetPanelBase.prototype = Object.create(WidgetBase.prototype);
WidgetPanelBase.prototype.constructor = WidgetPanelBase;

WidgetPanelBase.prototype.BUTTON_IMAGES = {
	close: './Widgets/WidgetPanelBase/Close.svg',
	help: '',
	options: './Resources/icons/Options.svg',
	configure: '',
	info: '',
	save: '',
	invalid: '',
};

WidgetPanelBase.prototype.getTitle = function() {
	return this._titleValue;
};

WidgetPanelBase.prototype.setTitle = function(title) {
	this._titleValue =
		title ||
		this.getOptions().panelTitle ||
		getLanguageTag(this.constructor, 'name');

	this._title.html(this._titleValue.toString());
};

WidgetPanelBase.prototype.addMenuWidget = function(
	widgetConstructor,
	options,
	buttonChildId,
	callback,
) {
	var menuContainerId = this.generateChildId(buttonChildId + '_menu');

	this.removeChildWidget(menuContainerId, function() {
		return this.addChildWidget(
			widgetConstructor,
			menuContainerId,
			options,
			callback,
		);
	});
};

WidgetPanelBase.prototype.setPanel = function(buttons, titleLabel) {
	var currentWidget = this;

	buttons = buttons || [];

	this.buttonIdsToValue = {};

	for (var i = 0; i < buttons.length; i++) {
		buttons[i].id = this.generateChildId(
			buttons[i].childId || buttons[i].label,
		);
		buttons[i].menuContainerId = this.generateChildId(
			(buttons[i].childId || buttons[i].label) + '_menu',
		);
		this.buttonIdsToValue[buttons[i].id] = buttons[i].value;

		if (buttons[i].image !== undefined) {
			buttons[i].imageSrc =
				this.BUTTON_IMAGES[buttons[i].image] ||
				this.BUTTON_IMAGES['invalid'];
		}
	}

	this.renderTemplate(
		{
			titleId: this._titleId,
			labelTitle:
				titleLabel ||
				this.getOptions().title ||
				getLanguageTag(this.constructor, 'name'),
			panelContainerId: this._panelContainerId,
			buttons: buttons,
		},
		WidgetPanelBase.name,
		this._container,
	);

	for (var k in this.buttonIdsToValue) {
		$(`#${k}`).click(function(e) {
			currentWidget.event(
				'buttonClicked',
				currentWidget.buttonIdsToValue[this.id],
			);

			e.stopPropagation();
			return;
		});
	}

	this._panelContainer = $(`#${this._panelContainerId}`);
	this._title = $(`#${this._titleId}`);

	this.setTitle(this.getTitle());
};

WidgetPanelBase.prototype.getContainer = function() {
	return this._panelContainer;
};

WidgetPanelBase.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			menu: 'Menu',
		},
	},
);

WidgetPanelBase.prototype.$_$ = function(done) {
	this.$_SetupWidgetTest(function(err, currentWidget) {
		this.setTitle('TESTING');

		if (this._title.html().trim() !== 'TESTING') {
			done({
				expected: 'TESTING',
				got: this._title.html().trim(),
				message: 'Shoud have set the title correctly',
			});
		}

		WidgetBase.prototype.$_$.call(this, done);
	});
};
