function ElementControllerEmbeddedWebThing(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty(
		'capability',
		null,
		'WEBTHING_TYPE_None',
		'select',
		[
			'WEBTHING_TYPE_None',
			'WEBTHING_TYPE_Alarm',
			'WEBTHING_TYPE_BinarySensor',
			'WEBTHING_TYPE_Camera',
			'WEBTHING_TYPE_ColorControl',
			'WEBTHING_TYPE_ColorSensor',
			'WEBTHING_TYPE_DoorSensor',
			'WEBTHING_TYPE_EnergyMonitor',
			'WEBTHING_TYPE_LeakSensor',
			'WEBTHING_TYPE_Light',
			'WEBTHING_TYPE_Lock',
			'WEBTHING_TYPE_MotionSensor',
			'WEBTHING_TYPE_MultiLevelSensor',
			'WEBTHING_TYPE_MultiLevelSwitch',
			'WEBTHING_TYPE_OnOffSwitch',
			'WEBTHING_TYPE_PushButton',
			'WEBTHING_TYPE_SmartPlug',
			'WEBTHING_TYPE_TemperatureSensor',
			'WEBTHING_TYPE_Thermostat',
			'WEBTHING_TYPE_VideoCamera',
		],
		null,
	);
	this.addProperty('description', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('description', 'string');
	this.addProperty('eventQueueDepth', null, 20, 'number', null, null);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
	WebThing_Config_t config;

	config.id = WebThing_BuildId(ATMO_GLOBALS_PROJECT_NAME_SLUG);
	config.title = WebThing_BuildTitle(ATMO_GLOBALS_PROJECT_NAME);
	config.type =  ATMO_PROPERTY(undefined, capability);
	config.description = ATMO_PROPERTY(undefined, description);
	config.eventQueueDepth = ATMO_PROPERTY(undefined, eventQueueDepth);
	WebThing_Init(&config);
	return ATMO_Status_Success;
	`,
	);
}

ElementControllerEmbeddedWebThing.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedWebThing.prototype.constructor = ElementControllerEmbeddedWebThing;

ElementControllerEmbeddedWebThing.prototype.NAME = 'WebThing';
ElementControllerEmbeddedWebThing.prototype.TYPE = 'EmbeddedWebThing';
ElementControllerEmbeddedWebThing.prototype.CATEGORY = 'webthing';
ElementControllerEmbeddedWebThing.prototype.HIDDEN = false;
ElementControllerEmbeddedWebThing.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'wifi',
	'esp32',
];
ElementControllerEmbeddedWebThing.prototype.DEFAULT_ABILITY = 'setup';
ElementControllerEmbeddedWebThing.prototype.DEFAULT_TRIGGER = '';
ElementControllerEmbeddedWebThing.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedWebThing.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedWebThing: 'WebThing',
			description: 'Description',
			eventQueueDepth: 'Event Queue Depth',
			WEBTHING_TYPE_Alarm: 'Alarm',
			WEBTHING_TYPE_BinarySensor: 'BinarySensor',
			WEBTHING_TYPE_Camera: 'Camera',
			WEBTHING_TYPE_ColorControl: 'ColorControl',
			WEBTHING_TYPE_ColorSensor: 'ColorSensor',
			WEBTHING_TYPE_DoorSensor: 'DoorSensor',
			WEBTHING_TYPE_EnergyMonitor: 'EnergyMonitor',
			WEBTHING_TYPE_LeakSensor: 'LeakSensor',
			WEBTHING_TYPE_Light: 'Light',
			WEBTHING_TYPE_Lock: 'Lock',
			WEBTHING_TYPE_MotionSensor: 'MotionSensor',
			WEBTHING_TYPE_MultiLevelSensor: 'MultiLevelSensor',
			WEBTHING_TYPE_MultiLevelSwitch: 'MultiLevelSwitch',
			WEBTHING_TYPE_OnOffSwitch: 'OnOffSwitch',
			WEBTHING_TYPE_PushButton: 'PushButton',
			WEBTHING_TYPE_SmartPlug: 'SmartPlug',
			WEBTHING_TYPE_TemperatureSensor: 'TemperatureSensor',
			WEBTHING_TYPE_Thermostat: 'Thermostat',
			WEBTHING_TYPE_VideoCamera: 'VideoCamera',
			WEBTHING_TYPE_None: 'Custom',
			capability: 'Capability',
		},
	},
);
