function ElementControllerEmbeddedVariable(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('initialValue', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('initialValue', 'string');

	this.addProperty(
		'initialDataType',
		null,
		'ATMO_DATATYPE_STRING',
		'select',
		this.DATA_TYPES,
		null,
	);
	this.addVariable('value', 'ATMO_Value_t');

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
    ATMO_InitValue(&ATMO_VARIABLE(undefined, value));
    if(ATMO_PROPERTY(undefined, initialValue) != NULL)
    {
        // Create initial value as string
        ATMO_Value_t strValue;
        ATMO_InitValue(&strValue);
        ATMO_CreateValueString(&strValue, ATMO_PROPERTY(undefined, initialValue));

        // Convert to desired type
        ATMO_CreateValueConverted(&ATMO_VARIABLE(undefined, value), ATMO_PROPERTY(undefined, initialDataType), &strValue);
        ATMO_FreeValue(&strValue);
    }
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setValue', [], ['valueSet'], false, false);
	this.setAbilityCode(
		'setValue',
		`
    ATMO_CreateValueCopy(&ATMO_VARIABLE(undefined, value), in);
    ATMO_CreateValueCopy(out, in);
    return ATMO_Status_Success;
    `,
	);

	this.addAbility('getValue', [], ['valueRetrieved'], false, false);
	this.setAbilityCode(
		'getValue',
		`
    ATMO_CreateValueCopy(out, &ATMO_VARIABLE(undefined, value));
    return ATMO_Status_Success;
    `,
	);
}

ElementControllerEmbeddedVariable.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedVariable.prototype.constructor = ElementControllerEmbeddedVariable;

ElementControllerEmbeddedVariable.prototype.NAME = 'Variable';
ElementControllerEmbeddedVariable.prototype.TYPE = 'EmbeddedVariable';
ElementControllerEmbeddedVariable.prototype.HIDDEN = false;
ElementControllerEmbeddedVariable.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
];
ElementControllerEmbeddedVariable.prototype.DEFAULT_ABILITY = 'setValue';
ElementControllerEmbeddedVariable.prototype.DEFAULT_TRIGGER = 'valueRetrieved';
ElementControllerEmbeddedVariable.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedVariable.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedVariable: 'Variable',
			setValue: 'Set Value',
			valueSet: 'Value Set',
			getValue: 'Get Value',
			valueRetrieved: 'Value Retrieved',
			initialDataType: 'Initial Data Type',
			initialValue: 'Initial Value',
		},
	},
);
