function ElementControllerAppDeviceEvent(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppLogicBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addAbility('send', [{ name: 'value', validator: null }]);

	this.addTrigger('sent');
}

ElementControllerAppDeviceEvent.prototype = Object.create(
	ElementControllerAppLogicBase.prototype,
);
ElementControllerAppDeviceEvent.prototype.constructor = ElementControllerAppDeviceEvent;

ElementControllerAppDeviceEvent.prototype.NAME = 'DeviceEvent';
ElementControllerAppDeviceEvent.prototype.TYPE = 'AppDeviceEvent';
ElementControllerAppDeviceEvent.prototype.CATEGORY = 'cloud';
ElementControllerAppDeviceEvent.prototype.HIDDEN = false;
ElementControllerAppDeviceEvent.prototype.DEFAULT_TRIGGER = 'sent';
ElementControllerAppDeviceEvent.prototype.DEFAULT_ABILITY = 'send';
ElementControllerAppDeviceEvent.prototype.DEFAULT_ARGUMENTS = [];
ElementControllerAppDeviceEvent.prototype.COUPLER_TYPE = 'deviceEvent';
ElementControllerAppDeviceEvent.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerAppDeviceEvent.prototype.language = deepAssign(
	{},
	ElementControllerBase.prototype.language,
	{
		'en-US': {
			AppDeviceEvent: 'Device Event',
			send: 'Send Event',
			value: 'Value',
			sent: 'Event Sent',
		},
	},
);
