function ElementControllerAppUILabel(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppUIBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('text', null, 'New Label', 'text', null, null);
	this.addProperty('font', null, '18px Helvetica', 'font', null, null);
	this.addProperty('color', null, 'black', 'color', null, null);
	this.addProperty('alignment', null, 'left', 'select', [
		'left',
		'right',
		'center',
	]);

	this.addAbility('setText', [{ name: 'text', validator: null }]);
	this.addAbility('setFont', [{ name: 'font', validator: null }]);
	this.addAbility('setColor', [{ name: 'color', validator: null }]);
}

ElementControllerAppUILabel.prototype = Object.create(
	ElementControllerAppUIBase.prototype,
);
ElementControllerAppUILabel.prototype.constructor = ElementControllerAppUILabel;

ElementControllerAppUILabel.prototype.NAME = 'Label';
ElementControllerAppUILabel.prototype.TYPE = 'AppUILabel';
ElementControllerAppUILabel.prototype.HIDDEN = false;
// ElementControllerAppUILabel.prototype.DEFAULT_TRIGGER = 'triggered';
ElementControllerAppUILabel.prototype.DEFAULT_ABILITY = 'setText';
ElementControllerAppUILabel.prototype.DEFAULT_ARGUMENTS = [
	'text',
	'color',
	'font',
];
ElementControllerAppUILabel.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerAppUILabel.prototype.language = deepAssign(
	{},
	ElementControllerAppUIBase.prototype.language,
	{
		'en-US': {
			AppUILabel: 'Label',
			text: 'Text',
			font: 'Font',
			color: 'Color',
			alignment: 'Alignment',
			setText: 'Set Text',
			setFont: 'Set Font',
			setColor: 'Set Color',
			left: 'Left',
			right: 'Right',
			center: 'Center',
		},
	},
);
