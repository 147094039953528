function ElementControllerAppBLEBase(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);
}

ElementControllerAppBLEBase.prototype = Object.create(
	ElementControllerBase.prototype,
);
ElementControllerAppBLEBase.prototype.constructor = ElementControllerAppBLEBase;

ElementControllerAppBLEBase.prototype.NAME = 'CharacteristicBase';
ElementControllerAppBLEBase.prototype.TYPE = 'AppBLECharacteristicBase';
ElementControllerAppBLEBase.prototype.HIDDEN = true;
ElementControllerAppBLEBase.prototype.CATEGORY = 'appBLE';
ElementControllerAppBLEBase.prototype.VARIANTS = ['app', 'ble'];

ElementControllerAppBLEBase.prototype.language = deepAssign(
	{},
	ElementControllerBase.prototype.language,
	{
		'en-US': {
			AppBLECharacteristicBase: 'Base BLE',
		},
	},
);
