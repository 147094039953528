//Copyright 2018 Atmosphere IoT Corp.
//All rights reserved

//jshint esversion: 6
function WidgetChangePassword(id, api, parentWidget, options) {
	var currentWidget = this;

	WidgetBase.call(this, id, api, parentWidget, options);

	this.changePasswordFormId = this.generateChildId('changePasswordForm');
	this.passwordInputId = this.generateChildId('passwordId');
	this.confirmPasswordInputId = this.generateChildId('confirmPasswordId');
	this.submitButtonId = this.generateChildId('submitButton');

	this.token = getHashCommand().changePassword;

	this.context = {};

	this.context.ids = {
		changePasswordFormId: this.changePasswordFormId,
		passwordInputId: this.passwordInputId,
		confirmPasswordInputId: this.confirmPasswordInputId,
		submitButtonId: this.submitButtonId,
	};

	this.context.language = passLanguageTagsToHandlebarsContext(
		this.language,
		this.context,
	);

	this.context.logoImageSrc = './loginLogo.svg';

	this.renderTemplate(this.context, WidgetChangePassword.name);

	this.$changePasswordForm = $(`#${this.changePasswordFormId}`);
	this.$passwordInput = $(`#${this.passwordInputId}`);
	this.$confirmPasswordInput = $(`#${this.confirmPasswordInputId}`);
	this.$submitButton = $(`#${this.submitButtonId}`);

	this.$changePasswordForm.submit(function(e) {
		e.preventDefault();

		if (
			currentWidget.$passwordInput.val() !==
			currentWidget.$confirmPasswordInput.val()
		) {
			currentWidget
				.getMainContainer()
				.showPopupErrorMessage(
					getLanguageTag(
						currentWidget.constructor,
						'passwordsDoNotMatch',
					),
				);
			return;
		}

		currentWidget.sendChangePasswordData(
			currentWidget.token,
			currentWidget.$passwordInput.val(),
			function(err, data) {
				if (data.status === 400) {
					currentWidget
						.getMainContainer()
						.showPopupErrorMessage(
							getLanguageTag(
								currentWidget.constructor,
								data.responseText,
							),
						);
					return;
				}

				if (data.status === 403) {
					currentWidget
						.getMainContainer()
						.showPopupErrorMessage(
							getLanguageTag(
								currentWidget.constructor,
								'accessDenied',
							),
						);
					return;
				}

				if (data.status === 500) {
					currentWidget
						.getMainContainer()
						.showPopupErrorMessage(
							getLanguageTag(
								currentWidget.constructor,
								'internalServerError',
							),
						);
					return;
				}

				currentWidget
					.getMainContainer()
					.showPopupInfoMessage(
						currentWidget.getLanguageTag('success'),
					);
				currentWidget.getMainContainer().logoutUser({ forward: false });
			},
		);
	});
}

WidgetChangePassword.prototype = Object.create(WidgetBase.prototype);
WidgetChangePassword.prototype.constructor = WidgetChangePassword;

WidgetChangePassword.prototype.sendChangePasswordData = function(
	token,
	password,
	callback,
) {
	var currentWidget = this;

	var url = getAPIBase() + '/auth/change_password';

	var data = {
		token: token,
		password: password,
	};

	// This route doesn't need to use the Cloud API wrapper
	// since it's totally unauthenticated
	$.ajax({
		url: url,
		type: 'POST',
		data: data,
		success: function(results) {
			callback.call(currentWidget, false, results);
		},
		error: function(results) {
			if (results.status == 403) {
				callback.call(currentWidget, { type: 'accessDenied' }, results);
			} else if (results.status === 400) {
				callback.call(currentWidget, { type: 'badRequest' }, results);
			} else {
				callback.call(currentWidget, { type: 'serverError' }, results);
			}
		},
	});
};

//Do not add this widget to the Dashboard widget wizard
WidgetChangePassword.prototype.excludeFromWizard = true;

WidgetChangePassword.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'WidgetChangePassword',
			passwordLabel: 'Password*',
			passwordPlaceholder: '',
			confirmPasswordLabel: 'Confirm Password*',
			confirmPasswordPlaceholder: '',
			submitButtonLabel: 'Submit',
			accessDenied:
				'Access denied. Please try again with updated information.',
			internalServerError:
				'There was an error with updating your password. Please try again.',
			changePassword: 'Change Password',
			passwordsDoNotMatch: 'The requested passwords do not match',
			success: 'Password updated',
			invalidPassword:
				'The provided password(s) are invalid! Please check your input',
		},
	},
);
