function BLECentralBackendNull() {
	BLECentralBackendBase.call(this);
}

BLECentralBackendNull.prototype = Object.create(
	BLECentralBackendBase.prototype,
);
BLECentralBackendNull.prototype.constructor = BLECentralBackendNull;

BLECentralBackendNull.prototype.IS_AVAILABLE = function() {
	return true;
};

BLECentralBackendNull.prototype.connectToDeviceByServiceUuid = function(
	primaryServiceUuid,
	callback,
) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendNull.prototype.connectToDeviceByAddress = function(
	address,
	callback,
) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendNull.prototype.disconnectFromDeviceByPrimaryServiceUuid = function(
	primaryServiceUuid,
	callback,
) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendNull.prototype.read = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	callback,
) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendNull.prototype.write = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	rawValue,
	callback,
) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendNull.prototype.writeWithoutResponse = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	rawValue,
	callback,
) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendNull.prototype.subscribe = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	callback,
) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendNull.prototype.unsubscribe = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	callback,
) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendNull.prototype.isScanning = function(callback) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendNull.prototype.startScanning = function(callback) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendNull.prototype.stopScanning = function(callback) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendNull.prototype.getDiscovered = function(callback) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendNull.prototype.getServices = function(address, callback) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendNull.prototype.getServiceCharacteristics = function(
	address,
	serviceUuid,
	callback,
) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendNull.prototype.remove = function(callback) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};
