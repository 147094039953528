function ElementAppDeviceEvent(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);
}

ElementAppDeviceEvent.prototype = Object.create(ElementAppBase.prototype);
ElementAppDeviceEvent.prototype.constructor = ElementAppDeviceEvent;

ElementAppDeviceEvent.prototype.send = function(value, callback) {
	var currentElement = this;
	var hashCommand = getHashCommand();

	if (hashCommand.thingUuid === undefined) {
		callback.call(this, { type: 'noThingUuidInContext' });
		return;
	}

	this._parent._api
		.getAPIRoute('/user/thing/:thingUuid/admin/event/:elementName/:value')
		.post(hashCommand.thingUuid, this._name, value, function(err, data) {
			if (err) {
				callback.call(currentElement, err);
				return;
			}

			currentElement.trigger('sent', callback);
		});
};
