function WidgetDeviceInfo(id, api, parent, options) {
	const currentWidget = this;

	WidgetBase.call(this, id, api, parent, options);

	this.deviceNameInputId = this.generateChildId('deviceNameInput');
	this.descriptionInputId = this.generateChildId('descriptionInput');
	this.deviceUuidInputId = this.generateChildId('deviceUuidInput');
	this.projectUuidInputId = this.generateChildId('projectUuidInput');
	this.deviceOrganizationLabelId = this.generateChildId(
		'deviceOrganizationLabel',
	);
	this.deviceOrganizationInputId = this.generateChildId(
		'deviceOrganizationInput',
	);
	this.addressInputId = this.generateChildId('addressInput');
	this.cityInputId = this.generateChildId('cityInput');
	this.stateInputId = this.generateChildId('stateInput');
	this.postalCodeInputId = this.generateChildId('postalCodeInput');
	this.countryInputId = this.generateChildId('countryInput');
	this.saveChangesButtonId = this.generateChildId('saveChangesButton');
	this.removeImageId = this.generateChildId('removeImage');
	this.deviceImageId = this.generateChildId('deviceImage');
	this.imageFileInputId = this.generateChildId('imageFileInput');
	this.deviceMetaSectionId = this.generateChildId('deviceMetaSection');
	this.deviceMetaContainerId = this.generateChildId('deviceMetaContainer');

	this.renderTemplate(
		{
			deviceInformationLabel: getLanguageTag(this.constructor, 'name'),
			deviceNameLabel: getLanguageTag(this.constructor, 'deviceName'),
			deviceNameInputId: this.deviceNameInputId,
			deviceName: getLanguageTag(this.constructor, 'loading'),
			descriptionLabel: getLanguageTag(this.constructor, 'description'),
			descriptionInputId: this.descriptionInputId,
			description: getLanguageTag(this.constructor, 'loading'),
			deviceUuidLabel: getLanguageTag(this.constructor, 'deviceUuid'),
			deviceUuidInputId: this.deviceUuidInputId,
			deviceUuid:
				(getHashCommand() || {}).thingUuid ||
				null ||
				getLanguageTag(this.constructor, 'loading'),

			projectUuidLabel: getLanguageTag(this.constructor, 'projectUuid'),
			projectUuidInputId: this.projectUuidInputId,
			projectUuid: getLanguageTag(this.constructor, 'loading'),
			deviceOrganizationLabel: getLanguageTag(
				this.constructor,
				'deviceOrganization',
			),
			deviceOrganizationLabelId: this.deviceOrganizationLabelId,
			deviceOrganizationInputId: this.deviceOrganizationInputId,
			deviceOrganization: getLanguageTag(this.constructor, 'loading'),
			addressLabel: getLanguageTag(this.constructor, 'address'),
			addressInputId: this.addressInputId,
			address: getLanguageTag(this.constructor, 'loading'),
			cityLabel: getLanguageTag(this.constructor, 'city'),
			cityInputId: this.cityInputId,
			city: getLanguageTag(this.constructor, 'loading'),
			stateLabel: getLanguageTag(this.constructor, 'state'),
			stateInputId: this.stateInputId,
			state: getLanguageTag(this.constructor, 'loading'),
			postalCodeLabel: getLanguageTag(this.constructor, 'postalCode'),
			postalCodeInputId: this.postalCodeInputId,
			postalCode: getLanguageTag(this.constructor, 'loading'),
			countryLabel: getLanguageTag(this.constructor, 'country'),
			countryInputId: this.countryInputId,
			country: getLanguageTag(this.constructor, 'loading'),
			saveChangesButtonId: this.saveChangesButtonId,
			saveChangesLabel: getLanguageTag(this.constructor, 'saveChanges'),
			deviceImageLabel: getLanguageTag(this.constructor, 'deviceImage'),
			removeImageId: this.removeImageId,
			removeImageLabel: getLanguageTag(this.constructor, 'removeImage'),
			deviceImageId: this.deviceImageId,
			imageFileInputId: this.imageFileInputId,
			deviceMetaLabel: this.getLanguageTag('deviceMeta'),
			deviceMetaSectionId: this.deviceMetaSectionId,
			deviceMetaContainerId: this.deviceMetaContainerId,
		},
		WidgetDeviceInfo.name,
	);

	this.deviceNameInput = $(`#${this.deviceNameInputId}`);
	this.descriptionInput = $(`#${this.descriptionInputId}`);
	this.deviceUuidInput = $(`#${this.deviceUuidInputId}`);
	this.projectUuidInput = $(`#${this.projectUuidInputId}`);
	this.deviceOrganizationInput = $(`#${this.deviceOrganizationInputId}`);
	this.deviceOrganizationLabel = $(`#${this.deviceOrganizationLabelId}`);
	this.addressInput = $(`#${this.addressInputId}`);
	this.cityInput = $(`#${this.cityInputId}`);
	this.stateInput = $(`#${this.stateInputId}`);
	this.postalCodeInput = $(`#${this.postalCodeInputId}`);
	this.countryInput = $(`#${this.countryInputId}`);
	this.saveChangesButton = $(`#${this.saveChangesButtonId}`);
	this.removeImage = $(`#${this.removeImageId}`);
	this.deviceImage = $(`#${this.deviceImageId}`);
	this.imageFileInput = $(`#${this.imageFileInputId}`);
	this.deviceMetaSection = $(`#${this.deviceMetaSectionId}`);
	this.deviceMetaContainer = $(`#${this.deviceMetaContainerId}`);

	this.metaSettingsFormWidget = null;

	this.deviceOrganizationInput.on('click', function(e) {
		e.preventDefault();

		currentWidget.getMainContainer().setLocation(LocationDeviceManager, {
			org: currentWidget._currentDevice.data.organization.id,
		});
	});

	this.canModifyDevice = false;

	this.saveChangesButton.hide();
}

WidgetDeviceInfo.prototype = Object.create(WidgetBase.prototype);
WidgetDeviceInfo.prototype.constructor = WidgetDeviceInfo;

WidgetDeviceInfo.prototype.initialize = function(callback) {
	const currentWidget = this;

	this.addChildWidget(
		WidgetSettingsForm,
		this.deviceMetaContainerId,
		{},
		function(err, metaSettingsFormWidget) {
			this.metaSettingsFormWidget = metaSettingsFormWidget;

			this.update(function(err) {
				this.imageFileInput.on('change', function(e) {
					currentWidget._onImageFileInputChanged(e);
				});

				this.removeImage.click(function() {
					currentWidget.getMainContainer().showConfirm(
						{
							message: currentWidget.getLanguageTag(
								'confirmRemovingImage',
							),
							title: currentWidget.getLanguageTag(
								'removingImageTitle',
							),
							confirmLabel: currentWidget.getLanguageTag(
								'confirmRemovingImageButton',
							),
						},

						function() {
							currentWidget.unsetImage();
						},
					);
				});

				this.deviceImage.click(function() {
					if (currentWidget.canModifyDevice) {
						currentWidget.imageFileInput.show();
						currentWidget.imageFileInput.trigger('click');
						currentWidget.imageFileInput.hide();
					}
				});

				this.deviceNameInput.change(function() {
					currentWidget._onInputChanged();
				});

				this.deviceNameInput.keydown(function(e) {
					currentWidget._onKeyDown(e);
				});

				this.descriptionInput.change(function() {
					currentWidget._onInputChanged();
				});

				this.descriptionInput.keydown(function(e) {
					currentWidget._onKeyDown(e);
				});

				this.addressInput.change(function() {
					currentWidget._onInputChanged();
				});

				this.addressInput.keydown(function(e) {
					currentWidget._onKeyDown(e);
				});

				this.cityInput.change(function() {
					currentWidget._onInputChanged();
				});

				this.cityInput.keydown(function(e) {
					currentWidget._onKeyDown(e);
				});

				this.stateInput.change(function() {
					currentWidget._onInputChanged();
				});

				this.stateInput.keydown(function(e) {
					currentWidget._onKeyDown(e);
				});

				this.postalCodeInput.change(function() {
					currentWidget._onInputChanged();
				});

				this.postalCodeInput.keydown(function(e) {
					currentWidget._onKeyDown(e);
				});

				this.countryInput.change(function() {
					currentWidget._onInputChanged();
				});

				this.countryInput.keydown(function(e) {
					currentWidget._onKeyDown(e);
				});

				this.saveChangesButton.change(function() {
					currentWidget._onInputChanged();
				});

				this.deviceImage.change(function() {
					currentWidget._onInputChanged();
				});

				this.saveChangesButton.click(function() {
					currentWidget.saveInformation();
				});

				WidgetBase.prototype.initialize.call(this, callback);
			});
		},
	);
};

WidgetDeviceInfo.prototype.disableAllInputs = function(value) {
	const formContainer = this.getContainer().find(
		'.WidgetDeviceInfo-InformationForm',
	);
	const labels = formContainer.find('.WidgetDeviceInfo-Label');
	// True means to disable
	if (value) {
		// Labels
		labels.addClass('standard-label-disabled');
		// Controls
		this.saveChangesButton.hide();
		this.removeImage.hide();
		// Inputs
		this.deviceImage.prop('disabled', true);
		this.deviceNameInput.prop('disabled', true);
		this.descriptionInput.prop('disabled', true);
		this.addressInput.prop('disabled', true);
		this.cityInput.prop('disabled', true);
		this.stateInput.prop('disabled', true);
		this.postalCodeInput.prop('disabled', true);
		this.countryInput.prop('disabled', true);
		this.saveChangesButton.prop('disabled', true);
		this.removeImage.prop('disabled', true);
		this.deviceImage.prop('disabled', true);
	} else {
		// Labels
		labels.removeClass('standard-label-disabled');
		// Controls
		this.saveChangesButton.show();
		this.removeImage.show();
		// Inputs
		this.deviceImage.prop('disabled', false);
		this.deviceNameInput.prop('disabled', false);
		this.descriptionInput.prop('disabled', false);
		this.addressInput.prop('disabled', false);
		this.cityInput.prop('disabled', false);
		this.stateInput.prop('disabled', false);
		this.postalCodeInput.prop('disabled', false);
		this.countryInput.prop('disabled', false);
		this.saveChangesButton.prop('disabled', false);
		this.removeImage.prop('disabled', false);
		this.deviceImage.prop('disabled', false);
	}
};

WidgetDeviceInfo.prototype.getAndShowImage = function(device, callback) {
	const currentWidget = this;
	const api = currentWidget.getApiV2().apis;

	api.devices
		.getDeviceImage({ id: device.id })
		.then((image) => {
			currentWidget.deviceImage.attr('src', image);
			callback.call(currentWidget, false);
		})
		.catch(() => {
			currentWidget.deviceImage.attr(
				'src',
				'./Resources/icons/SetImage.svg',
			);
			callback.call(currentWidget, false);
		});
};

WidgetDeviceInfo.prototype.fillDeviceInfo = function(deviceData) {
	const currentWidget = this;
	/* Street address meta should be strucutred as such
	 *
	 * deviceData.streetAddress = {
	 * 	"address":"STREET ADDRESS",
	 * 	"city":"CITY",
	 * 	"region":"STATE",
	 * 	"postalCode":"ZIP",
	 * 	"country":"US"
	 * }
	 */

	currentWidget.deviceNameInput.val(deviceData.name);
	currentWidget.descriptionInput.val(deviceData.description || null);
	currentWidget.deviceUuidInput.val(deviceData.uuid);

	const deviceOrgModel = new Organization(undefined, deviceData.organization);
	currentWidget.deviceOrganizationInput.text(deviceOrgModel.getStringPath());

	const currentUser = currentWidget.getCurrentUser();

	if (currentUser.ability.cannot('see', 'Organizations')) {
		currentWidget.deviceOrganizationLabel.hide();
		currentWidget.deviceOrganizationInput.hide();
	} else {
		currentWidget.deviceOrganizationLabel.show();
		currentWidget.deviceOrganizationInput.show();
	}

	currentWidget.addressInput.val(
		(deviceData.streetAddress || {}).address || null,
	);
	currentWidget.cityInput.val((deviceData.streetAddress || {}).city || null);
	currentWidget.stateInput.val(
		(deviceData.streetAddress || {}).region || null,
	);
	currentWidget.postalCodeInput.val(
		(deviceData.streetAddress || {}).postalCode || null,
	);
	currentWidget.countryInput.val(
		(deviceData.streetAddress || {}).country || null,
	);
	currentWidget.projectUuidInput.val(deviceData.projectUuid || null);

	const extraMetaFormSettings = [];

	//We only want to display meta information we don't already show somewhere else.
	for (const k in deviceData.meta) {
		if (
			![
				'disableaddnotification',
				'platform',
				'geoloc',
				'status',
			].includes(k.toLowerCase())
		) {
			extraMetaFormSettings.push({
				name: k,
				type: 'text',
				label: currentWidget.getLanguageTag(k),
				value: deviceData.meta[k],
			});
		}
	}

	if (extraMetaFormSettings.length <= 0) {
		currentWidget.deviceMetaSection.hide();
	} else {
		currentWidget.deviceMetaSection.show();
	}

	currentWidget.metaSettingsFormWidget.setForm(extraMetaFormSettings);

	//Disable all the inputs for the meta as we aren't implementing the ability to change them
	$(`#${currentWidget.deviceMetaContainerId} :input`).prop('disabled', true);
	return;
};

WidgetDeviceInfo.prototype.update = function(callback) {
	callback = callback || function() {};

	const currentWidget = this;

	this.saveChangesButton.prop('disabled', true);

	const deviceId = (getHashCommand() || {}).deviceId || null;
	const api = currentWidget.getApiV2().apis;
	const currentUser = currentWidget.getCurrentUser();

	api.devices
		.getDevice({ id: deviceId })
		.then(async (deviceData) => {
			const device = new Device(api, deviceData);
			this._currentDevice = device;
			await device.populate();
			if (currentUser.ability.can('modify', device)) {
				currentWidget.canModifyDevice = true;
				currentWidget.disableAllInputs(false);
				currentWidget.saveChangesButton.show();
			} else {
				currentWidget.canModifyDevice = false;
				currentWidget.disableAllInputs(true);
				currentWidget.saveChangesButton.hide();
			}
			currentWidget.fillDeviceInfo(device.data);
			currentWidget.getAndShowImage(device.data, callback);
		})
		.catch((e) => {
			console.error('Error reading device information ', e);
			currentWidget
				.getMainContainer()
				.showPopupErrorMessage(
					getLanguageTag(
						currentWidget.constructor,
						'errorReadingDeviceInformation',
					),
				);
		});
};

WidgetDeviceInfo.prototype._onInputChanged = function() {
	this.saveChangesButton.prop('disabled', false);
};

WidgetDeviceInfo.prototype._onKeyDown = function(e) {
	this.saveChangesButton.prop('disabled', false);

	if (e && e.keyCode == 13) {
		this.saveInformation();
	}
};

WidgetDeviceInfo.prototype.saveInformation = function(callback) {
	const currentWidget = this;

	callback = callback || function() {};

	const newStreetAddress = {
		address: this.addressInput.val().trim(),
		city: this.cityInput.val().trim(),
		region: this.stateInput.val().trim(),
		postalCode: this.postalCodeInput.val().trim(),
		country: this.countryInput.val().trim(),
	};

	const api = currentWidget.getApiV2().apis;

	// Before the big save happens,
	// Grab the current state of the device in case the metadata changed elsewhere
	// while we were editing
	api.devices
		.getDevice({ id: currentWidget._currentDevice.data.id })
		.then((device) => {
			const deviceModel = new Device(api, device);
			currentWidget._currentDevice = deviceModel;
			deviceModel.data.name = currentWidget.deviceNameInput.val().trim();
			deviceModel.data.streetAddress = newStreetAddress;
			deviceModel.data.description = currentWidget.descriptionInput
				.val()
				.trim();
			return deviceModel.save();
		})
		.then(() => {
			//We need to throw the changed event to let
			//our parent location know information changed
			currentWidget.event('changed');

			currentWidget
				.getMainContainer()
				.showPopupInfoMessage(
					currentWidget.getLanguageTag(
						'successSettingDeviceInformation',
					),
				);
			callback.call(currentWidget, false);
		})
		.catch((err) => {
			console.error('Error saving device: ', err);
			currentWidget
				.getMainContainer()
				.showPopupErrorMessage(
					getLanguageTag(
						currentWidget.constructor,
						'errorSettingDeviceInformation',
					),
				);
			callback.call(currentWidget, err);
		});
};

WidgetDeviceInfo.prototype.verifyImageData = function(imageData, callback) {
	const currentWidget = this;

	if (imageData.constructor === File) {
		const file = imageData;
		const fileName = imageData.name;

		if (checkForFileType(fileName, this.VALID_FILE_TYPES) === false) {
			callback.call(this, {
				type: 'invalidImageUploadExtensionError',
			});

			return;
		}

		callback.call(this, false, imageData);
		return;
	} else {
		callback.call(this, false, imageData);
		return;
	}
};

WidgetDeviceInfo.prototype.readImageFromFile = function(imageFile, callback) {
	const currentWidget = this;

	const fileReader = new FileReader();
	let base64ImageData = null;

	if (isPhonegap()) {
		fileReader.onloadend = function(e) {
			base64ImageData = e.target.result;

			callback.call(currentWidget, false, base64ImageData);

			return;
		};
	} else {
		fileReader.addEventListener('load', function(e) {
			base64ImageData = e.target.result;

			callback.call(currentWidget, false, base64ImageData);

			return;
		});
	}

	fileReader.readAsDataURL(imageFile);
	return;
};

WidgetDeviceInfo.prototype.setImage = function(base64ImageDataOrUrl, callback) {
	const currentWidget = this;

	if (!base64ImageDataOrUrl) {
		this.image = null;

		callback.call(this, false, null);

		return;
	}

	const img = new Image();
	const canvas = document.createElement('canvas');

	img.src = base64ImageDataOrUrl;

	img.onload = function() {
		if (
			img.height > currentWidget.UPLOADED_IMAGE_HEIGHT_MAX ||
			img.width > currentWidget.UPLOADED_IMAGE_WIDTH_MAX
		) {
			canvas.height = currentWidget.UPLOADED_IMAGE_HEIGHT_MAX;
			canvas.width =
				(currentWidget.UPLOADED_IMAGE_HEIGHT_MAX * img.width) /
				img.height;
		} else {
			canvas.height = img.height;
			canvas.width = img.width;
		}

		canvas
			.getContext('2d')
			.drawImage(img, 0, 0, canvas.width, canvas.height);

		const imageData = canvas.toDataURL('image/png') || null;

		if (!imageData) {
			callback.call(currentWidget, { type: 'invalidImageData' });
			return;
		}

		const api = currentWidget.getApiV2().apis;
		api.devices
			.updateDeviceImage(
				{ id: currentWidget._currentDevice.data.id },
				{
					requestBody: imageData,
				},
			)
			.then(() => {
				currentWidget.event('changed');
				currentWidget.update(callback);
			})
			.catch((err) => {
				callback.call(currentWidget, err);
				currentWidget
					.getMainContainer()
					.showPopupErrorMessage(
						getLanguageTag(
							currentWidget.constructor,
							'errorSettingDeviceInformation',
						),
					);
			});
	};
};

WidgetDeviceInfo.prototype.unsetImage = function(callback) {
	callback = callback || function() {};

	const currentWidget = this;

	const api = currentWidget.getApiV2().apis;
	api.devices
		.deleteDeviceImage({ id: currentWidget._currentDevice.data.id })
		.then(() => {
			currentWidget.event('changed');
			currentWidget.update(callback);
		})
		.catch((err) => {
			callback.call(currentWidget, err);
			currentWidget
				.getMainContainer()
				.showPopupErrorMessage(
					getLanguageTag(
						currentWidget.constructor,
						'errorSettingDeviceInformation',
					),
				);
		});
};

WidgetDeviceInfo.prototype._onImageFileInputChanged = function(event) {
	const currentWidget = this;

	event.preventDefault();
	event.stopPropagation();

	const file = event.target.files[0];

	function reportError(err) {
		currentWidget
			.getMainContainer()
			.showPopupErrorMessage(currentWidget.getLanguageTag(err.type));

		console.error(err);
		return;
	}

	if (file) {
		this.verifyImageData(file, function(err, verifiedImageData) {
			if (err) {
				reportError.call(this, err);
				return;
			}

			this.readImageFromFile(file, function(err, base64ImageData) {
				if (err) {
					reportError.call(this, err);
					return;
				}

				this.setImage(base64ImageData, function(err, data) {
					if (err) {
						reportError.call(this, err);
						return;
					}

					this.update();
				});

				return;
			});
		});
	}
};

WidgetDeviceInfo.prototype.UPLOADED_IMAGE_HEIGHT_MAX = 256;
WidgetDeviceInfo.prototype.UPLOADED_IMAGE_WIDTH_MAX = 256;

WidgetDeviceInfo.prototype.VALID_FILE_TYPES = [
	'.png',
	'.jpg',
	'.gif',
	'.jpeg',
	'.svg',
	'.webp',
];

WidgetDeviceInfo.prototype.ICON = './Resources/icons/Information.svg';

WidgetDeviceInfo.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'Device Information',
			deviceName: 'Name',
			description: 'Description',
			deviceUuid: 'UUID',
			projectUuid: 'Application UUID',
			deviceOrganization: 'Organization',
			address: 'Address',
			city: 'City',
			state: 'State / Province',
			postalCode: 'Zip / Postal Code',
			country: 'Country',
			saveChanges: 'Save Changes',
			deviceImage: 'Device Image',
			removeImage: 'Remove Image',
			deviceMeta: 'Device Meta',
			successSettingDeviceInformation: 'Device information updated',
			errorSettingDeviceInformation:
				'There was an error when trying to set the device information',
			errorReadingDeviceInformation:
				'There was an error when trying to read the device information',
			removingImageTitle: 'Remove Image',
			confirmRemovingImage:
				"Are you sure you want to remove this device's image?",
			confirmRemovingImageButton: 'Remove',
			invalidImageUploadExtensionError: 'Invalid image file selected',
			globalstarESN: 'Globalstar ESN',
			sigfoxDeviceId: 'Sigfox ID',
			loraDevEUI: 'LoRa Device EUI',
		},
	},
);
