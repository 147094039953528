function ElementAppBLECharacteristicCustom(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppBLECharacteristicBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);
}

ElementAppBLECharacteristicCustom.prototype = Object.create(
	ElementAppBLECharacteristicBase.prototype,
);
ElementAppBLECharacteristicCustom.prototype.constructor = ElementAppBLECharacteristicCustom;

ElementAppBLECharacteristicCustom.prototype.read = function(callback) {
	this._read('read', callback);
};

ElementAppBLECharacteristicCustom.prototype.write = function(value, callback) {
	this._write(value, 'written', callback);
};

ElementAppBLECharacteristicCustom.prototype.subscribe = function(callback) {
	this._subscribe(['notification', 'indication'], callback);
};

ElementAppBLECharacteristicCustom.prototype.unsubscribe = function(callback) {
	this._unsubscribe(callback);
};
