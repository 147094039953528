function PlaneControllerEmbeddedStm32L4(parentController, elementName) {
	PlaneControllerEmbeddedStm32.call(this, parentController, elementName);
}

PlaneControllerEmbeddedStm32L4.prototype = Object.create(
	PlaneControllerEmbeddedStm32.prototype,
);
PlaneControllerEmbeddedStm32L4.prototype.constructor = PlaneControllerEmbeddedStm32L4;

PlaneControllerEmbeddedStm32L4.prototype.VARIANTS = ['stm32l4'];
PlaneControllerEmbeddedStm32L4.prototype.SUPPORTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'interval',
	'variables',
	'ble',
	'gpio',
	'i2c',
	'spi',
	'uart',
	'fpmath',
	'stm32',
	'debugPrint',
];

PlaneControllerEmbeddedStm32L4.prototype.DRIVERS = {};

PlaneControllerEmbeddedStm32L4.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'PG12',
		mode: 'ATMO_GPIO_PinMode_Output_PushPull',
	},

	EmbeddedLSM303AGR: {
		gpioInstance: 0,
		spiInstance: 1,
		communicationMode: 'ATMO_DRIVERTYPE_SPI',
		spi3Wire: true,
		magCsPin: 'PB1',
		accelCsPin: 'PC4',
	},

	EmbeddedLPS22HB: {
		communicationMode: 'ATMO_DRIVERTYPE_SPI',
		spiInstance: 1,
		gpioInstance: 0,
		csPin: 'PA3',
		spi3Wire: true,
	},

	EmbeddedLSM6DSM: {
		communicationMode: 'ATMO_DRIVERTYPE_SPI',
		spiInstance: 1,
		gpioInstance: 0,
		i2cInstance: 0,
		csPin: 'PB12',
		spi3Wire: true,
	},

	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'PG12',
	},

	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'PG12',
	},

	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'PG12',
	},

	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'PG12',
	},
};

PlaneControllerEmbeddedStm32L4.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			defaultName: 'Stm32L4',
		},
	},
);
