function WidgetHelpMenu(id, api, parent, options) {
	var currentWidget = this;

	this._updateInterval = null;

	this._menuEntryToConfig = {};

	WidgetMenu.call(this, id, api, parent, options);
}

WidgetHelpMenu.prototype = Object.create(WidgetMenu.prototype);
WidgetHelpMenu.prototype.constructor = WidgetHelpMenu;

WidgetHelpMenu.prototype.initialize = function(callback) {
	this._menuEntryToConfig = {};

	var currentWidget = this;

	var menuEntries = [];

	var currentHelpMenuConfig = this.getOptions().menuEntries ||
		this.getConfiguration().menuEntries || [
			{
				label: {
					'en-US': 'Docs',
				},
				value: 'help',
				link: 'https://docs.atmosphereiot.com',
				imageSrc: './Resources/icons/Documentation.svg',
			},
		];

	for (var i = 0; i < currentHelpMenuConfig.length; i++) {
		var currentEntry = currentHelpMenuConfig[i];

		var entryHtml = $.handlebarTemplates['WidgetHelpMenu-Entry']({
			imageSrc: currentEntry.imageSrc,
			label: getMessageLanguage(currentEntry.label),
			isLink: Boolean(currentEntry.link),
		});

		menuEntries.push({
			label: entryHtml,
			value: currentEntry.value,
		});

		this._menuEntryToConfig[currentEntry.value] = currentEntry;
	}

	this.setMenu(
		menuEntries,
		`<div class="WidgetHelpMenu-Title standard-local-header-h3">${getLanguageTag(
			currentWidget.constructor,
			'help',
		)}</div>`,
	);
	this.addEventListener('menuEntryClicked', function(data) {
		currentWidget.hide();

		var menuEntryConfig = this._menuEntryToConfig[data];

		if (!menuEntryConfig) {
			console.error(`menuEntryConfig is not defined for "${data}"`);
			return;
		}

		if (menuEntryConfig.location) {
			this.getMainContainer().setLocation(
				menuEntryConfig.location,
				menuEntryConfig.arguments,
			);
			return;
		}

		if (menuEntryConfig.link) {
			openLinkInNewWindowOrTab(menuEntryConfig.link);
			return;
		}
	});

	WidgetMenu.prototype.initialize.call(this, callback);
};

WidgetHelpMenu.prototype.remove = function(callback) {
	WidgetMenu.prototype.remove.call(this, callback);
};

WidgetHelpMenu.prototype.language = deepAssign(
	{},
	WidgetMenu.prototype.language,
	{
		'en-US': {
			help: 'Help',
		},
	},
);

WidgetHelpMenu.prototype.$_$ = function(done) {
	var testMenuEntries = [
		{
			label: {
				'en-US': 'Help',
			},
			value: 'help',
			location: 'Help',
			imageSrc: './Resources/icons/Help.svg',
		},

		{
			label: {
				'en-US': 'Forums',
			},
			value: 'forums',
			link: 'https://forum.atmosphereiot.com',
			imageSrc: './Resources/icons/Forum.svg',
		},

		{
			label: {
				'en-US': 'Google',
			},
			value: 'forums',
			link: 'https://google.com',
			imageSrc: './Resources/icons/Forum.svg',
		},
	];

	this.$_SetupWidgetTest({ menuEntries: testMenuEntries }, function(err) {
		WidgetMenu.prototype.$_$.call(this, done);
	});
};
