function ElementControllerEmbeddedWifiConnection(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('instance', null, 0, 'driverInstance', null, 'wifi');
	this.addProperty('enabled', null, true, 'checkbox', null, null);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
    ATMO_WIFI_SetEnabled(ATMO_PROPERTY(undefined, instance), ATMO_PROPERTY(undefined, enabled));
    ATMO_WIFI_RegisterAbility(ATMO_PROPERTY(undefined, instance), ATMO_WIFI_CONNECTED, ATMO_ABILITY(undefined, connected));
    ATMO_WIFI_RegisterAbility(ATMO_PROPERTY(undefined, instance), ATMO_WIFI_DISCONNECTED, ATMO_ABILITY(undefined, disconnected));
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('setSSID', [], ['ssidSet'], false, false);
	this.setAbilityCode(
		'setSSID',
		`
    ATMO_CLOUD_SetConfig(ATMO_CLOUD_CONFIG(WIFI, SSID), in);
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('setPassword', [], ['passwordSet'], false, false);
	this.setAbilityCode(
		'setPassword',
		`
    ATMO_CLOUD_SetConfig(ATMO_CLOUD_CONFIG(WIFI, PASS), in);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('disconnected', [], ['disconnected'], false, true);
	this.setAbilityCode(
		'disconnected',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('connected', [], ['connected'], false, true);
	this.setAbilityCode(
		'connected',
		`
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('disconnect', [], [], false, false);
	this.setAbilityCode(
		'disconnect',
		`
	ATMO_WIFI_Stop(ATMO_PROPERTY(undefined, instance));
    return ATMO_Status_Success;
    `,
	);

	this.addAbility('reconnect', [], [], false, false);
	this.setAbilityCode(
		'reconnect',
		`
    ATMO_WIFI_Stop(ATMO_PROPERTY(undefined, instance));
    ATMO_WIFI_Config_t wifiConfig;

    // Get config from cloud
    ATMO_Value_t ssidVal;
    ATMO_InitValue(&ssidVal);
    ATMO_Value_t passVal;
    ATMO_InitValue(&passVal);

    if( ATMO_CLOUD_GetConfig( ATMO_CLOUD_CONFIG(WIFI, SSID), &ssidVal ) != ATMO_CLOUD_Status_Success )
    {
        return ATMO_Status_Fail;
    }

    if( ATMO_CLOUD_GetConfig( ATMO_CLOUD_CONFIG(WIFI, PASS), &passVal ) != ATMO_CLOUD_Status_Success )
    {
        return ATMO_Status_Fail;
    }

    wifiConfig.mode = ATMO_WIFI_MODE_STA;
    wifiConfig.ssid = (const char *)ssidVal.data;
    wifiConfig.password = (const char *)passVal.data;
    ATMO_WIFI_SetConfiguration(ATMO_PROPERTY(undefined, instance), &wifiConfig);
    ATMO_FreeValue(&ssidVal);
    ATMO_FreeValue(&passVal);
    ATMO_WIFI_Start(ATMO_PROPERTY(undefined, instance));
	return ATMO_Status_Success;
	`,
	);
}

ElementControllerEmbeddedWifiConnection.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedWifiConnection.prototype.constructor = ElementControllerEmbeddedWifiConnection;

ElementControllerEmbeddedWifiConnection.prototype.NAME = 'WifiConnection';
ElementControllerEmbeddedWifiConnection.prototype.DEFAULT_TRIGGER = 'connected';
ElementControllerEmbeddedWifiConnection.prototype.DEFAULT_ABILITY = 'setSSID';
ElementControllerEmbeddedWifiConnection.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerEmbeddedWifiConnection.prototype.TYPE =
	'EmbeddedWifiConnection';
ElementControllerEmbeddedWifiConnection.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'wifi',
];
ElementControllerEmbeddedWifiConnection.prototype.HIDDEN = false;
ElementControllerEmbeddedWifiConnection.prototype.CATEGORY = 'wifi';

ElementControllerEmbeddedWifiConnection.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedWifiConnection: 'Wi-Fi Connection',
			disconnect: 'Disconnect',
			connected: 'Connected',
			disconnected: 'Disconnected',
			reconnect: 'Attempt Reconnect',
			autoconnect: 'Attempt Connection on Startup',
			enabled: 'Wi-Fi Enabled',
			setSSID: 'Set SSID',
			ssidSet: 'SSID Set',
			setPassword: 'Set Password',
			passwordSet: 'Password Set',
		},
	},
);
