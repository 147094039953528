function WidgetAddDeviceSuccess(id, api, parent, options) {
	WidgetPanelBase.call(this, id, api, parent, options);
	this.options = options;
	this._buttonsFooter = null;
}

WidgetAddDeviceSuccess.prototype = Object.create(WidgetPanelBase.prototype);
WidgetAddDeviceSuccess.prototype.constructor = WidgetAddDeviceSuccess;

WidgetAddDeviceSuccess.prototype.initialize = function(callback) {
	const currentWidget = this;

	const { deviceId, deviceUuid, deviceToken } = this.getOptions();

	const deviceIdMessage = formatString(
		this.getLanguageTag('deviceId'),
		deviceId,
	);
	const successMessage = this.getLanguageTag('successMessage');
	const disclaimerMessage = this.getLanguageTag('onlyTimeHaveAccessToToken');

	this.renderTemplate(
		{
			deviceIdMessage,
			deviceToken,
			successMessage,
			disclaimerMessage,
		},
		WidgetAddDeviceSuccess.name,
	);

	this.addChildWidget(
		WidgetButtonsFooter,
		'WidgetAddDeviceSuccess-ButtonsFooter',
		{},
		(err, buttonsFooterWidget) => {
			$('.WidgetAddDeviceSuccess-Message').click((event) => {
				// Prevent any browser-specific behavior
				event.preventDefault();
				event.stopPropagation();
				// Open in external browser in the mobile app
				openLinkInNewWindowOrTab(currentWidget.HELP_PAGE_HREF);
			});

			currentWidget._buttonsFooter = buttonsFooterWidget;
			this._buttonsFooter.setButtons([
				{
					label: currentWidget.getLanguageTag('finish'),
					value: 'finished',
					classes: ['btn-primary'],
				},
			]);
			currentWidget._buttonsFooter.addEventListener(
				'footerButtonPressed',
				(value) => {
					currentWidget
						.getMainContainer()
						.setLocation(LocationDeviceDashboard, {
							deviceId,
							thingUuid: deviceUuid,
							section: 'info',
				   			org: getHashCommand().org,
						});
					currentWidget.getMainContainer().closeModal();
				},
			);

			currentWidget.handleClipboard();

			WidgetPanelBase.prototype.initialize.call(this, callback);
		},
	);
};

WidgetAddDeviceSuccess.prototype.handleClipboard = function() {
	const currentWidget = this;

	const clipboardContainer = new clipboard(
		`.WidgetAddDeviceSuccess-ClipboardWrapper`,
	);

	if (!clipboard.isSupported()) {
		clipboardContainer.hide();
	}

	clipboardContainer.on('success', (event) => {
		const copiedToClipboard = this.getLanguageTag('copiedToClipboard');
		currentWidget
			.getMainContainer()
			.showPopupInfoMessage(copiedToClipboard);
	});

	clipboardContainer.on('error', (event) => {
		console.error(`Action: ${event.action}`);
		console.error(`Trigger: ${event.trigger}`);
	});
};

WidgetAddDeviceSuccess.prototype.HELP_PAGE_HREF = `https://developer.atmosphereiot.com/atmosphere-api-v2.html#section/Authentication/deviceToken`;

WidgetAddDeviceSuccess.prototype.language = deepAssign(
	{},
	WidgetPanelBase.prototype.language,
	{
		'en-US': {
			name: 'Registration Successful!',
			deviceId: `Device ID: {0}`,
			successMessage:
				'Please copy the token above and use it to <a href="#">authenticate your device.</a>',
			onlyTimeHaveAccessToToken:
				'This is the only time you will have visible access to the device token!',
			finish: 'Finish',
			copiedToClipboard: 'Copied to clipboard',
		},
	},
);
