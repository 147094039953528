function WidgetProjectEditorControls(id, api, parentWidget, options) {
	options = options || {};

	options.entries = options.entries || [
		{
			name: 'info',
			label: this.getLanguageTag('info'),
			imageSrc: './Resources/icons/Information.svg',
		},
		{
			name: 'save',
			label: this.getLanguageTag('save'),
			imageSrc: './Resources/icons/Save.svg',
		},
		{
			name: 'replace',
			label: this.getLanguageTag('replace'),
			imageSrc: './Resources/icons/ReplaceProject.svg',
		},
		{
			name: 'export',
			label: this.getLanguageTag('export'),
			imageSrc: './Resources/icons/Export.svg',
		},
		{
			name: 'compile',
			label: this.getLanguageTag('compile'),
			imageSrc: './Resources/icons/Compile.svg',
		},
		{
			name: 'exit',
			label: this.getLanguageTag('exit'),
			imageSrc: './Resources/icons/Exit.svg',
		},
	];

	WidgetLocationControls.call(this, id, api, parentWidget, options);
}

WidgetProjectEditorControls.prototype = Object.create(
	WidgetLocationControls.prototype,
);
WidgetProjectEditorControls.prototype.constructor = WidgetProjectEditorControls;

WidgetProjectEditorControls.prototype.language = deepAssign(
	{},
	WidgetLocationControls.prototype.language,
	{
		'en-US': {
			name: 'BaseLocation',
			info: 'Info',
			save: 'Save',
			replace: 'Replace',
			compile: 'Compile',
			export: 'Export',
			exit: 'Exit',
		},
	},
);
