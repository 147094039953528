function ElementControllerCloudRegister(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addAbility('removeDevice', []);

	this.addTrigger('registered');
}

ElementControllerCloudRegister.prototype = Object.create(
	ElementControllerCloudBase.prototype,
);
ElementControllerCloudRegister.prototype.constructor = ElementControllerCloudRegister;

ElementControllerCloudRegister.prototype.NAME = 'CloudRegister';
ElementControllerCloudRegister.prototype.TYPE = 'CloudRegister';
ElementControllerCloudRegister.prototype.HIDDEN = false;
ElementControllerCloudRegister.prototype.DEFAULT_TRIGGER = 'registered';
ElementControllerCloudRegister.prototype.DEFAULT_ABILITY = 'removeDevice';
ElementControllerCloudRegister.prototype.DEFAULT_ARGUMENTS = [];
ElementControllerCloudRegister.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudRegister.prototype.language = deepAssign(
	{},
	ElementControllerCloudBase.prototype.language,
	{
		'en-US': {
			CloudRegister: 'Cloud Register',
			removeDevice: 'Remove Device',
			registered: 'Registered',
		},
	},
);
