function WidgetDeviceStorage(id, api, parent, options) {
	WidgetBase.call(this, id, api, parent, options);

	this.deviceStorageUsageContainerId = this.generateChildId(
		'deviceStorageUsageContainer',
	);
	this.clearStorageDataId = this.generateChildId('clearStorageData');
	this.deviceStorageTableContainerId = this.generateChildId(
		'deviceStorageTableContainer',
	);
	this.downloadAllDataId = this.generateChildId('downloadAllData');

	this.renderTemplate(
		{
			deviceStorageUsageContainerId: this.deviceStorageUsageContainerId,
			clearStorageDataId: this.clearStorageDataId,
			titleLabel: getLanguageTag(this.constructor, 'storageUsage'),
			clearStorageDataLabel: getLanguageTag(
				this.constructor,
				'clearStorageData',
			),
			deviceDataLabel: getLanguageTag(this.constructor, 'deviceData'),
			downloadAllDataLabel: getLanguageTag(
				this.constructor,
				'downloadAllData',
			),
			deviceStorageTableContainerId: this.deviceStorageTableContainerId,
			downloadAllDataId: this.downloadAllDataId,
			isPhonegap: isPhonegap(),
		},
		WidgetDeviceStorage.name,
	);
	
	this.canEditStorage = false;
}

WidgetDeviceStorage.prototype = Object.create(WidgetBase.prototype);
WidgetDeviceStorage.prototype.constructor = WidgetDeviceStorage;

WidgetDeviceStorage.prototype.initialize = function(callback) {
	const currentWidget = this;
	const api = currentWidget.getApiV2();

	const currentUser = this.getCurrentUser();
	const deviceId = getHashCommand().deviceId;
							
	api.apis.devices
		.getDevice({ id: deviceId })
		.then((deviceData) => {
	
		const device = new Device(api, deviceData);
		
		currentWidget.canEditStorage = currentUser.ability.can('write', device);
		
		this.addChildWidget(
			WidgetDeviceStorageUsage,
			this.deviceStorageUsageContainerId,
			{},
			function(err, deviceStorageUsageWidget) {
				this.addChildWidget(
					WidgetDeviceStorageTable,
					this.deviceStorageTableContainerId,
					{ hideFilterInput: true },
					function(err, deviceStorageTableWidget) {
						
						if(currentWidget.canEditStorage) {
							$(`#${currentWidget.clearStorageDataId}`).show();
						}
						
						else {
							$(`#${currentWidget.clearStorageDataId}`).hide();
						}
						
						$(`#${currentWidget.clearStorageDataId}`).click(function() {

							currentWidget.getMainContainer().showConfirm(
								{
									message: getLanguageTag(
										currentWidget.constructor,
										'clearAllStorage',
									),
								},
								function() {
									api.apis.storage
										.deleteDeviceStorageElements({
											id: deviceId,
										})
										.then(() => {
											deviceStorageTableWidget.update();
											deviceStorageUsageWidget.update();
										})
										.catch((error) => {
											currentWidget
												.getMainContainer()
												.showPopupErrorMessage(
													getLanguageTag(
														currentWidget.constructor,
														'errorClearingStorage',
													),
												);
										});
								},
							);
						});

						$(`#${currentWidget.downloadAllDataId}`).click(function() {
							currentWidget.onDownloadAllDataClicked();
						});

						WidgetBase.prototype.initialize.call(
							currentWidget,
							callback,
						);
					},
				);
			},
		);
			
	})
	.catch((err) => {
		console.error('Error reading device data ', err);
		callback.call(currentWidget, err);
	});
};

// Gets all the data for a specific elementName
WidgetDeviceStorage.prototype.dataBy = async function(query) {
	const api = this.getApiV2();

	const firstPage = await api.apis.data.getDeviceData(query);
	const { totalPages } = firstPage.meta;
	const data = [...firstPage.data];
	const newQuery = { ...query };

	if (totalPages > 1) {
		const promises = [];
		for (let i = 2; i <= totalPages; i += 1) {
			newQuery.page = i;
			promises.push(api.apis.data.getDeviceData(newQuery));
		}

		const pages = await Promise.all(promises);
		pages.forEach((page) => {
			data.push(...page.data);
		});
	}

	return data;
};

WidgetDeviceStorage.prototype.onDownloadAllDataClicked = function() {
	const currentWidget = this;
	const api = currentWidget.getApiV2();

	const deviceId = getHashCommand().deviceId;
	const dataPromises = [];
	let deviceName;
	let dataSets;

	api.apis.devices
		.getDevice({ id: deviceId })
		.then((device) => {
			deviceName = device.name;
			return api.apis.storage.getDeviceStorageElements({ id: deviceId });
		})
		.then((response) => {
			dataSets = response.data;

			dataSets.forEach((dataSet) => {
				dataPromises.push(
					currentWidget.dataBy({
						id: deviceId,
						elementName: dataSet.name,
					}),
				);
			});

			return Promise.all(dataPromises);
		})
		.then((deviceData) => {
			// When the promises are fulfilled they
			// need their results combined into
			// a single array.
			
			const exportData = [];
			
			deviceData.forEach((storage) => {
				
				storage.forEach((row) => {
				
					let fRow = row.payload || {};
					
					fRow.id = row.id;
					fRow.createdAt = row.createdAt;
					fRow.deviceId = row.deviceId;
					fRow.elementName = row.elementName;
					
					exportData.push(fRow);
				});
			});

			const saveDate = moment(Date.now()).format('YYYY-MM-DD');
			const fileName = `${deviceName}_AllData_${saveDate}`;
			saveObjectAsCSV(fileName, exportData);
		})
		.catch((error) => {
			console.warn(
				`[${currentWidget.constructor.name}] error occurred while calling update!`,
			);
			console.error(error);
			return;
		});

	return;
};

WidgetDeviceStorage.prototype.ICON = './Resources/icons/CloudStorage.svg';

WidgetDeviceStorage.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			storageUsage: 'Storage Usage',
			clearStorageData: 'Clear Storage Data',
			clearAllStorage:
				'Are you sure you want to clear the stored data for this device?',
			errorClearingStorage:
				"There was an error clearing the device's data. Please try again.",
			deviceData: 'Device Data',
			downloadAllData: 'Download All Data',
		},
	},
);
