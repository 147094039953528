function WidgetUsersTable(id, api, parentWidget, options) {
	/*
	 * This displays a filterable table of all the users
	 * in the system.
	 *
	 * @options
	 * @type {object}
	 * @property {number} pageSize - The maximum number of entries for each page
	 *
	 * @event WidgetUsersTable#userSelected
	 * @type {string} - Username of user that was selected
	 */

	WidgetBase.call(this, id, api, parentWidget, options);

	const currentWidget = this;
	this._table = null;

	this.addButtonId = this.generateChildId('addButton');
	this.moveButtonId = this.generateChildId('moveButton');
	this.deleteButtonId = this.generateChildId('deleteButton');
	this.showChildrenCheckboxId = this.generateChildId('showChildrenCheckbox');
	this.searchContainerId = this.generateChildId('searchContainer');
	this.tableContainerId = this.generateChildId('tableContainer');
}

WidgetUsersTable.prototype = Object.create(WidgetBase.prototype);
WidgetUsersTable.prototype.constructor = WidgetUsersTable;

WidgetUsersTable.prototype.initialize = function(callback) {
	const currentWidget = this;

	const currentUser = this.getMainContainer().getCurrentUser();

	this.getOrganizationContext((err, currentOrg) => {
		// Only show the addUser button if the current user is able to create a new basic user
		// is there a better way to check this?
		const canCreateNewUser = currentUser.ability.can(
			'manage',
			new User(null, { id: '', role: UserRoles.BASIC_USER }),
		);
		this.renderTemplate(
			{
				addButtonId: this.addButtonId,
				moveButtonId: this.moveButtonId,
				deleteButtonId: this.deleteButtonId,
				showChildrenCheckboxId: this.showChildrenCheckboxId,
				searchContainerId: this.searchContainerId,
				tableContainerId: this.tableContainerId,
				canCreateNewUser: canCreateNewUser,
				canMoveUsers: true,
				canDeleteUsers: true,
				addUser: this.getLanguageTag('addUser'),
				moveUsers: this.getLanguageTag('moveUsers'),
				deleteUsers: this.getLanguageTag('deleteUsers'),
				showSubOrganizations: this.getLanguageTag(
					'showSubOrganizations',
				),
				canSeeOrgs:
					currentUser.ability.can('see', 'Organizations') &&
					currentOrg.hasChildren,
			},
			WidgetUsersTable.name,
		);

		this.addButton = $(`#${this.addButtonId}`);
		this.moveButton = $(`#${this.moveButtonId}`);
		this.deleteButton = $(`#${this.deleteButtonId}`);
		this.showChildrenCheckbox = $(`#${this.showChildrenCheckboxId}`);

		this.addButton.on('click', function() {
			currentWidget.showAddUser();
		});

		this.moveButton.on('click', function() {
			currentWidget._onMoveSelectedUsers();
		});

		this.deleteButton.on('click', function() {
			currentWidget._onRemoveSelectedUsers();
		});

		this.moveButton.hide();
		this.deleteButton.hide();

		currentWidget.showChildrenCheckbox.on('click', (e) => {
			currentWidget.update(() => {});
		});

		const getUser = async function(apiFn, config, params) {
			const showAll = currentWidget.showChildrenCheckbox.prop('checked');
			const parameters = {
				...params,
				depth: showAll ? 'all' : '1',
			};
			const users = await apiFn(parameters);

			return {
				last_page: users.meta.totalPages,
				data: users.data.map((user) => {
					user.createdAt = new Date(user.createdAt);
					user.lastActive = new Date(user.lastActive);
					return user;
				}),
			};
		};

		const api = currentWidget.getApiV2();
		this.addChildWidget(
			WidgetSearchBox,
			this.searchContainerId,
			{
				onSearch: () => {
					currentWidget.update(() => {});
				},
			},
			function(err, searchBoxWidget) {
				currentWidget.searchBoxWidget = searchBoxWidget;

				this.addChildWidget(
					WidgetTableDynamic,
					currentWidget.tableContainerId,
					{
						hideFilterInput: true,
						selectable: true,
						apiRoute: api.apis.users.getUsers.bind(currentWidget),
						ajaxSorting: true,
						headerSort: true,
						addParams: () => {
							const showAll = currentWidget.showChildrenCheckbox.prop(
								'checked',
							);
							const params = {
								depth: showAll ? 'all' : '1',
								organizationId: getHashCommand().org,
							};
							const searchText = currentWidget.searchBoxWidget.getSearchText();

							if (searchText && searchText.length > 0) {
								params.searchText = searchText;
							}
							return params;
						},
						entryMapper: async (entry) => {
							entry.createdAt = new Date(entry.createdAt);
							entry.lastActive = new Date(entry.lastActive);
							const user = new User(
								currentWidget.getApiV2().apis,
								entry,
							);
							entry.img = await user.getImage();
							entry.imgHtml = currentWidget.userImageFormatter(
								entry.img,
							);
							entry.role = currentWidget.getLanguageTag(
								entry.role,
							);

							entry.userLink = currentWidget
								.getMainContainer()
								.getLocationLink('UserAdministration', {
									username: entry.username,
									id: entry.id,
									org: entry.organizationId,
								});
							return entry;
						},
						onRowClicked: (entry) => {
							currentWidget.event('userSelected', {
								id: entry.id,
								username: entry.username,
								organizationId: entry.organizationId,
							});
						},
						onRowDblTap: (entry) => {
							currentWidget.event('userSelected', {
								id: entry.id,
								username: entry.username,
								organizationId: entry.organizationId,
							});
						},
						onSelectionChanged: (rows) => {
							if (rows.length > 0) {
								currentWidget.moveButton.show();
								currentWidget.deleteButton.show();
							} else {
								currentWidget.moveButton.hide();
								currentWidget.deleteButton.hide();
							}
						},
						paginationSize: 20,
						columns: [
							{
								formatter: 'rowSelection',
								titleFormatter: 'rowSelection',
								hozAlign: 'center',
								width: 10,
								responsive: 0,
								headerSort: false,
							},
							{
								formatter: 'html',
								field: 'imgHtml',
								responsive: 3,
								align: 'center',
								width: 60,
								headerSort: false,
							},
							{
								title: 'Username',
								formatter: 'link',
								field: 'userLink',
								formatterParams: {
									labelField: 'username',
								},
								responsive: 0,
								minWidth: 150,
							},
							{
								title: 'First Name',
								field: 'firstName',
								responsive: 2,
								minWidth: 200,
							},
							{
								title: 'Last Name',
								field: 'lastName',
								responsive: 2,
								minWidth: 200,
							},
							{
								title: 'Organization',
								field: 'organizationName',
								responsive: 1,
								minWidth: 150,
								headerSort: false,
							},
							{
								title: 'Role',
								field: 'role',
								responsive: 2,
								minWidth: 200,
							},
							{
								title: 'Email',
								field: 'email',
								responsive: 3,
								minWidth: 200,
							},
							{
								title: 'Created At',
								field: 'createdAt',
								headerSortStartingDir: 'desc',
								formatter: 'datetime',
								responsive: 2,
								minWidth: 200,
								formatterParams: {
									humanize: true,
									suffix: true,
									invalidPlaceholder: 'Never',
									outputFormat: 'MMMM Do YYYY, h:mm a',
								},
							},
							{
								title: 'Last Active',
								field: 'lastActive',
								headerSortStartingDir: 'desc',
								formatter: 'datetime',
								responsive: 2,
								minWidth: 200,
								formatterParams: {
									humanize: true,
									suffix: true,
									invalidPlaceholder: 'Never',
									outputFormat: 'MMMM Do YYYY, h:mm a',
								},
							},
						],
					},

					(err, tableWidget) => {
						currentWidget._table = tableWidget;
						callback.call(currentWidget, false);
					},
				);
			},
		);
	});
};

WidgetUsersTable.prototype.update = function(callback) {
	const doneCb = callback || function() {};

	const currentWidget = this;
	currentWidget._table.update();
};

WidgetUsersTable.prototype.getNewUserFields = function() {
	const currentWidget = this;
	const currentUser = this.getCurrentUser();
	const rolesOpts = [];
	// Only allow to change role to a role you would have access to
	Object.values(UserRoles).forEach((role) => {
		const user = new User(null, { id: '', role });
		if (currentUser.ability.can('write', user)) {
			rolesOpts.push({
				label: currentWidget.getLanguageTag(role),
				value: role,
				selected: false,
			});
		}
	});
	return [
		// We've decided that IoT Central users only
		// need to provide an e-mail on the form.
		// Uncomment to re-enable.
		// {
		// 	name: 'username',
		// 	type: 'text',
		// 	label: this.getLanguageTag('username'),
		// 	value: '',
		// 	validator: (a) => {
		// 		return a.length > 0;
		// 	},
		// },
		{
			name: 'email',
			type: 'text',
			label: this.getLanguageTag('email'),
			value: '',
			validator: (a) => {
				let valid = true;
				if (a.length <= 0) {
					valid = false;
				}
				const input = String(a).toLowerCase();
				const isEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
				const email = isEmail.test(input);
				if (!email) {
					valid = false;
				}
				return valid;
			},
		},
		{
			name: 'firstName',
			type: 'text',
			label: this.getLanguageTag('firstName'),
			value: '',
			validator: (a) => {
				return a.length > 0;
			},
		},
		{
			name: 'lastName',
			type: 'text',
			label: this.getLanguageTag('lastName'),
			value: '',
			validator: (a) => {
				return a.length > 0;
			},
		},

		{
			name: 'company',
			type: 'text',
			label: this.getLanguageTag('company'),
			value: '',
			validator: (a) => {
				return a.length > 0;
			},
		},

		{
			name: 'role',
			type: 'select',
			label: this.getLanguageTag('role'),
			options: rolesOpts,
			validator: null,
			value: 'basicUser', // Basic user is the default in the dropdown.
		},
	];
};

WidgetUsersTable.prototype._onAddUserConfirmed = function(
	targetOrg,
	formValues,
) {
	const currentWidget = this;

	const api = currentWidget.getApiV2().apis;
	const user = new User(api, {
		username: formValues.email,
		email: formValues.email,
		firstName: formValues.firstName,
		lastName: formValues.lastName,
		company: formValues.company,
		role: formValues.role,
		organizationId: targetOrg.id,
	});

	user.create()
		.then(() => {
			currentWidget.update();
			currentWidget
				.getMainContainer()
				.showPopupInfoMessage(
					currentWidget.getLanguageTag('userAddedSuccessfully'),
				);
		})
		.catch((error) => {
			console.error(`Error creating new user due to: ${error}`);

			// Duplicate record, let the logged in user know specifically
			// why failure to the create the user occurred.
			if (currentWidget.isDuplicateErr(error)) {
				return currentWidget
					.getMainContainer()
					.showPopupErrorMessage(
						currentWidget.getLanguageTag('userAlreadyExists'),
					);
			}

			currentWidget
				.getMainContainer()
				.showPopupErrorMessage(
					currentWidget.getLanguageTag('errorAddingUser'),
				);
		});
};

WidgetUsersTable.prototype.isDuplicateErr = function(error) {
	return (
		error &&
		error.response.body &&
		error.response.body.message &&
		error.response.body.message.includes('Duplicate')
	);
};

WidgetUsersTable.prototype.showAddUser = function(callback) {
	const doneCb = callback || function() {};

	const currentWidget = this;

	currentWidget.getMainContainer().setModalWidget(
		WidgetSettingsForm,
		{
			confirmLabel: currentWidget.getLanguageTag('confirmCreateButton'),
			fields: currentWidget.getNewUserFields(),
		},
		function(err, settingsWidget) {
			settingsWidget.setTitle(
				currentWidget.getLanguageTag('createTitle'),
			);

			settingsWidget.addEventListener('dismissed', function() {
				currentWidget.getMainContainer().hideModal();
			});

			settingsWidget.addEventListener('confirmed', function() {
				currentWidget.getMainContainer().hideModal();

				currentWidget.getOrganizationContext((err, currentOrg) => {
					currentWidget._onAddUserConfirmed(
						currentOrg,
						settingsWidget.getValues(),
					);
				});
			});

			this.showModal();

			doneCb.call(this, false);
			return;
		},
	);
};

WidgetUsersTable.prototype.showOrganizationPicker = function(
	opts,
	onConfirmed,
) {
	const currentWidget = this;

	currentWidget
		.getMainContainer()
		.setModalWidget(
			WidgetOrganizationPicker,
			{ confirmLabel: opts.confirmBtnLabel },
			(err, widget) => {
				currentWidget.getMainContainer().showModal();

				widget.setTitle(opts.title);

				widget.addEventListener('dismissed', function() {
					currentWidget.getMainContainer().hideModal();
				});

				widget.addEventListener('confirmed', async function() {
					currentWidget.getMainContainer().hideModal();
					onConfirmed(widget.pickerContext);
				});
			},
		);
};

WidgetUsersTable.prototype._onMoveSelectedUsers = function() {
	const currentWidget = this;
	const usersToMove = this._table.getSelectedRowsData();
	currentWidget.showOrganizationPicker(
		{
			title: currentWidget.getLanguageTag('moveUsers'),
		},
		async function(targetOrg) {
			const movePromises = usersToMove.map((userData) => {
				const newUserData = { ...userData };
				newUserData.organizationId = targetOrg.id;
				const user = new User(
					currentWidget.getApiV2().apis,
					newUserData,
				);
				return user.save();
			});

			try {
				await Promise.all(movePromises);
				currentWidget
					.getMainContainer()
					.showPopupInfoMessage(
						currentWidget.getLanguageTag('usersMoved'),
					);
				currentWidget.update();
			} catch (err) {
				console.error('Error moving users', err);
				currentWidget
					.getMainContainer()
					.showPopupErrorMessage(
						currentWidget.getLanguageTag('errorMovingUsers'),
					);
			}
		},
	);
};

WidgetUsersTable.prototype._onRemoveSelectedUsers = function() {
	const currentWidget = this;

	const selectedRowsData = this._table.getSelectedRowsData();
	const api = currentWidget.getApiV2().apis;

	this.getMainContainer().showConfirm(
		{
			message: this.getLanguageTag('confirmUsersRemove'),
			title: this.getLanguageTag('removeUsersTitle'),
			confirmLabel: this.getLanguageTag('removeUsersButton'),
			confirmClasses: ['btn-danger'],
		},

		async function() {
			const users = selectedRowsData.map((userData) => {
				return new User(api, { id: userData.id });
			});

			const deletePromises = users.map((user) => {
				return user.delete();
			});

			try {
				await Promise.all(deletePromises);
				currentWidget
					.getMainContainer()
					.showPopupInfoMessage(
						currentWidget.getLanguageTag('usersRemoved'),
					);
				currentWidget.update(() => {});
			} catch (e) {
				console.error('Error deleting users', e);
				currentWidget
					.getMainContainer()
					.showPopupErrorMessage(
						currentWidget.getLanguageTag('errorRemovingUsers'),
					);
			}
		},
	);
};

WidgetUsersTable.prototype.userImageFormatter = function(img) {
	return $.handlebarTemplates[`${WidgetUsersTable.name}_UserEntry`]({
		imgSrc: img || './Resources/icons/UserProfile.svg',
	});
};

WidgetUsersTable.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'Users Table',
			username: 'Username',
			firstName: 'First Name',
			lastName: 'Last Name',
			email: 'Email',
			created: 'Created',
			lastActive: 'Last Active',
			role: 'Role',
			organization: 'Organization',
			removeSelectedUsers: 'Delete',
			confirmUsersRemove:
				'Are you sure you want to delete the selected users?',
			removeUsersButton: 'Delete',
			removeUsersTitle: 'Delete Users',
			errorRemovingUsers:
				'There was an error when deleting selected users. Please try again.',
			usersRemoved: 'Selected users deleted',
			filterInputPlaceHolder: 'Search for a user...',
			noData: 'No users found',
			moveSelectedUsers: 'Move',
			moveUsers: 'Move Users',
			usersMoved: 'Users moved successfully',
			errorMovingUsers:
				'There was an error while moving the selected users. Please try again.',
			addUser: 'Invite User',
			createTitle: 'Invite User',
			company: 'Company Name',
			userAddedSuccessfully: 'User invited successfully',
			errorAddingUser:
				'There was an error while inviting the new user. Please try again.',
			userAlreadyExists:
				'The user you are trying to create already exists. Please provide different criteria.',
			confirmCreateButton: 'Invite',
			selectNewUserOrg: 'Select Parent Organization',
			confirmBtnLabel: 'Confirm',
			deleteUsers: 'Delete',
			moveUsers: 'Move',
			addUser: 'Invite',
			showSubOrganizations: 'Show Sub-Organizations',
		},
	},
);
