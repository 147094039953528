function ElementControllerEmbeddedPWMPin(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('instance', null, 0, 'driverInstance', null, 'pwm');
	this.addProperty('pin', null, 'ATMO_DEFAULT_GPIO', 'text', null, null);
	this.addProperty('frequency', null, 1000, 'number', null, null);
	this.addProperty('dutyCycle', null, 0, 'number', null, null);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
	ATMO_PWM_Config_t config;
	config.pwmFreqHz = ATMO_PROPERTY(undefined, frequency);
	config.dutyCycle = ATMO_PROPERTY(undefined, dutyCycle);
	ATMO_PWM_SetPinConfiguration(ATMO_PROPERTY(undefined, instance), ATMO_PROPERTY(undefined, pin), &config);
	ATMO_PWM_Enable(
		ATMO_PROPERTY(undefined, instance),
		ATMO_PROPERTY(undefined, pin)
	);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setDutyCycle', [], ['dutyCycleSet']);
	this.setAbilityCode(
		'setDutyCycle',
		`
	unsigned int dutyCycle;
	ATMO_GetUnsignedInt(in, &dutyCycle);

	ATMO_PWM_Config_t config;
	config.pwmFreqHz = ATMO_PROPERTY(undefined, frequency);
	config.dutyCycle = dutyCycle;
	ATMO_PWM_SetPinConfiguration(ATMO_PROPERTY(undefined, instance), ATMO_PROPERTY(undefined, pin), &config);
	
	return ATMO_Status_Success;
	`,
	);
}

ElementControllerEmbeddedPWMPin.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedPWMPin.prototype.constructor = ElementControllerEmbeddedPWMPin;

ElementControllerEmbeddedPWMPin.prototype.TYPE = 'EmbeddedPWMPin';
ElementControllerEmbeddedPWMPin.prototype.HIDDEN = false;
ElementControllerEmbeddedPWMPin.prototype.DEFAULT_ABILITY = 'setDutyCycle';
ElementControllerEmbeddedPWMPin.prototype.DEFAULT_TRIGGER = 'triggered';
ElementControllerEmbeddedPWMPin.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'pwm',
];

ElementControllerEmbeddedPWMPin.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedPWMPin.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedPWMPin: 'PWM Pin',
			instance: 'Instance',
			pin: 'Pin',
			setDutyCycle: 'Set Duty Cycle',
			dutyCycleSet: 'Duty Cycle Set',
			frequency: 'Frequency (Hz)',
		},
	},
);
