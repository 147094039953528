function ElementAppCondition(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);
}

ElementAppCondition.prototype = Object.create(ElementAppBase.prototype);
ElementAppCondition.prototype.constructor = ElementAppCondition;

ElementAppCondition.prototype.check = function(value, callback) {
	var currentElement = this;
	var currentBoolean = Boolean(value);

	this._setProperty('value', value, function(err) {
		if (currentBoolean) {
			this.trigger('conditionTrue', callback);
			return;
		} else {
			this.trigger('conditionFalse', callback);
			return;
		}
	});
};
