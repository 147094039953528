//Copyright 2018 Atmosphere IoT Corp.
//All rights reserved
//jshint esversion: 6

function WidgetElementToolbox(id, api, parentWidget, options) {
	var currentWidget = this;

	WidgetBase.call(this, id, api, parentWidget, options);

	this.showAddButton = options.showAddButton || false;

	// ID assignments
	this.categoriesDivId = this.generateChildId('categoriesDiv');
	this.titleDivId = this.generateChildId('titleText');

	//jQuery Selectors
	this.categoriesDiv = $('#' + this.categoriesDivId);

	this.categories = {};
	this.iconIdToName = {};
}

WidgetElementToolbox.prototype = Object.create(WidgetBase.prototype);
WidgetElementToolbox.prototype.constructor = WidgetElementToolbox;

WidgetElementToolbox.prototype.initialize = function(callback) {
	this.update();

	WidgetBase.prototype.initialize.call(this, callback);
};

WidgetElementToolbox.prototype.showAddButton = function() {
	this.setShowAddButton(true);
};

WidgetElementToolbox.prototype.hideAddButton = function() {
	this.setShowAddButton(false);
};

WidgetElementToolbox.prototype.setShowAddButton = function(value) {
	this.showAddButton = Boolean(value);

	this.update();
};

WidgetElementToolbox.prototype.removeAllCategories = function() {
	this.categories = {};
	this.iconIdToName = {};
	this.update();
};

WidgetElementToolbox.prototype.addCategory = function(name, languageObject) {
	var currentWidget = this;

	if (name === undefined) {
		name = 'UNKNOWN';
	}

	if (this.categories[name] !== undefined) {
		return;
	}

	var categoryId = this.generateChildId('category_' + name);
	this.categories[name] = {
		id: categoryId,
		icons: {},
		language: languageObject,
	};

	this.update();
};

WidgetElementToolbox.prototype.removeCategory = function(name) {
	var currentWidget = this;

	if (this.categories[name] === undefined) {
		return;
	}

	delete this.categories[name];

	this.update();
};

WidgetElementToolbox.prototype.addIconToCategory = function(
	categoryName,
	handleName,
	imagePath,
	languageObject,
) {
	if (
		this.categories[categoryName] === undefined ||
		this.categories[categoryName].icons[handleName] !== undefined
	) {
		return;
	}

	var currentWidget = this;
	var iconId = this.generateChildId(categoryName + '_icon_' + handleName);
	this.categories[categoryName].icons[handleName] = {
		id: iconId,
		src: imagePath,
		language: languageObject,
		image: imagePath,
	};

	this.update();
};

WidgetElementToolbox.prototype.update = function() {
	var currentWidget = this;

	this.iconIdToName = {};

	var context = {
		id: this.generateChildId('content'),
		name: getLanguageTag(WidgetElementToolbox, 'name'),
		addLabel: getLanguageTag(WidgetElementToolbox, 'addElementLibrary'),
		addButtonId: this.generateChildId('addButton'),
		showAddButton: this.showAddButton,
		categories: [],
	};

	var k;
	var currentCategory;
	var iconName;
	var currentIcon;

	for (k in this.categories) {
		currentCategory = this.categories[k];

		var contextIcons = [];

		for (iconName in currentCategory.icons) {
			currentIcon = currentCategory.icons[iconName];

			this.iconIdToName[currentIcon.id] = iconName;

			var name = getFromLanguageObject(currentIcon.language, iconName);

			contextIcons.push({
				id: currentIcon.id,
				src: currentIcon.src,
				name: name,
			});
		}

		context.categories.push({
			name: getLanguageTag(WidgetElementToolbox, k),
			id: currentCategory.id,
			icons: contextIcons,
		});
	}

	this.renderTemplate(context, WidgetElementToolbox.name);

	var addButton = $('#' + context.addButtonId);

	addButton.click(function() {
		currentWidget.event('addButtonClicked');
	});

	for (k in this.categories) {
		currentCategory = this.categories[k];

		var iconsToLoad = Object.keys(currentCategory.icons);

		loadIcons();
	}

	function loadIcons() {
		if (iconsToLoad.length <= 0) {
			currentWidget.injectSVGElements();

			return;
		}

		var iconName = iconsToLoad.pop();

		var currentIcon = currentCategory.icons[iconName];

		var elementIconToolboxSrc = currentIcon.src.replace(
			'DesignerView',
			'ElementToolbox',
		);

		var currentIconHandle = $('#' + currentIcon.id);

		var name = getFromLanguageObject(
			currentIcon.language,
			iconName,
		).replace('Embedded', '');

		var elementNameContainerId = currentWidget.generateChildId(
			currentIcon.id + '_nameContainer',
		);

		currentIconHandle.html(`

			<div class="WidgetToolBox-Svg-Container">

				<img src="${elementIconToolboxSrc}" class="_svg-inject" title="${getFromLanguageObject(
			currentIcon.language,
			iconName,
		)}"/>

			</div>

			<div id="${elementNameContainerId}" class="WidgetElementToolbox-element-name-container" data-simplebar>

				<div class="WidgetElementToolbox-element-name-text">
				
					${name}
				
				</div>

			</div>

		`);

		elementNameContainer = $('#' + elementNameContainerId);

		elementNameContainer.off();

		elementNameContainer.on('click', function(e) {
			var element = $(this).find(
				'.WidgetElementToolbox-element-name-text',
			)[0];

			if (
				element.offsetHeight < element.scrollHeight ||
				element.offsetWidth < element.scrollWidth
			) {
				e.stopPropagation();
				e.preventDefault();
			}
		});

		currentIconHandle.parent().off();

		currentIconHandle.parent().click(function(e) {
			currentWidget.event(
				'iconClicked',
				currentWidget.iconIdToName[currentIconHandle[0].id],
			);
		});

		loadIcons();
	}
};

WidgetElementToolbox.prototype.language = {
	'en-US': {
		name: 'Element Toolbox',
		addElementLibrary: 'Add Element',
		ble: 'Bluetooth Low Energy',
		embeddedBasic: 'Embedded Basic',
		imported: 'Imported',
		cloud: 'Cloud',
		nfc: 'NFC',
		wifi: 'Wi-Fi',
		appLogic: 'Application Logic',
		appBLE: 'Application BLE',
		appUI: 'Application Interface',
		cloudAWS: 'Cloud AWS',
		cloudLogic: 'Cloud Logic',
		webthing: 'WebThing',
	},
};
