function ElementControllerEmbeddedFunction(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);
}

ElementControllerEmbeddedFunction.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedFunction.prototype.constructor = ElementControllerEmbeddedFunction;

ElementControllerEmbeddedFunction.prototype.NAME = 'Function';
ElementControllerEmbeddedFunction.prototype.TYPE = 'EmbeddedFunction';
ElementControllerEmbeddedFunction.prototype.HIDDEN = false;

ElementControllerEmbeddedFunction.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedFunction.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedFunction: 'Function',
		},
	},
);
