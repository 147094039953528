function ElementAppExpression(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);
}

ElementAppExpression.prototype = Object.create(ElementAppBase.prototype);
ElementAppExpression.prototype.constructor = ElementAppExpression;

ElementAppExpression.prototype.setValue = function(key, value, callback) {
	this._getProperty('values', function(err, values) {
		values[key] = value;
		this._setProperty('values', values, callback);
	});
};

ElementAppExpression.prototype.assignValues = function(values, callback) {
	if (!(values instanceof Object)) {
		callback.call(this, { type: 'isNotAnObject' });
		return;
	}

	this._getProperty('values', function(err, currentValues) {
		currentValues = Object.assign(currentValues, values);
		this._setProperty('values', currentValues, callback);
	});
};

ElementAppExpression.prototype.setValues = function(values, callback) {
	if (!(values instanceof Object)) {
		callback.call(this, { type: 'isNotAnObject' });
		return;
	}

	this._setProperty('values', values, callback);
	return;
};

ElementAppExpression.prototype.evaluate = function(values, callback) {
	var currentElement = this;

	values = values || {};

	this.assignValues(values, function(err) {
		if (err) {
			callback.call(this, err);
			return;
		}

		this._getProperty('expression', function(err, expression) {
			if (err) {
				callback.call(this, err);
				return;
			}

			this._getProperty('values', function(err, values) {
				codeEvalTest(expression, { values: values }).then(
					function(results) {
						var value = results.data;

						currentElement._setProperty('value', value, function(
							err,
						) {
							currentElement.trigger('evaluated', function(err) {
								var currentBoolean = Boolean(value);

								if (currentBoolean) {
									this.trigger('expressionTrue', callback);
									return;
								} else {
									this.trigger('expressionFalse', callback);
									return;
								}
							});
						});
					},
					function(err) {
						currentElement._triggerError(err, callback);
						return;
					},
				);
			});
		});
	});
};
