function ElementControllerCloudEventStandalone(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudEvent.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);
}

ElementControllerCloudEventStandalone.prototype = Object.create(
	ElementControllerCloudEvent.prototype,
);
ElementControllerCloudEventStandalone.prototype.constructor = ElementControllerCloudEventStandalone;

ElementControllerCloudEventStandalone.prototype.TYPE = 'CloudEventStandalone';
ElementControllerCloudEventStandalone.prototype.VARIANTS = [
	'cloud',
	'standalone',
];
ElementControllerCloudEventStandalone.prototype.COUPLED_TO = null;

ElementControllerCloudEventStandalone.prototype.language = deepAssign(
	{},
	ElementControllerCloudEvent.prototype.language,
	{
		'en-US': {
			CloudEventStandalone: 'Cloud Event',
		},
	},
);
