function ElementControllerDeviceEvent(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('value', null, null, 'none');
	this.addProperty('integration', null, 'default', 'select', [
		'default',
		'ble',
		'lora',
		'custom',
	]);

	this.addTrigger('eventReceived');
}

ElementControllerDeviceEvent.prototype = Object.create(
	ElementControllerCloudBase.prototype,
);
ElementControllerDeviceEvent.prototype.constructor = ElementControllerDeviceEvent;

ElementControllerDeviceEvent.prototype.NAME = 'DeviceEvent';
ElementControllerDeviceEvent.prototype.TYPE = 'DeviceEvent';
ElementControllerDeviceEvent.prototype.HIDDEN = false;
ElementControllerDeviceEvent.prototype.DEFAULT_TRIGGER = 'eventReceived';
ElementControllerDeviceEvent.prototype.DEFAULT_ABILITY = 'trigger';
ElementControllerDeviceEvent.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerDeviceEvent.prototype.COUPLED_TO = 'deviceEvent';
ElementControllerDeviceEvent.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerDeviceEvent.prototype.language = deepAssign(
	{},
	ElementControllerCloudBase.prototype.language,
	{
		'en-US': {
			DeviceEvent: 'Device Event',
			value: 'Value',
			eventReceived: 'Event Received',
			default: 'Default',
			ble: 'BLE',
			lora: 'LoRa',
			custom: 'Custom',
		},
	},
);
