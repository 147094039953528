function WidgetBase(id, api, parentWidget, options) {
	EventListener.call(this);

	this._id = id || null;
	this._api = api || null;
	this._container = $(`#${id}`) || null;
	this._parent = parentWidget;
	this._options = options || {};

	this._children = {};
}

WidgetBase.prototype = Object.create(EventListener.prototype);
WidgetBase.prototype.constructor = WidgetBase;

WidgetBase.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.event('initialized');

	callback.call(this, false);
};

WidgetBase.prototype.remove = function(callback) {
	const currentWidget = this;
	
	currentWidget.removeAllChildrenWidgets(function(err) {
		currentWidget._container.empty();
		currentWidget.event('removed');
		callback.call(currentWidget, false);
	});
};

WidgetBase.prototype.addChildWidget = function(
	widgetConstructor,
	id,
	options,
	callback,
) {
	var currentWidget = this;

	if (this._children[id] !== undefined) {
		callback.call(this, { type: 'idAlreadyUsed' }, null);
		return;
	}

	var newWidget = new widgetConstructor(id, this.getAPI(), this, options);

	this._children[id] = newWidget;

	var initializeTimeout = setTimeout(function() {
		console.error(
			`Initializing widget of type "${widgetConstructor.name}" took to long... are you sure you called the callback?`,
		);
	}, 30000);

	newWidget.initialize(function(err) {
		clearTimeout(initializeTimeout);

		callback.call(currentWidget, err, newWidget);
		return;
	});
};

WidgetBase.prototype.getChildrenIds = function() {
	return Object.keys(this._children);
};

WidgetBase.prototype.getChildWidget = function(idOrChildId) {
	return (
		this._children[idOrChildId] ||
		this._children[this.generateChildId(idOrChildId)] ||
		null
	);
};

WidgetBase.prototype.removeChildWidget = function(id, callback) {
	var currentWidget = this;

	if (id === null || id === undefined) {
		callback.call(this, false);
		return;
	}

	if (typeof id === 'object' && id.getId && typeof id.getId === 'function') {
		id = id.getId();
	}

	if (this._children[id] === undefined) {
		callback.call(this, { type: 'noSuchWidget' });
		return;
	}

	var removingTimeout = setTimeout(function() {
		if (widgetConstructor && widgetConstructor.name) {
			console.error(
				`Removing widget of type "${widgetConstructor.name}" took to long... are you sure you called the callback?`,
			);
		}
	}, 30000);

	this._children[id].remove(function(err) {
		clearTimeout(removingTimeout);

		delete currentWidget._children[id];

		currentWidget.event('childRemoved', { id: id });

		callback.call(currentWidget, err);
		return;
	});
};

WidgetBase.prototype.removeAllChildrenWidgetsExcept = function() {
	var currentWidget = this;
	var callback = arguments[arguments.length - 1];
	var exceptionListLength = arguments.length - 1;

	if (typeof callback !== 'function') {
		callback = function() {};
		exceptionListLength = arguments.length;
	}

	var childrenIds = Object.keys(this._children);

	function _removeAllChildrenWidgetsExceptHelper() {
		if (childrenIds.length <= 0) {
			callback.call(currentWidget, false);
			return;
		}

		var currentChildId = childrenIds.shift();

		var remove = true;

		for (var i = 0; i < exceptionListLength; i++) {
			if (currentChildId === arguments[i]) {
				remove = false;
				break;
			}
		}

		if (remove) {
			currentWidget.removeChildWidget(currentChildId, function(err) {
				_removeAllChildrenWidgetsExceptHelper();
			});
		} else {
			_removeAllChildrenWidgetsExceptHelper();
		}
	}

	_removeAllChildrenWidgetsExceptHelper();
};

WidgetBase.prototype.removeAllChildrenWidgets = function(callback) {
	var currentWidget = this;

	var childrenIds = Object.keys(this._children);

	function _removeAllChildrenWidgetsHelper() {
		if (childrenIds.length <= 0) {
			callback.call(currentWidget, false);
			return;
		}

		var currentChildId = childrenIds.shift();

		currentWidget.removeChildWidget(currentChildId, function(err) {
			_removeAllChildrenWidgetsHelper();
		});
	}

	_removeAllChildrenWidgetsHelper();
};

WidgetBase.prototype.hide = function() {
	this.getContainer().hide();
	
	this.event('hidden');
};

WidgetBase.prototype.show = function() {
	this.getContainer().show();
	
	this.event('shown');
};

WidgetBase.prototype.setVisible = function(value) {
	if (value) {
		this.show();
	} else {
		this.hide();
	}
};

WidgetBase.prototype.isVisible = function() {
	return Boolean(this.getContainer().is(':visible'));
};

WidgetBase.prototype.toggleVisible = function() {
	this.setVisible(!this.isVisible());
};

WidgetBase.prototype.getId = function() {
	return this._id;
};

WidgetBase.prototype.generateChildId = function(postfix) {
	return this._id + '_' + safeSelectorString(postfix);
};

WidgetBase.prototype.getCurrentUser = function() {
	return this.getMainContainer().getCurrentUser();
};

WidgetBase.prototype.getAPI = function() {
	return this._api;
};

WidgetBase.prototype.getApiV2 = function() {
	return this.getMainContainer()._apiv2;
};

WidgetBase.prototype.getContainer = function() {
	return this._container;
};

WidgetBase.prototype.getOptions = function() {
	return this._options;
};

WidgetBase.prototype.getParent = function() {
	return this._parent;
};

WidgetBase.prototype.getLanguageTag = function(tag) {
	return getLanguageTag(this.constructor, tag);
};

WidgetBase.prototype.getMainContainer = function() {
	if (this._parent === null) {
		return this;
	}

	return this._parent.getMainContainer();
};

WidgetBase.prototype.getConfiguration = function() {
	return (
		this.getMainContainer().getGlobalConfig()[this.constructor.name] || {}
	);
};

WidgetBase.prototype.getTemplateHtml = function(context, templateName) {
	var currentWidget = this;

	templateName = templateName || this.constructor.name;

	if ($.handlebarTemplates[templateName] === undefined) {
		console.error(
			`Failed to find a template for "${this.constructor.name}"!`,
		);
		return;
	}

	var html = $.handlebarTemplates[templateName](context);
	return html;
};

WidgetBase.prototype.renderTemplate = function(
	context,
	templateName,
	container,
) {
	var currentWidget = this;

	templateName = templateName || this.constructor.name;
	container = container || this.getContainer();

	if ($.handlebarTemplates[templateName] === undefined) {
		console.error(
			`Failed to find a template for "${this.constructor.name}"!`,
		);
		return;
	}

	var html = $.handlebarTemplates[templateName](context);
	container.html(html);

	var imageElements = container.find('img');

	currentWidget.processImageTooltips(imageElements);
	currentWidget.injectSVGElements();
};

WidgetBase.prototype.hasPermission = function(callback) {
	//This is a function to check if the current user
	//has permission to use this widget.

	callback.call(this, false, true);
};

WidgetBase.prototype.processImageTooltips = function(imageElements) {
	var currentWidget = this;

	for (var i = 0; i < imageElements.length; i++) {
		var currentImageElement = imageElements[i];
		if (currentImageElement.getAttribute('title')) {
			currentImageElement.title = currentWidget.getLanguageTag.call(
				currentWidget,
				currentImageElement.getAttribute('title'),
			);
		}
	}
};

WidgetBase.prototype.isValidHTML = function(html) {
	var div = document.createElement('div');
	div.innerHTML = html;
	return div.innerHTML === html;
};

WidgetBase.prototype.escapeHTML = function(html) {
	return html.replace(/</g, '&lt;').replace(/>/g, '&gt;');
};

WidgetBase.prototype.injectSVGElements = function() {
	var currentWidget = this;

	var injectElements = document.getElementsByClassName('_svg-inject');

	if (injectElements.length > 0) {
		currentWidget.processSvgInjectElement(injectElements[0], function(err) {
			window.requestAnimationFrame(function() {
				currentWidget.injectSVGElements();
			});
		});
	}
};

WidgetBase.prototype.processSvgInjectElement = function(
	svgInjectElement,
	callback,
) {
	var currentWidget = this;

	callback = callback || function() {};

	var tooltip,
		childNodes,
		titleElement = null;
	svgInjectElement.classList.remove('_svg-inject');

	if (svgInjectElement.getAttribute('src') === '') {
		return;
	}

	//NOTE: This check won't work if we end up base64 encoding an svg into the app
	//	which shouldn't be done in practice for svg's we need injected for styling
	if (
		!svgInjectElement.src
			.trim()
			.toLowerCase()
			.endsWith('.svg')
	) {
		return;
	}

	tooltip = svgInjectElement.getAttribute('title') || null;

	SVGInjector(
		svgInjectElement,
		{
			each: function(svgElement) {
				if (tooltip) {
					childNodes = svgElement.childNodes;

					if (!childNodes) {
						return;
					}

					for (var i = 0; i < childNodes.length; i++) {
						var currentChild = childNodes[i];

						if (currentChild.constructor === SVGTitleElement) {
							titleElement = currentChild;
							titleElement.textContent = tooltip;
							break;
						}
					}

					if (titleElement === null) {
						titleElement = document.createElementNS(
							'http://www.w3.org/2000/svg',
							'title',
						);
						titleElement.textContent = tooltip;
						svgElement.insertBefore(
							titleElement,
							svgElement.firstChild,
						);
					}
				}
			},
		},
		function(err) {
			callback.call(currentWidget, err);
			return;
		},
	);
};

// This function will help get the current organization id.
WidgetBase.prototype.getOrganizationId = function(callback) {
	
	const currentWidget = this;
	const api = currentWidget.getApiV2().apis;
	
	this.asyncGetOrganizationId()
	.then((orgId) => {
		callback.call(currentWidget, false, orgId);
		return;
	})
	.catch((err) => {
		callback.call(currentWidget, err, null);
		return;
	});
	
};

WidgetBase.prototype.asyncGetOrganizationId = async function() {
	const currentWidget = this;
	const api = currentWidget.getApiV2().apis;
	
	let currentOrgId = (getHashCommand() || {}).org || null;
	
	if(!currentOrgId) {
		let user = await api.auth.getCurrentUser();
		currentOrgId = user.organizationId || null;
	}
	
	return currentOrgId;
};

// This function will help get the current organization model object.
WidgetBase.prototype.getOrganizationContext = function(callback) {
	
	const currentWidget = this;

	this.asyncGetOrganizationContext()
	.then((org) => {
		callback.call(currentWidget, false, org);
		return;
	})
	.catch((err) => {
		callback.call(currentWidget, err, null);
	});
};

// This function will help get the current organization model object.
WidgetBase.prototype.asyncGetOrganizationContext = async function() {
	
	const currentWidget = this;
	const api = currentWidget.getApiV2().apis;

	const currentOrgId = await this.asyncGetOrganizationId();
	
	return api.organizations.getOrganization({id:currentOrgId});
};

WidgetBase.prototype.ICON = './Resources/icons/Help.svg';

WidgetBase.prototype.language = deepAssign(
	{},
	EventListener.prototype.language,
	{
		'en-US': {
			name: 'Base',
			save: 'Save',
			discard: 'Discard',
			confirm: 'Confirm',
			dismiss: 'Cancel',
			close: 'Close',
			title: 'Title',
			systemAdmin: 'System Administrator', // These will be used all over the place, so put them in WidgetBase
			administrator: 'Administrator',
			manager: 'Manager',
			technician: 'Technician',
			basicUser: 'Basic User',
			integrator: 'Integrator',
			developer: 'Developer',
		},
	},
);

WidgetBase.prototype.$_SetupWidgetTest = function(options, callback) {
	callback = callback || options;

	if (typeof callback !== 'function') {
		return;
	}

	if (options === callback) {
		options = {};
	}

	var currentWidget = this;

	if (currentWidget.getContainer() === undefined) {
		$('body').append(`<div id="${currentWidget.constructor.name}"></div>`);
		var api = new CloudApi();

		api.getRoutesFromServer(function(err) {
			currentWidget = new currentWidget.constructor(
				currentWidget.constructor.name,
				api,
				_mainContainer,
				options,
			);

			currentWidget.initialize(function(err) {
				callback.call(this, err, currentWidget);
			});
		});
		return;
	}

	callback.call(currentWidget, false, currentWidget);
	return;
};

WidgetBase.prototype.$_$ = function(done) {
	this.$_SetupWidgetTest(function(err, currentWidget) {
		if (!currentWidget.getConfiguration()) {
			done({
				expected: 'exists',
				got: currentWidget.getConfiguration(),
				message: 'Should have returned a configuration object',
			});
		}

		currentWidget.show();

		if (currentWidget.isVisible() !== true) {
			done({
				expected: true,
				got: currentWidget.isVisible(),
				message: 'Shoud show the widget',
			});
		}

		currentWidget.hide();

		if (currentWidget.isVisible() !== false) {
			done({
				expected: false,
				got: currentWidget.isVisible(),
				message: 'Shoud hide the widget',
			});
		}

		currentWidget.show();

		if (currentWidget.isVisible() !== true) {
			done({
				expected: true,
				got: currentWidget.isVisible(),
				message: 'Shoud show the widget again',
			});
		}

		currentWidget.remove(function(err) {
			if (currentWidget._container.html().trim() !== '') {
				done({
					expected: '',
					got: currentWidget._container.html().trim(),
					message: 'Shoud have removed all dom for the widget',
				});
				return;
			}

			done(false);
		});
	});
};
