function ElementControllerAppWebIO(parentController, parentPlane, elementName) {
	ElementControllerAppLogicBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('value', null, null, 'none', null, null);
	this.addProperty('path', null, 'http://localhost/', 'text', null, null);
	this.addProperty('dataType', null, 'text', 'select', [
		'xml',
		'html',
		'script',
		'json',
		'text',
	]);

	this.addAbility('get', []);
	this.addAbility('post', [{ name: 'data', validator: null }]);
	this.addAbility('setPath', [{ name: 'path', validator: null }]);

	this.addTrigger('getResponded');
	this.addTrigger('postResponded');
}

ElementControllerAppWebIO.prototype = Object.create(
	ElementControllerAppLogicBase.prototype,
);
ElementControllerAppWebIO.prototype.constructor = ElementControllerAppWebIO;

ElementControllerAppWebIO.prototype.NAME = 'WebIO';
ElementControllerAppWebIO.prototype.TYPE = 'AppWebIO';
ElementControllerAppWebIO.prototype.HIDDEN = false;
ElementControllerAppWebIO.prototype.DEFAULT_TRIGGER = 'getResponded';
ElementControllerAppWebIO.prototype.DEFAULT_ABILITY = 'get';
ElementControllerAppWebIO.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerAppWebIO.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerAppWebIO.prototype.language = {
	'en-US': {
		AppWebIO: 'Web IO',
		value: 'Value',
		getResponded: 'GET Responded',
		postResponded: 'POST Responded',
	},
};
