$.handlebarTemplates = {};
$.handlebarTemplates["WidgetPanelBase"] = Handlebars.compile(`<div class="WidgetPanelBase-Shadow">
	<div class="WidgetPanelBase-Top">

		<div class="WidgetPanelBase-TopBar">
		
			<div id="{{titleId}}" class="WidgetPanelBase-Title standard-local-header-h3 NoSelect">
				{{labelTitle}}
			</div>
		
			<div class="WidgetPanelBase-ButtonsContainer">
			{{#each buttons}}
			
				<div id="{{id}}" class="WidgetPanelBase-ButtonContainer">
					<img class="_svg-inject WidgetPanelBase-ButtonImage" src="{{imageSrc}}" title="menu"></img>
				</div>
				
				<div id="{{menuContainerId}}" class="WidgetPanelBase-ButtonMenuContainer">
				</div>
				
			{{/each}}
			</div>
		
		</div>
		
		<div id="{{panelContainerId}}" class="WidgetPanelBase-Container">
		</div>

	</div>
</div>
`);
$.handlebarTemplates["WidgetLocationControls"] = Handlebars.compile(`<div class="WidgetLocationControls-Top">
	{{#each entries}}
	
	<div data-controlname="{{name}}" class="ControlButton-Entry NoSelect">
		<img class="ControlButton-Image _svg-inject" src="{{imageSrc}}" title="{{label}}"></img>
		<div class="ControlButton-Label"><span>{{label}}</span></div>
	</div>
	
	{{/each}}
</div>
`);
$.handlebarTemplates["WidgetButtonsFooter"] = Handlebars.compile(`<div class="WidgetButtonsFooter-Top">

	<div id='{{aboveFooterContainerId}}' class="WidgetButtonsFooter-Container">
	</div>

	<div class="WidgetButtonsFooter-ButtonsContainer">

		{{#each buttons}}
		<button data-buttonvalue='{{JSONValue}}'
			class="WidgetButtonsFooter-Button btn{{#each classes}} {{this}}{{/each}}">
			{{label}}
		</button>
		{{/each}}

	</div>

</div>`);
$.handlebarTemplates["WidgetDeviceDataSelect"] = Handlebars.compile(`<div class='WidgetDeviceDataSelect-Top'>
	<div class='WidgetDeviceDataSelect-Header'>
		<div id="{{labelId}}" class='WidgetDeviceDataSelect-Label'></div>
	</div>

	<div class='WidgetDeviceDataSelect-SelectPage WidgetTable-Alt-SelectMenu'>
		<div
			id="{{deviceSelectId}}"
			class='WidgetDeviceDataSelect-MenuContainer'
		>
		</div>
	</div>

	<div class='WidgetDeviceDataSelect-SelectPage'>
		<div
			id="{{storageSelectId}}"
			class='WidgetDeviceDataSelect-MenuContainer'
		>
		</div>
	</div>

	<div class='WidgetDeviceDataSelect-SelectPage'>
		<div
			id="{{variableSelectId}}"
			class='WidgetDeviceDataSelect-MenuContainer'
		>
		</div>
	</div>

	<div id="{{footerContainerId}}"></div>
</div>`);
$.handlebarTemplates["WidgetSettingsForm"] = Handlebars.compile(`<div class="WidgetSettingsForm-Top">

	<div class="WidgetSettingsForm-InputsContainer">
	
		{{#each fields}}
		<div class="WidgetSettingsForm-InputContainer" title="{{description}}">
			{{#switch type}}
				
				<div class="WidgetSettingsForm-FieldLabelAndToggle-Container">
					<div class="WidgetSettingsForm-Field-Toggle-Container" {{#if_eq toggleable true}}style="display: block" {{else}} style="display: none"{{/if_eq}}>
						<label class="WidgetSettingsForm-Field-Toggle-Label standard-checkbox-container">
						<input class="WidgetSettingsForm-Field-Toggle-Checkbox" type="checkbox" data-fieldtogglelabel="{{name}}" checked>
						<span class="WidgetSettingsForm-Field-Toggle-Span standard-checkbox-checkmark"></span>
					</label>

					</div>
					<div class="WidgetSettingsForm-Label-Container">
						{{!-- Fallback to name if the label is not found --}}
						{{#if label}}
							<label data-settingsformlabel="{{name}}" class="WidgetSettingsForm-LabelContainer standard-label">
								{{label}}
							</label>
						{{else}}
							<label data-settingsformlabel="{{name}}" class="WidgetSettingsForm-LabelContainer standard-label">
								{{name}}
							</label>
						{{/if}}
					</div>
				</div>

				{{#case 'text'}}

					<input data-settingsformname="{{name}}" data-settingsformtype="{{type}}" type="text" class="WidgetSettingsForm-Input WidgetSettingsForm-InputText form-control standard-input-text" value="{{value}}"></input>

				{{/case}}
				
				{{#case 'ssid'}}
				
					<input data-settingsformname="{{name}}" data-settingsformtype="{{type}}" type="text" autocorrect="off" autocapitalize="none" class="WidgetSettingsForm-Input WidgetSettingsForm-InputText form-control standard-input-text" value="{{value}}"></input>
				
				{{/case}}
				
				{{#case 'username'}}
				
					<input data-settingsformname="{{name}}" data-settingsformtype="{{type}}" type="text" autocorrect="off" autocapitalize="none" name="username" class="WidgetSettingsForm-Input WidgetSettingsForm-InputText form-control standard-input-text" value="{{value}}"></input>
				
				{{/case}}
				
				{{#case 'password'}}
				
					<input data-settingsformname="{{name}}" data-settingsformtype="{{type}}" type="password" name="psw" class="WidgetSettingsForm-Input WidgetSettingsForm-InputText form-control standard-input-text" value="{{value}}"></input>
					
				{{/case}}
				
				{{#case 'number'}}

					<input data-settingsformname="{{name}}" data-settingsformtype="{{type}}" type="number" class="WidgetSettingsForm-Input WidgetSettingsForm-InputNumber form-control standard-input-text" min="{{min}}" max="{{max}}" value="{{value}}"></input>
				
				{{/case}}

				
				{{#case 'code'}}
					<div data-settingsformname="{{name}}" data-settingsformtype="{{type}}" class="WidgetSettingsForm-InputCode"></div>
				{{/case}}
				
				{{#case 'bounds'}}
				
					<div data-settingsformname="{{name}}" data-settingsformtype="{{type}}" class="WidgetSettingsForm-Input WidgetSettingsForm-InputNumber">
						<span class="WidgetSettingsForm-InputNumberLabel">Min:</span>
						<input data-settingssubname="min" type="number" class="WidgetSettingsForm-Input WidgetSettingsForm-InputNumber standard-input-text" min="{{min}}" max="{{max}}" value="{{value.min}}"></input>
						<span class="WidgetSettingsForm-InputNumberLabel">Max:</span>
						<input data-settingssubname="max" type="number" class="WidgetSettingsForm-Input WidgetSettingsForm-InputNumber standard-input-text" min="{{min}}" max="{{max}}" value="{{value.max}}"></input>
					</div>
				
				{{/case}}
				
				{{#case 'dateRangePicker'}}
					
					<input data-settingsformname="{{name}}" data-settingsformtype="{{type}}" type="text" class="WidgetSettingsForm-Input WidgetSettingsForm-InputDateRangePicker form-control standard-input-text" data-value="{{valueJSON}}"></input>
					
				{{/case}}
				
				{{#case 'jsonEditor'}}
				
					<div data-settingsformname="{{name}}" data-settingsformtype="{{type}}" style="width: {{options.width}}; height: {{options.height}}; padding:15px; margin-bottom: 40px;" data-value="{{valueJSON}}"></div>
				
				{{/case}}
				
				{{#case 'color'}}
				
				{{/case}}
				
				{{#case 'colorSwatch'}}
					<div data-settingsformname="{{name}}" data-settingsformtype="{{type}}" class="WidgetSettingsForm-Input WidgetSettingsForm-InputColorSwatch">
					
						{{#if_eq value "red"}}
						<div data-value="red" selected="selected" class="WidgetSettingsForm-ColorSwatch WidgetSettingsForm-ColorSwatch-Red">
						{{else}}
						<div data-value="red" class="WidgetSettingsForm-ColorSwatch WidgetSettingsForm-ColorSwatch-Red">
						{{/if_eq}}
							<img src="./Resources/icons/Selection.svg" class="_svg-inject WidgetSettingsForm-ColorSwatch-Selector" title="Red"></img>
						</div>
						
						{{#if_eq value "orange"}}
						<div data-value="orange" selected="selected" class="WidgetSettingsForm-ColorSwatch WidgetSettingsForm-ColorSwatch-Orange">
						{{else}}
						<div data-value="orange" class="WidgetSettingsForm-ColorSwatch WidgetSettingsForm-ColorSwatch-Orange">
						{{/if_eq}}
							<img src="./Resources/icons/Selection.svg" class="_svg-inject WidgetSettingsForm-ColorSwatch-Selector" title="Orange"></img>
						</div>
						
						{{#if_eq value "yellow"}}
						<div data-value="yellow" selected="selected" class="WidgetSettingsForm-ColorSwatch WidgetSettingsForm-ColorSwatch-Yellow">
						{{else}}
						<div data-value="yellow" class="WidgetSettingsForm-ColorSwatch WidgetSettingsForm-ColorSwatch-Yellow">
						{{/if_eq}}
							<img src="./Resources/icons/Selection.svg" class="_svg-inject WidgetSettingsForm-ColorSwatch-Selector" title="Yellow"></img>
						</div>
						
						{{#if_eq value "green"}}
						<div data-value="green" selected="selected" class="WidgetSettingsForm-ColorSwatch WidgetSettingsForm-ColorSwatch-Green">
						{{else}}
						<div data-value="green" class="WidgetSettingsForm-ColorSwatch WidgetSettingsForm-ColorSwatch-Green">
						{{/if_eq}}
							<img src="./Resources/icons/Selection.svg" class="_svg-inject WidgetSettingsForm-ColorSwatch-Selector" title="Green"></img>
						</div>
						
						{{#if_eq value "blue"}}
						<div data-value="blue" selected="selected" class="WidgetSettingsForm-ColorSwatch WidgetSettingsForm-ColorSwatch-Blue">
						{{else}}
						<div data-value="blue" class="WidgetSettingsForm-ColorSwatch WidgetSettingsForm-ColorSwatch-Blue">
						{{/if_eq}}
							<img src="./Resources/icons/Selection.svg" class="_svg-inject WidgetSettingsForm-ColorSwatch-Selector" title="Blue"></img>
						</div>

						{{#if_eq value "indigo"}}
						<div data-value="indigo" selected="selected" class="WidgetSettingsForm-ColorSwatch WidgetSettingsForm-ColorSwatch-Indigo">
						{{else}}
						<div data-value="indigo" class="WidgetSettingsForm-ColorSwatch WidgetSettingsForm-ColorSwatch-Indigo">
						{{/if_eq}}
							<img src="./Resources/icons/Selection.svg" class="_svg-inject WidgetSettingsForm-ColorSwatch-Selector" title="Indigo"></img>
						</div>
						
						{{#if_eq value "purple"}}
						<div data-value="purple" selected="selected" class="WidgetSettingsForm-ColorSwatch WidgetSettingsForm-ColorSwatch-Purple">
						{{else}}
						<div data-value="purple" class="WidgetSettingsForm-ColorSwatch WidgetSettingsForm-ColorSwatch-Purple">
						{{/if_eq}}
							<img src="./Resources/icons/Selection.svg" class="_svg-inject WidgetSettingsForm-ColorSwatch-Selector" title="Purple"></img>
						</div>
						
						{{#if_eq value "gray"}}
						<div data-value="gray" selected="selected" class="WidgetSettingsForm-ColorSwatch WidgetSettingsForm-ColorSwatch-Gray">
						{{else}}
						<div data-value="gray" class="WidgetSettingsForm-ColorSwatch WidgetSettingsForm-ColorSwatch-Gray">
						{{/if_eq}}
							<img src="./Resources/icons/Selection.svg" class="_svg-inject WidgetSettingsForm-ColorSwatch-Selector" title="Gray"></img>
						</div>
						

						{{#if options.hideUnset}}

						{{else}}
						{{#if_eq value "unset"}}
						<div data-value="unset" selected="selected" class="WidgetSettingsForm-ColorSwatch WidgetSettingsForm-ColorSwatch-Unset">
						{{else}}
						<div data-value="unset" class="WidgetSettingsForm-ColorSwatch WidgetSettingsForm-ColorSwatch-Unset">
						{{/if_eq}}
							<img src="./Resources/icons/Selection.svg" class="_svg-inject WidgetSettingsForm-ColorSwatch-Selector" title="Unset"></img>
						</div>

						{{/if}}
					
					</div>
				{{/case}}
				
				{{#case 'iconSelect'}}
				
				{{/case}}
				
				{{#case 'font'}}
				
				{{/case}}
				
				{{#case 'image'}}
				
				{{/case}}
				
				{{#case 'radio'}}
				
					<div data-settingsformname="{{name}}" data-settingsformtype="{{type}}" class="WidgetSettingsForm-InputRadioContainer">

						{{#each options}}
							{{#if_eq ../value value}}

							<label class="standard-radio-container">
								<input type="radio" name="{{../name}}" value="{{value}}" class="WidgetSettingsForm-InputRadio" checked="checked">
								<span class="standard-radio-checkmark"></span>
							</label>

							{{else}}

							<div class="WidgetSettingsForm-InputRadioWrapper">
								<label class="standard-radio-container">
									<input type="radio" name="{{../name}}" value="{{value}}" class="WidgetSettingsForm-InputRadio">
									<span class="standard-radio-checkmark"></span>
								</label>

							{{/if_eq}}
								<div class="WidgetSettingsForm-InputRadioLabel">{{label}}</div>
							</div>
						{{/each}}
					
					</div>
				
				
				{{/case}}
				
				{{#case 'select'}}
				
					<select data-settingsformname="{{name}}" data-settingsformtype="{{type}}" class="WidgetSettingsForm-Input WidgetSettingsForm-InputSelect form-control standard-input-select" >
						
						{{#each options}}
						
						{{#if_eq ../value value}}
						<option selected="selected" value="{{valueJSON}}" class="WidgetSettingsForm-InputSelectOption">{{label}}</option>
						{{else}}
						<option value="{{valueJSON}}" class="WidgetSettingsForm-InputSelectOption">{{label}}</option>
						{{/if_eq}}
						
						{{/each}}
						
					</select>
				
				{{/case}}

				{{#case 'state'}}
				
					<select data-settingsformname="{{name}}" data-settingsformtype="{{type}}" class="WidgetSettingsForm-Input WidgetSettingsForm-InputSelect form-control standard-input-select" >
						
						{{#each options}}
						
						{{#if_eq ../value value}}
						<option selected="selected" value="{{valueJSON}}" class="WidgetSettingsForm-InputSelectOption">{{label}}</option>
						{{else}}
						<option value="{{valueJSON}}" class="WidgetSettingsForm-InputSelectOption">{{label}}</option>
						{{/if_eq}}
						
						{{/each}}
						
					</select>
				
				{{/case}}

				{{#case 'country'}}
				
					<select data-settingsformname="{{name}}" data-settingsformtype="{{type}}" class="WidgetSettingsForm-Input WidgetSettingsForm-InputSelect form-control standard-input-select" >
						
						{{#each options}}
						
						{{#if_eq ../value value}}
						<option selected="selected" value="{{valueJSON}}" class="WidgetSettingsForm-InputSelectOption">{{label}}</option>
						{{else}}
						<option value="{{valueJSON}}" class="WidgetSettingsForm-InputSelectOption">{{label}}</option>
						{{/if_eq}}
						
						{{/each}}
						
					</select>
				
				{{/case}}

				{{#case 'geolocation' }}
					<div data-settingsformname="{{name}}" data-settingsformtype="{{type}}"
						class="WidgetSettingsForm-Geolocation-MapContainer"></div>
				{{/case}}

				{{#case 'checkbox'}}
				
					{{#if value}}
					<input data-settingsformname="{{name}}" data-settingsformtype="{{type}}" type="checkbox" class="WidgetSettingsForm-Input WidgetSettingsForm-InputCheckbox" checked="{{value}}"></input>
					{{else}}
					<input data-settingsformname="{{name}}" data-settingsformtype="{{type}}" type="checkbox" class="WidgetSettingsForm-Input WidgetSettingsForm-InputCheckbox"></input>
					{{/if}}
				
				{{/case}}

				{{#case 'toggle'}}
				
					{{#if value }}
					{{!-- Assign checked property to <input> element --}}
					<label class="standard-switch">
						<input data-settingsformname="{{name}}" data-settingsformtype="{{type}}" type="checkbox" data-toggle="toggle"
							data-onstyle="info" data-size="mini" class="WidgetSettingsForm-Input WidgetSettingsForm-InputCheckbox"
							checked />
						<div class="standard-slider round"></div>
					</label>
					{{else}}
					<label class="standard-switch">
						<input data-settingsformname="{{name}}" data-settingsformtype="{{type}}" type="checkbox" data-toggle="toggle"
							data-onstyle="info" data-size="mini" class="WidgetSettingsForm-Input WidgetSettingsForm-InputCheckbox" />
						<div class="standard-slider round"></div>
					</label>
					{{/if}}
				
				{{/case}}
				
				{{#case 'button'}}
				
				{{/case}}
				
				{{#case 'deviceSelect' 'deviceDataStoreSelect' 'deviceVariableSelect' 'deviceLastValuesSelect'}}
				
					<div data-settingsformname="{{name}}" data-settingsformtype="{{type}}" data-value="{{valueJSON}}" class="WidgetSettingsForm-Input WidgetSettingsForm-InputDeviceSelect">
					
						<div class="WidgetSettingsForm-DeviceDataSelectInfo">
						
							{{#if value.deviceData}}
							<div>
								<label class="WidgetSettingsForm-DeviceDataSelectLabel secondary-label">{{../deviceNameLabel}}</label>
								<div class="WidgetSettingsForm-DeviceDataSelectInput is-family-secondary">{{value.deviceData.name}}</div>
							</div>
							
							<div>
								<label class="WidgetSettingsForm-DeviceDataSelectLabel secondary-label">{{../deviceUuidLabel}}</label>
								<div class="WidgetSettingsForm-DeviceDataSelectInput is-family-secondary">{{value.deviceData.uuid}}</div>
							</div>
							
							{{/if}}
							
							{{#if value.storageName}}
							<div>
								<label class="WidgetSettingsForm-DeviceDataSelectLabel secondary-label">{{../storageNameLabel}}</label>
								<div class="WidgetSettingsForm-DeviceDataSelectInput is-family-secondary">{{value.storageName}}</div>
							</div>
							
							{{#if value.variables}}
							{{#each value.variables}}
							<div>
								<label class="WidgetSettingsForm-DeviceDataSelectLabel secondary-label">{{@key}}</label>
								<div class="WidgetSettingsForm-DeviceDataSelectInput is-family-secondary">{{this}}</div>
							</div>
							{{/each}}
							{{/if}}
							
							{{/if}}
							
							
						</div>
						
						<div>
							<button class="btn btn-link">{{../selectLabel}}</button>
						</div>
						
						<div class="WidgetSettingsForm-DeviceDataSelectContainer">
						</div>
						
					</div>
				
				{{/case}}
				
			{{/switch}}
		
		</div>
		{{/each}}
	
	</div>

	<div class="WidgetSettingsForm-InvalidMessage">
		{{invalidMessageLabel}}
	</div>
	
	<div id="{{footerContainerId}}"></div>
	
</div>
`);
$.handlebarTemplates["WidgetOrganizationInfo"] = Handlebars.compile(`<div class='WidgetOrganizationInfo-Top'>
	<div
		id="{{dataStorageUsageId}}"
		class='WidgetOrganizationInfo-dataStorageUsageContainer'
	>
	</div>
</div>
`, {"noEscape":true});
$.handlebarTemplates["WidgetOrganizations"] = Handlebars.compile(`<div class="WidgetOrganizations-Top">
	<div class="WidgetOrganizations-Container">
		<div class="WidgetOrganizations-SettingsContainer">
			<form autocomplete="off" class="WidgetOrganizations-InformationForm">

				<div class="WidgetOrganizations-LabelAndInput">
					<label class="WidgetOrganizations-Label standard-label">Name</label>
					<input id="{{settingsNameId}}" type="text" value="{{currentOrg.name}}"
						class="WidgetOrganizations-Input form-control standard-input-text"></input>
				</div>
				<div class="WidgetOrganizations-LabelAndInput">
					<label class="WidgetOrganizations-Label standard-label">Description</label>
					<input id="{{settingsDescriptionId}}" type="text" value="{{currentOrg.description}}"
						class="WidgetOrganizations-Input form-control standard-input-text"></input>
				</div>
				<button id="{{saveButtonId}}" class="btn btn-primary" disabled
					{{#unless canManageOrgs}}hidden{{/unless}}>Save</button>
			</form>
			<div class="WidgetOrganizations-SetImageContainer">
				{{#if canManageOrgs}}
				<button id="{{removeImageId}}" class="btn btn-link pb-0">
					Remove Image
				</button>
				<div class="WidgetOrganizations-ImageForm">
					{{else}}
					<div class="WidgetOrganizations-ImageForm pt-3">
						{{/if}}

						<img id="{{imageIconId}}" src="./Resources/icons/SetImage.svg"
							class="WidgetOrganizations-Image" title="orgImage">
						</img>

					</div>

					<input id="{{imageInputId}}" type="file" accept="image/*" capture="camera" display="none"
						class="WidgetOrganizations-ImageFileInput">
				</div>

			</div>

			<div class="WidgetOrganizations-TableLabelAndControls">
				<div class="WidgetOrganizations-TableControlsLeft">
					{{#if canManageOrgs}}
					<div id="{{addButtonId}}" class="ControlButton-Entry-Non-Responsive NoSelect">
						<img class="ControlButton-Image _svg-inject" src="./Resources/icons/Add.svg"
							title="Add Organization"></img>
						<div class="ControlButton-Label"><span>{{addOrganization}}</span></div>
					</div>
					{{/if}}
					{{#if canManageOrgs}}
					<div id="{{moveButtonId}}" class="ControlButton-Entry-Non-Responsive NoSelect">
						<img class="ControlButton-Image _svg-inject" src="./Resources/icons/MoveItem.svg"
							title="Move Organization"></img>
						<div class="ControlButton-Label"><span>{{moveOrganization}}</span></div>
					</div>
					{{/if}}
					{{#if canManageOrgs}}
					<div id="{{deleteButtonId}}" class="ControlButton-Entry-Non-Responsive NoSelect">
						<img class="ControlButton-Image _svg-inject" src="./Resources/icons/Trash.svg"
							title="Delete Organization"></img>
						<div class="ControlButton-Label"><span>{{deleteOrganization}}</span></div>
					</div>
					{{/if}}
				</div>

				<div class="WidgetOrganizations-TableControlsRight">
					{{#if canSeeOrgs}}
					<div class="WidgetOrganizations-SelectInputContainer">
						<label class="standard-checkbox-container">
							<span class="WidgetOrganizations-ShowChildrenLabel ControlButton-Label-No-Collapse">
								{{showSubOrganizations}}
							</span>
							<input id="{{showChildrenCheckboxId}}" type="checkbox">
							<span class="standard-checkbox-checkmark"></span>
						</label>
					</div>
					{{/if}}
					<div id="{{searchContainerId}}"></div>
				</div>
			</div>
			<div id="{{tableContainerId}}" class="WidgetOrganizations-TableContainer">
			</div>

		</div>
	</div>
`, {"noEscape":true});
$.handlebarTemplates["WidgetOrganizations_OrganizationEntry"] = Handlebars.compile(`
<div class="WidgetOrganizations-OrgEntry">
	<div class="WidgetOrganizations-ImageContainer">
		<img class="WidgetOrganizations-OrgImage" src="{{imgSrc}}"></img>
	</div>
</div>
`, {"noEscape":true});
$.handlebarTemplates["WidgetOrganizationPicker"] = Handlebars.compile(`<div class='WidgetOrganizationPicker-Top'>
	<div class='WidgetSettingsForm-InputsContainer'>
		<div id='WidgetOrganizationPicker-Breadcrumb'></div>
		<div id='WidgetOrganizationPicker-OrgList'></div>
	</div>
	<div id='WidgetOrganizationPicker-Footer'></div>
</div>`, {"noEscape":true});
$.handlebarTemplates["WidgetOrganizationPickerBreadcrumb"] = Handlebars.compile(`<div class="WidgetOrganizationPicker-Breadcrumb">
	<nav aria-label="breadcrumb" class="navbar pl-0">
		<ol id="atmo_org_breadcrumb_list" class="breadcrumb breadcrumb-narrow">
			<span class="navbar-brand atmo-org-navbar-brand">
				<img src="./Resources/icons/OrganizationsNav.svg" width="30px" height="auto">
			</span>
			{{#each breadcrumbs}}
			{{#if ellipsis}}
			<li class="breadcrumb-item navbar-text standard-local-subheader-h4">
				<a href="#" class="WidgetOrganizationPickerBreadcrumb-Ellipsis">...</a>
			</li>
			{{else}}
			{{#if @last}}
			<li
				class="breadcrumb-item navbar-text active standard-local-subheader-h4 {{#if @first}}{{#unless @last}}breadcrumb-item-root{{/unless}}{{/if}}">
				{{name}}</li>
			{{else}}
			<li
				class="breadcrumb-item navbar-text standard-local-subheader-h4 {{#if @first}}{{#unless @last}}breadcrumb-item-root{{/unless}}{{/if}}">
				<a href="#" class="WidgetOrganizationPickerBreadcrumb-Clickable" orgId={{id}}>{{name}}</a>
			</li>
			{{/if}}
			{{/if}}

			{{/each}}
		</ol>
	</nav>
</div>`, {"noEscape":true});
$.handlebarTemplates["WidgetOrganizationsTopNav"] = Handlebars.compile(`<div class='WidgetOrgazniationsTopNav-OrgBreadcrumbContainer'>
	<nav aria-label="breadcrumb" class="navbar WidgetOrganizationsTopNav-NavBar">
		<ol id="atmo_org_breadcrumb_list" class="breadcrumb breadcrumb-narrow">
			<span class="navbar-brand atmo-org-navbar-brand WidgetOrganizationsTopNav-Entry">
				<img id="WidgetOrganizationsTopNav_Icon" class="WidgetOrganizationsTopNav-EntryIcon"
					src='./Resources/icons/OrganizationsBreadcrumb.svg'></img>
			</span>
			{{#each breadcrumbs}}
			{{#if ellipsis}}
			<li class="breadcrumb-item navbar-text active standard-local-subheader-h4 WidgetOrganizationsTopNav-Label">
				...</li>
			{{else}}
			{{#if @last}}
			<li id="{{id}}_breadcrumb"
				class="breadcrumb-item navbar-text active standard-local-subheader-h4 WidgetOrganizationsTopNav-Label {{#if @first}}{{#unless @last}}breadcrumb-item-root{{/unless}}{{/if}}">
				{{name}}</li>
			{{else}}
			<li
				class="breadcrumb-item navbar-text standard-local-subheader-h4 {{#if @first}}{{#unless @last}}breadcrumb-item-root{{/unless}}{{/if}}">
				<a href="#" class="WidgetOrganizationsTopNav-ClickableBreadcrumb" orgId={{id}}>{{name}}</a>
			</li>
			{{/if}}
			{{/if}}

			{{/each}}
		</ol>
	</nav>
</div>`, {"noEscape":true});
$.handlebarTemplates["WidgetMenu"] = Handlebars.compile(`<div class='WidgetMenu-Top' data-cy="WidgetMenu">
	{{#if showTitle}}
		<div class='WidgetMenu-Title NoSelect'>
			{{labelTitle}}
		</div>
	{{/if}}

	{{#each entries}}
		<div
			data-menuValue="{{jsonValue}}" data-cy="WidgetMenu-{{@index}}"
			class='WidgetMenu-Entry NoSelect standard-local-subheader-h4'
		>
			{{label}}
		</div>
	{{/each}}

	{{#if showFooter}}
		<div class='WidgetMenu-Footer NoSelect'>
			{{labelFooter}}
		</div>
	{{/if}}
</div>`, {"noEscape":true});
$.handlebarTemplates["WidgetTable"] = Handlebars.compile(`<div class="WidgetTable-Top">

	<div class="WidgetTable-ControlsAndTable">
		<div class="WidgetTable-ControlsSection">
			<div class="WidgetTable-SelectionControlsContainer">
				<div id="{{selectAllId}}" class="WidgetTable-ButtonEntry">
					<img src="./Widgets/WidgetTable/selectAll.svg" class="_svg-inject WidgetTable-ButtonImage" title="selectAll"></img>
					<span class="WidgetTable-ButtonLabel NoSelect">{{labelSelectAll}}</span>
				</div>
				<div id="{{selectClearId}}" class="WidgetTable-ButtonEntry">
					<img src="./Widgets/WidgetTable/selectAll.svg" class="_svg-inject WidgetTable-ButtonImage" title="selectClear"></img>
					<span class="WidgetTable-ButtonLabel NoSelect">{{labelSelectClear}}</span>
				</div>
				
				<div class="WidgetTable-SelectionActionAndMenu">
					<div id="{{selectionActionId}}" class="WidgetTable-ButtonEntry">
						<img src="./Widgets/WidgetTable/action.svg" class="_svg-inject WidgetTable-ButtonImage" title="action"></img>
						<span class="WidgetTable-ButtonLabel NoSelect">{{labelAction}}</span>
					</div>
					
					<div id="{{selectionActionMenuContainerId}}" class="WidgetTable-SelectionActionMenu">
					</div>
				</div>
			</div>
			
			<div class="WidgetTable-ExtraInputContainer">
			
				<div id="{{filterInputContainerId}}" class="WidgetTable-FilterInputContainer">
					<input id="{{filterInputId}}" type="text" placeholder="{{filterInputPlaceHolder}}" class="WidgetTable-FilterInput"></input>
					<img src="./Widgets/WidgetTable/filterImage.svg" class="WidgetTable-FilterInputImage" title="search"></img>
				</div>
			</div>
		</div>

		<div class="WidgetTable-TableContainer">
			<div data-tableRow="header" id="{{headerId}}" class="WidgetTable-RowAndSelectContainer">
				<div class="WidgetTable-HeaderRow">
				
					{{#each columns}}
					<div id="{{this.id}}" class="WidgetTable-ColumnHeader WidgetTable-Column NoSelect" style="width:calc((100%/{{../columns.length}}) * {{columnWidth}}); {{columnStyles}}">
						<div class="WidgetTable-ColumnLabel">
							{{this.label}}
						</div>
						
						<img id="{{this.id}}_sortAscending" src="./Widgets/WidgetTable/sortAscending.svg" class="_svg-inject WidgetTable-ColumnHeaderSortImage" title="sortAscending"></img>
						<img id="{{this.id}}_sortDescending" src="./Widgets/WidgetTable/sortDescending.svg" class="_svg-inject WidgetTable-ColumnHeaderSortImage" title="sortDescending"></img>
					</div>
					{{/each}}
				</div>
				
				<div data-tableSelect="header" class="WidgetTable-SelectInputContainer">
				</div>
			</div>

			{{#if_lte tableData.length 0}}
				<div class="WidgetTable-Row">
					{{labelNoData}}
				</div>
			{{/if_lte}}

			{{#each tableData}}
			
			{{#if_gte @index ../firstIndex}}
			
			{{#if_lt @index ../lastIndex}}
			
			<div data-tableRow="{{@index}}" class="WidgetTable-RowAndSelectContainer WidgetTable-RowAndSelectContainerData">
				<div class="WidgetTable-Row {{this.class}}">
				
					{{#each this.columns}}
					<div data-tableColumn="{{@index}}" class="WidgetTable-Column" style="width:calc((100%/{{../../columns.length}}) * {{columnWidth}}); {{columnStyles}}">
					
						{{value}}
					
					</div>
					{{/each}}
				
				</div>
				<div data-tableSelect="{{@index}}" class="WidgetTable-SelectInputContainer">
					<label class="standard-checkbox-container">
						<input type="checkbox" class="WidgetTable-SelectInput"></input>
						<span class="standard-checkbox-checkmark"></span>
					</label>
				</div>
			</div>
			
			{{/if_lt}}
			
			{{/if_gte}}
			
			{{/each}}

		</div>
	</div>
	
	{{#if showTotal}}
	<div class="WidgetTable-ShowingNumberOfEntriesLabel standard-local-subheader-h4">
		{{totalLabel}}: {{tableData.length}}
	</div>
	{{/if}}
		
	{{#if pages}}
	<div class="WidgetTable-PageControlContainer">
		<div class="WidgetTable-PageControlButtonsContainer">
		
			<div data-tablepage="first" class="NoSelect WidgetTable-PageButton WidgetTable-FirstPageButton">
			<<
			</div>
			
			<!--<div data-tablepage="previous" class="NoSelect WidgetTable-PageButton WidgetTable-PreviousPageButton">
			<
			</div>-->
			
			{{#each pages}}
			
			{{#if_gte this.value ../firstPage}}
			
			{{#if_lte this.value ../lastPage}}
			
			{{#if_eq this.value ../page}}
			
			<div data-tablepage="{{this.value}}" class="NoSelect WidgetTable-PageButton WidgetTable-PageButtonCurrent">
			{{this.label}}
			</div>
			
			{{else}}
			
			<div data-tablepage="{{this.value}}" class="NoSelect WidgetTable-PageButton">
			{{this.label}}
			</div>
			
			{{/if_eq}}
			
			{{/if_lte}}
			
			{{/if_gte}}
			
			{{/each}}
			
			<!--<div data-tablepage="next" class="NoSelect WidgetTable-PageButton WidgetTable-NextPageButton">
			>
			</div>-->
			
			<div data-tablepage="last" class="NoSelect WidgetTable-PageButton WidgetTable-LastPageButton">
			>>
			</div>
		
		</div>
	</div>
	{{/if}}
	
</div>
`, {"noEscape":true});
$.handlebarTemplates["WidgetTableDynamic"] = Handlebars.compile(`<div class="WidgetTableDynamic-Top">

	<div class="WidgetTableDynamic-ControlsAndTable">
		<div class="WidgetTableDynamic-ControlsSection" id="{{controlsSectionId}}">
			<div class="WidgetTableDynamic-SelectionControlsContainer">
				<div class="WidgetTableDynamic-SelectionActionAndMenu">
					<div id="{{selectionActionId}}" class="WidgetTableDynamic-ButtonEntry">
						<img src="./Widgets/WidgetTable/action.svg" class="_svg-inject WidgetTableDynamic-ButtonImage" title="action"></img>
						<span class="WidgetTableDynamic-ButtonLabel NoSelect">{{labelAction}}</span>
					</div>
					
					<div id="{{selectionActionMenuContainerId}}" class="WidgetTableDynamic-SelectionActionMenu">
					</div>
				</div>
			</div>
		</div>

		<div id="{{tableId}}" class="WidgetTableDynamic-Table" />

	
</div>
`, {"noEscape":true});
$.handlebarTemplates["WidgetSearchBox"] = Handlebars.compile(`<div class="WidgetSearchBox-Container">
	<div id="{{inputContainerId}}" class="WidgetSearchBox-InputContainer">
		<input id="{{inputId}}" type="text" placeholder="{{inputPlaceholder}}"
			class="WidgetSearchBox-Input"></input>
		<img src="./Widgets/WidgetSearchBox/searchImage.svg" class="WidgetSearchBox-InputImage"
			title="search"></img>
	</div>
</div>`, {"noEscape":true});
$.handlebarTemplates["WidgetAddDevice"] = Handlebars.compile(`<div class='WidgetAddDevice-Top'>
	<div class='WidgetAddDevice-LeftPanel'>
		<div class="WidgetAddDevice-FormContainer">
			<div class="WidgetAddDevice-DeviceName">
				<label class="WidgetAddDevice-Label standard-label">Device Name</label>
				<input id="{{deviceNameInputId}}" type="text" value="New Device"
					class="form-control standard-input-text">
			</div>
			<div id="{{tableContainerId}}" class='WidgetAddDevice-TableContainer'>
			</div>
			<div id="{{invalidationContainerId}}" class="WidgetAddDevice-InvalidationContainer">
			</div>
		</div>
		<div id="{{buttonsFooterContainerId}}" class='WidgetAddDevice-ButtonsFooterContainer'>
		</div>
	</div>
	<div class='WidgetAddDevice-RightPanel'>
		<div id="{{projectDetailsContainerId}}" class='WidgetAddDevice-ProjectDetailsContainer'>
		</div>
	</div>
</div>`);
$.handlebarTemplates["WidgetAddDevice_ProjectDetails"] = Handlebars.compile(`<div class="WidgetAddDevice-ProjectDetails-Top">
	<div class="standard-local-header-h3">
		{{title}}
	</div>
	<div class="WidgetAddDevice-ProjectDetails-ImageContainer">
		<img src="{{projectImageSrc}}" class="WidgetAddDevice-ProjectDetails-Image _svg-inject"></img>
	</div>
	<div class="WidgetAddDevice-ProjectDetails-Info">
		{{#if type}}
		<div class="WidgetAddDevice-ProjectDetails-Field">
			<label class="secondary-label">{{typeLabel}}</label>
			<div class="is-family-secondary">{{type}}</div>
		</div>
		{{/if}}
		{{#if createdAt}}
		<div class="WidgetAddDevice-ProjectDetails-Field">
			<label class="secondary-label">{{createdAtLabel}}</label>
			<div class="is-family-secondary">{{createdAt}}</div>
		</div>
		{{/if}}
		{{#if updatedAt}}
		<div class="WidgetAddDevice-ProjectDetails-Field">
			<label class="secondary-label">{{updatedAtLabel}}</label>
			<div class="is-family-secondary">{{updatedAt}}</div>
		</div>
		{{/if}}
	</div>
</div>`);
$.handlebarTemplates["WidgetAddDeviceSuccess"] = Handlebars.compile(`<div class="WidgetAddDeviceSuccess-Top">
	<div class="WidgetAddDeviceSuccess-CloudWrapper">
		<img class="WidgetAddDeviceSuccess-Cloud" src="./Resources/images/PleasedCloud.png" />
	</div>
	<div class="WidgetAddDeviceSuccess-DeviceId">{{deviceIdMessage}}</div>
	<div class="WidgetAddDeviceSuccess-DeviceTokenWrapper">
		<code class="WidgetAddDeviceSuccess-DeviceToken">{{deviceToken}}</code>
		<div class="WidgetAddDeviceSuccess-ClipboardWrapper" data-clipboard-text="{{deviceToken}}">
			<img class="WidgetAddDeviceSuccess-Clipboard _svg-inject" src="./Resources/icons/Clipboard.svg" />
		</div>
	</div>
	<div class="WidgetAddDeviceSuccess-MessageWrapper">
		<div class="WidgetAddDeviceSuccess-Message">{{{successMessage}}}</div>
		<div class="WidgetAddDeviceSuccess-Disclaimer">{{disclaimerMessage}}</div>
	</div>
	<div id="WidgetAddDeviceSuccess-ButtonsFooter" class="WidgetAddDeviceSuccess-ButtonsFooterContainer"></div>
</div>`);
$.handlebarTemplates["WidgetAlert"] = Handlebars.compile(`<div class='WidgetAlert-Top'>
	<div class='WidgetAlert-Message'>
		{{{message}}}
	</div>

	<div id="{{footerContainerId}}"></div>
</div>`);
$.handlebarTemplates["WidgetUserAgreement"] = Handlebars.compile(`<div class='WidgetUserAgreement-Top'>
	<div class='WidgetUserAgreement-Agreement-Container'>
		<pre class='WidgetUserAgreement-Text' data-cy="Agreement-Text">{{agreementText}}</pre>
	</div>

	<div class='controls WidgetUserAgreement-Footer'>
		<div class='pull-left WidgetUserAgreement-Checkbox'>
			<label class='standard-checkbox-container' data-cy="Agreement-Checkbox">
				<label for="{{agreeCheckboxId}}"></label>
				<input
					id="{{agreeCheckboxId}}"
					value="{{agreeCheckboxName}}"
					name="{{agreeCheckboxName}}"
					type='checkbox'
				/>

				<span class='standard-checkbox-checkmark'></span>
			</label>
		</div>

		<div class='WidgetUserAgreement-Checkbox-Label'>
			{{agreeCheckboxLabel}}
		</div>

		<br />
	</div>

	<div id="{{footerContainerId}}"></div>
</div>`);
$.handlebarTemplates["WidgetConfirm"] = Handlebars.compile(`<div class='WidgetConfirm-Top'>
	<div class='WidgetConfirm-Message is-family-secondary'>
		{{message}}
	</div>

	<div id="{{footerContainerId}}"></div>
</div>`);
$.handlebarTemplates["WidgetLogin"] = Handlebars.compile(`<div class='WidgetLogin-Wrapper' data-cy="WidgetLogin">
	<div class='WidgetLogin-container'>
		<center>
			<img src="{{logoImageSrc}}"/>

			<div
				id="{{ids.onlineStatusFlashId}}"
				class='alert alert-warning WidgetLogin-form-signin WidgetLogin-OnlineStatusFlash'
			>
			</div>

			<div
				id="{{ids.internetExplorerWarningId}}"
				class='alert alert-warning WidgetLogin-form-signin WidgetLogin-InternetExplorerWarning'
			>
				{{language.internetExplorerWarning}}
			</div>

			<div id="{{ids.loginFormId}}" class='WidgetLogin-form-signin'>
				<label for='email' class='darkbg-label'>
					{{language.usernameLabel}}
				</label>
				<input
					id="{{ids.usernameInputId}}"
					type='text'
					name='username'
					class='WidgetLogin-form-control darkbg-input-text'
					placeholder="{{language.usernamePlaceHolder}}"
					required
					autofocus
					autocapitalize='none'
					autocorrect='off'
					autocomplete='username'
					data-cy='LoginUsernameInputField'
				/>

				<label for='password' class='darkbg-label'>
					{{language.passwordLabel}}
				</label>
				<input
					id="{{ids.passwordInputId}}"
					type='password'
					name='password'
					class='WidgetLogin-form-control darkbg-input-text'
					placeholder="{{language.passwordPlaceHolder}}"
					required
					autocomplete='current-password'
					data-cy='LoginPasswordInputField'
				/>

				<button
					id="{{ids.loginButtonId}}"
					class='btn btn-lg btn-primary-dark btn-block WidgetLogin-form-signin'
					data-cy='LoginButtonInput'
				>
					{{language.loginButtonLabel}}
				</button>

				<button
					id="{{ids.reloadAppButtonId}}"
					class='btn btn-lg btn-primary-dark btn-block WidgetLogin-form-signin'
					data-cy='LoginReloadAppButtonInput'
				>
					{{language.reloadAppButtonLabel}}
				</button>

				<br />

				<div class='WidgetLogin-text-block'>
					<a
						href='#'
						id="{{ids.forgotPasswordId}}"
						style='text-decoration: underline'
					>
						{{language.forgotPasswordQuestion}}
					</a>
				</div>

				<div
					id="{{ids.flashId}}"
					class='alert WidgetLogin-form-signin'
				></div>
			</div>
		</center>
	</div>
</div>
`);
$.handlebarTemplates["WidgetChangePassword"] = Handlebars.compile(`<div class="WidgetChangePassword-Wrapper">
	<div class="WidgetChangePassword-container">
		<center>
			<img src="{{logoImageSrc}}">
			<form id="{{ids.changePasswordFormId}}" class="WidgetChangePassword-form-signin">


				<label for="email" class="darkbg-label">{{language.passwordLabel}}</label>
				
				<input id="{{ids.passwordInputId}}" type="password" name="password" class="WidgetChangePassword-form-control darkbg-input-text"
				    placeholder="{{language.passwordPlaceholder}}" required autofocus autocomplete="new-password">

				<label for="password" class="darkbg-label">{{language.confirmPasswordLabel}}</label>
				<input id="{{ids.confirmPasswordInputId}}" type="password" name="confirmPassword" class="WidgetChangePassword-form-control darkbg-input-text" placeholder="{{language.passwordPlaceHolder}}"
				    required autocomplete="new-password">

				<button id="{{ids.submitButtonId}}" class="btn btn-primary-dark btn-block WidgetChangePassword-form-signin">{{language.submitButtonLabel}}</span>
				</button>
			</form>
		</center>
	</div>
</div>
`);
$.handlebarTemplates["WidgetSystemAdministrationConfigurations"] = Handlebars.compile(`<div class='WidgetSystemAdministratorConfigurations-Top'>
	<div class='WidgetSystemAdministratorConfigurations-Controls'>
		<div class='WidgetSystemAdministratorConfigurations-Top-Label card'>
			<div class='card-body'>
				<div
					class='WidgetSystemAdministratorConfigurations-MainActionButtonsContainer float-left'
				>
					<button
						id="{{rootIds.addSubSystemButtonId}}"
						class='btn btn-primary float-left'
					>
						{{language.newSubSystem}}
					</button>
					<button
						id="{{rootIds.addSubSystemConfigurationButtonId}}"
						class='btn btn-primary float-left'
					>
						{{language.addSubSystemConfiguration}}
					</button>
					<button
						id="{{rootIds.exportConfigurationsButtonId}}"
						class='btn btn-primary float-left'
					>
						{{language.exportSystemConfigurations}}
					</button>

					<div
						class='WidgetSystemAdministratorConfigurations-upload-btn-wrapper'
					>
						<button
							id="{{rootIds.importConfigurationsButtonId}}"
							class='btn btn-primary float-left'
						>
							<input
								id="{{rootIds.importConfigurationsFileInputId}}"
								name='importConfigurations'
								accept='.acsysconfig'
								type='file'
							/>
							{{language.importSystemConfigurations}}
						</button>
					</div>
				</div>

				<div class='float-right'>
					<button
						id="{{rootIds.removeSubSystemConfigurationsButtonId}}"
						class='btn btn-danger float-right'
					>
						{{language.removeSubsystemConfigurations}}
					</button>
				</div>
			</div>
		</div>
	</div>

	<div class='WidgetSystemAdministratorConfigurations-SubSystems-Container'>
		<div
			class='WidgetSystemAdministratorConfigurations-SubSystemsList-Container'
		>
			<div
				class='WidgetSystemAdministratorConfigurations-SubSystemsList list-group'
				id="{{rootIds.subSystemsListId}}"
				role='tablist'
			>
				{{#each subSystemsList}}
					<a
						class='WidgetSystemAdministratorConfigurations-SubSystemsList-list-group-item list-group-item list-group-item-action'
						id="{{this.id}}"
						data-toggle='list'
						href="{{this.targetId}}"
						role='tab'
						aria-controls='home'
					>
						{{this.name}}
					</a>
				{{/each}}
			</div>
		</div>

		<div
			class='WidgetSystemAdministratorConfigurations-SubSystemsDetails-Container'
		>
			<div class='tab-content' id='nav-tabContent'>
				{{#each subSystemsDetails}}
					<div
						class='tab-pane show'
						id="{{this.id}}"
						role='tabpanel'
						aria-labelledby='list-home-list'
					>
						{{#each this.inputs}}
							<div
								class='WidgetSystemAdministratorConfigurations-Tag-Card card'
							>
								<div class='card-header'>
									<div class='float-left'>
										<strong>
											{{this.tag}} ({{this.type}})
										</strong>
									</div>

									<div
										class='WidgetSystemAdministratorConfigurations-Tag-ActionButtonsContainer float-right'
									>
										<button
											id="{{this.actionButtons.delete.id
												}}"
											class='btn btn-danger float-right'
											type='button'
										>
											{{this.actionButtons.delete.label}}
										</button>

										<button
											id="{{this.actionButtons.edit.id}}"
											class='btn btn-primary float-right'
											type='button'
										>
											{{this.actionButtons.edit.label}}
										</button>
									</div>
								</div>

								<div class='card-body'>
									{{#switch this.type}}
										{{#case 'Boolean'}}
											<div>{{renderedData}}</div>
										{{/case}}

										{{#case 'Number'}}
											<div>{{renderedData}}</div>
										{{/case}}

										{{#case 'String'}}
											<div>{{renderedData}}</div>
										{{/case}}

										{{#case 'Object'}}
											<div
												class='WidgetSystemAdministratorConfigurations-Input-Container'
											>
												<pre
													class='WidgetAdministatorSystemConfigurations-Value'
												>{{renderedData}}</pre>
											</div>
										{{/case}}

										{{#case 'Array'}}
											<div
												class='WidgetSystemAdministratorConfigurations-Input-Container'
											>
												<pre
													class='WidgetAdministatorSystemConfigurations-Value'
												>{{renderedData}}</pre>
											</div>
										{{/case}}

										{{#case 'undefined'}}
											<div
												class='WidgetSystemAdministratorConfigurations-Input-Container'
											>
												<pre
													class='WidgetAdministatorSystemConfigurations-Value'
												>{{renderedData}}</pre>
											</div>
										{{/case}}

										{{#case 'null'}}
											<div
												class='WidgetSystemAdministratorConfigurations-Input-Container'
											>
												<pre
													class='WidgetAdministatorSystemConfigurations-Value'
												>{{renderedData}}</pre>
											</div>
										{{/case}}
									{{/switch}}
								</div>
							</div>
						{{/each}}
					</div>
				{{/each}}
			</div>
		</div>
	</div>
</div>`);
$.handlebarTemplates["WidgetPopupMessage"] = Handlebars.compile(`<div id="{{topContainerId}}" class="WidgetPopupMessage-Top WidgetPopupMessage-{{color}}">
	<div class="WidgetPopupMessage-Label">
		{{label}}
	</div>
	
	<div id="{{closeButtonId}}" class="WidgetPopupMessage-CloseButtonContainer">
		<img src="./Resources/icons/Close.svg" class="WidgetPopupMessage-CloseIcon _svg-inject" title="close"></img>
	</div>
</div>
`, {"noEscape":true});
$.handlebarTemplates["WidgetUsage"] = Handlebars.compile(`<div class="WidgetUsage-Top WidgetUsage-{{color}}">

	<div class="WidgetUsage-ValueAndIcon">
		<div class="WidgetUsage-ValueAndUnits">
			<div class="WidgetUsage-Value">{{value}}</div>
			<div class="WidgetUsage-Units">{{units}}</div>
		</div>
		<div class="WidgetUsage-Icon">
			<img src="{{imageSrc}}" class="_svg-inject WidgetUsage-IconImage" title="usage"></img>
		</div>
	</div>
	
	<div class="WidgetUsage-Labels">
		<div class="WidgetUsage-LeftLabel">{{leftLabel}}</div>
		<div class="WidgetUsage-RightLabel">{{rightLabel}}</div>
	</div>
	
	
	<div class="WidgetUsage-ProgressBar">
		<div class="WidgetUsage-ProgressBarFront" style="width:{{percent}}%"></div>
	</div>

</div>
`, {"noEscape":true});
$.handlebarTemplates["WidgetUserAccountOverview"] = Handlebars.compile(`<div class='WidgetUserAccountOverview-Top'>
	<div
		id="{{rootIds.userAccountGeneralUsageWidgetContainerId}}"
		class='WidgetUserAccountOverview-GeneralUsageContainer'
	>
	</div>
</div>`, {"noEscape":true});
$.handlebarTemplates["WidgetUserAccountOverviewGeneralUsage"] = Handlebars.compile(`<div>
	<div class='standard-section-subheader-h2'>
		General Usage
	</div>

	<div
		id="{{rootIds.deviceUsageWidgetContainerId}}"
		class='WidgetUserAccountOverviewGeneralUsage-UsageContainer'
	>
	</div>

	<div
		id="{{rootIds.dataUsageWidgetContainerId}}"
		class='WidgetUserAccountOverviewGeneralUsage-UsageContainer'
	>
	</div>
</div>`, {"noEscape":true});
$.handlebarTemplates["WidgetUserAccountOverviewGeneralUsageDevicesUsage"] = Handlebars.compile(``, {"noEscape":true});
$.handlebarTemplates["WidgetUserAccountOverviewGeneralUsageDevicesStorageUsage"] = Handlebars.compile(``, {"noEscape":true});
$.handlebarTemplates["WidgetUserAccountProfile"] = Handlebars.compile(`<div class="WidgetUserAccountProfile-Top">

	{{#if redirectButtonHref}}

	<div class="WidgetUserAccountProfile-CaptionContainer">

		<div class="WidgetUserAccountProfile-Caption">
			{{redirectButtonCaption}}
		</div>

		<a class="btn btn-primary WidgetUserAccountProfile-RedirectButton" href="{{redirectButtonHref}}"
			target="_system">
			{{redirectButtonLabel}}
		</a>


	</div>

	{{else}}

	<div class="WidgetUserAccountProfile-InformationContainer">

		<div class="WidgetUserAccountProfile-Title standard-section-subheader-h2">
			{{userInformationLabel}}
		</div>

		<form autocomplete="off" class="WidgetUserAccountProfile-InformationForm">

			<div class="WidgetUserAccountProfile-LabelAndInput">
				<label class="WidgetUserAccountProfile-Label standard-label">{{firstNameLabel}}</label>
				<input id="{{firstNameInputId}}" type="text" value="{{firstName}}"
					class="WidgetUserAccountProfile-Input form-control standard-input-text"></input>
			</div>

			<div class="WidgetUserAccountProfile-LabelAndInput">
				<label class="WidgetUserAccountProfile-Label standard-label">{{lastNameLabel}}</label>
				<input id="{{lastNameInputId}}" type="text" value="{{lastName}}"
					class="WidgetUserAccountProfile-Input form-control standard-input-text"></input>
			</div>

			<div class="WidgetUserAccountProfile-LabelAndInput">
				<label class="WidgetUserAccountProfile-Label standard-label-disabled">{{usernameLabel}}</label>
				<input id="{{usernameInputId}}" disabled type="text" value="{{username}}"
					class="WidgetUserAccountProfile-Input form-control standard-input-text"></input>
			</div>

			<div class="WidgetUserAccountProfile-LabelAndInput">
				<label class="WidgetUserAccountProfile-Label standard-label-disabled">{{emailLabel}}</label>
				{{!-- Only allow administrators to change e-mail for a user, and not their own account --}}
				<input id="{{emailInputId}}" disabled type="text" value="{{email}}"
					class="WidgetUserAccountProfile-Input form-control standard-input-text"></input>
			</div>

			<div class="WidgetUserAccountProfile-LabelAndInput">
				<label class="WidgetUserAccountProfile-Label standard-label">{{companyLabel}}</label>
				<input id="{{companyInputId}}" type="text" name="company" value="{{company}}"
					class="WidgetUserAccountProfile-Input form-control standard-input-text"></input>
			</div>

			<div class="WidgetUserAccountProfile-LabelAndInput">
				<label class="WidgetUserAccountProfile-Label standard-label">{{jobTitleLabel}}</label>
				<input id="{{jobTitleInputId}}" type="text" name="jobTitle" value="{{jobTitle}}"
					class="WidgetUserAccountProfile-Input form-control standard-input-text"></input>
			</div>

			<div class="WidgetUserAccountProfile-LabelAndInput" {{#if hideRole}}hidden{{/if}}>
				<label class="WidgetUserAccountProfile-Label standard-label">{{roleLabel}}</label>
				<select id="{{roleInputId}}" class="form-control standard-input-select">
					{{#each roles}}
					<option value={{value}} {{#if selected}}selected{{/if}}>{{label}}</option>
					{{/each}}
				</select>
			</div>

			{{!-- Don't show this until its been determined that hte user can enable / disable the account  --}}
			<div id="{{accountEnabledContainerId}}" class="WidgetUserAccountProfile-LabelAndInput" style="display: none">
				<label class="WidgetUserAccountProfile-Label standard-label">{{accountEnabledLabel}}</label>
				<label class="standard-switch">
					<input id="{{accountEnabledSwitchId}}" type="checkbox" data-toggle="toggle" data-onstyle="info" data-size="mini" />
					<div class="standard-slider round"></div>
				</label>
			</div>

			<div class="WidgetUserAccountProfile-LabelAndInput" {{#if hideRole}}hidden{{/if}}>
				<label class="WidgetUserAccountProfile-Label standard-label">{{organizationLabel}}</label>
				<a href="#" id="{{organizationInputId}}"
					class="WidgetUserAcountProfile-Organization standard-input-text">{{organization}}</a>
			</div>

			<div id="{{errorContainerId}}"
				class="WidgetUserAccountProfile-LabelAndInput WidgetUserAccountProfile-ErrorContainer"
				style="display:none">
				<p class="WidgetUserAccountProfile-ErrorContainer-Message">Error validating profile information, please
					ensure your inputs are valid! (First Name, Last Name are
					required fields)</p>
			</div>

		</form>

		<div class="WidgetUserAccountProfile-ButtonsContainer">

			<button id="{{saveChangesButtonId}}"
				class="btn btn-primary WidgetUserAccountProfile-SaveChangesButton">{{saveChangesLabel}}</button>

		</div>

	</div>

	<div class="WidgetUserAccountProfile-SetImageContainer">

		<div class="WidgetUserAccountProfile-TitleAndRemoveImage">
			<div class="WidgetUserAccountProfile-Title standard-section-subheader-h2">
				{{userImageLabel}}
			</div>

			<button id="{{removeImageId}}" class="btn btn-link WidgetUserAccountProfile-RemoveImage">
				{{removeImageLabel}}
			</button>

		</div>

		<div class="WidgetUserAccountProfile-ImageForm">

			<img id="{{userImageId}}" src="./Resources/icons/SetImage.svg" class="WidgetUserAccountProfile-Image"
				title="setProfileImage">
			</img>

		</div>

		<input id="{{imageFileInputId}}" type="file" accept="image/*" capture="camera"
			class="WidgetUserAccountProfile-ImageFileInput">

	</div>

	{{/if}}

</div>`, {"noEscape":true});
$.handlebarTemplates["WidgetUserAccountSecurity"] = Handlebars.compile(`<div class='WidgetUserAccountSecurity-Top'>
	{{#if_eq hideChangePasswordContainer false}}
		<div
			id="{{rootIds.userAccountSecurityChangePasswordContainerId}}"
			class='WidgetUserAccountSecurity-ChangePasswordContainer'
		>
		</div>
	{{/if_eq}}

	<div
		id="{{rootIds.userAccountSecurityAuthenticationTokensContainerId}}"
		class='WidgetUserAccountSecurity-AuthenticationTokensContainer'
	>
	</div>
</div>`, {"noEscape":true});
$.handlebarTemplates["WidgetUserAccountSecurityChangePassword"] = Handlebars.compile(`<div class='standard-section-subheader-h2'>
	{{language.changePassword}}
</div>

<div
	id="{{rootIds.changePasswordFormContainerId}}"
	class='WidgetUserAccountSecurityChangePasswordFormContainer-Top'
>
</div>

<div
	id="{{rootIds.changePasswordFooterButtonsContainerId}}"
	class='WidgetUserAccountSecurityChangePassword-ChangePasswordFooterButtonsContainer-Top'
>
</div>`, {"noEscape":true});
$.handlebarTemplates["WidgetUserAccountSecurityAuthenticationTokens"] = Handlebars.compile(`<div class='WidgetUserAccountSecurityAuthenticationTokens-Top'>
	<div class='WidgetUserAccountSecurityAuthenticationTokens-Header'>
		<div
			class='WidgetUserAccountSecurityAuthenticationTokens-Title standard-section-subheader-h2'
		>
			{{language.authenticationTokens}}
		</div>

		<button
			id="{{rootIds.generateTokenId}}"
			class='btn btn-link WidgetUserAccountSecurityAuthenticationTokens-GenerateToken'
		>
			{{language.generateToken}}
		</button>
	</div>

	<div class='WidgetUserAccountSecurityAuthenticationTokens-Table-Container'>
		<div class='WidgetUserAccountSecurityAuthenticationTokens-Label'>
			{{language.helpLabel}}
		</div>

		<div
			id="{{rootIds.tokensTableContainerId}}"
			class='WidgetUserAccountSecurityAuthenticationTokens-Table'
		>
		</div>

		<div class='WidgetUserAccountSecurityAuthenticationTokens-Label'>
			{{language.bottomLabel}}
		</div>
	</div>
</div>`, {"noEscape":true});
$.handlebarTemplates["WidgetUserAccountSecurityAuthenticationTokensTable"] = Handlebars.compile(``, {"noEscape":true});
$.handlebarTemplates["WidgetUserAccountCancel"] = Handlebars.compile(`<div class='WidgetUserAccountCancel-Top'>
	{{#if redirectButtonHref}}
		<div class='WidgetUserAccountCancel-CaptionContainer'>
			<div class='WidgetUserAccountCancel-Caption'>
				{{redirectButtonCaption}}
			</div>

			<a
				class='btn btn-primary WidgetUserAccountCancel-RedirectButton'
				href="{{redirectButtonHref}}"
				target='_system'
			>
				{{redirectButtonLabel}}
			</a>
		</div>
	{{else}}
		<div
			class='WidgetUserAccountCancel-Title standard-section-subheader-h2'
		>
			{{titleLabel}}
		</div>

		<div class='WidgetUserAccountCancel-Form'>
			<div class='WidgetUserAccountCancel-ConfirmationContainer'>
				<label class='WidgetUserAccountCancel-Label standard-label'>
					{{confirmationLabel}}
				</label>
				<div class='WidgetUserAccountCancel-ConfirmationContainerText'>
					<label class='standard-checkbox-container'>
						<span
							class='WidgetUserAccountCancel-ConfirmationContainerText'
						>
							{{confirmationMessage}}
						</span>
						<input
							class='WidgetUserAccountCancel-Checkbox'
							type='checkbox'
							name='accountActionConfirmationCheckbox'
						/>
						<span class='standard-checkbox-checkmark'></span>
					</label>
				</div>
			</div>
		</div>

		<div class='WidgetUserAccountCancel-Footer'>
			<button
				id="{{cancelAccountButtonId}}"
				class='btn btn-danger WidgetUserAccountCancel-CancelAccountButton'
			>
				{{cancelAccountButtonLabel}}
			</button>
		</div>
	{{/if}}
</div>`, {"noEscape":true});
$.handlebarTemplates["WidgetUserAccountMenu_Header"] = Handlebars.compile(`<div class="WidgetUserAccountMenu-Header-Container">

	<div class="WidgetUserAccountMenu-Header-Upper">
		<div class="WidgetUserAccountMenu-Header-Name">{{displayName}}</div>
		<div class="WidgetUserAccountMenu-Header-Email">{{email}}</div>
	</div>
	
	<div class="WidgetUserAccountMenu-Header-Middle">
		<div class="WidgetUserAccountMenu-Header-ProfileFrame">
		
			{{#if profileImage}}
			<img class="WidgetUserAccountMenu-Header-ProfileImage" src="{{profileImage}}"></img>
			{{else}}
			<img class="WidgetUserAccountMenu-Header-ProfileImage" src="./Resources/icons/ProfileNav.svg"></img>
			{{/if}}
		</div>
	</div>

</div>
`);
$.handlebarTemplates["WidgetUsersTable"] = Handlebars.compile(`<div class="WidgetUsersTable-Top">
	<div class="WidgetUsersTable-Container">
		<div class="WidgetUsersTable-TableLabelAndControls">
			<div class="WidgetUsersTable-TableControlsLeft">
				{{#if canCreateNewUser}}
				<div id="{{addButtonId}}" class="ControlButton-Entry-Non-Responsive NoSelect">
					<img class="ControlButton-Image _svg-inject" src="./Resources/icons/Add.svg"
						title="Add Users"></img>
					<div class="ControlButton-Label"><span>{{addUser}}</span></div>
				</div>
				{{/if}}
				{{#if canMoveUsers}}
				<div id="{{moveButtonId}}" class="ControlButton-Entry-Non-Responsive NoSelect">
					<img class="ControlButton-Image _svg-inject" src="./Resources/icons/MoveItem.svg"
						title="Move Users"></img>
					<div class="ControlButton-Label"><span>{{moveUsers}}</span></div>
				</div>
				{{/if}}
				{{#if canDeleteUsers}}
				<div id="{{deleteButtonId}}" class="ControlButton-Entry-Non-Responsive NoSelect">
					<img class="ControlButton-Image _svg-inject" src="./Resources/icons/Trash.svg"
						title="Delete Users"></img>
					<div class="ControlButton-Label"><span>{{deleteUsers}}</span></div>
				</div>
				{{/if}}
			</div>

			<div class="WidgetUsersTable-TableControlsRight">
				{{#if canSeeOrgs}}
				<div class="WidgetUsersTable-SelectInputContainer">
					<label class="standard-checkbox-container">
						<span class="WidgetUsersTable-ShowChildrenLabel ControlButton-Label-No-Collapse">
							{{showSubOrganizations}}
						</span>
						<input id="{{showChildrenCheckboxId}}" type="checkbox">
						<span class="standard-checkbox-checkmark"></span>
					</label>
				</div>
				{{/if}}
				<div id="{{searchContainerId}}"></div>
			</div>
		</div>
		<div id="{{tableContainerId}}" class="WidgetUsersTable-TableContainer">
		</div>
	</div>
</div>
`, {"noEscape":true});
$.handlebarTemplates["WidgetUsersTable_UserEntry"] = Handlebars.compile(`<div class="WidgetUsersTable-UserEntry">
	<div class="WidgetUsersTable-ImageContainer">
		<img class="WidgetUsersTable-UserImage" src="{{imgSrc}}"></img>
	</div>
</div>`, {"noEscape":true});
$.handlebarTemplates["WidgetNotificationManager_NotificationEntry"] = Handlebars.compile(`<div class="WidgetUsersTable-NotificationEntry">
	<div class="WidgetUsersTable-ImageContainer">
		<img class="WidgetUsersTable-NotificationImage" src="{{imgSrc}}"></img>
	</div>
</div>`, {"noEscape":true});
$.handlebarTemplates["WidgetHelpMenu-Entry"] = Handlebars.compile(`<div class="WidgetHelpMenu-Entry">
	<img class="WidgetHelpMenu-Icon _svg-inject" src="{{imageSrc}}"  title="{{label}}"></img>
	<div class="WidgetHelpMenu-Label">{{label}}</div>
	
	{{#if isLink}}
		<img class="WidgetHelpMenu-ExternalIcon _svg-inject" src="./Resources/icons/ExternalLink.svg"></img>
	{{/if}}
</div>
`);
$.handlebarTemplates["WidgetNavbar"] = Handlebars.compile(`<div id="{{id}}" class="WidgetNavbar">

	<div class="WidgetNavbar-HeaderContainer">
		<div class="WidgetNavbar-Logo NoSelect">
			<img class="WidgetNavbar-LogoImage" src="logo.svg"></img>
		</div>


		<div class="WidgetNavbar-FullLogo NoSelect">
			<img class="WidgetNavbar-FullLogoImage" src="fullLogo.svg"></img>
		</div>


		<div id="{{navbarExpanderButtonId}}" class="WidgetNavbar-ExpanderButton NoSelect">
			<img class="WidgetNavbar-ExpanderButton-ArrowImage" src="./Resources/icons/ExpanderArrow.svg">
			</img>
		</div>
	</div>
	<div class="WidgetNavbar-MenuItemsContainer">
		<div class="WidgetNavbar-TopContainer">

			<div class="WidgetNavbar-EntriesContainer">

				<ul class="WidgetNavbar-EntriesList">

					{{#each entries}}

					<li id="{{id}}" class="WidgetNavbar-ListEntry">
						<div class="NoSelect WidgetNavbar-EntryNumberCount"></div>
						<div class="WidgetNavbar-Entry"  data-cy="NavbarEntry-{{@index}}">
							<img class="_svg-inject WidgetNavbar-EntryIcon" src="{{imageSrc}}" title="{{label}}"></img>
							<span class="WidgetNavbar-EntryLabel standard-local-header-h3">{{label}}</span>
						</div>
					</li>

					{{/each}}
				</ul>

			</div>
		</div>

		<div class="WidgetNavbar-BottomContainer" data-cy="WidgetNavbar-BottomContainer">
			<ul class="WidgetNavbar-BottomItemList">

				{{#each bottomEntries}}

				<li id="{{id}}" class="WidgetNavbar-ListEntry">
					<div class="NoSelect WidgetNavbar-EntryNumberCount"></div>
					<div class="WidgetNavbar-Entry" data-cy="NavbarEntry-Bottom-{{@index}}">
						<img class="_svg-inject WidgetNavbar-EntryIcon" src="{{imageSrc}}" title="{{label}}"></img>
						<span class="WidgetNavbar-EntryLabel standard-local-header-h3">{{label}}</span>
					</div>
				</li>

				{{/each}}

			</ul>
		</div>
	</div>

	{{#each entries}}
	{{#if hasMenu}}
	<div id="{{menuContainerId}}" class="WidgetNavbar-MenuContainer">
	</div>
	{{/if}}
	{{/each}}

	{{#each bottomEntries}}
	{{#if hasMenu}}
	<div id="{{menuContainerId}}" class="WidgetNavbar-MenuContainer">
	</div>
	{{/if}}
	{{/each}}
</div>`);
$.handlebarTemplates["WidgetNotificationManager"] = Handlebars.compile(`<div class="WidgetNotificationManager-Top">
	<div class="WidgetNotificationManager-Container">
		<div class="WidgetNotificationManager-TableLabelAndControls">
			<div class="WidgetNotificationManager-TableControlsLeft">
				<div id="{{deleteButtonId}}" class="ControlButton-Entry-Non-Responsive NoSelect">
					<img class="ControlButton-Image _svg-inject" src="./Resources/icons/Trash.svg"
						title="{{deleteNotifications}}"></img>
					<div class="ControlButton-Label"><span>{{deleteNotifications}}</span></div>
				</div>
				<div id="{{selectAllNotificationsButtonId}}" class="ControlButton-Entry-Non-Responsive NoSelect">
					<img class="ControlButton-Image _svg-inject" src="./Resources/icons/SelectAll.svg"
						title="{{selectAllNotifications}}"></img>
					<div class="ControlButton-Label"><span id="{{selectAllNotificationsLabelId}}"
							class="WidgetNotificationManager-SelectAllLabel">{{selectAllNotifications}}</span></div>
				</div>
			</div>
		</div>
		<div id="{{tableContainerId}}" class="WidgetNotificationManager-TableContainer">
		</div>
	</div>
</div>`);
$.handlebarTemplates["WidgetNotificationSettings"] = Handlebars.compile(`<div class="WidgetNotificationSettings-Top">
	{{!-- Create a block for each preference type --}}
	{{#each preferences}}
	<div class="WidgetNotificationSettings-Container">

		<div class="standard-section-subheader-h2">
			{{this.header}}
		</div>

		<div id="{{generalNotificationsId}}" class="WidgetNotificationSettings-SettingsContainer">
			<form autocomplete="off" class="WidgetNotificationSettings-Form">
				<div class="standard-local-header-h3">
					{{this.subHeader}}
				</div>

				<label class="WidgetNotificationSettings-SettingSubTextLabel is-family-secondary">
					{{{this.subText}}}
				</label>
				{{!-- Checkbox for each individual setting --}}
				{{#each this.inputs}}
				<div class="WidgetNotificationSettings-CheckboxContainer is-family-secondary">
					<div class="WidgetNotificationSettings-Label">
						<label class="standard-checkbox-container">
							<span class="WidgetNotificationSettings-CheckboxLabel">
								{{this.label}}
							</span>
							{{#if this.enabled}}
							<input id="{{this.id}}" class="WidgetNotificationSettings-Checkbox" type="checkbox"
								name="notificationPref" checked {{#if @root.disabled}}disabled{{/if}}>
							{{else}}
							<input id="{{this.id}}" class="WidgetNotificationSettings-Checkbox" type="checkbox"
								name="notificationPref" {{#if @root.disabled}}disabled{{/if}}>
							{{/if}}
							<span class="standard-checkbox-checkmark"></span>
						</label>

					</div>
				</div>
				{{/each}}
			</form>
		</div>
	</div>
	{{/each}}
	<div id="{{footerContainerId}}" class="WidgetNotificationSettings-FooterButtonsContainer">
	</div>

</div>`);
$.handlebarTemplates["WidgetDashboardMenu"] = Handlebars.compile(`<div class="WidgetDashboardMenu-Top">

	<div class="WidgetDashboardMenu-Section">

		<div class="WidgetDashboardMenu-SectionTitle standard-local-subheader-h4 NoSelect">
			{{labelDashboardWidgets}}
		</div>

		<div class="WidgetDashboardMenu-IconsContainer">

			{{#each entries}}
			<div id="{{id}}" class="WidgetDashboardMenu-IconEntry">
				<img src="{{imageSrc}}" class="_svg-inject WidgetDashboardMenu-IconEntryImage" title="{{label}}"></img>
				<div class="WidgetDashboardMenu-IconEntryLabel NoSelect">{{label}}</div>
			</div>
			{{/each}}

		</div>

	</div>

	<hr class="WidgetDashboardMenu-Divider">
	</hr>

	<!--<div class="WidgetDashboardMenu-Section">
	
		<div class="WidgetDashboardMenu-SectionTitle standard-local-subheader-h4 NoSelect">
			{{labelDashboardSettings}}
		</div>
		
		<div class="WidgetDashboardMenu-SettingsContainer">
		</div>
	
	</div>
	
	<hr class="WidgetDashboardMenu-Divider"></hr>-->

	<div class="WidgetDashboardMenu-Section">

		<div class="WidgetDashboardMenu-SectionTitle standard-local-subheader-h4 NoSelect">
			{{labelDashboardOptions}}
		</div>

		<div class="WidgetDashboardMenu-IconsContainer">
			{{#each optionEntries}}
			<div id="{{id}}" class="WidgetDashboardMenu-IconEntry">
				{{#if_eq type "toggle"}}
				<label class="WidgetDashboardMenu-IconEntryImage standard-switch">

					{{#if_eq checked true}}
					<input type="checkbox" checked data-toggle="toggle" data-onstyle="info" data-size="mini"></input>
					{{else}}
					<input type="checkbox" data-toggle="toggle" data-onstyle="info" data-size="mini"></input>
					{{/if_eq}}

					<div class="standard-slider round">

					</div>

				</label>
				{{else}}
				<img src="{{imageSrc}}" class="_svg-inject WidgetDashboardMenu-IconEntryImage" title="{{label}}"></img>
				{{/if_eq}}
				<div class="WidgetDashboardMenu-OptionsEntryLabel NoSelect">{{label}}</div>
			</div>
			{{/each}}
		</div>

	</div>

</div>`);
$.handlebarTemplates["WidgetDashboard"] = Handlebars.compile(`<div class="WidgetDashboard-Top">

	<div id={{sideMenuId}} class="WidgetDashboard-SideMenu-Collapsed WidgetDashboard-SideMenu">
		<div id="{{sideMenuGripperId}}" class="WidgetDashboard-SideMenuGripper">
			<img src="./Widgets/WidgetDashboard/Cog.svg" class="_svg-inject WidgetDashboard-SideMenuGripperImage" title="dashboardMenu"></img>
		</div>
		
		<div id="{{sideMenuContainerId}}" class="WidgetDashboard-SideMenuContainer">
		</div>
	</div>
	
	<div id="{{noWidgetsNoticeContainerId}}" class="WidgetDashboard-NoWidgetNoticeContainer">
	</div>
	
	<div id="{{packeryContainerId}}" class="WidgetDashboard-PackeryContainer">
	</div>

	<div id="{{noWidgetsOverlayId}}" class="WidgetDashboard-NoWidgetsOverlay" data-cy="NoWidgetsOverlay">
		<div class="WidgetDashboard-NoWidgetsImageAndLabel">
			<img src="./Widgets/WidgetDashboard/Resources/noWidgets.svg" class="WidgetDashboard-NoWidgetsImage" data-cy="NoWidgetsImage" title="youHaveNoWidgets"></img>
			<div class="WidgetDashboard-NoWidgetsLabel standard-TitleContainer-h1">{{noWidgetsLabel}}</div>
		</div>
	</div>
	
</div>
`, {"noEscape":true});
$.handlebarTemplates["WidgetDeviceStorage"] = Handlebars.compile(`<div class='WidgetDeviceStorage-Top'>
	<div class='WidgetDeviceStorage-UsageAndLabels'>
		<div class='WidgetDeviceStorage-Labels'>
			<div
				class='NoSelect WidgetDeviceStorage-Title standard-section-subheader-h2'
			>
				{{titleLabel}}
			</div>

			<button
				id="{{clearStorageDataId}}"
				class='NoSelect WidgetDeviceStorage-ClearStorageData btn btn-link'
			>
				{{clearStorageDataLabel}}
			</button>
		</div>

		<div
			class='WidgetDeviceStorage-Usage'
			id="{{deviceStorageUsageContainerId}}"
		>
		</div>
	</div>

	<div class='WidgetDeviceStorage-TableAndLabels'>
		<div class='WidgetDeviceStorage-Labels'>
			<div
				class='NoSelect WidgetDeviceStorage-Title standard-section-subheader-h2'
			>
				{{deviceDataLabel}}
			</div>

			{{#if_eq isPhonegap false}}
				<button
					id="{{downloadAllDataId}}"
					class='NoSelect WidgetDeviceStorage-DownloadAllData btn btn-link'
				>
					{{downloadAllDataLabel}}
				</button>
			{{/if_eq}}
		</div>

		<div
			class='WidgetDeviceStorage-Table'
			id="{{deviceStorageTableContainerId}}"
		>
		</div>
	</div>
</div>`);
$.handlebarTemplates["WidgetDeviceGeolocation"] = Handlebars.compile(`<div class="WidgetDeviceGeolocation-Top">

	<div class="WidgetDeviceGeolocation-LabelsAndForm">

		<div class="WidgetDeviceGeolocation-LabelAndSetLocation">
			<div class="WidgetDeviceGeolocation-Label standard-section-subheader-h2">
				{{deviceGeolocationLabel}}
			</div>

			<button id="{{useCurrentLocationId}}" class="WidgetDeviceGeolocation-UseCurrentLocation btn btn-link">
				{{useCurrentLocationLabel}}
			</button>
		</div>

		<div class="WidgetDeviceGeolocation-Form">

			<div class="WidgetDeviceGeolocation-InputsContainer">
				<div class="WidgetDeviceGeolocation-InputContainer">
					<label class="WidgetDeviceGeolocation-InputLabel standard-label">
						{{latitudeLabel}}
					</label>

					<input id="{{latitudeInputId}}"
						class="WidgetDeviceGeolocation-Input form-control standard-input-text" type="number"
						value="{{latitude}}" /><span class="WidgetDeviceGeolocation-DegreesUnit">°</span>
				</div>

				<div class="WidgetDeviceGeolocation-InputContainer">
					<label class="WidgetDeviceGeolocation-InputLabel standard-label">
						{{longitudeLabel}}
					</label>

					<input id="{{longitudeInputId}}"
						class="WidgetDeviceGeolocation-Input form-control standard-input-text" type="number"
						value="{{longitude}}" /><span class="WidgetDeviceGeolocation-DegreesUnit">°</span>
				</div>
			</div>

			<div class="WidgetDeviceGeolocation-ButtonsContainer">
				<button id="{{saveChangesButtonId}}" class="btn btn-primary">{{saveChangesLabel}}</button>
			</div>

		</div>

	</div>

	<div id="{{deviceMapContainerId}}" class="WidgetDeviceGeolocation-DeviceMapContainer">
	</div>

</div>`);
$.handlebarTemplates["WidgetDeviceSettings"] = Handlebars.compile(`<div class='WidgetDeviceSettings-Top'>
	<div class='WidgetDeviceSettings-Title standard-section-subheader-h2'>
		{{titleLabel}}
	</div>

	<div id="{{settingsFormId}}" class='WidgetDeviceSettings-SettingsForm'>
	</div>
</div>`);
$.handlebarTemplates["WidgetMultipleDeviceSettings"] = Handlebars.compile(`<div class='WidgetMultipleDeviceSettings-Top'>
	<div class='WidgetMultipleDeviceSettings-Title standard-section-subheader-h2'>
		<span id="{{titleSpanId}}" class="WidgetMultipleDeviceSettings-Title-Span">{{titleLabel}}</span>
		<span class="WidgetMultipleDeviceSettings-DeviceCount">{{deviceCount}}</span>
	</div>
	<div id="{{settingsFormId}}" class='WidgetMultipleDeviceSettings-SettingsForm'>
	</div>
</div>`);
$.handlebarTemplates["WidgetDeviceLogs"] = Handlebars.compile(`<div class="WidgetDeviceLogs-Top">
	{{!-- Hide history table if not system administrator... --}}
	<div {{#if_eq canSeeHistoryTable false}}style="display: none"{{/if_eq}}>
		<div class="WidgetDeviceLogs-Title standard-section-subheader-h2">
			{{settingsHistoryTitleLabel}}
		</div>

		<div id="{{settingsHistoryTableContainerId}}" class="WidgetDeviceLogs-TableContainer">
		</div>
	</div>

	<div class="WidgetDeviceLogs-Title standard-section-subheader-h2">
		{{logsTitleLabel}}
	</div>

	<div id="{{logsTableContainerId}}" class="WidgetDeviceLogs-TableContainer">
	</div>

	<div class="WidgetDeviceLogs-Title standard-section-subheader-h2">
		{{errorsTitleLabel}}
	</div>

	<div id="{{errorsTableContainerId}}" class="WidgetDeviceLogs-TableContainer">
	</div>
</div>`);
$.handlebarTemplates["WidgetDeviceInfo"] = Handlebars.compile(`<div class="WidgetDeviceInfo-Top">

	<div class="WidgetDeviceInfo-InformationContainer">
	
		<div class="WidgetDeviceInfo-Title standard-section-subheader-h2">
			{{deviceInformationLabel}}
		</div>
		
		<div class="WidgetDeviceInfo-InformationForm">
		
			<div class="WidgetDeviceInfo-LabelAndInput">
				<label class="WidgetDeviceInfo-Label standard-label">{{deviceNameLabel}}</label>
				<input id="{{deviceNameInputId}}" type="text" value="{{deviceName}}" class="WidgetDeviceInfo-Input form-control standard-input-text"></input>
			</div>
		
			<div class="WidgetDeviceInfo-LabelAndInput">
				<label class="WidgetDeviceInfo-Label standard-label">{{descriptionLabel}}</label>
				<textarea id="{{descriptionInputId}}" type="text" value="{{description}}" class="WidgetDeviceInfo-Input standard-textarea"></textarea>
			</div>
		
			<div class="WidgetDeviceInfo-LabelAndInput">
				<label class="WidgetDeviceInfo-Label standard-label-disabled">{{deviceUuidLabel}}</label>
				<input id="{{deviceUuidInputId}}" disabled type="text" value="{{deviceUuid}}" class="WidgetDeviceInfo-Input form-control standard-input-text"></input>
			</div>
		
			<div class="WidgetDeviceInfo-LabelAndInput">
				<label class="WidgetDeviceInfo-Label standard-label-disabled">{{projectUuidLabel}}</label>
				<input id="{{projectUuidInputId}}" disabled type="text" value="{{projectUuid}}" class="WidgetDeviceInfo-Input form-control standard-input-text"></input>
			</div>
		
			<div class="WidgetDeviceInfo-LabelAndInput">
				<label id="{{deviceOrganizationLabelId}}" class="WidgetDeviceInfo-Label standard-label">{{deviceOrganizationLabel}}</label>
				<a href="#" id="{{deviceOrganizationInputId}}"
					class="WidgetDeviceInfo-Organization standard-input-text">{{deviceOrganization}}</a>
			</div>
		
			<div class="WidgetDeviceInfo-LabelAndInput">
				<label class="WidgetDeviceInfo-Label standard-label">{{addressLabel}}</label>
				<input id="{{addressInputId}}" type="text" name="address" value="{{address}}" class="WidgetDeviceInfo-Input form-control standard-input-text"></input>
			</div>
		
			<div class="WidgetDeviceInfo-LabelAndInput">
				<label class="WidgetDeviceInfo-Label standard-label">{{cityLabel}}</label>
				<input id="{{cityInputId}}" type="text" name="city" value="{{city}}" class="WidgetDeviceInfo-Input form-control standard-input-text"></input>
			</div>
		
			<div class="WidgetDeviceInfo-LabelAndInput">
				<label class="WidgetDeviceInfo-Label standard-label">{{stateLabel}}</label>
				<input id="{{stateInputId}}" type="text" name="state" value="{{state}}" class="WidgetDeviceInfo-Input form-control standard-input-text"></input>
			</div>
		
			<div class="WidgetDeviceInfo-LabelAndInput">
				<label class="WidgetDeviceInfo-Label standard-label">{{postalCodeLabel}}</label>
				<input id="{{postalCodeInputId}}" type="text" name="zip" value="{{postalCode}}" class="WidgetDeviceInfo-Input form-control standard-input-text"></input>
			</div>
		
			<div class="WidgetDeviceInfo-LabelAndInput">
				<label class="WidgetDeviceInfo-Label standard-label">{{countryLabel}}</label>
				<input id="{{countryInputId}}" type="text" name="country" value="{{country}}" class="WidgetDeviceInfo-Input form-control standard-input-text"></input>
			</div>
		
		</div>
		
		<div class="WidgetDeviceInfo-ButtonsContainer">
		
			<button id="{{saveChangesButtonId}}" class="btn btn-primary WidgetDeviceInfo-SaveChangesButton">{{saveChangesLabel}}</button>
		
		</div>
	
	</div>
	
	<div class="WidgetDeviceInfo-SetImageContainer">
	
		<div class="WidgetDeviceInfo-TitleAndRemoveImage">
			<div class="WidgetDeviceInfo-Title standard-section-subheader-h2">
				{{deviceImageLabel}}
			</div>
			
			<button id="{{removeImageId}}" class="WidgetDeviceInfo-RemoveImage btn btn-link">
				{{removeImageLabel}}
			</button>
			
		</div>
		
		<div class="WidgetDeviceInfo-ImageForm">
		
			<img id="{{deviceImageId}}" src="./Resources/icons/SetImage.svg" class="WidgetDeviceInfo-Image" title="deviceImage">
			</img>
		
		</div>
		
		<input id="{{imageFileInputId}}" type="file" accept="image/*" capture="camera" class="WidgetDeviceInfo-ImageFileInput">
	
	</div>

	<div id="{{deviceMetaSectionId}}" class="WidgetDeviceInfo-MetaContainer">
	
		<div class="WidgetDeviceInfo-Title standard-section-subheader-h2">
			{{deviceMetaLabel}}
		</div>
		
		<div id="{{deviceMetaContainerId}}"" class="WidgetDeviceInfo-InformationForm">
		
		</div>
		
	</div>

</div>
`);
$.handlebarTemplates["WidgetDataGlance"] = Handlebars.compile(`<div class='WidgetDataGlance-Top'>
	<div id="{{usageContainerId}}" class='WidgetDataGlance-UsageContainer'>
	</div>
</div>`);
$.handlebarTemplates["WidgetDataBulletin"] = Handlebars.compile(`<div class='WidgetDataBulletin-Top'>
	<div
		id={{tableContainerId}}
		class='WidgetDataBulletin-TableContainer'
	></div>
</div>`);
$.handlebarTemplates["WidgetDataTable"] = Handlebars.compile(`<div class='WidgetDataTable-Top'>
	<div id="{{tableContainerId}}" class="WidgetDataTable-TableContainer" style="display: none;"></div>
</div>`);
$.handlebarTemplates["WidgetDataGraph"] = Handlebars.compile(`<div class='WidgetDataGraph-Top' id="{{dataGraphContainerId}}"></div>`);
$.handlebarTemplates["WidgetDataGauge"] = Handlebars.compile(`<div id="{{dataGaugeContainerId}}" class='WidgetDataGauge-Top'>
</div>`);
$.handlebarTemplates["WidgetFindDevice"] = Handlebars.compile(`<div id="{{deviceSelectContainerId}}" class='WidgetFindDevice-Top'>
</div>`);
$.handlebarTemplates["WidgetDonutChart"] = Handlebars.compile(`<div class='WidgetDonutChart-Top' id="{{donutChartContainerId}}"></div>`);
$.handlebarTemplates["WidgetDeviceMap"] = Handlebars.compile(`<div class='WidgetDeviceMap-Top'>
	<div id="{{mapId}}" class='WidgetDeviceMap-mapContainer'></div>
	<button id="{{getDirectionsButtonId}}" class='WidgetDeviceMap-DirectionsButton btn btn-default btn-sm'>
		{{getDirectionsLabel}}
	</button>
	<div id="{{noDevicesMsgId}}" class='WidgetDeviceMap-NoDevicesMessage alert alert-info'>
		{{noneOfYourDevicesHaveGeolocationMetaInformationLabel}}
	</div>
</div>`);
$.handlebarTemplates["WidgetDeviceMap_InfoWindow"] = Handlebars.compile(`<div class="WidgetDeviceMap-InfoWindow-Top">

	{{#each entries}}
	<div class="WidgetDeviceMap-InfoWindow-Entry">
		<a class="WidgetDeviceMap-InfoWindow-DeviceLink" href="{{link}}">{{name}}</a>
		<div class="WidgetDeviceMap-InfoWindow-Status">
			<div class="WidgetDeviceMap-InfoWindow-StatusLabel">{{statusLabel}}:</div>
			<div class="WidgetDeviceMap-InfoWindow-StatusValue">{{status}}</div>
		</div>
		<img class="WidgetDeviceMap-InfoWindow-DeviceImage" src="{{image}}" title="{{name}}"></img>
	</div>
	{{/each}}

</div>
`);
$.handlebarTemplates["WidgetTrailMap"] = Handlebars.compile(`<div class='WidgetTrailMap-Top'>
	<div id="{{mapId}}" class='WidgetTrailMap-mapContainer'></div>
</div>`);
$.handlebarTemplates["WidgetTrailMap_InfoWindow"] = Handlebars.compile(`<div class="WidgetTrailMap-InfoWindow-Top">

	{{#each entries}}
	<div class="WidgetTrailMap-InfoWindow-Entry">
		{{name}}:{{value}}
	</div>
	{{/each}}

</div>`);
$.handlebarTemplates["WidgetDeviceTable"] = Handlebars.compile(`<div class="WidgetDeviceTable-Top">
	<div class="WidgetDeviceTable-Container">
		<div class="WidgetDeviceTable-TableLabelAndControls">
			<div class="WidgetDeviceTable-TableControlsLeft">
				<div id="{{selectAllButtonId}}" class="ControlButton-Entry-Non-Responsive NoSelect">
					<img class="ControlButton-Image _svg-inject" src="./Resources/icons/SelectAll.svg"
						title="{{selectAllHover}}"></img>
					<div class="ControlButton-Label"><span id="{{selectAllLabelId}}"
							class="WidgetNotificationManager-SelectAllLabel">{{selectAll}}</span></div>
				</div>
				{{#if canProvisionDevices}}
				<div id="{{addButtonId}}" class="ControlButton-Entry-Non-Responsive NoSelect">
					<img class="ControlButton-Image _svg-inject" src="./Resources/icons/Add.svg"
						title="Add Device"></img>
					<div class="ControlButton-Label"><span>{{addDevice}}</span></div>
				</div>
				<div id="{{scanButtonId}}" class="ControlButton-Entry-Non-Responsive NoSelect">
					<img class="ControlButton-Image _svg-inject" width="16px" height="16px"
						src="./Resources/icons/AddDevice.svg" title="Scan For Devices"></img>
					<div class="ControlButton-Label"><span>{{scanForDevice}}</span></div>
				</div>
				{{/if}}
				{{#if canMoveDevices}}
				<div id="{{moveButtonId}}" class="ControlButton-Entry-Non-Responsive NoSelect">
					<img class="ControlButton-Image _svg-inject" src="./Resources/icons/MoveItem.svg"
						title="Move Device"></img>
					<div class="ControlButton-Label"><span>{{moveDevice}}</span></div>
				</div>
				{{/if}}
				{{#if canDeleteDevices}}
				<div id="{{deleteButtonId}}" class="ControlButton-Entry-Non-Responsive NoSelect">
					<img class="ControlButton-Image _svg-inject" src="./Resources/icons/Trash.svg"
						title="Delete Device"></img>
					<div class="ControlButton-Label"><span>{{deleteDevice}}</span></div>
				</div>
				{{/if}}
				{{#if canConfigureDevices}}
				<div id="{{configureDevicesButtonId}}" class="ControlButton-Entry-Non-Responsive NoSelect">
					<img class="ControlButton-Image _svg-inject" src="./Resources/icons/Cog.svg"
						title="Configure Devices"></img>
					<div class="ControlButton-Label"><span>{{configureDevices}}</span></div>
				</div>
				{{/if}}
			</div>

			<div class="WidgetDeviceTable-TableControlsRight">
				{{#if canSeeOrgs}}
				<div class="WidgetDeviceTable-SelectInputContainer">
					<label class="standard-checkbox-container">
						<span class="WidgetDeviceTable-ShowChildrenLabel ControlButton-Label-No-Collapse">
							{{showSubOrganizations}}
						</span>
						<input id="{{showChildrenCheckboxId}}" type="checkbox">
						<span class="standard-checkbox-checkmark"></span>
					</label>
				</div>
				{{/if}}
				<div id="{{searchContainerId}}"></div>

				{{#if canCustomizeColumns}}
				<div id="{{menuButtonId}}" class="WidgetDeviceTable-ButtonContainer">
					<img class="_svg-inject WidgetDeviceTable-ButtonImage" src="./Resources/icons/Options.svg"
						title="menu"></img>
				</div>
				{{/if}}

				<div id="{{menuContainerId}}" class="WidgetDeviceTable-ButtonMenuContainer">
				</div>

			</div>
		</div>
		<div id="{{devicesTableContainerId}}" class="WidgetDeviceTable-TableContainer">
		</div>
	</div>
	<div id="{{noDevicesOverlayId}}" class="WidgetDeviceTable-NoDevicesOverlay">
		<div class="WidgetDeviceTable-NoDevicesImageAndLabel">
			<img src="./Widgets/WidgetDeviceTable/Resources/noDevices.svg" class="WidgetDeviceTable-NoDevicesImage"
				title="youHaveNoDevices"></img>
			<div class="WidgetDeviceTable-NoDevicesLabel standard-TitleContainer-h1">{{youHaveNoDevicesLabel}}</div>
			<div class="WidgetDeviceTable-NeedHelpLabel">{{needHelpAddingADeviceLabel}}</div>
		</div>
	</div>
</div>`, {"noEscape":true});
$.handlebarTemplates["WidgetDeviceTable_DeviceEntry"] = Handlebars.compile(`<div class="WidgetDeviceTable-DeviceEntry">
	<div class="WidgetDeviceTable-ImageContainer">
		<img class="WidgetDeviceTable-DeviceImage" src="{{imgSrc}}"></img>
	</div>
</div>`, {"noEscape":true});
$.handlebarTemplates["WidgetDeviceList"] = Handlebars.compile(`<div class='WidgetDeviceList-Top'>
	<div class="WidgetDeviceList-Container">
		<div id="{{devicesTableContainerId}}" class="WidgetDeviceList-TableContainer">
		</div>
	</div>
	<div id="{{footerWidgetContainerId}}" class='WidgetDeviceList-Footer'></div>
</div>`, {"noEscape":true});
$.handlebarTemplates["WidgetDataMeter"] = Handlebars.compile(`<div class='WidgetDataMeter-Top'>
	<canvas
		id="{{canvasId}}"
		class='WidgetDataMeter-Canvas'
		style='outline: none; -webkit-tap-highlight-color: rgba(255, 255, 255, 0);'
		tabindex='1'
	>
		Your browser does not support the HTML5 canvas tag.
	</canvas>
	<canvas
		id="{{hitCanvasId}}"
		class='WidgetDataMeter-HitCanvas'
		style='display:none; outline: none; -webkit-tap-highlight-color: rgba(255, 255, 255, 0); z-index:-99;'
	>
		Your browser does not support the HTML5 canvas tag.
	</canvas>
</div>`);
$.handlebarTemplates["WidgetAppView"] = Handlebars.compile(`<div class='WidgetAppView-Top'>
	<canvas
		id="{{canvasId}}"
		class='WidgetAppView-Canvas'
		style='outline: none; -webkit-tap-highlight-color: rgba(255, 255, 255, 0);'
		tabindex='1'
	>
		Your browser does not support the HTML5 canvas tag.
	</canvas>
	<canvas
		id="{{hitCanvasId}}"
		class='WidgetAppView-HitCanvas'
		style='display:none; outline: none; -webkit-tap-highlight-color: rgba(255, 255, 255, 0); z-index:-99;'
	>
		Your browser does not support the HTML5 canvas tag.
	</canvas>

	<img
		id="{{bleConnectionIconId}}"
		class='WidgetAppView-ConnectionIcon'
		src='./Widgets/WidgetAppView/Resources/Bluetooth.svg'
		title='bluetooth'
	/>
</div>`);
$.handlebarTemplates["WidgetAppViewControls"] = Handlebars.compile(`<div class="WidgetAppViewControls-Top">
	<div id="{{exitButtonId}}" class="WidgetAppViewControls-ButtonEntry NoSelect">
		<img class="WidgetAppViewControls-ButtonImage _svg-inject" src="./Resources/icons/Exit.svg" title="exitLabel"></img>
	</div>
</div>
`);
$.handlebarTemplates["WidgetProjectsTable"] = Handlebars.compile(`<div class='WidgetProjectsTable-Top'>
	<div class="WidgetProjectsTable-Container">
		<div class="WidgetProjectsTable-TableLabelAndControls">
			<div class="WidgetProjectsTable-TableControlsLeft">
				<div id="{{deleteButtonId}}" class="ControlButton-Entry-Non-Responsive NoSelect">
					<img class="ControlButton-Image _svg-inject" src="./Resources/icons/Trash.svg"
						title="Delete Device"></img>
					<div class="ControlButton-Label"><span>{{deleteDevice}}</span></div>
				</div>
			</div>

			<div class="WidgetProjectsTable-TableControlsRight">
				{{#if canSeeOrgs}}
				<div class="WidgetProjectsTable-SelectInputContainer">
					<label class="standard-checkbox-container">
						<span class="WidgetProjectsTable-ShowChildrenLabel ControlButton-Label-No-Collapse">
							{{showSubOrganizations}}
						</span>
						<input id="{{showChildrenCheckboxId}}" type="checkbox">
						<span class="standard-checkbox-checkmark"></span>
					</label>
				</div>
				{{/if}}
				<div id="{{searchContainerId}}"></div>
			</div>
		</div>
		<div id="{{tableContainerId}}" class="WidgetProjectsTable-TableContainer">
		</div>
	</div>
</div>
`);
$.handlebarTemplates["WidgetNewProjectSelector"] = Handlebars.compile(`<div class='WidgetNewProjectSelector-Top'>
	<div class='WidgetNewProjectSelector-LabelAndTable'>
		<!--		<div class="WidgetNewProjectSelector-Label">
			{{selectProjectTypeLabel}}
		</div>-->

		<div
			id="{{tableContainerId}}"
			class='WidgetNewProjectSelector-TableContainer'
		>
		</div>
	</div>

	<div class='WidgetNewProjectSelector-DetailsAndButtons'>
		<div
			id="{{projectDetailsContainerId}}"
			class='WidgetNewProjectSelector-ProjectDetailsContainer'
		>
		</div>

		<div
			id="{{buttonsFooterContainerId}}"
			class='WidgetNewProjectSelector-ButtonsFooterContainer'
		>
		</div>
	</div>
</div>`);
$.handlebarTemplates["WidgetNewProjectSelector_ProjectDetails"] = Handlebars.compile(`<div class="WidgetNewProjectSelector-ProjectDetails-Top">

	<div class="WidgetNewProjectSelector-ProjectDetails-TitleLabel standard-local-header-h3">
		{{title}}
	</div>

	{{#if projectType}}
	<div class="WidgetNewProjectSelector-ProjectDetails-NameLabelAndInput">
		<label class="WidgetNewProjectSelector-ProjectDetails-NameLabel standard-label">
			{{nameLabel}}
		</label>

		<input id="{{nameInputId}}" type="text" value="{{newProjectName}}"
			class="WidgetNewProjectSelector-ProjectDetails-NameInput form-control standard-input-text"></input>
	</div>
	{{/if}}

	<div class="WidgetNewProjectSelector-ProjectDetails-ImageContainer">
		<img src="{{projectImageSrc}}" class="_svg-inject WidgetNewProjectSelector-ProjectDetails-Image"
			title="{{projectType}}"></img>
	</div>

	<div class="WidgetNewProjectSelector-ProjectDetails-Info">

		{{#if projectType}}
		<div class="WidgetNewProjectSelector-ProjectDetails-TypeContainer">
			<label
				class="WidgetNewProjectSelector-ProjectDetails-TypeLabel secondary-label">{{projectTypeLabel}}</label>
			<div class="WidgetNewProjectSelector-ProjectDetails-Type is-family-secondary">{{projectType}}</div>
		</div>
		{{/if}}

		{{#if manufacturer}}
		<div class="WidgetNewProjectSelector-ProjectDetails-ManufacturerContainer">
			<label
				class="WidgetNewProjectSelector-ProjectDetails-ManufacturerLabel secondary-label">{{manufacturerLabel}}</label>
			<div class="WidgetNewProjectSelector-ProjectDetails-Manufacturer is-family-secondary">{{manufacturer}}</div>
		</div>
		{{/if}}

		{{#if description}}
		<div class="WidgetNewProjectSelector-ProjectDetails-DescriptionContainer">
			<label
				class="WidgetNewProjectSelector-ProjectDetails-DescriptionLabel secondary-label">{{descriptionLabel}}</label>
			<div class="WidgetNewProjectSelector-ProjectDetails-Description is-family-secondary">{{description}}</div>
		</div>
		{{/if}}

		{{#if chipset}}
		<div class="WidgetNewProjectSelector-ProjectDetails-ChipsetContainer">
			<label class="WidgetNewProjectSelector-ProjectDetails-ChipsetLabel secondary-label">{{chipsetLabel}}</label>
			<div class="WidgetNewProjectSelector-ProjectDetails-Chipset is-family-secondary">{{chipset}}</div>
		</div>
		{{/if}}

		{{#if protocol}}
		<div class="WidgetNewProjectSelector-ProjectDetails-ProtocolContainer">
			<label
				class="WidgetNewProjectSelector-ProjectDetails-ProtocolLabel secondary-label">{{protocolLabel}}</label>
			<div class="WidgetNewProjectSelector-ProjectDetails-Protocol is-family-secondary">{{protocol}}</div>
		</div>
		{{/if}}

	</div>

</div>`);
$.handlebarTemplates["WidgetProjectManager"] = Handlebars.compile(`<div id="{{id}}" class="WidgetProjectManager-menu-container">
	<div class="WidgetProjectManager-block-content">
		{{#each categories}}
		<div>
			{{#if_eq name "Create Application"}}
			<div class="WidgetProjectManager-section-title standard-section-subheader-h2">
				{{name}}
			</div>
			{{/if_eq}}
			{{#if_eq name "Recent Applications"}}
			{{#if_eq ../hasProjects true}}
			<div class="WidgetProjectManager-section-title standard-section-subheader-h2">
				{{name}}
			</div>
			{{/if_eq}}
			{{/if_eq}}
			<div class="WidgetProjectManager-category">
				{{#projects}}
				{{#if newProject}}
				<div class="WidgetProjectManager-wrap WidgetProjectManager-CreateProjectSectionCard">
					<div class="WidgetProjectManager-New-Project-Wrapper">
						<a id="{{id}}">
							<div class="WidgetProjectManager-Add-Icon-Div-Wrapper">
								<div class="WidgetProjectManager-Add-Icon-Div">
									<img src="{{addButtonPath}}" class="_svg-inject" title="newProjectName" />
								</div>
							</div>
							<div class="WidgetProjectManager-Name-Div-Wrapper" data-simplebar>
								<span class="WidgetProjectManager-name standard-local-header-h3">
									{{name}}
								</span>
							</div>
						</a>
					</div>
					{{else if importProject}}
					<div id="{{importProjectCardId}}"
						class="WidgetProjectManager-wrap WidgetProjectManager-CreateProjectSectionCard">
						<div class="WidgetProjectManager-Import-Project-Wrapper">
							<a id="{{id}}">
								<label for="{{fileInputId}}" class="WidgetProjectManager-FileImport-Label">
									<div class="WidgetProjectManager-Add-Icon-Div-Wrapper">
										<div class="WidgetProjectManager-Add-Icon-Div">
											<img src="{{addButtonPath}}" class="_svg-inject" title="importProject" />
										</div>
									</div>
									<div class="WidgetProjectManager-Name-Div-Wrapper" data-simplebar>
										<span class="WidgetProjectManager-name standard-local-header-h3">
											{{name}}
										</span>
									</div>
								</label>
								<input id="{{fileInputId}}" accept=".atmo" type="file" style="display:none;">
							</a>
						</div>
						{{else if ../../hasProjects}}
						<div class="WidgetProjectManager-wrap WidgetProjectManager-SavedProjectCard"
							data-projectuuid="{{projectUuid}}">
							<div class="WidgetProjectManager-Name-Div-Wrapper" data-simplebar>
								<span class="WidgetProjectManager-saved-name standard-local-header-h3">
									{{name}}
								</span>
								<p class="WidgetProjectManager-subname is-family-secondary">
									{{type}}
								</p>
							</div>
							<div class="WidgetProjectManager-button-wrapper">
								<a id="{{id}}">
									<button class="WidgetProjectManager-button button btn btn-primary ">
										{{../../open}}
									</button>
								</a>
							</div>
							<div class="WidgetProjectManager-ProjectDetails-Wrapper">
								<div>
									<p class="WidgetProjectManager-created is-family-secondary">
										{{../../created}} {{created}}
									</p>
									<p class="WidgetProjectManager-created is-family-secondary">
										{{../../updated}} {{modified}}
									</p>
								</div>
							</div>
							<div class="WidgetProjectManager-footer">
								<a id="{{exportButtonId}}">
									<img src="{{exportButtonPath}}" class="WidgetProjectManager-ButtonIcon _svg-inject"
										title="exportProject" />
								</a>
								<a id="{{removeButtonId}}" class="pull-right">
									<img src="{{removeButtonPath}}"
										class="WidgetProjectManager-ButtonRemoveIcon _svg-inject"
										title="removeProject" />
								</a>
							</div>
							{{/if}}
						</div>
						{{/projects}}
					</div>
				</div>
				{{/each}}
			</div>
		</div>
	</div>
	<div class="WidgetProjectManager-ProjectsTableLabel standard-section-subheader-h2">
		{{projectsTableLabel}}
	</div>
	<div id="{{projectsTableContainerId}}" class="WidgetProjectManager-ProjectsTableContainer">
	</div>
</div>`);
$.handlebarTemplates["WidgetDemoProjectsTable"] = Handlebars.compile(``);
$.handlebarTemplates["WidgetProjectInfoEditor"] = Handlebars.compile(`<div class="WidgetProjectInfoEditor-TopScroll">
	<div class="WidgetProjectInfoEditor-Top">
		<div class="WidgetProjectInfoEditor-Form">
			<label class="standard-label">
				{{nameLabel}}
			</label>
			<input id="{{nameInputId}}" class="form-control standard-input-text" value="{{nameValue}}" />
			<label class="standard-label">{{projectUuidLabel}}</label>
			<p>{{projectUuidValue}}</p>
			<label class="standard-label">{{createdLabel}}</label>
			<p>{{createdValue}}</p>
			<label class="standard-label">{{modifedLabel}}</label>
			<p>{{modifiedValue}}</p>
			<label for="{{descriptionInputId}}" class="standard-label">{{descriptionLabel}}</label>
			<textarea id="{{descriptionInputId}}" name="{{descriptionInputName}}" type="text"
				class="form-control standard-textarea">{{descriptionValue}}</textarea>
			<label class="standard-label">{{defaultDeviceDashboardLabel}}</label>
			<div class="WidgetProjectInfoEditor-InputDeviceSelect">
				<div class="WidgetProjectInfoEditor-DeviceDataSelectInfo">
					<div>
						<label
							class="WidgetProjectInfoEditor-DeviceDataSelectLabel secondary-label">{{dashboardSetLabel}}</label>
						<div class="WidgetProjectInfoEditor-DeviceDataSelectInput is-family-secondary">{{isDashboardSet}}
						</div>
					</div>
				</div>
				<div>
					<button id="{{setDefaultDeviceDashboardButtonId}}" class="btn btn-link">{{selectLabel}}</button>
				</div>
			</div>

			<div class="WidgetProjectInfoEditor-SetImageContainer">
				<div class="WidgetProjectInfoEditor-TitleAndRemoveImage">
					<label class="standard-label">{{deviceImageLabel}}</label>
					<button id="{{removeImageId}}" class="btn btn-link WidgetProjectInfoEditor-RemoveImage">{{removeImageLabel}}</button>
				</div>
				<div class="WidgetProjectInfoEditor-ImageForm">
					<img id="{{deviceImageId}}" src="./Resources/icons/SetImage.svg"
						class="WidgetProjectInfoEditor-Image" title="setProfileImage">
					</img>
				</div>
				<input id="{{imageFileInputId}}" type="file" accept="image/*" capture="camera"
					class="WidgetProjectInfoEditor-ImageFileInput">
			</div>
		</div>
		<div class="WidgetProjectInfoEditor-Footer">
			<button id="{{closeButtonId}}" class="WidgetProjectInfoEditor-CloseButton btn-primary btn">{{closeButtonLabel}}</button>
		</div>
		<div id="{{deviceDashboardSelectContainerId}}" class="WidgetProjectInfoEditor-DeviceDashboardSelectContainer">
		</div>
	</div>
</div>`);
$.handlebarTemplates["WidgetCompileReport"] = Handlebars.compile(`<div class="WidgetCompileReport-Top">
	<div class="WidgetCompileReport-Notice is-family-secondary">
		{{noticeLabel}}:
	</div>
	<div class="WidgetCompileReport-Errors">
	{{#each errors}}
	
	{{#if_eq type "error"}}
	
	{{#unless @first}}
	<div class="WidgetCompileReport-Divider"></div>
	{{/unless}}
	
	<div class="WidgetCompileReport-Entry is-family-secondary">
		<div class="WidgetCompileReport-FilenameErrorSection">
			
			<div class="WidgetCompileReport-FilenameErrorLabel">
				{{../filenameLabel}}: {{filename}}
			</div>

		</div>

		<div class="WidgetCompileReport-LineErrorSection">
			
			<div class="WidgetCompileReport-LineErrorLabel">
				{{../lineLabel}}: {{line}}
			</div>
			
			<div class="WidgetCompileReport-LineErrorText">
				{{text}}
			</div>
		</div>

		{{#if hasCode}}
		<div class="WidgetCompileReport-CodeSection">			
			<div class="WidgetCompileReport-Code">
				{{code}}
			</div>
		</div>
		{{/if}}
		
	</div>
	{{/if_eq}}

	{{#if_eq type "notification"}}
	
	{{#unless @first}}
	<div class="WidgetCompileReport-Divider"></div>
	{{/unless}}
	
	<div class="WidgetCompileReport-Entry">
		<div class="WidgetCompileReport-LineErrorSection">
			
			<div class="WidgetCompileReport-LineErrorText">
				{{#if_eq notificationType "fwTooLarge"}}
					{{../fwTooLargeLabel}} {{sizeReport}}
				{{else}}
					{{text}}
				{{/if_eq}}
				
			</div>
		</div>

	</div>
	{{/if_eq}}
	
	{{/each}}
	</div>
</div>	
<div class="WidgetCompileReport-Footer">
	<button id="{{closeButtonId}}" class="btn-primary btn">
		{{closeButtonLabel}}
	</button>
</div>`);
$.handlebarTemplates["WidgetAppViewLayoutSelection"] = Handlebars.compile(`<div class='WidgetAppViewLayoutSelection-Top'>
	<div
		class='WidgetAppViewLayoutSelection-TableContainer'
		id="{{tableContainerId}}"
	>
	</div>

	<div class='WidgetAppViewLayoutSelection-Footer'>
		<button
			id="{{dismissButtonId}}"
			class='WidgetAppViewLayoutSelection-DismissButton btn btn-cancel'
		>
			{{labelDismissButton}}
		</button>
		<button
			id="{{confirmButtonId}}"
			class='WidgetAppViewLayoutSelection-ConfirmButton btn btn-primary'
		>
			{{labelConfirmButton}}
		</button>
	</div>
</div>`);
$.handlebarTemplates["WidgetAppViewLayoutSelection_EnableColumn"] = Handlebars.compile(`<label data-appviewlayoutwidth="{{width}}" data-appviewlayoutheight="{{height}}" class="WidgetAppViewLayoutSelection-EnableSwitch standard-switch">

	{{#if_eq checked true}}
	<input type="checkbox" checked data-toggle="toggle" data-onstyle="info" data-size="mini"></input>
	{{else}}
	<input type="checkbox" data-toggle="toggle" data-onstyle="info" data-size="mini"></input>
	{{/if_eq}}
	
	<div class="standard-slider round">
		
	</div>

</label>
`);
$.handlebarTemplates["WidgetAppViewEditor"] = Handlebars.compile(`<div class='WidgetAppViewEditor-wrapper'>
	<div class='WidgetAppViewEditor-toolbar'>
		<div class='WidgetAppViewEditor-toolbar-icons'>
			<div
				class='WidgetAppViewEditor-toolbar-icon standard-svg-button-icon'
			>
				<a id="{{toBackButtonId}}">
					<img
						src={{iconPaths.toBack}}
						class='_svg-inject'
						title='layoutSendtoBack'
					/>
				</a>
			</div>

			<div
				class='WidgetAppViewEditor-toolbar-icon standard-svg-button-icon'
			>
				<a id="{{toFrontButtonId}}">
					<img
						src={{iconPaths.toFront}}
						class='_svg-inject'
						title='layoutBringToFront'
					/>
				</a>
			</div>

			<div
				class='WidgetAppViewEditor-toolbar-icon standard-svg-button-icon'
			>
				<a id="{{snapButtonId}}">
					<img
						src={{iconPaths.align}}
						class='_svg-inject'
						title='align'
					/>
				</a>
			</div>

			<div
				class='WidgetAppViewEditor-toolbar-icon standard-svg-button-icon'
			>
				<a id="{{gridSnapButtonId}}">
					<img
						src={{iconPaths.snap}}
						class='_svg-inject'
						title='layoutSnapToGrid'
					/>
				</a>
			</div>

			<div
				class='WidgetAppViewEditor-toolbar-icon standard-svg-button-icon'
			>
				<a id="{{smallerGridSizeButtonId}}">
					<img
						src={{iconPaths.decrease}}
						class='_svg-inject'
						title='layoutDecreaseGrid'
					/>
				</a>
			</div>

			<div
				class='WidgetAppViewEditor-toolbar-icon standard-svg-button-icon'
			>
				<a id="{{largerGridSizeButtonId}}">
					<img
						src={{iconPaths.increase}}
						class='_svg-inject'
						title='layoutIncreaseGrid'
					/>
				</a>
			</div>

			<div
				class='WidgetAppViewEditor-toolbar-icon standard-svg-button-icon'
			>
				<a id="{{toggleExecuteAppButtonId}}">
					<img
						src={{iconPaths.execute}}
						class='_svg-inject'
						title='executeApp'
					/>
				</a>
			</div>
		</div>
		<div class='WidgetAppViewEditor-toolbar-inputs'>
			<span class='WidgetAppViewEditor-toolbar-label'>
				{{xLabel}}:
			</span>

			<input
				id="{{xInputId}}"
				type='text'
				placeholder="{{xInputPlaceholder}}"
				class='WidgetAppViewEditor-toolbar-textfield form-control standard-input-text'
			/>

			<span class='WidgetAppViewEditor-toolbar-label'>
				{{yLabel}}:
			</span>

			<input
				id="{{yInputId}}"
				type='text'
				placeholder="{{yInputPlaceholder}}"
				class='WidgetAppViewEditor-toolbar-textfield form-control standard-input-text'
			/>

			<span class='WidgetAppViewEditor-toolbar-label'>
				{{widthLabel}}:
			</span>

			<input
				id="{{widthInputId}}"
				type='text'
				placeholder="{{widthInputPlaceholder}}"
				class='WidgetAppViewEditor-toolbar-textfield form-control standard-input-text'
			/>

			<span class='WidgetAppViewEditor-toolbar-label'>
				{{heightLabel}}:
			</span>

			<input
				id="{{heightInputId}}"
				type='text'
				placeholder="{{heightInputPlaceholder}}"
				class='WidgetAppViewEditor-toolbar-textfield form-control standard-input-text'
			/>
		</div>
	</div>
	<div class='WidgetAppViewEditor-work-area-header'>
		<span id="{{currentLayoutNameId}}"></span>

		<span class='WidgetAppViewEditor-header-right'>
			<a
				id="{{addLayoutButtonId}}"
				class='WidgetAppViewEditor-Work-Area-Icon standard-svg-button-icon'
			>
				<img
					src="{{iconPaths.addLayout}}"
					class='_svg-inject'
					title='addLayout'
				/>
			</a>

			<select
				id="{{layoutSelectorId}}"
				class='form-control WidgetAppViewEditor-layoutSelector standard-input-select'
			>
				<option value='1024x768'>
					1024x768
				</option>
			</select>
		</span>
	</div>

	<div class='WidgetAppViewEditor-work-area-wrapper'>
		<div class='WidgetAppViewEditor-work-area'>
			<div
				id="{{appViewContainerId}}"
				class='WidgetAppViewEditor-usable-work-area'
				data-simplebar
			>
				<!--Canvas goes here-->
			</div>
		</div>
	</div>

	{{!

		<!--End of application wrapper-->

		<div class="WidgetAppViewEditor-pagination">

			<a class="WidgetAppViewEditor-link-icons active-pagnation">

				<i class="fa fa-circle" aria-hidden="true"></i>

			</a>

			<a class="WidgetAppViewEditor-link-icons">

				<i class="fa fa-circle " aria-hidden="true"></i>

			</a>

			<a class="WidgetAppViewEditor-link-icons">
				
				<i class="fa fa-circle " aria-hidden="true"></i>

			</a>

		</div>

	}}
</div>`);
$.handlebarTemplates["WidgetCodeEditor"] = Handlebars.compile(`<div id="{{id}}" class='WidgetCodeEditor-ace-container'>
</div>`);
$.handlebarTemplates["WidgetProgrammingDialog"] = Handlebars.compile(`<div class='WidgetProgrammingDialog-Top'>
	<div>
		<form id="{{id}}">
			<fieldset>
				{{#each inputs}}
					<div class='control-group'>
						<label class='control-label standard-label'>
							{{name}}
						</label>

						<div class='controls'>
							{{#switch input}}
								{{#case 'text'}}
									<input
										id="{{id}}"
										name="{{name}}"
										type='text'
										value="{{value}}"
										data-cy="{{guidepost}}"
										class='form-control standard-input-text'
									/>
								{{/case}}

								{{#case 'number'}}
									<input
										id="{{id}}"
										name="{{name}}"
										type='text'
										value="{{value}}"
										data-cy="{{guidepost}}"
										class='form-control standard-input-text'
									/>
								{{/case}}

								{{#case 'color'}}
									<input
										id="{{id}}"
										name="{{name}}"
										type='text'
										value="{{value}}"
										data-cy="{{guidepost}}"
										class='form-control standard-input-text'
									/>
								{{/case}}

								{{#case 'font'}}
									<input
										id="{{id}}"
										name="{{name}}"
										type='text'
										value="{{value}}"
										data-cy="{{guidepost}}"
										class='form-control standard-input-text'
									/>
								{{/case}}

								{{#case 'image'}}
									<input
										id="{{id}}"
										name="{{name}}"
										type='text'
										value="{{value}}"
										data-cy="{{guidepost}}"
										class='form-control'
									/>
								{{/case}}

								{{#case 'checkbox'}}
									{{#if_eq value true}}
										<div class='standard-input-checkbox'>
											<input
												id="{{id}}"
												name="{{name}}"
												type='checkbox'
												data-cy="{{guidepost}}"
												checked
												class='hidden'
											/>

											<label for="{{id}}">
											</label>
										</div>
									{{else}}
										<div class='standard-input-checkbox'>
											<input
												id="{{id}}"
												name="{{name}}"
												type='checkbox'
												data-cy="{{guidepost}}"
												class='hidden'
											/>

											<label for="{{id}}">
											</label>
										</div>
									{{/if_eq}}
								{{/case}}

								{{#case 'array'}}{{/case}}

								{{#case 'object'}}{{/case}}

								{{#case 'code'}}{{/case}}

								{{#case 'select'}}
									<select
										id="{{id}}"
										name="{{name}}"
										data-cy="{{guidepost}}"
										class='form-control standard-input-select'
									>
										{{! Using select handlebar helper to select the correct value }}
										{{#select value.value}}
											{{#each inputOptions}}
												<option value="{{value}}">
													{{name}}
												</option>
											{{/each}}
										{{/select}}
									</select>
								{{/case}}
							{{/switch}}
						</div>
					</div>
				{{/each}}
			</fieldset>
		</form>
	</div>

	<div class='WidgetProgrammingDialog-Footer'>
		<button
			id="{{dismissButtonId}}"
			class='WidgetProgrammingDialog-DismissButton btn btn-cancel'
		>
			{{labelDismissButton}}
		</button>
		<button
			id="{{confirmButtonId}}"
			class='WidgetProgrammingDialog-ConfirmButton btn btn-primary'
		>
			{{labelConfirmButton}}
		</button>
	</div>
</div>`);
$.handlebarTemplates["WidgetProgrammingDialog_Feedback"] = Handlebars.compile(`<div class='WidgetProgrammingDialog-Top'>
	<div id="{{progressBarContainerId}}">
		<div class=''>
			<div class='WidgetProgrammingDialog_Feedback-ProgressBar'>
				<div
					id="{{progressBarId}}"
					role='progressbar'
					aria-valuenow=''
					aria-valuemin='0'
					aria-valuemax='100'
				>
				</div>
			</div>

			<div
				class='WidgetProgrammingDialog_Feedback-Progess-LabelContainer'
			>
				<label
					id="{{progressBarTotalId}}"
					class='WidgetProgrammingDialog-ProgressPercentage'
				>
				</label>
			</div>
		</div>
	</div>

	<div>
		<div id="{{infoContainerId}}" class='alert animated'>
		</div>
	</div>

	<div>
		<button
			id="{{showStdOutButtonId}}"
			class='WidgetProgrammingDialog_Feedback-AdvancedOutButton button btn btn-link btn-block'
		>
		</button>
	</div>

	<div
		id="{{stdOutContainerId}}"
		class='WidgetProgrammingDialog_Feedback-StdOutContainer'
		data-simplebar
	>
		<pre id="{{stdOutPreId}}">
		</pre>
	</div>

	<div class='WidgetProgrammingDialog-Footer'>
		<button
			id="{{dismissButtonId}}"
			class='WidgetProgrammingDialog-DismissButton btn btn-cancel'
		>
			{{labelDismissButton}}
		</button>
	</div>
</div>`);
$.handlebarTemplates["WidgetPlaneEditor"] = Handlebars.compile(`<div class="WidgetPlaneEditor-Top">
	<div class="WidgetPlaneEditor-LeftSide">
		<div class="WidgetPlaneEditor-Header">
				{{#each icons}}
					<div id="{{id}}" class="WidgetPlaneEditor-HeaderIconContainer">
						<img src={{src}} class="_svg-inject WidgetPlaneEditor-HeaderIconImage" data-cy="{{guidepost}}"  title="{{label}}"></img>
					</div>
				{{/each}}
		</div>
		<div id="{{resizePanelId}}" class="WidgetPlaneEditor-ResizablePanelContainer">
		
			<div id="{{leftPanelId}}" class="WidgetPlaneEditor-ResizePanelLeft">
			</div>
			
			<div id="{{splitterId}}" class="WidgetPlaneEditor-Splitter">
			</div>

			<div id="{{rightPanelId}}" class="WidgetPlaneEditor-ResizePanelRight">
			</div>
		</div>
	</div>

	<div  id="{{toolboxContainerId}}" class="WidgetPlaneEditor-ToolboxContainer">
	</div>

	<div id="{{propertiesContainerId}}" class="WidgetPlaneEditor-ElementEditorContainer">
	</div>
</div>
`);
$.handlebarTemplates["WidgetDesignerView"] = Handlebars.compile(`<div class='WidgetDesignerView-Top'>
	<canvas
		id="{{canvasId}}"
		width='3000'
		height='3000'
		class='WidgetDesignerView-Canvas'
		style='outline: none; -webkit-tap-highlight-color: rgba(255, 255, 255, 0);'
		tabindex='1'
	>
		Your browser does not support the HTML5 canvas tag.
	</canvas>
	<canvas
		id="{{hitCanvasId}}"
		class='WidgetDesignerView-HitCanvas'
		style='display:none; outline: none; -webkit-tap-highlight-color: rgba(255, 255, 255, 0); z-index:-99;'
	>
		Your browser does not support the HTML5 canvas tag.
	</canvas>
</div>`);
$.handlebarTemplates["WidgetElementEditor"] = Handlebars.compile(`{{!
{
	"nameId": "5bd59dfa-a082-e9a5-804b-6755668948cd_propertieselementNameEditor",
	"editorId": "5bd59dfa-a082-e9a5-804b-6755668948cd_properties_pencilIcon",
	"name": "AppUIButton",
	"propertiesName": "Properties",
	"triggersName": "Triggers",
	"triggerEventAddButtonId":"5bd59dfa-a082-e9a5-804b-6755668948cd_properties_addTriggerEventButton",
	"propertiesDivId": "5bd59dfa-a082-e9a5-804b-6755668948cd_properties_propertiesDiv",
	"triggersDivId": "5bd59dfa-a082-e9a5-804b-6755668948cd_properties_triggersDiv",
	"triggerSelectId": "5bd59dfa-a082-e9a5-804b-6755668948cd_properties_triggerSelect",
	"elements":["AppUIButton", "AppUIButton1", "AppUIButton2"],
	"properties": [
		{
			"id": "5bd59dfa-a082-e9a5-804b-6755668948cd_properties_propertyInput_labelText",
			"name": "Label Text",
			"value": "New Button",
			"input": "text",
			"inputOptions": []
		},
		{
			"id": "5bd59dfa-a082-e9a5-804b-6755668948cd_properties_propertyInput_labelFont",
			"name": "Label Font",
			"value": "18px Helvetica",
			"input": "font",
			"inputOptions": []
		},
		{
			"id": "5bd59dfa-a082-e9a5-804b-6755668948cd_properties_propertyInput_labelColor",
			"name": "Label Color",
			"value": "black",
			"input": "color",
			"inputOptions": []
		}
	],
	"triggers": [
		{
			"events": [],
			"name": "Triggered",
			"key": "triggered",
			"id": "5bd59dfa-a082-e9a5-804b-6755668948cd_properties_trigger_triggered"
		},
		{
			"events": [],
			"name": "Error",
			"key": "error",
			"id": "5bd59dfa-a082-e9a5-804b-6755668948cd_properties_trigger_error"
		},
		{
			"events": [
				{
					"id": "pressed_0",
					"trashId":pressed_0_trash",
					"key": "pressed",
					"name": "0",
					"targetElementName": "AppUIButton1",
					"abilitySelectId": "pressed_0_abilityOptions",
					"targetAbility": "",
					"targetAbilities": [
						{
							"name": "trigger",
							"value": "trigger"
						},
						{
							"name": "setLabelText",
							"value": "setLabelText"
						},
						{
							"name": "setLabelFont",
							"value": "setLabelFont"
						},
						{
							"name": "setLabelColor",
							"value": "setLabelColor"
						}
					],
					"argumentMappings": [
						{
							"id": "pressed_0_labelText_codeEdit",
							"key": "labelText",
						"editIconId": "",
							"name": "Label Text",
							"value": ""
						}
					],
					"argumentsName": "Arguments",
					"ability": "setLabelText"
				}
			],
			"name": "pressed",
			"key": "pressed",
			"id": "5bd59dfa-a082-e9a5-804b-6755668948cd_properties_trigger_pressed"
		},
		{
			"events": [],
			"name": "released",
			"key": "released",
			"id": "5bd59dfa-a082-e9a5-804b-6755668948cd_properties_trigger_released"
		},
		{
			"events": [],
			"name": "over",
			"key": "over",
			"id": "5bd59dfa-a082-e9a5-804b-6755668948cd_properties_trigger_over"
		}
	]
}
}}

<div id="{{id}}" class="WidgetElementEditor-block-container">

	<div class="WidgetElementEditor-Element-Name-Header">

		<div class="WidgetElementEditor-Element-Name pull-left standard-local-subheader-h4">


				{{elementName}}

		</div>

		<div class="WidgetElementEditor-Element-Name-Link pull-right">

			<a href="{{helpPageLink}}" id="{{helpLinkId}}">

				<div class="WidgetElementEditor-Help-Svg-Container">

					<img src="./Resources/icons/Help.svg" class="standard-svg-button-icon _svg-inject" target="_blank" height="16" width="16" title="help"/>

				</div>		

			</a>

		</div>

	</div>

	<div id="{{mainDivId}}" class="WidgetElementEditor-block-content">

		<div class="WidgetElementEditor-simplebar-container" data-simplebar>
			
			{{#unless isConnector}}
			<div class="WidgetElementEditor-Label standard-local-header-h3">

				{{propertiesName}}

			</div>
			{{/unless}}
			
			<div id="{{propertiesDivId}}">

				<div class="WidgetElementEditor-section">
					
					{{#unless isConnector}}

						<label class="WidgetElementEditor-Label label standard-label">

								{{elementNameLabel}}					

						</label>

						<input id="{{editorId}}" class="form-control WidgetElementEditor-input-box standard-input-text" type="text" value="{{name}}" />

					{{/unless}}

					{{#each properties}}

						<label class="WidgetElementEditor-Label label standard-label">

							{{name}}

						</label>

						{{#switch input}}

							{{#case 'text'}}

								<input id="{{id}}" class="form-control WidgetElementEditor-input-box standard-input-text" type="text" value="{{value}}" />

							{{/case}}

							{{#case 'number'}}

								<input id="{{id}}" class="form-control WidgetElementEditor-input-box standard-input-text" type="text" value="{{value}}" />

							{{/case}}

							{{#case 'color'}}

								<input id="{{id}}" class="form-control colorpicker-element WidgetElementEditor-input-box standard-input-text" value="{{value}}" data-color-format="rgba" type="text" /> 

							{{/case}}

							{{#case 'font'}}

								<input id="{{id}}" class="form-control WidgetElementEditor-input-box standard-input-text" type="text" value="{{value}}" />

							{{/case}}

							{{#case 'image'}}

								<input id="{{id}}" class="form-control WidgetElementEditor-input-box standard-input-text" type="text" value="{{value}}" disabled/>								

								<label class="button btn btn-primary btn-file btn-block WidgetElementEditor-file-upload-button">
									<div class="WidgetElementEditor-file-upload-button-label">{{../browseLabel}}</div>
									<input class="form-control WidgetElementEditor-input-box WidgetElementEditor-file-upload-input" accept=".png,.jpg,.jpeg,.gif" id="{{id}}_fileImporter" name="file" type="file" />
								</label>

							{{/case}}							

							{{#case 'checkbox'}} 
							
								{{#if_eq value true}}

									<label class="standard-switch">

										<input id="{{id}}" type="checkbox" checked data-toggle="toggle" data-onstyle="info" data-size="mini" checked></input>

										<div class="standard-slider round">
											
										</div>

									</label>

								{{else}}

									<label class="standard-switch">

										<input id="{{id}}" type="checkbox" checked data-toggle="toggle" data-onstyle="info" data-size="mini"></input>
										
										<div class="standard-slider round">											
										</div>

									</label>

								{{/if_eq}} 

							{{/case}}

							{{#case 'array'}} {{/case}}

							{{#case 'object'}} {{/case}} 

							{{#case 'code'}}

									<div class="WidgetElementEditor-Input-Code" id="{{id}}">
										<div id="{{id}}_popoutEditor">
											<img src="./Resources/icons/Edit.svg" class="_svg-inject WidgetElementEditor-Input-Code-EditButton" title="Popout Code"/>
										</div>
										
										<div id="{{id}}_aceEditor" class="WidgetElementEditor-Input-Code-AceEditor">
										</div>
									</div>

							{{/case}}

							{{#case 'select'}}

								<select id="{{id}}" class="form-control standard-input-select">

									{{#each inputOptions}}

										<option value="{{value}}">{{name}}</option>

									{{/each}}

								</select>

							{{/case}}
							
							{{#case 'driverInstance'}}
							
								<select id="{{id}}" class="form-control standard-input-select">
								
									{{#each inputOptions}}
									
										<option value="{{value}}">{{name}}</option>
										
									{{/each}}
									
								</select>
								
							{{/case}}

						{{/switch}}

					{{/each}}


				</div>

			</div>

			<div id="{{triggersDivId}}">

				<div class="WidgetElementEditor-header-name">

					<div class="WidgetElementEditor-Label standard-local-header-h3">

						{{triggersName}}

					</div>

					<select id="{{triggerSelectId}}" class="form-control standard-input-select">

						{{#each triggers}}

							<option value="{{key}}">

								{{name}}

							</option>

						{{/each}}

					</select>

					{{#each triggers}}

						<div id="{{id}}">

							{{#each events}}

								{{#if_eq eventError true}}

								<div class="WidgetElementEditor-inner-box WidgetElementEditor-error-box" id="{{id}}">

								{{else}}		

								<div class="WidgetElementEditor-inner-box" id="{{id}}">

								{{/if_eq}}

								<div class="WidgetElementEditor-EventNameAndDelete">
								
									<div>

										<label class="WidgetElementEditor-Ability-Label">
											(#{{name}}) {{targetElementName}}
										</label>

									</div>
								
								</div>

									<div class="WidgetElementEditor-inner-ability-box">

										<label class="WidgetElementEditor-Label standard-label">
											{{abilityTitle}}
										</label>

										<select id="{{abilitySelectId}}" class="form-control standard-input-select">

											{{#each targetAbilities}}

												<option value="{{name}}">

													{{label}}

												</option>

											{{/each}}

										</select>

									</div>

								{{#each argumentMappings}}

									<div class="WidgetElementEditor-inner-ability-box">

										<label class="WidgetElementEditor-Label standard-label">
										
										{{label}}

										</label>

										<div>

											<input id="{{id}}" type="text" value="{{value}}" class="form-control standard-input-text" />

										</div>

									</div>

								{{/each}}

									<div id="{{trashId}}" class="WidgetElementEditor-Delete-Event-Container">
										<div class="WidgetElementEditor-Delete-Element-Button">
										<img src="./Resources/icons/Trash.svg" class="_svg-inject WidgetElementEditor-inner-ability-box-trash" title="Delete Event"/>
									</div></div>

								{{#if_eq eventError true}}

									<div class="WidgetElementEditor-error-message">

										{{eventErrorMessage}}

									</div>

								{{/if_eq}}

							</div>

							{{/each}}

						</div>

					{{/each}}

				</div>

			</div>

			<div id="{{triggerEventAddButtonId}}" class="WidgetElementEditor-Add-Event NoSelect">
				<div class="WidgetElementToolbox-Add-Icon-Div">
					<img src="./Resources/icons/Add.svg" class="standard-svg-button-icon _svg-inject" title="Add Trigger Event" />
					<span class="standard-local-subheader-h4">{{addEventLabel}}</span>
				</div>
			</div>

		</div>

		<div class="WidgetElementEditor-Delete-Element-Container">

			<div id="{{removeElementId}}" data-name="{{name}}" class="WidgetElementEditor-Delete-Element-Button">

				<img src="./Resources/icons/Trash.svg" class="_svg-inject WidgetElementEditor-Delete-Element-Button-Trash" title="Delete Element"/>

			</div>
			
		</div>

	</div>

</div>

`);
$.handlebarTemplates["WidgetElementToolbox"] = Handlebars.compile(`{{!
{	id:"Mainid",
	name:"Element Toolbox",
	showAddButton:true,
	
	categories:[
	{
		id:"appble",
		name: "appble",
		icons: [
			{
				id:'element1_iconid',
				name: "element1",
				src: "http://via.placeholder.com/63x63"
			},
			{
				name: "element2",
				src: "http://via.placeholder.com/63x63"
			},
			{
				name: "element3 second-line",
				src: "http://via.placeholder.com/63x63"
			},
			{
				name: "element4",
				src: "http://via.placeholder.com/63x63"
			}
		]
	},
	{
	name: "appui",
		icons: [
			{
				name: "element5",
				src: "http://via.placeholder.com/63x63"
			},
			{
				name: "element6",
				src: "http://via.placeholder.com/63x63"
			},
			{
				name: "element7",
				src: "http://via.placeholder.com/63x63"
			},
			{
				name: "element8 second-rowww",
				src: "http://via.placeholder.com/63x63"
			}
		]
	}
]};
}}

<div id="{{id}}" class='WidgetElementToolbox-block-container'>
	<div class='WidgetElementToolbox-box-title-container'>
		<div class='WidgetElementToolbox-box-title standard-local-subheader-h4'>
			{{name}}
		</div>
	</div>

	<div class="WidgetElementToolbox-block-content {{showAddButton}}">
		<div class='WidgetElementToolbox-simplebar-container' data-simplebar>
			{{#each categories}}
				<div class='WidgetElementToolbox-category-block'>
					<div
						id="{{id}}"
						class='WidgetElementToolbox-section-title standard-local-header-h3'
					>
						{{name}}
					</div>

					<div class='WidgetElementToolbox-section-grid'>
						{{#each icons}}
							<div class='WidgetElementToolbox-icon-wrap'>
								<div
									id="{{id}}"
									class='WidgetElementToolbox-icon-content'
								>
									<div
										class='WidgetElementToolbox-element-name-container'
									>
										<div
											class='WidgetElementToolbox-element-name-text'
										>
											{{name}}
										</div>
									</div>
								</div>
							</div>
						{{/each}}
					</div>
				</div>
			{{/each}}
		</div>
	</div>

	{{#if showAddButton}}
		<div
			id="{{addButtonId}}"
			class='WidgetElementToolbox-add-event NoSelect'
		>
			<div class='WidgetElementToolbox-Add-Icon-Div'>
				<img
					src='./Resources/icons/Add.svg'
					class='standard-svg-button-icon _svg-inject'
					title='addElementLibrary'
				/>
				<span class='standard-local-subheader-h4'>
					{{addLabel}}
				</span>
			</div>
		</div>
	{{/if}}
</div>`);
$.handlebarTemplates["WidgetProvisioner"] = Handlebars.compile(`<div class="WidgetProvisioner-Top">
	<div class="WidgetProvisioner-Header">
		<div class="WidgetProvisioner-HeaderLabel">
			{{headerLabel}}
		</div>
		<div id="{{rescanButtonId}}" class="WidgetProvisioner-RescanButton">
			<img src="./Resources/icons/Reset.svg" class="_svg-inject WidgetProvisioner-RescanButtonImage"
				title="scanForDevicesSvgTitle"></img>
		</div>
	</div>
	<div id="{{menuContainerId}}" class="WidgetProvisioner-MenuContainer"></div>
	<div id="{{footerContainerId}}" class="WidgetProvisioner-FooterContainer"></div>
	<div id="{{overlayId}}" class="WidgetProvisioner-Overlay">
		<div class="WidgetProvisioner-OverlayLabel">
			{{overlayLabel}}
		</div>
	</div>
	<div id="{{extraSettingsContainerId}}" class="WidgetProvisioner-ExtraSettingsContainer">
	</div>
</div>
`);
$.handlebarTemplates["WidgetProvisioner_DeviceEntry"] = Handlebars.compile(`<div class='WidgetProvisioner-DeviceEntry-Top'>
	<div class='WidgetProvisioner-DeviceEntry-TypeAndMacLabels'>
		<div class='WidgetProvisioner-DeviceEntry-TypeLabel'>
			{{type}}
		</div>
		<div class='WidgetProvisioner-DeviceEntry-MacLabel'>
			({{label}})
		</div>
	</div>
	<div class='WidgetProvisioner-DeviceEntry-ProtocolIcons'>
		{{#if_eq protocol 'ble'}}
			BLE
		{{/if_eq}}
	</div>
</div>`);
$.handlebarTemplates["WidgetElementLibraryBrowser"] = Handlebars.compile(`<div class="WidgetElementLibraryBrowser-Wrapper">
	<div class="WidgetElementLibraryBrowser-Top">
		<div class="WidgetElementLibraryBrowser-TableContainer" id="{{tableContainerId}}">
		</div>
	</div>

	<div id="{{footerContainerId}}" class="WidgetElementLibraryBrowser-footer"></div>
		<input id="{{fileInputId}}" accept=".eel" type="file" class="WidgetElementLibraryBrowser-FileSelector"></input>
</div>`);
$.handlebarTemplates["WidgetElementLibraryBrowser_EnableColumn"] = Handlebars.compile(`<label data-eelenabler="{{libName}}" class="WidgetElementLibraryBrowser-EnableSwitch standard-switch">

	{{#if_eq checked true}}
	<input type="checkbox" checked data-toggle="toggle" data-onstyle="info" data-size="mini"></input>
	{{else}}
	<input type="checkbox" data-toggle="toggle" data-onstyle="info" data-size="mini"></input>
	{{/if_eq}}
	
	<div class="standard-slider round">
		
	</div>

</label>
`);
$.handlebarTemplates["WidgetElementLibraryBrowser_UpdateColumn"] = Handlebars.compile(`{{#if upgradable}}
<div class="WidgetElementLibraryBrowser-UpdateColumn" data-eelupdater="{{libName}}">
	<img src="./Resources/icons/Upgrade.svg" class="WidgetElementLibraryBrowser-UpdateImage _svg-inject" title="{{updateAvailableLabel}}"></img>
</div>
{{/if}}
`);
$.handlebarTemplates["WidgetElementLibraryBrowser_BuyColumn"] = Handlebars.compile(`<div class="WidgetElementLibraryBrowser-UpdateColumn" data-eelbuy="{{eelData}}">
	<img src=" ./Resources/icons/ShoppingCartAdd.svg" class="WidgetElementLibraryBrowser-UpdateImage _svg-inject" title="{{cartData.addToCart}}"></img>
</div>`);
$.handlebarTemplates["LocationBase"] = Handlebars.compile(`<div class='LocationBase-Top'>
	<div class='LocationBase-HeaderContainer'>
		<div class='LocationBase-TitleAndHeaderSideContainer'>
			<div id="{{titleLabelId}}" class='standard-TitleContainer-h1'>
				{{labelTitle}}
			</div>

			<div
				id="{{headerSideContainerId}}"
				class='LocationBase-HeaderSideContainer'
			>
			</div>
		</div>
	</div>

	<div class='LocationBase-TabsContainer'>
		<div class='LocationBase-TabsHeader'>
		</div>

		<div class='LocationBase-TabsContentShadow'>
		</div>

		<div class='LocationBase-TabsContent'>
		</div>
	</div>
</div>`);
$.handlebarTemplates["LocationBase_TabHeader"] = Handlebars.compile(`<div id="{{idHeader}}" class="LocationBase-TabHeaderEntry">

	<img src="{{imageSrc}}" data-tabId="{{tabId}}" class="_svg-inject LocationBase-TabHeaderEntryImage" title="{{label}}"></img>
	<span class="LocationBase-TabHeaderEntryLabel standard-local-subheader-h4">{{label}}</span>
</div>
`);
$.handlebarTemplates["LocationBase_TabContent"] = Handlebars.compile(`<div id="{{idContent}}" data-tabId="{{tabId}}" class='LocationBase-TabContent'>
</div>`);
$.handlebarTemplates["LocationAppView"] = Handlebars.compile(`<div class='LocationAppView-Top'>
	<div id="{{controlsId}}" class='LocationAppView-Controls'></div>

	<div id="{{containerId}}" class='LocationAppView-Container'></div>
</div>`);
$.handlebarTemplates["LocationEmbedded"] = Handlebars.compile(`<iframe src="{{src}}" class='LocationEmbedded-Top'></iframe>`);
$.handlebarTemplates["MainContainer"] = Handlebars.compile(`<div class='MainContainer-Top'>
	<div class='MainContainer-TopNavAndContent'>
		<div class='MainContainer-NavbarAndLocation'>
			<div
				id="{{navbarContainerId}}"
				class='MainContainer-NavbarContainer'
			></div>

			<div class='MainContainer-LocationAndTopNavContainer'>
				<div
					id="{{topNavContainerId}}"
					class='MainContainer-TopNavContainer'
				></div>
				<div
					id="{{locationContainerId}}"
					class='MainContainer-LocationContainer'
				></div>
			</div>
		</div>
	</div>

	<div id="{{modalId}}" class='MainContainer-Modal'>
		<div class='MainContainer-ModalCenter'>
			<div id="{{modalContainerId}}" class='MainContainer-ModalContainer'>
			</div>
		</div>
	</div>

	<div
		id="{{popupContainerId}}"
		class='MainContainer-PopupMessageContainer'
		data-cy="PopupMessage"
	></div>
</div>`);
