function ElementControllerCloudSigfox(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('value', null, null, 'none');
	this.addProperty('timestamp', null, null, 'none');

	this.addTrigger('dataReceived');
}

ElementControllerCloudSigfox.prototype = Object.create(
	ElementControllerCloudBase.prototype,
);
ElementControllerCloudSigfox.prototype.constructor = ElementControllerCloudSigfox;

ElementControllerCloudSigfox.prototype.NAME = 'CloudSigfox';
ElementControllerCloudSigfox.prototype.TYPE = 'CloudSigfox';
ElementControllerCloudSigfox.prototype.HIDDEN = false;
ElementControllerCloudSigfox.prototype.DEFAULT_TRIGGER = 'dataReceived';
ElementControllerCloudSigfox.prototype.DEFAULT_ABILITY = 'trigger';
ElementControllerCloudSigfox.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerCloudSigfox.prototype.COUPLED_TO = 'sigfox';
ElementControllerCloudSigfox.prototype.SINGLETON = true;
ElementControllerCloudSigfox.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudSigfox.prototype.language = deepAssign(
	{},
	ElementControllerCloudBase.prototype.language,
	{
		'en-US': {
			CloudSigfox: 'Sigfox',
			value: 'Value',
			dataReceived: 'Data Received',
			sendDataSet: 'Send Data Set',
			dataSent: 'Data Sent',
			sendData: 'Send Data',
			upstreamDataType: 'Upstream Data Type',
			downstreamDataType: 'Downstream Data Type',
		},
	},
);
