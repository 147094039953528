function ElementAppUIProgressBar(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppUIBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);

	var currentElement = this;

	this._setCrocObject(new CrocProgressBar(this._crocPanel.getRoot()));

	this._onPropertyChange();
}

ElementAppUIProgressBar.prototype = Object.create(ElementAppUIBase.prototype);
ElementAppUIProgressBar.prototype.constructor = ElementAppUIProgressBar;

ElementAppUIProgressBar.prototype._onPropertyChange = function() {
	ElementAppUIBase.prototype._onPropertyChange.call(this);

	this._crocObject.setMaxValue(this._properties.maxValue);
	this._crocObject.setMinValue(this._properties.minValue);
	this._crocObject.setValue(this._properties.value);
};

ElementAppUIProgressBar.prototype.setValue = function(value, callback) {
	value = this._convertToNumber(value);

	value = Math.max(this._properties.minValue, value);
	value = Math.min(this._properties.maxValue, value);

	this._setProperty('value', value, function(err) {
		this._crocObject.setValue(value);
		callback.call(this, err);
		return;
	});
};

ElementAppUIProgressBar.prototype.setMaxValue = function(maxValue, callback) {
	maxValue = this._convertToNumber(maxValue);

	this._setProperty('maxValue', maxValue, function(err) {
		this._crocObject.setMaxValue(maxValue);
		callback.call(this, err);
		return;
	});
};

ElementAppUIProgressBar.prototype.setMinValue = function(minValue, callback) {
	minValue = this._convertToNumber(minValue);

	this._setProperty('minValue', minValue, function(err) {
		this._crocObject.setMinValue(minValue);
		callback.call(this, err);
		return;
	});
};
