function WidgetFindDevice(id, api, parent, options) {
	var currentWidget = this;

	WidgetPanelBase.call(this, id, api, parent, options);

	this.deviceSelectContainerId = this.generateChildId(
		'deviceSelectContainer',
	);

	this.renderTemplate(
		{
			deviceSelectContainerId: this.deviceSelectContainerId,
		},
		WidgetFindDevice.name,
	);
}

WidgetFindDevice.prototype = Object.create(WidgetPanelBase.prototype);
WidgetFindDevice.prototype.constructor = WidgetFindDevice;

WidgetFindDevice.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.addChildWidget(
		WidgetDeviceDataSelect,
		this.deviceSelectContainerId,
		{ onlyDeviceSelect: true, showRowSelected: true },
		function(err, deviceSelect) {
			deviceSelect.addEventListener('event', function(e) {
				if (e.type === 'confirmed') {
					e.data = this.getValues().deviceData;
				}

				currentWidget.event(e.type, e.data);
			});

			deviceSelect.showDeviceSelect((err) => {
				WidgetPanelBase.prototype.initialize.call(this, callback);
			});
		},
	);
};

WidgetFindDevice.prototype.language = deepAssign(
	{},
	WidgetPanelBase.prototype.language,
	{
		'en-US': {
			name: 'Find Device',
		},
	},
);
