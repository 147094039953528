function ElementAppBase(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);
}

ElementAppBase.prototype = Object.create(ElementBase.prototype);
ElementAppBase.prototype.constructor = ElementAppBase;

ElementAppBase.prototype._moveToBack = function() {};

ElementAppBase.prototype._moveToFront = function() {};
