// Copyright 2018 Atmosphere IoT Corp.
// All rights reserved
// jshint esversion: 6

function WidgetDataMeter(id, api, parent, options) {
	
	options = options || {};
	
	WidgetPanelOptionsBase.call(this, id, api, parent, options);

	this.canvasId = this.generateChildId('canvas');
	this.hitCanvasId = this.generateChildId('hitCanvas');

	this.config = {
		deviceId: null,
		title: getLanguageTag(this.constructor, 'name'),
		storageName: null,
		variable: null,
		maxValue: 100,
		minValue: 0,
		prefix: '',
		units: '',
	};

	this.renderTemplate(
		{
			canvasId: this.canvasId,
			hitCanvasId: this.hitCanvasId,
		},
		WidgetDataMeter.name,
	);

	this.canvas = $('#' + this.canvasId);
	this.hitCanvas = $('#' + this.hitCanvasId);

	if (this.canvas[0] === null || this.hitCanvas[0] === null) {
		// FIXME: Find way to replicate the original error and create unit test for it
		throw new Error('AT-3862: canvas and/or hitCanvas are null');
	}

	//This widget doesn't need input events, so we should just
	//not have an event handler at all.
	function DummyCrocEventHandler(root) {}

	this.root = new CrocRoot(
		this.canvas[0],
		this.hitCanvas[0],
		false,
		DummyCrocEventHandler,
	);
	this.panel = new CrocPanel(this.root);
	this.root.addChild(this.panel);
	this.analogMeter = new CrocAnalogMeter(this.root, 0);
	this.analogMeter.setValueLabelToFixed(2);
	this.panel.addChild(this.analogMeter, 16, 16);
	this.root.setWidth(188);
	this.root.setHeight(156);
}

WidgetDataMeter.prototype = Object.create(WidgetPanelOptionsBase.prototype);
WidgetDataMeter.prototype.constructor = WidgetDataMeter;

WidgetDataMeter.prototype.initialize = function(callback) {
	this.update(function(err) {
		WidgetPanelOptionsBase.prototype.initialize.call(this, callback);
	});
};

WidgetDataMeter.prototype.update = function(callback) {
	var currentWidget = this;

	this.setTitle(this.config.title || getLanguageTag(WidgetDataMeter, 'name'));

	this.analogMeter.setValueLabelPrefix(this.config.prefix + ' ');

	this.analogMeter.setValueLabelPostfix(' ' + this.config.units);

	this.analogMeter.setMinValue(this.config.minValue);

	this.analogMeter.setMaxValue(this.config.maxValue);

	if (this.config.deviceId !== undefined && this.config.deviceId !== null) {
		const api = currentWidget.getApiV2().apis;
		api.devices
			.getDevice({ id: currentWidget.config.deviceId })
			.then((device) => {
				this.getAPI()
					.getAPIRoute(
						'/user/thing/:thingUuid/view/storage/:dataset/latestValues',
					)
					.get(
						device.uuid,
						currentWidget.config.storageName,
						function(err, data) {
							if (err && err.status === 0) {
								callback.call(currentWidget, err);
								return;
							}

							if (err) {
								callback.call(currentWidget, err);
								return;
							}

							if (data === null || data === undefined) {
								callback.call(currentWidget, {
									type: 'noData',
								});
								return;
							}

							if (
								data[currentWidget.config.variable] !==
								undefined
							) {
								currentWidget.analogMeter.setValue(
									Number(data[currentWidget.config.variable]),
								);
							}

							callback.call(currentWidget, false);
						},
					);
			})
			.catch((err) => {
				callback.call(currentWidget, err);
			});
	} else {
		callback.call(this, false);
	}
};

WidgetDataMeter.prototype.showSettings = function(callback) {
	callback = callback || function() {};

	var currentWidget = this;

	this.getMainContainer().setModalWidget(
		WidgetSettingsForm,
		{
			fields: [
				{
					name: 'title',
					type: 'text',
					label: getLanguageTag(this.constructor, 'title'),
					value: this.config.title,
				},

				{
					name: 'prefix',
					type: 'text',
					label: getLanguageTag(this.constructor, 'prefix'),
					value: this.config.prefix,
				},

				{
					name: 'units',
					type: 'text',
					label: getLanguageTag(this.constructor, 'units'),
					value: this.config.units,
				},

				{
					name: 'minValue',
					type: 'number',
					label: getLanguageTag(this.constructor, 'minValue'),
					value: this.config.minValue,
				},

				{
					name: 'maxValue',
					type: 'number',
					label: getLanguageTag(this.constructor, 'maxValue'),
					value: this.config.maxValue,
				},

				{
					name: 'variable',
					type: 'deviceVariableSelect',
					label: getLanguageTag(this.constructor, 'variable'),
					value: {
						deviceId: this.config.deviceId,
						storageName: this.config.storageName,
						variables: { value: this.config.variable },
					},
					options: {
						variables: [
							{
								name: 'value',
								label: getLanguageTag(
									this.constructor,
									'value',
								),
							},
						],
					},
				},
			],
		},
		function(err, settingsWidget) {
			settingsWidget.setTitle(
				getLanguageTag(currentWidget.constructor, 'configureDataMeter'),
			);

			settingsWidget.addEventListener('dismissed', function() {
				currentWidget.getMainContainer().hideModal();
			});

			settingsWidget.addEventListener('confirmed', function() {
				currentWidget.getMainContainer().hideModal();

				var values = this.getValues();

				values.variable = values.variable || {};

				var config = {
					deviceId: (values.variable.deviceId || {}).id || null,
					storageName: values.variable.storageName || null,
					variable: (values.variable.variables || {}).value || null,
					title: values.title || '',
					maxValue: values.maxValue || 0,
					minValue: values.minValue || 0,
					prefix: values.prefix || '',
					units: values.units || '',
				};

				currentWidget.setConfiguration(config, function() {
					this.event('configurationSet', {
						widget: this,
						configuration: this.config,
					});
				});
			});

			this.showModal();

			callback.call(this, false);
			return;
		},
	);
};

WidgetDataMeter.prototype.setConfiguration = function(config, callback) {
	callback = callback || function() {};

	this.config = {
		deviceId: config.deviceId || null,
		storageName: config.storageName || null,
		variable: config.variable || null,
		title: config.title || getLanguageTag(this.constructor, 'name'),
		maxValue: config.maxValue || 0,
		minValue: config.minValue || 0,
		prefix: config.prefix || '',
		units: config.units || '',
	};

	this.update(function(err) {
		callback.call(this, err);
	});
};

WidgetDataMeter.prototype.getConfiguration = function() {
	return this.config;
};

WidgetDataMeter.prototype.configure = function(callback) {};

WidgetDataMeter.prototype.PACKERY_SIZE = 'WidgetDashboard_Container_SxS';

WidgetDataMeter.prototype.language = deepAssign(
	{},
	WidgetPanelOptionsBase.prototype.language,
	{
		'en-US': {
			name: 'Data Meter',
			configureDataMeter: 'Configure Data Meter',
			prefix: 'Prefix',
			units: 'Unit',
			minValue: 'Min',
			maxValue: 'Max',
			value: 'Select Value',
			variable: 'Data Source',
		},
	},
);
