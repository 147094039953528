function ElementTest(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);
}

ElementTest.prototype = Object.create(ElementBase.prototype);
ElementTest.prototype.constructor = ElementTest;

ElementTest.prototype.test = function(value) {
	console.log(value);
};
