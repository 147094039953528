function ElementControllerEmbeddedNfcWellKnown(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addVariable('record', 'ATMO_NFC_Record_t');
	this.addVariable('recordHandle', 'unsigned int');

	this.addProperty('instance', null, 0, 'driverInstance', null, 'nfc');

	var nfcWellKnownTypes = [
		'ATMO_NFC_TYPE_WELLKNOWN_TEXT',
		'ATMO_NFC_TYPE_WELLKNOWN_URL',
	];

	this.addProperty(
		'type',
		null,
		'ATMO_NFC_TYPE_WELLKNOWN_TEXT',
		'select',
		nfcWellKnownTypes,
		null,
	);

	this.addProperty('defaultValue', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('defaultValue', 'string');

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
    ATMO_NFC_RegisterStoredRecord(ATMO_PROPERTY(undefined, instance), &ATMO_VARIABLE(undefined, record), &ATMO_VARIABLE(undefined, recordHandle));

    if(ATMO_PROPERTY(undefined, type) == ATMO_NFC_TYPE_WELLKNOWN_TEXT)
    {
        ATMO_NFC_MakeRecordText(&ATMO_VARIABLE(undefined, record), ATMO_PROPERTY(undefined, defaultValue));
    }
    else
    {
        ATMO_NFC_MakeRecordURL(&ATMO_VARIABLE(undefined, record), ATMO_PROPERTY(undefined, defaultValue));
    }

    ATMO_NFC_SyncAtmoRecords(ATMO_PROPERTY(undefined, instance));

	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setRecord', [], ['recordSet'], false, false);
	this.setAbilityCode(
		'setRecord',
		`
    char str[128];

    if( ATMO_GetString(in, str, 128) != ATMO_Status_Success )
    {
        return ATMO_Status_Fail;
    }

    if(ATMO_PROPERTY(undefined, type) == ATMO_NFC_TYPE_WELLKNOWN_TEXT)
    {
        ATMO_NFC_MakeRecordText(&ATMO_VARIABLE(undefined, record), str);
    }
    else
    {
        ATMO_NFC_MakeRecordURL(&ATMO_VARIABLE(undefined, record), str);
    }

    ATMO_NFC_SyncAtmoRecords(ATMO_PROPERTY(undefined, instance));
	return ATMO_Status_Success;
	`,
	);
}

ElementControllerEmbeddedNfcWellKnown.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedNfcWellKnown.prototype.constructor = ElementControllerEmbeddedNfcWellKnown;

ElementControllerEmbeddedNfcWellKnown.prototype.NAME = 'WellKnownRecord';
ElementControllerEmbeddedNfcWellKnown.prototype.TYPE = 'EmbeddedNfcWellKnown';
ElementControllerEmbeddedNfcWellKnown.prototype.HIDDEN = false;
ElementControllerEmbeddedNfcWellKnown.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'nfc',
];
ElementControllerEmbeddedNfcWellKnown.prototype.DEFAULT_ABILITY = 'setRecord';
ElementControllerEmbeddedNfcWellKnown.prototype.DEFAULT_TRIGGER = 'recordSet';
ElementControllerEmbeddedNfcWellKnown.prototype.CATEGORY = 'nfc';
ElementControllerEmbeddedNfcWellKnown.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedNfcWellKnown.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedNfcWellKnown: 'NFC Well Known Record',
			setRecord: 'Set Record Data',
			recordSet: 'Record Data Set',
			ATMO_NFC_TYPE_WELLKNOWN_TEXT: 'Text',
			ATMO_NFC_TYPE_WELLKNOWN_URL: 'URL',
			defaultValue: 'Default Value',
		},
	},
);
