function ElementControllerEmbeddedOperation(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	var operations = [
		'ATMO_OPERATOR_ADD',
		'ATMO_OPERATOR_SUBTRACT',
		'ATMO_OPERATOR_MULTIPLY',
		'ATMO_OPERATOR_DIVIDE',
	];

	this.addProperty(
		'operation',
		null,
		'ATMO_OPERATOR_ADD',
		'select',
		operations,
		null,
	);

	this.addProperty('operand', null, '1', 'number', null, null);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility(
		'performOperation',
		[],
		['operationPerformed'],
		false,
		false,
	);
	this.setAbilityCode(
		'performOperation',
		`
    return ATMO_PerformOperation(out, ATMO_PROPERTY(undefined, operation), ATMO_PROPERTY(undefined, operand), in);
    `,
	);
}

ElementControllerEmbeddedOperation.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedOperation.prototype.constructor = ElementControllerEmbeddedOperation;

ElementControllerEmbeddedOperation.prototype.NAME = 'Operation';
ElementControllerEmbeddedOperation.prototype.TYPE = 'EmbeddedOperation';
ElementControllerEmbeddedOperation.prototype.HIDDEN = false;
ElementControllerEmbeddedOperation.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
];
ElementControllerEmbeddedOperation.prototype.DEFAULT_ABILITY =
	'performOperation';
ElementControllerEmbeddedOperation.prototype.DEFAULT_TRIGGER =
	'operationPerformed';

ElementControllerEmbeddedOperation.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedOperation.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedOperation: 'Mathematical Operation',
			operation: 'Operation',
			operand: 'Operand',
			performOperation: 'Perform Operation',
			operationPerformed: 'Operation Performed',
			ATMO_OPERATOR_ADD: '+',
			ATMO_OPERATOR_SUBTRACT: '-',
			ATMO_OPERATOR_MULTIPLY: '*',
			ATMO_OPERATOR_DIVIDE: '/',
		},
	},
);
