function WidgetUserAccountSecurityAuthenticationTokensTable(
	id,
	api,
	parent,
	options,
) {
	WidgetTable.call(this, id, api, parent, options);
}

WidgetUserAccountSecurityAuthenticationTokensTable.prototype = Object.create(
	WidgetTable.prototype,
);
WidgetUserAccountSecurityAuthenticationTokensTable.prototype.constructor = WidgetUserAccountSecurityAuthenticationTokensTable;

WidgetUserAccountSecurityAuthenticationTokensTable.prototype.language = deepAssign(
	{},
	WidgetTable.prototype.language,
	{
		'en-US': {
			noData: "You don't have any tokens!",
		},
	},
);
