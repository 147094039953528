function PlaneControllerEmbeddedSTM32L476RGNucleo(
	parentController,
	elementName,
) {
	PlaneControllerEmbeddedStm32L4.call(this, parentController, elementName);
}

PlaneControllerEmbeddedSTM32L476RGNucleo.prototype = Object.create(
	PlaneControllerEmbeddedStm32L4.prototype,
);
PlaneControllerEmbeddedSTM32L476RGNucleo.prototype.constructor = PlaneControllerEmbeddedSTM32L476RGNucleo;

PlaneControllerEmbeddedSTM32L476RGNucleo.prototype.VARIANTS = [
	'stm32l4',
	'STM32L476RGNucleo',
];
PlaneControllerEmbeddedSTM32L476RGNucleo.prototype.SUPPORTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'interval',
	'variables',
	'adc',
	'gpio',
	'i2c',
	'spi',
	'fpmath',
	'stm32',
	'log',
	'debugPrint',
];
PlaneControllerEmbeddedSTM32L476RGNucleo.prototype.CLIENT_AGENT_REQUIRED = false;

PlaneControllerEmbeddedSTM32L476RGNucleo.prototype.DRIVERS = {
	adc: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_ADC_ADC1',
		},
	],
	ble: [],
	block: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLOCK_BLOCK1',
		},
	],
	datetime: [],
	filesytem: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_FILESYSTEM_FILESYSTEM1',
		},
	],
	gpio: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_GPIO_GPIO1',
		},
	],
	http: [],
	i2c: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C1',
			primary: true,
		},
	],
	interval: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_INTERVAL_INTERVAL1',
		},
	],
	nfc: [],
	pwm: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_PWM_PWM1',
		},
	],
	spi: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_SPI_SPI1',
		},
	],
	uart: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_UART_UART1',
		},
	],
	wifi: [],
};

PlaneControllerEmbeddedSTM32L476RGNucleo.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'D13',
		mode: 'ATMO_GPIO_PinMode_Output_PushPull',
	},

	EmbeddedBlueNRG: {
		gpioDriverInstance: 0,
		spiDriverInstance: 0,
		rstPin: 'D7',
		intPin: 'A0',
		csPin: 'A1',
	},

	EmbeddedADCPin: {
		pin: 'A0',
	},

	EmbeddedGroveLight: {
		adcInstance: 0,
		adcPin: 'A0',
	},

	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'D13',
	},

	EmbeddedGroveTemperature: {
		adcInstance: 0,
		adcPin: 'A0',
	},

	EmbeddedGroveRotaryAngle: {
		adcInstance: 0,
		adcPin: 'A0',
	},

	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'D13',
	},

	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'D13',
	},

	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'D13',
	},
};

PlaneControllerEmbeddedSTM32L476RGNucleo.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			defaultName: 'NUCLEO-L476RG',
			ATMO_DRIVERINSTANCE_SPI_SPI1: 'SPI1',
		},
	},
);
