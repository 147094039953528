function PlaneControllerEmbeddedNordic(parentController, elementName) {
	PlaneControllerEmbeddedBase.call(this, parentController, elementName);
}

PlaneControllerEmbeddedNordic.prototype = Object.create(
	PlaneControllerEmbeddedBase.prototype,
);
PlaneControllerEmbeddedNordic.prototype.constructor = PlaneControllerEmbeddedNordic;

PlaneControllerEmbeddedNordic.prototype.TYPE = 'Nordic';
PlaneControllerEmbeddedNordic.prototype.VARIANTS = [];
PlaneControllerEmbeddedNordic.prototype.SUPPORTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'interval',
	'variables',
	'fpmath',
	'ble',
	'gpio',
	'adc',
	'i2c',
	'spi',
	'pwm',
	'nordic',
	'uart',
	'debugPrint',
	'uptime',
	'log',
];
PlaneControllerEmbeddedNordic.prototype.SELECTABLE_VARIANTS = [];

PlaneControllerEmbeddedNordic.prototype.FIRMWARE_DOWNLOAD_FILES = {
	artifactName: 'firmware',
	fileName: 'atmosphere-project.hex',
};

PlaneControllerEmbeddedNordic.prototype.DRIVERS = {
	adc: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_ADC_ADC1',
		},
	],
	ble: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLE_BLE1',
		},
	],
	block: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLOCK_BLOCK1',
		},
	],
	embeddedCloud: [],
	datetime: [],
	filesytem: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_FILESYSTEM_FILESYSTEM1',
		},
	],
	gpio: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_GPIO_GPIO1',
		},
	],
	http: [],
	i2c: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C1',
			primary: true,
		},
	],
	interval: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_INTERVAL_INTERVAL1',
		},
	],
	nfc: [],
	pwm: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_PWM_PWM1',
		},
	],
	spi: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_SPI_SPI1',
		},
	],
	uart: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_UART_UART1',
		},
		{
			id: 1,
			name: 'ATMO_DRIVERINSTANCE_UART_UART2',
		},
	],
	wifi: [],
};

PlaneControllerEmbeddedNordic.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: '0',
	},

	EmbeddedPWMPin: {
		instance: 0,
		pin: '0',
	},

	EmbeddedADCPin: {
		instance: 0,
		pin: '0',
	},

	EmbeddedUART: {
		baudRate: 'ATMO_UART_BaudRate_115200',
	},
};

PlaneControllerEmbeddedNordic.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			defaultName: 'Nordic',
			ATMO_DRIVERINSTANCE_UART_UART1: 'USB UART',
			ATMO_DRIVERINSTANCE_UART_UART2: 'UART2',
		},
	},
);
