function ElementAppBLEScanner(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);

	var currentElement = this;

	this._pluginBLECentral = requires.PluginBLECentral;

	this._autoConnect = false;
	this._connectToLocalName = null;

	/*
	 * Example of advertisingData
	 *
	 * {
	 *	"address":"84683e0570b9",
	 * 	"mac":"84:68:3e:05:70:b9", //optional
	 * 	"connectable":true,
	 * 	"rssi":-63,
	 * 	"localName":"sLight",
	 * 	"primaryServiceUuid":"300692ab-0b5e-410b-8606-ed3a69d9f9c1",
	 * 	"serviceUuids":["300692ab-0b5e-410b-8606-ed3a69d9f9c1"]
	 * }
	 */
	this._currentAdvertisementListener = this._pluginBLECentral.addEventListener(
		'advertisement',
		function(advertisingData) {
			currentElement._setProperty(
				'advertisingData',
				advertisingData,
				function(err) {
					console.log(JSON.stringify(advertisingData));

					currentElement.trigger('advertisement');

					if (
						advertisingData.localName !== null &&
						advertisingData.localName ===
							currentElement._connectToLocalName
					) {
						currentElement._pluginBLECentral.connectToDeviceByAddress(
							advertisingData.address,
							function(err) {
								currentElement.trigger('connected');
							},
						);
					}

					return;
				},
			);
		},
	);

	this._currentScanningStartedListener = this._pluginBLECentral.addEventListener(
		'scanningStarted',
		function() {
			currentElement.trigger('scanningStarted');
			return;
		},
	);

	this._currentScanningStoppedListener = this._pluginBLECentral.addEventListener(
		'scanningStopped',
		function() {
			currentElement.trigger('scanningStopped');
			return;
		},
	);

	if (properties.autoConnect) {
		this.connectToDeviceByLocalName(properties.localName, function() {});
	}
}

ElementAppBLEScanner.prototype = Object.create(ElementBase.prototype);
ElementAppBLEScanner.prototype.constructor = ElementAppBLEScanner;

ElementAppBLEScanner.prototype.REQUIRES = ['PluginBLECentral'];

ElementAppBLEScanner.prototype._remove = function() {
	this._pluginBLECentral.removeEventListener(
		'advertisement',
		this._currentAdvertisementListener,
	);
	this._pluginBLECentral.removeEventListener(
		'scanningStarted',
		this._currentScanningStartedListener,
	);
	this._pluginBLECentral.removeEventListener(
		'scanningStopped',
		this._currentScanningStoppedListener,
	);

	this._pluginBLECentral.stopScanning(function() {});
};

ElementAppBLEScanner.prototype.connectToDeviceByLocalName = function(
	localName,
	callback,
) {
	this._connectToLocalName = localName || this._connectToLocalName;

	this.startScanning(callback);
};

ElementAppBLEScanner.prototype.isScanning = function(callback) {
	var currentElement = this;

	this._pluginBLECentral.isScanning(function(err, data) {
		if (err) {
			currentElement._triggerError(err);
			callback.call(currentElement, err);
			return;
		}

		currentElement._setProperty('scanning', data, function(err) {
			this.trigger('isScanningChecked', callback);
			return;
		});
	});
};

ElementAppBLEScanner.prototype.startScanning = function(callback) {
	var currentElement = this;

	this._pluginBLECentral.startScanning(function(err) {
		if (err) {
			currentElement._triggerError(err);
			callback.call(currentElement, err);
			return;
		}

		callback.call(currentElement, false);
		return;
	});
};

// FIXME: Be good to just keep scan till we hit something specific.
ElementAppBLEScanner.prototype.scanTillFound = function(
	localName,
	serviceUuids,
	manufacturerSpecific,
	callback,
) {};

ElementAppBLEScanner.prototype.stopScanning = function(callback) {
	var currentElement = this;

	this._pluginBLECentral.stopScanning(function(err) {
		if (err) {
			currentElement._triggerError(err);
			callback.call(currentElement, err);
			return;
		}

		callback.call(currentElement, false);
		return;
	});
};
