function PlaneControllerEmbeddedMcuxpresso(parentController, elementName) {
	PlaneControllerEmbeddedBase.call(this, parentController, elementName);
}

PlaneControllerEmbeddedMcuxpresso.prototype = Object.create(
	PlaneControllerEmbeddedBase.prototype,
);
PlaneControllerEmbeddedMcuxpresso.prototype.constructor = PlaneControllerEmbeddedMcuxpresso;

PlaneControllerEmbeddedMcuxpresso.prototype.TYPE = 'mcuxpresso';
PlaneControllerEmbeddedMcuxpresso.prototype.VARIANTS = [];
PlaneControllerEmbeddedMcuxpresso.prototype.SUPPORTS = [
	'embedded',
	'gpio',
	'adc',
	'i2c',
	'spi',
	'uart',
	'fpmath',
];
PlaneControllerEmbeddedMcuxpresso.prototype.SELECTABLE_VARIANTS = [];

PlaneControllerEmbeddedMcuxpresso.prototype.language = {
	'en-US': {
		defaultName: 'McuXpresso',
	},
};
