function PlaneControllerEmbeddedAvrIoT(parentController, elementName) {
	PlaneControllerEmbeddedBase.call(this, parentController, elementName);
}

PlaneControllerEmbeddedAvrIoT.prototype = Object.create(
	PlaneControllerEmbeddedBase.prototype,
);
PlaneControllerEmbeddedAvrIoT.prototype.constructor = PlaneControllerEmbeddedAvrIoT;

PlaneControllerEmbeddedAvrIoT.prototype.TYPE = 'avriot';
PlaneControllerEmbeddedAvrIoT.prototype.VARIANTS = [];
PlaneControllerEmbeddedAvrIoT.prototype.SUPPORTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'interval',
	'variables',
	'fpmath',
	'gpio',
	'adc',
	'i2c',
	'spi',
	'avriot',
	'uart',
	'http',
	'wifi',
	'log',
	'debugPrint',
];
PlaneControllerEmbeddedAvrIoT.prototype.SELECTABLE_VARIANTS = [];
PlaneControllerEmbeddedAvrIoT.prototype.CLIENT_AGENT_REQUIRED = false;

PlaneControllerEmbeddedAvrIoT.prototype.DRIVERS = {
	adc: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_ADC_ADC1',
		},
	],
	ble: [],
	block: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLOCK_BLOCK1',
		},
	],
	embeddedCloud: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_CLOUD_CLOUD1',
		},
	],
	datetime: [],
	filesytem: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_FILESYSTEM_FILESYSTEM1',
		},
	],
	gpio: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_GPIO_GPIO1',
		},
	],
	http: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_HTTP_HTTP1',
			primary: true,
		},
	],
	i2c: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C1',
			primary: true,
		},
	],
	interval: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_INTERVAL_INTERVAL1',
		},
	],
	nfc: [],
	pwm: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_PWM_PWM1',
		},
	],
	spi: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_SPI_SPI1',
		},
	],
	uart: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_UART_UART1',
		},
	],
	wifi: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_WIFI_WIFI1',
		},
	],
};

PlaneControllerEmbeddedAvrIoT.prototype.FIRMWARE_DOWNLOAD_FILES = {
	artifactName: 'firmware',
	fileName: 'Atmosphere_Project.hex',
};

PlaneControllerEmbeddedAvrIoT.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'P_PWM',
		mode: 'ATMO_GPIO_PinMode_Output_PushPull',
	},

	EmbeddedPWMPin: {
		instance: 0,
		pin: 'P_PWM',
	},

	EmbeddedADCPin: {
		instance: 0,
		pin: 'P_AN',
	},
	EmbeddedTemt6000: {
		adcPin: 'P_LIGHT_SENS',
		pullDownValue: 10000,
	},

	EmbeddedGroveLight: {
		adcDriverInstance: 0,
		adcPin: 'P_AN',
	},

	EmbeddedGroveTemperature: {
		adcInstance: 0,
		adcPin: 'P_AN',
	},

	EmbeddedGroveRotaryAngle: {
		adcInstance: 0,
		adcPin: 'P_AN',
	},

	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'P_PWM',
	},

	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'P_PWM',
	},

	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'P_PWM',
	},
};

PlaneControllerEmbeddedAvrIoT.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			defaultName: 'AVR-IoT WG',
			ATMO_DRIVERINSTANCE_CLOUD_CLOUD1: 'WiFi (Internal)',
		},
	},
);
