function WidgetSystemAdministrationConfigurations(id, api, parent, options) {
	WidgetBase.call(this, id, api, parent, options);

	// Internal Data Structures
	this.systemConfigurationsData = null;
	this.currentlySelectedSubSystem = null;

	// ID Assignment
	this.subSystemsListId = this.generateChildId('subSystemsListId');
	this.subSystemsDetailsId = this.generateChildId('subSystemsDetailsId');

	this.addSubSystemButtonId = this.generateChildId('addSubSystemButtonId');
	this.addSubSystemConfigurationButtonId = this.generateChildId(
		'addSubSystemConfigurationButtonId',
	);
	this.importConfigurationsButtonId = this.generateChildId(
		'importConfigurationsButtonId',
	);
	this.importConfigurationsFileInputId = this.generateChildId(
		'importConfigurationsFileInputId',
	);
	this.exportConfigurationsButtonId = this.generateChildId(
		'exportConfigurationsButtonId',
	);
	this.removeSubSystemConfigurationsButtonId = this.generateChildId(
		'removeSubSystemConfigurationsButtonId',
	);
}

WidgetSystemAdministrationConfigurations.prototype = Object.create(
	WidgetBase.prototype,
);
WidgetSystemAdministrationConfigurations.prototype.constructor = WidgetSystemAdministrationConfigurations;

WidgetSystemAdministrationConfigurations.prototype.initialize = function(
	callback,
) {
	var currentWidget = this;

	this.update(function(err, data) {
		if (err) {
			callback.call(currentWidget, err);
			return;
		}

		WidgetBase.prototype.initialize.call(this, callback);
		return;
	});
};

WidgetSystemAdministrationConfigurations.prototype.update = function(callback) {
	var currentWidget = this;

	this.getAPI()
		.getAPIRoute('/admin/configuration')
		.get(function(err, data) {
			if (err) {
				callback.call(currentWidget, err);
				return;
			}

			currentWidget.systemConfigurationsData = data;

			currentWidget.render(function(err, $selectedSubSystemTreeEntry) {
				if (err) {
					callback.call(currentWidget, err);
					return;
				}

				if ($selectedSubSystemTreeEntry) {
					$selectedSubSystemTreeEntry.click();
				}

				callback.call(currentWidget, false, data);
				return;
			});
		});
};

WidgetSystemAdministrationConfigurations.prototype.getTemplateContext = function(
	configurationData,
	callback,
) {
	var currentWidget = this;

	callback = callback || function() {};

	var context = {};

	context.rootIds = {
		subSystemsListId: this.subSystemsListId,
		subSystemDetailsId: this.subSystemsDetailsId,
		addSubSystemButtonId: this.addSubSystemButtonId,
		addSubSystemConfigurationButtonId: this
			.addSubSystemConfigurationButtonId,
		importConfigurationsButtonId: this.importConfigurationsButtonId,
		importConfigurationsFileInputId: this.importConfigurationsFileInputId,
		exportConfigurationsButtonId: this.exportConfigurationsButtonId,
		removeSubSystemConfigurationsButtonId: this
			.removeSubSystemConfigurationsButtonId,
	};

	context.language = {
		newSubSystem: getLanguageTag(this.constructor, 'newSubSystem'),
		addSubSystemConfiguration: getLanguageTag(
			this.constructor,
			'addToSubSystem',
		),
		importSystemConfigurations: getLanguageTag(
			this.constructor,
			'importConfigurations',
		),
		exportSystemConfigurations: getLanguageTag(
			this.constructor,
			'exportConfigurations',
		),
		removeSubsystemConfigurations: getLanguageTag(
			this.constructor,
			'removeSubsystemConfigurations',
		),
	};

	context.subSystemsList = [];
	context.subSystemsDetails = [];

	for (var subSystemName in configurationData) {
		var currentSubSystemData = configurationData[subSystemName];

		var detailsId = this.generateChildId(subSystemName + '_details');
		var inputs = [];

		for (var tag in currentSubSystemData) {
			var value = currentSubSystemData[tag];
			var type;
			var renderedData;

			if (value === '_blank') {
				// Skip placeholders
				continue;
			}

			switch (value) {
				case undefined:
					type = 'undefined';
					break;

				case null:
					type = 'null';
					break;

				default:
					type = (value.constructor || {}).name;
					break;
			}

			var inputDataObject = {
				rootId: this.generateChildId(`${detailsId}_${tag}`),
				system: subSystemName,
				tag: tag,
				type: type,
				value: value,
				renderedData: renderedData,
				actionButtons: {
					edit: {
						id: this.generateChildId(
							`${detailsId}_${tag}_editButton`,
						),
						label: getLanguageTag(
							WidgetSystemAdministrationConfigurations,
							'edit',
						),
					},
					delete: {
						id: this.generateChildId(
							`${detailsId}_${tag}_deleteButton`,
						),
						label: getLanguageTag(
							WidgetSystemAdministrationConfigurations,
							'delete',
						),
					},
				},
			};

			switch (String(inputDataObject.type)) {
				case 'Boolean':
					inputDataObject.renderedData = inputDataObject.value;
					break;

				case 'Number':
					inputDataObject.renderedData = inputDataObject.value;
					break;

				case 'String':
					inputDataObject.renderedData = inputDataObject.value;
					break;

				case 'Object':
					inputDataObject.renderedData = JSON.stringify(
						inputDataObject.value,
						null,
						2,
					);
					break;

				case 'Array':
					inputDataObject.renderedData = JSON.stringify(
						inputDataObject.value,
						null,
						2,
					);
					break;

				case 'undefined':
					inputDataObject.renderedData = 'undefined';
					break;

				case 'null':
					inputDataObject.renderedData = 'null';
					break;
			}

			inputs.push(inputDataObject);
		}

		context.subSystemsDetails.push({
			id: detailsId,
			name: subSystemName,
			data: currentSubSystemData,
			inputs: inputs.sort((a, b) =>
				a.tag > b.tag ? 1 : b.tag > a.tag ? -1 : 0,
			),
		});

		var targetId = `#${detailsId}`;

		context.subSystemsList.push({
			id: this.generateChildId(subSystemName),
			targetId: targetId,
			name: subSystemName,
		});
	}

	context.subSystemsDetails.sort((a, b) =>
		a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
	);
	context.subSystemsList.sort((a, b) =>
		a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
	);

	callback.call(this, false, context);
	return context;
};

WidgetSystemAdministrationConfigurations.prototype.render = function(callback) {
	var currentWidget = this;

	callback = callback || function() {};

	var context = this.getTemplateContext(
		this.systemConfigurationsData,
		function() {},
	);
	var container = this.getContainer();

	this.renderTemplate(
		context,
		WidgetSystemAdministrationConfigurations.name,
		container,
	);

	this.$addSubSystemButton = $(`#${this.addSubSystemButtonId}`);
	this.$addSubSystemConfigurationButton = $(
		`#${this.addSubSystemConfigurationButtonId}`,
	);
	this.$exportConfigurationsButton = $(
		`#${this.exportConfigurationsButtonId}`,
	);
	this.$importConfigurationsButton = $(
		`#${this.importConfigurationsButtonId}`,
	);
	this.$importConfigurationsFileInput = $(
		`#${this.importConfigurationsFileInputId}`,
	);
	this.$removeSubSystemConfigurationsButton = $(
		`#${this.removeSubSystemConfigurationsButtonId}`,
	);

	this.$addSubSystemButton.off();
	this.$addSubSystemButton.on('click', function(e) {
		currentWidget.onAddSubSystemButtonClicked(e);
	});

	this.$addSubSystemConfigurationButton.off();
	this.$addSubSystemConfigurationButton.on('click', function(e) {
		currentWidget.onAddSubSystemConfigurationButtonClicked(e);
	});

	this.$exportConfigurationsButton.off();
	this.$exportConfigurationsButton.on('click', function(e) {
		currentWidget.onExportConfigurationsButtonClicked(e);
	});

	// this.$importConfigurationsButton.off();
	// this.$importConfigurationsButton.on('click', function(e){
	// 	currentWidget.onImportConfigurationsButtonClicked(e);
	// });

	this.$importConfigurationsFileInput.off();
	this.$importConfigurationsFileInput.on('change', function(e) {
		e.preventDefault();
		e.stopPropagation();

		var file = e.target.files[0];

		if (file) {
			currentWidget.verifyConfigurationData(file, function(
				err,
				verifiedData,
			) {
				if (err && err.type) {
					currentWidget
						.getMainContainer()
						.showPopupErrorMessage(
							getLanguageTag(
								WidgetSystemAdministrationConfigurations,
								err.type,
							),
						);
					return;
				} else if (err) {
					currentWidget
						.getMainContainer()
						.showPopupErrorMessage(JSON.stringify(err));
					return;
				}

				currentWidget.openImportConfigurationsMenu(
					verifiedData,
					function(err, data) {
						if (err) {
							currentWidget
								.getMainContainer()
								.showPopupErrorMessage(err, function() {});
							return;
						}

						var resultMessage = data;

						currentWidget.update(function(err, data) {
							currentWidget
								.getMainContainer()
								.showPopupInfoMessage(
									resultMessage,
									function() {},
								);
							return;
						});
					},
				);
			});
		}
	});

	this.$removeSubSystemConfigurationsButton.off();
	this.$removeSubSystemConfigurationsButton.on('click', function(e) {
		currentWidget.onRemoveSubSystemConfigurationsButtonClicked(e);
	});

	var $selectedSubSystemTreeEntry = null;

	for (var u = 0; u < context.subSystemsList.length; u++) {
		var currentSubSystemListEntry = context.subSystemsList[u];
		var $currentSubSystemListEntry = $(`#${currentSubSystemListEntry.id}`);

		$currentSubSystemListEntry.data(currentSubSystemListEntry);

		if (
			currentWidget.currentlySelectedSubSystem ===
			$currentSubSystemListEntry.data('name')
		) {
			$selectedSubSystemTreeEntry = $currentSubSystemListEntry;
		}

		$currentSubSystemListEntry.off();
		$currentSubSystemListEntry.on('click', function(e) {
			var eventData = $(this).data();
			currentWidget.currentlySelectedSubSystem = eventData.name;
		});
	}

	for (var i = 0; i < context.subSystemsDetails.length; i++) {
		var currentSubSystemInputs = context.subSystemsDetails[i].inputs;

		for (var j = 0; j < currentSubSystemInputs.length; j++) {
			var currentSubsystemInput = currentSubSystemInputs[j];
			var actionButtons = currentSubsystemInput.actionButtons;
			var $editButton = $(`#${actionButtons.edit.id}`);
			var $deleteButton = $(`#${actionButtons.delete.id}`);

			$editButton.data(currentSubsystemInput);
			$deleteButton.data(currentSubsystemInput);

			$editButton.off();
			$editButton.on('click', function(e) {
				var eventData = $(this).data();
				currentWidget.onSystemTagEditButtonClicked(eventData);
			});

			$deleteButton.off();
			$deleteButton.on('click', function(e) {
				var eventData = $(this).data();
				currentWidget.onSystemTagDeleteButtonClicked(eventData);
			});
		}
	}

	callback.call(this, false, $selectedSubSystemTreeEntry);
	return;
};

WidgetSystemAdministrationConfigurations.prototype.onAddSubSystemButtonClicked = function(
	eventData,
) {
	var currentWidget = this;

	var options = {
		label: getLanguageTag(this.constructor, 'pleaseNameYourSubsystem'),
	};
	var repliedCallback = function(reply) {
		if (reply) {
			var system = reply;
			var tag = '_blank';
			var value = '_blank';

			currentWidget.addSystemConfiguration(system, tag, value, function(
				err,
				data,
			) {
				if (err) {
					currentWidget
						.getMainContainer()
						.showPopupErrorMessage(err, function() {});
					return;
				}

				var systemString = `(${system})`;
				var successMessage = formatString(
					getLanguageTag(
						currentWidget.constructor,
						'subsystemHasBeenAdded',
					),
					systemString,
				);

				currentWidget.update(function(err, data) {
					currentWidget
						.getMainContainer()
						.showPopupInfoMessage(successMessage, function() {});
				});
			});
		}
	};

	var dismissedCallback = function() {};
	var callback = function() {};

	this.getMainContainer().showPrompt(
		options,
		repliedCallback,
		dismissedCallback,
		callback,
	);
};

WidgetSystemAdministrationConfigurations.prototype.onAddSubSystemConfigurationButtonClicked = function(
	eventData,
) {
	this.openConfigurationMenu({});
};

WidgetSystemAdministrationConfigurations.prototype.onExportConfigurationsButtonClicked = function(
	eventData,
) {
	var currentWidget = this;

	var popupMessage = getLanguageTag(
		currentWidget.constructor,
		'exportingSystemConfigurationsPleaseWait',
	);
	this.getMainContainer().showPopupMessage(
		popupMessage,
		'info',
		null,
		function() {},
	);

	this.exportSystemConfigurations(function(err, exportedConfigData) {
		if (err && err.type) {
			currentWidget
				.getMainContainer()
				.showPopupErrorMessage(
					getLanguageTag(
						WidgetSystemAdministrationConfigurations,
						err.type,
					),
				);
			return;
		} else if (err) {
			currentWidget
				.getMainContainer()
				.showPopupErrorMessage(JSON.stringify(err));
			return;
		}

		popupMessage = formatString(
			getLanguageTag(
				currentWidget.constructor,
				'systemConfigurationsHaveBeenExportedAs',
			),
			exportedConfigData.fileName,
		);
		this.getMainContainer().showPopupMessage(
			popupMessage,
			'green',
			6000,
			function() {},
		);
		return;
	});
};

WidgetSystemAdministrationConfigurations.prototype.openImportConfigurationsMenu = function(
	verifiedData,
	callback,
) {
	var currentWidget = this;

	var options = {
		label: getLanguageTag(this.constructor, 'importAsOverlayYesOrNo'),
	};
	var repliedCallback = function(reply) {
		if (reply) {
			var overlay = reply.toUpperCase().startsWith('Y') ? true : false;

			currentWidget.importConfigurationData(
				overlay,
				verifiedData,
				function(err, data) {
					if (err) {
						callback.call(currentWidget, err, null);
						return;
					}

					var successMessage = getLanguageTag(
						currentWidget.constructor,
						'configurationsHaveBeenImported',
					);
					callback.call(currentWidget, false, successMessage);
					return;
				},
			);
		} else {
			callback.call(currentWidget, false, null);
			return;
		}
	};

	var dismissedCallback = function() {};
	callback = callback || function() {};

	this.getMainContainer().showPrompt(
		options,
		repliedCallback,
		dismissedCallback,
		callback,
	);
};

WidgetSystemAdministrationConfigurations.prototype.onRemoveSubSystemConfigurationsButtonClicked = function(
	eventData,
) {
	var currentWidget = this;

	if (!this.currentlySelectedSubSystem) {
		return;
	}

	var system = this.currentlySelectedSubSystem;
	var systemMessage = `(${system})`;

	var message = formatString(
		getLanguageTag(
			this.constructor,
			'areYouSureYouWantToDeleteTheSubsystem',
		),
		systemMessage,
	);
	var confirmButtonLabel = getLanguageTag(this.constructor, 'confirm');
	var dismissButtonLabel = getLanguageTag(this.constructor, 'cancel');

	var confirmOptions = {
		message: message,
		confirmButtonLabel: confirmButtonLabel,
		dismissButtonLabel: dismissButtonLabel,
	};

	var confirmedCallback = function() {
		currentWidget.removeAllSubSystemConfigurations(system, function(
			err,
			data,
		) {
			if (err) {
				currentWidget
					.getMainContainer()
					.showPopupErrorMessage(err, function() {});
				return;
			}

			var successMessage = formatString(
				getLanguageTag(
					currentWidget.constructor,
					'subSystemHasBeenRemoved',
				),
				systemMessage,
			);

			currentWidget.update(function(err, data) {
				currentWidget
					.getMainContainer()
					.showPopupInfoMessage(successMessage, function() {});
				return;
			});
		});
	};

	var dismissedCallback = function() {};
	var callback = function() {};

	this.getMainContainer().showConfirm(
		confirmOptions,
		confirmedCallback,
		dismissedCallback,
		callback,
	);
};

WidgetSystemAdministrationConfigurations.prototype.onSystemTagEditButtonClicked = function(
	eventData,
) {
	this.openConfigurationMenu({
		system: eventData.system,
		tag: eventData.tag,
		type: eventData.type,
		value: eventData.value,
	});
};

WidgetSystemAdministrationConfigurations.prototype.onSystemTagDeleteButtonClicked = function(
	eventData,
) {
	var currentWidget = this;

	var system = eventData.system;
	var tag = eventData.tag;
	var systemAndTag = `(${system} - ${tag})`;
	var message = formatString(
		getLanguageTag(
			this.constructor,
			'areYouSureYouWantToDeleteTheConfigurationTag',
		),
		systemAndTag,
	);
	var confirmButtonLabel = getLanguageTag(this.constructor, 'confirm');
	var dismissButtonLabel = getLanguageTag(this.constructor, 'cancel');

	var confirmOptions = {
		message: message,
		confirmButtonLabel: confirmButtonLabel,
		dismissButtonLabel: dismissButtonLabel,
	};

	var confirmedCallback = function() {
		currentWidget.removeSystemConfiguration(system, tag, function(
			err,
			data,
		) {
			if (err) {
				currentWidget
					.getMainContainer()
					.showPopupErrorMessage(err, function() {});
				return;
			}

			var successMessage = getLanguageTag(
				currentWidget.constructor,
				'youHaveDeletedTheConfigurationTag',
			);
			currentWidget.update(function(err, data) {
				currentWidget
					.getMainContainer()
					.showPopupInfoMessage(successMessage, function() {});
				return;
			});
		});
	};

	var dismissedCallback = function() {};
	var callback = function() {};

	this.getMainContainer().showConfirm(
		confirmOptions,
		confirmedCallback,
		dismissedCallback,
		callback,
	);
};

WidgetSystemAdministrationConfigurations.prototype.openConfigurationMenu = function(
	options,
) {
	var currentWidget = this;

	var subSystems = Object.keys(this.systemConfigurationsData);
	var subSystemOptions = [];

	for (var i = 0; i < subSystems.length; i++) {
		subSystemOptions.push({
			value: subSystems[i],
			label: subSystems[i],
		});
	}

	this.getMainContainer().setModalWidget(
		WidgetSettingsForm,
		{
			fields: [
				{
					name: 'system',
					type: 'select',
					label: getLanguageTag(
						currentWidget.constructor,
						'subSystem',
					),
					options: subSystemOptions,
					value:
						options.system ||
						currentWidget.currentlySelectedSubSystem ||
						null,
				},
				{
					name: 'tag',
					type: 'text',
					label: getLanguageTag(
						currentWidget.constructor,
						'configurationTag',
					),
					value: options.tag || null,
				},
				{
					name: 'value',
					type: 'jsonEditor',
					label: getLanguageTag(currentWidget.constructor, 'value'),
					value: options.value,
					options: {
						width: '800px',
						height: '600px',
					},
				},
			],
		},
		function(err, settingsWidget) {
			settingsWidget.addEventListener('dismissed', function() {
				currentWidget.getMainContainer().hideModal();
			});

			settingsWidget.addEventListener('confirmed', function() {
				currentWidget.getMainContainer().hideModal();
				values = this.getValues();

				currentWidget.addSystemConfiguration(
					values.system,
					values.tag,
					values.value,
					function(err, data) {
						if (err) {
							currentWidget
								.getMainContainer()
								.showPopupErrorMessage(err, function() {});
							return;
						}

						var messageString = `(${values.system} - ${values.tag})`;
						var successMessage = formatString(
							getLanguageTag(
								currentWidget.constructor,
								'subsystemConfigurationHasBeenSaved',
							),
							messageString,
						);

						currentWidget.update(function(err, data) {
							currentWidget
								.getMainContainer()
								.showPopupInfoMessage(
									successMessage,
									function() {},
								);
							return;
						});
					},
				);
			});

			this.showModal();
			return;
		},
	);
};

WidgetSystemAdministrationConfigurations.prototype.addSystemConfiguration = function(
	system,
	tag,
	value,
	callback,
) {
	var currentWidget = this;

	this.getAPI()
		.getAPIRoute('/admin/configurations/add/:system/:tag/:value')
		.post(system, tag, value, function(err, data) {
			if (err) {
				console.error(err);
				callback.call(currentWidget, err);
				return;
			}

			callback.call(currentWidget, false, data);
			return;
		});
};

WidgetSystemAdministrationConfigurations.prototype.removeSystemConfiguration = function(
	system,
	tag,
	callback,
) {
	var currentWidget = this;

	this.getAPI()
		.getAPIRoute('/admin/configurations/remove/:system/:tag')
		.post(system, tag, function(err, data) {
			if (err) {
				console.error(err);
				callback.call(currentWidget, err);
				return;
			}

			callback.call(currentWidget, false, data);
			return;
		});
};

WidgetSystemAdministrationConfigurations.prototype.removeAllSubSystemConfigurations = function(
	system,
	callback,
) {
	var currentWidget = this;

	var systemTags = Object.keys(this.systemConfigurationsData[system]);
	var errors = false;

	function _removeAllSubSystemConfigurationsHelper() {
		if (systemTags.length <= 0) {
			callback.call(currentWidget, errors);
			return;
		}

		var currentSystemTag = systemTags.pop();

		this.removeSystemConfiguration(system, currentSystemTag, function(
			err,
			data,
		) {
			if (err) {
				errors = errors || [];
				errors.push(err);
				_removeAllSubSystemConfigurationsHelper.call(this);
				return;
			}

			_removeAllSubSystemConfigurationsHelper.call(this);
		});
	}

	_removeAllSubSystemConfigurationsHelper.call(this);
};

WidgetSystemAdministrationConfigurations.prototype.verifyConfigurationData = function(
	configurationData,
	callback,
) {
	var currentWidget = this;

	if (configurationData.constructor === File) {
		var file = configurationData;
		var fileName = configurationData.name;

		if (checkForFileType(fileName, ['.acsysconfig']) === false) {
			callback.call(currentWidget, {
				type: 'invalidFileImportExtensionError',
			});
			return;
		}

		var reader = new FileReader();
		reader.readAsText(file);

		reader.onload = function(readerEvent) {
			var binaryString = readerEvent.target.result;
			var fileConfigurationData = binaryString.toString();
			processConfigurationData(fileConfigurationData);
			return;
		};
	} else {
		processConfigurationData(configurationData);
		return;
	}

	function processConfigurationData(incomingData) {
		var configurationDataToImport = null;

		try {
			if (incomingData.constructor === String) {
				try {
					configurationDataToImport = JSON.parse(incomingData);
				} catch (err) {
					configurationDataToImport = incomingData;
				}
			} else {
				configurationDataToImport = incomingData;
			}

			if (configurationDataToImport.constructor !== Array) {
				callback.call(currentWidget, {
					type: 'invalidFileImportGenericError',
				});
				return;
			} else {
				callback.call(currentWidget, false, configurationDataToImport);
				return;
			}
		} catch (err) {
			console.error(err);
			callback.call(currentWidget, {
				type: 'invalidFileImportJSONError',
			});
			return;
		}
	}
};

WidgetSystemAdministrationConfigurations.prototype.importConfigurationData = function(
	overlay,
	configData,
	callback,
) {
	var currentWidget = this;
	overlay = overlay || false;

	this.getAPI()
		.getAPIRoute('/admin/configurations/import/:overlay/:configData')
		.post(overlay, configData, function(err, data) {
			if (err) {
				callback.call(currentWidget, err);
				return;
			}

			callback.call(currentWidget, false);
			return;
		});
};

WidgetSystemAdministrationConfigurations.prototype.exportSystemConfigurations = function(
	callback,
) {
	var currentWidget = this;

	this.getAPI()
		.getAPIRoute('/admin/configurations/export')
		.get(function(err, exportedConfigData) {
			if (err) {
				callback.call(currentWidget, err);
				return;
			}

			var fileName =
				location.hostname +
				'_configurations_' +
				new Date().toISOString() +
				'.acsysconfig';

			callback.call(currentWidget, false, {
				exportedConfigData: exportedConfigData,
				fileName: fileName,
			});
			saveObjectAsJSON(fileName, exportedConfigData);
			return;
		});
};

WidgetSystemAdministrationConfigurations.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'System Configurations',
			subsystems: 'Subsystems',
			addSubsystem: 'Add Subsystem',
			pleaseNameYourSubsystem: 'Please name your subsystem.',
			addToSubSystem: 'ADD TO SUBSYSTEM',
			addSubSystemConfiguration: 'ADD TO SUBSYSTEM',
			exportConfigurations: 'EXPORT',
			importConfigurations: 'IMPORT',
			importConfigurationsMenu: 'Import',
			removeSubsystemConfigurations: 'REMOVE SUBSYSTEM',
			restartSubsystem: 'RESTART SUBSYSTEM',
			restartingSubsystem: 'Restarting subsystem: ',
			pleaseSelectASubsystemFirst: 'Please select a subsystem first!',
			pleaseSelectADataTypeForYourTag:
				'Please select a datatype for your tag.',
			pleaseSpecifyATagForSubsystem:
				'Please specify a tag for subsystem: ',
			youHaveNotEnteredATag: 'You have not entered a tag.',
			enterDataForSpecifiedTag: 'Enter data for specified tag: ',
			true: 'True',
			false: 'False',
			subsystemHasBeenAdded: 'Subsystem {0} added',
			subsystemConfigurationHasBeenSaved:
				'Subsystem Configuration has been saved!',
			youHaveNotEnteredAnyData: 'You have not entered any data!',
			areYouSureYouWantToSaveTheConfigurationValue:
				'Are you sure you want to save the configuration value?',
			subsystemConfigurationValueHasBeenSaved:
				'Subsystem configuration value has been saved!',
			subsystemConfigurationValueHasBeenRemoved:
				'Subsystem configuration value has been removed!',
			areYouSureYouWantToDeleteTheConfigurationTag:
				'Are you sure you want to delete the configuration tag? {0}',
			youHaveDeletedTheConfigurationTag:
				'You have deleted the configuration tag!',
			areYouSureYouWantToDeleteTheSubsystem:
				'Are you sure you want to delete the subsystem? {0}',
			errorAcquiringData: 'Error acquiring data!',
			noAvailableSubsystems: 'No available subsystems.',
			noSubSystemSelected: 'No subsystem selected.',
			undo: 'Undo',
			subSystem: 'Subsystem',
			saveValue: 'Save Value',
			deleteSystemTag: 'Delete System Tag',
			editJSONFor: 'Edit JSON for: ',
			cancel: 'CANCEL',
			saveData: 'SAVE JSON',
			edit: 'EDIT',
			delete: 'DELETE',
			addConfigurationMenu: 'Add Configuration Menu',
			configurationTag: 'Configuration Tag',
			array: 'Array',
			object: 'Object',
			boolean: 'Boolean',
			string: 'String',
			number: 'Number',
			datatype: 'Data Type',
			invalidFileImportGenericError:
				'Invalid file, please select a valid .acsysconfig file',
			invalidFileImportExtensionError:
				'Invalid file, please upload a file with the .acsysconfig extension',
			invalidFileImportJSONError:
				'Invalid file, please select a valid .acsysconfig file',
			clearAndImport: 'Clear and Import',
			importOverlay: 'Import Overlay',
			overlay: 'Overlay',
			invalidConfigurationDataBadJSON:
				'Invalid configuration data, bad JSON!',
			invalidConfigurationDataBadStructure:
				'Invalid configuration data, bad structure!',
			value: 'Value',
			exportingSystemConfigurationsPleaseWait:
				'Exporting system configurations...',
			systemConfigurationsHaveBeenExportedAs:
				'System configurations have been exported as: {0}',
			subSystemHasBeenRemoved: 'Subsystem {0} has been removed.',
			importAsOverlayYesOrNo: 'Import configurations as overlay? (Y/N)',
			newSubSystem: 'NEW SUBSYSTEM',
			configurationsHaveBeenImported:
				'Configurations have been imported.',
		},

		de: {
			name: 'Systemadministration',
		},

		es: {
			name: 'Administracion del Sistema',
			subsystems: 'Subsistemas',
			addSubsystem: 'Añadir Subsistema',
			pleaseNameYourSubsystem: 'Por favor, nombre su subsistema',
			addSubSystemConfiguration: 'AÑADIR CONFIGURACIÓN',
			removeSubsystemConfigurations: 'ELIMINAR TODOS CONFIGURACIONES',
			restartSubsystem: 'REINICIAR SUBSISTEMA',
			restartingSubsystem: 'Reiniciar el subsistema: ',
			pleaseSelectASubsystemFirst: 'Seleccione primero un subsistema!',
			pleaseSelectADataTypeForYourTag:
				'Por favor, seleccione un tipo de datos de la etiqueta.',
			pleaseSpecifyATagForSubsystem:
				'Por favor especificar una etiqueta para el subsistema: ',
			youHaveNotEnteredATag: 'No ha ingresado una etiqueta.',
			enterDataForSpecifiedTag:
				'Introduzca los datos para la etiqueta especificada: ',
			true: 'Cierto',
			false: 'Falso',
			subsystemConfigurationHasBeenSaved:
				'Se ha guardado la configuración del subsistema!',
			youHaveNotEnteredAnyData: '¡No has ingresado ningún dato!',
			areYouSureYouWantToSaveTheConfigurationValue:
				'¿Seguro que desea guardar el valor de configuración?',
			subsystemConfigurationValueHasBeenSaved:
				'Se ha guardado el valor de configuración del subsistema!',
			areYouSureYouWantToDeleteTheConfigurationTag:
				'¿Seguro que quieres eliminar la etiqueta de configuración?',
			youHaveDeletedTheConfigurationTag:
				'Ha eliminado la etiqueta de configuración!',
			areYouSureYouWantToDeleteTheSubsystem:
				'¿Estás seguro de que deseas eliminar el subsistema?',
			errorAcquiringData: '¡Error al adquirir datos!',
			noAvailableSubsystems: 'No hay subsistemas disponibles.',
			noSubSystemSelected: 'No se ha seleccionado ningún subsistema.',
			undo: 'Deshacer',
			saveValue: 'Guardar Valor',
			deleteSystemTag: 'Eliminar Etiqueta del Sistema',
			editJSONFor: 'Editar JSON para: ',
			cancel: 'CANCELAR',
			saveData: 'GUARDARDO JSON',
			edit: 'EDITAR',
			delete: 'RETIRAR',
			addConfigurationMenu: 'Menú de configuración',
			configurationTag: 'Tag de configuración',
			array: 'Vector',
			object: 'Objeto',
			boolean: 'Booleano',
			string: 'Cadena de caracteres',
			number: 'Número',
		},

		ar: {
			name: 'إدارة النظام',
			subsystems: 'فرعية',
			addSubsystem: 'إضافة النظام الفرعي',
			pleaseNameYourSubsystem: 'يرجى تسمية النظام الفرعي الخاص بك',
			addSubSystemConfiguration: 'إضافة تكوين النظام الفرعي',
			removeSubsystemConfigurations: 'إزالة التهيئات',
			restartSubsystem: 'إعادة تشغيل النظام الفرعي',
			restartingSubsystem: 'إعادة تشغيل النظام الفرعي',
			pleaseSelectASubsystemFirst: 'يرجى تحديد النظام الفرعي أولا',
			pleaseSelectADataTypeForYourTag: 'يرجى تحديد نوع البيانات للعلامة',
			pleaseSpecifyATagForSubsystem: 'يرجى تحديد علامة للنظام الفرعي',
			youHaveNotEnteredATag: 'لم تدخل علامة',
			enterDataForSpecifiedTag: ' أدخل بيانات لعلامة محددة',
			true: 'صحيح',
			false: 'زائف',
			subsystemConfigurationHasBeenSaved: 'تم حفظ تكوين النظام الفرعي',
			youHaveNotEnteredAnyData: 'لم تدخل أية بيانات',
			areYouSureYouWantToSaveTheConfigurationValue:
				'هل تريد بالتأكيد حفظ قيمة التهيئة؟',
			subsystemConfigurationValueHasBeenSaved:
				'تم حفظ قيمة التكوين الفرعي',
			areYouSureYouWantToDeleteTheConfigurationTag:
				'هل أنت متأكد أنك تريد حذف العلامة التكوين؟',
			youHaveDeletedTheConfigurationTag: 'لقد حذف العلامة التكوين',
			areYouSureYouWantToDeleteTheSubsystem:
				'هل تريد بالتأكيد حذف النظام الفرعي؟',
			errorAcquiringData: 'خطأ الحصول على البيانات',
			noAvailableSubsystems: 'لا توجد أنظمة فرعية متاحة',
			noSubSystemSelected: 'لم يتم تحديد أي نظام فرعي',
			undo: 'فك',
			saveValue: 'حفظ القيمة',
			deleteSystemTag: 'حذف علامة النظام',
			editJSONFor: 'JSON تحرير',
			cancel: 'إلغاء',
			saveData: 'JSON احفظ البيانات',
			edit: 'تصحيح',
			delete: 'حذف',
			addConfigurationMenu: 'إضافة قائمة التكوين',
			configurationTag: 'علامة التكوين',
			array: 'مصفوفة',
			object: 'موضوع',
			boolean: 'منطقية',
			string: 'سلسلة',
			number: 'رقم',
		},
	},
);

WidgetSystemAdministrationConfigurations.prototype.$_$ = function(done) {
	//Testing function for WidgetSystemAdministrationConfigurations

	this.$_SetupWidgetTest(function(err, currentWidget) {
		WidgetBase.prototype.$_$.call(this, done);
	});
};
