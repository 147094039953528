function ElementControllerAppBLEScanner(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppBLEBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('advertisingData', null, null, 'none', null, null);
	this.addProperty('autoConnect', null, false, 'checkbox', null, null);
	this.addProperty('localName', null, '', 'text', null, null);

	this.addAbility('startScanning', []);
	this.addAbility('stopScanning', []);
	this.addAbility('connectToDeviceByLocalName', [
		{ name: 'localName', validator: null },
	]);

	this.addTrigger('advertisement');
}

ElementControllerAppBLEScanner.prototype = Object.create(
	ElementControllerAppBLEBase.prototype,
);
ElementControllerAppBLEScanner.prototype.constructor = ElementControllerAppBLEScanner;

ElementControllerAppBLEScanner.prototype.NAME = 'BLEScanner';
ElementControllerAppBLEScanner.prototype.TYPE = 'AppBLEScanner';
ElementControllerAppBLEScanner.prototype.HIDDEN = true;
ElementControllerAppBLEScanner.prototype.DEFAULT_TRIGGER = 'advertisement';
ElementControllerAppBLEScanner.prototype.DEFAULT_ABILITY = 'startScanning';
ElementControllerAppBLEScanner.prototype.DEFAULT_ARGUMENTS = [
	'advertisingData',
];
ElementControllerAppBLEScanner.prototype.VARIANTS = [
	'app',
	'ble',
	'standalone',
];

ElementControllerAppBLEScanner.prototype.language = deepAssign(
	{},
	ElementControllerBase.prototype.language,
	{
		'en-US': {
			AppBLEScanner: 'BLE Scanner',
			advertisingData: 'Advertising Data',
			startScanning: 'Start Scanning',
			stopScanning: 'Stop Scanning',
			advertisement: 'Advertisement',
		},
	},
);
