function WidgetDesignerView(id, api, parentWidget, options) {
	var currentWidget = this;

	WidgetBase.call(this, id, api, parent, options);

	this.canvasId = this.generateChildId('canvas');
	this.hitCanvasId = this.generateChildId('hitCanvas');

	this.viewSize = 3000;

	this.renderTemplate(
		{
			canvasId: this.canvasId,
			hitCanvasId: this.hitCanvasId,
		},
		WidgetDesignerView.name,
	);

	this.canvasContainer = this.getContainer().find('.WidgetDesignerView-Top');
	this.canvas = $('#' + this.canvasId);
	this.hitCanvas = $('#' + this.hitCanvasId);

	if (this.canvas[0] === null || this.hitCanvas[0] === null) {
		//TODO: Find way to replicate the original error and create unit test for it
		throw new Error('AT-3862: canvas and/or hitCanvas are null');
	}

	this.root = new CrocRoot(this.canvas[0], this.hitCanvas[0], false);

	requestAnimationFrame(function() {
		currentWidget.root.setWidth(currentWidget.viewSize);
		currentWidget.root.setHeight(currentWidget.viewSize);
	});

	this.lastPointerMove = null;

	this.panel = new DesignerViewPanel(this.root);

	this.getContainer().on('dragover', function(event) {
		event.preventDefault();
		event.stopPropagation();
		$(this).addClass('WidgetDesignerView-Dragging');
	});

	this.getContainer().on('dragleave', function(event) {
		event.preventDefault();
		event.stopPropagation();
		$(this).removeClass('WidgetDesignerView-Dragging');
	});

	this.getContainer().on('drop', function(e) {
		e.preventDefault();
		e.stopPropagation();
		currentWidget.onDrop(e);
		$(this).removeClass('WidgetDesignerView-Dragging');
	});

	this.root.addEventListener('imageload', function(data) {
		setTimeout(function() {
			currentWidget.updateAllConnectorsPosition();
		}, 16);
	});

	this.root.addChild(this.panel);

	this.iconDefaultPath = this.root.themer.getValue(
		WidgetDesignerView,
		'iconPath',
	);
	this.iconEntries = {};
	this.connectorsFromTo = {};
	this.connectorsToFrom = {};
	this.connectorsMappedFrom = {};
	this.connectorsMappedTo = {};

	this.targetingElement = false;

	this.focusedConnector = null;

	this.focusedIcon = null;
	this.focusedIconDragTimeout = null;
	this.focusedIconDragging = false;
	this.focusedIconDraggingOffset = { x: 0, y: 0 };

	this.lastIconAdded = null;
	this.lastIconAddedPosition = { x: 0, y: 0 };

	this.draggingConnector = null;
	this.draggingConnectorFromIcon = null;
	this.draggingConnectorReleased = true;

	this.canvasContainer.scroll(function(e) {
		return currentWidget.onCanvasContainerScroll(e);
	});

	this.panel.addEventListener('pointermove', function(e) {
		return currentWidget.onPointerMove(e);
	});

	this.panel.addEventListener('pointerleave', function(e) {
		return currentWidget.onPointerLeave(e);
	});

	this.panel.addEventListener('pointerup', function(e) {
		return currentWidget.onPointerUp(e);
	});

	this.panel.addEventListener('pointerdown', function(e) {
		return currentWidget.onPointerDown(e);
	});

	this._currentKeyUp = this.root.addEventListener('keyup', function(e) {
		return currentWidget.onKeyUp(e);
	});
}

WidgetDesignerView.prototype = Object.create(WidgetBase.prototype);
WidgetDesignerView.prototype.constructor = WidgetDesignerView;

WidgetDesignerView.prototype.onDrop = function(e) {
	var currentWidget = this;

	e.preventDefault();
	// If dropped items aren't files, reject them
	var dt = e.originalEvent.dataTransfer;
	if (dt.items) {
		// Use DataTransferItemList interface to access the file(s)
		for (var i = 0; i < dt.items.length; i++) {
			if (dt.items[i].kind == 'file') {
				var f = dt.items[i].getAsFile();

				if (f.type.startsWith('image')) {
					var reader = new FileReader();

					reader.onload = function(image) {
						var imageData = image.target.result;

						currentWidget.panel.event('imagedrop', {
							image: imageData,
							x: e.originalEvent.layerX,
							y: e.originalEvent.layerY,
						});
						return;
					};

					reader.readAsDataURL(f);
				}
			}
		}
	} else {
		// Use DataTransfer interface to access the file(s)
		for (var i = 0; i < dt.files.length; i++) {
			console.log('... file[' + i + '].name = ' + dt.files[i].name);

			var reader = new FileReader();

			reader.onload = function(e) {
				console.log(e.target.result);
			};

			reader.readAsText(f);
		}
	}
};

WidgetDesignerView.prototype.screenSaverMode = function(enable) {
	var currentWidget = this;

	if (!enable) {
		if (
			this.screenSaverInterval !== undefined &&
			this.screenSaverInterval !== null
		) {
			clearInterval(this.screenSaverInterval);
			this.screenSaverInterval = null;
		}

		return;
	}

	var velocities = {};
	var flip = -1;

	this.screenSaverInterval = window.setInterval(function() {
		var rootWidth = currentWidget.root.getWidth();
		var rootHeight = currentWidget.root.getHeight();

		for (var k in currentWidget.iconEntries) {
			var currentVelocity = velocities[k];

			if (currentVelocity === undefined) {
				currentVelocity = {
					x: Math.random() * 2 * flip,
					y: Math.random() * 2 * flip,
				};

				flip = flip * -1;

				velocities[k] = currentVelocity;
			}

			var currentPosition = currentWidget.getIconPosition(k);

			var movingX = currentPosition.x + currentVelocity.x;
			var movingY = currentPosition.y + currentVelocity.y;

			if (movingX < 0) {
				currentVelocity.x = currentVelocity.x * -1.0;
			}

			if (movingX + currentWidget.iconEntries[k].getWidth() > rootWidth) {
				currentVelocity.x = currentVelocity.x * -1.0;
			}

			if (movingY < 0) {
				currentVelocity.y = currentVelocity.y * -1.0;
			}

			if (
				movingY + currentWidget.iconEntries[k].getHeight() >
				rootHeight
			) {
				currentVelocity.y = currentVelocity.y * -1.0;
			}

			currentWidget.setIconPosition(k, movingX, movingY);
		}
	}, 8);
};

WidgetDesignerView.prototype.addEventListener = function(type, callback) {
	return this.panel.addEventListener(type, callback);
};

WidgetDesignerView.prototype.removeEventListener = function(type, callback) {
	return this.panel.removeEventListener(type, callback);
};

WidgetDesignerView.prototype.onKeyUp = function(e) {
	if (this.targetingElement) {
		return;
	}

	if (e.key === 'v' && e.ctrlKey === true) {
		this.panel.event('pasteicon', {});
		return false;
	}

	if (this.focusedConnector !== null) {
		var connectorTo = this.connectorsMappedTo[this.focusedConnector.uuid];
		var connectorFrom = this.connectorsMappedFrom[
			this.focusedConnector.uuid
		];

		if (e.key === 'Delete') {
			this.panel.event('deleteconnector', {
				from: connectorFrom,
				to: connectorTo,
			});
			return false;
		}

		if (e.key === 'c' && e.ctrlKey === true) {
			this.panel.event('copyconnector', {
				from: this.focusedConnector.getHandle(),
				to: toIcon.getHandle(),
			});
			return false;
		}
	}

	if (this.focusedIcon !== null) {
		if (e.key === 'Delete') {
			this.panel.event('deleteicon', {
				name: this.focusedIcon.getHandle(),
			});
			return false;
		}

		if (e.key === 'c' && e.ctrlKey === true) {
			this.panel.event('copyicon', {
				name: this.focusedIcon.getHandle(),
			});
			return false;
		}

		if (e.key === 'x' && e.ctrlKey === true) {
			this.panel.event('cuticon', { name: this.focusedIcon.getHandle() });
			return false;
		}
	}
};

WidgetDesignerView.prototype.onCanvasContainerScroll = function(e) {
	return true;
};

WidgetDesignerView.prototype.onPointerMove = function(data) {
	if (this.targetingElement) {
		return;
	}

	this.lastPointerMove = data;

	if (this.focusedIcon !== null) {
		if (this.focusedIconDragging) {
			this.panel.getRoot().setCursor('move');

			var newX = data.x + this.focusedIconDraggingOffset.x;
			var newY = data.y + this.focusedIconDraggingOffset.y;

			newX = Math.min(
				Math.max(0, newX),
				this.root.getWidth() - this.focusedIcon.getWidth(),
			);
			newY = Math.min(
				Math.max(0, newY),
				this.root.getHeight() - this.focusedIcon.getHeight(),
			);

			this.setIconPosition(this.focusedIcon.getHandle(), newX, newY);

			this.panel.event('iconmove', {
				name: this.focusedIcon.getHandle(),
				x: newX,
				y: newY,
			});

			return false;
		}
	} else if (this.draggingConnector !== null) {
		this.draggingConnector.setEnd(data.x, data.y);

		return false;
	}

	return true;
};

WidgetDesignerView.prototype.onPointerLeave = function(data) {
	if (this.targetingElement) {
		return;
	}

	if (this.focusedIconDragTimeout !== null) {
		this.panel.getRoot().setCursor();
		clearTimeout(this.focusedIconDragTimeout);
		this.focusedIconDragTimeout = null;
	}

	this.focusedIconDragging = false;
};

WidgetDesignerView.prototype.onPointerUp = function(data) {
	if (this.targetingElement) {
		this.targetingElement = false;
		return true;
	}

	this.panel.blur();

	if (this.focusedIconDragTimeout !== null) {
		this.panel.getRoot().setCursor();
		clearTimeout(this.focusedIconDragTimeout);
		this.focusedIconDragTimeout = null;
	}

	if (this.focusedIconDragging === false && this.focusedIcon !== null) {
		this.panel.event('iconclick', { name: this.focusedIcon.getHandle() });
	} else if (this.draggingConnector !== null) {
		this.panel.removeChild(this.draggingConnector);
		this.draggingConnector = null;
		this.panel.getRoot().setCursor();
	}

	this.focusedIconDragging = false;

	return true;
};

WidgetDesignerView.prototype.onPointerDown = function(data) {
	if (this.targetingElementIcon) {
		this.targetingElementIcon = false;
	}

	if (this.targetingElement) {
		this.targetingElementIcon = true;
		this.targetingElement = false;
		this.panel.getRoot().setCursor('default');
		this.panel.event('elementTargeted', { element: null });
		return true;
	}

	this.setFocusedIcon(null);

	return true;
};

WidgetDesignerView.prototype.onIconPointerDown = function(iconEntry, data) {
	if (this.targetingElementIcon) {
		this.targetingElementIcon = false;
		this.panel.getRoot().setCursor('default');
		this.panel.event('elementTargeted', { element: iconEntry.handle });
		return false;
	}

	var currentWidget = this;
	var currentFocusedWidget = iconEntry;
	this.setFocusedIcon(iconEntry);

	this.draggingConnectorReleased = true;
	this.draggingConnectorFromIcon = null;

	var currentPosition = this.getIconPosition(this.focusedIcon.getHandle());

	this.focusedIconDraggingOffset.x = currentPosition.x - data.x;
	this.focusedIconDraggingOffset.y = currentPosition.y - data.y;

	this.focusedIconDragTimeout = setTimeout(function() {
		if (currentWidget.focusedIcon === currentFocusedWidget) {
			currentWidget.panel.getRoot().setCursor('move');
			currentWidget.focusedIconDragging = true;
			currentWidget.panel.focus();
		}
	}, 100);

	this.panel.event('iconpointerdown', { name: this.focusedIcon.getHandle() });

	return false;
};

WidgetDesignerView.prototype.onIconPointerUp = function(iconEntry, data) {
	var currentWidget = this;

	if (this.targetingElement) {
		this.targetingElement = false;
		return false;
	}

	if (this.draggingConnectorFromIcon && !this.draggingConnectorReleased) {
		this.draggingConnectorReleased = true;
		this.addConnector(
			this.draggingConnectorFromIcon.handle,
			iconEntry.handle,
		);

		this.panel.event('connectoradded', {
			from: this.draggingConnectorFromIcon.handle,
			to: iconEntry.handle,
		});

		this.draggingConnectorFromIcon = null;
	}

	return false;
};

WidgetDesignerView.prototype.onIconPointerMove = function(iconEntry, data) {
	return false;
};

WidgetDesignerView.prototype.onIconConnectorDown = function(iconEntry, data) {
	if (this.targetingElement) {
		return;
	}

	if (this.draggingConnector !== null) {
		return;
	}

	var newX = data.x + this.focusedIconDraggingOffset.x;
	var newY = data.y + this.focusedIconDraggingOffset.y;

	this.draggingConnectorReleased = false;
	this.draggingConnectorFromIcon = iconEntry;
	this.draggingConnector = new DesignerViewConnector(this.root, true);

	this.updateConnectorStartPosition(this.draggingConnector, iconEntry);

	this.draggingConnector.setEnd(data.x, data.y);

	this.panel.addChild(this.draggingConnector);
	this.panel.childToFront(this.draggingConnector);
};

WidgetDesignerView.prototype.onConnectorPointerDown = function(connector) {
	this.setFocusedConnector(connector);
};

WidgetDesignerView.prototype.getCompleteIconPath = function(iconType) {
	if (iconType.indexOf('.') >= 0 || iconType.indexOf('/') >= 0) {
		return iconType;
	}

	return this.iconDefaultPath + iconType + '.svg';
};

WidgetDesignerView.prototype.setTargetingElement = function(targeting) {
	/*
	 * If targeting element is enabled then the next
	 * time an designer view icon get's a pointer
	 * down event then the widget designer view will
	 * fire off a elementTargeted event and not focus
	 * on that element or make any changes to the current
	 * focus. This is used by WidgetElementEditor when
	 * adding a new trigger event and an element is
	 * required to be selected to continue adding the
	 * event.
	 *
	 */

	if (targeting) {
		this.panel.getRoot().setCursor('crosshair');
		this.targetingElement = true;
	} else {
		this.panel.getRoot().setCursor();
		this.targetingElement = false;
	}
};

WidgetDesignerView.prototype.setIconDefaultPath = function(defaultPath) {
	if (this.iconDefaultPath[this.iconDefaultPath.length - 1] !== '/') {
		this.iconDefaultPath += '/';
	}

	this.iconDefaultPath = defaultPath.toString();
};

WidgetDesignerView.prototype.getConnectorsFromIcon = function(iconHandle) {
	if (this.connectorsFromTo[iconHandle] === undefined) {
		return {};
	}

	var connectors = [];

	for (var k in this.connectorsFromTo[iconHandle]) {
		connectors.push(this.connectorsFromTo[iconHandle][k]);
	}

	return connectors;
};

WidgetDesignerView.prototype.getConnectorsToIcon = function(iconHandle) {
	if (this.connectorsToFrom[iconHandle] === undefined) {
		return {};
	}

	var connectors = [];

	for (var k in this.connectorsToFrom[iconHandle]) {
		connectors.push(this.connectorsToFrom[iconHandle][k]);
	}

	return connectors;
};

WidgetDesignerView.prototype.getConnectorsInvolved = function(iconHandle) {
	return this.getConnectorsFromIcon(iconHandle).concat(
		this.getConnectorsToIcon(iconHandle),
	);
};

WidgetDesignerView.prototype.getConnectorConnecting = function(
	fromIconHandle,
	toIconHandle,
) {
	if (this.connectorsFromTo[fromIconHandle] === undefined) {
		return null;
	}

	if (this.connectorsFromTo[fromIconHandle][toIconHandle] === undefined) {
		return null;
	}

	return this.connectorsFromTo[fromIconHandle][toIconHandle];
};

WidgetDesignerView.prototype.updateConnectorStartPosition = function(
	connector,
	iconEntry,
) {
	var iconOrientation = this.panel.getChildOrientation(iconEntry);

	connector.setStart(
		iconOrientation.x + iconEntry.getWidth(),
		iconOrientation.y + iconEntry.getHeight() / 2,
	);

	return;
};

WidgetDesignerView.prototype.updateConnectorEndPosition = function(
	connector,
	iconEntry,
) {
	var iconOrientation = this.panel.getChildOrientation(iconEntry);

	connector.setEnd(
		iconOrientation.x,
		iconOrientation.y + iconEntry.getHeight() / 2,
	);

	return;
};

WidgetDesignerView.prototype.updateAllConnectorsPosition = function(
	iconImgSrc,
) {
	if (iconImgSrc !== undefined) {
		for (var k in this.iconEntries) {
			if (this.iconEntries[k] === iconImgSrc) {
				this.updateConnectorsPosition(k);
			}
		}
	} else {
		for (var k in this.iconEntries) {
			this.updateConnectorsPosition(k);
		}
	}
};

WidgetDesignerView.prototype.updateConnectorsPosition = function(iconHandle) {
	var currentIcon = this.getIcon(iconHandle);

	if (currentIcon === null) {
		return;
	}

	var fromConnectors = this.getConnectorsFromIcon(iconHandle);
	var toConnectors = this.getConnectorsToIcon(iconHandle);

	for (var i = 0; i < fromConnectors.length; i++) {
		this.updateConnectorStartPosition(fromConnectors[i], currentIcon);
	}

	for (var i = 0; i < toConnectors.length; i++) {
		this.updateConnectorEndPosition(toConnectors[i], currentIcon);
	}

	return;
};

WidgetDesignerView.prototype.addConnector = function(
	fromIconHandle,
	toIconHandle,
) {
	var currentWidget = this;

	//Do we already have a connector between these two icons?
	if (this.getConnectorConnecting(fromIconHandle, toIconHandle) !== null) {
		return;
	}

	//Do the icons mentioned exist?
	if (
		this.iconEntries[fromIconHandle] === undefined ||
		this.iconEntries[toIconHandle] === undefined
	) {
		return;
	}

	var currentFromIcon = this.iconEntries[fromIconHandle];
	var currentToIcon = this.iconEntries[toIconHandle];

	var toIconOrientation = this.panel.getChildOrientation(currentToIcon);

	var currentConnector = new DesignerViewConnector(this.root);

	currentConnector.addEventListener('pointerdown', function() {
		return currentWidget.onConnectorPointerDown(currentConnector);
	});

	this.updateConnectorStartPosition(currentConnector, currentFromIcon);
	this.updateConnectorEndPosition(currentConnector, currentToIcon);

	if (this.connectorsFromTo[fromIconHandle] === undefined) {
		this.connectorsFromTo[fromIconHandle] = {};
	}

	this.connectorsFromTo[fromIconHandle][toIconHandle] = currentConnector;

	if (this.connectorsToFrom[toIconHandle] === undefined) {
		this.connectorsToFrom[toIconHandle] = {};
	}

	this.connectorsToFrom[toIconHandle][fromIconHandle] = currentConnector;

	this.connectorsMappedFrom[currentConnector.uuid] = fromIconHandle;
	this.connectorsMappedTo[currentConnector.uuid] = toIconHandle;

	this.panel.addChild(currentConnector);
	this.panel.childToBack(currentConnector);

	this.root.repaint();

	return;
};

WidgetDesignerView.prototype.removeConnector = function(
	fromIconHandle,
	toIconHandle,
) {
	var connector = null;

	if (this.connectorsFromTo[fromIconHandle] !== undefined) {
		if (this.connectorsFromTo[fromIconHandle][toIconHandle] !== undefined) {
			connector = this.connectorsFromTo[fromIconHandle][toIconHandle];
			delete this.connectorsFromTo[fromIconHandle][toIconHandle];
		}
	}

	if (this.connectorsToFrom[toIconHandle] !== undefined) {
		if (this.connectorsToFrom[toIconHandle][fromIconHandle] !== undefined) {
			connector = this.connectorsToFrom[toIconHandle][fromIconHandle];
			delete this.connectorsToFrom[toIconHandle][fromIconHandle];
		}
	}

	delete this.connectorsMappedFrom[connector.uuid];
	delete this.connectorsMappedTo[connector.uuid];

	this.panel.removeChild(connector);

	//Make sure we unfocus the connector if it's being deleted
	if (connector === this.focusedConnector) {
		this.setFocusedConnector(null);
	}

	return;
};

WidgetDesignerView.prototype.removeConnectorsInvolved = function(iconHandle) {
	if (this.connectorsFromTo[iconHandle] !== undefined) {
		for (var k in this.connectorsFromTo[iconHandle]) {
			this.removeConnector(iconHandle, k);
		}
	}

	if (this.connectorsToFrom[iconHandle] !== undefined) {
		for (var k in this.connectorsToFrom[iconHandle]) {
			this.removeConnector(k, iconHandle);
		}
	}
};

WidgetDesignerView.prototype.removeAllConnectors = function() {
	for (var k in this.iconEntries) {
		this.removeConnectorsInvolved(k);
	}
};

WidgetDesignerView.prototype.addIcon = function(
	handle,
	iconType,
	iconBorder,
	initialX,
	initialY,
	badge,
) {
	var currentWidget = this;

	if (this.iconEntries[handle] !== undefined) {
		return;
	}

	var icon = this.getCompleteIconPath(iconType);

	newIconEntry = new DesignerViewIcon(this.root, icon, 'none', badge, handle);

	newIconEntry.addEventListener('pointerdown', function(e) {
		return currentWidget.onIconPointerDown(this, e);
	});

	newIconEntry.addEventListener('pointerup', function(e) {
		return currentWidget.onIconPointerUp(this, e);
	});

	newIconEntry.addEventListener('pointermove', function(e) {
		return currentWidget.onIconPointerMove(this, e);
	});

	newIconEntry.addEventListener('connectorIconDown', function(e) {
		return currentWidget.onIconConnectorDown(this, e);
	});

	this.iconEntries[handle] = newIconEntry;

	var lastIconPosition = this.lastIconAddedPosition;

	if (this.lastIconAdded !== null) {
		lastIconPosition = this.getIconPosition(this.lastIconAdded.getHandle());
	}

	if (lastIconPosition === null) {
		lastIconPosition = this.lastIconAddedPosition;
	}

	if (lastIconPosition !== null) {
		var yOffset = 0;

		if (initialX === undefined || initialX === undefined) {
			initialX = lastIconPosition.x + 100;

			if (initialX + newIconEntry.getWidth() > this.root.getWidth()) {
				initialX = 0;
				yOffset = 100;
			}
		}

		if (initialY === undefined || initialY === undefined) {
			initialY = lastIconPosition.y + yOffset;

			if (initialY + newIconEntry.getHeight() > this.root.getHeight()) {
				initialX = 10;
				initialY = 10;
			}
		}
	} else {
		if (initialX === undefined || initialX === undefined) {
			initialX = lastIconPosition.x + 10;
		}

		if (initialY === undefined || initialY === undefined) {
			initialY = lastIconPosition.y + 10;
		}
	}

	this.panel.addChild(newIconEntry, initialX, initialY, 0.0);
	this.panel.childToFront(newIconEntry);

	this.lastIconAdded = newIconEntry;

	this.lastIconAddedPosition = { x: initialX, y: initialY };

	this.panel.event('iconmove', {
		name: newIconEntry.getHandle(),
		x: initialX,
		y: initialY,
	});

	return newIconEntry;
};

WidgetDesignerView.prototype.setIconType = function(handle, iconType) {
	var iconEntry = this.getIcon(handle);

	if (iconEntry === null || iconEntry === undefined) {
		return;
	}

	var icon = this.getCompleteIconPath(iconType);

	iconEntry.setImage(icon);
	return;
};

WidgetDesignerView.prototype.setIconHandle = function(oldHandle, newHandle) {
	if (this.getIcon(newHandle) !== null || this.getIcon(oldHandle) === null) {
		return;
	}

	this.iconEntries[newHandle] = this.iconEntries[oldHandle];
	delete this.iconEntries[oldHandle];

	this.iconEntries[newHandle].setHandle(newHandle);

	this.connectorsFromTo[newHandle] = this.connectorsFromTo[oldHandle];
	this.connectorsToFrom[newHandle] = this.connectorsToFrom[oldHandle];

	delete this.connectorsFromTo[oldHandle];
	delete this.connectorsToFrom[oldHandle];

	for (var k in this.connectorsFromTo) {
		for (var l in this.connectorsFromTo[k]) {
			if (l === oldHandle) {
				this.connectorsFromTo[k][newHandle] = this.connectorsFromTo[k][
					l
				];
				delete this.connectorsFromTo[k][l];

				this.connectorsMappedTo[
					this.connectorsFromTo[k][newHandle].uuid
				] = newHandle;
			}
		}
	}

	for (var k in this.connectorsToFrom) {
		for (var l in this.connectorsToFrom[k]) {
			if (l === oldHandle) {
				this.connectorsToFrom[k][newHandle] = this.connectorsToFrom[k][
					l
				];
				delete this.connectorsToFrom[k][l];

				this.connectorsMappedFrom[
					this.connectorsToFrom[k][newHandle].uuid
				] = newHandle;
			}
		}
	}
};

WidgetDesignerView.prototype.getIcon = function(iconHandle) {
	if (this.iconEntries[iconHandle] === undefined) {
		return null;
	}

	return this.iconEntries[iconHandle];
};

WidgetDesignerView.prototype.setIconPosition = function(iconHandle, x, y) {
	var currentIcon = this.getIcon(iconHandle);

	if (currentIcon === null) {
		return;
	}

	this.panel.setChildOrientation(currentIcon, x, y);
	this.updateConnectorsPosition(iconHandle);

	return;
};

WidgetDesignerView.prototype.setIconBadge = function(iconHandle, badge) {
	var currentIcon = this.getIcon(iconHandle);

	if (currentIcon === null) {
		return;
	}

	currentIcon.setBadge(badge);
	return;
};

WidgetDesignerView.prototype.getIconPosition = function(iconHandle) {
	var currentIcon = this.getIcon(iconHandle);

	if (currentIcon === null) {
		return null;
	}

	return this.panel.getChildOrientation(currentIcon);
};

WidgetDesignerView.prototype.removeIcon = function(handle) {
	if (this.iconEntries[handle] === undefined) {
		return;
	}

	this.removeConnectorsInvolved(handle);

	//If we are removing the focused icon then it should not be in focus anymore
	if (this.focusedIcon !== null && handle === this.focusedIcon.getHandle()) {
		this.setFocusedIcon(null);
	}

	var currentIconEntry = this.iconEntries[handle];

	this.panel.removeChild(currentIconEntry);

	delete this.iconEntries[handle];
};

WidgetDesignerView.prototype.removeAllIcons = function() {
	for (var k in this.iconEntries) {
		this.removeIcon(k);
	}
};

WidgetDesignerView.prototype.removeEverything = function() {
	this.removeAllConnectors();
	this.removeAllIcons();
};

WidgetDesignerView.prototype.setFocusedConnector = function(connector) {
	this.panel.blur();

	if (this.focusedIcon !== null) {
		this.panel.event('iconblur', this.focusedIcon);
		this.focusedIcon.setSelected(false);
	}

	this.focusedIcon = null;

	if (this.focusedConnector !== null) {
		this.panel.event('connectorblur', {
			connector: this.focusedConnector,
			from: this.connectorsMappedFrom[this.focusedConnector.uuid],
			to: this.connectorsMappedTo[this.focusedConnector.uuid],
		});

		this.focusedConnector.setSelected(false);
	}

	this.focusedConnector = connector;

	if (this.focusedConnector !== null) {
		this.panel.focus();
		this.panel.event('connectorfocus', {
			connector: this.focusedConnector,
			from: this.connectorsMappedFrom[this.focusedConnector.uuid],
			to: this.connectorsMappedTo[this.focusedConnector.uuid],
		});

		this.focusedConnector.setSelected(true);
	}
};

WidgetDesignerView.prototype.getFocusedConnector = function() {
	return this.focusedConnector;
};

WidgetDesignerView.prototype.setFocusedIcon = function(icon) {
	this.panel.blur();

	if (this.focusedConnector !== null) {
		this.panel.event('connectorblur', this.focusedConnector);
		this.focusedConnector.setSelected(false);
	}

	this.focusedConnector = null;

	if (this.focusedIcon !== null) {
		this.panel.event('iconblur', this.focusedIcon);
		this.focusedIcon.setSelected(false);
	}

	this.focusedIcon = icon;

	if (this.focusedIcon !== null) {
		this.panel.event('iconfocus', this.focusedIcon);
		this.focusedIcon.setSelected(true);
		this.lastIconAdded = this.focusedIcon;
		this.lastIconAddedPosition = this.getIconPosition(
			this.focusedIcon.getHandle(),
		);
	}
};
WidgetDesignerView.prototype.getFocusedIcon = function() {
	return this.focusedIcon;
};

WidgetDesignerView.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'Designer View',
		},
	},
);
