function PlaneControllerAppViewStandalone(parentController, elementName) {
	PlaneControllerAppView.call(this, parentController, elementName);

	this._types = ['base', 'app'];
}

PlaneControllerAppViewStandalone.prototype = Object.create(
	PlaneControllerAppView.prototype,
);
PlaneControllerAppViewStandalone.prototype.constructor = PlaneControllerAppViewStandalone;

PlaneControllerAppViewStandalone.prototype.TYPE = 'appStandalone';
PlaneControllerAppViewStandalone.prototype.SUPPORTS = [
	'app',
	'ui',
	'ble',
	'mobile',
	'standalone',
];

PlaneControllerAppViewStandalone.prototype.export = function() {
	var planeExportData = PlaneControllerAppView.prototype.export.call(this);

	return planeExportData;
};

PlaneControllerAppViewStandalone.prototype.language = {
	'en-US': {
		defaultName: 'Application',
	},
};
