function WidgetProjectInfoEditor(id, api, parent, options) {
	const currentWidget = this;

	WidgetPanelBase.call(this, id, api, parent, options);

	this.nameInputId = this.generateChildId('nameInput');
	this.descriptionInputId = this.generateChildId('descriptionInput');
	this.closeButtonId = this.generateChildId('closeButton');
	this.setDefaultDeviceDashboardButtonId = this.generateChildId(
		'defaultDeviceDashboardButton',
	);
	this.deviceDashboardSelectContainerId = this.generateChildId(
		'deviceDashboardSelectContainer',
	);
	this.deviceNameId = this.generateChildId('deviceName');
	this.removeImageId = this.generateChildId('removeImage');
	this.deviceImageId = this.generateChildId('deviceImage');
	this.imageFileInputId = this.generateChildId('imageFileInput');

	this._projectController = null;
}

//WidgetPanelBase Inheritance
WidgetProjectInfoEditor.prototype = Object.create(WidgetPanelBase.prototype);
WidgetProjectInfoEditor.prototype.constructor = WidgetProjectInfoEditor;

WidgetProjectInfoEditor.prototype.getDeviceDashboardConfig = function(
	thingUuid,
	callback,
) {
	const currentWidget = this;

	if (thingUuid === undefined || thingUuid === null) {
		callback.call(this, { type: 'invalidThingUuid' });
		return;
	}
	this.getAPI()
		.getAPIRoute('/user/thing/:thingUuid/view/configuration/:tag')
		.get(thingUuid, LocationDeviceDashboard.name, function(err, config) {
			if (err) {
				callback.call(currentWidget, err, null);
				return;
			}
			callback.call(currentWidget, false, config);
			return;
		});
};

WidgetProjectInfoEditor.prototype.setDefaultDeviceDashboard = function(
	deviceUuid,
	callback,
) {
	callback = callback || function() {};
	const currentWidget = this;

	this.getDeviceDashboardConfig(deviceUuid, function(err, data) {
		if (err) {
			this.getMainContainer().showPopupErrorMessage(
				getLanguageTag(
					this.constructor,
					'failedToGetDeviceDashboardConfig',
				),
			);
			return;
		}
		this._projectController
			.getPlane('Cloud')
			.setDefaultDeviceDashboardConfig(data);

		return;
	});
};

WidgetProjectInfoEditor.prototype.setProjectController = function(
	controller,
	callback,
) {
	const currentWidget = this;

	controller = controller || null;
	if (controller === null) {
		callback.call(this, { type: 'invalidController' });
		return;
	}

	this._projectController = controller;
	controller.getProjectFromServer(function(err, data) {
		const currentProjectData = controller.export();
		let isDashboardSet = currentWidget.isDashboardSet();

		if (!isDashboardSet) {
			isDashboardSet = currentWidget.getLanguageTag('dashboardNotSet');
		} else {
			isDashboardSet = currentWidget.getLanguageTag('dashboardSet');
		}

		currentWidget.renderTemplate(
			{
				nameLabel: getLanguageTag(
					currentWidget.constructor,
					'projectName',
				),
				nameInputId: currentWidget.nameInputId,
				nameValue: currentProjectData.name,
				projectUuidLabel: getLanguageTag(
					currentWidget.constructor,
					'projectUuid',
				),
				projectUuidValue: controller.getProjectUuid(),
				createdLabel: getLanguageTag(
					currentWidget.constructor,
					'created',
				),
				createdValue: moment(data.created).format('lll'),
				modifedLabel: getLanguageTag(
					currentWidget.constructor,
					'modified',
				),
				modifiedValue: moment(data.modified).format('lll'),
				descriptionInputId: currentWidget.descriptionInputId,
				descriptionValue: currentProjectData.description,
				descriptionLabel: getLanguageTag(
					currentWidget.constructor,
					'projectDescription',
				),
				defaultDeviceDashboardLabel: currentWidget.getLanguageTag(
					'defaultDeviceDashboardLabel',
				),
				dashboardSetLabel: currentWidget.getLanguageTag(
					'dashboardSetLabel',
				),
				dashboardSetId: currentWidget.dashboardSetId,
				isDashboardSet: isDashboardSet,
				selectLabel: currentWidget.getLanguageTag('selectLabel'),
				deviceImageLabel: getLanguageTag(
					currentWidget.constructor,
					'deviceImage',
				),
				removeImageId: currentWidget.removeImageId,
				removeImageLabel: getLanguageTag(
					currentWidget.constructor,
					'removeImage',
				),
				deviceImageId: currentWidget.deviceImageId,
				imageFileInputId: currentWidget.imageFileInputId,
				setDefaultDeviceDashboardButtonId:
					currentWidget.setDefaultDeviceDashboardButtonId,
				setDefaultDeviceDashboardButtonLabel: getLanguageTag(
					currentWidget.constructor,
					'setDefaultDeviceDashboard',
				),
				closeButtonId: currentWidget.closeButtonId,
				closeButtonLabel: getLanguageTag(
					currentWidget.constructor,
					'close',
				),
				deviceDashboardSelectContainerId:
					currentWidget.deviceDashboardSelectContainerId,
				deviceImageId: currentWidget.deviceImageId,
				imageFileInputId: currentWidget.imageFileInputId,
			},
			WidgetProjectInfoEditor.name,
		);

		currentWidget.nameInput = $(`#${currentWidget.nameInputId}`);
		currentWidget.descriptionInput = $(
			`#${currentWidget.descriptionInputId}`,
		);
		currentWidget.closeButton = $(`#${currentWidget.closeButtonId}`);
		currentWidget.setDefaultDeviceDashboardButton = $(
			`#${currentWidget.setDefaultDeviceDashboardButtonId}`,
		);
		currentWidget.deviceDashboardSelectContainer = $(
			`#${currentWidget.deviceDashboardSelectContainerId}`,
		);
		currentWidget.removeImage = $(`#${currentWidget.removeImageId}`);
		currentWidget.deviceImage = $(`#${currentWidget.deviceImageId}`);
		currentWidget.imageFileInput = $(`#${currentWidget.imageFileInputId}`);

		currentWidget.removeImage.click(function() {
			currentWidget.unsetImage();
		});

		currentWidget.deviceImage.click(function() {
			currentWidget.imageFileInput.show();
			currentWidget.imageFileInput.trigger('click');
			currentWidget.imageFileInput.hide();
		});

		const defaultDeviceImage =
			((currentProjectData || {}).meta || {}).defaultDeviceImage ||
			'./Resources/icons/SetImage.svg';

		currentWidget.deviceImage.attr('src', defaultDeviceImage);

		currentWidget.addChildWidget(
			WidgetDeviceDataSelect,
			currentWidget.deviceDashboardSelectContainerId,
			{
				onlyDeviceSelect: true,
				deviceFilter: {
					projectUuid: controller.getProjectUuid(),
				},
				showRowSelected: true,
			},
			function(err, deviceSelectWidget) {
				this.deviceDashboardSelect = deviceSelectWidget;
				this.deviceDashboardSelect.hide();

				this.deviceDashboardSelect.addEventListener(
					'confirmed',
					function() {
						const currentValues = currentWidget.deviceDashboardSelect.getValues();

						currentWidget.setDefaultDeviceDashboard(
							currentValues.deviceData.uuid,
						);

						currentWidget.deviceDashboardSelect.hide();
					},
				);

				this.deviceDashboardSelect.addEventListener(
					'dismissed',
					function() {
						currentWidget.deviceDashboardSelect.hide();
					},
				);

				currentWidget.closeButton.click(function() {
					currentWidget.event('closed');
				});

				currentWidget.nameInput.change(function() {
					const newName = $(this)
						.val()
						.toString()
						.trim();
					currentWidget._projectController.setName(newName);
				});

				currentWidget.descriptionInput.change(function() {
					const newDescription = $(this)
						.val()
						.toString();
					currentWidget._projectController.setDescription(
						newDescription,
					);
				});

				currentWidget.deviceImage.click(function(event) {
					event.preventDefault();
				});

				currentWidget.removeImage.click(function() {
					currentWidget.unsetImage(function(err, result) {
						currentWidget.deviceImage.attr(
							'src',
							'./Resources/icons/SetImage.svg',
						);
					});
				});

				currentWidget.imageFileInput.on('change', function(event) {
					currentWidget._onImageFileInputChanged(event);
				});

				currentWidget.setDefaultDeviceDashboardButton.click(function() {
					currentWidget.deviceDashboardSelect.showDeviceSelect(
						(err) => {
							currentWidget.deviceDashboardSelect.show();
						},
					);
				});

				callback.call(this, false);
			},
		);
	});
};

WidgetProjectInfoEditor.prototype.isDashboardSet = function() {
	const dashboard = this._projectController
		.getPlane('Cloud')
		.getMetaValue('LocationDeviceDashboard');
	if (dashboard) {
		return true;
	}
	return false;
};

WidgetProjectInfoEditor.prototype.verifyImageData = function(
	imageData,
	callback,
) {
	const currentWidget = this;
	if (imageData.constructor === File) {
		const file = imageData;
		const fileName = imageData.name;
		if (checkForFileType(fileName, this.VALID_FILE_TYPES) === false) {
			callback.call(this, {
				type: 'invalidImageUploadExtensionError',
			});
			return;
		}
		callback.call(this, false, imageData);
		return;
	} else {
		callback.call(this, false, imageData);
		return;
	}
};

WidgetProjectInfoEditor.prototype.readImageFromFile = function(
	imageFile,
	callback,
) {
	const currentWidget = this;

	const fileReader = new FileReader();
	let base64ImageData = null;
	fileReader.addEventListener('load', function(e) {
		base64ImageData = e.target.result;
		callback.call(currentWidget, false, base64ImageData);
		return;
	});
	fileReader.readAsDataURL(imageFile);
	return;
};

WidgetProjectInfoEditor.prototype.setDefaultDeviceImage = function(
	base64ImageDataOrUrl,
	callback,
) {
	const currentWidget = this;
	if (!base64ImageDataOrUrl) {
		this.image = null;
		callback.call(this, false, null);
		return;
	}
	const img = new Image();
	const canvas = document.createElement('canvas');
	img.src = base64ImageDataOrUrl;
	img.onload = function() {
		if (
			img.height > currentWidget.UPLOADED_IMAGE_HEIGHT_MAX ||
			img.width > currentWidget.UPLOADED_IMAGE_WIDTH_MAX
		) {
			canvas.height = currentWidget.UPLOADED_IMAGE_HEIGHT_MAX;
			canvas.width =
				(currentWidget.UPLOADED_IMAGE_HEIGHT_MAX * img.width) /
				img.height;
		} else {
			canvas.height = img.height;
			canvas.width = img.width;
		}

		canvas
			.getContext('2d')
			.drawImage(img, 0, 0, canvas.width, canvas.height);
		const imageData = canvas.toDataURL('image/png') || null;
		if (!imageData) {
			callback.call(currentWidget, { type: 'invalidImageData' });
			return;
		}

		currentWidget._projectController.setMetaValue(
			'defaultDeviceImage',
			imageData,
		);
		currentWidget.event('changed');
		callback.call(currentWidget, false, base64ImageDataOrUrl);
		return;
	};
};

WidgetProjectInfoEditor.prototype.unsetImage = function(callback) {
	const currentWidget = this;
	callback = callback || function() {};
	this._projectController.setMetaValue('defaultDeviceImage', null);
	currentWidget.event('changed');
	callback.call(currentWidget, false);
};

WidgetProjectInfoEditor.prototype._onImageFileInputChanged = function(event) {
	const currentWidget = this;

	event.preventDefault();
	event.stopPropagation();
	const file = event.target.files[0];
	function reportError(err) {
		currentWidget
			.getMainContainer()
			.showPopupErrorMessage(currentWidget.getLanguageTag(err.type));
		console.error(err);
		return;
	}

	if (file) {
		this.verifyImageData(file, function(err, verifiedImageData) {
			if (err) {
				reportError.call(this, err);
				return;
			}
			this.readImageFromFile(file, function(err, base64ImageData) {
				if (err) {
					reportError.call(this, err);
					return;
				}

				this.setDefaultDeviceImage(base64ImageData, function(
					err,
					data,
				) {
					if (err) {
						reportError.call(this, err);
						return;
					}
					currentWidget.deviceImage.attr('src', data);
				});
			});
		});
	}
};

WidgetProjectInfoEditor.prototype.UPLOADED_IMAGE_HEIGHT_MAX = 256;
WidgetProjectInfoEditor.prototype.UPLOADED_IMAGE_WIDTH_MAX = 256;

WidgetProjectInfoEditor.prototype.VALID_FILE_TYPES = [
	'.png',
	'.jpg',
	'.gif',
	'.jpeg',
	'.svg',
	'.webp',
];

WidgetProjectInfoEditor.prototype.language = deepAssign(
	{},
	WidgetPanelBase.prototype.language,
	{
		'en-US': {
			name: 'Application Info',
			projectName: 'Name',
			created: 'Created',
			modified: 'Updated',
			projectUuid: 'UUID',
			projectDescription: 'Description',
			setDefaultDeviceDashboard: 'Set Default Device Dashboard',
			failedToGetDeviceDashboardConfig:
				"There was an error when trying to get the device's dashboard configuration.",
			deviceImage: 'Default Device Image',
			removeImage: 'Remove Image',
			setProfileImage: 'Set Device Image',
			defaultDeviceDashboardLabel: 'Default Dashboard',
			dashboardSetLabel: 'Status',
			dashboardSet: 'Default dashboard is set for this application.',
			dashboardNotSet: 'Default dashboard not set for this application.',
			selectLabel: 'Select',
			removingImageTitle: 'Remove Image',
			invalidImageUploadExtensionError: 'Invalid image file selected',
		},
	},
);
