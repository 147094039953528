function ElementControllerEmbeddedCloudEvent(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	var dataTypes = [
		'ATMO_DATATYPE_VOID',
		'ATMO_DATATYPE_CHAR',
		'ATMO_DATATYPE_BOOL',
		'ATMO_DATATYPE_INT',
		'ATMO_DATATYPE_UNSIGNED_INT',
		'ATMO_DATATYPE_FLOAT',
		'ATMO_DATATYPE_DOUBLE',
		'ATMO_DATATYPE_BINARY',
		'ATMO_DATATYPE_STRING',
	];

	this.addProperty(
		'instance',
		null,
		0,
		'driverInstance',
		null,
		'embeddedCloud',
	);

	this.addProperty(
		'dataType',
		null,
		'ATMO_DATATYPE_STRING',
		'select',
		dataTypes,
		null,
	);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
    
	ATMO_CLOUD_RegisterCommandRxAbilityHandle(ATMO_ELEMENT_NAME(undefined), ATMO_ABILITY(undefined, commandReceived));
	return ATMO_Status_Success;
	`,
	);

	// NOTE from Nick: commandReceived is the legacy term. This is actually 'Event Received' in the UI
	// This is unable to easily be changed without breaking legacy projects. This 'commandReceived'
	// key is used in lots of generated embedded code. In order to change it, we would have to do difficult
	// text replacement in the embedded code in each .atmo, which I'm not sure can be done reliably
	this.addAbility('commandReceived', [], ['commandReceived'], true);

	this.setAbilityCode(
		'commandReceived',
		`
	ATMO_CreateValueConverted(out, ATMO_PROPERTY(undefined, dataType), in);
	return ATMO_Status_Success;
	`,
	);
}

ElementControllerEmbeddedCloudEvent.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedCloudEvent.prototype.constructor = ElementControllerEmbeddedCloudEvent;

ElementControllerEmbeddedCloudEvent.prototype.NAME = 'CloudEvent';
ElementControllerEmbeddedCloudEvent.prototype.DEFAULT_TRIGGER =
	'commandReceived';
ElementControllerEmbeddedCloudEvent.prototype.DEFAULT_ABILITY = 'setup';
ElementControllerEmbeddedCloudEvent.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerEmbeddedCloudEvent.prototype.TYPE = 'EmbeddedCloudEvent';
ElementControllerEmbeddedCloudEvent.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'embeddedCloud',
];
ElementControllerEmbeddedCloudEvent.prototype.HIDDEN = false;
ElementControllerEmbeddedCloudEvent.prototype.COUPLER_TYPE = 'cloudEvent';
ElementControllerEmbeddedCloudEvent.prototype.CATEGORY = 'cloud';
ElementControllerEmbeddedCloudEvent.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedCloudEvent.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedCloudEvent: 'Cloud Event',

			ATMO_DATATYPE_VOID: 'Void',
			ATMO_DATATYPE_CHAR: 'Character',
			ATMO_DATATYPE_BOOL: 'Boolean',
			ATMO_DATATYPE_INT: 'Integer',
			ATMO_DATATYPE_UNSIGNED_INT: 'Unsigned Integer',
			ATMO_DATATYPE_FLOAT: 'Floating Point (32-bit)',
			ATMO_DATATYPE_DOUBLE: 'Double Floating Point (64-bit)',
			ATMO_DATATYPE_BINARY: 'Raw Binary Data',
			ATMO_DATATYPE_STRING: 'String',
			dataType: 'Data Type',
			commandReceived: 'Event Received',
			instance: 'Cloud Driver Instance',
		},
	},
);
