function WidgetUserAccountMenu(id, api, parent, options) {
	const currentWidget = this;

	this._updateInterval = null;
	this._menuHeaderId = this.generateChildId('header');

	WidgetMenu.call(this, id, api, parent, options);
}

WidgetUserAccountMenu.prototype = Object.create(WidgetMenu.prototype);
WidgetUserAccountMenu.prototype.constructor = WidgetUserAccountMenu;

WidgetUserAccountMenu.prototype.initialize = function(callback) {
	const currentWidget = this;

	this.getMainContainer().addEventListener('profileImageChanged', function(
		event,
	) {
		currentWidget.update();
	});

	this.getMainContainer().addEventListener('profileInfoChanged', function(
		event,
	) {
		currentWidget.update();
	});

	this.update(function(err) {
		if (err) {
			callback.call(this, false);
			return;
		}

		WidgetMenu.prototype.initialize.call(this, callback);
	});
};

WidgetUserAccountMenu.prototype.getUserImage = function(user, callback) {
	const currentWidget = this;
	const api = this.getApiV2().apis;
	api.users
		.getUserImage({ id: user.id })
		.then((image) => {
			callback.call(currentWidget, image);
		})
		.catch(() => {
			callback.call(currentWidget, false);
		});
};

WidgetUserAccountMenu.prototype.update = function(callback) {
	callback = callback || function() {};
	const currentWidget = this;

	this.hide();

	const entries = [
		// {
		// 	label: `<div class="WidgetUserAccountMenu-Entry"><img class="WidgetUserAccountMenu-Icon _svg-inject" src="./Resources/icons/Overview.svg" title="overviewTooltip"></img><div class="WidgetUserAccountMenu-Label">${this.getLanguageTag(
		// 		'overview',
		// 	)}</div></div>`,
		// 	value: 'overview',
		// },
		{
			label: `<div class="WidgetUserAccountMenu-Entry"><img class="WidgetUserAccountMenu-Icon _svg-inject" src="./Resources/icons/UserProfile.svg" title="profileTooltip"></img><div class="WidgetUserAccountMenu-Label">${this.getLanguageTag(
				'profile',
			)}</div></div>`,
			value: 'profile',
		},
		{
			label: `<div class="WidgetUserAccountMenu-Entry"><img class="WidgetUserAccountMenu-Icon _svg-inject" src="./Resources/icons/Notifications.svg" title="notificationsTooltip"></img><div class="WidgetUserAccountMenu-Label">${this.getLanguageTag(
				'notifications',
			)}</div></div>`,
			value: 'notifications',
		},
		{
			label: `<div class="WidgetUserAccountMenu-Entry"><img class="WidgetUserAccountMenu-Icon _svg-inject" src="./Resources/icons/SecurityLock.svg" title="securityTooltip"></img><div class="WidgetUserAccountMenu-Label">${this.getLanguageTag(
				'security',
			)}</div></div>`,
			value: 'security',
		},
		{
			label: `<div class="WidgetUserAccountMenu-Entry"><img class="WidgetUserAccountMenu-Icon _svg-inject" src="./Resources/icons/Exit.svg" title="signOutTooltip"></img><div class="WidgetUserAccountMenu-Label">${this.getLanguageTag(
				'signOut',
			)}</div></div>`,
			value: 'signOut',
		},
	];

	// This widget seems to get instantiated early, before the user is actually logged in
	// If that happens, then just return
	// It will get update later after the user is logged in
	if (!currentWidget.getCurrentUser()) {
		callback.call(currentWidget, false);
		return;
	}

	this.setMenu.call(
		this,
		entries,
		`<div id="${this._menuHeaderId}" class="WidgetUserAccountMenu-Title standard-local-header-h3"></div>`,
	);

	const userInfo = currentWidget.getCurrentUser();

	currentWidget.getUserImage(userInfo, (image) => {
		currentWidget.renderTemplate(
			{
				displayName: `${userInfo.firstName} ${userInfo.lastName}`.trim(),
				email: userInfo.email || '',
				profileImage: image,
			},
			`${WidgetUserAccountMenu.name}_Header`,
			$(`#${currentWidget._menuHeaderId}`),
		);

		$(`#${currentWidget._menuHeaderId}`)
			.find('.WidgetUserAccountMenu-Header-ProfileFrame')
			.on('click', function() {
				currentWidget
					.getMainContainer()
					.setLocation(LocationUserAccount, {
						section: 'profile',
				  		org: getHashCommand().org,
					});
				currentWidget.hide();
			});

		currentWidget.addEventListener('menuEntryClicked', function(data) {
			this.hide();

			switch (data) {
				// case 'overview':
				// 	this.getMainContainer().setLocation(LocationUserAccount, {
				// 		section: 'overview',
				// 		org: getHashCommand().org,
				// 	});
				// 	break;

				case 'security':
					this.getMainContainer().setLocation(LocationUserAccount, {
						section: 'security',
						org: getHashCommand().org,
					});
					break;

				case 'profile':
					this.getMainContainer().setLocation(LocationUserAccount, {
						section: 'profile',
						org: getHashCommand().org,
					});
					break;

				case 'notifications':
					this.getMainContainer().setLocation(LocationUserAccount, {
						section: 'notifications',
						org: getHashCommand().org,
					});
					break;

				case 'cancel':
					this.getMainContainer().setLocation(LocationUserAccount, {
						section: 'cancel',
						org: getHashCommand().org,
					});
					break;

				case 'signOut':
					this.getMainContainer().logoutUser({ forward: false });
					break;
			}
		});

		callback.call(currentWidget, false);
	});

	return;
};

WidgetUserAccountMenu.prototype.remove = function(callback) {
	WidgetMenu.prototype.remove.call(this, callback);
};

WidgetUserAccountMenu.prototype.language = deepAssign(
	{},
	WidgetMenu.prototype.language,
	{
		'en-US': {
			myAccount: 'My Account',
			overview: 'Overview',
			profile: 'Profile',
			notifications: 'Notifications',
			security: 'Security',
			cancel: 'Cancel',
			signOut: 'Sign Out',

			overviewTooltip: 'Account Overview',
			profileTooltip: 'My Profile',
			securityTooltip: 'Account Security',
			notificationsTooltip: 'Notification Preferences',
			cancelTooltip: 'Cancel My Account',
			signOutTooltip: 'Sign Out',
		},
	},
);
