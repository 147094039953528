function BLECentralBackendClientAgent() {
	BLECentralBackendBase.call(this);

	var currentBackend = this;

	this._clientAgent = null;

	var clientAgent = _mainContainer.getClientAgent();

	if (
		clientAgent !== undefined &&
		clientAgent !== null &&
		clientAgent.bleCentral !== undefined &&
		clientAgent.bleCentral !== null
	) {
		this._clientAgent = clientAgent;
	}

	//Hook all events from the client agent up to the Plugin
	this._clientAgent.bleCentral.addEventListener('event', function(data) {
		currentBackend.event(data.type, data.data);
	});

	clientAgent.bleCentral.addEventListener('advertisement', function(data) {
		currentBackend._onAdvertisment(data);
	});

	this._primaryServiceUuidToAddress = {};
	this._searchingPrimaryServiceUuid = null;
}

BLECentralBackendClientAgent.prototype = Object.create(
	BLECentralBackendBase.prototype,
);
BLECentralBackendClientAgent.prototype.constructor = BLECentralBackendClientAgent;

BLECentralBackendClientAgent.prototype.IS_AVAILABLE = function() {
	var clientAgent = _mainContainer.getClientAgent();

	if (
		clientAgent !== undefined &&
		clientAgent !== null &&
		clientAgent.bleCentral !== undefined &&
		clientAgent.bleCentral !== null &&
		clientAgent.isConnected()
	) {
		return true;
	}

	return false;
};

BLECentralBackendClientAgent.prototype._onAdvertisment = function(data) {
	console.log(data);

	var currentBackend = this;

	if (
		this._searchingPrimaryServiceUuid &&
		data.primaryServiceUuid === this._searchingPrimaryServiceUuid
	) {
		this._searchingPrimaryServiceUuid = null;

		currentBackend.stopScanning(function(err) {
			if (err) {
				return;
			}

			currentBackend._primaryServiceUuidToAddress[
				data.primaryServiceUuid
			] = data.address;

			currentBackend.connectToDeviceByAddress(data.address, function(
				err,
			) {});
		});
	}
};

BLECentralBackendClientAgent.prototype.connectToDeviceByAddress = function(
	address,
	callback,
) {
	var currentBackend = this;

	console.log('BLECentralBackendClientAgent: connecting...');

	this._clientAgent.bleCentral.connect(address, function(err) {
		if (err) {
			console.log(err);
			callback.call(currentBackend, err, address);
			return;
		}

		currentBackend._clientAgent.bleCentral.discover(address, function(err) {
			if (err) {
				callback.call(currentBackend, err, address);
				return;
			}

			currentBackend.event('connected', {});

			callback.call(currentBackend, false, address);
			return;
		});
	});
};

BLECentralBackendClientAgent.prototype.connectToDevice = function(
	thingUuid,
	callback,
) {
	this.connectToDeviceByServiceUuid(thingUuid, callback);
};

BLECentralBackendClientAgent.prototype.disconnectFromDevice = function(
	thingUuid,
	callback,
) {
	this.disconnectFromDeviceByPrimaryServiceUuid(thingUuid, callback);
};

BLECentralBackendClientAgent.prototype.connectToDeviceByServiceUuid = function(
	primaryServiceUuid,
	callback,
) {
	var currentBackend = this;

	if (this._primaryServiceUuidToAddress[primaryServiceUuid] !== undefined) {
		callback.call(this, {
			type: 'deviceAlreadyConnectedWithThatServiceUuid',
		});
		return;
	}

	this._clientAgent.bleCentral.getConnectedPeripherals(function(err, data) {
		//Check to see if we are already connected for some reason to the device we are looking for
		for (var i = 0; i < data.length; i++) {
			if (data[i].primaryServiceUuid === primaryServiceUuid) {
				currentBackend._primaryServiceUuidToAddress[
					primaryServiceUuid
				] = data.address;

				this._clientAgent.bleCentral.discover(data.address, function(
					err,
				) {
					callback.call(currentBackend, err);
					return;
				});

				return;
			}
		}

		currentBackend._searchingPrimaryServiceUuid = primaryServiceUuid;

		currentBackend.startScanning(function(err) {
			callback.call(currentBackend, false);
		});
	});
};

BLECentralBackendClientAgent.prototype.disconnectFromDeviceByAddress = function(
	address,
	callback,
) {
	var currentBackend = this;

	this._clientAgent.bleCentral.disconnect(this.address, function(err) {
		for (var k in currentBackend._primaryServiceUuidToAddress) {
			if (currentBackend._primaryServiceUuidToAddress[k] === address) {
				delete currentBackend._primaryServiceUuidToAddress[k];
			}
		}

		currentBackend.event('disconnected', {});

		if (err) {
			callback.call(currentBackend, err);
			return;
		}

		callback.call(currentBackend, false);
		return;
	});
};

BLECentralBackendClientAgent.prototype.disconnectFromDeviceByPrimaryServiceUuid = function(
	primaryServiceUuid,
	callback,
) {
	if (this._primaryServiceUuidToAddress[primaryServiceUuid] === undefined) {
		callback.call(this, { type: 'noDeviceConnectedWithThatServiceUuid' });
		return;
	}

	var currentBackend = this;

	this._clientAgent.bleCentral.disconnect(
		this._primaryServiceUuidToAddress[primaryServiceUuid],
		function(err) {
			delete currentBackend._primaryServiceUuidToAddress[
				primaryServiceUuid
			];

			currentBackend.event('disconnected', {});

			if (err) {
				callback.call(currentBackend, err);
				return;
			}

			callback.call(currentBackend, false);
			return;
		},
	);
};

BLECentralBackendClientAgent.prototype.read = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	callback,
) {
	var currentBackend = this;

	this._clientAgent.bleCentral.readCharacteristic(
		address,
		bleServiceUuid,
		bleCharacteristicUuid,
		function(err, data) {
			callback.call(currentBackend, err, data);
			return;
		},
	);
};

BLECentralBackendClientAgent.prototype.write = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	rawValue,
	callback,
) {
	var currentBackend = this;

	this._clientAgent.bleCentral.writeCharacteristic(
		address,
		bleServiceUuid,
		bleCharacteristicUuid,
		rawValue,
		function(err) {
			callback.call(currentBackend, err);
			return;
		},
	);
};

BLECentralBackendClientAgent.prototype.writeWithoutResponse = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	rawValue,
	callback,
) {
	var currentBackend = this;

	this._clientAgent.bleCentral.writeCharacteristicNoResponse(
		address,
		bleServiceUuid,
		bleCharacteristicUuid,
		rawValue,
		function(err) {
			callback.call(currentBackend, err);
			return;
		},
	);
};

BLECentralBackendClientAgent.prototype.subscribe = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	callback,
) {
	var currentBackend = this;

	this._clientAgent.bleCentral.subscribeCharacteristic(
		address,
		bleServiceUuid,
		bleCharacteristicUuid,
		function(err) {
			callback.call(currentBackend, err);
			return;
		},
	);
};

BLECentralBackendClientAgent.prototype.unsubscribe = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	callback,
) {
	var currentBackend = this;

	this._clientAgent.bleCentral.unsubscribeCharacteristic(
		address,
		bleServiceUuid,
		bleCharacteristicUuid,
		rawValue,
		function(err) {
			callback.call(currentBackend, err);
			return;
		},
	);
};

BLECentralBackendBase.prototype.isScanning = function(callback) {
	var currentBackend = this;

	this._clientAgent.bleCentral.isScanning(function(err, scanning) {
		callback.call(currentBackend, err, scanning);
	});
};

BLECentralBackendClientAgent.prototype.startScanning = function(callback) {
	var currentBackend = this;

	this._clientAgent.bleCentral.startScanning(function(err) {
		currentBackend.event('scanningStarted', {});

		callback.call(currentBackend, err);
	});
};

BLECentralBackendClientAgent.prototype.stopScanning = function(callback) {
	var currentBackend = this;

	this._clientAgent.bleCentral.stopScanning(function(err) {
		currentBackend.event('scanningStopped', {});

		callback.call(currentBackend, err);
	});
};

BLECentralBackendClientAgent.prototype.getDiscovered = function(callback) {
	var currentBackend = this;

	this._clientAgent.bleCentral.getDiscoveredPeripherals(function(err, data) {
		callback.call(currentBackend, err, data);
	});
};

BLECentralBackendClientAgent.prototype.getServices = function(
	address,
	callback,
) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendClientAgent.prototype.getServiceCharacteristics = function(
	address,
	serviceUuid,
	callback,
) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendClientAgent.prototype.remove = function(callback) {
	var currentBackend = this;

	currentBackend.stopScanning(function(err) {
		currentBackend._clientAgent.bleCentral.disconnectAll(function(err) {
			callback.call(currentBackend, err);
		});
	});
};
