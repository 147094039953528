function PlaneControllerCloudStandalone(parentController, elementName) {
	PlaneControllerBase.call(this, parentController, elementName);
}

PlaneControllerCloudStandalone.prototype = Object.create(
	PlaneControllerBase.prototype,
);
PlaneControllerCloudStandalone.prototype.constructor = PlaneControllerCloudStandalone;

PlaneControllerCloudStandalone.prototype.TYPE = 'cloudStandalone';

PlaneControllerCloudStandalone.prototype.SUPPORTS = [
	'cloud',
	'aws',
	'standalone',
];

PlaneControllerCloudStandalone.prototype.COUPLING_BADGE = 'cloudBadge';

PlaneControllerCloudStandalone.prototype.PLANE_ICON =
	'./Resources/icons/CloudView.svg';

PlaneControllerCloudStandalone.prototype.setDefaultDeviceDashboardConfig = function(
	config,
) {
	this.setMetaValue('LocationDeviceDashboard', config);
	return;
};

PlaneControllerCloudStandalone.prototype.export = function() {
	var planeExportData = PlaneControllerBase.prototype.export.call(this);

	planeExportData.layout = {};

	planeExportData.layout.height = 1000;
	planeExportData.layout.width = 1000;
	planeExportData.layout.zIndexOrder = [];

	return planeExportData;
};

PlaneControllerCloudStandalone.prototype.language = {
	'en-US': {
		defaultName: 'Cloud',
	},
};
