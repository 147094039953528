function ElementControllerEmbeddedNxpRpkThread(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('join', [], [], false, false);
	this.setAbilityCode(
		'join',
		`
    ATMO_THREAD_VOLANSYS_TryJoin();
    return ATMO_Status_Success;
    `,
	);

	this.addAbility('setTemperature', [], [], false, false);
	this.setAbilityCode(
		'setTemperature',
		`
    int temperature;
    ATMO_GetInt(in, &temperature);
    ATMO_THREAD_VOLANSYS_SetTemperature(temperature);
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('setHumidity', [], [], false, false);
	this.setAbilityCode(
		'setHumidity',
		`
    int humidity;
    ATMO_GetInt(in, &humidity);
    ATMO_THREAD_VOLANSYS_SetHumidity(humidity);
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('setAmbientLight', [], [], false, false);
	this.setAbilityCode(
		'setAmbientLight',
		`
    int ambientLight;
    ATMO_GetInt(in, &ambientLight);
    ATMO_THREAD_VOLANSYS_SetAmbientLight(ambientLight);
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('setPressureHpa', [], [], false, false);
	this.setAbilityCode(
		'setPressureHpa',
		`
    int press;
    ATMO_GetInt(in, &press);
    ATMO_THREAD_VOLANSYS_SetPressure(press);
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('setAirQualityTvoc', [], [], false, false);
	this.setAbilityCode(
		'setAirQualityTvoc',
		`
    int air;
    ATMO_GetInt(in, &air);
    ATMO_THREAD_VOLANSYS_SetAirQualityTvoc(air);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setAirQualityCO2', [], [], false, false);
	this.setAbilityCode(
		'setAirQualityCO2',
		`
    int air;
    ATMO_GetInt(in, &air);
    ATMO_THREAD_VOLANSYS_SetAirQualityCO2(air);
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('setAccelX', [], [], false, false);
	this.setAbilityCode(
		'setAccelX',
		`
    float value = 0.0;
    ATMO_GetFloat(in, &value);
    ATMO_THREAD_VOLANSYS_SetAccelX(value);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setAccelY', [], [], false, false);
	this.setAbilityCode(
		'setAccelY',
		`
    float value = 0.0;
    ATMO_GetFloat(in, &value);
    ATMO_THREAD_VOLANSYS_SetAccelY(value);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setAccelZ', [], [], false, false);
	this.setAbilityCode(
		'setAccelZ',
		`
    float value = 0.0;
    ATMO_GetFloat(in, &value);
    ATMO_THREAD_VOLANSYS_SetAccelZ(value);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setMagX', [], [], false, false);
	this.setAbilityCode(
		'setMagX',
		`
    float value = 0.0;
    ATMO_GetFloat(in, &value);
    ATMO_THREAD_VOLANSYS_SetMagX(value);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setMagY', [], [], false, false);
	this.setAbilityCode(
		'setMagY',
		`
    float value = 0.0;
    ATMO_GetFloat(in, &value);
    ATMO_THREAD_VOLANSYS_SetMagY(value);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setMagZ', [], [], false, false);
	this.setAbilityCode(
		'setMagZ',
		`
    float value = 0.0;
    ATMO_GetFloat(in, &value);
    ATMO_THREAD_VOLANSYS_SetMagZ(value);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setGyroX', [], [], false, false);
	this.setAbilityCode(
		'setGyroX',
		`
    float value = 0.0;
    ATMO_GetFloat(in, &value);
    ATMO_THREAD_VOLANSYS_SetRotspeedX(value);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setGyroY', [], [], false, false);
	this.setAbilityCode(
		'setGyroY',
		`
    float value = 0.0;
    ATMO_GetFloat(in, &value);
    ATMO_THREAD_VOLANSYS_SetRotspeedY(value);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setGyroZ', [], [], false, false);
	this.setAbilityCode(
		'setGyroZ',
		`
    float value = 0.0;
    ATMO_GetFloat(in, &value);
    ATMO_THREAD_VOLANSYS_SetRotspeedZ(value);
	return ATMO_Status_Success;
	`,
	);
}

ElementControllerEmbeddedNxpRpkThread.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedNxpRpkThread.prototype.constructor = ElementControllerEmbeddedNxpRpkThread;

ElementControllerEmbeddedNxpRpkThread.prototype.DEFAULT_TRIGGER = 'triggered';
ElementControllerEmbeddedNxpRpkThread.prototype.DEFAULT_ABILITY =
	'setTemperature';
ElementControllerEmbeddedNxpRpkThread.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerEmbeddedNxpRpkThread.prototype.TYPE = 'EmbeddedNxpRpkThread';
ElementControllerEmbeddedNxpRpkThread.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'rpk',
];
ElementControllerEmbeddedNxpRpkThread.prototype.HIDDEN = false;
ElementControllerEmbeddedNxpRpkThread.prototype.CATEGORY = 'Rapid IOT';
ElementControllerEmbeddedNxpRpkThread.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedNxpRpkThread.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedNxpRpkThread: 'Volansys Thread',
		},
	},
);
