function PlaneControllerEmbeddedNRF52840DK(parentController, elementName) {
	PlaneControllerEmbeddedNordic.call(this, parentController, elementName);
}

PlaneControllerEmbeddedNRF52840DK.prototype = Object.create(
	PlaneControllerEmbeddedNordic.prototype,
);
PlaneControllerEmbeddedNRF52840DK.prototype.constructor = PlaneControllerEmbeddedNRF52840DK;

PlaneControllerEmbeddedNRF52840DK.prototype.VARIANTS = ['nRF52840DK'];
PlaneControllerEmbeddedNRF52840DK.prototype.CLIENT_AGENT_REQUIRED = false;

PlaneControllerEmbeddedNRF52840DK.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'LED1',
		mode: 'ATMO_GPIO_PinMode_Output_PushPull',
	},

	EmbeddedADCPin: {
		instance: 0,
		pin: 'A0',
	},

	EmbeddedPWMPin: {
		instance: 0,
		pin: 'p13',
	},

	EmbeddedBMI160: {
		i2cInstance: 1,
	},

	EmbeddedXbeeLteNbIoT: {
		uartInstance: 1,
	},

	EmbeddedXbeeLteCat1: {
		uartInstance: 1,
	},

	EmbeddedGroveLight: {
		adcInstance: 0,
		adcPin: 'A0',
	},

	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'p13',
	},

	EmbeddedGroveTemperature: {
		adcInstance: 0,
		adcPin: 'A0',
	},

	EmbeddedGroveRotaryAngle: {
		adcInstance: 0,
		adcPin: 'A0',
	},

	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'p13',
	},

	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'p13',
	},

	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'p13',
	},
};

PlaneControllerEmbeddedNRF52840DK.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedNordic.prototype.language,
	{
		'en-US': {
			defaultName: 'nRF52840-DK',
		},
	},
);
