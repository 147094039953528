function LocationDashboards(id, api, parentWidget, options) {
	options = options || {};

	LocationBase.call(this, id, api, parentWidget, options);
}

LocationDashboards.prototype = Object.create(LocationBase.prototype);
LocationDashboards.prototype.constructor = LocationDashboards;

LocationDashboards.prototype.initialize = function(callback) {
	this.loadConfiguration(function(err) {
		LocationBase.prototype.initialize.call(this, callback);
	});
};

LocationDashboards.prototype.setDashboardConfiguration = function(
	childId,
	configuration,
	callback,
) {
	const currentLocation = this;

	const dashboardWidget = this.getChildWidget(childId);

	if (!dashboardWidget) {
		console.trace();
		callback.call(this, { type: 'invalidChildIdForDashboard' });
		return;
	}

	dashboardWidget.setConfiguration(configuration, function(err) {
		dashboardWidget.addEventListener('configurationChanged', function() {
			currentLocation.dashboard.configuration = dashboardWidget.getConfiguration();
			currentLocation.saveConfiguration();
		});

		dashboardWidget.addEventListener('configurationReset', function() {
			currentLocation.setDefaultConfiguration();
			currentLocation.renderDashboard(callback);
		});
		
		dashboardWidget.addEventListener('eraseDashboard', function(data) {
			currentLocation.onEraseDashboard(data);
		});

		callback.call(currentLocation, err);
	});
};

LocationDashboards.prototype.renderDashboard = function(callback) {
	const currentLocation = this;

	const tabsConfig = [
		{
			childId: 'WidgetDashboard',
			label: 'WidgetDashboard',
			constructor: WidgetDashboard,
			options: {
				showChildrenInherit: currentLocation
					.getCurrentUser()
					.ability.can('see', 'Organizations'),
				readOnly: currentLocation
					.getCurrentUser()
					.ability.cannot('update', 'Dashboard'),
			},
		},
	];

	this.setTabWidgets(null, tabsConfig, function(err) {
		currentLocation.setDashboardConfiguration(
			'WidgetDashboard',
			currentLocation.dashboard.configuration,
			callback,
		);
	});
};

LocationDashboards.prototype.loadConfiguration = function(callback) {
	const currentLocation = this;

	const api = this.getApiV2().apis;

	this.getOrganizationId((err, orgId) => {
		
		if(err) {
			callback.call(currentLocation, err);
			return;
		}
		
		api.organizations
			.getDashboard({
				id: orgId,
			})
			.then((dashboard) => {
				currentLocation.dashboard = dashboard;
				currentLocation.renderDashboard(callback);
			})
			.catch((err) => {
				console.error('Failed to load dashboard: ', err);
				currentLocation
					.getMainContainer()
					.showPopupErrorMessage(
						getLanguageTag(
							currentLocation.constructor,
							'failedToLoadDashboardConfiguration',
						),
					);
				callback.call(currentLocation, err);
			});
	});
};

LocationDashboards.prototype.saveConfiguration = function(callback) {
	callback = callback || function() {};

	const currentLocation = this;

	const api = this.getApiV2().apis;

	this.getOrganizationId((err, orgId) => {
		api.organizations
			.updateDashboard(
				{
					id: orgId,
				},
				{
					requestBody: currentLocation.dashboard,
				},
			)
			.then(() => {
				callback.call(currentLocation, false);
			})
			.catch((err) => {
				currentLocation
					.getMainContainer()
					.showPopupErrorMessage(
						getLanguageTag(
							currentLocation.constructor,
							'failedToSaveDashboardConfiguration',
						),
					);
				callback.call(currentLocation, err);
			});
	});
};

LocationDashboards.prototype.setDefaultConfiguration = function(callback) {
	this.dashboard = {
		options: {},
		configuration: {
			widgets: [],
		},
	};

	this.saveConfiguration(callback);
};

LocationDashboards.prototype.onEraseDashboard = function(data, callback) {
	
	const currentLocation = this;
	
	callback = callback || function(){};
	
	const api = this.getApiV2().apis;
	
	this.getOrganizationId((err, orgId) => {

		if(err) {
			callback.call(currentLocation, err);
			return;
		}
		
		api.organizations
			.deleteDashboard(
				{
					id: orgId,
				},
			)
			.then(() => {
				currentLocation.loadConfiguration(callback);
			})
			.catch((err) => {
				callback.call(currentLocation, err);
			});
	});
};

LocationDashboards.prototype.language = deepAssign(
	{},
	LocationBase.prototype.language,
	{
		'en-US': {
			name: 'Dashboard',
			failedToLoadDashboardConfiguration:
				'Failed to load dashboard configurations',
			failedToSaveDashboardConfiguration:
				'Failed to save dashboard configurations',
		},
	},
);
