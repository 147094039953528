function WidgetProjectsTable(id, api, parentWidget, options) {
	WidgetBase.call(this, id, api, parentWidget, options);

	this._table = null;
	this.tableContainerId = this.generateChildId('tableContainer');
	this.searchContainerId = this.generateChildId('searchContainer');
	this.deleteButtonId = this.generateChildId('deleteButton');
	this.showChildrenCheckboxId = this.generateChildId('showChildrenCheckbox');
	this.searchContainerId = this.generateChildId('searchContainer');
	this.devicesTableContainerId = this.generateChildId(
		'devicesTableContainer',
	);
}

WidgetProjectsTable.prototype = Object.create(WidgetBase.prototype);
WidgetProjectsTable.prototype.constructor = WidgetProjectsTable;

WidgetProjectsTable.prototype.initialize = function(callback) {
	const currentWidget = this;
	const currentUser = this.getMainContainer().getCurrentUser();
	const api = currentWidget.getApiV2();

	this.getOrganizationContext((err, currentOrg) => {
		this.renderTemplate(
			{
				tableContainerId: this.tableContainerId,
				searchContainerId: this.searchContainerId,
				deleteButtonId: this.deleteButtonId,
				showChildrenCheckboxId: this.showChildrenCheckboxId,
				deleteDevice: this.getLanguageTag('removeSelectedProjects'),
				showSubOrganizations: this.getLanguageTag(
					'showSubOrganizations',
				),
				canSeeOrgs:
					currentUser.ability.can('see', 'Organizations') &&
					currentOrg.hasChildren,
			},
			WidgetProjectsTable.name,
		);

		this.searchContainer = $(`#${this.searchContainerId}`);
		this.deleteButton = $(`#${this.deleteButtonId}`);
		this.showChildrenCheckbox = $(`#${this.showChildrenCheckboxId}`);
		this.showChildrenCheckbox = $(`#${this.showChildrenCheckboxId}`);

		this.deleteButton.hide();

		this.deleteButton.on('click', (e) => {
			currentWidget._onRemoveSelectedProjects();
		});

		this.showChildrenCheckbox.on('click', (e) => {
			currentWidget.update(() => {});
		});

		const getProject = async function(apiFn, config, params) {
			const parameters = {
				...params,
			};
			const projects = await apiFn(parameters);

			return {
				last_page: projects.meta.totalPages,
				data: projects.data,
			};
		};

		this.addChildWidget(
			WidgetSearchBox,
			this.searchContainerId,
			{
				onSearch: () => {
					currentWidget.update(() => {});
				},
			},
			function(err, searchBoxWidget) {
				currentWidget.searchBoxWidget = searchBoxWidget;

				this.addChildWidget(
					WidgetTableDynamic,
					currentWidget.tableContainerId,
					{
						hideFilterInput: false,
						selectable: true,
						apiRoute: api.apis.projects.getProjects.bind(
							currentWidget,
						),
						ajaxSorting: true,
						headerSort: true,
						initialSort: [{ column: 'modified', dir: 'desc' }],
						paginationSize: 20,
						addParams: () => {
							const showAll = currentWidget.showChildrenCheckbox.prop(
								'checked',
							);
							const params = {
								organizationId: getHashCommand().org,
								depth: showAll ? 'all' : '1',
							};
							const searchText = currentWidget.searchBoxWidget.getSearchText();
							if (searchText && searchText.length > 0) {
								params.searchText = searchText;
							}

							return params;
						},
						entryMapper: async (entry) => {
							entry.nameLink = currentWidget.formatterProjectName(
								entry,
							);
							entry.type = entry.type;
							entry.created = new Date(entry.createdAt);
							entry.modified = new Date(entry.updatedAt);
							return entry;
						},
						onRowClicked: (entry) => {
							return entry;
						},
						onSelectionChanged: (rows) => {
							if (rows.length > 0) {
								currentWidget.deleteButton.show();
							} else {
								currentWidget.deleteButton.hide();
							}
						},
						tableBuilt: function() {
							currentWidget.injectSVGElements();
						},
						columns: [
							{
								formatter: 'rowSelection',
								titleFormatter: 'rowSelection',
								hozAlign: 'center',
								width: 10,
								responsive: 0,
								headerSort: false,
							},
							{
								title: 'Name',
								field: 'nameLink',
								formatter: 'html',
							},
							{
								title: 'Type',
								field: 'type',
							},
							{
								title: 'Organization',
								field: 'organizationName',
								headerSort: false,
								visible: currentUser.ability.can(
									'see',
									'Organizations',
								),
							},
							{
								title: 'Created',
								field: 'created',
								formatter: 'datetime',
								formatterParams: {
									humanize: true,
									suffix: true,
									invalidPlaceholder: 'Never',
									outputFormat: 'MMMM Do YYYY, h:mm a',
								},
							},
							{
								title: 'Updated',
								field: 'modified',
								formatter: 'datetime',
								headerSortStartingDir: 'desc',
								formatterParams: {
									humanize: true,
									suffix: true,
									invalidPlaceholder: 'Never',
									outputFormat: 'MMMM Do YYYY, h:mm a',
								},
							},
						],
					},

					(err, tableWidget) => {
						currentWidget._table = tableWidget;
						WidgetBase.prototype.initialize.call(
							currentWidget,
							callback,
						);
					},
				);
			},
		);
	});
};

WidgetProjectsTable.prototype.update = function(callback) {
	const doneCb = callback || function() {};
	const currentWidget = this;
	currentWidget._table.update();
};

WidgetProjectsTable.prototype.formatterProjectName = function(entry) {
	return `<a href="${generateHashCommand({
		location: 'ProjectEditor',
		projectId: entry.id,
		projectUuid: entry.uuid,
		org: entry.organizationId,
	})}" class="WidgetProjectTable-ProjectName NoSelect">${entry.name}</a>`;
};

WidgetProjectsTable.prototype._onRemoveSelectedProjects = function() {
	const currentWidget = this;
	const selectedRowsData = this._table.getSelectedRowsData();
	const api = currentWidget.getApiV2();

	this.getMainContainer().showConfirm(
		{
			message: this.getLanguageTag('confirmRemoveSelectedProjects'),
			title: this.getLanguageTag('confirmRemoveTitle'),
			confirmLabel: this.getLanguageTag('confirmRemoveButton'),
			confirmClasses: ['btn-danger'],
		},

		async function() {
			const promises = selectedRowsData.map((project) => {
				return api.apis.projects.deleteProject({ id: project.id });
			});

			try {
				await Promise.all(promises);
				currentWidget
					.getMainContainer()
					.showPopupInfoMessage(
						currentWidget.getLanguageTag('projectsDeleted'),
					);
				currentWidget.event('changed');
				currentWidget.update();
			} catch (e) {
				console.error('Error deleting projects', e);
				currentWidget
					.getMainContainer()
					.showPopupErrorMessage(
						currentWidget.getLanguageTag('errorRemovingProjects'),
					);
			}
		},
	);
};

WidgetProjectsTable.prototype.language = deepAssign(
	{},
	WidgetTable.prototype.language,
	{
		'en-US': {
			name: 'My Applications',
			projectName: 'Name',
			type: 'Type',
			description: 'Description',
			created: 'Created',
			updated: 'Updated',
			noData: "You don't have any applications created!",
			removeSelectedProjects: 'Delete',
			filterInputPlaceHolder: 'Search for an application...',
			confirmRemoveTitle: 'Delete Application(s)',
			confirmRemoveSelectedProjects:
				'Are you sure you want to delete the selected applications?',
			confirmRemoveButton: 'Delete',
			projectsDeleted: 'Selected applications successfully deleted',
			showSubOrganizations: 'Show Sub-Organizations',
			errorRemovingProjects:
				'There was an error removing the selected applications',
		},
	},
);
