// Copyright 2018 Atmosphere IoT Corp.
// All rights reserved
// jshint esversion: 6

function WidgetDashboardMenu(id, api, parent, options) {
	WidgetPanelBase.call(this, id, api, parent, options);
}

WidgetDashboardMenu.prototype = Object.create(WidgetPanelBase.prototype);
WidgetDashboardMenu.prototype.constructor = WidgetDashboardMenu;

WidgetDashboardMenu.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.menuEntryIdToValue = {};
	this.optionEntryIdToValue = {};

	const currentUser = _mainContainer.getCurrentUser();
	
	var menuEntries = [
		{
			childId: WidgetDataGlance.name,
			imageSrc:
				this.WIDGET_MENU_ICON_PATH + WidgetDataGlance.name + '.svg',
			label: getLanguageTag(WidgetDataGlance, 'name'),
			value: WidgetDataGlance,
		},

		{
			childId: WidgetDataBulletin.name,
			imageSrc:
				this.WIDGET_MENU_ICON_PATH + WidgetDataBulletin.name + '.svg',
			label: getLanguageTag(WidgetDataBulletin, 'name'),
			value: WidgetDataBulletin,
		},

		{
			childId: WidgetDataGauge.name,
			imageSrc:
				this.WIDGET_MENU_ICON_PATH + WidgetDataGauge.name + '.svg',
			label: getLanguageTag(WidgetDataGauge, 'name'),
			value: WidgetDataGauge,
		},

		{
			childId: WidgetDataGraph.name,
			imageSrc:
				this.WIDGET_MENU_ICON_PATH + WidgetDataGraph.name + '.svg',
			label: getLanguageTag(WidgetDataGraph, 'name'),
			value: WidgetDataGraph,
		},

		{
			childId: WidgetDataTable.name,
			imageSrc:
				this.WIDGET_MENU_ICON_PATH + WidgetDataTable.name + '.svg',
			label: getLanguageTag(WidgetDataTable, 'name'),
			value: WidgetDataTable,
		},

		{
			childId: WidgetDeviceMap.name,
			imageSrc:
				this.WIDGET_MENU_ICON_PATH + WidgetDeviceMap.name + '.svg',
			label: getLanguageTag(WidgetDeviceMap, 'name'),
			value: WidgetDeviceMap,
		},

		{
			childId: WidgetTrailMap.name,
			imageSrc: this.WIDGET_MENU_ICON_PATH + WidgetTrailMap.name + '.svg',
			label: getLanguageTag(WidgetTrailMap, 'name'),
			value: WidgetTrailMap,
		},
	];

	// Only show certain entries for the org dashboards and not device dashboards
	if(getHashCommand().location === 'Dashboards' && currentUser.ability.can('see', 'Organizations')) {
		menuEntries.push({
			childId: WidgetOrganizationNavigator.name,
			imageSrc: this.WIDGET_MENU_ICON_PATH + WidgetOrganizationNavigator.name + '.svg',
			label: getLanguageTag(WidgetOrganizationNavigator, 'name'),
			value: WidgetOrganizationNavigator,
		});
	}
	
	var optionEntries = [
		{
			childId: 'eraseDashboard',
			imageSrc: './Resources/icons/EraseDashboard.svg',
			label: getLanguageTag(this.constructor, 'eraseDashboard'),
			value: 'erase',
		},

		{
			childId: 'resetDashboard',
			imageSrc: './Resources/icons/Refresh.svg',
			label: getLanguageTag(this.constructor, 'resetDashboard'),
			value: 'reset',
		},
	];

	this.getOrganizationContext((err, orgContext) => {
		
		if(err) {
			callback.call(currentWidget, err);
			return;
		}
		
		if (this.getOptions().showChildrenInherit === true) {
			optionEntries = [
				{
					childId: 'inheritable',
					imageSrc: './Resources/icons/Copy.svg',
					label: getLanguageTag(this.constructor, 'inheritable'),
					type: 'toggle',
					checked:
						orgContext
							.childrenInheritDashboard === true
							? true
							: false,
					value: 'inheritable',
				},
				...optionEntries,
			];
		}

		for (var i = 0; i < menuEntries.length; i++) {
			menuEntries[i].id = this.generateChildId(menuEntries[i].childId);

			this.menuEntryIdToValue[menuEntries[i].id] = menuEntries[i].value;
		}

		for (var i = 0; i < optionEntries.length; i++) {
			optionEntries[i].id = this.generateChildId(optionEntries[i].childId);

			this.optionEntryIdToValue[optionEntries[i].id] = optionEntries[i].value;
		}

		this.renderTemplate({
			labelDashboardWidgets: getLanguageTag(
				this.constructor,
				'dashboardWidgets',
			),
			labelDashboardSettings: getLanguageTag(
				this.constructor,
				'dashboardSettings',
			),
			labelDashboardOptions: getLanguageTag(
				this.constructor,
				'dashboardOptions',
			),
			entries: menuEntries,
			optionEntries: optionEntries,
		});

		for (var i = 0; i < menuEntries.length; i++) {
			$(`#${menuEntries[i].id}`).click(function(e) {
				currentWidget.event(
					'menuEntrySelected',
					currentWidget.menuEntryIdToValue[this.id],
				);
			});
		}

		for (var i = 0; i < optionEntries.length; i++) {
			if (optionEntries[i].type === 'toggle') {
				$(`#${optionEntries[i].id}`).change(function(e) {
					currentWidget.event('optionEntrySelected', {
						type: currentWidget.optionEntryIdToValue[this.id],
						value: e.target.checked,
					});
				});
			} else {
				$(`#${optionEntries[i].id}`).click(function(e) {
					currentWidget.event('optionEntrySelected', {
						type: currentWidget.optionEntryIdToValue[this.id],
					});
				});
			}
		}

		WidgetPanelBase.prototype.initialize.call(this, callback);
	});
};

WidgetDashboardMenu.prototype.onMenuEntryClicked = function(value) {};

WidgetDashboardMenu.prototype.WIDGET_MENU_ICON_PATH =
	'./Widgets/WidgetDashboardMenu/Resources/';

WidgetDashboardMenu.prototype.language = deepAssign(
	{},
	WidgetPanelBase.prototype.language,
	{
		'en-US': {
			name: 'Configure Dashboard',
			dashboardWidgets: 'Dashboard Widgets',
			dashboardSettings: 'Dashboard Settings',
			dashboardOptions: 'Dashboard Options',
			refreshDashboard: 'Refresh Dashboard',
			eraseDashboard: 'Erase Dashboard',
			resetDashboard: 'Reset Dashboard',
			inheritable: 'Inheritable by Children',
		},
	},
);
