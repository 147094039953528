function WidgetAlert(id, api, parent, options) {
	var currentWidget = this;

	WidgetPanelBase.call(this, id, api, parent, options);

	var message = this.getOptions().message || '';

	this.footerContainerId = this.generateChildId('footerContainer');

	this.renderTemplate(
		{
			message: message,
			footerContainerId: this.footerContainerId,
		},
		WidgetAlert.name,
	);

	if (this.getOptions().title) {
		this.setTitle(this.getOptions().title);
	}
}

WidgetAlert.prototype = Object.create(WidgetPanelBase.prototype);
WidgetAlert.prototype.constructor = WidgetAlert;

WidgetAlert.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.addChildWidget(
		WidgetButtonsFooter,
		this.footerContainerId,
		{},
		function(err, footerWidget) {
			footerWidget.setButtons([
				{
					label:
						this.getOptions().closeLabel ||
						getLanguageTag(this.constructor, 'dismiss'),
					value: 'dismissed',
					classes: ['btn-primary'],
				},
			]);

			footerWidget.addEventListener('footerButtonPressed', function(
				value,
			) {
				currentWidget.event(value);
			});

			WidgetPanelBase.prototype.initialize.call(this, callback);
		},
	);
};

WidgetAlert.prototype.language = deepAssign(
	{},
	WidgetPanelBase.prototype.language,
	{
		'en-US': {
			name: 'Alert',
			dismiss: 'Close',
		},
	},
);
