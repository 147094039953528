function DesignerViewPanel(root) {
	CrocPanel.call(this, root);

	this.strokeStyle = getComputedStyle(this.getRoot().canvas).getPropertyValue(
		'--focusForegroundColor',
	);

	this.gridBackgroundCachedCanvas = null;
}

DesignerViewPanel.prototype = Object.create(CrocPanel.prototype);
DesignerViewPanel.prototype.constructor = DesignerViewPanel;

DesignerViewPanel.prototype.paintBackgroundGrid = function(
	context,
	width,
	height,
) {
	context.save();

	var step = 50;
	context.strokeStyle = this.strokeStyle;

	var i = 0;

	for (i = 0; i < width / step; i++) {
		context.beginPath();
		context.moveTo(i * step, 0);
		context.lineTo(i * step, height);
		context.stroke();
	}

	for (i = 0; i < height / step; i++) {
		context.beginPath();
		context.moveTo(0, i * step);
		context.lineTo(width, i * step);
		context.stroke();
	}

	context.restore();
};

DesignerViewPanel.prototype.paintDottedGrid = function(context, width, height) {
	var step = 50;

	if (this.gridBackgroundCachedCanvas === null) {
		this.gridBackgroundCachedCanvas = document.createElement('canvas');
		this.gridBackgroundCachedCanvas.width = width;
		this.gridBackgroundCachedCanvas.height = height;
		var temp_context = this.gridBackgroundCachedCanvas.getContext('2d');

		temp_context.save();
		temp_context.fillStyle = getComputedStyle(
			this.getRoot().canvas,
		).getPropertyValue('--objectBackgroundColor');

		var interations = width / step;

		for (var i = 0; i < interations; i++) {
			for (var j = 0; j < interations; j++) {
				temp_context.beginPath();
				temp_context.arc(i * 50 + 25, j * 50 + 25, 2, 0, 2 * Math.PI);
				temp_context.fill();
			}
		}

		temp_context.restore();
	}

	context.drawImage(this.gridBackgroundCachedCanvas, 0, 0);
};

DesignerViewPanel.prototype.paint = function(context, width, height) {
	if (!this.visible) {
		return null;
	}

	this.paintDottedGrid(context, width, height);
	// 	this.paintBackgroundGrid(context, width, height);

	CrocPanel.prototype.paint.call(this, context, width, height);
};
