function PlaneControllerEmbeddedOkdoE1(parentController, elementName) {
	PlaneControllerEmbeddedBase.call(this, parentController, elementName);
}

PlaneControllerEmbeddedOkdoE1.prototype = Object.create(
	PlaneControllerEmbeddedBase.prototype,
);
PlaneControllerEmbeddedOkdoE1.prototype.constructor = PlaneControllerEmbeddedOkdoE1;
PlaneControllerEmbeddedOkdoE1.prototype.CLIENT_AGENT_REQUIRED = true;
PlaneControllerEmbeddedOkdoE1.prototype.TYPE = 'LPC55S69';
PlaneControllerEmbeddedOkdoE1.prototype.VARIANTS = ['OkdoE1'];

PlaneControllerEmbeddedOkdoE1.prototype.DRIVERS = {
	adc: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_ADC_ADC1',
		},
	],
	block: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLOCK_BLOCK1',
		},
	],
	datetime: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_DATETIME_DATETIME1',
		},
	],
	filesytem: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_FILESYSTEM_FILESYSTEM1',
		},
	],
	gpio: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_GPIO_GPIO1',
		},
	],
	http: [],
	i2c: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C1',
		},
		{
			id: 1,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C2',
		},
	],
	interval: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_INTERVAL_INTERVAL1',
		},
	],
	pwm: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_PWM_PWM1',
		},
	],
	spi: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_SPI_SPI1',
		},
	],
	uart: [
		{
			id: 1,
			name: 'ATMO_DRIVERINSTANCE_UART_UART1',
		},
	],
	wifi: [],
};

PlaneControllerEmbeddedOkdoE1.prototype.SUPPORTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'interval',
	'variables',
	'gpio',
	'adc',
	'i2c',
	'spi',
	'uart',
	'fpmath',
	'pwm',
	'log',
	'debugPrint',
	'uptime'
];


PlaneControllerEmbeddedOkdoE1.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'P_LED_B',
		mode: 'ATMO_GPIO_PinMode_Output_PushPull',
	},

	EmbeddedPWMPin: {
		instance: 0,
		pin: 'P_LED_R',
	},

	EmbeddedADCPin: {
		instance: 0,
		pin: 'A1',
	},
	EmbeddedWinC1500: {
		resetPin: 'A3',
		enablePin: 'D6',
		intPin: 'D2',
		csPin: 'D10',
	},
	EmbeddedWiFi7Click: {
		resetPin: 'A3',
		enablePin: 'D6',
		intPin: 'D2',
		csPin: 'D10',
	},
	EmbeddedQBG96: {
		enablePin: 'A0',
	},
	EmbeddedNimbleLinkSkywireLTE: {
		enablePin: 'A0',
	},
};

PlaneControllerEmbeddedOkdoE1.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			defaultName: 'Okdo E1',
			ATMO_DRIVERINSTANCE_I2C_I2C1: 'I2C1',
			ATMO_DRIVERINSTANCE_I2C_I2C2: 'I2C4',
			ATMO_DRIVERINSTANCE_UART_UART1: 'UART2',
			ATMO_DRIVERINSTANCE_SPI_SPI1: 'SPI8'
		},
	},
);
