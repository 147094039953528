function WidgetDataBulletin(id, api, parent, options) {
	options = options || {};
	this._showingSettings = false;

	if (options.readOnly) {
		options.menuOptions = options.menuOptions || [
			{ label: this.getLanguageTag('help'), value: 'help' },
		];
	} else {
		options.menuOptions = options.menuOptions || [
			{ label: this.getLanguageTag('settings'), value: 'settings' },
			{
				label: this.getLanguageTag('decimalPlaces'),
				value: 'decimalPlaces',
			},
			{ label: this.getLanguageTag('help'), value: 'help' },
			{ label: this.getLanguageTag('remove'), value: 'remove' },
		];
	}

	options.gotoDevice = true;

	WidgetDashboardBase.call(this, id, api, parent, options);

	this._updateInterval = null;

	this.tableContainerId = this.generateChildId('tableContainer');

	this.config = {
		deviceId: null,
		title: getLanguageTag(this.constructor, 'name'),
		storageName: null,
		variables: null,
		updateTime: 60000,
	};

	this.renderTemplate(
		{
			tableContainerId: this.tableContainerId,
		},
		WidgetDataBulletin.name,
	);

	this.tableWidget = null;
}

WidgetDataBulletin.prototype = Object.create(WidgetDashboardBase.prototype);
WidgetDataBulletin.prototype.constructor = WidgetDataBulletin;

WidgetDataBulletin.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.addEventListener('optionSelected', function(option) {
		if (option === 'decimalPlaces') {
			this.showDecimalPointsSettings();
		}
	});

	this.addChildWidget(
		WidgetTable,
		this.tableContainerId,
		{ hideFilterInput: true, showTotal: false },
		function(err, tableWidget) {
			this.tableWidget = tableWidget;

			WidgetDashboardBase.prototype.initialize.call(this, callback);
		},
	);
};

WidgetDataBulletin.prototype.remove = function(callback) {
	if (this._updateInterval) {
		clearInterval(this._updateInterval);
		this._updateInterval = null;
	}

	if (this._showingSettings) {
		this.getMainContainer().hideModal();
	}

	WidgetDashboardBase.prototype.remove.call(this, callback);
};

WidgetDataBulletin.prototype.update = function(callback) {
	callback = callback || function() {};
	const currentWidget = this;
	const api = this.getApiV2();

	this.setTitle(
		this.config.title || getLanguageTag(WidgetDataBulletin, 'name'),
	);

	// get mandatory params
	if (!this.config.deviceId || !this.config.variables) {
		callback.call(this, false);
		return;
	}

	// build the query to get the last values and datatypes
	let dataTypes;
	const lastValQuery = {
		id: this.config.deviceId,
		elementName: this.config.storageName,
	};

	api.apis.data
		.getLastDeviceData(lastValQuery)
		.then((response) => {
			const { values } = response;

			if (values === null) {
				callback.call(currentWidget, false);
				return;
			}

			values._timestamp = response.updatedAt;
			dataTypes = DataTypes.dataTypesFrom(JSON.stringify(values));

			// Expect _timestamp to be a Date.
			if (!dataTypes._timestamp) {
				dataTypes._timestamp = 'Date';
			}

			var columnNames = [];
			var tableData = [];

			currentWidget.config.variables.forEach((x) => {
				var outputData = values[x];

				if (dataTypes[x] === 'Date') {
					outputData = new Date(values[x]).toLocaleString();
				} else if (
					dataTypes[x] === 'Number' &&
					(currentWidget.config.decimalPlaces || {})[x] !== undefined
				) {
					if (currentWidget.config.decimalPlaces[x] >= 0) {
						outputData = values[x].toFixed(
							currentWidget.config.decimalPlaces[x],
						);
					}
				}

				// We only care about language for very specific variable names not everything this widget happens to use.
				tableData.push({
					variableName: getFromLanguageObject(
						currentWidget.variableLanguage,
						x,
					),
					variableValue: outputData,
				});
			});

			currentWidget.tableWidget.setTable(
				['variableName', 'variableValue'],

				{
					variableName: {
						label: currentWidget.getLanguageTag('variableName'),
						sortable: true,
					},
					variableValue: {
						label: currentWidget.getLanguageTag('variableValue'),
						sortable: true,
					},
				},

				tableData,
				'ascending',
				'variableName',
			);

			callback.call(currentWidget, false);
		})
		.catch((error) => {
			console.warn(
				`[${currentWidget.constructor.name}]: Error while calling .update()!`,
			);

			console.error(error);
			callback.call(currentWidget, error);
			return;
		});
};

WidgetDataBulletin.prototype.showSettings = function(callback) {
	callback = callback || function() {};

	var currentWidget = this;

	this.getMainContainer().setModalWidget(
		WidgetSettingsForm,
		{
			fields: [
				{
					name: 'title',
					type: 'text',
					label: getLanguageTag(this.constructor, 'title'),
					value: this.config.title,
				},

				{
					name: 'updateTime',
					type: 'select',
					label: this.getLanguageTag('updateTime'),
					options: [
						{ value: 60000, label: this.getLanguageTag('1Minute') },
						{
							value: 300000,
							label: this.getLanguageTag('5Minutes'),
						},
						{
							value: 600000,
							label: this.getLanguageTag('10Minutes'),
						},
						{
							value: 900000,
							label: this.getLanguageTag('15Minutes'),
						},
						{
							value: 1800000,
							label: this.getLanguageTag('30Minutes'),
						},
						{ value: 3600000, label: this.getLanguageTag('1Hour') },
						{
							value: 7200000,
							label: this.getLanguageTag('2Hours'),
						},
						{
							value: 14400000,
							label: this.getLanguageTag('4Hours'),
						},
						{
							value: 21600000,
							label: this.getLanguageTag('6Hours'),
						},
						{
							value: 28800000,
							label: this.getLanguageTag('8Hours'),
						},
						{
							value: 43200000,
							label: this.getLanguageTag('12Hours'),
						},
					],
					value: this.config.updateTime,
				},

				{
					name: 'variables',
					type: 'deviceVariableSelect',
					label: getLanguageTag(this.constructor, 'variable'),
					value: {
						deviceId: this.config.deviceId,
						storageName: this.config.storageName,
						variables: { value: this.config.variables },
					},
					options: {
						variables: [
							{
								name: 'value',
								selectMultiple: true,
								label: getLanguageTag(
									this.constructor,
									'value',
								),
							},
						],
					},
				},
			],
		},
		function(err, settingsWidget) {
			settingsWidget.setTitle(
				getLanguageTag(currentWidget.constructor, 'configureDataTable'),
			);

			settingsWidget.addEventListener('dismissed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;
			});

			settingsWidget.addEventListener('confirmed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;

				var values = this.getValues();

				var config = {
					deviceId:
						(values.variables.deviceData || {}).id ||
						currentWidget.config.deviceId ||
						null,
					storageName:
						values.variables.storageName ||
						currentWidget.config.storageName ||
						null,
					variables:
						(values.variables.variables || {}).value ||
						currentWidget.config.variables ||
						null,
					sortingColumn: null,
					sortingType: null,
					title: values.title || '',
					updateTime: values.updateTime || 60000,
					decimalPlaces: currentWidget.config.decimalPlaces || {},
				};

				currentWidget.setConfiguration(config, function() {
					currentWidget.event('configurationSet', {
						widget: this,
						configuration: this.config,
					});
				});
			});

			currentWidget._showingSettings = true;
			this.showModal();

			callback.call(this, false);
			return;
		},
	);
};

WidgetDataBulletin.prototype.assignDecimalPlaces = function(decimalConfig) {
	for (var i = 0; i < Object.keys(decimalConfig).length; i += 1) {
		var currDecPl = Object.keys(decimalConfig)[i];
		decimalConfig[currDecPl] = this.decimalPlacesFallback(
			decimalConfig[currDecPl],
		);
	}
	return decimalConfig;
};

WidgetDataBulletin.prototype.decimalPlacesFallback = function(value) {
	if (value === 0) {
		return 0;
	}
	if (!value) {
		return 2;
	}

	return value;
};

WidgetDataBulletin.prototype.showDecimalPointsSettings = function(callback) {
	callback = callback || function() {};
	const currentWidget = this;
	const api = currentWidget.getApiV2();

	var settingsFields = [];

	if (!this.config.deviceId || !this.config.storageName) {
		this.getMainContainer().showPopupErrorMessage(
			this.getLanguageTag('youNeedToSetupFirst'),
		);
		callback.call(this, false);
		return;
	}

	const lastValQuery = {
		id: this.config.deviceId,
		elementName: this.config.storageName,
	};

	api.apis.data
		.getLastDeviceData(lastValQuery)
		.then((response) => {
			const { values } = response;

			const dataTypes = DataTypes.dataTypesFrom(JSON.stringify(values));

			for (var i = 0; i < currentWidget.config.variables.length; i++) {
				var value = (currentWidget.config.decimalPlaces || {})[
					currentWidget.config.variables[i]
				];

				if (value === undefined || value === null) {
					value = -1;
				}

				if (dataTypes[currentWidget.config.variables[i]] === 'Number') {
					settingsFields.push({
						name: currentWidget.config.variables[i],
						type: 'number',
						min: 0,
						max: 5,
						label: currentWidget.config.variables[i],
						value: value,
					});
				}
			}

			currentWidget
				.getMainContainer()
				.setModalWidget(
					WidgetSettingsForm,
					{ fields: settingsFields },
					function(err, settingsWidget) {
						settingsWidget.setTitle(
							currentWidget.getLanguageTag('decimalPlaces'),
						);

						settingsWidget.addEventListener(
							'dismissed',
							function() {
								currentWidget.getMainContainer().hideModal();
							},
						);

						settingsWidget.addEventListener(
							'confirmed',
							function() {
								currentWidget.getMainContainer().hideModal();

								var decimalPlaces = this.getValues();

								currentWidget.config.decimalPlaces = currentWidget.assignDecimalPlaces(
									decimalPlaces,
								);

								currentWidget.setConfiguration(
									currentWidget.config,
									function() {
										this.event('configurationSet', {
											widget: this,
											configuration: this.config,
										});
									},
								);
							},
						);

						this.showModal();

						callback.call(this, false);
						return;
					},
				);
		})
		.catch((error) => {
			console.warn(
				`[${currentWidget.constructor.name}] error while calling showDecimalPointsSettings!`,
			);
			console.warn(error);
			callback.call(currentWidget, error);
			return;
		});
};

WidgetDataBulletin.prototype.setConfiguration = function(config, callback) {
	var currentWidget = this;

	callback = callback || function() {};

	this.config = {
		deviceId: config.deviceId || null,
		storageName: config.storageName || null,
		variables: config.variables || null,
		title: config.title || '',
		sortingColumn: config.sortingColumn || null,
		sortingType: config.sortingType || null,
		updateTime: config.updateTime || 60000,
		decimalPlaces: config.decimalPlaces || {},
	};

	const api = currentWidget.getApiV2().apis;

	api.devices
		.getDevice({ id: config.deviceId })
		.then((device) => {
			currentWidget.deviceUuid = device.uuid;

			if (currentWidget._updateInterval) {
				clearInterval(currentWidget._updateInterval);
				currentWidget._updateInterval = null;
			}

			currentWidget.update(function(err) {
				if (currentWidget.config.updateTime < 60000) {
					currentWidget.config.updateTime = 60000;
				}

				currentWidget._updateInterval = setInterval(function() {
					if (isActive()) {
						currentWidget.update(function() {});
					}
				}, this.config.updateTime || 60000);
			});

			callback.call(currentWidget, false);
		})
		.catch((err) => {
			callback.call(currentWidget, err);
		});
};

WidgetDataBulletin.prototype.getConfiguration = function() {
	return this.config;
};

WidgetDataBulletin.prototype.configure = function(callback) {};

WidgetDataBulletin.prototype.PACKERY_SIZE = 'WidgetDashboard_Container_MxL';

WidgetDataBulletin.prototype.variableLanguage = {
	'en-US': {
		_timestamp: 'Server Timestamp',
		_id: 'Object ID',
	},
};

WidgetDataBulletin.prototype.language = deepAssign(
	{},
	WidgetDashboardBase.prototype.language,
	{
		'en-US': {
			name: 'Data Bulletin',
			configureDataTable: 'Configure Data Bulletin',
			value: 'Select Value(s)',
			noData: 'This data bulletin has no data!',
			_timestamp: 'Server Timestamp',
			_id: 'Object ID',
			variable: 'Data Source',
			variableName: 'Variable',
			variableValue: 'Value',
			decimalPlaces: 'Decimal Places',
			youNeedToSetupFirst: 'The data bulletin must be configured first',
			updateTime: 'Update Interval',
			'1Minute': '1 Minute',
			'5Minutes': '5 Minutes',
			'10Minutes': '10 Minutes',
			'15Minutes': '15 Minutes',
			'30Minutes': '30 Minutes',
			'1Hour': '1 Hour',
			'2Hours': '2 Hours',
			'4Hours': '4 Hours',
			'6Hours': '6 Hours',
			'8Hours': '8 Hours',
			'12Hours': '12 Hours',
		},
	},
);

WidgetDataBulletin.prototype.$_$ = function(done) {
	done(false);
};
