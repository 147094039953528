function ElementControllerCloudExpression(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudLogicBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('expression', null, '', 'code', null, null);
	this.addProperty('values', null, {}, 'none', null, null);
	this.addProperty('value', null, null, 'none', null, null);

	this.addAbility('setValue', [
		{ name: 'key', validator: null },
		{ name: 'value', validator: null },
	]);
	this.addAbility('assignValues', [{ name: 'values', validator: null }]);
	this.addAbility('setValues', [{ name: 'values', validator: null }]);
	this.addAbility('evaluate', [{ name: 'values', validator: null }]);

	this.addTrigger('evaluated');
	this.addTrigger('expressionTrue');
	this.addTrigger('expressionFalse');
}

ElementControllerCloudExpression.prototype = Object.create(
	ElementControllerCloudLogicBase.prototype,
);
ElementControllerCloudExpression.prototype.constructor = ElementControllerCloudExpression;

ElementControllerCloudExpression.prototype.NAME = 'Expression';
ElementControllerCloudExpression.prototype.TYPE = 'CloudExpression';
ElementControllerCloudExpression.prototype.HIDDEN = false;
ElementControllerCloudExpression.prototype.DEFAULT_TRIGGER = 'evaluated';
ElementControllerCloudExpression.prototype.DEFAULT_ABILITY = 'evaluate';
ElementControllerCloudExpression.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerCloudExpression.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudExpression.prototype.language = deepAssign(
	{},
	ElementControllerCloudLogicBase.prototype.language,
	{
		'en-US': {
			CloudExpression: 'Expression',
			expression: 'Expression',
			values: 'Values',
			value: 'value',
			setValue: 'Set Value',
			key: 'Key',
			name: 'Name',
			assignValues: 'Assign Values',
			setValues: 'Set Values',
			evaluate: 'Evaluate',
			evaluated: 'Evaluated',
			expressionTrue: 'Expression True',
			expressionFalse: 'Expression False',
		},
	},
);
