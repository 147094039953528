function ElementControllerAppCloudEvent(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppLogicBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('time', null, 15000, 'number', null, null);
	this.addProperty('value', null, null, 'none', null, null);

	this.addTrigger('commandReceived');
}

ElementControllerAppCloudEvent.prototype = Object.create(
	ElementControllerAppLogicBase.prototype,
);
ElementControllerAppCloudEvent.prototype.constructor = ElementControllerAppCloudEvent;

ElementControllerAppCloudEvent.prototype.NAME = 'CloudEvent';
ElementControllerAppCloudEvent.prototype.TYPE = 'AppCloudEvent';
ElementControllerAppCloudEvent.prototype.CATEGORY = 'cloud';
ElementControllerAppCloudEvent.prototype.HIDDEN = false;
ElementControllerAppCloudEvent.prototype.DEFAULT_TRIGGER = 'commandReceived';
ElementControllerAppCloudEvent.prototype.DEFAULT_ABILITY = 'trigger';
ElementControllerAppCloudEvent.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerAppCloudEvent.prototype.COUPLER_TYPE = 'cloudEvent';
ElementControllerAppCloudEvent.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerAppCloudEvent.prototype.language = deepAssign(
	{},
	ElementControllerBase.prototype.language,
	{
		'en-US': {
			AppCloudEvent: 'Cloud Event',
			commandReceived: 'Event Received',
			value: 'Value',
		},
	},
);
