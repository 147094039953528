function LocationSystemAdministration(id, api, parentWidget, options) {
	LocationBase.call(this, id, api, parentWidget, options);
}

LocationSystemAdministration.prototype = Object.create(LocationBase.prototype);
LocationSystemAdministration.prototype.constructor = LocationSystemAdministration;

LocationSystemAdministration.prototype.hasPermission = function(callback) {
	const currentUser = _mainContainer.getCurrentUser();

	if (!currentUser) {
		callback.call(this, false, false);
		return;
	}

	if (!currentUser.ability.can('see', 'SystemAdministration')) {
		callback.call(this, false, false);
		return;
	}

	LocationBase.prototype.hasPermission.call(this, callback);
};

LocationSystemAdministration.prototype.initialize = function(callback) {
	const currentLocation = this;

	this.setTabWidgets(
		getLanguageTag(LocationSystemAdministration, 'name'),
		[
			{
				constructor: WidgetSystemAdministrationConfigurations,
				options: {},
			},
		],
		function(err) {
			var controlsEntries = [];

			controlsEntries.push({
				name: 'sendNotification',
				label: currentLocation.getLanguageTag('sendNotification'),
				imageSrc: './Resources/icons/Notifications.svg',
			});

			currentLocation.addHeaderWidget(
				WidgetLocationControls,
				{ entries: controlsEntries },
				function(err, controlsWidget) {
					controlsWidget.addEventListener(
						'sendNotification',
						function() {
							currentLocation
								.getMainContainer()
								.setModalWidget(
									WidgetAdminNotificationSender,
									{},
									function(err, notificationSenderWidget) {
										notificationSenderWidget.addEventListener(
											'dismissed',
											function() {
												currentLocation
													.getMainContainer()
													.hideModal();
											},
										);

										notificationSenderWidget.addEventListener(
											'confirmed',
											async function() {
												const {
													message,
												} = notificationSenderWidget.getValues();
												try {
													currentLocation
														.getMainContainer()
														.hideModal();
													await currentLocation.sendNotification(
														{ message },
													);
													currentLocation
														.getMainContainer()
														.showPopupInfoMessage(
															currentLocation.getLanguageTag(
																'notificationSent',
															),
														);
												} catch (err) {
													console.error(
														`error sending notification due to ${err}`,
													);
													currentLocation
														.getMainContainer()
														.showPopupErrorMessage(
															currentLocation.getLanguageTag(
																'errorSendingNotification',
															),
														);
												}
											},
										);

										currentLocation
											.getMainContainer()
											.showModal();
									},
								);
						},
					);

					LocationBase.prototype.initialize.call(
						currentLocation,
						callback,
					);
				},
			);
		},
	);
};

LocationSystemAdministration.prototype.sendNotification = async function(
	payload,
) {
	const currentLocation = this;
	const api = currentLocation.getApiV2();
	return api.apis.notifications.createSystemNotification(
		{},
		{ requestBody: payload },
	);
};

LocationSystemAdministration.prototype.language = deepAssign(
	{},
	LocationBase.prototype.language,
	{
		'en-US': {
			name: 'System Admin',
			sendNotification: 'Send Notification',
			notificationSent: 'Notification sent',
			errorSendingNotification:
				'There was an error when trying to send the notification',
		},
		de: {
			name: 'Verwaltung',
		},

		es: {
			name: 'Administración',
		},

		ar: {
			name: 'الادارة',
		},
	},
);
