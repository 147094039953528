function WidgetUserAccountCancel(id, api, parent, options) {
	var currentWidget = this;

	WidgetBase.call(this, id, api, parent, options);

	this.cancelAccountButtonId = this.generateChildId('cancelAccountButton');

	var context = {
		titleLabel: this.getLanguageTag('name'),
		confirmationLabel: this.getLanguageTag('confirmationLabel'),
		confirmationMessage: this.getLanguageTag('confirmationMessage'),
		cancelAccountButtonId: this.cancelAccountButtonId,
		cancelAccountButtonLabel: this.getLanguageTag('cancelAccount'),
	};

	var globalConfig =
		this.getMainContainer().getGlobalConfig()[this.constructor.name] || {};
	var redirectButtonConfig = (globalConfig || {}).redirectButton || null;

	if (redirectButtonConfig) {
		// For redirecting to SSO page when using SAML
		context = Object.assign({}, context, {
			redirectButtonCaption: getFromLanguageObject(
				redirectButtonConfig.language,
				'redirectButtonCaption',
			),
			redirectButtonLabel: getFromLanguageObject(
				redirectButtonConfig.language,
				'redirectButtonLabel',
			),
			redirectButtonHref: redirectButtonConfig.href,
		});
	}

	this.renderTemplate(context, WidgetUserAccountCancel.name);

	this.cancelAccountButton = $(`#${this.cancelAccountButtonId}`);

	this.cancelAccountButton.click(function() {
		currentWidget._onCancelAccountClicked();
	});
}

WidgetUserAccountCancel.prototype = Object.create(WidgetBase.prototype);
WidgetUserAccountCancel.prototype.constructor = WidgetUserAccountCancel;

WidgetUserAccountCancel.prototype.initialize = function(callback) {
	WidgetBase.prototype.initialize.call(this, callback);
};

WidgetUserAccountCancel.prototype.getValues = function() {
	var confirmation =
		this.getContainer()
			.find("input[type='checkbox']:checked")
			.val() || null;

	return {
		confirmation: confirmation,
	};
};

WidgetUserAccountCancel.prototype.cancelAccount = function(callback) {
	var currentWidget = this;

	this.getAPI()
		.getAPIRoute('/user/general/remove')
		.post(function(err) {
			callback.call(currentWidget, err);
		});
};

WidgetUserAccountCancel.prototype._onCancelAccountClicked = function() {
	var currentWidget = this;

	var values = this.getValues();

	if (!values.confirmation) {
		this.getMainContainer().showPopupErrorMessage(
			this.getLanguageTag('pleaseConfirmWithCheckbox'),
		);
		return;
	}

	this.getMainContainer().showConfirm(
		{
			message: this.getLanguageTag('areYouSure'),
			title: this.getLanguageTag('name'),
			confirmLabel: this.getLanguageTag('confirmCancelButton'),
			confirmClasses: ['btn-danger'],
			dismissLabel: this.getLanguageTag('dismissCancelButton'),
		},

		function() {
			currentWidget.cancelAccount(
				function(err) {
					if (err) {
						this.getMainContainer().showPopupErrorMessage(
							this.getLanguageTag('errorCancellingAccount'),
						);
						return;
					}

					// We want to logout the user but because we canceled the account
					// we don't need to call the auth logout API because our JWT and
					// refresh token are already invalid at this point.
					this.getMainContainer().logoutUser({
						accountCancelled: true,
					});
				},
			);
		},
	);
};

WidgetUserAccountCancel.prototype.ICON = './Resources/icons/CancelAccount.svg';

WidgetUserAccountCancel.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'Cancel My Account',
			confirmationLabel: 'Cancellation Confirmation',
			confirmationMessage:
				'By clicking this checkbox, you acknowledge you are cancelling your user account. Upon cancellation, your account will be permanently deleted.',
			cancelAccount: 'Cancel Account',
			pleaseConfirmWithCheckbox:
				'Confirm your cancellation request by clicking the checkbox.',
			areYouSure:
				"Are you sure you want to cancel your account? This process can't be undone.",
			confirmCancelButton: 'Cancel My Account',
			dismissCancelButton: 'Go Back',
			errorCancellingAccount:
				'There was an error when cancelling your account.',
		},
	},
);
