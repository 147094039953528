/**
 *
 * @param {*} id
 * @param {*} api
 * @param {*} parent
 * @param {Function} options.onSearch - Callback on filter changed with new filter text
 * @param {Number} options.debounceMs [OPTIONAL] - Time in ms to wait after user input before executing onSearch
 */
function WidgetSearchBox(id, api, parent, options) {
	WidgetBase.call(this, id, api, parent, options);

	const currentWidget = this;

	this.inputContainerId = this.generateChildId('inputContainer');
	this.inputId = this.generateChildId('input');

	const context = {
		inputContainerId: this.inputContainerId,
		inputId: this.inputId,
		inputPlaceholder: getLanguageTag(this.constructor, 'searchInput'),
	};

	this.renderTemplate(context, WidgetSearchBox.name);

	this.inputContainer = $(`#${this.inputContainerId}`);
	this.input = $(`#${this.inputId}`);

	const debounceMs = options.debounceMs || 750;
	const onSearch = options.onSearch || function() {};

	const onSearchDebounced = debounce(() => {
		onSearch(currentWidget.input.val());
	}, debounceMs);
	currentWidget.input.keyup(onSearchDebounced);
}

WidgetSearchBox.prototype = Object.create(WidgetBase.prototype);
WidgetSearchBox.prototype.constructor = WidgetSearchBox;

WidgetSearchBox.prototype.initialize = function(callback) {
	const currentWidget = this;

	currentWidget.update(function(err) {
		WidgetBase.prototype.initialize.call(currentWidget, callback);
	});
};

WidgetSearchBox.prototype.getSearchText = function() {
	return this.input.val();
};

WidgetSearchBox.prototype.update = function(callback) {
	callback = callback || function() {};
	callback.call(this, false);
};

WidgetSearchBox.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'Search Box',
			searchInput: 'Search',
		},
	},
);
