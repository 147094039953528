function ElementControllerAppBLEBatteryService(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppBLECharacteristicBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('value', null, [], 'none', null, null);

	this.addAbility('readBatteryLevel', []);

	this.addTrigger('batteryLevelRead');
}

ElementControllerAppBLEBatteryService.prototype = Object.create(
	ElementControllerAppBLECharacteristicBase.prototype,
);
ElementControllerAppBLEBatteryService.prototype.constructor = ElementControllerAppBLEBatteryService;

ElementControllerAppBLEBatteryService.prototype.DEFAULT_TRIGGER =
	'batteryLevelRead';
ElementControllerAppBLEBatteryService.prototype.DEFAULT_ABILITY =
	'readBatteryLevel';
ElementControllerAppBLEBatteryService.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerAppBLEBatteryService.prototype.NAME = 'BLEBatteryService';
ElementControllerAppBLEBatteryService.prototype.TYPE = 'AppBLEBatteryService';
ElementControllerAppBLEBatteryService.prototype.HIDDEN = false;
ElementControllerAppBLEBatteryService.prototype.CATEGORY = 'appBLE';
ElementControllerAppBLEBatteryService.prototype.COUPLED_TO =
	'bleBatteryService';

ElementControllerAppBLEBatteryService.prototype.language = deepAssign(
	{},
	ElementControllerAppBLECharacteristicBase.prototype.language,
	{
		'en-US': {
			AppBLEBatteryService: 'BLE Battery Service',
			batteryLevelRead: 'Battery Level Read',
			readBatteryLevel: 'Read Battery Level',
			value: 'Value',
		},
	},
);
