function ElementControllerEmbeddedNxpRpkBacklight(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.backlightSettings = [
		'BLIGHT_LEVEL_OFF',
		'BLIGHT_LEVEL_LOW',
		'BLIGHT_LEVEL_MEDIUM',
		'BLIGHT_LEVEL_HIGH',
	];
	this.addProperty(
		'initialSetting',
		null,
		'BLIGHT_LEVEL_HIGH',
		'select',
		this.backlightSettings,
		null,
	);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
    Backlight_SetLevel(ATMO_PROPERTY(undefined, initialSetting));
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setBacklightOff', [], ['backlightSet']);
	this.setAbilityCode(
		'setBacklightOff',
		`
    Backlight_SetLevel(BLIGHT_LEVEL_OFF);
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('setBacklightLow', [], ['backlightSet']);
	this.setAbilityCode(
		'setBacklightLow',
		`
    Backlight_SetLevel(BLIGHT_LEVEL_LOW);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setBacklightMedium', [], ['backlightSet']);
	this.setAbilityCode(
		'setBacklightMedium',
		`
    Backlight_SetLevel(BLIGHT_LEVEL_MEDIUM);
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('setBacklightHigh', [], ['backlightSet']);
	this.setAbilityCode(
		'setBacklightHigh',
		`
    Backlight_SetLevel(BLIGHT_LEVEL_HIGH);
	return ATMO_Status_Success;
	`,
	);
}

ElementControllerEmbeddedNxpRpkBacklight.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedNxpRpkBacklight.prototype.constructor = ElementControllerEmbeddedNxpRpkBacklight;

ElementControllerEmbeddedNxpRpkBacklight.prototype.TYPE =
	'EmbeddedNxpRpkBacklight';
ElementControllerEmbeddedNxpRpkBacklight.prototype.HIDDEN = false;
ElementControllerEmbeddedNxpRpkBacklight.prototype.CATEGORY = 'Rapid IOT';
ElementControllerEmbeddedNxpRpkBacklight.prototype.DEFAULT_ABILITY =
	'setBacklightHigh';
ElementControllerEmbeddedNxpRpkBacklight.prototype.DEFAULT_TRIGGER =
	'backlightSet';
ElementControllerEmbeddedNxpRpkBacklight.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'rpk',
];
ElementControllerEmbeddedNxpRpkBacklight.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedNxpRpkBacklight.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedNxpRpkBacklight: 'Backlight',
			setBacklightOff: 'Set Backlight Off',
			setBacklightLow: 'Set Backlight Low',
			setBacklightMedium: 'Set Backlight Medium',
			setBacklightHigh: 'Set Backlight High',
			BLIGHT_LEVEL_OFF: 'Off',
			BLIGHT_LEVEL_LOW: 'Low',
			BLIGHT_LEVEL_MEDIUM: 'Medium',
			BLIGHT_LEVEL_HIGH: 'High',
			initialSetting: 'Initial Brightness',
			backlightSet: 'Backlight Set',
		},
	},
);
