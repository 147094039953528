function ElementControllerCloudAWSBase(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('region', null, 'us-east-1', 'text', null, null);
	this.addProperty('externalId', null, '', 'text', null, null);
	this.addProperty('roleArn', null, '', 'text', null, null);
}

ElementControllerCloudAWSBase.prototype = Object.create(
	ElementControllerCloudBase.prototype,
);
ElementControllerCloudAWSBase.prototype.constructor = ElementControllerCloudAWSBase;

ElementControllerCloudAWSBase.prototype.TYPE = 'CloudAWSBase';
ElementControllerCloudAWSBase.prototype.HIDDEN = true;
ElementControllerCloudAWSBase.prototype.CATEGORY = 'cloudAWS';

ElementControllerCloudAWSBase.prototype.language = deepAssign(
	{},
	ElementControllerCloudBase.prototype.language,
	{
		'en-US': {
			CloudAWSBase: 'AWS Base',
			region: 'Region',
			externalId: 'External ID',
			roleArn: 'Role ARN',
		},
	},
);
