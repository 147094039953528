function WidgetConfirm(id, api, parent, options) {
	var currentWidget = this;

	WidgetPanelBase.call(this, id, api, parent, options);

	var message = this.getOptions().message || '';
	var title =
		this.getOptions().title || getLanguageTag(this.constructor, 'name');

	this.footerContainerId = this.generateChildId('footerContainer');

	this.renderTemplate(
		{
			message: message,
			footerContainerId: this.footerContainerId,
		},
		WidgetConfirm.name,
	);

	this.setTitle(title);
}

WidgetConfirm.prototype = Object.create(WidgetPanelBase.prototype);
WidgetConfirm.prototype.constructor = WidgetConfirm;

WidgetConfirm.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.addChildWidget(
		WidgetButtonsFooter,
		this.footerContainerId,
		{},
		function(err, footerWidget) {
			footerWidget.setButtons([
				{
					label:
						this.getOptions().dismissLabel ||
						getLanguageTag(this.constructor, 'dismiss'),
					value: 'dismissed',
					classes: this.getOptions().dismissClasses || ['btn-cancel'],
				},

				{
					label:
						this.getOptions().confirmLabel ||
						getLanguageTag(this.constructor, 'confirm'),
					value: 'confirmed',
					classes: this.getOptions().confirmClasses || [
						'btn-primary',
					],
				},
			]);

			footerWidget.addEventListener('footerButtonPressed', function(
				value,
			) {
				currentWidget.event(value);
			});

			WidgetPanelBase.prototype.initialize.call(this, callback);
		},
	);
};

WidgetConfirm.prototype.language = deepAssign(
	{},
	WidgetPanelBase.prototype.language,
	{
		'en-US': {
			name: 'Confirmation',
		},
	},
);
