function ElementControllerEmbeddedIconLabelDisplay(
	parentController,
	parentPlane,
	elementName,
	numMeasurements,
) {
	ElementControllerEmbeddedPageTemplateBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty(
		'icon',
		null,
		this.iconList[0],
		'select',
		this.iconList,
		null,
	);
	this.addProperty('label', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('label', 'string');

	this.addAbility('onDisplayed', [], ['onDisplayed'], false, true);
	this.setAbilityCode(
		'onDisplayed',
		`
	return ATMO_Status_Success;
    `,
	);

	this.addAbility(
		'topRightButtonPressed',
		[],
		['topRightButtonPressed'],
		false,
		true,
	);
	this.setAbilityCode(
		'topRightButtonPressed',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility(
		'bottomRightButtonPressed',
		[],
		['bottomRightButtonPressed'],
		false,
		true,
	);
	this.setAbilityCode(
		'bottomRightButtonPressed',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility(
		'topLeftButtonPressed',
		[],
		['topLeftButtonPressed'],
		false,
		true,
	);
	this.setAbilityCode(
		'topLeftButtonPressed',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility(
		'bottomLeftButtonPressed',
		[],
		['bottomLeftButtonPressed'],
		false,
		true,
	);
	this.setAbilityCode(
		'bottomLeftButtonPressed',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
    ATMO_UI_PAGE_Config_t config;
	config.hidden = ATMO_PROPERTY(undefined, pageHidden);
	config.textColor = ATMO_PROPERTY(undefined, textColor);
    config.activeButtons = ATMO_UI_Page_GetButtonMask(ATMO_PROPERTY(undefined, topRightButtonEnabled),
    ATMO_PROPERTY(undefined,bottomRightButtonEnabled), ATMO_PROPERTY(undefined, topLeftButtonEnabled), ATMO_PROPERTY(undefined, bottomLeftButtonEnabled));
	config.x = ATMO_PROPERTY(undefined, x);
    config.x = ATMO_PROPERTY(undefined, x);
    config.y = ATMO_PROPERTY(undefined, y);
	strncpy(config.topLeftButtonLabel, ATMO_PROPERTY(undefined, topLeftButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
	strncpy(config.topRightButtonLabel, ATMO_PROPERTY(undefined, topRightButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
	strncpy(config.bottomLeftButtonLabel, ATMO_PROPERTY(undefined, bottomLeftButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
	strncpy(config.bottomRightButtonLabel, ATMO_PROPERTY(undefined, bottomRightButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
    config.spanX = ATMO_PROPERTY(undefined, spanX);
	config.spanY = ATMO_PROPERTY(undefined, spanY);
    config.title = ATMO_PROPERTY(undefined, pageTitle);
    config.titleHidden = ATMO_PROPERTY(undefined, titleHidden);
	ATMO_UI_SINGLEICONTEXT_Init(&config);
	ATMO_VARIABLE(undefined, pageHandle) = config.templateInstance;
    ATMO_UI_SINGLEICONTEXT_SetMainText(config.templateInstance, ATMO_PROPERTY(undefined, label));
    ATMO_UI_SINGLEICONTEXT_SetIcon(config.templateInstance, ATMO_PROPERTY(undefined, icon));
    ATMO_UI_SINGLEICONTEXT_RegisterOnDisplayedAbilityHandle(ATMO_VARIABLE(undefined,pageHandle), ATMO_ABILITY(undefined, onDisplayed));
    ATMO_UI_SINGLEICONTEXT_RegisterButtonAbilityHandle(ATMO_VARIABLE(undefined,pageHandle), 1, ATMO_ABILITY(undefined, topRightButtonPressed));
	ATMO_UI_SINGLEICONTEXT_RegisterButtonAbilityHandle(ATMO_VARIABLE(undefined,pageHandle), 2, ATMO_ABILITY(undefined, bottomRightButtonPressed));
	ATMO_UI_SINGLEICONTEXT_RegisterButtonAbilityHandle(ATMO_VARIABLE(undefined,pageHandle), 3, ATMO_ABILITY(undefined, topLeftButtonPressed));
    ATMO_UI_SINGLEICONTEXT_RegisterButtonAbilityHandle(ATMO_VARIABLE(undefined,pageHandle), 4, ATMO_ABILITY(undefined, bottomLeftButtonPressed));
    ATMO_UI_SINGLEICONTEXT_RegisterOnLeaveAbilityHandle(config.templateInstance, ATMO_ABILITY(undefined, onLeave));
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('onLeave', [], ['onLeave'], false, true);
	this.setAbilityCode(
		'onLeave',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setLabel', [], []);
	this.setAbilityCode(
		'setLabel',
		`
    char label[32];
    if(ATMO_GetString(in, label, 32) == ATMO_Status_Success)
    {
        ATMO_UI_SINGLEICONTEXT_SetMainText(ATMO_VARIABLE(undefined,pageHandle), label);
    }
    else
    {
        return ATMO_Status_Fail;
    }

    return ATMO_Status_Success;
    `,
	);
}

ElementControllerEmbeddedIconLabelDisplay.prototype = Object.create(
	ElementControllerEmbeddedPageTemplateBase.prototype,
);
ElementControllerEmbeddedIconLabelDisplay.prototype.constructor = ElementControllerEmbeddedIconLabelDisplay;

ElementControllerEmbeddedIconLabelDisplay.prototype.DEFAULT_TRIGGER =
	'onDisplayed';
ElementControllerEmbeddedIconLabelDisplay.prototype.DEFAULT_ABILITY =
	'setLabel';
ElementControllerEmbeddedIconLabelDisplay.prototype.DEFAULT_ARGUMENTS = [
	'value',
];
ElementControllerEmbeddedIconLabelDisplay.prototype.TYPE =
	'EmbeddedIconLabelDisplay';
ElementControllerEmbeddedIconLabelDisplay.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'rpk',
];
ElementControllerEmbeddedIconLabelDisplay.prototype.HIDDEN = false;
ElementControllerEmbeddedIconLabelDisplay.prototype.CATEGORY = 'Rapid IOT';
ElementControllerEmbeddedIconLabelDisplay.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedIconLabelDisplay.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedPageTemplateBase.prototype.language,
	{
		'en-US': {
			EmbeddedIconLabelDisplay: 'Labelled Icon',
			label: 'Initial Label',
			icon: 'Icon',
			setLabel: 'Set Label',
		},
	},
);
