function WidgetDeviceMap(id, api, parent, options) {
	var currentWidget = this;

	WidgetDashboardBase.call(this, id, api, parent, options);

	this.clickListener = null;
	this._updateInterval = null;

	this.config = {
		title: getLanguageTag(this.constructor, 'name'),
		updateTime: 60000,
		showSubOrgs: true,
		maxClusterZoom: null,
	};
}

WidgetDeviceMap.prototype = Object.create(WidgetDashboardBase.prototype);
WidgetDeviceMap.prototype.constructor = WidgetDeviceMap;

WidgetDeviceMap.prototype.initialize = function(callback) {
	var currentWidget = this;

	this._fixesLoaded = false;

	var lat = 0.0;
	var lon = 0.0;

	this.zoomListener = null;
	this.mapId = this.generateChildId('map');
	this.getDirectionsButtonId = this.generateChildId('getDirectionsButton');
	this.noDevicesMsgId = this.generateChildId('noDevicesMsgId');

	this.renderTemplate(
		{
			mapId: this.mapId,
			getDirectionsButtonId: this.getDirectionsButtonId,
			getDirectionsLabel: getLanguageTag(
				WidgetDeviceMap,
				'getDirections',
			),
			noDevicesMsgId: this.noDevicesMsgId,
			noneOfYourDevicesHaveGeolocationMetaInformationLabel: getLanguageTag(
				WidgetDeviceMap,
				'noneOfYourDevicesHaveGeolocationMetaInformation',
			),
		},
		WidgetDeviceMap.name,
	);

	this.map = $('#' + this.mapId);
	this.getDirectionsButton = $('#' + this.getDirectionsButtonId);
	this.getDirectionsButton.hide();
	this.noDevicesMsg = $('#' + this.noDevicesMsgId);
	this.noDevicesMsg.hide();

	this.getDirectionsButton.click(function() {
		currentWidget.openMapToMarker(currentWidget.markers[0]);
	});

	var center = new google.maps.LatLng(lat, lon);
	this.infoWindow = new google.maps.InfoWindow();

	this.googleMap = new google.maps.Map(document.getElementById(this.mapId), {
		zoom: 3,
		center: center,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		gestureHandling: 'cooperative',
	});

	this.googleMap.controls[google.maps.ControlPosition.TOP_CENTER].push(
		document.getElementById(this.getDirectionsButtonId),
	);
	this.googleMap.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(
		document.getElementById(this.noDevicesMsgId),
	);

	this.map.bind('DOMSubtreeModified', function(e) {
		requestAnimationFrame(function() {
			if (!currentWidget._fixesLoaded) {
				// NH-420 Fix, we change the target from _blank to _system for all anchors with href
				var numberOfAnchorsFound = currentWidget.map.find('a').length;

				currentWidget.map.find('a').each(function(i, val) {
					if (numberOfAnchorsFound >= 4) {
						currentWidget._fixesLoaded = true;
					}

					$(val).attr('target', '_system');

					if (isPhonegap()) {
						$(val).hide();
					}
				});
			}
		});
	});

	this.markers = [];
	this.markerCluster = null;
	this.setTitle(
		this.config.title ||
			this.getOptions().title ||
			getLanguageTag(WidgetDeviceMap, 'name'),
	);

	// Don't fetch all the devices available to the user on the map
	this.dontFetchAll = this.getOptions().dontFetchAll ? true : false;

	// https://koliada.atlassian.net/browse/AI-3371
	setTimeout(function() {
		requestAnimationFrame(function() {
			if (currentWidget._updateInterval) {
				clearInterval(currentWidget._updateInterval);
				currentWidget._updateInterval = null;
			}

			currentWidget._updateInterval = setInterval(function() {
				if (currentWidget.config.updateTime < 60000) {
					currentWidget.config.updateTime = 60000;
				}

				if (isActive()) {
					currentWidget.update(false, function(err) {});
				}
			}, currentWidget.config.updateTime || 60000);
		});
	}, 200);

	WidgetDashboardBase.prototype.initialize.call(this, callback);
};

WidgetDeviceMap.prototype.haveDevicesChanged = function(newDevices) {
	const oldDevices = this._devices || [];
	return !(
		Array.isArray(newDevices) &&
		oldDevices.length === newDevices.length &&
		oldDevices.every((val, index) => val === newDevices[index])
	);
};

WidgetDeviceMap.prototype.getAllDevices = async function() {
	const currentWidget = this;

	if (this.dontFetchAll) {
		// Don't fetch all the devices available to the user on the map
		console.debug(
			`[WidgetDeviceMap] dontFetchAll option has been set. Stopping the fetching of devices.`,
		);
		return [];
	}

	const api = this.getApiV2().apis;
	const currentOrg = await this.asyncGetOrganizationContext();
	const depth = this.config.showSubOrgs === true ? 'all' : 1;
	const initialDevices = await api.devices.getDeviceLocations({
		depth,
		organizationId: currentOrg.id,
	});
	const devices = [...initialDevices.data];

	const { totalPages } = initialDevices.meta || {};

	if (totalPages > 1) {
		const additionalDevicePromises = [];
		for (let i = 2; i <= totalPages; i += 1) {
			additionalDevicePromises.push(
				api.devices.getDevices({
					depth,
					organizationId: currentOrg.id,
				}),
			);
		}

		const pages = await Promise.all(additionalDevicePromises);

		pages.forEach((page) => {
			devices.push(...page.data);
		});
	}

	return devices;
};

WidgetDeviceMap.prototype.update = function(autoCenter, callback) {
	var currentWidget = this;

	callback = callback || function() {};
	var autoCenter = autoCenter || false;
	var command = getHashCommand() || {};

	this.setTitle(
		this.config.title ||
			this.getOptions().title ||
			getLanguageTag(WidgetDeviceMap, 'name'),
	);

	const api = currentWidget.getApiV2().apis;

	if (command.deviceId) {
		api.devices
			.getDevice({ id: command.deviceId })
			.then((device) => {
				device.latitude =
					device &&
					device.meta &&
					device.meta.geoloc &&
					device.meta.geoloc.lat
						? device.meta.geoloc.lat
						: null;
				device.longitude =
					device &&
					device.meta &&
					device.meta.geoloc &&
					device.meta.geoloc.lon
						? device.meta.geoloc.lon
						: null;
				currentWidget.updateMap([device], autoCenter);
				callback.call(currentWidget, false);
			})
			.catch((e) => {
				console.error('Error getting device data ', e);
				callback.call(this, e);
				return;
			});
	} else {
		// TODO how can we paginate this??
		this.getAllDevices()
			.then((devices) => {
				const newDevices = devices.map((device) => device.id);
				const autoCenterNewDevices = this.haveDevicesChanged(newDevices)
					? true
					: autoCenter;
				currentWidget._devices = newDevices;
				currentWidget.updateMap(devices, autoCenterNewDevices);
				callback.call(currentWidget, false);
			})
			.catch((e) => {
				console.error('Error getting device data ', e);
				callback.call(this, e);
				return;
			});
	}
};

WidgetDeviceMap.prototype.remove = function(callback) {
	if (this._updateInterval !== null) {
		clearInterval(this._updateInterval);
		this._updateInterval = null;
	}

	WidgetDashboardBase.prototype.remove.call(this, callback);
};

WidgetDeviceMap.prototype.showSettings = function(callback) {
	callback = callback || function() {};
	var currentWidget = this;

	let settings = {
		fields: [
			{
				name: 'title',
				type: 'text',
				label: getLanguageTag(this.constructor, 'title'),
				value: this.config.title,
			},
			{
				name: 'showSubOrgs',
				type: 'checkbox',
				label: getLanguageTag(this.constructor, 'showSubOrgs'),
				value: this.config.showSubOrgs,
			},
			{
				name: 'updateTime',
				type: 'select',
				label: this.getLanguageTag('updateTime'),
				options: [
					{ value: 60000, label: this.getLanguageTag('1Minute') },
					{
						value: 300000,
						label: this.getLanguageTag('5Minutes'),
					},
					{
						value: 600000,
						label: this.getLanguageTag('10Minutes'),
					},
					{
						value: 900000,
						label: this.getLanguageTag('15Minutes'),
					},
					{
						value: 1800000,
						label: this.getLanguageTag('30Minutes'),
					},
					{ value: 3600000, label: this.getLanguageTag('1Hour') },
					{
						value: 7200000,
						label: this.getLanguageTag('2Hours'),
					},
					{
						value: 14400000,
						label: this.getLanguageTag('4Hours'),
					},
					{
						value: 21600000,
						label: this.getLanguageTag('6Hours'),
					},
					{
						value: 28800000,
						label: this.getLanguageTag('8Hours'),
					},
					{
						value: 43200000,
						label: this.getLanguageTag('12Hours'),
					},
				],
				value: this.config.updateTime,
			},
			{
				name: 'maxClusterZoom',
				type: 'select',
				label: this.getLanguageTag('maxClusterZoom'),
				hidden: Boolean(getHashCommand().deviceId),
				options: [
					{
						value: null,
						label: this.getLanguageTag('clusterZoomDefault'),
					},
					{ value: 1, label: this.getLanguageTag('clusterZoom1') },
					{ value: 2, label: this.getLanguageTag('clusterZoom2') },
					{ value: 3, label: this.getLanguageTag('clusterZoom3') },
					{ value: 4, label: this.getLanguageTag('clusterZoom4') },
					{ value: 5, label: this.getLanguageTag('clusterZoom5') },
					{ value: 6, label: this.getLanguageTag('clusterZoom6') },
					{ value: 7, label: this.getLanguageTag('clusterZoom7') },
					{ value: 8, label: this.getLanguageTag('clusterZoom8') },
					{ value: 9, label: this.getLanguageTag('clusterZoom9') },
					{ value: 10, label: this.getLanguageTag('clusterZoom10') },
					{ value: 11, label: this.getLanguageTag('clusterZoom11') },
					{ value: 12, label: this.getLanguageTag('clusterZoom12') },
					{ value: 13, label: this.getLanguageTag('clusterZoom13') },
					{ value: 14, label: this.getLanguageTag('clusterZoom14') },
					{ value: 15, label: this.getLanguageTag('clusterZoom15') },
					{ value: 16, label: this.getLanguageTag('clusterZoom16') },
					{ value: 17, label: this.getLanguageTag('clusterZoom17') },
					{ value: 18, label: this.getLanguageTag('clusterZoom18') },
					{ value: 19, label: this.getLanguageTag('clusterZoom19') },
					{ value: 20, label: this.getLanguageTag('clusterZoom20') },
				],
				value: this.config.maxClusterZoom,
			},
		],
	};

	//If a single device we are looking at don't show the zoom cluster option
	if (getHashCommand().deviceId) {
		settings = {
			fields: [
				{
					name: 'title',
					type: 'text',
					label: getLanguageTag(this.constructor, 'title'),
					value: this.config.title,
				},
				{
					name: 'showSubOrgs',
					type: 'checkbox',
					label: getLanguageTag(this.constructor, 'showSubOrgs'),
					value: this.config.showSubOrgs,
				},
				{
					name: 'updateTime',
					type: 'select',
					label: this.getLanguageTag('updateTime'),
					options: [
						{ value: 60000, label: this.getLanguageTag('1Minute') },
						{
							value: 300000,
							label: this.getLanguageTag('5Minutes'),
						},
						{
							value: 600000,
							label: this.getLanguageTag('10Minutes'),
						},
						{
							value: 900000,
							label: this.getLanguageTag('15Minutes'),
						},
						{
							value: 1800000,
							label: this.getLanguageTag('30Minutes'),
						},
						{ value: 3600000, label: this.getLanguageTag('1Hour') },
						{
							value: 7200000,
							label: this.getLanguageTag('2Hours'),
						},
						{
							value: 14400000,
							label: this.getLanguageTag('4Hours'),
						},
						{
							value: 21600000,
							label: this.getLanguageTag('6Hours'),
						},
						{
							value: 28800000,
							label: this.getLanguageTag('8Hours'),
						},
						{
							value: 43200000,
							label: this.getLanguageTag('12Hours'),
						},
					],
					value: this.config.updateTime,
				},
			],
		};
	}

	this.getMainContainer().setModalWidget(
		WidgetSettingsForm,
		settings,
		function(err, settingsWidget) {
			settingsWidget.setTitle(
				getLanguageTag(currentWidget.constructor, 'configureDeviceMap'),
			);

			settingsWidget.addEventListener('dismissed', function() {
				currentWidget.getMainContainer().hideModal();
			});

			settingsWidget.addEventListener('confirmed', function() {
				currentWidget.getMainContainer().hideModal();

				values = this.getValues();
				values.variable = values.variable || {};

				var config = {
					title:
						values.title || getLanguageTag(currentWidget, 'name'),
					updateTime: values.updateTime || 60000,
					showSubOrgs: values.showSubOrgs,
					maxClusterZoom: values.maxClusterZoom,
				};

				currentWidget.setConfiguration(config, function() {
					this.event('configurationSet', {
						widget: this,
						configuration: this.config,
					});
				});
			});

			this.showModal();

			callback.call(this, false);
			return;
		},
	);
};

WidgetDeviceMap.prototype.updateMap = function(deviceData, autoCenter) {
	const currentWidget = this;

	this.clearAllMarkers();
	const api = this.getApiV2().apis;
	autoCenter = autoCenter || false;
	var bounds = new google.maps.LatLngBounds();

	if (
		deviceData === undefined ||
		deviceData === null ||
		Object.keys(deviceData).length <= 0
	) {
		return;
	}

	if (deviceData && deviceData.length && deviceData.length > 0) {
		for (var i = 0; i < deviceData.length; i++) {
			if (
				deviceData &&
				deviceData[i] &&
				deviceData[i].latitude &&
				deviceData[i].longitude
			) {
				var latLng = new google.maps.LatLng(
					deviceData[i].latitude,
					deviceData[i].longitude,
				);
				var deviceStatus = deviceData[i].statusText || 'none';
				var pinColor =
					this.STATUS_TO_COLOR[deviceStatus] ||
					this.STATUS_TO_COLOR.null;
				var pinImage = new google.maps.MarkerImage(
					`./Widgets/WidgetDeviceMap/Resources/mapPinIcons/${pinColor}.png`,
				);

				new google.maps.Size(21, 34);
				new google.maps.Point(0, 0);
				new google.maps.Point(10, 34);

				var marker = new google.maps.Marker({
					position: latLng,
					map: currentWidget.googleMap,
					icon: pinImage,
				});

				marker.setValues({
					thingName: deviceData[i].name,
					deviceId: deviceData[i].id,
					thingUuid: deviceData[i].uuid,
					organizationId: deviceData[i].organizationId,
				});

				this.markers.push(marker);
				bounds.extend(marker.position);

				google.maps.event.addListener(marker, 'click', async function(
					e,
				) {
					const deviceData = await api.devices.getDevice({
						id: this.get('deviceId'),
					});
					let name = deviceData.name;
					let deviceId = deviceData.id;
					let thingUuid = deviceData.uuid;
					let organizationId = deviceData.organizationId;
					let meta = deviceData.meta;
					let icon = meta.icon || 'calculator';
					const device = new Device(currentWidget.getApiV2().apis, {
						id: deviceId,
					});
					let image =
						(await device.getImage()) ||
						'./Resources/icons/DefaultDeviceIcon.svg';

					currentWidget.infoWindow.setContent(
						$.handlebarTemplates.WidgetDeviceMap_InfoWindow({
							entries: [
								{
									name: name,
									icon: icon,
									link:
										'#' +
										encodeURIComponent(
											JSON.stringify({
												location: 'DeviceDashboard',
												deviceId: deviceId,
												thingUuid: thingUuid,
												org: organizationId,
											}),
										),
									statusLabel: getLanguageTag(
										currentWidget.constructor,
										'status',
									),
									status: getLanguageTag(
										currentWidget.constructor,
										meta.status || 'none',
									),
									image: image,
								},
							],
						}),
					);

					currentWidget.infoWindow.open(
						currentWidget.googleMap,
						this,
					);
				});

				google.maps.event.addListener(marker, 'dblclick', function(e) {
					let deviceId = this.get('deviceId');
					let organizationId = this.get('organizationId');

					currentWidget
						.getMainContainer()
						.setLocation(LocationDeviceDashboard, {
							deviceId: deviceId,
							org: organizationId,
						});

					return;
				});
			}
		}
	}

	this.markerCluster = new MarkerClusterer(
		currentWidget.googleMap,
		this.markers,
		{
			imagePath: 'Widgets/WidgetDeviceMap/Resources/mapClusterIcons/info',
			maxZoom: this.config.maxClusterZoom,
			zoomOnClick: true,
		},
	);

	google.maps.event.addListener(
		this.markerCluster,
		'clusterclick',
		async function(cluster) {
			var markers = cluster.getMarkers();
			var entries = [];

			if (markers.length <= 0) {
				return;
			}

			for (var i = 0; i < markers.length; i++) {
				const deviceData = await api.devices.getDevice({
					id: markers[i].get('deviceId'),
				});
				var name = deviceData.name;
				var organizationId = deviceData.organizationId;
				var deviceId = deviceData.id;
				var meta = deviceData.meta;
				var icon = meta.icon || 'calculator';
				const device = new Device(currentWidget.getApiV2().apis, {
					id: deviceId,
				});
				var image =
					(await device.getImage()) ||
					'./Resources/icons/DefaultDeviceIcon.svg';

				entries.push({
					name: name,
					icon: icon,
					link:
						'#' +
						encodeURIComponent(
							JSON.stringify({
								location: 'DeviceDashboard',
								deviceId: deviceId,
								org: organizationId,
							}),
						),
					statusLabel: getLanguageTag(
						currentWidget.constructor,
						'status',
					),
					status: getLanguageTag(
						currentWidget.constructor,
						meta.status || 'none',
					),
					image: image,
				});
			}

			currentWidget.infoWindow.setContent(
				$.handlebarTemplates.WidgetDeviceMap_InfoWindow({
					entries: entries,
				}),
			);
			currentWidget.infoWindow.setPosition(cluster.getCenter());
			currentWidget.infoWindow.open(currentWidget.googleMap);
		},
	);

	// We only want to reset the zoom once and then ignore this after each update.
	if (this.zoomListener === null || autoCenter) {
		// If there is more than 1 marker on the map. The map should
		// not set the zoom level, this ensures all the markers will be
		// visible on the map.
		this.googleMap.fitBounds(bounds);
		this.zoomListener = true;
	}

	if (this.clickListener === null) {
		this.clickListener = google.maps.event.addListener(
			currentWidget.googleMap,
			'click',
			function(event) {
				currentWidget.event('locationClicked', {
					lat: event.latLng.lat(),
					lon: event.latLng.lng(),
				});
			},
		);
	}

	if (this.markers.length <= 0) {
		this.noDevicesMsg.show();
	} else {
		this.noDevicesMsg.hide();
	}

	if (this.markers.length === 1) {
		// If there is only 1 marker on the map, set the zoom level to something
		// that is easier to see. (Zoom level should not exceed 16 or be less than 4)
		requestAnimationFrame(function() {
			if (currentWidget.googleMap.getZoom() > 16) {
				currentWidget.googleMap.setZoom(16);
			} else if (currentWidget.googleMap.getZoom() < 4) {
				currentWidget.googleMap.setZoom(4);
			}
		});
		this.getDirectionsButton.show();
	} else {
		this.getDirectionsButton.hide();
	}
};

WidgetDeviceMap.prototype.clearAllMarkers = function() {
	if (this.markerCluster !== null) {
		this.markerCluster.clearMarkers();
	}

	for (var i = 0; i < this.markers.length; i++) {
		this.markers[i].setMap(null);
	}

	this.markers = [];
};

WidgetDeviceMap.prototype.openMapToMarker = function(marker) {
	if (marker === undefined || marker === null) {
		return;
	}

	var link =
		'https://www.google.com/maps/search/?api=1&query=' +
		marker.position.lat().toString() +
		',' +
		marker.position.lng().toString();
	window.open(link, '_system');
};

WidgetDeviceMap.prototype.setConfiguration = function(config, callback) {
	var currentWidget = this;

	callback = callback || function() {};

	// If show sub orgs has changed
	const autoCenter = this.config.showSubOrgs !== config.showSubOrgs;

	this.config = {
		title: config.title || getLanguageTag(this.constructor, 'name'),
		updateTime: config.updateTime || 60000,
		showSubOrgs:
			config.showSubOrgs !== undefined ? config.showSubOrgs : true,
		maxClusterZoom: config.maxClusterZoom,
	};

	if (this._updateInterval !== null) {
		clearInterval(this._updateInterval);
		this._updateInterval = null;
	}

	// Don't autofit the map after configuration.
	// Let the user adjust the refresh rate without suddenly experiencing
	// a re-pan of the map.
	this.update(false, function(err) {
		if (currentWidget.config.updateTime < 60000) {
			currentWidget.config.updateTime = 60000;
		}

		currentWidget._updateInterval = setInterval(function() {
			// Only update the map if it is actually visible to the user.
			if (currentWidget.isVisible() && isActive()) {
				currentWidget.update(false, function(err) {});
			}
		}, currentWidget.config.updateTime || 60000);
	});

	callback.call(this);
};

WidgetDeviceMap.prototype.setCoordinate = function(latLon, pending) {
	const { lat, lon } = latLon;
	const { googleMap } = this;

	// Seems to be some timing problem,
	// there appears to be no way to figure out if the
	// Google Maps instance has been initialized.
	// Should requestAnimationFrame be used instead?
	// Looked at WidgetDeviceMap and saw a whole lot of
	// similar usage there.
	setTimeout(() => {
		// Clear State
		this.clearAllMarkers();

		// If locations are being set by force, via the WidgetSettingsForm
		// widget for example, then make sure the device googleMap does not
		// update and override previously selected points
		if (this._updateInterval) {
			clearInterval(this._updateInterval);
		}

		const bounds = new google.maps.LatLngBounds();
		const latLng = new google.maps.LatLng(lat, lon);
		// blue if pending, gray if not
		const pinColor = pending ? '5bc0de' : 'bdbdbd';
		const pinImg = new google.maps.MarkerImage(
			`./Widgets/WidgetDeviceMap/Resources/mapPinIcons/${pinColor}.png`,
		);
		new google.maps.Size(21, 34);
		new google.maps.Point(0, 0);
		new google.maps.Point(10, 34);

		const marker = new google.maps.Marker({
			position: latLng,
			map: googleMap,
			icon: pinImg,
		});

		// Very important to track the state
		this.markers.push(marker);

		// Only perform this behavior for previously
		// saved positions. It's very jarring to the
		// user otherwise
		if (!pending) {
			bounds.extend(marker.position);
			googleMap.setCenter(latLng);
			googleMap.fitBounds(bounds);
			googleMap.setZoom(1);
		}
	}, 400);
};

WidgetDeviceMap.prototype.getConfiguration = function() {
	return this.config;
};

WidgetDeviceMap.prototype.PACKERY_SIZE = 'WidgetDashboard_Container_XLxM';

WidgetDeviceMap.prototype.STATUS_TO_COLOR = {
	null: 'bdbdbd',
	info: '5bc0de',
	good: '5cb85c',
	minor: '5bc0de',
	warning: 'f0ad4e',
	critical: 'd9534f',
	missing: 'f0ad4e',
};

WidgetDeviceMap.prototype.STATUS_TO_NUMBER = {
	null: 0,
	good: 1,
	info: 2,
	missing: 3,
	minor: 4,
	warning: 5,
	critical: 6,
};

WidgetDeviceMap.prototype.language = deepAssign(
	{},
	WidgetDashboardBase.prototype.language,
	{
		'en-US': {
			name: 'Device Map',
			getDirections: 'Get Directions',
			noConfigurationOptionsAreCurrentlyAvailable:
				'No configuration options are currently available.',
			noneOfYourDevicesHaveGeolocationMetaInformation:
				"None of your devices have geolocation information set, check your device's settings for more information.",
			status: 'Status',
			configureDeviceMap: 'Configure Device Map',
			updateTime: 'Update Interval',
			none: 'None',
			info: 'Info',
			good: 'Good',
			minor: 'Minor',
			warning: 'Warning',
			critical: 'Critical',
			missing: 'Missing',
			showSubOrgs: 'Show Sub-Organizations',
			'1Minute': '1 Minute',
			'5Minutes': '5 Minutes',
			'10Minutes': '10 Minutes',
			'15Minutes': '15 Minutes',
			'30Minutes': '30 Minutes',
			'1Hour': '1 Hour',
			'2Hours': '2 Hours',
			'4Hours': '4 Hours',
			'6Hours': '6 Hours',
			'8Hours': '8 Hours',
			'12Hours': '12 Hours',
			maxClusterZoom: 'Maximum Clustering Zoom Level',
			clusterZoomDefault: 'Default',
			clusterZoom1: 'Level 1',
			clusterZoom2: 'Level 2',
			clusterZoom3: 'Level 3',
			clusterZoom4: 'Level 4',
			clusterZoom5: 'Level 5',
			clusterZoom6: 'Level 6',
			clusterZoom7: 'Level 7',
			clusterZoom8: 'Level 8',
			clusterZoom9: 'Level 9',
			clusterZoom10: 'Level 10',
			clusterZoom11: 'Level 11',
			clusterZoom12: 'Level 12',
			clusterZoom13: 'Level 13',
			clusterZoom14: 'Level 14',
			clusterZoom15: 'Level 15',
			clusterZoom16: 'Level 16',
			clusterZoom17: 'Level 17',
			clusterZoom18: 'Level 18',
			clusterZoom19: 'Level 19',
			clusterZoom20: 'Level 20',
		},

		de: {
			name: 'Gerätekarte',
		},

		es: {
			name: 'Mapa De Dispositivos',
		},

		ar: {
			name: 'خريطة الجهاز',
		},
	},
);
