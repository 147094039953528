function PlaneControllerEmbeddedRaspberryPi(parentController, elementName) {
	PlaneControllerEmbeddedLinux.call(this, parentController, elementName);
}

PlaneControllerEmbeddedRaspberryPi.prototype = Object.create(
	PlaneControllerEmbeddedLinux.prototype,
);
PlaneControllerEmbeddedRaspberryPi.prototype.constructor = PlaneControllerEmbeddedRaspberryPi;

PlaneControllerEmbeddedRaspberryPi.prototype.VARIANTS = ['RaspberryPi'];
PlaneControllerEmbeddedRaspberryPi.prototype.SUPPORTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'interval',
	'variables',
	'gpio',
	'i2c',
	'spi',
	'fpmath',
	'debugPrint',
	'uptime',
	'linux',
	'raspberrypi4',
	'uart',
];
PlaneControllerEmbeddedRaspberryPi.prototype.CLIENT_AGENT_REQUIRED = false;

PlaneControllerEmbeddedRaspberryPi.prototype.FIRMWARE_DOWNLOAD_FILES = {
	artifactName: 'firmware',
	fileName: 'Atmosphere_Project.AppImage',
};

PlaneControllerEmbeddedRaspberryPi.prototype.DRIVERS = {
	adc: [],
	ble: [],
	block: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLOCK_BLOCK1',
		},
	],
	datetime: [],
	embeddedCloud: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_CLOUD_PI',
		},
	],
	filesytem: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_FILESYSTEM_FILESYSTEM1',
		},
	],
	gpio: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_GPIO_GPIO1',
		},
	],
	http: [],
	i2c: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C1',
			primary: true,
		},
	],
	interval: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_INTERVAL_INTERVAL1',
		},
	],
	nfc: [],
	pwm: [],
	spi: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_SPI_SPI1',
		},
	],
	uart: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_UART_UART1',
		},
	],
	wifi: [],
};

PlaneControllerEmbeddedRaspberryPi.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'P_LED1',
		mode: 'ATMO_GPIO_PinMode_Output_PushPull',
	},
	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'P29',
	},
	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'P29',
	},
	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'P29',
	},
	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'P29',
	},
};

PlaneControllerEmbeddedRaspberryPi.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			defaultName: 'Raspberry Pi 4',
			ATMO_DRIVERINSTANCE_CLOUD_PI: 'Internal',
			ATMO_DRIVERINSTANCE_UART_UART1: 'UART5(12/13)',
		},
	},
);
