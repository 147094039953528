//Polyfill our fetch command with the native plugin
async function fetchForPhonegap() {
	console.debug(arguments);

	async function _fetch() {
		res = await cordovaFetch.apply(null, arguments);

		console.debug(res);

		//We need to handle and inject cookies in the request ourselves so we find our cookies and store them
		if (
			((((res || {}).headers || {}).map || {})['set-cookie'] || [])
				.length > 0
		) {
			window._atmoPhonegapCookies = res.headers.map['set-cookie']
				.map((item) => item.split(';')[0])
				.join(';');

			if (isPhonegap()) {
				localStorage.setItem(
					'_atmoPhonegapCookies',
					window._atmoPhonegapCookies,
				);
			}
		}

		return res;
	}

	return await _fetch.apply(null, arguments);
}

class AtmosphereApi extends SwaggerClient {
	clientHasJwt() {
		return this.jwt !== undefined && this.jwt !== null;
	}

	jwtNeedsRefresh() {
		return !this.clientHasJwt() || this.jwt.isExpired();
	}

	clearCredentials() {
		this.jwt = null;
		//We need to make sure to clear our credentials
		this.authorizations = {};
		//We need to make sure also clear out the cookies as well
		window._atmoPhonegapCookies = null;

		if (isPhonegap()) {
			localStorage.setItem('_atmoPhonegapCookies', null);
		}
	}

	setJwt(jwt) {
		this.jwt = jwt;
		this.authorizations = {
			jwt: {
				value: this.jwt.token,
			},
		};
	}

	async refreshJwt() {
		try {
			const jwtData = await this.apis.auth.getJWT();
			this.setJwt(new Jwt(jwtData));
		} catch (e) {
			if (
				isActive() && // Only go to login page if the user is looking at the screen
				window._mainContainer &&
				!window._mainContainer.atLoginLocation()
			) {
				window._mainContainer.redirectToLogin();
			}
			throw e;
		}
	}

	buildOptsWithContext(opts) {
		const newOpts = { ...opts };

		// If we are using phonegap we need to use a native fetch
		if (isPhonegap()) {
			newOpts.userFetch = fetchForPhonegap;
		}

		// Use custom request interceptor if not defined
		if (!newOpts.requestInterceptor) {
			newOpts.requestInterceptor = (req) => {
				// Distinguish browser and API client requests in server logs
				if (isPhonegap()) {
					req.headers['X-Client'] = 'App';
				} else {
					req.headers['X-Client'] = 'Browser';
				}

				//Make sure we inject our refreshToken cookie
				if (window._atmoPhonegapCookies) {
					req.headers['Cookie'] = window._atmoPhonegapCookies;
				}

				// Maybe we have one stored in the localStorage on phonegap device?
				else if (isPhonegap()) {
					req.headers['Cookie'] = localStorage.getItem(
						'_atmoPhonegapCookies',
					);
				}

				return req;
			};
		}

		return newOpts;
	}

	hasSecurity(opts) {
		let path = null;

		if (opts.method === 'GET') {
			path = opts.spec.paths[opts.pathName].get;
		} else if (opts.method === 'PUT') {
			path = opts.spec.paths[opts.pathName].put;
		} else if (opts.method === 'DELETE') {
			path = opts.spec.paths[opts.pathName].delete;
		} else if (opts.method === 'PATCH') {
			path = opts.spec.paths[opts.pathName].patch;
		} else {
			path = opts.spec.paths[opts.pathName].post;
		}

		return path.security && path.security.length > 0;
	}

	async execute(opts) {
		const currentApi = this;
		const isSecurePath = currentApi.hasSecurity(opts);
		if (
			isSecurePath &&
			this.jwtNeedsRefresh() &&
			opts.operationId !== 'getJWT'
		) {
			await this.refreshJwt();
		}

		this.spec.schemes = `${getAPIBase().split('//')[0]}//`; // http/https
		this.spec.servers[0].url = `${getAPIBase()}/v2`;

		const optsWithContext = this.buildOptsWithContext(opts);

		try {
			// Try to execute the call
			const resp = await super.execute(optsWithContext);
			return resp.obj;
		} catch (e) {
			// Unauthorized. Refresh JWT
			if (
				isSecurePath &&
				e.statusCode === 401 &&
				opts.operationId !== 'getJWT'
			) {
				console.debug('Refreshing JWT');
				await currentApi.refreshJwt();
			} else if (isSecurePath && e.statusCode === 429) {
				//So we hit the throttle limit and now need to bump the user out of the UI
				if (
					window._mainContainer &&
					!window._mainContainer.atLoginLocation()
				) {
					window._mainContainer.redirectToLogin();
				}
			} else {
				// Some other HTTP error. Bubble it up to the caller.
				throw e;
			}

			// Try again
			return super.execute(optsWithContext);
		}
	}
}
