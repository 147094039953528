function ElementAppCloudEvent(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);

	var currentElement = this;

	if (this._parent.editable === false) {
		this._interval = setInterval(function() {
			currentElement._popEvent(function() {});
		}, properties['time']);
	}
}

ElementAppCloudEvent.prototype = Object.create(ElementAppBase.prototype);
ElementAppCloudEvent.prototype.constructor = ElementAppCloudEvent;

ElementAppCloudEvent.prototype._remove = function() {
	clearInterval(this._interval);
};

ElementAppCloudEvent.prototype._popEvent = function(callback) {
	var currentElement = this;
	var hashCommand = getHashCommand();

	if (hashCommand.thingUuid === undefined) {
		callback.call(this, { type: 'noThingUuidInContext' });
		return;
	}

	this._parent._api
		.getAPIRoute('/user/thing/:thingUuid/admin/command/:elementName/pop')
		.post(hashCommand.thingUuid, this._name, function(err, data) {
			if (err) {
				callback.call(currentElement, err);
				return;
			}

			currentElement._setProperty('value', data, function(err) {
				currentElement.trigger('commandReceived', callback);
			});
		});
};
