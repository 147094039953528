function WidgetOrganizationsTopNav(id, api, parent, options) {
	WidgetBase.call(this, id, api, parent, options);

	this.lastOrg = null;
	this.lastBreadcrumbs = [];
}

WidgetOrganizationsTopNav.prototype = Object.create(WidgetBase.prototype);
WidgetOrganizationsTopNav.prototype.constructor = WidgetOrganizationsTopNav;
WidgetOrganizationsTopNav.prototype.ELLIPSIS = { ellipsis: true };

WidgetOrganizationsTopNav.prototype.initialize = function(callback) {
	const currentWidget = this;

	this.getMainContainer().addEventListener('hashChanged', function() {
		currentWidget.onHashChanged();
	});

	this.getMainContainer().addEventListener('orgNameChanged', function(event) {
		// Forces the update.
		currentWidget.lastOrg = null;
		currentWidget.lastBreadcrumbs = [];
		currentWidget.update();
	});

	this.getMainContainer().addEventListener('userLoggingOut', function() {
		currentWidget.onUserLoggingOut();
	});

	$(window).on('resize', function() {
		currentWidget.renderBreadcrumbs(currentWidget.lastBreadcrumbs);
	});

	this.update(function(err) {
		if (err) {
			callback.call(currentWidget, err);
			return;
		}

		WidgetBase.prototype.initialize.call(this, callback);
	});
};

WidgetOrganizationsTopNav.prototype.update = function(callback = () => {}) {
	const currentWidget = this;

	currentWidget
		._render()
		.then(() => {
			callback.call(currentWidget, false);
			return;
		})
		.catch((err) => {
			throw err;
		});
};

WidgetOrganizationsTopNav.prototype.getMaxBreadcrumbs = function() {
	const medWindowQuery = window.matchMedia('(max-width: 600px)');
	const smWindowQuery = window.matchMedia('(max-width: 480px)');

	let maxBreadCrumbs;

	if (smWindowQuery.matches) {
		maxBreadCrumbs = 2;
	} else if (medWindowQuery.matches) {
		maxBreadCrumbs = 3;
	} else {
		maxBreadCrumbs = 4;
	}

	return maxBreadCrumbs;
};

WidgetOrganizationsTopNav.prototype.handleBreadcrumbClicked = function(
	breadcrumb,
) {
	const orgId = breadcrumb.attr('orgId');

	// If we click a bread crumb and we are in a DeviceDashboard
	// instead move us to the DeviceManager for that org.
	if (
		getHashCommand().location.toLowerCase() ===
		'DeviceDashboard'.toLowerCase()
	) {
		this.getMainContainer().setLocation(LocationDeviceManager, {
			org: orgId,
			reload: true,
		});
	} else {
		this.getMainContainer().setLocation(getHashCommand().location, {
			org: orgId,
			reload: true,
		});
	}
};

WidgetOrganizationsTopNav.prototype.renderBreadcrumbs = function(breadcrumbs) {
	const maxBreadcrumbs = this.getMaxBreadcrumbs();
	const container = this.getContainer();

	let trimmedCrumbs;

	if (breadcrumbs.length <= maxBreadcrumbs) {
		trimmedCrumbs = breadcrumbs;
	} else {
		const breadCrumbSlicePos = breadcrumbs.length - maxBreadcrumbs + 1;
		trimmedCrumbs = [
			breadcrumbs[0],
			{ ellipsis: true },
			...breadcrumbs.slice(breadCrumbSlicePos),
		];
	}

	this.renderTemplate(
		{ breadcrumbs: trimmedCrumbs },
		WidgetOrganizationsTopNav.name,
		container,
	);
};

WidgetOrganizationsTopNav.prototype._render = async function() {
	const currentWidget = this;
	const api = currentWidget.getApiV2().apis;

	// Get the org id from the command hash
	let currentOrgId = (getHashCommand() || {}).org || null;
	let user = null;

	if (!currentOrgId) {
		try {
			user = await api.auth.getCurrentUser();
		} catch (err) {
			// We failed to get the user maybe we aren't logged in
			return;
		}

		if (user) {
			currentOrgId = user.organizationId || null;
		}
	}

	// No need to update we already updated with this org before
	if (currentOrgId === this.lastOrg) {
		return;
	}

	this.lastOrg = currentOrgId;

	const currentOrg = await api.organizations.getOrganization({
		id: currentOrgId,
	});

	this.lastBreadcrumbs = [...currentOrg.path, currentOrg];

	currentWidget.renderBreadcrumbs(this.lastBreadcrumbs);

	$('.WidgetOrganizationsTopNav-ClickableBreadcrumb').on('click', function(
		e,
	) {
		e.preventDefault();
		currentWidget.handleBreadcrumbClicked($(this));
	});

	const icon = $('#WidgetOrganizationsTopNav_Icon');

	let image = './Resources/icons/OrganizationsBreadcrumb.svg';

	try {
		image = await api.organizations.getOrganizationImage({
			id: currentOrgId,
		});
	} catch (err) {
		image = './Resources/icons/OrganizationsBreadcrumb.svg';
	}

	icon.attr('src', image);
};

WidgetOrganizationsTopNav.prototype.onHashChanged = function() {
	this.update();
};

WidgetOrganizationsTopNav.prototype.onUserLoggingOut = function() {
	// Blank out the breadcrumbs when the user logs out
	this.lastBreadcrumbs = [];
	this.renderBreadcrumbs(this.lastBreadcrumbs);
};

WidgetOrganizationsTopNav.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'OrganizationsTopNav',
		},
	},
);
