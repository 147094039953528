function LocationNotifications(id, api, parentWidget, options) {
	LocationBase.call(this, id, api, parentWidget, options);
}

LocationNotifications.prototype = Object.create(LocationBase.prototype);
LocationNotifications.prototype.constructor = LocationNotifications;

LocationNotifications.prototype.initialize = function(callback) {
	var currentLocation = this;

	var tabsConfig = [
		{
			childId: 'table',
			constructor: WidgetNotificationManager,
			options: {},
		},
	];

	currentLocation.setTabWidgets(null, tabsConfig, function(err) {
		LocationBase.prototype.initialize.call(currentLocation, callback);
	});
};

LocationNotifications.prototype.language = deepAssign(
	{},
	LocationBase.prototype.language,
	{
		'en-US': {
			name: 'Notifications',
		},
	},
);
