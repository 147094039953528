function ElementAppAverage(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);

	//sampleSize : the number of samples to take before outputting an average
	//samples: the current array of samples
	//average : the average result of the last sample set
	//cma : the currnet CMA value (Cumulative Moving Average)
	//cmaSize : the number of samples currently in the CMA
}

ElementAppAverage.prototype = Object.create(ElementBase.prototype);
ElementAppAverage.prototype.constructor = ElementAppAverage;

ElementAppAverage.prototype.push = function(value, callback) {
	this._getProperty('samples', function(err, samples) {
		this._getProperty('sampleSize', function(err, sampleSize) {
			this._getProperty('cma', function(err, cma) {
				this._getProperty('cmaSize', function(err, cmaSize) {
					value = this._convertToNumber(value);

					if (isNaN(value)) {
						callback.call(this, { type: 'notANumber' });
						return;
					}

					samples.push(value);

					cma = (value + cmaSize * cma) / (cmaSize + 1);
					cmaSize = cmaSize + 1;

					this._setProperty('cma', cma, function(err) {
						this._setProperty('cmaSize', cmaSize, function(err) {
							if (
								samples.length >= sampleSize &&
								samples.length > 0
							) {
								var average = 0;

								for (var i = 0; i < samples.length; i++) {
									average += samples[i];
								}

								average = average / samples.length;

								this._setProperty('average', average, function(
									err,
								) {
									this._setProperty('samples', [], function(
										err,
									) {
										this.trigger('pushed', function(err) {
											this.trigger(
												'averagedSamples',
												callback,
											);
										});
									});
								});
							} else {
								this._setProperty('samples', samples, function(
									err,
								) {
									this.trigger('pushed', callback);
								});
							}
						});
					});
				});
			});
		});
	});
};

ElementAppAverage.prototype.clear = function(value, callback) {
	this._setProperty('cma', null, function(err) {
		this._setProperty('cmaSize', 0, function(err) {
			this._setProperty('average', null, function(err) {
				this._setProperty('samples', [], function(err) {
					this.trigger('cleared', callback);
				});
			});
		});
	});
};

ElementAppAverage.prototype.averageValues = function(values, callback) {
	if (values.length !== undefined) {
		var average = 0;
		var numberOfValues = 0;

		for (var i = 0; i < values.length; i++) {
			var currentValue = this._convertToNumber(values[i]);

			if (isNaN(currentValue)) {
				continue;
			}

			average += values[i];
			numberOfValues++;
		}

		average = average / numberOfValues;

		this._setProperty('average', average, function(err) {
			this.trigger('averaged', callback);
		});
	}
};

ElementAppAverage.prototype.setSampleSize = function(value, callback) {
	value = this._convertToInteger(value);

	if (isNaN(value)) {
		callback.call(this, { type: 'notAnInteger' });
		return;
	}

	this._setProperty('sampleSize', value, function(err) {
		this.trigger('sampleSizeSet', callback);
	});
};
