function WidgetNotificationsMenu(id, api, parent, options) {
	this._updateInterval = null;
	this._pushedNotificationIds = [];
	this._notificationData = [];

	WidgetMenu.call(this, id, api, parent, options);
}

WidgetNotificationsMenu.prototype = Object.create(WidgetMenu.prototype);
WidgetNotificationsMenu.prototype.constructor = WidgetNotificationsMenu;

WidgetNotificationsMenu.prototype.initialize = function(callback) {
	const currentWidget = this;

	this.askUserForPermissionToNotify();

	this.addEventListener('menuEntryClicked', function(data) {
		currentWidget.getMainContainer().setLocation(LocationNotifications, {
			notificationId: data.id,
			org: getHashCommand().org,
		});
		currentWidget.hide();
	});

	this.addEventListener('menuHrefHandled', () => {
		currentWidget.hide();
	});

	this._onLocationChanged = currentWidget.updateNotifications.bind(
		currentWidget,
		undefined,
	);
	
	this._onUserLoggedIn = currentWidget.updateNotifications.bind(
		currentWidget,
		undefined,
	);

	this.getMainContainer().addEventListener(
		'locationChanged',
		this._onLocationChanged,
	);
	
	this.getMainContainer().addEventListener(
		'userLoggedIn',
		this._onUserLoggedIn,
	)
	
	WidgetMenu.prototype.initialize.call(this, function(err) {
		if (err) {
			callback.call(currentWidget, err);
			return;
		}
		
		// AI-3664 have us listen to an event from maincontainer instead of running our own interval
		currentWidget.getMainContainer().addEventListener('notificationsRefreshed', function() {
			const currentUser = currentWidget
				.getMainContainer()
				.getCurrentUser();
			if (currentUser && isActive()) {
				// If cordova background mode is enabled
				// and the user has a session, continue
				// fetching notifications.
				// isActive() always returns true in
				// non-Cordova environments.
				return currentWidget.updateNotifications();
			}
			console.debug(
				`[WidgetNotificationsMenu] No user session found, no longer fetching notifications...`,
			);
		});
	
		currentWidget.addEventListener('hidden', function() {
			currentWidget.onHide();
		});

		callback.call(currentWidget, false);
		return;
	});

	//We need to setup a background task to check for notifications
	//even if the app is in background by the OS
	if (isPhonegap()) {
		window.BackgroundFetch.configure(
			function(taskId) {
				console.debug(
					`WidgetNotificationsMenu: Task executed (${taskId})`,
				);

				if (isActive()) {
					window.BackgroundFetch.finish(taskId);
					return;
				}

				currentWidget.updateNotifications(function() {
					console.debug(
						`WidgetNotificationsMenu: Task finished (${taskId})`,
					);
					window.BackgroundFetch.finish(taskId);
				});
			},
			function(err) {
				console.debug(
					'WidgetNotificationsMenu: BackgroundFetch failed',
					error,
				);
			},
			{
				minimumFetchInterval: 15, //Doesn't guarantee 15 minutes and also can't go lower than 15 on iOS
				requiredNetworkType: window.BackgroundFetch.NETWORK_TYPE_ANY,
			},
		);
	}
};

WidgetNotificationsMenu.prototype.remove = function(callback) {
	
	this
		.getMainContainer()
		.removeEventListener('locationChanged', this._onLocationChanged);
		
	this
		.getMainContainer()
		.removeEventListener('notificationsRead', this._onNotificationsRead);

	this
		.getMainContainer()
		.removeEventListener('_onUserLoggedIn', this._onUserLoggedIn);
	
	if (isPhonegap()) {
		window.BackgroundFetch.stop(
			function() {},
			function(err) {
				console.debug(
					'WidgetNotificationsMenu: Stopping background fetch failed',
					error,
				);
			},
		);
	}

	WidgetMenu.prototype.remove.call(this, callback);
};

WidgetNotificationsMenu.prototype.askUserForPermissionToNotify = function(
	callback,
) {
	const currentWidget = this;

	callback = callback || function() {};

	if (isPhonegap()) {
		//When the user clicks the notification on mobile
		//we will take them to the notification manager
		cordova.plugins.notification.local.on('click', function(obj) {
			currentWidget
				.getMainContainer()
				.setLocation(LocationNotifications, {
					reload: true,
					org: getHashCommand().org,
				});
		});
	} else {
		// We will only ask once if the user wants notifications. If they change their mind they can go to client settings.
		try {
			const { permission } = Notification || {};

			if (permission) {
				Notification.requestPermission().then(function(permission) {
					callback.call(currentWidget, false, permission);
				});
			} else {
				callback.call(
					currentWidget,
					false,
					(Notification || {}).permission || false,
				);
			}
		} catch (err) {
			console.debug(
				getLanguageTag(
					this.constructor,
					'yourBrowserDoesNotSupportNotifications',
				),
			);
			callback.call(currentWidget, err);
			return;
		}
	}
};

WidgetNotificationsMenu.prototype.onHide = function() {
	const currentWidget = this;

	if (currentWidget._notificationData.length > 0) {
		const reqParams = {};
		reqParams.to = currentWidget._notificationData[0].createdAt;

		if (currentWidget._notificationData.length > 1) {
			reqParams.from =
				currentWidget._notificationData[
					currentWidget._notificationData.length - 1
				].createdAt;
		}

		const api = currentWidget.getApiV2();

		try {
			api.apis.notifications
				.updateNotifications(reqParams, { requestBody: { read: true } })
				.then(() => {
					currentWidget.updateNotifications();
				});
		} catch (err) {
			console.error(`Error marking notifications read `, err, reqParams);
		}
	}
};

WidgetNotificationsMenu.prototype.MESSAGE_TYPE_TO_ICON = {
	deviceNotification: './Resources/icons/Device.svg',
	systemNotification: './Resources/icons/System.svg',
	accountNotification: './Resources/icons/Private.svg',
	unknown: './Resources/icons/Unknown.svg',
};

WidgetNotificationsMenu.prototype.markNotificationRead = function(id) {
	const currentWidget = this;
	currentWidget
		.getApiV2()
		.apis.notifications.updateNotification(
			{ id },
			{ requestBody: { read: true } },
		)
		.catch((err) => {
			console.error(`Error setting notification ${id} read: `, err);
		})
		.finally(() => {
			currentWidget.updateNotifications(() => {});
		});
};

WidgetNotificationsMenu.prototype.sendPushNotification = function(
	notificationData,
) {
	const currentWidget = this;

	// If it's phonegap we need to use a plugin
	if (isPhonegap()) {
		// We only wish to bother the user if the app
		// is not in the forground
		if (!isActive()) {
			cordova.plugins.notification.local.schedule({
				text: $('<div>')
					.html(getMessageLanguage(notificationData.translations))
					.text(),
				sound: null,
				smallIcon: 'res://transparenticonsmall.png',
			});
		}
	} else {
		if (!window.Notification) {
			return;
		}

		const pushNotification = new Notification(window.document.title, {
			requireInteraction: false,
			tag: notificationData.id,
			icon:
				this.MESSAGE_TYPE_TO_ICON[notificationData.type] ||
				this.MESSAGE_TYPE_TO_ICON['unknown'],
			body: $('<div>')
				.html(getMessageLanguage(notificationData.translations))
				.text(),
		});

		pushNotification.addEventListener('click', () => {
			currentWidget
				.getMainContainer()
				.setLocation(LocationNotifications, {
					org: getHashCommand().org,
				});
			currentWidget.hide();
		});
	}
};

WidgetNotificationsMenu.prototype.sendCountPushNotification = function(
	numberOfNewNotifications,
) {
	const currentWidget = this;

	// If it's phonegap we need to use a plugin
	// -A Phonegap Developer
	if (isPhonegap()) {
		// We only wish to bother the user if the app
		// is not in the forground
		if (!isActive()) {
			cordova.plugins.notification.local.schedule({
				text: this.getLanguageTag('youHaveANumberOfNewNotifications'),
				sound: null,
				smallIcon: 'res://transparenticonsmall.png',
			});
		}
	} else {
		if (!window.Notification) {
			return;
		}

		const pushNotification = new Notification(window.document.title, {
			tag: null,
			icon: this.MESSAGE_TYPE_TO_ICON['unknown'],
			body: this.getLanguageTag('youHaveANumberOfNewNotifications'),
		});

		pushNotification.addEventListener('click', function(data) {
			currentWidget
				.getMainContainer()
				.setLocation(LocationNotifications, {
					org: getHashCommand().org,
				});
			currentWidget.hide();
		});

		pushNotification.addEventListener('close', function(data) {});
	}
};

WidgetNotificationsMenu.prototype.updateNotifications = function(callback) {
	callback = callback || function() {};
	const currentWidget = this;

	const currentUser = currentWidget.getCurrentUser();
	if (!currentUser) {
		callback.call(currentWidget, { error: 'notLoggedIn' });
		return;
	}

	const api = currentWidget.getApiV2().apis;
	api.notifications
		.getNotifications({ unread: true, limit: 10 })
		.then((notifications) => {
			
			// We need to throw an event so that WidgetNavbar updates our number next to the bell
			window.requestAnimationFrame(function() {
				currentWidget.event('numberOfItemsChanged', notifications.meta.totalDocs);
			});

			const menuData = [];
			let numberOfNewNotifications = 0;
			const notificationData = notifications.data;

			currentWidget._notificationData = notificationData;
			currentWidget._totalUnread = notifications.meta.totalDocs;

			for (let i = 0; i < notificationData.length; i += 1) {
				let messageTranslation = getMessageLanguage(
					notificationData[i].translations,
				);

				// AI-3173: If we are running on mobile (Phonegap)
				// we need to attach each anchor link so that we
				// can properly handle redirecting in the app
				if (isPhonegap()) {
					let tempDiv = $('<div>').html(messageTranslation);
					let anchors = tempDiv.find('a');

					anchors.each((i) => {
						let aHref = $(anchors[i]).attr('href');

						// Are we trying to use a global link for
						// something that should be local?
						// If so remove the global reference and
						// convert to local link
						if (aHref.includes(getAPIBase())) {
							aHref = aHref.replace(getAPIBase() + '/', '');
							$(anchors[i]).attr('href', aHref);
						}

						// Else we can assume we want to make a global
						// link and open the user's default
						// web browser
						else {
							$(anchors[i]).attr('target', '_system');
						}
					});

					messageTranslation = tempDiv.html();
				}

				menuData.push({
					label: `<div></div><div class="WidgetNotificationsMenu-MessageTag">${messageTranslation}</div><div class="WidgetNotificationsMenu-MomentTag">${moment(
						new Date(notificationData[i].createdAt),
					).fromNow()}</div>`,
					value: {
						type: 'notification',
						id: notificationData[i].id,
					},
				});

				if (
					currentWidget._pushedNotificationIds.indexOf(
						notificationData[i].id,
					) < 0
				) {
					numberOfNewNotifications++;

					if (notificationData.length < 10) {
						currentWidget.sendPushNotification(notificationData[i]);
					}
				}

				currentWidget._pushedNotificationIds.push(
					notificationData[i].id,
				);
			}

			if (numberOfNewNotifications >= 10) {
				currentWidget.sendCountPushNotification(
					numberOfNewNotifications,
				);
			}

			currentWidget.setMenu(
				menuData,
				`<div class="WidgetNotificationMenu-Title standard-local-header-h3">${getLanguageTag(
					currentWidget.constructor,
					'notifications',
				)} (${notifications.meta.totalDocs})</div>`,
				`<button class="WidgetNotificationMenu-Footer btn btn-link">${getLanguageTag(
					currentWidget.constructor,
					'seeAllNotifications',
				)}</button>`,
			);

			currentWidget
				.getContainer()
				.find('.WidgetNotificationMenu-Footer')
				.click(function() {
					currentWidget
						.getMainContainer()
						.setLocation(LocationNotifications, {
							org: getHashCommand().org,
						});
					currentWidget.hide();
				});
		})
		.catch((err) => {
			console.error('Error fetching notifications', err);
		})
		.finally(() => {
			callback.call(currentWidget, false);
		});
};

WidgetNotificationsMenu.prototype.language = deepAssign(
	{},
	WidgetMenu.prototype.language,
	{
		'en-US': {
			manageNotifications: 'Manage Notifications',
			notifications: 'Notifications',
			seeAllNotifications: 'See all notifications',
			yourBrowserDoesNotSupportNotifications:
				'Your browser does not support push notifications',
			youHaveANumberOfNewNotifications: 'You have new notifications',
		},
	},
);
