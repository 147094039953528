function WidgetAppView(id, api, parent, options) {
	var currentWidget = this;

	options = options || {};

	WidgetBase.call(this, id, api, parent, options);

	this.canvasId = this.generateChildId('canvas');
	this.hitCanvasId = this.generateChildId('hitCanvas');
	this.bleConnectionIconId = this.generateChildId('bleConnectionIcon');

	this.renderTemplate(
		{
			canvasId: this.canvasId,
			hitCanvasId: this.hitCanvasId,
			bleConnectionIconId: this.bleConnectionIconId,
		},
		WidgetAppView.name,
	);

	this.canvasContainer = this.getContainer().find('.WidgetAppView-Top');
	this.canvas = $('#' + this.canvasId);
	this.hitCanvas = $('#' + this.hitCanvasId);

	PluginBLECentral.prototype.SELECT_BACKEND();

	this.pluginBLECentral = new PluginBLECentral();
	this.pluginNFC = new PluginNFC(this.getMainContainer().getNFCHandler());

	this.currentConnectedCallback = this.pluginBLECentral.addEventListener(
		'connected',
		function() {
			currentWidget.onConnected('ble');
		},
	);

	this.currentConnectingCallback = this.pluginBLECentral.addEventListener(
		'connecting',
		function() {
			currentWidget.onConnecting('ble');
		},
	);

	this.currentDisconnectedCallback = this.pluginBLECentral.addEventListener(
		'disconnected',
		function() {
			currentWidget.onDisconnected('ble');
		},
	);

	this.currentScanningStartedCallback = this.pluginBLECentral.addEventListener(
		'scanningStarted',
		function() {
			currentWidget.onScanningStarted('ble');
		},
	);

	this.currentScanningStoppedCallback = this.pluginBLECentral.addEventListener(
		'scanningStopped',
		function() {
			currentWidget.onScanningStopped('ble');
		},
	);

	this.fillContainer = this.getOptions().fillContainer || false;

	this.connectionIcons = {
		ble: $('#' + this.bleConnectionIconId),
	};

	for (var typeName in this.connectionIcons) {
		this.connectionIcons[typeName].hide();
	}
}

WidgetAppView.prototype = Object.create(WidgetBase.prototype);
WidgetAppView.prototype.constructor = WidgetAppView;

WidgetAppView.prototype.initialize = function(callback) {
	//We need to callback immediatlly or else WidgetAppViewEditor doesn't catch the import loads.

	var currentWidget = this;

	this.root = new CrocRoot(this.canvas[0], this.hitCanvas[0], false);

	this.appView = new AppView(
		this.getAPI(),
		{ PluginBLECentral: this.pluginBLECentral, PluginNFC: this.pluginNFC },
		this.root,
	);

	this._resizeHandler = function() {
		currentWidget.onResize();
	};

	this.wasConnected = false;

	$(window).resize(this._resizeHandler);

	WidgetBase.prototype.initialize.call(this, callback);
};

WidgetAppView.prototype.onConnected = function(type) {
	this.connectionIcons[type].show();
	this.connectionIcons[type].removeClass('WidgetAppView-ScanningIcon');

	this.wasConnected = true;
};

WidgetAppView.prototype.onConnecting = function(type) {
	this.connectionIcons[type].show();
	this.connectionIcons[type].addClass('WidgetAppView-ScanningIcon');
};

WidgetAppView.prototype.onDisconnected = function(type) {
	this.connectionIcons[type].hide();

	//If we had a connection and then lost it then try to connect again.
	if (this.wasConnected) {
		this.wasConnected = false;
		this.appView.connectToDevice(function() {});
	}
};

WidgetAppView.prototype.onScanningStarted = function(type) {
	this.connectionIcons[type].show();
	this.connectionIcons[type].addClass('WidgetAppView-ScanningIcon');
};

WidgetAppView.prototype.onScanningStopped = function(type) {};

WidgetAppView.prototype.remove = function(callback) {
	var currentWidget = this;

	$(window).off('resize', this._resizeHandler);

	this.appView.remove(function() {
		WidgetBase.prototype.remove.call(currentWidget, callback);
	});
};

WidgetAppView.prototype.onResize = function() {
	var currentWidget = this;

	if (this.fillContainer) {
		var windowWidth = $(window).width();
		var windowHeight = $(window).height();

		this.appView.getBestLayoutFit(windowWidth, windowHeight, function(
			err,
			width,
			height,
			scale,
		) {
			currentWidget.setLayoutFullscreen(width, height, scale);
		});
	}

	return;
};

WidgetAppView.prototype.showNoLayoutFits = function() {
	this.canvasContainer.hide();
};

WidgetAppView.prototype.setLayoutFullscreen = function(width, height, scale) {
	var currentWidget = this;

	windowWidth = $(window).width();

	scale = scale || 1.0;

	this.root.setWidth(width * scale);
	this.root.setHeight(height * scale);

	this.appView.root.setScaleFactor(scale);

	this.appView.setLayout(width, height, function(err) {
		currentWidget.canvasContainer.show();
	});
};

WidgetAppView.prototype.setLayoutPanel = function(width, height) {
	var currentWidget = this;

	this.canvasContainer.css('position', 'relative');
	this.canvasContainer.css('top', '0');
	this.canvasContainer.css('left', '0');
	this.canvasContainer.height(height);
	this.canvasContainer.width(width);
	this.root.setWidth(width);
	this.root.setHeight(height);

	this.appView.setLayout(width, height, function(err, width, height) {
		currentWidget.canvasContainer.show();
	});
};

WidgetAppView.prototype.setLayout = function(width, height) {
	var currentWidget = this;

	this.canvasContainer.css('position', 'relative');
	this.canvasContainer.css('top', '0');
	this.canvasContainer.css('left', '0');
	this.canvasContainer.height(height);
	this.canvasContainer.width(width);
	this.root.setWidth(width);
	this.root.setHeight(height);

	this.appView.setLayout(width, height, function(err, width, height) {
		currentWidget.canvasContainer.show();
	});
};

WidgetAppView.update = function() {};

WidgetAppView.loadByDeviceUuid = function(deviceUuid, plane) {};

WidgetAppView.loadByProjectUuid = function(projectUuid, plane) {};

WidgetAppView.loadByProjectData = function(projectData, plane) {};

WidgetAppView.loadByPlaneData = function(planeData) {};

WidgetAppView.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'App View',
			bluetooth: 'Bluetooth',
		},
	},
);
