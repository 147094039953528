function ElementControllerEmbeddedUART(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	var baudRates = [
		'ATMO_UART_BaudRate_1200',
		'ATMO_UART_BaudRate_2400',
		'ATMO_UART_BaudRate_4800',
		'ATMO_UART_BaudRate_9600',
		'ATMO_UART_BaudRate_19200',
		'ATMO_UART_BaudRate_38400',
		'ATMO_UART_BaudRate_57600',
		'ATMO_UART_BaudRate_115200',
		'ATMO_UART_BaudRate_230400',
		'ATMO_UART_BaudRate_460800',
		'ATMO_UART_BaudRate_921600',
	];

	var dataTypes = ['ATMO_DATATYPE_BINARY', 'ATMO_DATATYPE_STRING'];

	this.addProperty('instance', null, 0, 'driverInstance', null, 'uart');
	this.addProperty(
		'baudRate',
		null,
		'ATMO_UART_BaudRate_115200',
		'select',
		baudRates,
		null,
	);
	this.addProperty('bufferRegex', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('bufferRegex', 'rawstring');
	this.addProperty(
		'dataType',
		null,
		'ATMO_DATATYPE_STRING',
		'select',
		dataTypes,
	);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
	ATMO_UART_Peripheral_t uart;
	ATMO_UART_GetDefaultConfig(&uart);
	uart.baudRate = ATMO_PROPERTY(undefined, baudRate);
	uart.flowControl = ATMO_UART_FlowControl_None;
	uart.numDataBits = ATMO_UART_NumDataBits_8;
	uart.numStopBits = ATMO_UART_NumStopBits_1;
	uart.parity = ATMO_UART_Parity_None;
	uart.isBinaryData = ATMO_PROPERTY(undefined, dataType) == ATMO_DATATYPE_BINARY;
	uart.rxTrim = false;

	if(strlen(ATMO_PROPERTY(undefined, bufferRegex)) > 0)
	{
		uart.rxBuffer = true;
		strcpy(uart.splitRegex, ATMO_PROPERTY(undefined, bufferRegex));
	}
	else
	{
		uart.rxBuffer = false;
	}

	ATMO_UART_SetConfiguration(ATMO_PROPERTY(undefined, instance), &uart);
	ATMO_UART_RegisterRxAbilityHandle(ATMO_PROPERTY(undefined, instance), ATMO_ABILITY(undefined, receiveData));
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('readByteBlocking', [], ['byteReadBlocking']);
	this.setAbilityCode(
		'dataReceived',
		`
	unsigned int c = 0;
	uint32_t numBytesReceived = 0;
	if( (ATMO_UART_ReadBlocking(ATMO_PROPERTY(undefined, instance), &c, 1, &numBytesReceived, 0) != ATMO_UART_Status_Success) || (numBytesReceived != 1) )
	{
		return ATMO_Status_Fail;
	}

	ATMO_CreateValueBinary(out, &c, 1);

	return ATMO_Status_Success;
	`,
	);

	this.addAbility('receiveData', [], ['dataReceived'], false, true);
	this.setAbilityCode(
		'receiveData',
		`
	ATMO_CreateValueCopy(out, in);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('writeBlocking', [], ['dataWritten']);
	this.setAbilityCode(
		'writeBlocking',
		`
	uint32_t numBytesWritten = 0;

	if( (ATMO_UART_WriteBlocking(ATMO_PROPERTY(undefined, instance), (const char *)in->data, in->size, &numBytesWritten, 0) != ATMO_UART_Status_Success) || (numBytesWritten != in->size))
	{
		return ATMO_Status_Fail;
	}

	return ATMO_Status_Success;
	`,
	);
}

ElementControllerEmbeddedUART.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedUART.prototype.constructor = ElementControllerEmbeddedUART;

ElementControllerEmbeddedUART.prototype.TYPE = 'EmbeddedUART';
ElementControllerEmbeddedUART.prototype.HIDDEN = false;
ElementControllerEmbeddedUART.prototype.DEFAULT_ABILITY = 'writeBlocking';
ElementControllerEmbeddedUART.prototype.DEFAULT_TRIGGER = 'dataReceived';
ElementControllerEmbeddedUART.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'uart',
];
ElementControllerEmbeddedUART.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedUART.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedUART: 'UART',
			instance: 'Instance',
			baudRate: 'Baud Rate',
			bufferRegex: 'Buffered Reading Regex',
			dataType: 'Data Type',
			readByteBlocking: 'Read Byte',
			dataReceived: 'Data Received',
			writeBlocking: 'Write Blocking',
			dataWritten: 'Data Written',
			ATMO_UART_BaudRate_1200: '1200',
			ATMO_UART_BaudRate_2400: '2400',
			ATMO_UART_BaudRate_4800: '4800',
			ATMO_UART_BaudRate_9600: '9600',
			ATMO_UART_BaudRate_19200: '19200',
			ATMO_UART_BaudRate_38400: '38400',
			ATMO_UART_BaudRate_57600: '57600',
			ATMO_UART_BaudRate_115200: '115200',
			ATMO_UART_BaudRate_230400: '230400',
			ATMO_UART_BaudRate_460800: '460800',
			ATMO_UART_BaudRate_921600: '921600',
			ATMO_DATATYPE_BINARY: 'Binary',
			ATMO_DATATYPE_STRING: 'String',
		},
	},
);
