function ElementBase(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	/*
	 * This base class for all elements.
	 *
	 * @param parentApp: The parent widget app that owns this element
	 * @param properties: The initial properties for this element
	 * @param triggers: The execution triggers as described by the project data
	 * @param optional controller: The controller that is attached to this element for direct realtime editing
	 *
	 */

	EventListener.call(this);

	var currentElement = this;

	this._name = elementName;
	this._parent = parentApp;

	this._properties = properties;
	this._triggers = triggers;
	this._controller = controller;
	this._editable = false;

	if (this._controller !== undefined && this._controller !== null) {
		this._controller.addEventListener('propertySet', function(data) {
			currentElement._setProperty(data.property, data.value, function() {
				currentElement._onPropertyChange();
			});
		});

		this._controller.addEventListener('triggerEventAdded', function(data) {
			//{fromElement:this, toElement:this._parentPlane.getElement(targetElement.toString()), triggerName:triggerName, targetAbility:targetAbility, index:currentIndex}

			var elementData = data.fromElement.export();

			if (elementData.triggers !== undefined) {
				currentElement._triggers = elementData.triggers;
			}
		});
	}
}

ElementBase.prototype = Object.create(EventListener.prototype);
ElementBase.prototype.constructor = ElementBase;

ElementBase.prototype._initialize = function(callback) {
	this.trigger('initialized', function(err) {
		callback.call(this, false);
	});
};

ElementBase.prototype._setEditable = function(enabled) {
	this._editable = enabled;

	this._setWithController();
};

ElementBase.prototype._setWithController = function() {
	if (this._controller !== null) {
		this._properties = this._controller.export().properties;

		this._onPropertyChange();
	}
};

ElementBase.prototype._remove = function() {};

ElementBase.prototype._convertToInteger = function(value) {
	if (value === true) {
		return 1;
	}

	if (value === false) {
		return 0;
	}

	return parseInt(value);
};

ElementBase.prototype._convertToNumber = function(value) {
	if (value === true) {
		return 1;
	}

	if (value === false) {
		return 0;
	}

	return parseFloat(value);
};

ElementBase.prototype._convertToColor = function(value) {
	return value;
};

ElementBase.prototype._convertToFont = function(value) {
	if (typeof value !== 'string') {
		return null;
	}

	return value;
};

ElementBase.prototype._convertToString = function(value) {
	if (value === undefined) {
		return 'undefined';
	}

	if (value === null) {
		return 'null';
	}

	if (typeof value === 'object') {
		return JSON.stringify(value);
	}

	if (value.toString !== undefined) {
		return value.toString();
	}

	return 'unknown';
};

ElementBase.prototype._onPropertyChange = function() {};

ElementBase.prototype._setProperties = function(properties, callback) {};

ElementBase.prototype._getProperties = function(callback) {};

ElementBase.prototype._setProperty = function(propertyName, value, callback) {
	this._properties[propertyName] = value;
	callback.call(this, false);
};

ElementBase.prototype._getProperty = function(propertyName, callback) {
	if (
		this._controller !== undefined &&
		this._controller !== null &&
		this._editable
	) {
		this._properties = this._controller.export().properties;
	}

	if (this._properties[propertyName] === undefined) {
		callback.call(
			this,
			{ type: 'noSuchProperty', propertyName: propertyName },
			null,
		);
		return;
	}

	var value = this._properties[propertyName];

	if (typeof value === 'object' && this._properties[propertyName] !== null) {
		value = new this._properties[propertyName].constructor();
		Object.assign(value, this._properties[propertyName]);
	}

	callback.call(this, false, value);
	return;
};

ElementBase.prototype._hasProperty = function(propertyName, callback) {};

ElementBase.prototype._triggerError = function(errorData, callback) {
	this._setProperty('errorData', errorData.toString(), function(err) {
		this.error(errorData);
		this.trigger('error', callback);
	});
};

ElementBase.prototype._setTriggers = function(triggers, callback) {};

ElementBase.prototype._getTriggers = function(callback) {};

ElementBase.prototype._hasTrigger = function(triggerName, callback) {};

ElementBase.prototype.trigger = function(triggerName, callback) {
	/*
	 * Using the current triggers to execute the following triggers to all the
	 * elements that are next in execution using our safe execute function to
	 * run the user based javascript in the enviroment safely.
	 *
	 * This will execute the trigger on the app, given the name and trigger data.
	 *
	 * Example name: onTrigger, valueSet, error ect.
	 *
	 * Example trigger data: [{"mapping":{"value":{"code":"value + 23"}},"targetOrder":["value"],"targetElement":"CloudBuffer1","targetAbility":"push"}]
	 *
	 */

	if (typeof triggerName === 'function') {
		callback = triggerName;
		triggerName = null;
	}

	callback = callback || function() {};

	if (this._editable) {
		callback.call(currentElement, false);
		return;
	}

	triggerName = triggerName || 'triggered';

	var currentElement = this;

	if (this._triggers[triggerName] === undefined) {
		callback.call(currentElement, false);
		return;
	}

	var currentTriggerData = this._triggers[triggerName].slice();

	if (currentTriggerData === undefined || currentTriggerData.length === 0) {
		callback.call(currentElement, false);
		return;
	}

	var currentTriggerEventNumber = -1;

	function triggerExecutionHelper() {
		if (currentTriggerData.length <= 0) {
			callback.call(currentElement, false);
			return;
		}

		var currentTrigger = currentTriggerData.shift();
		currentTriggerEventNumber++;

		if (currentTrigger.targetAbility[0] === '_') {
			currentElement.error({ type: 'targetAbilityCanNotBePrivate' });
			triggerExecutionHelper();
			return;
		}

		currentElement._parent.getElement(
			currentTrigger.targetElement,
			function(err, targetElement) {
				if (err) {
					currentElement.error(err);
					triggerExecutionHelper();
					return;
				}

				if (
					targetElement[currentTrigger.targetAbility] === undefined ||
					targetElement[currentTrigger.targetAbility].apply ===
						undefined
				) {
					triggerExecutionHelper();
					return;
				}

				var currentMappingOrder = currentTrigger.targetOrder.slice();
				var currentArguments = [];

				function mappingValueExecutionHelper() {
					if (currentMappingOrder.length <= 0) {
						currentArguments.push(function(err) {
							triggerExecutionHelper();
						});

						targetElement[currentTrigger.targetAbility].apply(
							targetElement,
							currentArguments,
						);
						return;
					}

					var currentMappingValue = currentMappingOrder.shift();
					var currentMappingData =
						currentTrigger.mapping[currentMappingValue];

					if (
						currentMappingData === undefined ||
						currentMappingData.code === undefined
					) {
						triggerExecutionHelper();
						return;
					}

					var sandboxData = Object.assign(
						{},
						currentElement._properties,
					);
					sandboxData.target = targetElement._properties;

					codeEvalTest(currentMappingData.code, sandboxData).then(
						function(data) {
							currentArguments.push(data.data);
							mappingValueExecutionHelper();
						},
						function(err) {
							currentElement._parent.event(
								'triggerEventArgumentEvalError',
								{
									error: err,
									element: currentElement,
									mappingValue: currentMappingValue,
									mapping: currentMappingData,
									trigger: currentTrigger,
									sandbox: sandboxData,
									eventNumber: currentTriggerEventNumber,
									triggerName: triggerName,
								},
							);

							triggerExecutionHelper();

							return;
						},
					);
				}

				mappingValueExecutionHelper();
			},
		);
	}

	triggerExecutionHelper();
};
