//Copyright 2018 Atmosphere IoT Corp.
//All rights reserved

//jshint esversion: 6
function WidgetLogin(id, api, parentWidget, options) {
	var currentWidget = this;

	WidgetBase.call(this, id, api, parentWidget, options);

	this.loginFormId = this.generateChildId('loginForm');
	this.onlineStatusFlashId = this.generateChildId('onlineStatusFlashId');
	this.internetExplorerWarningId = this.generateChildId(
		'internetExplorerWarningId',
	);
	this.usernameInputId = this.generateChildId('usernameId');
	this.passwordInputId = this.generateChildId('passwordId');
	this.loginButtonId = this.generateChildId('loginId');
	this.forgotPasswordId = this.generateChildId('forgotPassword');
	this.reloadAppButtonId = this.generateChildId('reloadAppButtonId');
	this.flashId = this.generateChildId('flash');

	this.globalConfig = this.getMainContainer().getGlobalConfig() || {};

	this.context = {
		ids: {
			loginFormId: this.loginFormId,
			onlineStatusFlashId: this.onlineStatusFlashId,
			internetExplorerWarningId: this.internetExplorerWarningId,
			usernameInputId: this.usernameInputId,
			passwordInputId: this.passwordInputId,
			loginButtonId: this.loginButtonId,
			forgotPasswordId: this.forgotPasswordId,
			reloadAppButtonId: this.reloadAppButtonId,
			flashId: this.flashId,
		},
	};

	this.onlineStatus = null;

	this.context.language = passLanguageTagsToHandlebarsContext(
		this.language,
		this.context,
	);
	this.context.logoImageSrc = './loginLogo.svg';

	this.renderTemplate(this.context, WidgetLogin.name);

	this.$loginForm = $('#' + this.loginFormId);
	this.$onlineStatusFlash = $('#' + this.onlineStatusFlashId);
	this.$internetExplorerWarning = $('#' + this.internetExplorerWarningId);
	this.$usernameInput = $('#' + this.usernameInputId);
	this.$passwordInput = $('#' + this.passwordInputId);
	this.$loginButton = $('#' + this.loginButtonId);
	this.$forgotPassword = $('#' + this.forgotPasswordId);
	this.$reloadAppButton = $('#' + this.reloadAppButtonId);
	this.$flash = $(`#${this.flashId}`);

	this.$flash.hide();

	this.$onlineStatusFlash.hide();
	this.$internetExplorerWarning.hide();
	this.$reloadAppButton.hide();
	this.originalWindowOpen = window.open;
}

WidgetLogin.prototype = Object.create(WidgetBase.prototype);
WidgetLogin.prototype.constructor = WidgetLogin;

WidgetLogin.prototype.initialize = function(callback) {
	var currentWidget = this;

	if (isPhonegap() && (window.device || {}).platform === 'iOS') {
		window.open = function(url, target, options) {
			var instance = cordova.InAppBrowser.open(
				url,
				'_blank',
				'hidenavigationbuttons=yes,location=no',
			);

			instance.addEventListener('exit', function() {
				if (window.StatusBar) {
					setTimeout(function() {
						window.StatusBar.hide();
					}, 800);
				}
			});

			return instance;
		};
	}

	currentWidget.$reloadAppButton.click(function(e) {
		e.preventDefault();

		location.reload(true);
	});

	const api = (currentWidget.getApiV2() || {}).apis;

	//This is a better way to check if we are online or not
	//if the APIv2 Swagger Client didn't initialize then we
	//are certainly offline
	//Also if we didn't get to load google we need to reload the
	//app anyway
	if (!api || !google) {
		this.showOfflineStatusView();
		WidgetBase.prototype.initialize.call(this, callback);
		return;
	}

	//Check to see if we are already logged in
	api.auth
		.getCurrentUser()
		.then((userData) => {
			WidgetBase.prototype.initialize.call(currentWidget, function(err) {
				if (err) {
					callback.call(currentWidget, err);
					return;
				}

				callback.call(currentWidget, err);

				// We make sure we have the latest user context before changing location
				currentWidget.getMainContainer().handleInitGetUser(() => {
					currentWidget.getMainContainer().setLocation();
				});
			});
			return;
		})
		.catch((err) => {
			if (
				currentWidget.globalConfig &&
				currentWidget.globalConfig.WidgetLogin &&
				currentWidget.globalConfig.WidgetLogin.language &&
				currentWidget.globalConfig.WidgetLogin.language
			) {
				var langObject =
					currentWidget.globalConfig.WidgetLogin.language;

				if (isLanguageTagDefined(langObject, 'onLoginShown')) {
					currentWidget.$flash.html(
						getFromLanguageObject(langObject, 'onLoginShown'),
					);
					currentWidget.$flash.fadeIn('slow');
				}

				if (isLanguageTagDefined(langObject, 'onLoginShown')) {
					currentWidget.$loginButton
						.find('span')
						.html(
							getFromLanguageObject(
								langObject,
								'loginButtonLabel',
							),
						);
				}
			}

			if (
				currentWidget.globalConfig &&
				currentWidget.globalConfig.WidgetLogin &&
				currentWidget.globalConfig.WidgetLogin
					.forgotPasswordLinkVisible === false
			) {
				currentWidget.$forgotPassword.off();
				currentWidget.$forgotPassword.parent().hide();
			} else {
				currentWidget.$forgotPassword.click(function(e) {
					e.preventDefault();
					currentWidget.onForgotPasswordLinkClicked(e);
				});
			}

			if (
				currentWidget.globalConfig &&
				currentWidget.globalConfig.WidgetLogin &&
				currentWidget.globalConfig.WidgetLogin.loginLinkHref
			) {
				var loginLinkHref =
					currentWidget.globalConfig.WidgetLogin.loginLinkHref;

				currentWidget.$loginForm.find('label').remove();
				currentWidget.$loginForm.find('input').remove();

				function _doLoginProcess() {
					if (!isPhonegap()) {
						openLinkInCurrentWindowOrTab(loginLinkHref);
					} else if (
						cordova !== undefined &&
						cordova.InAppBrowser !== undefined
					) {
						_mainContainer.hide();

						var currentLoginRef = cordova.InAppBrowser.open(
							loginLinkHref,
							'_blank',
							'hidden=yes,clearcache=yes,clearsessioncache=yes,location=no',
						);

						window.iabLoginRef = currentLoginRef;

						function _loadStart(params) {
							//{"type":"loadstart","url":"https://nxptest.atmosphereiot.com/auth/saml/login"}
							//{"type":"loadstart","url":"https://www.nxp.com/idp-sso/profile/SAML2/Redirect/SSO?SAMLRequest=..."}
							//We have started loading back to the original site,
							//at this point hide the in app browser so people do
							//not see it as it's loading back up the page in the background.

							console.log('loadstart:' + JSON.stringify(params));

							if (
								params.type === 'loadstart' &&
								params.url.startsWith(getAPIBase()) &&
								!params.url.startsWith(loginLinkHref)
							) {
								currentLoginRef.hide();
							}
						}

						function _loadStop(params) {
							//{"type":"loadstop","url":"https://www.nxp.com/security/login?service=https%3A%2F%2Fwww.nxp.com%2Fidp-sso%2FAuthn%2FRemoteUser"}
							//{"type":"loadstop","url":"https://nxptest.atmosphereiot.com/"}
							//Have we loaded back to the orignial site?

							console.log('loadstop:' + JSON.stringify(params));

							if (
								params.type === 'loadstop' &&
								params.url.startsWith(getAPIBase()) &&
								!params.url.startsWith(loginLinkHref)
							) {
								//If so we need to hide it and then pull in the cookies from it
								//The inapp browser shares cookies with the internal one
								//So we should now be good to run so refresh the page.
								currentLoginRef.close();
							}
						}

						function _loadError(params) {
							console.log('loaderror:' + JSON.stringify(params));

							console.log(JSON.stringify(params));

							_mainContainer.show();
						}

						function _exit(params) {
							currentLoginRef.removeEventListener(
								'loadstart',
								_loadStart,
							);
							currentLoginRef.removeEventListener(
								'loadstop',
								_loadStop,
							);
							currentLoginRef.removeEventListener(
								'loaderror',
								_loadError,
							);
							currentLoginRef.removeEventListener('exit', _exit);

							console.log('exit:' + JSON.stringify(params));

							//The app doesn't know it has the new cookies until it reloads.

							location.reload(true);
						}

						currentLoginRef.addEventListener(
							'loadstart',
							_loadStart,
						);
						currentLoginRef.addEventListener('loadstop', _loadStop);
						currentLoginRef.addEventListener(
							'loaderror',
							_loadError,
						);
						currentLoginRef.addEventListener('exit', _exit);

						currentLoginRef.show();
					} else {
						console.trace();
						throw new Error('noInAppBrowserForRemoteAltAuthLogin');
					}
				}

				currentWidget.$passwordInput.on('keyup', function(e) {
					//Check both newschool and oldschool ways
					if (e.key === 'Enter' || e.keyCode === 13) {
						e.preventDefault();
						_doLoginProcess();
					}
				});

				currentWidget.$loginButton.off();

				currentWidget.$loginButton.on('click', function(e) {
					e.preventDefault();

					_doLoginProcess();
				});
			} else {
				currentWidget.$passwordInput.on('keyup', function(e) {
					//Check both newschool and oldschool ways
					if (e.key === 'Enter' || e.keyCode === 13) {
						e.preventDefault();
						currentWidget.onLoginFormSubmitted(e);
					}
				});

				currentWidget.$loginButton.on('click', function(e) {
					e.preventDefault();
					currentWidget.onLoginFormSubmitted(e);
				});
			}

			if (
				currentWidget.globalConfig &&
				currentWidget.globalConfig.WidgetLogin &&
				currentWidget.globalConfig.WidgetLogin.hideInputs
			) {
				currentWidget.hideInputs();
			}

			WidgetBase.prototype.initialize.call(this, callback);
		});
};

WidgetLogin.prototype.completeLogin = function() {
	const currentWidget = this;

	let forward = (getHashCommand() || {}).forward || {};
	let forwardLocation = forward.location;
	delete forward.location;

	currentWidget.getMainContainer().handleInitGetUser(() => {
		currentWidget.getMainContainer().setLocation(forwardLocation, forward);
	});
};

WidgetLogin.prototype.sendLoginData = function(username, password, callback) {
	var currentWidget = this;

	const loginData = { usernameOrEmail: username.trim(), password };
	const apiClient = currentWidget.getApiV2();
	apiClient.apis.auth
		.createLogin({}, { requestBody: loginData })
		.then((jwtData) => {
			apiClient.setJwt(new Jwt(jwtData));
			callback.call(currentWidget, false, jwtData);
		})
		.catch((err) => {
			callback.call(currentWidget, err);
		});
};

WidgetLogin.prototype.onLoginFormSubmitted = function(event) {
	var currentWidget = this;

	this.sendLoginData(
		this.$usernameInput.val(),
		this.$passwordInput.val(),
		function(err, data) {
			if (err) {
				if (err.status === 401) {
					this.getMainContainer().showPopupErrorMessage(
						getLanguageTag(this.constructor, 'accessDenied'),
					);
					return;
				} else if (err.status === 500) {
					this.getMainContainer().showPopupErrorMessage(
						getLanguageTag(this.constructor, 'internalServerError'),
					);
					return;
				} else if (err.type) {
					this.getMainContainer().showPopupErrorMessage(
						getLanguageTag(this.constructor, err.type),
					);
					return;
				} else {
					this.getMainContainer().showPopupErrorMessage(
						getLanguageTag(this.constructor, 'unknownError'),
					);
					return;
				}
			} else {
				this.completeLogin();
			}
		},
	);
};

WidgetLogin.prototype.sendChangePasswordRequest = function(username, callback) {
	var currentWidget = this;

	var url = getAPIBase() + '/auth/request_password_change';

	var data = {
		username: username,
	};

	// TODO: Move this into APIv2 and have swagger handle it properly
	// FIXME: We shouldn't be crafting requests outside the API handlers.
	if (!isPhonegap()) {
		$.ajax({
			url: url,
			type: 'POST',
			data: data,
			headers: { 'X-Client': 'Browser' },
			success: function(results) {
				callback.call(currentWidget, false, results);
			},
			error: function(results) {
				console.log(results);

				if (results.status == 403) {
					callback.call(
						currentWidget,
						{ type: 'accessDenied' },
						results,
					);
				} else {
					callback.call(
						currentWidget,
						{ type: 'serverError' },
						results,
					);
				}
			},
		});
	} else {
		const opts = {
			method: 'POST',
			body: JSON.stringify(data),
			timeout: undefined,
			serializer: 'json',
			responseType: 'json',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-Client': 'App',
			},
		};

		if (window._mainContainer) {
			const apiv2 = window._mainContainer._apiv2;
			const token = ((apiv2 || {}).jwt || {}).token || null;
			opts.headers.Authorization = `Bearer ${token}`;
		}

		console.debug(url, opts);

		cordovaFetch(url, opts)
			.then(function(res) {
				console.debug(res);
				callback.call(currentWidget, false, res._bodyText); //Do not try and parse it's not JSON
			})
			.catch(function(errRes) {
				if (errRes.status == 403) {
					callback.call(
						currentWidget,
						{ type: 'accessDenied' },
						errRes,
					);
				} else {
					callback.call(
						currentWidget,
						{ type: 'serverError' },
						errRes,
					);
				}
			});
	}
};

WidgetLogin.prototype.onForgotPasswordLinkClicked = function(event) {
	var currentWidget = this;

	if (
		this.$usernameInput.val() === '' ||
		this.$usernameInput.val() === undefined ||
		this.$usernameInput.val() === null
	) {
		this.getMainContainer().showPopupErrorMessage(
			getLanguageTag(this.constructor, 'pleaseEnterAUsernameOrEmail'),
		);
		return;
	}

	this.sendChangePasswordRequest(this.$usernameInput.val(), function(
		err,
		data,
	) {
		if (err) {
			this.getMainContainer().showPopupErrorMessage(
				getLanguageTag(this.constructor, 'noSuchAccountExists'),
			);
			return;
		}

		this.getMainContainer().showPopupInfoMessage(
			getLanguageTag(this.constructor, 'pleaseCheckYourEmail'),
		);
	});
};

WidgetLogin.prototype.checkForAndDisplayInternetExplorerMessage = function() {
	var currentWidget = this;

	if (isBrowserInternetExplorer() === true) {
		this.$internetExplorerWarning.fadeIn('slow');
	}
};

WidgetLogin.prototype.hideInputs = function() {
	this.$loginForm.find('label').hide();
	this.$loginForm.find('input').hide();
	this.$loginButton.attr('style', 'margin-top: 20px;');
};

WidgetLogin.prototype.showInputs = function() {
	if (
		this.globalConfig &&
		this.globalConfig.WidgetLogin &&
		this.globalConfig.WidgetLogin.hideInputs
	) {
		return;
	}

	this.$loginForm.find('label').show();
	this.$loginForm.find('input').show();
	this.$loginButton.removeAttr('style');
};

WidgetLogin.prototype.hideLoginButton = function() {
	this.$loginButton.hide();
};

WidgetLogin.prototype.showLoginButton = function() {
	this.$loginButton.show();
};

WidgetLogin.prototype.showOfflineStatusView = function() {
	var currentWidget = this;

	this.$onlineStatusFlash.text(
		getLanguageTag(
			WidgetLogin,
			'noConnectionDetectedPleaseRestartTheAppAndTryAgain',
		),
	);
	this.hideInputs();
	this.hideLoginButton();
	this.$forgotPassword.hide();
	this.$onlineStatusFlash.fadeIn('slow');
	this.$reloadAppButton.show();
};

WidgetLogin.prototype.hideOfflineStatusView = function() {
	var currentWidget = this;

	this.showInputs();
	this.showLoginButton();
	this.$forgotPassword.show();
	this.$reloadAppButton.hide();
	this.$onlineStatusFlash.fadeOut('slow');
};

WidgetLogin.prototype.remove = function(callback) {
	if (isPhonegap() && (window.device || {}).platform === 'iOS') {
		window.open = this.originalWindowOpen;
	}

	WidgetBase.prototype.remove.call(this, callback);
};

WidgetLogin.prototype.language = deepAssign({}, WidgetBase.prototype.language, {
	'en-US': {
		name: 'WidgetLogin',
		usernameLabel: 'Username / Email',
		usernamePlaceHolder: 'Username / Email',
		passwordLabel: 'Password',
		passwordPlaceHolder: 'Password',
		loginButtonLabel: 'Login',
		reloadAppButtonLabel: 'Reload App',
		accessDenied: 'Invalid username or password. Please try again.',
		internalServerError: 'Internal server error',
		unknownError: 'Unknown error',
		noSuchAccountExists: 'No such account exists',
		login: 'Login',
		pleaseEnterAUsernameOrEmail: 'Enter your account username or email',
		pleaseCheckYourEmail: 'Please check your email',
		forgotPasswordQuestion: 'Forgot Password?',
		signUpForAccess: 'Request a demo',
		noConnectionDetectedPleaseRestartTheAppAndTryAgain:
			'No connection detected. Restart the app and try again.',
		internetExplorerWarning:
			'Internet Explorer is not supported. We recommend using Chrome, Firefox, or Edge.',
		serverError: 'Unable to contact the server',
	},
});
