function ElementControllerAppBLEConnection(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	// 	this.addAbility('disconnect', [], [], false, false);
	// 	this.addAbility('connect', [], [], false, true);

	this.addTrigger('disconnected');
	this.addTrigger('connected');
}

ElementControllerAppBLEConnection.prototype = Object.create(
	ElementControllerBase.prototype,
);
ElementControllerAppBLEConnection.prototype.constructor = ElementControllerAppBLEConnection;

ElementControllerAppBLEConnection.prototype.NAME = 'BLEConnection';
ElementControllerAppBLEConnection.prototype.TYPE = 'AppBLEConnection';
ElementControllerAppBLEConnection.prototype.HIDDEN = false;
ElementControllerAppBLEConnection.prototype.CATEGORY = 'appBLE';
ElementControllerAppBLEConnection.prototype.VARIANTS = ['app'];
ElementControllerAppBLEConnection.prototype.DEFAULT_TRIGGER = 'connected';
ElementControllerAppBLEConnection.prototype.DEFAULT_ABILITY = 'trigger';
ElementControllerAppBLEConnection.prototype.DEFAULT_ARGUMENTS = [];
ElementControllerAppBLEConnection.prototype.COUPLED_TO = 'bleConnection';

ElementControllerAppBLEConnection.prototype.language = deepAssign(
	{},
	ElementControllerBase.prototype.language,
	{
		'en-US': {
			AppBLEConnection: 'BLE Connection',
			disconnect: 'Disconnect',
			connect: 'Connect',
			disconnected: 'Disconnected',
			connected: 'Connected',
		},
	},
);
