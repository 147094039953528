function WidgetDataGraph(id, api, parent, options) {
	var currentWidget = this;

	options = options || {};

	if (options.readOnly) {
		options.menuOptions = options.menuOptions || [
			{ label: this.getLanguageTag('help'), value: 'help' },
		];
	} else {
		options.menuOptions = options.menuOptions || [
			{ label: this.getLanguageTag('settings'), value: 'settings' },
			{
				label: this.getLanguageTag('rangeControl'),
				value: 'rangeControl',
			},
			{
				label: this.getLanguageTag('boundsControl'),
				value: 'boundsControl',
			},
			{
				label: this.getLanguageTag('colorsControl'),
				value: 'colorsControl',
			},
			{ label: this.getLanguageTag('help'), value: 'help' },
			{ label: this.getLanguageTag('remove'), value: 'remove' },
		];
	}

	options.gotoDevice = true;

	WidgetDashboardBase.call(this, id, api, parent, options);

	this.config = {
		deviceId: null,
		storageName: null,
		variable: null,
		title: this.getLanguageTag('name'),
		updateTime: 60000,
	};

	this._updateInterval = null;
	this._graphConfigured = false;
	this._showingSettings = false;

	this.dataGraphContainerId = this.generateChildId('dataGraphContainer');
}

WidgetDataGraph.prototype = Object.create(WidgetDashboardBase.prototype);
WidgetDataGraph.prototype.constructor = WidgetDataGraph;

WidgetDataGraph.prototype.showRangeControl = function(callback) {
	callback = callback || function() {};

	var currentWidget = this;

	if (!this.config.deviceId || !this.config.storageName) {
		this.getMainContainer().showPopupErrorMessage(
			this.getLanguageTag('youNeedToSetupFirst'),
		);
		callback.call(this, false);
		return;
	}

	var settingsFields = [
		{
			name: 'dateRange',
			type: 'select',
			label: this.getLanguageTag('dateRange'),
			options: [
				{ value: 600, label: this.getLanguageTag('lastTenMinutes') },
				{ value: 60 * 60, label: this.getLanguageTag('lastOneHour') },
				{
					value: 60 * 60 * 2,
					label: this.getLanguageTag('last2Hours'),
				},
				{
					value: 60 * 60 * 6,
					label: this.getLanguageTag('last6Hours'),
				},
				{
					value: 60 * 60 * 12,
					label: this.getLanguageTag('last12Hours'),
				},
				{
					value: 60 * 60 * 24,
					label: this.getLanguageTag('last24Hours'),
				},
				{
					value: (60 * 60 * 24) * 2,
					label: this.getLanguageTag('last48Hours'),
				},
				{
					value: 60 * 60 * 24 * 7,
					label: this.getLanguageTag('last7Days'),
				},
				{
					value: 60 * 60 * 24 * 30,
					label: this.getLanguageTag('last30Days'),
				},
				{
					value: 60 * 60 * 24 * 90,
					label: this.getLanguageTag('last90Days'),
				},
				{
					value: 60 * 60 * 24 * 120,
					label: this.getLanguageTag('last120Days'),
				},
				{
					value: 'customRange',
					label: this.getLanguageTag('customRange'),
				},
			],
			value: this.config.dateRange || 600,
		},

		{
			name: 'customRange',
			type: 'dateRangePicker',
			label: this.getLanguageTag('customRange'),
			value: {
				startDate: new Date((this.config.customRange || {}).startDate),
				endDate: new Date((this.config.customRange || {}).endDate),
			},
		},
	];

	this.getMainContainer().setModalWidget(
		WidgetSettingsForm,
		{ fields: settingsFields },
		function(err, settingsWidget) {
			settingsWidget.setTitle(
				currentWidget.getLanguageTag('dateRangeControl'),
			);

			settingsWidget.addEventListener('dismissed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;
			});

			settingsWidget.addEventListener('valueChanged', function(data) {
				if (data.name === 'customRange') {
					currentWidget.config.dateRange = 'customRange';
					currentWidget.config.customRange = {
						startDate: data.value.startDate.toISOString(),
						endDate: data.value.endDate.toISOString(),
					};

					currentWidget.showRangeControl();
				}
			});

			settingsWidget.addEventListener('confirmed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;

				var values = this.getValues();

				currentWidget.config.dateRange = values.dateRange || 600;
				currentWidget.config.customRange = {
					startDate: values.customRange.startDate.toISOString(),
					endDate: values.customRange.endDate.toISOString(),
				};

				currentWidget.setConfiguration(
					currentWidget.config,
					function() {
						this.event('configurationSet', {
							widget: this,
							configuration: this.config,
						});
					},
				);
			});

			currentWidget._showingSettings = true;
			this.showModal();

			callback.call(this, false);
			return;
		},
	);
};

WidgetDataGraph.prototype.showBoundsControl = function(callback) {
	callback = callback || function() {};

	var currentWidget = this;

	var settingsFields = [];

	if (!this.config.deviceId || !this.config.storageName) {
		this.getMainContainer().showPopupErrorMessage(
			this.getLanguageTag('youNeedToSetupFirst'),
		);
		callback.call(this, false);
		return;
	}

	for (var i = 0; i < this.config.yVariables.length; i++) {
		var currentBonds = (this.config.yBounds || {})[
			this.config.yVariables[i]
		] || { min: null, max: null };

		settingsFields.push({
			name: this.config.yVariables[i],
			type: 'bounds',
			label: this.config.yVariables[i],
			value: currentBonds,
		});
	}

	this.getMainContainer().setModalWidget(
		WidgetSettingsForm,
		{ fields: settingsFields },
		function(err, settingsWidget) {
			settingsWidget.setTitle(
				currentWidget.getLanguageTag('boundsControl'),
			);

			settingsWidget.addEventListener('dismissed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;
			});

			settingsWidget.addEventListener('confirmed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;

				currentWidget.config.yBounds = this.getValues();

				currentWidget.setConfiguration(
					currentWidget.config,
					function() {
						this.event('configurationSet', {
							widget: this,
							configuration: this.config,
						});
					},
				);
			});

			currentWidget._showingSettings = true;
			this.showModal();

			callback.call(this, false);
			return;
		},
	);
};

WidgetDataGraph.prototype.showColorsControl = function(callback) {
	callback = callback || function() {};

	var currentWidget = this;

	var settingsFields = [];

	if (!this.config.deviceId || !this.config.storageName) {
		this.getMainContainer().showPopupErrorMessage(
			this.getLanguageTag('youNeedToSetupFirst'),
		);
		callback.call(this, false);
		return;
	}

	for (var i = 0; i < this.config.yVariables.length; i++) {
		var startingColor = getComputedStyle(
			window.document.body,
		).getPropertyValue(
			currentWidget.GRAPH_COLOR_WHEEL[
				i % currentWidget.GRAPH_COLOR_WHEEL.length
			],
		);

		var currentColor =
			(this.config.lineColors || {})[this.config.yVariables[i]] ||
			startingColor;

		settingsFields.push({
			name: this.config.yVariables[i],
			type: 'colorSwatch',
			label: this.config.yVariables[i],
			value: currentColor,
			options: { hideUnset: true },
		});
	}

	this.getMainContainer().setModalWidget(
		WidgetSettingsForm,
		{ fields: settingsFields },
		function(err, settingsWidget) {
			settingsWidget.setTitle(
				currentWidget.getLanguageTag('colorsControl'),
			);

			settingsWidget.addEventListener('dismissed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;
			});

			settingsWidget.addEventListener('confirmed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;

				currentWidget.config.lineColors = this.getValues();

				currentWidget.setConfiguration(
					currentWidget.config,
					function() {
						this.event('configurationSet', {
							widget: this,
							configuration: this.config,
						});
					},
				);
			});

			currentWidget._showingSettings = true;
			this.showModal();

			callback.call(this, false);
			return;
		},
	);
};

WidgetDataGraph.prototype.showSettings = function(callback) {
	callback = callback || function() {};

	var currentWidget = this;

	this.getMainContainer().setModalWidget(
		WidgetSettingsForm,
		{
			fields: [
				{
					name: 'title',
					type: 'text',
					label: this.getLanguageTag('title'),
					value: this.config.title,
				},

				{
					name: 'updateTime',
					type: 'select',
					label: this.getLanguageTag('updateTime'),
					options: [
						{ value: 60000, label: this.getLanguageTag('1Minute') },
						{
							value: 300000,
							label: this.getLanguageTag('5Minutes'),
						},
						{
							value: 600000,
							label: this.getLanguageTag('10Minutes'),
						},
						{
							value: 900000,
							label: this.getLanguageTag('15Minutes'),
						},
						{
							value: 1800000,
							label: this.getLanguageTag('30Minutes'),
						},
						{ value: 3600000, label: this.getLanguageTag('1Hour') },
						{
							value: 7200000,
							label: this.getLanguageTag('2Hours'),
						},
						{
							value: 14400000,
							label: this.getLanguageTag('4Hours'),
						},
						{
							value: 21600000,
							label: this.getLanguageTag('6Hours'),
						},
						{
							value: 28800000,
							label: this.getLanguageTag('8Hours'),
						},
						{
							value: 43200000,
							label: this.getLanguageTag('12Hours'),
						},
					],
					value: this.config.updateTime,
				},

				{
					name: 'variable',
					type: 'deviceVariableSelect',
					label: this.getLanguageTag('variable'),
					value: {
						deviceId: this.config.deviceId,
						storageName: this.config.storageName,
						variables: { value: this.config.variable },
					},
					options: {
						variables: [
							{
								name: 'xVariable',
								label: this.getLanguageTag('xVariable'),
							},

							{
								name: 'yVariables',
								selectMultiple: true,
								label: this.getLanguageTag('yVariables'),
							},
						],
					},
				},
			],
		},
		function(err, settingsWidget) {
			settingsWidget.setTitle(
				getLanguageTag(currentWidget.constructor, 'configureDataGraph'),
			);

			settingsWidget.addEventListener('dismissed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;
			});

			settingsWidget.addEventListener('confirmed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;

				values = this.getValues();
				values.variable = values.variable || {};

				// FIXME: If yBounds are set then go through and clean out any entries that are no longer in use

				var config = {
					title: values.title,
					deviceId: (values.variable.deviceData || {}).id || null,
					storageName: values.variable.storageName || null,
					xVariable:
						(values.variable.variables || {}).xVariable || null,
					yVariables:
						(values.variable.variables || {}).yVariables || null,
					yBounds: currentWidget.config.yBounds || null,
					dateRange: currentWidget.config.dateRange || 600,
					updateTime: values.updateTime || 60000,
				};

				currentWidget.setConfiguration(config, function() {
					this.event('configurationSet', {
						widget: this,
						configuration: this.config,
					});
				});
			});

			currentWidget._showingSettings = true;
			this.showModal();

			callback.call(this, false);
			return;
		},
	);
};

WidgetDataGraph.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.renderTemplate(
		{
			dataGraphContainerId: this.dataGraphContainerId,
		},
		WidgetDataGraph.name,
	);

	this.eChart = echarts.init(
		document.getElementById(this.dataGraphContainerId),
	);

	this._resizeEventHandler = function() {
		currentWidget.eChart.resize();
	};

	$(window).resize(this._resizeEventHandler);

	this._interval();

	this.addEventListener('optionSelected', function(option) {
		if (option === 'boundsControl') {
			this.showBoundsControl();
		} else if (option === 'colorsControl') {
			this.showColorsControl();
		} else if (option === 'rangeControl') {
			this.showRangeControl();
		}
	});

	WidgetDashboardBase.prototype.initialize.call(this, callback);
};

WidgetDataGraph.prototype._interval = function() {
	var currentWidget = this;

	currentWidget.update(function() {
		if (currentWidget.config.updateTime < 60000) {
			currentWidget.config.updateTime = 60000;
		}

		this._updateInterval = setTimeout(function() {
			currentWidget._interval();
		}, currentWidget.config.updateTime || 60000);
	});
};

WidgetDataGraph.prototype.remove = function(callback) {
	$(window).off('resize', this._resizeEventHandler);

	this.eChart.dispose();

	if (this._updateInterval) {
		clearTimeout(this._updateInterval);
	}

	if (this._showingSettings) {
		this.getMainContainer().hideModal();
	}

	WidgetDashboardBase.prototype.remove.call(this, callback);
};

// Gets 1 page of 10000 points
// returned via the provided query to the v2 storage API.
WidgetDataGraph.prototype.dataBy = async function(query) {
	const api = this.getApiV2();
	const response = await api.apis.data.getDeviceData(query);
	return response.data;
};

WidgetDataGraph.prototype.update = function(callback) {
	callback = callback || function() {};
	const currentWidget = this;
	const api = currentWidget.getApiV2();

	// Check for mandatory API params
	if (!this.config.deviceId || !this.config.storageName) {
		console.warn(
			`[${currentWidget.constructor.name}] Unable to update() due to missing API params!`,
		);
		callback.call(this, false);
		return;
	}

	// Set panel title to whatever was configured
	this.setTitle(this.config.title);

	// define start and end dates
	let startDate = null;
	let endDate = null;

	// check for configured date range and set reasonable defaults
	const isCustomRange = currentWidget.config.dateRange === 'customRange';
	if (isCustomRange) {
		startDate = new Date(currentWidget.config.customRange.startDate);
		endDate = new Date(currentWidget.config.customRange.endDate);
	} else {
		startDate = new Date(
			Date.now() - currentWidget.config.dateRange * 1000,
		);
		endDate = new Date(Date.now());
	}

	// build the query to get the last values and datatypes
	let dataTypes;
	const lastValQuery = {
		id: this.config.deviceId,
		elementName: this.config.storageName,
	};

	// build the query for the data itself
	const storageQuery = {
		id: this.config.deviceId,
		elementName: this.config.storageName,
		page: 1,
		limit: 10000,
		from: startDate.toISOString(),
		to: endDate.toISOString(),
	};

	// make the API call to get lastValue to infer the datatypes
	api.apis.data
		.getLastDeviceData(lastValQuery)
		.then((response) => {
			const { values } = response;
			/* What values might look like...
				{
					"updatedAt": "2020-11-16T18:04:59.596Z",
					"deviceId": "5fb2bca55ef8632bdf807ed9",
					"elementName": "CloudStorage",
					"values": {
						"temperature": 46,
						"pressure": 62,
						"humidity": 85,
						"voltage": 83,
						"tamperSwitch": false,
						"firmwareTime": "2020-11-16T18:04:59.554Z",
						"message": "temperature within range"
					}
				}
			*/
			// UI expects the _timestamp field, what should be used as the _timestamp?
			values._timestamp = response.updatedAt;
			dataTypes = DataTypes.dataTypesFrom(JSON.stringify(values));

			return currentWidget.dataBy(storageQuery);
		})
		.then((storedValues) => {
			const graphData = storedValues.map((storedValue) => {
				const obj = storedValue.payload;
				obj._timestamp = storedValue.createdAt;
				return obj;
			});

			if (!dataTypes) {
				callback.call(currentWidget, { type: 'noData' });
				return;
			}

			// create graphSeries, graphLegend, graphXAxis, graphYAxis config for echarts
			var graphSeries = [];
			var graphLegend = [];
			var graphXAxis = [];
			var graphYAxis = [];

			var currentVariables = [];

			// Build E-Charts X-Axis config
			var currentGraphXAxis = {
				type:
					currentWidget.DATA_TYPE_TO_CHART[
						dataTypes[currentWidget.config.xVariable]
					] || 'value',
				boundaryGap: false,
				min: startDate,
				max: endDate,
				axisLabel: {},
				axisLabel: {
					fontSize: 10,
				},
			};
			if (currentGraphXAxis.type === 'time') {
				currentGraphXAxis.axisLabel.formatter = function(value, index) {
					return `${new Date(value).toLocaleDateString()}\n${new Date(
						value,
					).toLocaleTimeString()}`;
				};
			}
			graphXAxis.push(currentGraphXAxis);

			// Build E-Charts Y-Axis Config
			for (var i = 0; i < currentWidget.config.yVariables.length; i++) {
				graphLegend.push(currentWidget.config.yVariables[i]);

				var currentLineColor =
					(currentWidget.config.lineColors || {})[
						currentWidget.config.yVariables[i]
					] ||
					currentWidget.GRAPH_COLOR_WHEEL[
						i % currentWidget.GRAPH_COLOR_WHEEL.length
					];

				if (currentLineColor === 'unset') {
					currentLineColor =
						currentWidget.GRAPH_COLOR_WHEEL[
							i % currentWidget.GRAPH_COLOR_WHEEL.length
						];
				}

				currentLineColor = getComputedStyle(
					window.document.body,
				).getPropertyValue(`--${currentLineColor}`);

				var currentSeries = {
					name: currentWidget.config.yVariables[i],
					type: 'line',
					data: [],
					xAxisIndex: 0,
					lineStyle: {
						color: currentLineColor,
					},

					itemStyle: {
						color: currentLineColor,
					},
				};

				currentVariables.push(currentWidget.config.yVariables[i]);

				currentSeries.yAxisIndex = i;

				if (i < 2) {
					graphYAxis.push({
						name: currentWidget.config.yVariables[i],
						type:
							currentWidget.DATA_TYPE_TO_CHART[
								dataTypes[currentWidget.config.yVariables[i]]
							] || 'value',
						min: (
							(currentWidget.config.yBounds || {})[
								currentWidget.config.yVariables[i]
							] || {}
						).min,
						max: (
							(currentWidget.config.yBounds || {})[
								currentWidget.config.yVariables[i]
							] || {}
						).max,
						nameTextStyle: {
							padding: [0, 0, 0, 24],
						},
					});
				} else {
					graphYAxis.push({
						name: '',
						type:
							currentWidget.DATA_TYPE_TO_CHART[
								dataTypes[currentWidget.config.yVariables[i]]
							] || 'value',
						min: (
							(currentWidget.config.yBounds || {})[
								currentWidget.config.yVariables[i]
							] || {}
						).min,
						max: (
							(currentWidget.config.yBounds || {})[
								currentWidget.config.yVariables[i]
							] || {}
						).max,

						axisLabel: {
							show: false,
						},
					});
				}

				graphSeries.push(currentSeries);
			}

			// Format API data into format E-charts can consume
			for (var i = 0, len = graphData.length; i < len; i++) {
				for (var j = 0, oLen = currentVariables.length; j < oLen; j++) {
					var xValue = graphData[i][currentWidget.config.xVariable];
					var yValue = graphData[i][currentVariables[j]];

					if (
						xValue !== null &&
						xValue !== undefined &&
						yValue !== null &&
						yValue !== undefined
					) {
						graphSeries[j].data.push([xValue, yValue]);
					}
				}
			}

			// Render data
			if (!currentWidget._graphConfigured) {
				currentWidget.eChart.clear();
				currentWidget._graphConfigured = true;

				if (
					graphLegend.length === 0 ||
					graphXAxis.length === 0 ||
					graphYAxis.length === 0 ||
					graphSeries.length === 0
				) {
					callback.call(currentWidget, false);
					return;
				}

				currentWidget.eChart.setOption({
					tooltip: {
						mode: 'label',
						confine: true,
						formatter: function(params) {
							if (
								dataTypes[currentWidget.config.xVariable] ===
								'Date'
							) {
								return `${params.value[1]}<br\>${new Date(
									params.value[0],
								).toLocaleDateString()} ${new Date(
									params.value[0],
								).toLocaleTimeString()}`;
							} else {
								return `${params.value[1]}<br\>${params.value[0]}`;
							}
						},
					},
					dataZoom: [
						{
							type: 'slider',
							filterMode: 'none',
							start: 0,
							end: 100,
							bottom: 4,
							height: '26px',
							show: !isPhonegap(),
						},
					],
					title: {
						show: false,
					},
					legend: {
						data: graphLegend,
					},
					xAxis: graphXAxis,
					yAxis: graphYAxis,
					series: graphSeries,
				});
			} else {
				currentWidget.eChart.setOption({
					series: graphSeries,
					xAxis: graphXAxis,
				});
			}

			// Signal end of update
			callback.call(currentWidget, false);
			return;
		})
		.catch((error) => {
			if (error) {
				console.warn(
					`[${currentWidget.constructor.name}]: Error while calling .update()!`,
				);
				console.error(error);
				callback.call(currentWidget, error);
				return;
			}
		});
};

WidgetDataGraph.prototype.setConfiguration = function(config, callback) {
	this.config = {
		deviceId: config.deviceId || null,
		storageName: config.storageName || null,
		xVariable: config.xVariable || this.config.xVariable || null,
		yVariables: config.yVariables || this.config.yVariables || [],
		title: config.title || this.getLanguageTag('name'),
		yBounds: config.yBounds || this.config.yBounds || null,
		lineColors: config.lineColors || this.config.lineColors || null,
		dateRange: config.dateRange || this.config.dataRange || 600,
		customRange: config.customRange || this.config.customRange || null,
		updateTime: config.updateTime || 60000,
	};

	const currentWidget = this;

	this._graphConfigured = false;

	if (this._updateInterval) {
		clearTimeout(this._updateInterval);
	}

	const api = currentWidget.getApiV2().apis;
	api.devices
		.getDevice({ id: config.deviceId })
		.then((device) => {
			currentWidget.deviceUuid = device.uuid;

			currentWidget.update(function(err) {
				currentWidget._interval();
				callback.call(currentWidget, err);
			});
		})
		.catch((err) => {
			console.warn(
				`[${currentWidget.constructor.name}]: Error while calling .setConfiguration()!`,
			);
			console.error(err);
			callback.call(currentWidget, err);
			return;
		});
};

WidgetDataGraph.prototype.getConfiguration = function() {
	return this.config;
};

WidgetDataGraph.prototype.PACKERY_SIZE = 'WidgetDashboard_Container_XLxM';

WidgetDataGraph.prototype.DATA_TYPE_TO_CHART = {
	Number: 'value',
	Date: 'time',
	ObjectID: 'value',
	String: 'value',
};

WidgetDataGraph.prototype.GRAPH_COLOR_WHEEL = [
	'red',
	'orange',
	'yellow',
	'green',
	'blue',
	'purple',
	'pink',
	'gray',
	'teal',
];

WidgetDataGraph.prototype.language = deepAssign(
	{},
	WidgetDashboardBase.prototype.language,
	{
		'en-US': {
			name: 'Data Graph',
			title: 'Title',
			selectADevice: 'Select a Device',
			boundsControl: 'Bounds',
			colorsControl: 'Colors',
			variable: 'Data Source',
			xVariable: 'Select X-axis Value',
			yVariables: 'Select Y-axis Value(s)',
			rangeControl: 'Time Range',
			configureDataGraph: 'Configure Data Graph',
			dateRangeControl: 'Time Range',
			youNeedToSetupFirst: 'The graph must be configured first',
			dateRange: 'Date Range',
			lastTenMinutes: 'Last 10 Minutes',
			lastOneHour: 'Last Hour',
			last2Hours: 'Last 2 Hours',
			last6Hours: 'Last 6 Hours',
			last12Hours: 'Last 12 Hours',
			last24Hours: 'Last 24 Hours',
			last48Hours: 'Last 48 Hours',
			last7Days: 'Last 7 Days',
			last30Days: 'Last 30 Days',
			last90Days: 'Last 90 Days',
			last120Days: 'Last 120 Days',
			customRange: 'Custom Range',
			updateTime: 'Update Interval',
			'1Minute': '1 Minute',
			'5Minutes': '5 Minutes',
			'10Minutes': '10 Minutes',
			'15Minutes': '15 Minutes',
			'30Minutes': '30 Minutes',
			'1Hour': '1 Hour',
			'2Hours': '2 Hours',
			'4Hours': '4 Hours',
			'6Hours': '6 Hours',
			'8Hours': '8 Hours',
			'12Hours': '12 Hours',
		},
	},
);

WidgetDataGraph.prototype.$_$ = function(done) {
	this.$_SetupWidgetTest(function(err, currentWidget) {
		WidgetDashboardBase.prototype.$_$.call(this, done);
	});
};
