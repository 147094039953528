function PluginWifi() {
	PluginWifi.prototype.SELECTED_BACKEND.call(this);
}

PluginWifi.prototype.SELECT_BACKEND = function() {
	var SELECT_BACKEND = PluginWifi.prototype.SELECT_BACKEND;

	//We've already loaded and don't need to do this again.
	if (PluginWifi.prototype.BACKENDS === undefined) {
		return WifiBackendNull;
	}

	for (var i = 0; i < PluginWifi.prototype.BACKENDS.length; i++) {
		if (PluginWifi.prototype.BACKENDS[i].prototype.IS_AVAILABLE()) {
			var SELECTED_BACKEND = PluginWifi.prototype.BACKENDS[i];
			var BACKENDS = PluginWifi.prototype.BACKENDS;

			console.log('PluginWifi: Using ' + SELECTED_BACKEND.name);

			PluginWifi.prototype = Object.create(SELECTED_BACKEND.prototype);
			PluginWifi.prototype.constructor = PluginWifi;
			PluginWifi.prototype.SELECT_BACKEND = SELECT_BACKEND;
			PluginWifi.prototype.SELECTED_BACKEND = SELECTED_BACKEND;
			PluginWifi.prototype.BACKENDS = BACKENDS;

			return SELECTED_BACKEND;
			break;
		}
	}

	return WifiBackendNull;
};

//This is the order for which we will see what plugins are available for use.
PluginWifi.prototype.BACKENDS = [
	WifiBackendPhonegap,
	WifiBackendClientAgent,
	WifiBackendNull,
];
