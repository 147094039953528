function AppViewPanel(root) {
	CrocPanel.call(this, root);

	this.showDrawGuides = false;

	this.showGrid = false;
	this.gridSize = '10px';

	this.horizontalDrawGuide = null;
	this.verticleDrawGuide = null;
}

AppViewPanel.prototype = Object.create(CrocPanel.prototype);
AppViewPanel.prototype.constructor = AppViewPanel;

AppViewPanel.prototype.setShowGrid = function(show) {
	this.showGrid = Boolean(show);
	this.getRoot().repaint();
};

AppViewPanel.prototype.setGridSize = function(gridSize) {
	if (!this.convertToPixels(gridSize, 0) > 0) {
		return;
	}

	this.gridSize = gridSize;
	this.getRoot().repaint();
};

AppViewPanel.prototype.getGridSize = function() {
	return this.convertToPixels(this.gridSize, 0);
};

AppViewPanel.prototype.changeGridSizeBy = function(amount) {
	var currentGridSize = this.convertToPixels(this.gridSize, 0);
	this.setGridSize(currentGridSize + amount);
	return;
};

AppViewPanel.prototype.setShowDrawGuides = function(show) {
	this.showDrawGuides = Boolean(show);

	if (this.showDrawGuides === false) {
		this.horizontalDrawGuide = null;
		this.verticleDrawGuide = null;
	}

	this.getRoot().repaint();
};

AppViewPanel.prototype.setDrawGuides = function(horizontal, verticle) {
	this.horizontalDrawGuide = horizontal;
	this.verticleDrawGuide = verticle;
	this.getRoot().repaint();
};

AppViewPanel.prototype.paint = function(context, width, height) {
	CrocPanel.prototype.paint.call(this, context, width, height);

	if (this.showGrid) {
		context.save();

		var currentGridSize = this.convertToPixels(this.gridSize, 0);

		var step = currentGridSize;
		context.strokeStyle = 'lightBlue';

		var i = 0;

		for (i = 0; i < width / step; i++) {
			context.beginPath();
			context.moveTo(i * step, 0);
			context.lineTo(i * step, height);
			context.stroke();
		}

		for (i = 0; i < height / step; i++) {
			context.beginPath();
			context.moveTo(0, i * step);
			context.lineTo(width, i * step);
			context.stroke();
		}

		context.restore();
	}

	if (this.showDrawGuides) {
		context.save();

		var currentHorizontalGuide = this.horizontalDrawGuide;
		var currentVerticleGuide = this.verticleDrawGuide;

		if (currentHorizontalGuide !== null) {
			context.beginPath();
			context.setLineDash([5, 15]);
			context.moveTo(0, currentHorizontalGuide.position);
			context.lineTo(width, currentHorizontalGuide.position);
			context.strokeStyle = '#ff0000';
			context.stroke();
		}

		if (currentVerticleGuide !== null) {
			context.beginPath();
			context.setLineDash([5, 15]);
			context.moveTo(currentVerticleGuide.position, 0);
			context.lineTo(currentVerticleGuide.position, height);
			context.strokeStyle = '#ff0000';
			context.stroke();
		}

		context.restore();
	}
};
