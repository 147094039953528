function WidgetUserAccountSecurityAuthenticationTokens(
	id,
	api,
	parent,
	options,
) {
	WidgetBase.call(this, id, api, parent, options);

	this._tableData = [];
	this._tokensTable = null;

	// ID Assignment
	this.generateTokenId = this.generateChildId('generateTokenId');
	this.tokensTableContainerId = this.generateChildId(
		'tokensTableContainerId',
	);
}

WidgetUserAccountSecurityAuthenticationTokens.prototype = Object.create(
	WidgetBase.prototype,
);
WidgetUserAccountSecurityAuthenticationTokens.prototype.constructor = WidgetUserAccountSecurityAuthenticationTokens;

WidgetUserAccountSecurityAuthenticationTokens.prototype.initialize = function(
	callback,
) {
	var currentWidget = this;

	this.render(function(err, data) {
		var tokenTableOptions = { hideFilterInput: true };

		this.addChildWidget(
			WidgetUserAccountSecurityAuthenticationTokensTable,
			this.tokensTableContainerId,
			tokenTableOptions,
			function(err, tokensTable) {
				currentWidget._tokensTable = tokensTable;

				this.update(function(err, data) {
					WidgetBase.prototype.initialize.call(this, callback);
				});
			},
		);
	});
};

WidgetUserAccountSecurityAuthenticationTokens.prototype.update = function(
	callback,
) {
	var currentWidget = this;

	this.getTokensViaApi(function(err, tokens) {
		if (err) {
			callback.call(currentWidget, err);
			return;
		}

		/* 		
			[{
				"_username": "Demo",
				"apiToken": "7dc10e47-f856-430e-804f-69badb216624",
				"created": "2019-01-24T19:46:49.340Z"
			}]
		*/

		tableData = [];

		tokens = tokens || [];

		for (var i = 0; i < tokens.length; i++) {
			var currentTokenData = tokens[i];

			tableData.push({
				token: currentTokenData.apiToken,
				options: {
					viewButtonIconSrc: './Resources/icons/Show.svg',
					deleteButtonIconSrc: './Resources/icons/Trash.svg',
				},
			});
		}

		this._tokensTable.setTable(
			['token', 'options'],

			{
				token: {
					label: getLanguageTag(this.constructor, 'token'),
					columnWidth: 1.0,
					formatter: currentWidget.tokenFormatter,
					sortable: false,
				},
				options: {
					label: getLanguageTag(this.constructor, 'options'),
					columnWidth: 0.0,
					formatter: currentWidget.optionsFormatter,
					sortable: false,
				},
			},

			tableData,
			'descending',
			'token',
		);

		// $(`.WidgetUserAccountSecurityAuthenticationTokensViewButton-IconContainer-Top`).off();
		// $(`.WidgetUserAccountSecurityAuthenticationTokensDeleteButton-IconContainer-Top`).off();
		// $(`.WidgetUserAccountSecurityAuthenticationTokensViewButton-IconContainer-Top`).hide();

		$(
			`.WidgetUserAccountSecurityAuthenticationTokensViewButton-IconContainer-Top`,
		).on('click', function(e) {
			var tokenId = $(this).data('tokenid');
			currentWidget.onViewButtonClicked(e, tokenId);
		});

		$(
			`.WidgetUserAccountSecurityAuthenticationTokensDeleteButton-IconContainer-Top`,
		).on('click', function(e) {
			var tokenId = $(this).data('tokenid');
			currentWidget.onDeleteButtonClicked(e, tokenId);
		});

		this._tableData = tableData;

		callback.call(this, false, tableData);
		return;
	});
};

WidgetUserAccountSecurityAuthenticationTokens.prototype.tokenFormatter = function(
	value,
	rowData,
) {
	//value in this case is the token uuid
	return `<div id="${value}" class="WidgetUserAccountSecurityAuthenticationTokens-TokenContainer">********************************</div>`;
};

WidgetUserAccountSecurityAuthenticationTokens.prototype.optionsFormatter = function(
	value,
	rowData,
) {
	var currentWidget = this;

	var tokenId = rowData.token;

	var viewButtonIconSrc = value.viewButtonIconSrc;
	var viewButtonTooltip = getLanguageTag(
		this.constructor,
		'viewButtonTooltip',
	);

	var deleteButtonIconSrc = value.deleteButtonIconSrc;
	var deleteButtonTooltip = getLanguageTag(
		this.constructor,
		'deleteButtonTooltip',
	);

	var htmlBuffer = `<div class="WidgetUserAccountSecurityAuthenticationTokens-OptionsContainer">`;

	htmlBuffer += `
		<div class="WidgetUserAccountSecurityAuthenticationTokensViewButton-IconContainer-Top" data-tokenid="${tokenId}">
			<div class="WidgetUserAccountSecurityAuthenticationTokensViewButton-IconContainer">
				<img src="${viewButtonIconSrc}" class="_svg-inject WidgetUserAccountSecurityAuthenticationTokensViewButton-Icon" title="${getLanguageTag(
		this.getParent().constructor,
		'showToken',
	)}"/>
			</div>
		</div>`;

	htmlBuffer += `
		<div class="WidgetUserAccountSecurityAuthenticationTokensDeleteButton-IconContainer-Top" data-tokenid="${tokenId}"">
			<div class="WidgetUserAccountSecurityAuthenticationTokensDeleteButton-IconContainer">
				<img src="${deleteButtonIconSrc}" class="_svg-inject WidgetUserAccountSecurityAuthenticationTokensDeleteButton-Icon" title="${getLanguageTag(
		this.getParent().constructor,
		'removeToken',
	)}"/>
			</div>
		</div>`;

	htmlBuffer += `</div>`;

	return htmlBuffer;
};

WidgetUserAccountSecurityAuthenticationTokens.prototype.getTemplateContext = function(
	callback,
) {
	var currentWidget = this;

	callback = callback || function() {};

	var context = {
		bottomLabelLink:
			this.getConfiguration().bottomLabelLink ||
			'https://developer.atmosphereiot.com/documents/account/accountmanagement.html#api-tokens',
	};

	context.language = {
		authenticationTokens: getLanguageTag(
			this.constructor,
			'authenticationTokens',
		),
		generateToken: getLanguageTag(this.constructor, 'generateToken'),
		topLabel: getLanguageTag(this.constructor, 'topLabel'),
		bottomLabel: formatString(
			getLanguageTag(this.constructor, 'bottomLabel'),
			context.bottomLabelLink,
		),
	};

	context.rootIds = {
		generateTokenId: this.generateTokenId,
		tokensTableContainerId: this.tokensTableContainerId,
	};

	callback.call(this, false, context);
	return context;
};

WidgetUserAccountSecurityAuthenticationTokens.prototype.render = function(
	callback,
) {
	var currentWidget = this;

	callback = callback || function() {};

	var context = this.getTemplateContext(function() {});
	var templateName = this.constructor.name;
	var container = this.getContainer();

	this.renderTemplate(context, templateName, container);

	this.$generateToken = $(`#${this.generateTokenId}`);

	this.$generateToken.off();

	this.$generateToken.on('click', function(e) {
		e.preventDefault();
		currentWidget.onGenerateTokenClicked(e);
	});

	callback.call(this, false, null);
	return;
};

WidgetUserAccountSecurityAuthenticationTokens.prototype.onGenerateTokenClicked = function(
	event,
) {
	var currentWidget = this;

	this.generateToken(function(err, data) {
		if (err) {
			console.error(err);
			return;
		}

		if (this._tableData.length > 0) {
			this.getMainContainer().showPopupInfoMessage(
				this.getLanguageTag(
					'youHaveReachedTheMaximumAmountOfTokensForYourAccount',
				),
			);
		}

		this.update(function(err, data) {});
		return;
	});
};

WidgetUserAccountSecurityAuthenticationTokens.prototype.onViewButtonClicked = function(
	event,
	tokenId,
) {
	var mask = '********************************';

	var $currentTokenTextContainer = $(
		this._tokensTable.getContainer().find(`[id=${tokenId}]`),
	);
	var currentTokenText = $currentTokenTextContainer.text();

	if (currentTokenText.includes('*')) {
		$currentTokenTextContainer.text(tokenId);
	} else {
		$currentTokenTextContainer.text(mask);
	}
};

WidgetUserAccountSecurityAuthenticationTokens.prototype.onDeleteButtonClicked = function(
	event,
	tokenId,
) {
	var currentWidget = this;

	this.getMainContainer().showConfirm(
		{
			message: this.getLanguageTag('confirmRemoveToken'),
			title: this.getLanguageTag('removeToken'),
			confirmLabel: this.getLanguageTag('confirmRemoveTokenButton'),
			confirmClasses: ['btn-danger'],
		},

		function() {
			currentWidget.deleteAllTokens(function(err, data) {
				if (err) {
					console.error(err);
					return;
				}

				this.update(function(err, data) {});
				return;
			});
		},
	);
};

WidgetUserAccountSecurityAuthenticationTokens.prototype.getTokensViaApi = function(
	callback,
) {
	var currentWidget = this;

	this.getAPI()
		.getAPIRoute('/user/general/auth/api/tokens')
		.get(function(err, data) {
			if (err) {
				console.error(err);
				callback.call(currentWidget, err, false);
				return;
			}

			callback.call(currentWidget, false, data);
			return;
		});
};

WidgetUserAccountSecurityAuthenticationTokens.prototype.generateToken = function(
	callback,
) {
	var currentWidget = this;

	this.getAPI()
		.getAPIRoute('/user/general/auth/api/token/add')
		.post(function(err, data) {
			if (err) {
				console.error(err);
				callback.call(currentWidget, err, false);
				return;
			}

			callback.call(currentWidget, false, data);
			return;
		});
};

WidgetUserAccountSecurityAuthenticationTokens.prototype.deleteAllTokens = function(
	callback,
) {
	// FIXME: This will be changed to delete specific tokens.
	var currentWidget = this;

	this.getAPI()
		.getAPIRoute('/user/general/auth/api/tokens/remove')
		.post(function(err, data) {
			if (err) {
				console.error(err);
				callback.call(currentWidget, err, false);
				return;
			}

			callback.call(currentWidget, false, data);
			return;
		});
};

WidgetUserAccountSecurityAuthenticationTokens.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'WidgetUserAccountSecurityAuthenticationTokens',
			authenticationTokens: 'Authentication Tokens',
			generateToken: 'Generate Token',
			topLabel:
				"Below is the list of external API access tokens associated with your account. Remove any tokens you don't recognize.",
			bottomLabel:
				'Not sure how to use an authentication token? Head over to the docs to <a href="{0}" target="_system"> learn more. </a>',
			token: 'Token',
			options: 'Options',
			confirmRemoveToken: 'Are you sure you want to delete this token?',
			showToken: 'Show Token',
			removeToken: 'Delete Token',
			confirmRemoveTokenButton: 'Delete',
			youHaveReachedTheMaximumAmountOfTokensForYourAccount:
				'Your account has reached its token amount limit',
		},
	},
);

WidgetUserAccountSecurityAuthenticationTokens.prototype.$_$ = function(done) {
	//Testing function for WidgetUserAccountSecurityAuthenticationTokens
	this.$_SetupWidgetTest(function(err, currentWidget) {
		WidgetBase.prototype.$_$.call(this, done);
	});
};
