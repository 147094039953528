function ElementControllerCloudDeviceAbility(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('value', null, null, 'none');

	this.addTrigger('executed');
}

ElementControllerCloudDeviceAbility.prototype = Object.create(
	ElementControllerCloudBase.prototype,
);
ElementControllerCloudDeviceAbility.prototype.constructor = ElementControllerCloudDeviceAbility;

ElementControllerCloudDeviceAbility.prototype.TYPE = 'CloudDeviceAbility';
ElementControllerCloudDeviceAbility.prototype.HIDDEN = true;
ElementControllerCloudDeviceAbility.prototype.DEFAULT_TRIGGER = 'executed';
ElementControllerCloudDeviceAbility.prototype.DEFAULT_ABILITY = 'trigger';
ElementControllerCloudDeviceAbility.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerCloudDeviceAbility.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudDeviceAbility.prototype.language = deepAssign(
	{},
	ElementControllerCloudBase.prototype.language,
	{
		'en-US': {
			CloudStorage: 'Device Ability',
			value: 'Value',
			executed: 'Executed',
		},
	},
);
