function ElementControllerAppUITextfield(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppUIBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);
}

ElementControllerAppUITextfield.prototype = Object.create(
	ElementControllerAppUIBase.prototype,
);
ElementControllerAppUITextfield.prototype.constructor = ElementControllerAppUITextfield;

ElementControllerAppUITextfield.prototype.NAME = 'Textfield';
ElementControllerAppUITextfield.prototype.TYPE = 'AppUITextfield';
ElementControllerAppUITextfield.prototype.HIDDEN = true;
ElementControllerAppUITextfield.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerAppUITextfield.prototype.language = deepAssign(
	{},
	ElementControllerAppUIBase.prototype.language,
	{
		'en-US': {
			AppUITextfield: 'Textfield',
		},
	},
);
