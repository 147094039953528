function ElementControllerCloudDeviceSetting(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	// This is the internal property that is the current value for the setting element for use in trigger events
	this.addProperty('value', null, null, 'none');
	// This is the internal propery that is the complete device settings object for use in trigger events
	this.addProperty('settings', null, null, 'none');
	// The default value for the setting if none is applied by user or if the user's currently applied setting isn't valid
	this.addProperty('defaultValue', null, '', 'text', null, null);
	// A human friendly title for the setting that will appear in the device settings tab
	this.addProperty('title', null, '', 'text', null, null);
	// A human friendly description for the setting that will appear in the device settings tab
	this.addProperty('description', null, '', 'text', null, null);
	// Define the order in which the inputs will appear in the device settings tab
	this.addProperty('listOrder', null, 0, 'number', null, null);
	// What inputType of setting/input is this going to use?
	this.addProperty('inputType', null, 'text', 'select', [
		'text',
		'toggle',
		'number',
		'geolocation',
	]);

	// FUTURE: https://koliada.atlassian.net/browse/AI-3378

	// This will get the current value of the device setting updating and then trigger "settingRetrieved"
	this.addAbility('getSetting', []);

	this.addTrigger('settingChanged');
	this.addTrigger('settingRetrieved');
}

ElementControllerCloudDeviceSetting.prototype = Object.create(
	ElementControllerCloudBase.prototype,
);
ElementControllerCloudDeviceSetting.prototype.constructor = ElementControllerCloudDeviceSetting;

ElementControllerCloudDeviceSetting.prototype.TYPE = 'CloudDeviceSetting';
ElementControllerCloudDeviceSetting.prototype.HIDDEN = false;
ElementControllerCloudDeviceSetting.prototype.DEFAULT_TRIGGER =
	'settingChanged';
ElementControllerCloudDeviceSetting.prototype.DEFAULT_ABILITY = 'getSetting';
ElementControllerCloudDeviceSetting.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerCloudDeviceSetting.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudDeviceSetting.prototype.language = deepAssign(
	{},
	ElementControllerCloudBase.prototype.language,
	{
		'en-US': {
			CloudDeviceSetting: 'Device Setting',
			title: 'Title',
			defaultValue: 'Default Value',
			description: 'Description',
			inputType: 'Input Type',
			text: 'Text Input',
			listOrder: 'List Order',
			toggle: 'Toggle Switch',
			number: 'Number Input',
			geolocation: 'Geolocation',
			getSetting: 'Get Setting',
			settingChanged: 'Setting Changed',
			settingRetrieved: 'Setting Retrieved',
		},
	},
);
