function LocationOrganizations(id, api, parentWidget, options) {
	LocationBase.call(this, id, api, parentWidget, options);
}

LocationOrganizations.prototype = Object.create(LocationBase.prototype);
LocationOrganizations.prototype.constructor = LocationOrganizations;

LocationOrganizations.prototype.hasPermission = function(callback) {
	const currentUser = _mainContainer.getCurrentUser();

	if (!currentUser) {
		callback.call(this, false, false);
		return;
	}

	if (!currentUser.ability.can('see', 'Organizations')) {
		callback.call(this, false, false);
		return;
	}

	LocationBase.prototype.hasPermission.call(this, callback);
};

LocationOrganizations.prototype.initialize = function(callback) {
	const currentLocation = this;
	this.setTabWidgets(
		getLanguageTag(LocationOrganizations, 'name'),
		[
			{
				childId: 'organizations',
				constructor: WidgetOrganizations,
				options: {},
			},

			// Calculating organization usage the way
			// the application currently does proves to
			// be far too costly on instances like Sol,
			// where the root org needs to calculate
			// gigabytes of usage for thousands of devices.
			// organizationInfo tab is commented out as a result.

			// {
			// 	childId: 'organizationInfo',
			// 	constructor: WidgetOrganizationInfo,
			// 	options: {},
			// },
		],
		function(err) {
			LocationBase.prototype.initialize.call(this, (err) => {
				if (err) {
					callback.call(currentLocation, err);
					return;
				}

				const dashboardWidget = currentLocation.getChildWidget(
					'organizations',
				);

				currentLocation.getOrganizationContext((err, currentOrg) => {
					if (err) {
						callback.call(currentLocation, err);
						return;
					}

					const locationTitle =
						currentOrg.name ||
						currentLocation.getLanguageTag('name');
					currentLocation.setTitle(locationTitle);

					dashboardWidget.addEventListener('orgNameChanged', function(
						event,
					) {
						currentLocation.setTitle(event);
					});

					callback.call(currentLocation, false);
				});
			});
		},
	);
};

LocationOrganizations.prototype.language = deepAssign(
	{},
	LocationBase.prototype.language,
	{
		'en-US': {
			name: 'Organizations',
		},
	},
);
