function ElementControllerEmbeddedLibraryBase(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	if (this.EEL === null || this.EEL === undefined) {
		this.error('noEELDefinedForEmbeddedElementLibrary');
		return;
	}

	for (var i = 0; i < this.EEL.properties.length; i++) {
		var currentProperty = this.EEL.properties[i];

		this.addProperty(
			currentProperty.name,
			null,
			currentProperty.value,
			currentProperty.input,
			currentProperty.inputOptions,
			currentProperty.driverType,
		);

		if (currentProperty.embeddedConversion) {
			this.setPropertyEmbeddedConversion(
				currentProperty.name,
				currentProperty.embeddedConversion,
			);
		}
	}

	for (var i = 0; i < this.EEL.variables.length; i++) {
		var currentVariable = this.EEL.variables[i];

		this.addVariable(currentVariable.name, currentVariable.type);
	}

	if (this.EEL.implements) {
		this.addImplements(this.EEL.implements);

		for (var i = 0; i < this.EEL.implements.length; i++) {
			var driverInstanceName = `ATMO_DRIVERINSTANCE_${this.EEL.implements[
				i
			].toUpperCase()}_${elementName}`;

			if (
				parentPlane._runtimeDrivers[this.EEL.implements[i]] == undefined
			) {
				parentPlane._runtimeDrivers[this.EEL.implements[i]] = [];
			}

			if (
				parentPlane._runtimeDrivers[this.EEL.implements[i]].find(
					function(driver) {
						return driver.name === driverInstanceName;
					},
				) === undefined
			) {
				parentPlane._runtimeDrivers[this.EEL.implements[i]].push({
					name: driverInstanceName,
				});

				// Add language tag for it
				Object.keys(parentPlane.language).forEach(function(language) {
					parentPlane.language[language][
						driverInstanceName
					] = elementName;
				});
			}
		}
	}

	for (var i = 0; i < this.EEL.abilities.length; i++) {
		var currentAbility = this.EEL.abilities[i];

		this.addAbility(
			currentAbility.name,
			[],
			currentAbility.triggers,
			Boolean(currentAbility.interrupt),
			Boolean(currentAbility.hidden),
		);
		this.setAbilityCode(currentAbility.name, currentAbility.code);
	}

	for (var i = 0; i < this.EEL.triggers.length; i++) {
		var currentTrigger = this.EEL.triggers[i];

		this.addTrigger(currentTrigger.name);
	}
}

ElementControllerEmbeddedLibraryBase.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedLibraryBase.prototype.constructor = ElementControllerEmbeddedLibraryBase;

ElementControllerEmbeddedLibraryBase.prototype.TYPE = 'EmbeddedLibraryBase';
ElementControllerEmbeddedLibraryBase.prototype.HIDDEN = true;
ElementControllerEmbeddedLibraryBase.prototype.EEL = null; //When we dynamically create a new element for a library this must be set to the element object

ElementControllerEmbeddedLibraryBase.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedLibraryBase: 'Base Embedded Library Element',
		},
	},
);
