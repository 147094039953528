function PlaneControllerEmbeddedMax32630Fthr(parentController, elementName) {
	PlaneControllerEmbeddedMax3263x.call(this, parentController, elementName);
}

PlaneControllerEmbeddedMax32630Fthr.prototype = Object.create(
	PlaneControllerEmbeddedMax3263x.prototype,
);
PlaneControllerEmbeddedMax32630Fthr.prototype.constructor = PlaneControllerEmbeddedMax32630Fthr;

PlaneControllerEmbeddedMax32630Fthr.prototype.VARIANTS = ['Max32630Fthr'];
PlaneControllerEmbeddedMax32630Fthr.prototype.CLIENT_AGENT_REQUIRED = false;

PlaneControllerEmbeddedMax32630Fthr.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'LED_BLUE',
		mode: 'ATMO_GPIO_PinMode_Output_PushPull',
	},

	EmbeddedPWMPin: {
		instance: 0,
		pin: 'P3_3',
	},

	EmbeddedADCPin: {
		instance: 0,
		pin: 'AIN_0',
	},

	EmbeddedBMI160: {
		i2cInstance: 1,
	},

	EmbeddedXbeeLteNbIoT: {
		uartInstance: 1,
	},

	EmbeddedXbeeLteCat1: {
		uartInstance: 1,
	},

	EmbeddedGroveLight: {
		adcInstance: 0,
		adcPin: 'AIN_0',
	},

	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'P3_3',
	},

	EmbeddedGroveTemperature: {
		adcInstance: 0,
		adcPin: 'AIN_0',
	},

	EmbeddedGroveRotaryAngle: {
		adcInstance: 0,
		adcPin: 'AIN_0',
	},

	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'P3_3',
	},

	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'P3_3',
	},

	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'P3_3',
	},
};

PlaneControllerEmbeddedMax32630Fthr.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedMax3263x.prototype.language,
	{
		'en-US': {
			defaultName: 'Max32630 Feather',
		},
	},
);
