function ElementControllerAppCondition(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppLogicBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('value', null, null, 'none', null, null);

	this.addAbility('check', [{ name: 'value', validator: null }]);

	this.addTrigger('conditionTrue');
	this.addTrigger('conditionFalse');
}

ElementControllerAppCondition.prototype = Object.create(
	ElementControllerAppLogicBase.prototype,
);
ElementControllerAppCondition.prototype.constructor = ElementControllerAppCondition;

ElementControllerAppCondition.prototype.NAME = 'Condition';
ElementControllerAppCondition.prototype.TYPE = 'AppCondition';
ElementControllerAppCondition.prototype.HIDDEN = false;
ElementControllerAppCondition.prototype.DEFAULT_TRIGGER = 'conditionTrue';
ElementControllerAppCondition.prototype.DEFAULT_ABILITY = 'check';
ElementControllerAppCondition.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerAppCondition.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerAppCondition.prototype.language = deepAssign(
	{},
	ElementControllerBase.prototype.language,
	{
		'en-US': {
			AppCondition: 'Condition',
			value: 'Value',
			check: 'Check',
			conditionTrue: 'Condition True',
			conditionFalse: 'Condition False',
		},
	},
);
