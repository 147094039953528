function ElementControllerEmbeddedBase(
	parentController,
	parentPlane,
	elementName,
) {
	this._interruptAbilities = {};

	ElementControllerBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('embeddedPropertyConversions', null, {}, 'none');
	this.addProperty('codeUserChanged', null, {}, 'none');
}

ElementControllerEmbeddedBase.prototype = Object.create(
	ElementControllerBase.prototype,
);
ElementControllerEmbeddedBase.prototype.constructor = ElementControllerEmbeddedBase;

ElementControllerEmbeddedBase.prototype.TYPE = 'EmbeddedBase';
ElementControllerEmbeddedBase.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
];
ElementControllerEmbeddedBase.prototype.CATEGORY = 'embeddedBasic';

ElementControllerEmbeddedBase.prototype.setPropertyEmbeddedConversion = function(
	name,
	converter,
) {
	/*
	 * When the base embedded compiler goes to write the #defined macro for
	 * this property it won't know if you want to keep it literally the way
	 * it is displayed in text, or to place quotation to make is a defined
	 * string. This lets you choose the embedded type conversion.
	 *
	 */

	var currentProperty = this.getProperty('embeddedPropertyConversions') || {};
	var currentPropertyValue = currentProperty.value || {};

	currentPropertyValue[name] = converter;

	this.setProperty('embeddedPropertyConversions', currentPropertyValue);
	return;
};

ElementControllerEmbeddedBase.prototype.updateAbilityCode = function(oldName) {
	var name = this.getName();

	var currentProperty = this.getProperty('code') || {};
	var currentPropertyValue = currentProperty.value || {};

	/*
	 * We need to update any references to the ATMO_VARIABLE, ATMO_INTERRUPT, or ATMO_PROPERTY
	 * that the code maybe using.
	 */
	for (var abilityName in this._abilities) {
		var abilityCode = currentPropertyValue[abilityName];

		abilityCode = abilityCode.replace(
			/ATMO_PROPERTY\(([^,]*)/g,
			'ATMO_PROPERTY(' + name,
		);
		abilityCode = abilityCode.replace(
			/ATMO_TRIGGER\(([^,]*)/g,
			'ATMO_TRIGGER(' + name,
		);
		abilityCode = abilityCode.replace(
			/ATMO_VARIABLE\(([^,]*)/g,
			'ATMO_VARIABLE(' + name,
		);
		abilityCode = abilityCode.replace(
			/ATMO_INTERRUPT\(([^,]*)/g,
			'ATMO_INTERRUPT(' + name,
		);
		abilityCode = abilityCode.replace(
			/ATMO_ABILITY\(([^,]*)/g,
			'ATMO_ABILITY(' + name,
		);
		abilityCode = abilityCode.replace(
			/ATMO_INSTANCE\(([^,]*)/g,
			'ATMO_INSTANCE(' + name,
		);
		abilityCode = abilityCode.replace(
			/ATMO_ELEMENT_NAME\(([^\)]*)/g,
			'ATMO_ELEMENT_NAME(' + name,
		);
		abilityCode = abilityCode.replace(
			/ATMO_ELEMENT_NAME_SLUG\(([^\)]*)/g,
			'ATMO_ELEMENT_NAME_SLUG(' + name,
		);

		currentPropertyValue[abilityName] = abilityCode;
	}

	this.setProperty('code', currentPropertyValue);
};

ElementControllerEmbeddedBase.prototype.setName = function(name) {
	ElementControllerBase.prototype.setName.call(this, name);

	this.updateAbilityCode();

	return;
};

ElementControllerEmbeddedBase.prototype.addVariable = function(
	variableName,
	type,
) {
	var currentProperty = this.getProperty('variables') || {};
	var variables = currentProperty.value || {};

	variables[variableName] = { type: type };

	this.setProperty('variables', variables);

	return;
};

ElementControllerBase.prototype.addImplements = function(implementsList) {
	this._implements = implementsList;
};

ElementControllerEmbeddedBase.prototype.addAbility = function(
	abilityName,
	args,
	triggers,
	interrupt,
	hidden,
) {
	interrupt = interrupt || false;
	hidden = hidden || false;

	if (abilityName === 'setup') {
		hidden = true;
	}

	ElementControllerBase.prototype.addAbility.call(
		this,
		abilityName,
		args,
		triggers,
		hidden,
	);

	this._interruptAbilities[abilityName] = interrupt;

	if (this._properties['code'] === undefined) {
		this.addProperty('code', null, {}, 'code', undefined, undefined, true);
		this.addProperty(
			'variables',
			null,
			{},
			'variables',
			undefined,
			undefined,
			true,
		);
	}

	this.setAbilityCode(abilityName, '\treturn ATMO_Status_Success;');
};

ElementControllerEmbeddedBase.prototype.setAbilityCode = function(
	abilityName,
	code,
	userModified,
) {
	var currentAbility = this.getAbility(abilityName);

	userModified = userModified || false;

	if (currentAbility === null) {
		return;
	}

	var currentProperty = this.getProperty('code') || {};
	var abilitiesCode = currentProperty.value || {};

	var codeUserChangedProperty = this.getProperty('codeUserChanged') || {};
	var codeUserChanged = codeUserChangedProperty.value || {};

	abilitiesCode[abilityName] = code;
	codeUserChanged[abilityName] = userModified;

	this.setProperty('code', abilitiesCode);
	this.setProperty('codeUserChanged', codeUserChanged);

	this.updateAbilityCode();

	return;
};

ElementControllerEmbeddedBase.prototype.import = function(elementData) {
	if (elementData === undefined || elementData === null) {
		return;
	}

	this._meta = elementData.meta;
	this._triggers = Object.assign(this._triggers, elementData.triggers);
	this._coupledElementName = elementData.coupledElementName;
	this._coupledElementPlaneName = elementData.coupledElementPlaneName;
	this._implements = elementData.implements;

	for (propertyName in elementData.properties) {
		//Here we are going to check to see if the user ever
		//modified the code for this element, if they didn't
		//we just go with what ever the controller had set
		//and ignore any potentially old version.
		if (propertyName === 'code') {
			var codeUserChanged =
				elementData.properties['codeUserChanged'] || {};

			for (var abilityName in elementData.properties[propertyName]) {
				if (codeUserChanged[abilityName]) {
					this.setAbilityCode(
						abilityName,
						elementData.properties[propertyName][abilityName],
					);
				}
			}
		} else {
			this.mergeProperty(
				propertyName,
				elementData.properties[propertyName],
			);
		}
	}

	this.variables = elementData.variables;

	this.event('imported', elementData);
};

ElementControllerEmbeddedBase.prototype.export = function() {
	var elementExportData = ElementControllerBase.prototype.export.call(this);

	//We now also need to add the abilities and the special embedded ability properties to the data for this element as well.

	elementExportData.variables = this.variables;
	elementExportData.interruptAbilities = this._interruptAbilities;
	elementExportData.implements = this._implements;

	elementExportData.abilities = [];

	for (var k in this._abilities) {
		var currentAbility = this._abilities[k];

		elementExportData.abilities.push({
			name: k,
			triggers: currentAbility.triggers,
		});
	}

	return elementExportData;
};

ElementControllerEmbeddedBase.prototype.language = deepAssign(
	{},
	ElementControllerBase.prototype.language,
	{
		'en-US': {
			EmbeddedBase: 'Embedded Base',
		},
	},
);
