function LocationStudio(id, api, parentWidget, options) {
	LocationBase.call(this, id, api, parentWidget, options);
}

LocationStudio.prototype = Object.create(LocationBase.prototype);
LocationStudio.prototype.constructor = LocationStudio;

LocationStudio.prototype.hasPermission = function(callback) {
	const currentUser = _mainContainer.getCurrentUser();

	if (isPhonegap()) {
		callback.call(this, false, false);
		return;
	}

	if (!currentUser) {
		callback.call(this, false, false);
		return;
	}

	if (!currentUser.ability.can('see', 'Studio')) {
		callback.call(this, false, false);
		return;
	}

	LocationBase.prototype.hasPermission.call(this, callback);
};

LocationStudio.prototype.initialize = function(callback) {
	this.setTabWidgets(
		null,
		[
			{
				childId: 'projectManager',
				constructor: WidgetProjectManager,
				options: {},
			},
			{
				childId: 'demoProjects',
				constructor: WidgetDemoProjectsTable,
				options: {},
			},
		],
		function(err) {
			LocationBase.prototype.initialize.call(this, callback);
		},
	);
};

LocationStudio.prototype.language = deepAssign(
	{},
	LocationBase.prototype.language,
	{
		'en-US': {
			name: 'Applications',
		},
	},
);
