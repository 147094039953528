function PlaneControllerEmbeddedSparkfunEsp32Thing(
	parentController,
	elementName,
) {
	PlaneControllerEmbeddedEsp32.call(this, parentController, elementName);
}

PlaneControllerEmbeddedSparkfunEsp32Thing.prototype = Object.create(
	PlaneControllerEmbeddedEsp32.prototype,
);
PlaneControllerEmbeddedSparkfunEsp32Thing.prototype.constructor = PlaneControllerEmbeddedSparkfunEsp32Thing;

PlaneControllerEmbeddedSparkfunEsp32Thing.prototype.VARIANTS = [
	'SparkfunEsp32Thing',
];

PlaneControllerEmbeddedSparkfunEsp32Thing.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'IO5',
		mode: 'ATMO_GPIO_PinMode_Output_PushPull',
	},

	EmbeddedPWMPin: {
		instance: 0,
		pin: 'IO4',
	},

	EmbeddedADCPin: {
		instance: 0,
		pin: 'A0',
	},

	EmbeddedCloudCommand: {
		instance: 1,
	},

	EmbeddedCloudEvent: {
		instance: 1,
	},

	EmbeddedRelayClick: {
		relay1Pin: 'IO4',
		relay2Pin: 'IO5',
		gpioDriverInstance: 0,
	},

	EmbeddedIRThermoClick: {
		i2cInstance: 0,
	},

	EmbeddedHBridge2Click: {
		enablePin: 'IO2',
		dirPin: 'IO14',
		ginPin: 'IO4',
		speedCtrlPin: 'IO5',
		gpioDriverInstance: 0,
		pwmDriverInstance: 0,
		initialSpeedPct: 100,
	},

	EmbeddedGpsClick: {
		uartDriverInstance: 1,
		gpioDriverInstance: 0,
		powerPin: 'IO2',
		wupPin: 'IO14',
		rstPin: 'IO5',
		reportFrequency: 1,
	},

	EmbeddedMotionClick: {
		gpioDriverInstance: 0,
		enablePin: 'IO4',
		motionPin: 'IO5',
	},

	Embedded8x8LedClick: {
		csPin: 'IO5',
	},

	EmbeddedGroveLight: {
		adcInstance: 0,
		adcPin: 'A0',
	},

	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'IO5',
	},

	EmbeddedGroveRotaryAngle: {
		adcInstance: 0,
		adcPin: 'A0',
	},

	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'IO5',
	},

	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'IO5',
	},

	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'IO5',
	},

	EmbeddedADXL343: {
		i2cInstance: 0,
		gpioInstance: 0,
		int1Pin: 'IO4',
		int2Pin: 'IO5',
	},

	EmbeddedADXL345: {
		i2cInstance: 0,
		gpioInstance: 0,
		int1Pin: 'IO4',
		int2Pin: 'IO5',
	},
};

PlaneControllerEmbeddedSparkfunEsp32Thing.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedEsp32.prototype.language,
	{
		'en-US': {
			defaultName: 'Sparkfun ESP32 Thing',
		},
	},
);
