function PlaneControllerEmbeddedBeagleBoneGreen(parentController, elementName) {
	PlaneControllerEmbeddedLinux.call(this, parentController, elementName);
}

PlaneControllerEmbeddedBeagleBoneGreen.prototype = Object.create(
	PlaneControllerEmbeddedLinux.prototype,
);
PlaneControllerEmbeddedBeagleBoneGreen.prototype.constructor = PlaneControllerEmbeddedBeagleBoneGreen;

PlaneControllerEmbeddedBeagleBoneGreen.prototype.VARIANTS = ['BeagleBoneGreen'];
PlaneControllerEmbeddedBeagleBoneGreen.prototype.SUPPORTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'interval',
	'variables',
	'gpio',
	'adc',
	'uart',
	'i2c',
	'spi',
	'pwm',
	'fpmath',
	'debugPrint',
	'uptime',
	'ble',
	'linux',
	'log',
	'BeagleBoneGreen4',
];
PlaneControllerEmbeddedBeagleBoneGreen.prototype.CLIENT_AGENT_REQUIRED = false;

PlaneControllerEmbeddedBeagleBoneGreen.prototype.FIRMWARE_DOWNLOAD_FILES = {
	artifactName: 'firmware',
	fileName: 'Atmosphere_Project.AppImage',
};

PlaneControllerEmbeddedBeagleBoneGreen.prototype.DRIVERS = {
	adc: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_ADC_ADC1',
		},
	],
	ble: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLE_BLE1',
		},
	],
	block: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLOCK_BLOCK1',
		},
	],
	datetime: [],
	embeddedCloud: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_CLOUD_PI',
		},
	],
	filesytem: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_FILESYSTEM_FILESYSTEM1',
		},
	],
	gpio: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_GPIO_GPIO1',
		},
	],
	http: [],
	i2c: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C1',
			primary: true,
		},
	],
	interval: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_INTERVAL_INTERVAL1',
		},
	],
	nfc: [],
	pwm: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_PWM_PWM1',
		},
	],
	spi: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_SPI_SPI1',
		},
	],
	uart: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_UART_UART1',
		},
	],
	wifi: [],
};

PlaneControllerEmbeddedBeagleBoneGreen.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'P9_25',
		mode: 'ATMO_GPIO_PinMode_Output_PushPull',
	},

	EmbeddedPWMPin: {
		instance: 0,
		pin: 'P9_31',
	},

	EmbeddedADCPin: {
		instance: 0,
		pin: 'AIN0',
	},
	EmbeddedGroveLight: {
		adcInstance: 0,
		adcPin: 'AIN0',
	},

	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'P9_25',
	},

	EmbeddedGroveTemperature: {
		adcInstance: 0,
		adcPin: 'AIN0',
	},

	EmbeddedGroveRotaryAngle: {
		adcInstance: 0,
		adcPin: 'AIN0',
	},

	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'P9_25',
	},

	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'P9_25',
	},

	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'P9_25',
	},

	EmbeddedADXL343: {
		i2cInstance: 0,
		gpioInstance: 0,
		int1Pin: 'P9_25',
		int2Pin: 'P9_27',
	},

	EmbeddedADXL345: {
		i2cInstance: 0,
		gpioInstance: 0,
		int1Pin: 'P9_25',
		int2Pin: 'P9_27',
	},
};

PlaneControllerEmbeddedBeagleBoneGreen.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			defaultName: 'BeagleBone Green',
			ATMO_DRIVERINSTANCE_CLOUD_PI: 'Internal',
			ATMO_DRIVERINSTANCE_UART_UART1: 'UART4(P9_13/P9_11)',
		},
	},
);
