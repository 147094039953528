function ElementControllerAppUIProgressBar(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppUIBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('value', null, 0, 'number', null, null);
	this.addProperty('minValue', null, 0, 'number', null, null);
	this.addProperty('maxValue', null, 100, 'number', null, null);

	this.addAbility('setValue', [{ name: 'value', validator: null }]);
	this.addAbility('setMaxValue', [{ name: 'maxValue', validator: null }]);
	this.addAbility('setMinValue', [{ name: 'minValue', validator: null }]);
}

ElementControllerAppUIProgressBar.prototype = Object.create(
	ElementControllerAppUIBase.prototype,
);
ElementControllerAppUIProgressBar.prototype.constructor = ElementControllerAppUIProgressBar;

ElementControllerAppUIProgressBar.prototype.NAME = 'ProgressBar';
ElementControllerAppUIProgressBar.prototype.TYPE = 'AppUIProgressBar';
ElementControllerAppUIProgressBar.prototype.HIDDEN = false;
ElementControllerAppUIProgressBar.prototype.DEFAULT_TRIGGER = 'triggered';
ElementControllerAppUIProgressBar.prototype.DEFAULT_ABILITY = 'setValue';
ElementControllerAppUIProgressBar.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerAppUIProgressBar.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerAppUIProgressBar.prototype.language = deepAssign(
	{},
	ElementControllerAppUIBase.prototype.language,
	{
		'en-US': {
			AppUIProgressBar: 'Progress Bar',
			value: 'Value',
			minValue: 'Minimum Value',
			maxValue: 'Maximum Value',
			setValue: 'Set Value',
			value: 'Value',
			setMaxValue: 'Set Maximum Value',
			setMinValue: 'Set Minimum Value',
		},
	},
);
