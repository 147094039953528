function ElementControllerEmbeddedNfcMime(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addVariable('record', 'ATMO_NFC_Record_t');
	this.addVariable('recordHandle', 'unsigned int');

	this.addProperty('instance', null, 0, 'driverInstance', null, 'nfc');

	this.addProperty('type', null, 'text/plain', 'text', null, null);
	this.setPropertyEmbeddedConversion('type', 'string');

	this.addProperty('defaultValue', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('defaultValue', 'string');

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
    ATMO_NFC_RegisterStoredRecord(ATMO_PROPERTY(undefined, instance), &ATMO_VARIABLE(undefined, record), &ATMO_VARIABLE(undefined, recordHandle));
    ATMO_NFC_MakeRecordMime(&ATMO_VARIABLE(undefined, record), ATMO_PROPERTY(undefined, type), ATMO_PROPERTY(undefined, defaultValue), strlen(ATMO_PROPERTY(undefined, defaultValue)));
    ATMO_NFC_SyncAtmoRecords(ATMO_PROPERTY(undefined, instance));
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setRecord', [], ['recordSet'], false, false);
	this.setAbilityCode(
		'setRecord',
		`
    char data[128];

    if( ATMO_GetString(in, data, 128) != ATMO_Status_Success )
    {
        return ATMO_Status_Fail;
    }

    ATMO_NFC_MakeRecordMime(&ATMO_VARIABLE(undefined, record), ATMO_PROPERTY(undefined, type), data, strlen(data));
    ATMO_NFC_SyncAtmoRecords(ATMO_PROPERTY(undefined, instance));
	return ATMO_Status_Success;
	`,
	);
}

ElementControllerEmbeddedNfcMime.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedNfcMime.prototype.constructor = ElementControllerEmbeddedNfcMime;

ElementControllerEmbeddedNfcMime.prototype.NAME = 'MimeRecord';
ElementControllerEmbeddedNfcMime.prototype.TYPE = 'EmbeddedNfcMime';
ElementControllerEmbeddedNfcMime.prototype.HIDDEN = false;
ElementControllerEmbeddedNfcMime.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'nfc',
];
ElementControllerEmbeddedNfcMime.prototype.DEFAULT_ABILITY = 'setRecord';
ElementControllerEmbeddedNfcMime.prototype.DEFAULT_TRIGGER = 'recordSet';
ElementControllerEmbeddedNfcMime.prototype.CATEGORY = 'nfc';
ElementControllerEmbeddedNfcMime.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedNfcMime.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedNfcMime: 'NFC MIME Record',
			recordSet: 'Record Data Set',
			setRecord: 'Set Record Data',
			type: 'MIME Type',
			defaultValue: 'Default Value',
		},
	},
);
