function WidgetUserAccountProfile(id, api, parent, options) {
	var currentWidget = this;

	WidgetBase.call(this, id, api, parent, options);

	this.firstNameInputId = this.generateChildId('firstNameInput');
	this.lastNameInputId = this.generateChildId('lastNameInput');
	this.usernameInputId = this.generateChildId('usernameInput');
	this.emailInputId = this.generateChildId('emailInput');
	this.companyInputId = this.generateChildId('companyInput');
	this.jobTitleInputId = this.generateChildId('jobTitleInput');
	this.saveChangesButtonId = this.generateChildId('saveChangesButton');
	this.removeImageId = this.generateChildId('removeImage');
	this.userImageId = this.generateChildId('userImage');
	this.imageFileInputId = this.generateChildId('imageFileInput');
	this.roleInputId = this.generateChildId('roleInput');
	this.organizationInputId = this.generateChildId('organization');
	this.accountEnabledContainerId = this.generateChildId(
		'accountEnabledContainer',
	);
	this.accountEnabledSwitchId = this.generateChildId('accountEnabledSwitch');
	this.errorContainerId = this.generateChildId('errorContainer');

	const currentUser = this.getCurrentUser();

	const roleOpts = Object.values(UserRoles).map((role) => {
		return {
			label: this.getLanguageTag(role),
			value: role,
			selected: false,
		};
	});

	const context = {
		userInformationLabel: getLanguageTag(this.constructor, 'name'),
		firstNameLabel: getLanguageTag(this.constructor, 'firstName'),
		firstNameInputId: this.firstNameInputId,
		firstName: getLanguageTag(this.constructor, 'loading'),
		lastNameLabel: getLanguageTag(this.constructor, 'lastName'),
		lastNameInputId: this.lastNameInputId,
		lastName: getLanguageTag(this.constructor, 'loading'),
		usernameLabel: getLanguageTag(this.constructor, 'username'),
		usernameInputId: this.usernameInputId,
		username: getLanguageTag(this.constructor, 'loading'),
		emailLabel: getLanguageTag(this.constructor, 'email'),
		emailInputId: this.emailInputId,
		email: getLanguageTag(this.constructor, 'loading'),
		companyLabel: getLanguageTag(this.constructor, 'company'),
		companyInputId: this.companyInputId,
		company: getLanguageTag(this.constructor, 'loading'),
		jobTitleLabel: getLanguageTag(this.constructor, 'jobTitle'),
		jobTitleInputId: this.jobTitleInputId,
		jobTitle: getLanguageTag(this.constructor, 'loading'),
		roleLabel: getLanguageTag(this.constructor, 'role'),
		roleInputId: this.roleInputId,
		role: getLanguageTag(this.constructor, 'loading'),
		roles: roleOpts,
		saveChangesButtonId: this.saveChangesButtonId,
		saveChangesLabel: getLanguageTag(this.constructor, 'saveChanges'),
		userImageLabel: getLanguageTag(this.constructor, 'userImage'),
		removeImageId: this.removeImageId,
		removeImageLabel: getLanguageTag(this.constructor, 'removeImage'),
		userImageId: this.userImageId,
		imageFileInputId: this.imageFileInputId,
		organizationInputId: this.organizationInputId,
		organizationLabel: getLanguageTag(this.constructor, 'organization'),
		accountEnabledContainerId: this.accountEnabledContainerId,
		accountEnabledLabel: getLanguageTag(
			this.constructor,
			'accountEnabledLabel',
		),
		accountEnabledSwitchId: this.accountEnabledSwitchId,
		hideRole: currentUser.ability.cannot('see', 'Role'),
		errorContainerId: this.errorContainerId,
	};

	var globalConfig =
		this.getMainContainer().getGlobalConfig()[this.constructor.name] || {};
	var redirectButtonConfig = (globalConfig || {}).redirectButton || null;

	if (redirectButtonConfig) {
		context = Object.assign({}, context, {
			redirectButtonCaption: getFromLanguageObject(
				redirectButtonConfig.language,
				'redirectButtonCaption',
			),
			redirectButtonLabel: getFromLanguageObject(
				redirectButtonConfig.language,
				'redirectButtonLabel',
			),
			redirectButtonHref: redirectButtonConfig.href,
		});
	}

	this.renderTemplate(context, WidgetUserAccountProfile.name);

	this.firstNameInput = $(`#${this.firstNameInputId}`);
	this.lastNameInput = $(`#${this.lastNameInputId}`);
	this.usernameInput = $(`#${this.usernameInputId}`);
	this.emailInput = $(`#${this.emailInputId}`);
	this.companyInput = $(`#${this.companyInputId}`);
	this.jobTitleInput = $(`#${this.jobTitleInputId}`);
	this.organizationInput = $(`#${this.organizationInputId}`);
	this.saveChangesButton = $(`#${this.saveChangesButtonId}`);
	this.removeImage = $(`#${this.removeImageId}`);
	this.userImage = $(`#${this.userImageId}`);
	this.imageFileInput = $(`#${this.imageFileInputId}`);
	this.roleInput = $(`#${this.roleInputId}`);
	this.errorContainer = $(`#${this.errorContainerId}`);
	this.accountEnabledContainer = $(`#${this.accountEnabledContainerId}`);
	this.accountEnabledSwitch = $(`#${this.accountEnabledSwitchId}`);

	this.organizationInput.on('click', function(e) {
		e.preventDefault();

		currentWidget.getMainContainer().setLocation(LocationOrganizations, {
			org: currentWidget.user.organization.id,
		});
	});

	this.saveChangesButton.hide();
}

WidgetUserAccountProfile.prototype = Object.create(WidgetBase.prototype);
WidgetUserAccountProfile.prototype.constructor = WidgetUserAccountProfile;

WidgetUserAccountProfile.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.update(function(err) {
		this.imageFileInput.on('change', function(e) {
			currentWidget._onImageFileInputChanged(e);
		});

		this.removeImage.click(function() {
			currentWidget.getMainContainer().showConfirm(
				{
					message: currentWidget.getLanguageTag(
						'confirmRemovingImage',
					),
					title: currentWidget.getLanguageTag('removingImageTitle'),
					confirmLabel: currentWidget.getLanguageTag(
						'confirmRemovingImageButton',
					),
				},

				function() {
					currentWidget.unsetImage();
				},
			);
		});

		this.userImage.click(function() {
			currentWidget.imageFileInput.show();
			currentWidget.imageFileInput.trigger('click');
			currentWidget.imageFileInput.hide();
		});

		this.firstNameInput.change(function() {
			currentWidget._onInputChanged();
		});

		this.firstNameInput.keydown(function(e) {
			currentWidget._onKeyDown(e);
		});

		this.lastNameInput.change(function() {
			currentWidget._onInputChanged();
		});

		this.lastNameInput.keydown(function(e) {
			currentWidget._onKeyDown(e);
		});

		this.companyInput.change(function() {
			currentWidget._onInputChanged();
		});

		this.companyInput.keydown(function(e) {
			currentWidget._onKeyDown(e);
		});

		this.jobTitleInput.change(function() {
			currentWidget._onInputChanged();
		});

		this.roleInput.change(function() {
			currentWidget._onInputChanged();
		});

		this.jobTitleInput.keydown(function(e) {
			currentWidget._onKeyDown(e);
		});

		this.userImage.change(function() {
			currentWidget._onInputChanged();
		});

		this.saveChangesButton.click(function() {
			currentWidget.saveChangesButton.prop('disabled', false);
			currentWidget.saveInformation((err) => {
				if (err) {
					currentWidget.errorContainer.show();
					currentWidget.saveChangesButton.prop('disabled', true);
					return;
				}
			});
		});

		WidgetBase.prototype.initialize.call(this, callback);
	});
};

WidgetUserAccountProfile.prototype.setAvailableRoles = function(targetUser) {
	const currentUser = this.getCurrentUser();
	// Only allow to change role to a role you would have access to
	Object.values(UserRoles).forEach((role) => {
		const user = new User(null, { ...currentUser });
		user.data.role = role;
		const cannotSeeRole =
			currentUser.ability.cannot('manage', user) &&
			targetUser.role !== role;
		if (cannotSeeRole) {
			$(`#${this.roleInputId} option[value='${role}']`).remove();
		}
	});

	// If there is only 1 choice in the role dropdown, disable the <select>
	const renderedOptions = this.roleInput.find('option').length;
	if (renderedOptions <= 1) {
		this.roleInput.prop('disabled', true);
	}
};

WidgetUserAccountProfile.prototype.disableAllInputs = function(value) {
	if (value) {
		this.saveChangesButton.hide();
		this.firstNameInput.prop('disabled', true);
		this.lastNameInput.prop('disabled', true);
		this.companyInput.prop('disabled', true);
		this.jobTitleInput.prop('disabled', true);
		this.saveChangesButton.prop('disabled', true);
		this.removeImage.prop('disabled', true);
		this.userImage.prop('disabled', true);
		this.roleInput.prop('disabled', true);
		this.imageFileInput.prop('disabled', true);
		this.removeImage.hide();
	} else {
		this.saveChangesButton.show();
		this.firstNameInput.prop('disabled', false);
		this.lastNameInput.prop('disabled', false);
		this.companyInput.prop('disabled', false);
		this.jobTitleInput.prop('disabled', false);
		this.saveChangesButton.prop('disabled', false);
		this.removeImage.prop('disabled', false);
		this.userImage.prop('disabled', false);
		this.roleInput.prop('disabled', false);
		this.imageFileInput.prop('disabled', false);
		this.removeImage.show();
	}
};

WidgetUserAccountProfile.prototype.getAndShowImage = function(user, callback) {
	const currentWidget = this;
	const api = this.getApiV2().apis;
	api.users
		.getUserImage({ id: user.id })
		.then((image) => {
			currentWidget.userImage.attr('src', image);
			callback.call(currentWidget, false);
		})
		.catch(() => {
			currentWidget.userImage.attr(
				'src',
				'./Resources/icons/SetImage.svg',
			);
			callback.call(currentWidget, false);
		});
};

WidgetUserAccountProfile.prototype.update = function(callback) {
	callback = callback || function() {};

	const currentWidget = this;

	this.saveChangesButton.prop('disabled', true);

	const currentUser = this.getCurrentUser();
	const id = getHashCommand().id || currentUser.id;

	const api = this.getApiV2().apis;

	api.users
		.getUser({ id })
		.then((user) => {
			currentWidget.user = new User(api, user);
			currentWidget.setAvailableRoles(user);
			return this.user.getOrganization();
		})
		.then((organization) => {
			currentWidget.user.organization = organization;
			const user = currentWidget.user.data;
			const orgModel = new Organization(undefined, organization);
			currentWidget._updateInputs(
				user.firstName,
				user.lastName,
				user.username,
				user.email,
				user.company,
				user.jobTitle,
				orgModel.getStringPath(),
				user.role,
				user.disabled,
			);
			if (currentUser.ability.can('manage', this.user)) {
				currentWidget.saveChangesButton.prop('disabled', false);
				this.saveChangesButton.show();
			} else {
				currentWidget.disableAllInputs(true);
				currentWidget.saveChangesButton.prop('disabled', true);
				this.saveChangesButton.hide();
			}

			// Check if logged in user can edit the e-mail
			// of the profile user
			const canEditEmail = currentWidget.canUserEditEmail(
				currentUser,
				currentWidget.user, // NOTE: CASL check only accepts Model
			);
			if (canEditEmail) {
				this.emailInput.prop('disabled', false);
			}

			// Can the logged in user set the account disabled flag
			// of the profile user?
			const canUserDisableAccount = currentWidget.canUserDisableAccount(
				currentUser,
				currentWidget.user,
			);
			if (canUserDisableAccount) {
				currentWidget.accountEnabledContainer.show();
			}

			currentWidget.getAndShowImage(user, callback);
		})
		.catch((err) => {
			console.error('Error getting user data: ', err);
			callback.call(currentWidget, err);
		});
};

WidgetUserAccountProfile.prototype.selectRole = function(userRole) {
	$(`#${this.roleInputId} option`).each(function() {
		const input = $(this);
		if (input.val() === userRole) {
			input.prop('selected', true);
		} else {
			input.prop('selected', false);
		}
	});
};

WidgetUserAccountProfile.prototype.getSelectedRole = function() {
	const selectedRole = $(`#${this.roleInputId} option:selected`);
	return selectedRole.val();
};

WidgetUserAccountProfile.prototype._updateInputs = function(
	firstName,
	lastName,
	username,
	email,
	company,
	jobTitle,
	organization,
	role,
	disabled,
) {
	this.saveChangesButton.prop('disabled', true);

	this.firstNameInput.val(firstName);
	this.lastNameInput.val(lastName || null);
	this.usernameInput.val(username);
	this.emailInput.val(email);
	this.companyInput.val(company);
	this.jobTitleInput.val(jobTitle);
	this.organizationInput.text(organization);
	this.selectRole(role);
	this.accountEnabledSwitch.prop('checked', !disabled);
};

WidgetUserAccountProfile.prototype._onInputChanged = function() {
	this.saveChangesButton.prop('disabled', false);
	this.errorContainer.hide();
};

WidgetUserAccountProfile.prototype._onKeyDown = function(e) {
	this.saveChangesButton.prop('disabled', false);

	if (e && e.keyCode == 13) {
		this.saveInformation((err) => {
			if (err) {
				currentWidget.errorContainer.show();
				currentWidget.saveChangesButton.prop('disabled', true);
				return;
			}
		});
	}
};

// Perform save operation on current user model
WidgetUserAccountProfile.prototype.doSave = function(callback) {
	const currentWidget = this;
	this.user
		.save()
		.then((newUser) => {
			const editingOwnProfile =
				currentWidget.getMainContainer().getCurrentUser().username ===
				newUser.username;

			if (editingOwnProfile) {
				currentWidget.getMainContainer().setCurrentUser(newUser);
				currentWidget.getMainContainer().event('profileInfoChanged');
			}

			currentWidget
				.getMainContainer()
				.showPopupInfoMessage(
					currentWidget.getLanguageTag(
						'successSettingUserInformation',
					),
				);
			callback.call(currentWidget, false);
		})
		.catch((err) => {
			console.error('Error setting user information', err);
			currentWidget
				.getMainContainer()
				.showPopupErrorMessage(
					currentWidget.getLanguageTag('errorSettingUserInformation'),
				);
			callback.call(currentWidget, err);
		});
};

WidgetUserAccountProfile.prototype.canUserEditEmail = function(
	loggedInUser,
	profileUser,
	callback,
) {
	// look right away for matching ids
	const editingOwnProfile = loggedInUser.id === profileUser.data.id;
	// Cannot change email on your own profile
	if (editingOwnProfile) {
		return false;
	}
	// If you're not editing your own profile, we need to check abilities
	if (loggedInUser.ability.can('manage', profileUser)) {
		// You can edit the user's email
		return true;
	}
};

WidgetUserAccountProfile.prototype.canUserDisableAccount = function(
	loggedInUser,
	profileUser,
	callback,
) {
	// look right away for matching ids
	const editingOwnProfile = loggedInUser.id === profileUser.data.id;
	// Cannot disable your own profile
	if (editingOwnProfile) {
		return false;
	}
	// If you're not editing your own profile, we need to check abilities
	if (loggedInUser.ability.can('manage', profileUser)) {
		// You can set the user's disabled flag
		return true;
	}
};

WidgetUserAccountProfile.prototype.validate = function() {
	let valid = true;
	const isEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
	const { firstName, lastName, email } = this.user.data;
	if (!firstName || firstName.trim() === '') {
		valid = false;
	}
	if (!lastName || lastName.trim() === '') {
		valid = false;
	}
	if (!email || email.trim() === '' || !isEmail.test(email)) {
		valid = false;
	}
	return valid;
};

WidgetUserAccountProfile.prototype.saveInformation = function(callback) {
	var currentWidget = this;

	callback = callback || function() {};

	this.user.data.firstName = currentWidget.firstNameInput.val().trim();
	this.user.data.lastName = currentWidget.lastNameInput.val().trim();
	this.user.data.email = currentWidget.emailInput.val().trim();
	this.user.data.company = currentWidget.companyInput.val().trim();
	this.user.data.jobTitle = currentWidget.jobTitleInput.val().trim();
	this.user.data.role = currentWidget.getSelectedRole();
	this.user.data.disabled = !currentWidget.accountEnabledSwitch.is(
		':checked',
	);

	const isValid = this.validate();
	if (!isValid) {
		callback.call(currentWidget, { type: 'invalidFormData' }, false);
		return;
	}

	this.doSave(callback);
};

WidgetUserAccountProfile.prototype.verifyImageData = function(
	imageData,
	callback,
) {
	var currentWidget = this;

	if (imageData.constructor === File) {
		var file = imageData;
		var fileName = imageData.name;

		if (checkForFileType(fileName, this.VALID_FILE_TYPES) === false) {
			callback.call(this, {
				type: 'invalidImageUploadExtensionError',
			});

			return;
		}

		callback.call(this, false, imageData);
		return;
	} else {
		callback.call(this, false, imageData);
		return;
	}
};

WidgetUserAccountProfile.prototype.readImageFromFile = function(
	imageFile,
	callback,
) {
	var currentWidget = this;

	var fileReader = new FileReader();
	var base64ImageData = null;

	if (isPhonegap()) {
		fileReader.onloadend = function(e) {
			base64ImageData = e.target.result;

			callback.call(currentWidget, false, base64ImageData);

			return;
		};
	} else {
		fileReader.addEventListener('load', function(e) {
			base64ImageData = e.target.result;

			callback.call(currentWidget, false, base64ImageData);

			return;
		});
	}

	fileReader.readAsDataURL(imageFile);
	return;
};

WidgetUserAccountProfile.prototype.setImage = function(
	base64ImageDataOrUrl,
	callback,
) {
	var currentWidget = this;

	if (!base64ImageDataOrUrl) {
		this.image = null;

		callback.call(this, false, null);

		return;
	}

	var img = new Image();
	var canvas = document.createElement('canvas');

	img.src = base64ImageDataOrUrl;

	img.onload = function() {
		if (
			img.height > currentWidget.UPLOADED_IMAGE_HEIGHT_MAX ||
			img.width > currentWidget.UPLOADED_IMAGE_WIDTH_MAX
		) {
			canvas.height = currentWidget.UPLOADED_IMAGE_HEIGHT_MAX;
			canvas.width =
				(currentWidget.UPLOADED_IMAGE_HEIGHT_MAX * img.width) /
				img.height;
		} else {
			canvas.height = img.height;
			canvas.width = img.width;
		}

		canvas
			.getContext('2d')
			.drawImage(img, 0, 0, canvas.width, canvas.height);

		var imageData = canvas.toDataURL('image/png') || null;

		if (!imageData) {
			callback.call(currentWidget, { type: 'invalidImageData' });
			return;
		}

		const api = currentWidget.getApiV2().apis;
		api.users
			.updateUserImage(
				{ id: currentWidget.user.data.id },
				{ requestBody: imageData },
			)
			.then(() => {
				currentWidget.getMainContainer().event('profileImageChanged');
				callback.call(currentWidget, false);
			})
			.catch((err) => {
				callback.call(currentWidget, err);
			});
	};
};

WidgetUserAccountProfile.prototype.unsetImage = function(callback) {
	callback = callback || function() {};

	var currentWidget = this;

	const api = currentWidget.getApiV2().apis;
	api.users
		.deleteUserImage({ id: currentWidget.user.data.id })
		.then(() => {
			currentWidget.getMainContainer().event('profileImageChanged');
			currentWidget.getAndShowImage(currentWidget.user.data, callback);
		})
		.catch((err) => {
			callback.call(currentWidget, err);
		});
};

WidgetUserAccountProfile.prototype._onImageFileInputChanged = function(event) {
	var currentWidget = this;

	event.preventDefault();
	event.stopPropagation();

	var file = event.target.files[0];

	function reportError(err) {
		currentWidget
			.getMainContainer()
			.showPopupErrorMessage(currentWidget.getLanguageTag(err.type));

		console.error(err);
		return;
	}

	if (file) {
		this.verifyImageData(file, function(err, verifiedImageData) {
			if (err) {
				reportError.call(this, err);
				return;
			}

			this.readImageFromFile(file, function(err, base64ImageData) {
				if (err) {
					reportError.call(this, err);
					return;
				}

				this.setImage(base64ImageData, function(err, data) {
					if (err) {
						reportError.call(this, err);
						return;
					}

					this.update();
				});

				return;
			});
		});
	}
};

WidgetUserAccountProfile.prototype.UPLOADED_IMAGE_HEIGHT_MAX = 256;
WidgetUserAccountProfile.prototype.UPLOADED_IMAGE_WIDTH_MAX = 256;

WidgetUserAccountProfile.prototype.VALID_FILE_TYPES = [
	'.png',
	'.jpg',
	'.gif',
	'.jpeg',
	'.svg',
	'.webp',
];

WidgetUserAccountProfile.prototype.ICON = './Resources/icons/UserProfile.svg';

WidgetUserAccountProfile.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'Information',
			firstName: 'First Name',
			lastName: 'Last Name',
			username: 'Username',
			email: 'Email Address',
			company: 'Company',
			organization: 'Organization',
			jobTitle: 'Job Title',
			saveChanges: 'Save Changes',
			userImage: 'Profile Image',
			removeImage: 'Remove Image',
			setProfileImage: 'Set Image',
			successSettingUserInformation: 'Information saved.',
			errorSettingUserInformation:
				'There was an error when setting your profile information. Please try again.',
			removingImageTitle: 'Remove Image',
			confirmRemovingImage:
				'Are you sure you want to remove your profile image?',
			confirmRemovingImageButton: 'Remove',
			invalidImageUploadExtensionError: 'Invalid image file selected',
			accountEnabledLabel: 'Account Enabled',
		},
	},
);
