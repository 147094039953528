function PluginBLECentral() {
	PluginBLECentral.prototype.SELECTED_BACKEND.call(this);
}

PluginBLECentral.prototype.SELECT_BACKEND = function() {
	var SELECT_BACKEND = PluginBLECentral.prototype.SELECT_BACKEND;

	//We've already loaded and don't need to do this again.
	if (PluginBLECentral.prototype.BACKENDS === undefined) {
		return BLECentralBackendNull;
	}

	for (var i = 0; i < PluginBLECentral.prototype.BACKENDS.length; i++) {
		if (PluginBLECentral.prototype.BACKENDS[i].prototype.IS_AVAILABLE()) {
			var SELECTED_BACKEND = PluginBLECentral.prototype.BACKENDS[i];
			var BACKENDS = PluginBLECentral.prototype.BACKENDS;

			console.log('PluginBLECentral: Using ' + SELECTED_BACKEND.name);

			PluginBLECentral.prototype = Object.create(
				SELECTED_BACKEND.prototype,
			);
			PluginBLECentral.prototype.constructor = PluginBLECentral;
			PluginBLECentral.prototype.SELECT_BACKEND = SELECT_BACKEND;
			PluginBLECentral.prototype.SELECTED_BACKEND = SELECTED_BACKEND;
			PluginBLECentral.prototype.BACKENDS = BACKENDS;

			return SELECTED_BACKEND;
			break;
		}
	}

	return BLECentralBackendNull;
};

//This is the order for which we will see what plugins are available for use.
PluginBLECentral.prototype.BACKENDS = [
	BLECentralBackendPhonegap,
	BLECentralBackendClientAgent,
	BLECentralBackendNull,
];
