function ElementAppUIBase(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);

	this._crocPanel = null;

	if (
		this._parent._crocPanel === undefined ||
		this._parent._crocPanel === null
	) {
		this.error({ type: 'noCrocPanelForUI' });
		return;
	}

	this._crocPanel = this._parent._crocPanel;
	this._editHandles = null;
	this._editable = false;
}

ElementAppUIBase.prototype = Object.create(ElementBase.prototype);
ElementAppUIBase.prototype.constructor = ElementAppUIBase;

ElementAppUIBase.prototype._setEditable = function(enable, dontRemoveHandles) {
	ElementAppBase.prototype._setEditable.call(this);

	dontRemoveHandles = dontRemoveHandles || false;

	this._editable = enable;

	if (enable) {
		var editHandlesOffset = this._editHandles.getOffset();
		this._crocPanel.addChild(
			this._editHandles,
			editHandlesOffset.x,
			editHandlesOffset.y,
			0,
			0,
			0,
		);
		this._crocPanel.childInFrontOf(this._editHandles, this._crocObject);
		this._setLayout(this._parent.width, this._parent.height, function() {});
	} else if (!dontRemoveHandles) {
		this._crocPanel.removeChild(this._editHandles);
	}
};

ElementAppUIBase.prototype._onEditHandlesFocus = function() {
	this._parent.event('elementFocus', { name: this._name });
	return;
};

ElementAppUIBase.prototype._setCrocObject = function(crocObject) {
	var currentElement = this;

	this._crocObject = crocObject;

	this._editHandles = new LayoutEditorHandles(
		this._crocPanel.getRoot(),
		this._parent,
		this._controller,
		this._crocPanel,
		this._crocObject,
	);

	this._editHandles.addEventListener('focus', function() {
		currentElement._onEditHandlesFocus();
	});

	this._crocPanel.addChild(this._crocObject, 0, 0, 0, 0, 0);
	this._setEditable(this._parent.editable, true);
	this._setLayout(this._parent.width, this._parent.height, function() {});
	this._crocObject.setVisible(Boolean(this._properties.visible));
};

ElementAppUIBase.prototype._moveToBack = function() {
	this._crocPanel.childToBack(this._crocObject);

	this._editHandles.blur();

	if (this._editable) {
		this._crocPanel.childInFrontOf(this._editHandles, this._crocObject);
	}
};

ElementAppUIBase.prototype._moveToFront = function() {
	this._crocPanel.childToFront(this._crocObject);

	this._editHandles.blur();

	if (this._editable) {
		this._crocPanel.childInFrontOf(this._editHandles, this._crocObject);
	}
};

ElementAppUIBase.prototype._onPropertyChange = function() {
	ElementAppBase.prototype._onPropertyChange.call(this);

	this._setLayout(this._parent.width, this._parent.height, function() {});
	this.setVisible(this._properties.visible, function() {});
};

ElementAppUIBase.prototype._remove = function() {
	ElementAppBase.prototype._remove.call(this);

	this._crocPanel.removeChild(this._crocObject);

	if (this._editable) {
		this._crocPanel.removeChild(this._editHandles);
	}
};

ElementAppUIBase.prototype.setEnabled = function(enabled, callback) {};

ElementAppUIBase.prototype.enable = function(callback) {};

ElementAppUIBase.prototype.disable = function(callback) {};

ElementAppUIBase.prototype.setVisible = function(visible, callback) {
	this._setProperty('visible', visible, function(err) {
		this._crocObject.setVisible(visible);

		callback.call(this, false);
	});
};

ElementAppUIBase.prototype.hide = function(callback) {
	this._setProperty('visible', false, function(err) {
		this._crocObject.setVisible(false);

		callback.call(this, false);
	});
};

ElementAppUIBase.prototype.show = function(callback) {
	this._setProperty('visible', true, function(err) {
		this._crocObject.setVisible(true);

		callback.call(this, false);
	});
};

ElementAppUIBase.prototype._setLayout = function(width, height, callback) {
	this._getProperty('layouts', function(err, layouts) {
		if (err) {
			callback.call(this, err);
			return this.error(err);
		}

		if (
			layouts[width] === undefined ||
			layouts[width][height] === undefined
		) {
			callback.call(this, { type: 'elementHasNoLayout' });
			return this.error({ type: 'elementHasNoLayout' });
		}

		var currentLayout = layouts[width][height];

		this._crocObject.setTargetWidth(currentLayout.width);
		this._crocObject.setTargetHeight(currentLayout.height);
		this._crocPanel.setChildOrientation(
			this._getCrocObject(),
			currentLayout.x,
			currentLayout.y,
			currentLayout.rotation,
		);

		if (this._editHandles !== null) {
			var editHandlesOffset = this._editHandles.getOffset();
			this._crocPanel.setChildOrientation(
				this._editHandles,
				editHandlesOffset.x + currentLayout.x,
				editHandlesOffset.y + currentLayout.y,
				currentLayout.rotation,
			);
		}

		callback.call(this, false);
	});
};

ElementAppUIBase.prototype._getCrocObject = function() {
	return this._crocObject;
};
