function WidgetSettingsForm(id, api, parent, options) {
	var currentWidget = this;

	WidgetPanelBase.call(this, id, api, parent, options);

	this._fields = null;
	this._jsonEditors = null;
	this._geolocWidgets = null;
	this._aceEditors = {};

	// Some inputs work on triggers only so we need to store that data somewhere
	this._valueData = {};

	this.footerContainerId = this.generateChildId('footerContainer');
	this.footerWidget = null;
}

WidgetSettingsForm.prototype = Object.create(WidgetPanelBase.prototype);
WidgetSettingsForm.prototype.constructor = WidgetSettingsForm;

WidgetSettingsForm.prototype.initialize = function(callback) {
	var fieldsFromOptions = this.getOptions().fields || null;

	if (fieldsFromOptions !== null) {
		this.setForm(fieldsFromOptions);
	}

	WidgetPanelBase.prototype.initialize.call(this, callback);
};

WidgetSettingsForm.prototype.validate = function() {
	var currentValues = this.getValues();
	var valid = true;

	for (var i = 0; i < this._fields.length; i++) {
		var currentField = this._fields[i];

		if (currentField.validator) {
			if (!currentField.validator(currentValues[currentField.name])) {
				valid = false;
				this.setFieldValid(currentField.name, false);
			} else {
				this.setFieldValid(currentField.name, true);
			}
		}
	}

	if (valid) {
		this.getContainer()
			.find('.WidgetSettingsForm-InvalidMessage')
			.hide();
		this.footerWidget.setButtonDisabled('confirmed', false);
	} else {
		this.getContainer()
			.find('.WidgetSettingsForm-InvalidMessage')
			.show();
		this.footerWidget.setButtonDisabled('confirmed', true);
	}

	return valid;
};

WidgetSettingsForm.prototype.setFieldValid = function(name, valid) {
	if (valid) {
		this.getContainer()
			.find(`*[data-settingsformlabel=${name}]`)
			.removeClass('WidgetSettingsForm-LabelContainer-Invalid');
	} else {
		this.getContainer()
			.find(`*[data-settingsformlabel=${name}]`)
			.addClass('WidgetSettingsForm-LabelContainer-Invalid');
	}
};

WidgetSettingsForm.prototype.updateDeviceDataSelectInfo = function(
	name,
	value,
) {
	this.getContainer()
		.find(`*[data-settingsformname=${name}]`)
		.find('.WidgetSettingsForm-DeviceDataSelectInfo')
		.html(`<pre>${JSON.stringify(value, 0, 2)}</pre>`);
};

WidgetSettingsForm.prototype._attachColorSwatchControls = function(input) {
	var currentWidget = this;

	var colorSwatches = input.find('.WidgetSettingsForm-ColorSwatch');

	for (var i = 0; i < colorSwatches.length; i++) {
		$(colorSwatches[i]).click(function() {
			var selectedItems = $(this).siblings('[selected]');

			for (var j = 0; j < selectedItems.length; j++) {
				$(selectedItems[j]).removeAttr('selected');
			}

			$(this).attr('selected', 'selected');

			currentWidget.validate();
		});
	}
};

WidgetSettingsForm.prototype._attachDeviceDataSelectControls = function(
	input,
	options,
	values,
) {
	var currentWidget = this;
	var button = input.find('button');

	var valueName = $(input).attr('data-settingsformname');
	var deviceSelectInputContainerId = this.generateChildId(valueName);

	var container = $(input).find(
		'.WidgetSettingsForm-DeviceDataSelectContainer',
	);
	container.attr('id', deviceSelectInputContainerId);

	currentWidget.removeChildWidget(deviceSelectInputContainerId, function(
		err,
	) {
		currentWidget.addChildWidget(
			WidgetDeviceDataSelect,
			deviceSelectInputContainerId,
			options,
			function(err, newDeviceDataSelectWidget) {
				newDeviceDataSelectWidget.hide();

				newDeviceDataSelectWidget.addEventListener(
					'dismissed',
					function() {
						this.hide();
					},
				);

				newDeviceDataSelectWidget.addEventListener(
					'confirmed',
					function() {
						var currentSubWidget = this;

						input.attr(
							'data-value',
							JSON.stringify(this.getValues()),
						);

						window.requestAnimationFrame(function() {
							currentWidget.update();
							currentSubWidget.hide();
						});
					},
				);

				$(button).click(function() {
					var container = $(
						$(this)
							.parent()
							.siblings(
								'.WidgetSettingsForm-DeviceDataSelectContainer',
							),
					);
					var deviceDataSelect = currentWidget.getChildWidget(
						container[0].id,
					);
					deviceDataSelect.show();
					deviceDataSelect.showDeviceSelect();
				});
			},
		);
	});
};

WidgetSettingsForm.prototype._attachDeviceSelectControls = function(
	input,
	values,
) {
	return this._attachDeviceDataSelectControls(
		input,
		{ onlyDeviceSelect: true },
		values,
	);
};

WidgetSettingsForm.prototype._attachDeviceDataStoreSelectControls = function(
	input,
	values,
) {
	return this._attachDeviceDataSelectControls(input, {}, values);
};

WidgetSettingsForm.prototype._attachDeviceLastValuesSelectControls = function(
	input,
	values,
	variables,
) {
	variables = variables || [
		{
			name: 'value',
			label: getLanguageTag(this.constructor, 'value'),
			selectMultiple: false,
		},
	];

	return this._attachDeviceDataSelectControls(
		input,
		{ variables: variables, selectLastValues: true },
		values,
	);
};

WidgetSettingsForm.prototype._attachDeviceVariableSelectControls = function(
	input,
	values,
	variables,
) {
	variables = variables || [
		{
			name: 'value',
			label: getLanguageTag(this.constructor, 'value'),
			selectMultiple: false,
		},
	];

	return this._attachDeviceDataSelectControls(
		input,
		{ variables: variables },
		values,
	);
};

WidgetSettingsForm.prototype._attachJsonEditorControls = function(
	input,
	values,
	options,
) {
	var currentWidget = this;

	// create the editor
	var jsonEditorOptions = {
		dom: {
			width: options && options.width ? options.width : 400,
			height: options && options.height ? options.height : 400,
		},
		jsonEditor: {
			history: true,
			mode: 'code',
		},
	};

	var container = input.get(0);

	var jsonEditor = new JSONEditor(container, jsonEditorOptions.jsonEditor);

	try {
		values = JSON.parse(values);
	} catch (e) {
		values = values;
	}

	// set json
	var json = values;

	jsonEditor.set(json);

	if (!this._jsonEditors) {
		this._jsonEditors = {};
	}

	this._jsonEditors[$(input).data('settingsformname')] = jsonEditor;

	return jsonEditor;
};

WidgetSettingsForm.prototype._attachGeolocationControls = async function(
	input,
	values,
	options,
) {
	const currentWidget = this;

	// The "input" argument is the native DOM element
	const inputName = $(input).data('settingsformname');

	// The "values" argument represents the last
	// selected coordinates for the map by the user
	// which will be drawn on the map if available

	// The "options" argument expects values for
	// the child widget (WidgetDeviceGeolocation)
	// and should default some mandatory options
	// if they are not provided.

	// Create an ID to track the input to allow
	// more than one instance of the widget
	// in the form to be spawned.
	const id = this.generateChildId(inputName);

	// Give the input element that was rendered
	// by handlebars the unique ID so that the
	// child widget can spawn within it.
	input.attr('id', id);

	this.addChildWidget(
		WidgetDeviceGeolocation,
		id,
		options,
		(error, geolocWidget) => {
			if (error) {
				console.debug(
					`[WidgetSettingsForm] Unable to add geolocation input due to ${(error,
					error.stack)}`,
				);
				return;
			}

			// The device map widget should not be
			// updating periodically, this is very
			// jarring to the user
			clearInterval(geolocWidget.deviceMapWidget._updateInterval);

			// Set some coordinates if they have
			// been provided
			if (values) {
				geolocWidget.setValues(values);
			}

			// Track the geolocation widgets as
			// part of the state in case managing
			// them after construction of the form
			// is needed.
			if (!this._geolocWidgets) {
				this._geolocWidgets = {};
			}
			this._geolocWidgets[inputName] = geolocWidget;

			return geolocWidget;
		},
	);
};

WidgetSettingsForm.prototype._attachControls = async function() {
	var currentWidget = this;

	// Used for asynchronous initialization
	// of input controls
	const initPromises = [];

	var inputs = this.getContainer().find('*[data-settingsformname]');

	for (var i = 0; i < inputs.length; i++) {
		var entryName = $(inputs[i]).attr('data-settingsformname');
		var entryType = $(inputs[i]).attr('data-settingsformtype');
		var entryValue = $(inputs[i]).attr('data-value');

		switch (entryType) {
			case 'dateRangePicker':
				var currentValue = null;

				try {
					currentValue = JSON.parse(entryValue);
				} catch (e) {
					currentValue = null;
				}

				$(inputs[i]).daterangepicker({
					parentEl: $(`#${this.getMainContainer().getId()}`),
					startDate: new Date(currentValue.startDate || Date.now()),
					endDate: new Date(currentValue.endDate || Date.now()),
					timePicker: true,
				});

				currentWidget._valueData[entryName] = {
					startDate: new Date(currentValue.startDate || Date.now()),
					endDate: new Date(currentValue.endDate || Date.now()),
				};

				$(inputs[i]).on('apply.daterangepicker', function(e, picker) {
					var entryName = $(this).attr('data-settingsformname');

					currentWidget._valueData[entryName] = {
						startDate: new Date(picker.startDate._d),
						endDate: new Date(picker.endDate._d),
					};
				});

				break;

			case 'code':
				currentWidget._aceEditors[entryName] = window.ace.edit(
					inputs[i],
				);

				var fieldData = this._getFieldByName(entryName);

				currentWidget._aceEditors[entryName]
					.getSession()
					.setMode(fieldData.mode);
				currentWidget._aceEditors[entryName].setValue(fieldData.value);
				currentWidget._aceEditors[entryName].clearSelection();
				currentWidget._aceEditors[entryName].clearSelection();
				currentWidget._aceEditors[entryName]
					.getSession()
					.setMode('ace/mode/javascript');
				currentWidget._aceEditors[entryName].setTheme(
					'ace/theme/kuroir',
				);

				// Taken from WidgetElementEditor
				currentWidget._aceEditors[entryName].setOptions({
					enableBasicAutocompletion: true,
					enableLiveAutocompletion: true,
					dragEnabled: false,
					fontSize: '100%',
					showPrintMargin: false,
				});

				break;

			case 'jsonEditor':
				var fieldData = this._getFieldByName(entryName);
				var fieldOptions = fieldData.options || {};

				this._attachJsonEditorControls(
					$(inputs[i]),
					entryValue,
					fieldOptions,
				);

				break;

			case 'colorSwatch':
				this._attachColorSwatchControls($(inputs[i]));

				break;

			case 'deviceSelect':
				if (entryValue === '') {
					entryValue = 'null';
				}

				this._attachDeviceSelectControls(
					$(inputs[i]),
					JSON.parse(entryValue),
				);

				break;

			case 'deviceDataStoreSelect':
				if (entryValue === '') {
					entryValue = 'null';
				}

				this._attachDeviceDataStoreSelectControls(
					$(inputs[i]),
					JSON.parse(entryValue),
				);

				break;

			case 'deviceLastValuesSelect':
				if (entryValue === '') {
					entryValue = 'null';
				}

				var fieldData = this._getFieldByName(entryName);
				var fieldOptions = fieldData.options || {};
				var fieldVariables = fieldOptions.variables || null;

				this._attachDeviceLastValuesSelectControls(
					$(inputs[i]),
					JSON.parse(entryValue),
					fieldVariables,
				);

				break;

			case 'deviceVariableSelect':
				if (entryValue === '') {
					entryValue = 'null';
				}

				var fieldData = this._getFieldByName(entryName);
				var fieldOptions = fieldData.options || {};
				var fieldVariables = fieldOptions.variables || null;

				this._attachDeviceVariableSelectControls(
					$(inputs[i]),
					JSON.parse(entryValue),
					fieldVariables,
				);

				break;

			case 'geolocation':
				const input = $(inputs[i]);
				var fieldData = this._getFieldByName(entryName).value;
				var fieldOptions = (fieldData || {}).options || {
					zoom: 1,
					language: {
						'en-US': {
							useCurrentLocation: 'Use Device Location',
						},
					},
					dontFetchAll: true, // Don't fetch all the devices available to the user on the map
				};

				if (!fieldData || fieldData.length <= 0) {
					// Default to 0,0
					fieldData = { lat: 0, lon: 0 };
				}

				initPromises.push(
					this._attachGeolocationControls(
						input,
						fieldData,
						fieldOptions,
					),
				);

				break;

			default:
				break;
		}
	}

	// Return the promises and fulfill
	// them to fully initialize the form
	return Promise.all(initPromises);
};

WidgetSettingsForm.prototype._attachValidators = function() {
	const currentWidget = this;

	var inputs = this.getContainer().find('*[data-settingsformname]');

	for (var i = 0; i < inputs.length; i++) {
		var entryName = $(inputs[i]).attr('data-settingsformname');
		var entryType = $(inputs[i]).attr('data-settingsformtype');

		switch (entryType) {
			case 'toggle':
				$(inputs[i]).change(function() {
					var value = $(this).prop('checked');
					var name = $(this).attr('data-settingsformname');
					var type = $(this).attr('data-settingsformtype');
					currentWidget.event('valueChanged', {
						value: value,
						name: name,
						type: type,
					});
					currentWidget.validate();
				});
				break;

			case 'geolocation':
				// Refer to WidgetDeviceGeolocation
				// and WidgetDeviceMap

				// WidgetDeviceGeolocation
				const geolocWidget = currentWidget.getChildWidget(entryName);
				const { latitudeInput, longitudeInput } = geolocWidget;

				// WidgetDeviceMap
				const deviceMapWidget = geolocWidget.getChildWidget(
					geolocWidget.deviceMapContainerId,
				);
				const { googleMap } = deviceMapWidget;

				// Monitor map inputs
				// https://developers.google.com/maps/documentation/javascript/events
				googleMap.addListener('click', (event) => {
					const value = {
						lat: event.latLng.lat(),
						lon: event.latLng.lng(),
					};
					currentWidget.event('valueChanged', {
						value: value,
						name: entryName,
						type: entryType,
					});
					currentWidget.validate();
					geolocWidget.setValues(value, true);
				});

				// Monitor parent container
				// receives propagated events
				// from the text container
				const geolocWidgetContainer = geolocWidget.getContainer();
				geolocWidgetContainer.change((event) => {
					const value = {
						lat: parseFloat(latitudeInput.val()),
						lon: parseFloat(longitudeInput.val()),
					};
					currentWidget.event('valueChanged', {
						value: value,
						name: entryName,
						type: entryType,
					});
					currentWidget.validate();
					geolocWidget.setValues(value, true);
				});

				break;

			case 'checkbox':
				$(inputs[i]).change(function() {
					var value = $(this).prop('checked');
					var name = $(this).attr('data-settingsformname');
					var type = $(this).attr('data-settingsformtype');

					currentWidget.event('valueChanged', {
						value: value,
						name: name,
						type: type,
					});

					currentWidget.validate();
				});
				break;

			case 'code':
				$(inputs[i]).on('keyup', function(e) {
					var value = ace
						.edit($(this))
						.getSession()
						.getValue();
					var name = $(this).attr('data-settingsformname');
					var type = $(this).attr('data-settingsformtype');

					currentWidget.event('valueChanged', {
						value: value,
						name: name,
						type: type,
					});

					currentWidget.validate();
				});

				break;

			case 'number':
				$(inputs[i]).change(function() {
					var value = parseFloat($(this).val());
					var name = $(this).attr('data-settingsformname');
					var type = $(this).attr('data-settingsformtype');

					currentWidget.event('valueChanged', {
						value: value,
						name: name,
						type: type,
					});

					currentWidget.validate();
				});

				break;

			case 'radio':
				$(inputs[i]).change(function() {
					var value = $(this)
						.find('input:checked')
						.val();
					var name = $(this).attr('data-settingsformname');
					var type = $(this).attr('data-settingsformtype');

					currentWidget.event('valueChanged', {
						value: value,
						name: name,
						type: type,
					});

					currentWidget.validate();
				});
				break;

			case 'dateRangePicker':
				$(inputs[i]).on('apply.daterangepicker', function(e, picker) {
					var entryName = $(this).attr('data-settingsformname');
					var name = $(this).attr('data-settingsformname');
					var type = $(this).attr('data-settingsformtype');

					currentWidget.event('valueChanged', {
						value: {
							startDate: new Date(picker.startDate._d),
							endDate: new Date(picker.endDate._d),
						},
						name: name,
						type: type,
					});
				});

				break;

			default:
				// On iOS, and potentially others input event will catch "delete", "copy", "paste" ect where change will not
				$(inputs[i]).on('input', function() {
					var value = $(this).val();
					var name = $(this).attr('data-settingsformname');
					var type = $(this).attr('data-settingsformtype');

					currentWidget.event('valueChanged', {
						value: value,
						name: name,
						type: type,
					});

					currentWidget.validate();
				});

				break;
		}
	}

	return;
};

WidgetSettingsForm.prototype._getFieldByName = function(name) {
	for (var i = 0; i < this._fields.length; i++) {
		if (this._fields[i].name === name) {
			return this._fields[i];
		}
	}

	return null;
};

WidgetSettingsForm.prototype.getValues = function() {
	var outputData = {};

	var inputs = this.getContainer().find('*[data-settingsformname]');

	for (var i = 0; i < inputs.length; i++) {
		var entryName = $(inputs[i]).attr('data-settingsformname');
		var entryType = $(inputs[i]).attr('data-settingsformtype');

		var entryToggled = $(inputs[i])
			.closest('.WidgetSettingsForm-InputContainer')
			.find('.WidgetSettingsForm-Field-Toggle-Checkbox')
			.prop('checked');
		if (!entryToggled) {
			// Skip over this value if the checkbox for the field is not checked.
			console.debug(
				`Entry is not toggled, skipping ${entryName} and ${entryType}`,
			);
			continue;
		}

		var currentValue = null;

		switch (entryType) {
			case 'dateRangePicker':
				currentValue = this._valueData[entryName];
				break;

			case 'code':
				currentValue = ace
					.edit(inputs[i])
					.getSession()
					.getValue();
				break;

			case 'geolocation':
				const geolocWidget = this.getChildWidget(entryName);
				const { latitudeInput, longitudeInput } = geolocWidget;

				currentValue = {
					lat: parseFloat(latitudeInput.val()),
					lon: parseFloat(longitudeInput.val()),
				};

				break;

			case 'toggle':
				currentValue = $(inputs[i]).prop('checked');
				break;

			case 'checkbox':
				currentValue = $(inputs[i]).prop('checked');
				break;

			case 'number':
				currentValue = parseFloat($(inputs[i]).val());
				break;

			case 'bounds':
				currentValue = {
					min: parseFloat(
						$(inputs[i])
							.find('*[data-settingssubname="min"]')
							.val(),
					),
					max: parseFloat(
						$(inputs[i])
							.find('*[data-settingssubname="max"]')
							.val(),
					),
				};
				break;

			case 'radio':
				currentValue = $(inputs[i])
					.find('input:checked')
					.val();
				break;

			case 'colorSwatch':
				currentValue =
					$($(inputs[i]).find('[selected]')).attr('data-value') ||
					null;
				break;

			case 'deviceVariableSelect':
			case 'deviceDataStoreSelect':
			case 'deviceLastValuesSelect':
			case 'deviceSelect':
				currentValue = null;

				try {
					currentValue = JSON.parse($(inputs[i]).attr('data-value'));
				} catch (e) {
					currentValue = null;
				}

				break;

			case 'jsonEditor':
				// get json
				currentValue = this._jsonEditors[
					$(inputs[i]).data('settingsformname')
				].get();

				break;

			case 'select':
				var value = $(inputs[i]).val();

				try {
					value = JSON.parse(value);
				} catch (e) {
					value = $(inputs[i]).val();
				}

				currentValue = value;
				break;

			case 'state':
				var value = $(inputs[i]).val();

				try {
					value = JSON.parse(value);
				} catch (e) {
					value = $(inputs[i]).val();
				}

				currentValue = value;
				break;

			case 'country':
				var value = $(inputs[i]).val();

				try {
					value = JSON.parse(value);
				} catch (e) {
					value = $(inputs[i]).val();
				}

				currentValue = value;
				break;

			default:
				currentValue = $(inputs[i]).val();
				break;
		}

		outputData[entryName] = currentValue;
	}

	return outputData;
};

WidgetSettingsForm.prototype.updateFieldsValues = function() {
	var values = this.getValues();

	for (var i = 0; i < this._fields.length; i++) {
		if (values[this._fields[i].name]) {
			this._fields[i].value = values[this._fields[i].name];
		}
	}
};

WidgetSettingsForm.prototype.update = function(callback) {
	this.updateFieldsValues();

	this.setForm(this._fields, callback);
};

WidgetSettingsForm.prototype._processFields = function(callback) {
	var currentWidget = this;

	// Sometimes in the Handlebar template we need the
	// JSON form of an object so useful to do this.
	for (var i = 0; i < this._fields.length; i++) {
		this._fields[i].valueJSON = JSON.stringify(
			this._fields[i].value || null,
		);
	}

	var i = -1;

	function _processFieldsHelper() {
		i++;

		if (!(i < currentWidget._fields.length)) {
			callback.call(currentWidget, false);
			return;
		}

		switch (currentWidget._fields[i].type) {
			case 'select':
				for (
					var j = 0;
					j < currentWidget._fields[i].options.length;
					j++
				) {
					currentWidget._fields[i].options[
						j
					].valueJSON = JSON.stringify(
						currentWidget._fields[i].options[j].value,
					);
				}

				_processFieldsHelper();
				break;

			case 'state':
				var states = getIsoUsaState('_all');
				var stateOptions = [];

				for (var state in states) {
					stateOptions.push({
						label: states[state],
						value: state,
					});
				}

				currentWidget._fields[i].options = stateOptions;

				for (
					var j = 0;
					j < currentWidget._fields[i].options.length;
					j++
				) {
					currentWidget._fields[i].options[
						j
					].valueJSON = JSON.stringify(
						currentWidget._fields[i].options[j].value,
					);
				}

				_processFieldsHelper();
				break;

			case 'country':
				var countries = getIsoCountry('_all');
				var countryOptions = [];

				for (var country in countries) {
					countryOptions.push({
						label: countries[country],
						value: country,
					});
				}

				currentWidget._fields[i].options = countryOptions;

				for (
					var j = 0;
					j < currentWidget._fields[i].options.length;
					j++
				) {
					currentWidget._fields[i].options[
						j
					].valueJSON = JSON.stringify(
						currentWidget._fields[i].options[j].value,
					);
				}

				_processFieldsHelper();
				break;

			case 'deviceVariableSelect':
			case 'deviceDataStoreSelect':
			case 'deviceLastValuesSelect':
			case 'deviceSelect':
				// Do we not have the current device info for
				// a device uuid? If not then we have to get
				// it from the system.
				if (
					currentWidget._fields[i].value &&
					currentWidget._fields[i].value.deviceId &&
					!currentWidget._fields[i].value.deviceData
				) {
					const api = currentWidget.getApiV2().apis;

					api.devices
						.getDevice({
							id: currentWidget._fields[i].value.deviceId,
						})
						.then((deviceData) => {
							currentWidget._fields[
								i
							].value.deviceData = deviceData;
							currentWidget._fields[i].valueJSON = JSON.stringify(
								currentWidget._fields[i].value || null,
							);
							_processFieldsHelper();
						})
						.catch((err) => {
							console.error('Error getting device data ', err);
							_processFieldsHelper();
						});

					return;
				}

				_processFieldsHelper();

				break;

			default:
				_processFieldsHelper();
				break;
		}
	}

	_processFieldsHelper();
};

WidgetSettingsForm.prototype.setForm = function(fields, callback) {
	// This function will set all the fields
	// for the form and then at any time you
	// can get the field data from the widget.

	callback = callback || function() {};

	this._fields = fields.slice();

	var currentWidget = this;

	var invalidMessageLabel =
		this.getOptions().invalidMessageLabel ||
		getLanguageTag(this.constructor, 'invalid');
	var deviceNameLabel =
		this.getOptions().deviceNameLabel ||
		getLanguageTag(this.constructor, 'deviceName');
	var selectLabel =
		this.getOptions().selectLabel ||
		getLanguageTag(this.constructor, 'select');

	this._processFields(function(err) {
		this.renderTemplate(
			{
				toggleable: this.getOptions().toggleable,
				fields: this._fields,
				footerContainerId: this.footerContainerId,
				invalidMessageLabel: invalidMessageLabel,
				deviceNameLabel: deviceNameLabel,
				selectLabel: selectLabel,
				deviceUuidLabel: getLanguageTag(this.constructor, 'deviceUuid'),
				storageNameLabel: getLanguageTag(
					this.constructor,
					'storageName',
				),
			},
			WidgetSettingsForm.name,
		);

		this.removeAllChildrenWidgets((err) => {
			this.addChildWidget(
				WidgetButtonsFooter,
				this.footerContainerId,
				{},
				function(err, footerWidget) {
					this.footerWidget = footerWidget;

					// Consumer may override button choice
					const defaultButtons = [
						{
							label:
								this.getOptions().dismissLabel ||
								getLanguageTag(this.constructor, 'dismiss'),
							value: 'dismissed',
							classes: [`btn-cancel`],
						},
						{
							label:
								this.getOptions().confirmLabel ||
								getLanguageTag(this.constructor, 'save'),
							value: 'confirmed',
							classes: ['btn-primary'],
						},
					];

					const buttons =
						currentWidget.getOptions().buttons || defaultButtons;

					this.footerWidget.setButtons(buttons);

					this.footerWidget.addEventListener(
						'footerButtonPressed',
						function(value) {
							if (value === 'confirmed') {
								if (currentWidget.validate()) {
									currentWidget.event(
										'confirmed',
										currentWidget.getValues(),
									);
								} else {
									currentWidget.event(
										'invalidated',
										currentWidget.getValues(),
									);
								}
							} else {
								currentWidget.event(
									value,
									currentWidget.getValues(),
								);
							}
						},
					);

					this._attachControls().then(() => {
						this._attachValidators();
						this.validate();
						return callback.call(this, false);
					});
				},
			);
		});
	});
};

WidgetSettingsForm.prototype.language = deepAssign(
	{},
	WidgetPanelBase.prototype.language,
	{
		'en-US': {
			name: 'Settings',
			invalid: 'Some fields are invalid. Check then try again.',
			deviceName: 'Device Name',
			select: 'Select',
			value: 'Value',
			deviceName: 'Device Name',
			deviceUuid: 'Device UUID',
			storageName: 'Cloud Storage',
			close: 'Close',
		},
	},
);

WidgetSettingsForm.prototype.$_$ = function(done) {
	this.$_SetupWidgetTest(function(err, currentWidget) {
		this.addEventListener('confirmed', function() {
			console.log(this.getValues());
		});

		this.setForm([
			{
				name: 'testCheckbox',
				type: 'checkbox',
				label: 'Testing Checkbox',
				value: true,
				validator: function(a) {
					return a;
				},
			},

			{
				name: 'testSelect',
				type: 'select',
				label: 'Testing Select',
				options: [
					{ value: 'yes', label: 'Yes' },
					{ value: 'no', label: 'No' },
					{ value: 'maybe', label: 'Maybe' },
				],
				value: 'maybe',
			},

			{
				name: 'testRadio',
				type: 'radio',
				label: 'Testing Radio',
				options: [
					{ value: 'yes', label: 'Yes' },
					{ value: 'no', label: 'No' },
					{ value: 'maybe', label: 'Maybe' },
				],
				value: 'maybe',
			},

			{
				name: 'testText',
				type: 'text',
				label: 'Testing Text',
				value: 'Howdy howdy howdy',
				validator: function(a) {
					return a === 'Howdy howdy howdy';
				},
			},

			{
				name: 'testUsername',
				type: 'username',
				label: 'Testing Text',
				value: 'testing username',
			},

			{
				name: 'testSSID',
				type: 'ssid',
				label: 'Testing SSID',
				value: 'testingAP',
			},

			{
				name: 'testPassword',
				type: 'password',
				label: 'Testing Password',
				value: 'Howdy howdy howdy',
			},

			{
				name: 'testNumber',
				type: 'number',
				label: 'Testing Number',
				value: 22,
				min: -12,
				max: 20,
			},

			{
				name: 'testColorSwatch',
				type: 'colorSwatch',
				label: 'Select Color',
				value: 'red',
				validator: function(a) {
					return a !== 'yellow';
				},
				options: {},
			},

			{
				name: 'testIconSelect',
				type: 'iconSelect',
				value: null,
				options: {},
			},

			{
				name: 'testDeviceSelect',
				type: 'deviceSelect',
				label: 'Test Device Select',
				value: { deviceUuid: null },
				options: {},
			},

			{
				name: 'testDeviceDataStoreSelect',
				type: 'deviceDataStoreSelect',
				label: 'Test Device Data Store Select',
				value: { deviceUuid: null, storageName: null },
			},

			{
				name: 'testDeviceVariableSelect',
				type: 'deviceVariableSelect',
				label: 'Test Device Variable Select (2 Variables)',
				value: { deviceUuid: null, storageName: null, variables: null },
				options: {
					variables: [
						{
							name: 'first',
							label: 'First',
							value: null,
						},

						{
							name: 'second',
							label: 'Second',
							value: null,
						},
					],
				},
			},
		]);

		//TODO: Write the remaining tests to confirm the inputs.

		WidgetPanelBase.prototype.$_$.call(this, done);
	});
};
