function WidgetDeviceSettings(id, api, parentWidget, options) {
	WidgetBase.call(this, id, api, parentWidget, options);

	this.formWidget = null;
	this.footerWidget = null;

	this.settingsFormId = this.generateChildId('settingsFormId');
}

WidgetDeviceSettings.prototype = Object.create(WidgetBase.prototype);
WidgetDeviceSettings.prototype.constructor = WidgetDeviceSettings;

WidgetDeviceSettings.prototype.hasPermission = function(callback) {
	const currentWidget = this;
	const deviceId = (getHashCommand() || {}).deviceId || null;
	const api = _mainContainer.getApiV2().apis;
	const currentUser = _mainContainer.getCurrentUser();
	callback = callback || function() {};

	let device;

	api.devices
		.getDevice({ id: deviceId })
		.then((deviceData) => {
			const device = new Device(api, deviceData);
			return device.populate();
		})
		.then((populatedDevice) => {
			device = populatedDevice;
			return api.devices.getDeviceSettings({
				id: device.data.id,
			});
		})
		.then((settingsData) => {
			const { data: settings } = settingsData;
			// Are settings available and can the user read
			// the device data?
			if (
				settings.length > 0 &&
				currentUser.ability.can('read', device)
			) {
				return callback.call(currentWidget, false, true);
			}
			return callback.call(currentWidget, false, false);
		})
		.catch((error) => {
			console.error(
				`[WidgetDeviceSettings] Error checking widget permissions due to ${(error,
				error.stack)}`,
			);
			return callback.call(currentWidget, error, false);
		});
};

WidgetDeviceSettings.prototype.initialize = function(callback) {
	const currentWidget = this;
	this.renderTemplate(
		{
			titleLabel: this.getLanguageTag('name'),
			settingsFormId: this.settingsFormId,
			wifiSettingsButtonId: this.wifiSettingsButtonId,
			wifiSettingsButtonLabel: this.getLanguageTag('wifiSettings'),
			resetAllButtonId: this.resetAllButtonId,
			resetAllButtonLabel: this.getLanguageTag('resetAllButtonLabel'),
		},
		WidgetDeviceSettings.name,
	);

	this.addChildWidget(
		WidgetSettingsForm,
		this.settingsFormId,
		{
			// These opts will eventually
			// be set in this.update()
			fields: [],
			buttons: [],
		},
		function(err, formWidget) {
			this.formWidget = formWidget;
			this.footerWidget = formWidget.footerWidget;

			this.update((error) => {
				if (error) {
					console.error(
						`[WidgetDeviceSettings] Error initializing due to ${(error,
						error.stack)})`,
					);
					return callback.call(currentWidget, error);
				}
				WidgetBase.prototype.initialize.call(this, callback);
			});
		},
	);
};

// https://koliada.atlassian.net/browse/AI-3373
WidgetDeviceSettings.prototype.update = function(callback) {
	callback = callback || function() {};
	const currentWidget = this;
	const currentUser = this.getMainContainer().getCurrentUser();
	const api = this.getApiV2().apis;
	const deviceId = getHashCommand().deviceId;

	api.devices
		.getDevice({ id: deviceId })
		.then((deviceData) => {
			// Used for checking permissions when building out the form
			const device = new Device(api, deviceData);
			return device.populate();
		})
		.then((device) => {
			// Store this for building the form...
			// A populated device is necessary for
			// checking the CASL permissions correctly.
			currentDevice = device;
			return api.devices.getDeviceSettings({
				id: deviceId,
				sortBy: `+listOrder`,
			});
		})
		.then((response) => {
			const { data: settings } = response;
			const formOpts = this.formOptionsFrom(currentDevice, settings);

			this.formWidget.setForm(formOpts.fields);
			this.formWidget.footerWidget.setButtons(formOpts.buttons);
			this.formWidget.footerWidget.setButtonDisabled('submit', true);

			// Get the initial values so the UI know what changed, saves
			// the client from doing PUT requests for EVERY setting...
			const initialValues = this.formWidget.getValues();

			this.formWidget.addEventListener('valueChanged', (event) => {
				this.formWidget.footerWidget.setButtonDisabled('submit', false);
			});

			this.formWidget.footerWidget.addEventListener(
				'footerButtonPressed',
				async (eventData) => {
					switch (eventData) {
						case 'submit':
							await this.onSubmitButtonClicked(initialValues);
							break;
						case 'reset':
							await this.onResetAllButtonClicked();
							break;
						case 'wifi':
							this.wifiSettingsButtonClicked();
							break;
						default:
							console.debug(
								`[WidgetDeviceSettings] Footer button was pressed, but no recognizable case for event: ${eventData}`,
							);
							break;
					}
				},
			);

			// It's one thing to be able to read the device settings
			// its entirely different to be able to change them
			if (currentUser.ability.cannot('manage', currentDevice)) {
				currentWidget.disableAllInputs();
			}

			// Check all checkboxes by default implicity
			// In this scenario the checkboxes need to be checked
			// as they are hidden from the user. Disabled checkboxes
			// will prevent the user from submitting form data.
			this.$fieldToggleCheckboxes = $(
				this.formWidget
					.getContainer()
					.find('.WidgetSettingsForm-Field-Toggle-Checkbox'),
			);

			this.$fieldToggleCheckboxes.prop('checked', true);

			return callback.call(this, false);
		})
		.catch((error) => {
			console.debug(
				`[WidgetDeviceSettings] Error updating due to ${(error,
				error.stack)}`,
			);
			return callback.call(this, error);
		});
};

WidgetDeviceSettings.prototype.formOptionsFrom = function(device, settings) {
	const currentUser = this.getMainContainer().getCurrentUser();

	const opts = {
		fields: [],
		buttons: [],
	};
	settings.forEach((setting) => {
		opts.fields.push({
			name: setting.name,
			type: setting.inputType,
			label: setting.title,
			description: setting.description,
			value: setting.value,
			defaultValue: setting.defaultValue,
		});
	});

	// If the user can manage the device
	// they can also submit the data,
	// reset all settings, and configure
	// Wi-Fi connectivity
	if (currentUser.ability.can('manage', device)) {
		opts.buttons.push({
			label: this.getLanguageTag('resetAllButtonLabel'),
			value: 'reset',
			classes: ['btn-danger'],
		});
		opts.buttons.push({
			label: this.getLanguageTag('submitButtonLabel'),
			value: 'submit',
			classes: ['btn-primary'],
		});

		// Determine options for configuring Wi-Fi
		if (
			device &&
			device.protocols &&
			device.protocols.includes('WiFi') &&
			device.protocol === 'ble' &&
			PluginBLECentral.prototype.SELECT_BACKEND() !==
				BLECentralBackendNull
		) {
			opts.buttons.push({
				label: this.getLanguageTag('wifiSettings'),
				value: 'wifi',
				classes: ['btn-primary'],
			});
		}
	}

	return opts;
};

WidgetDeviceSettings.prototype.wifiSettingsButtonClicked = function() {
	const currentWidget = this;
	this.getMainContainer().setModalWidget(WidgetProvisioner, {}, function(
		err,
		provisionerWidget,
	) {
		this.getMainContainer().showModal();

		provisionerWidget.addEventListener('dismissed', function() {
			currentWidget.getMainContainer().hideModal();
		});

		provisionerWidget._handleExtraSettingsWiFi(
			null,
			{ uuid: getHashCommand().thingUuid },
			provisionerWidget._provisioners['ble'],
			true,
			function(err) {
				if (err) {
					console.debug(
						`[WidgetDeviceSettings] Error handling extra Wi-Fi settings due to ${(error,
						error.stack)}`,
					);
				}
				currentWidget.getMainContainer().hideModal();
			},
		);
	});
};

WidgetDeviceSettings.prototype.onResetAllButtonClicked = async function() {
	const currentWidget = this;
	const api = this.getApiV2().apis;
	const deviceId = getHashCommand().deviceId;

	this.getMainContainer().showConfirm(
		{
			title: getLanguageTag(this.constructor, 'resetAllConfirmTitle'),
			message: getLanguageTag(
				this.constructor,
				'confirmResetAllSettings',
			),
			confirmLabel: getLanguageTag(this.constructor, 'confirm'),
			dismissLabel: getLanguageTag(this.constructor, 'cancel'),
		},
		async function() {
			// Confirmed
			// Reset all device settings
			try {
				await api.devices.resetAllDeviceSettings({ id: deviceId });
			} catch (error) {
				console.debug(
					`Error resetting all device settings due to ${(error,
					error.stack)}`,
				);
				return currentWidget
					.getMainContainer()
					.showPopupErrorMessage(
						currentWidget.getLanguageTag('failedToResetSettings'),
					);
			}
			currentWidget.update();
			return currentWidget
				.getMainContainer()
				.showPopupInfoMessage(
					currentWidget.getLanguageTag('allSettingsReset'),
				);
		},
	);
};

WidgetDeviceSettings.prototype.onSubmitButtonClicked = async function(
	initialValues,
) {
	const currentWidget = this;
	const api = this.getApiV2().apis;

	const deviceId = getHashCommand().deviceId;
	const settings = this.formWidget.getValues();
	const updates = [];

	for (settingName in settings) {
		const value = settings[settingName];
		const initial = initialValues[settingName];

		// Only issue a PUT request for the value
		// if it actually changed in the form
		if (!deepEqual(value, initial)) {
			updates.push(
				api.devices.updateDeviceSetting(
					{ id: deviceId, settingName },
					{
						// Create a valid JSON string
						// as part of the PUT request
						requestBody: JSON.stringify(value),
					},
				),
			);
		}
	}

	try {
		await Promise.all(updates);
		currentWidget.update();
		// Update the settings history table
		// under the Logs tab
		currentWidget.event('settingsChanged');
		return this.getMainContainer().showPopupInfoMessage(
			this.getLanguageTag('settingsSaved'),
		);
	} catch (error) {
		console.debug(
			`[WidgetDeviceSettings] Unable to save settings due to ${(error,
			error.stack)}`,
		);
		return this.getMainContainer().showPopupErrorMessage(
			this.getLanguageTag('failedToSaveSettings'),
		);
	}
};

WidgetDeviceSettings.prototype.disableAllInputs = function() {
	const { formWidget, footerWidget } = this;

	const inputs = this.getContainer()
		.find('*[data-settingsformname]')
		.toArray();
	inputs.forEach((input) => {
		const inputName = $(input).attr('data-settingsformname');
		const inputType = $(input).attr('data-settingsformtype');
		$(input).off();
		$(input).prop('disabled', true);
		$(input).addClass('disabled');

		// Make sure to disable the googleMaps event listeners
		if (inputType === 'geolocation') {
			// https://koliada.atlassian.net/browse/AI-3371
			return setTimeout(() => {
				const geolocWidget = formWidget._geolocWidgets[inputName];
				const { googleMap } = geolocWidget.deviceMapWidget;
				google.maps.event.clearListeners(googleMap);
			}, 500);
		}
	});

	footerWidget.setButtonDisabled('wifi');
	footerWidget.setButtonDisabled('reset');
	footerWidget.setButtonDisabled('submit');
};

WidgetDeviceSettings.prototype.ICON = './Resources/icons/DeviceSettings.svg';

WidgetDeviceSettings.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'Settings',
			wifiSettings: 'Configure Wi-Fi Settings',
			deviceId: 'Device ID',
			changedToId: 'Changed To',
			changedAt: 'Changed',
			changedById: 'User',
			listOrder: 'List Order',
			settingName: 'Name',
			settingTitle: 'Title',
			description: 'Description',
			inputType: 'Input Type',
			inputTypeProperties: 'Input Properties',
			value: 'Value',
			defaultValue: 'Default Value',
			submitButtonLabel: 'Submit',
			resetAllButtonLabel: 'Reset',
			resetAllConfirmTitle: 'Reset all Device Settings',
			confirmResetAllSettings:
				'Are you sure you want to reset all device settings to their default values?',
			allSettingsReset: 'All settings have been reset for the device.',
			failedToResetSettings:
				'Failed to reset all settings, please try again.',
			settingsSaved: 'All settings have been saved for the device.',
			failedToSaveSettings:
				'Failed to set device settings, please try again.',
		},
	},
);
