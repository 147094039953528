function ElementControllerCloudForEach(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudLogicBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('value', null, null, 'none', null, null);

	this.addAbility('iterate', [{ name: 'value', validator: null }]);

	this.addTrigger('iteration');
}

ElementControllerCloudForEach.prototype = Object.create(
	ElementControllerCloudLogicBase.prototype,
);
ElementControllerCloudForEach.prototype.constructor = ElementControllerCloudForEach;

ElementControllerCloudForEach.prototype.NAME = 'ForEach';
ElementControllerCloudForEach.prototype.TYPE = 'CloudForEach';
ElementControllerCloudForEach.prototype.HIDDEN = false;
ElementControllerCloudForEach.prototype.DEFAULT_TRIGGER = 'iteration';
ElementControllerCloudForEach.prototype.DEFAULT_ABILITY = 'iterate';
ElementControllerCloudForEach.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerCloudForEach.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudForEach.prototype.language = deepAssign(
	{},
	ElementControllerCloudLogicBase.prototype.language,
	{
		'en-US': {
			CloudForEach: 'For Each',
			value: 'Value',
			iterate: 'Iterate',
			iteration: 'Iteration',
		},
	},
);
