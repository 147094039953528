function PlaneControllerEmbeddedSensorTile(parentController, elementName) {
	PlaneControllerEmbeddedStm32L4.call(this, parentController, elementName);
}

PlaneControllerEmbeddedSensorTile.prototype = Object.create(
	PlaneControllerEmbeddedStm32L4.prototype,
);
PlaneControllerEmbeddedSensorTile.prototype.constructor = PlaneControllerEmbeddedSensorTile;

PlaneControllerEmbeddedSensorTile.prototype.VARIANTS = [
	'stm32l4',
	'sensortile',
];
PlaneControllerEmbeddedSensorTile.prototype.SUPPORTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'interval',
	'variables',
	'ble',
	'gpio',
	'i2c',
	'spi',
	'fpmath',
	'stm32',
	'debugPrint',
];
PlaneControllerEmbeddedSensorTile.prototype.CLIENT_AGENT_REQUIRED = false;

PlaneControllerEmbeddedSensorTile.prototype.DRIVERS = {
	adc: [],
	ble: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLE_BLE1',
		},
	],
	block: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLOCK_BLOCK1',
		},
	],
	datetime: [],
	filesytem: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_FILESYSTEM_FILESYSTEM1',
		},
	],
	gpio: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_GPIO_GPIO1',
		},
	],
	http: [],
	i2c: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C1',
			primary: true,
		},
	],
	interval: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_INTERVAL_INTERVAL1',
		},
	],
	nfc: [],
	pwm: [],
	spi: [
		{
			id: 1,
			name: 'ATMO_DRIVERINSTANCE_SPI_SPI2',
		},
	],
	uart: [],
	wifi: [],
};

PlaneControllerEmbeddedSensorTile.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'PG12',
		mode: 'ATMO_GPIO_PinMode_Output_PushPull',
	},

	EmbeddedLSM303AGR: {
		gpioInstance: 0,
		spiInstance: 1,
		communicationMode: 'ATMO_DRIVERTYPE_SPI',
		spi3Wire: true,
		magCsPin: 'PB1',
		accelCsPin: 'PC4',
	},

	EmbeddedLPS22HB: {
		communicationMode: 'ATMO_DRIVERTYPE_SPI',
		spiInstance: 1,
		gpioInstance: 0,
		csPin: 'PA3',
		spi3Wire: true,
	},

	EmbeddedLSM6DSM: {
		communicationMode: 'ATMO_DRIVERTYPE_SPI',
		spiInstance: 1,
		gpioInstance: 0,
		i2cInstance: 0,
		csPin: 'PB12',
		spi3Wire: true,
	},

	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'PG12',
	},

	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'PG12',
	},

	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'PG12',
	},

	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'PG12',
	},
};

PlaneControllerEmbeddedSensorTile.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			defaultName: 'ST SensorTile',
			ATMO_DRIVERINSTANCE_SPI_SPI2: 'SPI2 (Internal Sensors)',
		},
	},
);
