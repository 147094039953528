function ElementControllerCloudBuffer(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudLogicBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('buffer', null, [], 'none', null, null);
	this.addProperty('pushedValue', null, null, 'none', null, null);
	this.addProperty('shiftedValue', null, null, 'none', null, null);
	this.addProperty('poppedValue', null, null, 'none', null, null);
	this.addProperty('splicedValue', null, null, 'none', null, null);
	this.addProperty('oldIndexValue', null, null, 'none', null, null);
	this.addProperty('indexValue', null, null, 'none', null, null);
	this.addProperty('value', null, null, 'none', null, null);

	this.addAbility('push', [{ name: 'value', validator: null }]);
	this.addAbility('pop', []);
	this.addAbility('shift', []);
	this.addAbility('splice', [
		{ name: 'index', validator: null },
		{ name: 'length', validator: null },
	]);
	this.addAbility('setIndex', [
		{ name: 'index', validator: null },
		{ name: 'value', validator: null },
	]);
	this.addAbility('getIndex', [{ name: 'index', validator: null }]);
	this.addAbility('clear', []);

	this.addTrigger('pushed');
	this.addTrigger('shifted');
	this.addTrigger('popped');
	this.addTrigger('spliced');
	this.addTrigger('indexSet');
	this.addTrigger('indexRetrieved');
	this.addTrigger('cleared');
}

ElementControllerCloudBuffer.prototype = Object.create(
	ElementControllerCloudLogicBase.prototype,
);
ElementControllerCloudBuffer.prototype.constructor = ElementControllerCloudBuffer;

ElementControllerCloudBuffer.prototype.NAME = 'Buffer';
ElementControllerCloudBuffer.prototype.TYPE = 'CloudBuffer';
ElementControllerCloudBuffer.prototype.HIDDEN = false;
ElementControllerCloudBuffer.prototype.DEFAULT_TRIGGER = 'pushed';
ElementControllerCloudBuffer.prototype.DEFAULT_ABILITY = 'push';
ElementControllerCloudBuffer.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerCloudBuffer.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudBuffer.prototype.language = deepAssign(
	{},
	ElementControllerCloudLogicBase.prototype.language,
	{
		'en-US': {
			CloudBuffer: 'Buffer',
			buffer: 'Buffer',
			pushedValue: 'Pushed Value',
			shiftedValue: 'Shifted Value',
			poppedValue: 'Popped Value',
			splicedValue: 'Spliced Value',
			oldIndexValue: 'Old Index Value',
			indexValue: 'Index Value',
			value: 'Value',
			push: 'Push',
			pop: 'Pop',
			shift: 'Shift',
			splice: 'Splice',
			index: 'Index',
			setIndex: 'Set Index',
			getIndex: 'Get Index',
			clear: 'Clear',
			pushed: 'Pushed',
			shifted: 'Shifted',
			popped: 'Popped',
			spliced: 'Spliced',
			indexSet: 'Index Set',
			indexRetrieved: 'Index Retrieved',
			cleared: 'Cleared',
		},
	},
);
