function ElementControllerCloudAWSKinesisFirehose(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudAWSBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addAbility('putRecord', [
		{ name: 'deliveryStreamName', validator: null },
		{ name: 'recordData', validator: null },
	]);
	this.addAbility('putRecords', [
		{ name: 'deliveryStreamName', validator: null },
		{ name: 'recordsData', validator: null },
	]);

	this.addTrigger('recordPlaced');
	this.addTrigger('recordsPlaced');
}

ElementControllerCloudAWSKinesisFirehose.prototype = Object.create(
	ElementControllerCloudAWSBase.prototype,
);
ElementControllerCloudAWSKinesisFirehose.prototype.constructor = ElementControllerCloudAWSKinesisFirehose;

ElementControllerCloudAWSKinesisFirehose.prototype.NAME = 'AWSKinesisFirehose';
ElementControllerCloudAWSKinesisFirehose.prototype.TYPE =
	'CloudAWSKinesisFirehose';
ElementControllerCloudAWSKinesisFirehose.prototype.HIDDEN = false;
ElementControllerCloudAWSKinesisFirehose.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudAWSKinesisFirehose.prototype.language = deepAssign(
	{},
	ElementControllerCloudAWSBase.prototype.language,
	{
		'en-US': {
			CloudAWSKinesisFirehose: 'AWS Kinesis Firehose',
			putRecord: 'Insert Record (putRecord)',
			putRecords: 'Insert Records (putRecordBatch)',
			deliveryStreamName: 'Stream Name',
			recordData: 'Record Data',
			recordsData: 'Records Data',
			recordPlaced: 'Record Inserted',
			recordsPlaced: 'Records Inserted',
		},
	},
);
