function ElementControllerDeviceEventStandalone(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerDeviceEvent.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);
}

ElementControllerDeviceEventStandalone.prototype = Object.create(
	ElementControllerDeviceEvent.prototype,
);
ElementControllerDeviceEventStandalone.prototype.constructor = ElementControllerDeviceEventStandalone;

ElementControllerDeviceEventStandalone.prototype.TYPE = 'DeviceEventStandalone';
ElementControllerDeviceEventStandalone.prototype.VARIANTS = [
	'cloud',
	'standalone',
];
ElementControllerDeviceEventStandalone.prototype.COUPLED_TO = null;

ElementControllerDeviceEventStandalone.prototype.language = deepAssign(
	{},
	ElementControllerDeviceEvent.prototype.language,
	{
		'en-US': {
			DeviceEventStandalone: 'Device Event',
		},
	},
);
