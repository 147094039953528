function ElementControllerCloudEvent(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addAbility('sendCommand', [{ name: 'value', validator: null }]);
	// FIXME AI-3423: Add integration support back in to pop cloud events by integration type
	// this.addProperty('integration', null, 'default', 'select', [
	// 	'default',
	// 	'ble',
	// 	'lora',
	// 	'custom',
	// ]);

	this.addTrigger('commandQueued');
}

ElementControllerCloudEvent.prototype = Object.create(
	ElementControllerCloudBase.prototype,
);
ElementControllerCloudEvent.prototype.constructor = ElementControllerCloudEvent;

ElementControllerCloudEvent.prototype.NAME = 'CloudEvent';
ElementControllerCloudEvent.prototype.TYPE = 'CloudEvent';
ElementControllerCloudEvent.prototype.HIDDEN = false;
ElementControllerCloudEvent.prototype.DEFAULT_TRIGGER = 'commandQueued';
ElementControllerCloudEvent.prototype.DEFAULT_ABILITY = 'sendCommand';
ElementControllerCloudEvent.prototype.DEFAULT_ARGUMENTS = [];
ElementControllerCloudEvent.prototype.COUPLED_TO = 'cloudEvent';
ElementControllerCloudEvent.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudEvent.prototype.language = deepAssign(
	{},
	ElementControllerCloudBase.prototype.language,
	{
		'en-US': {
			CloudEvent: 'Cloud Event',
			value: 'Value',
			sendCommand: 'Send',
			commandQueued: 'Event Queued',
			// default: 'Default',
			// ble: 'BLE',
			// lora: 'LoRa',
			// custom: 'Custom',
		},
	},
);
