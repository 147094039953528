function ElementControllerCloudNotification(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);
	this.addAbility('send', [{ name: 'message', validator: null }]);
}

ElementControllerCloudNotification.prototype = Object.create(
	ElementControllerCloudBase.prototype,
);
ElementControllerCloudNotification.prototype.constructor = ElementControllerCloudNotification;

ElementControllerCloudNotification.prototype.NAME = 'CloudNotification';
ElementControllerCloudNotification.prototype.TYPE = 'CloudNotification';
ElementControllerCloudNotification.prototype.HIDDEN = false;
ElementControllerCloudNotification.prototype.DEFAULT_TRIGGER = 'triggered';
ElementControllerCloudNotification.prototype.DEFAULT_ABILITY = 'send';
ElementControllerCloudNotification.prototype.DEFAULT_ARGUMENTS = [];
ElementControllerCloudNotification.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudNotification.prototype.language = deepAssign(
	{},
	ElementControllerCloudBase.prototype.language,
	{
		'en-US': {
			CloudNotification: 'Cloud Notification',
			send: 'Send',
			message: 'Message',
		},
	},
);
