function PlaneControllerEmbeddedCypress(parentController, elementName) {
	PlaneControllerEmbeddedBase.call(this, parentController, elementName);
}

PlaneControllerEmbeddedCypress.prototype = Object.create(
	PlaneControllerEmbeddedBase.prototype,
);
PlaneControllerEmbeddedCypress.prototype.constructor = PlaneControllerEmbeddedCypress;

PlaneControllerEmbeddedCypress.prototype.TYPE = 'Cypress';
PlaneControllerEmbeddedCypress.prototype.VARIANTS = [];
PlaneControllerEmbeddedCypress.prototype.SUPPORTS = [
	'embedded',
	'gpio',
	'adc',
	'i2c',
	'spi',
	'uart',
	'fpmath',
];
PlaneControllerEmbeddedCypress.prototype.SELECTABLE_VARIANTS = [];

PlaneControllerEmbeddedCypress.prototype.language = {
	'en-US': {
		defaultName: 'Cypress',
	},
};
