function ElementAppUIToggle(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppUIBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);

	var currentElement = this;

	this._setCrocObject(new CrocToggle(this._crocPanel.getRoot()));

	this._onPropertyChange();

	this._crocObject.addEventListener('valuechanged', function(value) {
		currentElement._setProperty('value', value, function(err) {
			this.trigger('changed');

			if (Boolean(value)) {
				this.trigger('checked');
			} else {
				this.trigger('unchecked');
			}
		});
	});
}

ElementAppUIToggle.prototype = Object.create(ElementAppUIBase.prototype);
ElementAppUIToggle.prototype.constructor = ElementAppUIToggle;

ElementAppUIToggle.prototype._onPropertyChange = function() {
	ElementAppUIBase.prototype._onPropertyChange.call(this);

	this._crocObject.setValue(this._properties.value);
};

ElementAppUIToggle.prototype.toggle = function(callback) {
	this._getProperty('value', function(err, value) {
		this._setProperty('value', !value, function(err) {
			this._crocObject.setValue(!value);
			callback.call(this, err);
			return;
		});
	});
};

ElementAppUIToggle.prototype.setValue = function(value, callback) {
	value = Boolean(value);

	this._setProperty('value', value, function(err) {
		this._crocObject.setValue(value);
		callback.call(this, err);
		return;
	});
};
