//Copyright 2018 Atmosphere IoT Corp.
//All rights reserved

/*
 * CloudApi.js: This file configures the API - make sure to set apiBase appropriately.
 */

/*
 * The APIBadRoute is used for when a route is requested that is not there.
 */

function APIBadRoute() {}

APIBadRoute.prototype.get = function() {
	var callback = arguments[arguments.length - 1];
	callback.call(this, { type: 'noSuchRoute' }, null);
	return;
};

APIBadRoute.prototype.post = function() {
	var callback = arguments[arguments.length - 1];
	callback.call(this, { type: 'noSuchRoute' }, null);
	return;
};

/*
 * The APIRoute class is the container and controller for accessing an API
 * route from the server.
 */

function APIRoute(parentAPI, apiBase, path) {
	this.apiBase = apiBase;
	this.path = path;
	this.argumentMapping = [];
	this.parent = parentAPI;
	this.timeout = 30000;

	this.buildArgumentsMapping();
}

APIRoute.prototype.makeUniqueSuffix = function() {
	return '?_=' + new Date().getTime();
};

APIRoute.prototype.buildArgumentsMapping = function() {
	if (this.path.split === undefined || this.path.split.call === undefined) {
		console.debug('Bad path: ' + JSON.stringify(this.path));
		return;
	}

	var colonSplit = this.path.split(':');

	for (var i = 1; i < colonSplit.length; i++) {
		this.argumentMapping.push(colonSplit[i].split('/')[0]);
	}
};

APIRoute.prototype.apply = function() {
	this.call.apply(this, arguments);
};

APIRoute.prototype.setAJAXTimeout = function(timeout) {
	this.timeout = parseInt(timeout);
	return;
};

APIRoute.prototype.doAjaxPost = function(url, data, extraOpts, callback) {
	const currentApi = this;
	
	if(!isPhonegap()) {
		const opts = {
			type: 'POST',
			url,
			crossDomain: true,
			data,
			success: (responseData) => {
				callback.call(currentApi, false, responseData);
			},
			error: (responseData, textStatus, errorThrown) => {
				callback.call(currentApi, responseData, errorThrown);
			},
			timeout: undefined,
		};

		if (window._mainContainer) {
			const apiv2 = window._mainContainer._apiv2;
			const token = ((apiv2 || {}).jwt || {}).token || null;
			
			opts.headers = {
				Authorization: `Bearer ${token}`,
				// Distinguish browser and API client requests in server logs
				'X-Client': 'Browser',
			};
		}

		$.ajax({
			...opts,
			...extraOpts,
		});
	}
	
	else {
		const opts = {
			method:'POST',
			body:data,
			timeout:undefined,
			serializer:'json',
			responseType:'json',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-Client': 'App',
			}
		}
		
		if (window._mainContainer) {
			const apiv2 = window._mainContainer._apiv2;
			const token = ((apiv2 || {}).jwt || {}).token || null;
			opts.headers.Authorization = `Bearer ${token}`;
		}
		
		console.debug(url, opts);
		
		cordovaFetch(url, opts).then(function(res) {
			console.debug(res);
			
			let resData = null;
			
			try {
				resData = JSON.parse(res._bodyText);
			}
			
			catch(e) {
				resData = res;
			}
			
			callback.call(currentApi, false, resData);
			
		}).catch(function(errRes) {
			console.debug(errRes);
			callback.call(currentApi, errRes.data, errRes.error);
		});
		
	}
};

APIRoute.prototype.refreshJwtIfNeeded = async function() {
	if (!window._mainContainer) {
		return false;
	}
	const apiv2 = window._mainContainer._apiv2;
	if (apiv2.jwtNeedsRefresh()) {
		return apiv2.refreshJwt();
	}
};

APIRoute.prototype.postDirect = function(postData, extraOpts, callback) {
	const currentAPIRoute = this;
	const processAjaxPostErr = (err, respData) => {
		callback.call(
			currentAPIRoute,
			{ type: respData, status: err.status },
			null,
		);
	};

	const processRefreshTokenError = (err) => {
		callback.call(
			currentAPIRoute,
			err,
			null,
		);
	};

	const processAjaxPostSuccess = (data) => {
		callback.call(currentAPIRoute, false, data);
	};

	const shouldRefreshJwtToken = (err) => {
		return err && err.status === 401;
	};

	const apiv2 = window._mainContainer ? window._mainContainer._apiv2 : {};
	this.refreshJwtIfNeeded()
		.then(() => {
			const url = this.apiBase + this.path + this.makeUniqueSuffix();
			this.doAjaxPost(url, postData, extraOpts, (err, respData) => {
				if (err && !apiv2) {
					callback.call(currentAPIRoute, err);
					return;
				}
				if (shouldRefreshJwtToken(err)) {
					// Refresh and try again
					apiv2
						.refreshJwt()
						.then(() => {
							this.doAjaxPost(
								url,
								postData,
								extraOpts,
								(postRetryErr, respRetryData) => {
									if (postRetryErr) {
										processAjaxPostErr(
											postRetryErr,
											respRetryData,
										);
										return;
									}
									processAjaxPostSuccess(respRetryData);
								},
							);
						})
						.catch((refreshJwtErr) => {
							processRefreshTokenError(refreshJwtErr);
						});
				} else if (err) {
					processAjaxPostErr(err, respData);
				} else {
					processAjaxPostSuccess(respData);
				}
			});
		})
		.catch((err) => {
			processRefreshTokenError(err);
		});
};

APIRoute.prototype.post = function() {
	var currentAPIRoute = this;

	var postData = {};
	var callback = arguments[arguments.length - 1];

	if (callback === undefined || callback.call === undefined) {
		console.debug('No callback provided!');
		console.trace();
		return;
	}

	if (arguments.length - 1 !== this.argumentMapping.length) {
		console.debug('Not enough arguments for API call');
		callback.call(this, { type: 'notEnoughArgumentsForAPICall' }, null);
		return;
	}

	for (
		var i = 0;
		i < arguments.length - 1 && i < this.argumentMapping.length;
		i++
	) {
		postData[this.argumentMapping[i]] = arguments[i];
	}

	currentAPIRoute.postDirect(
		JSON.stringify(postData),
		{ dataType: 'JSON', contentType: 'application/json' },
		callback,
	);
};

APIRoute.prototype.doAjaxGet = function(url, callback) {
	const currentApi = this;
	
	if(!isPhonegap()) {
		const config = {
			type: 'GET',
			url,
			crossDomain: true,
			dataType: 'json',
			success: (responseData) => {
				callback.call(currentApi, false, responseData);
			},
			error: (responseData, textStatus, errorThrown) => {
				callback.call(currentApi, responseData, errorThrown);
			},
			timeout: undefined,
		};

		if (window._mainContainer) {
			const apiv2 = window._mainContainer ? window._mainContainer._apiv2 : {};
			const token = ((apiv2 || {}).jwt || {}).token || null;
			
			config.headers = {
				Authorization: `Bearer ${token}`,
				// Distinguish browser and API client requests in server logs
				'X-Client': 'Browser',
			};
		}

		$.ajax(config);
	} else {
		const opts = {
			method:'GET',
			timeout:undefined,
			serializer:'json',
			responseType:'json',
		}
		
		if (window._mainContainer) {
			const apiv2 = window._mainContainer ? window._mainContainer._apiv2 : {};
			const token = ((apiv2 || {}).jwt || {}).token || null;
			
			opts.headers = {
				Authorization: `Bearer ${token}`,
				// Distinguish browser and API client requests in server logs
				'X-Client': 'App',
			};
		}
		
		cordovaFetch(url, opts).then(function(res) {
			console.debug(res);
			
			let resData = null;
			
			try {
				resData = JSON.parse(res._bodyText);
			}
			
			catch(e) {
				resData = null;
			}
			
			callback.call(currentApi, false, resData);
		}).catch(function(errRes) {
			console.debug(errRes);
			callback.call(currentApi, errRes.data, errRes.error);
		});
	}
};

APIRoute.prototype.get = function() {
	var currentAPIRoute = this;

	var finalPath = this.path;
	var callback = arguments[arguments.length - 1];

	if (callback === undefined || callback.call === undefined) {
		return;
	}

	for (
		var i = 0;
		i < arguments.length - 1 && i < this.argumentMapping.length;
		i++
	) {
		finalPath = finalPath.replace(
			':' + this.argumentMapping[i],
			encodeURIComponent(JSON.stringify(arguments[i])),
		);
	}

	const processAjaxGetErr = (err, respData) => {
		callback.call(
			currentAPIRoute,
			{ type: respData, status: err.status },
			null,
		);
	};

	const processRefreshTokenError = (err) => {
		callback.call(
			currentAPIRoute,
			err,
			null,
		);
	};

	//Health and global do not require credentials so don't bother
	if (finalPath === '/global' || finalPath === '/health') {
		const url = this.apiBase + finalPath + this.makeUniqueSuffix();
		this.doAjaxGet(url, callback);
		return;
	}

	const processAjaxGetSuccess = (data) => {
		callback.call(currentAPIRoute, false, data);
	};

	const shouldRefreshJwtToken = (err) => {
		return err && err.status === 401;
	};

	const apiv2 = window._mainContainer ? window._mainContainer._apiv2 : {};
	this.refreshJwtIfNeeded()
		.then(() => {
			const url = this.apiBase + finalPath + this.makeUniqueSuffix();
			this.doAjaxGet(url, (err, respData) => {
				if (err && !apiv2) {
					callback.call(currentAPIRoute, err);
					return;
				}
				if (shouldRefreshJwtToken(err)) {
					// Refresh and try again
					apiv2
						.refreshJwt()
						.then(() => {
							this.doAjaxGet(
								url,
								(postRetryErr, respRetryData) => {
									if (postRetryErr) {
										processAjaxGetErr(
											postRetryErr,
											respRetryData,
										);
										return;
									}
									processAjaxGetSuccess(respRetryData);
								},
							);
						})
						.catch((refreshJwtErr) => {
							processRefreshTokenError(refreshJwtErr);
						});
				} else if (err) {
					processAjaxGetErr(err, respData);
				} else {
					processAjaxGetSuccess(respData);
				}
			});
		})
		.catch((err) => {
			processRefreshTokenError(err);
		});
};

/*
 * The APITestRoute is used for testing.
 */

function APITestRoute(parentAPI, path, onUse, delay, random, randomStart) {
	APIRoute.call(this, parentAPI, 'file://', path);

	this.onUse = onUse;
	this.delay = delay || 0;
	this.random = random || false;
	this.randomStart = randomStart || 0;
}

APITestRoute.prototype = Object.create(APIRoute.prototype);
APITestRoute.prototype.constructor = APITestRoute;

APITestRoute.prototype.get = function() {
	var currentAPIRoute = this;

	var postData = {};
	var callback = arguments[arguments.length - 1];

	if (callback === undefined || callback.call === undefined) {
		console.debug('No callback provided!');
		console.trace();
		return;
	}

	if (arguments.length - 1 !== this.argumentMapping.length) {
		console.debug('Not enough arguments for API call');
		callback.call(this, { type: 'notEnoughArgumentsForAPICall' }, null);
		return;
	}

	for (
		var i = 0;
		i < arguments.length - 1 && i < this.argumentMapping.length;
		i++
	) {
		postData[this.argumentMapping[i]] = arguments[i];
	}

	var timeout = this.delay;

	if (this.random) {
		var randomDelta = this.delay - this.randomStart;

		timeout = this.randomStart + randomDelta * Math.random();
	}

	setTimeout(function() {
		currentAPIRoute.onUse(arguments, callback);
	}, timeout);
};

APITestRoute.prototype.post = APITestRoute.prototype.get;

function CloudApi() {
	this.apiBase = getAPIBase();
	this.routes = {};
	this.buildAPIRoutes();
}

CloudApi.prototype.addTestRoute = function(
	path,
	onUse,
	delay,
	random,
	randomStart,
) {
	/*
	 * This function lets you add a test route for unit tests that will mimic
	 * a real call to the user.
	 *
	 * @param path The path you want to simulate that would normally be provided by an actual server
	 * @param onUse The callback that responsed to the request to this API route
	 * @param delay How long at most in milliseconds should we wait to respond to the request simulating delay
	 * @param random Should we randomize the delay?
	 * @param randomStart if we are randomizing the delay what is the least amount of time we would want to wait
	 *
	 */

	var newRoute = new APITestRoute(
		this,
		path,
		onUse,
		delay,
		random,
		randomStart,
	);

	this.routes[path] = newRoute;
	return;
};

CloudApi.prototype.getRoutesFromServer = function(callback) {
	var currentAPI = this;

	this.ROUTES = CloudApi.prototype.ROUTES.slice();
	this.buildAPIRoutes();

	this.getAPIRoute('/user/general/routes', function(err, route) {
		route.get(function(err, data) {
			if(!data) {
				data = [];
			}
			
			//Combine the routes from the server with the routes we always want to have.
			//Make sure we only have each route entered once in the list
			data = [...new Set([...data, ...CloudApi.prototype.ALWAYS_ROUTES])];
			
			currentAPI.ROUTES = data;
			currentAPI.buildAPIRoutes();
			
			callback.call(currentAPI, false);
		});
	});
};

CloudApi.prototype.buildAPIRoutes = function() {
	if (this.ROUTES !== undefined && this.ROUTES !== null) {
		for (var i = 0; i < this.ROUTES.length; i++) {
			var currentRoutePath = this.ROUTES[i];

			//FIXME: We should never be getting null routes back, let's investigate why this is happening on SAML users.

			if (currentRoutePath === undefined || currentRoutePath === null) {
				continue;
			} else {
				this.routes[currentRoutePath] = new APIRoute(
					this,
					this.apiBase,
					currentRoutePath,
				);
			}
		}
	}
};

CloudApi.prototype.getAPIRoute = function(path, callback) {
	if (this.routes[path] === undefined) {
		if (callback !== undefined) {
			callback.call(this, { type: 'noSuchRoute' }, null);
		}

		return new APIBadRoute();
	}

	if (callback !== undefined) {
		callback.call(this, false, this.routes[path]);
	}

	return this.routes[path];
};

CloudApi.prototype.makeUniqueSuffix = function() {
	return '?_=' + new Date().getTime();
};

//This is a very generic call system.
CloudApi.prototype.makeAPIRequest = function(path, data, callback) {};

CloudApi.prototype.ROUTES = [
	'/global',
	'/user/general/routes',
	'/user',
	'/version',
	'/revision',
	'/health',
	'/isUp',
];

CloudApi.prototype.ALWAYS_ROUTES = [
	'/global',
	'/user/general/routes',
	'/user',
	'/version',
	'/revision',
	'/health',
	'/isUp',
];
