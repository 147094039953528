function WidgetOrganizationNavigator(id, api, parent, options) {
	options = options || {};
	
	// We do not wish to have a setting menu entry
	options.menuOptions = [
		{ label: getLanguageTag(this.constructor, 'help'), value: 'help' },
		{
			label: getLanguageTag(this.constructor, 'remove'),
			value: 'remove',
		},
	];
	
	WidgetDashboardBase.call(this, id, api, parent, options);
	
	this._tableWidget = null;
}

WidgetOrganizationNavigator.prototype = Object.create(WidgetDashboardBase.prototype);
WidgetOrganizationNavigator.prototype.constructor = WidgetOrganizationNavigator;

WidgetOrganizationNavigator.prototype.initialize = function(callback) {
	const currentWidget = this;

	const api = currentWidget.getApiV2().apis;
	
	// We need some special styling for the table and this is easier
	// than making weird handlebar wrappers.
	this._container.addClass( "WidgetOrganizationNavigator-container" );
	
	currentWidget.getOrganizationId((err, orgId) => {
		//Add our dynamic table to the getContainer() returned container
		this.addChildWidget(
			WidgetTableDynamic,
			currentWidget._panelContainerId,
			{
				hideFilterInput: true,
				selectable: false,
				apiRoute: api.organizations.getOrganizations.bind(
					currentWidget,
				),
				addParams: () => {
					const params = {
						parentId: orgId,
						depth: '1',
					};
					
					return params;
				},
				entryMapper: async (entry) => {
					const org = new Organization(
						currentWidget.getApiV2().apis,
						entry,
					);
					entry.parent = org.getParentName();
				
					entry.status = currentWidget.statusFormatter(
						entry.statusText,
					);
					entry.orgLink = currentWidget
						.getMainContainer()
						.getLocationLink('Dashboards', {
							org: entry.id,
						});
					return entry;
				},
				rowFormatter: (row) => {
					const rowData = row.getData();
					const element = row.getElement();
					const rowClass = deviceStatus.rowClass(
						rowData.raw.statusText,
					);
					row.getElement().classList.add(rowClass);
				},
				onRowClicked: (entry) => {
					currentWidget.handleOrgClicked(entry.id);
				},
				onRowDblTap: (entry) => {
					currentWidget.handleOrgClicked(entry.id);
				},
				onSelectionChanged: (rows) => {
				},
				ajaxSorting: true,
				headerSort: true,
				paginationSize: 7,
				columns: [
					{
						title: currentWidget.getLanguageTag('orgName'),
						formatter: 'link',
						field: 'orgLink',
						formatterParams: {
							labelField: 'name',
						},
						responsive: 0,
						minWidth: 150,
					},
					{
						title: currentWidget.getLanguageTag('status'),
						field: 'status',
						responsive: 1,
						minWidth: 100,
						headerSort: true,
					},
				],
			},

			function(err, tableWidget) {
				if (err) {
					callback.call(currentWidget, err);
					return;
				}

				currentWidget._tableWidget = tableWidget;

				WidgetDashboardBase.prototype.initialize.call(
					currentWidget,
					callback,
				);
			},
		);

	});
};

WidgetOrganizationNavigator.prototype.statusFormatter = function(value, rowData) {
	if (!value || !value.toLowerCase) {
		return this.getLanguageTag('none');
	}

	return this.getLanguageTag(value.toLowerCase());
};

WidgetOrganizationNavigator.prototype.setConfiguration = function(config, callback) {
	callback.call(this);
};

/**
 * An organization item has actually been clicked. Navigate to that organization's dashboard.
 *
 * @param {*} org
 */
WidgetOrganizationNavigator.prototype.handleOrgClicked = function(orgId) {
	const currentWidget = this;
	const api = currentWidget.getApiV2().apis;

	this.getMainContainer().setLocation(LocationDashboards, {org:orgId, reload:true});
};

WidgetOrganizationNavigator.prototype.PACKERY_SIZE = 'WidgetDashboard_Container_XLxL';


WidgetOrganizationNavigator.prototype.language = deepAssign(
	{},
	WidgetDashboardBase.prototype.language,
	{
		'en-US': {
			name: 'Organization Navigator',
			orgName: 'Name',
			status: 'Status',
			none: 'None',
			info: 'Info',
			good: 'Good',
			minor: 'Minor',
			warning: 'Warning',
			critical: 'Critical',
			missing: 'Missing',
			parent: 'Parent',
			addOrganization: 'Add',
			moveOrganization: 'Move',
			deleteOrganization: 'Delete',
			confirmOrganizationRemove:
				'Are you sure you want to delete the selected organizations?',
			removeOrganizations: 'Delete Organizations',
			removeOrganizationsButton: 'Delete',
			showSubOrganizations: 'Show Sub-Organizations',
		},
	},
);
