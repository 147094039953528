function ElementControllerEmbeddedStaticTextDisplay(
	parentController,
	parentPlane,
	elementName,
	numMeasurements,
) {
	ElementControllerEmbeddedPageTemplateBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('text', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('text', 'string');

	this.addProperty(
		'fontSize',
		null,
		'ATMO_FONT_SIZE_20PT',
		'select',
		[
			'ATMO_FONT_SIZE_8PT',
			'ATMO_FONT_SIZE_10PT',
			'ATMO_FONT_SIZE_13PT',
			'ATMO_FONT_SIZE_16PT',
			'ATMO_FONT_SIZE_20PT',
			'ATMO_FONT_SIZE_24PT',
			'ATMO_FONT_SIZE_32PT',
		],
		null,
	);

	this.addProperty(
		'horizontalAlignment',
		null,
		'GUI_TA_LEFT',
		'select',
		['GUI_TA_LEFT', 'GUI_TA_HCENTER', 'GUI_TA_RIGHT'],
		null,
	);

	this.addAbility(
		'topRightButtonPressed',
		[],
		['topRightButtonPressed'],
		false,
		true,
	);
	this.setAbilityCode(
		'topRightButtonPressed',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility(
		'bottomRightButtonPressed',
		[],
		['bottomRightButtonPressed'],
		false,
		true,
	);
	this.setAbilityCode(
		'bottomRightButtonPressed',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility(
		'topLeftButtonPressed',
		[],
		['topLeftButtonPressed'],
		false,
		true,
	);
	this.setAbilityCode(
		'topLeftButtonPressed',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility(
		'bottomLeftButtonPressed',
		[],
		['bottomLeftButtonPressed'],
		false,
		true,
	);
	this.setAbilityCode(
		'bottomLeftButtonPressed',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('onDisplayed', [], ['onDisplayed'], false, true);
	this.setAbilityCode(
		'onDisplayed',
		`
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('setText', [], [], false, false);
	this.setAbilityCode(
		'setText',
		`
    ATMO_Value_t strVal;
    ATMO_InitValue(&strVal);
    ATMO_CreateValueConverted(&strVal, ATMO_DATATYPE_STRING, in);
    ATMO_UI_STATICTEXT_SetText(ATMO_VARIABLE(undefined,pageHandle), strVal.data);
    ATMO_FreeValue(&strVal);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
    ATMO_UI_PAGE_Config_t config;
	config.hidden = ATMO_PROPERTY(undefined, pageHidden);
	config.textColor = ATMO_PROPERTY(undefined, textColor);
	config.activeButtons = ATMO_UI_Page_GetButtonMask(ATMO_PROPERTY(undefined, topRightButtonEnabled),
		ATMO_PROPERTY(undefined,bottomRightButtonEnabled), ATMO_PROPERTY(undefined, topLeftButtonEnabled), ATMO_PROPERTY(undefined, bottomLeftButtonEnabled));
    config.x = ATMO_PROPERTY(undefined, x);
	config.y = ATMO_PROPERTY(undefined, y);
	strncpy(config.topLeftButtonLabel, ATMO_PROPERTY(undefined, topLeftButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
	strncpy(config.topRightButtonLabel, ATMO_PROPERTY(undefined, topRightButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
	strncpy(config.bottomLeftButtonLabel, ATMO_PROPERTY(undefined, bottomLeftButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
	strncpy(config.bottomRightButtonLabel, ATMO_PROPERTY(undefined, bottomRightButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
    config.spanX = ATMO_PROPERTY(undefined, spanX);
	config.spanY = ATMO_PROPERTY(undefined, spanY);
    config.title = ATMO_PROPERTY(undefined, pageTitle);
    config.titleHidden = ATMO_PROPERTY(undefined, titleHidden);
	ATMO_UI_STATICTEXT_Init(&config);
	ATMO_VARIABLE(undefined, pageHandle) = config.templateInstance;
    ATMO_UI_STATICTEXT_SetText(config.templateInstance, ATMO_PROPERTY(undefined, text));
	ATMO_UI_STATICTEXT_SetTextSize(config.templateInstance, ATMO_PROPERTY(undefined, fontSize));
	ATMO_UI_STATICTEXT_SetAlignment(config.templateInstance, ATMO_PROPERTY(undefined, horizontalAlignment));
	ATMO_UI_STATICTEXT_RegisterButtonAbilityHandle(ATMO_VARIABLE(undefined,pageHandle), 1, ATMO_ABILITY(undefined, topRightButtonPressed));
	ATMO_UI_STATICTEXT_RegisterButtonAbilityHandle(ATMO_VARIABLE(undefined,pageHandle), 2, ATMO_ABILITY(undefined, bottomRightButtonPressed));
	ATMO_UI_STATICTEXT_RegisterButtonAbilityHandle(ATMO_VARIABLE(undefined,pageHandle), 3, ATMO_ABILITY(undefined, topLeftButtonPressed));
    ATMO_UI_STATICTEXT_RegisterButtonAbilityHandle(ATMO_VARIABLE(undefined,pageHandle), 4, ATMO_ABILITY(undefined, bottomLeftButtonPressed));
    
	if(!config.hidden)
	{
		ATMO_UI_STATICTEXT_RegisterOnDisplayedAbilityHandle(ATMO_VARIABLE(undefined,pageHandle), ATMO_ABILITY(undefined, onDisplayed));
	}
    return ATMO_Status_Success;
    `,
	);
}

ElementControllerEmbeddedStaticTextDisplay.prototype = Object.create(
	ElementControllerEmbeddedPageTemplateBase.prototype,
);
ElementControllerEmbeddedStaticTextDisplay.prototype.constructor = ElementControllerEmbeddedStaticTextDisplay;

ElementControllerEmbeddedStaticTextDisplay.prototype.DEFAULT_TRIGGER =
	'onDisplayed';
ElementControllerEmbeddedStaticTextDisplay.prototype.DEFAULT_ABILITY =
	'setText';
ElementControllerEmbeddedStaticTextDisplay.prototype.DEFAULT_ARGUMENTS = [
	'value',
];
ElementControllerEmbeddedStaticTextDisplay.prototype.TYPE =
	'EmbeddedStaticTextDisplay';
ElementControllerEmbeddedStaticTextDisplay.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'rpk',
];
ElementControllerEmbeddedStaticTextDisplay.prototype.HIDDEN = false;
ElementControllerEmbeddedStaticTextDisplay.prototype.CATEGORY = 'Rapid IOT';
ElementControllerEmbeddedStaticTextDisplay.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedStaticTextDisplay.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedPageTemplateBase.prototype.language,
	{
		'en-US': {
			EmbeddedStaticTextDisplay: 'Text Page',
			text: 'Text',
			fontSize: 'Font Size',
			ATMO_FONT_SIZE_8PT: '8 pt',
			ATMO_FONT_SIZE_10PT: '10 pt',
			ATMO_FONT_SIZE_13PT: '13 pt',
			ATMO_FONT_SIZE_16PT: '16 pt',
			ATMO_FONT_SIZE_20PT: '20 pt',
			ATMO_FONT_SIZE_24PT: '24 pt',
			ATMO_FONT_SIZE_32PT: '32 pt',
			onDisplayed: 'On Displayed',
			setText: 'Set Text',
			GUI_TA_LEFT: 'Left',
			GUI_TA_HCENTER: 'Center',
			GUI_TA_RIGHT: 'Right',
			horizontalAlignment: 'Alignment',
		},
	},
);
