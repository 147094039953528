function WifiBackendPhonegap() {
	WifiBackendBase.call(this);

	console.log('WifiBackendPhonegap()');

	this._isEnabled = false;
	this._socket = null;
	this._timeoutCallback = null;
	this._timeoutSeconds = 0;
}

WifiBackendPhonegap.prototype = Object.create(WifiBackendBase.prototype);
WifiBackendPhonegap.prototype.constructor = WifiBackendPhonegap;

WifiBackendPhonegap.prototype.IS_AVAILABLE = function() {
	return isPhonegap() && WifiWizard2 !== undefined;
};

WifiBackendPhonegap.prototype._connectNetworkAndroid = function(
	options,
	callback,
) {
	var currentBackend = this;

	// Add the network first
	console.log('Adding network...');
	WifiWizard2.addNetwork(
		WifiWizard2.formatWifiConfig(
			options.ssid,
			options.password,
			options.security,
		),
		function() {
			// Success
			console.log('Successfully added network...');
			WifiWizard2.androidConnectNetwork(
				options.ssid,
				function(win, fail) {
					if (fail) {
						console.log('Error connecting to WiFi network');
						callback.call(currentBackend, true);
					}
					console.log('Successfully connected to ' + options.ssid);
					if (options.bind == true) {
						console.log('Binding...');
						// Bind to network
						WifiWizard2.androidBindWiFi(function(win, fail) {
							if (fail) {
								console.log('Error binding to WiFi network');
								callback.call(currentBackend, true);
							}
							callback.call(currentBackend, false);
						});
					} else {
						WifiWizard2.androidUnBindWiFi(function(win, fail) {
							callback.call(currentBackend, false);
							return;
						});
					}
				},
				function(err) {
					console.log(
						'Error connecting to ' + options.ssid + ': ' + err,
					);
					callback.call(currentBackend, true);
				},
			);
		},
		function(err) {
			// Error
			console.log('Error adding network...');
			console.log(err);
			callback.call(currentBackend, true);
		},
	);
};

WifiBackendPhonegap.prototype.connectNetwork = function(options, callback) {
	var currentBackend = this;

	if (device.platform == 'Android') {
		console.log('Removing network ' + options.ssid);
		WifiWizard2.removeNetwork(
			options.ssid,
			function() {
				console.log('Successfully removed network. Connecting...');
				currentBackend._connectNetworkAndroid(options, callback);
			},
			function(err) {
				console.log('Error removing network. Connecting...');
				console.log(err);
				currentBackend._connectNetworkAndroid(options, callback);
			},
		);
	} else {
		// iOS
		WifiWizard2.iOSConnectNetwork(
			options.ssid,
			options.password || '',
			function() {
				callback.call(currentBackend, false);
			},
			function(err) {
				callback.call(currentBackend, true);
			},
		);
	}
};

WifiBackendPhonegap.prototype.disconnectNetwork = function(ssid, callback) {
	var currentBackend = this;

	if (device.platform == 'Android') {
		WifiWizard2.androidDisconnectNetwork(
			ssid,
			function() {
				callback.call(currentBackend, false);
			},
			function(err) {
				callback.call(currentBackend, true);
			},
		);
	} else {
		WifiWizard2.iOSDisconnectNetwork(
			ssid,
			function() {
				callback.call(currentBackend, false);
			},
			function(err) {
				callback.call(currentBackend, true);
			},
		);
	}
};

WifiBackendPhonegap.prototype.getScanResults = function(callback) {
	var currentBackend = this;
	console.log('Getting scan results...');
	WifiWizard2.scan(
		{},
		function(networkList) {
			console.log('Scan complete!');
			callback.call(currentBackend, false, networkList);
		},
		function() {
			callback.call(currentBackend, true);
		},
	);
};

WifiBackendPhonegap.prototype.getCurrentSSID = function(callback) {
	var currentBackend = this;

	WifiWizard2.getConnectedSSID(
		function(ssid) {
			callback.call(currentBackend, false, ssid);
		},
		function(error) {
			callback.call(currentBackend, true);
		},
	);
};

WifiBackendPhonegap.prototype.tcpConnect = function(
	addr,
	port,
	rxCallback,
	rxTimeoutCallback,
	rxTimeoutSeconds,
	callback,
) {
	var currentBackend = this;
	this._socket = new Socket();
	this._socket.onData = function(data) {
		if (currentBackend._currentSendTimeout) {
			clearTimeout(currentBackend._currentSendTimeout);
		}

		rxCallback(data);
	};
	this._timeoutCallback = rxTimeoutCallback;
	this._timeoutSeconds = rxTimeoutSeconds;
	this._socket.onClose = function() {
		console.log('Socket closed');
	};

	this._socket.onError = function(err) {
		console.log('Socket Error: ' + err);
	};
	this._socket.open(
		addr,
		port,
		function() {
			callback.call(currentBackend, false);
		},
		function() {
			callback.call(currentBackend, true);
		},
	);
};

WifiBackendPhonegap.prototype.tcpSend = function(data, callback) {
	var currentBackend = this;
	console.log('Socket State: ' + currentBackend._socket.state);
	if (currentBackend._socket === null) {
		callback.call(currentBackend, true);
		return;
	}

	currentBackend._currentSendTimeout = setTimeout(function() {
		currentBackend._timeoutCallback();
	}, currentBackend._timeoutSeconds * 1000);

	currentBackend._socket.write(
		data,
		function() {
			callback.call(currentBackend, false);
		},
		function(err) {
			console.log('Send error: ' + err);
			callback.call(currentBackend, true);
		},
	);
};

WifiBackendPhonegap.prototype.tcpDisconnect = function(callback) {
	var currentBackend = this;

	if (currentBackend._socket == null || currentBackend._socket == undefined) {
		callback.call(currentBackend, false);
		return;
	}
	currentBackend._socket.close(
		function() {
			callback.call(currentBackend, false);
		},
		function(err) {
			callback.call(currentBackend, true);
		},
	);
};
