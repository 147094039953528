function PlaneControllerEmbeddedArduinoMega(parentController, elementName) {
	PlaneControllerEmbeddedArduino.call(this, parentController, elementName);
}

PlaneControllerEmbeddedArduinoMega.prototype = Object.create(
	PlaneControllerEmbeddedArduino.prototype,
);
PlaneControllerEmbeddedArduinoMega.prototype.constructor = PlaneControllerEmbeddedArduinoMega;

PlaneControllerEmbeddedArduinoMega.prototype.VARIANTS = ['mega'];
PlaneControllerEmbeddedArduinoMega.prototype.SUPPORTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'fpmath',
	'interval',
	'variables',
	'gpio',
	'adc',
	'i2c',
	'spi',
	'debugPrint',
	'uptime',
	'log',
	'uart',
];

PlaneControllerEmbeddedArduinoMega.prototype.DRIVERS = {
	adc: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_ADC_ADC1',
		},
	],
	ble: [],
	block: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLOCK_BLOCK1',
		},
	],
	cellular: [],
	embeddedCloud: [],
	datetime: [],
	filesytem: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_FILESYSTEM_FILESYSTEM1',
		},
	],
	gpio: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_GPIO_GPIO1',
		},
	],
	http: [],
	i2c: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C1',
			primary: true,
		},
	],
	interval: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_INTERVAL_INTERVAL1',
		},
	],
	nfc: [],
	pwm: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_PWM_PWM1',
		},
	],
	spi: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_SPI_SPI1',
		},
	],
	uart: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_UART_UART1',
		},
		{
			id: 1,
			name: 'ATMO_DRIVERINSTANCE_UART_UART2',
		},
	],
	wifi: [],
};

PlaneControllerEmbeddedArduinoMega.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'D13',
	},

	EmbeddedPWMPin: {
		instance: 0,
		pin: 'D13',
	},

	EmbeddedADCPin: {
		instance: 0,
		pin: 'A0',
	},

	EmbeddedWinC1500: {
		resetPin: 5,
		enablePin: -1,
		intPin: 7,
		csPin: 10,
	},

	EmbeddedXbeeLteNbIoT: {
		uartInstance: 1,
	},

	EmbeddedXbeeLteCat1: {
		uartInstance: 1,
	},

	EmbeddedGroveLight: {
		adcInstance: 0,
		adcPin: 'A0',
	},

	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'D13',
	},

	EmbeddedGroveTemperature: {
		adcInstance: 0,
		adcPin: 'A0',
	},

	EmbeddedGroveRotaryAngle: {
		adcInstance: 0,
		adcPin: 'A0',
	},

	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'D13',
	},

	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'D13',
	},

	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'D13',
	},
};

PlaneControllerEmbeddedArduinoMega.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			defaultName: 'Arduino Mega',
			ATMO_DRIVERINSTANCE_UART_UART1: 'UART1 (USB)',
			ATMO_DRIVERINSTANCE_UART_UART2: 'UART2 (18/19)',
		},
	},
);
