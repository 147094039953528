function WidgetUserAccountSecurity(id, api, parent, options) {
	WidgetBase.call(this, id, api, parent, options);

	// ID Assignment
	this.userAccountSecurityChangePasswordContainerId = this.generateChildId(
		'userAccountSecurityChangePasswordContainerId',
	);
	this.userAccountSecurityAuthenticationTokensContainerId = this.generateChildId(
		'userAccountSecurityAuthenticationTokensContainerId',
	);
}

WidgetUserAccountSecurity.prototype = Object.create(WidgetBase.prototype);
WidgetUserAccountSecurity.prototype.constructor = WidgetUserAccountSecurity;

WidgetUserAccountSecurity.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.update(function(err, data) {
		// callback = callback || function () {};
		WidgetBase.prototype.initialize.call(this, callback);
	});
};

WidgetUserAccountSecurity.prototype.update = function(callback) {
	var currentWidget = this;

	this.render(function(err, data) {
		this.addChildWidget(
			WidgetUserAccountSecurityChangePassword,
			this.userAccountSecurityChangePasswordContainerId,
			{},
			function(err, data) {
				this.addChildWidget(
					WidgetUserAccountSecurityAuthenticationTokens,
					this.userAccountSecurityAuthenticationTokensContainerId,
					{},
					function(err, data) {
						callback.call(currentWidget, false);
						return;
					},
				);
			},
		);
	});
};

WidgetUserAccountSecurity.prototype.getTemplateContext = function(callback) {
	var currentWidget = this;

	callback = callback || function() {};

	var context = {};

	context.language = {};

	context.rootIds = {
		userAccountSecurityChangePasswordContainerId: this
			.userAccountSecurityChangePasswordContainerId,
		userAccountSecurityAuthenticationTokensContainerId: this
			.userAccountSecurityAuthenticationTokensContainerId,
	};

	var globalConfig =
		this.getMainContainer().getGlobalConfig()[this.constructor.name] || {};
	var hideChangePasswordContainer =
		(globalConfig || {}).hideChangePasswordContainer || false;

	context = Object.assign({}, context, {
		hideChangePasswordContainer: hideChangePasswordContainer,
	});

	callback.call(this, false, context);
	return context;
};

WidgetUserAccountSecurity.prototype.render = function(callback) {
	var currentWidget = this;

	callback = callback || function() {};

	var context = this.getTemplateContext(function() {});
	var templateName = this.constructor.name;
	var container = this.getContainer();

	this.renderTemplate(context, templateName, container);

	callback.call(this, false, null);
	return;
};

WidgetUserAccountSecurity.prototype.ICON = './Resources/icons/SecurityLock.svg';

WidgetUserAccountSecurity.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'WidgetUserAccountSecurity',
		},
	},
);

WidgetUserAccountSecurity.prototype.$_$ = function(done) {
	//Testing function for WidgetUserAccountSecurity

	this.$_SetupWidgetTest(function(err, currentWidget) {
		WidgetBase.prototype.$_$.call(this, done);
	});
};
