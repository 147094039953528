function PlaneControllerEmbeddedAdafruitHuzzah32(
	parentController,
	elementName,
) {
	PlaneControllerEmbeddedEsp32.call(this, parentController, elementName);
}

PlaneControllerEmbeddedAdafruitHuzzah32.prototype = Object.create(
	PlaneControllerEmbeddedEsp32.prototype,
);
PlaneControllerEmbeddedAdafruitHuzzah32.prototype.constructor = PlaneControllerEmbeddedAdafruitHuzzah32;

PlaneControllerEmbeddedAdafruitHuzzah32.prototype.VARIANTS = [
	'AdafruitHuzzah32',
];

PlaneControllerEmbeddedAdafruitHuzzah32.prototype.DRIVERS = {
	adc: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_ADC_ADC1',
		},
	],
	ble: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLE_BLE1',
		},
	],
	block: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLOCK_BLOCK1',
		},
	],
	embeddedCloud: [
		{
			id: 1,
			name: 'ATMO_DRIVERINSTANCE_CLOUD_WIFI',
		},
	],
	datetime: [],
	filesytem: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_FILESYSTEM_FILESYSTEM1',
		},
	],
	gpio: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_GPIO_GPIO1',
		},
	],
	http: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_HTTP_HTTP1',
			primary: true,
		},
	],
	i2c: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C1',
			primary: true,
		},
	],
	interval: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_INTERVAL_INTERVAL1',
		},
	],
	nfc: [],
	pwm: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_PWM_PWM1',
		},
	],
	spi: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_SPI_SPI1',
		},
	],
	uart: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_UART_UART1',
		},
		{
			id: 1,
			name: 'ATMO_DRIVERINSTANCE_UART_UART2',
			primary: true,
		},
	],
	wifi: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_WIFI_WIFI1',
		},
	],
};

PlaneControllerEmbeddedAdafruitHuzzah32.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'IO13',
		mode: 'ATMO_GPIO_PinMode_Output_PushPull',
	},

	EmbeddedPWMPin: {
		instance: 0,
		pin: 'IO13',
	},

	EmbeddedADCPin: {
		instance: 0,
		pin: 'A0',
	},

	EmbeddedCloudCommand: {
		instance: 1,
	},

	EmbeddedCloudEvent: {
		instance: 1,
	},

	EmbeddedRelayClick: {
		relay1Pin: 'IO18',
		relay2Pin: 'IO19',
		gpioDriverInstance: 0,
	},

	EmbeddedIRThermoClick: {
		i2cInstance: 0,
	},

	EmbeddedHBridge2Click: {
		enablePin: 'IO18',
		dirPin: 'IO19',
		ginPin: 'IO20',
		speedCtrlPin: 'IO22',
		gpioDriverInstance: 0,
		pwmDriverInstance: 0,
		initialSpeedPct: 100,
	},

	EmbeddedGpsClick: {
		uartDriverInstance: 1,
		gpioDriverInstance: 0,
		powerPin: 'IO18',
		wupPin: 'IO19',
		rstPin: 'IO20',
		reportFrequency: 1,
	},

	EmbeddedMotionClick: {
		gpioDriverInstance: 0,
		enablePin: 'IO18',
		motionPin: 'IO19',
	},

	Embedded8x8LedClick: {
		csPin: 'IO18',
	},

	EmbeddedGroveLight: {
		adcInstance: 0,
		adcPin: 'A0',
	},

	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'IO13',
	},

	EmbeddedGroveTemperature: {
		adcInstance: 0,
		adcPin: 'A0',
	},

	EmbeddedGroveRotaryAngle: {
		adcInstance: 0,
		adcPin: 'A0',
	},

	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'IO13',
	},

	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'IO13',
	},

	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'IO13',
	},

	EmbeddedADXL343: {
		i2cInstance: 0,
		gpioInstance: 0,
		int1Pin: 'IO18',
		int2Pin: 'IO19',
	},

	EmbeddedADXL345: {
		i2cInstance: 0,
		gpioInstance: 0,
		int1Pin: 'IO18',
		int2Pin: 'IO19',
	},
};

PlaneControllerEmbeddedAdafruitHuzzah32.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedEsp32.prototype.language,
	{
		'en-US': {
			defaultName: 'Adafruit Huzzah32',
		},
	},
);
