/**
 * Project compiler class
 */
function ProjectCompiler(eventEmitter) {
	this.eventEmitter = eventEmitter;
}

ProjectCompiler.prototype.constructor = ProjectCompiler;

ProjectCompiler.prototype.buildErrorNotification = function(err) {
	return [
		{
			type: 'notification',
			text: `Atmosphere internal error. Please contact technical support at support@atmosphereiot.com with ID CompileWebSocketError and details: ${JSON.stringify(
				err,
			)}`,
		},
	];
};

ProjectCompiler.prototype.messageHandler = function(event) {
	const currentCompiler = this;

	const buildMessage = JSON.parse(event.data);

	if (buildMessage.messageType === 'BuildResult') {
		currentCompiler.buildCompleteCallback.call(
			currentCompiler,
			buildMessage.err,
			buildMessage.data,
		);
		currentCompiler.eventEmitter('compiled', {
			err: buildMessage.err,
			data: buildMessage.data,
		});

		currentCompiler.socket.close();
	} else if (buildMessage.messageType === 'AuthenticationFail') {
		currentCompiler.buildCompleteCallback.call(currentCompiler, true, {});
	} else if (buildMessage.messageType === 'BuildProgress') {
		console.log(buildMessage.data);
	}
};

ProjectCompiler.prototype.compile = function(
	jwt,
	projectUuid,
	versionUuid,
	buildCompleteCallback,
) {
	const currentCompiler = this;

	this.buildCompleteCallback = buildCompleteCallback;

	const apiBase = getAPIBase();
	const compileRoute = getWebsocketBase() + '/user/create/project/build';

	const compileSocket = new WebSocket(compileRoute);
	currentCompiler.socket = compileSocket;

	compileSocket.onopen = () => {
		const buildReq = {
			messageType: 'BuildRequest',
			token: jwt.token,
			projectUuid,
			versionUuid,
			apiBase,
		};

		// It seems that sometimes in chrome, when this event  fires, the websocket isn't quite
		// "open" fully. On occasion I see the client send things here and the server doesn't
		// receive the data. Putting a delay in here to see if that helps.
		setTimeout(() => {
			compileSocket.send(JSON.stringify(buildReq));
		}, 500);
	};

	compileSocket.onerror = (err) => {
		compileSocket.close();
		const errData = currentCompiler.buildErrorNotification(err);

		// For some reason the event emitter wants err but the callback wants an object with an "error" key
		currentCompiler.eventEmitter('compiled', {
			err: errData,
		});
		currentCompiler.buildCompleteCallback.call(currentCompiler, {
			error: errData,
		});
	};

	compileSocket.onmessage = currentCompiler.messageHandler.bind(
		currentCompiler,
	);
};

// Not sure if this is the best way to do it. This allows the module to work in both the browser and in node for unit testing
if (typeof module !== 'undefined' && module.exports) {
	module.exports = ProjectCompiler;
}
