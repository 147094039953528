function ElementControllerEmbeddedDebugPrint(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addProperty('prepend', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('prepend', 'string');

	this.addAbility('print', [], ['printed'], false, false);
	this.setAbilityCode(
		'print',
		`
    if((in != NULL) && (in->type != ATMO_DATATYPE_VOID))
    {
        char str[256];
        unsigned int prependLen = strlen(ATMO_PROPERTY(undefined, prepend));
        // If there's text to prepend, do that first
        if(prependLen > 0)
        {
            sprintf(str, "%s: ", ATMO_PROPERTY(undefined, prepend));
            prependLen += 2; // Adding 2 chars for ": "
        }
        
        // Grab the string version of the input data, place it after the prepend text
        if(ATMO_GetString(in, str + prependLen, 256 - prependLen) == ATMO_Status_Success)
        {
            ATMO_PLATFORM_DebugPrint("%s\\r\\n", str);
        }
    }
    else
    {
        ATMO_PLATFORM_DebugPrint("%s\\r\\n", ATMO_PROPERTY(undefined, prepend));
    }
    
    return ATMO_Status_Success;
    `,
	);
}

ElementControllerEmbeddedDebugPrint.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedDebugPrint.prototype.constructor = ElementControllerEmbeddedDebugPrint;

ElementControllerEmbeddedDebugPrint.prototype.NAME = 'DebugPrint';
ElementControllerEmbeddedDebugPrint.prototype.TYPE = 'EmbeddedDebugPrint';
ElementControllerEmbeddedDebugPrint.prototype.HIDDEN = false;
ElementControllerEmbeddedDebugPrint.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'debugPrint',
];
ElementControllerEmbeddedDebugPrint.prototype.DEFAULT_ABILITY = 'print';
ElementControllerEmbeddedDebugPrint.prototype.DEFAULT_TRIGGER = 'printed';

ElementControllerEmbeddedDebugPrint.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedDebugPrint.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedDebugPrint: 'Debug Print',
			print: 'Print',
			printed: 'Printed',
			prepend: 'Prepend Text',
		},
	},
);
