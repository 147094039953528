function ElementControllerCloudSchedule(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('minute', null, null, 'number');
	this.addProperty('hour', null, null, 'number');
	this.addProperty('dayOfWeek', null, null, 'none');

	this.addAbility('setSchedule', [
		{ name: 'minute', validator: null },
		{ name: 'hour', validator: null },
		{ name: 'dayOfWeek', validator: null },
	]);

	this.addTrigger('scheduled');
}

ElementControllerCloudSchedule.prototype = Object.create(
	ElementControllerCloudBase.prototype,
);
ElementControllerCloudSchedule.prototype.constructor = ElementControllerCloudSchedule;

ElementControllerCloudSchedule.prototype.TYPE = 'CloudSchedule';
ElementControllerCloudSchedule.prototype.HIDDEN = true;
ElementControllerCloudSchedule.prototype.DEFAULT_TRIGGER = 'scheduled';
ElementControllerCloudSchedule.prototype.DEFAULT_ABILITY = 'setSchedule';
ElementControllerCloudSchedule.prototype.DEFAULT_ARGUMENTS = [];
ElementControllerCloudSchedule.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudSchedule.prototype.language = deepAssign(
	{},
	ElementControllerCloudBase.prototype.language,
	{
		'en-US': {
			CloudSchedule: 'Cloud Schedule',
			minute: 'Minute',
			hour: 'Hour',
			dayOfWeek: 'Day of Week',
			setSchedule: 'Set Schedule',
			scheduled: 'Scheduled',
		},
	},
);
