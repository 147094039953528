function ElementControllerEmbeddedPageTemplateBase(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.colorList = ['GUI_BLACK', 'GUI_RED', 'GUI_GREEN', 'GUI_BLUE'];

	this.addVariable('pageHandle', 'ATMO_DriverInstanceHandle_t');
	this.addProperty(
		'textColor',
		null,
		this.colorList[0],
		'select',
		this.colorList,
		null,
	);
	this.addProperty('pageTitle', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('pageTitle', 'string');
	this.addProperty('titleHidden', null, false, 'checkbox', null, null);
	this.addProperty('pageHidden', null, false, 'checkbox', null, null);

	this.addProperty('topRightButtonLabel', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('topRightButtonLabel', 'string');
	this.addProperty(
		'topRightButtonEnabled',
		null,
		false,
		'checkbox',
		null,
		null,
	);

	this.addProperty('bottomRightButtonLabel', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('bottomRightButtonLabel', 'string');
	this.addProperty(
		'bottomRightButtonEnabled',
		null,
		false,
		'checkbox',
		null,
		null,
	);

	this.addProperty('topLeftButtonLabel', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('topLeftButtonLabel', 'string');
	this.addProperty(
		'topLeftButtonEnabled',
		null,
		false,
		'checkbox',
		null,
		null,
	);

	this.addProperty('bottomLeftButtonLabel', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('bottomLeftButtonLabel', 'string');
	this.addProperty(
		'bottomLeftButtonEnabled',
		null,
		false,
		'checkbox',
		null,
		null,
	);
	this.addProperty('x', null, 0, 'number', null, null);
	this.addProperty('y', null, 0, 'number', null, null);
	this.addProperty('spanX', null, 1, 'number', null, null);
	this.addProperty('spanY', null, 1, 'number', null, null);

	this.addAbility('displayPage', [], [], false, false);
	this.setAbilityCode(
		'displayPage',
		`
	ATMO_UI_Page_DisplayPageByCoord(ATMO_PROPERTY(undefined, x), ATMO_PROPERTY(undefined, y), false);
	return ATMO_Status_Success;
	`,
	);

	this.iconList = [
		'icon_applications_airq',
		'icon_applications',
		'icon_applications_fall',
		'icon_applications_humidity',
		'icon_applications_led',
		'icon_applications_light',
		'icon_applications_motion',
		'icon_applications_pressure',
		'icon_applications_tap',
		'icon_applications_thermostat',
		'icon_info_androqrcode',
		'icon_info',
		'icon_info_iosqrcode',
		'icon_sensors_accmag',
		'icon_sensors_airq',
		'icon_sensors',
		'icon_sensors_gyro',
		'icon_sensors_light',
		'icon_sensors_pressure',
		'icon_sensors_temphum',
		'icon_settings_backlight',
		'icon_settings_bluetooth',
		'icon_settings',
		'icon_settings_buzzer',
		'icon_settings_nfc',
		'icon_settings_reset',
		'icon_settings_sensortag',
		'icon_settings_thread',
	];
}

ElementControllerEmbeddedPageTemplateBase.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedPageTemplateBase.prototype.constructor = ElementControllerEmbeddedPageTemplateBase;

ElementControllerEmbeddedPageTemplateBase.prototype.TYPE =
	'EmbeddedPageTemplateBase';
ElementControllerEmbeddedPageTemplateBase.prototype.HIDDEN = true;

ElementControllerEmbeddedPageTemplateBase.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedPageTemplateBase: 'Base Embedded Page',
			ATMO_UI_PAGE_NAV_UP: 'Up',
			ATMO_UI_PAGE_NAV_DOWN: 'Down',
			ATMO_UI_PAGE_NAV_LEFT: 'Left',
			ATMO_UI_PAGE_NAV_RIGHT: 'Right',
			autoIndex: 'Auto Index',
			index: 'Submenu Index',
			submenu: 'Submenu',
			pageTitle: 'Page Title',
			titleHidden: 'Title Hidden',
			topRightButtonEnabled: 'Top Right Button Enabled',
			bottomRightButtonEnabled: 'Bottom Right Button Enabled',
			topLeftButtonEnabled: 'Top Left Button Enabled',
			bottomLeftButtonEnabled: 'Bottom Left Button Enabled',
			topRightButtonPressed: 'Top Right Button Pressed',
			topLeftButtonPressed: 'Top Left Button Pressed',
			bottomRightButtonPressed: 'Bottom Right Button Pressed',
			bottomLeftButtonPressed: 'Bottom Left Button Pressed',
			topRightButtonLabel: 'Top Right Button Label',
			bottomRightButtonLabel: 'Bottom Right Button Label',
			topLeftButtonLabel: 'Top Left Button Label',
			bottomLeftButtonLabel: 'Bottom Left Button Label',
			onDisplayed: 'Displayed',
			onLeave: 'Left Page',
			icon_applications_airq: 'Applications - Air Quality',
			icon_applications: 'Applications',
			icon_applications_fall: 'Applications - Tilt/Fall',
			icon_applications_humidity: 'Applications - Humidity',
			icon_applications_led: 'Applications - LED',
			icon_applications_light: 'Applications - Light',
			icon_applications_motion: 'Applications - Motion',
			icon_applications_pressure: 'Applications - Pressure',
			icon_applications_tap: 'Applications - Tap',
			icon_applications_thermostat: 'Applications - Thermostat',
			icon_info_androqrcode: 'Info - Android QR',
			icon_info: 'Info',
			icon_info_iosqrcode: 'Info - iOS QR',
			icon_sensors_accmag: 'Sensors - Accel/Mag',
			icon_sensors_airq: 'Sensors - Air Quality',
			icon_sensors: 'Sensors',
			icon_sensors_gyro: 'Sensors - Gyroscope',
			icon_sensors_light: 'Sensors - Light',
			icon_sensors_pressure: 'Sensors - Pressure',
			icon_sensors_temphum: 'Sensors - Temp/Humid',
			icon_settings_backlight: 'Settings - Backlight',
			icon_settings_bluetooth: 'Settings - Bluetooth',
			icon_settings: 'Settings',
			icon_settings_buzzer: 'Settings - Buzzer',
			icon_settings_nfc: 'Settings - NFC',
			icon_settings_reset: 'Settings - Reset',
			icon_settings_sensortag: 'Settings - Sensor Tag',
			icon_settings_thread: 'Settings - Thread',
			GUI_RED: 'Red',
			GUI_BLACK: 'Black',
			GUI_GREEN: 'Green',
			GUI_BLUE: 'Blue',
			textColor: 'Text Color',
			pageHidden: 'Page Hidden',
			displayPage: 'Display Page',
		},
	},
);
