function DesignerViewConnector(root, disableEffects) {
	CrocBase.call(this, root);

	disableEffects = Boolean(disableEffects);

	this.start = { x: 0, y: 0 };
	this.end = { x: 0, y: 0 };
	this.lineWidth = 2;
	this.hover = false;
	this.drawBadge = true;
	this.lineWidthFocused = 4;
	this.selected = false;

	this.badgeIconNormal = new CrocImageSimple(
		root,
		root.themer.getValue(DesignerViewConnector, 'connectorBadge'),
	);
	this.arrowIconNormal = new CrocImageSimple(
		root,
		root.themer.getValue(DesignerViewConnector, 'arrowIcon'),
	);

	this.badgeIconError = new CrocImageSimple(
		root,
		root.themer.getValue(DesignerViewConnector, 'connectorBadgeError'),
	);
	this.arrowIconError = new CrocImageSimple(
		root,
		root.themer.getValue(DesignerViewConnector, 'arrowIconError'),
	);

	this.setNormal();

	if (!disableEffects) {
		this.addEventListener('pointermove', function(e) {
			this.getRoot().setCursor('pointer');

			this.setHover(true);

			return false;
		});

		this.addEventListener('pointerleave', function(e) {
			this.getRoot().setCursor();

			this.setHover(false);

			return true;
		});
	}
}

DesignerViewConnector.prototype = Object.create(CrocBase.prototype);
DesignerViewConnector.prototype.constructor = DesignerViewConnector;

DesignerViewConnector.prototype.setSelected = function(selected) {
	this.selected = Boolean(selected);

	this.getRoot().repaint();
};

DesignerViewConnector.prototype.setHover = function(hover) {
	if (this.hover === hover) {
		return;
	}

	this.hover = hover;
	this.getRoot().repaint();
};

DesignerViewConnector.prototype.setColors = function(
	lineColor,
	lineColorHover,
	lineColorFocused,
) {
	this.lineColor = lineColor;
	this.lineColorHover = lineColorHover;
	this.lineColorFocused = lineColorFocused;

	this.getRoot().repaint();
};

DesignerViewConnector.prototype.setNormal = function() {
	this.lineColor = getComputedStyle(this.getRoot().canvas).getPropertyValue(
		'--blue',
	);
	this.lineColorHover = getComputedStyle(
		this.getRoot().canvas,
	).getPropertyValue('--blueLight');
	this.lineColorFocused = getComputedStyle(
		this.getRoot().canvas,
	).getPropertyValue('--blue');

	this.badgeIcon = this.badgeIconNormal;
	this.arrowIcon = this.arrowIconNormal;

	this.getRoot().repaint();
};

DesignerViewConnector.prototype.setError = function() {
	this.lineColor = getComputedStyle(this.getRoot().canvas).getPropertyValue(
		'--red',
	);
	this.lineColorHover = getComputedStyle(
		this.getRoot().canvas,
	).getPropertyValue('--redLight');
	this.lineColorFocused = getComputedStyle(
		this.getRoot().canvas,
	).getPropertyValue('--red');

	this.badgeIcon = this.badgeIconError;
	this.arrowIcon = this.arrowIconError;

	this.getRoot().repaint();
};

DesignerViewConnector.prototype.getWidth = function() {
	return Math.abs(this.end.x - this.start.x);
};

DesignerViewConnector.prototype.getHeight = function() {
	return Math.abs(this.end.y - this.start.y);
};

DesignerViewConnector.prototype.hitTest = function(
	context,
	x,
	y,
	width,
	height,
) {
	if (!this.visible) {
		return null;
	}

	var x1 = this.convertToPixels(this.start.x, width);
	var y1 = this.convertToPixels(this.start.y, height);
	var x2 = this.convertToPixels(this.end.x, width);
	var y2 = this.convertToPixels(this.end.y, height);

	context.save();
	var imageWidth = this.badgeIcon.getWidth();
	var imageHeight = this.badgeIcon.getHeight();

	context.translate(
		x1 + (x2 - x1) / 2 - imageWidth / 2,
		y1 + (y2 - y1) / 2 - imageHeight / 2,
	);
	if (this.badgeIcon.hitTest(context, x, y, width, height) !== null) {
		return this;
	}
	context.restore();

	//First we are goint to do a quick bounding check to see if we are anywhere close
	//Then we will do the more complicated per pixel check.
	var currentInvTransform = this.inverseTransform(
		context.getCurrentTransform(),
	);
	var p = this.transformPoint(currentInvTransform, x, y);
	var a = {
		x: Math.min(this.start.x, this.end.x) - 32,
		y: Math.min(this.start.y, this.end.y) - this.lineWidth,
	};
	var d = {
		x: Math.max(this.start.x, this.end.x) + 32,
		y: Math.max(this.start.y, this.end.y) + this.lineWidth,
	};

	if (p.x > a.x && p.x < d.x && p.y > a.y && p.y < d.y) {
		//Now we know we are close enough to the connector that a per-pixel check is worth a go.
		this.getRoot().clearHitContext();

		var oldLineWidth = this.lineWidth;
		var oldDrawBadge = this.drawBadge;
		this.drawBadge = false;
		this.lineWidth = 10;
		this.paint(context, width, height);
		this.lineWidth = oldLineWidth;
		this.drawBadge = oldDrawBadge;

		//Now we grab the pixel data around where the pointer is.
		var imageData = context.getImageData(p.x - 1, p.y - 1, 3, 3);

		if (imageData === null) {
			return null;
		}

		var alphaAverage = 0;

		for (var i = 0; i < imageData.data.length / 4; i++) {
			alphaAverage += imageData.data[i * 4 + 3];
		}

		alphaAverage = alphaAverage / (imageData.data.length / 4);

		if (alphaAverage > 10.0) {
			return this;
		}

		return null;
	}

	return null;
};

DesignerViewConnector.prototype.setStart = function(x, y) {
	if (x === undefined) {
		x = this.start.x;
	}

	if (y === undefined) {
		y = this.start.y;
	}

	this.start = { x: x, y: y };
	this.getRoot().repaint();
	return;
};

DesignerViewConnector.prototype.setEnd = function(x, y) {
	if (x === undefined) {
		x = this.end.x;
	}

	if (y === undefined) {
		y = this.end.y;
	}

	this.end = { x: x, y: y };
	this.getRoot().repaint();
	return;
};

DesignerViewConnector.prototype.drawArrow = function(ctx) {
	// #layer1
	ctx.save();
	ctx.transform(1.0, 0.0, 0.0, 1.0, 0.0, -1044.3622);

	// #rect4134
	ctx.save();
	ctx.beginPath();
	ctx.transform(1.0, 0.0, 0.0, 1.0, 0.0, 1044.3622);
	ctx.moveTo(0.0, 0.0);
	ctx.lineTo(0.0, 8.0);
	ctx.lineTo(8.0, 4.0);
	ctx.lineTo(0.0, 0.0);
	ctx.fill();
	ctx.restore();
	ctx.restore();
};

DesignerViewConnector.prototype.paint = function(context, width, height) {
	if (!this.visible) {
		return null;
	}

	var lineWidth = this.convertToPixels(this.lineWidth, 0);
	var x1 = this.convertToPixels(this.start.x, width);
	var y1 = this.convertToPixels(this.start.y, height);
	var x2 =
		this.convertToPixels(this.end.x, width) - this.arrowIcon.getWidth();
	var y2 = this.convertToPixels(this.end.y, height);
	var lineColor = this.lineColor;

	if (this.hover) {
		lineColor = this.lineColorHover;
	}

	if (this.selected) {
		lineColor = this.lineColorFocused;
		lineWidth = this.lineWidthFocused;
	}

	/*
	 * In memory of Atmosphere Classic
	 * 2014-2018
	 *
	 * "I guess the arrow draw code is useful?"
	 */

	var xSwing = x1 - x2;
	var xSwingMax = 40;
	var xSwingDeadZone = 15;

	if (xSwing > xSwingMax) xSwing = xSwingMax;

	if (Math.abs(xSwing) < xSwingDeadZone)
		xSwing = Math.sign(xSwing) * xSwingDeadZone;

	if (xSwing === 0) {
		xSwing = xSwingDeadZone;
	}

	context.save();

	context.beginPath();
	context.lineWidth = lineWidth;
	context.strokeStyle = lineColor;

	if (x1 < x2) {
		context.moveTo(x1, y1);
		context.bezierCurveTo(
			x1 + (x2 - x1) / 2,
			y1,
			x1 + (x2 - x1) / 2,
			y2,
			x2,
			y2,
		);
	} else if (y1 < y2) {
		context.moveTo(x1, y1);
		context.bezierCurveTo(
			x1 + xSwing,
			y1 + 20,
			x1 + xSwing,
			y1 + 40,
			x1 + (x2 - x1) / 2,
			y1 + (y2 - y1) / 2,
		);

		context.moveTo(x2, y2);
		context.bezierCurveTo(
			x2 - xSwing,
			y2 - 20,
			x2 - xSwing,
			y2 - 40,
			x1 + (x2 - x1) / 2,
			y1 + (y2 - y1) / 2,
		);
	} else {
		context.moveTo(x1, y1);
		context.bezierCurveTo(
			x1 + xSwing,
			y1 - 20,
			x1 + xSwing,
			y1 - 40,
			x1 + (x2 - x1) / 2,
			y1 + (y2 - y1) / 2,
		);

		context.moveTo(x2, y2);
		context.bezierCurveTo(
			x2 - xSwing,
			y2 + 20,
			x2 - xSwing,
			y2 + 40,
			x1 + (x2 - x1) / 2,
			y1 + (y2 - y1) / 2,
		);
	}

	context.stroke();

	context.save();

	context.translate(x2, y2 - this.arrowIcon.getHeight() / 2);
	context.fillStyle = lineColor;
	this.drawArrow(context);
	context.restore();

	if (this.drawBadge) {
		var imageWidth = this.badgeIcon.getWidth() || 32;
		var imageHeight = this.badgeIcon.getHeight() || 32;

		context.translate(
			x1 + (x2 - x1) / 2 - imageWidth / 2,
			y1 + (y2 - y1) / 2 - imageHeight / 2,
		);
		this.badgeIcon.paint(context, width, height);
	}

	context.restore();
};
