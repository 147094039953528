function WidgetMultipleDeviceSettings(id, api, parentWidget, options) {
	WidgetBase.call(this, id, api, parentWidget, options);

	this.titleSpanId = this.generateChildId('titleSpanId');
	this.settingsFormId = this.generateChildId('settingsFormId');
	this.formWidget = null;
	this.$fieldToggleCheckboxes = null;
}

WidgetMultipleDeviceSettings.prototype = Object.create(WidgetBase.prototype);
WidgetMultipleDeviceSettings.prototype.constructor = WidgetMultipleDeviceSettings;

WidgetMultipleDeviceSettings.prototype.initialize = function(callback) {
	// Used later to collect all the device settings
	this.projectName = this.getOptions().projectName;
	this.projectUuid = this.getOptions().projectUuid;
	this.versionUuid = this.getOptions().versionUuid;
	this.defaultSettings = this.getOptions().defaultSettings;
	this.deviceIdList = this.getOptions().deviceIdList || [];

	this.renderTemplate(
		{
			titleLabel: this.projectName || this.getLanguageTag('name'),
			titleSpanId: this.titleSpanId,
			deviceCount: formatString(
				this.getLanguageTag('deviceCount'),
				this.deviceIdList.length,
			),
			settingsFormId: this.settingsFormId,
		},
		WidgetMultipleDeviceSettings.name,
	);
	this.$titleSpan = $(`#${this.titleSpanId}`);

	this.addChildWidget(
		WidgetSettingsForm,
		this.settingsFormId,
		{
			// These opts will eventually
			// be set in this.update()
			fields: [],
			buttons: [],
		},
		function(err, formWidget) {
			const formOpts = this.formOptionsFrom(this.defaultSettings);
			this.formWidget = formWidget;
			this.formWidget.setForm(formOpts.fields);
			this.initialValues = this.formWidget.getValues();

			this.$titleSpan.on('click', (event) => {
				this.showDeviceList((err) => {
					if (err) {
						return console.warn(
							`Unable to open device modal due to ${err}`,
						);
					}
				});
			});

			// Uncheck all checkboxes by default
			this.$fieldToggleCheckboxes = $(
				this.formWidget
					.getContainer()
					.find('.WidgetSettingsForm-Field-Toggle-Checkbox'),
			);

			this.$fieldToggleCheckboxes.prop('checked', false);

			WidgetBase.prototype.initialize.call(this, callback);
		},
	);
};

WidgetMultipleDeviceSettings.prototype.showDeviceList = function(callback) {
	const currentWidget = this;
	console.log(this.deviceIdList);
	this.getMainContainer().setModalWidget(
		WidgetDeviceList,
		{ deviceIdList: this.deviceIdList },
		(err, deviceListWidget) => {
			// empty callback, nothing needs to happen once its shown
			deviceListWidget.addEventListener('dismissed', function() {
				currentWidget.getMainContainer().hideModal();
			});
		},
	);
	this.getMainContainer().showModal();
	return callback.call(this, false);
};

WidgetMultipleDeviceSettings.prototype.formOptionsFrom = function(settings) {
	const opts = {
		fields: [],
		buttons: [],
	};
	settings.forEach((setting) => {
		opts.fields.push({
			name: setting.name,
			type: setting.inputType,
			label: setting.title,
			description: setting.description,
			// Always use the default value here
			value: setting.defaultValue,
			defaultValue: setting.defaultValue,
			toggleable: true,
		});
	});

	return opts;
};

WidgetMultipleDeviceSettings.prototype.ICON =
	'./Resources/icons/DeviceSettings.svg';

WidgetMultipleDeviceSettings.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'Settings',
			wifiSettings: 'Configure Wi-Fi Settings',
			deviceId: 'Device ID',
			changedToId: 'Changed To',
			changedAt: 'Changed',
			changedById: 'User',
			listOrder: 'List Order',
			settingName: 'Name',
			settingTitle: 'Title',
			description: 'Description',
			inputType: 'Input Type',
			inputTypeProperties: 'Input Properties',
			value: 'Value',
			defaultValue: 'Default Value',
			deviceCount: '({0} devices)',
		},
	},
);
