function WidgetDataTable(id, api, parent, options) {
	
	options = options || {};

	if (options.readOnly) {
		options.menuOptions = options.menuOptions || [
			{ label: this.getLanguageTag('help'), value: 'help' },
		];
	} else {
		options.menuOptions = options.menuOptions || [
			{ label: this.getLanguageTag('settings'), value: 'settings' },
			{
				label: this.getLanguageTag('decimalPlaces'),
				value: 'decimalPlaces',
			},
			{ label: this.getLanguageTag('help'), value: 'help' },
			{ label: this.getLanguageTag('remove'), value: 'remove' },
		];
	}

	options.gotoDevice = true;
	
	WidgetDashboardBase.call(this, id, api, parent, options);
	
	this._updateInterval = null;
	this._showingSettings = false;

	this.tableContainerId = this.generateChildId('tableContainer');

	this.config = {
		deviceId: null,
		title: getLanguageTag(this.constructor, 'name'),
		storageName: null,
		variables: null,
		updateTime: 60000,
	};

	this.renderTemplate(
		{
			tableContainerId: this.tableContainerId,
		},
		WidgetDataTable.name,
	);

	this.tableContainer = $(`#${this.tableContainerId}`);

	this.tableWidget = null;
}

WidgetDataTable.prototype = Object.create(WidgetDashboardBase.prototype);
WidgetDataTable.prototype.constructor = WidgetDataTable;

WidgetDataTable.prototype.initialize = function(callback) {
	// Initialize will just set up the empty table,
	// and update is where the API call gets made
	// to retrieve data.
	const currentWidget = this;

	this.addEventListener('optionSelected', function(option) {
		if (option === 'decimalPlaces') {
			this.showDecimalPointsSettings();
		}
	});

	this.addChildWidget(
		WidgetTableDynamic,
		currentWidget.tableContainerId,
		{
			data: [], // Update will provide the data
			apiRoute: this.dataBy.bind(currentWidget),
			ajaxSorting: true,
			headerSort: true,
			paginationSize: 20,
			columns: [], // This gets set in the update function
			addParams: () => {
				// These params get fed in as the
				// first argument to WidgetDataTable.prototype.dataBy
				const params = {};
				if (currentWidget && currentWidget.config) {
					params.id = currentWidget.config.deviceId;
					params.elementName = currentWidget.config.storageName;
				}
				params.limit = 7; // should stay within the widget's height
				if (
					currentWidget.tableWidget &&
					currentWidget.tableWidget._table
				) {
					const tabulatorInstance = currentWidget.tableWidget._table;
					currentWidget.beforeSort(tabulatorInstance.getSorters());
				}
				return params;
			},
			rowFormatter: (row) => {
				return row;
			},
		},
		function(err, tableWidget) {
			currentWidget.tableWidget = tableWidget;
			WidgetDashboardBase.prototype.initialize.call(
				currentWidget,
				callback,
			);
		},
	);
};

WidgetDataTable.prototype.remove = function(callback) {
	if (this._updateInterval) {
		clearInterval(this._updateInterval);
		this._updateInterval = null;
	}

	if (this._showingSettings) {
		this.getMainContainer().hideModal();
	}

	WidgetDashboardBase.prototype.remove.call(this, callback);
};

// Gets 1 page of 10000 points
// returned via the provided query to the v2 storage API.
WidgetDataTable.prototype.dataBy = async function(query) {
	const api = this.getApiV2();
	// check for mandatory api params
	if (!query.id || !query.elementName) {
		return null;
	}

	const response = await api.apis.data.getDeviceData(query);
	const { data, meta } = response;

	// Map data into something flat that Tabulator can use
	const tableData = data.map((storedValue) => {
		const obj = { ...storedValue };
		obj._timestamp = storedValue.createdAt;
		return obj;
	});

	return { meta, data: tableData };
};

WidgetDataTable.prototype.update = function(callback) {
	const currentWidget = this;
	const api = currentWidget.getApiV2();

	// set title
	this.setTitle(this.config.title || this.getLanguageTag('name'));

	// check for mandatory api params
	if (!this.config.deviceId || !this.config.storageName) {
		console.warn(
			`[${this.constructor.name}] Unable to update() due to missing API params!`,
		);
		callback.call(this, false);
		return;
	}

	// The dataTypes variable will be used later, to determine
	// the formatter to apply to each column.
	// Build the query to get the last values and datatypes
	let dataTypes;
	const lastValQuery = {
		id: this.config.deviceId,
		elementName: this.config.storageName,
	};

	// Get a copy of the tabulator instance, to call replaceData()
	// and other settings post-update
	const tabulatorInstance = currentWidget.tableWidget._table;

	// make the API call to get lastValue to infer the datatypes
	api.apis.data
		.getLastDeviceData(lastValQuery)
		.then((response) => {
			const { values } = response;
			/* What values might look like...
				{
					"updatedAt": "2020-11-16T18:04:59.596Z",
					"deviceId": "5fb2bca55ef8632bdf807ed9",
					"elementName": "CloudStorage",
					"values": {
						"temperature": 46,
						"pressure": 62,
						"humidity": 85,
						"voltage": 83,
						"tamperSwitch": false,
						"firmwareTime": "2020-11-16T18:04:59.554Z",
						"message": "temperature within range"
					}
				}
			*/

			if(!values) {
				return;
			}
			
			// UI expects the _timestamp field, what should be used as the _timestamp?
			values._timestamp = response.updatedAt;
			dataTypes = DataTypes.dataTypesFrom(JSON.stringify(values));

			// http://tabulator.info/docs/4.0/update#alter-update
			return tabulatorInstance.replaceData();
		})
		.then(() => {
			
			if(dataTypes) {
				// apply column settings
				const configuredCols = currentWidget.buildColumnsWith(dataTypes);
				tabulatorInstance.setColumns(configuredCols);

				const sorting = currentWidget.getSorting();

				//Has any sorting been set yet?
				if (sorting.length > 0) {
					// apply sort
					tabulatorInstance.setSort(sorting[0].column, sorting[0].dir);
				}

				// show the table
				currentWidget.tableContainer.show();
			} else {
				// hide the table
				currentWidget.tableContainer.show();
			}

			// signal end of update
			callback.call(currentWidget, false);
			return;
		})
		.catch((error) => {
			console.warn(
				`[${currentWidget.constructor.name}] Error when calling update!`,
			);
			console.error(error);
			callback.call(currentWidget, error);
			return;
		});
};

// Because this widget is dealing with arbitrary user
// data. It cannot anticipate what the data is going to look
// like. This function will allow the widget to parse the data
// from the detected dataTypes and format each column
// appropriately for that datatype
WidgetDataTable.prototype.buildColumnsWith = function(dataTypes) {
	const currentWidget = this;
	// Set the config for tabulator column visibility
	// These are based on arbitrary user inputs specified in the config
	const columns = [];

	// If a number is detected in the API data, the configured decimal
	// point settings will be applied to each field via use of
	// this formatter.
	const decimalFormatter = (cell, formatterParams, onRendered) => {
		const { decimalPlaces } = formatterParams;
		const value = cell.getValue();
		if (value) {
			return Number(value).toFixed(decimalPlaces);
		}
	};

	//If a date is detected in the API data, we will use the date formatter.
	const timeFormatter = (cell, formatterParams, onRendered) => {
		const value = cell.getValue();
		const momentDate = new moment(value);

		if (momentDate.isValid) {
			return new Date(value).toLocaleString();
		} else {
			return '';
		}
	};

	this.config.variables.forEach((variable) => {
		// "variable" can be thought of as the current column
		const config = {};

		// Show the default timestamp as "Server Timestamp"
		// in the column headers
		config.title = getFromLanguageObject(
			currentWidget.variableLanguage,
			variable,
		);

		// This is to ensure the table processes the data correctly
		// when making the ajax request with Tabulator.
		if (variable === '_timestamp') {
			config.field = variable;
		} else {
			config.field = `payload.${variable}`;
		}

		const dataType = dataTypes[variable];

		if (dataType === 'Number') {
			// config.sorter = 'number';
			const decimalCfg = currentWidget.config.decimalPlaces || {};
			const decimalPlaces = decimalCfg[variable];

			// If a decimal point setting is available,
			// set the precision
			if (decimalPlaces && decimalPlaces > -1) {
				config.formatter = decimalFormatter;
				config.formatterParams = { decimalPlaces };
			}
		}
		// Render as date if detected to be a date
		if (dataType === 'Date') {
			config.formatter = timeFormatter;
		}

		columns.push(config);
	});

	return columns;
};

WidgetDataTable.prototype.getSorting = function() {
	let sorting = [];

	if (
		!this.config ||
		!this.config.sortingType ||
		!this.config.sortingColumn
	) {
		return sorting;
	}

	let column;
	let dir;

	// Only sort on the payload field.
	// This is what the v2 storage API
	// supports for sorting.
	if (
		this.config.sortingColumn &&
		!this.config.sortingColumn.startsWith('payload.')
	) {
		column = `payload.${this.config.sortingColumn}`;
	} else {
		column = `${this.config.sortingColumn}`;
	}

	if (this.config.sortingType === 'ascending') {
		dir = 'asc';
	}
	if (this.config.sortingType === 'descending') {
		dir = 'desc';
	}

	sorting = [
		{
			column,
			dir,
		},
	];

	// [{"column": "payload.temperature", "dir": "asc"}]
	return sorting;
};

// Store config if sorting is clicked
WidgetDataTable.prototype.beforeSort = function(sorters) {
	if (!sorters || sorters.length <= 0) {
		return;
	}

	const sorter = sorters[0];

	this.config.sortingColumn = sorter.field;

	if (sorter.dir === 'asc') {
		this.config.sortingType = 'ascending';
	}
	if (sorter.dir === 'desc') {
		this.config.sortingType = 'descending';
	}

	this.event('configurationSet', {
		widget: this,
		configuration: this.config,
	});
};

WidgetDataTable.prototype.showSettings = function(callback) {
	callback = callback || function() {};

	var currentWidget = this;

	this.getMainContainer().setModalWidget(
		WidgetSettingsForm,
		{
			fields: [
				{
					name: 'title',
					type: 'text',
					label: getLanguageTag(this.constructor, 'title'),
					value: this.config.title,
				},

				{
					name: 'updateTime',
					type: 'select',
					label: this.getLanguageTag('updateTime'),
					options: [
						{ value: 60000, label: this.getLanguageTag('1Minute') },
						{
							value: 300000,
							label: this.getLanguageTag('5Minutes'),
						},
						{
							value: 600000,
							label: this.getLanguageTag('10Minutes'),
						},
						{
							value: 900000,
							label: this.getLanguageTag('15Minutes'),
						},
						{
							value: 1800000,
							label: this.getLanguageTag('30Minutes'),
						},
						{ value: 3600000, label: this.getLanguageTag('1Hour') },
						{
							value: 7200000,
							label: this.getLanguageTag('2Hours'),
						},
						{
							value: 14400000,
							label: this.getLanguageTag('4Hours'),
						},
						{
							value: 21600000,
							label: this.getLanguageTag('6Hours'),
						},
						{
							value: 28800000,
							label: this.getLanguageTag('8Hours'),
						},
						{
							value: 43200000,
							label: this.getLanguageTag('12Hours'),
						},
					],
					value: this.config.updateTime,
				},

				{
					name: 'variables',
					type: 'deviceVariableSelect',
					label: getLanguageTag(this.constructor, 'variable'),
					value: {
						deviceId: this.config.deviceId,
						storageName: this.config.storageName,
						variables: { value: this.config.variables },
					},
					options: {
						variables: [
							{
								name: 'value',
								selectMultiple: true,
								label: getLanguageTag(
									this.constructor,
									'value',
								),
							},
						],
					},
				},
			],
		},
		function(err, settingsWidget) {
			settingsWidget.setTitle(
				getLanguageTag(currentWidget.constructor, 'configureDataTable'),
			);

			settingsWidget.addEventListener('dismissed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;
			});

			settingsWidget.addEventListener('confirmed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;

				var values = this.getValues();

				var config = {
					deviceId: (values.variables.deviceData || {}).id || null,
					storageName: values.variables.storageName || null,
					variables: (values.variables.variables || {}).value || null,
					sortingColumn: null,
					sortingType: null,
					title: values.title || '',
					decimalPlaces: currentWidget.config.decimalPlaces || {},
					updateTime: values.updateTime || 60000,
				};

				currentWidget.setConfiguration(config, function() {
					currentWidget.event('configurationSet', {
						widget: this,
						configuration: this.config,
					});
				});
			});

			currentWidget._showingSettings = true;
			this.showModal();

			callback.call(this, false);
			return;
		},
	);
};

WidgetDataTable.prototype.assignDecimalPlaces = function(decimalConfig) {
	for (var i = 0; i < Object.keys(decimalConfig).length; i += 1) {
		var currDecPl = Object.keys(decimalConfig)[i];
		decimalConfig[currDecPl] = this.decimalPlacesFallback(
			decimalConfig[currDecPl],
		);
	}
	return decimalConfig;
};

WidgetDataTable.prototype.decimalPlacesFallback = function(value) {
	if (value === 0) {
		return 0;
	}
	if (!value) {
		return 2;
	}
	return value;
};

WidgetDataTable.prototype.showDecimalPointsSettings = function(callback) {
	callback = callback || function() {};
	const currentWidget = this;
	const api = currentWidget.getApiV2();

	var settingsFields = [];

	// Check for mandatory API params
	if (!this.config.deviceId || !this.config.storageName) {
		this.getMainContainer().showPopupErrorMessage(
			this.getLanguageTag('youNeedToSetupFirst'),
		);
		callback.call(this, false);
		return;
	}

	// build the query to get the last values and datatypes
	let dataTypes;
	const lastValQuery = {
		id: this.config.deviceId,
		elementName: this.config.storageName,
	};

	// make the API call
	api.apis.data
		.getLastDeviceData(lastValQuery)
		.then((response) => {
			const { values } = response;

			dataTypes = DataTypes.dataTypesFrom(JSON.stringify(values));
			// Expect _timestamp to be a Date.
			if (!dataTypes._timestamp) {
				dataTypes._timestamp = 'Date';
			}

			// build decimal points config options from variables, using the data types
			// to determine if it's a valid input to configure decimal points for...
			// Doesn't make sense to config if it's not a Number.
			for (var i = 0; i < currentWidget.config.variables.length; i++) {
				var value = (currentWidget.config.decimalPlaces || {})[
					currentWidget.config.variables[i]
				];

				if (value === undefined || value === null) {
					value = -1;
				}

				if (dataTypes[currentWidget.config.variables[i]] === 'Number') {
					settingsFields.push({
						name: currentWidget.config.variables[i],
						type: 'number',
						min: 0,
						max: 5,
						label: currentWidget.config.variables[i],
						value: value,
					});
				}
			}

			// build modal widget with this data
			currentWidget
				.getMainContainer()
				.setModalWidget(
					WidgetSettingsForm,
					{ fields: settingsFields },
					function(err, settingsWidget) {
						settingsWidget.setTitle(
							currentWidget.getLanguageTag('decimalPlaces'),
						);

						settingsWidget.addEventListener(
							'dismissed',
							function() {
								currentWidget.getMainContainer().hideModal();
							},
						);

						settingsWidget.addEventListener(
							'confirmed',
							function() {
								currentWidget.getMainContainer().hideModal();

								var decimalPlaces = this.getValues();
								currentWidget.config.decimalPlaces = currentWidget.assignDecimalPlaces(
									decimalPlaces,
								);

								currentWidget.setConfiguration(
									currentWidget.config,
									function() {
										this.event('configurationSet', {
											widget: this,
											configuration: this.config,
										});
									},
								);
							},
						);

						this.showModal();

						callback.call(this, false);
						return;
					},
				);
		})
		.catch((error) => {
			if (error) {
				console.warn(
					`[${currentWidget.constructor.name}]: Error while calling .showDecimalPointSettings()!`,
				);
				console.error(error);
				callback.call(currentWidget, error);
				return;
			}
		});
};

WidgetDataTable.prototype.setConfiguration = function(config, callback) {
	var currentWidget = this;

	callback = callback || function() {};

	this.config = {
		deviceId: config.deviceId || null,
		storageName: config.storageName || null,
		variables: config.variables || null,
		title: config.title || '',
		sortingColumn: config.sortingColumn || null,
		sortingType: config.sortingType || null,
		decimalPlaces: config.decimalPlaces || {},
		updateTime: config.updateTime || 60000,
	};

	if (this._updateInterval) {
		clearInterval(this._updateInterval);
	}

	if (!config.deviceId) {
		callback.call(currentWidget, false);
		return;
	}

	const api = currentWidget.getApiV2().apis;
	api.devices
		.getDevice({ id: config.deviceId })
		.then((device) => {
			currentWidget.deviceUuid = device.uuid;

			currentWidget.update(function(err) {
				if (currentWidget.config.updateTime < 60000) {
					currentWidget.config.updateTime = 60000;
				}

				currentWidget._updateInterval = setInterval(function() {
					
					if(isActive()) {
						currentWidget.update(function() {});
					}
					
				}, currentWidget.config.updateTime || 60000);
			});

			callback.call(currentWidget, false);
		})
		.catch((err) => {
			callback.call(currentWidget, err);
		});
};

WidgetDataTable.prototype.getConfiguration = function() {
	return this.config;
};

WidgetDataTable.prototype.configure = function(callback) {};

WidgetDataTable.prototype.PACKERY_SIZE = 'WidgetDashboard_Container_LxL';

WidgetDataTable.prototype.variableLanguage = {
	'en-US': {
		_timestamp: 'Server Timestamp',
		_id: 'Object ID',
	},
};

WidgetDataTable.prototype.language = deepAssign(
	{},
	WidgetDashboardBase.prototype.language,
	{
		'en-US': {
			name: 'Data Table',
			configureDataTable: 'Configure Data Table',
			value: 'Select Value',
			noData: 'This Data Table has no Data!',
			_timestamp: 'Server Timestamp',
			variable: 'Data Source',
			decimalPlaces: 'Decimal Places',
			updateTime: 'Update Interval',
			'1Minute': '1 Minute',
			'5Minutes': '5 Minutes',
			'10Minutes': '10 Minutes',
			'15Minutes': '15 Minutes',
			'30Minutes': '30 Minutes',
			'1Hour': '1 Hour',
			'2Hours': '2 Hours',
			'4Hours': '4 Hours',
			'6Hours': '6 Hours',
			'8Hours': '8 Hours',
			'12Hours': '12 Hours',
			youNeedToSetupFirst: 'You need to setup the widget first',
		},
	},
);
