function DesignerViewIcon(root, icon, border, badge, handle, connectorIcon) {
	var currentIcon = this;

	CrocImageSimple.call(this, root, icon);

	this.handle = handle;
	this.border = 'none';
	this.borderColor = '#5d6261';
	this.borderWidth = '4px';

	this.borderFocusedColor = '#bfe7f7';
	this.borderFocusedWidth = '4px';
	this.borderFocusedRounded = '8px';

	this.borderPadding = '0px';
	this.labelTextMargin = '4px';
	this.labelTextSize = '12px';
	this.showLabel = true;
	this.selected = false;

	this.badge = null;
	this.connectorIcon = null;

	if (badge !== undefined && badge !== null) {
		this.badge = new CrocImageSimple(root, badge);
	}

	if (connectorIcon !== undefined && connectorIcon !== null) {
		this.connectorIcon = new CrocImageSimple(root, connectorIcon);
	} else {
		this.connectorIcon = new CrocImageSimple(
			root,
			root.themer.getValue(DesignerViewIcon, 'connectorIcon'),
		);
	}

	this.label = new CrocLabel(
		root,
		handle,
		this.labelTextSize +
			' Gotham-Book, ' +
			this.labelTextSize +
			' Roboto, ' +
			this.labelTextSize +
			' Helvetica Neue',
		this.borderColor,
	);
	this.label.setAlignment('center');

	this.addEventListener('pointermove', function(e) {
		this.getRoot().setCursor('pointer');

		return false;
	});

	this.addEventListener('pointerleave', function(e) {
		this.getRoot().setCursor();

		return true;
	});

	if (this.connectorIcon !== null) {
		this.connectorIcon.addEventListener('pointerdown', function(e) {
			currentIcon.event('connectorIconDown', e);

			return false;
		});

		this.connectorIcon.addEventListener('pointerup', function(e) {
			currentIcon.event('connectorIconUp', e);

			return false;
		});

		this.connectorIcon.addEventListener('pointermove', function(e) {
			currentIcon.event('connectorMove', e);

			this.getRoot().setCursor('e-resize');

			return false;
		});

		this.connectorIcon.addEventListener('pointerleave', function(e) {
			this.getRoot().setCursor();

			return false;
		});
	}

	this.setBorder(border);
}

DesignerViewIcon.prototype = Object.create(CrocImageSimple.prototype);
DesignerViewIcon.prototype.constructor = DesignerViewIcon;

DesignerViewIcon.prototype.setSelected = function(selected) {
	this.selected = Boolean(selected);

	this.getRoot().repaint();
};

DesignerViewIcon.prototype.setBadge = function(badge) {
	this.badge = new CrocImageSimple(
		this.getRoot(),
		this.getRoot().themer.getValue(DesignerViewIcon, badge),
	);
	this.getRoot().repaint();
};

DesignerViewIcon.prototype.setBorder = function(border) {
	if (this.border === border) {
		return;
	}

	if (DesignerViewIcon.prototype.VALID_BORDERS.indexOf(border) < 0) {
		return;
	}

	this.border = border;
	this.getRoot().repaint();

	return;
};

DesignerViewIcon.prototype.setHandle = function(handle) {
	this.handle = handle;
	this.label.setText(handle);
};

DesignerViewIcon.prototype.getHandle = function() {
	return this.handle;
};

DesignerViewIcon.prototype.getWidth = function() {
	var innerWidth = this.getInnerWidth();

	return innerWidth;
};

DesignerViewIcon.prototype.getInnerWidth = function() {
	var currentIconWidth = CrocImageSimple.prototype.getWidth.call(this);

	if (this.border === 'none') {
		return currentIconWidth;
	}

	var currentBorderPadding = this.convertToPixels(this.borderPadding, 0);

	return currentIconWidth + 2 * currentBorderPadding;
};

DesignerViewIcon.prototype.getHeight = function() {
	var currentIconHeight = CrocImageSimple.prototype.getHeight.call(this);

	if (this.border === 'none' && !this.showLabel) {
		return currentIconHeight;
	}

	var currentBorderPadding = this.convertToPixels(this.borderPadding, 0);
	var currentLabelTextSize = this.convertToPixels(this.labelTextSize, 0);
	var currentLabelTextMargin = this.convertToPixels(this.labelTextMargin, 0);

	return (
		currentIconHeight +
		2 * currentBorderPadding +
		currentLabelTextSize +
		currentLabelTextMargin * 2
	);
};

DesignerViewIcon.prototype.hitTest = function(context, x, y, width, height) {
	var hitReturn = [];
	var hitObject = null;
	var currentWidth = this.getInnerWidth();
	var currentHeight = this.getHeight();

	context.save();
	context.translate(
		currentWidth - currentWidth / 8,
		currentHeight / 2 - this.connectorIcon.getHeight() / 2,
	);
	hitObject = this.connectorIcon.hitTest(context, x, y, width, height);
	if (hitObject !== null) {
		hitReturn.push(hitObject);
	}

	context.restore();

	hitObject = CrocBase.prototype.hitTest.call(
		this,
		context,
		x,
		y,
		width,
		height,
	);

	if (hitObject !== null) {
		hitReturn.push(hitObject);
	}

	if (hitReturn.length === 0) {
		return null;
	}

	return hitReturn;
};

DesignerViewIcon.prototype.paint = function(context, width, height) {
	var currentIconHeight = CrocImageSimple.prototype.getHeight.call(this);
	var currentLabelTextMargin = this.convertToPixels(this.labelTextMargin, 0);
	var currentWidth = this.getInnerWidth();
	var currentHeight = this.getHeight();
	var currentIconWidth = CrocImageSimple.prototype.getWidth.call(this);
	var currentBorderPadding = this.convertToPixels(
		this.borderPadding,
		currentIconWidth,
	);
	var borderWidth = this.convertToPixels(this.borderWidth, 0);
	var borderColor = this.borderColor;

	context.save();

	if (this.connectorIcon !== null) {
		context.save();

		context.translate(
			currentWidth,
			currentHeight / 2 - this.connectorIcon.getHeight() / 2,
		);

		this.connectorIcon.paint(context, width, height);

		context.restore();
	}

	if (this.border !== 'none') {
		if (this.selected) {
			borderWidth = this.convertToPixels(this.borderFocusedWidth, 0);
			borderColor = this.borderFocusedColor;
		}

		context.fillStyle = 'white';
		context.fillRect(0, 0, currentWidth, currentHeight);

		context.lineCap = 'round';
		context.lineWidth = borderWidth;
		context.strokeStyle = borderColor;

		context.beginPath();
		context.moveTo(0, 0);
		context.lineTo(currentWidth, 0);
		context.lineTo(currentWidth, currentHeight);
		context.lineTo(0, currentHeight);
		context.lineTo(0, 0);

		context.stroke();

		context.restore();

		context.save();

		context.beginPath();
		context.lineTo(
			currentWidth - currentBorderPadding,
			currentBorderPadding,
		);
		context.lineTo(
			currentWidth - currentBorderPadding,
			currentHeight - currentBorderPadding,
		);
		context.lineTo(
			currentBorderPadding,
			currentHeight - currentBorderPadding,
		);
		context.lineTo(currentBorderPadding, currentBorderPadding);
		context.clip();

		context.translate(currentBorderPadding, currentBorderPadding);
	}

	context.translate(0, currentIconHeight / 4 - currentLabelTextMargin / 2);

	if (this.selected) {
		var r = this.convertToPixels(this.borderFocusedRounded, 0);
		var x = -(borderWidth / 2) - currentLabelTextMargin / 2;
		var y = -(borderWidth / 2) - currentLabelTextMargin / 2;
		var w = currentIconWidth + borderWidth + currentLabelTextMargin;
		var h = currentIconHeight + borderWidth + currentLabelTextMargin;

		if (w < 2 * r) r = w / 2;
		if (h < 2 * r) r = h / 2;

		context.fillStyle = this.borderFocusedColor;

		context.beginPath();
		context.moveTo(x + r, y);
		context.arcTo(x + w, y, x + w, y + h, r);
		context.arcTo(x + w, y + h, x, y + h, r);
		context.arcTo(x, y + h, x, y, r);
		context.arcTo(x, y, x + w, y, r);
		context.closePath();

		context.fill();
	}

	CrocImageSimple.prototype.paint.call(this, context, width, height);

	if (this.showLabel) {
		context.translate(
			0,
			currentIconHeight + currentLabelTextMargin + currentBorderPadding,
		);

		this.label.paint(context, currentIconWidth, height);
	}
	context.restore();

	if (this.badge !== null) {
		context.save();

		context.translate(
			-1.0 * (this.badge.getWidth() / 2) + currentIconWidth / 2,
			0,
		);

		this.badge.paint(context, width, height);

		context.restore();
	}
};

DesignerViewIcon.prototype.VALID_BORDERS = ['none', 'solid', 'dotted'];
