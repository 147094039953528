function WidgetNotificationManager(id, api, parentWidget, options) {
	/*
	 * This displays a filterable table of all the notifications
	 * in the system.
	 *
	 * @options
	 * @type {object}
	 * @property {number} pageSize - The maximum number of entries for each page
	 *
	 */

	WidgetBase.call(this, id, api, parentWidget, options);

	const currentWidget = this;
	this._table = null;
	this._currentTotalNotifications = 0;
	this.deleteButtonId = this.generateChildId('deleteButton');
	this.selectAllNotificationsButtonId = this.generateChildId(
		'selectAllNotificationsButton',
	);
	this.tableContainerId = this.generateChildId('tableContainer');
	this.selectAllNotificationsLabelId = this.generateChildId(
		'selectAllNotificationsLabel',
	);

	this.allNotificationsSelected = false;

	this.renderTemplate(
		{
			deleteButtonId: this.deleteButtonId,
			selectAllNotificationsButtonId: this.selectAllNotificationsButtonId,
			selectAllNotificationsLabelId: this.selectAllNotificationsLabelId,
			tableContainerId: this.tableContainerId,
			deleteNotifications: this.getLanguageTag(
				'removeSelectedNotifications',
			),
			selectAllNotifications: this.getLanguageTag(
				'selectAllNotifications',
			),
		},
		WidgetNotificationManager.name,
	);

	this.deleteButton = $(`#${this.deleteButtonId}`);
	this.selectAllNotificationsButton = $(
		`#${this.selectAllNotificationsButtonId}`,
	);

	this.selectAllNotificationsLabel = $(
		`#${this.selectAllNotificationsLabelId}`,
	);

	this.deleteButton.on('click', function() {
		currentWidget._onRemoveSelectedNotifications();
	});

	this.selectAllNotificationsButton.on('click', function() {
		currentWidget._onSelectAllNotifications();
	});

	this.deleteButton.hide();
	this.selectAllNotificationsButton.hide();
}

WidgetNotificationManager.prototype = Object.create(WidgetBase.prototype);
WidgetNotificationManager.prototype.constructor = WidgetNotificationManager;

WidgetNotificationManager.prototype.notificationEntryFormatter = function(img) {
	return $.handlebarTemplates[
		`${WidgetNotificationManager.name}_NotificationEntry`
	]({
		imgSrc: img || './Resources/icons/Unknown.svg',
	});
};

WidgetNotificationManager.prototype.initialize = function(callback) {
	const currentWidget = this;

	const api = currentWidget.getApiV2();
	this.addChildWidget(
		WidgetTableDynamic,
		currentWidget.tableContainerId,
		{
			hideFilterInput: true,
			selectable: true,
			apiRoute: api.apis.notifications.getNotifications.bind(
				currentWidget,
			),
			ajaxSorting: true,
			headerSort: false,
			addParams: () => {
				return {};
			},
			ajaxResponse: async (url, params, response) => {
				// Mark all of these notifications as read
				if (response.data.length > 0) {
					const reqParams = {};
					reqParams.to = response.data[0].createdAt.toISOString();

					if (response.data.length > 1) {
						reqParams.from = response.data[
							response.data.length - 1
						].createdAt.toISOString();
					}

					try {
						await api.apis.notifications.updateNotifications(
							reqParams,
							{ requestBody: { read: true } },
						);
						currentWidget
							.getMainContainer()
							.event('notificationsRead');
					} catch (err) {
						console.error(
							`Error marking notifications read`,
							err,
							reqParams,
						);
					}
				}

				let stats = ``;
				const { page, limit, row_count, data } = response;
				// tabulator's "row_count" is really the total records
				const total = row_count;
				const start = (page - 1) * limit + 1;
				const end = (page - 1) * limit + data.length;
				if (total > 0) {
					stats += `Showing ${start} - ${end} of ${total} entries`;
				} else {
					stats = '0 entries';
				}

				currentWidget._currentTotalNotifications = total;

				$(`#${currentWidget.tableContainerId}`)
					.find(`.WidgetTableDynamic-footer`)
					.text(stats);
				currentWidget.selectAllNotificationsLabel.text(
					`${currentWidget.getLanguageTag(
						'selectAllNotifications',
					)} (${currentWidget._currentTotalNotifications})`,
				);

				return response;
			},
			entryMapper: async (entry) => {
				const notification = new NotificationModel(api.apis, entry);
				entry.model = notification;
				entry.createdAt = new Date(entry.createdAt);
				entry.message = getMessageLanguage(entry.translations);
				entry.imgHtml = currentWidget.notificationEntryFormatter(
					notification.getImage(),
				);

				// AI-3173: If we are running on mobile (Phonegap)
				// we need to attach each anchor link so that we
				// can properly handle redirecting in the app
				if (isPhonegap()) {
					let tempDiv = $('<div>').html(entry.message);
					let anchors = tempDiv.find('a');

					anchors.each((i) => {
						let aHref = $(anchors[i]).attr('href');

						// Are we trying to use a global link for
						// something that should be local?
						// If so remove the global reference and
						// convert to local link
						if (aHref.includes(getAPIBase())) {
							aHref = aHref.replace(getAPIBase() + '/', '');
							$(anchors[i]).attr('href', aHref);
						}

						// Else we can assume we want to make a global
						// link and open the user's default
						// web browser
						else {
							$(anchors[i]).attr('target', '_system');
						}
					});

					entry.message = tempDiv.html();
				}
				return entry;
			},
			onSelectionChanged: (rows) => {
				if (rows.length > 0) {
					currentWidget.deleteButton.show();
				} else {
					currentWidget.deleteButton.hide();
				}

				let numberOfCheckboxesDisplayed = $(
					`#${currentWidget.tableContainerId}`,
				)
					.find('.tabulator-table')
					.find(':checkbox').length;
				let numberOfCheckboxesChecked = $(
					`#${currentWidget.tableContainerId}`,
				)
					.find('.tabulator-table')
					.find(':checkbox:checked').length;

				if (
					numberOfCheckboxesChecked === numberOfCheckboxesDisplayed &&
					numberOfCheckboxesChecked !== 0
				) {
					currentWidget.selectAllNotificationsButton.show();
				} else {
					currentWidget.allNotificationsSelected = false; //If for any reason we stop selecting all entries then set this flag to false.
					currentWidget.selectAllNotificationsButton.hide();
				}
			},
			paginationSize: 20,
			columns: [
				{
					formatter: 'rowSelection',
					titleFormatter: 'rowSelection',
					hozAlign: 'center',
					width: 10,
					responsive: 0,
				},
				{
					formatter: 'html',
					field: 'imgHtml',
					align: 'center',
					width: 60,
					responsive: 3,
				},
				{
					title: 'Message',
					formatter: 'html',
					field: 'message',
					formatterParams: {
						labelField: 'username',
					},
					minWidth: 200,
					responsive: 0,
				},
				{
					title: 'Created At',
					field: 'createdAt',
					formatter: 'datetimediff',
					formatterParams: {
						humanize: true,
						suffix: true,
						invalidPlaceholder: 'Never',
					},
					responsive: 2,
				},
			],
		},

		(err, tableWidget) => {
			currentWidget._table = tableWidget;
			
			// An event was thrown to indicate that we need to refresh our notifications
			currentWidget.getMainContainer().addEventListener('notificationsRefreshed', function() {
				currentWidget.update();
			});
			
			callback.call(currentWidget, false);
		},
	);
};

WidgetNotificationManager.prototype._onRemoveSelectedNotifications = async function() {
	const currentWidget = this;
	const api = this.getApiV2();

	const selectedRowsData = this._table.getSelectedRowsEntries();

	if (this.allNotificationsSelected) {
		this.getMainContainer().showConfirm(
			{
				message: this.getLanguageTag('confirmNotificationsRemoveAll'),
				title: this.getLanguageTag('removeNotificationsTitle'),
				confirmLabel: this.getLanguageTag(
					'removeSelectedNotifications',
				),
				confirmClasses: ['btn-danger'],
			},

			async () => {
				let query = {
					from: new Date(0).toISOString(),
					to: new Date().toISOString(),
				};

				try {
					await api.apis.notifications.deleteNotifications(query);

					currentWidget
						.getMainContainer()
						.showPopupInfoMessage(
							currentWidget.getLanguageTag(
								'allNotificationsRemoved',
							),
						);
				} catch (e) {
					console.error('Error deleting notifications', e);
					currentWidget
						.getMainContainer()
						.showPopupErrorMessage(
							currentWidget.getLanguageTag(
								'errorRemovingNotifications',
							),
						);
				}

				currentWidget.update(() => {
					
				});
			},
		);
	} else {
		this.getMainContainer().showConfirm(
			{
				message: this.getLanguageTag('confirmNotificationsRemove'),
				title: this.getLanguageTag('removeNotificationsTitle'),
				confirmLabel: this.getLanguageTag(
					'removeSelectedNotifications',
				),
				confirmClasses: ['btn-danger'],
			},

			async () => {
				const deletePromises = selectedRowsData.map((entry) => {
					return entry.model.delete();
				});
				try {
					await Promise.all(deletePromises);
					currentWidget
						.getMainContainer()
						.showPopupInfoMessage(
							currentWidget.getLanguageTag(
								'notificationsRemoved',
							),
						);
				} catch (e) {
					console.error('Error deleting notifications', e);
					currentWidget
						.getMainContainer()
						.showPopupErrorMessage(
							currentWidget.getLanguageTag(
								'errorRemovingNotifications',
							),
						);
				}

				currentWidget.update(() => {});
			},
		);
	}
};

WidgetNotificationManager.prototype._onSelectAllNotifications = function() {
	const currentWidget = this;

	this.allNotificationsSelected = !this.allNotificationsSelected;

	if (this.allNotificationsSelected) {
		// Change the button text to clear selected
		this.selectAllNotificationsLabel.text(
			this.getLanguageTag('clearSelectAllNotifications'),
		);
	} else {
		// Clear the selected rows on the table
		this._table.deselectRow();

		this.selectAllNotificationsLabel.text(
			`${this.getLanguageTag('selectAllNotifications')} (${
				this._currentTotalNotifications
			})`,
		);
	}
};

WidgetNotificationManager.prototype.update = function(callback) {
	const doneCb = callback || function() {};

	const currentWidget = this;
	currentWidget._table.update();
};

WidgetNotificationManager.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'Notifications',
			created: 'Created',
			removeSelectedNotifications: 'Delete',
			confirmNotificationsRemove:
				'Are you sure you want to delete the selected notifications?',
			confirmNotificationsRemoveAll:
				'Are you sure you want to delete all of your notifications?',
			removeNotificationsButton: 'Delete',
			removeNotificationsTitle: 'Delete Notifications',
			errorRemovingNotifications:
				'There was an error when deleting selected notifications. Please try again.',
			notificationsRemoved: 'Selected notifications removed',
			selectAllNotifications: 'Select All',
			clearSelectAllNotifications: 'Clear Selection',
			allNotificationsRemoved: 'All notifications removed',
			moveUsers: 'Move',
			addUser: 'Add',
			showSubOrganizations: 'Show Sub-Organizations',
		},
	},
);
