function ElementControllerEmbeddedBLECharacteristicCustom(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addVariable('bleServiceHandle', 'ATMO_BLE_Handle_t');
	this.addVariable('bleCharacteristicHandle', 'ATMO_BLE_Handle_t');

	this.addProperty('instance', null, 0, 'driverInstance', null, 'ble');

	//Service UUID for the characteristic
	this.addProperty(
		'bleServiceUuid',
		this._uuidValidator,
		parentPlane.getPrimaryUuid(),
		'text',
	);
	this.setPropertyEmbeddedConversion('bleServiceUuid', 'string');

	//Characteristic UUID for the characteristic
	this.addProperty(
		'bleCharacteristicUuid',
		this._uuidValidator,
		parentPlane.getNextAvailableUuidAfterPrimaryUuid(),
		'text',
	);
	this.setPropertyEmbeddedConversion('bleCharacteristicUuid', 'string');

	this.addProperty('read', null, true, 'checkbox', null, null);
	this.addProperty('write', null, true, 'checkbox', null, null);
	this.addProperty('notify', null, false, 'checkbox', null, null);

	// 	this.addProperty('readData', null, null, 'none', null, null);
	this.addProperty(
		'readDataType',
		null,
		'ATMO_DATATYPE_STRING',
		'select',
		this.DATA_TYPES,
		null,
	);
	// 	this.addProperty('readDataTypeStringLength', null, '64', 'number', null, 'Number');
	//
	// 	this.addProperty('writtenData', null, null, 'none', null, null);
	this.addProperty(
		'writeDataType',
		null,
		'ATMO_DATATYPE_STRING',
		'select',
		this.DATA_TYPES,
		null,
	);
	// 	this.addProperty('writeDataTypeStringLength', null, '64', 'number', null, 'Number');
	//
	// 	this.addProperty('notificationData', null, null, 'none', null, null);
	this.addProperty(
		'notifyDataType',
		null,
		'ATMO_DATATYPE_STRING',
		'select',
		this.DATA_TYPES,
		null,
	);
	// 	this.addProperty('notifyDataTypeStringLength', null, '64', 'number', null, 'Number');

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
	ATMO_BLE_GATTSAddService(
		ATMO_PROPERTY(undefined, instance),
		&ATMO_VARIABLE(undefined, bleServiceHandle), 
		ATMO_PROPERTY(undefined, bleServiceUuid));
	
	uint8_t property = 0;
	uint8_t permission = 0;
	
	property |= ATMO_PROPERTY(undefined, read) ? ATMO_BLE_Property_Read : 0;
	property |= ATMO_PROPERTY(undefined, write) ? ATMO_BLE_Property_Write : 0;
	property |= ATMO_PROPERTY(undefined, notify) ? ATMO_BLE_Property_Notify : 0;

	permission |= ATMO_PROPERTY(undefined, read) ? ATMO_BLE_Permission_Read : 0;
	permission |= ATMO_PROPERTY(undefined, write) ? ATMO_BLE_Permission_Write : 0;

	ATMO_DATATYPE types[3] = {ATMO_PROPERTY(undefined, writeDataType), ATMO_PROPERTY(undefined, readDataType), ATMO_PROPERTY(undefined, notifyDataType)};
	
	ATMO_BLE_GATTSAddCharacteristic(
		ATMO_PROPERTY(undefined, instance),
		&ATMO_VARIABLE(undefined, bleCharacteristicHandle), 
		ATMO_VARIABLE(undefined, bleServiceHandle), 
		ATMO_PROPERTY(undefined, bleCharacteristicUuid), 
		property, permission, ATMO_GetMaxValueSize(3, 64, types));
	
	ATMO_BLE_GATTSRegisterCharacteristicAbilityHandle(
		ATMO_PROPERTY(undefined, instance),
		ATMO_VARIABLE(undefined, bleCharacteristicHandle), 
		ATMO_BLE_Characteristic_Written, 
		ATMO_ABILITY(EmbeddedBLECharacteristicCustom, written));
	
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setValue', [], null, 'valueSet');
	this.setAbilityCode(
		'setValue',
		`
	
	// Convert to the desired write data type
	ATMO_Value_t convertedValue;
	ATMO_InitValue(&convertedValue);
	ATMO_CreateValueConverted(&convertedValue, ATMO_PROPERTY(undefined, readDataType), in);

	ATMO_BLE_GATTSSetCharacteristic(
		ATMO_PROPERTY(undefined, instance),
		ATMO_VARIABLE(undefined, bleCharacteristicHandle),
		convertedValue.size, 
		(uint8_t *)convertedValue.data,
		NULL);
	
	ATMO_FreeValue(&convertedValue);
		
	return ATMO_Status_Success;
	`,
	);

	// 	this.addAbility('notify', [{name:'value', validator:null}]);

	this.addAbility('written', [], ['written'], false, true);

	this.setAbilityCode(
		'written',
		`
	ATMO_CreateValueConverted(out, ATMO_PROPERTY(undefined, writeDataType), in);
	return ATMO_Status_Success;
	`,
	);

	// 	this.addAbility('notify', [{name:'value', validator:null}]);

	this.addAbility('subscibed', [], ['subscibed'], false, true);
	this.addAbility('unsubscribed', [], ['unsubscribed'], false, true);
}

ElementControllerEmbeddedBLECharacteristicCustom.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedBLECharacteristicCustom.prototype.constructor = ElementControllerEmbeddedBLECharacteristicCustom;

ElementControllerEmbeddedBLECharacteristicCustom.prototype.NAME =
	'BLECharacteristicCustom';
ElementControllerEmbeddedBLECharacteristicCustom.prototype.DEFAULT_TRIGGER =
	'written';
ElementControllerEmbeddedBLECharacteristicCustom.prototype.DEFAULT_ABILITY =
	'setValue';
ElementControllerEmbeddedBLECharacteristicCustom.prototype.DEFAULT_ARGUMENTS = [
	'value',
];
ElementControllerEmbeddedBLECharacteristicCustom.prototype.TYPE =
	'EmbeddedBLECharacteristicCustom';
ElementControllerEmbeddedBLECharacteristicCustom.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'ble',
];
ElementControllerEmbeddedBLECharacteristicCustom.prototype.HIDDEN = false;
ElementControllerEmbeddedBLECharacteristicCustom.prototype.COUPLER_TYPE =
	'bleCharacteristicCustom';
ElementControllerEmbeddedBLECharacteristicCustom.prototype.CATEGORY = 'ble';

ElementControllerEmbeddedBLECharacteristicCustom.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedBLECharacteristicCustom.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedBLECharacteristicCustom: 'BLE GATT Characteristic',

			bleServiceHandle: 'Service Handle',
			bleCharacteristicUuid: 'Characteristic Handle',
			instance: 'Instance',
			bleServiceUuid: 'Service UUID (128-bit)',
			bleCharacteristicUuid: 'Characteristic UUID (128-bit)',
			readDataType: 'Read Data Type',
			writeDataType: 'Write Data Type',
			notifyDataType: 'Notify Data Type',
			setup: 'Setup',
			setValue: 'Set Value',
			valueSet: 'Value Set',
			notify: 'Notify',
			notified: 'Notified',
			written: 'Written',
			subscibed: 'Subscribed',
			unsubscribed: 'Unsubscribed',
		},
	},
);
