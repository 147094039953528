/*
 * This is the table widget that allows you to display items in
 * a row column format.
 *
 * @param {string} id					Unique container div id
 * @param {CloudAPI} api				CloudAPI instance
 * @param {WidgetBase} parent			Parent Widget
 * @param {Object} options 				Widget Options
 * @param {Boolean} options.showTotal	Should we display the total number of entries at the bottom of the table
 * @param {Number} options.pageSize		How many entries should be on each page
 */
function WidgetTable(id, api, parent, options) {
	WidgetBase.call(this, id, api, parent, options);

	this._data = [];
	this._columnSettings = {};
	this._columnOrder = [];
	this._columnIdToVariable = {};
	this._currentSorting = null;
	this._actionMenuEntries = null;
	this.selectedRow = null;

	this.headerId = this.generateChildId('header');
	this.filterInputId = this.generateChildId('filterInput');
	this.selectAllId = this.generateChildId('selectAll');
	this.selectClearId = this.generateChildId('selectClear');
	this.filterInputContainerId = this.generateChildId('filterInputContainer');
	this.selectionActionId = this.generateChildId('selectionAction');
	this.selectionActionMenuContainerId = this.generateChildId(
		'selectionActionMenuContainer',
	);

	this._filterTimeout = null;
	this._page = null;
}

WidgetTable.prototype = Object.create(WidgetBase.prototype);
WidgetTable.prototype.constructor = WidgetTable;

WidgetTable.prototype.formatterTime = function(a) {
	return new Date(a).toLocaleString();
};

WidgetTable.prototype.formatterTimeFromNow = function(a) {
	//If there is no value set have it return never
	if (a === null) {
		return getLanguageTag(WidgetTable, 'never');
	}

	return moment(new Date(a)).fromNow();
};

WidgetTable.prototype.initialize = function(callback) {
	// 	this.setTable(['notification', 'received'],
	//
	// 		{
	// 			notification:{label:'Notification', sortable:true},
	// 			received:{label:'Received', sortable:true, formatter:function(a){return new Date(a).toLocaleString();}}
	// 		},
	//
	// 		[
	// 			{notification:'Temperature data above limit!', received:Date.now() - 1000},
	// 			{notification:'Colin wants new styles!', received:Date.now() - 123000},
	// 			{notification:'All the tribbles have escaped!', received:Date.now() - 10000},
	// 			{notification:'All CSS and no play makes Kieron a dull boy...', received:Date.now()},
	// 		], 'descending', 'received');
	//
	// 	this.addEventListener('columnClicked', function(e) {
	// 		console.log(e);
	// 	});

	WidgetBase.prototype.initialize.call(this, callback);
};

WidgetTable.prototype.remove = function(callback) {
	if (this._filterTimeout !== null) {
		clearTimeout(this._filterTimeout);
		this._filterTimeout = null;
	}

	WidgetBase.prototype.remove.call(this, callback);
};

WidgetTable.prototype.getRow = function(rowNumber) {
	return this.getContainer().find(`*[data-tableRow="${rowNumber}"]`);
};

WidgetTable.prototype.getColumn = function(rowNumber, columnNumber) {
	return this.getRow(rowNumber).find(`*[data-tableColumn="${columnNumber}"]`);
};

WidgetTable.prototype.getRowSelectInput = function(rowNumber) {
	return this.getContainer().find(`*[data-tableSelect="${rowNumber}"]`);
};

WidgetTable.prototype.getSelectedRowsData = function() {
	var outputData = [];

	var inputs = this.getContainer().find('*[data-tableSelect]');

	for (var i = 0; i < inputs.length; i++) {
		if ($($(inputs[i]).find('input')).prop('checked')) {
			var rowNumber = parseInt($(inputs[i]).attr('data-tableSelect'));
			outputData.push(this._filteredData[rowNumber]);
		}
	}

	return outputData;
};

WidgetTable.prototype._checkToShowClearSelection = function() {
	//If there are no available actions then don't show anything.
	if (!this._actionMenuEntries) {
		this._selectClear.hide();
		this._selectAll.hide();
		this._selectionAction.hide();
		this._actionMenuWidget.hide();
		this.getContainer()
			.find('*[data-tableSelect]')
			.hide();
		return;
	}

	if (
		this.getContainer()
			.find('*[data-tableSelect]')
			.find('input:checked').length > 0
	) {
		this._selectClear.show();
		this._selectAll.hide();
		this._selectionAction.show();
		this._actionMenuWidget.hide();
		return;
	}

	this._selectClear.hide();
	this._selectAll.show();
	this._selectionAction.hide();
	this._actionMenuWidget.hide();
	return;
};

WidgetTable.prototype._attachCheckboxEvents = function() {
	var inputs = this.getContainer()
		.find('*[data-tableSelect]')
		.find('input');
	var wrappers = this.getContainer().find('*[data-tableSelect]');

	var currentWidget = this;

	for (var i = 0; i < wrappers.length; i++) {
		$(wrappers[i]).click(function(e) {
			e.stopPropagation();
		});
	}

	for (var i = 0; i < inputs.length; i++) {
		$(inputs[i]).change('change', function() {
			currentWidget._checkToShowClearSelection();
		});
	}
};

WidgetTable.prototype._attachRowColumnEventHandlers = function() {
	var currentWidget = this;

	var rows = this.getContainer().find('*[data-tableRow]');
	var columns = this.getContainer().find('*[data-tableColumn]');

	rows.each(function() {
		$(this).click(function() {
			var row = parseInt($(this).attr('data-tableRow'));
			if (currentWidget.getOptions().showRowSelected === true) {
				currentWidget
					.getContainer()
					.find('.WidgetTable-Row')
					.removeClass('WidgetTable-RowSelected');
				$(this)
					.find('.WidgetTable-Row')
					.addClass('WidgetTable-RowSelected');
			}
			currentWidget.event('rowClicked', {
				row: row,
				rowData: currentWidget._filteredData[row],
			});
		});
	});

	columns.each(function() {
		$(this).click(function() {
			var row = parseInt(
				$(this)
					.parent()
					.parent()
					.attr('data-tableRow'),
			);
			var column = parseInt($(this).attr('data-tableColumn'));

			currentWidget.event('columnClicked', {
				row: row,
				column: column,
				rowData: currentWidget._filteredData[row],
				columnData:
					currentWidget._filteredData[row][
						currentWidget._columnOrder[column]
					],
			});
		});
	});
};

WidgetTable.prototype.selectAllRows = function() {
	var inputs = this.getContainer()
		.find('*[data-tableSelect]')
		.find('input');

	inputs.each(function() {
		$(this).prop('checked', true);
	});

	this._checkToShowClearSelection();
};

WidgetTable.prototype.deselectAllRows = function() {
	var inputs = this.getContainer()
		.find('*[data-tableSelect]')
		.find('input');

	inputs.each(function() {
		$(this).prop('checked', false);
	});

	this._checkToShowClearSelection();
};

WidgetTable.prototype.clearFilter = function() {
	if (this._filterInput) {
		this.filter('');
	}
};

WidgetTable.prototype.filter = function(value) {
	this._filterValue = value.trim() || null;

	this.sortTable(
		this._currentSorting.type,
		this._currentSorting.key,
		function() {
			this._filterInput.val(value);
			this._filterInput.focus();
			this.event('tableFilterChanged', this._filterValue);
		},
	);
};

WidgetTable.prototype.toggleSorting = function(key) {
	if (this._currentSorting === null || this._currentSorting.key !== key) {
		this.sortTable('descending', key);
		this.event('tableSortingChanged', this._currentSorting);
	} else {
		if (this._currentSorting.type === 'ascending') {
			this.sortTable('descending', key);
			this.event('tableSortingChanged', this._currentSorting);
		} else {
			this.sortTable('ascending', key);
			this.event('tableSortingChanged', this._currentSorting);
		}
	}
};

WidgetTable.prototype.sortTable = function(type, key, callback) {
	var currentWidget = this;
	callback = callback || function() {};

	if (type === 'none') {
		type = false;
	}

	if (!(this._columnSettings[key] || {}).sortable) {
		type = false;
		key = null;
	}

	this._currentSorting = { type: type, key: key };

	this._columnIdToVariable = {};

	if (!this._columnOrder) {
		var mergedData = {};

		for (var i = 0; i < this._data.length; i++) {
			mergedData = Object.assign(mergedData, this._data[i]);
		}

		this._columnOrder = Object.keys(mergedData);
	}

	var columns = [];

	for (var i = 0; i < this._columnOrder.length; i++) {
		var currentColumnSettings = this._columnSettings[
			this._columnOrder[i]
		] || {
			label: this._columnOrder[i],
		};

		columns.push({
			label: currentColumnSettings.label,
			id: this.generateChildId(
				currentColumnSettings.childId || this._columnOrder[i],
			),
			sorting:
				this._currentSorting.key === this._columnOrder[i]
					? this._currentSorting.type
					: false,
			variable: this._columnOrder[i],
			columnWidth: currentColumnSettings.columnWidth || 1.0,
		});
	}

	if (type && this._columnOrder.indexOf(key) < 0) {
		console.error(`WidgetTable.prototype.sortTable: No such key "${key}"`);
		callback.call(this, { type: 'noSuchKey' });
		return;
	}

	if (type) {
		var currentColumnSettings = this._columnSettings[key] || {};

		var compareAscending =
			currentColumnSettings.compareAscending ||
			function(a, b) {
				if (a[key] === null || a[key] === undefined) {
					return -1;
				}

				if (b[key] === null || b[key] === undefined) {
					return 1;
				}

				if (
					a[key].constructor === String &&
					b[key].constructor === String
				) {
					return a[key].localeCompare(b[key]);
				}

				if (a[key] < b[key]) return -1;
				if (a[key] > b[key]) return 1;

				return 0;
			};

		var compareDescending =
			currentColumnSettings.compareDescending ||
			function(a, b) {
				if (a[key] === null || a[key] === undefined) {
					return 1;
				}

				if (b[key] === null || b[key] === undefined) {
					return -1;
				}

				if (
					a[key].constructor === String &&
					b[key].constructor === String
				) {
					return b[key].localeCompare(a[key]);
				}

				if (a[key] > b[key]) return -1;
				if (a[key] < b[key]) return 1;

				return 0;
			};

		if (type === 'ascending') {
			this._data.sort(compareAscending);
		} else if (type === 'descending') {
			this._data.sort(compareDescending);
		}
	}

	var tableData = [];
	var filteredData = [];

	for (var i = 0; i < this._data.length; i++) {
		var currentRow = { class: this._data[i]['_class'] || '', columns: [] };

		for (var j = 0; j < this._columnOrder.length; j++) {
			this._columnSettings[this._columnOrder[j]] =
				this._columnSettings[this._columnOrder[j]] || {};

			var currentColumnSettings = this._columnSettings[
				this._columnOrder[j]
			];

			var currentData = this._data[i][this._columnOrder[j]];

			if (currentData === undefined) {
				currentData = null;
			}

			if (currentColumnSettings.formatter) {
				currentData = currentColumnSettings.formatter.call(
					this,
					currentData,
					this._data[i],
				);
			}

			var finalData = {
				value: currentData,
				columnWidth: currentColumnSettings.columnWidth || 1.0,
			};

			currentRow.columns.push(finalData);
		}

		var matchingFilter = false;

		//Do we have a filter set? Then we process the data through it
		if (this._filterValue) {
			for (var k in this._columnSettings) {
				var currentFormatter = this._columnSettings[k].formatter;

				var filterFunction =
					this._columnSettings[k].filter ||
					function(data, formattedData, filterValue) {
						if (
							formattedData === null ||
							formattedData === undefined
						) {
							formattedData = '';
						}

						if (formattedData.toString === undefined) {
							data = '';
						}

						if (filterValue.toString === undefined) {
							filterValue = '';
						}

						return (
							formattedData
								.toString()
								.toLocaleLowerCase()
								.indexOf(
									filterValue.toString().toLocaleLowerCase(),
								) > -1
						);
					};

				var formattedData = this._data[i][k];

				if (currentFormatter) {
					formattedData = currentFormatter.call(
						currentWidget,
						formattedData,
						this._data[i],
					);
				}

				//Do we pass the filter check?
				if (
					filterFunction(
						this._data[i][k],
						formattedData,
						this._filterValue,
					)
				) {
					matchingFilter = true;
					break;
				}
			}

			if (matchingFilter) {
				tableData.push(currentRow);
				filteredData.push(this._data[i]);
			}
		} else {
			tableData.push(currentRow);
			filteredData.push(this._data[i]);
		}
	}

	this._columns = columns;
	this._tableData = tableData;
	this._filteredData = filteredData;

	const parent = this.getParent();
	if (parent && parent.constructor.name === 'WidgetDataBulletin') {
		// WidgetDataBulletin post-processing
		// Put the _timestamp field at the
		// bottom row if configured
		const timestampIdx = this._tableData.findIndex((element) => {
			if (
				element &&
				element.columns &&
				element.columns[0] &&
				element.columns[0].value ===
					getFromLanguageObject(parent.variableLanguage, '_timestamp')
			) {
				return true;
			}
		});

		const timestampRowExists = timestampIdx !== -1;

		if (timestampRowExists) {
			this._tableData.push(this._tableData.splice(timestampIdx, 1)[0]);
		}
	}

	this.renderTable(callback);
};

/*
 * This function will set the table's data for you.
 * The first variable is the table's column order and reference names for each column,
 * next is the settings data for each column. The keys in this object must match the
 * values used in the column order.
 *
 * For each column in the column settings you can specify a human readable label,
 * whether it's sortable, and also provide a formatter function for pre-rendering
 * formatting of the data. The formatter function will be called by the table for
 * each entry and it'll be based the value that is in the column and also the data
 * for the rest of the row.
 *
 * Next you provide the table data which is an array of objects where each object
 * has keys that match the values referenced in the column order.
 *
 * There is a setting to choose if we are descending or ascending on a variable
 * and finally which column name to sort on.
 *
 */
WidgetTable.prototype.setTable = function(
	columnOrder,
	columnSettings,
	data,
	sorting,
	sortingColumn,
	actionMenuEntries,
) {
	// 	this.setTable(['notification', 'received'],
	//
	// 		{
	// 			notification:{label:'Notification', sortable:true},
	// 			received:{label:'Received', sortable:true, formatter:function(a){return new Date(a).toLocaleString();}}
	// 		},
	//
	// 		[
	// 			{notification:'Temperature data above limit!', received:Date.now() - 1000},
	// 			{notification:'Colin wants new styles!', received:Date.now() - 123000},
	// 			{notification:'All the tribbles have escaped!', received:Date.now() - 10000},
	// 			{notification:'All CSS and no play makes Kieron a dull boy...', received:Date.now()},
	// 		], 'descending', 'received');

	var currentWidget = this;

	this._data = data.slice();
	this._columnSettings = columnSettings;
	this._columnOrder = columnOrder;
	this._actionMenuEntries = actionMenuEntries;

	this.sortTable(sorting, sortingColumn);
};

WidgetTable.prototype.setPage = function(page) {
	var numberOfPages = Math.ceil(
		this._tableData.length / this.getOptions().pageSize,
	);

	if (typeof page === 'string') {
		switch (page) {
			case 'first':
				this._page = 0;
				this.renderTable();
				break;

			case 'previous':
				this._page = Math.max(this._page - 1, 0);
				this.renderTable();
				break;

			case 'next':
				this._page = Math.min(this._page + 1, numberOfPages - 1);
				this.renderTable();
				break;

			case 'last':
				this._page = numberOfPages - 1;
				this.renderTable();
				break;
		}
	} else if (typeof page === 'number') {
		if (page < numberOfPages && page >= 0) {
			this._page = page;
			this.renderTable();
		}
	}
};

WidgetTable.prototype.renderTable = function(callback) {
	var currentWidget = this;

	callback = callback || function() {};

	var pages = null;
	var firstIndex = 0;
	var lastIndex = this._tableData.length;

	var firstPage = this._page - 1;
	var lastPage = this._page + 1;

	if (this.getOptions().pageSize) {
		this._page = this._page || 0;

		var numberOfPages = Math.ceil(
			this._tableData.length / this.getOptions().pageSize,
		);
		// 		pages = Array.apply(null, {length: numberOfPages}).map(Number.call, Number);
		pages = [];

		for (var i = 0; i < numberOfPages; i++) {
			pages.push({
				value: i,
				label: (i + 1).toString(),
			});
		}

		firstIndex = this._page * this.getOptions().pageSize;
		lastIndex =
			this._page * this.getOptions().pageSize +
			this.getOptions().pageSize;

		if (this._page === 0) {
			firstPage = 0;
			lastPage = this._page + 2;
		}

		if (this._page === numberOfPages - 1) {
			firstPage = this._page - 2;
			lastPage = this._page;
		}
	}

	this.renderTemplate(
		{
			headerId: this.headerId,
			selectAllId: this.selectAllId,
			labelSelectAll: getLanguageTag(this.constructor, 'selectAll'),
			selectClearId: this.selectClearId,
			labelSelectClear: getLanguageTag(this.constructor, 'selectClear'),
			selectionActionId: this.selectionActionId,
			labelAction: getLanguageTag(this.constructor, 'action'),
			labelNoData:
				this.getOptions().noData ||
				getLanguageTag(this.constructor, 'noData'),
			selectionActionMenuContainerId: this.selectionActionMenuContainerId,
			filterInputContainerId: this.filterInputContainerId,
			filterInputId: this.filterInputId,
			filterInputPlaceHolder:
				this.getOptions().filterInputPlaceHolder ||
				getLanguageTag(this.constructor, 'filterInputPlaceHolder'),
			rowPrefixId: this.generateChildId('row'),
			columns: this._columns,
			tableData: this._tableData,
			page: this._page,
			firstPage: firstPage,
			lastPage: lastPage,
			pages: pages,
			firstIndex: firstIndex,
			lastIndex: lastIndex,
			totalLabel: this.getLanguageTag('total'),
			showTotal: this.getOptions().showTotal,
		},
		WidgetTable.name,
	);

	if (this.getOptions().pageSize) {
		//We now have to attach the page button controllers

		var pageButtons = this.getContainer().find('*[data-tablepage]');

		var currentWidget = this;

		for (var i = 0; i < pageButtons.length; i++) {
			$(pageButtons[i]).click(function(e) {
				var page = parseInt($(this).attr('data-tablepage'));
				if (isNaN(page)) {
					currentWidget.setPage($(this).attr('data-tablepage'));
				} else {
					currentWidget.setPage(page);
				}
			});
		}
	}

	this._filterInput = $(`#${this.filterInputId}`);
	this._filterInputContainer = $(`#${this.filterInputContainerId}`);

	this._selectionAction = $(`#${this.selectionActionId}`);
	this._selectionActionMenuContainer = $(
		`#${this.selectionActionMenuContainerId}`,
	);

	this.removeChildWidget(this.selectionActionMenuContainerId, function(err) {
		this.addChildWidget(
			WidgetMenu,
			this.selectionActionMenuContainerId,
			{},
			function(err, actionMenuWidget) {
				this._actionMenuWidget = actionMenuWidget;

				this._actionMenuWidget.setMenu(this._actionMenuEntries || []);

				this._actionMenuWidget.hide();

				actionMenuWidget.addEventListener('menuEntryClicked', function(
					value,
				) {
					currentWidget._actionMenuWidget.hide();
					currentWidget.event('actionSelected', value);
				});

				this._selectionAction.click(function(e) {
					currentWidget._actionMenuWidget.toggleVisible();

					e.stopPropagation();
				});

				if (!this.getOptions().hideFilterInput) {
					this._filterInputContainer.show();

					this._filterInput.on('input', function() {
						var currentInput = this;

						if (currentWidget._filterTimeout !== null) {
							clearTimeout(currentWidget._filterTimeout);
							currentWidget._filterTimeout = null;
						}

						currentWidget._filterTimeout = setTimeout(function() {
							currentWidget._filterTimeout = null;
							currentWidget.filter($(currentInput).val());
						}, 500);
					});
				} else {
					this._filterInputContainer.hide();
				}

				this._selectAll = $(`#${this.selectAllId}`);

				this._selectAll.click(function() {
					currentWidget.selectAllRows();
				});

				this._selectClear = $(`#${this.selectClearId}`);

				this._selectClear.click(function() {
					currentWidget.deselectAllRows();
				});

				this._checkToShowClearSelection();

				for (var i = 0; i < this._columns.length; i++) {
					this._columnIdToVariable[
						this._columns[i].id
					] = this._columns[i].variable;

					if (
						this._columnSettings[this._columns[i].variable].sortable
					) {
						$(`#${this._columns[i].id}`).click(function() {
							currentWidget.toggleSorting(
								currentWidget._columnIdToVariable[this.id],
							);
						});
					}
				}

				for (var i = 0; i < this._columns.length; i++) {
					var currentColumn = this._columns[i];

					$(`#${currentColumn.id}_sortAscending`).hide();
					$(`#${currentColumn.id}_sortDescending`).hide();

					if (currentColumn.variable === this._currentSorting.key) {
						if (this._currentSorting.type === 'ascending') {
							$(`#${currentColumn.id}_sortAscending`).show();
						} else if (this._currentSorting.type === 'descending') {
							$(`#${currentColumn.id}_sortDescending`).show();
						}
					}
				}

				this._attachCheckboxEvents();
				this._attachRowColumnEventHandlers();

				callback.call(this, false);
			},
		);
	});
};

WidgetTable.prototype.language = deepAssign({}, WidgetBase.prototype.language, {
	'en-US': {
		name: 'Table',
		filterInputPlaceHolder: 'Filter Entries',
		selectAll: 'Select All',
		selectClear: 'Clear Selection',
		action: 'Action',
		search: 'Search Table',
		noData: 'This table has no information.',
		sortAscending: 'Sort Ascending',
		sortDescending: 'Sort Descending',
		never: 'Never',
		total: 'Total',
	},
});

WidgetTable.prototype.$_$ = function(done) {
	//Testing function for WidgetTable

	this.$_SetupWidgetTest(function(err, currentWidget) {
		var actionWasSelected = false;

		currentWidget.setTable(
			['notification', 'received'],

			{
				notification: { label: 'Notification', sortable: true },
				received: {
					label: 'Received',
					sortable: true,
					formatter: function(a) {
						return new Date(a).toLocaleString();
					},
				},
			},

			[
				{
					notification: 'Temperature data above limit!',
					received: Date.now() - 1000,
				},
				{
					notification: 'Colin wants new styles!',
					received: Date.now() - 123000,
				},
				{
					notification: 'All the tribbles have escaped!',
					received: Date.now() - 10000,
				},
				{
					notification:
						'All CSS and no play makes Kieron a dull boy...',
					received: Date.now(),
				},
				{
					notification: 'Colin wants new styles!',
					received: Date.now() - 123000,
				},
				{
					notification: 'All the tribbles have escaped!',
					received: Date.now() - 10000,
				},
				{
					notification:
						'All CSS and no play makes Kieron a dull boy...',
					received: Date.now(),
				},
				{
					notification: 'Colin wants new styles!',
					received: Date.now() - 123000,
				},
				{
					notification: 'All the tribbles have escaped!',
					received: Date.now() - 10000,
				},
				{
					notification:
						'All CSS and no play makes Kieron a dull boy...',
					received: Date.now(),
				},
				{
					notification: 'Colin wants new styles!',
					received: Date.now() - 123000,
				},
				{
					notification: 'All the tribbles have escaped!',
					received: Date.now() - 10000,
				},
				{
					notification:
						'All CSS and no play makes Kieron a dull boy...',
					received: Date.now(),
				},
				{
					notification: 'Colin wants new styles!',
					received: Date.now() - 123000,
				},
				{
					notification: 'All the tribbles have escaped!',
					received: Date.now() - 10000,
				},
				{
					notification:
						'All CSS and no play makes Kieron a dull boy...',
					received: Date.now(),
				},
				{
					notification: 'Colin wants new styles!',
					received: Date.now() - 123000,
				},
				{
					notification: 'All the tribbles have escaped!',
					received: Date.now() - 10000,
				},
				{
					notification:
						'All CSS and no play makes Kieron a dull boy...',
					received: Date.now(),
				},
				{
					notification: 'Colin wants new styles!',
					received: Date.now() - 123000,
				},
				{
					notification: 'All the tribbles have escaped!',
					received: Date.now() - 10000,
				},
				{
					notification:
						'All CSS and no play makes Kieron a dull boy...',
					received: Date.now(),
				},
				{
					notification: 'Colin wants new styles!',
					received: Date.now() - 123000,
				},
				{
					notification: 'All the tribbles have escaped!',
					received: Date.now() - 10000,
				},
				{
					notification:
						'All CSS and no play makes Kieron a dull boy...',
					received: Date.now(),
				},
			],
			'descending',
			'received',
			[{ label: 'Testing', value: 'testing' }],
		);

		currentWidget.addEventListener('actionSelected', function(action) {
			if (action === 'testing') {
				actionWasSelected = true;
			}
		});

		var tableRows = currentWidget.getContainer().find('*[data-tablerow]');

		if (tableRows.length !== 5) {
			done({
				expected: 5,
				got: tableRows.length,
				message: 'Shoud have the correct number of rows in the table',
			});
			return;
		}

		currentWidget._selectAll.click();

		var selectedRowsData = currentWidget.getSelectedRowsData();

		if (selectedRowsData.length !== 4) {
			done({
				expected: 4,
				got: selectedRowsData.length,
				message: 'Shoud have selected all the rows',
			});
			return;
		}

		WidgetBase.prototype.$_$.call(this, done);
	});
};
