function ElementControllerCloudSigfoxStandalone(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudSigfox.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addTrigger('sendDataSet');
	this.addTrigger('dataSent');

	this.addAbility('sendData', [{ name: 'value', validator: null }]);

	this.addProperty(
		'upstreamDataType',
		null,
		'ATMO_DATATYPE_BINARY',
		'select',
		[
			'ATMO_DATATYPE_VOID',
			'ATMO_DATATYPE_CHAR',
			'ATMO_DATATYPE_BOOL',
			'ATMO_DATATYPE_INT',
			'ATMO_DATATYPE_UNSIGNED_INT',
			'ATMO_DATATYPE_FLOAT',
			'ATMO_DATATYPE_DOUBLE',
			'ATMO_DATATYPE_BINARY',
			'ATMO_DATATYPE_STRING',
			'ATMO_DATATYPE_3D_VECTOR_FLOAT',
		],
	);

	this.addProperty(
		'downstreamDataType',
		null,
		'ATMO_DATATYPE_BINARY',
		'select',
		[
			'ATMO_DATATYPE_VOID',
			'ATMO_DATATYPE_CHAR',
			'ATMO_DATATYPE_BOOL',
			'ATMO_DATATYPE_INT',
			'ATMO_DATATYPE_UNSIGNED_INT',
			'ATMO_DATATYPE_FLOAT',
			'ATMO_DATATYPE_DOUBLE',
			'ATMO_DATATYPE_BINARY',
			'ATMO_DATATYPE_STRING',
			'ATMO_DATATYPE_3D_VECTOR_FLOAT',
		],
	);
}

ElementControllerCloudSigfoxStandalone.prototype = Object.create(
	ElementControllerCloudSigfox.prototype,
);
ElementControllerCloudSigfoxStandalone.prototype.constructor = ElementControllerCloudSigfoxStandalone;

ElementControllerCloudSigfoxStandalone.prototype.TYPE = 'CloudSigfoxStandalone';
ElementControllerCloudSigfoxStandalone.prototype.VARIANTS = [
	'cloud',
	'standalone',
];
ElementControllerCloudSigfoxStandalone.prototype.COUPLED_TO = null;

ElementControllerCloudSigfoxStandalone.prototype.language = deepAssign(
	{},
	ElementControllerCloudSigfox.prototype.language,
	{
		'en-US': {
			CloudSigfoxStandalone: 'Sigfox',
		},
	},
);
