function ElementControllerAppUIButton(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppUIBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('labelText', null, 'New Button', 'text', null, null);
	this.addProperty('labelFont', null, '18px Helvetica', 'font', null, null);
	this.addProperty('labelColor', null, 'black', 'color', null, null);

	this.addProperty('pressedData', null, { x: 0, y: 0 }, 'none', null, null);
	this.addProperty('releasedData', null, { x: 0, y: 0 }, 'none', null, null);
	this.addProperty('overData', null, { x: 0, y: 0 }, 'none', null, null);
	this.addProperty('leftData', null, { x: 0, y: 0 }, 'none', null, null);

	this.addAbility('setLabelText', [{ name: 'labelText', validator: null }]);
	this.addAbility('setLabelFont', [{ name: 'labelFont', validator: null }]);
	this.addAbility('setLabelColor', [{ name: 'labelColor', validator: null }]);

	this.addTrigger('pressed');
	this.addTrigger('released');
	this.addTrigger('over');
}

ElementControllerAppUIButton.prototype = Object.create(
	ElementControllerAppUIBase.prototype,
);
ElementControllerAppUIButton.prototype.constructor = ElementControllerAppUIButton;

ElementControllerAppUIButton.prototype.NAME = 'Button';
ElementControllerAppUIButton.prototype.TYPE = 'AppUIButton';
ElementControllerAppUIButton.prototype.HIDDEN = false;
ElementControllerAppUIButton.prototype.DEFAULT_TRIGGER = 'pressed';
ElementControllerAppUIButton.prototype.DEFAULT_ABILITY = 'setLabelText';
ElementControllerAppUIButton.prototype.DEFAULT_ARGUMENTS = [];
ElementControllerAppUIButton.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerAppUIButton.prototype.language = deepAssign(
	{},
	ElementControllerAppUIBase.prototype.language,
	{
		'en-US': {
			AppUIButton: 'Button',
			labelText: 'Label Text',
			labelFont: 'Label Font',
			labelColor: 'Label Color',
			pressedData: 'Pressed Data',
			releasedData: 'Released Data',
			overData: 'Over Data',
			leftData: 'Left Data',
			setLabelText: 'Set Label Text',
			setLabelFont: 'Set Label Font',
			setLabelColor: 'Set Label Color',
			pressed: 'Pressed',
			released: 'Released',
			over: 'Over',
		},
	},
);
