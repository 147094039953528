function ElementControllerEmbeddedNxpRpkBuzzer(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('beep', [], []);
	this.setAbilityCode(
		'beep',
		`
    ATMO_MK64F_Buzzer_On();
    ATMO_PLATFORM_DelayMilliseconds(200);
    ATMO_MK64F_Buzzer_Off();
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('turnOn', [], ['buzzerStateChange']);
	this.setAbilityCode(
		'turnOn',
		`
    ATMO_MK64F_Buzzer_On();
    ATMO_CreateValueBool(out, true);
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('turnOff', [], ['buzzerStateChange']);
	this.setAbilityCode(
		'turnOff',
		`
    ATMO_MK64F_Buzzer_Off();
    ATMO_CreateValueBool(out, false);
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('toggle', [], ['buzzerStateChange']);
	this.setAbilityCode(
		'toggle',
		`
    bool newState = false;
    ATMO_MK64F_Buzzer_Toggle(&newState);
    ATMO_CreateValueBool(out, newState);
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('setState', [], ['buzzerStateChange']);
	this.setAbilityCode(
		'setState',
		`
    bool on = false;
    ATMO_GetBool(in, &on);

    if( on )
    {
        ATMO_MK64F_Buzzer_On();
    }
    else
    {
        ATMO_MK64F_Buzzer_Off();
    }
    ATMO_CreateValueBool(out, on);
    
	return ATMO_Status_Success;
    `,
	);
}

ElementControllerEmbeddedNxpRpkBuzzer.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedNxpRpkBuzzer.prototype.constructor = ElementControllerEmbeddedNxpRpkBuzzer;

ElementControllerEmbeddedNxpRpkBuzzer.prototype.DEFAULT_TRIGGER =
	'buzzerStateChange';
ElementControllerEmbeddedNxpRpkBuzzer.prototype.DEFAULT_ABILITY = 'turnOn';
ElementControllerEmbeddedNxpRpkBuzzer.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerEmbeddedNxpRpkBuzzer.prototype.TYPE = 'EmbeddedNxpRpkBuzzer';
ElementControllerEmbeddedNxpRpkBuzzer.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'rpk',
];
ElementControllerEmbeddedNxpRpkBuzzer.prototype.HIDDEN = false;
ElementControllerEmbeddedNxpRpkBuzzer.prototype.CATEGORY = 'Rapid IOT';
ElementControllerEmbeddedNxpRpkBuzzer.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedNxpRpkBuzzer.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedNxpRpkBuzzer: 'Buzzer',
			EmbeddedBuzzer: 'Buzzer',
		},
	},
);
