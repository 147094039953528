function ElementAppUIImage(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppUIBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);

	var currentElement = this;

	this._setCrocObject(
		new CrocImageSimple(this._crocPanel.getRoot(), properties.image),
	);

	this._crocObject.addEventListener('pointerdown', function(e) {
		currentElement._setProperty('pressedData', e, function() {
			currentElement.trigger('pressed');
		});

		return false;
	});

	this._crocObject.addEventListener('pointerup', function(e) {
		currentElement._setProperty('releasedData', e, function() {
			currentElement.trigger('released');
		});

		return false;
	});

	this._crocObject.addEventListener('pointermove', function(e) {
		currentElement._setProperty('overData', e, function() {
			currentElement.trigger('over');
		});
	});

	this._crocObject.addEventListener('pointerleave', function(e) {
		currentElement._setProperty('leftData', e, function() {
			currentElement.trigger('left');
		});
	});

	this._crocObject.setScaling(properties.scaling || 'target');

	this._onPropertyChange();
}

ElementAppUIImage.prototype = Object.create(ElementAppUIBase.prototype);
ElementAppUIImage.prototype.constructor = ElementAppUIImage;

ElementAppUIImage.prototype._onPropertyChange = function() {
	ElementAppUIBase.prototype._onPropertyChange.call(this);

	this._crocObject.setImage(this._properties.image);
	this._crocObject.setScaling(this._properties.scaling);
};

ElementAppUIImage.prototype.setImage = function(image, callback) {
	this._setProperty('image', image, function(err) {
		this._crocObject.setImage(image);
		callback.call(this, err);
		return;
	});
};

ElementAppUIImage.prototype.setScaling = function(scaling, callback) {
	if (scaling !== 'none' || scaling !== 'stretch' || scaling !== 'target') {
		return;
	}

	this._setProperty('scaling', scaling, function(err) {
		this._crocObject.setScaling(scaling);
		callback.call(this, err);
		return;
	});
};
