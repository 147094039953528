function LocationAppView(id, api, parentWidget, options) {
	WidgetBase.call(this, id, api, parentWidget, options);

	this._containerId = this.generateChildId('appView');
	this._controlsId = this.generateChildId('controls');
	this._appViewWidget = null;
}

LocationAppView.prototype = Object.create(WidgetBase.prototype);
LocationAppView.prototype.constructor = LocationAppView;

LocationAppView.prototype.initialize = function(callback) {
	var currentLocation = this;

	var currentHashCommand = getHashCommand() || {};

	this._thingUuid = currentHashCommand.thingUuid || null;
	this._deviceId = currentHashCommand.deviceId || null;
	
	this.renderTemplate(
		{
			containerId: this._containerId,
			controlsId: this._controlsId,
		},
		LocationAppView.name,
	);
	
	this.addChildWidget(WidgetAppViewControls, this._controlsId, {}, function(
		err,
		appViewControlsWidget,
	) {
		appViewControlsWidget.addEventListener('exit', function() {
			currentLocation._onExit();
		});

		if (this._thingUuid === null) {
			WidgetBase.prototype.initialize.call(this, callback);
			return;
		}

		this.addChildWidget(
			WidgetAppView,
			this._containerId,
			{ fillContainer: true },
			function(err, appViewWidget) {
				this._appViewWidget = appViewWidget;

				//FIXME: The name of the plane can change so we should just fetch all the plane data and then
				// figure out from there which plane we need instead of leaving it up to the server and the name
				// being the same each time
				this.getAPI()
					.getAPIRoute('/user/thing/:thingUuid/view/plane/:planeName')
					.get(this._thingUuid, 'App View', function(err, data) {
						if (err || !data) {
							currentLocation
								.getMainContainer()
								.showPopupErrorMessage(
									getLanguageTag(
										currentLocation.constructor,
										'failedToLoadAppView',
									),
								);
							WidgetBase.prototype.initialize.call(
								currentLocation,
								callback,
							);
							return;
						}

						currentLocation._appViewWidget.appView.import(
							data,
							function() {
								currentLocation._appViewWidget.onResize();

								WidgetBase.prototype.initialize.call(
									currentLocation,
									callback,
								);
								return;
							},
						);
					});
			},
		);
	});
};

LocationAppView.prototype._onExit = function(callback) {
	callback = callback || function() {};

	this.getMainContainer().setLocation(LocationDeviceDashboard, {
		thingUuid: this._thingUuid,
		deviceId: this._deviceId,
		org: getHashCommand().org,
	});

	callback.call(this, false);
	return;
};

LocationAppView.prototype.FULLSCREEN = true;

LocationAppView.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'App View',
			failedToLoadAppView:
				"There was an error while loading this device's app view",
		},
	},
);
