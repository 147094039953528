function PlaneControllerEmbeddedPIC32MZEF(parentController, elementName) {
	PlaneControllerEmbeddedMicrochip.call(this, parentController, elementName);
}

PlaneControllerEmbeddedPIC32MZEF.prototype = Object.create(
	PlaneControllerEmbeddedMicrochip.prototype,
);
PlaneControllerEmbeddedPIC32MZEF.prototype.constructor = PlaneControllerEmbeddedPIC32MZEF;

PlaneControllerEmbeddedPIC32MZEF.prototype.VARIANTS = ['PIC32-MZ-EF'];
PlaneControllerEmbeddedPIC32MZEF.prototype.SUPPORTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'interval',
	'variables',
	'gpio',
	'i2c',
	'spi',
	'fpmath',
	'debugPrint',
	'uptime',
];
PlaneControllerEmbeddedPIC32MZEF.prototype.CLIENT_AGENT_REQUIRED = true;

PlaneControllerEmbeddedPIC32MZEF.prototype.FIRMWARE_DOWNLOAD_FILES = {
	artifactName: 'firmware',
	fileName: 'Atmosphere_Project.hex',
};

PlaneControllerEmbeddedPIC32MZEF.prototype.DRIVERS = {
	adc: [],
	ble: [],
	block: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLOCK_BLOCK1',
		},
	],
	datetime: [],
	embeddedCloud: [],
	filesytem: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_FILESYSTEM_FILESYSTEM1',
		},
	],
	gpio: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_GPIO_GPIO1',
		},
	],
	http: [],
	i2c: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C1',
			primary: true,
		},
		{
			id: 1,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C2',
		},
	],
	interval: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_INTERVAL_INTERVAL1',
		},
	],
	nfc: [],
	pwm: [],
	spi: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_SPI_SPI1',
		},
		{
			id: 1,
			name: 'ATMO_DRIVERINSTANCE_SPI_SPI2',
		},
	],
	uart: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_UART_UART1',
		},
	],
	wifi: [],
};

PlaneControllerEmbeddedPIC32MZEF.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'P_LED1',
		mode: 'ATMO_GPIO_PinMode_Output_PushPull',
	},
	EmbeddedADCPin: {
		instance: 0,
		pin: 'P_MB1_AN',
	},
	EmbeddedPWMPin: {
		pin: 'P_MB1_PWM',
	},
	EmbeddedWiFi7Click: {
		spiDriverInstance: 1,
		resetPin: 'P_MB2_RST',
		enablePin: 'P_MB2_PWM',
		intPin: 'P_MB2_INT',
		csPin: 'P_MB2_SS',
	},
	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'P_MB2_INT',
	},
	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'P_MB2_INT',
	},
	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'P_MB2_INT',
	},
	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'P_MB2_INT',
	},
};

PlaneControllerEmbeddedPIC32MZEF.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			defaultName: 'Curiosity PIC32MZEF',
			ATMO_DRIVERINSTANCE_I2C_I2C1: 'I2C1 (Click 1)',
			ATMO_DRIVERINSTANCE_I2C_I2C2: 'I2C2 (Click 2)',
			ATMO_DRIVERINSTANCE_UART_UART1: 'UART1 (USB)',
			ATMO_DRIVERINSTANCE_UART_UART2: 'UART2 (Click 1)',
			ATMO_DRIVERINSTANCE_SPI_SPI1: 'SPI1 (Click 1)',
			ATMO_DRIVERINSTANCE_SPI_SPI2: 'SPI2 (Click 2)',
		},
	},
);
