function ElementControllerEmbeddedGPIOPin(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	var modeOptions = [
		'ATMO_GPIO_PinMode_Disabled',
		'ATMO_GPIO_PinMode_Input_HighImpedance',
		'ATMO_GPIO_PinMode_Input_PullUp',
		'ATMO_GPIO_PinMode_Input_PullDown',
		'ATMO_GPIO_PinMode_Output_PushPull',
		'ATMO_GPIO_PinMode_Output_OpenDrain',
		'ATMO_GPIO_PinMode_Output_OpenDrainPullUp',
	];

	var interruptModeOptions = [
		'ATMO_GPIO_InterruptTrigger_None',
		'ATMO_GPIO_InterruptTrigger_RisingEdge',
		'ATMO_GPIO_InterruptTrigger_FallingEdge',
		'ATMO_GPIO_InterruptTrigger_BothEdges',
		'ATMO_GPIO_InterruptTrigger_LogicZero',
		'ATMO_GPIO_InterruptTrigger_LogicOne',
	];

	var stateOptions = ['ATMO_GPIO_PinState_Low', 'ATMO_GPIO_PinState_High'];

	this.addProperty('instance', null, 0, 'driverInstance', null, 'gpio');
	this.addProperty('pin', null, 'ATMO_DEFAULT_GPIO', 'text', null, null);
	this.addProperty(
		'mode',
		null,
		'ATMO_GPIO_PinMode_Output_OpenDrainPullUp',
		'select',
		modeOptions,
		null,
	);
	this.addProperty(
		'state',
		null,
		'ATMO_GPIO_PinState_Low',
		'select',
		stateOptions,
		null,
	);
	this.addProperty('interruptEnabled', null, false, 'checkbox', null, null);
	this.addProperty(
		'interruptMode',
		null,
		'ATMO_GPIO_InterruptTrigger_None',
		'select',
		interruptModeOptions,
		null,
	);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
	ATMO_GPIO_Config_t config;
	config.pinMode = ATMO_PROPERTY(undefined, mode);
	config.initialState = ATMO_PROPERTY(undefined, state);
	ATMO_GPIO_SetPinConfiguration(ATMO_PROPERTY(undefined, instance), ATMO_PROPERTY(undefined, pin), &config);

	#if ATMO_PROPERTY(undefined, interruptEnabled) == true
		ATMO_GPIO_RegisterInterruptCallback(
			ATMO_PROPERTY(undefined, instance), 
			ATMO_PROPERTY(undefined, pin), 
			ATMO_PROPERTY(undefined, interruptMode), 
			ATMO_INTERRUPT(undefined, interrupt));
	#endif
	
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setState', [], ['stateSet']);
	this.setAbilityCode(
		'setState',
		`
	ATMO_BOOL_t state;
	ATMO_GetBool(in, &state);
	ATMO_GPIO_SetPinState(ATMO_PROPERTY(undefined, instance), ATMO_PROPERTY(undefined, pin), (ATMO_GPIO_PinState_t)state);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setPinHigh', [], ['stateSet']);
	this.setAbilityCode(
		'setPinHigh',
		`
	ATMO_GPIO_SetPinState(ATMO_PROPERTY(undefined, instance), ATMO_PROPERTY(undefined, pin), ATMO_GPIO_PinState_High);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setPinLow', [], ['stateSet']);
	this.setAbilityCode(
		'setPinLow',
		`
	ATMO_GPIO_SetPinState(ATMO_PROPERTY(undefined, instance), ATMO_PROPERTY(undefined, pin), ATMO_GPIO_PinState_Low);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('read', [], ['read']);
	this.setAbilityCode(
		'read',
		`
	ATMO_CreateValueBool(out, ATMO_GPIO_Read(ATMO_PROPERTY(undefined, instance), ATMO_PROPERTY(EmbeddedGPIOPin, pin)));
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('toggle', [], ['toggled']);
	this.setAbilityCode(
		'toggle',
		`
	ATMO_GPIO_Toggle(ATMO_PROPERTY(undefined, instance), ATMO_PROPERTY(undefined, pin));
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('interrupt', [], ['interrupt'], true);
	this.setAbilityCode(
		'interrupt',
		`
	ATMO_CreateValueCopy(out, in);
	return ATMO_Status_Success;
	`,
	);
}

ElementControllerEmbeddedGPIOPin.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedGPIOPin.prototype.constructor = ElementControllerEmbeddedGPIOPin;

ElementControllerEmbeddedGPIOPin.prototype.NAME = 'GPIOPin';
ElementControllerEmbeddedGPIOPin.prototype.TYPE = 'EmbeddedGPIOPin';
ElementControllerEmbeddedGPIOPin.prototype.HIDDEN = false;
ElementControllerEmbeddedGPIOPin.prototype.DEFAULT_ABILITY = 'toggle';
ElementControllerEmbeddedGPIOPin.prototype.DEFAULT_TRIGGER = 'interrupt';
ElementControllerEmbeddedGPIOPin.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedGPIOPin.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedGPIOPin: 'GPIO Pin',
			pin: 'GPIO Pin',
			mode: 'GPIO Mode',
			state: 'Initial State',
			interruptEnabled: 'Enable Interrupt',
			interruptMode: 'Interrupt Mode',
			ATMO_GPIO_PinMode_Disabled: 'Disabled',
			ATMO_GPIO_PinMode_Input_HighImpedance: 'Input High Impedance',
			ATMO_GPIO_PinMode_Input_PullUp: 'Input Pull Up',
			ATMO_GPIO_PinMode_Input_PullDown: 'Input Pull Down',
			ATMO_GPIO_PinMode_Output_PushPull: 'Ouput Push Pull',
			ATMO_GPIO_PinMode_Output_OpenDrain: 'Ouput Open Drain',
			ATMO_GPIO_PinMode_Output_OpenDrainPullUp:
				'Ouput Open Drain Pull Up',
			ATMO_GPIO_InterruptTrigger_None: 'None',
			ATMO_GPIO_InterruptTrigger_RisingEdge: 'Rising Edge',
			ATMO_GPIO_InterruptTrigger_FallingEdge: 'Falling Edge',
			ATMO_GPIO_InterruptTrigger_BothEdges: 'Both Edges',
			ATMO_GPIO_InterruptTrigger_LogicZero: 'Logic Zero',
			ATMO_GPIO_InterruptTrigger_LogicOne: 'Logic One',
			ATMO_GPIO_PinState_Low: 'Low',
			ATMO_GPIO_PinState_High: 'High',
			setState: 'Set State',
			stateSet: 'State Set',
			read: 'Read',
			toggle: 'Toggle',
			interrupt: 'Interrupt',
			toggled: 'Toggled',
			setPinHigh: 'Set Pin High',
			setPinLow: 'Set Pin Low',
		},
	},
);
