function WidgetDataGlance(id, api, parent, options) {
	var currentWidget = this;
	
	options = options || {};
	
	options.gotoDevice = true;
	
	WidgetDashboardBase.call(this, id, api, parent, options);

	this._usageWidget = null;
	this._showingSettings = false;

	this._updateInterval = null;
	this._updateTimeFromInterval = null;
	
	this._currentValue = null;
	this._currentReportedTime = null;
	this._currentColor = null;
	
	this.usageContainerId = this.generateChildId('usageContainer');
	
	this.config = {
		deviceId: null,
		storageName: null,
		variable: null,
		title: getLanguageTag(this.constructor, 'name'),
		units: '',
		icon: null,
		color: 'gray',
		updateTime: 60000,
		decimalPoints: 2,
	};

	currentWidget.renderTemplate({
		usageContainerId: this.usageContainerId,
	});
}

WidgetDataGlance.prototype = Object.create(WidgetDashboardBase.prototype);
WidgetDataGlance.prototype.constructor = WidgetDataGlance;

WidgetDataGlance.prototype.showSettings = function(callback) {
	callback = callback || function() {};

	var currentWidget = this;

	this.getMainContainer().setModalWidget(
		WidgetSettingsForm,
		{
			fields: [
				{
					name: 'title',
					type: 'text',
					label: getLanguageTag(this.constructor, 'title'),
					value: this.config.title,
				},

				{
					name: 'units',
					type: 'text',
					label: getLanguageTag(this.constructor, 'units'),
					value: this.config.units,
				},

				{
					name: 'decimalPoints',
					type: 'number',
					min: 0,
					max: 5,
					label: getLanguageTag(this.constructor, 'decimalPoints'),
					value: this.config.decimalPoints,
				},

				{
					name: 'icon',
					type: 'select',
					label: getLanguageTag(this.constructor, 'icon'),
					options: [
						{
							value: './Resources/icons/Accelerometer.svg',
							label: getLanguageTag(
								this.constructor,
								'acceleration',
							),
						},
						{
							value: './Resources/icons/Audio.svg',
							label: getLanguageTag(this.constructor, 'audio'),
						},
						{
							value: './Resources/icons/AirQuality.svg',
							label: getLanguageTag(
								this.constructor,
								'airQuality',
							),
						},
						{
							value: './Resources/icons/Battery.svg',
							label: getLanguageTag(this.constructor, 'battery'),
						},
						{
							value: './Resources/icons/Checked.svg',
							label: getLanguageTag(
								this.constructor,
								'checkmark',
							),
						},
						{
							value: './Resources/icons/Cloud.svg',
							label: getLanguageTag(this.constructor, 'cloud'),
						},
						{
							value: './Resources/icons/Current.svg',
							label: getLanguageTag(this.constructor, 'current'),
						},
						{
							value: './Resources/icons/Cog.svg',
							label: getLanguageTag(this.constructor, 'cog'),
						},
						{
							value: './Resources/icons/Electricity.svg',
							label: getLanguageTag(
								this.constructor,
								'electricity',
							),
						},
						{
							value: './Resources/icons/Favorite.svg',
							label: getLanguageTag(this.constructor, 'favorite'),
						},
						{
							value: './Resources/icons/Hot.svg',
							label: getLanguageTag(this.constructor, 'fire'),
						},
						{
							value: './Resources/icons/Humidity.svg',
							label: getLanguageTag(this.constructor, 'humidity'),
						},
						{
							value: './Resources/icons/Information.svg',
							label: getLanguageTag(this.constructor, 'info'),
						},
						{
							value: './Resources/icons/Light.svg',
							label: getLanguageTag(this.constructor, 'light'),
						},
						{
							value: './Resources/icons/Geolocation.svg',
							label: getLanguageTag(
								this.constructor,
								'geolocation',
							),
						},
						{
							value: './Resources/icons/Magnetometer.svg',
							label: getLanguageTag(
								this.constructor,
								'magnetism',
							),
						},
						{
							value: './Resources/icons/Motion.svg',
							label: getLanguageTag(this.constructor, 'motion'),
						},
						{
							value: './Resources/icons/Gyroscope.svg',
							label: getLanguageTag(
								this.constructor,
								'orientation',
							),
						},
						{
							value: './Resources/icons/Pressure.svg',
							label: getLanguageTag(this.constructor, 'pressure'),
						},
						{
							value: './Resources/icons/Temperature.svg',
							label: getLanguageTag(
								this.constructor,
								'temperature',
							),
						},
						{
							value: './Resources/icons/Error.svg',
							label: getLanguageTag(this.constructor, 'warning'),
						},
						{
							value: './Resources/icons/Wind.svg',
							label: getLanguageTag(this.constructor, 'wind'),
						},
					],
					value: this.config.icon,
				},

				{
					name: 'color',
					type: 'colorSwatch',
					label: getLanguageTag(this.constructor, 'color'),
					value: this.config.color,
					options: {},
				},

				{
					name: 'updateTime',
					type: 'select',
					label: this.getLanguageTag('updateTime'),
					options: [
						{ value: 60000, label: this.getLanguageTag('1Minute') },
						{
							value: 300000,
							label: this.getLanguageTag('5Minutes'),
						},
						{
							value: 600000,
							label: this.getLanguageTag('10Minutes'),
						},
						{
							value: 900000,
							label: this.getLanguageTag('15Minutes'),
						},
						{
							value: 1800000,
							label: this.getLanguageTag('30Minutes'),
						},
						{ value: 3600000, label: this.getLanguageTag('1Hour') },
						{
							value: 7200000,
							label: this.getLanguageTag('2Hours'),
						},
						{
							value: 14400000,
							label: this.getLanguageTag('4Hours'),
						},
						{
							value: 21600000,
							label: this.getLanguageTag('6Hours'),
						},
						{
							value: 28800000,
							label: this.getLanguageTag('8Hours'),
						},
						{
							value: 43200000,
							label: this.getLanguageTag('12Hours'),
						},
					],
					value: this.config.updateTime,
				},

				{
					name: 'variable',
					type: 'deviceVariableSelect',
					label: getLanguageTag(this.constructor, 'variable'),
					value: {
						deviceId: this.config.deviceId,
						storageName: this.config.storageName,
						variables: { value: this.config.variable },
					},
					options: {
						variables: [
							{
								name: 'value',
								label: this.getLanguageTag('value'),
							},
						],
					},
				},
			],
		},
		function(err, settingsWidget) {
			settingsWidget.setTitle(
				getLanguageTag(
					currentWidget.constructor,
					'configureDataGlance',
				),
			);

			settingsWidget.addEventListener('dismissed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;
			});

			settingsWidget.addEventListener('confirmed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;

				values = this.getValues();
				values.variable = values.variable || {};

				var config = {
					deviceId: (values.variable.deviceData || {}).id || null,
					storageName: values.variable.storageName || null,
					variable: (values.variable.variables || {}).value || null,
					title: values.title || '',
					units: values.units || '',
					icon: values.icon || null,
					color: values.color || 'gray',
					updateTime: values.updateTime || 60000,
					decimalPoints: currentWidget.decimalPlacesFallback(
						values.decimalPoints,
					),
				};

				currentWidget.setConfiguration(config, function() {
					this.event('configurationSet', {
						widget: this,
						configuration: this.config,
					});
				});
			});

			currentWidget._showingSettings = true;
			this.showModal();

			callback.call(this, false);
			return;
		},
	);
};

WidgetDataGlance.prototype.decimalPlacesFallback = function(value) {
	if (value === 0) {
		return 0;
	}
	if (!value) {
		return 2;
	}

	return value;
};

WidgetDataGlance.prototype.setConfiguration = function(config, callback) {
	var currentWidget = this;

	callback = callback || function() {};

	this.config = {
		deviceId: config.deviceId || null,
		storageName: config.storageName || null,
		variable: config.variable || null,
		title: config.title || getLanguageTag(this.constructor, 'name'),
		units: config.units || '',
		icon: config.icon || null,
		color: config.color || 'gray',
		updateTime: config.updateTime || 60000,
		decimalPoints: currentWidget.decimalPlacesFallback(
			config.decimalPoints,
		),
	};

	if (this._updateInterval) {
		clearInterval(this._updateInterval);
	}

	this.update(function(err) {
		if (currentWidget.config.updateTime < 60000) {
			currentWidget.config.updateTime = 60000;
		}

		this._updateInterval = setInterval(function() {
			if (isActive()) {
				currentWidget.update(function() {});
			}
		}, this.config.updateTime || 60000);

		callback.call(this, err);
	});
};

WidgetDataGlance.prototype.initialize = function(callback) {
	var currentWidget = this;
	
	this.addChildWidget(WidgetUsage, this.usageContainerId, {}, function(
		err,
		usageWidget,
	) {
		this._usageWidget = usageWidget;

		this.update(function(err) {
			if (currentWidget.config.updateTime < 60000) {
				currentWidget.config.updateTime = 60000;
			}

			this._updateInterval = setInterval(function() {
				if (isActive()) {
					currentWidget.update(function() {});
				}
			}, this.config.updateTime || 60000);
		});

		WidgetDashboardBase.prototype.initialize.call(this, callback);
	});
};

WidgetDataGlance.prototype.remove = function(callback) {
	if (this._updateInterval) {
		clearInterval(this._updateInterval);
	}

	if(this._updateTimeFromInterval) {
		clearInterval(this._updateTimeFromInterval);
	}
	
	if (this._showingSettings) {
		this.getMainContainer().hideModal();
	}

	WidgetDashboardBase.prototype.remove.call(this, callback);
};

WidgetDataGlance.prototype.updateAsUnset = function() {
	this._usageWidget.setUsage(
		getLanguageTag(this.constructor, 'unset'),
		null,
		'gray',
		'./Resources/icons/Cog.svg',
		getLanguageTag(this.constructor, 'useMenuSettings'),
		null,
		null,
	);
};

WidgetDataGlance.prototype.updateAsNoData = function () {
	this._usageWidget.setUsage(
		getLanguageTag(this.constructor, 'none'),
		null,
		'gray',
		'./Resources/icons/Unknown.svg',
		getLanguageTag(this.constructor, 'noData'),
		null,
		null,
	);
}

WidgetDataGlance.prototype._statusToColor = {
	none: 'gray',
	info: 'blue',
	missing: 'gray',
	good: 'green',
	warning: 'yellow',
	critical: 'red',
};

WidgetDataGlance.prototype.update = function(callback) {
	var currentWidget = this;

	if (this.config.deviceId === null) {
		this.updateAsUnset();
		callback.call(this, false);
		return;
	}

	// While we are updating the current data
	// turn off the update from interval so we
	// don't end up with the two updates fighting
	// each other
	if(this._updateTimeFromInterval) {
		clearInterval(this._updateTimeFromInterval);
		this._updateTimeFromInterval = null;
	}
	
	this.setTitle(this.config.title);

	const api = currentWidget.getApiV2().apis;

	// Used when parsing the device status
	let deviceStatusText = 'none';

	api.devices
		.getDevice({ id: currentWidget.config.deviceId })
		.then((response) => {
			deviceStatusText = response.statusText;

			return api.data.getLastDeviceData({
				id: currentWidget.config.deviceId,
				elementName: currentWidget.config.storageName,
			});
		})
		.then((response) => {
			const { values } = response;
			if (values === null) {
				currentWidget.updateAsNoData();
				callback.call(currentWidget, false);
				return;
			}

			// UI expects the _timestamp field, what should be used as the _timestamp?
			values._timestamp = response.updatedAt;
			dataTypes = DataTypes.dataTypesFrom(JSON.stringify(values));

			if (
				!values ||
				values[currentWidget.config.variable] === undefined ||
				values[currentWidget.config.variable] === null
			) {
				values[currentWidget.config.variable] = getLanguageTag(
					currentWidget.constructor,
					'none',
				);
			}

			var currentColor = currentWidget.config.color;

			if (currentColor === 'unset') {
				var status = deviceStatusText || 'none';
				status = status.toLowerCase();

				currentColor = currentWidget._statusToColor[status];
			}

			var glanceValue = values[currentWidget.config.variable];

			if (typeof glanceValue === 'boolean') {
				if (glanceValue) {
					glanceValue = currentWidget.getLanguageTag('true');
				} else {
					glanceValue = currentWidget.getLanguageTag('false');
				}
			} else if (typeof glanceValue === 'number') {
				let format = '0.';
				for (let i = 0; i < currentWidget.config.decimalPoints; i++) {
					format += '0';
				}

				format += 'a';

				glanceValue = numeral(glanceValue).format(format);
			}

			const currentTime = new Date(Date.now());
			let reportedTime = new Date(values._timestamp);

			if (reportedTime > currentTime) {
				reportedTime = currentTime;
			}

			currentWidget._currentValue = glanceValue;
			currentWidget._currentReportedTime = reportedTime;
			currentWidget._currentColor = currentColor;
			
			currentWidget._usageWidget.setUsage(
				currentWidget._currentValue,
				currentWidget.config.units,
				currentWidget._currentColor,
				currentWidget.config.icon || './Resources/icons/Unknown.svg',
				currentWidget.config.variable,
				moment(currentWidget._currentReportedTime).fromNow(),
				null,
			);

			
			// We need to start up the update time from interval again
			// will run every 5 seconds to just update the visuals of
			// the time from
			currentWidget._updateTimeFromInterval = setInterval(function() {
				currentWidget.updateTimeFrom();
			}, 5000);
			
			callback.call(currentWidget, false);
		})
		.catch((error) => {
			console.warn(
				`[${currentWidget.constructor.name}] error during update()!`,
			);
			console.error(error);
			callback.call(currentWidget, error);
			return;
		});
};

// This function will take the last gathered values
// and then update the usage widget with the new from
// time. This method should not make any API requests
// it is only to update the time from in the widget
WidgetDataGlance.prototype.updateTimeFrom = function() {
	
	// We aren't acutally set yet, so ignore
	if (!this.config.deviceId) {
		return;
	}
	
	this._usageWidget.setUsage(
		this._currentValue,
		this.config.units,
		this._currentColor,
		this.config.icon || './Resources/icons/Unknown.svg',
		this.config.variable,
		moment(this._currentReportedTime).fromNow(),
		null,
	);
};

WidgetDataGlance.prototype.getConfiguration = function() {
	return this.config;
};

WidgetDataGlance.prototype.PACKERY_SIZE = 'WidgetDashboard_Container_MxS';

WidgetDataGlance.prototype.ICONS_PATH = './Widgets/WidgetDataGlance/Resources/';

WidgetDataGlance.prototype.language = deepAssign(
	{},
	WidgetDashboardBase.prototype.language,
	{
		'en-US': {
			name: 'Data Glance',
			configureDataGlance: 'Configure Data Glance',
			updateTime: 'Update Interval',
			'1Minute': '1 Minute',
			'5Minutes': '5 Minutes',
			'10Minutes': '10 Minutes',
			'15Minutes': '15 Minutes',
			'30Minutes': '30 Minutes',
			'1Hour': '1 Hour',
			'2Hours': '2 Hours',
			'4Hours': '4 Hours',
			'6Hours': '6 Hours',
			'8Hours': '8 Hours',
			'12Hours': '12 Hours',
			none: 'None',
			unset: 'Unset',
			useMenuSettings: 'Use the menu and settings to configure',
			title: 'Title',
			variable: 'Data Source',
			units: 'Unit',
			icon: 'Icon',
			color: 'Color',
			decimalPoints: 'Number of Decimal Places',
			red: 'Red',
			orange: 'Orange',
			yellow: 'Yellow',
			green: 'Green',
			blue: 'Blue',
			purple: 'Purple',
			pink: 'Pink',
			data: 'Data',
			acceleration: 'Acceleration',
			audio: 'Audio',
			airQuality: 'Air Quality',
			battery: 'Battery',
			checkmark: 'Checkmark',
			cloud: 'Cloud',
			current: 'Current',
			cog: 'Cog',
			electricity: 'Electricity',
			favorite: 'Favorite',
			fire: 'Fire',
			gas: 'Gas',
			humidity: 'Humidity',
			info: 'Info',
			light: 'Light',
			geolocation: 'Geolocation',
			magnetism: 'Magnetism',
			motion: 'Motion',
			orientation: 'Orientation',
			pressure: 'Pressure',
			temperature: 'Temperature',
			warning: 'Warning',
			wind: 'Wind',
			value: 'Select Value',
			true: 'True',
			false: 'False',
			noData: 'No Data Available',
		},

		de: {
			name: 'Daten-Blick',
		},

		es: {
			name: 'Vistazo de Datos',
		},

		ar: {
			name: 'البيانات وهلة',
		},

		cn: {
			name: '数据简图',
		},
	},
);

WidgetDataGlance.prototype.$_$ = function(done) {
	done(false);
};
