function ElementAppWebIO(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);
}

ElementAppWebIO.prototype = Object.create(ElementAppBase.prototype);
ElementAppWebIO.prototype.constructor = ElementAppWebIO;

ElementAppWebIO.prototype.setPath = function(path, callback) {
	/*
	 * This allows you to programically set
	 * the path that the webIO will use when
	 * making a web request.
	 */

	if (!path || !path.toString) {
		callback.call(this, { type: 'invalidPath' });
		return;
	}

	this._setProperty('path', path.toString(), callback);
};

ElementAppWebIO.prototype.get = function(callback) {
	var currentElement = this;

	this._getProperty('path', function(err, path) {
		this._getProperty('dataType', function(err, dataType) {
			$.ajax({
				type: 'GET',
				url: path,
				crossDomain: true,
				dataType: dataType,
				success: function(responseData, textStatus, jqXHR) {
					currentElement._setProperty('value', responseData, function(
						err,
					) {
						currentElement.trigger('getResponded', callback);
					});
				},
				error: function(responseData, textStatus, errorThrown) {
					if (responseData.status === 400) {
						callback.call(
							currentElement,
							responseData.responseJSON,
							null,
						);
						return;
					}

					callback.call(
						currentElement,
						responseData.errorThrown,
						null,
					);
				},
			});
		});
	});
};

ElementAppWebIO.prototype.post = function(data, callback) {
	var currentElement = this;

	this._getProperty('path', function(err, path) {
		this._getProperty('dataType', function(err, dataType) {
			$.ajax({
				type: 'POST',
				url: path,
				data: JSON.stringify(data),
				crossDomain: true,
				dataType: dataType,
				contentType: 'application/json',
				success: function(responseData, textStatus, jqXHR) {
					currentElement._setProperty('value', responseData, function(
						err,
					) {
						currentElement.trigger('postResponded', callback);
					});
				},
				error: function(responseData, textStatus, errorThrown) {
					if (responseData.status === 400) {
						callback.call(
							currentElement,
							responseData.responseJSON,
							null,
						);
						return;
					}

					callback.call(
						currentElement,
						responseData.errorThrown,
						null,
					);
				},
			});
		});
	});
};
