function LocationUsersAdministration(id, api, parentWidget, options) {
	LocationBase.call(this, id, api, parentWidget, options);

	this._userTableWidget = null;
}

LocationUsersAdministration.prototype = Object.create(LocationBase.prototype);
LocationUsersAdministration.prototype.constructor = LocationUsersAdministration;

LocationUsersAdministration.prototype.hasPermission = function(callback) {
	
	const currentUser = _mainContainer.getCurrentUser();

	if(!currentUser) {
		callback.call(this, false, false);
		return;
	}
	
	if(!currentUser.ability.can('see', 'UsersAdministration')) {
		callback.call(this, false, false);
		return;
	}
	
	LocationBase.prototype.hasPermission.call(this, callback);
};

LocationUsersAdministration.prototype.initialize = function(callback) {
	var currentLocation = this;

	currentLocation.setTabWidgets(
		null,
		[
			{
				childId: 'usersTable',
				constructor: WidgetUsersTable,
				options: {},
			},
		],
		function(err) {
			this._userTableWidget = this.getChildWidget('usersTable');

			this._userTableWidget.addEventListener('userSelected', function(
				event,
			) {
				// The user will only be able to see users that they have permission to read
				// So no need to check permisssion here
				currentLocation
					.getMainContainer()
					.setLocation(LocationUserAdministration, {
						id: event.id,
						username: event.username, // Username will not be needed once the user profile widget is fixed
						org: event.organizationId,
					});

				return;
			});
			callback.call(currentLocation, err);
		},
	);
};

LocationUsersAdministration.prototype.language = deepAssign(
	{},
	LocationBase.prototype.language,
	{
		'en-US': {
			name: 'Users',
		},
	},
);
