function ElementAppBLEBatteryService(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppBLECharacteristicBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);
}

ElementAppBLEBatteryService.prototype = Object.create(
	ElementAppBLECharacteristicBase.prototype,
);
ElementAppBLEBatteryService.prototype.constructor = ElementAppBLEBatteryService;

ElementAppBLEBatteryService.prototype.readBatteryLevel = function(callback) {
	var currentElement = this;

	this._pluginBLECentral.read(null, '180f', '2a19', function(err, data) {
		if (err) {
			callback.call(currentElement, err);
			return currentElement._triggerError(err, function() {});
		}

		currentElement._setProperty('value', data[0], function(err, data) {
			if (err) {
				callback.call(currentElement, err);
				return currentElement._triggerError(err, function() {});
			}

			currentElement.trigger('batteryLevelRead', callback);
			return;
		});
	});
};
