function LocationDeviceDashboard(id, api, parentWidget, options) {
	options = options || {};

	LocationBase.call(this, id, api, parentWidget, options);
}

LocationDeviceDashboard.prototype = Object.create(LocationBase.prototype);
LocationDeviceDashboard.prototype.constructor = LocationDeviceDashboard;

LocationDeviceDashboard.prototype.initialize = function(callback) {
	const currentLocation = this;

	const currentCommandHash = getHashCommand() || {};
	const deviceId = currentCommandHash.deviceId || null;

	if (!deviceId) {
		this.getMainContainer().showPopupErrorMessage(
			getLanguageTag(this.constructor, 'invalidDevice'),
		);
		callback.call(this, {
			type: 'invalidDevice',
			redirect: LocationDeviceManager,
		});
		return;
	}

	const api = this.getMainContainer()._apiv2.apis;

	api.devices
		.getDevice({ id: deviceId })
		.then((deviceData) => {
			currentLocation._currentDevice = new Device(api, deviceData);

			currentCommandHash.thingUuid = this._currentDevice.data.uuid;
			
			currentLocation.getMainContainer().forceHashCommand(currentCommandHash, true);
			
			currentLocation.loadConfiguration(function(err) {
				currentLocation.update(function(err) {
					LocationBase.prototype.initialize.call(
						currentLocation,
						callback,
					);
				});
			});
		})
		.catch((err) => {
			console.error('Error getting device information ', err);
			currentLocation
				.getMainContainer()
				.showPopupErrorMessage(
					getLanguageTag(this.constructor, 'invalidDevice'),
				);
			callback.call(currentLocation, {
				type: 'invalidDevice',
				redirect: LocationDeviceManager,
			});
		});
};

LocationDeviceDashboard.prototype.update = function(callback) {
	callback = callback || function() {};

	const currentLocation = this;
	const api = currentLocation.getMainContainer()._apiv2.apis;

	api.devices
		.getDevice({ id: this._currentDevice.data.id })
		.then((deviceData) => {
			currentLocation.setTitle(deviceData.name);
			callback.call(currentLocation, false);
		})
		.catch((err) => {
			console.error('Failed to load device data: ', err);
			currentLocation
				.getMainContainer()
				.showPopupErrorMessage(
					getLanguageTag(
						currentLocation.constructor,
						'failedToFindDevice',
					),
				);
			callback.call(currentLocation, {
				type: 'invalidDevice',
				redirect: LocationDeviceManager,
			});
		});
};

LocationDeviceDashboard.prototype._onExit = function(callback) {
	callback = callback || function() {};

	const hashCommand = getHashCommand();

	this.getMainContainer().setLocation(LocationDeviceManager, {
		org: hashCommand.org,
	});

	callback.call(this, false);
	return;
};

LocationDeviceDashboard.prototype._onAppView = function(callback) {
	callback = callback || function() {};

	this.getMainContainer().setLocation(LocationAppView, {
		thingUuid: this._currentDevice.data.uuid || null,
		deviceId: this._currentDevice.data.id || null,
		org: getHashCommand().org,
	});
	callback.call(this, false);
	return;
};

LocationDeviceDashboard.prototype.setDashboardConfiguration = function(
	childId,
	configuration,
	callback,
) {
	const currentLocation = this;

	const dashboardWidget = this.getChildWidget(childId);

	if (!dashboardWidget) {
		console.trace();
		callback.call(this, { type: 'invalidChildIdForDashboard' });
		return;
	}

	dashboardWidget.setConfiguration(configuration, function(err) {
		dashboardWidget.addEventListener('configurationChanged', function(
			data,
		) {
			currentLocation.saveConfiguration();
		});

		dashboardWidget.addEventListener('configurationReset', function(data) {
			currentLocation.setDefaultConfiguration();
		});

		dashboardWidget.addEventListener('eraseDashboard', function(data) {
			currentLocation.onEraseDashboard(data);
		});

		callback.call(currentLocation, err);
	});
};

LocationDeviceDashboard.prototype.setConfiguration = function(
	config,
	callback,
) {
	const currentLocation = this;

	if (!config.dashboards) {
		config.dashboards = [
			{
				type: 'WidgetDashboard',
				name: getLanguageTag(this.constructor, 'name'),
				options: {},
				configuration: {
					widgets: [],
				},
			},
		];
	}

	this._config = config;

	const tabsConfig = [];

	for (let i = 0; i < config.dashboards.length; i++) {
		tabsConfig.push({
			childId: config.dashboards[i].name,
			label: config.dashboards[i].name,
			constructor: WidgetDashboard,
			options: {
				readOnly: currentLocation
					.getCurrentUser()
					.ability.cannot('update', 'DeviceDashboard'),
			},
		});
	}

	tabsConfig.push({
		childId: 'info',
		label: getLanguageTag(this.constructor, 'info'),
		constructor: WidgetDeviceInfo,
		options: {},
	});

	tabsConfig.push({
		childId: 'storage',
		label: getLanguageTag(this.constructor, 'storage'),
		constructor: WidgetDeviceStorage,
		options: {},
	});

	tabsConfig.push({
		childId: 'geolocation',
		label: getLanguageTag(this.constructor, 'geolocation'),
		constructor: WidgetDeviceGeolocation,
		options: {},
	});

	tabsConfig.push({
		childId: 'settings',
		label: getLanguageTag(this.constructor, 'settings'),
		constructor: WidgetDeviceSettings,
		options: {},
	});

	tabsConfig.push({
		childId: 'logs',
		label: getLanguageTag(this.constructor, 'logs'),
		constructor: WidgetDeviceLogs,
		options: {},
	});

	this.setTabWidgets(null, tabsConfig, function(err) {
		const settingsWidget = currentLocation.getChildWidget('settings');
		if (settingsWidget) {
			settingsWidget.addEventListener('settingsChanged', () => {
				// If the settings get changed, update the settings
				// history table.
				currentLocation.getChildWidget('logs').update();
			});
		}

		this.getChildWidget('info').addEventListener('changed', function() {
			currentLocation.update();
		});

		this.addHeaderWidget(WidgetDeviceDashboardControls, {}, function(
			err,
			controlsWidget,
		) {
			controlsWidget.addEventListener('exit', function() {
				currentLocation._onExit();
			});

			controlsWidget.addEventListener('appView', function() {
				currentLocation._onAppView();
			});

			controlsWidget.addEventListener('removeDevice', function() {
				currentLocation.getMainContainer().showConfirm(
					{
						message: getLanguageTag(
							currentLocation.constructor,
							'doYouWantToDeleteThisDevice',
						),
						title: getLanguageTag(
							currentLocation.constructor,
							'removeDeviceTitle',
						),
						confirmLabel: getLanguageTag(
							currentLocation.constructor,
							'removeDeviceButton',
						),
						confirmClasses: ['btn-danger'],
					},

					function() {
						//Confirmed

						currentLocation._currentDevice
							.delete()
							.then(() => {
								currentLocation
									.getMainContainer()
									.setLocation(LocationDeviceManager, {
										org: getHashCommand().org,
									});
							})
							.catch((err) => {
								console.error('Error deleting device: ', err);
								return;
							});
					},
				);
			});

			const dashboardEntries = config.dashboards.slice();

			function _setConfigurationHelper() {
				if (dashboardEntries.length <= 0) {
					callback.call(currentLocation, false);
					return;
				}

				const currentDashboardEntry = dashboardEntries.shift();

				currentLocation.setDashboardConfiguration(
					currentDashboardEntry.name,
					currentDashboardEntry.configuration,
					function(err) {
						_setConfigurationHelper();
					},
				);
			}

			_setConfigurationHelper();
		});
	});
};

LocationDeviceDashboard.prototype.getConfiguration = function() {
	const config = {
		dashboards: [],
	};

	const childrenIds = this.getChildrenIds();

	const usedDashboardNames = [];

	for (let i = 0; i < childrenIds.length; i++) {
		const currentWidget = this.getChildWidget(childrenIds[i]);

		if (currentWidget.getConfiguration) {
			// FIXME: We eventually want to allow users to make their own dashboards
			// 	setting the name of the dashboard to what ever they want.
			if (
				usedDashboardNames.indexOf(
					getLanguageTag(this.constructor, 'name'),
				) >= 0
			) {
				continue;
			}

			usedDashboardNames.push(getLanguageTag(this.constructor, 'name'));

			config.dashboards.push({
				type: currentWidget.constructor.name,
				name: getLanguageTag(this.constructor, 'name'),
				options: {},
				configuration: currentWidget.getConfiguration(),
			});
		}
	}

	return config;
};

LocationDeviceDashboard.prototype.getConfigurationSaveName = function() {
	return this.constructor.name;
};

LocationDeviceDashboard.prototype.convertDefaultConfig = function(config) {
	const currentLocation = this;
	if (!config) {
		return config;
	} else if (config.constructor === Array) {
		for (let i = 0; i < config.length; i++) {
			config[i] = this.convertDefaultConfig(config[i]);
		}
	} else if (typeof config === 'object') {
		for (const k in config) {
			if (k === 'thingUuid' && typeof config[k] === 'string') {
				config['deviceId'] = currentLocation._currentDevice.data.id;
				delete config[k];
			} else if (k === 'deviceUuid' && typeof config[k] === 'string') {
				config['deviceId'] = currentLocation._currentDevice.data.id;
				delete config[k];
			} else if (k === 'deviceId' && typeof config[k] === 'string') {
				config[k] = currentLocation._currentDevice.data.id;
			} else {
				config[k] = this.convertDefaultConfig(config[k]);
			}
		}
	}

	return config;
};

LocationDeviceDashboard.prototype.getDefaultConfig = function(callback) {
	const currentLocation = this;

	this.getAPI()
		.getAPIRoute('/user/thing/:thingUuid/view/manifestConfiguration/:tag')
		.post(
			currentLocation._currentDevice.data.uuid,
			this.constructor.name,
			function(err, config) {
				callback.call(
					currentLocation,
					err,
					currentLocation.convertDefaultConfig(config),
				);
			},
		);
};

LocationDeviceDashboard.prototype.setDefaultConfiguration = function(callback) {
	const defaultConfig = {
		dashboards: [
			{
				type: 'WidgetDashboard',
				name: getLanguageTag(this.constructor, 'name'),
				options: {},
				configuration: {
					widgets: [],
				},
			},
		],
	};

	this.getDefaultConfig(function(err, config) {
		config = config || defaultConfig;

		this.setConfiguration(config, function(err) {
			this.saveConfiguration(callback);
		});
	});
};

LocationDeviceDashboard.prototype.loadConfiguration = function(callback) {
	const currentLocation = this;

	const defaultConfig = {
		dashboards: [
			{
				type: 'WidgetDashboard',
				name: getLanguageTag(currentLocation.constructor, 'name'),
				options: {},
				configuration: {
					widgets: [],
				},
			},
		],
	};

	this.getAPI()
		.getAPIRoute('/user/thing/:thingUuid/view/configuration/:tag')
		.get(
			currentLocation._currentDevice.data.uuid,
			this.getConfigurationSaveName(),
			function(err, config) {
				if (err) {
					currentLocation
						.getMainContainer()
						.showPopupErrorMessage(
							getLanguageTag(
								currentLocation.constructor,
								'failedToLoadDashboardConfiguration',
							),
						);
					callback.call(currentLocation, err);
					return;
				}

				//If there is no config, we can check to see if the
				//cloud plane data for the device has declared a default
				//configuration for the new device.
				if (!config || (config.dashboards || []).length <= 0) {
					currentLocation.setDefaultConfiguration(callback);
				} else {
					currentLocation.setConfiguration(config, callback);
					return;
				}
			},
		);
};

LocationDeviceDashboard.prototype.saveConfiguration = function(callback) {
	callback = callback || function() {};

	const currentLocation = this;
	const currentConfig = this.getConfiguration();

	if (((currentConfig || {}).dashboards || []).length <= 0) {
		callback.call(currentLocation, { type: 'invalidConfiguration' });
		return;
	}

	this.getAPI()
		.getAPIRoute('/user/thing/:thingUuid/admin/configuration/:tag/:data')
		.post(
			currentLocation._currentDevice.data.uuid,
			this.getConfigurationSaveName(),
			currentConfig,
			function(err, config) {
				if (err && err.status !== 403) {
					currentLocation
						.getMainContainer()
						.showPopupErrorMessage(
							getLanguageTag(
								currentLocation.constructor,
								'failedToSaveDashboardConfiguration',
							),
						);
				}

				callback.call(currentLocation, err);
				return;
			},
		);
};

LocationDeviceDashboard.prototype.onEraseDashboard = function(data, callback) {
	const currentLocation = this;

	callback = callback || function() {};

	if (!(data || {}).dashboard) {
		callback.call(currentLocation, { type: 'noDashboardProvided' });
		return;
	}

	data.dashboard.removeAllWidgets(function(err) {
		if (err) {
			callback.call(currentLocation, err);
			return;
		}

		callback.call(currentLocation, false);
		return;
	});
};

LocationDeviceDashboard.prototype.NAVBAR_LOCATION = LocationDeviceManager;

LocationDeviceDashboard.prototype.language = deepAssign(
	{},
	LocationBase.prototype.language,
	{
		'en-US': {
			name: 'Dashboard',
			failedToFindDevice: 'The device requested does not exist',
			failedToLoadDashboardConfiguration:
				'Failed to load dashboard configurations',
			failedToSaveDashboardConfiguration:
				'Failed to save dashboard configurations',
			invalidDevice: 'An invalid device UUID was selected',
			geolocation: 'Geolocation',
			info: 'Info',
			storage: 'Storage',
			settings: 'Settings',
			logs: 'Logs',
			removeDeviceTitle: 'Delete Device',
			doYouWantToDeleteThisDevice:
				'Are you sure you want to delete this device?',
			removeDeviceButton: 'Delete',
		},
	},
);
