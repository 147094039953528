function ElementControllerEmbeddedWebThingProperty(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('title', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('title', 'string');
	this.addProperty(
		'capability',
		null,
		'WEBTHING_TYPE_None',
		'select',
		[
			'WEBTHING_TYPE_None',
			'WEBTHING_PROPERTY_TYPE_AlarmProperty',
			'WEBTHING_PROPERTY_TYPE_BooleanProperty',
			'WEBTHING_PROPERTY_TYPE_BrightnessProperty',
			'WEBTHING_PROPERTY_TYPE_ColorModeProperty',
			'WEBTHING_PROPERTY_TYPE_ColorProperty',
			'WEBTHING_PROPERTY_TYPE_ColorTemperatureProperty',
			'WEBTHING_PROPERTY_TYPE_CurrentProperty',
			'WEBTHING_PROPERTY_TYPE_FrequencyProperty',
			'WEBTHING_PROPERTY_TYPE_HeatingCoolingProperty',
			'WEBTHING_PROPERTY_TYPE_InstantaneousPowerProperty',
			'WEBTHING_PROPERTY_TYPE_LeakProperty',
			'WEBTHING_PROPERTY_TYPE_LevelProperty',
			'WEBTHING_PROPERTY_TYPE_LockedProperty',
			'WEBTHING_PROPERTY_TYPE_MotionProperty',
			'WEBTHING_PROPERTY_TYPE_OnOffProperty',
			'WEBTHING_PROPERTY_TYPE_OpenProperty',
			'WEBTHING_PROPERTY_TYPE_PushedProperty',
			'WEBTHING_PROPERTY_TYPE_TargetTemperatureProperty',
			'WEBTHING_PROPERTY_TYPE_TemperatureProperty',
			'WEBTHING_PROPERTY_TYPE_ThermostatModeProperty',
			'WEBTHING_PROPERTY_TYPE_VoltageProperty',
		],
		null,
	);
	this.addProperty('description', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('description', 'string');
	this.addProperty(
		'type',
		null,
		'ATMO_DATATYPE_STRING',
		'select',
		this.DATA_TYPES,
		null,
	);
	this.addProperty('initialValue', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('initialValue', 'string');
	this.addProperty('minimum', null, 0, 'number', null, null);
	this.addProperty('maximum', null, 100, 'number', null, null);
	this.addProperty('unit', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('unit', 'string');
	this.addProperty('readOnly', null, false, 'checkbox', null, null);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
	WebThing_Property_t prop;
	prop.id = ATMO_ELEMENT_NAME_SLUG(undefined);
	prop.capabilityType = ATMO_PROPERTY(undefined, capability);
	prop.title = strlen(ATMO_PROPERTY(undefined, title)) == 0 ? ATMO_ELEMENT_NAME(undefined): ATMO_PROPERTY(undefined, title);
	prop.description = ATMO_PROPERTY(undefined, description);
	prop.minimum = ATMO_PROPERTY(undefined, minimum);
	prop.maximum = ATMO_PROPERTY(undefined, maximum);
	prop.unit = ATMO_PROPERTY(undefined, unit);
	prop.readOnly = ATMO_PROPERTY(undefined, readOnly);
	prop.onWrite = ATMO_ABILITY(undefined, written);

	ATMO_InitValue(&prop.value);
    if(ATMO_PROPERTY(undefined, initialValue) != NULL)
    {
        // Create initial value as string
        ATMO_Value_t strValue;
        ATMO_InitValue(&strValue);
        ATMO_CreateValueString(&strValue, ATMO_PROPERTY(undefined, initialValue));

        // Convert to desired type
        ATMO_CreateValueConverted(&prop.value, ATMO_PROPERTY(undefined, type), &strValue);
        ATMO_FreeValue(&strValue);
	}
	WebThing_AddProperty(&prop);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('write', [], ['written'], false, false);
	this.setAbilityCode(
		'write',
		`
    WebThing_WritePropertyById(ATMO_ELEMENT_NAME_SLUG(undefined), in);
    return ATMO_Status_Success;
	`,
	);

	this.addAbility('written', [], ['written'], false, true);
	this.setAbilityCode(
		'written',
		`
    ATMO_CreateValueCopy(out, in);
    return ATMO_Status_Success;
    `,
	);
}

ElementControllerEmbeddedWebThingProperty.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedWebThingProperty.prototype.constructor = ElementControllerEmbeddedWebThingProperty;

ElementControllerEmbeddedWebThingProperty.prototype.NAME = 'WebThingProperty';
ElementControllerEmbeddedWebThingProperty.prototype.TYPE =
	'EmbeddedWebThingProperty';
ElementControllerEmbeddedWebThingProperty.prototype.CATEGORY = 'webthing';
ElementControllerEmbeddedWebThingProperty.prototype.HIDDEN = false;
ElementControllerEmbeddedWebThingProperty.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'wifi',
	'esp32',
];
ElementControllerEmbeddedWebThingProperty.prototype.DEFAULT_ABILITY = 'write';
ElementControllerEmbeddedWebThingProperty.prototype.DEFAULT_TRIGGER = 'written';
ElementControllerEmbeddedWebThingProperty.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedWebThingProperty.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedWebThingProperty: 'WebThing Property',
			description: 'Description',
			name: 'Name',
			id: 'ID',
			initialValue: 'Initial Value',
			type: 'Data Type',
			minimum: 'Minimum Value (optional)',
			maximum: 'Maximum Value (optional)',
			unit: 'SI Unit (optional)',
			readOnly: 'Read Only',
			write: 'Write',
			written: 'Written',
			title: 'Title',
			capability: 'Capability',
			WEBTHING_TYPE_None: 'Custom',
			WEBTHING_PROPERTY_TYPE_AlarmProperty: 'AlarmProperty',
			WEBTHING_PROPERTY_TYPE_BooleanProperty: 'BooleanProperty',
			WEBTHING_PROPERTY_TYPE_BrightnessProperty: 'BrightnessProperty',
			WEBTHING_PROPERTY_TYPE_ColorModeProperty: 'ColorModeProperty',
			WEBTHING_PROPERTY_TYPE_ColorProperty: 'ColorProperty',
			WEBTHING_PROPERTY_TYPE_ColorTemperatureProperty:
				'ColorTemperatureProperty',
			WEBTHING_PROPERTY_TYPE_CurrentProperty: 'CurrentProperty',
			WEBTHING_PROPERTY_TYPE_FrequencyProperty: 'FrequencyProperty',
			WEBTHING_PROPERTY_TYPE_HeatingCoolingProperty:
				'HeatingCoolingProperty',
			WEBTHING_PROPERTY_TYPE_InstantaneousPowerProperty:
				'InstantaneousPowerProperty',
			WEBTHING_PROPERTY_TYPE_LeakProperty: 'LeakProperty',
			WEBTHING_PROPERTY_TYPE_LevelProperty: 'LevelProperty',
			WEBTHING_PROPERTY_TYPE_LockedProperty: 'LockedProperty',
			WEBTHING_PROPERTY_TYPE_MotionProperty: 'MotionProperty',
			WEBTHING_PROPERTY_TYPE_OnOffProperty: 'OnOffProperty',
			WEBTHING_PROPERTY_TYPE_OpenProperty: 'OpenProperty',
			WEBTHING_PROPERTY_TYPE_PushedProperty: 'PushedProperty',
			WEBTHING_PROPERTY_TYPE_TargetTemperatureProperty:
				'TargetTemperatureProperty',
			WEBTHING_PROPERTY_TYPE_TemperatureProperty: 'TemperatureProperty',
			WEBTHING_PROPERTY_TYPE_ThermostatModeProperty:
				'ThermostatModeProperty',
			WEBTHING_PROPERTY_TYPE_VoltageProperty: 'VoltageProperty',
		},
	},
);
