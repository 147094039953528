function ProjectController(api, apiv2) {
	ControllerBase.call(this);

	this._name = '';
	this._createVersion = '2';
	this._planes = {};
	this._description = '';
	this._created = '';
	this._lastModified = '';
	this._meta = {};
	this._id = null;
	this._projectUuid = null;
	this._versionUuid = null;
	this._versionName = null;

	this._currentlySaved = null;

	this.setName(getLanguageTag(ProjectController, 'initialProjectName'));
	this.setDescription(getLanguageTag(ProjectController, 'initialProjectName'));
	this.setLastModifiedTime();
	this.setCreatedTime();
	this.setCreateVersionToLatest();

	this.api = api;
	this.apiv2 = apiv2;
}

ProjectController.prototype = Object.create(ControllerBase.prototype);
ProjectController.prototype.constructor = ProjectController;

ProjectController.prototype.getProjectId = function() {
	return this._id;
};

ProjectController.prototype.getProjectUuid = function() {
	return this._projectUuid;
};

ProjectController.prototype.setMeta = function(meta) {
	this._meta = meta;
};

ProjectController.prototype.setMetaValue = function(key, value) {
	this._meta[key] = value;
	return;
};

ProjectController.prototype.getMetaValue = function(key) {
	return this._meta[key];
};

ProjectController.prototype.getMeta = function() {
	return this._meta;
};

ProjectController.prototype.getAvailablePlanes = function() {
	const planeControllers = {};
	const windowKeys = Object.keys(window);
	for (let i = 0; i < windowKeys.length; i++) {
		if (windowKeys[i].indexOf('PlaneController') === 0) {
			planeControllers[windowKeys[i]] = {
				controllerConstructor: window[windowKeys[i]],
			};
		}
	}
	return planeControllers;
};

ProjectController.prototype.getPlaneControllerConstructorByType = function(
	type,
	variants,
) {
	const planeControllers = this.getAvailablePlanes();
	for (let k in planeControllers) {
		const currentController = planeControllers[k].controllerConstructor;
		if (currentController.prototype.TYPE === type) {
			const currentVariants = currentController.prototype.VARIANTS;
			let variantsMatch = true;
			// Make sure the variants list contains all imported variants
			for (var i = 0; i < variants.length; i++) {
				if (currentVariants.indexOf(variants[i]) < 0) {
					variantsMatch = false;
					break;
				}
			}
			if (variantsMatch) {
				return currentController;
			}
		}
	}
	return null;
};

ProjectController.prototype.addPlane = function(
	planeControllerConstructor,
	planeData,
) {
	const planeName = getLanguageTag(planeControllerConstructor, 'defaultName');
	if (this._planes[planeName] !== undefined) {
		this.error('planeDuplicateName');
		return;
	}
	this._planes[planeName] = new planeControllerConstructor(this, planeData);
	this.event('planeAdded', {
		name: planeName,
		controller: this._planes[planeName],
	});
	this._planes[planeName].import(planeData);
	return this._planes[planeName];
};

ProjectController.prototype.removePlane = function(planeName) {
	if (this._planes[planeName] === undefined) {
		this.error('planeDoesNotExist');
		return;
	}
	const removedPlane = this._planes[planeName];
	delete this._planes[planeName];
	this.event('planeRemoved', { name: planeName, controller: removedPlane });
	return removedPlane;
};

ProjectController.prototype.removeAllPlanes = function() {
	for (let k in this._planes) {
		this.removePlane(k);
	}
};

ProjectController.prototype.getPlane = function(planeName) {
	return this._planes[planeName];
};

ProjectController.prototype.getName = function() {
	return this._name;
};

ProjectController.prototype.setName = function(name) {
	if (name === undefined) {
		name = '';
	}
	const oldName = this._name;
	this._name = name;
	this.event('nameSet', { name: this._name, oldName: oldName });
	return;
};

ProjectController.prototype.setDescription = function(description) {
	this._description = description;
	this.event('descriptionSet', { description: this._description });
	return;
};

ProjectController.prototype.setCreatedTime = function(createdTime) {
	if (createdTime === undefined) {
		createdTime = new Date().toISOString();
	} else {
		createdTime = new Date(createdTime).toISOString();
	}

	this._created = createdTime;
	this.event('createdTimeSet', { createdTime: this._created });
};

ProjectController.prototype.setLastModifiedTime = function(modifiedTime) {
	if (modifiedTime === undefined) {
		modifiedTime = new Date().toISOString();
	} else {
		modifiedTime = new Date(modifiedTime).toISOString();
	}

	this._lastModified = modifiedTime;
	this.event('lastModifiedTimeSet', { modifiedTime: this._lastModified });
};

ProjectController.prototype.getProjectInfo = function(callback) {
	const currentController = this;
	const api = this.apiv2.apis;
	const projectId = this.getProjectId();

	api.projects
		.getProject({ id: projectId })
		.then((response) => {
			callback.call(currentController, false, response);
			return;
		})
		.catch((err) => {
			callback.call(currentController, err);
			return;
		});
};

ProjectController.prototype.getProjectFromServer = function(callback) {
	const currentController = this;
	const api = this.apiv2.apis;
	const projectId = this.getProjectId();

	api.projects
		.getProjectSpec({ id: projectId })
		.then((response) => {
			callback.call(currentController, false, response);
			return;
		})
		.catch((err) => {
			callback.call(currentController, err);
			return;
		});
};

ProjectController.prototype.isElementNameUnique = function(elementName) {
	const allElementNames = {};
	for (let k in this._planes) {
		const currentPlaneElements = this._planes[k].getElements();

		for (let i = 0; i < currentPlaneElements.length; i++) {
			allElementNames[currentPlaneElements[i].getName()] = true;
		}
	}
	if (allElementNames[elementName] === true) {
		return false;
	}
	return true;
};

ProjectController.prototype.getUniqueElementName = function(elementNameBase) {
	const allElementNames = {};
	for (let k in this._planes) {
		const currentPlaneElements = this._planes[k].getElements();

		for (let i = 0; i < currentPlaneElements.length; i++) {
			allElementNames[currentPlaneElements[i].getName()] = true;
		}
	}

	let appendText = '';
	let appendNumber = 0;
	while (allElementNames[elementNameBase + appendText] === true) {
		appendNumber++;
		appendText = appendNumber.toString();
	}

	return elementNameBase + appendText;
};

ProjectController.prototype.addCoupledElementToPlanes = function(
	couplerElementController,
) {
	if (couplerElementController.COUPLER_TYPE !== null) {
		for (let k in this._planes) {
			const currentPlane = this._planes[k];
			currentPlane.addElementByCouplerElement(couplerElementController);
		}
	}
};

ProjectController.prototype.removeCoupledElementsFromPlanes = function(
	couplerElementController,
) {
	if (
		couplerElementController !== null &&
		couplerElementController.COUPLER_TYPE !== null
	) {
		for (let k in this._planes) {
			const currentPlane = this._planes[k];
			currentPlane.removeElementsByCouplerElementController(
				couplerElementController,
			);
		}
	}
};

ProjectController.prototype.getPlaneArtifactsFile = function(
	planeController,
	artifactName,
	fileName,
	callback,
) {
	const currentController = this;
	let planeName = null;
	for (let k in this._planes) {
		if (this._planes[k] === planeController) {
			planeName = k;
			break;
		}
	}

	if (planeName === null) {
		callback.call(this, {}, null);
		return;
	}

	const projectUuid = this._projectUuid;
	const versionUuid = this._versionUuid;

	this.api
		.getAPIRoute(
			'/user/create/project/:projectUuid/version/:versionUuid/plane/:planeName/artifact/:artifactName/:fileName',
		)
		.get(
			projectUuid,
			versionUuid,
			planeName,
			artifactName,
			fileName,
			function(err, fileData) {
				callback.call(currentController, err, fileData);
			},
		);
};

ProjectController.prototype.getPlaneArtifacts = function(
	planeController,
	callback,
) {
	const currentController = this;
	let planeName = null;
	for (let k in this._planes) {
		if (this._planes[k] === planeController) {
			planeName = k;
			break;
		}
	}

	if (planeName === null) {
		callback.call(this, {}, null);
		return;
	}

	const projectUuid = this._projectUuid;
	const versionUuid = this._versionUuid;

	let artifactsRoute = this.api.getAPIRoute(
		'/user/create/project/:projectUuid/version/:versionUuid/plane/:planeName/artifacts',
	);

	artifactsRoute.get(projectUuid, versionUuid, planeName, function(
		err,
		data,
	) {
		callback.call(currentController, err, data);
	});
};

ProjectController.prototype.getJwt = async function(api) {
	if (api.jwtNeedsRefresh()) {
		await api.refreshJwt();
	}
	return api.jwt;
};

ProjectController.prototype.compile = function(callback) {
	this.event('compiling', {});
	const currentController = this;
	const compiler = new ProjectCompiler(this.event.bind(this));
	this.getJwt(this.apiv2).then((jwt) => {
		compiler.compile(
			jwt,
			currentController._projectUuid,
			currentController._versionUuid,
			callback,
		);
	});
};

ProjectController.prototype.add = function(name, callback) {
	const currentController = this;
	const api = this.apiv2.apis;
	name = name || this._name;

	async function _getOrganizationIdHelper() {
		// Get the org id from the command hash
		let currentOrgId = (getHashCommand() || {}).org || null;

		if (!currentOrgId) {
			let user = await api.auth.getCurrentUser();
			currentOrgId = user.organizationId || null;
		}

		return currentOrgId;
	}

	_getOrganizationIdHelper()
		.then((orgId) => {
			api.projects
				.postProjects(
					{},
					{ requestBody: { name, organizationId: orgId } },
				)
				.then((response) => {
					currentController._id = response.id;
					currentController._projectUuid = response.uuid;
					currentController._versionUuid = null;
					currentController.save(callback);
					return;
				})
				.catch((err) => {
					console.error(err);
					callback.call(currentController, err);
					return;
				});
		})
		.catch((err) => {
			callback.call(currentController, err, null);
			return;
		});
};

ProjectController.prototype.isSaved = function() {
	return (
		JSON.stringify(this._currentlySaved) === JSON.stringify(this.export())
	);
};

ProjectController.prototype.save = function(callback) {
	const currentController = this;
	const api = this.apiv2.apis;

	const projectId = this.getProjectId();
	const projectUuid = this.getProjectUuid();

	if (projectUuid) {
		const projectData = this.export();
		api.projects
			.createProjectSpec({ id: projectId }, { requestBody: projectData })
			.then((response) => {
				currentController._currentlySaved = projectData;
				callback.call(currentController, false, response);
				return;
			})
			.catch((err) => {
				console.error(err);
				callback.call(currentController, err);
				return;
			});
	} else {
		callback.call(this, { type: 'projectNotAdded' });
		return;
	}
};

ProjectController.prototype.load = function(projectId, callback) {
	const currentController = this;
	const api = this.apiv2.apis;

	api.projects
		.getProject({ id: projectId })
		.then((response) => {
			currentController.removeAllPlanes();
			currentController._id = response.id;
			currentController._projectUuid = response.uuid;
			currentController._name = response.name;
			return api.projects.getProjectSpec({
				id: currentController.getProjectId(),
			});
		})
		.then((response) => {
			currentController.import(response);
			callback.call(currentController, false);
		})
		.catch(() => {
			callback.call(currentController, {
				type: 'failedToLoadProject',
			});
		});
};

ProjectController.prototype.setCreateVersionToLatest = function() {
	this._createVersion = '2';
};

ProjectController.prototype.import = function(projectData) {
	if (projectData === null) {
		this.event('importError', { type: 'projectDataIsNull' });
		return;
	}

	this._name = projectData.name;
	this._createVersion = projectData.createVersion;
	this._description = projectData.description;
	this._lastModified = projectData.lastModified;
	this._created = projectData.created;
	this._meta = projectData.meta;

	for (let planeName in projectData.planes) {
		const planeControllerConstructor = this.getPlaneControllerConstructorByType(
			projectData.planes[planeName].type,
			projectData.planes[planeName].variants,
		);

		// Update variants
		projectData.planes[planeName].variants =
			planeControllerConstructor.prototype.VARIANTS;

		if (planeControllerConstructor !== null) {
			this.addPlane(
				planeControllerConstructor,
				projectData.planes[planeName],
			);
		}
	}

	for (let k in this._planes) {
		this._planes[k].postImport();
	}

	// After the import
	// The project is now mutated to the latest version
	this.setCreateVersionToLatest();
	this._currentlySaved = JSON.parse(JSON.stringify(this.export()));
};

ProjectController.prototype.export = function() {
	const projectExportData = {};

	projectExportData.name = this._name;
	projectExportData.createVersion = this._createVersion;
	projectExportData.description = this._description;
	projectExportData.lastModified = this._lastModified;
	projectExportData.created = this._created;
	projectExportData.meta = this._meta;
	projectExportData.planes = {};

	for (let planeName in this._planes) {
		projectExportData.planes[planeName] = this._planes[planeName].export();
	}

	return projectExportData;
};

ProjectController.prototype.language = {
	'en-US': {
		name: 'Project Controller',
		initialProjectName: 'New Application',
		initialProjectDescription: 'New Application',
	},
};
