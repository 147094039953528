function ElementControllerEmbeddedIconLinesDisplay(
	parentController,
	parentPlane,
	elementName,
	numMeasurements,
) {
	ElementControllerEmbeddedPageTemplateBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty(
		'icon',
		null,
		this.iconList[0],
		'select',
		this.iconList,
		null,
	);
	this.addProperty('iconLabel', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('iconLabel', 'string');
	this.addProperty('numLines', null, 4, 'number', null, null);
	this.addProperty('line1Text', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('line1Text', 'string');
	this.addProperty('line2Text', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('line2Text', 'string');
	this.addProperty('line3Text', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('line3Text', 'string');
	this.addProperty('line4Text', null, '', 'text', null, null);
	this.setPropertyEmbeddedConversion('line4Text', 'string');

	this.addAbility('onDisplayed', [], ['onDisplayed'], false, true);
	this.setAbilityCode(
		'onDisplayed',
		`
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('onLeave', [], ['onLeave'], false, true);
	this.setAbilityCode(
		'onLeave',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setIconLabelAndColor', [], []);
	this.setAbilityCode(
		'setIconLabelAndColor',
		`
    struct {
        char str[32];
        GUI_COLOR color;
    } icon_data;
 
    ATMO_GetBinary(in, &icon_data, sizeof(icon_data));
    ATMO_UI_ICONLINES_SetIconLabelColor(ATMO_VARIABLE(undefined, pageHandle), icon_data.str, icon_data.color);
    return ATMO_Status_Success;
    `,
	);

	this.addAbility('setIconLabel', [], []);
	this.setAbilityCode(
		'setIconLabel',
		`
    char str[32];
    ATMO_GetString(in, str, 32);
    ATMO_UI_ICONLINES_SetIconLabel(ATMO_VARIABLE(undefined, pageHandle), str);
    return ATMO_Status_Success;
    `,
	);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
    ATMO_UI_PAGE_Config_t config;
    config.hidden = ATMO_PROPERTY(undefined, pageHidden);
    config.textColor = ATMO_PROPERTY(undefined, textColor);
    config.activeButtons = ATMO_UI_Page_GetButtonMask(ATMO_PROPERTY(undefined, topRightButtonEnabled),
    ATMO_PROPERTY(undefined,bottomRightButtonEnabled), ATMO_PROPERTY(undefined, topLeftButtonEnabled), ATMO_PROPERTY(undefined, bottomLeftButtonEnabled));
	config.x = ATMO_PROPERTY(undefined, x);
    config.y = ATMO_PROPERTY(undefined, y);
	strncpy(config.topLeftButtonLabel, ATMO_PROPERTY(undefined, topLeftButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
	strncpy(config.topRightButtonLabel, ATMO_PROPERTY(undefined, topRightButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
	strncpy(config.bottomLeftButtonLabel, ATMO_PROPERTY(undefined, bottomLeftButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
	strncpy(config.bottomRightButtonLabel, ATMO_PROPERTY(undefined, bottomRightButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
    config.spanX = ATMO_PROPERTY(undefined, spanX);
	config.spanY = ATMO_PROPERTY(undefined, spanY);
    config.title = ATMO_PROPERTY(undefined, pageTitle);
    config.titleHidden = ATMO_PROPERTY(undefined, titleHidden);
	ATMO_UI_ICONLINES_Init(&config, ATMO_PROPERTY(undefined, numLines), false);
	ATMO_VARIABLE(undefined, pageHandle) = config.templateInstance;
    ATMO_UI_ICONLINES_SetMainText(config.templateInstance, 0, ATMO_PROPERTY(undefined, line1Text));
    ATMO_UI_ICONLINES_SetMainText(config.templateInstance, 1, ATMO_PROPERTY(undefined, line2Text));
    ATMO_UI_ICONLINES_SetMainText(config.templateInstance, 2, ATMO_PROPERTY(undefined, line3Text));
    ATMO_UI_ICONLINES_SetMainText(config.templateInstance, 3, ATMO_PROPERTY(undefined, line4Text));
    ATMO_UI_ICONLINES_SetIconLabel(config.templateInstance, ATMO_PROPERTY(undefined, iconLabel));
    ATMO_UI_ICONLINES_RegisterButtonAbilityHandle(ATMO_VARIABLE(undefined,pageHandle), 1, ATMO_ABILITY(undefined, topRightButtonPressed));
	ATMO_UI_ICONLINES_RegisterButtonAbilityHandle(ATMO_VARIABLE(undefined,pageHandle), 2, ATMO_ABILITY(undefined, bottomRightButtonPressed));
	ATMO_UI_ICONLINES_RegisterButtonAbilityHandle(ATMO_VARIABLE(undefined,pageHandle), 3, ATMO_ABILITY(undefined, topLeftButtonPressed));
    ATMO_UI_ICONLINES_RegisterButtonAbilityHandle(ATMO_VARIABLE(undefined,pageHandle), 4, ATMO_ABILITY(undefined, bottomLeftButtonPressed));
    ATMO_UI_ICONLINES_SetIcon(config.templateInstance, ATMO_PROPERTY(undefined, icon));
    ATMO_UI_ICONLINES_RegisterOnDisplayedAbilityHandle(ATMO_VARIABLE(undefined,pageHandle), ATMO_ABILITY(undefined, onDisplayed));
    ATMO_UI_ICONLINES_RegisterOnLeaveAbilityHandle(config.templateInstance, ATMO_ABILITY(undefined, onLeave));
    return ATMO_Status_Success;
    `,
	);

	this.addAbility('setLine1Text', [], []);
	this.setAbilityCode(
		'setLine1Text',
		`
    char label[32];
    if(ATMO_GetString(in, label, 32) == ATMO_Status_Success)
    {
        ATMO_UI_ICONLINES_SetMainText(ATMO_VARIABLE(undefined,pageHandle), 0, label);
    }
    else
    {
        return ATMO_Status_Fail;
    }

    return ATMO_Status_Success;
    `,
	);

	this.addAbility('setLine2Text', [], []);
	this.setAbilityCode(
		'setLine2Text',
		`
    char label[32];
    if(ATMO_GetString(in, label, 32) == ATMO_Status_Success)
    {
        ATMO_UI_ICONLINES_SetMainText(ATMO_VARIABLE(undefined,pageHandle), 1, label);
    }
    else
    {
        return ATMO_Status_Fail;
    }

    return ATMO_Status_Success;
    `,
	);

	this.addAbility('setLine3Text', [], []);
	this.setAbilityCode(
		'setLine3Text',
		`
    char label[32];
    if(ATMO_GetString(in, label, 32) == ATMO_Status_Success)
    {
        ATMO_UI_ICONLINES_SetMainText(ATMO_VARIABLE(undefined,pageHandle), 2, label);
    }
    else
    {
        return ATMO_Status_Fail;
    }

    return ATMO_Status_Success;
    `,
	);

	this.addAbility('setLine4Text', [], []);
	this.setAbilityCode(
		'setLine4Text',
		`
    char label[32];
    if(ATMO_GetString(in, label, 32) == ATMO_Status_Success)
    {
        ATMO_UI_ICONLINES_SetMainText(ATMO_VARIABLE(undefined,pageHandle), 3, label);
    }
    else
    {
        return ATMO_Status_Fail;
    }

    return ATMO_Status_Success;
    `,
	);

	this.addAbility(
		'topRightButtonPressed',
		[],
		['topRightButtonPressed'],
		false,
		true,
	);
	this.setAbilityCode(
		'topRightButtonPressed',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility(
		'bottomRightButtonPressed',
		[],
		['bottomRightButtonPressed'],
		false,
		true,
	);
	this.setAbilityCode(
		'bottomRightButtonPressed',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility(
		'topLeftButtonPressed',
		[],
		['topLeftButtonPressed'],
		false,
		true,
	);
	this.setAbilityCode(
		'topLeftButtonPressed',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility(
		'bottomLeftButtonPressed',
		[],
		['bottomLeftButtonPressed'],
		false,
		true,
	);
	this.setAbilityCode(
		'bottomLeftButtonPressed',
		`
	return ATMO_Status_Success;
	`,
	);
}

ElementControllerEmbeddedIconLinesDisplay.prototype = Object.create(
	ElementControllerEmbeddedPageTemplateBase.prototype,
);
ElementControllerEmbeddedIconLinesDisplay.prototype.constructor = ElementControllerEmbeddedIconLinesDisplay;

ElementControllerEmbeddedIconLinesDisplay.prototype.DEFAULT_TRIGGER =
	'onDisplayed';
ElementControllerEmbeddedIconLinesDisplay.prototype.DEFAULT_ABILITY =
	'setLine1Text';
ElementControllerEmbeddedIconLinesDisplay.prototype.DEFAULT_ARGUMENTS = [
	'value',
];
ElementControllerEmbeddedIconLinesDisplay.prototype.TYPE =
	'EmbeddedIconLinesDisplay';
ElementControllerEmbeddedIconLinesDisplay.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'rpk',
];
ElementControllerEmbeddedIconLinesDisplay.prototype.HIDDEN = false;
ElementControllerEmbeddedIconLinesDisplay.prototype.CATEGORY = 'Rapid IOT';

ElementControllerEmbeddedIconLinesDisplay.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedIconLinesDisplay.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedPageTemplateBase.prototype.language,
	{
		'en-US': {
			EmbeddedIconLinesDisplay: 'Icon Text Lines',
			label: 'Initial Label',
			icon: 'Icon',
			setLine1Text: 'Set Line 1 Text',
			setLine2Text: 'Set Line 2 Text',
			setLine3Text: 'Set Line 3 Text',
			setLine4Text: 'Set Line 4 Text',
			line1Text: 'Line 1 Default Text',
			line2Text: 'Line 2 Default Text',
			line3Text: 'Line 3 Default Text',
			line4Text: 'Line 4 Default Text',
			setIconLabel: 'Set Icon Label',
			iconLabel: 'Icon Label',
		},
	},
);
