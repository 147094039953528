function ElementControllerEmbeddedDeviceEvent(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty(
		'instance',
		null,
		0,
		'driverInstance',
		null,
		'embeddedCloud',
	);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
    
	
	return ATMO_Status_Success;
    `,
	);

	this.addAbility('sendEvent', [], ['eventSent'], false, false);
	this.setAbilityCode(
		'sendEvent',
		`
    
	if( ATMO_CLOUD_SendEvent(ATMO_PROPERTY(undefined, instance), ATMO_ELEMENT_NAME(undefined), in, 100) != ATMO_CLOUD_Status_Success )
    {  
        return ATMO_Status_Fail;
    }

	return ATMO_Status_Success;
	`,
	);
}

ElementControllerEmbeddedDeviceEvent.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedDeviceEvent.prototype.constructor = ElementControllerEmbeddedDeviceEvent;

ElementControllerEmbeddedDeviceEvent.prototype.NAME = 'DeviceEvent';
ElementControllerEmbeddedDeviceEvent.prototype.DEFAULT_TRIGGER = 'eventSent';
ElementControllerEmbeddedDeviceEvent.prototype.DEFAULT_ABILITY = 'sendEvent';
ElementControllerEmbeddedDeviceEvent.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerEmbeddedDeviceEvent.prototype.TYPE = 'EmbeddedDeviceEvent';
ElementControllerEmbeddedDeviceEvent.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'embeddedCloud',
];
ElementControllerEmbeddedDeviceEvent.prototype.HIDDEN = false;
ElementControllerEmbeddedDeviceEvent.prototype.COUPLER_TYPE = 'deviceEvent';
ElementControllerEmbeddedDeviceEvent.prototype.CATEGORY = 'cloud';
ElementControllerEmbeddedDeviceEvent.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedDeviceEvent.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedDeviceEvent: 'Device Event',
			sendEvent: 'Send Event',
			eventSent: 'Event Sent',
			instance: 'Cloud Driver Instance',
		},
	},
);
