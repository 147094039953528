function ElementControllerAppUISlider(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppUIBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('value', null, 0, 'number', null, null);
	this.addProperty('stepping', null, 1, 'number', null, null);
	this.addProperty('minValue', null, 0, 'number', null, null);
	this.addProperty('maxValue', null, 100, 'number', null, null);

	this.addAbility('setValue', [{ name: 'value', validator: null }]);
	this.addAbility('setStepping', [{ name: 'stepping', validator: null }]);
	this.addAbility('setMaxValue', [{ name: 'maxValue', validator: null }]);
	this.addAbility('setMinValue', [{ name: 'minValue', validator: null }]);

	this.addTrigger('changed');
}

ElementControllerAppUISlider.prototype = Object.create(
	ElementControllerAppUIBase.prototype,
);
ElementControllerAppUISlider.prototype.constructor = ElementControllerAppUISlider;

ElementControllerAppUISlider.prototype.NAME = 'Slider';
ElementControllerAppUISlider.prototype.TYPE = 'AppUISlider';
ElementControllerAppUISlider.prototype.HIDDEN = false;
ElementControllerAppUISlider.prototype.DEFAULT_TRIGGER = 'changed';
ElementControllerAppUISlider.prototype.DEFAULT_ABILITY = 'setValue';
ElementControllerAppUISlider.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerAppUISlider.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerAppUISlider.prototype.language = deepAssign(
	{},
	ElementControllerAppUIBase.prototype.language,
	{
		'en-US': {
			AppUISlider: 'Slider',
			value: 'Value',
			stepping: 'Stepping',
			minValue: 'Minimum Value',
			maxValue: 'Maximum Value',
			setValue: 'Set Value',
			value: 'Value',
			setStepping: 'Set Stepping',
			stepping: 'Stepping',
			setMaxValue: 'Set Maximum Value',
			maxValue: 'Maximum Value',
			setMinValue: 'Set Minimum Value',
			minValue: 'Minimum Value',
			changed: 'Changed',
		},
	},
);
