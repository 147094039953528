function PlaneControllerEmbeddedArduinoDue(parentController, elementName) {
	PlaneControllerEmbeddedArduino.call(this, parentController, elementName);
}

PlaneControllerEmbeddedArduinoDue.prototype = Object.create(
	PlaneControllerEmbeddedArduino.prototype,
);
PlaneControllerEmbeddedArduinoDue.prototype.constructor = PlaneControllerEmbeddedArduinoDue;

PlaneControllerEmbeddedArduinoDue.prototype.VARIANTS = ['due'];
PlaneControllerEmbeddedArduinoDue.prototype.SUPPORTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'fpmath',
	'interval',
	'variables',
	'gpio',
	'adc',
	'i2c',
	'spi',
	'uart',
	'debugPrint',
	'log',
	'uptime',
];

PlaneControllerEmbeddedArduinoDue.prototype.DRIVERS = {
	adc: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_ADC_ADC1',
		},
	],
	ble: [],
	block: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLOCK_BLOCK1',
		},
	],
	datetime: [],
	filesytem: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_FILESYSTEM_FILESYSTEM1',
		},
	],
	gpio: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_GPIO_GPIO1',
		},
	],
	http: [],
	i2c: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C1',
			primary: true,
		},
	],
	interval: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_INTERVAL_INTERVAL1',
		},
	],
	nfc: [],
	pwm: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_PWM_PWM1',
		},
	],
	spi: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_SPI_SPI1',
		},
	],
	uart: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_UART_UART1',
		},
		{
			id: 1,
			name: 'ATMO_DRIVERINSTANCE_UART_UART2',
		},
		{
			id: 2,
			name: 'ATMO_DRIVERINSTANCE_UART_UART3',
		},
		{
			id: 3,
			name: 'ATMO_DRIVERINSTANCE_UART_UART4',
		},
		{
			id: 4,
			name: 'ATMO_DRIVERINSTANCE_UART_UART5',
		},
	],
	wifi: [],
};

PlaneControllerEmbeddedArduinoDue.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'D13',
	},

	EmbeddedPWMPin: {
		instance: 0,
		pin: 'D13',
	},

	EmbeddedADCPin: {
		instance: 0,
		pin: 'A0',
	},
	EmbeddedGroveVibration: {
		vibrationPin: 3,
	},

	EmbeddedGroveLight: {
		adcInstance: 0,
		adcPin: 'A0',
	},

	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'D13',
	},

	EmbeddedGroveTemperature: {
		adcInstance: 0,
		adcPin: 'A0',
	},

	EmbeddedGroveRotaryAngle: {
		adcInstance: 0,
		adcPin: 'A0',
	},

	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'D13',
	},

	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'D13',
	},

	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'D13',
	},
};

PlaneControllerEmbeddedArduinoDue.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			defaultName: 'Arduino Due',
			ATMO_DRIVERINSTANCE_UART_UART1: 'UART1 (Prog USB)',
			ATMO_DRIVERINSTANCE_UART_UART2: 'UART2 (18/19)',
			ATMO_DRIVERINSTANCE_UART_UART3: 'UART3 (16/17)',
			ATMO_DRIVERINSTANCE_UART_UART4: 'UART4 (14/15)',
			ATMO_DRIVERINSTANCE_UART_UART5: 'UART5 (Native USB)',
		},
	},
);
