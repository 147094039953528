function PlaneControllerEmbeddedPicIoT(parentController, elementName) {
	PlaneControllerEmbeddedMicrochip.call(this, parentController, elementName);
}

PlaneControllerEmbeddedPicIoT.prototype = Object.create(
	PlaneControllerEmbeddedMicrochip.prototype,
);
PlaneControllerEmbeddedPicIoT.prototype.constructor = PlaneControllerEmbeddedPicIoT;

PlaneControllerEmbeddedPicIoT.prototype.VARIANTS = ['PicIoT'];
PlaneControllerEmbeddedPicIoT.prototype.SUPPORTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'interval',
	'variables',
	'adc',
	'gpio',
	'uart',
	'i2c',
	'spi',
	'fpmath',
	'debugPrint',
	'log',
	'uptime',
];
PlaneControllerEmbeddedPicIoT.prototype.CLIENT_AGENT_REQUIRED = false;

PlaneControllerEmbeddedPicIoT.prototype.FIRMWARE_DOWNLOAD_FILES = {
	artifactName: 'firmware',
	fileName: 'Atmosphere_Project.hex',
};

PlaneControllerEmbeddedPicIoT.prototype.DRIVERS = {
	adc: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_ADC_ADC1',
		},
	],
	ble: [],
	block: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_BLOCK_BLOCK1',
		},
	],
	datetime: [],
	embeddedCloud: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_CLOUD_WIFI',
		},
	],
	filesytem: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_FILESYSTEM_FILESYSTEM1',
		},
	],
	gpio: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_GPIO_GPIO1',
		},
	],
	http: [],
	i2c: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C1',
			primary: true,
		},
		{
			id: 1,
			name: 'ATMO_DRIVERINSTANCE_I2C_I2C2',
		},
	],
	interval: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_INTERVAL_INTERVAL1',
		},
	],
	nfc: [],
	pwm: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_PWM_PWM1',
		},
	],
	spi: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_SPI_SPI1',
		},
	],
	uart: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_UART_UART1',
		},
		{
			id: 1,
			name: 'ATMO_DRIVERINSTANCE_UART_UART2',
			primary: true,
		},
	],
	wifi: [
		{
			id: 0,
			name: 'ATMO_DRIVERINSTANCE_WIFI_WIFI1',
		},
	],
};

PlaneControllerEmbeddedPicIoT.prototype.DEFAULT_PROPERTIES = {
	EmbeddedGPIOPin: {
		instance: 0,
		pin: 'LED_DATA',
		mode: 'ATMO_GPIO_PinMode_Output_PushPull',
	},
	EmbeddedADCPin: {
		instance: 0,
		pin: 'P_AN',
	},
	EmbeddedMCP9808: {
		i2cInstance: 1,
	},
	EmbeddedTemt6000: {
		adcPin: 'P_LIGHTSENS',
	},
	EmbeddedPWMPin: {
		pin: 'P_PWM',
	},
	EmbeddedHeartRate5Click: {
		intPin: 'P_INT',
		rstPin: 'P_RST',
	},
	EmbeddedPressure4Click: {
		csPin: 'P_CS',
	},
	EmbeddedGroveLight: {
		adcInstance: 0,
		adcPin: 'P_AN',
	},
	EmbeddedGroveVariableColorLED: {
		gpioInstance: 0,
		gpioPin: 'P_INT',
	},
	EmbeddedGroveTemperature: {
		adcInstance: 0,
		adcPin: 'P_AN',
	},
	EmbeddedGroveRotaryAngle: {
		adcInstance: 0,
		adcPin: 'P_AN',
	},
	EmbeddedGrovePIRMotion: {
		gpioInstance: 0,
		gpioPin: 'P_INT',
	},
	EmbeddedGroveBuzzer: {
		gpioInstance: 0,
		gpioPin: 'P_INT',
	},
	EmbeddedGroveButton: {
		gpioInstance: 0,
		gpioPin: 'P_INT',
	},
	EmbeddedADXL343: {
		i2cInstance: 0,
		gpioInstance: 0,
		int1Pin: 'P_INT',
		int2Pin: 'P_RST',
	},
	EmbeddedADXL345: {
		i2cInstance: 0,
		gpioInstance: 0,
		int1Pin: 'P_INT',
		int2Pin: 'P_RST',
	},
	Embedded8x8LedClick: {
		csPin: 'P_CS',
	},
};

PlaneControllerEmbeddedPicIoT.prototype.language = deepAssign(
	{},
	PlaneControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			defaultName: 'PIC-IoT WG',
			ATMO_DRIVERINSTANCE_WIFI_WIFI1: 'WiFi',
			ATMO_DRIVERINSTANCE_CLOUD_WIFI: 'WiFi',
			ATMO_DRIVERINSTANCE_I2C_I2C1: 'I2C1 (Click)',
			ATMO_DRIVERINSTANCE_I2C_I2C2: 'I2C2 (Internal)',
			ATMO_DRIVERINSTANCE_UART_UART1: 'UART1 (USB)',
			ATMO_DRIVERINSTANCE_UART_UART2: 'UART2 (Click)',
		},
	},
);
