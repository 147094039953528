function ElementControllerCloudTheThingsNetworkStandalone(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('value', null, null, 'none');
	this.addProperty('timestamp', null, null, 'none');

	this.addProperty(
		'upstreamDataType',
		null,
		'ATMO_DATATYPE_BINARY',
		'select',
		this.DATA_TYPES,
	);

	this.addProperty(
		'downstreamDataType',
		null,
		'ATMO_DATATYPE_BINARY',
		'select',
		this.DATA_TYPES,
	);

	this.addTrigger('dataReceived');
}

ElementControllerCloudTheThingsNetworkStandalone.prototype = Object.create(
	ElementControllerCloudBase.prototype,
);
ElementControllerCloudTheThingsNetworkStandalone.prototype.constructor = ElementControllerCloudTheThingsNetworkStandalone;

ElementControllerCloudTheThingsNetworkStandalone.prototype.NAME =
	'CloudTheThingsNetwork';
ElementControllerCloudTheThingsNetworkStandalone.prototype.TYPE =
	'CloudTheThingsNetworkStandalone';
ElementControllerCloudTheThingsNetworkStandalone.prototype.HIDDEN = false;
ElementControllerCloudTheThingsNetworkStandalone.prototype.DEFAULT_TRIGGER =
	'dataReceived';
ElementControllerCloudTheThingsNetworkStandalone.prototype.DEFAULT_ABILITY =
	'trigger';
ElementControllerCloudTheThingsNetworkStandalone.prototype.DEFAULT_ARGUMENTS = [
	'value',
];
ElementControllerCloudTheThingsNetworkStandalone.prototype.VARIANTS = [
	'cloud',
	'standalone',
];
ElementControllerCloudTheThingsNetworkStandalone.prototype.SINGLETON = true;
ElementControllerCloudTheThingsNetworkStandalone.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerCloudTheThingsNetworkStandalone.prototype.language = deepAssign(
	{},
	ElementControllerCloudTheThingsNetwork.prototype.language,
	{
		'en-US': {
			CloudTheThingsNetworkStandalone: 'The Things Network',
		},
	},
);
