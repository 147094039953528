function WidgetLocationControls(id, api, parentWidget, options) {
	/*
	 * This is a standard control buttons that typically
	 * appear in the top right of the UI in a location.
	 *
	 * You can configure this by passing in the options
	 * such as the following.
	 *
	 * {
	 * 	entries:[
	 * 		{
	 * 			name:"info",
	 * 			imageSrc:"./Resource/icons/information.svg",
	 * 			label:"Information"
	 * 		}
	 * 	]
	 * }
	 *
	 *
	 */
	WidgetBase.call(this, id, api, parentWidget, options);
}

WidgetLocationControls.prototype = Object.create(WidgetBase.prototype);
WidgetLocationControls.prototype.constructor = WidgetLocationControls;

WidgetLocationControls.prototype.initialize = function(callback) {
	var currentWidget = this;

	var entries = this.getOptions().entries || [];

	this.renderTemplate(
		{
			entries: entries,
		},
		WidgetLocationControls.name,
	);

	var inputs = this.getContainer().find('*[data-controlname]');

	for (var i = 0; i < inputs.length; i++) {
		$(inputs[i]).click(function() {
			currentWidget.event($(this).attr('data-controlname'));
		});
	}

	WidgetBase.prototype.initialize.call(this, callback);
};

WidgetLocationControls.prototype.getEntry = function(name) {
	return this.getContainer()
		.find(`*[data-controlname="${name}"]`);
};

WidgetLocationControls.prototype.showEntry = function(name) {
	this.getContainer()
		.find(`*[data-controlname="${name}"]`)
		.show();
};

WidgetLocationControls.prototype.hideEntry = function(name) {
	this.getContainer()
		.find(`*[data-controlname="${name}"]`)
		.hide();
};

WidgetLocationControls.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'Controls',
		},
	},
);
