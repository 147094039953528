function WifiBackendClientAgent() {
	WifiBackendBase.call(this);

	console.log('WifiBackendClientAgent()');

	this._clientAgent = null;

	var clientAgent = _mainContainer.getClientAgent();

	if (
		clientAgent !== undefined &&
		clientAgent !== null &&
		clientAgent.wifi !== undefined &&
		clientAgent.wifi !== null
	) {
		this._clientAgent = clientAgent;
	}

	this._rxCallback = undefined;

	this._clientAgent.wifi.addEventListener('tcpData', function(data) {
		currentBackend._rxCallback(data);
	});
}

WifiBackendClientAgent.prototype = Object.create(WifiBackendBase.prototype);
WifiBackendClientAgent.prototype.constructor = WifiBackendClientAgent;

WifiBackendClientAgent.prototype.IS_AVAILABLE = function() {
	var clientAgent = _mainContainer.getClientAgent();
	console.log(JSON.stringify(clientAgent));
	if (
		clientAgent !== undefined &&
		clientAgent !== null &&
		clientAgent.wifi !== undefined &&
		clientAgent.wifi !== null &&
		clientAgent.isConnected()
	) {
		return true;
	}

	return false;
};

WifiBackendClientAgent.prototype.connectNetwork = function(options, callback) {
	var currentBackend = this;
	currentBackend._clientAgent.wifi.connectNetwork(options, function(err) {
		callback.call(currentBackend, err);
	});
};

WifiBackendClientAgent.prototype.disconnectNetwork = function(ssid, callback) {
	var currentBackend = this;
	currentBackend._clientAgent.wifi.disconnectNetwork(ssid, function(err) {
		callback.call(currentBackend, err);
	});
};

WifiBackendClientAgent.prototype.getScanResults = function(callback) {
	var currentBackend = this;
	currentBackend._clientAgent.wifi.getScanResults(function(err, results) {
		if (err) {
			callback.call(currentBackend, true);
			return;
		}
		callback.call(currentBackend, false, results);
	});
};

WifiBackendClientAgent.prototype.getCurrentSSID = function(callback) {
	var currentBackend = this;
	currentBackend._clientAgent.wifi.getCurrentSSID(function(err, ssid) {
		if (err) {
			callback.call(currentBackend, true);
			return;
		}
		callback.call(currentBackend, false, ssid);
	});
};

WifiBackendClientAgent.prototype.tcpConnect = function(
	addr,
	port,
	rxCallback,
	rxTimeoutCallback,
	rxTimeoutSeconds,
	callback,
) {
	var currentBackend = this;
	currentBackend._rxCallback = rxCallback;
	currentBackend._clientAgent.wifi.tcpConnect(addr, port, function(err) {
		callback.call(currentBackend, err);
	});
};

WifiBackendClientAgent.prototype.tcpSend = function(data, callback) {
	var currentBackend = this;
	currentBackend._clientAgent.wifi.tcpSend(data, function(err) {
		callback.call(currentBackend, err);
	});
};

WifiBackendClientAgent.prototype.tcpDisconnect = function(callback) {
	var currentBackend = this;
	currentBackend._clientAgent.wifi.tcpDisconnect(function(err) {
		callback.call(currentBackend, err);
	});
};
