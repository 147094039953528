function getAPIBase() {
	let apiPath = window.location.pathname.replace(/\/$/, '');
	let apiBase = null;

	if (apiPath.endsWith('.html')) {
		apiPath = apiPath
			.split('/')
			.splice(0, 2)
			.join('/');
	}

	//If we are the mobile app
	if (isPhonegap()) {
		apiBase = getAPIBaseForMobile();
	} else {
		if (window.location.protocol === 'file:') {
			apiBase = '';
		} else {
			if (window.location.port.length === 0) {
				apiBase =
					window.location.protocol +
					'//' +
					window.location.hostname +
					apiPath;
			} else {
				apiBase =
					window.location.protocol +
					'//' +
					window.location.hostname +
					':' +
					window.location.port +
					apiPath;
			}
		}
	}

	return apiBase;
}

function getAPIBaseForMobile() {
	// If this (window._checkedRemoteAPIBasePath) isn't set we haven't checked to see if we are running
	// a newer version of the app, if we are running a newer version of the app from the target server then
	// use the alt path stored from the build config as altRemoteAPIBasePath.
	// See createProject.js under /mobileApp,  Which adds variables to the injected.js file in the client side code.
	let apiBase = null;
	if (!window._checkedRemoteAPIBasePath) {
		try {
			// Send a request to production, to get the version number that production is running.
			// FIXME: Browsers are deperacating sync ajax requests in the main thread, also
			// we should probably be doing this inside our API handler code not making raw requests
			// here
			let request = null;
			
			try {
				request = new XMLHttpRequest();
				request.open('GET', remoteAPIBasePath + '/version', false); // `false` makes the request synchronous
				request.send(null);
			}
			
			catch(err) {
				if (
					isActive() && // Only go to login page if the user is looking at the screen
					window._mainContainer
				) {
					window._mainContainer.redirectToLogin();
				}
			}
			
			if (request.status === 200) {
				// If the version embedded into the mobile app is greater than that of production
				// point the client to the altRemoteAPIBasePath (staging)
				// in order to get the past the Apple review process.
				let prodVersion = JSON.parse(request.responseText);
				if (ATMO_VERSION > prodVersion) {
					window._checkedRemoteAPIBasePath = altRemoteAPIBasePath;
				} else {
					window._checkedRemoteAPIBasePath = remoteAPIBasePath;
				}
			} else {
				window._checkedRemoteAPIBasePath = remoteAPIBasePath;
			}
		} catch (error) {
			throw new Error(
				`Unable to getAPIBase due to ${error} , stack: ${error.stack}`,
			);
		}
	}

	apiBase = window._checkedRemoteAPIBasePath;
	return apiBase;
}

function getWebsocketBase() {
	const apiBase = _mainContainer._globalConfig.deviceAPIBase || getAPIBase();
	const apiSplit = apiBase.split('://');
	const protocol = apiSplit[0] === 'https' ? 'wss' : 'ws';
	const websocketBase = protocol + '://' + apiSplit[1];
	return websocketBase;
}

function isRunningInProduction(apiBase) {
	// If the app can't connect then assume we are not running in production
	try {
		apiBase = apiBase || getAPIBase();
		if (apiBase.indexOf('.ngrok.io') > -1) {
			return false;
		}
		var hostCheck = RegExp(/^https\:\/\/.*\..*\..*$/);
		return hostCheck.test(apiBase);
	} catch (error) {
		console.error(error);
		return false;
	}
}

function clientHasBLE() {
	PluginBLECentral.prototype.SELECT_BACKEND();

	return (
		PluginBLECentral.prototype.SELECTED_BACKEND.name !==
		'BLECentralBackendNull'
	);
}

function getLanguage() {
	var lang = window.navigator.languages
		? window.navigator.languages[0]
		: null;
	lang =
		lang ||
		window.navigator.language ||
		window.navigator.browserLanguage ||
		window.navigator.userLanguage;

	if (lang === undefined || lang === null) {
		lang = 'en-US';
	}

	return lang;
}

function getLanguageTag(constructorFunction, tag) {
	var currentLanguage = null;

	if (
		constructorFunction !== undefined &&
		constructorFunction.prototype !== undefined
	) {
		currentLanguage = constructorFunction.prototype.language;
	}

	return getFromLanguageObject(currentLanguage, tag);
}

function getFromLanguageObject(languageObject, tag) {
	var value =
		buildCompleteLanguageObject(languageObject, getLanguage())[tag] || tag;

	return value;
}

function isLanguageTagDefined(languageObject, tag) {
	return Boolean(
		buildCompleteLanguageObject(languageObject, getLanguage())[tag],
	);
}

function getMessageLanguage(languageObject) {
	var value = null;

	if (languageObject[getLanguage()] !== undefined) {
		value = languageObject[getLanguage()];
	} else if (getLanguage().indexOf('-') > -1) {
		if (languageObject[getLanguage().split('-')[0]] !== undefined) {
			value = languageObject[getLanguage().split('-')[0]];
		} else {
			if (languageObject['en-US'] !== undefined) {
				value = languageObject['en-US'];
			} else if ('en-US'.indexOf('-') > -1) {
				if (languageObject['en-US'.split('-')[0]] !== undefined) {
					value = languageObject['en-US'.split('-')[0]];
				}
			}
		}
	} else {
		if (languageObject['en-US'] !== undefined) {
			value = languageObject['en-US'];
		} else if ('en-US'.indexOf('-') > -1) {
			if (languageObject['en-US'.split('-')[0]] !== undefined) {
				value = languageObject['en-US'.split('-')[0]];
			}
		}
	}

	return value;
}

function buildCompleteLanguageObject(languageObject, languageCode) {
	languageObject = languageObject || {};

	window.globalLanguageObject = window.globalLanguageObject || {};

	var enGlobal = window.globalLanguageObject['en-US'] || {};
	var langGlobal = window.globalLanguageObject[languageCode] || {};
	var enLocal = languageObject['en-US'] || {};
	var langLocal = languageObject[languageCode] || {};

	langObj = Object.assign({}, enGlobal, langGlobal, enLocal, langLocal);

	return langObj;
}

function passLanguageTagsToHandlebarsContext(languageObject) {
	return buildCompleteLanguageObject(languageObject, getLanguage());
}

function passWidgetIdsToHandlebarsContext(widgetInstance) {
	var ids = {};

	if (widgetInstance === null || widgetInstance === undefined) {
		return ids;
	}

	var widgetKeys = Object.keys(widgetInstance);

	ids.id = widgetInstance.id;

	// Look for properties with Id at the end of them.
	for (var i = 0; i < widgetKeys.length; i++) {
		var currentWidgetKey = widgetKeys[i];

		if (currentWidgetKey.substr(-2) === 'Id') {
			//Look to see if the last 2 characters are an id.
			ids[currentWidgetKey] = widgetInstance[currentWidgetKey];
		}
	}

	return ids;
}

function generateHashCommand(args) {
	return `#${encodeURIComponent(JSON.stringify(args))}`;
}

function setHashCommand(args) {
	window.location.hash = generateHashCommand(args);

	return;
}

function getHashCommand(hash) {
	hash = hash || location.hash;

	var command = decodeURIComponent(hash.replace('#', ''));
	// catch this annoying error before we get this annoying error
	if (command !== undefined && command !== null && command.length !== 0) {
		try {
			command = JSON.parse(command);
		} catch (err) {
			return null;
		}
		return command;
	} else {
		return null;
	}
}

function isPhonegap() {
	return window.cordova !== undefined;
}

function refreshPhonegap() {
	if (!isPhonegap()) {
		return;
	}

	window.CacheClear(
		function() {
			location.reload(true);
		},
		function() {
			console.log('refreshPhonegap(): Failed to clear cache');
		},
	);
}

// This function will let us know if our 
// application is running in the background
function isActive() {
	if(isPhonegap()) {
		//If we haven't loaded this yet then we are loading, then we are active
		if(!cordova.plugins || !cordova.plugins.backgroundMode) {
			return true;
		}
		
		return cordova.plugins.backgroundMode.isActive()
	}
	
	else {
		return true;
	}
}

function getKeyByValue(object, value) {
	return Object.keys(object)[Object.values(object).indexOf(value)];
}

function isString(obj) {
	return Object.prototype.toString.call(obj) === '[object String]'
		? true
		: false;
}

function isValueObject(value) {
	return value && typeof value === 'object' && !Array.isArray(value)
		? true
		: false;
}

function isValueArray(value) {
	return Array.isArray(value) ? true : false;
}

function isInteger(value) {
	return /^[+-]?[0-9]+$/.test(value.toString());
}

function isHex(value) {
	if (value.toString().startsWith('0x')) {
		return /^[0-9a-fA-F]+$/.test(value.toString().substring(2));
	}

	return false;
}

function isFloat(value) {
	return !isNaN(parseFloat(value)) && value.toString().indexOf('.') >= 0;
}

function isNumber(value) {
	return isInteger(value) || isHex(value) || isFloat(value);
}

function isBoolean(val) {
	return val === false || val === true;
}

function deepArrayCopy(a) {
	var b = [];

	if (!isValueArray(a)) {
		return null;
	}

	for (var i = 0; i < a.length; i++) {
		var currentItem = a[i];

		if (isValueObject(currentItem)) {
			var newItem = {};

			deepAssign(newItem, currentItem);
			b.push(newItem);
		} else if (isValueArray(currentItem)) {
			b.push(deepArrayCopy(currentItem));
		} else {
			b.push(currentItem);
		}
	}

	return b;
}

function deepArrayOverlay(target) {
	for (var i = 1; i < arguments.length; i++) {
		var currentSource = arguments[i];

		if (isValueArray(currentSource)) {
			for (var j = 0; j < currentSource.length; j++) {
				if (j < target.length) {
					if (
						isValueArray(target[j]) &&
						isValueArray(currentSource[j])
					) {
						target[j] = deepArrayOverlay(
							target[j],
							currentSource[j],
						);
					} else if (
						isValueObject(target[j]) &&
						isValueObject(currentSource[j])
					) {
						target[j] = deepOverlay(target[j], currentSource[j]);
					} else {
						target[j] = deepOverlay(target[j], currentSource[j]);
					}
				} else {
					target[j] = currentSource[j];
				}
			}
		} else if (isValueObject(currentSource)) {
			for (var k in currentSource) {
				var j = null;

				try {
					j = parseInt(k);
				} catch (e) {
					return target;
				}

				if (j < target.length) {
					if (
						isValueArray(target[j]) &&
						isValueArray(currentSource[j])
					) {
						target[j] = deepArrayOverlay(
							target[j],
							currentSource[j],
						);
					} else if (
						isValueObject(target[j]) &&
						isValueObject(currentSource[j])
					) {
						target[j] = deepOverlay(target[j], currentSource[j]);
					} else {
						target[j] = deepOverlay(target[j], currentSource[j]);
					}
				} else {
					target[j] = currentSource[j];
				}
			}
		}
	}

	return target;
}

function deepOverlay(target) {
	for (var i = 1; i < arguments.length; i++) {
		var currentSource = arguments[i];

		for (var k in currentSource) {
			var currentItem = currentSource[k];

			if (isValueArray(target[k]) && isValueObject(currentItem)) {
				target[k] = deepArrayOverlay(target[k], currentItem);
			} else if (isValueObject(currentItem)) {
				if (!isValueObject(target[k])) {
					target[k] = {};
				}

				deepAssign(target[k], currentItem);
			} else if (isValueArray(currentItem)) {
				target[k] = deepArrayOverlay(target[k], currentItem);
			} else {
				target[k] = currentItem;
			}
		}
	}

	return target;
}

function deepAssign(target) {
	for (var i = 1; i < arguments.length; i++) {
		var currentSource = arguments[i];

		for (var k in currentSource) {
			var currentItem = currentSource[k];

			if (isValueObject(currentItem)) {
				if (!isValueObject(target[k])) {
					target[k] = {};
				}

				deepAssign(target[k], currentItem);
			} else if (isValueArray(currentItem)) {
				target[k] = deepArrayCopy(currentItem);
			} else {
				target[k] = currentItem;
			}
		}
	}

	return target;
}

function deepEqual(a, b) {
	if (isValueObject(a) && isValueObject(b)) {
		for (var k in a) {
			if (a.hasOwnProperty(k)) {
				if (!deepEqual(a[k], b[k])) {
					return false;
				}
			}
		}
	} else if (isValueArray(a) && isValueArray(b)) {
		if (a.length !== b.length) {
			return false;
		}

		for (var i = 0; i < a.length; i++) {
			if (a[i] !== b[i]) {
				return false;
			}
		}
	} else if (a !== b) {
		return false;
	}
	return true;
}

function UleInt16ToNumber(data) {
	if (data.length < 2) {
		return null;
	}

	var buf = new Uint8Array(2);

	for (var i = 0; i < 2; i++) {
		buf[i] = data[i];
	}

	var value = new Uint16Array(buf.buffer)[0];

	return value;
}

function UleInt32ToNumber(data) {
	if (data.length < 4) {
		return null;
	}

	var buf = new Uint8Array(4);

	for (var i = 0; i < 4; i++) {
		buf[i] = data[i];
	}

	var value = new Uint32Array(buf.buffer)[0];

	return value;
}

function numberToUleInt32(data) {
	data = parseInt(data);

	if (isNaN(data)) {
		return null;
	}

	if (data < 0) {
		return null;
	}

	var value = [0, 0, 0, 0];

	value[3] = (data >> 24) & 0xff;
	value[2] = (data >> 16) & 0xff;
	value[1] = (data >> 8) & 0xff;
	value[0] = (data >> 0) & 0xff;

	return value;
}

function numberToUleInt64(data) {
	data = parseInt(data);

	if (isNaN(data)) {
		return null;
	}

	if (data < 0) {
		return null;
	}

	var value = [0, 0, 0, 0, 0, 0, 0, 0];

	value[7] = (data >> 56) & 0xff;
	value[6] = (data >> 48) & 0xff;
	value[5] = (data >> 40) & 0xff;
	value[4] = (data >> 32) & 0xff;
	value[3] = (data >> 24) & 0xff;
	value[2] = (data >> 16) & 0xff;
	value[1] = (data >> 8) & 0xff;
	value[0] = (data >> 0) & 0xff;

	return value;
}

function generateUUID() {
	var d = new Date().getTime();
	var uuid = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
		c,
	) {
		var r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
	return uuid;
}

function uuidToArray(uuidString) {
	if (uuidString === null || uuidString === undefined) {
		return null;
	}

	uuidString = uuidString.replace(/-/g, '');

	var uuidArray = [];

	var currentPosition = 0;

	while (currentPosition < uuidString.length) {
		uuidArray.push(parseInt(uuidString.substr(currentPosition, 2), 16));

		currentPosition += 2;
	}

	return uuidArray;
}

function arrayToUuid(uuidArray) {
	if (uuidArray.length !== 16) {
		return null;
	}

	var uuidString = '';

	for (var i = 0; i < uuidArray.length; i++) {
		uuidString += ('00' + uuidArray[i].toString(16)).slice(-2);

		if (i === 3 || i === 5 || i === 7 || i === 9) {
			uuidString += '-';
		}
	}

	return uuidString;
}

function addToUuid(uuidString, value) {
	var uuidArray = uuidToArray(uuidString);

	if (uuidArray === null) {
		return null;
	}

	var newUuid = uuidArray.slice();

	if (uuidArray === null) {
		return null;
	}

	for (var i = uuidArray.length - 1; i >= 0; i--) {
		var currentValue = uuidArray[i] + value;

		newUuid[i] = currentValue % 256;

		if (newUuid[i] < 0) {
			newUuid[i] = newUuid[i] + 0x100;
		}

		value = Math.floor(currentValue / 256);

		if (value === 0) {
			break;
		}
	}

	if (value !== 0) {
		//OVERFLOW
	}

	return arrayToUuid(newUuid);
}

// Create a safe variable string for a jQuery selector\
function safeSelectorString(string) {
	return btoa(unescape(encodeURIComponent(string)))
		.replace(/=/g, '')
		.replace(/\//g, '-')
		.replace(/\+/g, '_');
}

function getIsoCountry(countryCode) {
	var isoCountries = {
		AF: 'Afghanistan',
		AX: 'Aland Islands',
		AL: 'Albania',
		DZ: 'Algeria',
		AS: 'American Samoa',
		AD: 'Andorra',
		AO: 'Angola',
		AI: 'Anguilla',
		AQ: 'Antarctica',
		AG: 'Antigua And Barbuda',
		AR: 'Argentina',
		AM: 'Armenia',
		AW: 'Aruba',
		AU: 'Australia',
		AT: 'Austria',
		AZ: 'Azerbaijan',
		BS: 'Bahamas',
		BH: 'Bahrain',
		BD: 'Bangladesh',
		BB: 'Barbados',
		BY: 'Belarus',
		BE: 'Belgium',
		BZ: 'Belize',
		BJ: 'Benin',
		BM: 'Bermuda',
		BT: 'Bhutan',
		BO: 'Bolivia',
		BA: 'Bosnia And Herzegovina',
		BW: 'Botswana',
		BV: 'Bouvet Island',
		BR: 'Brazil',
		IO: 'British Indian Ocean Territory',
		BN: 'Brunei Darussalam',
		BG: 'Bulgaria',
		BF: 'Burkina Faso',
		BI: 'Burundi',
		KH: 'Cambodia',
		CM: 'Cameroon',
		CA: 'Canada',
		CV: 'Cape Verde',
		KY: 'Cayman Islands',
		CF: 'Central African Republic',
		TD: 'Chad',
		CL: 'Chile',
		CN: 'China',
		CX: 'Christmas Island',
		CC: 'Cocos (Keeling) Islands',
		CO: 'Colombia',
		KM: 'Comoros',
		CG: 'Congo',
		CD: 'Congo, Democratic Republic',
		CK: 'Cook Islands',
		CR: 'Costa Rica',
		CI: "Cote D'Ivoire",
		HR: 'Croatia',
		CU: 'Cuba',
		CY: 'Cyprus',
		CZ: 'Czech Republic',
		DK: 'Denmark',
		DJ: 'Djibouti',
		DM: 'Dominica',
		DO: 'Dominican Republic',
		EC: 'Ecuador',
		EG: 'Egypt',
		SV: 'El Salvador',
		GQ: 'Equatorial Guinea',
		ER: 'Eritrea',
		EE: 'Estonia',
		ET: 'Ethiopia',
		FK: 'Falkland Islands (Malvinas)',
		FO: 'Faroe Islands',
		FJ: 'Fiji',
		FI: 'Finland',
		FR: 'France',
		GF: 'French Guiana',
		PF: 'French Polynesia',
		TF: 'French Southern Territories',
		GA: 'Gabon',
		GM: 'Gambia',
		GE: 'Georgia',
		DE: 'Germany',
		GH: 'Ghana',
		GI: 'Gibraltar',
		GR: 'Greece',
		GL: 'Greenland',
		GD: 'Grenada',
		GP: 'Guadeloupe',
		GU: 'Guam',
		GT: 'Guatemala',
		GG: 'Guernsey',
		GN: 'Guinea',
		GW: 'Guinea-Bissau',
		GY: 'Guyana',
		HT: 'Haiti',
		HM: 'Heard Island & Mcdonald Islands',
		VA: 'Holy See (Vatican City State)',
		HN: 'Honduras',
		HK: 'Hong Kong',
		HU: 'Hungary',
		IS: 'Iceland',
		IN: 'India',
		ID: 'Indonesia',
		IR: 'Iran, Islamic Republic Of',
		IQ: 'Iraq',
		IE: 'Ireland',
		IM: 'Isle Of Man',
		IL: 'Israel',
		IT: 'Italy',
		JM: 'Jamaica',
		JP: 'Japan',
		JE: 'Jersey',
		JO: 'Jordan',
		KZ: 'Kazakhstan',
		KE: 'Kenya',
		KI: 'Kiribati',
		KR: 'Korea',
		KW: 'Kuwait',
		KG: 'Kyrgyzstan',
		LA: "Lao People's Democratic Republic",
		LV: 'Latvia',
		LB: 'Lebanon',
		LS: 'Lesotho',
		LR: 'Liberia',
		LY: 'Libyan Arab Jamahiriya',
		LI: 'Liechtenstein',
		LT: 'Lithuania',
		LU: 'Luxembourg',
		MO: 'Macao',
		MK: 'Macedonia',
		MG: 'Madagascar',
		MW: 'Malawi',
		MY: 'Malaysia',
		MV: 'Maldives',
		ML: 'Mali',
		MT: 'Malta',
		MH: 'Marshall Islands',
		MQ: 'Martinique',
		MR: 'Mauritania',
		MU: 'Mauritius',
		YT: 'Mayotte',
		MX: 'Mexico',
		FM: 'Micronesia, Federated States Of',
		MD: 'Moldova',
		MC: 'Monaco',
		MN: 'Mongolia',
		ME: 'Montenegro',
		MS: 'Montserrat',
		MA: 'Morocco',
		MZ: 'Mozambique',
		MM: 'Myanmar',
		NA: 'Namibia',
		NR: 'Nauru',
		NP: 'Nepal',
		NL: 'Netherlands',
		AN: 'Netherlands Antilles',
		NC: 'New Caledonia',
		NZ: 'New Zealand',
		NI: 'Nicaragua',
		NE: 'Niger',
		NG: 'Nigeria',
		NU: 'Niue',
		NF: 'Norfolk Island',
		MP: 'Northern Mariana Islands',
		NO: 'Norway',
		OM: 'Oman',
		PK: 'Pakistan',
		PW: 'Palau',
		PS: 'Palestinian Territory, Occupied',
		PA: 'Panama',
		PG: 'Papua New Guinea',
		PY: 'Paraguay',
		PE: 'Peru',
		PH: 'Philippines',
		PN: 'Pitcairn',
		PL: 'Poland',
		PT: 'Portugal',
		PR: 'Puerto Rico',
		QA: 'Qatar',
		RE: 'Reunion',
		RO: 'Romania',
		RU: 'Russian Federation',
		RW: 'Rwanda',
		BL: 'Saint Barthelemy',
		SH: 'Saint Helena',
		KN: 'Saint Kitts And Nevis',
		LC: 'Saint Lucia',
		MF: 'Saint Martin',
		PM: 'Saint Pierre And Miquelon',
		VC: 'Saint Vincent And Grenadines',
		WS: 'Samoa',
		SM: 'San Marino',
		ST: 'Sao Tome And Principe',
		SA: 'Saudi Arabia',
		SN: 'Senegal',
		RS: 'Serbia',
		SC: 'Seychelles',
		SL: 'Sierra Leone',
		SG: 'Singapore',
		SK: 'Slovakia',
		SI: 'Slovenia',
		SB: 'Solomon Islands',
		SO: 'Somalia',
		ZA: 'South Africa',
		GS: 'South Georgia And Sandwich Isl.',
		ES: 'Spain',
		LK: 'Sri Lanka',
		SD: 'Sudan',
		SR: 'Suriname',
		SJ: 'Svalbard And Jan Mayen',
		SZ: 'Swaziland',
		SE: 'Sweden',
		CH: 'Switzerland',
		SY: 'Syrian Arab Republic',
		TW: 'Taiwan',
		TJ: 'Tajikistan',
		TZ: 'Tanzania',
		TH: 'Thailand',
		TL: 'Timor-Leste',
		TG: 'Togo',
		TK: 'Tokelau',
		TO: 'Tonga',
		TT: 'Trinidad And Tobago',
		TN: 'Tunisia',
		TR: 'Turkey',
		TM: 'Turkmenistan',
		TC: 'Turks And Caicos Islands',
		TV: 'Tuvalu',
		UG: 'Uganda',
		UA: 'Ukraine',
		AE: 'United Arab Emirates',
		GB: 'United Kingdom',
		US: 'United States',
		UM: 'United States Outlying Islands',
		UY: 'Uruguay',
		UZ: 'Uzbekistan',
		VU: 'Vanuatu',
		VE: 'Venezuela',
		VN: 'Viet Nam',
		VG: 'Virgin Islands, British',
		VI: 'Virgin Islands, U.S.',
		WF: 'Wallis And Futuna',
		EH: 'Western Sahara',
		YE: 'Yemen',
		ZM: 'Zambia',
		ZW: 'Zimbabwe',
	};

	if (countryCode === '_all') {
		return isoCountries;
	}

	return isoCountries[countryCode];
}

function getIsoUsaState(stateCode) {
	var isoUsaStates = {
		AL: 'Alabama',
		AK: 'Alaska',
		AS: 'American Samoa',
		AZ: 'Arizona',
		AR: 'Arkansas',
		CA: 'California',
		CO: 'Colorado',
		CT: 'Connecticut',
		DE: 'Delaware',
		DC: 'District Of Columbia',
		FM: 'Federated States Of Micronesia',
		FL: 'Florida',
		GA: 'Georgia',
		GU: 'Guam',
		HI: 'Hawaii',
		ID: 'Idaho',
		IL: 'Illinois',
		IN: 'Indiana',
		IA: 'Iowa',
		KS: 'Kansas',
		KY: 'Kentucky',
		LA: 'Louisiana',
		ME: 'Maine',
		MH: 'Marshall Islands',
		MD: 'Maryland',
		MA: 'Massachusetts',
		MI: 'Michigan',
		MN: 'Minnesota',
		MS: 'Mississippi',
		MO: 'Missouri',
		MT: 'Montana',
		NE: 'Nebraska',
		NV: 'Nevada',
		NH: 'New Hampshire',
		NJ: 'New Jersey',
		NM: 'New Mexico',
		NY: 'New York',
		NC: 'North Carolina',
		ND: 'North Dakota',
		MP: 'Northern Mariana Islands',
		OH: 'Ohio',
		OK: 'Oklahoma',
		OR: 'Oregon',
		PW: 'Palau',
		PA: 'Pennsylvania',
		PR: 'Puerto Rico',
		RI: 'Rhode Island',
		SC: 'South Carolina',
		SD: 'South Dakota',
		TN: 'Tennessee',
		TX: 'Texas',
		UT: 'Utah',
		VT: 'Vermont',
		VI: 'Virgin Islands',
		VA: 'Virginia',
		WA: 'Washington',
		WV: 'West Virginia',
		WI: 'Wisconsin',
		WY: 'Wyoming',
	};

	if (stateCode === '_all') {
		return isoUsaStates;
	}

	return isoUsaStates[stateCode];
}

function formatString(str) {
	str = arguments['0'];
	delete arguments['0'];
	var args = arguments;
	return str.replace(/{(\d+)}/g, function(match, number) {
		var shifterNumber = Number(number) + 1;

		return typeof args[shifterNumber] != 'undefined'
			? args[shifterNumber]
			: match;
	});
}

function saveCanvasScreenShot(filename, canvas) {
	var base64DataUrl = canvas.toDataURL();

	if (base64DataUrl.startsWith('data:image/png;base64')) {
		filename = filename + '.png';
	} else if (base64DataUrl.startsWith('data:image/jpeg;base64')) {
		filename = filename + '.jpg';
	}

	var link = document.createElement('a');
	link.setAttribute('href', base64DataUrl);
	link.setAttribute('download', filename.toString());
	link.setAttribute('target', '_system');
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

function saveObjectAsJSON(filename, data) {
	var jsonBuffer = JSON.stringify(data, 0, 2);

	// we are detecting if the browser is IE 10+
	if (
		navigator &&
		navigator.msSaveBlob &&
		typeof navigator.msSaveBlob === 'function'
	) {
		var blob = new Blob([jsonBuffer], {
			type: 'text/json;;',
		});
		navigator.msSaveBlob(blob, filename.toString());
	} else {
		// Non IE Browser has been detected

		var jsonHeader = 'data:text/json;,';
		var encodedUri = jsonHeader + encodeURIComponent(jsonBuffer);

		var link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', filename.toString());
		link.setAttribute('target', '_system');
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
}

function saveObjectAsCSV(filename, objectList) {
	if (objectList === null || objectList === undefined) {
		return;
	}

	var allKeys = [];
	var i, j;

	for (i = 0; i < objectList.length; i++) {
		var newKeys = Object.keys(objectList[i]);

		for (j = 0; j < newKeys.length; j++) {
			if (allKeys.indexOf(newKeys[j]) === -1) {
				allKeys.push(newKeys[j]);
			}
		}
	}

	allKeys.sort();
	
	var data = [allKeys];

	for (i = 0; i < objectList.length; i++) {
		var currentLine = [];

		for (j = 0; j < allKeys.length; j++) {
			if (objectList[i][allKeys[j]] !== undefined) {
				currentLine[j] = JSON.stringify(objectList[i][allKeys[j]]);
			} else {
				currentLine[j] = '';
			}
		}

		data.push(currentLine);
	}

	var csvBuffer = '';

	data.forEach(function(infoArray, index) {
		dataString = infoArray.join(',');
		csvBuffer += index < data.length ? dataString + '\n' : dataString;
	});

	// we are detecting if the browser is IE 10+
	if (
		navigator &&
		navigator.msSaveBlob &&
		typeof navigator.msSaveBlob === 'function'
	) {
		var blob = new Blob([csvBuffer], {
			type: 'text/csv;charset=utf-8;',
		});
		navigator.msSaveBlob(blob, filename.toString() + '.csv');
	} else {
		// Non IE Browser has been detected

		var csvHeader = 'data:text/csv;charset=utf-8,';
		csvBuffer = csvHeader + csvBuffer;

		var encodedUri = encodeURI(csvBuffer);
		var link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('target', '_system');
		link.setAttribute('download', filename.toString() + '.csv');
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
}

function saveBlob(filename, blob) {
	var link = document.createElement('a');
	var blobUrl = URL.createObjectURL(blob);

	document.body.appendChild(link);
	link.href = blobUrl;
	link.download = filename.toString();

	if (isPhonegap()) {
		link.target = '_system';
	}

	link.click();
	document.body.removeChild(link);
}

function saveBinaryBuffer(filename, buffer) {
	var binaryArray = new Uint8Array(buffer.length);

	for (var i = 0; i < buffer.length; i++) {
		binaryArray[i] = buffer[i];
	}

	var blob = new Blob([binaryArray], { type: 'application/octet-stream' });
	saveBlob(filename, blob);
}

function saveURL(filename, url) {
	// we are detecting if the browser is IE 10+
	if (
		navigator &&
		navigator.msSaveBlob &&
		typeof navigator.msSaveBlob === 'function' &&
		URL.createObjectURL === undefined
	) {
		// 		var blob = new Blob([csvBuffer], {
		// 			type: "text/csv;charset=utf-8;"
		// 		});
		// 		navigator.msSaveBlob(blob, filename.toString() + ".csv");
	} else {
		// Non IE Browser has been detected

		fetch(url)
			.then((res) => res.blob())
			.then(function(blob) {
				saveBlob(filename, blob);
			});
	}
}

function chunkArray(myArray, chunk_size) {
	var index = 0;
	var arrayLength = myArray.length;
	var tempArray = [];

	for (index = 0; index < arrayLength; index += chunk_size) {
		myChunk = myArray.slice(index, index + chunk_size);
		// Do something if you want with the group
		tempArray.push(myChunk);
	}

	return tempArray;
}

function removeDuplicatesFromObjectArrayByKey(originalArray, objKey) {
	var trimmedArray = [];
	var values = [];
	var value;

	for (var i = 0; i < originalArray.length; i++) {
		value = originalArray[i][objKey];

		if (values.indexOf(value) === -1) {
			trimmedArray.push(originalArray[i]);
			values.push(value);
		}
	}

	return trimmedArray;
}

/*
Returns true if the String is valid.
1. Undergoes truthy evaluation first.
2. Checks for datatype of string.
3. Trims unecessary surrounding spaces from the string.
4. Checks if the string is empty after the trim
5. If the string passes all these checks, it is valid, boolean true is returned.
*/
function validateString(inputString) {
	if (inputString) {
		if (typeof inputString === 'string') {
			inputString = inputString.trim();
			if (inputString === '') {
				return false; //empty string fail
			} else {
				return true; //valid string
			}
		} else {
			return false; //not of type string
		}
	} else {
		return false; //fails to satisfy JavaScript Truthy evaluation
	}
}

//return an array of values that match a provided key
function getValues(obj, key) {
	var objects = [];
	for (var i in obj) {
		if (!obj.hasOwnProperty(i)) continue;
		if (typeof obj[i] == 'object') {
			objects = objects.concat(getValues(obj[i], key));
		} else if (i == key) {
			objects.push(obj[i]);
		}
	}
	return objects;
}

//return an array of keys that match on a certain value
function getKeys(obj, val) {
	var objects = [];
	for (var i in obj) {
		if (!obj.hasOwnProperty(i)) continue;
		if (typeof obj[i] == 'object') {
			objects = objects.concat(getKeys(obj[i], val));
		} else if (obj[i] == val) {
			objects.push(i);
		}
	}
	return objects;
}

function getReadableByteSize(bytes, si) {
	var thresh = si ? 1000 : 1024;
	if (Math.abs(bytes) < thresh) {
		return bytes + ' B';
	}
	var units = si
		? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
	var u = -1;
	do {
		bytes /= thresh;
		++u;
	} while (Math.abs(bytes) >= thresh && u < units.length - 1);
	return bytes.toFixed(1) + ' ' + units[u];
}

/*
 * A JavaScript implementation of the RSA Data Security, Inc. MD5 Message
 * Digest Algorithm, as defined in RFC 1321.
 * Version 2.2 Copyright (C) Paul Johnston 1999 - 2009
 * Other contributors: Greg Holt, Andrew Kepert, Ydnar, Lostinet
 * Distributed under the BSD License
 * See http://pajhome.org.uk/crypt/md5 for more info.
 */

/*
 * Configurable variables. You may need to tweak these to be compatible with
 * the server-side, but the defaults work in most cases.
 */
var hexcase = 0; /* hex output format. 0 - lowercase; 1 - uppercase        */
var b64pad = ''; /* base-64 pad character. "=" for strict RFC compliance   */

/*
 * These are the functions you'll usually want to call
 * They take string arguments and return either hex or base-64 encoded strings
 */
function hex_md5(s) {
	return rstr2hex(rstr_md5(str2rstr_utf8(s)));
}
function b64_md5(s) {
	return rstr2b64(rstr_md5(str2rstr_utf8(s)));
}
function any_md5(s, e) {
	return rstr2any(rstr_md5(str2rstr_utf8(s)), e);
}
function hex_hmac_md5(k, d) {
	return rstr2hex(rstr_hmac_md5(str2rstr_utf8(k), str2rstr_utf8(d)));
}
function b64_hmac_md5(k, d) {
	return rstr2b64(rstr_hmac_md5(str2rstr_utf8(k), str2rstr_utf8(d)));
}
function any_hmac_md5(k, d, e) {
	return rstr2any(rstr_hmac_md5(str2rstr_utf8(k), str2rstr_utf8(d)), e);
}

/*
 * Perform a simple self-test to see if the VM is working
 */
function md5_vm_test() {
	return hex_md5('abc').toLowerCase() == '900150983cd24fb0d6963f7d28e17f72';
}

/*
 * Calculate the MD5 of a raw string
 */
function rstr_md5(s) {
	return binl2rstr(binl_md5(rstr2binl(s), s.length * 8));
}

/*
 * Calculate the HMAC-MD5, of a key and some data (raw strings)
 */
function rstr_hmac_md5(key, data) {
	var bkey = rstr2binl(key);
	if (bkey.length > 16) bkey = binl_md5(bkey, key.length * 8);

	var ipad = Array(16),
		opad = Array(16);
	for (var i = 0; i < 16; i++) {
		ipad[i] = bkey[i] ^ 0x36363636;
		opad[i] = bkey[i] ^ 0x5c5c5c5c;
	}

	var hash = binl_md5(ipad.concat(rstr2binl(data)), 512 + data.length * 8);
	return binl2rstr(binl_md5(opad.concat(hash), 512 + 128));
}

/*
 * Convert a raw string to a hex string
 */
function rstr2hex(input) {
	try {
		hexcase;
	} catch (e) {
		hexcase = 0;
	}
	var hex_tab = hexcase ? '0123456789ABCDEF' : '0123456789abcdef';
	var output = '';
	var x;
	for (var i = 0; i < input.length; i++) {
		x = input.charCodeAt(i);
		output += hex_tab.charAt((x >>> 4) & 0x0f) + hex_tab.charAt(x & 0x0f);
	}
	return output;
}

/*
 * Convert a raw string to a base-64 string
 */
function rstr2b64(input) {
	try {
		b64pad;
	} catch (e) {
		b64pad = '';
	}
	var tab =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
	var output = '';
	var len = input.length;
	for (var i = 0; i < len; i += 3) {
		var triplet =
			(input.charCodeAt(i) << 16) |
			(i + 1 < len ? input.charCodeAt(i + 1) << 8 : 0) |
			(i + 2 < len ? input.charCodeAt(i + 2) : 0);
		for (var j = 0; j < 4; j++) {
			if (i * 8 + j * 6 > input.length * 8) output += b64pad;
			else output += tab.charAt((triplet >>> (6 * (3 - j))) & 0x3f);
		}
	}
	return output;
}

/*
 * Convert a raw string to an arbitrary string encoding
 */
function rstr2any(input, encoding) {
	var divisor = encoding.length;
	var i, j, q, x, quotient;

	/* Convert to an array of 16-bit big-endian values, forming the dividend */
	var dividend = Array(Math.ceil(input.length / 2));
	for (i = 0; i < dividend.length; i++) {
		dividend[i] =
			(input.charCodeAt(i * 2) << 8) | input.charCodeAt(i * 2 + 1);
	}

	/*
	 * Repeatedly perform a long division. The binary array forms the dividend,
	 * the length of the encoding is the divisor. Once computed, the quotient
	 * forms the dividend for the next step. All remainders are stored for later
	 * use.
	 */
	var full_length = Math.ceil(
		(input.length * 8) / (Math.log(encoding.length) / Math.log(2)),
	);
	var remainders = Array(full_length);
	for (j = 0; j < full_length; j++) {
		quotient = Array();
		x = 0;
		for (i = 0; i < dividend.length; i++) {
			x = (x << 16) + dividend[i];
			q = Math.floor(x / divisor);
			x -= q * divisor;
			if (quotient.length > 0 || q > 0) quotient[quotient.length] = q;
		}
		remainders[j] = x;
		dividend = quotient;
	}

	/* Convert the remainders to the output string */
	var output = '';
	for (i = remainders.length - 1; i >= 0; i--)
		output += encoding.charAt(remainders[i]);

	return output;
}

/*
 * Encode a string as utf-8.
 * For efficiency, this assumes the input is valid utf-16.
 */
function str2rstr_utf8(input) {
	var output = '';
	var i = -1;
	var x, y;

	while (++i < input.length) {
		/* Decode utf-16 surrogate pairs */
		x = input.charCodeAt(i);
		y = i + 1 < input.length ? input.charCodeAt(i + 1) : 0;
		if (0xd800 <= x && x <= 0xdbff && 0xdc00 <= y && y <= 0xdfff) {
			x = 0x10000 + ((x & 0x03ff) << 10) + (y & 0x03ff);
			i++;
		}

		/* Encode output as utf-8 */
		if (x <= 0x7f) output += String.fromCharCode(x);
		else if (x <= 0x7ff)
			output += String.fromCharCode(
				0xc0 | ((x >>> 6) & 0x1f),
				0x80 | (x & 0x3f),
			);
		else if (x <= 0xffff)
			output += String.fromCharCode(
				0xe0 | ((x >>> 12) & 0x0f),
				0x80 | ((x >>> 6) & 0x3f),
				0x80 | (x & 0x3f),
			);
		else if (x <= 0x1fffff)
			output += String.fromCharCode(
				0xf0 | ((x >>> 18) & 0x07),
				0x80 | ((x >>> 12) & 0x3f),
				0x80 | ((x >>> 6) & 0x3f),
				0x80 | (x & 0x3f),
			);
	}
	return output;
}

/*
 * Encode a string as utf-16
 */
function str2rstr_utf16le(input) {
	var output = '';
	for (var i = 0; i < input.length; i++)
		output += String.fromCharCode(
			input.charCodeAt(i) & 0xff,
			(input.charCodeAt(i) >>> 8) & 0xff,
		);
	return output;
}

function str2rstr_utf16be(input) {
	var output = '';
	for (var i = 0; i < input.length; i++)
		output += String.fromCharCode(
			(input.charCodeAt(i) >>> 8) & 0xff,
			input.charCodeAt(i) & 0xff,
		);
	return output;
}

/*
 * Convert a raw string to an array of little-endian words
 * Characters >255 have their high-byte silently ignored.
 */
function rstr2binl(input) {
	var output = Array(input.length >> 2);
	for (var i = 0; i < output.length; i++) output[i] = 0;
	for (var i = 0; i < input.length * 8; i += 8)
		output[i >> 5] |= (input.charCodeAt(i / 8) & 0xff) << i % 32;
	return output;
}

/*
 * Convert an array of little-endian words to a string
 */
function binl2rstr(input) {
	var output = '';
	for (var i = 0; i < input.length * 32; i += 8)
		output += String.fromCharCode((input[i >> 5] >>> i % 32) & 0xff);
	return output;
}

/*
 * Calculate the MD5 of an array of little-endian words, and a bit length.
 */
function binl_md5(x, len) {
	/* append padding */
	x[len >> 5] |= 0x80 << len % 32;
	x[(((len + 64) >>> 9) << 4) + 14] = len;

	var a = 1732584193;
	var b = -271733879;
	var c = -1732584194;
	var d = 271733878;

	for (var i = 0; i < x.length; i += 16) {
		var olda = a;
		var oldb = b;
		var oldc = c;
		var oldd = d;

		a = md5_ff(a, b, c, d, x[i + 0], 7, -680876936);
		d = md5_ff(d, a, b, c, x[i + 1], 12, -389564586);
		c = md5_ff(c, d, a, b, x[i + 2], 17, 606105819);
		b = md5_ff(b, c, d, a, x[i + 3], 22, -1044525330);
		a = md5_ff(a, b, c, d, x[i + 4], 7, -176418897);
		d = md5_ff(d, a, b, c, x[i + 5], 12, 1200080426);
		c = md5_ff(c, d, a, b, x[i + 6], 17, -1473231341);
		b = md5_ff(b, c, d, a, x[i + 7], 22, -45705983);
		a = md5_ff(a, b, c, d, x[i + 8], 7, 1770035416);
		d = md5_ff(d, a, b, c, x[i + 9], 12, -1958414417);
		c = md5_ff(c, d, a, b, x[i + 10], 17, -42063);
		b = md5_ff(b, c, d, a, x[i + 11], 22, -1990404162);
		a = md5_ff(a, b, c, d, x[i + 12], 7, 1804603682);
		d = md5_ff(d, a, b, c, x[i + 13], 12, -40341101);
		c = md5_ff(c, d, a, b, x[i + 14], 17, -1502002290);
		b = md5_ff(b, c, d, a, x[i + 15], 22, 1236535329);

		a = md5_gg(a, b, c, d, x[i + 1], 5, -165796510);
		d = md5_gg(d, a, b, c, x[i + 6], 9, -1069501632);
		c = md5_gg(c, d, a, b, x[i + 11], 14, 643717713);
		b = md5_gg(b, c, d, a, x[i + 0], 20, -373897302);
		a = md5_gg(a, b, c, d, x[i + 5], 5, -701558691);
		d = md5_gg(d, a, b, c, x[i + 10], 9, 38016083);
		c = md5_gg(c, d, a, b, x[i + 15], 14, -660478335);
		b = md5_gg(b, c, d, a, x[i + 4], 20, -405537848);
		a = md5_gg(a, b, c, d, x[i + 9], 5, 568446438);
		d = md5_gg(d, a, b, c, x[i + 14], 9, -1019803690);
		c = md5_gg(c, d, a, b, x[i + 3], 14, -187363961);
		b = md5_gg(b, c, d, a, x[i + 8], 20, 1163531501);
		a = md5_gg(a, b, c, d, x[i + 13], 5, -1444681467);
		d = md5_gg(d, a, b, c, x[i + 2], 9, -51403784);
		c = md5_gg(c, d, a, b, x[i + 7], 14, 1735328473);
		b = md5_gg(b, c, d, a, x[i + 12], 20, -1926607734);

		a = md5_hh(a, b, c, d, x[i + 5], 4, -378558);
		d = md5_hh(d, a, b, c, x[i + 8], 11, -2022574463);
		c = md5_hh(c, d, a, b, x[i + 11], 16, 1839030562);
		b = md5_hh(b, c, d, a, x[i + 14], 23, -35309556);
		a = md5_hh(a, b, c, d, x[i + 1], 4, -1530992060);
		d = md5_hh(d, a, b, c, x[i + 4], 11, 1272893353);
		c = md5_hh(c, d, a, b, x[i + 7], 16, -155497632);
		b = md5_hh(b, c, d, a, x[i + 10], 23, -1094730640);
		a = md5_hh(a, b, c, d, x[i + 13], 4, 681279174);
		d = md5_hh(d, a, b, c, x[i + 0], 11, -358537222);
		c = md5_hh(c, d, a, b, x[i + 3], 16, -722521979);
		b = md5_hh(b, c, d, a, x[i + 6], 23, 76029189);
		a = md5_hh(a, b, c, d, x[i + 9], 4, -640364487);
		d = md5_hh(d, a, b, c, x[i + 12], 11, -421815835);
		c = md5_hh(c, d, a, b, x[i + 15], 16, 530742520);
		b = md5_hh(b, c, d, a, x[i + 2], 23, -995338651);

		a = md5_ii(a, b, c, d, x[i + 0], 6, -198630844);
		d = md5_ii(d, a, b, c, x[i + 7], 10, 1126891415);
		c = md5_ii(c, d, a, b, x[i + 14], 15, -1416354905);
		b = md5_ii(b, c, d, a, x[i + 5], 21, -57434055);
		a = md5_ii(a, b, c, d, x[i + 12], 6, 1700485571);
		d = md5_ii(d, a, b, c, x[i + 3], 10, -1894986606);
		c = md5_ii(c, d, a, b, x[i + 10], 15, -1051523);
		b = md5_ii(b, c, d, a, x[i + 1], 21, -2054922799);
		a = md5_ii(a, b, c, d, x[i + 8], 6, 1873313359);
		d = md5_ii(d, a, b, c, x[i + 15], 10, -30611744);
		c = md5_ii(c, d, a, b, x[i + 6], 15, -1560198380);
		b = md5_ii(b, c, d, a, x[i + 13], 21, 1309151649);
		a = md5_ii(a, b, c, d, x[i + 4], 6, -145523070);
		d = md5_ii(d, a, b, c, x[i + 11], 10, -1120210379);
		c = md5_ii(c, d, a, b, x[i + 2], 15, 718787259);
		b = md5_ii(b, c, d, a, x[i + 9], 21, -343485551);

		a = safe_add(a, olda);
		b = safe_add(b, oldb);
		c = safe_add(c, oldc);
		d = safe_add(d, oldd);
	}
	return Array(a, b, c, d);
}

/*
 * These functions implement the four basic operations the algorithm uses.
 */
function md5_cmn(q, a, b, x, s, t) {
	return safe_add(bit_rol(safe_add(safe_add(a, q), safe_add(x, t)), s), b);
}
function md5_ff(a, b, c, d, x, s, t) {
	return md5_cmn((b & c) | (~b & d), a, b, x, s, t);
}
function md5_gg(a, b, c, d, x, s, t) {
	return md5_cmn((b & d) | (c & ~d), a, b, x, s, t);
}
function md5_hh(a, b, c, d, x, s, t) {
	return md5_cmn(b ^ c ^ d, a, b, x, s, t);
}
function md5_ii(a, b, c, d, x, s, t) {
	return md5_cmn(c ^ (b | ~d), a, b, x, s, t);
}

/*
 * Add integers, wrapping at 2^32. This uses 16-bit operations internally
 * to work around bugs in some JS interpreters.
 */
function safe_add(x, y) {
	var lsw = (x & 0xffff) + (y & 0xffff);
	var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
	return (msw << 16) | (lsw & 0xffff);
}

/*
 * Bitwise rotate a 32-bit number to the left.
 */
function bit_rol(num, cnt) {
	return (num << cnt) | (num >>> (32 - cnt));
}

function generateB64ImageFromSource(
	imgSrc,
	imageType,
	width,
	height,
	callback,
) {
	var currentWidget = this;

	var img = document.createElement('img');
	var canvas = document.createElement('canvas');

	imageType = imageType || 'image/png';

	img.onload = function() {
		if (img.height > height) {
			canvas.height = height;
			canvas.width = (width * img.width) / img.height;
		} else {
			canvas.height = img.height;
			canvas.width = img.width;
		}

		canvas
			.getContext('2d')
			.drawImage(img, 0, 0, canvas.width, canvas.height);

		var imageData = canvas.toDataURL('image/png');
		callback.call(currentWidget, false, imageData);
	};

	img.src = imgSrc;
}

function checkForFileType(fileName, exts) {
	return new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$').test(
		fileName.toLowerCase(),
	);
}

function isBrowserInternetExplorer() {
	var isIE =
		navigator.userAgent.indexOf('MSIE') !== -1 ||
		navigator.appVersion.indexOf('Trident/') > 0
			? true
			: false;
	return isIE;
}

function openLinkInNewWindowOrTab(href) {
	
	if(isPhonegap()) {
		cordova.InAppBrowser.open(href, '_system', 'hidden=yes,location=no')
	}
	
	else {
		window.open(href, '_system');
	}
}

function openLinkInCurrentWindowOrTab(href) {
	window.open(href, '_self');
}

function getErrorObject() {
	try {
		throw Error('');
	} catch (err) {
		return err;
	}
}

function addHandleBarHelpers() {
	if (Handlebars) {
		Handlebars.registerHelper('if_eq', function(a, b, opts) {
			if (a && a.constructor === Number) {
				a = parseInt(a);
			}

			if (b && b.constructor === Number) {
				b = parseInt(b);
			}

			if (a === b) return opts.fn(this);
			else return opts.inverse(this);
		});
		Handlebars.registerHelper('if_not', function(a, opts) {
			if (!a) return opts.fn(this);
			else return opts.inverse(this);
		});
		Handlebars.registerHelper('if_or', function(a, b, opts) {
			if (a || b) return opts.fn(this);
			else return opts.inverse(this);
		});
		Handlebars.registerHelper('if_and', function(a, b, opts) {
			if (a && b) return opts.fn(this);
			else return opts.inverse(this);
		});
		Handlebars.registerHelper('if_neq', function(a, b, opts) {
			if (a !== b) return opts.fn(this);
			else return opts.inverse(this);
		});
		Handlebars.registerHelper('if_gt', function(a, b, opts) {
			if (a > b) return opts.fn(this);
			else return opts.inverse(this);
		});
		Handlebars.registerHelper('if_gte', function(a, b, opts) {
			if (a >= b) return opts.fn(this);
			else return opts.inverse(this);
		});
		Handlebars.registerHelper('if_lt', function(a, b, opts) {
			if (a < b) return opts.fn(this);
			else return opts.inverse(this);
		});
		Handlebars.registerHelper('if_lte', function(a, b, opts) {
			if (a <= b) return opts.fn(this);
			else return opts.inverse(this);
		});

		Handlebars.registerHelper('switch', function(value, options) {
			this._switch_value_ = value;
			var html = options.fn(this);
			delete this._switch_value_;
			return html;
		});

		Handlebars.registerHelper('case', function() {
			var args = Array.prototype.slice.call(arguments);

			var options = args.pop();
			var caseValues = args;

			if (caseValues.indexOf(this._switch_value_) === -1) {
				return '';
			} else {
				return options.fn(this);
			}
		});

		Handlebars.registerHelper('select', function(value, options) {
			return options
				.fn(this)
				.split('\n')
				.map(function(v) {
					var t = 'value="' + value + '"';
					return !RegExp(t).test(v)
						? v
						: v.replace(t, t + ' selected="selected"');
				})
				.join('\n');
		});

		Handlebars.registerHelper('withItem', function(object, options) {
			var objectOut = object[options.hash.key];

			if (objectOut !== undefined) {
				return options.fn(objectOut);
			} else {
				return options.inverse(null);
			}
		});
		Handlebars.registerHelper('times', function(n, block) {
			var oldIndex = block.data.index;
			var oldFirst = block.data.first;
			var oldLast = block.data.last;

			var accum = '';
			for (var i = 0; i < n; ++i) {
				block.data.index = i;
				block.data.first = i === 0;
				block.data.last = i === n - 1;
				accum += block.fn(this);
			}

			block.data.index = oldIndex;
			block.data.first = oldFirst;
			block.data.last = oldLast;

			return accum;
		});
		Handlebars.registerHelper('json', function(context) {
			return JSON.stringify(context);
		});
	}
}

function isURL(str) {
	var pattern = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$',
		'i',
	); // fragment locator
	return pattern.test(str);
}

function $_$(done) {
	var windowKeys = Object.keys(window);

	var testableEntries = [];

	for (var i = 0; i < windowKeys.length; i++) {
		var currentObject = window[windowKeys[i]];

		if (
			currentObject &&
			currentObject.prototype &&
			currentObject.prototype.$_$ &&
			typeof currentObject.prototype.$_$ === 'function'
		) {
			testableEntries.push(windowKeys[i]);
		}
	}

	var errors = false;

	function _$_$_Helper() {
		if (testableEntries.length <= 0) {
			done(errors);
			return;
		}

		var testDone = false;

		var currentEntryName = testableEntries.shift();

		var timeout = setTimeout(function() {
			errors = errors || [];

			errors.push({
				name: currentEntryName,
				err: { type: 'timedout' },
			});

			if (!testDone) {
				testDone = true;
				_$_$_Helper();
			}
		}, 30000);

		try {
			window[currentEntryName].prototype.$_$(function(err) {
				if (err) {
					errors = errors || [];

					errors.push({
						name: currentEntryName,
						err: err,
					});
				}

				if (!testDone) {
					clearTimeout(timeout);
					testDone = true;
					_$_$_Helper();
				}
			});
		} catch (err) {
			errors = errors || [];
			errors.push({
				name: currentEntryName,
				err: err,
			});

			if (!testDone) {
				clearTimeout(timeout);
				testDone = true;
				_$_$_Helper();
			}
		}
	}

	_$_$_Helper();
}
