function WidgetPopupMessage(id, api, parentWidget, options) {
	WidgetBase.call(this, id, api, parentWidget, options);

	this._topContainerId = this.generateChildId('top');
	this._closeButtonId = this.generateChildId('closeButton');
	this._hideTimeout = null;

	this.renderTemplate(
		{
			topContainerId: this._topContainerId,
			closeButtonId: this._closeButtonId,
			color: 'gray',
			label: '',
		},
		WidgetPopupMessage.name,
	);

	this._topContainer = $(`#${this._topContainerId}`);
	this._closeButton = $(`#${this._closeButtonId}`);
}

WidgetPopupMessage.prototype = Object.create(WidgetBase.prototype);
WidgetPopupMessage.prototype.constructor = WidgetPopupMessage;

WidgetPopupMessage.prototype.showMessage = function(
	message,
	color,
	timeout,
	callback,
) {
	/*
	 * This will popup the message that you have entered
	 * in the message string and also use the class style
	 * for the particular color selected. The timeout
	 * indicates how long you would like the message to
	 * stay up, setting to 0, null, false will make it
	 * so that the message stays up until it's closed
	 * by the user when the press the close button.
	 *
	 * The callback function will be fired off once the
	 * message has timedout, or the user manually dismisses
	 * it.
	 *
	 * Example:
	 *
	 * this.showMessage('This is a test <a href="https://google.com">CLICK ME</a>', 'orange', 5000, function(err) {
	 * 	console.log('Message is now hidden')
	 * });
	 */

	var currentWidget = this;

	callback = callback || function() {};
	timeout = timeout || 0;

	if (this._hideTimeout !== null) {
		clearTimeout(this._hideTimeout);
		this._hideTimeout = null;
	}

	this.renderTemplate(
		{
			topContainerId: this._topContainerId,
			closeButtonId: this._closeButtonId,
			color: color,
			label: message,
		},
		WidgetPopupMessage.name,
	);

	this._topContainer = $(`#${this._topContainerId}`);
	this._closeButton = $(`#${this._closeButtonId}`);

	this.hide();

	this._closeButton.on('click', function() {
		currentWidget.hide();
	});

	setTimeout(function() {
		currentWidget.show();
	}, 30);

	if (timeout !== 0) {
		this._hideTimeout = setTimeout(function() {
			currentWidget.hide();
			callback.call(currentWidget, false);
		}, timeout);
	}
};

WidgetPopupMessage.prototype.show = function() {
	this._topContainer.addClass('WidgetPopupMessage-Visible');
};

WidgetPopupMessage.prototype.hide = function() {
	this._topContainer.removeClass('WidgetPopupMessage-Visible');
};

WidgetPopupMessage.prototype.isVisible = function() {
	return this._topContainer.hasClass('WidgetPopupMessage-Visible');
};

WidgetPopupMessage.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			close: 'Close',
		},
	},
);
