function BLECentralBackendBase() {
	/*
	 * This is the base backend for communicating over BLE. All data is expected to be an array
	 * of values where each entry in the array represents a raw 8-bit value that is to be
	 * assembled into a buffer into it "real" form.
	 *
	 * methods:
	 *
	 * events:
	 *	"advertisement": Can have following, with some values being optional
	 *		{
	 *			"address":"84683e0570b9",
	 * 			"mac":"84:68:3e:05:70:b9", //optional
	 * 			"connectable":true,
	 * 			"rssi":-63,
	 * 			"localName":"sLight",
	 * 			"primaryServiceUuid":"300692ab-0b5e-410b-8606-ed3a69d9f9c1",
	 * 			"serviceUuids":["300692ab-0b5e-410b-8606-ed3a69d9f9c1"]
	 *		}
	 *
	 * 	"error"
	 * 	"enabled"
	 * 	"disabled"
	 * 	"scanningStarted"
	 * 	"scanningStopped"
	 * 	"connected"
	 * 	"connectFailed"
	 * 	"discovered"
	 * 	"discoverFailed"
	 * 	"disconnected"
	 * 	"disconnectFailed"
	 *
	 */
	EventListener.call(this);

	this._plugins = {};

	this.loadPlugins(function(err) {});
}

BLECentralBackendBase.prototype = Object.create(EventListener.prototype);
BLECentralBackendBase.prototype.constructor = BLECentralBackendBase;

BLECentralBackendBase.prototype.IS_AVAILABLE = function() {
	return false;
};

BLECentralBackendBase.prototype.addPlugin = function(
	pluginConstructor,
	callback,
) {
	if (typeof pluginConstructor !== 'function') {
		callback.call(this, { type: 'invalidPluginConstructor' }, null);
		return;
	}

	if (this._plugins[pluginConstructor.name] !== undefined) {
		callback.call(this, { type: 'pluginAlreadyAdded' }, null);
		return;
	}

	var newPlugin = new pluginConstructor(this);
	this._plugins[pluginConstructor.name] = newPlugin;

	callback.call(this, false, newPlugin);
	return newPlugin;
};

BLECentralBackendBase.prototype.loadPlugins = function(callback) {
	var currentBackend = this;
	var bleCentralPlugins = [];
	var windowKeys = Object.keys(window);

	for (i = 0; i < windowKeys.length; i++) {
		if (windowKeys[i].indexOf('BLECentralPlugin') === 0) {
			if (typeof window[windowKeys[i]] === 'function') {
				bleCentralPlugins.push(window[windowKeys[i]]);
			}
		}
	}

	var errors = false;

	function _loadPluginsHelper() {
		if (bleCentralPlugins.length <= 0) {
			callback.call(currentBackend, errors);
			return;
		}

		var currentPlugin = bleCentralPlugins.shift();

		currentBackend.addPlugin(currentPlugin, function(err) {
			if (err) {
				errors = errors || [];
				errors.push(err);
			}

			_loadPluginsHelper();
			return;
		});
	}

	_loadPluginsHelper();
	return;
};

BLECentralBackendBase.prototype.read = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	callback,
) {
	callback.call(this, { type: 'bleFunctionNotSupported' }, null);
};

BLECentralBackendBase.prototype.write = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	rawValue,
	callback,
) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendBase.prototype.writeWithoutResponse = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	rawValue,
	callback,
) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendBase.prototype.subscribe = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	onDataCallback,
	callback,
) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendBase.prototype.unsubscribe = function(
	address,
	bleServiceUuid,
	bleCharacteristicUuid,
	callback,
) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendBase.prototype.isScanning = function(callback) {
	callback.call(this, { type: 'bleFunctionNotSupported' }, false);
};

BLECentralBackendBase.prototype.startScanning = function(callback) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendBase.prototype.stopScanning = function(callback) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendBase.prototype.getDiscovered = function(callback) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendBase.prototype.getServices = function(address, callback) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};

BLECentralBackendBase.prototype.getServiceCharacteristics = function(
	address,
	serviceUuid,
	callback,
) {
	callback.call(this, { type: 'bleFunctionNotSupported' });
};
