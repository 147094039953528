function WidgetAdminNotificationSender(id, api, parent, options) {
	options = options || {};

	options.confirmLabel = this.getLanguageTag('send');

	WidgetSettingsForm.call(this, id, api, parent, options);
}

WidgetAdminNotificationSender.prototype = Object.create(
	WidgetSettingsForm.prototype,
);
WidgetAdminNotificationSender.prototype.constructor = WidgetAdminNotificationSender;

WidgetAdminNotificationSender.prototype.initialize = function(callback) {
	WidgetSettingsForm.prototype.initialize.call(this, function(err) {
		if (err) {
			callback.call(this, err);
		}

		this.setForm([
			{
				name: 'message',
				type: 'text',
				label: 'Message',
				value: '',
			},
		]);

		callback.call(this, false);
	});
};

WidgetAdminNotificationSender.prototype.language = deepAssign(
	{},
	WidgetPanelBase.prototype.language,
	{
		'en-US': {
			name: 'Send Notification',
			send: 'Send',
		},
	},
);
