function ElementControllerAppUIToggle(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerAppUIBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('value', null, false, 'checkbox', null, null);

	this.addAbility('setValue', [{ name: 'value', validator: null }]);
	this.addAbility('toggle', []);

	this.addTrigger('changed');
	this.addTrigger('checked');
	this.addTrigger('unchecked');
}

ElementControllerAppUIToggle.prototype = Object.create(
	ElementControllerAppUIBase.prototype,
);
ElementControllerAppUIToggle.prototype.constructor = ElementControllerAppUIToggle;

ElementControllerAppUIToggle.prototype.NAME = 'Toggle';
ElementControllerAppUIToggle.prototype.TYPE = 'AppUIToggle';
ElementControllerAppUIToggle.prototype.HIDDEN = false;
ElementControllerAppUIToggle.prototype.DEFAULT_TRIGGER = 'changed';
ElementControllerAppUIToggle.prototype.DEFAULT_ABILITY = 'setValue';
ElementControllerAppUIToggle.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerAppUIToggle.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerAppUIToggle.prototype.language = deepAssign(
	{},
	ElementControllerAppUIBase.prototype.language,
	{
		'en-US': {
			AppUIToggle: 'Toggle Switch',
			value: 'Value',
			toggle: 'Toggle',
			setValue: 'Set Value',
			changed: 'Changed',
			checked: 'Checked',
			unchecked: 'Unchecked',
		},
	},
);
