function LocationMultipleDeviceSettings(id, api, parentWidget, options) {
	LocationBase.call(this, id, api, parentWidget, options);

	// state
	this.page = 0;
	this.pages = 0;
	this.multipleDeviceSettingsWidget = null;

	this.deviceIdList = [];
	this.formWidgets = [];
}

LocationMultipleDeviceSettings.prototype = Object.create(
	LocationBase.prototype,
);
LocationMultipleDeviceSettings.prototype.constructor = LocationMultipleDeviceSettings;

LocationMultipleDeviceSettings.prototype.initialize = function(callback) {
	const currentLocation = this;
	const api = currentLocation.getApiV2().apis;
	this.deviceIdList = getHashCommand().deviceIdList || this.deviceIdList;
	const deviceIdList = this.deviceIdList;

	// Take the user to the device table if there are no devices to configure
	// to allow them to easily re-attempt their selection.
	if (!deviceIdList || deviceIdList.length <= 0) {
		this.getMainContainer().showPopupInfoMessage(
			this.getLanguageTag('noDevicesSelected'),
		);
		this.getMainContainer().setLocation(LocationDeviceManager, {
			org: getHashCommand().org,
		});
		return callback.call(this, false);
	}

	const tabs = [];
	tabs.push({
		childId: 'multipleDeviceSettingsWidget',
		constructor: WidgetBase,
	});

	this.setTabWidgets(null, tabs, (err) => {
		if (err) {
			console.debug(err);
			return callback.call(this, err);
		}
		this.multipleDeviceSettingsWidget = this.getChildWidget(
			'multipleDeviceSettingsWidget',
		);

		api.devices
			.batchDefaultSettings(
				{},
				{
					requestBody: deviceIdList,
				},
			)
			.then((response) => {
				// The response contains the ids which wil be used to
				// add additional Device setting widgets;

				if (!response.data || response.data.length <= 0) {
					// If there is no data to work with, notify the user
					// there are no devices with configurable settings
					// and boot them back to the previous location.
					this.getMainContainer().showPopupInfoMessage(
						this.getLanguageTag('noConfigurableDevicesAvailable'),
					);
					this.getMainContainer().setLocation(LocationDeviceManager);
					return callback.call(this, false);
				}

				this.addSettingsForms(response.data)
					.then(() => {
						this.addFooterWidget((err) => {
							if (err) {
								console.error(err);
								return callback.call(this, err);
							}
							this.onPageChanged();
							this.setTitle(
								this.getLanguageTag('name'),
								LocationMultipleDeviceSettings.prototype
									.HELP_LINK_HREF,
							);
							LocationBase.prototype.initialize.call(
								this,
								callback,
							);
						});
					})
					.catch((err) => {
						console.error(err);
						return callback.call(this, err);
					});
			});
	});
};

LocationMultipleDeviceSettings.prototype.addFooterWidget = function(callback) {
	const currentLocation = this;

	this.footerWidgetId = this.generateChildId('footerWidget');
	this.paginationInfoId = this.generateChildId('pagionationInfo');

	this.multipleDeviceSettingsWidget.getContainer().append(
		`<div id="${this.paginationInfoId}" class="LocationMultipleDeviceSettings-PaginationInfo-Container"></div>
		<div id="${this.footerWidgetId}" class="LocationMultipleDeviceSettings-FooterWidget"></div>`,
	);

	this.$paginationInfo = this.getContainer().find(
		'.LocationMultipleDeviceSettings-PaginationInfo-Container',
	);

	this.multipleDeviceSettingsWidget.addChildWidget(
		WidgetButtonsFooter,
		this.footerWidgetId,
		{},
		(err, footerWidget) => {
			if (err) {
				console.debug(err);
				return callback.call(this, err);
			}
			this.footerWidget = footerWidget;

			this.footerWidget.setButtons([
				{
					label: this.getLanguageTag('previous'),
					value: 'previous',
					classes: ['btn-primary'],
				},
				{
					label: this.getLanguageTag('next'),
					value: 'next',
					classes: ['btn-primary'],
				},
				{
					label: this.getLanguageTag('submit'),
					value: 'submit',
					classes: ['btn-primary'],
				},
			]);

			this.footerWidget.addEventListener(
				'footerButtonPressed',
				(value) => {
					// For now submitting the settings over the API will
					// happen with a confirmation
					if (value === 'previous') {
						this.page -= 1;
						this.onPageChanged();
					}

					if (value === 'next') {
						this.page += 1;
						this.onPageChanged();
					}

					if (value === 'submit') {
						this.onSubmitButtonPressed((err, result) => {
							// check for error
							if (err) {
								console.debug(err);
								return callback.call(this, err);
							}
							return callback.call(this, false);
						});
					}
				},
			);

			return callback.call(this, false);
		},
	);
};

LocationMultipleDeviceSettings.prototype.addSettingsForms = async function(
	settingsData,
) {
	const currentLocation = this;
	this.pages = settingsData.length;

	const addChildWidgetPromise = function(childId, opts) {
		return new Promise((resolve, reject) => {
			currentLocation.multipleDeviceSettingsWidget.addChildWidget(
				WidgetMultipleDeviceSettings,
				childId,
				opts,
				function(err, widget) {
					if (err) {
						reject(err);
					}
					resolve(widget);
				},
			);
		});
	};

	for (let i = 0; i < settingsData.length; i += 1) {
		const settings = settingsData[i];
		const { projectUuid } = settings;

		const childId = currentLocation.generateChildId(
			`settingForm_${projectUuid}`,
		);

		currentLocation.multipleDeviceSettingsWidget
			.getContainer()
			.append(`<div id="${childId}" data-page="${i}"></div>`);

		const widget = await addChildWidgetPromise(childId, settings);
		currentLocation.formWidgets.push(widget);
	}
};

LocationMultipleDeviceSettings.prototype.hideAllPages = function() {
	this.getContainer()
		.find('[data-page]')
		.hide();
};

LocationMultipleDeviceSettings.prototype.showPage = function(index) {
	this.getContainer()
		.find(`[data-page=${index}]`)
		.show();
};

LocationMultipleDeviceSettings.prototype.onPageChanged = function() {
	const start = 0;
	const index = this.page;
	const end = this.pages;

	// Always do this...
	this.hideAllPages();
	this.footerWidget.setButtonDisabled('previous', false);
	this.footerWidget.setButtonDisabled('next', true);
	this.footerWidget.setButtonDisabled('submit', false);

	// If only one page
	if (this.pages <= 1) {
		this.page = start;
		this.footerWidget.setButtonDisabled('previous', true);
		this.footerWidget.setButtonDisabled('next', true);
		this.footerWidget.setButtonDisabled('submit', false);
		this.updatePagination(1, 1);
		return this.showPage(start);
	}

	if (index <= start) {
		// Beginning
		this.page = start;
		this.footerWidget.setButtonDisabled('previous', true);
		this.footerWidget.setButtonDisabled('next', false);
		this.footerWidget.setButtonDisabled('submit', true);
		this.updatePagination(1, this.pages);
		return this.showPage(start);
	}

	if (index > start && index < end - 1) {
		// Middle
		this.page = index;
		this.footerWidget.setButtonDisabled('previous', false);
		this.footerWidget.setButtonDisabled('next', false);
		this.footerWidget.setButtonDisabled('submit', true);
		this.updatePagination(this.page + 1, this.pages);
		return this.showPage(index);
	}

	// End
	this.updatePagination(this.page + 1, this.pages);
	return this.showPage(index);
};

LocationMultipleDeviceSettings.prototype.updatePagination = function(
	page,
	totalPages,
) {
	this.$paginationInfo.text(
		formatString(this.getLanguageTag('paginationInfo'), page, totalPages),
	);
};

LocationMultipleDeviceSettings.prototype.onSubmitButtonPressed = function(
	callback,
) {
	const currentLocation = this;
	const api = this.getApiV2().apis;
	const body = [];

	const numDevices = this.deviceIdList.length;

	this.getMainContainer().showConfirm(
		{
			title: getLanguageTag(this.constructor, 'confirmTitle'),
			message: formatString(
				getLanguageTag(
					this.constructor,
					'confirmMultipleDeviceSettings',
				),
				numDevices,
			),
			confirmLabel: getLanguageTag(this.constructor, 'save'),
			dismissLabel: getLanguageTag(this.constructor, 'cancel'),
		},
		() => {
			// confirmed

			// Give indication to the user that settings are being saved
			currentLocation.footerWidget.setButtonDisabled('submit', true);
			currentLocation
				.getMainContainer()
				.showPopupInfoMessage(
					currentLocation.getLanguageTag('deviceSettingsSaving'),
				);

			for (let i = 0; i < this.formWidgets.length; i += 1) {
				const widget = this.formWidgets[i];
				const form = widget.formWidget;
				const deviceIdList = widget.deviceIdList;
				const settings = form.getValues();

				for (let j = 0; j < deviceIdList.length; j += 1) {
					const deviceId = deviceIdList[j];

					for (settingName in settings) {
						const value = settings[settingName];

						body.push({
							deviceId,
							name: settingName,
							value,
						});
					}
				}
			}

			if (body.length <= 0) {
				currentLocation
					.getMainContainer()
					.showPopupInfoMessage(
						currentLocation.getLanguageTag('noSettingsToggled'),
					);
				return callback.call(currentLocation, false, null);
			}

			return api.devices
				.batchUpdateDeviceSettings({}, { requestBody: body })
				.then((result) => {
					currentLocation
						.getMainContainer()
						.setLocation(LocationDeviceManager);
					currentLocation
						.getMainContainer()
						.showPopupInfoMessage(
							currentLocation.getLanguageTag(
								'deviceSettingsSaved',
							),
						);
					return callback.call(currentLocation, false, result);
				})
				.catch((err) => {
					// Re-enable the submit button
					currentLocation.footerWidget.setButtonDisabled(
						'submit',
						false,
					);
					// Notify the user to let them try again
					currentLocation
						.getMainContainer()
						.showPopupInfoMessage(
							currentLocation.getLanguageTag(
								'errorSavingDeviceSettings',
							),
						);
					return callback.call(currentLocation, err);
				});
		},
		() => {
			// rejected
		},
		() => {
			// spawned
		},
	);
};

LocationMultipleDeviceSettings.prototype.HELP_LINK_HREF =
	'https://docs.atmosphereiot.com/multiple-device-settings/';

LocationMultipleDeviceSettings.prototype.language = deepAssign(
	{},
	LocationBase.prototype.language,
	{
		'en-US': {
			name: 'Multiple Device Settings',
			noConfigurableDevicesAvailable:
				'No configurable devices available.',
			noDevicesSelected: 'No devices selected.',
			confirmTitle: 'Save Device Configurations',
			confirmMultipleDeviceSettings:
				'Are you sure you want to save device settings for {0} devices?',
			deviceSettingsSaving:
				'Device settings are currently being applied, please wait.',
			deviceSettingsSaved: 'Device settings were successfully saved.',
			errorSavingDeviceSettings:
				'There was an error saving device settings, please try again!',
			noSettingsToggled:
				'No settings have been selected for configuring the devices, please mark the checkboxes as needed.',
			paginationInfo: 'Page {0} of {1}',
		},
	},
);
