function LocationLogin(id, api, parentWidget, options) {
	LocationBase.call(this, id, api, parentWidget, options);

	this._loginWidget = null;
}

LocationLogin.prototype = Object.create(LocationBase.prototype);
LocationLogin.prototype.constructor = LocationLogin;

LocationLogin.prototype.initialize = function(callback) {
	this.getContainer().html(
		`<div id="${this.generateChildId('login')}"></div>`,
	);

	this.addChildWidget(
		WidgetLogin,
		this.generateChildId('login'),
		{},
		function(err, loginWidget) {
			this._loginWidget = loginWidget;

			LocationBase.prototype.initialize.call(this, callback);
		},
	);
};

LocationLogin.prototype.FULLSCREEN = true;
