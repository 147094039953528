//Copyright 2018 Atmosphere IoT Corp.
//All rights reserved
// jshint esversion: 6

function WidgetUserAccountOverviewGeneralUsageDevicesUsage(
	id,
	api,
	parent,
	options,
) {
	WidgetUsage.call(this, id, api, parent, options);

	this._interval = null;
}

WidgetUserAccountOverviewGeneralUsageDevicesUsage.prototype = Object.create(
	WidgetUsage.prototype,
);
WidgetUserAccountOverviewGeneralUsageDevicesUsage.prototype.constructor = WidgetUserAccountOverviewGeneralUsageDevicesUsage;

WidgetUserAccountOverviewGeneralUsageDevicesUsage.prototype.initialize = function(
	callback,
) {
	const currentWidget = this;

	this.update(function() {
		this._interval = setInterval(function() {
			if (currentWidget.isVisible() && isActive()) {
				currentWidget.update(function() {});
			}
		}, 60000);

		WidgetUsage.prototype.initialize.call(currentWidget, callback);
	});
};

WidgetUserAccountOverviewGeneralUsageDevicesUsage.prototype.remove = function(
	callback,
) {
	const currentWidget = this;

	if (this._interval) {
		clearInterval(this._interval);
		this._interval = null;
	}

	WidgetUsage.prototype.remove.call(currentWidget, callback);
};

WidgetUserAccountOverviewGeneralUsageDevicesUsage.prototype.update = function(
	callback,
) {
	const currentWidget = this;

	callback = callback || function() {};

	let cardLimit,
		cardCount = null;

	const api = currentWidget.getApiV2().apis;

	this.getOrganizationContext((err, org) => {
		if(err) {
			callback.call(currentWidget, err);
			return;
		}

		api.organizations.getUsage({id: org.id}).then((orgWithUsage) => {
			cardLimit = orgWithUsage.deviceCountLimit;
			cardCount = orgWithUsage.deviceCount;
	
			let storageUsageRatio = 1;
	
			if (cardLimit && cardLimit >= 0) {
				storageUsageRatio = cardCount / cardLimit;
			}
	
			let currentColor = 'green';
	
			if (storageUsageRatio >= 0.75) {
				currentColor = 'yellow';
			}
	
			if (storageUsageRatio >= 0.9) {
				currentColor = 'orange';
			}
	
			if (storageUsageRatio >= 0.98) {
				currentColor = 'red';
			}
	
			const cardLabel =
				cardLimit >= 0
					? cardLimit
					: currentWidget.getLanguageTag('unlimitedStorage');
	
			currentWidget.setUsage(
				cardCount,
				null,
				currentColor,
				'./Resources/icons/DevicesUsage.svg',
				getLanguageTag(currentWidget.constructor, 'devicesConnected'),
				cardLabel,
				storageUsageRatio,
			);
	
			callback.call(currentWidget, false);
	
		})
	});
};

WidgetUserAccountOverviewGeneralUsageDevicesUsage.prototype.language = deepAssign(
	{},
	WidgetUsage.prototype.language,
	{
		'en-US': {
			devicesConnected: 'Devices Connected',
			unlimitedStorage: 'Unlimited',
		},
	},
);

WidgetUserAccountOverviewGeneralUsageDevicesUsage.prototype.$_$ = function(
	done,
) {
	this.$_SetupWidgetTest(function(err, currentWidget) {
		WidgetUsage.prototype.$_$.call(this, done);
	});
};
