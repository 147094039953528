function WidgetOrganizationInfo(id, api, parent, options) {
	WidgetBase.call(this, id, api, parent, options);

	this.dataStorageUsageId = this.generateChildId('dataStorageUsage');
	
	this._usageWidget = null;
}

WidgetOrganizationInfo.prototype = Object.create(WidgetBase.prototype);
WidgetOrganizationInfo.prototype.constructor = WidgetOrganizationInfo;

WidgetOrganizationInfo.prototype.initialize = function(callback) {
	
	this.renderTemplate(
		{
			dataStorageUsageId: this.dataStorageUsageId,
		},
		WidgetOrganizationInfo.name,
	);
		
	this.addChildWidget(
			WidgetUsage,
			this.dataStorageUsageId,
			{},
			function(err, newUsageWidget) {
				this._usageWidget = newUsageWidget;
				
				//Set to default say loading
				this._usageWidget.setUsage(
					numeral(0).format('0.00b'),
					null,
					'green',
					'./Resources/icons/StorageOverview.svg',
					null,
					this.getLanguageTag('loading'),
					0,
				);
				
				this.update(callback);
			}
	);
}

WidgetOrganizationInfo.prototype.update = async function(callback) {
	
	let currentWidget = this;
	callback = callback || function(){};
	const api = currentWidget.getApiV2().apis;

	let orgId = await this.asyncGetOrganizationId();
	
	api.organizations.getUsage({id: orgId}).then((orgWithUsage) => {
		
		console.log(orgWithUsage);
		
		
		
		currentWidget._usageWidget.setUsage(
			numeral(orgWithUsage.dataUsageMb * 1000000).format('0.00b'),
			null,
			'green',
			'./Resources/icons/StorageOverview.svg',
			null,
			currentWidget.getLanguageTag('organizationalUsage'),
			orgWithUsage.dataUsageMb/orgWithUsage.dataUsageMbLimit,
		);
		
		callback.call(currentWidget, false);
	
	});
	
}

WidgetOrganizationInfo.prototype.ICON = './Resources/icons/CloudStorage.svg';

WidgetOrganizationInfo.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'Info',
			organizationalUsage: 'Total organization\'s usage',
			loading: 'Loading...'
		},
	},
);
