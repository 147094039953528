function WidgetUserAccountOverviewGeneralUsage(id, api, parent, options) {
	WidgetBase.call(this, id, api, parent, options);

	// ID Assignment
	this.deviceUsageWidgetContainerId = this.generateChildId(
		'deviceUsageWidgetContainerId',
	);
	this.dataUsageWidgetContainerId = this.generateChildId(
		'dataUsageWidgetContainerId',
	);
}

WidgetUserAccountOverviewGeneralUsage.prototype = Object.create(
	WidgetBase.prototype,
);
WidgetUserAccountOverviewGeneralUsage.prototype.constructor = WidgetUserAccountOverviewGeneralUsage;

WidgetUserAccountOverviewGeneralUsage.prototype.initialize = function(
	callback,
) {
	var currentWidget = this;

	this.render(function(err, data) {
		this.addChildWidget(
			WidgetUserAccountOverviewGeneralUsageDevicesUsage,
			this.deviceUsageWidgetContainerId,
			{},
			function() {
				currentWidget.addChildWidget(
					WidgetUserAccountOverviewGeneralUsageDevicesStorageUsage,
					this.dataUsageWidgetContainerId,
					{},
					function() {
						WidgetBase.prototype.initialize.call(this, callback);
					},
				);
			},
		);
	});
};

WidgetUserAccountOverviewGeneralUsage.prototype.getTemplateContext = function(
	callback,
) {
	var currentWidget = this;

	callback = callback || function() {};

	var context = {};

	context.rootIds = {
		deviceUsageWidgetContainerId: this.deviceUsageWidgetContainerId,
		dataUsageWidgetContainerId: this.dataUsageWidgetContainerId,
	};

	context.language = {
		generalUsage: getLanguageTag(this.constructor, 'generalUsage'),
	};

	callback.call(this, false, context);
	return context;
};

WidgetUserAccountOverviewGeneralUsage.prototype.render = function(callback) {
	var currentWidget = this;

	callback = callback || function() {};

	var context = this.getTemplateContext(function() {});
	var templateName = this.constructor.name;
	var container = this.getContainer();

	this.renderTemplate(context, templateName, container);

	callback.call(this, false, null);
	return;
};

WidgetUserAccountOverviewGeneralUsage.prototype.language = deepAssign(
	{},
	WidgetBase.prototype.language,
	{
		'en-US': {
			name: 'WidgetUserAccountOverviewGeneralUsage',
			generalUsage: 'General Usage',
			unlimited: 'unlimited',

			outboundEventsIconTooltip: 'Outbound Events',
			outboundEventsLabel: 'Outbound Events',
			outbountEventsSubLabel: 'of {0} monthly events',

			cloudStorageIconTooltip: 'Cloud Storage',
			cloudStorageLabel: '{0} Data Storage Used',
			cloudStorageSubLabel: 'of {0} storage',

			usersOverviewLabel: 'User Accounts',
			usersOverviewSubLabel: 'of {0} users',
		},
	},
);

WidgetUserAccountOverviewGeneralUsage.prototype.$_$ = function(done) {
	//Testing function for WidgetUserAccountOverviewGeneralUsage

	this.$_SetupWidgetTest(function(err, currentWidget) {
		WidgetBase.prototype.$_$.call(this, done);
	});
};
