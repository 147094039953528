//Copyright 2018 Atmosphere IoT Corp.
//All rights reserved
//jshint esversion: 6

function WidgetProvisioner(id, api, parent, options) {
	var currentWidget = this;

	WidgetPanelBase.call(this, id, api, parent, options);

	this.menu = null;
	this.footer = null;

	this._provisioning = false;
	this._provisioners = {};
	this._devicesFound = {};
	this._updateDevicesMenuTimeout = null;

	this.menuContainerId = this.generateChildId('menuContainer');
	this.footerContainerId = this.generateChildId('footerContainer');
	this.overlayId = this.generateChildId('overlay');
	this.rescanButtonId = this.generateChildId('rescanButton');
	this.extraSettingsContainerId = this.generateChildId(
		'extraSettingsContainer',
	);

	this.renderTemplate(
		{
			headerLabel: getLanguageTag(this.constructor, 'devicesInRange'),
			menuContainerId: this.menuContainerId,
			footerContainerId: this.footerContainerId,
			overlayId: this.overlayId,
			rescanButtonId: this.rescanButtonId,
			overlayLabel: getLanguageTag(
				this.constructor,
				'provisioningPleaseWait',
			),
			extraSettingsContainerId: this.extraSettingsContainerId,
		},
		WidgetProvisioner.name,
	);

	this.overlay = $(`#${this.overlayId}`);
	this.rescanButton = $(`#${this.rescanButtonId}`);
	this.extraSettingsContainer = $(`#${this.extraSettingsContainerId}`);

	this.overlay.hide();
	this.rescanButton.hide();
	this.extraSettingsContainer.hide();

	this.rescanButton.on('click', function(err) {
		currentWidget.startSearching(30000, function(err) {});
	});

	return;
}

WidgetProvisioner.prototype = Object.create(WidgetPanelBase.prototype);
WidgetProvisioner.prototype.constructor = WidgetProvisioner;

WidgetProvisioner.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.addChildWidget(
		WidgetButtonsFooter,
		this.footerContainerId,
		{},
		function(err, footer) {
			this.footer = footer;

			this.footer.setButtons([
				{
					label:
						this.getOptions().dismissLabel ||
						getLanguageTag(this.constructor, 'dismiss'),
					value: 'dismissed',
					classes: [`btn-primary`],
				},
			]);

			this.footer.addEventListener('footerButtonPressed', function(
				value,
			) {
				currentWidget.event(value);
			});

			this.addChildWidget(
				WidgetMenu,
				this.menuContainerId,
				{ disableAutoHide: true },
				function(err, menu) {
					this.menu = menu;

					this.menu.addEventListener('menuEntryClicked', function(
						deviceData,
					) {
						currentWidget._onDeviceSelected(deviceData);
					});

					this.addProvisioner(ProvisionerBLE, function(err) {
						WidgetPanelBase.prototype.initialize.call(
							this,
							callback,
						);
					});

					if (!isPhonegap()) {
						this.addProvisioner(ProvisionerUart, function(err) {
							WidgetPanelBase.prototype.initialize.call(
								this,
								callback,
							);
						});
					}
				},
			);
		},
	);
};

WidgetProvisioner.prototype.remove = function(callback) {
	this.stopSearching(function(err) {
		this.removeAllProvisioners(function(err) {
			WidgetPanelBase.prototype.remove.call(this, callback);
		});
	});
};

WidgetProvisioner.prototype.hasPermission = function(callback) {
	//This is a function to check if the current user
	//has permission to use this widget.

	var currentWidget = this;

	ProvisionerBLE.prototype.hasPermission(function(err, allowed) {
		callback.call(currentWidget, err, allowed);
	});
};

WidgetProvisioner.prototype._onSearchingStarted = function(type) {
	this.rescanButton.hide();
};

WidgetProvisioner.prototype._onSearchingStopped = function(type) {
	this.rescanButton.show();
};

WidgetProvisioner.prototype._onDeviceFound = function(type, deviceData) {
	deviceData.protocol = type;

	this._devicesFound[deviceData.id] = deviceData;

	this.updateDevicesMenu();
};

WidgetProvisioner.prototype._onDeviceSelected = function(deviceData) {
	var currentWidget = this;

	if (!deviceData) {
		return;
	}

	if (this._provisioning) {
		return;
	}

	this.overlay.show();

	this._provisioning = true;

	// If type is WiFi, get the SSID and password at the beginning
	if (deviceData.protocol == 'wifi') {
		this._provisioners[deviceData.protocol].clearWifiSettings();
		currentWidget._handleExtraSettingsWiFi(
			deviceData.id,
			undefined,
			this._provisioners[deviceData.protocol],
			false,
			function(err) {
				currentWidget.overlay.show();
				currentWidget._provisioners[
					deviceData.protocol
				].provisionDevice(
					deviceData.id,
					deviceData.type,
					deviceData.projectUuid,
					function(err, newDevice, extraSettings) {
						currentWidget._onProvisionComplete(
							err,
							deviceData,
							newDevice,
							extraSettings,
						);
						return;
					},
				);
			},
		);
	} else {
		currentWidget._provisioners[deviceData.protocol].provisionDevice(
			deviceData.id,
			deviceData.type,
			deviceData.projectUuid,
			function(err, newDevice, extraSettings) {
				currentWidget._onProvisionComplete(
					err,
					deviceData,
					newDevice,
					extraSettings,
				);
				return;
			},
		);
	}
};

WidgetProvisioner.prototype._handleExtraSettings = function(
	deviceId,
	device,
	extraSettings,
	provisioner,
	callback,
) {
	var currentWidget = this;

	var errors = false;

	// This is a convenient way to get WiFi last
	// Typically when SSID/PASS are sent, all kinds of stuff happens so it's smart to do that at the end after all other extra settings
	extraSettings.sort();

	function _handleExtraSettingsHelper() {
		if (extraSettings.length <= 0) {
			callback.call(currentWidget, errors);
			return;
		}

		var currentExtraSettings = extraSettings.shift();

		switch (currentExtraSettings) {
			case 'WiFi':
				// If this is a wifi board
				// Then the wifi settings have already been sent down
				if (provisioner.TYPE == 'wifi') {
					_handleExtraSettingsHelper();
					return;
				}

				currentWidget._handleExtraSettingsWiFi(
					deviceId,
					device,
					provisioner,
					true,
					function(err) {
						if (err) {
							errors = errors || [];
							errors.push(err);
						}

						_handleExtraSettingsHelper();
					},
				);

				return;

			case 'Sigfox':
				currentWidget._handleExtraSettingsSigfox(
					deviceId,
					device,
					provisioner,
					function(err) {
						if (err) {
							errors = errors || [];
							errors.push(err);
						}

						_handleExtraSettingsHelper();
					},
				);

				return;

			case 'LoRa':
				currentWidget._handleExtraSettingsLoRa(
					deviceId,
					device,
					provisioner,
					function(err) {
						if (err) {
							errors = errors || [];
							errors.push(err);
						}

						_handleExtraSettingsHelper();
					},
				);

				return;

			default:
				_handleExtraSettingsHelper();
				return;
		}
	}

	_handleExtraSettingsHelper();
};

WidgetProvisioner.prototype._handleExtraSettingsWiFi = function(
	deviceId,
	device,
	provisioner,
	sendToDevice,
	callback,
) {
	callback = callback || function() {};

	var currentWidget = this;

	this.overlay.hide();
	this.extraSettingsContainer.show();

	this.removeChildWidget(this.extraSettingsContainerId, function() {
		this.addChildWidget(
			WidgetSettingsForm,
			this.extraSettingsContainerId,
			{},
			function(err, wifiExtraSettingsFormWidget) {
				wifiExtraSettingsFormWidget.setTitle(
					getLanguageTag(this.constructor, 'configureWiFiSettings'),
				);

				wifiExtraSettingsFormWidget.setForm([
					{
						name: 'ssid',
						type: 'ssid', //We use this so we don't get a captilization attempt on mobile
						label: getLanguageTag(this.constructor, 'ssid'),
						value: '',
						validator: function(a) {
							return a.length > 0;
						},
					},

					{
						name: 'password',
						type: 'password',
						label: getLanguageTag(this.constructor, 'password'),
						value: '',
						validator: function(a) {
							return a.length >= 8;
						}, //WiFi passwords need at least 8 characters
					},
				]);

				wifiExtraSettingsFormWidget.addEventListener(
					'confirmed',
					function() {
						currentWidget.extraSettingsContainer.hide();
						currentWidget.overlay.show();

						if (sendToDevice) {
							if (deviceId) {
								provisioner.setExtraSettings(
									deviceId,
									'WiFi',
									wifiExtraSettingsFormWidget.getValues(),
									function(err) {
										currentWidget.removeChildWidget(
											currentWidget.extraSettingsContainerId,
											function() {
												currentWidget.overlay.hide();

												callback.call(
													currentWidget,
													err,
												);
											},
										);
									},
								);
							} else {
								provisioner.setExtraSettingsByUuid(
									device.uuid,
									'WiFi',
									wifiExtraSettingsFormWidget.getValues(),
									function(err) {
										if (err) {
											currentWidget
												.getMainContainer()
												.showPopupErrorMessage(
													currentWidget.getLanguageTag(
														err.type ||
															'errorSettingDevice',
													),
												);
										}

										currentWidget.removeChildWidget(
											currentWidget.extraSettingsContainerId,
											function() {
												currentWidget.overlay.hide();

												callback.call(
													currentWidget,
													err,
												);
											},
										);
									},
								);
							}
						} else {
							provisioner.saveExtraSettings(
								deviceId,
								'WiFi',
								wifiExtraSettingsFormWidget.getValues(),
								function(err) {
									currentWidget.removeChildWidget(
										currentWidget.extraSettingsContainerId,
										function() {
											currentWidget.overlay.hide();
											callback.call(currentWidget, false);
										},
									);
								},
							);
						}
					},
				);

				wifiExtraSettingsFormWidget.addEventListener(
					'dismissed',
					function() {
						currentWidget.extraSettingsContainer.hide();
						currentWidget.overlay.show();

						currentWidget.removeChildWidget(
							currentWidget.extraSettingsContainerId,
							function() {
								currentWidget.overlay.hide();

								callback.call(currentWidget, false);
							},
						);
					},
				);
			},
		);
	});
};

WidgetProvisioner.prototype._handleExtraSettingsSigfox = function(
	deviceId,
	device,
	provisioner,
	callback,
) {
	var currentWidget = this;

	this.overlay.show();

	const api = currentWidget.getApiV2().apis;

	provisioner.setExtraSettingsByUuid(
		device.uuid,
		'Sigfox',
		{ device: new Device(api, device) },
		function(err) {
			currentWidget.overlay.hide();

			callback.call(currentWidget, err);
		},
	);
};

WidgetProvisioner.prototype._handleExtraSettingsLoRa = function(
	deviceId,
	device,
	provisioner,
	callback,
) {
	var currentWidget = this;

	this.overlay.show();
	const api = currentWidget.getApiV2().apis;

	if (deviceId) {
		provisioner.setExtraSettings(
			deviceId,
			'LoRa',
			{ device: new Device(api, device) },
			function(err) {
				currentWidget.overlay.hide();

				callback.call(currentWidget, err);
			},
		);
	} else {
		provisioner.setExtraSettingsByUuid(
			deviceUuid,
			'LoRa',
			{ device: new Device(api, device) },
			function(err) {
				currentWidget.overlay.hide();

				callback.call(currentWidget, err);
			},
		);
	}
};

WidgetProvisioner.prototype._onSearchingStarted = function(provisionerType) {
	var currentWidget = this;

	if (provisionerType === 'ble') {
		// 		this.setSearchingStatus(getLanguageTag(WidgetProvisioner, 'searching'));
	}
};

WidgetProvisioner.prototype._onProvisionComplete = function(
	err,
	deviceEntry,
	newDevice,
	extraSettings,
	callback,
) {
	callback = callback || function() {};

	this.overlay.hide();
	var currentWidget = this;

	if (err) {
		Sentry.withScope(function(scope) {
			scope.setTag('deviceEntry', deviceEntry);
			scope.setTag('newDevice', newDevice);
			scope.setTag('extraSettings', extraSettings);
			scope.setTag('provisioning', currentWidget._provisioning);
			scope.setTag('provisioners', currentWidget._provisioners);
			scope.setTag('devicesFound', currentWidget._devicesFound);

			Sentry.captureException(err);
		});

		console.log(JSON.stringify(err));

		var errorType = ((err || {}).err || {}).type || 'errorProvisioning';

		this.getMainContainer().showPopupErrorMessage(
			getLanguageTag(this.constructor, errorType),
		);
		this.event('dismissed');
		callback.call(currentWidget, err);
		return;
	} else {
		this._handleExtraSettings(
			deviceEntry.id,
			newDevice,
			extraSettings,
			this._provisioners[deviceEntry.protocol],
			function(err) {
				//TODO: If in a group ask the user what role from the device to attach to the group

				this.event('dismissed');

				//So when we press back from the LocationAppView it'll take us to the device dashboard instead of back to were we where when we provisoined the device.
				this.getMainContainer().pushCommandHistory({
					location: 'DeviceDashboard',
					thingUuid: newDevice.uuid,
					deviceId: newDevice.id,
				});
				this.getMainContainer().setLocation(LocationAppView, {
					thingUuid: newDevice.uuid,
					deviceId: newDevice.id,
					org: getHashCommand().org,
				});
				callback.call(currentWidget, err);
			},
		);
	}

	return;
};

WidgetProvisioner.prototype.addProvisioner = function(
	provisionerConstructor,
	callback,
) {
	callback = callback || function() {};

	var currentWidget = this;

	if (
		this._provisioners[provisionerConstructor.prototype.TYPE] !== undefined
	) {
		console.error('Provisioner with that type already added!');
		return;
	}

	var currentProvisioner = new provisionerConstructor(this.getAPI());

	this._provisioners[
		provisionerConstructor.prototype.TYPE
	] = currentProvisioner;

	currentProvisioner.addEventListener('searchingStarted', function() {
		currentWidget._devicesFound = {};

		currentWidget._onSearchingStarted(this.TYPE);

		currentWidget.updateDevicesMenu();
	});

	currentProvisioner.addEventListener('searchingStopped', function() {
		currentWidget._onSearchingStopped(this.TYPE);
	});

	currentProvisioner.addEventListener('deviceFound', function(deviceData) {
		currentWidget._onDeviceFound(this.TYPE, deviceData);
	});

	callback.call(this, false);
};

WidgetProvisioner.prototype.updateDevicesMenu = function(callback) {
	var currentWidget = this;

	callback = callback || function() {};

	if (this._provisioning) {
		callback.call(this, false);
		return;
	}

	this._menuEntries = [];

	for (var k in this._devicesFound) {
		var currentDevice = this._devicesFound[k];

		var currentLabel = $.handlebarTemplates[
			WidgetProvisioner.name + '_DeviceEntry'
		](currentDevice);

		this._menuEntries.push({
			label: currentLabel,
			value: currentDevice,
		});
	}

	if (this._updateDevicesMenuTimeout === null) {
		this._updateDevicesMenuTimeout = setTimeout(function() {
			currentWidget.menu.setMenu(currentWidget._menuEntries);
			currentWidget._updateDevicesMenuTimeout = null;
		}, 1000);
	}

	callback.call(this);
};

WidgetProvisioner.prototype.removeAllProvisioners = function(callback) {
	callback = callback || function() {};

	for (var k in this._provisioners) {
		this._provisioners[k].removeAllEventListeners();
	}

	callback.call(this, false);
	return;
};

WidgetProvisioner.prototype.startSearching = function(timeout, callback) {
	var currentWidget = this;
	var provisionerKeys = Object.keys(this._provisioners);

	function startSearchingHelper() {
		if (provisionerKeys.length <= 0) {
			callback.call(currentWidget, false, {
				type: 'searchingHasCompleted',
			});
			return;
		}

		var currentProvisionerKey = provisionerKeys.shift();
		var currentProvisioner =
			currentWidget._provisioners[currentProvisionerKey];

		currentProvisioner.startSearching(timeout, function(err) {
			if (err) {
			}

			startSearchingHelper();
		});
	}

	startSearchingHelper();
};

WidgetProvisioner.prototype.stopSearching = function(callback) {
	var currentWidget = this;
	var provisionerKeys = Object.keys(this._provisioners);

	function stopSearchingHelper() {
		if (provisionerKeys.length <= 0) {
			callback.call(currentWidget, false, {
				type: 'searchingHasCompleted',
			});
			return;
		}

		var currentProvisionerKey = provisionerKeys.shift();
		var currentProvisioner =
			currentWidget._provisioners[currentProvisionerKey];

		currentProvisioner.stopSearching(function(err) {
			if (err) {
			}

			stopSearchingHelper();
		});
	}

	stopSearchingHelper();
};

WidgetProvisioner.prototype.autoProvision = function(
	name,
	uuid,
	type,
	callback,
) {
	/*
	 * This function takes a set of information that is used
	 * by the provisioner plugins to find a specific device
	 * for provisioning. In the case of ble the name would
	 * be the localname in the advertiment package and the
	 * uuid would be the primary service uuid. The type is
	 * the type of provisioner we should use for auto
	 * provisioning, like "ble" for Bluetooth Low Energy.
	 */

	var currentWidget = this;

	if (this._provisioning) {
		return;
	}

	this.overlay.show();

	this._provisioning = true;

	if (this._provisioners[type] === undefined) {
		callback.call(this, { type: 'invalidProvisionType' });
		return;
	}

	this._provisioningDevice = true;

	this._provisioners[type].startAutoProvisioning(name, uuid, 20000, function(
		err,
		deviceData,
		newDeviceUuid,
		extraSettings,
	) {
		currentWidget._onProvisionComplete(
			err,
			deviceData,
			newDeviceUuid,
			extraSettings,
			callback,
		);
		return;
	});
};

WidgetProvisioner.prototype.language = deepAssign(
	{},
	WidgetPanelBase.prototype.language,
	{
		'en-US': {
			name: 'Scan for Devices',
			devicesInRange: 'Devices in range',
			dismiss: 'Close',
			scanForDevicesSvgTitle: 'Scan for devices',
			errorProvisioning:
				'An error occurred while provisioning the device',
			provisioningPleaseWait: 'Provisioning device, please wait...',
			ssid: 'SSID',
			password: 'Password',
			configureWiFiSettings: 'Configure Wi-Fi Settings',
			errorSettingDevice: 'There was an error when setting the device',
			timeoutConnectingToDevice: 'Unable to find the device',
			thingLimitReached: 'You have reached your device limit!',
		},

		de: {
			name: 'Provisioner',
		},
	},
);

WidgetProvisioner.prototype.$_$ = function(done) {
	this.$_SetupWidgetTest(function(err, currentWidget) {
		WidgetPanelBase.prototype.$_$.call(this, done);
	});
};
