function ElementControllerCloudAWSSNS(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerCloudAWSBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);
}

ElementControllerCloudAWSSNS.prototype = Object.create(
	ElementControllerCloudAWSBase.prototype,
);
ElementControllerCloudAWSSNS.prototype.constructor = ElementControllerCloudAWSSNS;

ElementControllerCloudAWSSNS.prototype.NAME = 'AWSSNS';
ElementControllerCloudAWSSNS.prototype.TYPE = 'CloudAWSSNS';
ElementControllerCloudAWSSNS.prototype.HIDDEN = true;

ElementControllerCloudAWSSNS.prototype.language = deepAssign(
	{},
	ElementControllerCloudAWSBase.prototype.language,
	{
		'en-US': {
			CloudAWSSNS: 'AWS SNS',
		},
	},
);
