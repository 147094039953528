function MainContainer(container) {
	var currentMainContainer = this;

	//If a container is not passed it's assumed there is a MainContainer div
	container = container || $('#MainContainer');

	WidgetBase.call(this, 'MainContainer', null, null, {});

	this._navbarContainerId = this.generateChildId('navbar');
	this._locationContainerId = this.generateChildId('location');
	this._topNavContainerId = this.generateChildId('topnavbar');
	this._modalId = this.generateChildId('modal');
	this._modalContainerId = this.generateChildId('modalContainer');
	this._modalHidden = true;
	this._popupContainerId = this.generateChildId('popupContainer');
	this._clientAgent = null;
	this._nfcHandler = null;
	this._ignoreHashChange = false;
	this._commandHistory = [];
	this._notificationsRefreshInterval = null;
	
	// TODO: We shouldn't need to drag around the current user context either.
	this._currentUser = undefined;

	this.renderTemplate({
		navbarContainerId: this._navbarContainerId,
		locationContainerId: this._locationContainerId,
		modalId: this._modalId,
		modalContainerId: this._modalContainerId,
		popupContainerId: this._popupContainerId,
		topNavContainerId: this._topNavContainerId,
	});

	this._navbarContainer = $(`#${this._navbarContainerId}`);
	this._locationContainer = $(`#${this._locationContainerId}`);
	this._modal = $(`#${this._modalId}`);
	this._modalContainer = $(`#${this._modalContainerId}`);
	this._topNavContainer = $(`#${this._topNavContainerId}`);

	this.setModalHidden(true);

	this._globalConfig = null;
	this._changingLocation = false;

	//If we request to set a new location, but are still loading
	//a location we enter the request onto this queue which
	//is shifted out and allowed to run and set the location
	//after the current location is loaded.
	this._changingLocationQueue = [];

	this._currentLocation = null;
	this._popupMessageWidget = null;
}

MainContainer.prototype = Object.create(WidgetBase.prototype);
MainContainer.prototype.constructor = MainContainer;

MainContainer.prototype.getClientAgent = function() {
	return this._clientAgent;
};

MainContainer.prototype.getNFCHandler = function() {
	return this._nfcHandler;
};

MainContainer.prototype.onWindowResize = function() {};

MainContainer.prototype.isModalHidden = function() {
	return this._modalHidden;
};

MainContainer.prototype.setModalHidden = function(value) {
	if (value) {
		this._modalHidden = true;
		this._modal.hide();
		this._locationContainer.removeClass('NoSelect');
		this._navbarContainer.removeClass('NoSelect');
		this.event('modalHidden');
	} else {
		this._modalHidden = false;
		this._modal.show();
		this._locationContainer.addClass('NoSelect');
		this._navbarContainer.addClass('NoSelect');
		this.event('modalShown');
	}
};

MainContainer.prototype.showModal = function() {
	this.setModalHidden(false);
};

MainContainer.prototype.hideModal = function() {
	this.setModalHidden(true);
};

MainContainer.prototype.closeModal = function(callback) {
	callback = callback || function() {};

	this.hideModal();
	this.removeChildWidget(this._modalContainerId, callback);
};

MainContainer.prototype.setModalWidget = function(
	widgetConstructor,
	options,
	callback,
) {
	this.removeChildWidget(this._modalContainerId, function(err) {
		this.addChildWidget(
			widgetConstructor,
			this._modalContainerId,
			options,
			callback,
		);
	});
};

MainContainer.prototype.showAlert = function(
	options,
	dismissedCallback,
	callback,
) {
	options = options || {};
	dismissedCallback = dismissedCallback || function() {};
	callback = callback || function() {};

	this.setModalWidget(WidgetAlert, options, function(err, alertWidget) {
		currentMainContainer = this;

		alertWidget.addEventListener('dismissed', function(value) {
			currentMainContainer.hideModal();

			dismissedCallback.call(this);
		});

		this.showModal();

		callback.call(this, false, alertWidget);
	});
};

MainContainer.prototype.showPrompt = function(
	options,
	repliedCallback,
	dismissedCallback,
	callback,
) {
	var currentMainContainer = this;

	options = options || {};
	dismissedCallback = dismissedCallback || function() {};
	callback = callback || function() {};

	var label = options.label || getLanguageTag(this.constructor, 'title');

	this.getMainContainer().setModalWidget(
		WidgetSettingsForm,
		{
			fields: [
				{
					name: 'reply',
					type: 'text',
					label: label,
					value: null,
				},
			],
		},
		function(err, settingsWidget) {
			settingsWidget.addEventListener('dismissed', function() {
				currentMainContainer.hideModal();
				dismissedCallback.call(currentMainContainer, null);
				return;
			});

			settingsWidget.addEventListener('confirmed', function() {
				currentMainContainer.hideModal();

				values = this.getValues();
				var reply = values.reply;

				repliedCallback.call(currentMainContainer, reply);
				return;
			});

			this.showModal();

			callback.call(this, false);
			return;
		},
	);
};

MainContainer.prototype.showConfirm = function(
	options,
	confirmedCallback,
	dismissedCallback,
	callback,
) {
	options = options || {};

	if (typeof options === 'string') {
		options = { message: options };
	}

	dismissedCallback = dismissedCallback || function() {};
	callback = callback || function() {};

	this.setModalWidget(WidgetConfirm, options, function(err, confirmWidget) {
		currentMainContainer = this;

		confirmWidget.addEventListener('confirmed', function(value) {
			currentMainContainer.hideModal();

			confirmedCallback.call(this);
		});

		confirmWidget.addEventListener('dismissed', function(value) {
			currentMainContainer.hideModal();

			dismissedCallback.call(this);
		});

		this.showModal();

		callback.call(this, false, confirmWidget);
	});
};

MainContainer.prototype.showPopupMessage = function(
	message,
	color,
	timeout,
	callback,
) {
	callback = callback || function() {};

	var currentMainContainer = this;

	this._popupMessageWidget.showMessage(message, color, timeout, function(
		err,
	) {
		callback.call(currentMainContainer, err);
		return;
	});
};

MainContainer.prototype.showPopupInfoMessage = function(message, callback) {
	this.showPopupMessage(message, 'blue', 3000, callback);
};

MainContainer.prototype.showPopupErrorMessage = function(message, callback) {
	this.showPopupMessage(message, 'red', 0, callback);
};

MainContainer.prototype.getGlobalConfig = function() {
	return this._globalConfig;
};

MainContainer.prototype.getDefaultLocation = function() {
	return this._globalConfig.defaultLocation || 'Dashboards';
};

// TODO: We shouldn't need to drag around the current user context either.
MainContainer.prototype.setCurrentUser = function(newUser) {
	this._currentUser = newUser;
	this._currentUser.ability = Abilities.defineFor(this._currentUser);
};

// TODO: We shouldn't need to drag around the current user context either.
MainContainer.prototype.clearCurrentUser = function() {
	//We also want to clear any command history the user has
	this._commandHistory = [];
	this._currentUser = null;
};

MainContainer.prototype.getCurrentUser = function() {
	return this._currentUser;
};

MainContainer.prototype.onHashChange = async function() {
	var currentMainContainer = this;

	this.event('hashChanged');

	//We want to just ignore this hash change because we are forcing the hash to update
	if (
		this._ignoreHashChange &&
		this._ignoreHashChange === window.location.hash
	) {
		this._ignoreHashChange = false;
		return;
	}

	var command = getHashCommand() || {};

	//We are already changing location so don't allow the hash update and ignore this request
	if (this._changingLocation) {
		return;
	}

	if (command.location === undefined || command.location === null) {
		this.setLocation(this.getDefaultLocation());
		return;
	}

	var newLocationConstructor = window['Location' + command.location];

	if (newLocationConstructor === undefined) {
		this.setLocation(this.getDefaultLocation());
		return;
	}

	this._changingLocation = true;

	var currentLocationId = null;

	this.pushCommandHistory(command);

	if (this._currentLocation !== null) {
		currentLocationId = this._currentLocation.getId();
	}

	this.removeChildWidget(currentLocationId, function(err) {
		if (err) {
			console.error(`Error removing location "${err}"`);
		}

		this._currentLocation = null;

		this.setLocationFullscreen(newLocationConstructor.prototype.FULLSCREEN);

		this.addChildWidget(
			newLocationConstructor,
			this._locationContainerId,
			command,
			function(err, newLocation) {
				this._currentLocation = newLocation;

				this._changingLocation = false;

				if (err) {
					console.error(err);

					//Don't continue the location queue
					this._changingLocationQueue = [];

					if (err.redirect) {
						this.setLocation(err.redirect, {
							org: getHashCommand().org,
						});
						return;
					}

					//We didn't get someplace to go back to so
					//take them to the login page
					else {
						this.redirectToLogin();
						return;
					}

					return;
				}

				this.event('locationChanged', {
					location: this._currentLocation,
					locationName: this._currentLocation.constructor.name.replace(
						'Location',
						'',
					),
				});

				//Let's see if there are any pending location changes on the queue
				let queuedLocation = this._changingLocationQueue.shift();

				//If there are we call setLocation again with the queued location
				if (queuedLocation) {
					this.setLocation(
						queuedLocation.locationConstructorOrName,
						queuedLocation.args,
					);
					return;
				}
			},
		);
	});
};

MainContainer.prototype.onBackbutton = function() {
	if (this.atLoginLocation()) {
		return;
	}

	this.setLocationBack();
	return;
};

MainContainer.prototype.onNavbarEntrySelected = function(event) {
	if (event.value.link !== undefined) {
		openLinkInNewWindowOrTab(event.value.link);
	} else if (event.value.location !== undefined) {
		event.value.args = event.value.args || {};
		event.value.args.org = getHashCommand().org;

		this.setLocation(event.value.location, event.value.args);
	}
};

MainContainer.prototype.forceHashCommand = function(value, ignoreChanging) {
	// We don't normally want to allow forcing the hash during a load
	// of a location but if you need to you can by passing ignoreChanging
	// as true
	if (!this._changingLocation || ignoreChanging) {
		this._ignoreHashChange = generateHashCommand(value);

		this._lastHash = generateHashCommand(value);
		window.location.hash = generateHashCommand(value);
	}
};

MainContainer.prototype.setHash = function(value) {
	this._lastHash = window.location.hash;

	const currentCommand = getHashCommand() || {};

	var reload = Boolean(value.reload);

	delete value.reload;

	setHashCommand(value);

	if (window.location.hash === this._lastHash && reload) {
		this.onHashChange();
	}
};

/**
 * Get a contextual hyperlink to a location
 * @param {} locationConstructorOrName
 * @param {*} args
 */
MainContainer.prototype.getLocationLink = function(
	locationConstructorOrName,
	args,
) {
	const locationName =
		typeof locationConstructorOrName === 'string'
			? locationConstructorOrName
			: locationConstructorOrName.name;

	const hashObj = { location: locationName, ...args };

	return `#${encodeURIComponent(JSON.stringify(hashObj))}`;
};

MainContainer.prototype.setLocation = function(
	locationConstructorOrName,
	args,
) {
	//We are still loading the new location so
	if (this._changingLocation) {
		//Stick it on the queue and it'll be execuded once the current location is done.
		this._changingLocationQueue.push({ locationConstructorOrName, args });

		return;
	}

	var locationConstructor = locationConstructorOrName || null;
	var locationConstructorOrName =
		locationConstructorOrName || this.getDefaultLocation();

	if (typeof locationConstructorOrName === 'string') {
		locationConstructor = window['Location' + locationConstructorOrName];
	}

	if (locationConstructor === undefined || locationConstructor === null) {
		console.error(
			`Could not find location constructor for "${locationConstructorOrName}"`,
		);
		return;
	}

	args = args || {};

	if (locationConstructor.name.indexOf('Location') !== 0) {
		console.error(
			`Invalid constructor not a location "${locationConstructor.name}"`,
		);
		return;
	}

	// We never want to ignore this hash change
	this._ignoreHashChange = false;
	args.location = locationConstructor.name.replace('Location', '');
	this.setHash(args);
	return;
};

MainContainer.prototype.pushCommandHistory = function(command) {
	/*
	 * This function will push onto the command hash
	 * if the new command is targeting a different
	 * location than the last command on the history
	 * stack, if it's the same location we overwrite
	 * the previous history (this prevents us from just
	 * going back a section when what we want to do
	 * is to go back to the last location we went to
	 */

	//If there is nothing on the command history just push it on
	if (this._commandHistory.length <= 0) {
		this._commandHistory.push(command);
		return;
	}

	var lastCommand = this._commandHistory[this._commandHistory.length - 1];

	if (lastCommand.location === command.location) {
		this._commandHistory.pop();
		this._commandHistory.push(command);
	} else {
		this._commandHistory.push(command);
	}

	if (this._commandHistory.length >= 100) {
		while (this._commandHistory.length >= 100) {
			this._commandHistory.shift();
		}
	}
};

MainContainer.prototype.setLocationBack = function() {
	/*
	 * This function will pop the last value
	 * off the hash history and then make the
	 * change for the on hash change to move
	 * the user back a location with arguments
	 */

	//We don't do this while changing location
	if (this._changingLocation) {
		//We are loading a location currently so stick it on the queue till after
		this._changingLocationQueue.push({ locationConstructorOrName, args });

		return true;
	}

	this._commandHistory.pop();
	var lastCommand = this._commandHistory.pop();

	if (!lastCommand) {
		return false;
	}

	setHashCommand(lastCommand);

	return true;
};

MainContainer.prototype.setLocationFullscreen = function(fullscreen) {
	if (
		fullscreen &&
		!this._locationContainer.hasClass(
			'MainContainer-LocationContainer-Fullscreen',
		)
	) {
		this._locationContainer.addClass(
			'MainContainer-LocationContainer-Fullscreen',
		);
	} else if (!fullscreen) {
		this._locationContainer.removeClass(
			'MainContainer-LocationContainer-Fullscreen',
		);
	}
};

MainContainer.prototype.isLocationFullscreen = function() {
	return this._locationContainer.hasClass(
		'MainContainer-LocationContainer-Fullscreen',
	);
};

/**
 *
 * @param {Boolean} opts.accountCancelled - If true we canceled the account and need to skip calling the auth logout
 */
MainContainer.prototype.logoutUser = function(opts = {}) {
	const currentMainContainer = this;

	const url = this.getLogoutUrl();
	this.hideModal();
	this.event('userLoggingOut');

	if (opts.accountCancelled) {
		currentMainContainer._apiv2.clearCredentials();
		currentMainContainer.clearCurrentUser();
		currentMainContainer.redirectToLogin(opts);
	} else {
		this._apiv2.apis.auth
			.logout()
			.then((result) => {
				console.log(result);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				currentMainContainer._apiv2.clearCredentials();
				currentMainContainer.clearCurrentUser();
				currentMainContainer.redirectToLogin(opts);
			});
	}
};

MainContainer.prototype.getLogoutUrl = function() {
	// Assume the APIV2 path first
	let url = `${getAPIBase()}/v2/auth/logout`;
	if (isPhonegap()) {
		// Is this a mobile app with a packaged remoteAPIBasePath?
		url = isURL(this._globalConfig.sendLogoutDataRouteOrUrl)
			? this._globalConfig.sendLogoutDataRouteOrUrl
			: remoteAPIBasePath + this._globalConfig.sendLogoutDataRouteOrUrl;
	} else if (this._globalConfig.sendLogoutDataRouteOrUrl) {
		// This is not a mobile app, but could be a SAML environment for example.
		url = isURL(this._globalConfig.sendLogoutDataRouteOrUrl)
			? this._globalConfig.sendLogoutDataRouteOrUrl
			: getAPIBase() + this._globalConfig.sendLogoutDataRouteOrUrl;
	}
	return url;
};

MainContainer.prototype.updateTitle = function() {
	if (
		this._globalConfig &&
		this._globalConfig.windowTitle !== undefined &&
		this._globalConfig.windowTitle !== null
	) {
		document.title = this._globalConfig.windowTitle;
	} else {
		document.title = 'Atmosphere IoT';
	}
};

MainContainer.prototype.updateIndexHtmlMetaTagDescription = function() {
	var indexHtmlMetaTagDescription = 'Atmosphere IoT Platform Web App';

	var globalConfig = this.getGlobalConfig();

	if (
		globalConfig &&
		globalConfig.indexHtmlMetaTagDescription &&
		globalConfig.indexHtmlMetaTagDescription.trim() !== ''
	) {
		indexHtmlMetaTagDescription = globalConfig.indexHtmlMetaTagDescription;
	}

	document
		.querySelector('meta[name="description"]')
		.setAttribute('content', indexHtmlMetaTagDescription);
};

MainContainer.prototype.initializeRoutesAndConfigs = function(callback) {
	var currentMainContainer = this;

	this._api.getAPIRoute('/global').get(function(err, data) {
		if (err) {
			console.error(JSON.stringify(err));
			console.error(
				'MainContainer failed to get the global config from the server',
			);
		}

		currentMainContainer._globalConfig = data || {};

		currentMainContainer.updateTitle();

		currentMainContainer.updateIndexHtmlMetaTagDescription();

		currentMainContainer._api.getRoutesFromServer(function(err) {
			if (err) {
				if (err.status !== 403) {
					console.error(JSON.stringify(err));
					console.error(
						'MainContainer failed to get API routes from server!',
					);
				}
			}

			currentMainContainer.event('ready');

			callback.call(currentMainContainer, false);
			return;
		});
	});
};

MainContainer.prototype.checkUserAgreement = function(callback) {
	callback = callback || function() {};

	var currentMainContainer = this;

	WidgetUserAgreement.prototype.hasAgreed(this, function(err, agreed) {
		if (!agreed) {
			currentMainContainer.setModalWidget(
				WidgetUserAgreement,
				{},
				function(err, agreementWidget) {
					agreementWidget.addEventListener(
						'agreementAccepted',
						function() {
							currentMainContainer.hideModal();
						},
					);

					agreementWidget.addEventListener(
						'agreementRejected',
						function() {
							currentMainContainer.logoutUser();
						},
					);

					currentMainContainer.showModal();
					callback.call(currentMainContainer);
				},
			);

			return;
		}

		callback.call(currentMainContainer);
	});
};

MainContainer.prototype.show = function() {
	// We only want to hide for iOS not Android or other platforms
	// TODO: Determine if we want to give Android version a splash screen again
	if (((navigator || {}).splashscreen || null) && device.platform === 'iOS') {
		navigator.splashscreen.hide();
	}

	WidgetBase.prototype.show.call(this);
};

MainContainer.prototype.hide = function() {
	if (((navigator || {}).splashscreen || null) && device.platform === 'iOS') {
		navigator.splashscreen.show();
	}

	WidgetBase.prototype.hide.call(this);
};

// If there is an error creating the swagger client (like there will be for unit tests)
// Keep going with the code but log it and set the api to undefined
MainContainer.prototype.initializeApiV2 = async function() {
	try {
		this._apiv2 = await new AtmosphereApi(`${getAPIBase()}/spec`);
	} catch (e) {
		console.error('Error initializing Swagger Client', e);
		// In test mode, this is expected to fail currently
		// Test mode will assign a mock API to window scope
		this._apiv2 = window._apiv2 || undefined;
	}
};

MainContainer.prototype.fetchUserContext = async function() {
	const currentMainContainer = this;
	// Get current user context
	const user = await this._apiv2.apis.auth.getCurrentUser();
	if (!user) {
		console.error('Current user is null!');
		return null;
	}

	currentMainContainer.setCurrentUser(user);

	return user;
};

/**
 *
 * @param {Boolean} opts.forward - If false, do not add current location to forward hash. Otherwise, add it.
 */
MainContainer.prototype.redirectToLogin = function(opts, callback) {
	callback = callback || function() {};
	opts = opts || {};

	const currentMainContainer = this;

	let forwardLocation = getHashCommand() || undefined;
	if (opts.forward === false) {
		forwardLocation = undefined;
	}

	currentMainContainer.setLocation(LocationLogin, {
		reload: true,
		forward: forwardLocation,
	});

	callback.call(this, false);
};

MainContainer.prototype.atLoginLocation = function() {
	const atLoginLocation =
		['login', 'registration', 'changepassword'].indexOf(
			((getHashCommand() || {}).location || '').toLowerCase(),
		) >= 0;
	return atLoginLocation;
};

/*
 * This is called by WidgetLogin to complete the login
 */
MainContainer.prototype.handleInitGetUser = function(callback) {
	const currentMainContainer = this;

	if (!currentMainContainer._apiv2) {
		callback.call(this, true);
		return;
	}

	currentMainContainer
		.fetchUserContext()
		.then((userContext) => {
			currentMainContainer.initializeRoutesAndConfigs(function(err) {
				currentMainContainer.checkUserAgreement(() => {
					callback.call(this, false);

					currentMainContainer.event('userLoggedIn');
				});
			});
		})
		.catch((err) => {
			callback.call(this, err);
			return;
		});
};

MainContainer.prototype.initialize = function(callback) {
	var currentMainContainer = this;

	this.hide();

	this._api = new CloudApi();

	this.initializeApiV2().then(() => {
		this.initializeRoutesAndConfigs(function(err) {
			this._nfcHandler = new NFCHandler(this);

			this._clientAgent = new CloudClientAgent();

			this._clientAgent.connect(
				this._globalConfig.remoteClientAgent,
				null,
				function(err) {
					currentMainContainer.handleInitGetUser(function(err) {
						if (err && !currentMainContainer.atLoginLocation()) {
							currentMainContainer.redirectToLogin();
						}

						currentMainContainer.addChildWidget(
							WidgetPopupMessage,
							currentMainContainer._popupContainerId,
							{},
							function(err, popupMessageWidget) {
								this._popupMessageWidget = popupMessageWidget;

								currentMainContainer.addChildWidget(
									WidgetNavbar,
									currentMainContainer._navbarContainerId,
									{},

									function(err, navbarWidget) {
										this._navbarWidget = navbarWidget;

										this._navbarWidget.addEventListener(
											'navbarEntrySelected',
											function(value) {
												currentMainContainer.onNavbarEntrySelected(
													value,
												);
											},
										);

										currentMainContainer.addChildWidget(
											WidgetOrganizationsTopNav,
											currentMainContainer._topNavContainerId,
											{},
											function(err, topNavWidget) {
												currentMainContainer._topNavWidget = topNavWidget;
												currentMainContainer.show();

												// We fake the first onHashChange to kick of the initialization of the default location
												currentMainContainer.onHashChange();

												$(window).resize(function() {
													currentMainContainer.onWindowResize();
												});

												// We want to make sure we have completely setup MainContainer before
												// we allow it to start servicing the hash changes in the location
												$(window).on(
													'hashchange',
													function() {
														currentMainContainer.onHashChange();
													},
												);

												// We want to handle the back button function our selves.
												if (isPhonegap()) {
													document.addEventListener(
														'backbutton',
														() => {
															currentMainContainer.onBackbutton();
														},
														false,
													);
												}
													
												// Maincontainer will send an event to let other widgets know we need to refresh our notifications
												currentMainContainer._notificationsRefreshInterval = setInterval(function() {
													currentMainContainer.event('notificationsRefreshed');
												}, 120000);
												
												//Always call your parent class initalize inside your initalize function
												WidgetBase.prototype.initialize.call(
													currentMainContainer,
													callback,
												);
											},
										);
									},
								);
							},
						);
					});
				},
			);
		});
	});
};
