function PlaneControllerEmbeddedMicrochip(parentController, elementName) {
	PlaneControllerEmbeddedBase.call(this, parentController, elementName);
}

PlaneControllerEmbeddedMicrochip.prototype = Object.create(
	PlaneControllerEmbeddedBase.prototype,
);
PlaneControllerEmbeddedMicrochip.prototype.constructor = PlaneControllerEmbeddedMicrochip;

PlaneControllerEmbeddedMicrochip.prototype.TYPE = 'Microchip';
PlaneControllerEmbeddedMicrochip.prototype.VARIANTS = [];
PlaneControllerEmbeddedMicrochip.prototype.SUPPORTS = [
	'embedded',
	'gpio',
	'adc',
	'i2c',
	'spi',
	'uart',
	'fpmath',
];
PlaneControllerEmbeddedMicrochip.prototype.SELECTABLE_VARIANTS = [];

PlaneControllerEmbeddedMicrochip.prototype.language = {
	'en-US': {
		defaultName: 'Microchip',
	},
};
