function WidgetDataGauge(id, api, parent, options) {
	
	options.gotoDevice = true;
	
	WidgetDashboardBase.call(this, id, api, parent, options);

	this.eChart = null;

	this.config = {
		deviceId: null,
		title: getLanguageTag(this.constructor, 'name'),
		storageName: null,
		variable: null,
		maxValue: 100,
		minValue: 0,
		numberOfSplits: 10,
		prefix: '',
		units: '',
		decimalPoints: 0,
		segmentStart: 20,
		segmentEnd: 80,
		segmentOneColor: 'green',
		segmentTwoColor: 'blue',
		segmentThreeColor: 'red',
		updateTime: 60000,
	};

	this._updateInterval = null;
	this._showingSettings = false;

	this.dataGaugeContainerId = this.generateChildId('dataGaugeContainer');
}

WidgetDataGauge.prototype = Object.create(WidgetDashboardBase.prototype);
WidgetDataGauge.prototype.constructor = WidgetDataGauge;

WidgetDataGauge.prototype._interval = function() {
	var currentWidget = this;

	currentWidget.update(function() {
		if (currentWidget.config.updateTime < 60000) {
			currentWidget.config.updateTime = 60000;
		}

		this._updateInterval = setTimeout(function() {
			currentWidget._interval();
		}, currentWidget.config.updateTime || 60000);
	});
};

WidgetDataGauge.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.renderTemplate(
		{
			dataGaugeContainerId: this.dataGaugeContainerId,
		},
		WidgetDataGauge.name,
	);

	this.eChart = echarts.init(
		document.getElementById(this.dataGaugeContainerId),
	);

	this._resizeEventHandler = function() {
		currentWidget.eChart.resize();
	};

	$(window).resize(this._resizeEventHandler);

	this._interval();

	WidgetDashboardBase.prototype.initialize.call(this, callback);
};

WidgetDataGauge.prototype.showSettings = function(callback) {
	callback = callback || function() {};

	var currentWidget = this;

	this.getMainContainer().setModalWidget(
		WidgetSettingsForm,
		{
			fields: [
				{
					name: 'title',
					type: 'text',
					label: getLanguageTag(this.constructor, 'title'),
					value: this.config.title,
				},

				{
					name: 'prefix',
					type: 'text',
					label: getLanguageTag(this.constructor, 'prefix'),
					value: this.config.prefix,
				},

				{
					name: 'units',
					type: 'text',
					label: getLanguageTag(this.constructor, 'units'),
					value: this.config.units,
				},

				{
					name: 'decimalPoints',
					type: 'number',
					min: 0,
					max: 5,
					label: getLanguageTag(this.constructor, 'decimalPoints'),
					value: this.config.decimalPoints,
				},

				{
					name: 'minValue',
					type: 'number',
					label: getLanguageTag(this.constructor, 'minValue'),
					value: this.config.minValue,
				},

				{
					name: 'maxValue',
					type: 'number',
					label: getLanguageTag(this.constructor, 'maxValue'),
					value: this.config.maxValue,
				},

				{
					name: 'numberOfSplits',
					type: 'number',
					label: getLanguageTag(this.constructor, 'numberOfSplits'),
					value: this.config.numberOfSplits,
				},

				{
					name: 'segmentStart',
					type: 'number',
					label: getLanguageTag(this.constructor, 'segmentStart'),
					value: this.config.segmentStart,
				},

				{
					name: 'segmentEnd',
					type: 'number',
					label: getLanguageTag(this.constructor, 'segmentEnd'),
					value: this.config.segmentEnd,
				},

				{
					name: 'segmentOneColor',
					type: 'colorSwatch',
					label: getLanguageTag(this.constructor, 'segmentOneColor'),
					value: this.config.segmentOneColor,
					options: { hideUnset: true },
				},

				{
					name: 'segmentTwoColor',
					type: 'colorSwatch',
					label: getLanguageTag(this.constructor, 'segmentTwoColor'),
					value: this.config.segmentTwoColor,
					options: { hideUnset: true },
				},

				{
					name: 'segmentThreeColor',
					type: 'colorSwatch',
					label: getLanguageTag(
						this.constructor,
						'segmentThreeColor',
					),
					value: this.config.segmentThreeColor,
					options: { hideUnset: true },
				},

				{
					name: 'updateTime',
					type: 'select',
					label: this.getLanguageTag('updateTime'),
					options: [
						{ value: 60000, label: this.getLanguageTag('1Minute') },
						{
							value: 300000,
							label: this.getLanguageTag('5Minutes'),
						},
						{
							value: 600000,
							label: this.getLanguageTag('10Minutes'),
						},
						{
							value: 900000,
							label: this.getLanguageTag('15Minutes'),
						},
						{
							value: 1800000,
							label: this.getLanguageTag('30Minutes'),
						},
						{ value: 3600000, label: this.getLanguageTag('1Hour') },
						{
							value: 7200000,
							label: this.getLanguageTag('2Hours'),
						},
						{
							value: 14400000,
							label: this.getLanguageTag('4Hours'),
						},
						{
							value: 21600000,
							label: this.getLanguageTag('6Hours'),
						},
						{
							value: 28800000,
							label: this.getLanguageTag('8Hours'),
						},
						{
							value: 43200000,
							label: this.getLanguageTag('12Hours'),
						},
					],
					value: this.config.updateTime,
				},

				{
					name: 'variable',
					type: 'deviceVariableSelect',
					label: getLanguageTag(this.constructor, 'variable'),
					value: {
						deviceId: this.config.deviceId,
						storageName: this.config.storageName,
						variables: { value: this.config.variable },
					},
					options: {
						variables: [
							{
								name: 'value',
								label: getLanguageTag(
									this.constructor,
									'value',
								),
							},
						],
					},
				},
			],
		},
		function(err, settingsWidget) {
			settingsWidget.setTitle(
				getLanguageTag(currentWidget.constructor, 'configureDataGauge'),
			);

			settingsWidget.addEventListener('dismissed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;
			});

			settingsWidget.addEventListener('confirmed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;

				var values = this.getValues();

				values.variable = values.variable || {};

				var config = {
					deviceId: (values.variable.deviceData || {}).id || null,
					storageName: values.variable.storageName || null,
					variable: (values.variable.variables || {}).value || null,
					title: values.title || '',
					decimalPoints: currentWidget.decimalPlacesFallback(
						values.decimalPoints,
					),
					maxValue: values.maxValue || 0,
					minValue: values.minValue || 0,
					numberOfSplits: values.numberOfSplits,
					segmentStart: values.segmentStart,
					segmentEnd: values.segmentEnd,
					prefix: values.prefix || '',
					units: values.units || '',
					segmentOneColor: values.segmentOneColor || 'green',
					segmentTwoColor: values.segmentTwoColor || 'blue',
					segmentThreeColor: values.segmentThreeColor || 'red',
					updateTime: values.updateTime || 60000,
				};

				currentWidget.setConfiguration(config, function() {
					this.event('configurationSet', {
						widget: this,
						configuration: this.config,
					});
				});
			});

			currentWidget._showingSettings = true;
			this.showModal();

			callback.call(this, false);
			return;
		},
	);
};

WidgetDataGauge.prototype.decimalPlacesFallback = function(value) {
	if (value === 0) {
		return 0;
	}
	if (!value) {
		return 2;
	}

	return value;
};

WidgetDataGauge.prototype.update = function(callback) {
	const currentWidget = this;
	const api = currentWidget.getApiV2();

	callback = callback || function() {};

	if (this.config.deviceId === undefined || this.config.variable === null) {
		callback.call(this, false);
		return;
	}

	api.apis.data
		.getLastDeviceData({
			id: this.config.deviceId,
			elementName: this.config.storageName,
		})
		.then((response) => {
			const data = response.values;
			// UI uses the _timestamp field.
			data._timestamp = response.updatedAt;

			currentWidget.setTitle(
				currentWidget.config.title ||
					currentWidget.getLanguageTag('name'),
			);

			var firstSegmentRatio =
				(currentWidget.config.segmentStart -
					currentWidget.config.minValue) /
				(currentWidget.config.maxValue - currentWidget.config.minValue);
			var secondSegmentRatio =
				(currentWidget.config.segmentEnd -
					currentWidget.config.minValue) /
				(currentWidget.config.maxValue - currentWidget.config.minValue);
			var thirdSegmentRatio =
				(currentWidget.config.maxValue -
					currentWidget.config.minValue) /
				(currentWidget.config.maxValue - currentWidget.config.minValue);

			var gaugeValue = Number(data[currentWidget.config.variable]);

			//If it's not a number then make it a blank string
			if (isNaN(gaugeValue)) {
				gaugeValue = '';
			} else {
				gaugeValue = gaugeValue.toFixed(
					currentWidget.config.decimalPoints,
				);
			}

			currentWidget.eChart.setOption(
				{
					tooltip: {
						formatter: `${currentWidget.config.prefix} {c} ${currentWidget.config.units}`,
					},
					series: [
						{
							type: 'gauge',
							min: currentWidget.config.minValue,
							max: currentWidget.config.maxValue,
							splitNumber:
								currentWidget.config.numberOfSplits || -1,
							axisLine: {
								lineStyle: {
									color: [
										[
											firstSegmentRatio,
											getComputedStyle(
												window.document.body,
											).getPropertyValue(
												`--${currentWidget.config.segmentOneColor}`,
											),
										],
										[
											secondSegmentRatio,
											getComputedStyle(
												window.document.body,
											).getPropertyValue(
												`--${currentWidget.config.segmentTwoColor}`,
											),
										],
										[
											thirdSegmentRatio,
											getComputedStyle(
												window.document.body,
											).getPropertyValue(
												`--${currentWidget.config.segmentThreeColor}`,
											),
										],
									],
									width: 12,
								},
							},
							axisTick: {
								splitNumber: 10,
								length: 16,
								lineStyle: {
									color: 'auto',
								},
							},
							axisLabel: {
								textStyle: {
									color: 'auto',
									fontFamily: getComputedStyle(
										window.document.body,
									).getPropertyValue('--primaryFontFamily'),
								},

								formatter: function(v) {
									return v.toFixed(
										currentWidget.config.decimalPoints,
									);
								},
							},
							splitLine: {
								show: true,
								length: 30,
								lineStyle: {
									color: 'auto',
								},
							},
							pointer: {
								width: 5,
							},
							title: {
								show: false,
								offsetCenter: [0, 0],
								textStyle: {
									fontWeight: 'bolder',
									fontFamily: getComputedStyle(
										window.document.body,
									).getPropertyValue('--primaryFontFamily'),
								},
							},
							detail: {
								formatter: `${currentWidget.config.prefix} {value} ${currentWidget.config.units}`,
								offsetCenter: [0, 120],
								textStyle: {
									color: 'auto',
									fontFamily: getComputedStyle(
										window.document.body,
									).getPropertyValue('--primaryFontMedium'),
								},
							},
							data: [
								{
									value: gaugeValue,
									name: currentWidget.config.variable,
								},
							],
						},
					],
				},
				true,
			);

			callback.call(currentWidget, false);
		})
		.catch((error) => {
			console.warn(
				`[${this.constructor.name}] Error while calling update()!`,
			);
			callback.call(currentWidget, error);
			return;
		});
};

WidgetDataGauge.prototype.remove = function(callback) {
	$(window).off('resize', this._resizeEventHandler);

	this.eChart.dispose();

	if (this._updateInterval) {
		clearTimeout(this._updateInterval);
	}

	if (this._showingSettings) {
		this.getMainContainer().hideModal();
	}

	WidgetDashboardBase.prototype.remove.call(this, callback);
};

WidgetDataGauge.prototype.setConfiguration = function(config, callback) {
	var currentWidget = this;

	callback = callback || function() {};

	this.config = {
		deviceId: config.deviceId || null,
		storageName: config.storageName || null,
		variable: config.variable || null,
		title: config.title || getLanguageTag(this.constructor, 'name'),
		decimalPoints: this.decimalPlacesFallback(config.decimalPoints),
		maxValue: config.maxValue || 0,
		minValue: config.minValue || 0,
		segmentStart: config.segmentStart,
		segmentEnd: config.segmentEnd,
		numberOfSplits: config.numberOfSplits,
		prefix: config.prefix || '',
		units: config.units || '',
		segmentOneColor: config.segmentOneColor || 'green',
		segmentTwoColor: config.segmentTwoColor || 'blue',
		segmentThreeColor: config.segmentThreeColor || 'red',
		updateTime: config.updateTime || 60000,
	};

	if (this._updateInterval) {
		clearTimeout(this._updateInterval);
	}

	this.update(function(err) {
		this._interval();
		callback.call(this, err);
	});
};

WidgetDataGauge.prototype.getConfiguration = function() {
	return this.config;
};

WidgetDataGauge.prototype.PACKERY_SIZE = 'WidgetDashboard_Container_MxM';

WidgetDataGauge.prototype.GAUGE_COLOR_WHEEL = [
	'red',
	'orange',
	'yellow',
	'green',
	'blue',
	'purple',
	'pink',
	'gray',
	'teal',
];

WidgetDataGauge.prototype.language = deepAssign(
	{},
	WidgetDashboardBase.prototype.language,
	{
		'en-US': {
			name: 'Data Gauge',
			configureDataGauge: 'Configure Data Gauge',
			prefix: 'Prefix',
			units: 'Unit',
			minValue: 'Min',
			maxValue: 'Max',
			value: 'Select Value',
			variable: 'Data Source',
			numberOfSplits: 'Number of Splits',
			decimalPoints: 'Number of Decimal Places',
			segmentStart: 'Middle Segment Start',
			segmentEnd: 'Middle Segment End',
			segmentOneColor: 'Start Segment Color',
			segmentTwoColor: 'Middle Segment Color',
			segmentThreeColor: 'End Segment Color',
			updateTime: 'Update Interval',
			'1Minute': '1 Minute',
			'5Minutes': '5 Minutes',
			'10Minutes': '10 Minutes',
			'15Minutes': '15 Minutes',
			'30Minutes': '30 Minutes',
			'1Hour': '1 Hour',
			'2Hours': '2 Hours',
			'4Hours': '4 Hours',
			'6Hours': '6 Hours',
			'8Hours': '8 Hours',
			'12Hours': '12 Hours',
		},
	},
);
