function ElementControllerAppUIBase(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	//Layouts are stored in each element as a double nested object.
	// First key is width of the screen in pixels,
	// Subkey is height
	// So if you want to get the layout width for the element at 1024x768 you would access
	// layout[1024][768].width;
	this.addProperty('layouts', null, {}, 'layouts', null, null);

	this.addProperty('visible', null, true, 'checkbox', null, null);

	this.addAbility('hide', []);
	this.addAbility('show', []);
	this.addAbility('setVisible', [{ name: 'visible', validator: null }]);
}

ElementControllerAppUIBase.prototype = Object.create(
	ElementControllerBase.prototype,
);
ElementControllerAppUIBase.prototype.constructor = ElementControllerAppUIBase;

ElementControllerAppUIBase.prototype.TYPE = 'AppUIBase';
ElementControllerAppUIBase.prototype.HIDDEN = true;
ElementControllerAppUIBase.prototype.CATEGORY = 'appUI';
ElementControllerAppUIBase.prototype.VARIANTS = ['app', 'ui'];

ElementControllerAppUIBase.prototype.language = deepAssign(
	{},
	ElementControllerBase.prototype.language,
	{
		'en-US': {
			AppUIBase: 'UI Base',
			layouts: 'Layouts',
			visible: 'Visible',
			hide: 'Hide',
			show: 'Show',
			setVisible: 'Set Visible',
			visible: 'Visible',
		},
	},
);
