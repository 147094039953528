function PlaneControllerAppView(parentController, elementName) {
	PlaneControllerBase.call(this, parentController, elementName);

	this._types = ['base', 'app'];
}

PlaneControllerAppView.prototype = Object.create(PlaneControllerBase.prototype);
PlaneControllerAppView.prototype.constructor = PlaneControllerAppView;

PlaneControllerAppView.prototype.TYPE = 'app';
PlaneControllerAppView.prototype.SUPPORTS = ['app', 'ui', 'ble', 'mobile'];
PlaneControllerAppView.prototype.EXTRA_TOOLS = ['WidgetAppViewEditor'];

PlaneControllerAppView.prototype.COUPLING_BADGE = 'applicationBadge';

PlaneControllerAppView.prototype.PLANE_ICON =
	'./Resources/icons/ApplicationView.svg';

PlaneControllerAppView.prototype.export = function() {
	var planeExportData = PlaneControllerBase.prototype.export.call(this);

	return planeExportData;
};

PlaneControllerAppView.prototype.language = {
	'en-US': {
		defaultName: 'Application',
	},
};
