function ElementAppUILabel(
	elementName,
	parentApp,
	requires,
	properties,
	triggers,
	controller,
) {
	ElementAppUIBase.call(
		this,
		elementName,
		parentApp,
		requires,
		properties,
		triggers,
		controller,
	);

	var currentElement = this;

	this._setCrocObject(
		new CrocLabel(
			this._crocPanel.getRoot(),
			this._properties.text,
			this._properties.font,
			this._properties.color,
		),
	);

	this._crocObject.setWrapping('word');

	this._onPropertyChange();
}

ElementAppUILabel.prototype = Object.create(ElementAppUIBase.prototype);
ElementAppUILabel.prototype.constructor = ElementAppUILabel;
ElementAppUILabel.prototype.ALIGNMENT_TO_CROC = {
	left: 'left',
	right: 'right',
	center: 'center',
};

ElementAppUILabel.prototype._onPropertyChange = function() {
	ElementAppUIBase.prototype._onPropertyChange.call(this);

	this._crocObject.setText(this._properties.text);
	this._crocObject.setFont(this._properties.font);
	this._crocObject.setColor(this._properties.color);
	this._crocObject.setAlignment(this._properties.alignment);
};

ElementAppUILabel.prototype.setText = function(text, callback) {
	text = this._convertToString(text);

	this._setProperty('text', text, function(err) {
		this._crocObject.setText(text);
		callback.call(this, err);
		return;
	});
};

ElementAppUILabel.prototype.setFont = function(font, callback) {
	font = this._convertToFont(font);

	this._setProperty('font', font, function(err) {
		this._crocObject.setFont(font);
		callback.call(this, err);
		return;
	});
};

ElementAppUILabel.prototype.setColor = function(color, callback) {
	color = this._convertToColor(color);

	this._setProperty('color', color, function(err) {
		this._crocObject.setColor(color);
		callback.call(this, err);
		return;
	});
};

ElementAppUILabel.prototype.setAlignment = function(alignment, callback) {
	var crocAlignment = this.ALIGNMENT_TO_CROC[alignment];

	if (crocAlignment === undefined) {
		callback.call(this, { type: 'invalidAlignment' });
		return;
	}

	this._setProperty('alignment', alignment, function(err) {
		this._crocObject.setAlignment(crocAlignment, 'start');
		this.trigger('alignmentSet', callback);
		return;
	});
};

ElementAppUILabel.prototype.setAlignmentLeft = function(callback) {
	this.setAlignment('left', callback);
};

ElementAppUILabel.prototype.setAlignmentRight = function(callback) {
	this.setAlignment('right', callback);
};

ElementAppUILabel.prototype.setAlignmentCenter = function(callback) {
	this.setAlignment('center', callback);
};
