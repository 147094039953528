function buildSandBoxExecuteBlobData(code) {
	if (code === '' || code === null || code === undefined) {
		code = 'null';
	}

	var blobData = new Blob(
		[
			'function _f(){return (' + code + '\n);}\n\n',
			'(',
			function() {
				var _postMessage = postMessage;
				var _addEventListener = addEventListener;

				_addEventListener('message', function(_e) {
					if (_e.data === null || _e.data === '') {
						_postMessage(undefined);
					}

					for (var k in _e.data) {
						self[k] = _e.data[k];
					}

					_postMessage(_f());
				});
			}.toString(),
			')()',
		],
		{
			type: 'application/javascript',
		},
	);

	return blobData;
}

function buildExecuteCodeBlobWorker(untrustedCode) {
	var blobURL = URL.createObjectURL(
		buildSandBoxExecuteBlobData(untrustedCode),
	);

	var worker = new Worker(blobURL);

	URL.revokeObjectURL(blobURL);

	return worker;
}

function executeCodeBlobWorker(worker, sandBox) {
	return new Promise(function(resolve, reject) {
		worker.onmessage = function(evt) {
			resolve({
				data: evt.data,
			});
		};

		worker.onerror = function(evt) {
			resolve({
				err: evt.message,
				worker: worker,
				sandBox: sandBox,
			});
		};

		worker.postMessage(sandBox);
	});
}

function buildCodeEvalTestWorker() {
	var blobData = new Blob(
		[
			'(',
			function() {
				var _postMessage = postMessage;
				var _addEventListener = addEventListener;
				var _workerQueue = [];

				delete self.addEventListener;
				delete self.postMessage;

				_addEventListener('message', function(_e) {
					var k;
					if (_e.data === null || _e.data === '') {
						_postMessage(undefined);
					}

					for (k in _e.data.sandbox) {
						self[k] = _e.data.sandbox[k];
					}

					if (
						_e.data.code === '' ||
						_e.data.code === null ||
						_e.data.code === undefined
					) {
						_e.data.code = 'null';
					}

					eval('_f = function(){return (' + _e.data.code + ');}');

					var _value = null;
					var _err = null;

					try {
						_value = _f();
					} catch (err) {
						_err = err.message;
					}

					_postMessage({ id: _e.data.id, err: _err, value: _value });

					for (k in self) {
						if (
							k !== '_postMessage' ||
							k !== '_addEventListener' ||
							k !== 'eval'
						) {
							delete self[k];
						}
					}
				});
			}.toString(),
			')()',
		],
		{
			type: 'application/javascript',
		},
	);

	var blobURL = URL.createObjectURL(blobData);

	var worker = new Worker(blobURL);

	URL.revokeObjectURL(blobURL);

	return worker;
}

function codeEvalTest(untrustedCode, sandbox) {
	if (window.codeEvalTestWorkerMessageIdToPromiseResolve === undefined) {
		window.codeEvalTestWorkerMessageIdToPromiseResolve = {};
		window.codeEvalTestWorkerMessageIdToPromiseReject = {};
	}

	var messageId = generateUUID();

	if (window.codeEvalTestWorker === undefined) {
		window.codeEvalTestWorker = buildCodeEvalTestWorker();

		codeEvalTestWorker.onmessage = function(evt) {
			if (
				window.codeEvalTestWorkerMessageIdToPromiseResolve[
					evt.data.id
				] === undefined
			) {
				return;
			}

			if (evt.data.err) {
				window.codeEvalTestWorkerMessageIdToPromiseReject[evt.data.id]({
					err: evt.data.err,
					sandbox: sandbox,
				});
			} else {
				window.codeEvalTestWorkerMessageIdToPromiseResolve[evt.data.id](
					{
						data: evt.data.value,
					},
				);
			}

			delete window.codeEvalTestWorkerMessageIdToPromiseResolve[
				evt.data.id
			];
			delete window.codeEvalTestWorkerMessageIdToPromiseReject[
				evt.data.id
			];
		};

		codeEvalTestWorker.onerror = function(evt) {
			window.codeEvalTestWorkerMessageIdToPromiseReject[messageId]({
				err: evt.message,
				sandbox: sandbox,
			});
		};
	}

	var promise = new Promise(function(resolve, reject) {
		window.codeEvalTestWorkerMessageIdToPromiseResolve[messageId] = resolve;
		window.codeEvalTestWorkerMessageIdToPromiseReject[messageId] = reject;
	});

	codeEvalTestWorker.postMessage({
		sandbox: sandbox,
		code: untrustedCode,
		id: messageId,
	});

	return promise;
}
