function PlaneControllerCloud(parentController, elementName) {
	PlaneControllerBase.call(this, parentController, elementName);
}

PlaneControllerCloud.prototype = Object.create(PlaneControllerBase.prototype);
PlaneControllerCloud.prototype.constructor = PlaneControllerCloud;

PlaneControllerCloud.prototype.TYPE = 'cloud';

PlaneControllerCloud.prototype.SUPPORTS = ['cloud', 'aws'];

PlaneControllerCloud.prototype.COUPLING_BADGE = 'cloudBadge';

PlaneControllerCloud.prototype.PLANE_ICON = './Resources/icons/CloudView.svg';

PlaneControllerCloud.prototype.setDefaultDeviceDashboardConfig = function(
	config,
) {
	this.setMetaValue('LocationDeviceDashboard', config);
	return;
};

PlaneControllerCloud.prototype.export = function() {
	var planeExportData = PlaneControllerBase.prototype.export.call(this);

	planeExportData.layout = {};

	planeExportData.layout.height = 1000;
	planeExportData.layout.width = 1000;
	planeExportData.layout.zIndexOrder = [];

	return planeExportData;
};

PlaneControllerCloud.prototype.language = {
	'en-US': {
		defaultName: 'Cloud',
	},
};
