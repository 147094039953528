function ElementControllerEmbeddedOnOffDisplay(
	parentController,
	parentPlane,
	elementName,
	numMeasurements,
) {
	ElementControllerEmbeddedPageTemplateBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.setProperty('bottomLeftButtonLabel', 'Toggle');
	this.setProperty('bottomLeftButtonEnabled', true);
	this.addProperty(
		'icon',
		null,
		this.iconList[0],
		'select',
		this.iconList,
		null,
	);
	this.setPropertyEmbeddedConversion('label', 'string');
	this.addProperty('initialValue', null, true, 'checkbox', null, null);
	this.addProperty('persist', null, true, 'checkbox', null, null);
	this.addProperty('differentStartup', null, false, 'checkbox', null, null);

	// Hide the properties that the users don't care about for this element
	this.setPropertyHidden('bottomLeftButtonLabel');
	this.setPropertyHidden('bottomLeftButtonEnabled');

	this.addAbility('onDisplayed', [], ['onDisplayed'], false, true);
	this.setAbilityCode(
		'onDisplayed',
		`
	return ATMO_Status_Success;
    `,
	);

	this.addAbility(
		'topRightButtonPressed',
		[],
		['topRightButtonPressed'],
		false,
		true,
	);
	this.setAbilityCode(
		'topRightButtonPressed',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility(
		'bottomRightButtonPressed',
		[],
		['bottomRightButtonPressed'],
		false,
		true,
	);
	this.setAbilityCode(
		'bottomRightButtonPressed',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility(
		'topLeftButtonPressed',
		[],
		['topLeftButtonPressed'],
		false,
		true,
	);
	this.setAbilityCode(
		'topLeftButtonPressed',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
    ATMO_UI_PAGE_Config_t config;
    config.hidden = ATMO_PROPERTY(undefined, pageHidden);
    config.textColor = ATMO_PROPERTY(undefined, textColor);
	config.activeButtons = ATMO_UI_Page_GetButtonMask(ATMO_PROPERTY(undefined, topRightButtonEnabled),
		ATMO_PROPERTY(undefined,bottomRightButtonEnabled), ATMO_PROPERTY(undefined, topLeftButtonEnabled), ATMO_PROPERTY(undefined, bottomLeftButtonEnabled));
	config.x = ATMO_PROPERTY(undefined, x);
    config.y = ATMO_PROPERTY(undefined, y);
	strncpy(config.topLeftButtonLabel, ATMO_PROPERTY(undefined, topLeftButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
	strncpy(config.topRightButtonLabel, ATMO_PROPERTY(undefined, topRightButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
	strncpy(config.bottomLeftButtonLabel, ATMO_PROPERTY(undefined, bottomLeftButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
	strncpy(config.bottomRightButtonLabel, ATMO_PROPERTY(undefined, bottomRightButtonLabel), ATMO_BUTTON_LABEL_MAXLEN);
    config.spanX = ATMO_PROPERTY(undefined, spanX);
	config.spanY = ATMO_PROPERTY(undefined, spanY);
    config.title = ATMO_PROPERTY(undefined, pageTitle);
    config.titleHidden = ATMO_PROPERTY(undefined, titleHidden);
    ATMO_UI_SELECTICON_Init(&config, 2, true, ATMO_PROPERTY(undefined, persist), ATMO_PROPERTY(undefined, differentStartup));
    ATMO_VARIABLE(undefined, pageHandle) = config.templateInstance;
    ATMO_UI_SELECTICON_SetIcon(config.templateInstance, ATMO_PROPERTY(undefined,icon));
    ATMO_UI_SELECTICON_SetOptionText(config.templateInstance, 0, "Off", !ATMO_PROPERTY(undefined, initialValue));
    ATMO_UI_SELECTICON_SetOptionText(config.templateInstance, 1, "On", ATMO_PROPERTY(undefined, initialValue));
    ATMO_UI_SELECTICON_RegisterOptionSelectedAbilityHandle(config.templateInstance, 0, ATMO_ABILITY(undefined, offSet));
    ATMO_UI_SELECTICON_RegisterOptionSelectedAbilityHandle(config.templateInstance, 1, ATMO_ABILITY(undefined, onSet));
    ATMO_UI_SELECTICON_RegisterOptionSelectedStartupAbilityHandle(config.templateInstance, 0, ATMO_ABILITY(undefined, offSetStartup));
    ATMO_UI_SELECTICON_RegisterOptionSelectedStartupAbilityHandle(config.templateInstance, 1, ATMO_ABILITY(undefined, onSetStartup));
    ATMO_UI_SELECTICON_RegisterButtonAbilityHandle(ATMO_VARIABLE(undefined,pageHandle), 1, ATMO_ABILITY(undefined, topRightButtonPressed));
	ATMO_UI_SELECTICON_RegisterButtonAbilityHandle(ATMO_VARIABLE(undefined,pageHandle), 2, ATMO_ABILITY(undefined, bottomRightButtonPressed));
	ATMO_UI_SELECTICON_RegisterButtonAbilityHandle(ATMO_VARIABLE(undefined,pageHandle), 3, ATMO_ABILITY(undefined, topLeftButtonPressed));
    ATMO_UI_SELECTICON_RegisterOnDisplayedAbilityHandle(ATMO_VARIABLE(undefined,pageHandle), ATMO_ABILITY(undefined, onDisplayed));
    ATMO_UI_SELECTICON_OverlaySaved(ATMO_VARIABLE(undefined, pageHandle));
    return ATMO_Status_Success;
    `,
	);

	this.addAbility('setOff', [], ['offSet']);
	this.setAbilityCode(
		'setOff',
		`
    ATMO_UI_SELECTICON_SetOption(ATMO_VARIABLE(undefined,pageHandle), 0, true);
    ATMO_CreateValueBool(out, false);
    return ATMO_Status_Success;
    `,
	);

	this.addAbility('setOn', [], ['setOn']);
	this.setAbilityCode(
		'setOn',
		`
    ATMO_UI_SELECTICON_SetOption(ATMO_VARIABLE(undefined,pageHandle), 0, false);
    ATMO_CreateValueBool(out, true);
    return ATMO_Status_Success;
    `,
	);

	this.addAbility('offSet', [], ['offSet'], false, true);
	this.setAbilityCode(
		'offSet',
		`
    ATMO_CreateValueBool(out, false);
    return ATMO_Status_Success;
    `,
	);

	this.addAbility('onSet', [], ['onSet'], false, true);
	this.setAbilityCode(
		'onSet',
		`
    ATMO_CreateValueBool(out, true);
    return ATMO_Status_Success;
    `,
	);

	this.addAbility('offSetStartup', [], ['offSetStartup'], false, true);
	this.setAbilityCode(
		'offSet',
		`
    ATMO_CreateValueBool(out, false);
    return ATMO_Status_Success;
    `,
	);

	this.addAbility('onSetStartup', [], ['onSetStartup'], false, true);
	this.setAbilityCode(
		'onSet',
		`
    ATMO_CreateValueBool(out, true);
    return ATMO_Status_Success;
    `,
	);
}

ElementControllerEmbeddedOnOffDisplay.prototype = Object.create(
	ElementControllerEmbeddedPageTemplateBase.prototype,
);
ElementControllerEmbeddedOnOffDisplay.prototype.constructor = ElementControllerEmbeddedOnOffDisplay;

ElementControllerEmbeddedOnOffDisplay.prototype.DEFAULT_TRIGGER = 'setOn';
ElementControllerEmbeddedOnOffDisplay.prototype.DEFAULT_ABILITY = 'setOn';
ElementControllerEmbeddedOnOffDisplay.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerEmbeddedOnOffDisplay.prototype.TYPE = 'EmbeddedOnOffDisplay';
ElementControllerEmbeddedOnOffDisplay.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'rpk',
];
ElementControllerEmbeddedOnOffDisplay.prototype.HIDDEN = false;
ElementControllerEmbeddedOnOffDisplay.prototype.CATEGORY = 'Rapid IOT';

ElementControllerEmbeddedOnOffDisplay.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedOnOffDisplay.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedPageTemplateBase.prototype.language,
	{
		'en-US': {
			NULL: 'No Icon',
			EmbeddedOnOffDisplay: 'On/Off Page',
			valueChanged: 'Value Changed',
			initialValue: 'Initial Value',
			label: 'Label',
			setOff: 'Select Off',
			setOn: 'Select On',
			onSet: 'On Selected',
			offSet: 'Off Selected',
			onSetStartup: 'On Selected (Startup)',
			offSetStartup: 'Off Selected (Startup)',
			persist: 'Persist Across Reboot',
			differentStartup: 'Different Startup Behavior',
		},
	},
);
