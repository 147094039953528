function WidgetTrailMap(id, api, parent, options) {
	var currentWidget = this;

	options = options || {};

	if (options.readOnly) {
		options.menuOptions = options.menuOptions || [
			{ label: this.getLanguageTag('help'), value: 'help' },
		];
	} else {
		options.menuOptions = options.menuOptions || [
			{ label: this.getLanguageTag('settings'), value: 'settings' },
			{
				label: this.getLanguageTag('rangeControl'),
				value: 'rangeControl',
			},
			{ label: this.getLanguageTag('help'), value: 'help' },
			{ label: this.getLanguageTag('remove'), value: 'remove' },
		];
	}

	options.gotoDevice = true;

	WidgetDashboardBase.call(this, id, api, parent, options);

	this.clickListener = null;
	this._updateInterval = null;

	this._fixesLoaded = false;
	this.markers = [];
	this.paths = [];

	this.config = {
		title: getLanguageTag(this.constructor, 'name'),
		deviceId: null,
		updateTime: 60000,
		storageName: null,
		latVariable: null,
		lonVariable: null,
		timeVariable: null,
		variable: null,
	};
}

WidgetTrailMap.prototype = Object.create(WidgetDashboardBase.prototype);
WidgetTrailMap.prototype.constructor = WidgetTrailMap;

WidgetTrailMap.prototype.initialize = function(callback) {
	var currentWidget = this;

	this.infoWindow = new google.maps.InfoWindow();

	var lat = 0.0;
	var lon = 0.0;

	this.zoomListener = null;
	this.mapId = this.generateChildId('map');
	this.getDirectionsButtonId = this.generateChildId('getDirectionsButton');
	this.noDevicesMsgId = this.generateChildId('noDevicesMsgId');

	this.renderTemplate(
		{
			mapId: this.mapId,
		},
		WidgetTrailMap.name,
	);

	this.map = $('#' + this.mapId);

	var center = new google.maps.LatLng(lat, lon);

	this.googleMap = new google.maps.Map(document.getElementById(this.mapId), {
		zoom: 3,
		center: center,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		gestureHandling: 'cooperative',
	});

	this.googleMap.controls[google.maps.ControlPosition.TOP_CENTER].push(
		document.getElementById(this.getDirectionsButtonId),
	);
	this.googleMap.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(
		document.getElementById(this.noDevicesMsgId),
	);

	this.map.bind('DOMSubtreeModified', function(e) {
		requestAnimationFrame(function() {
			if (!currentWidget._fixesLoaded) {
				// NH-420 Fix, we change the target from _blank to _system for all anchors with href
				var numberOfAnchorsFound = currentWidget.map.find('a').length;

				currentWidget.map.find('a').each(function(i, val) {
					if (numberOfAnchorsFound >= 4) {
						currentWidget._fixesLoaded = true;
					}

					$(val).attr('target', '_system');

					if (isPhonegap()) {
						$(val).hide();
					}
				});
			}
		});
	});

	this.setTitle(
		this.config.title ||
			this.getOptions().title ||
			getLanguageTag(WidgetTrailMap, 'name'),
	);

	// https://koliada.atlassian.net/browse/AI-3371
	setTimeout(function() {
		requestAnimationFrame(function() {
			currentWidget.update(true, function(err) {
				if (currentWidget._updateInterval) {
					clearInterval(currentWidget._updateInterval);
					currentWidget._updateInterval = null;
				}

				currentWidget._updateInterval = setInterval(function() {
					if (currentWidget.config.updateTime < 60000) {
						currentWidget.config.updateTime = 60000;
					}

					if (currentWidget.isVisible && isActive()) {
						currentWidget.update(false, function(err) {});
					}
				}, currentWidget.config.updateTime || 60000);
			});
		});
	}, 200);

	this.addEventListener('optionSelected', function(option) {
		if (option === 'rangeControl') {
			this.showRangeControl();
		}
	});

	WidgetDashboardBase.prototype.initialize.call(this, callback);
};

WidgetTrailMap.prototype.remove = function(callback) {
	if (this._updateInterval !== null) {
		clearInterval(this._updateInterval);
		this._updateInterval = null;
	}

	WidgetDashboardBase.prototype.remove.call(this, callback);
};

// Gets 1 page of 10000 points
// returned via the provided query to the v2 storage API.
WidgetTrailMap.prototype.dataBy = async function(query) {
	const api = this.getApiV2();
	const response = await api.apis.data.getDeviceData(query);
	const promises = [];

	for (let i = 2; i <= response.meta.totalPages; i++) {
		query.page = i;

		promises.push(api.apis.data.getDeviceData(query));
	}

	const pages = await Promise.all(promises);

	pages.forEach((page) => {
		response.data.push(...page.data);
	});

	return response.data;
};

WidgetTrailMap.prototype.update = function(autoCenter, callback) {
	var currentWidget = this;

	callback = callback || function() {};
	var autoCenter = autoCenter || false;
	var command = getHashCommand() || {};

	this.setTitle(
		this.config.title ||
			this.getOptions().title ||
			getLanguageTag(WidgetTrailMap, 'name'),
	);

	this.updateMap(true, function(err) {
		callback.call(currentWidget, err);
	});
};

WidgetTrailMap.prototype.updateMap = function(autoCenter, callback) {
	const currentWidget = this;

	// clean state of map
	this.clearAllMarkers();
	this.clearAllPaths();

	// decide to autoCenter
	autoCenter = autoCenter || false;

	// get the bounds
	var bounds = new google.maps.LatLngBounds();

	// check for mandatory api params
	if (!this.config.deviceId || !this.config.storageName) {
		callback.call(this, false);
		return;
	}

	// build dateRange for query
	let startDate;
	let endDate;

	if (this.config.dateRange !== 'customRange') {
		startDate = new Date(Date.now() - this.config.dateRange * 1000);
		endDate = new Date(Date.now());
	} else {
		startDate = new Date(this.config.customRange.startDate);
		endDate = new Date(this.config.customRange.endDate);
	}

	// build dataBy query
	const query = {
		id: this.config.deviceId,
		elementName: this.config.storageName,
		sortBy: `-${this.config.timeVariable}`,
		limit: 10000,
		from: startDate.toISOString(),
		to: endDate.toISOString(),
	};

	// create locationClicked listener
	if (this.clickListener === null) {
		this.clickListener = google.maps.event.addListener(
			this.googleMap,
			'click',
			function(event) {
				currentWidget.event('locationClicked', {
					lat: event.latLng.lat(),
					lon: event.latLng.lng(),
				});
			},
		);
	}

	// call dataBy -- remove the device call you dont need it
	this.dataBy(query)
		.then((storedValues) => {
			const trailData = storedValues.map((storedValue) => {
				const obj = storedValue.payload;
				// The UI uses the _timestamp field
				// for the server side timestamp,
				// which API V2 returns under the
				// createdAt field.
				obj._timestamp = storedValue.createdAt;
				return obj;
			});

			var finalDataPoints = [];
			var data = trailData || []; //If we don't get data back then work with an empty list instead

			for (var i = 0; i < data.length; i++) {
				var currentPoint = {};
				var parseLat = parseFloat(
					data[i][currentWidget.config.latVariable],
				);
				var parseLng = parseFloat(
					data[i][currentWidget.config.lonVariable],
				);

				if (isNaN(parseLat) || isNaN(parseLng)) {
					continue;
				}

				let redLevel = Math.round(255 - (i / data.length) * 255);
				let greenLevel = Math.round(0 + (i / data.length) * 255);

				function padHex(number, length) {
					var str = '' + number.toString(16);
					while (str.length < length) {
						str = '0' + str;
					}

					return str;
				}

				currentPoint.color =
					'#' + padHex(redLevel, 2) + padHex(greenLevel, 2) + '00';
				currentPoint.loc = {};
				currentPoint.loc.lat = parseLat;
				currentPoint.loc.lng = parseLng;
				currentPoint.loc.time =
					data[i][currentWidget.config.timeVariable];

				finalDataPoints.push(currentPoint);
			}

			for (var i = 0; i < finalDataPoints.length; i++) {
				var latLng = new google.maps.LatLng(
					finalDataPoints[i].loc.lat,
					finalDataPoints[i].loc.lng,
				);

				var pinImageUrl = `./Widgets/WidgetTrailMap/Resources/mapPinIcons/small.png`;

				// All markers should be zindex 0 except the final data point
				let markerZIndex = 0;

				// If it's the last data point
				if (i === finalDataPoints.length - 1) {
					// Give it a special pin
					pinImageUrl = `./Widgets/WidgetTrailMap/Resources/mapPinIcons/small_latest.png`;
					// Set it above all other pins
					markerZIndex = 1;
				}

				var pinImage = {
					url: pinImageUrl,
					size: new google.maps.Size(18, 18),
					origin: new google.maps.Point(0, 0),
					anchor: new google.maps.Point(9, 9),
				};

				var marker = new google.maps.Marker({
					position: latLng,
					map: currentWidget.googleMap,
					icon: pinImage,
					zIndex: markerZIndex,
				});

				marker.setValues({
					loc: finalDataPoints[i].loc,
				});

				google.maps.event.addListener(marker, 'click', async function(
					e,
				) {
					currentWidget.infoWindow.setContent(
						$.handlebarTemplates.WidgetTrailMap_InfoWindow({
							entries: [
								{
									name: currentWidget.getLanguageTag('time'),
									value: `${new Date(
										this.get('loc').time,
									).toLocaleDateString()}, ${new Date(
										this.get('loc').time,
									).toLocaleTimeString()}`,
								},
							],
						}),
					);

					currentWidget.infoWindow.open(
						currentWidget.googleMap,
						this,
					);
				});

				currentWidget.markers.push(marker);
				bounds.extend(marker.position);

				if (finalDataPoints[i + 1] === undefined) {
					break;
				}

				var path = new google.maps.Polyline({
					path: [finalDataPoints[i].loc, finalDataPoints[i + 1].loc],
					geodesic: true,
					strokeColor: finalDataPoints[i + 1].color,
					strokeOpacity: 1.0,
					strokeWeight: 2,
				});

				path.setMap(currentWidget.googleMap);
				currentWidget.paths.push(path);
			}

			// We only want to reset the zoom once and then ignore this after each update.
			if (currentWidget.zoomListener === null || autoCenter) {
				// If there is more than 1 marker on the map. The map should
				// not set the zoom level, this ensures all the markers will be
				// visible on the map.
				currentWidget.googleMap.fitBounds(bounds);
				currentWidget.zoomListener = true;
			}

			// signal end of update
			callback.call(currentWidget, false);
		})
		.catch((error) => {
			console.warn(
				`[${currentWidget.constructor.name}] Error occurred while calling update!`,
			);
			console.error(error);
			callback.call(currentWidget, error);
			return;
		});
};

WidgetTrailMap.prototype.showSettings = function(callback) {
	callback = callback || function() {};
	var currentWidget = this;

	this.getMainContainer().setModalWidget(
		WidgetSettingsForm,
		{
			fields: [
				{
					name: 'title',
					type: 'text',
					label: getLanguageTag(this.constructor, 'title'),
					value: this.config.title,
				},
				{
					name: 'updateTime',
					type: 'select',
					label: this.getLanguageTag('updateTime'),
					options: [
						{ value: 60000, label: this.getLanguageTag('1Minute') },
						{
							value: 300000,
							label: this.getLanguageTag('5Minutes'),
						},
						{
							value: 600000,
							label: this.getLanguageTag('10Minutes'),
						},
						{
							value: 900000,
							label: this.getLanguageTag('15Minutes'),
						},
						{
							value: 1800000,
							label: this.getLanguageTag('30Minutes'),
						},
						{ value: 3600000, label: this.getLanguageTag('1Hour') },
						{
							value: 7200000,
							label: this.getLanguageTag('2Hours'),
						},
						{
							value: 14400000,
							label: this.getLanguageTag('4Hours'),
						},
						{
							value: 21600000,
							label: this.getLanguageTag('6Hours'),
						},
						{
							value: 28800000,
							label: this.getLanguageTag('8Hours'),
						},
						{
							value: 43200000,
							label: this.getLanguageTag('12Hours'),
						},
					],
					value: this.config.updateTime,
				},

				{
					name: 'variable',
					type: 'deviceVariableSelect',
					label: this.getLanguageTag('variable'),
					value: {
						deviceId: this.config.deviceId,
						storageName: this.config.storageName,
						variables: { value: this.config.variable },
					},
					options: {
						variables: [
							{
								name: 'timeVariable',
								label: this.getLanguageTag('timeVariable'),
							},

							{
								name: 'lonVariable',
								label: this.getLanguageTag('lonVariable'),
							},

							{
								name: 'latVariable',
								label: this.getLanguageTag('latVariable'),
							},
						],
					},
				},
			],
		},
		function(err, settingsWidget) {
			settingsWidget.setTitle(
				getLanguageTag(currentWidget.constructor, 'configureTrailMap'),
			);

			settingsWidget.addEventListener('dismissed', function() {
				currentWidget.getMainContainer().hideModal();
			});

			settingsWidget.addEventListener('confirmed', function() {
				currentWidget.getMainContainer().hideModal();

				values = this.getValues();
				values.variable = values.variable || {};

				var config = {
					title:
						values.title || getLanguageTag(currentWidget, 'name'),
					deviceId: (values.variable.deviceData || {}).id || null,
					updateTime: values.updateTime || 60000,
					storageName: values.variable.storageName || null,
					latVariable:
						(values.variable.variables || {}).latVariable || null,
					lonVariable:
						(values.variable.variables || {}).lonVariable || null,
					timeVariable:
						(values.variable.variables || {}).timeVariable || null,
					dateRange: currentWidget.config.dateRange || 600,
				};

				currentWidget.setConfiguration(config, function() {
					this.event('configurationSet', {
						widget: this,
						configuration: this.config,
					});
				});
			});

			this.showModal();

			callback.call(this, false);
			return;
		},
	);
};

WidgetTrailMap.prototype.showRangeControl = function(callback) {
	callback = callback || function() {};

	var currentWidget = this;

	if (!this.config.deviceId || !this.config.storageName) {
		this.getMainContainer().showPopupErrorMessage(
			this.getLanguageTag('youNeedToSetupFirst'),
		);
		callback.call(this, false);
		return;
	}

	var settingsFields = [
		{
			name: 'dateRange',
			type: 'select',
			label: this.getLanguageTag('dateRange'),
			options: [
				{ value: 600, label: this.getLanguageTag('lastTenMinutes') },
				{ value: 60 * 60, label: this.getLanguageTag('lastOneHour') },
				{
					value: 60 * 60 * 2,
					label: this.getLanguageTag('last2Hours'),
				},
				{
					value: 60 * 60 * 6,
					label: this.getLanguageTag('last6Hours'),
				},
				{
					value: 60 * 60 * 12,
					label: this.getLanguageTag('last12Hours'),
				},
				{
					value: 60 * 60 * 24,
					label: this.getLanguageTag('last24Hours'),
				},
				{
					value: 60 * 60 * 24 * 7,
					label: this.getLanguageTag('last7Days'),
				},
				{
					value: 60 * 60 * 24 * 30,
					label: this.getLanguageTag('last30Days'),
				},
				{
					value: 60 * 60 * 24 * 90,
					label: this.getLanguageTag('last90Days'),
				},
				{
					value: 60 * 60 * 24 * 120,
					label: this.getLanguageTag('last120Days'),
				},
				{
					value: 'customRange',
					label: this.getLanguageTag('customRange'),
				},
			],
			value: this.config.dateRange || 600,
		},

		{
			name: 'customRange',
			type: 'dateRangePicker',
			label: this.getLanguageTag('customRange'),
			value: {
				startDate: new Date((this.config.customRange || {}).startDate),
				endDate: new Date((this.config.customRange || {}).endDate),
			},
		},
	];

	this.getMainContainer().setModalWidget(
		WidgetSettingsForm,
		{ fields: settingsFields },
		function(err, settingsWidget) {
			settingsWidget.setTitle(
				currentWidget.getLanguageTag('dateRangeControl'),
			);

			settingsWidget.addEventListener('dismissed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;
			});

			settingsWidget.addEventListener('valueChanged', function(data) {
				if (data.name === 'customRange') {
					currentWidget.config.dateRange = 'customRange';
					currentWidget.config.customRange = {
						startDate: data.value.startDate.toISOString(),
						endDate: data.value.endDate.toISOString(),
					};

					currentWidget.showRangeControl();
				}
			});

			settingsWidget.addEventListener('confirmed', function() {
				currentWidget.getMainContainer().hideModal();
				currentWidget._showingSettings = false;

				var values = this.getValues();

				currentWidget.config.dateRange = values.dateRange || 600;
				currentWidget.config.customRange = {
					startDate: values.customRange.startDate.toISOString(),
					endDate: values.customRange.endDate.toISOString(),
				};

				currentWidget.setConfiguration(
					currentWidget.config,
					function() {
						this.event('configurationSet', {
							widget: this,
							configuration: this.config,
						});
					},
				);
			});

			currentWidget._showingSettings = true;
			this.showModal();

			callback.call(this, false);
			return;
		},
	);
};

WidgetTrailMap.prototype.clearAllMarkers = function() {
	for (var i = 0; i < this.markers.length; i++) {
		this.markers[i].setMap(null);
	}

	this.markers = [];
};

WidgetTrailMap.prototype.clearAllPaths = function() {
	for (var i = 0; i < this.paths.length; i++) {
		this.paths[i].setMap(null);
	}

	this.paths = [];
};

WidgetTrailMap.prototype.openMapToMarker = function(marker) {
	if (marker === undefined || marker === null) {
		return;
	}

	var link =
		'https://www.google.com/maps/search/?api=1&query=' +
		marker.position.lat().toString() +
		',' +
		marker.position.lng().toString();
	window.open(link, '_system');
};

WidgetTrailMap.prototype.setConfiguration = function(config, callback) {
	var currentWidget = this;

	callback = callback || function() {};

	this.config = {
		title: config.title || getLanguageTag(this.constructor, 'name'),
		deviceId: config.deviceId || null,
		updateTime: config.updateTime || 60000,
		storageName: config.storageName || null,
		latVariable: config.latVariable || this.config.latVariable || null,
		lonVariable: config.lonVariable || this.config.lonVariable || null,
		timeVariable: config.timeVariable || this.config.timeVariable || null,
		dateRange: config.dateRange || this.config.dataRange || 600,
		customRange: config.customRange || this.config.customRange || null,
	};

	if (this._updateInterval !== null) {
		clearInterval(this._updateInterval);
		this._updateInterval = null;
	}

	this.update(false, function(err) {
		currentWidget._updateInterval = setInterval(function() {
			if (isActive()) {
				currentWidget.update(false, function(err) {});
			}
		}, currentWidget.config.updateTime || 60000);
	});

	callback.call(this);
};

WidgetTrailMap.prototype.getConfiguration = function() {
	return this.config;
};

WidgetTrailMap.prototype.PACKERY_SIZE = 'WidgetDashboard_Container_XLxM';

WidgetTrailMap.prototype.STATUS_TO_COLOR = {
	null: 'bdbdbd',
	info: '5bc0de',
	good: '5cb85c',
	minor: '5bc0de',
	warning: 'f0ad4e',
	critical: 'd9534f',
	missing: 'f0ad4e',
};

WidgetTrailMap.prototype.STATUS_TO_NUMBER = {
	null: 0,
	good: 1,
	info: 2,
	missing: 3,
	minor: 4,
	warning: 5,
	critical: 6,
};

WidgetTrailMap.prototype.language = deepAssign(
	{},
	WidgetDashboardBase.prototype.language,
	{
		'en-US': {
			name: 'Trail Map',
			getDirections: 'Get Directions',
			noConfigurationOptionsAreCurrentlyAvailable:
				'No configuration options are currently available.',
			noneOfYourDevicesHaveGeolocationMetaInformation:
				"None of your devices have geolocation information set, check your device's settings for more information.",
			status: 'Status',
			configureTrailMap: 'Configure Trail Map',
			updateTime: 'Update Interval',
			none: 'None',
			info: 'Info',
			good: 'Good',
			minor: 'Minor',
			warning: 'Warning',
			critical: 'Critical',
			missing: 'Missing',
			'1Minute': '1 Minute',
			'5Minutes': '5 Minutes',
			'10Minutes': '10 Minutes',
			'15Minutes': '15 Minutes',
			'30Minutes': '30 Minutes',
			'1Hour': '1 Hour',
			'2Hours': '2 Hours',
			'4Hours': '4 Hours',
			'6Hours': '6 Hours',
			'8Hours': '8 Hours',
			'12Hours': '12 Hours',
			rangeControl: 'Time Range',
			dateRangeControl: 'Time Range',
			youNeedToSetupFirst: 'The graph must be configured first',
			dateRange: 'Date Range',
			lastTenMinutes: 'Last 10 Minutes',
			lastOneHour: 'Last Hour',
			last2Hours: 'Last 2 Hours',
			last6Hours: 'Last 6 Hours',
			last12Hours: 'Last 12 Hours',
			last24Hours: 'Last 24 Hours',
			last7Days: 'Last 7 Days',
			last30Days: 'Last 30 Days',
			last90Days: 'Last 90 Days',
			last120Days: 'Last 120 Days',
			customRange: 'Custom Range',
			timeVariable: 'Time Variable',
			lonVariable: 'Longitude Variable',
			latVariable: 'Latitude Variable',
			time: 'Time',
		},

		de: {
			name: 'Gerätekarte',
		},

		es: {
			name: 'Mapa De Dispositivos',
		},

		ar: {
			name: 'خريطة الجهاز',
		},
	},
);
