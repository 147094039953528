function ElementControllerEmbeddedBLEConnection(
	parentController,
	parentPlane,
	elementName,
) {
	ElementControllerEmbeddedBase.call(
		this,
		parentController,
		parentPlane,
		elementName,
	);

	this.addProperty('instance', null, 0, 'driverInstance', null, 'ble');

	var pairingTypes = [
		'ATMO_BLE_PairingType_JustWorks',
		'ATMO_BLE_PairingType_RandomKey',
		'ATMO_BLE_PairingType_UserKey',
	];

	this.addProperty(
		'pairingType',
		null,
		'ATMO_BLE_PairingType_JustWorks',
		'select',
		pairingTypes,
		null,
	);
	// 	this.addProperty('pairingType', null, 'none', 'select', null, null);
	this.addProperty('pairingKey', null, '123456', 'text', null, null);
	// 	this.addProperty('randomPairingKey', false, 'checkbox', 'select', null, null);
	// 	this.addProperty('autoConnect', null, true, 'checkbox', null, null);

	this.addAbility('setup', [], [], false, true);
	this.setAbilityCode(
		'setup',
		`
	ATMO_BLE_PairingCfg_t pairingCfg;
	pairingCfg.pairingKey = ATMO_PROPERTY(undefined, pairingKey);
	pairingCfg.type = ATMO_PROPERTY(undefined, pairingType);
	ATMO_BLE_GAPPairingCfg(ATMO_PROPERTY(undefined, instance), &pairingCfg);

	ATMO_BLE_RegisterEventAbilityHandle(ATMO_PROPERTY(undefined, instance), ATMO_BLE_EVENT_Connected, ATMO_ABILITY(undefined, connected));
	ATMO_BLE_RegisterEventAbilityHandle(ATMO_PROPERTY(undefined, instance), ATMO_BLE_EVENT_Disconnected, ATMO_ABILITY(undefined, disconnected));
	ATMO_BLE_RegisterEventAbilityHandle(ATMO_PROPERTY(undefined, instance), ATMO_BLE_EVENT_PairingRequested, ATMO_ABILITY(undefined, pairingRequested));
	ATMO_BLE_RegisterEventAbilityHandle(ATMO_PROPERTY(undefined, instance), ATMO_BLE_EVENT_PairingSuccess, ATMO_ABILITY(undefined, pairingSucceeded));
	ATMO_BLE_RegisterEventAbilityHandle(ATMO_PROPERTY(undefined, instance), ATMO_BLE_EVENT_PairingFailed, ATMO_ABILITY(undefined, pairingFailed));
	
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('disconnect', [], [], false, false);
	this.setAbilityCode(
		'disconnect',
		`
	ATMO_BLE_GAPDisconnect(ATMO_PROPERTY(undefined, instance));
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('connected', [], ['connected'], false, true);
	this.setAbilityCode(
		'connected',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('disconnected', [], ['disconnected'], false, true);
	this.setAbilityCode(
		'disconnected',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('pairingRequested', [], ['pairingRequested'], false, true);
	this.setAbilityCode(
		'pairingRequested',
		`
	ATMO_CreateValueCopy(out, in);
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('pairingSucceeded', [], ['pairingSucceeded'], false, true);
	this.setAbilityCode(
		'pairingSucceeded',
		`
	return ATMO_Status_Success;
	`,
	);

	this.addAbility('pairingFailed', [], ['pairingFailed'], false, true);
	this.setAbilityCode(
		'pairingFailed',
		`
	return ATMO_Status_Success;
	`,
	);
}

ElementControllerEmbeddedBLEConnection.prototype = Object.create(
	ElementControllerEmbeddedBase.prototype,
);
ElementControllerEmbeddedBLEConnection.prototype.constructor = ElementControllerEmbeddedBLEConnection;

ElementControllerEmbeddedBLEConnection.prototype.NAME = 'BLEConnection';
ElementControllerEmbeddedBLEConnection.prototype.DEFAULT_TRIGGER = 'connected';
ElementControllerEmbeddedBLEConnection.prototype.DEFAULT_ABILITY = 'disconnect';
ElementControllerEmbeddedBLEConnection.prototype.DEFAULT_ARGUMENTS = ['value'];
ElementControllerEmbeddedBLEConnection.prototype.TYPE = 'EmbeddedBLEConnection';
ElementControllerEmbeddedBLEConnection.prototype.VARIANTS = [
	'embedded',
	'triggers',
	'abilities',
	'properties',
	'variables',
	'ble',
];
ElementControllerEmbeddedBLEConnection.prototype.HIDDEN = false;
ElementControllerEmbeddedBLEConnection.prototype.CATEGORY = 'ble';
ElementControllerEmbeddedBLEConnection.prototype.COUPLER_TYPE = 'bleConnection';

ElementControllerEmbeddedBLEConnection.prototype.HELP_PAGE_HREF =
	'https://docs.atmosphereiot.com';

ElementControllerEmbeddedBLEConnection.prototype.language = deepAssign(
	{},
	ElementControllerEmbeddedBase.prototype.language,
	{
		'en-US': {
			EmbeddedBLEConnection: 'BLE Connection',
			disconnect: 'Disconnect',
			connected: 'Connected',
			disconnected: 'Disconnected',
			pairingRequested: 'Pairing Requested',
			pairingSucceeded: 'Pairing Succeeded',
			pairingFailed: 'Pairing Failed',
			pairingType: 'Pairing Type',
			pairingKey: 'Custom Passkey',
			ATMO_BLE_PairingType_JustWorks: 'Just Works',
			ATMO_BLE_PairingType_RandomKey: 'Random Passkey',
			ATMO_BLE_PairingType_UserKey: 'Custom Passkey',
		},
	},
);
