
function WidgetDashboardBase(id, api, parent, options) {
	
	options = options || {};
	
	options.menuOptions = options.menuOptions || [
		{
			label: getLanguageTag(this.constructor, 'settings'),
			value: 'settings',
		},
		{ label: getLanguageTag(this.constructor, 'help'), value: 'help' },
		{
			label: getLanguageTag(this.constructor, 'remove'),
			value: 'remove',
		},
	];
	
	if (options.readOnly) {
		options.menuOptions = [
			{
				label: getLanguageTag(this.constructor, 'help'),
				value: 'help',
			},
		];
	}
	
	// If the widget would like to handle the goto device and we are in the dashboards area
	if(options.gotoDevice && ((getHashCommand() || {}).location || '').toLowerCase() === 'dashboards') {
		options.menuOptions.push({
			label: this.getLanguageTag('gotoDevice'),
			value: 'gotoDevice'
		});
	}
	
	WidgetPanelOptionsBase.call(this, id, api, parent, options);
}

WidgetDashboardBase.prototype = Object.create(WidgetPanelOptionsBase.prototype);
WidgetDashboardBase.prototype.constructor = WidgetDashboardBase;

WidgetDashboardBase.prototype.initialize = function(callback) {
	
	this.addEventListener('optionSelected', function(option) {
		if (option === 'gotoDevice') {
			this.event('gotoDevice', this);
			this.onGotoDevice();
		}
	});
	
	WidgetPanelOptionsBase.prototype.initialize.call(this, callback);
}

WidgetDashboardBase.prototype.onGotoDevice = function() {

	// If we aren't configured tell the user to do that in an error message
	if (!this.config.deviceId) {
		this.getMainContainer().showPopupInfoMessage(this.getLanguageTag('configureWidget'));
		return;
	}
	
	// Else we link the user to the device dashboard
	this.getMainContainer().setLocation(LocationDeviceDashboard, {deviceId:this.config.deviceId, org:getHashCommand().org});
};

WidgetDashboardBase.prototype.language = deepAssign(
	{},
	WidgetPanelOptionsBase.prototype.language,
	{
		'en-US': {
			name: 'Dashboard Widget',
			configureWidget: 'Use the menu to configure the widget first',
		},
	},
);
